import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import { userIsAdmin, hasPermission } from "../../../redux/selectors/Admin.jsx";

import _ from "lodash";
import qs from "qs";
import cx from "classnames";
import moment from "moment";
import axios from "~/variables/axios.jsx";
import { basePath, apiUrl } from "~/variables/server.jsx";

// lib
import YatClient from '~/lib/yat.js';

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import ShipmentSearchCard from "~/components/ShipmentSearchCard/ShipmentSearchCard.jsx";
import ShipmentHistoryCard from "~/components/ShipmentHistoryCard/ShipmentHistoryCard.jsx";
import GeneralInformation from "~/components/GeneralInformation/GeneralInformation.jsx";
import AddressFields from "~/components/AddressFields/AddressFields.jsx";
import ContactFields from "~/components/ContactFields/ContactFields.jsx";
import ContactSearch from "~/components/TMS/ContactSearch.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import HandlingUnit from "~/components/HandlingUnit/HandlingUnit.jsx";
import SpecialInformationFields from "~/components/SpecialInformationFields/SpecialInformationFields.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import { openAlertModal } from "../../Alert/actions/Alert.jsx";

import ImportExportInformation from "../components/ImportExportInformation.jsx";
import PostPickupDetails from "../components/PostPickupDetails.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FileCopy from "@material-ui/icons/FileCopy";
import FastRewind from "@material-ui/icons/FastRewind";
import FastForward from "@material-ui/icons/FastForward";
import LocationOn from "@material-ui/icons/LocationOn";
import SwapVert from "@material-ui/icons/SwapVert";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Box from '@material-ui/core/Box';
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";

// Modals
import ContactSearchModal from "~/components/ContactSearchModal/ContactSearchModal.jsx";
import ProductSearchModal from "../../BillOfLading/components/ProductSearchModal.jsx";
import InternationalNotesModal from '../../InternationalNotesModal/containers/InternationalNotesModal.jsx';
import { getIntlNotes } from "../../InternationalNotesModal/actions/InternationalNotes.jsx";
import { loadLevelsIfEmpty } from "../../../components/ImportanceLevel/actions/ImportanceLevel.jsx";
import HistoryModal from "../../../components/HistoryModal/HistoryModal.jsx";

import { getShipmentHistory } from "../../InternationalLoadBoard/actions/InternationalLoadBoard.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import { isObject, getTimezoneOffset } from "../../../helpers.jsx";

class International extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            loading: true,
            alert: null,
            user: null,
            username: "",
            body: null,
            company: null,
            date_created: "",
            time_created: "",
            warehouse: "",
            warehouses: [],
            intl_carrier: "",
            intl_carrier_rate: "",
            intl_carrier_duties: "",
            intl_ship_id: "",
            status: "hold",
            search_ship_by: "intlShipId",
            search_ship: "",
            saved_ship_id: "",

            // imp/exp info
            direction: "import",
            shipping_mode: "ocean",
            cb_name: "",
            cb_phone: "",
            cb_email: "",
            service_type: "",
            named_place: "",
            service_types: [
                {label: "Door to Door", name: "door_to_door"},
                {label: "Door to Airport/Port", name: "door_to_port"},
                {label: "Airport/Port to Door", name: "port_to_door"},
                {label: "Airport/Port to Airport/Port", name: "port_to_port"},
            ],

            container_size: "",
            container_sizes: [
                {label: "Select Size", name: ""},
                {label: "20", name: "20"},
                {label: "40", name: "40"},
                {label: "Oversize/Other", name: "over_size"},
            ],

            container_type: "",
            container_types: [
                {label: "Select Type", name: ""},
                {label: "Standard", name: "standard"},
                {label: "High Cube", name: "high_cube"},
                {label: "Reefer", name: "reefer"},
                {label: "Open Top", name: "open_top"},
                {label: "Flat Rack", name: "flat_rack"},
                {label: "Other", name: "other"},
            ],

            container_load: "",

            inco_term: "",
            inco_terms: [
                {label: "EXW", name: "exw"},
                {label: "FOB", name: "fob"},
                {label: "CIF", name: "cif"},
                {label: "CPT", name: "cpt"},
                {label: "CRF", name: "crf"},
                {label: "DAP", name: "dap"},
                {label: "DDP", name: "ddp"},
                {label: "FAS", name: "fas"},
                {label: "FCA", name: "fca"},
                {label: "CIP", name: "cip"},
                {label: "DAT", name: "dat"},
            ],

            // Gen info
            provider_id: "",
            master_bill_num: "",
            require_master_bill: false,
            house_bill_num: "",
            require_house_bill: false,
            airway_bill_num: "",
            require_airway_bill: false,
            isf_num: "",
            require_isf: false,
            customs_entry_num: "",
            po_num: "",
            require_po: false,
            so_num: "",
            require_so: false,
            ref_num: "",
            require_ref: false,
            transit_status: "",
            transit_sub_status: "",
            custom_date: "",
            freight_ready_date: "",
            ready_time: "",
            close_time: "",
            level: null,
            enable_new_ln_ft_method: 0,

            ref_entry_opts: ['test', 'test2'],
            references: [],
            ref_entry_type: 'freeform',

            display_custom_piece_count: false,
            custom_pallet_count: "",
            custom_piece_count: "",
            custom_loose_count: "",
            custom_unit_master_name: "Piece",
            custom_unit_master_id: null,
            custom_overall_piece_qty: "",

            notes: "",
            imageFiles: [],
            canned_note: "",
            total_declared_value: 0,

            // shipper info
            shipper_name: "",
            shipper_address1: "",
            shipper_address2: "",
            shipper_zip: "",
            shipper_city: "",
            shipper_state: "",
            shipper_country: "",
            shipper_contact_name: "",
            shipper_contact_phone: "",
            shipper_contact_email: "",
            shipper_contact_fax: "",
            shipper_search_by: "SEARCH_ANY",
            shipper_search_for: "",

            //Consignee info
            consignee_name: "",
            consignee_address1: "",
            consignee_address2: "",
            consignee_zip: "",
            consignee_city: "",
            consignee_state: "",
            consignee_country: "",
            consignee_contact_name: "",
            consignee_contact_phone: "",
            consignee_contact_email: "",
            consignee_contact_fax: "",
            consignee_search_by: "SEARCH_ANY",
            consignee_search_for: "",

            nmfc_notice_message:  "For an accurate quote on the shipments or to make the dimensions mandatory please call us at 1-888-653-1323.",
            units: [
                {
                    id: null,
                    type: "Pallet",
                    pieces: "1",
                    stackable: false,
                    length: "",
                    length_uom: "in",
                    width: "",
                    width_uom: "in",
                    height: "",
                    height_uom: "in",
                    cube: "",
                    products: [
                        {
                            id: null,
                            nmfc: "",
                            hs_code: "",
                            class: "50",
                            description: "",
                            uom: "Piece",
                            pieces: "1",
                            weight: "",
                            weight_uom: "lbs",
                            declared_value: "",
                            hazardous: false,
                            auto_weight: false,
                            full_value_coverage: false,
                            part_no: "",
                            overall_piece_qty: "",
                            hazmat: {
                                class: "",
                                un: "",
                                packingGroup: "",
                                emergencyNumber: "",
                                packingInstructions: "",
                                overpackUsed: false
                            },
                            errors: []
                        }
                    ],
                    errors: []
                }
            ],

            containers: [
                {
                    id: null,
                    container_num: "",
                    seal_num: "",
                    container_size: "",
                    container_type: "",
                    custom_pallet_count: "",
                    custom_piece_count: "",
                    custom_loose_count: "",
                    custom_unit_master_name: "Piece",
                    custom_unit_master_id: "",
                    custom_overall_piece_qty: "",
                    units: [],
                }
            ],
            productSearchModal: false,
            products: [],
            productKey: "",
            productUnitKey: "",
            defaultProduct: {},
            totals: {
                units: 0,
                weight: 0,
                linear_feet: 0,
                cubic_meters: 0,
                value: 0,
                overall_piece_qty: 0,
                containers: 0,
            },

            uom_opt: "imperial", //Imperial or Metric

            imp_length_types: [
                {label: "IN.", name: "in"},
                {label: "FT.", name: "ft"},
                {label: "YD.", name: "yd"},
            ],
            met_length_types: [
                {label: "MM.", name: "mm"},
                {label: "CM.", name: "cm"},
                {label: "M.", name: "m"},
            ],
            default_length_type: "in",

            imp_weight_types: [
                {label: "OZ.", name: "oz"},
                {label: "LBS.", name: "lbs"},
            ],
            met_weight_types: [
                {label: "G.", name: "g"},
                {label: "KG.", name: "kg"},
            ],
            default_weight_type: "lbs",

            // post pickup details
            vehicle_name: "",
            vehicle_num: "",
            container_num: "",
            seal_num: "",
            origin_port: "",
            dest_port: "",
            booking_num: "",
            cargo_ready_date: "",
            actual_pu_date: "",
            doc_cutoff_origin_date: "",
            cargo_cutoff_origin_date: "",
            actual_receipt_origin_date: "",
            etd_depart_origin_date: "",
            cob_depart_origin_date: "",
            eta_arrival_dest_date: "",
            actual_arrival_dest_date: "",
            eta_final_dest_date: "",
            actual_final_dest_date: "",
            last_free_day: "",
            eta_door_date: "",
            actual_door_date: "",
            post_pallet_count: "",
            post_total_weight: "",
            post_weight_type: "lbs",
            post_air_weight: "",
            post_air_weight_type: "lbs",

            //pages
            tabIndex: 0,
            carrierTabIndex: 0,
            containerTabIndex: 0,
            expanded: "",

            // modals
            contactSearchModal: false,
            contacts: [],
            contactSearchType: "",
            historyModal: false,

            classification: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleCarrierTabChange = this.handleCarrierTabChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleDatetime = this.handleDatetime.bind(this);
        this.clearGeneralInfo = this.clearGeneralInfo.bind(this);
        this.handleReference = this.handleReference.bind(this);
        this.handleContactSearch = this.handleContactSearch.bind(this);
        this.handleContactSelect = this.handleContactSelect.bind(this);
        this.clearAddress = this.clearAddress.bind(this);
        this.handleSwap = this.handleSwap.bind(this);
        this.clearCarriersAndSetState = this.clearCarriersAndSetState.bind(this);
        this.addNote = this.addNote.bind(this);
        this.toggleHazardous = this.toggleHazardous.bind(this);
        this.handleHazardous = this.handleHazardous.bind(this);

        this.addContainer = this.addContainer.bind(this);
        this.removeContainer = this.removeContainer.bind(this);
        this.handleContainer = this.handleContainer.bind(this);

        this.addUnit = this.addUnit.bind(this);
        this.handleUnit = this.handleUnit.bind(this);
        this.removeUnit = this.removeUnit.bind(this);
        this.updateTotals = this.updateTotals.bind(this);

        this.handleProductSearch = this.handleProductSearch.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.handleProduct = this.handleProduct.bind(this);
        this.clearProduct = this.clearProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.setDefaultProduct = this.setDefaultProduct.bind(this);

        this.getIntlCarriers = this.getIntlCarriers.bind(this);

        this.handleSaveDraft = this.handleSaveDraft.bind(this);
        this.handleSaveNew = this.handleSaveNew.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.loadSavedImages = this.loadSavedImages.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.buildPayload = this.buildPayload.bind(this);
        this.validatePayload = this.validatePayload.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.loadDefaultData = this.loadDefaultData.bind(this);

        this.handleModal = this.handleModal.bind(this);
        this.handleEmailError = this.handleEmailError.bind(this);

        this.updatePostPickup = this.updatePostPickup.bind(this);
    }

    async componentDidMount() {
        let intlShipId = "";
        let url = "";
        let alert = null;

        if (!_.isEmpty(this.props.match.params.id)) {
            intlShipId = this.props.match.params.id;
        }

        const isNewShipment = this.isNewShipment(intlShipId);

        if(!isNewShipment) {
            url = "/index.php?p=api&r=json&c=international&m=load&id=" + intlShipId;
        } else {
            url = "/index.php?p=api&r=json&c=international&m=newShipment";
        }

        alert = (
            <SweetAlert
                info
                style={{
                    display: "block",
                    color: "black"
                }}
                title="International Shipment is loading."
                onConfirm={() => {}}
                showConfirm={false}
            >
                Please wait...
                <br />
                <div style={{ textAlign: "center", margin: 5 }}>
                    <CircularProgress />
                </div>
            </SweetAlert>
        );

        this.setState({
            mounted: true,
            alert
        });

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body) && this.state.mounted) {
                await this.loadPage(response);

                if(!isNewShipment) {
                    this.loadSavedImages(intlShipId);
                }
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an unexpected error loading the international shipment!", { variant: "error" });
        }
        if(this.props.userHasBolLevelPermission) {
            this.props.loadLevelsIfEmpty();
        }
        this.setState({
            alert: null
        });
    }

    async loadPage(response) {
        const data = response.data;
        let setDefaultProduct = false;

        let {
            status,
            username,
            date_created,
            time_created,
            intl_ship_id,
            warehouses,
            warehouse,
            intl_carrier,
            intl_carrier_rate,
            intl_carrier_duties,
            direction,
            shipping_mode,
            container_load,
            cb_name,
            cb_phone,
            cb_email,
            service_type,
            named_place,
            container_size,
            container_type,
            inco_term,
            provider_id,
            master_bill_num,
            house_bill_num,
            airway_bill_num,
            isf_num,
            customs_entry_num,
            po_num,
            so_num,
            ref_num,
            require_po,
            require_so,
            require_ref,
            require_master_bill,
            require_house_bill,
            require_airway_bill,
            require_isf,
            transit_status,
            transit_sub_status,
            custom_date,
            freight_ready_date,
            ready_time,
            close_time,
            references,
            ref_entry_type,
            display_custom_piece_count,
            custom_pallet_count,
            custom_piece_count,
            custom_loose_count,
            custom_overall_piece_qty,
            custom_unit_master_id,
            custom_unit_master_name,
            notes,
            total_declared_value,
            shipper_name,
            shipper_address1,
            shipper_address2,
            shipper_zip,
            shipper_city,
            shipper_state,
            shipper_country,
            shipper_contact_name,
            shipper_contact_phone,
            shipper_contact_email,
            shipper_contact_fax,
            consignee_name,
            consignee_address1,
            consignee_address2,
            consignee_zip,
            consignee_city,
            consignee_state,
            consignee_country,
            consignee_contact_name,
            consignee_contact_phone,
            consignee_contact_email,
            consignee_contact_fax,
            units,
            containers,
            defaultProduct,
            uom_opt,
            vehicle_name,
            vehicle_num,
            container_num,
            seal_num,
            origin_port,
            dest_port,
            booking_num,
            cargo_ready_date,
            actual_pu_date,
            doc_cutoff_origin_date,
            cargo_cutoff_origin_date,
            actual_receipt_origin_date,
            etd_depart_origin_date,
            cob_depart_origin_date,
            eta_arrival_dest_date,
            actual_arrival_dest_date,
            eta_final_dest_date,
            actual_final_dest_date,
            last_free_day,
            eta_door_date,
            actual_door_date,
            post_pallet_count,
            post_total_weight,
            post_weight_type,
            post_air_weight,
            post_air_weight_type,
            level,
            enable_new_ln_ft_method,
        } = this.state;

        const res = data.body;

        username = data.user.name,

        date_created = !_.isEmpty(res.create_time) ? moment(res.create_time).format("L") : "";
        time_created = !_.isEmpty(res.create_time) ? moment(res.create_time).format("h:mm A") : "";

        intl_ship_id = res.id || "",
        status= res.status,
        warehouses = res.warehouses || [],
        warehouse = res.warehouse.code || "",

        intl_carrier = res.intl_carrier || '',
        intl_carrier_rate =  res.intl_carrier_rate || '',
        intl_carrier_duties =  res.intl_carrier_duties || '',

        // import/Export info
        direction = res.direction || "";
        shipping_mode = res.shipping_mode || "";
        container_load = res.container_load || "";
        cb_name = res.cb_name || "";
        cb_phone = res.cb_phone || "";
        cb_email = res.cb_email || "";
        service_type = res.service_type || "";
        named_place = res. named_place || "";
        container_size = res. container_size || "";
        container_type = res.container_type || "";
        inco_term = res.inco_term || "";

        // general information
        provider_id = res.provider_id || "";
        master_bill_num = res.master_bill_num || "";
        house_bill_num = res.house_bill_num || "";
        airway_bill_num = res.airway_bill_num || "";
        isf_num = res.isf_num || "";
        customs_entry_num = res.customs_entry_num || "";
        po_num = res.po_num || res.warehouse.po_number || "";
        so_num = res.so_num || res.warehouse.so_number || "";
        ref_num = res.ref_num || res.warehouse.ref_number || "";
        transit_status = res.transit_status || "";
        transit_sub_status = res.transit_sub_status || "";
        custom_date = res.custom_date || "";
        freight_ready_date = res.freight_ready_date || "";
        ready_time = res.ready_time || "";
        close_time = res.close_time || "";
        references = res.references || [];
        ref_entry_type = "freeform"; //THIS NEEDS DONE
        uom_opt = res.uom_option || "imperial";
        level = res.intl_ship_level_id;
        enable_new_ln_ft_method	 = res.enable_new_ln_ft_method;

        // custom piece count setting
        display_custom_piece_count = res.display_custom_piece_count || this.state.display_custom_piece_count;
        switch(res.warehouse.override_custom_piece_count) {
            case "enable":
                display_custom_piece_count = true;
                break;
            case "disable":
                display_custom_piece_count = false;
                break;
        }

        custom_pallet_count = res.custom_pallet_count;
        custom_piece_count = res.custom_piece_count;
        custom_loose_count = res.custom_loose_count;
        custom_overall_piece_qty = res.custom_overall_piece_qty;

        if (res.custom_unit_master_id) {
            custom_unit_master_id = res.custom_unit_master_id;
            data.body.uom.forEach($uom => {
                if ($uom['id'] === custom_unit_master_id ) {
                    custom_unit_master_name = $uom['name'];
                }
            });
        }

        if(res.require_refs) {
            require_po = res.require_refs.require_intl_po == 1 ? true : false;
            require_so = res.require_refs.require_intl_so == 1 ? true : false;
            require_ref = res.require_refs.require_intl_ref == 1 ? true : false;
            require_master_bill = res.require_refs.require_master_bill == 1 ? true : false;
            require_house_bill = res.require_refs.require_house_bill == 1 ? true : false;
            require_airway_bill = res.require_refs.require_airway_bill == 1 ? true : false;
            require_isf = res.require_refs.require_isf == 1 ? true : false;
        }

        // special
        notes = !_.isEmpty(res.special) ?  res.special.notes : "";

        // shipment details
        const locations = res.locations;
        if (!_.isEmpty(locations)) {
            for(const loc of locations) {
                if(loc.type == 'shipper') {
                    shipper_name = loc.name || "";
                    shipper_address1 = loc.addr1 || "";
                    shipper_address2 = loc.addr2 || "";
                    shipper_zip = loc.zip || "";
                    shipper_city = loc.city || "";
                    shipper_state = loc.state || "";
                    shipper_country = loc.country || "";
                    shipper_contact_name = loc.contact.name || "";
                    shipper_contact_phone = loc.contact.phone || "";
                    shipper_contact_email = loc.contact.email || "";
                    shipper_contact_fax = loc.contact.fax || "";
                } else {
                    consignee_name = loc.name || "";
                    consignee_address1 = loc.addr1 || "";
                    consignee_address2 = loc.addr2 || "";
                    consignee_zip = loc.zip || "";
                    consignee_city = loc.city || "";
                    consignee_state = loc.state || "";
                    consignee_country = loc.country || "";
                    consignee_contact_name = loc.contact.name || "";
                    consignee_contact_phone = loc.contact.phone || "";
                    consignee_contact_email = loc.contact.email || "";
                    consignee_contact_fax = loc.contact.fax || "";
                }
            }
        }

        if(res.shipping_mode === "ocean") {
            const res_containers = res.containers;
            if(!_.isEmpty(res_containers)) {
                containers = [];
                for (const resCon of res_containers) {
                    const container = {
                        id: resCon.id,
                        container_num: resCon.container_num,
                        seal_num: resCon.seal_num,
                        container_size: resCon.container_size,
                        container_type: resCon.container_type,
                        custom_pallet_count: resCon.custom_pallet_count,
                        custom_piece_count: resCon.custom_piece_count,
                        custom_loose_count: resCon.custom_loose_count,
                        custom_unit_master_name: resCon.custom_unit_master_name,
                        custom_unit_master_id: resCon.custom_unit_master_id,
                        custom_overall_piece_qty: resCon.custom_overall_piece_qty,
                        units: !_.isEmpty(resCon.units) ? this.setUnitResData(resCon.units) : [],
                    }
                    containers.push(container);
                }

            } else {
                setDefaultProduct = true;
            }
        } else {
            if(!_.isEmpty(res.units)) {
                units = this.setUnitResData(res.units);
            } else {
                setDefaultProduct = true;
            }
        }

        if(!_.isEmpty(res.defaultProduct)) {
            defaultProduct = res.defaultProduct;
        } else {
            setDefaultProduct = false;
        }

        if(setDefaultProduct) {
            this.setDefaultProduct(res.defaultProduct);
        }

        // post pickup
        if(!_.isEmpty(res.postPickup)) {
            vehicle_name = res.postPickup.vehicle_name;
            vehicle_num = res.postPickup.vehicle_num;
            container_num = res.postPickup.container_num;
            seal_num = res.postPickup.seal_num;
            origin_port = res.postPickup.origin_port;
            dest_port = res.postPickup.dest_port;
            booking_num = res.postPickup.booking_num;
            cargo_ready_date = res.postPickup.cargo_ready_date;
            actual_pu_date = res.postPickup.actual_pu_date;
            doc_cutoff_origin_date = res.postPickup.doc_cutoff_origin_date;
            cargo_cutoff_origin_date = res.postPickup.cargo_cutoff_origin_date;
            actual_receipt_origin_date = res.postPickup.actual_receipt_origin_date;
            etd_depart_origin_date = res.postPickup.etd_depart_origin_date;
            cob_depart_origin_date = res.postPickup.cob_depart_origin_date;
            eta_arrival_dest_date = res.postPickup.eta_arrival_dest_date;
            actual_arrival_dest_date = res.postPickup.actual_arrival_dest_date;
            eta_final_dest_date = res.postPickup.eta_final_dest_date;
            actual_final_dest_date = res.postPickup.actual_final_dest_date;
            last_free_day = res.postPickup.last_free_day;
            eta_door_date = res.postPickup.eta_door_date;
            actual_door_date = res.postPickup.actual_door_date;
            post_pallet_count = res.postPickup.post_pallet_count;
            post_total_weight = res.postPickup.post_total_weight;
            post_weight_type = res.postPickup.post_weight_type;
            post_air_weight = res.postPickup.post_air_weight;
            post_air_weight_type = res.postPickup.post_air_weight_type;
        }

        this.setState({
            user: data.user,
            body: data.body,
            username,
            date_created,
            time_created,
            status,
            warehouses,
            warehouse,
            intl_carrier,
            intl_carrier_rate,
            intl_carrier_duties,
            intl_ship_id,
            saved_ship_id: intl_ship_id,
            direction,
            shipping_mode,
            container_load,
            cb_name,
            cb_phone,
            cb_email,
            service_type,
            named_place,
            container_size,
            container_type,
            inco_term,
            provider_id,
            master_bill_num,
            house_bill_num,
            airway_bill_num,
            isf_num,
            customs_entry_num,
            po_num,
            so_num,
            ref_num,
            require_po,
            require_so,
            require_ref,
            require_master_bill,
            require_house_bill,
            require_airway_bill,
            require_isf,
            transit_status,
            transit_sub_status,
            custom_date,
            freight_ready_date,
            ready_time,
            close_time,
            references,
            ref_entry_type,
            display_custom_piece_count,
            custom_pallet_count,
            custom_piece_count,
            custom_loose_count,
            custom_overall_piece_qty,
            custom_unit_master_id,
            custom_unit_master_name,
            notes,
            total_declared_value,
            shipper_name,
            shipper_address1,
            shipper_address2,
            shipper_zip,
            shipper_city,
            shipper_state,
            shipper_country,
            shipper_contact_name,
            shipper_contact_phone,
            shipper_contact_email,
            shipper_contact_fax,
            consignee_name,
            consignee_address1,
            consignee_address2,
            consignee_zip,
            consignee_city,
            consignee_state,
            consignee_country,
            consignee_contact_name,
            consignee_contact_phone,
            consignee_contact_email,
            consignee_contact_fax,
            units,
            containers,
            defaultProduct,
            uom_opt,
            vehicle_name,
            vehicle_num,
            container_num,
            seal_num,
            origin_port,
            dest_port,
            booking_num,
            cargo_ready_date,
            actual_pu_date,
            doc_cutoff_origin_date,
            cargo_cutoff_origin_date,
            actual_receipt_origin_date,
            etd_depart_origin_date,
            cob_depart_origin_date,
            eta_arrival_dest_date,
            actual_arrival_dest_date,
            eta_final_dest_date,
            actual_final_dest_date,
            last_free_day,
            eta_door_date,
            actual_door_date,
            post_pallet_count,
            post_total_weight,
            post_weight_type,
            post_air_weight,
            post_air_weight_type,
            level,
            enable_new_ln_ft_method,
        }, () => this.updateTotals())
    }

    async loadDefaultData() {
        let alert = null;
        alert = (
            <SweetAlert
                info
                style={{
                    display: "block",
                    color: "black"
                }}
                title="International Shipment is loading."
                onConfirm={() => {}}
                showConfirm={false}
            >
                Please wait...
                <br />
                <div style={{ textAlign: "center", margin: 5 }}>
                    <CircularProgress />
                </div>
            </SweetAlert>
        );

        this.setState({
            alert
        });

        try {
            const response = await axios.get("/index.php?p=api&r=json&c=international&m=load&whCode=" + this.state.warehouse);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body) && this.state.mounted) {
                const data = response.data;
                let {
                    warehouses,
                    warehouse,
                    references,
                    notes,
                    require_po,
                    require_so,
                    require_ref,
                    require_master_bill,
                    require_house_bill,
                    require_airway_bill,
                    require_isf,
                    po_num,
                    so_num,
                    ref_num,
                    defaultProduct,
                    display_custom_piece_count,

                } = this.state;

                const res = data.body;

                warehouses = res.warehouses || [],
                warehouse = res.warehouse.code || "",
                references = res.references || [];
                notes = !_.isEmpty(res.special) ?  res.special.notes : "";
                require_po = res.require_refs.require_intl_po == 1 ? true : false;
                require_so = res.require_refs.require_intl_so == 1 ? true : false;
                require_ref = res.require_refs.require_intl_ref == 1 ? true : false;
                require_master_bill = res.require_refs.require_master_bill == 1 ? true : false;
                require_house_bill = res.require_refs.require_house_bill == 1 ? true : false;
                require_airway_bill = res.require_refs.require_airway_bill == 1 ? true : false;
                require_isf = res.require_refs.require_isf == 1 ? true : false;
                po_num = res.warehouse.po_number || "";
                so_num = res.warehouse.so_number || "";
                ref_num = res.warehouse.ref_number || "";
                defaultProduct = {};

                // custom piece count setting
                display_custom_piece_count = res.display_custom_piece_count;
                switch(res.warehouse.override_custom_piece_count) {
                    case "enable":
                        display_custom_piece_count = true;
                        break;
                    case "disable":
                        display_custom_piece_count = false;
                        break;
                }

                if(!_.isEmpty(res.defaultProduct)) {
                    defaultProduct = res.defaultProduct;
                    this.setDefaultProduct(res.defaultProduct);
                }

                this.setState({
                    body: data.body,
                    warehouses,
                    warehouse,
                    references,
                    notes,
                    po_num,
                    so_num,
                    ref_num,
                    defaultProduct,
                    require_po,
                    require_so,
                    require_ref,
                    require_master_bill,
                    require_house_bill,
                    require_airway_bill,
                    require_isf,
                    display_custom_piece_count,
                });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an unexpected error loading the international shipment!", { variant: "error" });
        }

        this.setState({
            alert: null
        });
    }

    isNewShipment(intl_ship_id) {
        if(!intl_ship_id) return true;
        if(Number.isInteger(intl_ship_id) && intl_ship_id > 0) {
            return false;
        } else if(intl_ship_id.trim().length) {
            return false;
        }
        return true;
    }

    isNewShipment(intl_ship_id) {
        if(!intl_ship_id) return true;
        if(Number.isInteger(intl_ship_id) && intl_ship_id > 0) {
            return false;
        } else if(intl_ship_id.trim().length) {
            return false;
        }
        return true;
    }



    handleModal(modal, state) {
        this.setState({ [modal]: state });
    }

    handleSaveDraft(){
        const data = this.buildPayload();
        const isNewShipment = this.isNewShipment(this.state.intl_ship_id);
        if(isNewShipment) {
            this.saveNew(data);
        } else {
            this.saveDraft(data)
        }
    }

    handleSaveNew() {
        const data = this.buildPayload();
        this.saveNew(data);
    }

    async saveNew(data) {
        const config = {};
        const copyShipAsNew = this.isNewShipment(this.state.intl_ship_id) ? false : true;
        data['general']['status'] = "hold";

        if(!_.isEmpty(this.state.imageFiles) && copyShipAsNew) {
            if (confirm("Would you like to copy attached files to the new International Shipment?")) {
                data['copyImages'] = true;
            }
        }

        try {
            const response = await axios.post("/index.php?p=api&r=json&c=international&m=saveNew", data, config);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if(response.data && response.data.body && response.data.body.id) {
                this.setState({
                    tabIndex: 0,
                });

                await this.loadPage(response);

                this.props.scrollTop(80);
                this.props.pageTitle('International - ' + response.data.body.id);
                const path = basePath + "/admin/intl/" + response.data.body.id;
                this.props.history.push(path);
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an unexpected error saving the international shipment!", { variant: "error" });
        }

        if(!_.isEmpty(this.state.imageFiles) && !copyShipAsNew) {
            this.handleUpload(this.state.imageFiles);
        }
    }

    async saveDraft(data) {
        const config = {};
        try {
            const response = await axios.post("/index.php?p=api&r=json&c=international&m=saveDraft", data, config);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            this.props.scrollTop(80);
            if(response.data && response.data.body && response.data.body.id) {
                await this.loadPage(response);
                const path = basePath + "/admin/intl/" + response.data.body.id;
                this.props.history.push(path);
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an unexpected error saving the international shipment!", { variant: "error" });
        }
    }

    async updatePostPickup() {
        const config = {};
        const payload = this.buildPayload();
        try {
            const response = await axios.post(`/index.php?p=api&r=json&c=international&m=savePostPickupDetails&id=${this.state.intl_ship_id}`, payload.postPickup, config);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an unexpected error updating Post Pickup Details information!", { variant: "error" });
        }
    }

    async beginExecution(sendToTfm) {
        const config = {};
        let data = this.buildPayload();
        const valid = this.validatePayload(data);
        if(valid) {
            if(sendToTfm) {
                data['sendToTfm'] = true;
                data['container_types'] = Object.values(this.state.container_types);
                data['container_sizes'] = Object.values(this.state.container_sizes);
                data['service_types'] = Object.values(this.state.service_types);
            }
            try {
                const response = await axios.post("/index.php?p=api&r=json&c=international&m=execute", data, config);
                if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                this.props.scrollTop(80);
                if(response.data && response.data.body && response.data.body.id) {
                    await this.loadPage(response);
                    const path = basePath + "/admin/intl/" + response.data.body.id;

                    if(response.data.body.email_failed) {
                        this.handleEmailError(path);
                    } else {
                        this.props.history.push(path);
                    }
                }
            } catch (error) {
                console.error(error);
                this.props.setNotification("There was an unexpected error executing the International Shipment!", { variant: "error" });
            }
        }
    }

    handleEmailError(path) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="ERROR SUBMITTING INTERNATIONAL SHIPMENT FOR REVIEW"
                    onConfirm={() => {
                        this.setState({ alert: null });
                        this.props.history.push(path);
                    }}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    confirmBtnText="OK"
                >
                   There was an error submitting this shipment to TMS International for review. Please contact Customer Service to ensure the shipment is processed. customerservice@targetfmi.com
                </SweetAlert>
            )
        });
    }

    validatePayload(data) {
        const { general, locations, units, containers, transit } = data;
        const { po_num, so_num, ref_num, master_bill_num, house_bill_num, airway_bill_num, isf_num } = general;
        const {require_po, require_so, require_ref, require_master_bill, require_house_bill, require_airway_bill, require_isf, shipping_mode, container_load } = this.state;
        let success = true;

        if(require_po && !po_num.trim().length) {
            this.props.setNotification("PO # is required for your company or warehouse.", { variant: "error" });
            return false;
        }
        if(require_so && !so_num.trim().length) {
            this.props.setNotification("Order/SO # is required for your company or warehouse.", { variant: "error" });
            return false;
        }
        if(require_ref && !ref_num.trim().length) {
            this.props.setNotification("Reference # is required for your company or warehouse.", { variant: "error" });
            return false;
        }
        if(require_master_bill && !master_bill_num.trim().length) {
            this.props.setNotification("Master Bill # is required for your company.", { variant: "error" });
            return false;
        }
        if(require_house_bill && !house_bill_num.trim().length) {
            this.props.setNotification("House Bill # is required for your company.", { variant: "error" });
            return false;
        }
        if(require_airway_bill && !airway_bill_num.trim().length) {
            this.props.setNotification("Airway Bill # is required for your company.", { variant: "error" });
            return false;
        }
        if(require_isf && !isf_num.trim().length) {
            this.props.setNotification("ISF/ITN # is required for your company.", { variant: "error" });
            return false;
        }
        // check required import/export information
        if(general.direction == "import") {
           if(!general.cb_name.trim().length || !general.cb_phone.trim().length || !general.cb_email.trim().length) {
                this.props.setNotification("Customs Broker Information is required for Imported Shipments.", { variant: "error" });
                return false;
           }
        }

        // require inco term
        if(!general.inco_term.length) {
            this.props.setNotification("Inco Terms are required.", { variant: "error" });
            return false;
        }

        // check required dates
        if(!transit.freight_ready_date.trim().length) {
            this.props.setNotification("Freight Ready Date is required.", { variant: "error" });
            return false;
        }

        if(general.direction == "export") {
            if(!transit.ready_time.trim().length) {
                this.props.setNotification("Ready Time is required for Exported shipments.", { variant: "error" });
                return false;
            }

            if(!transit.close_time.trim().length) {
                this.props.setNotification("Close Time is required for Exported shipments.", { variant: "error" });
                return false;
            }
        }


        // Check required shipping details
        // Shipper
        Object.keys(locations.shipper).forEach((key) => {
            if(!locations.shipper[key].trim().length && key != "addr2" && key != "contact_fax") {
                this.props.setNotification(`Shipper Shipment Details Information "${key}" is required.`, { variant: "error" });
                success = false;
                return;
            }
        });

        // Consignee
        Object.keys(locations.consignee).forEach((key) => {
            if(!locations.consignee[key].trim().length && key != "addr2" && key != "contact_fax") {
                this.props.setNotification(`Consignee Shipment Details Information "${key}" is required.`, { variant: "error" });
                success = false;
                return;
            }
        });

        // Custom Refs
        if(!_.isEmpty(general.references)) {
            general.references.forEach(ref => {
                if(ref.required == '1' && !ref.value.trim().length) {
                    this.props.setNotification(`Custom Reference ${ref.type} is required.`, { variant: "error" });
                    success = false;
                    return;
                }
            })
        }

        // Products
        if(shipping_mode == "ocean") {
            containers.forEach((container, idx) => {
                if(container_load == "FCL") {
                    if(!container.container_size.length || !container.container_type.length) {
                        this.props.enqueueSnackbar(`Container Size and Type is required for FCL shipments.`, { variant: "error" });
                        success = false;
                        return;
                    }
                }
                if(success) {
                    success = this.validateUnitData(container.units);
                    if(!success) {
                        this.props.setNotification(`Invalid product information provided for container # ${idx + 1} .`, { variant: "error" });
                        return;
                    }
                }
            })
        } else {
            success = this.validateUnitData(units);
        }

        return success;
    }

    validateUnitData(units) {
        let success = true;
        if (_.isEmpty(units)) {
            this.props.setNotification("Handling Units are required to execute this shipment.", { variant: "error" });
            return false;
        } else {
            units.forEach(unit => {
                if(isNaN(unit.details.length) || unit.details.length <= 0 || isNaN(unit.details.width) || unit.details.width <= 0 || isNaN(unit.details.height) || unit.details.height <= 0) {
                    this.props.setNotification("Handling Units dimension's cannot be empty.", { variant: "error" });
                    success = false;
                    return;
                }
                if(_.isEmpty(unit.products)) {
                    this.props.setNotification("Handling Units must have products.", { variant: "error" });
                    success = false;
                    return;
                }

                unit.products.forEach(prod => {
                    if(!success) {
                        return;
                    }
                    if(!prod.hs_code.trim().length) {
                        this.props.setNotification("A Product HS Code is required.", { variant: "error" });
                        success = false;
                        return;
                    } else if(!prod.product.trim().length) {
                        this.props.setNotification("A Product Description is required.", { variant: "error" });
                        success = false;
                        return;
                    } else if(!prod.nmfc.trim().length && this.state.direction == "export") {
                        this.props.setNotification("A product NMFC Number is required for export shipments.", { variant: "error" });
                        success = false;
                        return;
                    } else if(isNaN(prod.pieces) || prod.pieces <= 0 || !prod.pieces.length) {
                        this.props.setNotification("Invalid amount for Product Quantity.", { variant: "error" });
                        success = false;
                        return;
                    } else if(isNaN(prod.weight) || prod.weight <= 0 || !prod.weight.length) {
                        this.props.setNotification("Invalid amount for Product Weight.", { variant: "error" });
                        success = false;
                        return;
                    } else if(prod.full_value_coverage == 1 && (isNaN(prod.declared_value) || prod.declared_value <= 0)) {
                        this.props.setNotification("Product Declared Value is required when Full Value Coverage is enabled.", { variant: "error" });
                        success = false;
                        return;
                    } else if(prod.hazardous == 1) {
                        if(!prod.haz_class.trim().length) {
                            this.props.setNotification("Hazardous Product Class required.", { variant: "error" });
                            success = false;
                            return;
                        } else if(!prod.un_num.trim().length) {
                            this.props.setNotification("Hazardous Product UN # required.", { variant: "error" });
                            success = false;
                            return;
                        } else if(!prod.emergency.trim().length) {
                            this.props.setNotification("Hazardous Product Emergency # required.", { variant: "error" });
                            success = false;
                            return;
                        } else if(!prod.packing_inst.trim().length) {
                            this.props.setNotification("Hazardous Product Packing Instructions required.", { variant: "error" });
                            success = false;
                            return;
                        }
                    }
                })
            })

            return success;
        }
    }

    buildPayload() {
        const data = {};
        const {state} = this;
        data.general = {
            intl_ship_id: state.intl_ship_id,
            status: state.status,
            warehouse: state.warehouse,
            intl_carrier: state.intl_carrier,
            intl_carrier_rate: state.intl_carrier_rate,
            intl_carrier_duties: state.intl_carrier_duties,
            provider_id: state.provider_id,
            master_bill_num: state.master_bill_num,
            house_bill_num: state.house_bill_num,
            airway_bill_num: state.airway_bill_num,
            isf_num: state.isf_num,
            po_num: state.po_num,
            so_num: state.so_num,
            ref_num: state.ref_num,
            customs_entry_num: state.customs_entry_num,
            custom_date: !_.isEmpty(state.custom_date) ? moment(this.custom_date).format("YYYY-MM-DD") : "",
            direction: state.direction,
            cb_name: state.cb_name,
            cb_phone: state.cb_phone,
            cb_email: state.cb_email,
            shipping_mode: state.shipping_mode,
            container_load: state.container_load,
            service_type: state.service_type,
            container_size: state.container_size,
            container_type: state.container_type,
            inco_term: state.inco_term,
            named_place: state.named_place,
            uom_option: state.uom_opt,
            total_declared_value: state.total_declared_value,
            references: state.references,
            custom_pallet_count: state.custom_pallet_count,
            custom_piece_count: state.custom_piece_count,
            custom_loose_count: state.custom_loose_count,
            custom_overall_piece_qty: state.custom_overall_piece_qty,
            custom_unit_master_id: state.custom_unit_master_id,
            intl_ship_level_id: state.level,
        };

        data.locations = {};

        data.locations.shipper = {
            name: state.shipper_name,
            addr1: state.shipper_address1,
            addr2: state.shipper_address2,
            city: state.shipper_city,
            state: state.shipper_state,
            zip: state.shipper_zip,
            country: state.shipper_country,
            contact_name: state.shipper_contact_name,
            contact_phone: state.shipper_contact_phone,
            contact_email: state.shipper_contact_email,
            contact_fax: state.shipper_contact_fax,

        };

        data.locations.consignee = {
            name: state.consignee_name,
            addr1: state.consignee_address1,
            addr2: state.consignee_address2,
            city: state.consignee_city,
            state: state.consignee_state,
            zip: state.consignee_zip,
            country: state.consignee_country,
            contact_name: state.consignee_contact_name,
            contact_phone: state.consignee_contact_phone,
            contact_email: state.consignee_contact_email,
            contact_fax: state.consignee_contact_fax,

        };

        data.special = {
            notes: state.notes
        }

        data.transit = {
            transit_status: state.transit_status,
            transit_sub_status: state.transit_sub_status,
            freight_ready_date: !_.isEmpty(state.freight_ready_date) ? moment(state.freight_ready_date).format("YYYY-MM-DD") : "",
            ready_time: state.ready_time,
            close_time: state.close_time,
            intl_carrier: state.intl_carrier,
            intl_carrier_rate: state.intl_carrier_rate,
        }

        data.postPickup = {
            vehicle_name: state.vehicle_name,
            vehicle_num: state.vehicle_num,
            container_num: state.container_num,
            seal_num: state.seal_num,
            origin_port: state.origin_port,
            dest_port: state.dest_port,
            booking_num: state.booking_num,
            cargo_ready_date: state.cargo_ready_date,
            actual_pu_date: state.actual_pu_date,
            doc_cutoff_origin_date: state.doc_cutoff_origin_date,
            cargo_cutoff_origin_date: state.cargo_cutoff_origin_date,
            actual_receipt_origin_date: state.actual_receipt_origin_date,
            etd_depart_origin_date: state.etd_depart_origin_date,
            cob_depart_origin_date: state.cob_depart_origin_date,
            eta_arrival_dest_date: state.eta_arrival_dest_date,
            actual_arrival_dest_date: state.actual_arrival_dest_date,
            eta_final_dest_date: state.eta_final_dest_date,
            actual_final_dest_date: state.actual_final_dest_date,
            last_free_day: state.last_free_day,
            eta_door_date: state.eta_door_date,
            actual_door_date: state.actual_door_date,
            post_pallet_count: state.post_pallet_count,
            post_total_weight: state.post_total_weight,
            post_weight_type: state.post_weight_type,
            post_air_weight: state.post_air_weight,
            post_air_weight_type: state.post_air_weight_type,
            container_data: state.containers.map((prop) => { return { id: prop.id, container_num: prop.container_num, seal_num: prop.seal_num}})
        }

        data.units = [];
        data.containers = [];
        if(state.shipping_mode == 'ocean') {
            if (!_.isEmpty(state.containers)) {
                data.containers = state.containers.map((prop) => {
                    return {
                        id: prop.id,
                        container_num: prop.container_num,
                        seal_num: prop.seal_num,
                        container_size: prop.container_size,
                        container_type: prop.container_type,
                        custom_pallet_count: prop.custom_pallet_count,
                        custom_piece_count: prop.custom_piece_count,
                        custom_loose_count: prop.custom_loose_count,
                        custom_unit_master_id: prop.custom_unit_master_id,
                        custom_overall_piece_qty: prop.custom_overall_piece_qty,
                        units: this.setUnitReqData(prop.units)
                    }
                })
            }
        }else{
            if (!_.isEmpty(state.units)) {
                data.units = this.setUnitReqData(state.units);
            }
        }

        data.totals = state.totals;
        return data;
    }

    setUnitReqData(units) {
        return units.map((prop, key) => {
            let { length, width, height } = prop;
            const unit = {};
            let unit_weight = 0;

            unit.products = prop.products.map((product, key) => {
                unit_weight += parseFloat(product.weight, 10);

                return {
                    id: product.id,
                    product: product.description,
                    pieces: product.pieces,
                    hs_code: product.hs_code,
                    uom: product.uom,
                    nmfc: product.nmfc,
                    class: product.class,
                    weight: product.weight,
                    weight_uom: product.weight_uom,
                    full_value_coverage: product.full_value_coverage ? 1 : 0,
                    part_no: product.part_no,
                    overall_piece_qty: product.overall_piece_qty,
                    declared_value: product.declared_value,
                    hazardous: product.hazardous ? 1 : 0 ,
                    haz_class: product.hazmat.class,
                    un_num: product.hazmat.un,
                    group: product.hazmat.packingGroup,
                    packing_inst: product.hazmat.packingInstructions,
                    emergency: product.hazmat.emergencyNumber,
                    overpack_used: product.hazmat.overpackUsed,

                };
            });

            length = parseFloat(length);
            width = parseFloat(width);
            height = parseFloat(height);

            unit.details = {
                id: prop.id,
                pieces: prop.pieces,
                stack: prop.stackable ? 1 : 0,
                type: prop.type,
                length: length,
                length_uom: prop.length_uom,
                width: width,
                width_uom: prop.width_uom,
                height: height,
                height_uom: prop.height_uom,
                weight: unit_weight * parseInt(prop.pieces, 10)
            };

            return unit;
        });
    }

    setUnitResData(unitData) {
        let units = [];
        for (const resUnit of unitData) {
            const unit = {
                id: resUnit.id,
                type: resUnit.type,
                pieces: resUnit.pieces,
                stackable: resUnit.stack == '1' ? true : false,
                length: resUnit.length,
                length_uom: resUnit.length_uom,
                width: resUnit.width,
                width_uom: resUnit.width_uom,
                height: resUnit.height,
                height_uom: resUnit.height_uom,
                cube: "",
                products: []
            };

            if (!_.isEmpty(resUnit.products)) {
                for (const unitProduct of resUnit.products) {
                    const product = {
                        id: unitProduct.id,
                        nmfc: unitProduct.nmfc,
                        hs_code: unitProduct.hs_code,
                        class: unitProduct.class,
                        description: unitProduct.name,
                        uom: unitProduct.uom,
                        pieces: unitProduct.pieces,
                        weight: unitProduct.weight,
                        weight_uom: unitProduct.weight_uom,
                        declared_value: unitProduct.declared_value || "",
                        hazardous: unitProduct.hazmat == '1' ? true : false,
                        auto_weight: false,
                        full_value_coverage: unitProduct.full_value_coverage == '1' ? true : false,
                        part_no: unitProduct.part_no,
                        overall_piece_qty: unitProduct.overall_piece_qty,
                        hazmat: {
                            class: unitProduct.haz_class,
                            un: unitProduct.un_num,
                            packingGroup: unitProduct.group,
                            emergencyNumber: unitProduct.emergency,
                            packingInstructions: unitProduct.packing_inst,
                            overpackUsed: unitProduct.overpack_used == '1' ? true : false
                        }
                    };
                    unit.products.push(product);
                }
            }
            units.push(unit);
        }

        return units;
    }

    handleLocationNotes(contact) {
        var internalNotes = {};
        if(contact.internalNoteCount === 1) {
            if(contact.shipper.internal_note) {
                this.openInternalNoteAlert(contact.shipper.internal_note);
            } else if(contact.consignee.internal_note) {
                this.openInternalNoteAlert(contact.consignee.internal_note);
            }
        } else if(contact.internalNoteCount > 1){
            internalNotes.shipper = [contact.shipper.name, contact.shipper.internal_note];
            internalNotes.consignee = [contact.consignee.name, contact.consignee.internal_note];
            this.openInternalNoteAlert(internalNotes);
        } else {
            this.openInternalNoteAlert(contact.internal_note);
        }

        var externalNote = this.state.notes;
        if(contact.external_notes) { // if multiple notes (if there are multiple default locations with external notes)
            contact.external_notes.map((ext) => {
                if(!this.state.notes.includes(ext)) {
                    externalNote +=  " - " + ext;
                }
            })
            this.setState({ notes: externalNote })
        } else { // if single note (user selected an address with an external note from the contact search)
            if(contact.external_note) {
                if(!this.state.notes.includes(contact.external_note)) {
                    externalNote += " - " + contact.external_note
                }
                this.setState({ notes: externalNote })
            }
        }
    }

    openInternalNoteAlert(notes) {
        var noteItems;
        if(notes === undefined || notes === '') return false;
        if(notes.shipper || notes.consignee) {
            var notesArray = Object.entries(notes)
            noteItems = notesArray.filter(function(elem) { // filter out empty values
                if(elem[1][0] === '' && elem[1][1] === '') {
                    return false;
                }
                return true;
            }).map((note) => { // map each note to an accordion element
                var loc = note[1];
                var name = loc[0];
                var note = loc[1];
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {note}
                        </AccordionDetails>
                    </Accordion>
                )
            });
            this.setState({
                alert: (
                    <SweetAlert
                        style={{
                            display: "block",
                            color: "black"
                        }}
                        title="Location Note"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                        confirmBtnText="Okay"
                    >
                        {noteItems}
                    </SweetAlert>
                )
            })
        } else {
            this.setState({
                alert: (
                    <SweetAlert
                        style={{
                            display: "block",
                            color: "black"
                        }}
                        title="Location Note"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                        confirmBtnText="Okay"
                    >
                        <Typography variant="body1">
                            {notes}
                        </Typography>
                    </SweetAlert>
                )
            })
        }
    }

    async handleContactSearch(type) {
        let url = "";
        let searchType = "";

        const { warehouse } = this.state;
        const searchBy = this.state[type + "_search_by"];
        const searchFor = this.state[type + "_search_for"];

        switch (type) {
            case "shipper":
                searchType = "shipper";
                break;
            case "consignee":
                searchType = "consignee";
                break;
        }

        if (searchBy == "SEARCH_ANY") {
            url = "/index.php?p=api&r=json&c=location&m=findAnything&d=" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
        } else {
            url = "/index.php?p=api&r=json&c=location&m=find&d=" + searchBy + "/" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
        }

        try {
            const response = await axios.get(url);

            if (typeof response.data !== "string") {
                if (response.data.body.length == 1) {
					const contact = response.data.body[0];
                    this.clearCarriersAndSetState({
                        [type + "_name"]: contact.location_name || "",
                        [type + "_address1"]: contact.address1 || "",
                        [type + "_address2"]: contact.address2 || "",
                        [type + "_zip"]: contact.zip || "",
                        [type + "_city"]: contact.city || "",
                        [type + "_state"]: contact.state || "",
                        [type + "_country"]: contact.country || "US",
                        [type + "_contact_name"]: contact.contact_name || "",
                        [type + "_contact_phone"]: contact.phone || "",
                        [type + "_contact_email"]: contact.email || "",
                        [type + "_contact_fax"]: contact.fax || "",
                        contactSearchModal: false,
                        contacts: [],
                        contactSearchType: "",
                    }, () => {});
                } else if (response.data.body.length > 1) {
                    this.setState({
                        contacts: response.data.body,
                        contactSearchType: type,
                        contactSearchModal: true
                    });
                } else {
                    this.props.setNotification("No contacts found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching contacts!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching contacts!", {
                variant: "error"
            });
        }
    }

    handleContactSelect(contactKey, type) {
        const { contacts } = this.state;
        const contact = {};
        Object.keys(contacts[contactKey]).map(key => {
            const prop = contacts[contactKey][key];
            if (prop) {
                if(!Array.isArray(prop)) {
                    contact[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
                } else {
                    contact[key] = prop;
                }
            }
        });

        this.handleLocationNotes(contact);

        this.clearCarriersAndSetState({
            [type + "_name"]: contact.location_name || '',
            [type + "_address1"]: contact.address1 || '',
            [type + "_address2"]: contact.address2 || '',
            [type + "_zip"]: contact.zip || '',
            [type + "_city"]: contact.city || '',
            [type + "_state"]: contact.state || '',
            [type + "_country"]: contact.country || '',
            [type + "_contact_name"]: contact.contact_name || '',
            [type + "_contact_phone"]: contact.phone || '',
            [type + "_contact_email"]: contact.email || '',
            [type + "_contact_fax"]: contact.fax || '',
            contactSearchModal: false,
            contacts: [],
            contactSearchType: "",
        }, () => {});
    }

    async setDefaultProduct(prod) {
        let { units, default_weight_type, containers, shipping_mode } = this.state;
        units = [
            {
                id: null,
                type: "Pallet",
                pieces: "1",
                stackable: false,
                length: "",
                length_uom: "in",
                width: "",
                width_uom: "in",
                height: "",
                height_uom: "in",
                cube: "",
                products: [
                    {
                        nmfc: prod.nmfc || "",
                        hs_code: prod.hs_code || "",
                        class: prod.class || "",
                        description: prod.name || "",
                        uom: prod.code || "",
                        pieces: prod.pieces || "",
                        weight: prod.weight || "",
                        weight_uom: default_weight_type,
                        declared_value: prod.declared_value || "",
                        hazardous: !_.isEmpty(prod.haz_class) ? true : false,
                        auto_weight: false,
                        full_value_coverage: false,
                        part_no: prod.part_no || "",
                        overall_piece_qty: prod.overall_piece_qty || "",
                        hazmat: {
                            class: prod.haz_class || "",
                            un: prod.un_num || "",
                            packingGroup: prod.group || "",
                            emergencyNumber: prod.emergency || "",
                            packingInstructions: prod.packing_inst || "",
                            overpackUsed: false
                        }
                    }
                ]
            }
        ];

        if(shipping_mode === 'ocean') {
            let idx = containers.length - 1;
            containers[idx].units = units;
            this.setState({
                containers,
                classification: false
            }, () => this.updateTotals());
        } else {
            this.setState({
                units,
                classification: false
            }, () => this.updateTotals());
        }

    }

    addProduct(i) {
        let { units, default_weight_type, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === 'ocean' ? containers[containerTabIndex].units : units;

        if (unitsData.length) {
            unitsData[i].products.push({
                nmfc: "",
                hs_code: "",
                class: "50",
                weight: "",
                weight_uom: default_weight_type,
                uom: unitsData[i].type,
                pieces: 1,
                description: "",
                declared_value: "",
                hazardous: false,
                auto_weight: false,
                full_value_coverage: false,
                part_no: "",
                overall_piece_qty: "",
                hazmat: {
                    class: "",
                    un: "",
                    packingGroup: "",
                    emergencyNumber: "",
                    packingInstructions: "",
                    overpackUsed: false
                }
            });

            if(shipping_mode === 'ocean') {
                containers[containerTabIndex].units = unitsData;
                this.setState({
                    containers,
                    classification: false
                }, () => this.updateTotals());
            } else {
                units = unitsData;
                this.setState({
                    units,
                    classification: false
                }, () => this.updateTotals());
            }
        }
    }

    removeProduct(i, j) {
        let { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === 'ocean' ? containers[containerTabIndex].units : units;

        if (unitsData.length && !_.isEmpty(unitsData[i]) && !_.isEmpty(unitsData[i].products[j])) {
            unitsData[i].products.splice(j, 1);

            const productIndex = j + 1;
            const unitIndex = i + 1;
            const contIndex = containerTabIndex + 1;

            if(shipping_mode === 'ocean') {
                containers[containerTabIndex].units = unitsData;
                this.setState({
                    containers,
                    classification: false
                }, () => this.updateTotals());
                this.props.setNotification(`Product #${productIndex} has been removed from Unit #${unitIndex}, Container #${contIndex}.`, { variant: "info" });
            } else {
                units = unitsData;
                this.setState({
                    units,
                    classification: false
                }, () => this.updateTotals());
                this.props.setNotification(`Product #${productIndex} has been removed from Unit #${unitIndex}.`, { variant: "info" });
            }

        }
    }

    async clearProduct(i, j) {
        let { units, default_weight_type, containers, containerTabIndex, shipping_mode } = this.state;
        let unitsData = shipping_mode === 'ocean' ? containers[containerTabIndex].units : units;
        if (!_.isEmpty(unitsData)) {
            const unit = unitsData[i];
            if (!_.isEmpty(unit.products)) {
                const product = {
                    nmfc: "",
                    hs_code: "",
                    class: "",
                    weight: "",
                    weight_uom: default_weight_type,
                    uom: unitsData[i].type,
                    pieces: 1,
                    description: "",
                    declared_value: "",
                    hazardous: false,
                    auto_weight: false,
                    full_value_coverage: false,
                    part_no: "",
                    overall_piece_qty: "",
                    hazmat: {
                        class: "",
                        un: "",
                        packingGroup: "",
                        emergencyNumber: "",
                        packingInstructions: "",
                        overpackUsed: false
                    }
                };
                unit.products[j] = product;
                unitsData[i] = unit;
                if(shipping_mode === "ocean") {
                    containers[containerTabIndex].units = unitsData;
                    await this.clearCarriersAndSetState({
                        containers,
                        classification: false,
                    });
                } else {
                    units = unitsData;
                    await this.clearCarriersAndSetState({
                        units,
                        classification: false,
                    });
                }

                this.updateTotals();
            }
        }
    }

    async handleProduct(i, j, name, event) {
        let { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === 'ocean' ? containers[containerTabIndex].units : units;
        if (unitsData.length) {
            const unit = unitsData[i];
            if (unit.products.length) {
                const product = unit.products[j];
                if (name === "auto_weight" || name === "full_value_coverage") {
                    product[name] = !product[name];
                } else {
                    product[name] = event.target.value;
                }
                unit.products[j] = product;
                unitsData[i] = unit;
                if(shipping_mode === "ocean") {
                    containers[containerTabIndex].units = unitsData;
                    await this.clearCarriersAndSetState({
                        containers,
                        classification: false,
                    });
                } else {
                    units = unitsData;
                    await this.clearCarriersAndSetState({
                        units,
                        classification: false,
                    });
                }
                this.updateTotals();
            }
        }
    }

    addUnit() {
        const { units, default_length_type, default_weight_type, containers, containerTabIndex, shipping_mode, uom_opt } = this.state;
        const newUnit = {
            type: "Pallet",
            pieces: 1,
            stackable: false,
            length: "",
            length_uom: uom_opt == "metric" ? "cm" :  default_length_type,
            width: "",
            width_uom: uom_opt == "metric" ? "cm" :  default_length_type,
            height: "",
            height_uom: uom_opt == "metric" ? "cm" :  default_length_type,
            density: "",
            cube: "",
            products: [
                {
                    nmfc: "",
                    hs_code: "",
                    class: "50",
                    description: "",
                    uom: "Piece",
                    pieces: "1",
                    weight: "",
                    weight_uom: uom_opt == "metric" ? "kg" :  default_weight_type,
                    declared_value: "",
                    hazardous: false,
                    auto_weight: false,
                    full_value_coverage: false,
                    part_no: "",
                    overall_piece_qty: "",
                    hazmat: {
                        class: "",
                        un: "",
                        packingGroup: "",
                        emergencyNumber: "",
                        packingInstructions: "",
                        overpackUsed: false
                    },
                    errors: []
                }
            ],
            errors: []
        };

        if(shipping_mode === 'ocean') {
            containers[containerTabIndex].units.push(newUnit);
            this.setState({
                containers,
                classification: false
            }, () => this.updateTotals());
        } else {
            units.push(newUnit);
            this.setState({
                units,
                classification: false
            }, () => this.updateTotals());
        }
    }

    removeUnit(i) {
        let { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === 'ocean' ? containers[containerTabIndex].units : units;
        const unitNo = i + 1;
        const contNo = containerTabIndex + 1;

        if (unitsData.length) {
            unitsData.splice(i, 1);
            if(shipping_mode === 'ocean') {
                containers[containerTabIndex].units = unitsData;
                this.setState({
                    containers,
                    classification: false
                }, () => this.updateTotals());
                this.props.setNotification(`Unit #${unitNo} has been removed from Container # ${contNo}.`, { variant: "info" });
            } else {
                units = unitsData;
                this.setState({
                    units,
                    classification: false
                }, () => this.updateTotals());
                this.props.setNotification(`Unit #${unitNo} has been removed.`, { variant: "info" });
            }
        }

    }

    handleUnit(i, name, event) {
        let { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === "ocean" ? containers[containerTabIndex].units : units;

        if (unitsData.length) {
            const unit = unitsData[i];

            unit[name] = event.target.value;
            unitsData[i] = unit;

            if(shipping_mode === "ocean") {
                containers[containerTabIndex].units = unitsData;
                this.clearCarriersAndSetState({
                    containers,
                    classification: false,
                },
                    this.updateTotals()
                );
            } else {
                units = unitsData;
                this.clearCarriersAndSetState({
                    units,
                    classification: false,
                },
                    this.updateTotals()
                );
            }
        }
    }

    getUnitTypes(uom) {
        const { classes } = this.props;
        return uom.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.name}
                >
                    {prop.name}
                </MenuItem>
            );
        });
    }

    setExpanded = (i) => (event, isExpanded) => {
        this.setState({
            expanded: isExpanded ? `panel${i}` : false,
            containerTabIndex: i
        })
      };

      getMenuItems(options, value) {
        const classes = this.props.classes
        return options.map((option, key) => {
            return (
		    	<MenuItem
	            	key={`${value}_${key}`}
		            classes={{
		                root: classes.selectMenuItem,
		                selected: classes.selectMenuItemSelected
		            }}
		            value={option.name}
		        >
		            {option.label}
		        </MenuItem>
            );
        });
    }

    buildContainerTab() {
        const { containers, containerTabIndex, container_sizes, container_types, status } = this.state;
        const { classes } = this.props;
        const executed = !_.isEmpty(status) && status != 'hold';

        return containers.map((cont, i) => {
            return (
                <GridItem key={i} xs={12} lg={2} style={{padding:"0", margin:"0"}}>
                    <Accordion
                        expanded={this.state.expanded === `panel${i}`}
                        onChange={this.setExpanded(i)}
                        style={{margin:"5px"}}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={containerTabIndex === i ? classes.unitHeader : classes.unit}>
                            <Typography variant="subtitle1"><strong>CONTAINER #{i +1}</strong></Typography>
                        </AccordionSummary>
                        <Typography variant="body2" style={{padding:"5px"}}>
                            <div>
                                <strong>Size: </strong>
                                <Select
                                    MenuProps={{ className: classes.selectMenu }}
                                    displayEmpty
                                    classes={{ select: `${classes.select} ${this.state.container_load == "FCL" ? classes.requiredSelect : ""}` }}
                                    value={cont.container_size || ""}
                                    inputProps={{
                                        name: 'container_size',
                                        id: cont.container_size
                                    }}
                                    onChange={e => this.handleContainer(i, 'container_size', e)}
                                >
                                    {this.getMenuItems(container_sizes, 'container_size')}
                                </Select>
                            </div>
                            <br/>
                            <div>
                                <strong>Type: </strong>
                                <Select
                                    MenuProps={{ className: classes.selectMenu }}
                                    displayEmpty
                                    classes={{ select: `${classes.select} ${this.state.container_load == "FCL" ? classes.requiredSelect : ""}` }}
                                    value={cont.container_type || ""}
                                    inputProps={{
                                        name: 'container_type',
                                        id: cont.container_type
                                    }}
                                    onChange={e => this.handleContainer(i, 'container_type', e)}
                                >
                                    {this.getMenuItems(container_types, 'container_type')}
                                </Select>
                            </div>
                            <br/>
                            <div>
                                <strong>Container#: </strong>{cont.container_num}
                            </div>
                            <br/>
                            <div>
                                <strong>Seal#: </strong>{cont.seal_num}
                            </div>
                            {!executed && containers.length > 1 && (
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    <WithTooltip
                                        title="Remove Container"
                                    >
                                        <Button style={{margin: "5px"}} color="danger" size="sm" justIcon simple onClick={e => this.removeContainer(i)}>
                                            <Delete/>
                                        </Button>
                                    </WithTooltip>
                                </div>
                            )}
                        </Typography>
                    </Accordion>
                </GridItem>
            );
        });
    }

    handleContainer(i, name, event) {
        const { containers } = this.state;
        if(containers.length) {
            const container = containers[i];
            if(name === "custom_unit_master_name") {
                this.state.body.uom.forEach($uom => {
                    if ($uom["name"] === event.target.value ) {
                        container['custom_unit_master_id'] = $uom["id"];
                    }
                });
            }
            container[name] = event.target.value;
            containers[i] = container;
            this.setState({ containers }, () => this.updateTotals());
        }
    }

    async addContainer() {
        const { containers, defaultProduct } = this.state;
        const newContainer = {
            id: null,
            container_num: "",
            seal_num: "",
            container_size: "",
            container_type: "",
            custom_pallet_count: "",
            custom_piece_count: "",
            custom_loose_count: "",
            custom_unit_master_name: "Piece",
            custom_unit_master_id: "",
            custom_overall_piece_qty: "",
            units: []
        };

        containers.push(newContainer);
        this.setState({ containers }, async () => {
            if(!_.isEmpty(defaultProduct)) {
                await this.setDefaultProduct(defaultProduct);
            }
            this.updateTotals();
        });
    }

    removeContainer(i) {
        const { containers } = this.state;
        if (containers.length) {
            const index = i + 1;
            containers.splice(i, 1);
            this.setState({ containers, containerTabIndex: 0 }, () => this.updateTotals());
            this.props.setNotification(`Container #${index} has been removed.`, { variant: "info" });
        }
    }

    updateTotals() {
        const { units, containers, shipping_mode } = this.state;
        let allUnits = [];
        if(shipping_mode == 'ocean') {
            containers.forEach(container => {
                container.units.forEach(unit => {
                   allUnits.push(unit);
                })
            })
            this.handleTotals(allUnits);
        } else {
            this.handleTotals(units);
        }

    }

    async handleTotals(units) {
        const { uom_opt, containers, shipping_mode } = this.state;

        let totalUnits = 0;
        let totalWeight = 0;
        let totalCube = 0;
        let totalLNFT = 0;
        let totalLength = 0;
        let totalValue = 0;
        let totalOverallPiece = 0;

        let shipmentList = [];

        for (const i in units) {
            const unit = units[i];
            let weight = 0;
            let cube = 0;
            let { length, width, height, pieces } = unit;

            length = parseFloat(length);
            width = parseFloat(width);
            height = parseFloat(height);

            unit.products.forEach(product => {
                let prodWeight = parseFloat(product.weight);

                if (uom_opt == "imperial") {
                    if(product.weight_uom == "oz") {
                        prodWeight = prodWeight / 16;
                    }
                } else {
                    if(product.weight_uom == "g") {
                        prodWeight = prodWeight / 1000;
                    }
                }

                if (product.auto_weight) {
                    weight += prodWeight * product.pieces;
                } else {
                    weight += prodWeight;
                }

                if (!_.isEmpty(product.declared_value)) {
                    const quantity = parseFloat(_.trim(product.pieces));
                    const declared_value = parseFloat(_.trim(product.declared_value));
                    totalValue += declared_value <= 0 ? 0 : declared_value * quantity * pieces;
                }

                totalOverallPiece += isNaN(parseInt(product.overall_piece_qty)) ? 0 : parseInt(product.overall_piece_qty);
            });

            totalWeight += weight * pieces;

            shipmentList.push(YatClient.formatShipment(unit));

            // convert everything to feet
            if(uom_opt == "imperial") {
                if(unit.length_uom == "in") {
                    length = length / 12;
                } else if(unit.length_uom == "yd") {
                    length = length * 3;
                } else {
                    length = length;
                }

                if(unit.width_uom == "in") {
                    width = width / 12;
                } else if(unit.width_uom == "yd") {
                    width = width * 3;
                } else {
                    width = width;
                }

                if(unit.height_uom == "in") {
                    height = height / 12;
                } else if(unit.height_uom == "yd") {
                    height = height * 3;
                } else {
                    height = height;
                }

            } else {

                if(unit.length_uom == "mm") {
                    length = length / 304.8;
                } else if(unit.length_uom == "cm") {
                    length = length / 30.48;
                } else {
                    length = length * 3.281;
                }

                if(unit.width_uom == "mm") {
                    width = width / 304.8;
                } else if(unit.width_uom == "cm") {
                    width = width / 30.48;
                } else {
                    width = width * 3.281;
                }

                if(unit.height_uom == "mm") {
                    height = height / 304.8;
                } else if(unit.height_uom == "cm") {
                    height = height / 30.48;
                } else {
                    height = height * 3.281;
                }
            }

            // SUM the longest sides
            totalLength += length > width ? length * pieces : width * pieces;

            // convert from feet to meters
            let lengthM = length / 3.281;
            let widthM = width / 3.281;
            let heightM = height / 3.281;

            //cubic meters
            cube = lengthM * widthM * heightM;

            totalCube += cube * pieces;
            totalUnits += isNaN(parseInt(pieces)) ? 1 : parseInt(pieces);

            unit.cube = cube.toFixed(2);
            units[i] = unit;
        }

        totalLNFT = totalLength;

        //if user entered a custom pallet count OR custom overall piece count, display custom count.
        if (this.state.display_custom_piece_count) {
            if(!isNaN(parseInt(this.state.custom_pallet_count))) {
                totalUnits = parseInt(this.state.custom_pallet_count);
            }

            if(!isNaN(parseInt(this.state.custom_overall_piece_qty))) {
                totalOverallPiece = parseInt(this.state.custom_overall_piece_qty);
            }

            if(shipping_mode == 'ocean') {
                let contCustomPal = 0;
                let contCustomOverAll = 0;

                containers.forEach(cont => {
                    if(!isNaN(parseInt(cont.custom_pallet_count))) {
                        contCustomPal += parseInt(cont.custom_pallet_count);
                    }
                    if(!isNaN(parseInt(cont.custom_overall_piece_qty))) {
                        contCustomOverAll += parseInt(cont.custom_overall_piece_qty);
                    }
                })

                if(contCustomPal > 0) {
                    totalUnits =  contCustomPal;
                }

                if(contCustomOverAll > 0) {
                    totalOverallPiece = contCustomOverAll;
                }
            }
        }

        const totals = {
            units: isNaN(totalUnits) || !isFinite(totalUnits) ? units.length : totalUnits,
            weight: isNaN(totalWeight) || !isFinite(totalWeight) ? 0.0 : totalWeight.toFixed(2),
            cubic_meters: isNaN(totalCube) || !isFinite(totalCube) ? 0.0 : totalCube.toFixed(2),
            linear_feet: isNaN(totalLNFT) || !isFinite(totalLNFT) ? 0.0 : totalLNFT.toFixed(2),
            value: isNaN(totalValue) || !isFinite(totalValue) ? 0.0 : totalValue.toFixed(2),
            overall_piece_qty: isNaN(totalOverallPiece) || !isFinite(totalOverallPiece) ? 0 : totalOverallPiece,
            containers: containers.length
        };

        this.setState({ totals, total_declared_value: totals.value });
    }

    toggleHazardous(i, j) {
        let { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === "ocean" ? containers[containerTabIndex].units : units;

        if (unitsData.length) {
            const product = unitsData[i].products[j];
            product.hazardous = !product.hazardous;
            unitsData[i].products[j] = product;

            if(shipping_mode === "ocean") {
                containers[containerTabIndex].units = unitsData;
                this.setState({ containers });
            } else {
                units = unitsData;
                this.setState({ units });
            }
        }
    }

    handleHazardous(i, j, name, event) {
        let { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === "ocean" ? containers[containerTabIndex].units : units;

        if (unitsData.length) {
            const unit = unitsData[i];
            if (unit.products.length) {
                const product = unit.products[j];
                if (name === "overpackUsed") {
                    product.hazmat[name] = !product.hazmat[name];
                } else {
                    product.hazmat[name] = event.target.value;
                }
                unit.products[j] = product;
                unitsData[i] = unit;

                if(shipping_mode === "ocean") {
                    containers[containerTabIndex].units = unitsData;
                    this.setState({ containers });
                } else {
                    units = unitsData;
                    this.setState({ units });
                }
            }
        }
    }

    validatePhoneNumber(phoneNumber) {
        const phoneRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d{1,6}))?/;
        if(phoneNumber == null || !phoneNumber.match(phoneRegex)) {
            this.props.setNotification("Invalid hazmat emergency contact. Please enter a valid phone number in a valid format (e.g. ###-###-#### ext.####)", { variant: "error" });
        } else {
            return true;
        }
    }

    async handleProductSearch(i, j) {
        let { warehouse, units, containers, containerTabIndex, shipping_mode } = this.state;
        const productData = shipping_mode === "ocean" ? containers[containerTabIndex].units[i].products[j] : units[i].products[j];
        const url = "/index.php?p=api&r=json&c=product&m=find&d=" + warehouse + "/" + productData.description;

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string") {

                if (response.data.body.length == 1 && response.data.body[0].is_parcel != 1) {
                    if(response.data.body[0].alert_message != null && response.data.body[0].alert_message.length > 0) {
                        this.setState({
                            units,
                            products: response.data.body,
                            productUnitKey: i,
                            productKey: j,
                        }, this.openAlert(response.data.body[0].alert_message, () => {this.handleProductSelect(response.data.body[0].id)}));

                    } else {
                        this.setState({
                            products: response.data.body,
                            productUnitKey: i,
                            productKey: j,
                        });
                        this.handleProductSelect(response.data.body[0].id);
                    }
                } else if (response.data.body.length > 1) {
                    this.setState({
                        products: response.data.body,
                        productUnitKey: i,
                        productKey: j,
                        productSearchModal: true
                    });
                } else {
                    this.props.setNotification("No products found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching products!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching products!", { variant: "error" });
        }
    }

    handleProductAlert(key, productId) {
        const { products } = this.state;
		if(products[key].alert_message != null && products[key].alert_message.length > 0) {
			this.openAlert(products[key].alert_message, () => {this.handleProductSelect(productId)});
		} else {
			this.handleProductSelect(productId);
		}
    }

    async handleProductSelect(productId) {
        const { warehouse, productUnitKey, productKey, shipping_mode, containerTabIndex } = this.state;
        const url = "/index.php?p=api&r=json&c=product&m=load&d=" + warehouse + "/" + productId;

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string") {
                if(!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                const unitsData = shipping_mode === "ocean" ? _.cloneDeep(this.state.containers) : _.cloneDeep(this.state.units);
                const productSelect = {};
                Object.keys(response.data.body).map(key => {
                    const prop = response.data.body[key];
                    if (prop) {
                        productSelect[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
                    } else {
                        productSelect[key] = "";
                    }
                });

                const product = shipping_mode === "ocean" ? unitsData[containerTabIndex].units[productUnitKey].products[productKey] : unitsData[productUnitKey].products[productKey];

                product.nmfc = productSelect.nmfc;
                product.class = productSelect.class;
                product.description = productSelect.name;
                product.weight = productSelect.weight;
                product.declared_value = productSelect.declared_value;
                product.hs_code = productSelect.hs_code;
                product.uom = productSelect.unit;
                product.hazardous = false;
                product.hazmat.overpackUsed = false;
                product.hazmat.class = "";
                product.hazmat.un = "";
                product.hazmat.packingGroup = "";
                product.hazmat.emergencyNumber = "";
                product.hazmat.packingInstructions = "";

                if (productSelect.haz_class.length > 0 ||
                    productSelect.un_num.length > 0 ||
                    productSelect.group.length > 0 ||
                    productSelect.emergency.length > 0 ||
                    productSelect.packing_inst.length > 0) {

                    product.hazardous = true;
                    product.hazmat.class = productSelect.haz_class;
                    product.hazmat.un = productSelect.un_num;
                    product.hazmat.packingGroup = productSelect.group;
                    product.hazmat.emergencyNumber = productSelect.emergency;
                    product.hazmat.packingInstructions = productSelect.packing_inst;

                }

                if(shipping_mode === "ocean") {
                    unitsData[containerTabIndex].units[productUnitKey].products[productKey] = product;
                    const containers = unitsData;
                    await this.clearCarriersAndSetState({
                        containers,
                        products: [],
                        productUnitKey: "",
                        productKey: "",
                        productSearchModal: false,
                        classification: false,
                    });

                } else {
                    unitsData[productUnitKey].products[productKey] = product;
                    const units = unitsData;
                    await this.clearCarriersAndSetState({
                        units,
                        products: [],
                        productUnitKey: "",
                        productKey: "",
                        productSearchModal: false,
                        classification: false,

                    });
                }
                this.updateTotals();
            } else {
                this.props.setNotification("There was an error loading the product!", {variant: "error"});
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an exception loading the product!", { variant: "error" });
        }
    }

    getProducts(products) {
        return products.map((product, key) => {
            let row = [
                <div dangerouslySetInnerHTML={{ __html: product.code }} />,
                <div dangerouslySetInnerHTML={{ __html: product.name }} />,
                <div dangerouslySetInnerHTML={{ __html: product.nmfc }} />,
                <div dangerouslySetInnerHTML={{ __html: product.hs_code }} />,
                <div dangerouslySetInnerHTML={{ __html: product.pClass }} />,
                <div dangerouslySetInnerHTML={{ __html: product.weight }} />,
                <div dangerouslySetInnerHTML={{ __html: product.hazmat }} />,
                <div className="actions-right">
                    <Button size="sm" color="linkedin" onClick={() => this.handleProductAlert(key, product.id)}>
                        Select
                    </Button>
                </div>
            ];
            return  product.is_parcel == 0 ? row : [];
        });
    }

    async saveProduct(i, j) {
        const { units, containers, containerTabIndex, shipping_mode } = this.state;
        const unitsData = shipping_mode === "ocean" ? containers[containerTabIndex].units : units;
        const unit = unitsData[i];
        const product = unit.products[j];

        // product validation
        if (_.isEmpty(product.description)) {
            this.props.setNotification("Description field is empty!", { variant: "error" });
            return false;
        }

        if (_.isEmpty(product.pieces)) {
            this.props.setNotification("Quantity field is empty!", { variant: "error" });
            return false;
        } else if (isNaN(product.pieces)) {
            this.props.setNotification("Quantity must be an integer!", { variant: "error" });
            return false;
        }

        if (_.isEmpty(product.weight)) {
            this.props.setNotification("Weight field is empty!", { variant: "error" });
            return false;
        } else if (isNaN(product.weight) || product.weight <= 0) {
            this.props.setNotification("Weight must be an integer/floating point number greater than zero!", { variant: "error" });
            return false;
        }

        // hazmat validation
        if (product.hazardous) {
            if (_.isEmpty(product.hazmat.class)) {
                this.props.setNotification("Hazmat Class field is empty!", { variant: "error" });
                return false;
            }

            if (_.isEmpty(product.hazmat.un)) {
                this.props.setNotification("Hazmat UN # field is empty!", { variant: "error" });
                return false;
            }

            if (_.isEmpty(product.hazmat.packingGroup)) {
                this.props.setNotification("Hazmat Packing Group field is empty!", { variant: "error" });
                return false;
            }

            if (_.isEmpty(product.hazmat.emergencyNumber)) {
                this.props.setNotification("Hazmat Emergency # field is empty!", { variant: "error" });
                return false;
            }
        }

        const data = {
            product: product.description,
            pieces: product.pieces,
            uom: product.uom,
            nmfc: product.nmfc,
            hs_code: product.hs_code,
            pclass: product.class,
            weight: product.weight,
            warehouse: this.state.warehouse,
            haz_class: product.hazmat.class,
            un_num: product.hazmat.un,
            group: product.hazmat.packingGroup,
            emergency: product.hazmat.emergencyNumber,
            packing_inst: product.hazmat.packingInstructions,
        };

        const url = "/index.php?p=api&r=json&c=product&m=add";
        try {
            const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.product)) {
                this.props.setNotification("Product successfully saved!", { variant: "success" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error saving the product!", { variant: "error" });
        }

        return false;
    }

    handleAddress(type) {
        const address = {
            address1: this.state[type + "_address1"],
            address2: this.state[type + "_address2"],
            zip: this.state[type + "_zip"],
            city: this.state[type + "_city"],
            state: this.state[type + "_state"]
        };

        let addressSearchString = "";

        for (const key in address) {
            const val = address[key];
            if (typeof val === "string" && _.trim(val) !== "") {
                if (addressSearchString === "") {
                    addressSearchString = _.trim(val);
                } else {
                    addressSearchString += " " + _.trim(val);
                }
            }
        }

        if (addressSearchString !== "") {
            const addressSearchURL = "http://www.google.com/maps/place/?q=" + encodeURIComponent(addressSearchString);
            const addressSearchTitle = "Address Search";
            const addressSearchOpts = "resizable=1, scrollbars=1, fullscreen=0, status=0";

            window.open(addressSearchURL, addressSearchTitle, addressSearchOpts);
        }
    }

    handleSwap() {
        const {
            shipper_name,
            shipper_address1,
            shipper_address2,
            shipper_zip,
            shipper_city,
            shipper_state,
            shipper_country,
            shipper_contact_name,
            shipper_contact_phone,
            shipper_contact_email,
            shipper_contact_fax,
            consignee_name,
            consignee_address1,
            consignee_address2,
            consignee_zip,
            consignee_city,
            consignee_state,
            consignee_country,
            consignee_contact_name,
            consignee_contact_phone,
            consignee_contact_email,
            consignee_contact_fax,
        } = this.state;

        this.setState({
            shipper_name: consignee_name || "",
            shipper_address1: consignee_address1 || "",
            shipper_address2: consignee_address2 || "",
            shipper_zip: consignee_zip || "",
            shipper_city: consignee_city || "",
            shipper_state: consignee_state || "",
            shipper_country: consignee_country || "",
            shipper_contact_name: consignee_contact_name || "",
            shipper_contact_phone: consignee_contact_phone || "",
            shipper_contact_email: consignee_contact_email || "",
            shipper_contact_fax: consignee_contact_fax || "",
            consignee_name: shipper_name || "",
            consignee_address1: shipper_address1 || "",
            consignee_address2: shipper_address2 || "",
            consignee_zip: shipper_zip || "",
            consignee_city: shipper_city || "",
            consignee_state: shipper_state || "",
            consignee_country: shipper_country || "",
            consignee_contact_name: shipper_contact_name || "",
            consignee_contact_phone: shipper_contact_phone || "",
            consignee_contact_email: shipper_contact_email || "",
            consignee_contact_fax: shipper_contact_fax || "",
        });
    }

    clearAddress(type) {
        this.clearCarriersAndSetState({
            [type + "_name"]: "",
            [type + "_address1"]: "",
            [type + "_address2"]: "",
            [type + "_zip"]: "",
            [type + "_city"]: "",
            [type + "_state"]: "",
            [type + "_country"]: "",
            saveShipperCode: "",
            saveConsigneeCode: "",
            saveBillingCode: "",
        });
    }

    handleReference(refList) {
        this.setState({references: refList.references});
    }

    handleChange(event) {
        const name = event.target.name;
        if(name === 'transit_status') {
            var value = JSON.parse(event.target.value);
            this.setState({
                transit_status: value.parent ? value.parent : value.status,
                transit_sub_status: value.parent ? value.status : null,
            });
         } else {
            this.setState({ [name]: event.target.value }, () => {
                if(event.target.value === "air") {
                    if(!_.isEmpty(this.state.defaultProduct)) {
                        this.setDefaultProduct(this.state.defaultProduct);
                    }
                    this.setState({container_load: ""});
                }
                if(event.target.value === "ocean") {
                    if(!_.isEmpty(this.state.defaultProduct)) {
                        this.setDefaultProduct(this.state.defaultProduct);
                    }
                }
                if(event.target.value === "export") {
                    this.setState({
                        cb_name: "",
                        cb_phone: "",
                        cb_email: "",
                    });
                }
                if(event.target.value === "import") {
                    if(this.state.shipping_mode === "ocean") {
                        let containers = [...this.state.containers];
                        containers.map((container) => {
                            container.units.map((unit) => {
                                unit.products.map((prodEl) => {
                                    prodEl.full_value_coverage = false;
                                })
                           })
                        })
                        this.setState({ containers });

                    } else {

                        let units = [...this.state.units];
                        units.map((el) => {
                            el.products.map((prodEl) => {
                                prodEl.full_value_coverage = false;
                            })
                        })
                        this.setState({ units });
                    }
                }
                if(name == "warehouse") {
                    this.loadDefaultData()
                }
            });
        }
    }

    handleInput = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            if (name === "custom_pallet_count" || name === "custom_overall_piece_qty") {
                this.updateTotals();
            }
        });

        if (name === "custom_unit_master_name") {
            this.state.body.uom.forEach($uom => {
                if ($uom["name"] === event.target.value ) {
                    this.setState({
                        custom_unit_master_id: $uom["id"],
                    });
                }
            });
        }
    }

    handleKeyPress(name, event) {
        if (event.key === "Enter") {
            if (name === "shipSearch") {
                this.handleSearch();
            } else if (name === "contactSearchShipper") {
                this.handleContactSearch("shipper");
            } else if (name === "contactSearchConsignee") {
                this.handleContactSearch("consignee");
            }
        }
    }

    handleChecked = name => event => {
        const val = event.target.value;
        if(name === "uom_opt") {
            let defaultLengthType = val === "imperial" ? "in" : "cm";
            let defaultWeightType = val === "imperial" ? "lbs" : "kg";

            if(this.state.shipping_mode === "ocean") {
                let containers = [...this.state.containers];
                containers.map((container) => {
                    container.units.map((unit) => {
                        unit.length_uom = defaultLengthType;
                        unit.height_uom = defaultLengthType;
                        unit.width_uom = defaultLengthType;
                        unit.products.map((prodEl) => {
                            prodEl.weight_uom = defaultWeightType;
                        })
                   })
                })
                this.setState({containers, default_length_type: defaultLengthType, default_weight_type: defaultWeightType});
            } else {
                let units = [...this.state.units];
                units.map((el) => {
                    el.length_uom = defaultLengthType;
                    el.height_uom = defaultLengthType;
                    el.width_uom = defaultLengthType;
                    el.products.map((prodEl) => {
                        prodEl.weight_uom = defaultWeightType;
                    })
                })
                this.setState({units, default_length_type: defaultLengthType, default_weight_type: defaultWeightType});
            }
        }
        this.setState({ [name]: val }, () => {});
	};

    async handleSearch() {
        const val = "";
        if (_.isEmpty(val)) {
            return;
        }
    }

    handleTabChange = (event, index) => {
        if(index == 7) {
            this.onNoteClick(this.state.intl_ship_id)
        } else {
            this.setState({ tabIndex: index});
        }
    }

	onNoteClick = async (id, hasUnread) => {
		await this.props.getIntlNotes(id);
	}

    handleCarrierTabChange = (event, index) => {
        this.setState({ carrierTabIndex: index });
    }

    handleDatetime(name, momentObj) {
        if(name === "close_time" || name === "ready_time") {
            moment.isMoment(momentObj) ? this.setState({ [name]: momentObj.format("HH:mm:ss") }) : this.setState({ [name]: momentObj });
        } else {
            this.setState({ [name]: momentObj });
        }
    }

    clearGeneralInfo() {
        this.clearCarriersAndSetState({
            provider_id: "",
            master_bill_num: "",
            house_bill_num: "",
            customs_entry_num: "",
            airway_bill_num: "",
            isf_num: "",
            po_num: "",
            so_num: "",
            ref_num: "",
            custom_date: "",
            transit_status: "",
            transit_sub_status: "",
            freight_ready_date: "",
            ready_time: "",
            close_time: "",
        });
    }

    getIntlCarriers(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.scac}
                >
                    {prop.carrier}
                </MenuItem>
            );
        });
    }

    getRateAndDuties() {
        const { classes } = this.props;
        return(
            <>
                <GridItem sm={2} md={2}>
                    <WithTooltip
                        title="Rate"
                        content="Enter a rate (optional, not required to execute)"
                    >
                        <span>
                            <InputLabel className={classes.label}>Rate</InputLabel>
                        </span>
                    </WithTooltip>
                    <br />
                    <CustomInput
                        inputProps={{
                            type: "number",
                            id: "intl_carrier_rate",
                            name: "intl_carrier_rate",
                            inputProps: { min: 0 },
                            value: this.state.intl_carrier_rate || "",
                            onChange: this.handleInput("intl_carrier_rate"),
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </GridItem>
                <GridItem sm={2} md={2}>
                    <WithTooltip
                        title="Duties"
                        content="Enter a duties amount (optional, not required to execute)"
                    >
                        <span>
                            <InputLabel className={classes.label}>Duties</InputLabel>
                        </span>
                    </WithTooltip>
                    <br />
                    <CustomInput
                        inputProps={{
                            type: "number",
                            id: "intl_carrier_duties",
                            name: "intl_carrier_duties",
                            inputProps: { min: 0 },
                            value: this.state.intl_carrier_duties || "",
                            onChange: this.handleInput("intl_carrier_duties"),
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </GridItem>
            </>
        )
    }
    /**
     * Clear carrier fields from state
     * @param  object override - optionally provide more fields to set in state
     * @param  function callback - optional callback
     */
    clearCarriersAndSetState = async (override, callback) => {
        const overrideObject = isObject(override) ? override : {};
        await this.setState({
            ...overrideObject
        }, () => {
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    addNote() {
        let { canned_note, notes } = this.state;
        if (!_.isEmpty(canned_note)) {
            notes += "\n" + canned_note;
            this.setState({ notes });
        }
    }

    async handleUpload(files) {
        if(!_.isEmpty(this.state.saved_ship_id)) {
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("imgFile_" + i.toString(), files[i]);
            }

            const config = {
                headers: {
                    "content-type": "multipart/form-data"
                }
            };

            const imgResponse = await axios.post("/index.php?p=api&c=international&m=uploadImg&d=" + this.state.saved_ship_id, data, config);
            if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.message)) {
                this.props.handleMessages(imgResponse);
            }

            if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.body) && imgResponse.data.body.uploadedImages) {
                this.loadSavedImages(this.state.saved_ship_id);
            } else {
                this.props.setNotification("There was an error saving images to the International Shipment!", { variant: "error" });
            }


        } else {

            this.setState({ imageFiles: this.state.imageFiles.concat(files) });

        }
    }

    async loadSavedImages(intlShipId) {
        const url = "/index.php?p=api&c=international&m=imageCollection&d=" + intlShipId;
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.images)) {
                this.setState({ imageFiles: response.data.body.images });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the images!", { variant: "error" });
        }
    }

    async deleteImage(i) {
        const { imageFiles } = this.state;
        const image = imageFiles[i];
        this.setState({ alert: null });

        if (image.preview) {
            imageFiles.splice(i, 1);
            this.setState({ imageFiles });
            return;
        }

        try {
            const response = await axios.get("/index.php?p=api&r=json&c=international&m=deleteImg&d=" + image.id);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.deleted === 1) {
                imageFiles.splice(i, 1);
                this.setState({ imageFiles });
                this.props.setNotification("Image successfully deleted!", {
                    variant: "success"
                });
            } else {
                this.props.setNotification("There was an error deleting the image!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error deleting the image!", { variant: "error" });
        }
    }

    getImages(data) {
        return data.map((prop, key) => {
            const src = prop.preview ? prop.preview : apiUrl + "/index.php?p=api&c=international&m=downloadImg&d=" + prop.id + "&thumb=1";
            const filename = !_.isEmpty(prop.filename) ? prop.filename : prop.name;
            const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
            return <Preview key={key} src={src} index={key} extension={extension} handleClick={this.handleImageClick.bind(this)} name={filename} handleDelete={this.handleDeleteImage.bind(this)} />;
        });
    }

    handleImageClick(i) {
        const { imageFiles } = this.state;
        const image = imageFiles[i];
        if (image.id) {
            const url = apiUrl + "/index.php?p=api&c=international&m=downloadImg&d=" + image.id;
            const title = "Image Preview";
            const options = "resizable=1, scrollbars=1, fullscreen=0, status=0";
            window.open(url, title, options);
        }
    }

    handleDeleteImage(key) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="Are you sure?"
                    onConfirm={() => this.deleteImage(key)}
                    onCancel={() => this.setState({ alert: null })}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    You will not be able to recover this file!
                </SweetAlert>
            )
        });
    }

    onHistoryClick = async (id) => {
		await this.props.getShipmentHistory(id);
		this.handleModal("historyModal", true)
	}

    openAlert = (message, callback) => {
        this.props.openAlertModal(message, callback);
    }

    async updateShipmentLevel(level) {
        const previousLevel = this.state.level;
        let newLevelId = +level.id;
		if(+newLevelId == +previousLevel) newLevelId--;
        this.setState({
            level: newLevelId,
        });
	}

    render() {

        const { classes } = this.props;
        const selectClasses = cx({
            [classes.select]: true,
            [classes.requiredSelect]: true
        });
        const executed = this.state.status != 'hold' && !_.isEmpty(this.state.status);
        const isOcean = this.state.shipping_mode == "ocean";
        const searchByOptions = [
    		{val: "intlShipId", display: "Shipment ID"},
    		{val: "status", display: "Status"},
            {val: "custom", display: "Custom #"},
            {val: "carrier", display: "Carrier Name"},
            {val: "shipper", display: "Shipper Name"},
            {val: "oAddress1", display: "Origin Address 1"},
            {val: "oCity", display: "Origin City"},
            {val: "oState", display: "Origin State"},
            {val: "oZip", display: "Origin Zip"},
            {val: "consignee", display: "Consignee Name"},
            {val: "dAddress1", display: "Destination Address 1"},
            {val: "dCity", display: "Destination City"},
            {val: "dState", display: "Destination State"},
            {val: "dZip", display: "Destination Zip"},
            {val: "pro", display: "PRO #"},
            {val: "ref", display: "Reference #"},
            {val: "po", display: "PO #"},
            {val: "so", display: "Order/SO #"},
            {val: "date", display: "Date"},
            {val: "transitStatus", display: "Transit Status"},
            {val: "pickupDate", display: "Pickup Date"},
            {val: "expectedDeliveryDate", display: "Expected Delivery Date"},
            {val: "deliveryDate", display: "Delivery Date"}
    	];
        const isNewShipment = this.isNewShipment(this.state.intl_ship_id);
        let prodCatCols = ["Code", "Name", "NMFC", "HS Code", "Class", "Weight", "Hazmat", "Actions"];
        return(
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <ShipmentSearchCard
                        classes={classes}
                        searchByOptions={searchByOptions}
                        shipmentIdName={"Int'l Shipment"}
                        searchBy={this.state.search_ship_by || ""}
                        searchById={"search_ship_by"}
                        search={this.state.search_ship || ""}
                        searchId={"search_ship"}
                        savedShipmentId={this.state.saved_ship_id || "N/A"}
                        status={this.state.status || "N/A"}
                        warehouse={this.state.warehouse || ""}
                        executed={executed}
                        selectClasses={selectClasses}
                        onChangeSelect={this.handleChange}
                        onChangeSearch={this.handleInput("search_ship")}
                        onKeyPressSearch={e => this.handleKeyPress("shipSearch", e)}
                        onSearch={this.handleSearch}
                        warehouses={!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.warehouses) ? (this.state.body.warehouses) : ("")}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <ShipmentHistoryCard
                        username={this.state.username}
                        dateCreated={this.state.date_created}
                        timeCreated={this.state.time_created}
                        confirmationNumber={""}
                        transactionId={""}
                        pickupWindowStart={""}
                        pickupWindowEnd={""}
                        trackingSent={false}
                        trackingLink={""}
                        p44DispatchCanceled={false}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={12}>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs
                            value={this.state.tabIndex}
                            onChange={this.handleTabChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                            ScrollButtonComponent={(props) => {
                                if (
                                    props.direction === "left" &&
                                    !props.disabled
                                ) {
                                    return (
                                        <IconButton {...props}>
                                            <KeyboardArrowLeft/>
                                        </IconButton>
                                    );
                                } else if (
                                    props.direction === "right" &&
                                    !props.disabled
                                ) {
                                    return (
                                        <IconButton {...props}>
                                            <KeyboardArrowRight/>
                                        </IconButton>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        >
                            <Tab label="Import/Export Info" />
                            <Tab label="General Info" />
                            <Tab label="Shipment Details" />
                            <Tab label="Product Info" />
                            <Tab label="Special Inst" />
                            <Tab label="Choose Carrier" />
                            {executed  && (
                                <Tab label="Post TFM Pickup Details" />
                            )}
                            {executed  && (
                                <Tab label="Notes" />
                            )}
                        </Tabs>
                    </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.props.openNotesModal && (
                        <InternationalNotesModal />
                    )}
                    {this.state.tabIndex == 0 && (
                        <ImportExportInformation
                            classes={classes}
                            direction={this.state.direction}
                            onChangeSelect={this.handleChange}
                            onChangeChecked={(name) => this.handleChecked(name)}
                            handleInput={(name) => this.handleInput(name)}
                            handleContainer={this.handleContainer}
                            addContainer={this.addContainer}
                            removeContainer={this.removeContainer}
                            getMenuItems={(items, item) => this.getMenuItems(items, item)}
                            cb_name={this.state.cb_name}
                            cb_phone={this.state.cb_phone}
                            cb_email={this.state.cb_email}
                            shipping_mode={this.state.shipping_mode}
                            service_type={this.state.service_type}
                            service_types={this.state.service_types}
                            container_size={this.state.container_size}
                            container_sizes={this.state.container_sizes}
                            container_type={this.state.container_type}
                            container_types={this.state.container_types}
                            inco_term={this.state.inco_term}
                            inco_terms={this.state.inco_terms}
                            named_place={this.state.named_place}
                            containers={this.state.containers}
                            container_load={this.state.container_load}
                            executed={executed}
                        />
                    )}
                    {this.state.tabIndex == 1 && (
                       <GeneralInformation
                            showPro={false}
                            showBol={false}
                            showBolTransitTimeInfo={false}
                            showLevelSelect={this.props.userHasBolLevelPermission}
                            levelId={this.state.level}
                            onSelectLevel={level => {this.updateShipmentLevel(level)}}
                            showIntlTransitTimeInfo={true}
                            showProviderId={true}
                            showMasterBill={true}
                            showHouseBill={true}
                            showAirwayBill={true}
                            showCustomsEntryNum={true}
                            showIsfNum={true}
                            allowTransitFieldsUpdate={false}
                            allowUpdate={true}
                            provider_id={this.state.provider_id}
                            master_bill_num={this.state.master_bill_num}
                            require_master_bill={this.state.require_master_bill}
                            house_bill_num={this.state.house_bill_num}
                            require_house_bill={this.state.require_house_bill}
                            airway_bill_num={this.state.airway_bill_num}
                            require_airway_bill={this.state.require_airway_bill}
                            customs_entry_num={this.state.customs_entry_num}
                            isf_num={this.state.isf_num}
                            require_isf={this.state.require_isf}
                            po={this.state.po_num}
                            require_po={this.state.require_po}
                            so_num={this.state.so_num}
                            require_so={this.state.require_so}
                            ref_entry_type={this.state.ref_entry_type}
                            refOpts={this.state.ref_entry_opts}
                            ref_num={this.state.ref_num}
                            require_ref={this.state.require_ref}
                            custom_date={this.state.custom_date}
                            transit_status={this.state.transit_status}
                            transit_sub_status={this.state.transit_sub_status}
                            freight_ready_date={this.state.freight_ready_date}
                            ready_time={this.state.ready_time}
                            close_time={this.state.close_time}
                            references={this.state.references}
                            direction={this.state.direction}
                            clearGeneralInfo={this.clearGeneralInfo}
                            onUpdate={() => this.handleSaveDraft()}
                            onChangeCustomDate={moment => {this.handleDatetime("custom_date", moment);}}
                            onChangeFreightReadyDate={moment => {this.handleDatetime("freight_ready_date", moment);}}
                            onChangeReadyTime={moment => {this.handleDatetime("ready_time", moment);}}
                            onChangeCloseTime={moment => {this.handleDatetime("close_time", moment);}}
                            onChangeSo={this.handleInput("so_num")}
                            onChangePo={this.handleInput("po_num")}
                            onChangeHouseBill={this.handleInput("house_bill_num")}
                            onChangeProviderId={this.handleInput("provider_id")}
                            onChangeMasterBill={this.handleInput("master_bill_num")}
                            onChangeAirwayBill={this.handleInput("airway_bill_num")}
                            onChangeIsf={this.handleInput("isf_num")}
                            onChangeCustomsEntryNum={this.handleInput("customs_entry_num")}
                            onChangeRef={this.handleInput("ref_num")}
                            onChangeTransitStatus={this.handleChange}
                            handleReference={this.handleReference}
                        />
                    )}
                    {this.state.tabIndex == 2 && (
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={8}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <h5>Shipper Information</h5>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <ContactSearch
                                                    classes={classes}
                                                    searchByField="shipper_search_by"
                                                    searchByValue={this.state.shipper_search_by}
                                                    onChangeSearchBy={this.handleChange}
                                                    searchForField="shipper_search_for"
                                                    searchForValue={this.state.shipper_search_for || ""}
                                                    onChangeSearchFor={this.handleInput("shipper_search_for")}
                                                    onKeyPress={e => this.handleKeyPress("contactSearchShipper", e)}
                                                    onSearch={e => this.handleContactSearch("shipper")}
                                                    disabled={executed}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <AddressFields
                                                entity="shipper"
                                                classes={classes}
                                                disabled={executed}
                                                nameField="shipper_name"
                                                address1Field="shipper_address1"
                                                address2Field="shipper_address2"
                                                zipField="shipper_zip"
                                                cityField="shipper_city"
                                                stateField="shipper_state"
                                                countryField="shipper_country"
                                                nameValue={this.state.shipper_name || ""}
                                                address1Value={this.state.shipper_address1 || ""}
                                                address2Value={this.state.shipper_address2 || ""}
                                                zipValue={this.state.shipper_zip || ""}
                                                cityValue={this.state.shipper_city || ""}
                                                stateValue={this.state.shipper_state || ""}
                                                countryValue={this.state.shipper_country || ""}
                                                onChangeName={this.handleInput("shipper_name")}
                                                onChangeAddress1={this.handleInput("shipper_address1")}
                                                onChangeAddress2={this.handleInput("shipper_address2")}
                                                onChangeZip={this.handleInput("shipper_zip")}
                                                onChangeCity={this.handleInput("shipper_city")}
                                                onChangeState={this.handleInput("shipper_state")}
                                                onChangeCountry={this.handleInput("shipper_country")}

                                            />
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <WithTooltip
                                                    title="Clear Shipper Address"
                                                    content="Remove all shipper address information."
                                                >
                                                    <span>
                                                        <Button size="sm" color="warning" onClick={e => this.clearAddress("shipper")} className={classes.marginRight} disabled={false}>
                                                            Clear
                                                        </Button>
                                                    </span>
                                                </WithTooltip>
                                                <WithTooltip
                                                    title="Search Shipper Address"
                                                    content="Search for the street view of the shipper address."
                                                >
                                                    <span>
                                                        <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("shipper")} className={classes.marginRight}>
                                                            <LocationOn />
                                                        </Button>
                                                    </span>
                                                </WithTooltip>
                                                <WithTooltip
                                                    title="Swap Information"
                                                    content="Swap shipper and consignee information."
                                                >
                                                    <span>
                                                        <Button justIcon round size="sm" color="info" onClick={this.handleSwap} className={classes.marginRight} disabled={false}>
                                                            <SwapVert />
                                                        </Button>
                                                    </span>
                                                </WithTooltip>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} className={classes.dividerLeft}>
                                        <h5>Shipper Contact</h5>
                                        <ContactFields
                                            classes={classes}
                                            entity="shipper"
                                            nameField="shipper_contact_name"
                                            phoneField="shipper_contact_phone"
                                            emailField="shipper_contact_email"
                                            faxField="shipper_contact_fax"
                                            nameValue={this.state.shipper_contact_name || ""}
                                            phoneValue={this.state.shipper_contact_phone || ""}
                                            emailValue={this.state.shipper_contact_email || ""}
                                            faxValue={this.state.shipper_contact_fax || ""}
                                            onChangeName={this.handleInput("shipper_contact_name")}
                                            onChangePhone={this.handleInput("shipper_contact_phone")}
                                            onChangeEmail={this.handleInput("shipper_contact_email")}
                                            onChangeFax={this.handleInput("shipper_contact_fax")}
                                            required={true}
                                            disabled={executed}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={8} className={classes.dividerTop}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <h5>Consignee Information</h5>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <ContactSearch
                                                    classes={classes}
                                                    searchByField="consignee_search_by"
                                                    searchByValue={this.state.consignee_search_by}
                                                    onChangeSearchBy={this.handleChange}
                                                    searchForField="consignee_search_for"
                                                    searchForValue={this.state.consignee_search_for || ""}
                                                    onChangeSearchFor={this.handleInput("consignee_search_for")}
                                                    onKeyPress={e => this.handleKeyPress("contactSearchConsignee", e)}
                                                    onSearch={e => this.handleContactSearch("consignee")}
                                                    disabled={executed}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <AddressFields
                                                entity="consignee"
                                                classes={classes}
                                                disabled={executed}
                                                nameField="consignee_name"
                                                address1Field="consignee_address1"
                                                address2Field="consignee_address2"
                                                zipField="consignee_zip"
                                                cityField="consignee_city"
                                                stateField="consignee_state"
                                                countryField="consignee_country"
                                                nameValue={this.state.consignee_name || ""}
                                                address1Value={this.state.consignee_address1 || ""}
                                                address2Value={this.state.consignee_address2 || ""}
                                                zipValue={this.state.consignee_zip || ""}
                                                cityValue={this.state.consignee_city || ""}
                                                stateValue={this.state.consignee_state || ""}
                                                countryValue={this.state.consignee_country || ""}
                                                onChangeName={this.handleInput("consignee_name")}
                                                onChangeAddress1={this.handleInput("consignee_address1")}
                                                onChangeAddress2={this.handleInput("consignee_address2")}
                                                onChangeZip={this.handleInput("consignee_zip")}
                                                onChangeCity={this.handleInput("consignee_city")}
                                                onChangeState={this.handleInput("consignee_state")}
                                                onChangeCountry={this.handleInput("consignee_country")}

                                            />
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <WithTooltip
                                                    title="Clear Consignee Address"
                                                    content="Remove all consignee address information."
                                                >
                                                    <span>
                                                        <Button size="sm" color="warning" onClick={e => this.clearAddress("consignee")} className={classes.marginRight} disabled={false}>
                                                            Clear
                                                        </Button>
                                                    </span>
                                                </WithTooltip>
                                                <WithTooltip
                                                    title="Search Consignee Address"
                                                    content="Search for the street view of the consignee address."
                                                >
                                                    <span>
                                                        <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("consignee")} className={classes.marginRight}>
                                                            <LocationOn />
                                                        </Button>
                                                    </span>
                                                </WithTooltip>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} className={classes.dividerTop + " " + classes.dividerLeft}>
                                        <h5>Consignee Contact</h5>
                                        <ContactFields
                                            classes={classes}
                                            entity="consignee"
                                            nameField="consignee_contact_name"
                                            phoneField="consignee_contact_phone"
                                            emailField="consignee_contact_email"
                                            faxField="consignee_contact_fax"
                                            nameValue={this.state.consignee_contact_name || ""}
                                            phoneValue={this.state.consignee_contact_phone || ""}
                                            emailValue={this.state.consignee_contact_email || ""}
                                            faxValue={this.state.consignee_contact_fax || ""}
                                            onChangeName={this.handleInput("consignee_contact_name")}
                                            onChangePhone={this.handleInput("consignee_contact_phone")}
                                            onChangeEmail={this.handleInput("consignee_contact_email")}
                                            onChangeFax={this.handleInput("consignee_contact_fax")}
                                            required={true}
                                            disabled={executed}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    )}
                    {this.state.tabIndex == 3 && (
                        <Card>
                             {this.state.shipping_mode == "ocean" && (
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        {this.buildContainerTab()}
                                        {(!executed) && (
                                            <GridItem xs={12} lg={1}>
                                                <WithTooltip
                                                    title="Add Container"
                                                >
                                                    <Button style={{marginTop: "15px"}} color="success" size="sm" justIcon round onClick={this.addContainer}>
                                                        <Add/>
                                                    </Button>
                                                </WithTooltip>
                                            </GridItem>
                                        )}
                                    </GridContainer>
                                </GridItem>
                            )}
                            <CardBody style={{padding:"5px"}}>
                                <GridContainer>
                                    <HandlingUnit
                                        classes={classes}
                                        executed={executed}
                                        removeUnit={this.removeUnit}
                                        hasProductRead={this.props.hasProductRead}
                                        hasProductWrite={this.props.hasProductWrite}
                                        saveProduct={this.saveProduct}
                                        handleUnit={this.handleUnit}
                                        addUnit={this.addUnit}
                                        addProduct={this.addProduct}
                                        handleProduct={this.handleProduct}
                                        clearProduct={this.clearProduct}
                                        removeProduct={this.removeProduct}
                                        nmfc_notice_message={this.state.nmfc_notice_message}
                                        units={this.state.shipping_mode === 'ocean' ? this.state.containers[this.state.containerTabIndex]['units'] :  this.state.units}
                                        user={this.state.user}
                                        onChangeChecked={(name) => this.handleChecked(name)}
                                        uom_opt={this.state.uom_opt}
                                        imp_length_types={this.state.imp_length_types}
                                        met_length_types={this.state.met_length_types}
                                        imp_weight_types={this.state.imp_weight_types}
                                        met_weight_types={this.state.met_weight_types}
                                        uomSelect={true}
                                        onChangeSelect={this.handleChange}
                                        display_custom_piece_count={this.state.display_custom_piece_count}
                                        body={this.state.body}
                                        showHsCode={true}
                                        showClass={true}
                                        showSubCode={false}
                                        showNetWeight={false}
                                        showCondition={false}
                                        showPackaging={false}
                                        showDeclaredIntl={true}
                                        showOverallPiece={true}
                                        enable_product_part_no={true}
                                        showFullValueCoverage={this.state.direction == "export"}
                                        toggleHazardous={this.toggleHazardous}
                                        handleHazardous={this.handleHazardous}
                                        validatePhoneNumber={this.validatePhoneNumber}
                                        handleProductSearch={this.handleProductSearch}
                                        updateTotals={this.updateTotals}
                                        requireNmfc={this.state.direction == "export"}
                                    />
                                    <GridItem xs={12} sm={6} md={12} lg={4}>
                                        {(this.state.display_custom_piece_count) && (
                                            <CustomCounts
                                                custom_pallet_count={isOcean ? this.state.containers[this.state.containerTabIndex].custom_pallet_count : this.state.custom_pallet_count}
                                                custom_piece_count={isOcean ? this.state.containers[this.state.containerTabIndex].custom_piece_count : this.state.custom_piece_count}
                                                custom_unit_master_name={isOcean ? this.state.containers[this.state.containerTabIndex].custom_unit_master_name : this.state.custom_unit_master_name}
                                                custom_loose_count={isOcean ? this.state.containers[this.state.containerTabIndex].custom_loose_count : this.state.custom_loose_count}
                                                custom_overall_piece_qty={isOcean ? this.state.containers[this.state.containerTabIndex].custom_overall_piece_qty :this.state.custom_overall_piece_qty}
                                                executed={executed}
                                                uom={this.state.body.uom}
                                                handleInput={isOcean ? (name, event) => this.handleContainer(this.state.containerTabIndex, name, event) : (name, event) => this.handleInput(name)(event)}
                                                getUnitTypes={(uom) => this.getUnitTypes(uom)}
                                                classes={this.props.classes}
                                            />
                                        ) || ('')}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12} >
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6} lg={4} >
                                                <Paper className={classes.paper} elevation={1}>
                                                    <GridContainer style={{ padding: "5px 5px 5px 5px" }}>
                                                        {this.state.shipping_mode == "ocean" && (
                                                            <>
                                                                <GridItem xs={6} md={6} className={classes.left}>
                                                                    Total Containers:
                                                                </GridItem>
                                                                <GridItem xs={6} md={6} className={classes.right}>
                                                                    <strong>{this.state.totals.containers}</strong>
                                                                </GridItem>
                                                            </>
                                                        )}
                                                        <GridItem xs={6} md={6} className={classes.left}>
                                                            Total Units:
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.right}>
                                                            <strong>{this.state.totals.units}</strong>
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.left}>
                                                            Total Weight:
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.right}>
                                                            <strong>{this.state.totals.weight} {this.state.uom_opt == "imperial" ? "lbs." : "kg." }</strong>
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.left}>
                                                            Total Overall Pieces:
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.right}>
                                                            <strong>{this.state.totals.overall_piece_qty}</strong>
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.left}>
                                                            Linear Feet:
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.right}>
                                                            <strong>{this.state.totals.linear_feet} ft.</strong>
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.left}>
                                                            Total Cubic Meters:
                                                        </GridItem>
                                                        <GridItem xs={6} md={6} className={classes.right}>
                                                            <strong>{this.state.totals.cubic_meters} m.</strong>
                                                        </GridItem>
                                                    </GridContainer>
                                                </Paper>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    )}
                    {this.state.tabIndex == 4 && (
                      <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h5>Special Information</h5>
                                    <SpecialInformationFields
                                        cannedNotes={this.state.body.notes}
                                        notes={this.state.notes}
                                        canned_note={this.state.canned_note}
                                        onChangeNotes={this.handleInput("notes")}
                                        onAddNote={this.addNote}
                                        handleChange={this.handleChange}
                                        showCod={false}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.dividerTop} style={{marginTop: "10%"}}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h5>Upload Files</h5>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Dropzone handleUpload={this.handleUpload} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <aside className={classes.thumbsContainer}>{!_.isEmpty(this.state.imageFiles) && this.getImages(this.state.imageFiles)}</aside>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                      </Card>
                    )}
                    {this.state.tabIndex == 5 && (
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem sm={4} md={4}>
                                                <Tabs
                                                    value={this.state.carrierTabIndex}
                                                    onChange={this.handleCarrierTabChange}
                                                    textColor="secondary"
                                                    indicatorColor="secondary"
                                                >
                                                    <Tab label="TFM" />
                                                    <Tab label="PROVIDER" />
                                                </Tabs>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    {this.state.carrierTabIndex == 0 && (
                                        <GridContainer>
                                            <GridItem sm={12}>
                                                <GridContainer style={{padding: '10px'}}>
                                                    {this.getRateAndDuties()}
                                                </GridContainer>
                                            </GridItem>
                                            {!executed && (
                                                <GridItem sm={12} md={12} className={classes.center}>
                                                    <WithTooltip
                                                        title="Submit this shipment to TFM International"
                                                        content="Save all the latest changes, set the status to 'Executed,' and enable printing"
                                                    >
                                                        <span>
                                                            <Button
                                                                color="success"
                                                                size="lg"
                                                                style={{
                                                                    marginRight: "5px"
                                                                }}
                                                                onClick={() => this.beginExecution(true)}
                                                            >
                                                                SUBMIT TO TFM INTL FOR REVIEW
                                                            </Button>
                                                        </span>
                                                    </WithTooltip>
                                                </GridItem>
                                            )}
                                        </GridContainer>
                                    )}
                                    {this.state.carrierTabIndex == 1 && (
                                        <GridContainer>
                                            <GridItem sm={4} md={4}>
                                                <WithTooltip
                                                    title="Choose a Carrier"
                                                    content="Select a carrier from the list."
                                                >
                                                    <span>
                                                        <InputLabel className={classes.label}>Choose Carrier</InputLabel>
                                                    </span>
                                                </WithTooltip>
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <Select
                                                        MenuProps={{ className: classes.selectMenu }}
                                                        classes={{ select: classes.select + " " + classes.requiredSelect }}

                                                        value={this.state.intl_carrier}
                                                        inputProps={{
                                                            name: "intl_carrier",
                                                            id: "intl_carrier",

                                                        }}
                                                        onChange={this.handleChange}
                                                    >
                                                        {!_.isEmpty(this.state.body) && this.state.body.intl_carriers && this.getIntlCarriers(this.state.body.intl_carriers)}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            {this.getRateAndDuties()}
                                        </GridContainer>
                                    )}
                                </GridContainer>
                            </CardBody>
                        </Card>
                    )}
                    {this.state.tabIndex == 6 && executed && (
                        <PostPickupDetails
                            disabled={!this.props.hasPostPickupWrite ? true : false}
                            canUpdate={this.props.hasPostPickupWrite ? true : false}
                            handleInput={(name) => this.handleInput(name)}
                            vehicle_name={this.state.vehicle_name}
                            vehicle_num={this.state.vehicle_num}
                            container_num={this.state.container_num}
                            seal_num={this.state.seal_num}
                            origin_port={this.state.origin_port}
                            dest_port={this.state.dest_port}
                            booking_num={this.state.booking_num}
                            cargo_ready_date={this.state.cargo_ready_date}
                            actual_pu_date={this.state.actual_pu_date}
                            doc_cutoff_origin_date={this.state.doc_cutoff_origin_date}
                            cargo_cutoff_origin_date={this.state.cargo_cutoff_origin_date}
                            actual_receipt_origin_date={this.state.actual_receipt_origin_date}
                            etd_depart_origin_date={this.state.etd_depart_origin_date}
                            cob_depart_origin_date={this.state.cob_depart_origin_date}
                            eta_arrival_dest_date={this.state.eta_arrival_dest_date}
                            actual_arrival_dest_date={this.state.actual_arrival_dest_date}
                            eta_final_dest_date={this.state.eta_final_dest_date}
                            actual_final_dest_date={this.state.actual_final_dest_date}
                            last_free_day={this.state.last_free_day}
                            eta_door_date={this.state.eta_door_date}
                            actual_door_date={this.state.actual_door_date}
                            post_pallet_count={this.state.post_pallet_count}
                            post_weight_type={this.state.post_weight_type}
                            post_total_weight={this.state.post_total_weight}
                            post_air_weight={this.state.post_air_weight}
                            post_air_weight_type={this.state.post_air_weight_type}
                            isExport={this.state.direction == "export" ? true: false}
                            shipping_mode={this.state.shipping_mode}
                            containers={this.state.containers}
                            onChangeDate={(name, moment) => this.handleDatetime(name, moment)}
                            onChangeSelect={this.handleChange}
                            updatePostPickup={this.updatePostPickup}
                            handleContainer={this.handleContainer}
                        />
                    )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.center}>
                    {this.state.tabIndex == 0 && (
                        <GridContainer>
                                <GridItem xs={6} sm={6} md={6} className={classes.center} />
                                <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                        <span>
                                            <Button
                                                color="success"
                                                style={{
                                                    marginRight: "5px"
                                                }}
                                                onClick={() => this.handleTabChange(null, 1)}
                                            >
                                                General Information<FastForward />
                                            </Button>
                                        </span>
                                </GridItem>
                            </GridContainer>
                    )}
                    {this.state.tabIndex == 1 && (
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="danger"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 0)}
                                    >
                                        <FastRewind/>Import/Export Information
                                    </Button>
                                </span>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="success"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 2)}
                                    >
                                         Shipment Details<FastForward />
                                    </Button>
                                </span>
                            </GridItem>
                        </GridContainer>
                    )}
                    {this.state.tabIndex == 2 && (
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="danger"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 1)}
                                    >
                                        <FastRewind/>General Information
                                    </Button>
                                </span>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="success"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 3)}
                                    >
                                         Product Information<FastForward/>
                                    </Button>
                                </span>
                            </GridItem>
                        </GridContainer>
                    )}
                    {this.state.tabIndex == 3 && (
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="danger"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 2)}
                                    >
                                        <FastRewind/>Shipment Details
                                    </Button>
                                </span>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="success"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 4)}
                                    >
                                         Special Instructions<FastForward/>
                                    </Button>
                                </span>
                            </GridItem>
                        </GridContainer>
                    )}
                    {this.state.tabIndex == 4 && (
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="danger"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 3)}
                                    >
                                        <FastRewind/>Product Information
                                    </Button>
                                </span>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="success"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 5)}
                                    >
                                         Choose A Carrier<FastForward />
                                    </Button>
                                </span>
                            </GridItem>
                        </GridContainer>
                    )}
                    {this.state.tabIndex == 5 && (
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="danger"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 4)}
                                    >
                                        <FastRewind/>Special Instructions
                                    </Button>
                                </span>
                            </GridItem>
                            {executed ? (
                                <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                    <span>
                                        <Button
                                            color="success"
                                            style={{
                                                marginRight: "5px"
                                            }}
                                            onClick={() => this.handleTabChange(null, 6)}
                                        >
                                            Post TFM Pickup Details<FastForward />
                                        </Button>
                                    </span>
                                </GridItem>
                            ):(
                                <GridItem xs={6} sm={6} md={6} className={classes.center}></GridItem>
                            )}
                        </GridContainer>
                    )}
                    {this.state.tabIndex == 6 && (
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}>
                                <span>
                                    <Button
                                        color="danger"
                                        style={{
                                            marginRight: "5px"
                                        }}
                                        onClick={() => this.handleTabChange(null, 5)}
                                    >
                                        <FastRewind/>Choose A Carrier
                                    </Button>
                                </span>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.center}></GridItem>
                        </GridContainer>
                    )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.center}>
                    <WithTooltip
                        title={isNewShipment ? "Save as Draft" : "Update"}
                        content={isNewShipment ? "Save this TMS International Shipment and set the status to 'On-Hold'" : "Update this International Shipment"}
                    >
                        <span>
                            <Button
                                color="success"
                                size="lg"
                                style={{
                                    marginRight: "5px"
                                }}
                                onClick={() => this.handleSaveDraft()}
                            >
                                <FileCopy />{isNewShipment ? "Save as Draft" : "Update"}
                            </Button>
                        </span>
                    </WithTooltip>
                    {!isNewShipment && (
                        <WithTooltip
                            title="Save as New"
                            content="Save this TMS International Shipment with a new International shipment ID and set the status to 'On-Hold'"
                        >
                            <span>
                                <Button
                                    color="linkedin"
                                    size="lg"
                                    style={{
                                        marginRight: "5px"
                                    }}
                                    onClick={() => this.handleSaveNew()}
                                >
                                    <FileCopy />Save as New
                                </Button>
                            </span>
                        </WithTooltip>
                    )}
                    <Button
                        color="linkedin"
                        size="lg"
                        style={{
                            marginRight: "5px"
                        }}
                        onClick={() => this.onHistoryClick(this.state.intl_ship_id)}
                    >
                        History
                    </Button>
                </GridItem>
                {!_.isEmpty(this.state.intl_carrier) && this.state.carrierTabIndex == 1 && !executed && (
                    <GridItem xs={12} sm={12} md={12} className={classes.center}>
                        <WithTooltip
                            title="Execute"
                            content="Save and Execute this International shipment."
                        >
                            <span>
                                <Button
                                    color="success"
                                    size="lg"
                                    style={{
                                        marginRight: "5px"
                                    }}
                                    onClick={() => this.beginExecution(false)}
                                >
                                    EXECUTE
                                </Button>
                            </span>
                        </WithTooltip>
                    </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "50px" }} />
                {this.state.alert}
                <ContactSearchModal
                	open={this.state.contactSearchModal}
                	contacts={this.state.contacts}
                	onClose={() => this.handleModal("contactSearchModal", false)}
                	handleContactSelect={(key) => this.handleContactSelect(key, this.state.contactSearchType)}
                	contactSearchType={this.state.contactSearchType}
                	status={this.state.status}
                />
                <ProductSearchModal
                	open={this.state.productSearchModal}
                	onClose={() => this.handleModal("productSearchModal", false)}
                	products={this.state.products}
                	getProducts={() => this.getProducts(this.state.products)}
                	categoryColumns={prodCatCols}
                />
                <HistoryModal
                    title={"Int'l Shipment History"}
                    open={this.state.historyModal}
                    onClose={() => this.handleModal("historyModal", false)}
                    history={this.props.history}
                    getHistory={() => this.props.intlHistory}
                    columns={["Date", "User", "Action", "Message"]}
                />
            </GridContainer>
        )
    }
}

class CustomCounts extends Component {
    render() {
        const classes = this.props.classes || {};
        const executed = this.props.executed;

		return(
            <Paper className={classes.paper} elevation={1}>
                <h5>Custom Count</h5>
                <GridContainer style={{ padding: "5px 5px 5px 5px" }}>
                    <GridItem xs={12}>
                        <WithTooltip
                            title="Custom Unit Count"
                            content="Enter a custom count for the total number of handling units."
                        >
                            <span>
                                <InputLabel htmlFor="custom_pallet_count" className={classes.label}>
                                    Units
                                </InputLabel>
                            </span>
                        </WithTooltip>
                        <br />
                        <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "number",
                                id: "custom_pallet_count",
                                name: "custom_pallet_count",
                                value: this.props.custom_pallet_count || "",
                                onChange: e => this.props.handleInput("custom_pallet_count", e),
                                disabled: executed,
                            }}
                            white
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <WithTooltip
                                    title="Custom Product Type and Count"
                                    content="Enter a custom product type and count for the total number of products."
                                >
                                    <span>
                                        <InputLabel htmlFor="custom_piece_count" className={classes.label}>
                                            Products
                                        </InputLabel>
                                    </span>
                                </WithTooltip>
                            </GridItem>
                            <br/>
                            <GridItem xs={6}>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        type: "number",
                                        id: "custom_piece_count",
                                        name: "custom_piece_count",
                                        value: this.props.custom_piece_count || "",
                                        onChange: e => this.props.handleInput("custom_piece_count", e),
                                        disabled: executed,
                                    }}
                                    white
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <FormControl
                                    fullWidth className={classes.selectFormControl}>
                                    <Select
                                        MenuProps={{ className: classes.selectMenu }}
                                        classes={{ select: classes.select }}
                                        inputProps={{
                                            type: "text",
                                            id: "custom_unit_master_name",
                                            name: "custom_unit_master_name",
                                            value: this.props.custom_unit_master_name,
                                            onChange: e => this.props.handleInput("custom_unit_master_name", e),
                                            disabled: executed,
                                        }}
                                        disabled={executed}
                                    >
                                        {!_.isEmpty(this.props.uom) && !_.isEmpty(this.props.uom) && this.props.getUnitTypes(this.props.uom)}
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                        <WithTooltip
                            title="Custom Loose Count"
                            content="Enter a custom count for the total number of loose items."
                        >
                            <span>
                                <InputLabel htmlFor="custom_loose_count" className={classes.label}>
                                    Loose Items
                                </InputLabel>
                            </span>
                        </WithTooltip>
                        <br />
                        <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "number",
                                id: "custom_loose_count",
                                name: "custom_loose_count",
                                value: this.props.custom_loose_count || "",
                                onChange: e => this.props.handleInput("custom_loose_count", e),
                                disabled: executed,
                            }}
                            white
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <WithTooltip
                            title="Overall Piece Quantity"
                            content="Enter a custom count for the overall piece quantity."
                        >
                            <span>
                                <InputLabel htmlFor="custom_overall_piece_qty" className={classes.label}>
                                    Overall Piece Quantity
                                </InputLabel>
                            </span>
                        </WithTooltip>
                        <br />
                        <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "number",
                                id: "custom_overall_piece_qty",
                                name: "custom_overall_piece_qty",
                                value: this.props.custom_overall_piece_qty || "",
                                onChange: e => this.props.handleInput("custom_overall_piece_qty", e),
                                disabled: executed,
                            }}
                            white
                        />
                    </GridItem>
                </GridContainer>
            </Paper>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        openAlertModal,
		getIntlNotes,
        loadLevelsIfEmpty,
        getShipmentHistory
    }, dispatch);
}

const mapStateToProps = state => {
    return {
        userIsAdmin: userIsAdmin(state),
        hasPostPickupWrite: hasPermission("TMS_INTL_POST_PICKUP_WRITE")(state),
        hasProductRead: hasPermission("PRODUCT_CATALOG_READ")(state),
        hasProductWrite: hasPermission("PRODUCT_CATALOG_WRITE")(state),
		openNotesModal: state.InternationalNotes.modalOpen,
        intlHistory: state.InternationalLoadBoard.history,
        yatUrl: state.Environment.yat_index_url,
        userHasBolLevelPermission: !!hasPermission("USE_BOL_LEVEL")(state),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withSnackbar(International)));
