const initialState = {
	loading: false,
	bolNotesStatusesLoading: false,
	bolNotesCountLoading: false,
	bolsWithNewNotes: [],
	bolsWithNotes: {},
	transitStatusOpts: [],
	lateDeliveryOpts: [],
	latePickupOpts: [],
	postingPanelColumns: [],
	postingPanelDefaultColumns: [],
	refTypes: [],
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {

		case "SET_ARCHIVED_BILLS_OF_LADING_SET_LOADING": {
			return {...newState, loading: action.val};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_SET_BOL_NOTES_STATUS_LOADING": {
			return {...newState, bolNotesStatusesLoading: action.val};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_SET_BOL_NOTES_COUNT_LOADING": {
			return {...newState, bolNotesCountLoading: action.val};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_BOLS_WITH_NEW_NOTES": {
			return {...newState, bolsWithNewNotes: [...action.val]};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_BOLS_WITH_NOTES": {
			return {...newState, bolsWithNotes: {...action.val}};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_BOLS_TRANSIT_STATUS_OPTS": {
			return {...newState, transitStatusOpts: action.val};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_BOLS_LATE_DELIVERY_OPTS": {
			return {...newState, lateDeliveryOpts: action.val};
		}
		case "SET_ARCHIVED_BILLS_OF_LADING_BOLS_LATE_PICKUP_OPTS": {
			return {...newState, latePickupOpts: action.val};
		}
		case "SET_BOL_NOTES_ADDED_NOTE": {
			let tempBolsWithNotes = state.bolsWithNotes;
            Object.keys(tempBolsWithNotes).map(bol => {
                if (bol === action.val) {
                    tempBolsWithNotes[bol]++;
                }
            })
            return {...newState, bolsWithNotes: {...tempBolsWithNotes}};
		}
		case "SET_BOL_NOTES_DELETED_NOTE": {
			let tempBolsWithNotes = state.bolsWithNotes;
            Object.keys(tempBolsWithNotes).map(bol => {
                if (bol === action.val) {
                    tempBolsWithNotes[bol]--;
                }
            })
            return {...newState, bolsWithNotes: {...tempBolsWithNotes}};
		}
		case "CLEAR_ARCHIVED_BILLS_OF_LADING": {
			return initialState;
		}
		case "SET_POSTING_PANEL_COLUMNS": {
			return {...newState, postingPanelColumns: action.cols};
		}
		case "SET_POSTING_PANEL_DEFAULT_COLUMNS": {
			return {...newState, postingPanelDefaultColumns: action.cols};
		}
		case "SET_POSTING_PANEL_REF_TYPES": {
			return {...newState, refTypes: action.val};
		}
		default:
			return state;
	}
}