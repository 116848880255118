import axios from "~/variables/axios.jsx";
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';

export const setLookupSearchBy = val => {
	return {type: "UPDATE_SEARCH_BY", val};
}
export const setLookupSearchTerms = val => {
	return {type: "UPDATE_SEARCH_TERMS", val};
}
export const setLookupReferenceType = val => {
	return {type: "UPDATE_REFERENCE_TYPE", val};
}
export const setLookupReferenceTypes = val => {
	return {type: "UPDATE_REFERENCE_TYPES", val};
}

export const clearParcelLookup = () => {
    return dispatch => {
        dispatch(setLookupSearchBy('so'));
        dispatch(setLookupSearchTerms(''));
        dispatch(setLookupReferenceType(''));
        window.localStorage.setItem('searchBy', 'so');
        window.localStorage.setItem('referenceType', '');
    }
}

export const performLookup = (clear, search_by = '', search_terms = '', reference_type = '', searchingToCreate = false) => {
	return async dispatch => {
        let url = "/index.php?p=api&r=json&c=parcel&m=parcelPostingPanel&d=1/20";
        let search = "";
        
        if(searchingToCreate) {
            // searching to create requires search fields be provided but search from archived shipments does not.
            if(search_terms == '' || search_by == '' ) {
                    dispatch(setNotification("Search fields are required to perform lookup!", {variant: "error"}));
                    return false;	
                }
                if(search_by == 'customReference' && reference_type == '') {
                    dispatch(setNotification("A custom reference type is required when searching by custom references.", {variant: "error"}));
                    return false;	
                }
        }
        
        if(search_by == 'customReference' && reference_type !== '' && search_terms !== '') {
            search_terms = reference_type + '/' + search_terms;
        }
            
        if (clear !== true && search_by.length > 0 && search_terms.length > 0) {
            search = "&search_by=" + encodeURIComponent(search_by) + "&search_terms=" + encodeURIComponent(search_terms);
        }
        
        if(searchingToCreate) {
            search += "&create_shipment=" + encodeURIComponent(true);
        }

        try {
            const response = await axios.get(url + search);
            dispatch(handleMessages(response));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                const body = response.data.body;
                return {
                    current: body.current.toString() || "1",
                    last: body.last.toString() || "1",
                    result: Object.values(body.result) || [],
                    active_search: body.active_search || [],
                };
            }
        } catch (error) {
            console.error(error);
            dispatch(setNotification(error, {variant: "error"}));
        }
        return false;
    }
}
