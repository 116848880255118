import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import classnames from "classnames";
import { ReactTableDefaults } from 'react-table-6';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterQueryModal from "../../../components/FilterQueryModal/components/FilterQueryModal.jsx";
import allActions from "../../../components/FilterQueryModal/actions/FilterQueryModal.jsx";
import { setNotification } from "../../../redux/actions/notifications.jsx";
import { updateFilters } from "../actions/InternalCSTracking.jsx";

/**
 * Override the default component passed to react table for rendering pagination 
 * so that we can customize how it renders and add controls to it
 */
class CustomPagination extends ReactTableDefaults.PaginationComponent {

	/**
	 * Apply the preset filters
	 */
	handlePresetSelect = id => {
		const preset = this.getPresetFromId(id);
		if (preset) {
			try {
				let filters = JSON.parse(preset.filters);
				this.props.updateFilters(filters);
			} catch (err) {
				console.error(err);
				this.props.setNotification("Error getting filters from preset", {variant: "error"});
			}
		}
	}

	/**
	 * Find the preset that matches the provided ID
	 */
	getPresetFromId = id => {
		for (let i = 0; i < this.props.filterPresets.length; i++) {
			if (+id === +this.props.filterPresets[i].id) {
				return this.props.filterPresets[i];
			}
		}
		return null;
	}

	handleClearFilters = () => {
		this.props.updateFilters([]);
	}

	getAppliedPreset = () => {
		if (this.props.appliedFilters.length) {
			const filtersJson = JSON.stringify(this.props.appliedFilters);
			for (let i = 0; i < this.props.filterPresets.length; i++) {
				if (filtersJson === this.props.filterPresets[i].filters) {
					return this.props.filterPresets[i];
				}
			}
		}
		return null;
	}

	updateFilters = (filters) => {
		this.props.updateFilters(filters);
	}

	setFilterOptions = (text) => {
		switch(text) {
            case 'Company':
                this.props.setOptions(this.props.sortedCompanyOptions);
                break;
            case 'Warehouse':
                this.props.setOptions(this.props.sortedWarehouseOptions);
                break;
            case 'Code':
                this.props.setOptions(this.props.sortedCodeOptions);
                break;
            case 'Transit Status':
                this.props.setOptions(this.props.sortedTransitStatusOptions);
                break;
            case 'Carrier':
                this.props.setOptions(this.props.sortedCarrierOptions);
                break;
            case 'Importance Level':
                this.props.setOptions([
                    "low",
                    "medium",
                    "high",
                ]);
                break;
            case 'Direction/Terms':
                this.props.setOptions([
                    "Outbound/Prepaid",
                    "Outbound/Collect",
                    "Inbound/Collect",
                    "Third Party",
                ]);
                break;
            case 'BOL Status':
                this.props.setOptions([
                    "executed",
                    "hold",
                    "billed",
                    "third_party_payor",
                    "tfm_received",
                    "canceled",
                    "scheduled",
                    "auditing",
                    "ttl_confirmed",
                    "ttl_received",
                ]);
                break;
			default:
				this.props.setOptions(this.props.sortedCompanyOptions);
                break;
        }
	}

	render() {
		const {
	      pages,
	      page,
	      canPrevious,
	      canNext,
	      className,
	      renderCurrentPage,
	      renderTotalPagesCount,
	    } = this.props
	    const appliedPreset = this.getAppliedPreset();

	    const buttonStyle = {paddingTop: "0px", paddingBottom: "0px"};
		return (
			<Fragment>
		      	<div className={classnames(className, '-pagination')} style={{paddingRight: "5px", paddingLeft: "5px"}}>
		      		{this.props.filterPresetsLoading && (
		      			<span>Loading filter presets...<CircularProgress size={8}/></span>
		      		)}
		      		{(!this.props.filterPresetsLoading && !this.props.filterPresets.length) ? (
						<div>
							<FilterQueryModal
								headerData={this.props.columns}
								filters={this.props.filters}
								clearPreset={this.handleClearFilters}
								updateFilters={this.updateFilters}
								setFilterOptions={(text) => this.setFilterOptions(text)}
								fieldsWithLimitedOperators={this.props.fieldsWithLimitedOperators}
								fieldsWithAutoComplete={this.props.fieldsWithAutoComplete}
                                fieldsWithDate={this.props.fieldsWithDate}
								optionsLoading={this.props.optionsLoading}
							/>
							<span style={{color: "gray"}}>No filter presets found</span>
						</div>
		      		) : null}
		      		{(!this.props.filterPresetsLoading && this.props.filterPresets.length) ? (
		      			<div>
							<FilterQueryModal
								headerData={this.props.columns}
								filters={this.props.filters}
								clearPreset={this.handleClearFilters}
								updateFilters={this.updateFilters}
								setFilterOptions={(text) => this.setFilterOptions(text)}
								fieldsWithLimitedOperators={this.props.fieldsWithLimitedOperators}
								fieldsWithAutoComplete={this.props.fieldsWithAutoComplete}
                                fieldsWithDate={this.props.fieldsWithDate}
								optionsLoading={this.props.optionsLoading}
							/>
			      			<Select 
				      			disabled={this.props.tableLoading}
				      			labelId="filter-preset-label" 
				      			displayEmpty
				      			style={{minWidth: "120px"}} 
				      			value=""
				      			onChange={e => {this.handlePresetSelect(e.target.value)}}
				      		>
				      			<MenuItem value="" disabled>
						            Filter Presets
						        </MenuItem>
						        {this.props.filterPresets.map((preset, key) => {
						        	return (
						        		<MenuItem key={key} value={preset.id}>
								        	<div style={{maxWidth: "300px", padding: "5px"}}>
								        		<div><b>{preset.name}</b></div>
								        		{typeof preset.description === "string" && preset.description.length ? (
								        			<div style={{whiteSpace: "normal"}}>{preset.description}</div>
								        		) : null}
								        	</div>
								        </MenuItem>
						        	)
						        })}
				      		</Select>
				      		{!!this.props.appliedFilters.length && (
				      			<Button
				      				variant="outlined"
						        	style={buttonStyle}
						        	size="small"
									onClick={this.handleClearFilters}
						            disabled={this.props.tableLoading}
						         >
						         	Clear Filters
						        </Button>
				      		)}
				      		{!!appliedPreset && (
				      			<small style={{color: "gray", marginLeft: "5px"}}>{`Preset applied: ${appliedPreset.name}`}</small>
				      		)}
			      		</div>
		      		) : null}
		        	
		      		<div>
						<Button
							style={buttonStyle}
							title="Previous Page"
							size="small"
							onClick={() => {
				             	if (!canPrevious) return
				             	this.changePage(page - 1)
				            }}
				            disabled={!canPrevious || this.state.page < 1}
				         >
				         	<ArrowBackIcon />
				        </Button>
				        <span className="-pageInfo">
				            {this.props.pageText}{' '}
				            {renderCurrentPage(page)}{' '}
				            {this.props.ofText} {renderTotalPagesCount(pages)}
			        	</span>
				        <Button
				        	style={buttonStyle}
				        	title="Next Page"
				        	size="small"
							onClick={() => {
				            	if (!canNext) return
				             	this.changePage(page + 1)
				            }}
				            disabled={!canNext || this.state.page >= this.props.pages - 1}
				         >
				         	<ArrowForwardIcon />
				        </Button>
				    </div>
		      	</div>
	      </Fragment>
	    )
  	}
}

const mapStateToProps = state => {
	return {
		tableLoading: !!state.InternalCSTracking.internalCSLoading,
		appliedFilters: state.InternalCSTracking.filters,
		filterPresets: state.InternalCSTracking.filterPresets,
		filterPresetsLoading: !!state.InternalCSTracking.filterPresetsLoading,
		filters: state.InternalCSTracking.filters,
		sortedCompanyOptions: state.InternalCSTracking.sortedCompanyOptions,
        sortedWarehouseOptions: state.InternalCSTracking.sortedWarehouseOptions,
        sortedCodeOptions: state.InternalCSTracking.sortedCodeOptions,
        sortedCarrierOptions: state.InternalCSTracking.sortedCarrierOptions,
        sortedTransitStatusOptions: state.InternalCSTracking.sortedTransitStatusOptions,
        fieldsWithLimitedOperators: state.InternalCSTracking.fieldsWithLimitedOperators,
		fieldsWithAutoComplete: state.InternalCSTracking.fieldsWithAutoComplete,
		fieldsWithDate: state.InternalCSTracking.fieldsWithDate,
		optionsLoading: state.InternalCSTracking.optionsLoading,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setNotification,
		updateFilters,
		...allActions,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPagination);