import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import _ from "lodash";
import axios from "~/variables/axios.jsx";
import qs from "qs";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import "formdata-polyfill";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";

// material ui icons
import Check from "@material-ui/icons/Check";
import Save from "@material-ui/icons/Save";
import Undo from "@material-ui/icons/Undo";
import Edit from "@material-ui/icons/Edit";
import Clear from "@material-ui/icons/Clear";
import Person from "@material-ui/icons/Person";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class CarrierInfo extends Component {

    state = this.props.data;

	constructor(props) {
        super(props);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
        this.getImage = this.getImage.bind(this);
        this.saveCarrier = this.saveCarrier.bind(this);
		this.loadNotes = this.loadNotes.bind(this);
		this.getNotes = this.getNotes.bind(this);
		this.addNote = this.addNote.bind(this);
		this.handleNote = this.handleNote.bind(this);
		this.handleEditNote = this.handleEditNote.bind(this);
		this.cancelNote = this.cancelNote.bind(this);
		this.saveNote = this.saveNote.bind(this);
		this.handleDeleteNote = this.handleDeleteNote.bind(this);
		this.deleteNote = this.deleteNote.bind(this);
    }

    async componentDidMount() {
        if(this.props.newScac) {
            const url = "/index.php?p=api&r=json&c=admin&m=newScac";
            try {
                const response = await axios.get(url);
                const { data } = response;
                if (typeof data !== "string" && !_.isEmpty(data.message)) {
                    this.props.handleMessages(response);
                }
                if (typeof data !== "string" && !_.isEmpty(data.user)) {
                    this.setState({
                        show: true,
                        loading: false,
                        user: data.user,
                        body: data.body
                    });
                } else {
                    this.setState({ loading: false });
                    this.props.setNotification("There was an error loading the data!", {
                        variant: "error"
                    });
                }
            } catch (error) {
                console.error(error);
                this.setState({ loading: false });
                this.props.setNotification("There was an error loading the data!", {
                    variant: "error"
                });
            }
        } else {
            const url = "/index.php?p=api&r=json&c=admin&m=editScac&d=" + this.state.id;
            try {
                const response = await axios.get(url);
                const { data } = response;
                if (typeof data !== "string" && !_.isEmpty(data.message)) {
                    this.props.handleMessages(response);
                }
                if (typeof data !== "string" && !_.isEmpty(data.user) && !_.isEmpty(data.body) && !_.isEmpty(data.body[0])) {
                    const body = data.body[0];
                    // this.props.pageTitle('Edit Carrier - ' + body.carrier);
                    this.setState({
                        show: true,
                        loading: false,
                        user: data.user,
                        body: data.body,
                        scac: body.scac || "",
                        scac_master_id: body.id || "",
                        carrier: body.carrier || "",
                        phone: body.phone || "",
                        email: body.email || "",
                        volume: body.max_cube || "",
                        density: body.min_pcf || "",
                        server: body.server || "",
                        ftp_user: body.ftp_user || "",
                        ftp_pass: body.ftp_pass || "",
                        api_user: body.api_user || "",
                        api_pass: body.api_pass || "",
                        logo_file: body.logo_file || "",
                        api_tracking: body.api_tracking == 1,
                        pickup_setting: (body.api_pickup == 1) ? 'api' : (body.email_pickup == 1) ? 'email' : 'disabled',
                        api_pickup: body.api_pickup == 1,
                        email_pickup: body.email_pickup == 1,
                        auto_pro: body.auto_pro == 1,
                        auto_pro_type: body.auto_pro_type || "",
                        use_parcel: body.use_parcel == 1,
                        p44_api_tracking: body.p44_api_tracking == 1,
                        p44_api_pickup: body.p44_api_pickup == 1,
                        p44_exclude_pickup: body.p44_exclude_pickup == 1,
                        account_require_username: body.account_require_username == 1,
                        account_require_password: body.account_require_password == 1,
                        account_require_account_number: body.account_require_account_number == 1,
                        account_require_edi_reference_number: body.account_require_edi_reference_number == 1,
                        account_require_license_key: body.account_require_license_key == 1,
						scac_alias_map: body.scac_alias_map || [],
                    }, () => {
	                    this.loadNotes();
						this.handleScacMappings();
                    });
                } else {
                    this.setState({ loading: false });
                    this.props.setNotification("There was an error loading the data!", {
                        variant: "error"
                    });
                }
            } catch (error) {
                console.error(error);
                this.setState({ loading: false });
                this.props.setNotification("There was an error loading the data!", {
                    variant: "error"
                });
            }
        }
	}


	handleChecked = name => event => {
		if(name == "p44_api_pickup" && this.state.pickup_setting != "api") {
			this.props.setNotification("You must select API pickups before project44 pickups can be enabled.", {
				variant: "error"
			});
			return;
		}
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		if(event.target.name == "pickup_setting" && event.target.value != "api") {
			this.setState({
				p44_api_pickup: false,
				[event.target.name]: event.target.value
			})
		} else {
			this.setState({ [event.target.name]: event.target.value });
		}
	}

	handleUpload(files) {
		const logo_file = files[0];
		this.setState({ logo_file });
	}

    async saveCarrier(newScac) {

		const carrierData = new FormData(); // FormData is needed to upload files

		carrierData.append("scac", this.state.scac);
		carrierData.append("carrier", this.state.carrier);
		carrierData.append("phone", this.state.phone);
		carrierData.append("email", this.state.email.replace(/\s/g,''));
		carrierData.append("volume", this.state.volume);
		carrierData.append("density", this.state.density);
		carrierData.append("server", this.state.server);
		carrierData.append("ftp_user", this.state.ftp_user);
		carrierData.append("ftp_pass", this.state.ftp_pass);
		carrierData.append("api_user", this.state.api_user);
		carrierData.append("api_pass", this.state.api_pass);
		carrierData.append("logo_file", this.state.logo_file);
		carrierData.append("auto_pro_type", this.state.auto_pro_type);

		if (this.state.api_tracking) {
			carrierData.append("api_tracking", "1");
		}
		if (this.state.auto_pro) {
			carrierData.append("auto_pro", "1");
		}
		if (this.state.use_parcel) {
			carrierData.append("use_parcel", "1");
		}
		if (this.state.p44_api_pickup) {
			carrierData.append("p44_api_pickup", "1");
		}
		if (this.state.p44_api_tracking) {
			carrierData.append("p44_api_tracking", "1");
		}
		if (this.state.p44_exclude_pickup) {
			carrierData.append("p44_exclude_pickup", "1");
		}
		if (this.state.p44_parent_scac.trim()) {
			carrierData.append("p44_parent_scac", this.state.p44_parent_scac.trim());
		}
		if (this.state.account_require_username) {
			carrierData.append("account_require_username", "1");
		}
		if (this.state.account_require_password) {
			carrierData.append("account_require_password", "1");
		}
		if (this.state.account_require_account_number) {
			carrierData.append("account_require_account_number", "1");
		}
		if (this.state.account_require_edi_reference_number) {
			carrierData.append("account_require_edi_reference_number", "1");
		}
		if (this.state.account_require_license_key) {
			carrierData.append("account_require_license_key", "1");
		}
		if(this.state.actual_scac.trim()) {
			carrierData.append("actual_scac", this.state.actual_scac.trim());
		}
		if(this.state.scac_map_pickups) carrierData.append("scac_map_pickups", "1");
		if(this.state.scac_map_tracking) carrierData.append("scac_map_tracking", "1");
		if(this.state.scac_map_p44) carrierData.append("scac_map_p44", "1");
		if(this.state.scac_map_carrier_connect) carrierData.append("scac_map_carrier_connect", "1");
		if(this.state.scac_map_auto_pro) carrierData.append("scac_map_auto_pro", "1");

		let mappingScac = false;
		for(const key of carrierData.keys()) {
			if(key.indexOf('scac_map_') !== -1)  {
				mappingScac = true;
				if(!carrierData.has('actual_scac')) {
					this.props.setNotification("Please provide a value for Actual SCAC to apply selected mapping", { variant: "error" });
					return;
				}
			}
		}

		if(carrierData.has('actual_scac') && !mappingScac) {
			this.props.setNotification("Please select the features the Actual SCAC should be used for", { variant: "error" });
			return;
		}

		if (this.state.pickup_setting == "api") {
			carrierData.append("api_pickup", "1");
		} else if (this.state.pickup_setting == "email") {
			if (this.state.email.length == 0) {
				this.props.setNotification("Email pickups requires an email address.", {
					variant: "error"
				});
				return;
			}
			carrierData.append("email_pickup", "1");
		}

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		const url = newScac ? "/index.php?p=api&r=json&c=admin&m=newScac" : "/index.php?p=api&r=json&c=admin&m=editScac&d=" + this.state.scac_master_id;

		try {
			const response = await axios.post(url, carrierData, config);
			const { data } = response;
			if(typeof data !== "string" && typeof data.message !== 'undefined') {
				this.props.handleMessages(response);
				if (data.body) {
					this.setState({ saved: true });
				}
			} else {
				this.props.setNotification("Unexpected response received from the server!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the carrier!", {
				variant: "error"
			});
		}

	}

	handleDelete() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure?"
					onConfirm={() => this.setState({ alert: null, logo_file: "" })}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this file!
				</SweetAlert>
			)
		});
	}

	getImage(prop) {
        const src = this.props.newScac ? (!_.isEmpty(prop.preview) ? prop.preview : "#") : (!_.isEmpty(prop.preview) ? prop.preview : apiUrl + "/getLogo.php?logo=" + prop);
		const filename = !_.isEmpty(prop.filename) ? prop.filename : !_.isEmpty(prop.name) ? prop.name : prop;
		const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
		return <Preview src={src} index={0} extension={extension} handleClick={() => {}} handleDelete={this.handleDelete.bind(this)} />;
    }

	async loadNotes() {
		const url = "/index.php?p=api&r=json&c=admin&m=getScacNotes&d=" + this.state.id + "/";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({ notes: !_.isEmpty(response.data.body) ? response.data.body : [] });
			} else {
				this.props.setNotification("There was an error loading the notes!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the notes!", { variant: "error" });
		}
	}

	getNotes(data) {
		const { classes } = this.props;
		const types = {
			general: "General",
			autopro: "Auto-Pro",
			pur: "Pickup Request"
		};
		return data.map((prop, key) => {
			prop.id = prop.id || "";
			prop.edit = prop.edit || false;
			if (prop.id === "") {
				return (
					<GridItem xs={12} key={key} style={{ margin: "10px 0px" }}>
						<Paper className={classes.paper} elevation={1}>
							<GridContainer>
								<GridItem xs={6}>
									<Chip icon={<Person />} label={prop.username} />
									{" - "}
									<FormControl style={{ width: "175px", marginRight: "8px" }} className={classes.selectFormControl}>
										<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.type} inputProps={{ name: "type" }} onChange={e => this.handleNote("type", e)}>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value=""
											/>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="general"
											>
												General
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="autopro"
											>
												Auto-Pro
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="pur"
											>
												Pickup Request
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={6} className={classes.right}>
									<Button justIcon round size="sm" color="warning" className={classes.marginRight} onClick={() => this.cancelNote()}>
										<Undo />
									</Button>
									<Button justIcon round size="sm" color="success" onClick={() => this.saveNote(key)}>
										<Save />
									</Button>
								</GridItem>
								<GridItem xs={12} style={{ marginTop: "10px" }}>
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "note",
											value: prop.note || "",
											multiline: true,
											onChange: e => this.handleNote("note", e)
										}}
										white
									/>
								</GridItem>
							</GridContainer>
						</Paper>
					</GridItem>
				);
			} else {
				if (prop.edit) {
					return (
						<GridItem xs={12} key={key} style={{ margin: "10px 0px" }}>
							<Paper className={classes.paper} elevation={1}>
								<GridContainer>
									<GridItem xs={6}>
										<Chip icon={<Person />} label={prop.username} />
										{" - "}
										<FormControl style={{ width: "175px", marginRight: "8px" }} className={classes.selectFormControl}>
											<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.type} inputProps={{ name: "type" }} onChange={e => this.handleNote("type", e)}>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value=""
												/>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="general"
												>
													General
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="autopro"
												>
													Auto-Pro
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="pur"
												>
													Pickup Request
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={6} className={classes.right}>
										<Button justIcon round size="sm" color="warning" className={classes.marginRight} onClick={() => this.handleEditNote(key, false)}>
											<Undo />
										</Button>
										<Button justIcon round size="sm" color="success" onClick={() => this.saveNote(key)}>
											<Save />
										</Button>
									</GridItem>
									<GridItem xs={12} style={{ marginTop: "10px" }}>
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "note",
												value: prop.note || "",
												multiline: true,
												onChange: e => this.handleNote("note", e)
											}}
											white
										/>
									</GridItem>
								</GridContainer>
							</Paper>
						</GridItem>
					);
				} else {
					let type = prop.type;
					switch (prop.type) {
						case "general":
						case "autopro":
						case "pur":
							type = types[prop.type];
							break;
					}
					const note = prop.note.replace(/(?:\r\n|\r|\n)/g, "<br />");
					return (
						<GridItem xs={12} key={key} style={{ margin: "10px 0px" }}>
							<Paper className={classes.paper} elevation={1}>
								<GridContainer>
									<GridItem xs={6}>
										<Chip icon={<Person />} label={prop.username} />
										{" - " + type}
									</GridItem>
									<GridItem xs={6} className={classes.right}>
										<i>{prop.create_time}</i>
										<Button justIcon round size="sm" color="info" className={classes.marginLeft} onClick={() => this.handleEditNote(key, true)}>
											<Edit />
										</Button>
										{prop.user_credential_id == this.state.user.id && (
											<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleDeleteNote(key, prop.id)}>
												<Clear />
											</Button>
										)}
									</GridItem>
									<GridItem xs={12} style={{ marginTop: "10px" }}>
										<div dangerouslySetInnerHTML={{ __html: note }} />
									</GridItem>
								</GridContainer>
							</Paper>
						</GridItem>
					);
				}
			}
		});
	}

	addNote() {
		const { notes } = this.state;
		notes.unshift({
			id: "",
			type: "",
			note: "",
			username: this.state.user.name,
			user_credential_id: this.state.user.id,
			create_time: ""
		});
		this.setState({ notes });
	}

	handleEditNote(i, edit) {
		const { notes } = this.state;
		if (notes.length) {
			const note = notes[i];
			note.edit = edit;
			notes[i] = note;
			this.setState({ notes });
		}
	}

	handleNote(name, event) {
		const { notes } = this.state;
		if (notes.length) {
			const note = notes[0];
			note[name] = event.target.value;
			notes[0] = note;
			this.setState({ notes });
		}
	}

	cancelNote() {
		const { notes } = this.state;
		notes.shift();
		this.setState({ notes });
	}

	async saveNote(i) {
		const { notes, id } = this.state;

		const data = {
			id: notes[i].id,
			note: notes[i].note,
			type: notes[i].type,
			scacMasterId: id
		};

		const url = "/index.php?p=api&r=json&c=admin&m=saveScacNote";

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				this.loadNotes();
			} else {
				this.props.setNotification("There was an error saving the note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the note!", { variant: "error" });
		}
	}

	handleDeleteNote(key, id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this note?"
					onConfirm={() => this.deleteNote(key, id)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					It cannot be recovered!
				</SweetAlert>
			)
		});
	}

	async deleteNote(key, id) {
		this.setState({ alert: null });

		const url = "/index.php?p=api&r=json&c=admin&m=deleteScacNote&d=" + id;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				const { notes } = this.state;
				notes.splice(key, 1);
				this.setState({ notes });
			} else {
				this.props.setNotification("There was an error deleting the note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the note!", { variant: "error" });
		}
	}

	handleScacMappings() {
		let {
			scac_alias_map,
			p44_parent_scac,
			actual_scac,
			scac_map_pickups,
			scac_map_tracking,
			scac_map_p44,
			scac_map_carrier_connect,
			scac_map_auto_pro
		} = this.state;

		if(!_.isEmpty(scac_alias_map)) {
			scac_alias_map.forEach(map => {
				// Handle this one differently, it is allowed to be different
				if(map.place === 'project44PickupParentScac') {
					p44_parent_scac = map.convert_scac_to;
				} else {
					// All other mappings should be converting to the same SCAC, not supporting otherwise at this time
					if(actual_scac === '') actual_scac = map.convert_scac_to;
					switch(map.place) {
						case 'pickupRequest':
							scac_map_pickups = true;
							break;
						case 'tracking':
							scac_map_tracking = true;
							break;
						case 'project44':
							scac_map_p44 = true;
							break;
						case 'carrierConnectModel':
							scac_map_carrier_connect = true;
							break;
						case 'assignProNumber':
							scac_map_auto_pro = true;
							break;
					}
				}
			});
		}
		this.setState({
			p44_parent_scac,
			actual_scac,
			scac_map_pickups,
			scac_map_tracking,
			scac_map_p44,
			scac_map_carrier_connect,
			scac_map_auto_pro
		});
	}

    render() {

		if (this.state.saved) {
			const redirectTo = basePath + "/admin/management/carriers";
			return <Redirect to={redirectTo} />;
		}

        const classes = this.props.classes || {};

        return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Name</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>SCAC</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "scac",
													value: this.state.scac || "",
													onChange: this.handleInput("scac")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={8}>
											<InputLabel className={classes.label}>Carrier</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "carrier",
													value: this.state.carrier || "",
													onChange: this.handleInput("carrier")
												}}
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Contact</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<InputLabel className={classes.label}>800 #</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "phone",
													value: this.state.phone || "",
													onChange: this.handleInput("phone")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<InputLabel className={classes.label}>Email</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "email",
													value: this.state.email || "",
													onChange: this.handleInput("email")
												}}
												helpText="Enter email addresses separated by commas."
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>FTP</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>FTP Server</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "server",
													value: this.state.server || "",
													onChange: this.handleInput("server")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>FTP Username</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "ftp_user",
													value: this.state.ftp_user || "",
													onChange: this.handleInput("ftp_user")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>FTP Password</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "password",
													name: "ftp_pass",
													value: this.state.ftp_pass || "",
													onChange: this.handleInput("ftp_pass")
												}}
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>API</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<InputLabel className={classes.label}>API Username</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "api_user",
													value: this.state.api_user || "",
													onChange: this.handleInput("api_user")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<InputLabel className={classes.label}>API Password</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "password",
													name: "api_pass",
													value: this.state.api_pass || "",
													onChange: this.handleInput("api_pass")
												}}
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Pickups and Tracking</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>Pickups</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.pickup_setting || ""}
													inputProps={{
														id: "pickup_setting",
														name: "pickup_setting"
													}}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="disabled"
													>
														{"Disabled"}
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="api"
													>
														{"API"}
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="email"
													>
														{"Email"}
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.api_tracking}
														tabIndex={-1}
														onChange={this.handleChecked("api_tracking")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable API Tracking"
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Limits</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={3}>
											<InputLabel className={classes.label}>Max. Cubic Ft.</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "volume",
													value: this.state.volume || "",
													onChange: this.handleInput("volume")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={3}>
											<InputLabel className={classes.label}>Min. PCF</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "density",
													value: this.state.density || "",
													onChange: this.handleInput("density")
												}}
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Auto-PRO</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>Auto-PRO Assignment Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.auto_pro_type || ""}
													inputProps={{
														id: "auto_pro_type",
														name: "auto_pro_type"
													}}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="general"
													>
														{"General Use"}
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="warehouse"
													>
														{"Assign By Warehouse (Outbound Only)"}
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="terminal"
													>
														{"Assign By Terminal"}
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.auto_pro}
														tabIndex={-1}
														onChange={this.handleChecked("auto_pro")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Auto-PRO"
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Company Account Settings</strong></h5>
										</GridItem>
										<GridItem xs={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.account_require_username}
														tabIndex={-1}
														onChange={this.handleChecked("account_require_username")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require Username"
											/>
										</GridItem>
										<GridItem xs={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.account_require_password}
														tabIndex={-1}
														onChange={this.handleChecked("account_require_password")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require Password"
											/>
										</GridItem>
										<GridItem xs={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.account_require_account_number}
														tabIndex={-1}
														onChange={this.handleChecked("account_require_account_number")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require Account #"
											/>
										</GridItem>
										<GridItem xs={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.account_require_edi_reference_number}
														tabIndex={-1}
														onChange={this.handleChecked("account_require_edi_reference_number")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require EDI Reference #"
											/>
										</GridItem>
										<GridItem xs={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.account_require_license_key}
														tabIndex={-1}
														onChange={this.handleChecked("account_require_license_key")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require License Key"
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>SCAC Mapping</strong></h5>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<InputLabel className={classes.label}>
												Actual SCAC
												<WithTooltip content="Leave blank if main SCAC is not an alias. Otherwise, enter the carrier's real SCAC. This SCAC will be used for features and tools checked below.">
													<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
												</WithTooltip>
											</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "actual_scac",
													value: this.state.actual_scac || "",
													onChange: this.handleInput("actual_scac")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={0} sm={8}></GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.scac_map_pickups}
														tabIndex={-1}
														onChange={this.handleChecked("scac_map_pickups")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Pickups (Legacy)
														<WithTooltip content="Use the actual SCAC for pickups requested through a direct connection with the carrier's API.">
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.scac_map_tracking}
														tabIndex={-1}
														onChange={this.handleChecked("scac_map_tracking")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Tracking (Legacy)
														<WithTooltip content="Use the actual SCAC when manually requesting tracking data through the Track Shipment tool.">
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.scac_map_p44}
														tabIndex={-1}
														onChange={this.handleChecked("scac_map_p44")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Project44
														<WithTooltip content="Use the actual SCAC for any requests made through Project44. This including pickup, tracking, and image requests.">
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.scac_map_carrier_connect}
														tabIndex={-1}
														onChange={this.handleChecked("scac_map_carrier_connect")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Carrier Connect
														<WithTooltip content="Use the actual SCAC when some of SMC3's Carrier Connect features. This includes looking up transit information using the Transit Info tool.">
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.scac_map_auto_pro}
														tabIndex={-1}
														onChange={this.handleChecked("scac_map_auto_pro")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Auto-PRO
														<WithTooltip content="Use the actual SCAC when using the Auto-PRO feature and setting PRO ranges.">
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Project44</strong></h5>
										</GridItem>
										<GridItem xs={12} lg={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.p44_api_pickup}
														tabIndex={-1}
														onChange={this.handleChecked("p44_api_pickup")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Enable Pickups
														<WithTooltip content="Requires API to be selected for Pickups">
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
												disabled={this.state.pickup_setting != "api"}
											/>
										</GridItem>
										<GridItem xs={12} lg={4}>
											<FormControl>
												<FormControlLabel
													control={
														<Checkbox
															checked={this.state.p44_api_tracking}
															tabIndex={-1}
															onChange={this.handleChecked("p44_api_tracking")}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot
															}}
														/>
													}
													classes={{
														label: classes.label
													}}
													label="Enable Tracking"
												/>
											</FormControl>
										</GridItem>
										<GridItem xs={12} lg={4}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.p44_exclude_pickup}
														tabIndex={-1}
														onChange={this.handleChecked("p44_exclude_pickup")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Exclude Pickup #
														<WithTooltip
															content="Continue to receive a pickup number, but don't include it in tracking efforts. This may improve tracking for carriers with non-unique pickup numbers"
														>
															<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} lg={4}>
											<InputLabel className={classes.label}>
												Parent SCAC
												<WithTooltip content="This only pertains to pickup requests. In certain rare cases, Project44 requires a carrier's parent SCAC. If carrier also has an Actual SCAC that is being used with Project44, this Parent SCAC will override it.">
													<InfoOutlined fontSize="small" style={{ marginLeft: "5px" }} />
												</WithTooltip>
											</InputLabel>
											<br />
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "p44_parent_scac",
													value: this.state.p44_parent_scac || "",
													onChange: this.handleInput("p44_parent_scac")
												}}
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} lg={6} style={{marginTop:"20px"}}>
									<GridContainer>
										<GridItem xs={12}>
											<h5><strong>Logo Image</strong></h5>
										</GridItem>
										<GridContainer style={{marginLeft:"0px"}}>
											<GridItem xs={12} sm={8}>
												<Dropzone handleUpload={this.handleUpload} />
											</GridItem>
											{!_.isEmpty(this.state.logo_file) && (
												<GridItem xs={12} sm={4}>
													<aside className={classes.thumbsContainer}>{this.getImage(this.state.logo_file)}</aside>
												</GridItem>
											)}
										</GridContainer>
									</GridContainer>
								</GridItem>
                                {!this.props.newScac && (
                                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop:"20px"}}>
									<h5><strong>Notes</strong></h5>
									<GridContainer>
										<GridItem xs={12}>
											<Button color="linkedin" onClick={this.addNote} disabled={!_.isEmpty(this.state.notes[0]) && this.state.notes[0].id === ""}>
												New Note
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer justify="center">
										{!_.isEmpty(this.state.notes) ? (
											this.getNotes(this.state.notes)
										) : (
											<GridItem xs={12} className={classes.center}>
												<Badge color="info">No Notes</Badge>
											</GridItem>
										)}
									</GridContainer>
								</GridItem>
                                )}
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<Button
										color="info"
										style={{
											marginRight: "5px",
											marginTop: "20px"
										}}
										onClick={() => this.saveCarrier(this.props.newScac)}
									>
										Save SCAC
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(CarrierInfo)));