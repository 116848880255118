import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import _ from "lodash";
import axios from "~/variables/axios.jsx";
import qs from "qs";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import "formdata-polyfill";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import AddCircle from "@material-ui/icons/AddCircle";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class AdditionalContacts extends Component {

    state = this.props.data;

	constructor(props) {
        super(props);
        this.state = {
            mounted: false,
		};
    }

    async componentDidMount() {
		this.setState({mounted:true});
	}

	renderAdditionalContacts() {
		const { classes } = this.props;

		let additionalContacts = this.props.additional_tracking_emails.map((prop, key) => {
			return (
				<React.Fragment key={key}>
					<br />
					<GridItem xs={5} sm={5} md={10}>
						<CustomInput
							id={"additional_tracking_emails[" + key + "]"}
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "Additional Emails",
								value: prop || "",
								onChange: this.props.handleTrackingEmailInput(key),
								startAdornment: (
									<InputAdornment position="start">
										<AlternateEmail />
									</InputAdornment>
								),
							}}
							white
						/>
					</GridItem>
					<GridItem xs={1} sm={1} md={1}>
						<Button color="reddit" size="sm" justIcon round className={classes.marginRight} onClick={() => this.props.removeTrackingEmail(key)} disabled={this.props.updating_tracking}>
							<Delete/>
						</Button>
					</GridItem>
				</React.Fragment>

			);
		});

		additionalContacts.push(
			<GridItem xs={5} sm={5} md={10}>
				<Button color="linkedin" onClick={this.props.handleTrackingEmailInput()} style={{ marginBottom: "10px" }} className={classes.marginRight} disabled={this.props.additional_tracking_emails.length >= 10}>
					<AddCircle /> Add Another Tracking Email
				</Button>
			</GridItem>
		);
		return additionalContacts;
	}

	render() {
		if(this.state.mounted) {
			return (
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
					<WithTooltip title={`Additional Emails`} content={`Enter an additional contact for tracking link. NOTE: The consignee contact email is also sent the tracking link.`}>
						<h5>Additional Tracking Link Recipients</h5>
					</WithTooltip>
					</GridItem>
					{this.renderAdditionalContacts()}
				</GridContainer>
			)
		}
		return false;
	}
}

export default withStyles(style)(withSnackbar(AdditionalContacts));