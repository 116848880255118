import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import Phone from "@material-ui/icons/Phone";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Person from "@material-ui/icons/Person";

import { FaFax } from "react-icons/fa";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import { capitalizePhrase } from "../../helpers.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ContactFields extends React.Component {
    render() {
        const classes = this.props.classes || {};
        const entity = this.props.entity || "entity";
        const capitalized = capitalizePhrase(entity);
        let disabled = this.props.disabled;
        if(this.props.enable_executed_edit) {
            disabled = false;
        }
        return (
            <GridContainer>
                <GridItem xs={6} sm={6} md={12}>
                    <WithTooltip title={`${capitalized} Contact Name`} content={`Enter the ${entity} contact name.`}>
                        <InputLabel className={classes.label}>Name</InputLabel>
                    </WithTooltip>
                    <br />
                    <CustomInput
                        id={this.props.nameField}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            type: "text",
                            name: this.props.nameField,
                            value: this.props.nameValue || "",
                            onChange: this.props.onChangeName,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            ),
                            disabled: disabled
                        }}
                        required={this.props.required}
                        white
                    />
                </GridItem>
                <GridItem xs={6} sm={6} md={12}>
                    <WithTooltip title={`${capitalized} Phone Number`} content={`Enter the ${entity} phone number.`}>
                        <InputLabel className={classes.label}>Phone</InputLabel>
                    </WithTooltip>
                    <br />
                    <CustomInput
                        id={this.props.phoneField}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            type: "text",
                            name: this.props.phoneField,
                            value: this.props.phoneValue || "",
                            onChange: this.props.onChangePhone,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Phone />
                                </InputAdornment>
                            ),
                            disabled: disabled
                        }}
                        required={this.props.required}
                        white
                    />
                </GridItem>
                <GridItem xs={6} sm={6} md={12}>
                    <WithTooltip title={`${capitalized} Email Address`} content={`Enter the ${entity} email address.`}>
                        <InputLabel className={classes.label}>Email</InputLabel>
                    </WithTooltip>
                    <br />
                    <CustomInput
                        id={this.props.emailField}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            type: "text",
                            name: this.props.emailField,
                            value: this.props.emailValue || "",
                            onChange: this.props.onChangeEmail,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AlternateEmail />
                                </InputAdornment>
                            ),
                            disabled: disabled
                        }}
                        required={this.props.required}
                        white
                    />
                </GridItem>
                <GridItem xs={6} sm={6} md={12}>
                    <WithTooltip title={`${capitalized} Fax Number`} content={`Enter the ${entity} fax number.`}>
                        <InputLabel className={classes.label}>Fax</InputLabel>
                    </WithTooltip>
                    <br />
                    <CustomInput
                        id={this.props.faxField}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            type: "text",
                            name: this.props.faxField,
                            value: this.props.faxValue|| "",
                            onChange: this.props.onChangeFax,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaFax />
                                </InputAdornment>
                            ),
                            disabled: disabled
                        }}
                        white
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(style)(ContactFields);