import React, { Component, Fragment } from "react";
import GridContainer from "~/components/Grid/GridContainer.jsx";

class TopActions extends Component {
	render() {
		return (
			<GridContainer style={{marginTop: "5px"}}>
				{this.props.children}
			</GridContainer>
		);
	}
}

export default TopActions;
