import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import { capitalizePhrase } from "../../helpers.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class AddressFields extends React.Component {
    render() {
        const classes = this.props.classes || {};
        const entity = this.props.entity || "entity";
        const capitalized = capitalizePhrase(entity);
        let disabled = this.props.disabled;
        if(this.props.enable_executed_edit) {
            disabled = false;
        }
        return (
            <React.Fragment>
                <GridItem xs={12}>
                    <GridContainer>
                        <GridItem xs>
                            <WithTooltip title={`${capitalized} Name`} content={`Enter the ${entity} name.`}>
                                <InputLabel className={classes.label}>Name</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.nameField}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.nameField,
                                    value: this.props.nameValue || "",
                                    onChange: this.props.onChangeName,
                                    disabled: disabled ? true : false,
                                }}
                                required
                            />
                        </GridItem>
                        <GridItem xs>
                            <WithTooltip title={`${capitalized} Address`} content={`Enter the ${entity} address.`}>
                                <InputLabel className={classes.label}>Address 1</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.address1Field}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.address1Field,
                                    value: this.props.address1Value || "",
                                    onChange: this.props.onChangeAddress1,
                                    disabled: disabled ? true : false,
                                }}
                                required
                            />
                        </GridItem>
                        <GridItem xs>
                            <WithTooltip title={`${capitalized} Extended Address`} content={`Enter the ${entity} extended address.`}>
                                <InputLabel className={classes.label}>Address 2</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.address2Field}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.address2Field,
                                    value: this.props.address2Value || "",
                                    onChange: this.props.onChangeAddress2,
                                    disabled: disabled ? true : false,
                                }}
                                white
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                    <GridContainer>
                        <GridItem xs>
                            <WithTooltip title={`${capitalized} Postal Code`} content={`Enter the ${entity} postal code.`}>
                                <InputLabel className={classes.label}>Zip</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.zipField}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.zipField,
                                    value: this.props.zipValue || "",
                                    onChange: this.props.onChangeZip,
                                    disabled: disabled ? true : false,
                                }}
                                required
                            />
                        </GridItem>
                        <GridItem xs>
                            <WithTooltip title={`${capitalized} City`} content={`Enter the ${entity} city.`}>
                                <InputLabel className={classes.label}>City</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.cityField}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.cityField,
                                    value: this.props.cityValue || "",
                                    onChange: this.props.onChangeCity,
                                    disabled: disabled ? true : false,
                                }}
                                required
                            />
                        </GridItem>
                        <GridItem xs={2}>
                            <WithTooltip title={`${capitalized} State`} content={`Enter the ${entity} state.`}>
                                <InputLabel className={classes.label}>State</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.stateField}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.stateField,
                                    value: this.props.stateValue || "",
                                    onChange: this.props.onChangeState,
                                    disabled: disabled ? true : false,
                                }}
                                required
                            />
                        </GridItem>
                        <GridItem xs={2}>
                            <WithTooltip title={`${capitalized} Country`} content={`Enter the ${entity} country.`}>
                                <InputLabel className={classes.label}>Country</InputLabel>
                            </WithTooltip>
                            <br />
                            <CustomInput
                                id={this.props.countryField}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    name: this.props.countryField,
                                    value: this.props.countryValue || "",
                                    onChange: this.props.onChangeCountry,
                                    onBlur: this.props.onBlurCountry ? this.props.onBlurCountry : null,
                                    disabled: disabled ? true : false,
                                }}
                                required
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </React.Fragment>
        );
    }
}

export default withStyles(style)(AddressFields);