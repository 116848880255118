import React from "react";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";

import cx from "classnames";
import qs from "qs";

import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { basePath, apiUrl } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import moment from "moment";
import "formdata-polyfill";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

// material ui icons
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import Add from "@material-ui/icons/Add";
import Person from "@material-ui/icons/Person";
import Schedule from "@material-ui/icons/Schedule";
import Edit from "@material-ui/icons/Edit";
import AttachMoney from "@material-ui/icons/AttachMoney";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardText from "~/components/Card/CardText.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import ClaimsBadge from "~/components/CustomBadges/ClaimsBadge.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ViewClaim extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			redirect: null,
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			id: null,
			modified: null,
			comments: [],
			type: "",
			carrier: "",
			view_max_liability: false,
			status: "",
			status_color: "",
			company: "",
			bol: "",
			pro: "",
			claimNumber: "",
			direction: "",
			shipperZip: "",
			consigneeZip: "",
			contacts: [
				{
					id: "",
					first_name: "",
					last_name: "",
					phone: "",
					phone_ext: "",
					email: ""
				}
			],
			products: [
				{
					id: "",
					description: "",
					price: "",
					quantity: "",
					weight: ""
				}
			],
			description: "",
			images: [],
			comment: "",
			max_liability: [],
			claim_total_amount: "0.00",
			showLiability: false,
			status: "",
			loadingLiability: false,
			claim_type: "",
			companies: [],
			company: "",
			company_name: "",
			showCompanies: false,
			loadingCompanies: true,
			carriers: [],
			carrier: "",
			carrier_name: "",
			showCarriers: false,
			loadingCarriers: true,
			payout: "",
			payout_input: "",
			tfm_payment_date: "",
			tfm_payment_date_input: "",
			tfm_check_number: "",
			tfm_check_number_input: "",
			description: "",
			description_input: "",
			pro: "",
			pro_input: "",
			bol: "",
			bol_input: "",
			bol_name_id: "",
			bol_name_date: "",
			claim_no: "",
			claim_no_input: "",
			admins: [],
			agent: null,
			agent_name: "",
			agent_username: "",
			showAdmins: false,
			loadingAdmins: true,
			hover: {
				claim_type: false,
				status: false,
				company_name: false,
				carrier_name: false,
				payout: false,
				tfm_payment_date: false,
				tfm_check_number: false,
				description: false,
				pro: false,
				bol: false,
				claim_no: false,
				agent: false
			},
			edit: {
				claim_type: false,
				status: false,
				company_name: false,
				carrier_name: false,
				payout: false,
				tfm_payment_date: false,
				tfm_check_number: false,
				description: false,
				pro: false,
				bol: false,
				claim_no: false,
				agent: false
			},
			update: {
				claim_type: false,
				status: false,
				company_name: false,
				carrier_name: false,
				payout: false,
				tfm_payment_date: false,
				tfm_check_number: false,
				description: false,
				pro: false,
				bol: false,
				claim_no: false,
				agent_username: false
			},
			text: ["payout", "tfm_payment_date", "tfm_check_number", "description", "pro", "bol", "claim_no"],
			internal: false,
			status_options: [],
			denial_reason_options: [],
		};
		this.handleInput = this.handleInput.bind(this);
		this.getContacts = this.getContacts.bind(this);
		this.addContact = this.addContact.bind(this);
		this.handleRemoveContact = this.handleRemoveContact.bind(this);
		this.removeContact = this.removeContact.bind(this);
		this.saveContact = this.saveContact.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.addProduct = this.addProduct.bind(this);
		this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
		this.removeProduct = this.removeProduct.bind(this);
		this.saveProduct = this.saveProduct.bind(this);
		this.handleLiability = this.handleLiability.bind(this);
		this.saveContact = this.saveContact.bind(this);
		this.getImages = this.getImages.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.getComments = this.getComments.bind(this);
		this.saveComment = this.saveComment.bind(this);
		this.handleImageClick = this.handleImageClick.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleHover = this.handleHover.bind(this);
		this.handleClaimChange = this.handleClaimChange.bind(this);
		this.setCompanies = this.setCompanies.bind(this);
		this.getCompanies = this.getCompanies.bind(this);
		this.setCarriers = this.setCarriers.bind(this);
		this.getCarriers = this.getCarriers.bind(this);
		this.handleAssign = this.handleAssign.bind(this);
		this.assignSelf = this.assignSelf.bind(this);
		this.getDescription = this.getDescription.bind(this);
		this.updateTotal = this.updateTotal.bind(this);
	}

	async componentDidMount() {
		let { id } = this.state;
		if (!_.isEmpty(this.props.match.params.id)) {
			id = this.props.match.params.id;
		}
		this.setState({
			id,
			mounted: true
		});
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=claims&m=view&d=" + id);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body && response.data.user && this.state.mounted) {
				let total = 0;
				for (const prop of response.data.body.products) {
					total += parseFloat(prop.price * prop.quantity);
				}
				this.props.pageTitle('Edit Claim');
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					body: response.data.body,
					products: response.data.body.products,
					contacts: response.data.body.contacts,
					comments: response.data.body.comments,
					claim_total_amount: (Math.round(total * 100) / 100).toFixed(2),
					images: response.data.body.images || [],
					claim_type: response.data.body.claim_type,
					status: response.data.body.status,
					denial_reason: response.data.body.denial_reason,
					status_color: response.data.body.status_color,
					company_name: response.data.body.company_name,
					carrier_name: response.data.body.carrier_name,
					payout: response.data.body.payout,
					tfm_payment_date: moment(response.data.body.tfm_payment_date).format("L"),
					tfm_check_number: response.data.body.tfm_check_number,
					description: response.data.body.description,
					pro: response.data.body.pro,
					bol: response.data.body.bol,
					bol_name_id: response.data.body.bol_name_id || "",
					bol_name_date: response.data.body.bol_name_date || "",
					claim_no: response.data.body.claim_no,
					agent_name: response.data.body.agent_name,
					agent_username: response.data.body.agent_username,
					status_options: response.data.body.status_options,
					denial_reason_options: response.data.body.denial_reason_options,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleContact(i, name, event) {
		const { contacts } = this.state;
		const prop = contacts[i];
		prop[name] = event.target.value;
		contacts[i] = prop;
		this.setState({ contacts });
	}

	getContacts(data) {
		const { classes } = this.props;
		return data.map((prod, key) => {
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={classes.package} style={{ paddingBottom: "5px" }}>
						<GridContainer>
							<GridItem xs={11}>
								<GridContainer>
									<GridItem xs>
										<InputLabel className={classes.label}>First Name</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "first_name",
												value: prod.first_name || "",
												onChange: e => this.handleContact(key, "first_name", e)
											}}
											required
										/>
									</GridItem>
									<GridItem xs>
										<InputLabel className={classes.label}>Last Name</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "last_name",
												value: prod.last_name || "",
												onChange: e => this.handleContact(key, "last_name", e)
											}}
											required
										/>
									</GridItem>
									<GridItem xs>
										<InputLabel className={classes.label}>Phone</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "phone",
												value: prod.phone || "",
												onChange: e => this.handleContact(key, "phone", e)
											}}
											required
										/>
									</GridItem>
									<GridItem xs>
										<InputLabel className={classes.label}>Extension</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "phone_ext",
												value: prod.phone_ext || "",
												onChange: e => this.handleContact(key, "phone_ext", e)
											}}
											white
										/>
									</GridItem>
									<GridItem xs>
										<InputLabel className={classes.label}>Email</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "email",
												value: prod.email || "",
												onChange: e => this.handleContact(key, "email", e)
											}}
											required
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
							{key == 0 ? (
								<GridItem xs={1} className={classes.right}>
									<Button style={{ marginTop: "14px", marginRight: "5px" }} color="success" size="sm" justIcon round onClick={() => this.addContact()}>
										<Add />
									</Button>
									<Button style={{ marginTop: "14px" }} color="info" size="sm" justIcon round onClick={() => this.saveContact(key)}>
										<Save />
									</Button>
								</GridItem>
							) : (
								<GridItem xs={1} className={classes.right}>
									<Button style={{ marginTop: "14px", marginRight: "5px" }} color="danger" size="sm" justIcon round onClick={() => this.handleRemoveContact(key)}>
										<Close />
									</Button>
									<Button style={{ marginTop: "14px" }} color="info" size="sm" justIcon round onClick={() => this.saveContact(key)}>
										<Save />
									</Button>
								</GridItem>
							)}
						</GridContainer>
					</GridItem>
				</GridContainer>
			);
		});
	}

	addContact() {
		const { contacts } = this.state;
		contacts.push({
			id: "",
			first_name: "",
			last_name: "",
			phone: "",
			phone_ext: "",
			email: ""
		});
		this.setState({ contacts });
	}

	handleRemoveContact(i) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this contact?"
					onConfirm={() => this.removeContact(i)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					They will not longer receive updates about this claim.
				</SweetAlert>
			)
		});
	}

	async removeContact(i) {
		const { contacts } = this.state;
		const contact = contacts[i];

		var id = contact.id;
		var claimId = this.state.id;

		if (_.isEmpty(id)) {
			contacts.splice(i, 1);
			this.setState({ contacts, alert: null });
			return;
		}

		try {
			const response = await axios.get("/index.php?p=api&r=json&c=claims&m=deleteContact&d=" + claimId + "/" + id);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
				contacts.splice(i, 1);
				this.setState({ contacts, alert: null });
			} else {
				this.props.setNotification("There was an error removing the contact!", { variant: "error" });
				this.setState({ alert: null });
			}
		} catch (error) {
			console.error(error);
			this.setState({ alert: null });
			this.props.setNotification("There was an error removing the contact!", {
				variant: "error"
			});
		}
	}

	getProducts(data) {
		if (this.state.user && this.state.user.level === "admin") {
			const { classes } = this.props;
			return data.map((prod, key) => {
				return (
					<GridContainer key={key} className={classes.unitWrapper}>
						<GridItem xs={12} className={classes.package} style={{ paddingBottom: "5px" }}>
							<GridContainer>
								<GridItem xs={11}>
									<GridContainer>
										<GridItem xs>
											<small style={{ marginTop: "10px" }}>Quantity</small>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "number",
													name: "quantity",
													value: prod.quantity || "",
													onChange: e => this.handleProduct(key, "quantity", e)
												}}
												required
											/>
										</GridItem>
										<GridItem xs>
											<small style={{ marginTop: "10px" }}>Weight Per Item</small>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "weight",
													value: prod.weight || "",
													onChange: e => this.handleProduct(key, "weight", e),
													endAdornment: <InputAdornment position="end">lbs.</InputAdornment>
												}}
												required
											/>
										</GridItem>
										<GridItem xs>
											<small style={{ marginTop: "10px" }}>Price Per Item</small>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "price",
													value: prod.price || "",
													onChange: e => this.handleProduct(key, "price", e),
													startAdornment: <InputAdornment position="start">$</InputAdornment>
												}}
												required
											/>
										</GridItem>
										<GridItem xs>
											<small style={{ marginTop: "10px" }}>Description/Part #</small>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "description",
													value: prod.description || "",
													onChange: e => this.handleProduct(key, "description", e)
												}}
												required
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								{key == 0 ? (
									<GridItem xs={1} className={classes.right}>
										<Button style={{ marginTop: "14px", marginRight: "5px" }} color="success" size="sm" justIcon round onClick={() => this.addProduct()}>
											<Add />
										</Button>
										<Button style={{ marginTop: "14px" }} color="info" size="sm" justIcon round onClick={() => this.saveProduct(key)}>
											<Save />
										</Button>
									</GridItem>
								) : (
									<GridItem xs={1} className={classes.right}>
										<Button style={{ marginTop: "14px", marginRight: "5px" }} color="danger" size="sm" justIcon round onClick={() => this.handleRemoveProduct(key)}>
											<Close />
										</Button>
										<Button style={{ marginTop: "14px" }} color="info" size="sm" justIcon round onClick={() => this.saveProduct(key)}>
											<Save />
										</Button>
									</GridItem>
								)}
							</GridContainer>
						</GridItem>
					</GridContainer>
				);
			});
		} else {
			return data.map((prop, key) => {
				return [prop.description, prop.quantity, prop.weight + " lbs.", "$" + parseFloat(prop.price).toFixed(2), "$" + parseFloat(prop.price * prop.quantity).toFixed(2)];
			});
		}
	}

	handleProduct(i, name, event) {
		const { products } = this.state;
		const prop = products[i];
		prop[name] = event.target.value;
		products[i] = prop;
		this.setState({ products });
		this.updateTotal();
	}

	addProduct() {
		const { products } = this.state;
		products.push({
			id: "",
			quantity: "",
			weight: "",
			price: "",
			description: ""
		});
		this.setState({ products });
		this.updateTotal();
	}

	handleRemoveProduct(i) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this product from the claim?"
					onConfirm={() => this.removeProduct(i)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				/>
			)
		});
	}

	async removeProduct(i) {
		const { products } = this.state;
		const product = products[i];

		var id = product.id;
		var claimId = this.state.id;

		if (_.isEmpty(id)) {
			products.splice(i, 1);
			this.setState({ products, alert: null });
			return;
		}

		try {
			const response = await axios.get("/index.php?p=api&r=json&c=claims&m=deleteProduct&d=" + claimId + "/" + id);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
				products.splice(i, 1);
				this.setState({ products, alert: null });
				this.updateTotal();
			} else {
				this.props.setNotification("There was an error removing the product!", { variant: "error" });
				this.setState({ alert: null });
			}
		} catch (error) {
			console.error(error);
			this.setState({ alert: null });
			this.props.setNotification("There was an error removing the product!", {
				variant: "error"
			});
		}
	}

	async handleLiability() {
		const { showLiability } = this.state;
		if (showLiability === true || _.isEmpty(this.state.body) || _.isEmpty(this.state.body.scac_master_id)) {
			this.setState({
				showLiability: false,
				loadingLiability: false
			});
			return;
		}
		this.setState({
			showLiability: true,
			loadingLiability: true
		});
		if (!_.isEmpty(this.state.max_liability)) {
			return;
		}
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=claims&m=getMaxLiability&carrierId=" + this.state.body.scac_master_id);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.records)) {
				this.setState({
					loadingLiability: false,
					max_liability: response.data.body.records
				});
			} else {
				this.setState({ loadingLiability: false });
				this.props.setNotification("There was an error loading the max liability!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loadingLiability: false });
			this.props.setNotification("There was an error loading the max liability!", {
				variant: "error"
			});
		}
	}

	getMaxLiability(data) {
		return data.map((prop, key) => {
			return [prop.classNumber, "$" + prop.amount];
		});
	}

	async saveContact(i) {
		const { contacts } = this.state;
		const contact = contacts[i];

		if (_.isEmpty(contact.first_name)) {
			this.props.setNotification("First Name is required for all claim contacts, so they can receive updates.", {
				variant: "error"
			});
			return;
		}

		if (_.isEmpty(contact.last_name)) {
			this.props.setNotification("Last Name is required for all claim contacts, so they can receive updates.", {
				variant: "error"
			});
			return;
		}

		if (_.isEmpty(contact.phone)) {
			this.props.setNotification("Phone# is required for all claim contacts, so they can receive updates.", {
				variant: "error"
			});
			return;
		}

		if (_.isEmpty(contact.email)) {
			this.props.setNotification("Email is required for all claim contacts, so they can receive updates.", {
				variant: "error"
			});
			return;
		}

		contact.claimId = this.state.id;

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=claims&m=saveContact", qs.stringify(contact));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
				contacts[i] = response.data.body.result;
				this.setState({ contact });
			} else {
				this.props.setNotification("There was an error saving the contact!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the contact!", {
				variant: "error"
			});
		}
	}

	async saveProduct(i) {
		const { products } = this.state;

		const product = {
			id: products[i].id,
			quantity: _.trim(products[i].quantity.replace(/[a-zA-Z, ]+/g, "")),
			weight: _.trim(products[i].weight.replace(/[a-zA-Z, ]+/g, "")),
			price: _.trim(products[i].price.replace(/(^\$)|,+/g, "")),
			description: _.trim(products[i].description)
		};

		if (_.isEmpty(product.quantity) || _.isEmpty(product.weight) || _.isEmpty(product.price) || _.isEmpty(product.description)) {
			this.props.setNotification("Please fill in all available fields to add a product to the claim.", {
				variant: "error"
			});
			return;
		}

		// make sure price, weight, and quantity are numbers
		const regex = /^[0-9.* ]+$/;

		if (!regex.test(product.price)) {
			this.props.setNotification('Please check the value entered for "Price Per Item". Do not include any letters, punctuations, or special characters.', {
				variant: "error"
			});
			return;
		}

		if (!regex.test(product.weight)) {
			this.props.setNotification('Please check the value entered for "Weight Per Item". Enter the value in pounds, and do not include any letters, punctuations, or special characters.', {
				variant: "error"
			});
			return;
		}

		if (!regex.test(product.quantity)) {
			this.props.setNotification('Please check the value entered for "Quantity". Do not include any letters, punctuations, or special characters.', {
				variant: "error"
			});
			return;
		}

		product.claimId = this.state.id;

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=claims&m=saveProduct", qs.stringify(product));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
				products[i] = response.data.body.result;
				this.setState({ products });
				this.updateTotal();
			} else {
				this.props.setNotification("There was an error saving the product!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the product!", {
				variant: "error"
			});
		}
	}

	getImages(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			const src = apiUrl + "/index.php?p=api&c=claims&m=downloadImg&d=" + prop.id;
			const extension = prop.filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "");
			return <Preview key={key} src={src} index={key} extension={extension} handleClick={this.handleImageClick.bind(this)} handleDelete={this.handleDeleteImage.bind(this)} />;
		});
	}

	handleImageClick(i) {
		const { images } = this.state;
		const image = images[i];
		if (image.id) {
			const url = apiUrl + "/index.php?p=api&c=claims&m=downloadImg&d=" + image.id;
			const title = "Image Preview";
			const options = "resizable=1, scrollbars=1, fullscreen=0, status=0";
			window.open(url, title, options);
		}
	}

	handleDeleteImage(key) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure?"
					onConfirm={() => this.deleteImage(key)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this file!
				</SweetAlert>
			)
		});
	}

	async deleteImage(i) {
		const { images } = this.state;
		const image = images[i];
		this.setState({
			alert: null
		});
		try {
			const response = await axios.get("/index.php?p=api&c=claims&m=deleteImg&d=" + image.id);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.deleted === 1) {
				images.splice(i, 1);
				this.setState({ images });
				this.props.setNotification("File successfully deleted!", {
					variant: "success"
				});
			} else {
				this.props.setNotification("There was an error removing the file!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error removing the file!", {
				variant: "error"
			});
		}
	}

	async handleUpload(files) {
		if (_.isEmpty(files)) {
			return;
		}
		const fd = new FormData();
		for (const key in files) {
			fd.append("imgFile_" + key.toString(), files[key]);
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		try {
			const url = "/index.php?p=api&c=claims&m=uploadImg&d=" + this.state.id;
			const response = await axios.post(url, fd, config);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.uploadedImages)) {
				this.props.setNotification("File(s) successfully uploaded!", {
					variant: "success"
				});
				const uploadedImages = response.data.body.uploadedImages.map((id, index) => {
					return {
						id,
						filename: files[index].name
					};
				});
				const { images } = this.state;
				this.setState({
					images: images.concat(uploadedImages)
				});
			} else {
				this.props.setNotification("There was an error uploading the file(s)!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error uploading the file(s)!", {
				variant: "error"
			});
		}
	}

	getComments(data) {
		const { classes } = this.props;
		return data
			.filter(prop => {
				if (this.state.internal) {
					return prop.internal == 1;
				}
				return prop.internal == 0;
			})
			.map((prop, key) => {
				let username = prop.username;
				if (!_.isEmpty(prop.user)) {
					username += " (" + prop.user + ")";
				}
				const comment = prop.comment.replace(/(?:\r\n|\r|\n)/g, "<br />");
				return (
					<GridItem xs={12} key={key} style={{ marginTop: "15px" }}>
						<Paper className={classes.paper} elevation={1}>
							<GridContainer>
								<GridItem xs={6}>
									<Chip icon={<Person />} label={username} />
									{"  added a comment:"}
								</GridItem>
								<GridItem xs={6} className={classes.right}>
									<i>{prop.timestamp}</i>
								</GridItem>
								<GridItem xs={12} style={{ marginTop: "10px" }}>
									<div dangerouslySetInnerHTML={{ __html: comment }} />
								</GridItem>
							</GridContainer>
						</Paper>
					</GridItem>
				);
			});
	}

	getStatusOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return(
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.status}
				>
					{prop.status}
				</MenuItem>
			);
		});
	}

	getDenialReasonOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return(
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.reason}
				>
					{prop.reason}
				</MenuItem>
			);
		});
	}

	async saveComment() {
		if (_.isEmpty(this.state.comment)) {
			return false;
		}

		const data = {
			comment: this.state.comment,
			claimId: this.state.id,
			internal: this.state.internal ? 1 : 0
		};

		try {
			const url = "/index.php?p=api&r=json&c=claims&m=saveComment";
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
				const { comments } = this.state;
				const result = response.data.body.result;

				comments.push({
					id: result.id,
					comment: result.comment,
					timestamp: result.lm_info.lm_time,
					username: result.lm_info.lm_username,
					internal: result.internal,
					user: this.state.user.disp_name,
					id: this.state.user.id
				});

				this.setState({
					comment: "",
					comments
				});
			} else {
				this.props.setNotification("There was an error adding the comment!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error adding the comment!", {
				variant: "error"
			});
		}
	}

	handleHover(admin, key, value) {
		if (!admin || this.state.update[key]) {
			return;
		}
		const { hover } = this.state;
		hover[key] = value;
		this.setState({ hover });
	}

	handleEdit(admin, key, value) {
		if (!admin) {
			return;
		}

		let input = key;
		const { edit, hover, text } = this.state;
		edit[key] = value;

		if (value === false) {
			hover[key] = false;
		}

		if (text.indexOf(key) > -1) {
			input = key + "_input";
		}

		this.setState({
			[input]: this.state[key],
			edit,
			hover
		});

		if (key === "company_name") {
			this.setCompanies();
		} else if (key === "carrier_name") {
			this.setCarriers();
		} else if (key === "agent") {
			this.setAdmins();
		}
	}

	async handleClaimChange(admin, show, key, event) {
		if (!admin) {
			return;
		}

		let { hover, edit, update, companies, company, carriers, carrier, admins, agent, agent_name, agent_username, status } = this.state;
		let value = "";
		let name = "";
		edit[key] = show;
		hover[key] = false;

		if (key === "company_name") {
			company = event.target.value;
			value = companies[company].name;
		} else if (key === "carrier_name") {
			carrier = event.target.value;
			value = carriers[carrier].name;
		} else if (key === "tfm_payment_date") {
			value = moment(event).format("L");
		} else if (key === "agent") {
			key = "agent_username";
			if (event.target.value !== "" && event.target.value !== undefined && event.target.value !== null) {
				agent = event.target.value;
				value = admins[agent].username;
				agent_name = admins[agent].name;
				agent_username = admins[agent].username;
			} else {
				agent = null;
				value = "";
				edit[key] = false;
			}
		} else {
			value = event.target.value;
		}

		const data = {
			claimId: this.state.id
		};

		switch (key) {
			case "status":
				name = "status";
				data[name] = value;
				break;
			case "company_name":
				name = "company";
				key = "company_name_id";
				data[name] = companies[company].id;
				break;
			case "carrier_name":
				name = "carrier";
				key = "scac_master_id";
				data[name] = carriers[carrier].id;
				break;
			case "tfm_payment_date":
				name = "tfmPaymentDate";
				data[name] = value;
				break;
			case "tfm_check_number":
				name = "tfmCheckNumber";
				data[name] = value;
			case "claim_type":
				name = "type";
				data[name] = value;
				break;
			case "claim_no":
				name = "claimNumber";
				data[name] = value;
				break;
			case "agent_username":
				name = "agent";
				key = "agent_id";
				data[name] = agent !== null ? admins[agent].id : "0";
				break;
			default:
				name = key;
				data[name] = value;
		}

		if (_.isEmpty(data[name]) !== _.isEmpty(this.state.body[key]) || (!_.isEmpty(data[name]) && (data[name] != this.state.body[key] || _.isEmpty(this.state.body[key])))) {

			update[key] = true;
			this.setState({ update });

			const url = "/index.php?p=api&r=json&c=claims&m=updateClaim";

			try {
				const response = await axios.post(url, qs.stringify(data));
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
					update[key] = false;
					this.setState({
						body: response.data.body.result.claim,
						[key]: value,
						edit,
						hover,
						update,
						company,
						carrier,
						agent,
						agent_name,
						agent_username,
						status: response.data.body.result.claim.status,
						status_color: response.data.body.result.claim.status_color,
					});
				} else {
					update[key] = false;
					this.setState({
						agent: this.state.agent,
						edit,
						hover,
						update,
						company,
						carrier,
						agent_name,
						agent_username
					});
					this.props.setNotification("There was an error updating the claim!", {
						variant: "error"
					});
				}
			} catch (error) {
				console.error(error);
				update[key] = false;
				this.setState({
					agent: this.state.agent,
					edit,
					hover,
					update,
					company,
					carrier,
					agent_name,
					agent_username
				});
				this.props.setNotification("There was an error updating the claim!", {
					variant: "error"
				});
			}
		} else {
			this.setState({
				[key]: value,
				edit,
				hover,
				update,
				company,
				carrier,
				agent,
				agent_name,
				agent_username
			});
		}
	}

	async setCompanies() {
		if (!_.isEmpty(this.state.companies)) {
			if (this.state.showCompanies !== true || this.state.loadingCompanies !== false) {
				this.setState({
					showCompanies: true,
					loadingCompanies: false
				});
			}
			return;
		}

		this.setState({
			showCompanies: false,
			loadingCompanies: true
		});

		const url = "/index.php?p=api&r=json&c=claims&m=getCompanies";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.companies)) {
				let { company_name, company } = this.state;
				const companies = response.data.body.companies;
				for (let i = 0; i < companies.length; i++) {
					if (companies[i].name === company_name) {
						company = i;
						break;
					}
				}
				this.setState({
					showCompanies: true,
					loadingCompanies: false,
					companies,
					company
				});
			} else {
				this.setState({ loadingCompanies: false });
				this.props.setNotification("There was an error loading the companies!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loadingCompanies: false });
			this.props.setNotification("There was an error loading the companies!", {
				variant: "error"
			});
		}
	}

	getCompanies(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={key}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	async setCarriers() {
		if (!_.isEmpty(this.state.carriers)) {
			if (this.state.showCarriers !== true || this.state.loadingCarriers !== false) {
				this.setState({
					showCarriers: true,
					loadingCarriers: false
				});
			}
			return;
		}

		this.setState({
			showCarriers: false,
			loadingCarriers: true
		});

		const url = "/index.php?p=api&r=json&c=claims&m=getCarriers";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.carriers)) {
				let { carrier_name, carrier } = this.state;
				const carriers = response.data.body.carriers;
				for (let i = 0; i < carriers.length; i++) {
					if (carriers[i].name === carrier_name) {
						carrier = i;
						break;
					}
				}
				this.setState({
					showCarriers: true,
					loadingCarriers: false,
					carriers,
					carrier
				});
			} else {
				this.setState({ loadingCarriers: false });
				this.props.setNotification("There was an error loading the carriers!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loadingCarriers: false });
			this.props.setNotification("There was an error loading the carriers!", {
				variant: "error"
			});
		}
	}

	getCarriers(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={key}
				>
					{prop.name + " | " + prop.scac}
				</MenuItem>
			);
		});
	}

	async setAdmins(assign) {
		if (!_.isEmpty(this.state.admins)) {
			if (this.state.showAdmins !== true || this.state.loadingAdmins !== false) {
				this.setState({
					showAdmins: true,
					loadingAdmins: false
				});
			}
			return;
		}

		this.setState({
			showAdmins: false,
			loadingAdmins: true
		});

		const url = "/index.php?p=api&r=json&c=claims&m=getAdmins";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.admins)) {
				let { agent_username, agent } = this.state;
				const admins = response.data.body.admins;
				for (let i = 0; i < admins.length; i++) {
					if (admins[i].username === agent_username) {
						agent = i;
						break;
					}
				}
				this.setState({
					showAdmins: true,
					loadingAdmins: false,
					admins,
					agent
				});
				if (assign) {
					this.assignSelf();
				}
			} else {
				this.setState({ loadingAdmins: false });
				this.props.setNotification("There was an error loading the admins!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loadingAdmins: false });
			this.props.setNotification("There was an error loading the admins!", {
				variant: "error"
			});
		}
	}

	getAdmins(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			let display = prop.username;
			if (!_.isEmpty(prop.name)) {
				display += " (" + prop.name + ")";
			}
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={key}
				>
					{display}
				</MenuItem>
			);
		});
	}

	handleBlur(key) {
		const { edit } = this.state;
		edit[key] = false;
		this.setState({ edit });
	}

	handleKeyPress(key, event) {
		if (event.key === "Enter") {
			this.handleClaimChange(true, false, key, event);
		}
	}

	handleAssign() {
		if (_.isEmpty(this.state.admins)) {
			this.setAdmins(true);
		} else {
			this.assignSelf();
		}
	}

	async assignSelf() {
		if (_.isEmpty(this.state.user) || _.isEmpty(this.state.admins)) {
			return;
		}
		let { agent, admins, agent_username, agent_name } = this.state;
		let admin = null;
		for (let i = 0; i < admins.length; i++) {
			if (admins[i].id === this.state.user.id) {
				agent = i;
				admin = admins[i];
				break;
			}
		}

		if (admin !== null) {
			const data = {
				claimId: this.state.id,
				agent: admin.id
			};

			const url = "/index.php?p=api&r=json&c=claims&m=updateClaim";

			try {
				const response = await axios.post(url, qs.stringify(data));
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
					this.setState({
						agent,
						agent_name: admin.name,
						agent_username: admin.username
					});
				} else {
					this.props.setNotification("There was an error updating the claim!", {
						variant: "error"
					});
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error updating the claim!", {
					variant: "error"
				});
			}
		} else {
			this.props.setNotification("There was an error assigning the user!", {
				variant: "error"
			});
		}
	}

	getDescription(data) {
		const desc = data.replace(/(?:\r\n|\r|\n)/g, "<br />");
		return <div dangerouslySetInnerHTML={{ __html: desc }} />;
	}

	updateTotal() {
		let total = 0;
		for (const product of this.state.products) {
			let price = 0;
			if (!isNaN(product.price) && product.price > 0) {
				price = parseFloat(product.price);
			}
			if (!isNaN(product.quantity) && product.quantity > 0) {
				price *= parseFloat(product.quantity);
			}
			total += price;
		}

		this.setState({ claim_total_amount: (Math.round(total * 100) / 100).toFixed(2) });
	}

	render() {
		const { classes } = this.props;
		const adminUser = this.state.user && this.state.user.level === "admin";
		const notAssigned = _.isEmpty(this.state.agent_username) || (this.state.user && this.state.user.name !== this.state.agent_username);
		const hoverClass = cx({
			[classes.hover]: adminUser
		});
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="tfmOrange" text>
							<CardText color="tfmOrange">
								<h4 className={classes.cardTitle}>Claim # {this.state.id}</h4>
							</CardText>
						</CardHeader>
						{this.state.show ? (
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<GridContainer>
											<GridItem xs={12}>
												<Paper className={classes.paper} elevation={1}>
													{this.state.edit.claim_type ? (
														<div>
															<b>Type</b>:
															<br />
															<FormControl className={classes.selectFormControl} style={{ width: "250px" }}>
																<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={this.state.claim_type || ""} onChange={e => this.handleClaimChange(adminUser, false, "claim_type", e)}>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Concealed Damage"
																	>
																		Concealed Damage
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Damage"
																	>
																		Damage
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Damage / Shortage"
																	>
																		Damage / Shortage
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Shortage"
																	>
																		Shortage
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Lost"
																	>
																		Lost
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Lost / Damage"
																	>
																		Lost / Damage
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Service Failure"
																	>
																		Service Failure
																	</MenuItem>
																	<MenuItem
																		classes={{
																			root: classes.selectMenuItem,
																			selected: classes.selectMenuItemSelected
																		}}
																		value="Other"
																	>
																		Other
																	</MenuItem>
																</Select>
															</FormControl>
															<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleEdit(adminUser, "claim_type", false)}>
																<Close />
															</Button>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "claim_type", true)} onMouseOut={() => this.handleHover(adminUser, "claim_type", false)} onClick={() => this.handleEdit(adminUser, "claim_type", true)}>
																<b>Type</b>: {this.state.update.claim_type ? <CircularProgress className={classes.progress} /> : this.state.claim_type}
															</span>
															{this.state.hover.claim_type && adminUser && !this.state.update.claim_type && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.status ? (
														<div>
															<b>Status</b>:
															<br />
															<FormControl className={classes.selectFormControl} style={{ width: "250px" }}>
																<Select
																	MenuProps={{ className: classes.selectMenu }}
																	classes={{ select: classes.select }}
																	value={this.state.status || ""}
																	onChange={e => this.handleClaimChange(adminUser, false, "status", e)}
																>
																	{!_.isEmpty(this.state.status_options) && this.getStatusOptions(this.state.status_options)}
																</Select>
															</FormControl>
															<Button
																justIcon
																round
																size="sm"
																color="danger"
																className={classes.marginLeft}
																onClick={() => this.handleEdit(adminUser, "status", false)}
															>
																<Close />
															</Button>
														</div>
													) : (
														<div className={hoverClass}>
															<span
																className={hoverClass}
																onMouseOver={() => this.handleHover(adminUser, "status", true)}
																onMouseOut={() => this.handleHover(adminUser, "status", false)}
																onClick={() => this.handleEdit(adminUser, "status", true)}
															>
																<b>Status</b>: {this.state.update.status ?
																	<CircularProgress className={classes.progress} /> :
																	<ClaimsBadge classes={classes} status={this.state.status} color={this.state.status_color}/>}
															</span>
															{this.state.hover.status && adminUser && !this.state.update.status && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.denial_reason ? (
														<div>
															<b>Denial Reason</b>:
															<br />
															<FormControl className={classes.selectFormControl} style={{ width: "250px" }}>
																<Select
																	MenuProps={{ className: classes.selectMenu }}
																	classes={{ select: classes.select }}
																	value={this.state.denial_reason || ""}
																	onChange={e => this.handleClaimChange(adminUser, false, "denial_reason", e)}
																>
																	{!_.isEmpty(this.state.denial_reason_options) && this.getDenialReasonOptions(this.state.denial_reason_options)}
																</Select>
															</FormControl>
															<Button
																justIcon
																round
																size="sm"
																color="danger"
																className={classes.marginLeft}
																onClick={() => this.handleEdit(adminUser, "denial_reason", false)}
															>
																<Close />
															</Button>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "denial_reason", true)} onMouseOut={() => this.handleHover(adminUser, "denial_reason", false)} onClick={() => this.handleEdit(adminUser, "denial_reason", true)}>
																<b>Denial Reason</b>: {this.state.update.denial_reason ? <CircularProgress className={classes.progress} /> : this.state.denial_reason}
															</span>
															{this.state.hover.denial_reason && adminUser && !this.state.update.denial_reason && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.company_name ? (
														<div>
															<b>Company</b>:
															<br />
															{this.state.showCompanies ? (
																<FormControl className={classes.selectFormControl} style={{ width: "250px" }}>
																	<Select
																		MenuProps={{ className: classes.selectMenu }}
																		classes={{ select: classes.select }}
																		value={this.state.company || ""}
																		onChange={e => this.handleClaimChange(adminUser, false, "company_name", e)}
																	>
																		{!_.isEmpty(this.state.companies) && this.getCompanies(this.state.companies)}
																	</Select>
																</FormControl>
															) : (
																<span>{this.state.loadingCompanies ? <CircularProgress className={classes.progress} /> : <Badge color="danger">Failed to retrieve companies from the server!</Badge>}</span>
															)}
															<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleEdit(adminUser, "company_name", false)}>
																<Close />
															</Button>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "company_name", true)} onMouseOut={() => this.handleHover(adminUser, "company_name", false)} onClick={() => this.handleEdit(adminUser, "company_name", true)}>
																<b>Company</b>: {this.state.update.company_name ? <CircularProgress className={classes.progress} /> : this.state.company_name}
															</span>
															{this.state.hover.company_name && adminUser && !this.state.update.company_name && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.carrier_name ? (
														<div>
															<b>Carrier</b>:
															<br />
															{this.state.showCarriers ? (
																<FormControl className={classes.selectFormControl} style={{ width: "250px" }}>
																	<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={this.state.carrier || ""} onChange={e => this.handleClaimChange(adminUser, false, "carrier_name", e)}>
																		{!_.isEmpty(this.state.carriers) && this.getCarriers(this.state.carriers)}
																	</Select>
																</FormControl>
															) : (
																<span>{this.state.loadingCarriers ? <CircularProgress className={classes.progress} /> : <Badge color="danger">Failed to retrieve carriers from the server!</Badge>}</span>
															)}
															<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleEdit(adminUser, "carrier_name", false)}>
																<Close />
															</Button>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "carrier_name", true)} onMouseOut={() => this.handleHover(adminUser, "carrier_name", false)} onClick={() => this.handleEdit(adminUser, "carrier_name", true)}>
																<b>Carrier</b>: {this.state.update.carrier_name ? <CircularProgress className={classes.progress} /> : this.state.carrier_name}
															</span>
															{this.state.hover.carrier_name && adminUser && !this.state.update.carrier_name && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
												</Paper>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<Paper className={classes.paper} elevation={1}>
													{this.state.edit.payout ? (
														<div>
															<b>Payout</b>:
															<br />
															<CustomInput
																inputProps={{
																	type: "text",
																	name: "payout_input",
																	startAdornment: (
																		<InputAdornment position="start">
																			<AttachMoney />
																		</InputAdornment>
																	),
																	style: { width: "250px" },
																	value: this.state.payout_input || "",
																	onChange: this.handleInput("payout_input"),
																	onKeyPress: e => this.handleKeyPress("payout", e),
																	onBlur: e => this.handleClaimChange(adminUser, false, "payout", e)
																}}
															/>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "payout", true)} onMouseOut={() => this.handleHover(adminUser, "payout", false)} onClick={() => this.handleEdit(adminUser, "payout", true)}>
																<b>Payout</b>: {this.state.update.payout ? <CircularProgress className={classes.progress} /> : <span>${!_.isEmpty(this.state.payout) ? this.state.payout : " - "}</span>}
															</span>
															{this.state.hover.payout && adminUser && !this.state.update.payout && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.tfm_payment_date ? (
														<div>
															<b>TFM Payout Date</b>:
															<br />
															<FormControl style={{ width: "250px" }}>
																<Datetime
																	timeFormat={false}
																	value={this.state.tfm_payment_date_input || ""}
																	onChange={m => this.handleClaimChange(adminUser, false, "tfm_payment_date", m)}
																	className={classes.datetime}
																	inputProps={{
																		name: "tfm_payment_date",
																		placeholder: "Select Payout Date"
																	}}
																/>
															</FormControl>
															<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleEdit(adminUser, "tfm_payment_date", false)}>
																<Close />
															</Button>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "tfm_payment_date", true)} onMouseOut={() => this.handleHover(adminUser, "tfm_payment_date", false)} onClick={() => this.handleEdit(adminUser, "tfm_payment_date", true)}>
																<b>TFM Payout Date</b>: {this.state.update.tfm_payment_date ? <CircularProgress className={classes.progress} /> : this.state.tfm_payment_date}
															</span>
															{this.state.hover.tfm_payment_date && adminUser && !this.state.update.tfm_payment_date && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.tfm_check_number ? (
														<div>
															<b>TFM Check #</b>:
															<br />
															<CustomInput
																inputProps={{
																	type: "text",
																	name: "tfm_check_number_input",
																	style: { width: "250px" },
																	value: this.state.tfm_check_number_input || "",
																	onChange: this.handleInput("tfm_check_number_input"),
																	onKeyPress: e => this.handleKeyPress("tfm_check_number", e),
																	onBlur: e => this.handleClaimChange(adminUser, false, "tfm_check_number", e)
																}}
															/>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "tfm_check_number", true)} onMouseOut={() => this.handleHover(adminUser, "tfm_check_number", false)} onClick={() => this.handleEdit(adminUser, "tfm_check_number", true)}>
																<b>TFM Check #</b>: {this.state.update.tfm_check_number ? <CircularProgress className={classes.progress} /> : this.state.tfm_check_number}
															</span>
															{this.state.hover.tfm_check_number && adminUser && !this.state.update.tfm_check_number && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.description ? (
														<div>
															<b>Additional Notes</b>:
															<br />
															<CustomInput
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "text",
																	name: "description",
																	multiline: true,
																	value: this.state.description_input || "",
																	onChange: this.handleInput("description_input"),
																	onBlur: e => this.handleClaimChange(adminUser, false, "description", e)
																}}
																white
															/>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "description", true)} onMouseOut={() => this.handleHover(adminUser, "description", false)} onClick={() => this.handleEdit(adminUser, "description", true)}>
																<b>Additional Notes</b>:
																{this.state.hover.description && adminUser && !this.state.update.description && (
																	<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																		<Edit />
																	</Button>
																)}
																<div>{this.state.update.description ? <CircularProgress className={classes.progress} /> : this.getDescription(this.state.description)}</div>
															</span>
														</div>
													)}
												</Paper>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<GridContainer>
											<GridItem xs={12}>
												<Paper className={classes.paper} elevation={1}>
													{this.state.edit.pro ? (
														<div>
															<b>PRO #</b>:
															<br />
															<CustomInput
																inputProps={{
																	type: "text",
																	name: "pro_input",
																	style: { width: "250px" },
																	value: this.state.pro_input || "",
																	onChange: this.handleInput("pro_input"),
																	onKeyPress: e => this.handleKeyPress("pro", e),
																	onBlur: e => this.handleClaimChange(adminUser, false, "pro", e)
																}}
															/>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "pro", true)} onMouseOut={() => this.handleHover(adminUser, "pro", false)} onClick={() => this.handleEdit(adminUser, "pro", true)}>
																<b>PRO #</b>: {this.state.update.pro ? <CircularProgress className={classes.progress} /> : this.state.pro}
															</span>
															{this.state.hover.pro && adminUser && !this.state.update.pro && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.bol ? (
														<div>
															<b>BoL #</b>:
															<br />
															<CustomInput
																inputProps={{
																	type: "text",
																	name: "bol_input",
																	style: { width: "250px" },
																	value: this.state.bol_input || "",
																	onChange: this.handleInput("bol_input"),
																	onKeyPress: e => this.handleKeyPress("bol", e),
																	onBlur: e => this.handleClaimChange(adminUser, false, "bol", e)
																}}
															/>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "bol", true)} onMouseOut={() => this.handleHover(adminUser, "bol", false)} onClick={() => this.handleEdit(adminUser, "bol", true)}>
																<b>BoL #</b>:{" "}
																{this.state.update.bol ? <CircularProgress className={classes.progress} /> : <span>{!_.isEmpty(this.state.bol_name_id) && !_.isEmpty(this.state.bol_name_date) ? <NavLink to={basePath + "/admin/bol/" + this.state.bol_name_date + "/" + this.state.bol_name_id}>{this.state.bol}</NavLink> : <span>{this.state.bol}</span>}</span>}
															</span>
															{this.state.hover.bol && adminUser && !this.state.update.bol && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													{this.state.edit.claim_no ? (
														<div>
															<b>Carrier Claim #</b>:
															<br />
															<CustomInput
																inputProps={{
																	type: "text",
																	name: "claim_no_input",
																	style: { width: "250px" },
																	value: this.state.claim_no_input || "",
																	onChange: this.handleInput("claim_no_input"),
																	onKeyPress: e => this.handleKeyPress("claim_no", e),
																	onBlur: e => this.handleClaimChange(adminUser, false, "claim_no", e)
																}}
															/>
														</div>
													) : (
														<div className={hoverClass}>
															<span className={hoverClass} onMouseOver={() => this.handleHover(adminUser, "claim_no", true)} onMouseOut={() => this.handleHover(adminUser, "claim_no", false)} onClick={() => this.handleEdit(adminUser, "claim_no", true)}>
																<b>Carrier Claim #</b>: {this.state.update.claim_no ? <CircularProgress className={classes.progress} /> : this.state.claim_no}
															</span>
															{this.state.hover.claim_no && adminUser && !this.state.update.claim_no && (
																<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft}>
																	<Edit />
																</Button>
															)}
														</div>
													)}
													<br />
													<GridContainer>
														<GridItem xs={12} style={{ marginBottom: "5px" }}>
															<GridContainer>
																<GridItem xs={3}>
																	<Chip variant="outlined" size="small" icon={<Person />} label="Created" />
																</GridItem>
																<GridItem xs={9}>
																	{this.state.body.create_username + " "}
																	{!_.isEmpty(this.state.body.create_name) && <span>({this.state.body.create_name})</span>}
																</GridItem>
															</GridContainer>
														</GridItem>
														<GridItem xs={12} style={{ marginBottom: "5px" }}>
															<GridContainer>
																<GridItem xs={3}>
																	<Chip variant="outlined" size="small" icon={<Schedule />} label="Created" />
																</GridItem>
																<GridItem xs={9}>{this.state.body.create_time}</GridItem>
															</GridContainer>
														</GridItem>
														<GridItem xs={12} style={{ marginBottom: "5px" }}>
															<GridContainer>
																<GridItem xs={3}>
																	<Chip variant="outlined" size="small" icon={<Person />} label="Updated" />
																</GridItem>
																<GridItem xs={9}>
																	{this.state.body.lm_username + " "}
																	{!_.isEmpty(this.state.body.lm_name) && <span>({this.state.body.lm_name})</span>}
																</GridItem>
															</GridContainer>
														</GridItem>
														<GridItem xs={12} style={{ marginBottom: "5px" }}>
															<GridContainer>
																<GridItem xs={3}>
																	<Chip variant="outlined" size="small" icon={<Schedule />} label="Updated" />
																</GridItem>
																<GridItem xs={9}>{this.state.body.lm_time}</GridItem>
															</GridContainer>
														</GridItem>
														<GridItem xs={12}>
															<GridContainer>
																<GridItem xs={3}>
																	<Chip variant="outlined" size="small" icon={<Person />} label="Agent" />
																</GridItem>
																<GridItem xs={9}>
																	{this.state.edit.agent ? (
																		<span>
																			{this.state.showAdmins ? (
																				<FormControl className={classes.selectFormControl} style={{ width: "250px" }}>
																					<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={this.state.agent || ""} onChange={e => this.handleClaimChange(adminUser, false, "agent", e)}>
																						<MenuItem
																							classes={{
																								root: classes.selectMenuItem,
																								selected: classes.selectMenuItemSelected
																							}}
																							value=""
																						/>
																						{!_.isEmpty(this.state.admins) && this.getAdmins(this.state.admins)}
																					</Select>
																				</FormControl>
																			) : (
																				<span>{this.state.loadingAdmins ? <CircularProgress className={classes.progress} /> : <Badge color="danger">Failed to retrieve admins from the server!</Badge>}</span>
																			)}
																			<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleEdit(adminUser, "agent", false)}>
																				<Close />
																			</Button>
																		</span>
																	) : (
																		<div>
																			{this.state.update.agent_id ? (
																				<CircularProgress className={classes.progress} />
																			) : (
																				<span className={hoverClass} onClick={() => this.handleEdit(adminUser, "agent", true)}>
																					{!_.isEmpty(this.state.agent_username) && this.state.agent_username != null && this.state.agent_username !== "null" && this.state.agent_username + " "}
																					{!_.isEmpty(this.state.agent_name) && <span>({this.state.agent_name})</span>}
																				</span>
																			)}{" "}
																			{adminUser && !this.state.update.claim_no && !this.state.update.agent_id && (
																				<Button justIcon simple size="sm" color="linkedin" className={classes.marginLeft} onClick={() => this.handleEdit(adminUser, "agent", true)}>
																					<Edit />
																				</Button>
																			)}{" "}
																			{adminUser && notAssigned && !this.state.update.agent_id && (
																				<span className={hoverClass} onClick={this.handleAssign}>
																					<Badge color="info">Assign to Me</Badge>
																				</span>
																			)}
																		</div>
																	)}
																</GridItem>
															</GridContainer>
														</GridItem>
													</GridContainer>
												</Paper>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={12}>
												<Button color="linkedin" size="sm" simple onClick={this.handleLiability}>
													View Max Liability For Carriers
												</Button>
											</GridItem>
											{this.state.showLiability && (
												<GridItem xs={12}>
													{!_.isEmpty(this.state.max_liability) ? (
														<Table tableHead={["Class", "Amount / lb."]} tableData={this.getMaxLiability(this.state.max_liability)} />
													) : (
														<GridContainer>
															<Spinner loading={this.state.loadingLiability} message="Failed to retrieve max liability from the server" />
														</GridContainer>
													)}
												</GridItem>
											)}
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<h5>Contacts</h5>
										<small>Contacts added here will receive emails for all future updates to this claim.</small>
									</GridItem>
									<GridItem xs={12} sm={12} md={12} className={classes.unitContainer}>
										{this.getContacts(this.state.contacts)}
									</GridItem>
								</GridContainer>
								{!_.isEmpty(this.state.products) && (
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<h5>Products</h5>
										</GridItem>
										{adminUser ? (
											<GridItem xs={12} sm={12} md={12} className={classes.unitContainer}>
												{this.getProducts(this.state.products)}
											</GridItem>
										) : (
											<GridItem xs={12} sm={12} md={12}>
												<Table tableHead={["Description/Part#", "Quantity", "Weight Per Item", "Price Per Item", "Total"]} tableData={this.getProducts(this.state.products)} />
											</GridItem>
										)}
										{!_.isEmpty(this.state.claim_total_amount) && (
											<GridItem xs={12} className={classes.right}>
												<h5>
													<b>
														Total Amount Requested: <span style={{ color: "green" }}>${this.state.claim_total_amount}</span>
													</b>
												</h5>
											</GridItem>
										)}
									</GridContainer>
								)}
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<h5>Images</h5>
										<GridContainer>
											<GridItem xs={12} sm={12} md={6}>
												<Dropzone handleUpload={this.handleUpload.bind(this)} />
											</GridItem>
											<GridItem xs={12} sm={12} md={6}>
												<aside className={classes.thumbsContainer}>{!_.isEmpty(this.state.images) && this.getImages(this.state.images)}</aside>
											</GridItem>
										</GridContainer>
										<h5>Comments</h5>
										{adminUser && (
											<GridContainer>
												<GridItem xs={12}>
													<Button
														color={this.state.internal ? "white" : "warning"}
														className={classes.marginRight}
														onClick={() => {
															this.setState({ internal: false });
														}}
													>
														Public
													</Button>
													<Button
														color={this.state.internal ? "warning" : "white"}
														onClick={() => {
															this.setState({ internal: true });
														}}
													>
														Internal
													</Button>
												</GridItem>
											</GridContainer>
										)}
										<GridContainer style={{ marginBottom: "10px" }}>{!_.isEmpty(this.state.comments) && this.getComments(this.state.comments)}</GridContainer>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12}>
												<InputLabel className={classes.label}>Comment</InputLabel>
												<br />
												<CustomInput
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "text",
														name: "comment",
														multiline: true,
														value: this.state.comment || "",
														onChange: this.handleInput("comment")
													}}
													white
												/>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												<Button color="linkedin" size="sm" className={classes.marginRight} onClick={this.saveComment}>
													Post Comment
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						) : (
							<CardBody>
								<GridContainer>
									<Spinner loading={this.state.loading} message="Failed to retrieve the claim from the server" />
								</GridContainer>
							</CardBody>
						)}
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(ViewClaim));
