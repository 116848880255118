import React, { Component } from "react";
import { withSnackbar } from "notistack";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import InputChip from "~/views/CarrierPortal/components/InputChip.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

/**
 * A search input field that accepts one or more values
 *
 * props:
 * menuLabel - string - label that appears above drop down menu
 * menuOptions - array - drop down menu options
 *  [
 *    { value: 'pro', display: 'PRO' },
 *    { value: 'invoiceNumber', display: 'Invoice #' }
 *  ]
 * searchBy - string - chosen value from drop down menu
 * onChangeSearchBy - fx - updates the search by value
 * inputChipLabel - string - label that appears above the input chip
 * placeholder - string - placeholder that appears inside the input chip field
 * searchValues - array - values entered by user into the input chip field
 * changeSearchValues - fx - updates the array of search values
 * clearAllSearchValues - fx - clears the array of tags
 */
class SearchByChip extends Component {

  render() {
    const classes = this.props.classes;
    return (
      <GridContainer>
        {('menuLabel' in this.props && 'menuOptions' in this.props) && (
          <GridItem xs={12} md={2} style={{marginBottom: "10px"}}>
            <InputLabel fontWeight="bold">
                {this.props.menuLabel}
            </InputLabel>
            <FormControl fullWidth>
                <Select
                    value={this.props.searchBy}
                    onChange={(e) => this.props.onChangeSearchBy("searchBy", e)}
                    inputProps={{ id: "searchBy", name: "searchBy" }}
                >
                  {this.props.menuOptions.map((option, index) => {
                    return <MenuItem key={'menuItem_' + index} value={option.value}>{option.display}</MenuItem>
                  })}
                </Select>
            </FormControl>
          </GridItem>
        )}
        {'inputChipLabel' in this.props && (
          <GridItem xs={12}>
            <InputLabel fontWeight="bold" className={classes.label}>
              {this.props.inputChipLabel}
            </InputLabel>
          </GridItem>
        )}
        <GridItem xs={12} md={10} style={{ paddingTop: "0px", paddingLeft: "15px" }}>
          <>
            <InputChip
              tags={this.props.searchValues}
              placehold={this.props.placeholder}
              changeTagsList={this.props.changeSearchValues}
            />
            <Link href="#" onClick={this.props.clearAllSearchValues} style={{ float: "right", marginTop: "10px" }}>
              Clear All
            </Link>
          </>
        </GridItem>
      </GridContainer>
    )
  }

}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(SearchByChip));