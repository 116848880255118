import React from "react";
import qs from "qs";
import _ from "lodash";
import axios from "~/variables/axios.jsx";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath } from "~/variables/server.jsx";

import { getCustomTableHeaders, getPages} from "~/helpers.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import SearchModal from "~/components/Search/components/Search.jsx";
import Favorites from "~/components/Favorites/Favorites.jsx";


import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ReactTables extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			favorites: [],
			body: null,
			user: null,
			columns: [
				{ Header: "ID", accessor: "id", colDisabled: false },
				{ Header: "Name", accessor: "name", colDisabled: false },
				{ Header: "Billing Type", accessor: "billing_type", colDisabled: false },
				{ Header: "Calculation Type", accessor: "calc_type", colDisabled: false },
				{ Header: "Calculation Value", accessor: "calc_value", colDisabled: false },
				{ Header: "Actions", accessor: "actions", colDisabled: false }
			],
			customColumns: [],
			searchFields: [
				{ name: "Name", value: "company_name.name" },
			],
			filters: [
				{
					field: "company_name.name",
					compare: "starts with",
					value: ""
				}
			],
			alert: null
		};

		this.setHeaders = this.setHeaders.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	async componentDidMount() {
		this.setState({
			mounted: true
		});
		const filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);
		if(filters.length) {
			this.handleSearch(filters);
			return;
		}
		try {
			let favoriteResults = await this.getFavorites();
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=companies&d=1/20");
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.handleMessages(data.message);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user) && this.state.mounted) {
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					favorites: favoriteResults,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	setHeaders(customColumns) {
		this.setState({ customColumns });
	}

	getColumns(data) {
		let favorites = this.state.favorites.map(favorite => favorite.id);
		const { customColumns, columns, filters } = this.state;
		let cols = customColumns.length ? customColumns : columns;
		return data.map((prop, key) => {
			const link = basePath + "/admin/company/" + prop.id;
			return cols.map((col) => {
				const disabled = col.colDisabled === "false" ? false : (!col.colDisabled ? false : true);
				if (!disabled) {
					if (col.accessor === "actions") {
						return (
							<div>
								<Favorites
									id={prop.id}
									favorites={favorites}
									filters={filters}
									tableName={'company_name'}
									favoriteItem={this.favoriteItem.bind(this)}
								/>
								<NavLink to={link}>
									<Button size="sm" color="info">
										View/Edit
									</Button>
								</NavLink>
							</div>
						);
					} else {
						return prop[col.accessor];
					}
				}
			});
		});
	}

	async favoriteItem(favoriteResults) {
		const { filters } = this.state;
		favoriteResults.doSearch ? this.doSearch(true, filters) : this.setState({favorites: favoriteResults.favorites});
	}

	saveCustomColumns(columnResult) {
		this.setState({customColumns: columnResult});
	}

	getCustomColumns(columnResult) {
		this.setState({customColumns: columnResult});
	}

	async getFavorites() {
		let favorites = await axios.get("/index.php?p=api&r=json&c=admin&m=getFavorites&table_name=company_name");
		let favoriteResults = [];
		if (typeof favorites !== "string" && !_.isEmpty(favorites.data) && !_.isEmpty(favorites.data.message)) {
			this.props.handleMessages(favorites);
		}
		if (typeof favorites.data !== "string" && !_.isEmpty(favorites.data.body) && favorites.data.body.favorites) {
			favorites.data.body.favorites.forEach((item) => {
				item.favorite_item = true;
				favoriteResults.push(item);
			});
		}
		this.setState({
			favorites: favoriteResults
		});

		return favoriteResults;
	}

	async handlePageClick(page) {
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=companies&d=" + page + "/20");
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the companies!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the companies!", {
				variant: "error"
			});
		}
	}

	async clearFilters() {
		this.setState({
			show: false,
			loading: true,
			filters: [
				{
					field: "company_name.name",
					compare: "starts with",
					value: ""
				}
			]
		});
		try {
			let favoriteResults = await this.getFavorites();
			this.setState({
				favorites: favoriteResults
			});

			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=companies&d=1/20");
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the companies!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the companies!", {
				variant: "error"
			});
		}
	}

	async handleSearch(filters) {
        this.setState({
            show: false,
            loading: true,
			filters: filters,
        });

        let searchResult = await this.doSearch(false, filters);

		if(searchResult) {
			await this.doSearch(true, filters);
			this.setState({
				show: true,
        	});
		}
		this.setState({
			loading: false
		});
	}

	async doSearch(favorites = false, filters) {
		let isEmpty = false;

		filters.forEach(filter => {
			if (!filter.value.trim()) isEmpty = true;
		});

		if (isEmpty) {
			this.setState({show: true});
			this.props.setNotification("Search values are required", { variant: "error" });
			return false;
		}

		const data = { queries: filters };
		let url = "/index.php?p=api&r=json&c=search&m=company";
		if(favorites == true) {
			url = url + "&favorites=true";
		}

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user)) {
				if(favorites == true) {
					this.setState({
						favorites: response.data.body,
					});
				} else {
					const body = {
						result: response.data.body,
						current: 1,
						last: 1,
						pages: []
					};
					this.setState({
						body,
						user: response.data.user
					});
				}
				return true;
			} else {
				this.props.setNotification("There was an error searching for companies!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching for companies!", { variant: "error" });
		}
	}

	render() {
		const { classes } = this.props;
		const { customColumns, columns} = this.state;
		const link = basePath + "/admin/company/new";
		return (
			<GridContainer>
				<GridItem xs={12}>
					{this.state.alert}
					<Card>
						<CardBody>
							<Grid container>
								<GridItem xs={3} sm={3} md={3} className={classes.left}>
									<ColumnListLayoutModal
										buttonType="primary"
										type={"companies"}
										fetchColumns={(items) => this.getCustomColumns(items)}
										listColumns={this.state.columns}
										customListColumns={this.state.customColumns}
										updateCols={() => {}}
										saveCustomColumns={(items) => this.saveCustomColumns(items)}
										applyCustomColumns={(items) => this.setHeaders(items)}
									/>

									<SearchModal
										fields={this.state.searchFields}
										onClear={this.clearFilters}
										onSearch={this.handleSearch}
										loading={false}
									/>
								</GridItem>
								<GridItem xs={6} sm={6} md={6} className={classes.center}>
									<Pagination pages={getPages(this.state.body, this.handlePageClick)} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3} className={classes.right}>
									<NavLink to={link}>
										<Button variant="contained" color="linkedin">New Company</Button>
									</NavLink>
								</GridItem>
							</Grid>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} className={classes.center}>
										{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.result) ? (
											(this.state.body.current == 1 ?
												<Table tableHead={getCustomTableHeaders(customColumns, columns)} tableFavorites={this.getColumns(this.state.favorites)} tableData={this.getColumns(this.state.body.result)} />
											:
												<Table tableHead={getCustomTableHeaders(customColumns, columns)} tableData={this.getColumns(this.state.body.result)} />
											)
										) : (
											<Badge color="info">No companies</Badge>
										)}
									</Grid>
								) : (
									<Spinner loading={this.state.loading} message="Failed to retrieve companies from the server" />
								)}
							</Grid>
							<Grid container>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={getPages(this.state.body, this.handlePageClick)} />
								</GridItem>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		searchFilters: state.Search.filters,
	};
};

export default connect(mapStateToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(ReactTables)));
