import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

// core components
import InternationalLoadBoardList from '../components/InternationalLoadBoardList.jsx';
import Card from "~/components/Card/Card.jsx";

import { 
	getIntlShipments,
	setShipmentPage,
	setLoadBoardFilters,
	getLoadBoardOptions,
	getFilterPresets,
	getLoadBoardListColumns
} from '../actions/InternationalLoadBoard.jsx';

class InternationalLoadBoardContainer extends Component {

	state = {
		mounted: false,
	}

	async componentDidMount() {
		this.props.setShipmentPage(1);
		await this.props.getLoadBoardListColumns();
		await this.props.getLoadBoardOptions();
		await this.props.getIntlShipments();
		await this.props.getFilterPresets();
		this.setState({mounted: true});
	}

	setPage = async (n) => {
		this.props.setShipmentPage(n);
		await this.props.getIntlShipments();
	}

	search = async (filters) => {
		this.props.setShipmentPage(1);
		this.props.setLoadBoardFilters(filters);
		await this.props.getIntlShipments();
	}

	render() {
		return(
			<Card>
				<InternationalLoadBoardList
					setPage={this.setPage}
					search={this.search}
					mounted={this.state.mounted}
				/>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		loadBoardDefaultColumns: state.InternationalLoadBoard.loadBoardDefaultColumns,
		loadBoardCustomColumns: state.InternationalLoadBoard.loadBoardCustomColumns,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getIntlShipments,
		setShipmentPage,
		setLoadBoardFilters,
		getLoadBoardOptions,
		getFilterPresets,
		getLoadBoardListColumns
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InternationalLoadBoardContainer);