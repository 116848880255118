const initialState = {
	internalCSLoading: false,
	trackingRows: [],
	sortBy: "TMS BOL ID",
	desc: true,
	filters: [],
	numRecords: 0,
	pageSize: 100,
	pageNum: 1,
	filterPresets: [],
	filterPresetsLoading: false,
	optionsLoading: false,
	companies: [],
	carriers: [],
	warehouses: [],
	codes: [],
	transitStatuses: [],
	sortedCompanyOptions: [],
	sortedCarrierOptions: [],
	sortedWarehouseOptions: [],
	sortedCodeOptions: [],
	sortedTransitStatusOptions: [],
	fieldsWithLimitedOperators: [
		"Company",
		"Warehouse",
		"Code",
		"Direction/Terms",
		"Transit Status",
		"BOL Status",
		"Latest Tracking Update",
		"Carrier",
		"Shipper",
		"Shipper Address",
		"Shipper Address 2",
		"Shipper City",
		"Shipper State",
		"Consignee",
		"Consignee Address",
		"Consignee Address 2",
		"Consignee State",
		"Consignee City",
		"Importance Level"
	],
	fieldsWithAutoComplete: [
		"Company",
		"Warehouse",
		"Code",
		"Direction/Terms",
		"BOL Status",
		"Transit Status",
		"Importance Level",
		"Carrier",
	],
	fieldsWithDate: [
		"Ship Date",
		"Guaranteed Date",
		"Expected Delivery Date",
		"Execution Date",
		"User Custom Date",
	]
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {

		case "SET_INTERNAL_CS_DATA_LOADING": {
			return {...newState, internalCSLoading: action.val};
		}
		case "SET_INTERNAL_CS_DATA_ROWS": {
			return {...newState, trackingRows: [...action.val]};
		}
		case "SET_INTERNAL_CS_DATA_SORT_BY": {
			return {...newState, sortBy: action.val};
		}
		case "SET_INTERNAL_CS_DATA_SORT_DIR": {
			return {...newState, desc: action.val};
		}
		case "CLEAR_INTERNAL_CS_DATA": {
			return initialState;
		}
		case "SET_INTERNAL_CS_DATA_PAGE_SIZE": {
			return {...newState, pageSize: action.val};
		}
		case "SET_INTERNAL_CS_DATA_FILTERS": {			
			return {...newState, filters: [...action.val]};
		}
		case "SET_INTERNAL_CS_DATA_NUM_RECORDS": {
			return {...newState, numRecords: action.val};
		}
		case "SET_INTERNAL_CS_DATA_PAGE_NUM": {
			return {...newState, pageNum: action.val};
		}
		case "SET_INTERNAL_CS_FILTER_PRESETS": {
			return {...newState, filterPresets: Array.isArray(action.val) ? [...action.val] : []};
		}
		case "SET_INTERNAL_CS_FILTER_PRESETS_LOADING": {
			return {...newState, filterPresetsLoading: !!action.val};
		}
		case "SET_OPTIONS_LOADING":
			return {...newState, optionsLoading: action.val};
		case "STORE_COMPANIES":
			return {...newState, companies: action.val};
		case "STORE_CARRIERS":
			return {...newState, carriers: action.val};
		case "STORE_WAREHOUSES":
			return {...newState, warehouses: action.val};
		case "STORE_CODES":
			return {...newState, codes: action.val};
		case "STORE_TRANSIT_STATUSES":
			return {...newState, transitStatuses: action.val};
		case "SET_SORTED_COMPANY_OPTIONS":
			return {...newState, sortedCompanyOptions: action.val};
		case "SET_SORTED_CARRIER_OPTIONS":
			return {...newState, sortedCarrierOptions: action.val};
		case "SET_SORTED_WAREHOUSE_OPTIONS":
			return {...newState, sortedWarehouseOptions: action.val};
		case "SET_SORTED_CODE_OPTIONS":
			return {...newState, sortedCodeOptions: action.val};
		case "SET_SORTED_TRANSIT_STATUS_OPTIONS":
			return {...newState, sortedTransitStatusOptions: action.val};
		case "INTERNAL_CS_LOAD_STORED_STATE": {
			let sortBy = newState.sortBy;
			let desc = newState.desc;
			let filters = newState.filters;
			let pageNum = newState.pageNum;
			if (typeof action.val === "object") {
				if (action.val.sortBy !== undefined) sortBy = action.val.sortBy;
				if (action.val.desc !== undefined) desc = action.val.desc;
				if (action.val.filters !== undefined) filters = action.val.filters;
				if (action.val.pageNum !== undefined) pageNum = action.val.pageNum;
			}
			return {
				...newState,
				sortBy,
				desc,
				filters,
				pageNum,
			};
		}
		default:
			return state;
	}
}