import React from "react";
import qs from "qs";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

import WarehouseSelector from "~/components/TMS/WarehouseSelector.jsx";
import CarrierTerminalSelector from "~/components/TMS/CarrierTerminalSelector.jsx";

class NewProRange extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			id: null,
			carrier: '',
			scac: '',
			warehouses: [],
			terminalCode: '',
			autoProType: 'general',
			start: '',
			end: '',
			notification_threshold: 80,
			notification_email: '',
			increment: '1',
			note: ''
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCarrier = this.handleChangeCarrier.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleWarehouse = this.handleWarehouse.bind(this);
		this.handleTerminal = this.handleTerminal.bind(this);
		this.save = this.save.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		const url = (_.isEmpty(this.props.match.params.id))
			? '/index.php?p=api&r=json&c=admin&m=addProRange'
			: '/index.php?p=api&r=json&c=admin&m=editProRange&d=' + this.props.match.params.id;
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (
				typeof data !== "string" &&
				this.state.mounted
			) {
				this.props.handleMessages(response);
				const range = (typeof data.body.range === 'object' && !_.isEmpty(data.body.range)) ? data.body.range[0] : {};

				let warehouses = [];
				if(!_.isEmpty(range.warehouses)) {
					for(var i = 0; i < range.warehouses.length; i++) {
						warehouses.push(range.warehouses[i].id);
					}
				}
				if(!_.isEmpty(this.props.match.params.id)) {
					this.props.pageTitle('Edit PRO Range - ' + range.scac);
				}
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					id: range.id || null,
					start: range.start || '',
					end: range.end || '',
					note: range.note || '',
					increment: range.increment || '1',
					notification_threshold: range.notification_threshold || 80,
					notification_email: range.notification_email || "",
					warehouses: warehouses,
					terminalCode: range.terminalCode || '',
					autoProType: range.auto_pro_type || 'general',
					carrier: range.carrier_id || '',
					scac: range.scac || '',
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChangeCarrier(event) {
		const id = event.target.value;
		var autoProType = 'general';
		var scac = '';
		for(var i = 0; i < this.state.body.carriers.length; i++) {
			if(id == this.state.body.carriers[i].id) {
				scac = this.state.body.carriers[i].scac;
				autoProType = this.state.body.carriers[i].auto_pro_type;
				break;
			}
		}
		this.setState({
			carrier: id,
			scac: scac,
			autoProType: autoProType,
			terminalCode: "",
			warehouses: [],
		});
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleInput(name) {
		this.setState({ [name]: event.target.value });
	}
	handleWarehouse(warehouses) {
		this.setState({ warehouses });
	}
	handleTerminal(terminal) {
		this.setState({ terminalCode: (!_.isEmpty(terminal)) ? terminal.code : '' });
	}
	async save() {
		const url = (_.isEmpty(this.state.id))
			? '/index.php?p=api&r=json&c=admin&m=addProRange'
			: '/index.php?p=api&r=json&c=admin&m=editProRange';
		const { id, carrier, warehouses, terminalCode, start, end, increment, note, notification_threshold, notification_email } = this.state;
		let data = { id, carrier, warehouses, terminalCode, start, end, increment, note, notification_threshold, notification_email };
		try {
			const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string") {
                this.props.handleMessages(response);
                if(response.data.body != false && this.state.id === null) {
                	this.setState({ id: (isNaN(response.data.body)) ? response.data.body : response.data.body.toString() });
                }
            } else {
                this.props.setNotification("An error occurred saving the PRO Block", { variant: "error" });
                this.setState({ loadingCarriers: false });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("An exception occurred saving the PRO Block", { variant: "error" });
            this.setState({ loadingCarriers: false });
        }
	}
	getCarriers(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{"(" + prop.scac + ")  " + prop.carrier}
				</MenuItem>
			);
		});
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs>
											<CustomInput
												labelText="Select Carrier"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "select",
													id: "carrier",
													name: "carrier",
													readOnly: !!this.state.id,
													value: this.state.carrier || "",
													onChange: this.handleChangeCarrier,
													classes: !this.state.id ? {select: classes.requiredSelect} : null,
												}}
												options={this.state.body && this.state.body.carriers ? this.getCarriers(this.state.body.carriers) : null}
												white
											/>
										</GridItem>
										<GridItem xs>
											<CustomInput
												labelText="Starting PRO #"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "start",
													readOnly: !!this.state.id,
													value:
														this.state.start || "",
													onChange: this.handleInput(
														"start"
													)
												}}
												white
											/>
										</GridItem>
										<GridItem xs>
											<CustomInput
												labelText="Ending PRO #"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "end",
													value: this.state.end || "",
													readOnly: !!this.state.id,
													onChange: this.handleInput(
														"end"
													)
												}}
												white
											/>
										</GridItem>
										<GridItem xs>
											<CustomInput
												labelText="Increment"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "number",
													name: "increment",
													readOnly: !!this.state.id,
													value:
														this.state.increment ||
														"",
													onChange: this.handleInput(
														"increment"
													)
												}}
												white
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
									<GridItem xs>
										<CustomInput
											labelText="Notification Threshold"
											helpText="Accepts integers 1-100. Once this percentage of pro's are consumed, notifications will be sent to the notification email addressees"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "number",
												name: "notification_threshold",
												endAdornment: <InputAdornment position="end">%</InputAdornment>,
												value:
													this.state.notification_threshold ||
													"",
												onChange: this.handleInput(
													"notification_threshold"
												)
											}}
											white
										/>
									</GridItem>
									<GridItem xs>
										<CustomInput
											labelText="Notification Emails"
											helpText="Comma delmit multiple email addresses to be notified when threshold is met"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "notification_email",
												value:
													this.state.notification_email ||
													"",
												onChange: this.handleInput(
													"notification_email"
												)
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Notes"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												multiline: true,
												id: "note",
												name: "note",
												value: this.state.note || "",
												onChange: this.handleInput("note")
											}}
											white
										/>
									</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									<GridContainer justify="flex-end" >
										<Button
											style={{marginRight: "10px"}}
											color="info"
											onClick={this.save}
										>
											Save
										</Button>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
					{(() => {
						if(this.state.autoProType === 'warehouse') {
							return (
								<WarehouseSelector warehouses={this.state.body.warehouses} selectedWarehouses={this.state.warehouses} onChange={this.handleWarehouse} mode="select" />
							);
						} else if(this.state.autoProType === 'terminal') {
							return (
								<CarrierTerminalSelector scac={this.state.scac} onChange={this.handleTerminal} initTerminalCode={this.state.terminalCode} />
							);
						} else {
							return null
						}
					})()}
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(NewProRange));