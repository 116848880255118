import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from '~/components/Grid/GridItem.jsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from "~/components/CustomButtons/Button.jsx";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { getUserSetting } from "../../../redux/selectors/Admin.jsx";
import {
	setUpdateDist,
	setUpdateCostCenter,
	setUpdateBol,
	setUpdatePo,
	setUpdateRef,
	setUpdateRa,
	setUpdateVendor,
	setUpdateNote,
	setDeclinePayment,
	setCorrectionCode,
	setAdjustmentNote,
	setReleaseForPayment,
	setHoldsToRelease,
	setNotApproved,
	setNotApprovedNote,
	updateBill,
	setSelectedCompanyLocation,
	setCustomerPortalUploadedFiles,
} from '../actions/CustomerPortal.jsx';

class UpdateHeldBillContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
		};
	}

	render() {

		if (!this.props.canUpdate) {
			return null;
		}

		return (
			<Card style={{marginBottom: "5px"}}>
				<CardContent>
					<GridContainer>
						{this.state.alert}
						<GridItem xs={6}>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="GL Code"
					                value={this.props.updateDist || ""}
					                onChange={this.setUpdateDist}
					                disabled={this.props.loading}
					            />
							</GridItem>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="Cost/Profit Center"
					                value={this.props.updateCostCenter || ""}
					                onChange={this.setUpdateCostCenter}
					                disabled={this.props.loading}
					            />
							</GridItem>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="BOL#"
					                value={this.props.updateBol || ""}
					                onChange={this.setUpdateBol}
					                disabled={this.props.loading}
					            />
							</GridItem>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="PO#"
					                value={this.props.updatePo || ""}
					                onChange={this.setUpdatePo}
					                disabled={this.props.loading}
					            />
							</GridItem>
							<GridItem xs={12}>
								{this.props.refEntryType === "select" ? (
									<FormControl style={{ width: "100%" }} disabled={this.props.loading}>
										<InputLabel>REF#</InputLabel>
										<Select
											value={this.props.updateRef || ""}
											onChange={this.setUpdateRef}
										>
										{this.getRefEntryOpts(this.props.refEntryOpts)}
										{(this.props.refEntryOpts.indexOf(this.props.updateRef) === -1) ? (
											<MenuItem
												hidden
												key={"refopt_ff"}
												value={this.props.updateRef || ""}
											>
												{this.props.updateRef}
											</MenuItem>
										) : ''}
										</Select>
									</FormControl>
								) : (
									<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="REF#"
					                value={this.props.updateRef || ""}
					                onChange={this.setUpdateRef}
					                disabled={this.props.loading}
					            	/>
								)}
							</GridItem>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="RA#"
					                value={this.props.updateRa || ""}
					                onChange={this.setUpdateRa}
					                disabled={this.props.loading}
					            />
							</GridItem>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="Vendor#"
					                value={this.props.updateVendor || ""}
					                onChange={this.setUpdateVendor}
					                disabled={this.props.loading}
					            />
							</GridItem>
							<GridItem xs={12}>
								<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
					                label="Note"
					                value={this.props.updateNote || ""}
					                onChange={this.setUpdateNote}
					                disabled={this.props.loading}
					            />
							</GridItem>
						</GridItem>
						<GridItem xs={6}>
							{this.props.canDecline ? (
								<Fragment>
									{(this.props.canDecline && this.props.canDisapprove) ? (
										<>
											<GridItem xs={12}>
												{this.notApprovedControl()}
											</GridItem>
											{!!this.props.notApproved ? (
												<>
													<GridItem xs={12} sm={12} md={12}>
														<h5>Upload Files</h5>
														<p>Any uploaded files will be sent to your auditor as part of the disapproval.</p>
													</GridItem>
													<GridItem xs={12} sm={12} md={6}>
														<Dropzone handleUpload={this.handleUpload} />
													</GridItem>
													<GridItem xs={12} sm={12} md={6}>
														<aside>
															{!_.isEmpty(this.props.uploadedFiles) && this.getImages(this.props.uploadedFiles)}
														</aside>
													</GridItem>
												</>
											) : (
												<>
													<GridItem xs={12} sm={12} md={12}></GridItem>
													<GridItem xs={12} sm={12} md={6}></GridItem>
													<GridItem xs={12} sm={12} md={6}></GridItem>
												</>
											)}
											<GridItem xs={12}>
												<TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
													label="Disapproval Note"
													value={this.props.notApprovedNote || ""}
													onChange={this.setNotApprovedNote}
													disabled={!this.props.notApproved || this.props.loading}
												/>
											</GridItem>
										</>
									) : (
										/* This is just to keep consistent grid items/layout on page*/
										<>
											<GridItem xs={12}>
											</GridItem>
											<GridItem xs={12} sm={12} md={12}>
											</GridItem>
											<GridItem xs={12} sm={12} md={6}>
											</GridItem>
											<GridItem xs={12} sm={12} md={6}>
											</GridItem>
											<GridItem xs={12}>
											</GridItem>
										</>
									)}
									<GridItem xs={12}>
										{this.props.canDecline && this.declineControl()}
									</GridItem>
									<GridItem xs={12}>
										{this.props.canDecline && <TextField fullWidth size="small" onKeyPress={this.handleKeyPress}
							                label="Adjustment Note"
							                value={this.props.adjustmentNote || ""}
							                onChange={this.setAdjustmentNote}
							                disabled={!this.props.declinePayment || this.props.loading}
							            />}
									</GridItem>
									<GridItem xs={12}>
										{this.props.canDecline && <FormControl disabled={!this.props.declinePayment || this.props.loading}>
									        <InputLabel>Correction Code</InputLabel>
									        <Select
									         	value={this.props.correctionCode}
									         	onChange={this.handleCorrectionCode}
									        >
									         	<MenuItem value="I">Improper Billing</MenuItem>
									         	<MenuItem value="N">No Payment</MenuItem>
									        </Select>
									      </FormControl>}
									</GridItem>
								</Fragment>
							) : null}
							{this.props.canRelease ? (
								<GridItem xs={12}>
									{this.releaseControl()}
								</GridItem>
							) : null}
							<GridItem xs={12}>
								{this.locationControl()}
							</GridItem>
							<GridItem xs={12} container justify="flex-end">
								<Button
									round
									color={"success"}
									size="sm"
									onClick={this.submit}
									disabled={this.props.updateBillLoading}
								>{this.props.loading ? <CircularProgress color="inherit" size={20}/> : <span>Update</span>}</Button>
							</GridItem>
						</GridItem>
					</GridContainer>
				</CardContent>
			</Card>
		);
	}

	handleUpload = file => {
		let files = this.props.uploadedFiles;
		files.push(file);

		this.props.setCustomerPortalUploadedFiles(files);
	}

    getImages = files => {
        return files.map((prop, key) => {
			let src = "";
			let filename = "";
			let extension = "";
			if(!_.isEmpty(prop[0])) {
				src = prop[0].preview;
				filename = prop[0].name;
				extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
			}
			return <Preview key={key} src={src} index={key} extension={extension} name={filename} handleClick={() => {return null}} handleDelete={this.handleDeleteImage.bind(this)} />;
        });
    }

	handleDeleteImage(key) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this image?"
					onConfirm={() => this.deleteImage(key)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
				</SweetAlert>
			)
		});
	}

	deleteImage = (i) => {
		const { uploadedFiles } = this.props;
		this.setState({ alert: null });
		uploadedFiles.splice(i, 1);
		this.props.setCustomerPortalUploadedFiles(uploadedFiles);
	}

	releaseControl = () => {
		return (
			<GridContainer>
				<GridItem xs={12}>
					<FormControlLabel
						label="Release Hold(s)"
						control={
							<Checkbox
				            	size="small"
				                checked={this.props.releaseForPayment}
				                onChange={this.handleReleaseForPayment}
				                disabled={this.props.loading}
				            />
						}
					/>
					<FormControlLabel
						label="Release All Holds"
						control={
							<Checkbox
				            	size="small"
				                checked={this.props.holdsToRelease.length == this.props.unreleasedHolds.length}
				                onChange={this.handleReleaseAll}
				                disabled={this.props.loading}
				            />
						}
					/>
				</GridItem>
				{this.props.unreleasedHolds.map((hold, index) => {
					return (
						<GridItem xs={12} key={index}>
							<FormControlLabel
								label={(hold.hasOwnProperty('acc') && hold.acc.hasOwnProperty('code') && hold.acc.code != null) ?
									`${hold.code} - ${hold.name} - ${hold.acc.code}` :
									`${hold.code} - ${hold.name}`}
								control={
									<Checkbox
										disabled={!this.props.releaseForPayment || this.props.loading || this.props.disabledHoldIds.indexOf(hold.id) !== -1}
						            	size="small"
						            	value={hold.id}
						                checked={this.props.holdsToRelease.indexOf(hold.id) !== -1}
						                onChange={this.handleHoldCheckbox}
						            />
								}
							/>
						</GridItem>
					);
				})}
			</GridContainer>
		);
	}

	locationControl = () => {
		return this.props.canChangeLocations ? (
			<Fragment>
				<InputLabel>Change Billed Locations</InputLabel>
				<Select
					value={this.props.billedLocation}
					onChange={this.handleCompanyLocation}
				>
				{this.getLocationMenu()}

				</Select>
			</Fragment>
		) : null;
	}

	getLocationMenu = () => {
		const { companyLocations } = this.props;

		return companyLocations.filter(function(loc) {
			if(loc.isServiceFeeLoc == '1') {
				return false;
			}
			return true;
		}).map((prop, key) => {
			return (
				<MenuItem
					key={key}
					value={prop.id}
				>
					{prop.description}
				</MenuItem>
			);
		});
	}

	declineControl = () => {
		return (
			<FormControlLabel
				label="Decline Payment"
				control={
					<Checkbox
						size="small"
						checked={this.props.declinePayment}
						onChange={this.handleDeclinePayment}
						disabled={this.props.loading}
					/>
				}
			/>
		);
	}

	getRefEntryOpts(opts) {
        return opts.map((val, key) => {
            return (
                <MenuItem
                    key={`refopt_${key}`}
                    value={val}
                >
                    {((_.isEmpty(val)) ? (<span style={{color: 'transparent'}}>none</span>) : val)}
                </MenuItem>
            );
        });
    }

	notApprovedControl = () => {
		return (
			<FormControlLabel
				label="Not Approved"
				control={
					<Checkbox
						size="small"
						checked={this.props.notApproved}
						onChange={this.handleNotApproved}
						disabled={this.props.loading}
					/>
				}
			/>
		);
	}

	setUpdateDist = e => this.props.setUpdateDist(e.target.value);
	setUpdateCostCenter = e => this.props.setUpdateCostCenter(e.target.value);
	setUpdateBol = e => this.props.setUpdateBol(e.target.value);
	setUpdatePo = e => this.props.setUpdatePo(e.target.value);
	setUpdateRef = e => this.props.setUpdateRef(e.target.value);
	setUpdateRa = e => this.props.setUpdateRa(e.target.value);
	setUpdateVendor = e => this.props.setUpdateVendor(e.target.value);
	setUpdateNote = e => this.props.setUpdateNote(e.target.value);
	setAdjustmentNote = e => this.props.setAdjustmentNote(e.target.value);
	setCorrectionCode = e => this.props.setCorrectionCode(e.target.value);
	setNotApprovedNote = e => this.props.setNotApprovedNote(e.target.value);
	handleDeclinePayment = e => {
		if (e.target.checked) {
			this.props.setDeclinePayment(true);
			this.props.setNotApproved(false);
			this.props.setReleaseForPayment(false);
		} else {
			this.props.setDeclinePayment(false);
		}
	}
	handleReleaseForPayment = e => {
		if (e.target.checked) {
			this.props.setReleaseForPayment(true);
			this.props.setNotApproved(false);
			this.props.setDeclinePayment(false);
		} else {
			this.props.setReleaseForPayment(false);
		}
	}

	handleReleaseAll = e => {
		let holdsToRelease = [];
		// if releaseAll is checked, check the "Release Hold(s)" checkbox and all unreleased holds
		// if unchecked, holdsToRelease remains empty and all individual hold checkboxes are unchecked
		if (e.target.checked) {
			this.props.setReleaseForPayment(true);
			this.props.setNotApproved(false);
			this.props.setDeclinePayment(false);
			this.props.unreleasedHolds.map((hold, index) => {
				holdsToRelease.push(hold.id);
			});
		}
		this.props.setHoldsToRelease(holdsToRelease);
	}

	handleNotApproved = e => {
		if (e.target.checked) {
			this.props.setNotApproved(true);
			this.props.setDeclinePayment(false);
			this.props.setReleaseForPayment(false);
		} else {
			this.props.setNotApproved(false);
		}
	}
	handleCorrectionCode = e => {
		this.props.setCorrectionCode(e.target.value);
	}
	handleCompanyLocation = e => {
		this.props.setSelectedCompanyLocation(e.target.value);
	}
	handleKeyPress = e  => {
		if (e.key === "Enter") {
			this.submit();
		}
	}
	handleHoldCheckbox = e => {
		const id = +e.target.value;
		let holdsToRelease = this.props.holdsToRelease;
		if (holdsToRelease.indexOf(id) === -1) {
			holdsToRelease.push(id);
		} else {
			holdsToRelease.splice(holdsToRelease.indexOf(id), 1);
		}
		this.props.setHoldsToRelease(holdsToRelease);
	}
	submit = () => {
		this.props.updateBill();
	}
}

const mapStateToProps = state => {
	let hasCarrierAmtDue = state.CustomerPortal.viewingBill.financialInfo && (
		+state.CustomerPortal.viewingBill.financialInfo.carrierTotalBilled > 0  ||
		+state.CustomerPortal.viewingBill.financialInfo.carrierAmountDue > 0
	);

	let unreleasedHolds = [];
	if (state.CustomerPortal.viewingBill && Array.isArray(state.CustomerPortal.viewingBill.holds)) {
		unreleasedHolds = state.CustomerPortal.viewingBill.holds.filter((hold) => {
			return !hold.release;
		});
	}

	let disabledHoldIds = [];
	let canWrite = false;
	const overrides = getUserSetting("hold_access_overrides_by_hold_id")(state);
	if (overrides && unreleasedHolds) {
		unreleasedHolds.map(unreleasedHold => {
			if (overrides[unreleasedHold.holdCodeId]) {
				if(!+overrides[unreleasedHold.holdCodeId].write) {
					disabledHoldIds.push(unreleasedHold.id);
				} else {
					if(getUserSetting("hold_write")(state)) {
						canWrite = true;
					}
				}
			}
		});
	}

	let canDisapprove = true;
	if (state.CustomerPortal.viewingBill && (state.CustomerPortal.viewingBill.statusINT == 4 || state.CustomerPortal.viewingBill.invoiceNumber)) {
		canDisapprove = false;
	}

	return {
		canUpdate: canWrite,
		canDecline: hasCarrierAmtDue && +getUserSetting("hold_decline", 0)(state),
		canChangeLocations: +getUserSetting("hold_change_locations", 0)(state),
		canRelease: unreleasedHolds.length ? true : false,
		loading: state.CustomerPortal.updateBillLoading,
		refEntryType: state.CustomerPortal.refEntryType,
		refEntryOpts: state.CustomerPortal.refEntryOpts,
		updateDist: state.CustomerPortal.updateDist,
		updateCostCenter: state.CustomerPortal.updateCostCenter,
		updateBol: state.CustomerPortal.updateBol,
		updatePo: state.CustomerPortal.updatePo,
		updateRef: state.CustomerPortal.updateRef,
		updateVendor: state.CustomerPortal.updateVendor,
		updateRa: state.CustomerPortal.updateRa,
		updateNote: state.CustomerPortal.updateNote,
		declinePayment: state.CustomerPortal.declinePayment,
		correctionCode: state.CustomerPortal.correctionCode,
		companyLocations: state.CustomerPortal.tfmInvoiceLocations,
		billedLocation: state.CustomerPortal.tfmInvoiceBilledLocation,
		adjustmentNote: state.CustomerPortal.adjustmentNote,
		releaseForPayment: state.CustomerPortal.releaseForPayment,
		holdsToRelease: state.CustomerPortal.holdsToRelease,
		notApproved: state.CustomerPortal.notApproved,
		notApprovedNote: state.CustomerPortal.notApprovedNote,
		uploadedFiles: state.CustomerPortal.uploadedFiles,
		canDisapprove,
		unreleasedHolds,
		disabledHoldIds,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setUpdateDist,
		setUpdateCostCenter,
		setUpdateBol,
		setUpdatePo,
		setUpdateRef,
		setUpdateRa,
		setUpdateVendor,
		setUpdateNote,
		setDeclinePayment,
		setCorrectionCode,
		setAdjustmentNote,
		setReleaseForPayment,
		updateBill,
		setHoldsToRelease,
		setNotApproved,
		setNotApprovedNote,
		setSelectedCompanyLocation,
		setCustomerPortalUploadedFiles,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(UpdateHeldBillContainer));
