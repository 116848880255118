import React, { Component } from "react";
import { connect } from "react-redux";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import axios from "~/variables/axios.jsx";
import { searchObject, validateItem } from "../../helpers.jsx";
import FormControl from "@material-ui/core/FormControl";
import Search from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import { hasPermission, parcelWeightLimit } from "../../redux/selectors/Admin.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

import HazardousOptionsModal from "../../components/HazardousOptionsModal/HazardousOptionsModal.jsx";

class HazmatInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hazInfo: [],
			showHazOptions: false,
			selectedKey: null,
			transportationMode: "",
			un: "",
			properShippingName: "",
			technicalName: "",
			requiredLabel: "",
			hazardClass: "",
			subHazardClass: "",
			packingGroup: "",
			packingInstructionCode: "",
			packagingType: "",
			innerContainerType: "",
			regulatoryQuanity: "",
			uom: "",
			quanity: "",
			accessibility: "",
			allowedUnitsOfMeasure: [],
		};
	}

	getInnerContainer() {
		const { classes } = this.props;
		let data = [
			"",
			"AluminiumBag",
			"AluminiumCans",
			"AluminiumTin",
			"AluminiumTube",
			"Earthenware",
			"FiberBox",
			"FiberCan",
			"GlassAmpoule",
			"GlassTube",
			"MetalCans",
			"MetalFlexibleTube",
			"MetalTin",
			"MetalTube",
			"MultiWallPaperBag",
			"PaperBag",
			"PlasticBag",
			"PlasticContainer",
			"PlasticFlexibleTube",
			"PlasticTube",
			"Receptacle",
		];

		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected,
					}}
					value={prop}
				>
					{prop ? prop.replace(/([A-Z])/g, ' $1').trim() : 'None'}
				</MenuItem>
			);
		});
	}

	getClasses() {
		const { classes } = this.props;
		let data = [
			"",
			"1.4",
			"1.4A",
			"1.4B",
			"1.4C",
			"1.4D",
			"1.4E",
			"1.4F",
			"1.4G",
			"1.4H",
			"1.4J",
			"1.4K",
			"1.4L",
			"1.4S",
			"1.4N",
			"2.1",
			"2.2",
			"3",
			"3,6.1",
			"3,8",
			"4.1",
			"4.2",
			"4.3",
			"5.1",
			"5.2",
			"6.1",
			"6.1,3",
			"6.1,8",
			"6.2",
			"7",
			"8",
			"8,3",
			"9",
		];
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected,
					}}
					value={prop}
				>
					{prop ? prop : 'None'}
				</MenuItem>
			);
		});
	}

	handleHazOptionsSubmit = (selection) => {
		let product = this.state.hazInfo[selection].ProductObject;
		if(product.PackingGroup == 'None') {
			product.PackingGroup = '';
		}
		this.props.setHazUNInfo(
			product,
			this.state.selectedKey,
			selection
		);
		this.closeHazOptions();
	};

	closeHazOptions = () => {
		this.setState({
			showHazOptions: false,
			hazInfo: [],
		});
	};

	showSelectHazInfo = (data, key) => {
		this.setState({
			hazInfo: data,
			selectedKey: key,
			showHazOptions: true,
		});
	};


	getSubClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.props.state;
		let data = [
			"",
			"1.4",
			"1.4A",
			"1.4B",
			"1.4C",
			"1.4D",
			"1.4E",
			"1.4F",
			"1.4G",
			"1.4H",
			"1.4J",
			"1.4K",
			"1.4L",
			"1.4S",
			"1.4N",
			"2.1",
			"2.2",
			"3",
			"3,6.1",
			"3,8",
			"4.1",
			"4.2",
			"4.3",
			"5.1",
			"5.2",
			"6.1",
			"6.1,3",
			"6.1,8",
			"6.2",
			"7",
			"8",
			"8,3",
			"9",
		];
		if (regulation_set === "cfr") {
			// Hanging on to this in case it is a UPS thing?
			data = [
				"",
				"1.4A",
				"1.4B",
				"1.4C",
				"1.4D",
				"1.4E",
				"1.4F",
				"1.4G",
				"1.4H",
				"1.4J",
				"1.4K",
				"1.4L",
				"1.4S",
				"1.4N",
				"2.1",
				"2.2",
				"3",
				"3,6.1",
				"3,8",
				"4.1",
				"4.3",
				"5.1",
				"5.2",
				"6.1",
				"6.1,3",
				"6.1,8",
				"6.2",
				"7",
				"8",
				"8,3",
				"9",
			];
		}
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected,
					}}
					value={prop}
				>
					{prop ? prop : 'None'}
				</MenuItem>
			);
		});
	}

	async handleHazUNSearch(key) {
		this.setState({
			selectedKey: null,
		});

		const { packages } = { ...this.props.state };
		if (!_.isEmpty(packages)) {
			const prop = packages[key];

			if (prop.hazmat.un != "" && this.props.state.packages[0].hazmat.transportationMode != "") {
				let warehouseCode = this.props.state.warehouse
				if (this.props.warehouseCode) {
					warehouseCode = this.props.warehouseCode
				}
				const url =
					"/index.php?p=api&c=hazmatComplianceEngine&m=getUNInformation&unNumber=" +
					prop.hazmat.un +
					"&transportation=" +
					(this.props.state.packages[0].hazmat.transportationMode
						.toUpperCase()
						.indexOf("GROUND") >= 0
						? "Ground"
						: "Air")
						+ "&warehouse=" + encodeURIComponent(warehouseCode);
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (
					response &&
					response.data &&
					response.data.body &&
					response.data.body.response &&
					!_.isEmpty(response.data.body.response)
				) {
					const responseData = response.data.body.response;
					if (Array.isArray(responseData)) {
						this.showSelectHazInfo(responseData, key);
					} else {
						let product = responseData.ProductObject;
						if(product.PackingGroup == 'None') {
							product.PackingGroup = '';
						}
						this.props.setHazUNInfo(product, key);
					}
				} else if(!response.data.message.length) {
					this.props.handleMessages([
						{
							type: "warning",
							message: "No response data available for this UN number.",
						},
					]);
				}
			} else {
				if (prop.hazmat.un == "") {
					this.props.handleMessages([
						{
							type: "warning",
							message: "A UN number must be provided to search.",
						},
					]);
				} else if (this.props.state.packages[0].hazmat.transportationMode == "") {
					this.props.handleMessages([
						{
							type: "warning",
							message: "Transportation mode must be selected to search.",
						},
					]);
				}
			}
		}
	}

	handleHazardous = (i, name, event) => {
		const { packages } = this.props.state;
		if (!_.isEmpty(packages)) {
			const prop = packages[i];
			if (name == "transportationMode" || name == "un") {
				//reset shipment info, but keep the user-entered info.
				prop.hazmat = {
					accessibility: "",
					class: "",
					emergencyPhone: prop.hazmat.emergencyPhone,
					emergencyResponseRegistrant: prop.hazmat.emergencyResponseRegistrant,
					innerContainerType: prop.hazmat.innerContainerType,
					intlEmergencyPhone: prop.hazmat.intlEmergencyPhone,
					offeror: prop.hazmat.offeror,
					packagingType: prop.hazmat.packagingType,
					packingGroup: "",
					packingInstructionCode: "",
					properShippingName: "",
					quantity: prop.hazmat.quantity,
					regulatoryQuantity: "",
					requiredLabel: "",
					signatoryContactName: prop.hazmat.signatoryContactName,
					signatoryPlace: prop.hazmat.signatoryPlace,
					signatoryTitle: prop.hazmat.signatoryTitle,
					subHazardClass: "",
					technicalName: "",
					transportationMode: prop.hazmat.transportationMode,
					un: prop.hazmat.un,
					uom: "",
				};
			}

			prop.hazmat[name] = event.target.value;
			packages[i] = prop;
			this.setState({
				packages,
				parcelRates: [],
			});
			this.props.getTotalWeight;
		}
	};

	getServices = () => {
		const { classes } = this.props;

		let services = this.props.state.services;
		const access = this.props.packages[0].hazmat.accessibility;

		return services.map((service, key) => {
			if (access == "ACCESSIBLE" && this.state.hazmat === true) {
				if (
					service.value != "FEDEX_GROUND" &&
					service.value != "PRIORITY_OVERNIGHT" &&
					service.value != "FIRST_OVERNIGHT"
				) {
					return false;
				}
			}

			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected,
					}}
					value={service.value}
				>
					{service.name}
				</MenuItem>
			);
		});
	};

	render() {
		const { classes } = this.props;
		const prod = this.props.prod;
		const key = this.props.pos;
		const props = this.props.state;
		const catalogForm = props.parcel_view || false;
		const isQuotePage = this.props.isQuotePage || false;

		return (
			<>
				<HazardousOptionsModal
					title={<span>Please select the proper hazardous option</span>}
					open={this.state.showHazOptions}
					onSubmit={this.handleHazOptionsSubmit}
					onClose={this.closeHazOptions}
					data={this.state.hazInfo}
					transMode={searchObject(props.packages, '0.hazmat.transportationMode', '')}
				/>

				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>
									Transportation Mode
								</InputLabel>
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: catalogForm ? classes.select : classes.select + " " + classes.requiredSelect,
										}}
										value={validateItem(prod.hazmat.transportationMode, "transMode") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "transportationMode", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value=""
										>
											None
										</MenuItem>
										{props.regulation_set === "iata" && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="Ground"
											>
												Ground
											</MenuItem>
										)}
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="Passenger"
										>
											Passenger Aircraft
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="Cargo"
										>
											Cargo Aircraft Only
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem sm>
								<InputLabel className={classes.label}>UN # </InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: false,
									}}
									inputProps={{
										type: "text",
										name: "un",
										value: prod.hazmat.un || "",
										onChange: (e) => this.handleHazardous(key, "un", e),
									}}
									required={catalogForm ? false : true}
								/>
								<Button
									color="white"
									size="sm"
									justIcon
									round
									className={classes.marginLeft}
									onClick={(e) => this.handleHazUNSearch(key)}
								>
									<Search />
								</Button>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>
									Proper Shipping Name
								</InputLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "properShippingName",
										value: prod.hazmat.properShippingName || "",
										onChange: (e) =>
											this.handleHazardous(key, "properShippingName", e),
									}}
									required={catalogForm ? false : true}

								/>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>Technical Name</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "technicalName",
										value: prod.hazmat.technicalName || "",
										onChange: (e) =>
											this.handleHazardous(key, "technicalName", e),
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>Required Label</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "requiredLabel",
										value: prod.hazmat.requiredLabel || "",
										onChange: (e) =>
											this.handleHazardous(key, "requiredLabel", e),
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>Hazard Class</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: catalogForm ? classes.select : classes.select + " " + classes.requiredSelect,
										}}
										value={validateItem(prod.hazmat.class, "class") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "class", e)
										}
									>
										{this.getClasses()}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>Sub Hazard Class</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: classes.select,
										}}
										value={validateItem(prod.hazmat.subHazardClass, "class") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "subHazardClass", e)
										}
									>
										{this.getSubClasses()}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>Packing Group</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: classes.select,
										}}
										value={validateItem(prod.hazmat.packingGroup, "pkgGroup") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "packingGroup", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value=""
										>
											None
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="I"
										>
											I
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="II"
										>
											II
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="III"
										>
											III
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>
									Packing Inst. Code
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "packingInstructionCode",
										value: prod.hazmat.packingInstructionCode || "",
										onChange: (e) =>
											this.handleHazardous(key, "packingInstructionCode", e),
									}}

								/>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>Packaging Type</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: catalogForm ? classes.select : classes.select + " " + classes.requiredSelect,
										}}
										value={validateItem(prod.hazmat.packagingType, "pkgType") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "packagingType", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value=""
										>
											None
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="CYLINDER"
										>
											Cylinder
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="FIBERBOARD"
										>
											Fiberboard Box
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="PLASTIC BOX"
										>
											Plastic Box
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="PLASTIC DRUM"
										>
											Plastic Drum
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="PLASTIC JERRICAN"
										>
											Plastic Jerrican
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="STEEL DRUM"
										>
											Steel Drum
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="WOODEN BOX"
										>
											Wooden Box
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>
									Inner Container Type
								</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: classes.select,
										}}
										value={validateItem(prod.hazmat.innerContainerType, "innerContainer") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "innerContainerType", e)
										}
									>
										{this.getInnerContainer()}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>
									Regulatory Quantity
								</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: classes.select,
										}}
										value={validateItem(prod.hazmat.regulatoryQuantity, "regQty") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "regulatoryQuantity", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value=""
										>
											None
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="FR"
										>
											Fully Regulated
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="LQ"
										>
											Limited Quantity
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>UOM</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: catalogForm ? classes.select : classes.select + " " + classes.requiredSelect,
										}}
										value={validateItem(prod.hazmat.uom, "uom") || ""}
										onChange={(e) => this.handleHazardous(key, "uom", e)}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value=""
										>
											None
										</MenuItem>
										{props.allowedUnitsOfMeasure.indexOf("cylinder") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="cylinder"
											>
												cylinders
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("g") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="g"
											>
												g
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("gallon") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="gallon"
											>
												gallon
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("kg") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="kg"
											>
												kg
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("kgG") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="kg G"
											>
												kg G
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("L") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="L"
											>
												L
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("mL") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="ml"
											>
												ml
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("oz") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="ounce"
											>
												ounce
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("pint") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="pint"
											>
												pint
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("lbs") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="pound"
											>
												pound
											</MenuItem>
										)}
										{props.allowedUnitsOfMeasure.indexOf("qt") >= 0 && (
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value="quart"
											>
												quart
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>Accessibility</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{
											select: catalogForm ? classes.select : classes.select + " " + classes.requiredSelect,
										}}
										value={validateItem(prod.hazmat.accessibility, "accessibility") || ""}
										onChange={(e) =>
											this.handleHazardous(key, "accessibility", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value=""
										>
											None
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="ACCESSIBLE"
										>
											Accessible
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value="INACCESSIBLE"
										>
											Inaccessible
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>Quantity</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "number",
										name: "quantity",
										value: prod.hazmat.quantity || "",
										inputProps: { min: 0 },
										onChange: (e) => this.handleHazardous(key, "quantity", e),
									}}
									required={catalogForm ? false : true}
								/>
							</GridItem>

						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>

							{isQuotePage && (
								<>
									<GridItem xs>
										<InputLabel className={classes.label}>Offeror</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												type: "text",
												name: "offeror",
												value: prod.hazmat.offeror || "",
												onChange: (e) =>
													this.handleHazardous(key, "offeror", e),
											}}
											required
										/>
									</GridItem>

									<GridItem xs>
										<InputLabel className={classes.label}>
											Emergency Phone
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												type: "text",
												name: "emergencyPhone",
												value: prod.hazmat.emergencyPhone || "",
												onChange: (e) =>
													this.handleHazardous(key, "emergencyPhone", e),
											}}
											required
										/>
									</GridItem>
								</>
							)}
						</GridContainer>
					</GridItem>
					{isQuotePage && (
						<>
							<GridItem xs>
								<InputLabel className={classes.label}>
									Emergency Response Registrant #
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "emergencyResponseRegistrant",
										value: prod.hazmat.emergencyResponseRegistrant || "",
										onChange: (e) =>
											this.handleHazardous(
												key,
												"emergencyResponseRegistrant",
												e
											),
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>
									International Emergency Phone
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "intlEmergencyPhone",
										value: prod.hazmat.intlEmergencyPhone || "",
										onChange: (e) =>
											this.handleHazardous(key, "intlEmergencyPhone", e),
									}}
									required
								/>
							</GridItem>
						</>
					)}
					{isQuotePage && (
						<GridItem>
							<GridContainer>
								<GridItem xs>
									<InputLabel className={classes.label}>
										Signatory Contact Name
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: "text",
											name: "signatoryContactName",
											value: prod.hazmat.signatoryContactName || "",
											onChange: (e) =>
												this.handleHazardous(
													key,
													"signatoryContactName",
													e
												),
										}}
										required
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>
										Signatory Title
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: "text",
											name: "signatoryTitle",
											value: prod.hazmat.signatoryTitle || "",
											onChange: (e) =>
												this.handleHazardous(key, "signatoryTitle", e),
										}}
										required
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>
										Signatory Place
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: "text",
											name: "signatoryPlace",
											value: prod.hazmat.signatoryPlace || "",
											onChange: (e) =>
												this.handleHazardous(key, "signatoryPlace", e),
										}}
										required
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
				</GridContainer>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		hasAddressBookRead: hasPermission("ADDRESS_BOOK_READ")(state),
		canCreateBol: hasPermission("USE_BILL_OF_LADING")(state),
		weightLimit: parcelWeightLimit(state),
	};
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(HazmatInfo)));
