const url = window.location.href;
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const requestUri = window.location.pathname;
const https = (protocol === "https:") ? true : false;
const httpHost = protocol + "//" + hostname;
const basePath = (requestUri.includes("/root")) ? requestUri.substring(0, (requestUri.indexOf("/root") + 5)) : "";
const pathname = (basePath !== "") ? requestUri.replace(basePath, "") : requestUri;
const baseUrl = httpHost + basePath;
const assetPath = "/view/js/react/src/assets";
const apiUrl = (requestUri.includes("/root")) ? baseUrl.replace(/^([:])([0-9]{4})$/g, ":80/api") : baseUrl;

module.exports = {
	url,
	protocol,
	hostname,
	requestUri,
	pathname,
	https,
	basePath,
	httpHost,
	baseUrl,
	assetPath,
	apiUrl
};
