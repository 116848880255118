import React from "react";
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import moment from "moment";
import { NavLink } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class GlobalNotifications extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			filters: {
				id: "",
				name: "",
				carrier: "",
				accessorial: "",
				startDate: "",
				endDate: ""
			}
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.deleteMessage = this.deleteMessage.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=system&m=messages"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getHeaders() {
		return [
			"ID",
			"Visibility",
			"Start",
			"Stop",
			"Message",
			"Added By",
			"Added On",
			"Actions"
		];
	}
	getColumns(data) {
		const { classes } = this.props;
		const link = basePath + '/admin/system/message/';
		return data.map((prop, key) => {
			return [
				prop.id,
				prop.display_to,
				moment.unix(prop.start_date).format("LLLL"),
				moment.unix(prop.end_date).format("LLLL"),
				<Paper className={classes.infoPaper} elevation={1}>
					<Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{prop.message}</Markdown>
				</Paper>,
				prop.username,
				moment.unix(prop.timestamp).format("LLLL"),
				<div className="actions-right">
					<NavLink to={link + prop.id}>
						<Button
							size="sm"
							color="info"
							style={{
								marginRight: "4px"
							}}
						>
							View/Edit
						</Button>
					</NavLink>
					<Button
						size="sm"
						color="danger"
						style={{
							marginRight: "4px"
						}}
						onClick={() => this.deleteMessage(prop.id)}
					>
						Delete
					</Button>
				</div>
			];
		});
	}
	async deleteMessage(id) {
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=system&m=deleteMessage&d=" + id
			);
			const data = response.data;
			if (typeof data !== "string") {
				this.props.handleMessages(response);
				this.setState({
					loading: false,
					body: response.data.body,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an unexpected error while deleting the system message!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred while deleting the system message!", {
				variant: "error"
			});
		}
	}
	render() {
		const { classes } = this.props;
		const link = basePath + "/admin/system/message/new";
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											style={{
												marginRight: "4px"
											}}
										>
											New Message
										</Button>
									</NavLink>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(this.state.body)}
												customCellClasses={[
													classes.right
												]}
												customClassesForCells={[7]}
												customHeadCellClasses={[
													classes.right
												]}
												customHeadClassesForCells={[7]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{
														"No system messages to display"
													}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve system messages from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(
	withSnackbar(GlobalNotifications)
);