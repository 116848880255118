
var utils = utils || {};


// forces string conversion
// defaults to empty string on invalid value
utils.toString = function (v) {
    return v ? String(v) : '';
}

// forces float conversion
// defaults to 0.0 on invalid value
utils.toFloat = function (v) {
    return parseFloat(v) || 0.0;
}

// forces Date conversion
// defaults to unix epoch on invalid value
utils.toDate = function (v) {
    return Date.parse(v) || new Date(0);
}

// capitalize the first letter or string
// defaults to empty string on invalid value
utils.titleCase = function(v){
    return (typeof v === "string" && v.length) ? v[0].toUpperCase() + v.slice(1).toLowerCase() : "";
  }


export default utils;