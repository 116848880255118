import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import qs from "qs";
import { Redirect} from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import DateRangePicker from "~/components/DateRangePicker/DateRangePicker.jsx";
import { handleReactDatetimeChange } from '../../../helpers.jsx';

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

import ScheduleRecipients from "~/components/TMS/ReportScheduleRecipients.jsx";
import TemplateDefinition from "~/components/TMS/ReportScheduleTemplateDefinition.jsx";
import FilterDefinition from "~/components/TMS/ReportScheduleFilterDefinition.jsx";

class ScheduledReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            show: false,
            loading: true,
            redirect: false,
            activeTab: "details",
            report_id: null,
            template_id: null,
            offset_date: '',
            type: '',
            name: '',
            description: '',
            schedule: '',
            format: '',
            recipients: [],
            ftp_site: '',
            ftp_dir: '',
            ftp_user: '',
            ftp_pass: '',
            columns: [],
            filters: [],
            jsonReport: '',
            email: '',
            start_date: '',
            end_date: '',
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleTemplateDefinition = this.handleTemplateDefinition.bind(this);
        this.handleScheduleRecipients = this.handleScheduleRecipients.bind(this);
        this.handleFilterDefinition = this.handleFilterDefinition.bind(this);
        this.save = this.save.bind(this);
        this.runReport = this.runReport.bind(this);
    }

    async componentDidMount() {
        this.setState({ mounted: true });
        if(!_.isEmpty(this.props.match.params.id)) {
            const url = '/index.php?p=api&r=json&c=reporting&m=editScheduledReport&d=' + this.props.match.params.id;
            try {
                const response = await axios.get(url);
                if (typeof response.data !== "string") {
                    this.props.handleMessages(response);
                    if(!_.isEmpty(response.data.body)) {
                        const { report_id, template_id, type, name, description, schedule, format, recipients, ftp_site, ftp_dir, ftp_user, ftp_pass, columns, filters } = response.data.body.report;
                    	this.props.pageTitle('Edit Scheduled Report - ' + name);
                        const { jsonReport } = response.data.body;
                        this.setState({ report_id, template_id, type, name, description, schedule, format, recipients, ftp_site, ftp_dir, ftp_user, ftp_pass, columns, filters, jsonReport, loading: false, show: true });
                    }
                } else {
                    this.props.setNotification("An unexpected error occurred fetching the report data!", { variant: "error" });
                }
            } catch (error) {
                console.error(error);
                this.props.setNotification("An exception occurred fetching the report data!", { variant: "error" });
            }
        } else if(!_.isEmpty(this.props.match.params.tplId)) {
            const url = '/index.php?p=api&r=json&c=reporting&m=scheduleNewReport&d=' + this.props.match.params.tplId;
            try {
                const response = await axios.get(url);
                if (typeof response.data !== "string") {
                    if(response.data.body !== false) {

                        const { template_id, type, name, description, columns} = response.data.body;
						this.props.pageTitle('Schedule New Report');
                        const filters = [];

                        for(var i = 0; i < response.data.body.filters.length; i++) {
                        	filters.push({filter: response.data.body.filters[i], value: ""});
                        }

                        this.setState({ template_id, type, name, description, columns, filters, loading: false, show: true });
                    }
                } else {
                    this.props.setNotification("An unexpected error occurred fetching the required template data!", { variant: "error" });
                }
            } catch (error) {
                console.error(error);
                this.props.setNotification("An exception occurred fetching the required template data!", { variant: "error" });
            }
        } else {
            console.error('No IDs were provided to ScheduleReport, see admin routes for proper URI.');
        }
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }



    handleTabChange = (event, tab) => {
        this.setState({ activeTab: tab });
    }

    handleInput(event) {
    	this.setState({ [event.target.name]: event.target.value });
    }

    handleDateRangeChange(dates) {
        this.setState({
            start_date: handleReactDatetimeChange(dates.startDate, "MM/DD/YYYY"),
            end_date: handleReactDatetimeChange(dates.endDate, "MM/DD/YYYY")
        });
    }

    handleTemplateDefinition(format) {
        this.setState({ format });
    }

    handleScheduleRecipients(data) {
        const { schedule, recipients, ftp_site, ftp_dir, ftp_user, ftp_pass, offset_date } = data;
        this.setState({ schedule, recipients, ftp_site, ftp_dir, ftp_user, ftp_pass, offset_date });
    }

    handleFilterDefinition(data) {
        const { filters } = data;
        this.setState({ filters });
    }

    async save() {
        this.setState({ loading: true });
        const { report_id, template_id, type, name, description, format, schedule, ftp_site, ftp_dir, ftp_user, ftp_pass } = this.state;

        let filters = {};
        let recipients = this.state.recipients.join(',');

        let offset_date = (typeof this.state.offset_date == 'object') ? this.state.offset_date.format('LLL') : this.state.offset_date;

        if(offset_date.length < 1) {
            this.props.setNotification("Please provide an offset date and time.", { variant: "error" });
            return;
        }

        for(var i = 0; i < this.state.filters.length; i++) {
            if(this.state.filters[i].filter === 'exclude_statuses') {
                filters[this.state.filters[i].filter] = this.state.filters[i].value.split(',').map((prop, key) => {
                    return prop.replace(/\'/g, "");
                });
            } else {
                filters[this.state.filters[i].filter] = this.state.filters[i].value;
            }
        }

        const data = { report_id, template_id, type, offset_date, name, description, format, schedule, recipients, ftp_site, ftp_dir, ftp_user, ftp_pass, filters, columns: [] };
        const url = '/index.php?p=api&r=json&c=reporting&m=scheduleReport';

        try {
            const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string") {
                this.props.handleMessages(response);
                if(response.data.body) {
                    this.setState({ loading: false, redirect: <Redirect to={basePath + "/admin/scheduled-reports"} /> });
                } else {
                    this.setState({ loading: false });
                }
            } else {
                this.setState({ loading: false });
                this.props.setNotification("An unexpected error occurred scheduling the report!", { variant: "error" });
            }
        } catch(error) {
            console.error(error);
            this.props.setNotification("An exception occurred scheduling the report!", { variant: "error" });
            this.setState({ loading: false });
        }
    }

    async runReport() {
        this.setState({
            loading: true
        });

        const { report_id, start_date, end_date, email } = this.state;

        if (report_id < 1) {
            this.props.setNotification("The report must exist to run on demand!", { variant: "error" });
            this.setState({
                loading: false,
            });
            return false;
        }

        if (_.isEmpty(email)) {
            this.props.setNotification("Email is required to run on demand!", { variant: "error" });
            this.setState({
                loading: false,
            });
            return false;
        }

        let data = { report_id, email };

        if (!_.isEmpty(start_date) && !_.isEmpty(end_date)) {
            const startDate = moment(start_date, "MM/DD/YYYY");
            const endDate = moment(end_date, "MM/DD/YYYY");

            if (!startDate.isValid() || !endDate.isValid()) {
                this.props.setNotification("Invalid date(s)", { variant: "error" });
                this.setState({ loading: false });
                return false;
            }

            if (endDate.diff(startDate, 'days') < 0) {
                this.props.setNotification("Start date must be before end date", { variant: "error" });
                this.setState({ loading: false });
                return false;
            }

            // plus 1 to include start date, 366 to account for leap years
            if (endDate.diff(startDate, 'days') + 1 > 366) {
                this.props.setNotification("The difference between start date and end date cannot be greater than one year", { variant: "error" });
                this.setState({ loading: false });
                return false;
            }

            data['start_date'] = start_date;
            data['end_date'] = end_date;
        }



        let url = '/index.php?p=api&c=reporting&m=runOnDemandReport';

        try {

            const response = await axios.post(
                url,
                qs.stringify(data)
            );

            if (!_.isEmpty(response.data)) {

                this.props.handleMessages(response);

                if (response.data.body !== false) {

                    this.setState({
                        loading: false,
                    });

                } else {

                    this.setState({ loading: false });
                    this.props.setNotification(
                        "Unable to run report!", { variant: "error" }
                    );

                }

            } else {

                this.setState({ loading: false });
                this.props.setNotification(
                    "An unexpected response was received while attempting to run report!", { variant: "error" }
                );

            }

        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
            this.props.setNotification(
                "An error occured while running the report!", { variant: "error" }
            );
        }

    }

    render() {
        if(this.state.redirect) {
            return this.state.redirect;
        }
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TabContext value={this.state.activeTab}>
                        <TabList onChange={this.handleTabChange} textColor="primary">
                            <Tab label="Report Details" value="details" />
                            <Tab label="Filter Definition" value="filters" />
                            <Tab label="Template Definition" value="template" />
                            <Tab label="Schedule and Recipients" value="schedule" />
                        </TabList>
                        <GridContainer>
                            <GridItem  xs={12} sm={12} md={12}>
                                <TabPanel value="details">
                                    <InputLabel
                                        htmlFor="name"
                                        className={
                                            classes.label
                                        }
                                    >
                                        Report Name
                                    </InputLabel>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            id: "name",
                                            name: "name",
                                            value: this.state.name || "",
                                            onChange: (e) => {this.handleInput(e)}
                                        }}
                                        white
                                    />

                                    <InputLabel
                                        htmlFor="description"
                                        className={
                                            classes.label
                                        }
                                    >
                                        Description
                                    </InputLabel>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            id: "description",
                                            name: "description",
                                            value: this.state.description || "",
                                            onChange: (e) => {this.handleInput(e)}
                                        }}
                                        white
                                    />

                                    <Accordion defaultExpanded color={"linkedin"}>
                                        <AccordionSummary id="on-demand" aria-controls="on-demand" expandIcon={<ExpandMoreIcon />}>
                                            <strong>Run On-Demand</strong>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h6><strong>Recipient Override</strong></h6>
                                                    <InputLabel
                                                        htmlFor="email"
                                                        className={
                                                            classes.label
                                                        }
                                                    >
                                                        Email
                                                    </InputLabel>
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            type: "text",
                                                            id: "email",
                                                            name: "email",
                                                            value: this.state.email || "",
                                                            onChange: (e) => { this.handleInput(e) }
                                                        }}
                                                        required
                                                        white
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h6><strong>Filter Override</strong></h6>
                                                    <InputLabel
                                                        htmlFor="execution_date"
                                                        className={
                                                            classes.label
                                                        }
                                                    >
                                                        Execution Date
                                                    </InputLabel>
                                                    <DateRangePicker
                                                        dateFormat="MM/DD/YYYY"
                                                        onChange={dates => { this.handleDateRangeChange(dates) }}
                                                        width={275}
                                                        inputProps={{
                                                            id: "execution_date",
                                                            name: "execution_date",
                                                            placeholder: "Select Execution Date Range"
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={3}>
                                                    <Button
                                                        fullWidth
                                                        color={"linkedin"}
                                                onClick={() => { this.runReport() }}
                                                    >
                                                        Run Report
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </TabPanel>
                                <TabPanel value="filters">
                                    <FilterDefinition
                                        filters={this.state.filters}
                                        onChange={this.handleFilterDefinition}
                                    />
                                </TabPanel>
                                <TabPanel value="template">
                                    <TemplateDefinition
                                        format={this.state.format}
                                        columns={this.state.columns}
                                        onChange={this.handleTemplateDefinition}
                                    />
                                </TabPanel>
                                <TabPanel value="schedule">
                                    <ScheduleRecipients
                                        report_id={this.state.report_id}
                                        schedule={this.state.schedule}
                                        offset_date={this.state.offset_date}
                                        recipients={this.state.recipients}
                                        ftp_site={this.state.ftp_site}
                                        ftp_dir={this.state.ftp_dir}
                                        ftp_user={this.state.ftp_user}
                                        ftp_pass={this.state.ftp_pass}
                                        onChange={this.handleScheduleRecipients}
                                    />
                                </TabPanel>
                            </GridItem>
                        </GridContainer>
                    </TabContext>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}/>
                <GridItem xs={4} sm={4} md={4} style={{marginTop: "10px"}}>
                	<Button
						fullWidth
						color="linkedin"
						onClick={() => {this.save()}}
					>
						Schedule Report
					</Button>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(ScheduledReport)));