import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { basePath } from '../../../variables/server.jsx';
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Search from "@material-ui/icons/Search";
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import ViewImagesModal from "./ViewImagesModal.jsx";

import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import ReactTable from '~/components/ReactTable/ReactTable.jsx';
import GridItem from "~/components/Grid/GridItem.jsx";

import { 
	applyInvoiceListColumns,
	applyInvoiceListCustomColumns,
	saveCustomInvoiceListColumns,
 } from '../actions/CustomerPortal.jsx';

import _ from "lodash";

// lib
import utils from '~/lib/utils.js';


/**
 * Renders a table with bill data for a list of bills using ReactTable
 * This is basically just a ReactTable configured to show the columns associated with lists of 
 * carrier invoice data and provide a few configurable features like selecting bills, hiding columns, etc
 * Includes the viewImages modal for loading carrier documents
 *
 * Available props:
 * selectedInvoices - array - list of invoice IDs for checking the checkbox in the first column
 * hideColumns - array - pass an array of column identifiers to hide columns
 * loading - bool - passed to react table
 * proCellFunction - function - for customizing how the PRO column is rendered
 * canViewBill - boolean - toggles whether or not to render a link in the PRO column
 * handleCheckbox - function - callback for clicking a checkbox
 * handleSelectAll - function - callback for clicking the select all control
 * data - array - array of invoice objects in the format as returned by index.php?p=api&r=json&c=billing&m=getBilledShipments
 */
class InvoiceList extends Component {

	state = {
		images: [],
		imageModalOpen: false,
		update: false
	}

	updateCols = () => {
		this.setState({update: !!this.state.update})
	}

	columns = () => {
		const columns = [
			{
				Header: () => {
					return (
						<Checkbox 
							size="small"
							checked={!_.isEmpty(this.props.selectedInvoices) && this.props.selectedInvoices.length !== 0 && this.props.selectedInvoices.length === this.props.data.length}
							onChange={this.handleSelectAll.bind(this)}
						/>
					);
				}, 
				Cell: this.checkBoxCell, 
				identifier: "checkboxes",
				accessor: "checkboxes",
				width: 75,
				sortable: false, 
				resizable: false
			},
			{
				Header: "View Bill", 
				Cell: this.viewBillCell, 
				identifier: "docs",
				accessor: "docs",
				width: 100,
				sortable: false,
				resizable: false,
				colDisabled: false
			},
			{
				Header: "Hold Status",         
				identifier: "hold status",
				accessor: "code", 
				width: 125,
				type: String,
				sortable: true,
				resizable: false,
				colDisabled: false
			},
			{
				Header: "Hold Comments",
				Cell: this.holdCommentsCell,
				identifier: "hold comments",
				accessor: "hold_comment", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Remark",              
				identifier: "remark",
				accessor: "customer_notes", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Pro #",               
				Cell: this.proCell, 
				accessor: "pro_number",
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Dist Code",           
				accessor: "gl_code", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Cost/Profit Center",	
				accessor: "dist_code", 
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Carrier",             
				accessor: "carrier_name", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Carrier #",           
				accessor: "carrier_number",
				width: 150, 
				type: Number,
				colDisabled: false
			},
			{
				Header: "Invoice #",           
				accessor: "carrier_invoice_number", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Amount Paid",         
				Cell: this.amountPaidCell, 
				accessor: "carrier_total_paid", 
				width: 150,
				type: Number, 
				sortValue: (r, id) => utils.toFloat(r['carrier_total_billed']) - utils.toFloat(r['carrier_amt_due']),
				colDisabled: false
			},
			{
				Header: "Account #",           
				accessor: "carrier_account_number", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Ship Date",           
				Cell: this.shipDateCell, 
				accessor: "ship_date", 
				width: 150,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Weight",              
				accessor: "total_weight", 
				width: 150,
				type: Number,
				colDisabled: false
			},
			{
				Header: "DIM Weight",              
				accessor: "total_dim_weight", 
				width: 150,
				type: Number,
				colDisabled: false
			},
			{
				Header: "Amt Billed",          
				Cell: this.amountBilledCell, 
				accessor: "carrier_total_billed", 
				width: 150,
				type: Number,
				colDisabled: false
			},
			{
				Header: "Terms",          		
				accessor: "direction", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipper Company",     
				accessor: "origin_name", 
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Origin Street",       
				Cell: this.originAddressCell, 
				accessor: "origin_address", 
				width: 150,
				type: String,
				colDisabled: false,
				sortValue: (r, id) => toString(r['origin_address1']) + toString(r['origin_address2'])},
			{
				Header: "Origin City",         
				accessor: "origin_city", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Origin State",        
				accessor: "origin_state", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Origin Zip",          
				accessor: "origin_postal_code", 
				width: 150,
				type: Number,
				colDisabled: false
			},
			{
				Header: "Consignee Company",   
				accessor: "destination_name", 
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Destination Street",  
				Cell: this.destinationAddressCell, 
				accessor: "destination_address", 
				width: 175,
				type: String,
				colDisabled: false,
				sortValue: (r, id) => toString(r['destination_address1']) + toString(r['destination_address2'])},
			{
				Header: "Destination City",    
				accessor: "destination_city", 
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Destination State",   
				accessor: "destination_state", 
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Destination Zip",     
				accessor: "destination_postal_code", 
				width: 150,
				type: Number,
				colDisabled: false
			},
			{
				Header: "BL #",                
				accessor: "bol_number", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "SO #",                
				accessor: "so_number", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "PO #",                
				accessor: "po_number", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Ret Authorization #", 
				accessor: "ra_number", 
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Cust Reference 1",    
				accessor: "ref_number_1",
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Cust Reference 2",    
				accessor: "ref_number_2", 
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Carrier Invoice Date",
				Cell: this.invoiceDateCell,
				accessor: "carrier_invoice_date",
				width: 175,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Audit Date",
				Cell: this.dateProcessedCell,
				accessor: "date_processed",
				width: 150,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Store #",             
				accessor: "store_number", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Last Web Edit",       
				Cell: this.lastModifedCell, 
				accessor: "last_modified", 
				width: 150,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Bill To City",        
				accessor: "billing_city", 
				width: 150,
				type: String,
				colDisabled: false
			},
			{
				Header: "Bill To Zip",         
				accessor: "billing_postal_code", 
				width: 150,
				type: Number,
				colDisabled: false
			},
		];

		if(!this.props.heldBills) {
			return columns;
		}

		if(_.isEmpty(this.props.invoiceListColumns)) {
			this.props.applyInvoiceListColumns(columns);
		}

		if(_.isEmpty(this.props.invoiceListCustomColumns)) {
			return columns;
		}else {
			let customCol = [];
			this.props.invoiceListCustomColumns.forEach((v) => {
				const disabled = v.colDisabled === "false" ? false : !v.colDisabled ? false : true;
				columns.forEach((e) => {
					if(!customCol.includes(e) && (v.accessor == e.accessor || e.accessor == 'checkboxes') && !disabled) {
						customCol.push(e)
						return;
					} 
				})
			})
			return customCol;
		}
	}

	checkBoxCell = rowInfo => {
		return (
			<Checkbox
				value={rowInfo.row.original.id}
            	size="small"
                checked={!_.isEmpty(this.props.selectedInvoices) && this.props.selectedInvoices.indexOf(+rowInfo.row.original.id) !== -1}
                onChange={this.handleCheckbox.bind(this)}
			/>
		);
	}
	
	viewBillCell = rowInfo => {
		if (!Array.isArray(rowInfo.row.original.image) || !rowInfo.row.original.image.length) return null;
        return (
        	<div value={rowInfo.row.original.id} onClick={this.handleDocsClick} style={{paddingLeft: 15}}>
	        	<IconButton size="small">
	            	<Search fontSize="inherit"/>
	            </IconButton>
            </div>
        );
	}

	proCell = rowInfo => {
		//need to be able to customize this so we can send people to different routes depending on the context
		if (typeof this.props.proCellFunction === "function") {
			return this.props.proCellFunction(rowInfo);
		}
		if (!this.props.canViewBill) return <div>{rowInfo.row.original.pro_number}</div>;
        return <Link to={`${basePath}/admin/customer-portal/held-bills/view/${rowInfo.row.original.id}`}>{rowInfo.row.original.pro_number}</Link>
	}
	
	originAddressCell = rowInfo => {
		return <div>{`${rowInfo.row.original.origin_address1} ${rowInfo.row.original.origin_address2}`}</div>
	}

	destinationAddressCell = rowInfo => {
        return <div>{`${rowInfo.row.original.destination_address1} ${rowInfo.row.original.destination_address2}`}</div>
	}

	amountPaidCell = rowInfo => {
        return <div>{`$${Math.max(0, utils.toFloat(rowInfo.row.original.true_cost) - utils.toFloat(rowInfo.row.original.amount_due)).toFixed(2)}`}</div>
	}

	amountBilledCell = rowInfo => {
		return <div>{`$${(utils.toFloat(rowInfo.row.original.true_cost)).toFixed(2)}`}</div>
	}
	
	shipDateCell = rowInfo => this.formatDate(rowInfo.row.original.ship_date)
	invoiceDateCell = rowInfo => this.formatDate(rowInfo.row.original.carrier_invoice_date)
	dateProcessedCell = rowInfo => this.formatDate(rowInfo.row.original.date_processed)
	lastModifedCell = rowInfo => this.formatDate(rowInfo.row.original.last_modified)

	formatDate = date => {
		if (
			typeof date === "string" && date.length >= 10) {
			date = moment(date.substring(0, 10), "YYYY-MM-DD").format("MM/DD/YYYY");
		}
		return <span>{date}</span>;
	}

	handleCheckbox = e => {
		this.props.handleCheckbox(e);
	}
	
	handleSelectAll = e => {
		this.props.handleSelectAll(e);
	}
	
	onCloseImageModal = () => {
		this.setState({imageModalOpen: false});
	}
	
	handleDocsClick = e => {
		let invid = e.currentTarget.getAttribute('value');
		let images = [];
		this.props.data.map(invoice => {
			if (+invoice.id === +invid && Array.isArray(invoice.image)) {
				images = invoice.image;
			}
		})
		this.setState({images, imageModalOpen: true});
	}

	holdCommentsCell = rowInfo => {
		let note = "";
		rowInfo.row.original.holds.forEach((hold, idx, arr) => {
			if(hold.note.length) {
				note += `${hold.code}: ${hold.note}`;
				if(idx !== arr.length - 1) note += ", ";
			}
		});
		return note;
	}

	render() {
		return (
			<div style={this.props.style}>
				{this.props.heldBills && (
					<Fragment>
						<GridItem xs={12} sm={12} md={12} style={{ textAlign:"left", color: "#000"}}>
							<ColumnListLayoutModal
								loading={this.props.loading}
								updateCols={this.updateCols}
								saveCustomColumns={(items) => this.props.saveCustomInvoiceListColumns(items)}
								applyCustomColumns={(items) => this.props.applyInvoiceListCustomColumns(items)}
								fetchColumns={() => {}}
								listColumns={this.props.invoiceListColumns}
								customListColumns={this.props.invoiceListCustomColumns}
							/>
						</GridItem>
					</Fragment>
				)}
				<ViewImagesModal open={this.state.imageModalOpen} images={this.state.images} onClose={this.onCloseImageModal}/>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<ReactTable
						loading={this.props.loading}
						disabled={this.props.loading}
						columns={this.columns()}
						data={this.props.data}
						totalRows={this.props.totalRecords}
						leftPaginationLabelFormat={
							this.props.canMassRelease?
							({ from, to, count }) => `Selected (${this.props.selectedInvoices.length})`
							:
							() => ''
						}
						rightPaginationLabelFormat={({ from, to, count }) => `Showing ${from}–${to}`}
						pageSize={this.props.pageSize}
						pageNumber={this.props.pageNumber}
						setPageNumber={this.props.setPageNumber}
						visibleRows={this.props.visibleRows}
						overscan={10}
						enableSorting
						enableResizing
						hiddenColumns={this.props.hiddenColumns}
						defaultColumn={{
							align: 'left'
						}}
					/>
				</Paper>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		invoiceListCustomColumns: state.CustomerPortal.invoiceListCustomColumns,
		invoiceListColumns: state.CustomerPortal.invoiceListColumns,
	}
}



const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		applyInvoiceListColumns,
		applyInvoiceListCustomColumns,
		saveCustomInvoiceListColumns,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);

