import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Skeleton from 'react-loading-skeleton';
import GridItem from "~/components/Grid/GridItem.jsx";
import InvoiceList from '../components/InvoiceList.jsx';
import TopActions from '../components/TopActions.jsx';
import { basePath } from '../../../variables/server.jsx';
import { Link } from 'react-router-dom'
import {
	fetchTfmInvoiceCarrierBills,
	setCustomerPortalTfmInvoiceCarrierBillsPageNumber,
	setCustomerPortalTfmInvoiceCarrierBillsTotalRecords,
} from '../actions/CustomerPortal.jsx'
import _ from "lodash";


class TFMInvoiceCarrierBillsListContainer extends Component {
	state = {
		initialized: false,
		brokerId: "",
		invoiceNumber: "",
	}

	async componentDidMount() {
		this.setState({brokerId: this.props.match.params.brokerid, invoiceNumber: this.props.match.params.invoicenumber}, async () => {
			this.props.setCustomerPortalTfmInvoiceCarrierBillsTotalRecords(0);
			await this.loadInvoices();
			this.props.pageTitle(`View TFM Invoice - ${this.props.match.params.invoicenumber}`);
			this.setState({initialized: true});
		});
	}

	loadInvoices = () => {
		return this.props.fetchTfmInvoiceCarrierBills(this.state.brokerId, this.state.invoiceNumber);
	}

	numberPages = () => {
		return +this.props.pageSize > this.props.totalRecords
			? 1
			: Math.ceil(+this.props.totalRecords / +this.props.pageSize);
	}

	onPageChange = index => {
		this.props.setCustomerPortalTfmInvoiceCarrierBillsPageNumber(index);
		this.loadInvoices();
	}

	render() {
		if (!this.state.initialized) return this.loading();
		return (
			<Fragment>
				<TopActions>
					<Fragment>
						{this.state.initialized && <GridItem xs={12} sm={12} md={12} style={{ textAlign:"right", color: "#000"}}>
							<h4 style={{marginRight: "5px"}}>{`Records: ${this.props.totalRecords}`}</h4>
						</GridItem>}
					</Fragment>
				</TopActions>
				<InvoiceList
					data={this.props.invoices}
					selectedInvoices={[]}
					canViewBill={this.props.canViewBill}
					defaultPageSize={this.props.pageSize}
					minRows={this.props.pageSize}
					hiddenColumns={["checkboxes", "hold status", "hold comments", "remark"]}
					showPaginationTop={true}
					showPaginationBottom={false}
					showPageSizeOptions={false}
					sortable={false}
					manual={true}
					setPageNumber={this.onPageChange}
					heldBills={false}
					totalRecords={this.props.totalRecords}
					pageNumber={this.props.pageNumber}
					pageSize={this.props.pageSize}
					loading={this.props.loading}
					proCellFunction={rowInfo => {
						if(!_.isEmpty(rowInfo.row.original)) {
							if (!this.props.canViewBill) {
								return rowInfo.row.original.pro_number;
							}
							return <Link to={`${basePath}/admin/customer-portal/view-carrier-bill/${rowInfo.row.original.id}`}>{rowInfo.row.original.pro_number}</Link>
						}
						return ""
					}}
				/>
			</Fragment>
		);
	}

	loading() {
		return (
			<Fragment>
				<TopActions>
					<GridItem xs={12} sm={12} md={12} style={{ textAlign:"right", color: "#000"}}>
						<Skeleton width={140} height={40} />
					</GridItem>
				</TopActions>
				<Skeleton height={415} />
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.CustomerPortal.fetchInvoicesLoading,
		invoices: state.CustomerPortal.invoices,
		pageNumber: state.CustomerPortal.tfmInvoiceCarrierBillsPageNumber,
		pageSize: state.CustomerPortal.tfmInvoiceCarrierBillsPageSize,
		totalRecords: state.CustomerPortal.tfmInvoiceCarrierBillsTotalRecords,
		canViewBill: true, //@TODO check permission here when permission exists for viewing docs
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchTfmInvoiceCarrierBills,
		setCustomerPortalTfmInvoiceCarrierBillsPageNumber,
		setCustomerPortalTfmInvoiceCarrierBillsTotalRecords,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TFMInvoiceCarrierBillsListContainer);
