import React from "react";
import { connect } from "react-redux";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import { basePath } from "~/variables/server.jsx";
import { hasPermission } from "../../redux/selectors/Admin.jsx";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Person from "@material-ui/icons/Person";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Phone from "@material-ui/icons/Phone";

// react-icons
import { FaFax } from "react-icons/fa";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class AddressBook extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			data: [],
			body: null,
			user: null,
			current: 1,
			last: 1,
			warehouse: "",
			alert: null,
			search_by: "name",
			query: "",
		};
		this.getWarehouses = this.getWarehouses.bind(this);
		this.handleWarehouse = this.handleWarehouse.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.deleteAddress = this.deleteAddress.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const url = "/index.php?p=api&r=json&c=location&m=addressBook";
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.user) &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.locations)
			) {
				const body = response.data.body;
				let warehouse = body.warehouses.filter( warehouse => { return warehouse.is_default == 1; } );
				if(_.isEmpty(warehouse)) {
					warehouse = body.warehouses;
				}
				this.setState({
					show: true,
					loading: false,
					body: body,
					data: body.locations.result,
					user: response.data.user,
					current: body.locations.current ? body.locations.current.toString() : "1",
					last: body.locations.last ? body.locations.last.toString() : "1",
					warehouse: warehouse[0].id
				});
			} else {
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error loading the data!",
				{ variant: "error" }
			);
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getWarehouses(warehouses) {
		const { classes } = this.props;
		return warehouses.map((warehouse, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={warehouse.id}
				>
					{warehouse.name}
				</MenuItem>
			);
		});
	}

	async handleWarehouse(id) {

		let url = "/index.php?p=api&r=json&c=location&m=addressBook&d=" + id;

		this.setState({
			show: false,
			loading: true,
			warehouse: id
		});

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.locations)
			) {
				const body = response.data.body;
				this.setState({
					show: true,
					loading: false,
					body: body,
					user: response.data.user,
					data: body.locations.result,
					current: body.locations.current ? body.locations.current.toString() : "1",
					last: body.locations.last ? body.locations.last.toString() : "1",
				});
			} else {
				this.props.setNotification(
					"There was an error loading warehouse locations!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an exception loading warehouse locations!",
				{ variant: "error" }
			);
		}

	}

	getAddresses(data) {
		return data.map((prop, key) => {
			const link = basePath + "/admin/address/edit?d=" + prop.id + "/" + this.state.body.current_warehouse;
			return [
				prop.id,
				prop.code,
				prop.name,
				<address>
					<div dangerouslySetInnerHTML={{ __html: prop.address1 }} />
					{!_.isEmpty(prop.address2) && <div dangerouslySetInnerHTML={{ __html: prop.address2 }} />}
					<div dangerouslySetInnerHTML={{ __html: prop.city + ", " + prop.state + " " + prop.zip }} />
				</address>,
				<GridContainer>
					{!_.isEmpty(prop.contact_name) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<Person />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.contact_name }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.phone) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<Phone />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.phone }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.email) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<AlternateEmail />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.email }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.fax) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<FaFax />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.fax }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
				</GridContainer>,
				prop.type,
				prop.default == 1 ? "*" : "",
				<div className="actions-right">
					<NavLink to={link}>
						<Button size="sm" color="info">
							{this.props.hasWrite ? "View/Edit" : "View"}
						</Button>
					</NavLink>
					{this.props.hasWrite && <Button
						size="sm"
						color="danger"
						onClick={() => this.handleDelete(prop.id, this.state.body.current_warehouse)}
					>
						Delete
					</Button>}
				</div>
			];
		});
	}
	getPagination() {
		const delta = 5;
		let currentPage = 1,
			pageCount = 1;

		if (!_.isEmpty(this.state.current) && this.state.current != 1) {
			currentPage = parseInt(this.state.current);
		}
		if (!_.isEmpty(this.state.last) && this.state.last != 1) {
			pageCount = parseInt(this.state.last);
		}

		let pagination = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" },
			pages = [];

		const separate = (a, b) => [
			a,
			...({
				0: [],
				1: [b],
				2: [a + 1, b]
			}[b - a] || ["...", b])
		];

		pages = Array(delta * 2 + 1)
			.fill()
			.map((_, index) => currentPage - delta + index)
			.filter(page => 0 < page && page <= pageCount)
			.flatMap((page, index, { length }) => {
				if (!index) {
					return separate(1, page);
				}
				if (index === length - 1) {
					return separate(page, pageCount);
				}
				return [page];
			});

		if (pageCount > 1 && !_.isEmpty(pages)) {
			pagination = pages.map((prop, key) => {
				let page = { text: prop };
				if (prop == currentPage) {
					page.active = true;
				} else if (prop === "...") {
					page.disabled = true;
				} else {
					page.onClick = e => this.handlePageClick(prop);
				}
				return page;
			});
		}

		if (currentPage < pageCount) {
			nextPage.onClick = e => this.handlePageClick(currentPage + 1);
		} else {
			nextPage.disabled = true;
		}

		if (currentPage - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(currentPage - 1);
		} else {
			prevPage.disabled = true;
		}

		pagination.push(nextPage);
		pagination.unshift(prevPage);

		return pagination;
	}
	async handlePageClick(page) {
		this.setState({ show: false, loading: true });
		let url = "/index.php?p=api&r=json&c=location&m=addressBook&d=" + this.state.warehouse + "/" + page + "/20";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.locations)
			) {
				const { locations } = response.data.body;
				this.setState({
					show: true,
					loading: false,
					data: locations.result,
					current: locations.current ? locations.current.toString() : "1",
					last: locations.last ? locations.last.toString() : "1"
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the data!",
				{ variant: "error" }
			);
		}
	}
	handleDelete(id, warehouse) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this address?"
					onConfirm={() => this.deleteAddress(id, warehouse)}
					onCancel={() => this.deleteAddress()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async deleteAddress(id, warehouse) {
		this.setState({ alert: null });

		if (_.isEmpty(id) || _.isEmpty(warehouse)) {
			return;
		}

		try {
			const url = "/index.php?p=api&r=json&c=location&m=delete&d=" + id + "/" + warehouse;
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				response.data.body.deleted
			) {
				await this.handlePageClick(this.state.current);
			} else {
				this.props.setNotification(
					"There was an error deleting the address!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error deleting the address!",
				{ variant: "error" }
			);
		}
	}
	async handleSearch() {
		const { search_by, query, warehouse } = this.state;

		let url = "/index.php?p=api&r=json&c=location&m=lookup&d=" + warehouse +
			"&query=" + encodeURIComponent(query) + "&search_type=" + search_by;

		this.setState({
			show: false,
			loading: true
		});

		try {
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.locations)
			) {
				let warehouse = response.data.body.warehouses.filter( warehouse => { return warehouse.is_default == 1; } );
				if(_.isEmpty(warehouse)) {
					warehouse = response.data.body.warehouses;
				}
				this.setState({
					show: true,
					loading: false,
					body: response.data.body,
					data: response.data.body.locations.result,
					user: response.data.user,
					current: response.data.body.locations.current ? response.data.body.locations.current.toString() : "1",
					last: response.data.body.locations.last ? response.data.body.locations.last.toString() : "1",
					// warehouse: warehouse[0].id
				});
			} else {
				this.setState({
					show: true,
					loading: false
				});
				this.props.setNotification(
					"There was an error searching the panel!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error searching the panel!", {
				variant: "error"
			});
		}
	}
	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch();
		}
	}
	render() {
		const { classes } = this.props;
		const selectedWarehouse =
			this.state.body && this.state.body.current_warehouse
				? this.state.body.current_warehouse
				: "";
		let newLocationLink = basePath + "/admin/address/new";
		if (selectedWarehouse) {
			newLocationLink += "?d=" + selectedWarehouse;
		}
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<GridContainer>
										{this.props.hasWrite && <GridItem xs={4}>
											<NavLink to={newLocationLink}>
												<Button color="linkedin">
													New Address
												</Button>
											</NavLink>
										</GridItem>}
										<GridItem xs>
											<InputLabel htmlFor="warehouse" className={classes.label}>
												Warehouse/Facility
											</InputLabel>
											<br />
											<FormControl className={classes.selectFormControl} style={{ width: "175px" }}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{ select: classes.select }}
													value={this.state.warehouse}
													inputProps={{
														name: "warehouse",
														id: "warehouse"
													}}
													onChange={e => this.handleWarehouse(e.target.value)}
												>
													{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.warehouses) ? (
														this.getWarehouses(this.state.body.warehouses)
													) : (
														<MenuItem
															disabled
															classes={{ root: classes.selectMenuItem }}
														>
															No Warehouses
														</MenuItem>
													)}
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<GridContainer>
										<GridItem xs>
											<InputLabel className={classes.label}>Search By</InputLabel>
                                            <br />
                                            <FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{className: classes.selectMenu}}
													classes={{select: classes.select}}
													value={this.state.search_by}
													inputProps={{
														name: "search_by",
														id: "search_by"
													}}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="name"
													>
														Name
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="code"
													>
														Code
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="address"
													>
														Address
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="city"
													>
														City
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="state"
													>
														State
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="zip"
													>
														Zip
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs style={{ paddingTop: "20px" }}>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													id: "query",
													name: "query",
													value: this.state.query || "",
													onChange: this.handleInput("query"),
													onKeyPress: this.handleKeyPress
												}}
												white
											/>
										</GridItem>
										<GridItem xs={3} style={{ paddingTop: "12px" }}>
											<Button
												size="sm"
												color="linkedin"
												onClick={this.handleSearch}
											>
												<Search /> Search
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(this.state.data) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.data) ? (
											<Table
												tableHead={[
													"ID",
													"Code",
													"Name",
													"Address",
													"Contact",
													"Type",
													"Default",
													"Actions"
												]}
												tableData={this.getAddresses(this.state.data)}
												customCellClasses={[classes.right]}
												customClassesForCells={[7]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[7]}
											/>
										) : (
											<Badge color="info">No Addresses</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve addresses from the server"
									/>
								)}
							</Grid>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasWrite: hasPermission("ADDRESS_BOOK_WRITE")(state),
	};
}

export default connect(mapStateToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(AddressBook)));