import React, { Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import _ from "lodash";
import axios from "~/variables/axios.jsx";
import { connect } from "react-redux";
import { clearNotifications } from "~/redux/actions/notifications.jsx";

import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath } from "~/variables/server.jsx";
import { getCustomTableHeaders, getPages } from "~/helpers.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import SearchModal from "~/components/Search/components/Search.jsx";
import Favorites from "~/components/Favorites/Favorites.jsx";

import usersStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Users extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			alert: null,
			mounted: false,
			show: false,
			loading: true,
			favorites: [],
			body: null,
			user: null,
			result: null,
			user_id: "",
			columns: [
				{ Header: "ID", accessor: "user_credential_id", colDisabled: false },
				{ Header: "Username", accessor: "username", colDisabled: false },
				{ Header: "Role", accessor: "role", colDisabled: false },
				{ Header: "Permission Group", accessor: "permission_group", colDisabled: false },
				{ Header: "Rate Display", accessor: "rate_display_name", colDisabled: false },
				{ Header: "Company", accessor: "company_name", colDisabled: false },
				{ Header: "API Key", accessor: "key", colDisabled: false },
				{ Header: "Status", accessor: "active", colDisabled: false },
				{ Header: "Actions", accessor: "actions", colDisabled: false }
			],
			customColumns: [],
			searchFields: [
				{ name: "Username", value: "user_credential.username" },
				{ name: "Company", value: "company_name.name" },
				{ name: "Role", value: "user_detail.role" },
				{ name: "Permission Group", value: "user_roles.name" },
				{ name: "API Key", value: "user_api_key.key" }
			],
			filters: [
				{
					field: "user_credential.username",
					compare: "starts with",
					value: ""
				}
			],
			alert: null
		};
		this.switchUser = this.switchUser.bind(this);
		this.setHeaders = this.setHeaders.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleDisable = this.handleDisable.bind(this);
		this.setUserStatus = this.setUserStatus.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		const filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);
		if(filters.length) {
			this.handleSearch(filters);
			return;
		}
		try {
			const favoriteResults = await this.getFavorites();
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=users");
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					favorites: favoriteResults,
					result: data.body.result || null
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", { variant: "error" });
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	async switchUser(user_id) {
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=user&m=takeAccount&d=${user_id}/true`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.user)) {
				this.props.handleMessages(response);
				window.location.href = basePath + "/admin";
				this.props.clearNotifications();
			} else {
				this.props.setNotification("There was an error assuming the user!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error assuming the user!", {
				variant: "error"
			});
		}
	}

	setHeaders(customColumns) {
		this.setState({ customColumns });
	}

	getColumns(users, isFavorite=false) {
		let favorites = this.state.favorites.map(favorite => favorite.user_credential_id);
		const { customColumns, columns, filters } = this.state;
		let cols = customColumns.length ? customColumns : columns
	
		return users.map((prop, userKey) => {
			const belongsToCompany = prop.company_name_id != 0;
			let navLink = basePath + "/admin/company-" + prop.company_name_id + "/user/edit/" + prop.user_credential_id;
			if (!belongsToCompany) {
				navLink = basePath + "/admin/user/edit/" + prop.user_credential_id;
			}

			const buttonStyle = {paddingLeft: "10px", paddingRight: "10px"};
			const copyLink = belongsToCompany
				? `${basePath}/admin/company-${prop.company_name_id}/user/new?copy=${prop.user_credential_id}`
				: `${basePath}/admin/user/new?copy=${prop.user_credential_id}`;

			return cols.map((col, key) => {
				const disabled = col.colDisabled === "false" ? false : (!col.colDisabled ? false : true);
				if (!disabled) {
					if (col.accessor === "active") {
						return prop.active == 1 ? (
							<Button size="sm" color="success" onClick={() => this.setUserStatus(userKey, "disable", isFavorite)} style={buttonStyle}>
								Active
							</Button>
						) : (
							<Button size="sm" color="warning" onClick={() => this.setUserStatus(userKey, "enable", isFavorite)} style={buttonStyle}>
								Disabled
							</Button>
						);
					} else if (col.accessor === "actions") {
						return (
							<Fragment key={key}>
								<Favorites
									id={prop.user_credential_id}
									favorites={favorites}
									filters={filters}
									tableName={'user_credential'}
									favoriteItem={this.favoriteItem.bind(this)}
								/>
								<NavLink to={navLink} style={{color: "white"}}>
									<Button color="info" size="sm" style={buttonStyle}>
										View/Edit
									</Button>
								</NavLink>

								{!_.isEmpty(prop.key) ? (
									<Button size="sm" color="black" onClick={() => this.handleDisable(userKey, isFavorite)} style={buttonStyle}>
										Disable API
									</Button>
								) : (
									<Button size="sm" color="success" onClick={() => this.grantApiKey(userKey, isFavorite)} style={buttonStyle}>
										Grant API
									</Button>
								)}
								{['carrier', 'user'].indexOf(prop.role) > -1 && (
									<Button size="sm" color="warning" onClick={() => this.switchUser(prop.user_credential_id)} style={buttonStyle}>
										Switch User
									</Button>
								)}
								<NavLink to={copyLink} style={{color: "white"}}>
									<Button color="info" size="sm" style={buttonStyle}>
										Copy
									</Button>
								</NavLink>
							</Fragment>
						);
					} else {
						return prop[col.accessor];
					}
				}
			});
		});
	}

	async favoriteItem(favoriteResults) {
		const { filters } = this.state;
		favoriteResults.doSearch ? this.doSearch(true, filters) : this.setState({favorites: favoriteResults.favorites});
	}

	saveCustomColumns(columnResult) {
		this.setState({customColumns: columnResult});
	}

	getCustomColumns(columnResult) {
		this.setState({customColumns: columnResult});
	}

	async getFavorites() {
		let favorites = await axios.get("/index.php?p=api&r=json&c=admin&m=getFavorites&table_name=user_credential");
		let favoriteResults = [];
		if (typeof favorites !== "string" && !_.isEmpty(favorites.data) && !_.isEmpty(favorites.data.message)) {
			this.props.handleMessages(favorites);
		}
		if (typeof favorites.data !== "string" && !_.isEmpty(favorites.data.body) && favorites.data.body.favorites) {
			favorites.data.body.favorites.forEach((item) => {
				item.favorite_item = true;
				favoriteResults.push(item);
			});
		}

		return favoriteResults;
	}

	async handlePageClick(page) {
		const url = "/index.php?p=api&r=json&c=admin&m=users&d=" + page + "/20";
		try {
			const favoriteResults = await this.getFavorites();
			const response = await axios.get(url);
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					show: true,
					loading: false,
					result: data.body.result || null,
					body: data.body,
					favorites: favoriteResults
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the users!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the users!", {
				variant: "error"
			});
		}
	}

	async clearFilters() {
		this.setState({
			show: false,
			loading: true,
			filters: [
				{
					field: "user_credential.username",
					compare: "starts with",
					value: ""
				}
			],
			result: null
		});
		try {
			const favoriteResults = await this.getFavorites();
			this.setState({
				favorites: favoriteResults
			});
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=users");
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					result: data.body.result || null
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the users!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the users!", {
				variant: "error"
			});
		}
	}

	async handleSearch(filters) {

        this.setState({
            show: false,
            loading: true,
			filters: filters,
        });

        let searchResult = await this.doSearch(false, filters);

		if(searchResult) {
			await this.doSearch(true, filters);
			this.setState({
				show: true,
        	});
		}
		this.setState({
			loading: false
		});

	}

	async doSearch(favorites = false, filters) {
		const data = { queries: filters };
		let url = "/index.php?p=api&r=json&c=search&m=user";
		if(favorites == true) {
			url = url + "&favorites=true";
		}

		let isEmpty = false;

		filters.forEach(filter => {
			if (!filter.value.trim()) isEmpty = true;
		});

		if (isEmpty) {
			this.setState({show: true});
			this.props.setNotification("Search values are required", { variant: "error" });
			return false;
		}

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user)) {
				if(favorites == true) {
					this.setState({
						favorites: response.data.body,
					});
				} else {
					const body = {
						current: 1,
						last: 1,
						pages: []
					};
					this.setState({
						result: response.data.body,
						body,
						user: response.data.user
					});
				}
				return true;
			} else {
				this.props.setNotification("There was an error searching for users!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching for users!", { variant: "error" });
		}
		return false;
	}

	async setUserStatus(i, status, isFav) {
		const { result, favorites } = this.state;
		const userResult = this.getUserRows(i, isFav);
		
		if (status == "enable" && (_.isEmpty(userResult.user) || userResult.user.active == 1)) {
			console.log(status)
			return;
		} else if(status == "disable" && (_.isEmpty(userResult.user) || userResult.user.active == 0)) {
			console.log(status)
			return;
		}

		const url = `/index.php?p=api&r=json&c=admin&m=changeUserStatus&d=${userResult.user.user_credential_id}/${status}/user`;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const active = status == "enable" ? 1 : 0;
				if(result[userResult.resIdx]) {
					result[userResult.resIdx].active = active;
					this.setState({ result });
				}

				if( favorites[userResult.favIdx]) {
					favorites[userResult.favIdx].active = active;
					this.setState({ favorites });
				}
			} else {
				this.props.setNotification("There was an error changing the user status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error changing the user status!", { variant: "error" });
		}
	}

	async grantApiKey(i, isFav) {
		const { body } = this.state;
		const userResult = this.getUserRows(i, isFav);
		if (_.isEmpty(userResult) || !_.isEmpty(userResult.user.key)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&m=grantAPIKey&d=" + userResult.user.user_credential_id + "/0/0/" + body.current;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.handlePageClick(body.current);

			} else {
				this.props.setNotification("There was an error granting the user an API key!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error granting the user an API key!", { variant: "error" });
		}
	}

	handleDisable(i, isFav) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to disable this user's API Key?"
					onConfirm={() => this.disableApiKey(i, isFav)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					Their existing API key will be destroyed and cannot be recovered!
				</SweetAlert>
			)
		});
	}

	async disableApiKey(i,isFav) {
		const { body } = this.state;
		const userResult = this.getUserRows(i, isFav);
		if (_.isEmpty(userResult) || _.isEmpty(userResult.user.key)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&confirm=1&m=disableAPIKey&d=" + userResult.user.user_credential_id + "/0/0/" + body.current;

		this.setState({ alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.handlePageClick(body.current);
			} else {
				this.props.setNotification("There was an error disabling the user's API key!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error disabling the user's API key!", { variant: "error" });
		}
	}

	getUserRows = (i, isFav) => {
		const { result, favorites } = this.state;
		const userResult = isFav ? favorites[i] : result[i];
		const resultIdx = result.findIndex(res => res.user_credential_id === userResult.user_credential_id);
		const favoriteIdx = favorites.findIndex(fav => fav.user_credential_id === userResult.user_credential_id);

		return {user: userResult, favIdx: favoriteIdx, resIdx: resultIdx};
	}

	render() {
		const { classes } = this.props;
		const { customColumns, columns} = this.state;
		const link = basePath + "/admin/user/new";
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<Grid container>
								<GridItem xs={3} sm={3} md={3} className={classes.left}>
									<ColumnListLayoutModal
										buttonType="primary"
										type={'user'}
										fetchColumns={(items) => this.getCustomColumns(items)}
										listColumns={this.state.columns}
										customListColumns={this.state.customColumns}
										updateCols={() => {}}
										saveCustomColumns={(items) => this.saveCustomColumns(items)}
										applyCustomColumns={(items) => this.setHeaders(items)}
									/>

									<SearchModal
										fields={this.state.searchFields}
										onClear={this.clearFilters}
										onSearch={this.handleSearch}
										loading={false}
									/>
								</GridItem>
								<GridItem xs={6} sm={6} md={6} className={classes.center}>
									<Pagination pages={getPages(this.state.body, this.handlePageClick)} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3} className={classes.right}>
									<NavLink to={link}>
										<Button color="linkedin">New User</Button>
									</NavLink>
								</GridItem>
							</Grid>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} sm={12} md={12} className={!_.isEmpty(this.state.result) ? classes.left : classes.center}>
									{!_.isEmpty(this.state.result) ?
										(this.state.body.current == 1 ?
											<Table tableHead={getCustomTableHeaders(customColumns, columns)} tableFavorites={this.getColumns(this.state.favorites, true)} tableData={this.getColumns(this.state.result)} />
										:
											<Table tableHead={getCustomTableHeaders(customColumns, columns)} tableData={this.getColumns(this.state.result)} />
										)
									:
										<Badge color="info">No users to display</Badge>}
									</Grid>
								) : (
									<Spinner loading={this.state.loading} message="Failed to retrieve users from the server" />
								)}
							</Grid>
							<Grid container>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={getPages(this.state.body, this.handlePageClick)} />
								</GridItem>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = (state) => {
    return {
        searchFilters: state.Search.filters,
    };
};

export default connect(mapStateToProps, {clearNotifications})(withStyles(usersStyle)(withSnackbar(Users)));
