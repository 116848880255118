// import Dashboard from "~/views/Dashboard/Dashboard.jsx";
import Dashboard from "~/views/TMS/Dashboard.jsx";
import PasswordResetPage from "~/views/Pages/PasswordResetPage.jsx";
import ErrorPage from "~/views/Pages/ErrorPage.jsx";
import LoginPage from "~/views/Pages/LoginPage.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import LocalShipping from "@material-ui/icons/LocalShipping"; // LTL/Carrier Selection
import Settings from "@material-ui/icons/Settings"; // Settings
import Build from "@material-ui/icons/Build"; // Shipping Tools
import ShowChart from "@material-ui/icons/ShowChart"; // Reports
import Launch from '@material-ui/icons/Launch';
import Mail from "@material-ui/icons/Mail"; // Contact Us

// react-icons
import { FaFileInvoice } from "react-icons/fa";
import { FiPackage, FiGlobe } from "react-icons/fi";
import { GoReport } from "react-icons/go";

// TMS - User
import BillOfLading from "~/views/BillOfLading/containers/BillOfLading.jsx";
import ArchivedBillsOfLading from "~/views/ArchivedBillsOfLading/containers/ArchivedBillsOfLading.jsx";
import LtlQuote from "~/views/TMS/LtlQuote.jsx";
import LtlQuotePanel from "~/views/TMS/LtlQuotePanel.jsx";
import ParcelQuote from "~/views/TMS/ParcelQuote.jsx";
import ParcelScheduler from "~/views/TMS/ParcelScheduler.jsx";
import ParcelQuotePanel from "~/views/TMS/ParcelQuotePanel.jsx";
import ManagePrinters from "~/views/TMS/ManagePrinters.jsx";
import ArchivedParcelShipments from "~/views/TMS/ArchivedParcelShipments.jsx";
import TrackShipment from "~/views/TMS/TrackShipment.jsx";
import PublicTrackPro from "~/views/TMS/PublicTrackPro.jsx";
import MonitoredPros from "~/views/TMS/MonitoredPros.jsx";
import TransitInfo from "~/views/TMS/TransitInfo.jsx";
import Miler from "~/views/TMS/Miler.jsx";
import ManifestReports from "~/views/TMS/ManifestReports.jsx";
import ProductCatalog from "~/views/TMS/ProductCatalog.jsx";
import EditProduct from "~/views/TMS/EditProduct.jsx";
import HandlingUnitCatalog from "~/views/TMS/HandlingUnitCatalog.jsx";
import NewHandlingUnit from "~/views/TMS/NewHandlingUnit.jsx";
import EditHandlingUnit from "~/views/TMS/EditHandlingUnit.jsx";
import AddressBook from "~/views/TMS/AddressBook.jsx";
import EditAddress from "~/views/TMS/EditAddress.jsx";
import CannedNotes from "~/views/TMS/CannedNotes.jsx";
import NewNote from "~/views/TMS/NewNote.jsx";
import EditNote from "~/views/TMS/EditNote.jsx";
import PickupTimes from "~/views/TMS/PickupTimes.jsx";
import Claims from "~/views/TMS/Claims.jsx";
import NewClaim from "~/views/TMS/NewClaim.jsx";
import ViewClaim from "~/views/TMS/ViewClaim.jsx";
import DataDump from "~/views/TMS/DataDump.jsx";
import RunReport from "~/views/TMS/RunReport.jsx";
import CustomerPortalLandingContainer from '../views/CustomerPortal/containers/CustomerPortalLandingContainer.jsx';
import HeldBillsContainer from '../views/CustomerPortal/containers/HeldBillsContainer.jsx';
import HeldBillsSummaryContainer from '../views/CustomerPortal/containers/HeldBillsSummaryContainer.jsx'
import ViewBillContainer from '../views/CustomerPortal/containers/ViewBillContainer.jsx';
import ViewHeldBillContainer from '../views/CustomerPortal/containers/ViewHeldBillContainer.jsx';
import CannedReportingContainer from '../views/CustomerPortal/containers/CannedReportingContainer.jsx';
import ContactUs from "../views/TMS/ContactUs.jsx";
import TFMInvoicesContainer from "../views/CustomerPortal/containers/TFMInvoicesContainer.jsx";
import TFMInvoiceSearchContainer from "../views/CustomerPortal/containers/TFMInvoiceSearchContainer.jsx";
import TFMInvoiceCarrierBillsListContainer from "../views/CustomerPortal/containers/TFMInvoiceCarrierBillsListContainer.jsx";
import International from "~/views/International/containers/International.jsx";
import InternationalLoadBoardContainer from "~/views/InternationalLoadBoard/containers/InternationalLoadBoardContainer.jsx";

import ScheduledReportHistory from "~/views/TMS/Admin/ScheduledReportHistory.jsx";
import ReportsContainer from "../views/CustomReporting/containers/ReportsContainer.jsx";
import RunReportContainer from "../views/CustomReporting/containers/RunReportContainer.jsx";
import ScrollableReportBuilder from "../views/CustomReporting/containers/ScrollableReportBuilder.jsx";
import ReportSchedulerContainer from "../views/CustomReporting/containers/ReportSchedulerContainer.jsx";
import ScheduledReportsContainer from "../views/CustomReporting/containers/ScheduledReportsContainer.jsx";

import HistoryPanel from "~/views/TMS/HistoryPanel.jsx";

import { basePath } from "~/variables/server.jsx";

export default [
	{
		identifier: "Dashboard",
		path: "/dashboard",
		name: "Dashboard",
		rtlName: "Dashboard",
		icon: DashboardIcon,
		component: Dashboard,
		layout: basePath + "/admin"
	},
	{
		collapse: true,
		name: "Pages",
		rtlName: "Pages",
		icon: Image,
		state: "pageCollapse",
		invisible: true,
		views: [
			{
				path: "/login-page",
				name: "Login Page",
				mini: "L",
				rtlName: "Login Page",
				rtlMini: "L",
				component: LoginPage,
				layout: basePath + "/auth"
			},
			{
				path: "/error-page",
				name: "Error Page",
				rtlName: "Error Page",
				mini: "E",
				rtlMini: "E",
				component: ErrorPage,
				layout: basePath + "/auth"
			},
			{
				path: "/change-password/:token?",
				name: "Password Reset Page",
				mini: "P",
				rtlName: "Password Reset Page",
				rtlMini: "P",
				component: PasswordResetPage,
				layout: basePath + "/auth"
			},
		]
	},
	{
		identifier: "Freight",
		collapse: true,
		name: "Freight",
		rtlName: "Freight",
		state: "openBOL",
		icon: FaFileInvoice,
		views: [
			{
				path: "/bol/HistoryPanel/:bol",
				name: "BOL Tracking History",
				mini: "BT",
				rtlName: "BOL Tracking History",
				rtlMini: "BT",
				component: HistoryPanel,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'USE_POSTING_PANEL',
					'USE_CARRIER_TRACKING',
				]
			},
			{
				path: "/bol/:date/:id",
				name: "View BOL",
				mini: "VB",
				rtlName: "View BOL",
				rtlMini: "VB",
				invisible: true,
				component: BillOfLading,
				layout: basePath + "/admin",
				permissions: [
					'USE_BILL_OF_LADING',
				]
			},
			{
				identifier: "Create Shipment",
				path: "/bol/new",
				name: "Create Shipment",
				mini: "FS",
				rtlName: "Create Shipment",
				rtlMini: "FS",
				component: BillOfLading,
				layout: basePath + "/admin",
				permissions: [
					'USE_BILL_OF_LADING',
				]
			},
			{
				path: "/bol/archived?:filters",
				name: "Load Board",
				mini: "FB",
				rtlName: "Load Board",
				rtlMini: "FB",
				invisible: true,
				component: ArchivedBillsOfLading,
				layout: basePath + "/admin",
				permissions: [
					'USE_POSTING_PANEL',
				]
			},
			{
				identifier: "Load Board",
				path: "/bol/archived",
				name: "Load Board",
				mini: "FB",
				rtlName: "Load Board",
				rtlMini: "FB",
				component: ArchivedBillsOfLading,
				layout: basePath + "/admin",
				permissions: [
					'USE_POSTING_PANEL',
				]
			},
			{
				identifier: "Freight Quote",
				path: "/ltl/get-quote",
				name: "Freight Quote",
				mini: "FQ",
				rtlName: "Freight Quote",
				rtlMini: "FQ",
				component: LtlQuote,
				layout: basePath + "/admin",
				permissions: [
					'USE_QUOTE_PANEL',
				]
			},
			{
				identifier: "Freight Quote Panel",
				path: "/ltl/quote-panel",
				name: "Freight Quote Panel",
				mini: "FP",
				rtlName: "Freight Quote Panel",
				rtlMini: "FP",
				component: LtlQuotePanel,
				layout: basePath + "/admin",
				permissions: [
					'USE_QUOTE_PANEL',
				]
			},
		]
	},
	{
		identifier: "Parcel",
		collapse: true,
		name: "Parcel",
		rtlName: "Parcel",
		state: "openParcel",
		icon: FiPackage,
		// need to account for company settings rather than user settings
		views: [
			{
				path: "/parcel/parcel-scheduler/:shipmentId",
				name: "Create Shipment",
				mini: "PS",
				rtlName: "Create Shipment",
				rtlMini: "PS",
				invisible: true,
				component: ParcelScheduler,
				layout: basePath + "/admin",
				permissions: [
					'USE_PARCEL_SHIPPING',
				]
			},
			{
				identifier: "ParcelScheduler",
				path: "/parcel/parcel-scheduler",
				name: "Create Shipment",
				mini: "PS",
				rtlName: "Create Shipment",
				rtlMini: "PS",
				invisible: false,
				component: ParcelScheduler,
				layout: basePath + "/admin",
				permissions: [
					'USE_PARCEL_SHIPPING',
				]
			},
			{
				identifier: "ArchivedParcelShipments",
				path: "/parcel/archived-shipments",
				name: "Load Board",
				mini: "PB",
				rtlName: "Load Board",
				rtlMini: "PB",
				component: ArchivedParcelShipments,
				layout: basePath + "/admin",
				permissions: [
					'USE_PARCEL_SHIPPING',
				]
			},
			{
				path: "/parcel/get-quote/:shipmentId",
				name: "Parcel Rate",
				mini: "PR",
				rtlName: "Parcel Rate",
				rtlMini: "PR",
				invisible: true,
				component: ParcelQuote,
				layout: basePath + "/admin",
				permissions: [
					'USE_PARCEL_RATING',
				]
			},
			{
				identifier: "ParcelQuote",
				path: "/parcel/get-quote/",
				name: "Parcel Rate",
				mini: "PR",
				rtlName: "Parcel Rate",
				rtlMini: "PR",
				component: ParcelQuote,
				layout: basePath + "/admin",
				permissions: [
					'USE_PARCEL_RATING',
				]
			},
			{
				identifier: "ParcelQuotePanel",
				path: "/parcel/quote-panel",
				name: "Parcel Rate Panel",
				mini: "PP",
				rtlName: "Parcel Rate Panel",
				rtlMini: "PP",
				component: ParcelQuotePanel,
				layout: basePath + "/admin",
				permissions: [
					'USE_PARCEL_RATING',
				]
			},
			{
				identifier: "ProductCatalogParcel",
				path: "/parcel/product-catalog-parcel",
				name: "Parcel Product Catalog",
				mini: "PC",
				rtlName: "Parcel Product Catalog",
				rtlMini: "PC",
				component: ProductCatalog,
				layout: basePath + "/admin",
				permissions: [
					'PRODUCT_CATALOG_WRITE',
				]
			},
			{
				identifier: "NewProduct",
				path: "/product/parcel/new/:wid?",
				name: "New Product",
				mini: "NP",
				rtlName: "New Product",
				rtlMini: "NP",
				component: EditProduct,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'PRODUCT_CATALOG_WRITE',
					'PRODUCT_CATALOG_READ',
				]
			},
			{
				identifier: "EditProduct",
				path: "/product/parcel/edit/:id/:wid",
				name: "Edit Product",
				mini: "EP",
				rtlName: "Edit Product",
				rtlMini: "EP",
				component: EditProduct,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'PRODUCT_CATALOG_WRITE',
					'PRODUCT_CATALOG_READ',
				]
			},
			{
				identifier: "Manage Printers",
				path: "/parcel/manage-printers",
				name: "Manage Printers",
				mini: "MP",
				rtlName: "Manage Printers",
				rtlMini: "MP",
				component: ManagePrinters,
				layout: basePath + "/admin",
				invisible: false,
			},
		]
	},
	{
		identifier: "International",
		collapse: true,
		name: "International",
		rtlName: "International",
		state: "openInternational",
		icon: FiGlobe,
		views: [
			{
				identifier: "Create Shipment",
				path: "/intl/new",
				name: "Create Shipment",
				mini: "NS",
				rtlName: "Create Shipment",
				rtlMini: "NS",
				component: International,
				layout: basePath + "/admin",
				permissions: [
					'USE_TMS_INTL',
				]
			},
			{
				path: "/intl/load-board",
				name: "Load Board",
				mini: "LB",
				rtlName: "Load Board",
				rtlMini: "LB",
				component: InternationalLoadBoardContainer,
				layout: basePath + "/admin",
				permissions: [
					'USE_TMS_INTL',
			]
			},
			{
				path: "/intl/:id",
				name: "View Intl Shipment",
				mini: "VS",
				rtlName: "View Intl Shipment",
				rtlMini: "VS",
				invisible: true,
				component: International,
				layout: basePath + "/admin",
				permissions: [
					'USE_TMS_INTL',
				]
			},
		],
	},
	{
		identifier: "Shipping Tools",
		collapse: true,
		name: "Shipping Tools",
		rtlName: "Shipping Tools",
		state: "openShippingTools",
		icon: Build,
		views: [
			{
				identifier: "Track Shipment",
				path: "/shipping/track-shipment",
				name: "Track Shipment",
				mini: "TS",
				rtlName: "Track Shipment",
				rtlMini: "TS",
				component: TrackShipment,
				layout: basePath + "/admin",
				permissions: [
					'USE_CARRIER_TRACKING',
				]
			},
			{
				path: "/public-track-pro/:pro",
				name: "Public Track PRO",
				mini: "TP",
				rtlName: "Public Track PRO",
				rtlMini: "TP",
				component: PublicTrackPro,
				layout: basePath + "/admin",
				invisible: true
			},
			{
				identifier: "Monitored PROs",
				path: "/shipping/monitored-pros",
				name: "Monitored PROs",
				mini: "MP",
				rtlName: "Monitored PROs",
				rtlMini: "MP",
				component: MonitoredPros,
				layout: basePath + "/admin",
				permissions: [
					'USE_CARRIER_TRACKING',
				]
			},
			{
				identifier: "Transit Info",
				path: "/shipping/transit-info",
				name: "Transit Info",
				mini: "TI",
				rtlName: "Transit Info",
				rtlMini: "TI",
				component: TransitInfo,
				layout: basePath + "/admin",
				permissions: [
					'ACCESS_CARRIER_CONNECT',
				]
			},
			{
				identifier: "Miler",
				path: "/shipping/miler",
				name: "Miler",
				mini: "M",
				rtlName: "Miler",
				rtlMini: "M",
				component: Miler,
				layout: basePath + "/admin",
				permissions: [
					'USE_MILER',
				]
			}
		]
	},
	{
		identifier: "Reporting",
		collapse: true,
		name: "Reporting",
		rtlName: "Reporting",
		state: "openReporting",
		icon: ShowChart,
		views: [
			{
				identifier: "ManifestReports",
				path: "/manifest-reports",
				name: "Manifest Reports",
				mini: "MR",
				rtlName: "Manifest Reports",
				rtlMini: "MR",
				component: ManifestReports,
				layout: basePath + "/admin",
				permissions: [
					'VIEW_COMPANY_REPORTS',
				]
			},
			{
				identifier: "DataDump",
				path: "/data-dump",
				name: "Data Dump",
				mini: "DD",
				rtlName: "Data Dump",
				rtlMini: "DD",
				component: DataDump,
				layout: basePath + "/admin",
				permissions: [
	                'USE_DATA_DUMP',
				]
			},
			{
				path: "/report/run/:id",
				name: "Run Report",
				mini: "RR",
				rtlName: "Run Report",
				rtlMini: "RR",
				component: RunReport,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'VIEW_COMPANY_REPORTS',
				]
			},
			{
				path: "/report-scheduler/:reportId",
				name: "Schedule",
				mini: "CRS",
				rtlName: "Schedule",
				rtlMini: "CRS",
				component: ReportSchedulerContainer,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'USE_REPORT_BUILDER',
				]
			},
			{
				path: "/report-scheduler-edit/:scheduleId",
				name: "Schedule",
				mini: "CRS",
				rtlName: "Schedule",
				rtlMini: "CRS",
				component: ReportSchedulerContainer,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'USE_REPORT_BUILDER',
				]
			},
			{
				identifier: "ReportBuilding",
				path: "/reports",
				name: "Report Building",
				mini: "RB",
				rtlName: "Report",
				rtlMini: "RB",
				component: ReportsContainer,
				layout: basePath + "/admin",
				permissions: [
	                'USE_REPORT_BUILDER',
				]
			},
			{
				path: "/run-report/:reportId",
				name: "Run On Demand Report",
				mini: "RODB",
				rtlName: "Run Report",
				rtlMini: "RODB",
				component: RunReportContainer,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'USE_REPORT_BUILDER',
				]
			},
			{
				path: "/report-build/new/:reportType/:reportBehavior",
				name: "Report Builder",
				mini: "RSB",
				rtlName: "Report Builder",
				rtlMini: "RSB",
				component: ScrollableReportBuilder,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'USE_REPORT_BUILDER',
				]
			},
			{
				path: "/report-build/:reportId",
				name: "Report Builder",
				mini: "RSB",
				rtlName: "Report Builder",
				rtlMini: "RSB",
				component: ScrollableReportBuilder,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'USE_REPORT_BUILDER',
				]
			},
			{
				path: "/scheduled-report/:id?",
				name: "Scheduled Report",
				mini: "RP",
				rtlName: "Scheduled Report",
				rtlMini: "RP",
				component: ScheduledReportsContainer,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
	                'VIEW_COMPANY_REPORTS',
				]
			},
			{
				// this shouldn't even be here. requires admin access?
				path: "/scheduled-report-history/:id",
				name: "Scheduled Report History",
				mini: "RH",
				rtlName: "Scheduled Report History",
				rtlMini: "RH",
				component: ScheduledReportHistory,
				layout: basePath + "/admin",
				invisible: true,
			},
		]
	},
	{
		identifier: "Settings",
		collapse: true,
		name: "Settings",
		rtlName: "Settings",
		state: "openSettings",
		icon: Settings,
		views: [
			{
				identifier: "ProductCatalog",
				path: "/ltl/product-catalog",
				name: "LTL Product Catalog",
				mini: "LPC",
				rtlName: "LTL Product Catalog",
				rtlMini: "LPC",
				component: ProductCatalog,
				layout: basePath + "/admin",
				permissions: [
					'PRODUCT_CATALOG_WRITE',
				]
			},
			{
				identifier: "NewProduct",
				path: "/product/new/:wid?",
				name: "New Product",
				mini: "NP",
				rtlName: "New Product",
				rtlMini: "NP",
				component: EditProduct,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'PRODUCT_CATALOG_WRITE',
					'PRODUCT_CATALOG_READ',
				]
			},
			{
				identifier: "EditProduct",
				path: "/product/edit/:id/:wid",
				name: "Edit Product",
				mini: "EP",
				rtlName: "Edit Product",
				rtlMini: "EP",
				component: EditProduct,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'PRODUCT_CATALOG_WRITE',
					'PRODUCT_CATALOG_READ',
				]
			},
			{
				identifier: "HandlingUnitCatalog",
				path: "/handling-unit-catalog",
				name: "Handling Unit Catalog",
				mini: "UC",
				rtlName: "Handling Unit Catalog",
				rtlMini: "UC",
				component: HandlingUnitCatalog,
				layout: basePath + "/admin",
				permissions: [
					'HANDLING_UNIT_READ',
				]

			},
			{
				identifier: "NewHandlingUnit",
				path: "/handling-unit/new",
				name: "New Handling Unit",
				mini: "NU",
				rtlName: "New Handling Unit",
				rtlMini: "NU",
				component: NewHandlingUnit,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'HANDLING_UNIT_WRITE',
				]
			},
			{
				identifier: "EditHandlingUnit",
				path: "/handling-unit/edit",
				name: "Edit Handling Unit",
				mini: "EU",
				rtlName: "Edit Handling Unit",
				rtlMini: "EU",
				component: EditHandlingUnit,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'HANDLING_UNIT_READ',
					'HANDLING_UNIT_WRITE',
				]
			},
			{
				identifier: "AddressBook",
				path: "/address-book",
				name: "Address Book",
				mini: "AB",
				rtlName: "Address Book",
				rtlMini: "AB",
				component: AddressBook,
				layout: basePath + "/admin",
				permissions: [
					'ADDRESS_BOOK_READ',
				]
			},
			{
				identifier: "NewAddress",
				path: "/address/new",
				name: "New Address",
				mini: "NA",
				rtlName: "New Address",
				rtlMini: "NA",
				component: EditAddress,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'ADDRESS_BOOK_WRITE',
				]
			},
			{
				identifier: "EditAddress",
				path: "/address/edit",
				name: "Edit Address",
				mini: "EA",
				rtlName: "Edit Address",
				rtlMini: "EA",
				component: EditAddress,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'ADDRESS_BOOK_READ',
					'ADDRESS_BOOK_WRITE',
				]
			},
			{
				identifier: "CannedNotes",
				path: "/canned-notes",
				name: "Canned Notes",
				mini: "CN",
				rtlName: "Canned Notes",
				rtlMini: "CN",
				component: CannedNotes,
				layout: basePath + "/admin",
				permissions: [
					'CANNED_NOTES_READ',
				]
			},
			{
				identifier: "NewNote",
				path: "/note/new",
				name: "New Note",
				mini: "NN",
				rtlName: "New Note",
				rtlMini: "NN",
				component: NewNote,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					"CANNED_NOTES_WRITE",
				]
			},
			{
				identifier: "EditNote",
				path: "/note/:id",
				name: "Edit Note",
				mini: "EN",
				rtlName: "Edit Note",
				rtlMini: "EN",
				component: EditNote,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					"CANNED_NOTES_READ",
					"CANNED_NOTES_WRITE",
				]
			},
			{
				identifier: "PickupTimes",
				path: "/pickup-times",
				name: "Pickup Times",
				mini: "PT",
				rtlName: "Pickup Times",
				rtlMini: "PT",
				component: PickupTimes,
				layout: basePath + "/admin",
				permissions: [
					'CANNED_NOTES_READ',
				]
			}
		]
	},
	{
		identifier: "Claims",
		collapse: true,
		name: "Claims",
		rtlName: "Claims",
		state: "openClaims",
		icon: GoReport,
		views: [
			{
				path: "/support/claims",
				name: "My Claims",
				mini: "MC",
				rtlName: "My Claims",
				rtlMini: "MC",
				component: Claims,
				layout: basePath + "/admin",
				permissions: [
					'USE_CLAIMS',
				],
				companyPermissions: [
					'enable_claims',
				]
			},
			{
				path: "/claim/new/:bol",
				name: "New Claim",
				mini: "NC",
				rtlName: "New Claim",
				rtlMini: "NC",
				component: NewClaim,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'USE_CLAIMS',
				],
				companyPermissions: [
					'enable_claims',
				]
			},
			{
				path: "/claim/new",
				name: "New Claim",
				mini: "NC",
				rtlName: "New Claim",
				rtlMini: "NC",
				component: NewClaim,
				layout: basePath + "/admin",
				permissions: [
					'USE_CLAIMS',
				],
				companyPermissions: [
					'enable_claims',
				]
			},
			{
				path: "/claim/:id",
				name: "View Claim",
				mini: "VC",
				rtlName: "View Claim",
				rtlMini: "VC",
				component: ViewClaim,
				layout: basePath + "/admin",
				invisible: true,
				permissions: [
					'USE_CLAIMS',
				],
				companyPermissions: [
					'enable_claims',
				]
			}
		]
	},
	{
		path: "/customer-portal/view-carrier-bill/:id",
		name: "View Carrier Bill",
		component: ViewBillContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],

	},
	{
		path: "/customer-portal/held-bills/view/:id",
		name: "View Held Bill",
		component: ViewHeldBillContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],
	},
	{
		path: "/customer-portal/held-bills/:code",
		name: "Select for Mass Release",
		component: HeldBillsContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],
	},
	{
		path: "/customer-portal/held-bills",
		name: "Update/Release - Category Selection",
		component: HeldBillsSummaryContainer,
		layout: basePath + "/admin",
		invisible: true,
		userSettings: [
			'hold_read',
		]
	},
	{
		path: "/customer-portal/canned-reports",
		name: "View and Run On-Demand Reports",
		component: CannedReportingContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],
	},
	{
		path: "/customer-portal/view-invoices/:brokerid/:invoicenumber",
		name: "View TFM Invoice",
		component: TFMInvoiceCarrierBillsListContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],
		userSettings: [
			'view_tfm_invoices',
		]
	},
	{
		path: "/customer-portal/view-invoices",
		name: "View TFM Invoices",
		component: TFMInvoicesContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],
		userSettings: [
			'view_tfm_invoices',
		]
	},
	{
		path: "/customer-portal/search-invoices",
		name: "Search PROs",
		component: TFMInvoiceSearchContainer,
		layout: basePath + "/admin",
		invisible: true,
		permissions: [
			'HOLD_RELEASE',
		],
		userSettings: [
			'view_tfm_invoices',
		]
	},
	{
		name: "Customer Portal",
		identifier: "CustomerPortal",
		rtlName: "Customer Portal",
		icon: Launch,
		path: "/customer-portal",
		layout: basePath + "/admin",
		component: CustomerPortalLandingContainer,
		permissions: [
			'HOLD_RELEASE',
		],
	},
	{
		path: "/contact-us",
		name: "Contact Us",
		rtlName: "CU",
		icon: Mail,
		component: ContactUs,
		layout: basePath + "/admin"
	},
	{
        path: "/change-password/:id",
        name: "Change Password",
        mini: "CP",
        rtlName: "Change Password",
        rtlMini: "CP",
        component: PasswordResetPage,
        layout: basePath + "/admin",
        invisible: true,
      },
];
