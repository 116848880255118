import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircle from "@material-ui/icons/AddCircle";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class CreateLoadModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Copy Shipment to TargetTL</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody + " " + classes.left} style={{ minHeight:
                 "600px" }}>
                    <GridContainer>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Shipment Type</InputLabel>
                            <br />
                            <FormControl fullWidth className={classes.selectFormControl}>
                                <Select
                                    MenuProps={{ className: classes.selectMenu }}
                                    classes={{ select: classes.select }}
                                    value={this.props.shipmentType || ""}
                                    inputProps={{
                                        name: "shipmentType",
                                        id: "shipmentType"
                                    }}
                                    onChange={this.props.handleChange}
                                >
                                    {!_.isEmpty(this.props.shipmentTypes) ? (
                                        this.props.getShipmentTypes()
                                    ) : (
                                        <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                                            No Shipment Types
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Equipment Type</InputLabel>
                            <br />
                            <FormControl fullWidth className={classes.selectFormControl}>
                                <Select
                                    MenuProps={{ className: classes.selectMenu }}
                                    classes={{ select: classes.select }}
                                    value={this.props.equipment || ""}
                                    inputProps={{
                                        name: "equipment",
                                        id: "equipment"
                                    }}
                                    onChange={this.props.handleChange}
                                >
                                    {!_.isEmpty(this.props.equipmentTypes) ? (
                                        this.props.getEquipmentTypes()
                                    ) : (
                                        <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                                            No Equipment Types
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Customer</InputLabel>
                            <br />
                            <Autocomplete
                                id="customerId"
                                style={{ width: "100%" }}
                                options={this.props.customers}
                                classes={{ option: classes.option }}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                inputValue={this.props.customerInput || ""}
                                onInputChange={(event, value) => {
                                    if (event && event.type === "change") {
                                        this.props.customerInputValueChange(value);
                                    }
                                }}
                                onChange={(event, option) => {
                                    if (option) {
                                    	this.props.customerInputOptionChange(option.id, option.name);
                                    } else {
                                    	this.props.customerInputOptionChange("", "");
                                    }
                                }}
                                renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps
                                        }}
                                    />
                                )}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Carrier</InputLabel>
                            <br />
                            <Autocomplete
                                id="carrierId"
                                style={{ width: "100%" }}
                                options={this.props.carrierTypes}
                                classes={{ option: classes.option }}
                                autoHighlight
                                getOptionLabel={option => option.name}
                                inputValue={this.props.carrierInput || ""}
                                onInputChange={(event, value) => {
                                    if (event && event.type === "change") {
                                       this.props.carrierInputValueChange(value);
                                    }
                                }}
                                onChange={(event, option) => {
                                    if (option) {
                                        this.props.carrierInputOptionChange(option.id, option.name);
                                    } else {
                                        this.props.carrierInputOptionChange("", "");
                                    }
                                }}
                                renderOption={option => <React.Fragment>{option.name + " - " + option.scac}</React.Fragment>}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps
                                        }}
                                    />
                                )}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Pickup Dock Ready</InputLabel>
                            <br />
                            <FormControl fullWidth>
                                <Datetime
                                    timeFormat
                                    value={this.props.pickupDockOpen || ""}
                                    onChange={m => this.props.dateTimeChange("pickupDockOpen", m)}
                                    className={classes.datetime}
                                    inputProps={{
                                        id: "pickupDockOpen",
                                        name: "pickupDockOpen",
                                        placeholder: "Select Pickup Dock Ready Time"
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Pickup Dock Close</InputLabel>
                            <br />
                            <FormControl fullWidth>
                                <Datetime
                                    timeFormat
                                    value={this.props.pickupDockClose || ""}
                                    onChange={m => this.props.dateTimeChange("pickupDockClose", m)}
                                    className={classes.datetime}
                                    inputProps={{
                                        id: "pickupDockClose",
                                        name: "pickupDockClose",
                                        placeholder: "Select Pickup Dock Close Time"
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Dropoff Dock Ready</InputLabel>
                            <br />
                            <FormControl fullWidth>
                                <Datetime
                                    timeFormat
                                    value={this.props.dropoffDockOpen || ""}
                                    onChange={m => this.props.dateTimeChange("dropoffDockOpen", m)}
                                    className={classes.datetime}
                                    inputProps={{
                                        id: "dropoffDockOpen",
                                        name: "dropoffDockOpen",
                                        placeholder: "Select Dropoff Dock Ready Time"
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.label}>Dropoff Dock Close</InputLabel>
                            <br />
                            <FormControl fullWidth>
                                <Datetime
                                    timeFormat
                                    value={this.props.dropoffDockClose || ""}
                                    onChange={m => this.props.dateTimeChange("dropoffDockClose", m)}
                                    className={classes.datetime}
                                    inputProps={{
                                        id: "dropoffDockClose",
                                        name: "dropoffDockClose",
                                        placeholder: "Select Dropoff Dock Close Time"
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button size="sm" color="linkedin" onClick={() => this.props.addService()}>
                                <AddCircle /> Add Accessorial
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className={classes.unitContainer}>
                            {!_.isEmpty(this.props.services) && this.props.getServices()}
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="warning" onClick={() => this.props.onClose()} className={classes.marginRight}>
                        Close
                    </Button>
                    {this.props.creatingShipment ? (
                        <Button color="linkedin" style={{ lineHeight: 0 }}>
                            <CircularProgress size={16} style={{ color: "white" }} />
                        </Button>
                    ) : (
                        <Button color="linkedin" onClick={() => this.props.createShipment()} className={classes.marginRight}>
                            Submit
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(CreateLoadModal);