import React from "react";
import { connect } from "react-redux";
import axios from "~/variables/axios.jsx";

import qs from "qs";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import Datetime from "react-datetime";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import NavPills from "~/components/NavPills/NavPills.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import RateResults from "../RateResults/containers/RateResults.jsx";
import { userIsAdmin } from "../../redux/selectors/Admin.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Transition extends React.Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

class LtlQuotePanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			alert: null,
			body: {},
			user: {},
			company: {},
			current: 1,
			next: "",
			prev: "",
			search_by: "qid",
			search_terms: "",
			limit: "20",
			quoteModal: false,
			quote: {},
			quote_id: "",
			quoteDetail: {},
			preventExclusionExecution: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.loadQuote = this.loadQuote.bind(this);
		this.getQuoteDetails = this.getQuoteDetails.bind(this);
		this.getUnits = this.getUnits.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.getAccessorials = this.getAccessorials.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.handlePrintAll = this.handlePrintAll.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=postingPanel&m=quotes&d=1/20"
			);
			this.props.handleMessages(response);
			const data = response.data;
			if (data.body && data.user && this.state.mounted) {
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					current: data.body.current.toString() || "1",
					next: data.body.next || "",
					prev: data.body.prev || "",
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	getHeaders() {
		return ["Quote ID", "Date", "Shipper", "Consignee", "Warehouse", "Actions"];
	}
	getColumns(quotes) {
		return quotes.map((prop, key) => {
			let quoteType = "";
			quoteType += prop.type === "tl" ? "TL" : "";
			quoteType += "QID-" + prop.quote_id;
			return [
				quoteType,
				prop.date,
				prop.shipper,
				prop.consignee,
				prop.warehouse,
				<div className="actions-right">
					{/* onClick={(e) => this.loadQuote(prop.quote_id, prop.type)} */}
					<Button
						size="sm"
						color="linkedin"
						onClick={() => this.loadQuote(prop.quote_id, prop.type)}
					>Load</Button>
					<Button
						size="sm"
						color="info"
						onClick={() => this.handlePrintAll(prop.quote_id)}
					>Print</Button>
				</div>
			];
		});
	}
	
	getPagination() {
		let nextPage = { text: ">" };
		let prevPage = { text: "<" };

		if (this.state.next !== "" && !isNaN(this.state.next)) {
			nextPage.onClick = e => this.handlePageClick(this.state.next);
		} else {
			nextPage.disabled = true;
		}

		if (this.state.prev !== "" && !isNaN(this.state.prev)) {
			prevPage.onClick = e => this.handlePageClick(this.state.prev);
		} else {
			prevPage.disabled = true;
		}

		return [prevPage, nextPage];
	}

	async handlePageClick(page) {
		let search = "";
		let { limit } = this.state;

		if (this.state.body.active_search.length) {
			search = this.state.body.active_search;
		}

		if (isNaN(limit)) {
			limit = "20";
		}

		this.setState({ show: false, loading: true });

		const url = "/index.php?p=api&r=json&c=postingPanel&m=quotes&d=" + page + "/" + limit + search;

		try {
			const response = await axios.get(url);
			if (response.data) {
				this.props.handleMessages(response);
				const data = response.data;
				if (
					data.body.result &&
					data.body.result.length > 0 &&
					this.state.mounted
				) {
					this.setState({
						show: true,
						loading: false,
						body: data.body,
						current: data.body.current.toString() || "1",
						next: data.body.next || "",
						prev: data.body.prev || "",
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error loading the data!",
						{ variant: "error" }
					);
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	async handleSearch() {
		this.setState({
			show: false,
			loading: true
		});

		let search = "";
		const { search_by, search_terms, limit } = this.state;

		if (!_.isEmpty(search_by) && !_.isEmpty(search_terms)) {
			search = "&search_by=" + encodeURIComponent(search_by) + "&search_terms=" + encodeURIComponent(search_terms);
		}

		if (isNaN(limit)) {
			limit = "20";
		}

		const url = "/index.php?p=api&r=json&c=postingPanel&m=quotes&d=1/" + limit + search;

		try {
			const response = await axios.get(url);
			if (response.data) {
				this.props.handleMessages(response);
				const data = response.data;
				if (
					data.body.result &&
					data.body.result.length > 0 &&
					this.state.mounted
				) {
					this.setState({
						show: true,
						loading: false,
						body: data.body,
						current: data.body.current.toString() || "1",
						next: data.body.next || "",
						prev: data.body.prev || "",
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error loading the data!",
						{
							variant: "error"
						}
					);
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	async loadQuote(quote_id, type) {
		this.setState({
			quoteModal: false,
			quote: {},
			quote_id: "",
			tfmr_internal_id: false,
			distance: 0,
			quoteDetail: {},
			selected: ""
		});
		if (type === "tl") {
			/*$.get('index.php?p=api&r=json&c=ctsi&m=getTLCalcQuote&d=' + target, function(response) {

                if(response) {

                    response = $.parseJSON(response);

                    if(response.message) {
                        handleMessages(response);
                    }

                    if(response.body) {

                        var equipment = '';

                        switch(response.body.equipment) {

                            case 'Van':
                                equipment = 'Trailer';
                                break;

                            case 'Reefer':
                                equipment = 'Refrigerated Trailer';
                                break;

                            case 'Flat':
                                equipment = 'Flatbed';
                                break;
                        }

                        $equipmentDisplay = $('<h4/>')
                            .css({
                                'text-align': 'center',
                                'width': '100%',
                                'margin' : '20px 0px 0px 0px',
                            })
                            .append(equipment);

                        $zipDisplay = $('<h3/>')
                            .css({
                                'margin': '5px 0px 20px 0px',
                                'width': '100%',
                                'text-align': 'center',
                            })
                            .append(response.body.shipZip + ' to ' + response.body.consZip);

                        $('#shipment').append($equipmentDisplay);
                        $('#shipment').append($zipDisplay);

                        //display quote
                        $row = $('<div/>').css('text-align', 'center');

                        $row.append('<div style="text-align:center;"><h2 id="tlQuote">$' + response.body.amount + '</h2></div>');

                        $('#quote').html($row);

                        $('#tlQuote').css({
                            'width': '40%',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'border': '1px solid',
                            'border-color': 'rgb(115, 175, 234)',
                            'border-radius': '30px',
                        });

                        $verbage = $('<div/>')
                            .addClass('well')
                            .addClass('well-small')
                            .addClass('text-small')
                            .append('All pricing subject to availability and standard market fluctuations. Quoted rate may differ from actual rate negotiated with carrier, rate shown is based on historical averages for this lane for a full truck load. We do our best to find the best rate possible for every shipment. We will contact you to confirm shipment and provide final pricing prior to dispatching carrier and finalizing shipment. Actual pricing may be higher or lower than quoted amount. Accessorial charges including, but not limited to, hazardous, lift gates, inside delivery, etc vary by carrier and are not included in this quote. We will work directly with the carrier to negotiate an appropriate rate for these accessorials when they are requested and will confirm them with you prior to booking the load.');

                        $span = $('<div/>').append($verbage);

                        $row = $('<div/>').append($span);

                        $('#quote').prepend($row);

                        $qidBanner = $('<div/>').addClass('alert alert-success')
                            .html('<strong>Quote ID:</strong><em> TLQID-' + response.body.id + '</em>')

                        $('#quote').append($qidBanner);

                        $('#origin_destination').html(response.body.shipZip + ' - ' + response.body.consZip);

                        load.stop();

                    } else {
                       // this.setError('Error', 'There was an error loading the quote.', 'error');
                       return false;
                    }

                } else {
                    setError('Oops!', 'There was an error loading the quote.', 'error');
                    return false;
                }
            });*/
		} else {
			try {
				const response = await axios.get("/index.php?p=api&r=json&c=ctsi&m=loadQuote&d=" + quote_id);
				const detailRes = await axios.get("/index.php?p=api&r=json&c=postingPanel&m=quoteDetail&d=" + quote_id);

				const warehouseCode = detailRes.data.body.post.general.warehouse_code;
				const warehouseDefaults = await axios.get("/index.php?p=api&r=json&c=warehouse&m=getDefaults&d=" + warehouseCode);
				const preventExclusionExecution = warehouseDefaults.data.body.prevent_exclusion_execution == 1 ? true : false;

				if (
					typeof response.data !== "string" &&
					!_.isEmpty(response.data.message)
				) {
					this.props.handleMessages(response);
				}
				if (
					typeof detailRes.data !== "string" &&
					!_.isEmpty(detailRes.data.message)
				) {
					this.props.handleMessages(detailRes);
				}
				if (
					typeof response.data !== "string" &&
					typeof detailRes.data !== "string" &&
					!_.isEmpty(response.data.body) &&
					!_.isEmpty(response.data.user) &&
					!_.isEmpty(detailRes.data.body)
				) {
					this.setState({
						quote_id,
						tfmr_internal_id: response.data.body.tfmr_internal_id || false,
						distance: response.data.body.distance,
						quote: response.data.body,
						quoteDetail: detailRes.data.body.post,
						quoteModal: true,
						selected: response.data.body.selected,
						preventExclusionExecution: preventExclusionExecution
					});
				} else {
					this.props.setNotification(
						"There was an error loading the quote!",
						{ variant: "error" }
					);
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification(
					"There was an error loading the quote!",
					{ variant: "error" }
				);
			}
		}

		return false;
	}
	getUnits(data) {
		return data.map((prop, key) => {
			return [
				prop.num_of,
				prop.type,
				prop.stack,
				(parseFloat(prop.length) * 12).toFixed(2),
				(parseFloat(prop.width) * 12).toFixed(2),
				(parseFloat(prop.height) * 12).toFixed(2)
			];
		});
	}
	getProducts(data) {
		return data.map((prop, key) => {
			return [
				prop.class,
				prop.weight
			];
		});
	}
	getAccessorials(data) {
		return data.map((prop, key) => {
			return <li key={key}>{prop}</li>;
		});
	}
	getQuoteDetails() {
		const { classes } = this.props;
		const { quote, quoteDetail, quote_id } = this.state;

		let tabs = [], products = [], validDimensions = true;

		if (_.isEmpty(quote) || _.isEmpty(quoteDetail)) {
			return tabs;
		}

		if (!_.isEmpty(quoteDetail.units)) {
			for (let unit of quoteDetail.units) {
				if (
					isNaN(parseFloat(unit.length)) ||
                    parseFloat(unit.length) <= 0 ||
                    isNaN(parseFloat(unit.width)) ||
                    parseFloat(unit.width) <= 0 ||
                    isNaN(parseFloat(unit.height)) ||
                    parseFloat(unit.height) <= 0
                ) {
                    validDimensions = false;
                }
                if (!_.isEmpty(unit.products)) {
                	products = products.concat(unit.products);
                }
			}
		}

		tabs.push({
			tabButton: "Details",
			tabContent: (
				<Grid container className={classes.left}>
					<GridItem xs={12}>
						<Table
							hover
							tableHead={["Pieces", "Type", "Stackable", "Length", "Width", "Height"]}
							tableData={this.getUnits(quoteDetail.units)}
						/>
					</GridItem>
					<GridItem xs={4}>
						<Table
							hover
							tableHead={["Class", "Weight"]}
							tableData={this.getProducts(products)}
						/>
					</GridItem>
					<GridItem xs={4}>
						<Paper className={classes.infoPaper + " " + classes.left} elevation={1}>
							<Typography variant="body2">
								Total Cubit Feet: <strong>{parseFloat(quoteDetail.general.total_cube).toFixed(2) + " ft."}<sup>{3}</sup></strong><br/>
								Total PCF: <strong>{parseFloat(quoteDetail.general.total_pcf).toFixed(2)}</strong><br/>
								Linear Feet: <strong>{parseFloat(quoteDetail.general.linear_feet).toFixed(2) + " ft."}</strong><br/>
								Total Weight: <strong>{parseFloat(quoteDetail.general.total_weight).toFixed(2) + " lbs."}</strong><br/>
								{quoteDetail.general.volume && quoteDetail.general.shipmentTotalLength && (
									<>Shipment Total Length: <strong>{quoteDetail.general.shipmentTotalLength}</strong><br/></>
								)}
								{quoteDetail.general.volume && quoteDetail.general.shipmentTotalWidth && (
									<>Shipment Total Width: <strong>{quoteDetail.general.shipmentTotalWidth}</strong><br/></>
								)}
								{quoteDetail.general.volume && quoteDetail.general.shipmentTotalHeight && (
									<>Shipment Total Height: <strong>{quoteDetail.general.shipmentTotalHeight}</strong></>
								)}
							</Typography>
						</Paper>
					</GridItem>
					<GridItem xs={4}>
						<Paper className={classes.infoPaper + " " + classes.left} elevation={1}>
							<Typography variant="body2">
								Origin: <strong>{quoteDetail.general.shipper}</strong>
								{quoteDetail.general.hasOwnProperty('shipper_country') && (
									<strong> - {quoteDetail.general.shipper_country}</strong>
								)}
								<br/>
								Destination: <strong>{quoteDetail.general.consignee}</strong>
								{quoteDetail.general.hasOwnProperty('consignee_country') && (
									<strong> - {quoteDetail.general.consignee_country}</strong>
								)}
								<br/>
								Direction/Terms: <strong>{quoteDetail.general.shipment_type}</strong><br/>
								Warehouse: <strong>{quoteDetail.general.warehouse_code}</strong><br/>
								{quoteDetail.hasOwnProperty('accessorials') && !_.isEmpty(quoteDetail.accessorials) && (
									this.getAccessorials(quoteDetail.accessorials)
								)}
							</Typography>
						</Paper>
					</GridItem>
				</Grid>
			)
		});

		tabs.push({
			tabButton: "Rates",
			tabContent: (
				<Grid container>
					<GridItem xs={12} style={{ marginBottom: "15px", textAlign: "left" }}>
						<Paper className={classes.infoPaper} elevation={1}>
							This quote does not apply on shipments that
							qualify as a Capacity Load or as a Cubic
							Capacity Density rated shipment. For
							shipments weighing more than 7,500 lbs. or
							that are greater than 750 cubic ft. or that
							take up more than 10 linear feet of a
							trailer please contact TFM at 1-888-653-1323
							for routing Instruction. This quote will be
							based on the information you provide. Actual
							charges will be determined by shipment
							characteristics and any additional service
							options applicable at time of shipment.
							{this.state.user && (this.state.user.level === "admin" || this.state.user.user_company_settings.enable_liability == 1) &&
								" Carrier liability rates are based on product data provided here and are subject to all applicable items found in carrier rules tariffs, such as packaging requirements and accurate NMFC numbers. If you require additional coverage, and you have non-new (used, refurbished, etc.) products on your shipment, please contact TFM for pricing."}
						</Paper>
					</GridItem>
					<GridItem xs={4} style={{ marginBottom: "15px", textAlign: "left" }}>
						<Paper className={classes.paper} elevation={1}>
							<Button
								round
								size="sm"
								className={classes.nonProgramCarriers}
							>
								{" "}
							</Button>{" "}
							<Button
								round
								size="sm"
								className={
									classes.nonProgramCarriersAlt
								}
							>
								{" "}
							</Button>{" "}
							<b>Non-Program Carriers</b>
							<br />
							<Button
								round
								size="sm"
								className={classes.programCarriers}
							>
								{" "}
							</Button>{" "}
							<Button
								round
								size="sm"
								className={classes.programCarriersAlt}
							>
								{" "}
							</Button>{" "}
							<b>Program Carriers</b>
						</Paper>
					</GridItem>
					<GridItem xs={8} className={classes.right}>
						{validDimensions && (
							<NavLink
								to={basePath + "/admin/bol/new?quote=" + quote_id}
							>
								<Button
									color="linkedin"
									className={classes.marginRight}
								>
									Create BoL
								</Button>
							</NavLink>
						)}
						<Button
							color="warning"
							className={classes.marginRight}
							onClick={() => this.handlePrintAll(quote_id)}
						>
							Print All Quotes
						</Button>
					</GridItem>
					<GridItem xs={12}>
						{this.renderRateResults(quote.carriers)}
					</GridItem>
				</Grid>
			)
		});

		return tabs;
	}

	isVolume = () => {
		return typeof this.state.quote === "object" &&
			   this.state.quote !== null &&
			   !!+this.state.quote.volume;
	}

	renderRateResults = (carriers) => {
		return (
			<RateResults
               carriers={carriers}
               distance={this.state.distance}
               tfmrId={+this.state.tfmr_internal_id}
               volume={this.isVolume()}
               showDetails={false}
               showHideMessage={true}
               canSelect={true}
               actionsHeader="Actions"
               onSelect={carrier => {
               		this.handleSelect(this.state.selected == carrier.scac ? "" : carrier.scac);
               }}
               selectedCarrierScac={this.state.selected}
               classes={this.props.classes}
               canGetTerminalInfo={false}
               showImportantButton={true}
               onClickImportant={carrier => {
               		this.handleImportantDetails(carrier);
               }}
			   disableExclusions={this.state.preventExclusionExecution}
           />
		);
	}

	handlePrint() {

	}
	handlePrintAll(quote_id) {
		window.open(apiUrl + "/index.php?p=api&c=rater&m=printQuote&d=" + quote_id);
	}
	async handleSelect(scac) {
		if(_.isEmpty(this.state.quote_id)) {
			this.props.setNotification(
				"Quote ID is invalid!",
				{ variant: "error" }
			);
			return false;
		}
		scac = scac || "";
		const url = "/index.php?p=api&r=json&c=rater&m=saveQuoteCarrier";
		const data = { scac, qid: this.state.quote_id };
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				response.data.body !== false
			) {
				const { body } = response.data;
				const text = (!_.isEmpty(scac)) ? "selected" : "unselected";
				const selected = (!_.isEmpty(body) && !_.isEmpty(scac) && body.toUpperCase() === scac.toUpperCase()) ? scac : "";
				this.setState({ selected });
				this.props.setNotification(
					"Carrier " + text + "!",
					{ variant: "success" }
				);
			} else {
				this.props.setNotification(
					"There was an error selecting the carrier!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error selecting the carrier!",
				{ variant: "error" }
			);
		}
	}

	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch();
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs={12}>
											<Grid container>
												<Grid item xs style={{ paddingRight: "10px", paddingTop: "7px" }}>
													<FormControl
														fullWidth
														className={classes.selectFormControl}
													>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{ select: classes.select }}
															value={this.state.search_by || ""}
															inputProps={{
																name: "search_by",
																id: "search_by"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="qid"
															>
																Quote ID
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="date"
															>
																Date
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="shipper"
															>
																Shipper
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="consignee"
															>
																Consignee
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs style={{ paddingTop: "7px" }}>
													{this.state.search_by === "date" ? (
														<FormControl fullWidth>
															<Datetime
																timeFormat={false}
																value={this.state.search_terms || ""}
																onChange={m => this.handleDatetime("search_terms", m)}
																className={classes.datetime}
																inputProps={{
																	name: "search_terms"
																}}
															/>
														</FormControl>
													) : (
														<CustomInput
															id="search_terms"
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "search_terms",
																value: this.state.search_terms || "",
																onChange: this.handleInput("search_terms"),
																onKeyPress: this.handleKeyPress
															}}
															white
														/>
													)}
												</Grid>
												<Grid item xs={3} sm={3} md={6}>
													<Button
														size="sm"
														color="linkedin"
														className={classes.marginLeft}
														onClick={this.handleSearch}
													>
														<Search /> Search
													</Button>
												</Grid>
											</Grid>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={2} />
								<GridItem xs={8} sm={8} md={8} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
								{/* DO NOT REMOVE! */}
								{false ? (
									<GridItem xs={2}>
										<small>Results</small>
										<CustomInput
											id="limit"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "number",
												name: "limit",
												value: this.state.limit || "",
												onChange: this.handleInput("limit")
											}}
											white
										/>
									</GridItem>
								) : (
									<GridItem xs={2} />
								)}
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(this.state.body.result) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.body.result) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(this.state.body.result)}
												customCellClasses={[classes.right]}
												customClassesForCells={[5]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[5]}
											/>
										) : (
											<Badge color="warning">No Quotes</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve LTL quotes from the server"
									/>
								)}
							</Grid>
							<GridContainer>
							 	<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.quoteModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("quoteModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							color="transparent"
							onClick={() => this.handleModalClose("quoteModal")}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Quote ID: {this.state.quote_id}</h4>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
					>
						<GridContainer>
							{!_.isEmpty(this.state.quote) && !_.isEmpty(this.state.quoteDetail) && (
								<GridItem xs={12}>
									<NavPills
										color="warning"
										tabs={this.getQuoteDetails()}
									/>
								</GridItem>
							)}
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button
							onClick={() => this.handleModalClose("quoteModal")}
							color="white"
						>
							{"Close"}
						</Button>
					</DialogActions>
				</Dialog>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
        userIsAdmin: userIsAdmin(state),
    };
}

export default connect(mapStateToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(LtlQuotePanel)));
