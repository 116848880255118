import React, { Component, Fragment } from "react";
import pluralize from 'pluralize';
import withStyles from "@material-ui/core/styles/withStyles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from '~/components/Grid/GridItem.jsx';
import GridText from '../../../components/Grid/GridText.jsx';
import Typography from '@material-ui/core/Typography';

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ProductDetails extends Component {
    render() {
        return (
            <Fragment>
                {this.getProductDetails()}
            </Fragment>
        );
    }

    getProductDetails = () => {
        if (!this.props.bill) return null;
        return (
            <Fragment>
                {this.getUnits()}
                {this.getLooseItems()}
                {this.getAccessorials()}
            </Fragment>
        );
    }

    getUnits = () => {
        const StyledUnit = withStyles(style)(Unit);
        if (!this.props.bill.units.length || this.props.hideProducts) return null;
        return (
            <GridItem xs={12}>
                {this.props.bill.units.map((unit, index) => {
                    return <StyledUnit key={index} index={index} unit={unit} hideAmount={this.props.hideAmount}/>
                })}
            </GridItem>
        );
    }

    getLooseItems = () => {
        if (!Array.isArray(this.props.bill.looseItems) || !this.props.bill.looseItems.length || this.props.hideProducts) return null;
        return (
            <GridItem xs={12}>
                <h5><strong>LOOSE ITEMS</strong></h5>
                <GridContainer>
                    {this.props.bill.looseItems.map((item, index) => {
                        return <Product key={index} index={index} product={item} hideAmount={this.props.hideAmount}/>
                    })}
                </GridContainer>
            </GridItem>
        );
    }

    getAccessorials = () => {
        if (!Array.isArray(this.props.bill.accessorials) || !this.props.bill.accessorials.length) return null;
        return (
            <GridItem xs={12}>
                <h5><strong>ACCESSORIALS</strong></h5>
                <GridContainer>
                    {this.props.bill.accessorials.map((item, index) => {
                        return <Accessorial key={index} index={index} accessorial={item}/>
                    })}
                </GridContainer>
            </GridItem>
        );
    }
}

class Unit extends Component {
    render() {
        const unit = this.props.unit;
        const classes = this.props.classes || {};
        return (
            <GridItem xs={12} className={classes.unitWrapper} style={{marginBottom: "10px"}}>
                <GridContainer className={classes.unitHeader}>
                    <h5>
                        <GridItem xs={12}>
                            <strong>{`UNIT #${this.props.index + 1}`}</strong>
                        </GridItem>
                    </h5>
                </GridContainer>
                <GridContainer className={classes.unit}>
                    <GridText width={2} text={pluralize(unit.type, unit.pieces, true)} />
                    <GridText width={2} text={unit.stack ? "Stackable" : "Not Stackable"} />
                    <GridText width={2} text={`${unit.length}x${unit.width}x${unit.height}`} />
                    <GridText width={2} text={`${unit.weight}lbs`} />
                    <GridItem xs={2} />
                    {!this.props.hideAmount && (
                    <GridText width={2} text={`Charge: $${unit.charge}`} />
                    )}
                </GridContainer>
                {unit.products.length ? (<GridContainer className={classes.product}>
                    {unit.products.map((product, index) => {
                        return (
                            <Product key={index} index={index} product={product} hideAmount={this.props.hideAmount} />
                        )
                    })}
                </GridContainer>) : null}
            </GridItem>
        );
    }
}

class Product extends Component {
    render() {
        const product = this.props.product;
        return (
            <Fragment>
                <GridText width={2} text={`Product #${this.props.index + 1}`} />
                <GridText width={2} text={`${product.pieces} x ${!product.description || product.description === "" ? "(No Description}" : product.description}`} />
                <GridText width={2} text={product.code !== "" ? `Class: ${product.code}` : ""} />
                <GridText width={2} text={product.nmfc !== "" ? `NMFC: ${product.nmfc}` : ""} />
                <GridText width={2} text={product.weight !== "" ? `Weight: ${product.weight}lbs` : ""} />
                {!this.props.hideAmount && (
                    <GridText width={2} text={product.charge !== "" ? `Charge: $${product.charge}` : ""} />
                )}
            </Fragment>
        );
    }
}

class Accessorial extends Component {
    render() {
        const accessorial = this.props.accessorial;
        return (
            <Fragment>
                <GridText width={2} text={`Accessorial #${this.props.index + 1}`} />
                <GridText width={2} text={accessorial.code !== "" ? `Code: ${accessorial.code}` : ""} />
                <GridText width={4} text={`${accessorial.description}`} />
                <GridItem xs={2} />
                <GridText width={2} text={accessorial.charge !== "" ? `Charge: $${accessorial.charge}` : ""} />
            </Fragment>
        );
    }
}

export default ProductDetails;
