import React from "react";
import _ from "lodash";
import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Box from "@material-ui/core/Box";

import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import ReportScheduler from "~/views/CustomReporting/components/ReportScheduler.jsx";
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

import moment from "moment";

import { Radio } from "@material-ui/core";

// core components
import { ValidatorForm } from "react-material-ui-form-validator";
import "moment-timezone";

class FinishStep extends React.Component {
  constructor(props) {
    super(props);

    const isEdit = (this.props.reportId != "0" && this.props.reportId != undefined) ||
      localStorage.getItem("reportId") !== ""
      ? true
      : false;

    this.state = {
      isEdit,
      selectedValue: "",

      Schedule: false,
      RunNow: false,
      SaveAs: false,
      SaveReport: isEdit,

      reportSchedule: {
        name: this.props.reportName,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        recipients: [""],
        schedule: "",
        reportId: this.props.reportId,
        format: "",
        scheduleId: "",
        time: moment(),
        timeFormat: moment().format("HH:00"),
      },
    };
  }

  componentDidMount() {
    if (this.state.isEdit) {
      this.handlePushState();
    }
  }

  saveScheduledReport = async () => { };

  handleChange = (reportSchedule) => {
    this.setState({ reportSchedule }, this.handlePushState);
  };

  handleInput = (name) => (event) => {
    let value = event.target.value;

    this.setState(prevState => ({
      reportSchedule: {
        ...prevState.reportSchedule,
        [name]: value
      }
    }), this.handlePushState);
  };

  handlePushState = () => {
    this.props.handleStepState(this.state, 'finish');
  };

  handleRadioButtonChange = (event) => {
    let value = event.target.value;

    this.setState({
      selectedValue: value,
      RunNow: value === "a" ? true : false,
      SaveReport: value === "b" ? true : false,
      Schedule: value === "c" ? true : false,
      SaveAs: value === "as" ? true : false,
    }, this.handlePushState);
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer direction={"column"}>
        <GridItem>
          <FormControlLabel
            checked={this.state.selectedValue === "a"}
            onChange={this.handleRadioButtonChange}
            value="a"
            name="radio-button-demo"
            aria-label="A"
            classes={{
              root: classes.root,
              // checked: classes.checked,
            }}
            label="Run Now"
            control={<Radio color="primary" />}
          />
        </GridItem>
        {this.state.isEdit ? (
          <GridItem>
            <FormControlLabel
              checked={this.state.selectedValue === "as"}
              onChange={this.handleRadioButtonChange}
              value="as"
              name="radio-button-demo"
              aria-label="AS"
              classes={{
                root: classes.root,
                // checked: classes.checked,
              }}
              label="Save As New"
              control={<Radio color="primary" />}
            />

            {this.state.selectedValue === "as" ? (
              <Box pt={1}>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: "string",
                    id: "name",
                    name: "name",
                    required: true,
                    value: this.state.reportSchedule.name || "",
                    onChange: this.handleInput("name"),
                    placeholder: "Enter report name",
                  }}
                  white
                />
              </Box>
            ) : (
              ""
            )}
          </GridItem>
        ) : null}

        <GridItem xs={12} sm={12} md={4} lg={4}>
          <FormControlLabel
            checked={this.state.selectedValue === "b"}
            onChange={this.handleRadioButtonChange}
            value="b"
            name="radio-button-demo"
            aria-label="B"
            classes={{
              root: classes.root,
              // checked: classes.checked,
            }}
            label="Save and Run"
            control={<Radio color="primary" />}
          />

          {this.state.selectedValue === "b" ? (
            <Box pt={1}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "string",
                  id: "name",
                  name: "name",
                  required: true,
                  value: this.state.reportSchedule.name || "",
                  onChange: this.handleInput("name"),
                  placeholder: "Enter report name",
                }}
                white
              />
            </Box>
          ) : (
            ""
          )}
        </GridItem>

        <GridItem>
          <FormControlLabel
            checked={this.state.selectedValue === "c"}
            onChange={this.handleRadioButtonChange}
            value="c"
            name="radio-button-demo"
            aria-label="C"
            classes={{
              root: classes.root,
              // checked: classes.checked,
            }}
            label="Save and Schedule"
            control={<Radio color="primary" />}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridItem xs={12} sm={12} md={4} lg={4} />
          <ValidatorForm onSubmit={this.saveScheduledReport}>
            {this.state.selectedValue === "c" ? (
              <>
                <GridContainer direction={"column"}>
                  <GridItem xs={4} md={4} lg={4}>

                    <Box pt={2}>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "string",
                          id: "name",
                          name: "name",
                          required: true,
                          value: this.state.reportSchedule.name || "",
                          onChange: this.handleInput("name"),
                          placeholder: "Enter report name",
                        }}
                        white
                      />
                    </Box>

                  </GridItem>
                </GridContainer>
                <br />
                <ReportScheduler
                  reportSchedule={this.state.reportSchedule}
                  classes={classes}
                  onChange={this.handleChange}
                />
              </>
            ) : (
              " "
            )}
          </ValidatorForm>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(ltlQuoteStyle)(withSnackbar(FinishStep));
