import axios from "~/variables/axios.jsx";
import { handleMessages, setNotification } from "../../../redux/actions/notifications.jsx";

export const loadAutoSelectAccessorialMaps = locationId => {
	return async (dispatch) => {
		let result = [];
		try {
			const url = `index.php?p=api&r=json&c=autoSelectAccessorialLocationMap&m=collection&filters[location_name_id]=${locationId}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));

			if (response && response.data && response.data.body && Array.isArray(response.data.body.response)) {
				result = response.data.body.response;
			}
		} catch (error) {
			console.error(error);
			dispatch(setNotification("There was an error loading accessorial map data!", {variant: "error"}));
		}
		return result;
	}
}

export const loadParcelData = locationId => {
	return async (dispatch) => {
		let result = [];
		try {
			const url = `index.php?p=api&r=json&c=parcelLocationData&m=collection&filters[location_name_id]=${locationId}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));

			if (response && response.data && response.data.body && Array.isArray(response.data.body.response)) {
				result = response.data.body.response;
			}
		} catch (error) {
			console.error(error);
			dispatch(setNotification("There was an error loading accessorial map data!", {variant: "error"}));
		}
		return result;
	}
}