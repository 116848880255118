import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { baseUrl, assetPath } from "~/variables/server.jsx";
import footerStyle from "~/assets/jss/empire-tms/components/footerStyle.jsx";

const poweredByLogo = baseUrl + assetPath + "/img/empire/icon-light.png";

function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
        </div>
        <div className={classes.right}>
          <span>Powered By &nbsp;</span>
          <img className={classes.poweredByImg} src={poweredByLogo} alt="TFM" />
        </div>
        <p className={classes.right}>
          Copyright &copy; {1900 + new Date().getYear()}{" "}
          <a href="https://www.targetfmi.com" className={anchor}>
            {"Target FMI"}
          </a>
          {". Protected by United States Patent No. 9,747,578"}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
