import React from "react";
import qs from "qs";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import SnackbarContent from "~/components/Snackbar/SnackbarContent.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";



class PickupTimes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			loading: true,
			show: false,
			disabled: true,
			warehouseAssignments: {},
			warehouseID: '',
			warehouseData: {},
		};

		this.getWarehouseAssignments = this.getWarehouseAssignments.bind(this);
		this.getWarehouseData = this.getWarehouseData.bind(this);
		this.req = this.req.bind(this);
		this.enqueueSnackbar = this.enqueueSnackbar.bind(this);

		this.handleChangeWarehouse = this.handleChangeWarehouse.bind(this);
		this.handleChangeTime = this.handleChangeTime.bind(this);
		this.handleSave = this.handleSave.bind(this);

		this.renderDropDown = this.renderDropDown.bind(this);
		this.renderTable = this.renderTable.bind(this);
	}



	async componentDidMount() {
		this.setState({ mounted: true });
		const warehouseAssignments = await this.getWarehouseAssignments();
		const warehouseID = !_.isEmpty(warehouseAssignments)? Object.keys(warehouseAssignments)[0] : '';
		const warehouseData = await this.getWarehouseData(warehouseID);

		this.setState({
			show: !_.isEmpty(warehouseAssignments),
			loading: false,
			disabled: _.isEmpty(warehouseAssignments),
			warehouseAssignments: warehouseAssignments,
			warehouseID: warehouseID,
			warehouseData: warehouseData
		});
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}



	async req(method, url, data, onSuccess, onError) {
		try {
			const response = await method(url, data);
			if (typeof response.data !== "string") {
				this.props.handleMessages(response);
				const body = response.data.body;
				if (body && this.state.mounted) {
					onSuccess();
					return body;
				}
				else {
					onError();
					return [];
				}
			} else {
				onError();
				return [];
			}
		} catch (error) {
			console.error(error);
			onError();
			return [];
		}
	}
	enqueueSnackbar(msg, variant) {
		this.setState({ loading: false });
		this.props.setNotification(msg, {variant: variant});
	}



	async getWarehouseAssignments() {
		const body = await this.req(axios.get, "/index.php?p=api&r=json&c=userSettings&m=notes", null,
			() => {},
			() => this.enqueueSnackbar("There was an error loading the pickup rates!", "error")
		);

		let warehouseAssignments = {};
		for (let prop of body.warehouses) {
			warehouseAssignments[prop.id] = prop.name;
		}
		return warehouseAssignments;
	}
	async getWarehouseData(id) {
		const body = await this.req(axios.get, `/index.php?p=api&r=json&c=userSettings&m=defaultPickupTimes&d=wids/[${id}]`, null,
			() => {},
			() => this.enqueueSnackbar("There was an error loading the pickup rates!", "error")
		);

		let warehouseData = {id: id, carriers: {}};
		for (let prop of body) {
			let carrier = {
				warehouse_name_id: id,
				scac: prop.scac,
				carrier: prop.carrier,
				carrier_id: prop.carrier_id,
				ready: {
					hour: prop.ready_hour,
					minute: prop.ready_minute
				},
				close: {
					hour: prop.close_hour,
					minute: prop.close_minute
				}
			}
			warehouseData.carriers[carrier.carrier_id] = carrier;
		}
		return warehouseData;
	}



	async handleSave() {
		this.setState({disabled: true})
		const { warehouseData } = this.state;
		const data = Object.values(warehouseData.carriers);

		await this.req(axios.post, "/index.php?p=api&r=json&c=userSettings&m=defaultPickupTimes", data,
			() => this.enqueueSnackbar("Pickup times successfully saved!", "success"),
			() => this.enqueueSnackbar("There was an error saving the pickup rates!", "error")
		);
		this.setState({disabled: false});
	}
	async handleChangeWarehouse(e) {
		const warehouseID = e.target.value;
		this.setState({warehouseID: warehouseID, show: false, loading: true, disabled: true});
		const warehouseData = await this.getWarehouseData(warehouseID);
		this.setState({warehouseData: warehouseData, show: true, loading: false, disabled: false});
	}
	handleChangeTime(carrier_id, dock, time, event) {
		let { warehouseData } = this.state;
		let oldTime = warehouseData.carriers[carrier_id][dock][time];
		let newTime = event.target.value;

		// input validation | forces 24 hour format, allows empty ----------------------
		oldTime = oldTime ? String(oldTime).trim() : ''
		newTime = newTime.trim();
		if (newTime.length === 0 || (oldTime === '00' && newTime === '0')) {
			newTime = '';
		}
		else {
			const newTimeInt = parseInt(newTime);
			if (time.includes('hour') && (!Number.isInteger(newTimeInt) || newTime >= 24 || newTime < 0)) return;
			else if (time.includes('minute') && ( !Number.isInteger(newTimeInt) || newTime >= 60 || newTime < 0)) return;
			newTime = String(newTimeInt).padStart(2, '0');
		}
		// -----------------------------------------------------------------------------

		warehouseData.carriers[carrier_id][dock][time] = newTime;
		this.setState({ warehouseData });
	}



	render() {
		const { classes } = this.props;
		const  { warehouseAssignments, warehouseID, warehouseData } = this.state;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} className={classes.right} style={{ marginTop: "30px" }}>
					<SnackbarContent
						message={"NOTE - All times should be in 24-hour format!"}
						color="info"
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Card style={{ marginTop: "15px" }}>
						<CardBody>
							<GridContainer style={{ marginBottom: "15px" }}>
								<GridItem xs={1.5}>
									<Button
										className={classes.button}
										onClick={this.handleSave}
										disabled={this.state.disabled}
										color="linkedin"
									>
										{this.state.disabled && this.state.show?
											<CircularProgress className={classes.circularProgress} size={15} color="white" style={{minWidth: '29.583px', minHeight: '17.129px'}}/>
											: 'Save'}
									</Button>
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<FormControl
										fullWidth
										className={
											classes.selectFormControl
										}
										disabled={this.state.disabled}
									>
										<InputLabel
											htmlFor="warehouseData"
											className={classes.selectLabel}
										>
											Select Warehouse
										</InputLabel>
										<Select
											MenuProps={{
												className:
													classes.selectMenu
											}}
											classes={{
												select:
													classes.select +
													" " +
													classes.requiredSelect
											}}
											value={warehouseID}
											onChange={this.handleChangeWarehouse}
										>
											{this.renderDropDown(warehouseAssignments)}
										</Select>
									</FormControl>
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(warehouseData) ? classes.left : classes.center}
									>
										{!_.isEmpty(warehouseData) ? (
											<Table
												tableHead={[
													"SCAC",
													"Carrier",
													"Dock Ready",
													"Dock Close"
												]}
												tableData={this.renderTable(warehouseData.carriers)}
											/>
										) : (
											<Badge color="info">No Pickup Times</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message={_.isEmpty(this.state.warehouseAssignments) ? "No pickup times found" : "Failed to retrieve pickup times from the server"}
									/>
								)}
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
	renderDropDown(warehouseAssignments) {
		const { classes } = this.props;
		return Object.entries(warehouseAssignments).map(([id, name]) => {
			return (
				<MenuItem
					key={"wh_sel_" + id}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={id}
				>
					{name}
				</MenuItem>
			);
		});
	}
	renderTable(warehouseCarriers) {
		let carriersArray = Object.values(warehouseCarriers);
		const disabled = this.state.disabled;

		return carriersArray.sort((a, b) => a.carrier.localeCompare(b.carrier)).map(c => {
			return [
				c.scac,
				c.carrier,
				<GridContainer>
					<GridItem xs={3}>
						<CustomInput
							disabled={true}
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								type: "text",
								name: "ready_hour",
								value: c.ready.hour || "",
								placeholder: "HH",
								onChange: e =>
									this.handleChangeTime(
										c.carrier_id,
										"ready",
										"hour",
										e
									),
								className: disabled ? 'Mui-disabled' : undefined,
								readOnly: disabled
							}}
							white
						/>
					</GridItem>
					<GridItem xs={3}>
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "ready_minute",
								value: c.ready.minute || "",
								placeholder: "MM",
								onChange: e =>
									this.handleChangeTime(
										c.carrier_id,
										"ready",
										"minute",
										e
									),
									className: disabled ? 'Mui-disabled' : undefined,
									readOnly: disabled
							}}
							white
						/>
					</GridItem>
				</GridContainer>,
				<GridContainer>
					<GridItem xs={3}>
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "close_hour",
								value: c.close.hour || "",
								placeholder: "HH",
								onChange: e =>
									this.handleChangeTime(
										c.carrier_id,
										"close",
										"hour",
										e
									),
									className: disabled ? 'Mui-disabled' : undefined,
									readOnly: disabled
							}}
							white
						/>
					</GridItem>
					<GridItem xs={3}>
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "close_minute",
								value: c.close.minute || "",
								placeholder: "MM",
								onChange: e =>
									this.handleChangeTime(
										c.carrier_id,
										"close",
										"minute",
										e
									),
									className: disabled ? 'Mui-disabled' : undefined,
									readOnly: disabled
							}}
							white
						/>
					</GridItem>
				</GridContainer>
			];
		});
	}



}

export default withStyles(archivedBillsOfLadingStyle)(
	withSnackbar(PickupTimes)
);