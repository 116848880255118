import axios from '../../../variables/axios.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import { basePath, apiUrl } from '../../../variables/server.jsx';
import qs from "qs";

const setIntlShipmentId = (id) => {
	return {type: "SET_INTL_NOTE_SHIPMENT_ID", id};
}

const setIntlNotesModalOpen = (val) => {
	return {type: "SET_INTL_NOTES_MODAL_OPEN", val};
}

const setIntlNotes = (notes) => {
	return {type: "SET_INTL_NOTES", notes};
}

const setIntlNotesLoading = (val) => {
	return {type: "SET_INTL_NOTES_LOADING", val};
}

const setIntlNotesSaveLoading = (val) => {
	return {type: "SET_INTL_NOTES_SAVE_LOADING", val};
}

const setIntlNotesDeleteLoading = (val) => {
	return {type: "SET_INTL_NOTES_DELETE_LOADING", val};
}

export const setNewNoteMessage = (message) => {
	return {type: "SET_NEW_INTL_NOTE_MESSAGE", message};
}

const setEditingNotes = (val) => {
	return {type: "SET_INTL_NOTES_EDITING_NOTES", val};
}

export const closeModal = () => {
	return async (dispatch) => {
		dispatch(setIntlNotesModalOpen(false));
	}
}

export const getIntlNotes = (id) => {
	return async (dispatch) => {
		dispatch(setIntlShipmentId(id));
		dispatch(setIntlNotesModalOpen(true));
		dispatch(setIntlNotesLoading(true));
		try {
			const url = `index.php?p=api&r=json&c=internationalNotes&m=collection&orderBy[]=id-&filters[intl_shipment_id]=${id}&limit=999`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body) {
				const notes = Array.isArray(response.data.body.response) ? response.data.body.response : [];
				dispatch(setIntlNotes(notes));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching International notes.", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setIntlNotesLoading(false));
		}
	}
}

export const saveIntlNote = (id = null, message = "", internal = false) => {
	return async (dispatch, getState) => {
		dispatch(setIntlNotesSaveLoading(true));
		const state = getState();
		try {
			const payload = id 
				? qs.stringify({message}) 
				: qs.stringify({
					intl_shipment_id: state.InternationalNotes.intlShipId,
					message,
					internal: internal ? 1 : 0,
				});
			let url = `index.php?p=api&r=json&c=internationalNotes&m=save`;
			if(id) {
				url += `&id=${id}`;
			}
			const response = await axios.post(url, payload);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				let notes = [...state.InternationalNotes.notes];
				if(id) {
					notes.map((note, index) => {
						if (+note.id === +id) {
							notes[index].message = message;
						}
					});
					dispatch(stopEditingNote(id));
				} else {
					notes.unshift(response.data.body.response);
					dispatch(setNewNoteMessage(""));	
				}
				dispatch(setIntlNotes(notes));
			}
		} catch (err) {
			dispatch(setNotification("Error saving International Shipment note", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setIntlNotesSaveLoading(false));
		}
	}
}

export const deleteIntlNote = (id) => {
	return async (dispatch, getState) => {
		dispatch(setIntlNotesDeleteLoading(true));
		try {
			const url = `index.php?p=api&r=json&c=internationalNotes&m=delete&id=${id}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.deleted) {
				const state = getState();
				let notes = [...state.InternationalNotes.notes];
				dispatch(setIntlNotes(notes.filter(note => {
					return +note.id !== +id;
				})));
			}
		} catch (err) {
			dispatch(setNotification("Error deleting International Shipment note", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setIntlNotesDeleteLoading(false));
		}
	}
}

export const setEditNoteValue = (id, value) => {
	return (dispatch, getState) => {
		const state = getState();
		let editNotes = {...state.InternationalNotes.editingNotes};
		if (Object.keys(editNotes).indexOf(id) !== -1) {
			editNotes[id].message = value;
			dispatch(setEditingNotes(editNotes));
		}
	}
}

export const editIntlNote = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		let editNotes = {...state.InternationalNotes.editingNotes};
		let notes = [...state.InternationalNotes.notes];
		if (Object.keys(editNotes).indexOf(id) === -1) {
			notes.map(note => {
				if (+note.id === +id) {
					editNotes[id] = {
						message: note.message,
					};
				}
			});
			dispatch(setEditingNotes(editNotes));
		}
	}
}

export const stopEditingNote = (id) => {
	return (dispatch, getState) => {
		const state = getState();
		let editNotes = {...state.InternationalNotes.editingNotes};
		if (Object.keys(editNotes).indexOf(id) !== -1) {
			delete(editNotes[id]);
			dispatch(setEditingNotes(editNotes));
		}
	}
}