import React, { Component, Fragment } from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import qs from "qs";
import { basePath } from "~/variables/server.jsx";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import "../components/CannedReporting.css";
import newPermissionGroupStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";

import { Loader } from "@progress/kendo-react-indicators";

import { GridPDFExport } from "@progress/kendo-react-pdf";

// lib
import utils from '~/lib/utils.js'

class RunReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: true,
      reportId: this.props.match.params.reportId,
      isHistory:
        this.props.location.state != null
          ? this.props.location.state.isHistory
          : false,
      reportName: null,
      dataResult: null,
      columns: [],
      data: [],
      pageNum: 1,
      totalCount: 0,
      summary: null,
      aggregates: null,
      classcharge: "",
      skip: 0,
      take: 25,
      sort: null,
      reportBehaviour: null,
      aliasMap: [],
      dataState: {
        skip: 0,
        take: 25,
      },
    };
    this.replaceStr = this.replaceStr.bind(this);
    this.formatColumn = this.formatColumn.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.editReport = this.editReport.bind(this);
  }

  async componentDidMount() {
    await this.runReport(this.state.reportId).then((res) => {
      this.setState({
        dataResult: process(res, this.state.dataState),
      });
    });

    localStorage.setItem("NewReportRun", true);
  }

  /* --- Helper Functions --- */

  replaceStr = (str, find, replace) => {
    for (var i = 0; i < find.length; i++) {
      str = str.replace(new RegExp(find[i], "gi"), replace[i]);
    }
    return str;
  }

  formatColumn = (column) => {
    column = column.toString().trim();

    var formatted = {
      title:
        column.charAt(0).toUpperCase() +
        this.replaceStr(column, ["#", "_"], ["", " "])
          .slice(1)
          .trim(),
      field: column.trim(),
    };
    return formatted;
  }

  /* --- API Calls --- */

  async runReport(reportId, skip = 1, rowCount = 25) {
    this.setState({ initialized: false });

    let pageNum = 1;
    if (skip >= rowCount) {
      pageNum = skip / rowCount + 1;
    }

    const data = { history: this.state.isHistory };
    let url =
      "/index.php?p=api&r=json&c=customReporting&m=runReport&d=" +
      reportId +
      "/" +
      pageNum +
      "/" +
      rowCount;

    try {
      const response = this.state.isHistory
        ? await axios.post(url, qs.stringify(data), { timeout: 120000})
        : await axios.get(url, { timeout: 120000});

      if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
        this.props.handleMessages(response);
      }

      if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.result)) {
        const report = response.data.body;

        if (report.result[0] == "42000") { // needs better error checking
          throw new Error("Error loading data from reporting server");
        }

        const groupings = report.grouping ? report.grouping.split(",") : [];
        const columns = report.headers;
        const summary = report.summary;

        var formattedColumns = [];
        var aggregates = [];

        if (report.report_behaviour == "Summary") {
          formattedColumns = groupings.map(this.formatColumn);

          summary.map((i) => {
            var item =  i.type + "_" + i.column;
            var title =
              "Total " +
              i.column.charAt(0).toUpperCase() +
              this.replaceStr(i.column, ["#", "_"], ["", " "])
                .slice(1)
                .trim();

            formattedColumns.push({
              title: this.state.aliasMap[item] ? this.state.aliasMap[item] : item,
              field: item,
            });

            aggregates.push({
              field: i.column,
              aggregate: i.type,
            });
          });
        } else if (report.report_behaviour == "Detail") {
          formattedColumns = columns.map(this.formatColumn);
        }

        var temp = report.remark;

        this.setState({
          wizard: report.wizard,
          columns: formattedColumns,
          reportType: report.type,
          reportName:
            temp == "temp" || !_.isEmpty(temp)
              ? report.name
              : report.type +
                " " +
                report.report_behaviour +
                " " +
                report.name,
          summary: report.summary,
          reportBehaviour: report.report_behaviour,
          classcharge: report.classcharges,
          aggregates: aggregates,
          data: report.result,
          totalCount: report.last,
          initialized: true,
        });

        return report.result;
      } else {
        this.setState({
          data: [],
          totalCount: 0,
          initialized: true,
          loading: false,
        });

        return [];
      }
    } catch (error) {
      console.log("error", error);
      this.setState({
        data: [],
        totalCount: 0,
        initialized: true,
        loading: false,
      });

      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });

      return [];
    }
  }

  async editReport() {
    try {
      this.props.history.push(
        basePath +
          "/admin/report-build/" +
          this.state.reportId
      );
    } catch (e) {
      console.error(e);
    }
  }


  /* --- Exporting  --- */

  exportCsv = () => {
    this.downloadReport(this.state.reportId, 'csv');
  };
  exportExcel = () => {
    this.downloadReport(this.state.reportId, 'xls')
  };
  exportPDF = () => {
    this.downloadReport(this.state.reportId, 'pdf')
  };

  downloadReport = (history_id, format) => {
    window.open(
      basePath +
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=downloadScheduledReportDefinitions&d=" +
        history_id +
        "/" +
        format + "/1"
    );
  }

  dataPageChange = (event) => {
    this.setState(
      {
        isHistory: true,
        skip: event.page.skip,
        take: event.page.take,
        dataState: {
          skip: 0,
          take: event.page.take,
        },
      },
      () => {
        this.runReport(
          this.state.reportId,
          event.page.skip,
          event.page.take
        ).then((res) => {
          this.setState({
            dataResult: process(res, this.state.dataState),
          });
        });
      }
    );
  };

  dataStateChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
    this.runReport(this.state.reportId, event.page.skip, event.page.take).then(
      (res) => {
        this.setState({
          dataResult: process(res, this.state.dataState),
          dataState: event.dataState,
        });
      }
    );
  };

  cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupFooter") {
      var summary = this.state.summary;
      for (let index = 0; index < summary.length; index++) {
        const element = summary[index];
        var col = element.column;
        var type = element.type;

        if (cellProps.field === element.column) {
          var repl = cellProps.dataItem.aggregates;
          var repll = col + "." + type;
          var final = _.get(repl, repll);
          return (
            <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {type.toUpperCase()}: {final}
            </td>
          );
        }
      }
    }

    return tdElement;
  };
  
  toTitleCase = (text) => {
    let result = text.replace(/([A-Z])/g, " $1");
    result = text.replaceAll("_"," ");
    let spacePosition = 0;
    while(spacePosition >= 0) {
      let tmpStr = "";
      if(spacePosition > 0) {
        tmpStr = result.slice(0, spacePosition);
      }
      result = tmpStr + result.charAt(spacePosition).toUpperCase() + result.slice(spacePosition + 1);
      spacePosition = result.indexOf(" ", spacePosition + 1);
      if(spacePosition !== -1) {
        spacePosition++;
      }
    }
    return result;
  }

  render() {
    const _export = null;
    const _pdfExport = null;
    const { classes } = this.props;

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} style={{ paddingTop: "10px" }}>
            <ExcelExport
              data={this.state.data}
              fileName={this.state.reportName}
              ref={(exporter) => {
                this._export = exporter;
              }}
            >
              <Grid
                style={{
                  height: "666px",
                  fontSize: "12px",
                  lineHeight: "0px",
                }}
                className={!this.state.initialized ? "k-state-disabled" : ""}
                reorderable={true}
                pageable={{
                  buttonCount: 0,
                  info: false,
                  type: "numeric",
                  pageSizes: [10, 25, 50, 100],
                  previousNext: true,
                }}
                pager={Pager}
                rowHeight={.33}
                sort={this.state.sort}
                data={this.state.dataResult}
                total={this.state.totalCount}
                skip={this.state.skip}
                take={this.state.take}
                pageSize={this.state.take}
                onPageChange={this.dataPageChange}
                onDataStateChange={this.dataStateChange}
              >
                <GridToolbar>
                  <div>
                    <b>{this.state.reportName}</b>
                  </div>

                  <div style={{ paddingLeft: "40%" }}>
                    {this.state.reportName != null &&
                    this.state.reportBehaviour == "Summary" ? (
                      <div>
                        <button
                          title="Edit report"
                          className="k-button k-primary"
                          onClick={this.editReport}
                        >
                          Edit Report
                        </button>
                        &nbsp;
                        <button
                          title="Export to CSV"
                          className="k-button k-primary"
                          onClick={this.exportCsv}
                        >
                          Export to CSV
                        </button>
                        &nbsp;
                        <button
                          className="k-button k-primary"
                          onClick={this.exportPDF}
                        >
                          Export to PDF
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          title="Edit report"
                          className="k-button k-primary"
                          onClick={this.editReport}
                        >
                          Edit Report
                        </button>
                        &nbsp;
                        <button
                          title="Export to CSV"
                          className="k-button k-primary"
                          onClick={this.exportCsv}
                        >
                          Export to CSV
                        </button>
                      </div>
                    )}
                  </div>
                </GridToolbar>
                {this.state.columns.map((col, index) => {
                  return (
                    <GridColumn
                      key={index}
                      field={col.field}
                      title={this.toTitleCase(col.title)}
                      width="200px"
                      cell={NumberCell}
                      headerCell={GridHeaderCell}
                    />
                  );
                })}
              </Grid>
            </ExcelExport>
            <GridPDFExport
              data={this.state.data}
              ref={(element) => {
                this._pdfExport = element;
              }}
              margin="1cm"
              fileName={this.state.reportName}
            >
              <Grid
                style={{
                  height: "500px",
                }}
                className={!this.state.initialized ? "k-state-disabled" : ""}
                sortable={true}
                groupable={{
                  footer: "visible",
                }}
                reorderable={true}
                pageable={{
                  buttonCount: 10,
                  pageSizes: true,
                }}
                data={this.state.dataResult}
                {...this.state.dataState}
                total={this.state.totalCount}
                skip={this.state.skip}
                take={this.state.take}
                onPageChange={this.dataPageChange}
                onDataStateChange={this.dataStateChange}
              >
                <GridToolbar>
                  <label htmlFor="reportName">{this.state.reportName}</label>
                  <button
                    title="Export to CSV"
                    className="k-button k-primary"
                    onClick={this.exportCsv}
                  >
                    Export to CSV
                  </button>
                  &nbsp;
                  <button
                    className="k-button k-primary"
                    onClick={this.exportPDF}
                  >
                    Export to PDF
                  </button>
                </GridToolbar>
                {this.state.columns.map((col, index) => {
                  return (
                    <GridColumn
                      key={index}
                      field={col.field}
                      title={col.title}
                      width="200px"
                    />
                  );
                })}
              </Grid>
            </GridPDFExport>
            {!this.state.initialized && (
              <Loader
                size={"large"}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
                type={"infinite-spinner"}
              />
            )}
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

const GridHeaderCell = (props) => (
  <React.Fragment>
    <span className="k-cell-inner">
      <span className="k-link" onClick={props.onClick}>
          {props.title}
          {props.children}
      </span>
    </span>
  </React.Fragment>
);

const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>
        {" "}
        {props.dataItem[props.field]}
      </td>
    );
};

const Pager = (props) => {
  const currentPage = Math.floor(props.skip / props.take) + 1;
  const changePage = (e, skip = props.skip, take = props.take) => {
    if (props.onPageChange) {
      props.onPageChange({
        target: e.target,
        skip: skip,
        take: take,
        syntheticEvent: e,
        nativeEvent: e.nativeEvent,
      });
    }
  };

  const nextPage = (e) => {
    changePage(e, props.skip + props.take, props.take);
  };

  const prevPage = (e) => {
    changePage(e, props.skip - props.take, props.take);
  };

  const updateTake = (e) => {
    changePage(e, props.skip, e.value);
  };

  return (
    <GridContainer style={{
      borderTop: "1px solid",
      borderTopColor: "inherit",
      width: "100%",
      margin: "0"
    }}>
      <GridItem xs={6}>
        <div className={"k-pager k-pager-md k-grid-pager"}>
          <button title="Previous"
            className="k-button k-button-icontext k-pager-nav"
            onClick={prevPage}
            disabled={props.skip === 0}
          ><span className="k-icon k-i-arrow-60-left" /></button>
          <span className="k-label" style={{ paddingRight: "10px" }}>
            Page
          </span>
          <NumericTextBox title="Page"
            value={currentPage} spinners={false} step={1} width={25}
            onChange={e => changePage(e, (e.value - 1) * props.take)} />
          <button title="Next"
            className="k-button k-button-icontext k-pager-nav"
            onClick={nextPage}
            disabled={props.total <= props.skip + props.take}
          ><span className="k-icon k-i-arrow-60-right" /></button>
        </div>
      </GridItem>
      <GridItem xs={6}>
        <DropDownList style={{width: "50px"}} data={props.pageSizes} value={props.take} onChange={updateTake} />
        <span className="k-label">
          per page
        </span>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(newPermissionGroupStyle)(
  withSnackbar(RunReportContainer)
);
