import React, { useState, useEffect } from "react";

import axios from 'axios';
import qs from "qs";

import { useDispatch } from "react-redux";
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

import { baseUrl, assetPath } from "~/variables/server.jsx";

import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '~/three/OrbitControls.jsx';

import jsPDF from 'jspdf'
import html2canvas from "html2canvas";

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button, Dialog, DialogContent, DialogActions, InputAdornment, CircularProgress } from '@material-ui/core';
import { ThreeDRotation as ThreeDRotationIcon, Close as CloseIcon } from '@material-ui/icons';

import Transition from "~/components/TMS/ModalTransition.jsx";

import Trailer from "./components/Trailer.jsx";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { brandColors } from "assets/jss/empire-tms.jsx";

export default function LnftLoadPlanModal(props){

	const dispatch = useDispatch();
	const useStyles = makeStyles(ltlQuoteStyle);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [serviceResponse, setServiceResponse] = useState("");
	const [loadPlanResult, setResult] = useState("");
	const [rotation, setRotation] = useState([0, Math.PI/2 ,0]);
	const [screenCapture, setCapture] = useState(false);
	const [printScreen, startPrint] = useState(false);
	const [source, setSource] = useState(axios.CancelToken.source())
	const classes = useStyles();

	useEffect(() => {
		if(screenCapture) {
			startPrint(true);
		}
	}, [screenCapture]);

	useEffect(() => {
		if(printScreen && screenCapture) {
			printPdf();
			startPrint(false);
		}
	}, [printScreen]);

	const handleOpen = async () => {
		if(validateRequest()) {
			calculateLnft();
		}
	}

	const handleClose = () => {
		source && source.cancel('Operation canceled.');
		setSource(axios.CancelToken.source())
		setOpen(false);
		setServiceResponse("");
		setResult("");
	}

	const printPdf = async () => {
		await html2canvas(document.querySelector("#stage")).then(canvas => {
			const imgData = canvas.toDataURL('image/jpeg', 1.0);
			const pdf = new jsPDF("l", "mm", "a4");
			pdf.addImage(imgData, 'JPEG', 0, 0);
			pdf.save("lft_img.pdf");
		}).finally(() => setCapture(false))
	}

	const calculateLnft = async () => {
		setLoading(true);
		setOpen(true);
		try {
			const post = qs.stringify({units: props.units, company: props.company});
			const response = await axios.post('/index.php?p=api&r=json&c=admin&m=lnftCalc', post, {cancelToken: source.token});
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				dispatch(handleMessages(response));
			}

			if(response.data.body.service_resp) {
				setServiceResponse(response.data.body.service_resp || "");
				setResult(response.data.body.result);
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				dispatch(setNotification("Error submitting linear feet calculation request.", {
					variant: "error"
				}));
			}
		}
		setLoading(false);
	}

	const validateRequest = () => {
		let valid = true;
		if(!props.units.length) {
			dispatch(setNotification("No handling units provided.", {
				variant: "info"
			}));
			valid = false;
		}

		props.units.forEach(unit => {
			const l = unit.length;
			const w = unit.width;
			const h = unit.height;
			if((isNaN(l) || l <= 0) || (isNaN(w) || w <= 0) || (isNaN(h) || h <= 0)) {
				dispatch(setNotification("One or more invalid unit dimension(s) provided.", {
					variant: "info"
				}));
				valid = false;
			}
		});
		return valid;
	}

	const resp = serviceResponse === "" ? false : JSON.parse(serviceResponse);
	const tfmBackground = baseUrl + assetPath + "/img/target_background.png";
	return(
		<span>
			{props.units.length && (
				<IconButton onClick={handleOpen} component="span" disabled={props.reqLoading}>
					{props.reqLoading ? (
						 <CircularProgress style={{ color: "grey" }} size={"1.25rem"}/>
					) : (
						<ThreeDRotationIcon fontSize="small"/>
					)}
				</IconButton>
			)}
			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				maxWidth="xl"
			>
				<DialogContent>
					<GridContainer>
						<GridItem xs={12} lg={9} id="stage" style={{ border: '1px solid black', width: "1080px", height: "720px", backgroundColor: "grey", backgroundImage: screenCapture ? null : `url(${tfmBackground})`, backgroundSize: 'cover' }} >
							{loading ? (
								<GridContainer style={{ height: 'inherit', alignItems: 'center' }}>
									<GridItem xs={12} className={classes.center}>
										<CircularProgress style={{ color: brandColors.empireOrange }} size={"5rem"}/>
									</GridItem>
								</GridContainer>
							) : (
								<Canvas flat linear gl={{ preserveDrawingBuffer: true, antialias: true}}>
									<OrbitControls
										makeDefault 
										position={[10, 5, 10]} 
										target={[0, 0, 0]}
										minPolarAngle={0}
										maxPolarAngle={Math.PI * 0.5}
										minAzimuthAngle={0}
										maxAzimuthAngle={Math.PI}
										minDistance={1}
										maxDistance={6}
									/>
									<ambientLight intensity={Math.PI} />
									{resp ? (
										<Trailer
											serviceResponse={resp}
											result={loadPlanResult}
											rotation={rotation}
											screenCapture={screenCapture}
										/>
									) : null}
								</Canvas>
							)}
						</GridItem>
						<GridItem xs={12} lg={3}>
							{props.units.map((unit, idx) => {
								return(
									<GridContainer className={classes.unitWrapper} key={idx} style={{padding: "1px"}}>
										<GridItem xs={12} className={classes.unit}>
											<GridContainer>
												<GridItem xs={12} className={classes.unitHeader}>UNIT # {idx+1}</GridItem>
												<GridItem xs={12} sm={3}>
													Quantity
													<CustomInput
														formControlProps={{ fullWidth: true }}
														inputProps={{
															value: unit.pieces,
															disabled: true,
														}}
														required
													/>
												</GridItem>
												<GridItem xs={12} sm={3}>
													Length
													<CustomInput
														formControlProps={{ fullWidth: true }}
														inputProps={{
															value: unit.length,
															disabled: true,
															endAdornment: <InputAdornment position="end">in.</InputAdornment>,
														}}
														required
													/>
												</GridItem>
												<GridItem xs={12} sm={3}>
													Width
													<CustomInput
														formControlProps={{ fullWidth: true }}
														inputProps={{
															value: unit.width,
															disabled: true,
															endAdornment: <InputAdornment position="end">in.</InputAdornment>,
														}}
														required
													/>
												</GridItem>
												<GridItem xs={12} sm={3}>
													Height
													<CustomInput
														formControlProps={{ fullWidth: true }}
														inputProps={{
															value: unit.height,
															disabled: true,
															endAdornment: <InputAdornment position="end">in.</InputAdornment>,
														}}
														required
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								)
							})}
						</GridItem>
					</GridContainer>
					<DialogActions>
						<Button onClick={handleClose} >Close</Button>
						<Button onClick={() => setCapture(true)}>Print Screen</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</span>
	);
};