import React from "react";

import "formdata-polyfill";

import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ShipmentSearchCard extends React.Component {

	getWarehouses(warehouses) {
        return warehouses.map((warehouse, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: this.props.classes.selectMenuItem,
                        selected: this.props.classes.selectMenuItemSelected
                    }}
                    value={warehouse.code}
                >
                    {warehouse.name}
                </MenuItem>
            );
        });
    }

    getSearchByOptions() {
    	const classes = this.props.classes || {};
    	const options = this.props.searchByOptions
    	return options.map((option, key) => {
            return (
		    	<MenuItem
	            	key={key}
		            classes={{
		                root: classes.selectMenuItem,
		                selected: classes.selectMenuItemSelected
		            }}
		            value={option.val}
		        >
		            {option.display}
		        </MenuItem>
            );
        });
    }

    render() {
        const classes = this.props.classes || {};
        return (
            <Card>
                <CardBody>
                    <h6>Search</h6>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Grid container>
                                <Grid item xs style={{ paddingRight: "10px" }}>
                                    <FormControl className={classes.selectFormControl} fullWidth>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            value={this.props.searchBy || ""}
                                            inputProps={{
                                                name: this.props.searchById,
                                                id: this.props.searchById
                                            }}
                                            onChange={this.props.onChangeSelect}
                                        >
                                            {this.getSearchByOptions()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs style={{ paddingTop: "1px" }}>
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            id: this.props.searchId,
                                            name: this.props.searchId,
                                            type: "text",
                                            value: this.props.search || "",
                                            onChange: this.props.onChangeSearch,
                                            onKeyPress: this.props.onKeyPressSearch
                                        }}
                                        white
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button justIcon round color="linkedin" className={classes.marginLeft} onClick={this.props.onSearch}>
                                        <Search />
                                    </Button>
                                </Grid>
                            </Grid>
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                            <h6>Entry Information</h6>
                            <Grid container>
                                <Grid item xs={12} className={classes.left}>
                                    {this.props.shipmentIdName}: <strong>{this.props.savedShipmentId || "N/A"}</strong>
                                </Grid>
                                <Grid item xs={12} className={classes.left}>
                                    Status: {!_.isEmpty(this.props.status) ? <Badge color="gray">{this.props.status}</Badge> : "N/A"}
                                </Grid>
                            </Grid>
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                            <h6>Profile Information</h6>
                            <InputLabel htmlFor="warehouse" className={classes.label}>
                                Warehouse
                            </InputLabel>
                            <br />
                            <FormControl fullWidth className={classes.selectFormControl}>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{ select: this.props.selectClasses }}
                                    value={this.props.warehouse || ""}
                                    inputProps={{
                                        name: "warehouse",
                                        id: "warehouse"
                                    }}
                                    onChange={this.props.onChangeSelect}
                                    disabled={this.props.executed}
                                >
                                    {!_.isEmpty(this.props.warehouses) ? (
                                        this.getWarehouses(this.props.warehouses)
                                    ) : (
                                        <MenuItem
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            No Warehouses
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(style)(ShipmentSearchCard);