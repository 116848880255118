import React from "react";

import _ from "lodash";
import axios from "~/variables/axios.jsx";

import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import FileCopy from "@material-ui/icons/FileCopy";
import FastRewind from "@material-ui/icons/FastRewind";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class LaneSplits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: null,
			id: null,
			companyId: null,
			mounted: false,
			copySplitModal: false,
			alert: null,
			show: false,
			loading: true,
			user: null,
			body: null,
			split_applied: null,
			split_id: null,
			company: null,
			copy_to_split: false,
			lanes: [],
			available: []
		};
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.getHeaders = this.getHeaders.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.copyToSplit = this.copyToSplit.bind(this);
		this.deleteLaneSplit = this.deleteLaneSplit.bind(this);
		this.getCopyModal = this.getCopyModal.bind(this);
		this.getCopyModalOptions = this.getCopyModalOptions.bind(this);

	}

	async componentDidMount() {

		this.setState({
			mounted: true,
			id: this.props.match.params.id,
			companyId: this.props.match.params.company,
		});

		const url = "/index.php?p=api&r=json&c=admin&m=companyLaneSplits&d=" + this.props.match.params.company + "/" + this.props.match.params.id;

		try {
			const response = await axios.get(url);
			const data = response.data;

			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			this.props.pageTitle('Lane Splits - ' + data.body.company.name);
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					company: data.body.company,
					companyId: this.props.match.params.company,
					id: this.props.match.params.id,
					lanes: data.body.lanes,
					split_applied: data.body.split_applied,
					split_id: data.body.split_id,
				});

			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}

		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", { variant: "error" });
		}

		// Get the available splits for copying
		const availableUrl = '/index.php?p=api&r=json&c=admin&m=getCompanySplits&d=' + this.state.companyId;

		try{

			const availableResponse = await axios.get(availableUrl);

			let splits = availableResponse.data.body;

			var available = [];

			if(splits !== false) {

				// Filter out the results that have already been applied or that are the same as the current split
				for (var i = 0; i < splits.length; i++) {

					if(splits[i].applied == null && splits[i].id != this.state.id) {
						available.push(splits[i]);
					}

				}

			} else{

				this.props.setNotification("An unexpected error occurred checking for available splits to copy to!", { variant: "error" });

			}

			this.setState({
				available: available
			});


		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error checking for available splits to copy to!", { variant: "error" });
			return false;
		}

	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	getHeaders() {
		return ["ID","Calc Value","Min Profit Margin","Passthru","Origin","Destination"];
	}

	getColumns(data) {

		const { classes } = this.props;

		const link = basePath + "/admin/company-" + this.state.company.company_name_id.toString() + "/edit-lane-split/" + this.state.id.toString() + "/";

		return data.map((prop, key) => {
			return [
				prop.laneId,
				prop.calcVal,
				prop.minProfit,
				prop.passthru,
				prop.origin,
				prop.destination,
				<div className={classes.right}>
					{this.state.split_applied === false && (
						<NavLink to={link + prop.laneId.toString()}>
							<Button size="sm" color="info" className={classes.marginRight}>
								Edit
							</Button>
						</NavLink>
					)}
					{this.state.split_applied === false && (
						<Button size="sm" color="danger" onClick={() => {this.deleteLaneSplit(prop.laneId)}}>
							Delete
						</Button>
					)}
				</div>
			];
		});

	}

	getCopyModalOptions(data) {

		const { classes } = this.props;

		return data.map((prop, key) => {
			return [
				<MenuItem
					classes={{
						root: classes.selectMenuItem,
					}}
					value={prop.id}
				>
					{prop.date} - {prop.newCalcType} - {prop.newSplit}
				</MenuItem>
			];
		});

	}

	getCopyModal(data) {

		const { classes } = this.props;

		if(data.length < 1) {

			return (
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    There are no other splits for this company that have not been applied.
                </DialogContent>
			);

		} else{

			return (
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                	<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={8} sm={8} md={8}>
				                	<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											inputProps={{
												name: "copy_to_split",
												id: "copy_to_split",
												value: this.state.copy_to_split || "false",
												onChange: this.handleInput("copy_to_split")
											}}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
												}}
												value="false"
											>
												Select a Split to Copy To
											</MenuItem>
											{this.getCopyModalOptions(data)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={4} sm={4} md={4}>
				                	<Button
				                		size="sm"
										color="linkedin"
										onClick={this.copyToSplit}
									>
										Copy
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
                </DialogContent>
            );

		}

	}

	async copyToSplit() {

		if(this.state.copy_to_split === false) {
			this.props.setNotification("You must select a split to copy to!", { variant: "warning" });
			return;
		}

		const url = '/index.php?p=api&r=json&c=admin&m=copyLaneSplits&d=' + this.state.id + '/' + this.state.copy_to_split + '/' + this.state.companyId;

		try {

			const response = await axios.get(url);

			if (typeof response.data !== "string") {

				this.props.handleMessages(response);

				let result = response.data.body;

				if (result === false) {
					return; // Server will set and handle the message
				}

				this.setState({
					copy_to_split: false,
					copySplitModal: false
				});

			} else {

				this.props.setNotification("Received unexpected response from the server while copying split!", { variant: "error" });

			}

		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error copying to split!", { variant: "error" });
		}
	}

	async deleteLaneSplit(id) {

		try {

			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=deleteCompanyLaneSplit&record=" + id,
			);

			const data = response.data;

			if (data.body.result === true) {

				this.props.handleMessages(response);

				const url = "/index.php?p=api&r=json&c=admin&m=companyLaneSplits&d=" + this.state.companyId + "/" + this.state.id;

				try {
					const reloadResp = await axios.get(url);
					const reloadData = reloadResp.data;

					if (typeof reloadData !== "string" && !_.isEmpty(reloadData.message)) {
						this.props.handleMessages(reloadData.message);
					}

					if (typeof reloadData !== "string" && !_.isEmpty(reloadData.body) && !_.isEmpty(reloadData.user)) {
						this.setState({
							show: true,
							loading: false,
							user: reloadData.user,
							body: reloadData.body,
							company: reloadData.body.company,
							lanes: reloadData.body.lanes,
							split_applied: reloadData.body.split_applied,
							split_id: reloadData.body.split_id,
						});

					} else {
						this.setState({ loading: false });
						this.props.setNotification("There was an error re-loading the data!", { variant: "error" });
					}

				} catch (error) {
					console.error(error);
					this.setState({ loading: false });
					this.props.setNotification("There was an error re-loading the data!", { variant: "error" });
				}

			} else {

				this.setState({ loading: false });
				this.props.setNotification(
					"Unable to delete the lane split!", { variant: "error" }
				);

			}

		} catch (error) {

			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error deleting the lane split!", { variant: "error" }
			);

		}

	}

	render() {

		const { classes } = this.props;

		const newLink = basePath + "/admin/company-" + this.state.companyId + "/edit-lane-split/" + this.state.id + "/";

		return (
			<GridContainer>
				{!_.isEmpty(this.state.companyId) && (
					<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
						<NavLink to={basePath + "/admin/company/" + this.state.companyId}>
							<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
								<FastRewind /> Back to Company
							</Button>
						</NavLink>
						<Button size="lg" color="white" onClick={() => this.handleModalOpen("copySplitModal")}>
							<FileCopy /> Copy to Split
						</Button>
					</GridItem>
				)}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader>
							{this.state.split_applied === false && (
								<NavLink to={newLink}>
									<Button color="linkedin" className={classes.marginRight}>
										New Split
									</Button>
								</NavLink>
							)}
						</CardHeader>
						<CardBody>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} sm={12} md={12} className={classes.center}>
										{!_.isEmpty(this.state.lanes) ? <Table tableHead={this.getHeaders()} tableData={this.getColumns(this.state.lanes)} /> : <Badge color="info">No Lane Splits</Badge>}
									</Grid>
								) : (
									<Spinner loading={this.state.loading} message="Failed to retrieve lane splits from the server" />
								)}
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Dialog
		                classes={{
		                    root: classes.center + " " + classes.modalRoot,
		                    paper: classes.modal + " " + classes.modalMedium
		                }}
		                open={this.state.copySplitModal}
		                keepMounted
		                onClose={() => this.handleModalClose("copySplitModal")}
		                aria-labelledby="classic-modal-slide-title"
		                aria-describedby="classic-modal-slide-description"
		            >
		                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
		                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("copySplitModal")}>
		                        <Close className={classes.modalClose} />
		                    </Button>
		                    <h4 className={classes.modalTitle}>Copy Lanes to Split</h4>
		                </DialogTitle>
						{this.getCopyModal(this.state.available)}
						<DialogActions className={classes.modalFooter}>
		                    <Button onClick={() => this.handleModalClose("copySplitModal")} color="white">
		                        Close
		                    </Button>
		                </DialogActions>
		            </Dialog>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(LaneSplits));