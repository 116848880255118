import React, { Component, Fragment } from "react";
import Slide from "@material-ui/core/Slide";
import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";

// core components.
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

//style for this view
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";


class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

class ParcelServices extends Component {

    state = {
        mounted: false
    };


    componentDidMount() {
        this.setState({
            mounted: true
        });
    }

    onChange(name,e) {
        this.props.onChange(name, e);
    }

    render() {
        const { classes } = this.props;

        return (
            <GridItem xs={12} style={{paddingTop: '5px', paddingBottom: '5px'}}>
                <GridContainer>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>Offeror</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "offeror",
                                value: this.props.offeror || "",
                                onChange: e => this.props.onChange("offeror", e)
                            }}
                            required
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>Emergency Phone</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "emergency_phone",
                                value: this.props.emergency_phone || "",
                                onChange: e => this.props.onChange("emergency_phone", e)
                            }}
                            required
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>Emergency Response Registrant #</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "emergency_response_registrant",
                                value: this.props.emergency_response_registrant || "",
                                onChange: e => this.onChange("emergency_response_registrant", e)
                            }}
                            required
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>International Emergency Phone</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "intl_emergency_phone",
                                value: this.props.intl_emergency_phone || "",
                                onChange: e => this.onChange("intl_emergency_phone", e)
                            }}
                            required
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>Signatory Contact Name</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "signatory_contact_name",
                                value: this.props.signatory_contact_name || "",
                                onChange: e => this.onChange("signatory_contact_name", e)
                            }}
                            required
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>Signatory Title</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "signatory_contact_title",
                                value: this.props.signatory_contact_title || "",
                                onChange:  e => this.onChange("signatory_contact_title", e)
                            }}
                            required
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label}>Signatory Place</InputLabel>
                        <br />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                name: "signatory_place",
                                value: this.props.signatory_place || "",
                                onChange:  e => this.onChange("signatory_place", e)
                            }}
                            required
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        );
    }
}

export default (withStyles(ltlQuoteStyle)(withSnackbar(ParcelServices)));