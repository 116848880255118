import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import cx from "classnames";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Book from "@material-ui/icons/Book";
import Button from "~/components/CustomButtons/Button.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import TransitSelect from "~/components/TMS/TransitSelect.jsx";
import Datetime from "react-datetime";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import ReferenceList from "~/components/TMS/ReferenceList.jsx";
import LevelSelect from "~/components/ImportanceLevel/containers/ImportanceLevelSelect.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class GeneralInformation extends Component {

    render() {
        const classes = this.props.classes || {};
        const refOpts = Array.isArray(this.props.refOpts) ? this.props.refOpts : [];
        return (
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <GridContainer>
                                {(this.props.showProviderId) && (
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="Magaya Shipment ID"
                                            content="Unique Maygaya identifier for the shipment."
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>Magaya Shipment ID</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "provider_id",
                                                name: "provider_id",
                                                value: this.props.provider_id || "",
                                                onChange: this.props.onChangeProviderId
                                            }}
                                            white
                                        />
                                    </GridItem>
                                )}
                                {(this.props.showMasterBill) && (
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="Master Bill #"
                                            content="Unique Master Bill Number for the shipment."
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>Master Bill #</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "master_bill_num",
                                                name: "master_bill_num",
                                                value: this.props.master_bill_num || "",
                                                onChange: this.props.onChangeMasterBill
                                            }}
                                            required={!!this.props.require_master_bill}
                                            white
                                        />
                                    </GridItem>
                                )}
                                {(this.props.showHouseBill) && (
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="House Bill #"
                                            content="House Bill Number for the shipment."
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>House Bill #</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "house_bill_num",
                                                name: "house_bill_num",
                                                value: this.props.house_bill_num || "",
                                                onChange: this.props.onChangeHouseBill
                                            }}
                                            required={!!this.props.require_house_bill}
                                            white
                                        />
                                    </GridItem>
                                )}
                                {(this.props.showAirwayBill) && (
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="Airway Bill #"
                                            content="Airway Bill Number for the shipment."
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>Airway Bill #</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "airway_bill_num",
                                                name: "airway_bill_num",
                                                value: this.props.airway_bill_num || "",
                                                onChange: this.props.onChangeAirwayBill
                                            }}
                                            required={!!this.props.require_airway_bill}
                                            white
                                        />
                                    </GridItem>
                                )}
                                {(this.props.showIsfNum) && (
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="ISF/ITN"
                                            content=""
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>ISF/ITN</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "isf_num",
                                                name: "isf_num",
                                                value: this.props.isf_num || "",
                                                onChange: this.props.onChangeIsf
                                            }}
                                            required={!!this.props.require_isf}
                                            white
                                        />
                                    </GridItem>
                                )}
                                {(this.props.showCustomsEntryNum) && (
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="Customs Entry #"
                                            content="Customs Entry Number for the shipment."
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>Customs Entry #</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "customs_entry_num",
                                                name: "customs_entry_num",
                                                value: this.props.customs_entry_num || "",
                                                onChange: this.props.onChangeCustomsEntryNum
                                            }}
                                            white
                                        />
                                    </GridItem>
                                )}
                                {(this.props.showPro) && (
                                    <GridItem xs={4}>
                                        <WithTooltip
                                            title="PRO Number"
                                            content="Unique identifier used by carriers for tracking the freight."
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>PRO #</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />

                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                id: "pro_num",
                                                name: "pro_num",
                                                value: this.props.pro || "",
                                                onChange: this.props.onChangePro
                                            }}
                                            required={!!this.props.require_pro}
                                            white={!this.props.require_pro}
                                        />

                                        {this.props.showGetPro ? (
                                            <WithTooltip
                                                title="PRO Book"
                                                content="Retrieve an available PRO # for the carrier selected"
                                            >
                                                <span>
                                                    <Button justIcon round color="info" size="sm" className={classes.marginLeft} onClick={this.props.getPro}>
                                                        <Book />
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                        ) : null}
                                    </GridItem>
                                )}
                                {(this.props.showBol) && (
                                    <GridItem xs={4}>
                                        <WithTooltip
                                            title="Bill of Lading Number"
                                            content="Unique identifier for the contract of freight services"
                                        >
                                            <span>
                                                <InputLabel className={classes.label}>BoL #</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <br />
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "bol_num",
                                                name: "bol_num",
                                                value: this.props.bol || "",
                                                onChange: this.props.onChangeBol
                                            }}
                                            white
                                        />
                                    </GridItem>
                                )}

                                <GridItem xs={4}>
                                    <WithTooltip
                                        title="Purchase Order Number"
                                        content="Unique identifier for the purchase transaction of the freight."
                                    >
                                        <span>
                                            <InputLabel className={classes.label}>PO #</InputLabel>
                                        </span>
                                    </WithTooltip>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            id: "po_num",
                                            name: "po_num",
                                            value: this.props.po || "",
                                            onChange: this.props.onChangePo
                                        }}
                                        required={!!this.props.require_po}
                                        white={!this.props.require_po}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <GridContainer>
                                <GridItem xs={4}>
                                    <WithTooltip
                                        title="Shipping Order Number"
                                        content="Unique identifier for the shipping order of the itemized freight to be transported."
                                    >
                                        <span>
                                            <InputLabel className={classes.label}>Order/SO #</InputLabel>
                                        </span>
                                    </WithTooltip>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            id: "so_num",
                                            name: "so_num",
                                            value: this.props.so_num || "",
                                            onChange: this.props.onChangeSo
                                        }}
                                        required={!!this.props.require_so}
                                        white={!this.props.require_so}
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    <WithTooltip
                                        title="Reference Number"
                                        content="Unique customer number for identifying the shipment."
                                    >
                                        <span>
                                            <InputLabel className={classes.label}>Reference #</InputLabel>
                                        </span>
                                    </WithTooltip>
                                    <br />
                                    {(this.props.ref_entry_type === 'select') ? (
                                        <FormControl fullWidth className={classes.selectFormControl}>
                                            <Select
                                                MenuProps={{ className: classes.selectMenu }}
                                                /* had to tweak the standard select styling to remove the automatic uppercasing */
                                                classes={(() => {
                                                    let selectNoUpper = {...classes.select}
                                                    selectNoUpper.textTransform = 'none';
                                                    return {
                                                        select: cx({
                                                            [selectNoUpper]: true,
                                                            [classes.requiredSelect]: true
                                                        })
                                                    }
                                                })()}
                                                value={this.props.ref_num || ''}
                                                inputProps={{ name: "ref_num" }}
                                                onChange={this.props.onChangeRef}
                                            >
                                                {refOpts.map((val, key) => {
                                                    return (
                                                        <MenuItem
                                                            key={`refopt_${key}`}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={val}
                                                        >
                                                            {((_.isEmpty(val)) ? (<span style={{color: 'transparent'}}>none</span>) : val)}
                                                        </MenuItem>
                                                    );
                                                })}
                                                {(refOpts.indexOf(this.props.ref_num) === -1) ? (
                                                    <MenuItem
                                                        hidden
                                                        key={"refopt_ff"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value={this.props.ref_num}
                                                    >
                                                        {this.props.ref_num}
                                                    </MenuItem>
                                                ) : ''}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                id: "ref_num",
                                                name: "ref_num",
                                                value: this.props.ref_num || "",
                                                onChange: this.props.onChangeRef
                                            }}
                                            required={!!this.props.require_ref}
                                            white={!this.props.require_ref}
                                        />
                                    )}
                                </GridItem>
                                <GridItem xs={4}>
                                    <WithTooltip
                                        title="Custom Date"
                                        content="User-defined value of the shipping date to be displayed on the bill of lading."
                                    >
                                        <span>
                                            <InputLabel className={classes.label}>Custom Date</InputLabel>
                                        </span>
                                    </WithTooltip>
                                    <br />
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            value={this.props.custom_date || ""}
                                            onChange={this.props.onChangeCustomDate}
                                            className={classes.datetime}
                                            inputProps={{
                                                id: "custom_date",
                                                name: "custom_date",
                                                placeholder: "Select Custom Date"
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={8} />
                                <GridItem xs={4}>
                                    {!_.isEmpty(this.props.originalExecutionDate) && <small>{`Original Tender Date: ${this.props.originalExecutionDate}`}</small>}
                                </GridItem>
                                <GridItem xs={12} className={classes.right}>
                                    {this.props.enable_blind_shipment && (
                                        <WithTooltip
                                            title="Blind Shipment"
                                            content="When checked, a separate BoL will be created for the customer."
                                        >
                                            <span>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}
                                                            inputProps={{ name: "blind_shipment" }}
                                                            checked={!!this.props.is_blind_shipment}
                                                            onChange={this.props.onChangeBlindShipment}
                                                        />
                                                    }
                                                    classes={{ label: classes.label }}
                                                    label="Blind Shipment"
                                                />
                                            </span>
                                        </WithTooltip>
                                    )}
                                    {this.props.company_require_pod_for_billing && (
                                        <span>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                        inputProps={{ name: "require_pod_for_billing" }}
                                                        checked={this.props.require_pod_for_billing}
                                                        onChange={this.props.onRequirePodForBilling}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                                label="Require POD for Billing"
                                            />
                                        </span>
                                    )}
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.dividerTop}>
                            <GridContainer>
                                <GridItem xs={6} sm={4}>
                                    <WithTooltip
                                        title="Transit Status"
                                        content="The current package status during transportation."
                                    >
                                        <span>
                                            <InputLabel className={classes.label}>Transit Status</InputLabel>
                                        </span>
                                    </WithTooltip>
                                    <br />
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <TransitSelect
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            value={JSON.stringify({
                                                status: this.props.transit_sub_status ? this.props.transit_sub_status : this.props.transit_status,
                                                parent: this.props.transit_sub_status ? this.props.transit_status : null
                                            })}
                                            inputProps={{
                                                name: "transit_status",
                                                id: "transit_status"
                                            }}
                                            onChange={this.props.onChangeTransitStatus}
                                            type='transit'
                                        />
                                    </FormControl>
                                </GridItem>
                                {(this.props.showBolTransitTimeInfo) && (
                                    <React.Fragment>
                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Pickup Date"
                                                content="Select when the shipment will be picked up."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Pickup Date</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={this.props.pickup_date || ""}
                                                    timeFormat={false}
                                                    onChange={this.props.onChangePickupDate}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "pickup_date",
                                                        name: "pickup_date",
                                                        placeholder: "Select Pickup Date"
                                                    }}
                                                />
                                            </FormControl>
                                            <GridContainer  >
                                                <GridItem xs={12}>
                                                    {!_.isEmpty(this.props.original_pd) && <small>{`Original PD: ${this.props.original_pd}`}</small>}
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Expected Delivery Date"
                                                content="Select the date when the shipment is expected to be delivered."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Expected Delivery Date</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={this.props.expected_delivery_date || ""}
                                                    timeFormat={false}
                                                    onChange={this.props.onChangeExpectedDeliveryDate}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "expected_delivery_date",
                                                        name: "expected_delivery_date",
                                                        placeholder: "Select Expected Delivery Date"
                                                    }}
                                                />
                                            </FormControl>
                                            <GridContainer  >
                                                <GridItem xs={12}>
                                                    {!_.isEmpty(this.props.original_edd) && <small>{`Original EDD: ${this.props.original_edd}`}</small>}
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Delivery Date"
                                                content="Select the date when the shipment has been delivered."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Delivery Date</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={this.props.delivery_date || ""}
                                                    timeFormat={false}
                                                    onChange={this.props.onChangeDeliveryDate}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "delivery_date",
                                                        name: "delivery_date",
                                                        placeholder: "Select Delivery Date"
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Delivery Time"
                                                content="Select the time when the shipment has been delivered."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Delivery Time</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={moment(this.props.delivery_time, "HH:mm:ss", true).isValid()
                                                        ? moment(this.props.delivery_time, "HH:mm:ss").format("h:mm A")
                                                        : this.props.delivery_time}
                                                    dateFormat={false}
                                                    onChange={this.props.onChangeDeliveryTime}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "delivery_time",
                                                        name: "delivery_time",
                                                        placeholder: "Select Delivery Time"
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Late Pickup Reason"
                                                content="Select reason for late pickup"
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Late Pickup Reason: </InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <TransitSelect
                                                    MenuProps={{ className: classes.selectMenu }}
                                                    classes={{ select: classes.select }}
                                                    value={this.props.late_pickup_reason || ""}
                                                    inputProps={{
                                                        name: "late_pickup_reason",
                                                        id: "late_pickup_reason"
                                                    }}
                                                    onChange={this.props.onChangeLatePickupReason}
                                                    type="pickup"
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Late Delivery Reason"
                                                content="Select reason for late delivery"
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Late Delivery Reason: </InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <TransitSelect
                                                    MenuProps={{ className: classes.selectMenu }}
                                                    classes={{ select: classes.select }}
                                                    value={this.props.late_delivery_reason || ""}
                                                    inputProps={{
                                                        name: "late_delivery_reason",
                                                        id: "late_delivery_reason"
                                                    }}
                                                    onChange={this.props.onChangeLateDeliveryReason}
                                                    type="delivery"
                                                />
                                            </FormControl>
                                        </GridItem>
                                    </React.Fragment>
                                )}
                                {(this.props.showIntlTransitTimeInfo) && (
                                    <React.Fragment>
                                        <GridItem xs={6} sm={3}>
                                            <WithTooltip
                                                title="Freight Ready Date"
                                                content="Select the date when the shipment freight is ready."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Freight Ready Date</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={moment(this.props.freight_ready_date).isValid() ? moment(this.props.freight_ready_date).format("MM/DD/YYYY") : ''}
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    onChange={this.props.onChangeFreightReadyDate}
                                                    className={classes.required}
                                                    inputProps={{
                                                        id: "freight_ready_date",
                                                        name: "freight_ready_date",
                                                        placeholder: "Select Freight Ready Date"
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={6} sm={3}>
                                            <WithTooltip
                                                title="Ready Time"
                                                content="Select the time when the shipment is ready."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Ready Time</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={moment(this.props.ready_time, "HH:mm:ss", true).isValid()
                                                        ? moment(this.props.ready_time, "HH:mm:ss").format("h:mm A")
                                                        : this.props.ready_time}
                                                    dateFormat={false}
                                                    onChange={this.props.onChangeReadyTime}
                                                    className={this.props.direction == "export" ? classes.required : classes.datetime}
                                                    inputProps={{
                                                        id: "ready_time",
                                                        name: "ready_time",
                                                        placeholder: "Select Ready Time"
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={6} sm={2}>
                                            <WithTooltip
                                                title="Close Time"
                                                content="Select the time when the shipment will close."
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Close Time</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    value={moment(this.props.close_time, "HH:mm:ss", true).isValid()
                                                        ? moment(this.props.close_time, "HH:mm:ss").format("h:mm A")
                                                        : this.props.close_time}
                                                    dateFormat={false}
                                                    onChange={this.props.onChangeCloseTime}
                                                    className={this.props.direction == "export" ? classes.required : classes.datetime}
                                                    inputProps={{
                                                        id: "close_time",
                                                        name: "close_time",
                                                        placeholder: "Select Close Time"
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                    </React.Fragment>
                                )}
                                <GridItem xs={12} className={classes.right}>
                                    {!!this.props.allowTransitFieldsUpdate && (
                                        <WithTooltip
                                            title="Update Transit Information"
                                            content="Updates transit information fields after executing a bill of lading."
                                        >
                                            <span>
                                                <Button size="sm" color="warning" className={classes.marginLeft} onClick={this.props.onTransitUpdate}>
                                                    Update
                                                </Button>
                                            </span>
                                        </WithTooltip>
                                    )}
                                    <WithTooltip
                                        title="Remove General Information"
                                        content="Remove General Information from the bill of lading."
                                    >
                                        <span>
                                            <Button
                                                color="info"
                                                size="sm"
                                                onClick={this.props.clearGeneralInfo}
                                                className={this.props.allowTransitFieldsUpdate ? "" : classes.marginLeft}>
                                                Clear
                                            </Button>
                                        </span>
                                    </WithTooltip>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        {this.props.showLevelSelect && (
                            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.dividerTop}>
                                <GridContainer>
                                    <GridItem xs={6} sm={3}>
                                        <WithTooltip
                                            title="Importance Level"
                                            content="Select a importance level."
                                            placement="top"
                                        >
                                            <span>
                                                <InputLabel className={this.props.classes.label}>Importance Level</InputLabel>
                                            </span>
                                        </WithTooltip>
                                        <LevelSelect
                                            levelId={this.props.levelId}
                                            onSelect={this.props.onSelectLevel}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        )}
                    </GridContainer>
                    <ReferenceList
                        refTypeUrl={'/index.php?p=api&r=json&c=userSettings&m=getReferenceTypes'}
                        data={this.props.references}
                        onUpdate={this.props.handleReference}
                    />
                </CardBody>
            </Card>
        );
    }
}

GeneralInformation.propTypes = {
    onChangePro: PropTypes.func,
    onChangeBol: PropTypes.func,
    onChangePo: PropTypes.func,
    onChangeSo: PropTypes.func,
    onChangeRef: PropTypes.func,
    onChangeHouseBill: PropTypes.func,
    onChangeProviderId: PropTypes.func,
    onChangeMasterBill: PropTypes.func,
    onChangeCustomDate: PropTypes.func,
    onChangeBlindShipment: PropTypes.func,
    onChangeFreightReadyDate: PropTypes.func,
    onChangeReadyTime: PropTypes.func,
    onChangeCloseTime: PropTypes.func,
    onRequirePodForBilling: PropTypes.func,
    onUpdate: PropTypes.func,
    onChangeTransitStatus: PropTypes.func,
    onChangeLateDeliveryReason: PropTypes.func,
    onChangeLatePickupReason: PropTypes.func,
    onChangePickupDate: PropTypes.func,
    onChangeExpectedDeliveryDate: PropTypes.func,
    onChangeDeliveryDate: PropTypes.func,
    onTransitUpdate: PropTypes.func,
    clearGeneralInfo: PropTypes.func,
    getPro: PropTypes.func,
    handleReference: PropTypes.func,
};

export default withStyles(style)(GeneralInformation);
