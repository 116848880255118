const initialState = {
    filterHeaders: [],
    filterName:'',
    operator: [],
    queryFilters: [],
    options: [],
};

export default function(state = initialState, action) {
    const newState = state;
	switch(action.type){
        case "SET_FILTER_HEADER_VALUES":
            return {...newState, filterHeaders: action.payload};
        case "UPDATE_FILTER_NAME_VALUE":
            return {...newState, filterName: action.payload};
        case "UPDATE_OPERATOR_VALUE":
            return {...newState, operator: action.payload};
        case "UPDATE_FILTERS_VALUE":
            return {...newState, queryFilters: action.payload};
        case "SET_OPTIONS":
            return {...newState, options: action.payload};
        default: 
            return state
	}
}