import React from "react";
import _ from "lodash";
import moment from "moment";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { getUserSetting } from "../../redux/selectors/Admin.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import NavPills from "~/components/NavPills/NavPills.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUps, faFedex } from "@fortawesome/free-brands-svg-icons";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";

function Transition(props) {
	return <Slide direction="down" {...props} />;
}

class ParcelQuotePanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			user: null,
			active_search: "",
			current: "1",
			last: "1",
			result: [],
			search_by: "qid",
			search_terms: "",
			quote_id: "",
			parcelRates: [],
			parcelQuoteModal: false
		};
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.loadQuote = this.loadQuote.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getParcelRates = this.getParcelRates.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			let url = "/index.php?p=api&r=json&c=parcel&m=parcelQuotePanel&d=1/20";
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.result) &&
				this.state.mounted
			) {
				const body = response.data.body;
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					active_search: body.active_search || "",
					current: body.current.toString() || "1",
					last: body.last.toString() || "1",
					result: body.result
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	async handlePageClick(page) {

		let search = "";

		if (!_.isEmpty(this.state.active_search)) {
			search = this.state.active_search;
		}

		const url = "/index.php?p=api&r=json&c=parcel&m=parcelQuotePanel&d=" + page + "/20" + search;

		this.setState({ show: false, loading: true });

		try {
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.result) &&
				this.state.mounted
			) {
				const { body } = response.data;
				this.setState({
					show: true,
					loading: false,
					current: body.current.toString() || "1",
					last: body.last.toString() || "1",
					result: body.result,
					active_search: body.active_search || ""
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the quotes!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the quotes!",
				{ variant: "error" }
			);
		}
	}
	getPagination() {
		const delta = 5;
		let currentPage = 1,
			pageCount = 1;

		if (!_.isEmpty(this.state.current) && this.state.current != 1) {
			currentPage = parseInt(this.state.current);
		}
		if (!_.isEmpty(this.state.last) && this.state.last != 1) {
			pageCount = parseInt(this.state.last);
		}

		let pagination = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" },
			pages = [];

		const separate = (a, b) => [
			a,
			...({
				0: [],
				1: [b],
				2: [a + 1, b]
			}[b - a] || ["...", b])
		];

		pages = Array(delta * 2 + 1)
			.fill()
			.map((_, index) => currentPage - delta + index)
			.filter(page => 0 < page && page <= pageCount)
			.flatMap((page, index, { length }) => {
				if (!index) {
					return separate(1, page);
				}
				if (index === length - 1) {
					return separate(page, pageCount);
				}
				return [page];
			});

		if (pageCount > 1 && !_.isEmpty(pages)) {
			pagination = pages.map((prop, key) => {
				let page = { text: prop };
				if (prop == currentPage) {
					page.active = true;
				} else if (prop === "...") {
					page.disabled = true;
				} else {
					page.onClick = e => this.handlePageClick(prop);
				}
				return page;
			});
		}

		if (currentPage < pageCount) {
			nextPage.onClick = e => this.handlePageClick(currentPage + 1);
		} else {
			nextPage.disabled = true;
		}

		if (currentPage - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(currentPage - 1);
		} else {
			prevPage.disabled = true;
		}

		pagination.push(nextPage);
		pagination.unshift(prevPage);

		return pagination;
	}
	getParcelQuotes(data) {
		return data.map((prop, key) => {
			let quoteType = "", actions = "";
			quoteType += prop.type === "tl" ? "TL" : "";
			quoteType += "QID-" + prop.quote_id;
			if(!_.isEmpty(prop.shipper) && !_.isEmpty(prop.consignee)) {
				actions = (
					<div className="actions-right">
						<Button
							size="sm"
							color="linkedin"
							style={{ marginRight: "5px" }}
							onClick={() => this.loadQuote(prop.quote_id)}
						>
							Load
						</Button>
					</div>
				);
			}
			return [
				quoteType,
				moment(prop.ship_date).format("MM-DD-YYYY"),
				prop.shipper_country == null ? prop.shipper : prop.shipper + ' - ' + prop.shipper_country,
				prop.consignee_country == null ? prop.consignee : prop.consignee + ' - ' + prop.consignee_country,
				actions
			];
		});
	}
	async loadQuote(quoteId) {
		try {
			let url = "/index.php?p=api&r=json&c=parcelRateRequest&m=quoteDetail&d=" + quoteId;
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.quote_id) &&
				!_.isEmpty(response.data.body.parcelRates)
			) {
				this.setState({
					quote_id: response.data.body.quote_id,
					parcelRates: response.data.body.parcelRates,
					parcelQuoteModal: true
				});
			} else {
				this.props.setNotification(
					"There was an error loading the quote!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the quote!", {
				variant: "error"
			});
		}
	}
	getPackages(data) {
		return data.map((prop, key) => {
			return [
				(prop.length != null && prop.length != '0' && prop.length != '') ? prop.length + " in." : "N/A",
				(prop.width != null && prop.width != '0' && prop.width != '') ? prop.width + " in." : "N/A",
				(prop.height != null && prop.height != '0' && prop.height != '') ? prop.height + " in." : "N/A",
				(prop.weight != null && prop.weight != '0' && prop.weight != '') ? prop.weight + " lbs" : "N/A",
				prop.qty
			];
		});
	}
	getHeaders() {
		let headers = [];
		headers.push("Service");
		if(!this.props.hideParcelRateDetails) {
			headers.push("Base Charge");
			headers.push("Total Surcharges");
			headers.push("Discount");
		}
		headers.push("Total Charge");
		return headers;
	}
	getColumns(parcelRates) {
		const { classes } = this.props;
		const carrier = parcelRates.carrier;
		const shipmentType = parcelRates.shipmentType;
		return parcelRates.rates.map((prop, key) => {
			let totalSurcharges = "N/A",
				discount = "N/A";
			if (
				!isNaN(parseFloat(prop.totalSurcharges)) &&
				parseFloat(prop.totalSurcharges) != 0
			) {
				totalSurcharges =
					"$" +
					parseFloat(prop.totalSurcharges)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,");
			}
			if (
				!isNaN(parseFloat(prop.discount)) &&
				parseFloat(prop.discount) != 0
			) {
				discount =
					"$" +
					parseFloat(prop.discount)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,");
			}
			
			let data = [];
			data.push(<strong>{prop.service}</strong>);
			if(!this.props.hideParcelRateDetails) {
				data.push("$" +
					parseFloat(prop.baseCharge)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,")
				);
				data.push(totalSurcharges);
				data.push(discount);
			}
			data.push("$" +
				parseFloat(prop.totalCharge)
					.toFixed(2)
					.replace(/\d(?=(\d{3})+\.)/g, "$&,")
			);;
			return {
				color: "info",
				data: data
			};
		});
	}
	getRatesTable(parcelRates) {
		return (
			<GridContainer>
				<GridItem xs={12}>
					{parcelRates.ratingMethod && (
						<h5>Rating Method: {parcelRates.ratingMethod}</h5>
					)}
					{parcelRates.ratingMethod == "DIM" &&
						parcelRates.dimDivisor != 139 && (
							<h5>Dim Divisor: {parcelRates.dimDivisor}</h5>
						)}
					{parcelRates.billingWeight && (
						<h5>
							Total Billing Weight: {parcelRates.billingWeight}{" "}
							lbs.
						</h5>
					)}
					<Table
						hover
						tableHead={this.getHeaders()}
						tableData={this.getColumns(parcelRates)}
					/>
				</GridItem>
			</GridContainer>
		);
	}
	getParcelRates(parcelRates) {
		const { classes } = this.props;
		let tabs = [], packages = [], totalWeight = 0, packageCount = 0;

		const fedexRates = parcelRates.filter(
			parcelRate => parcelRate.carrier === "FEDEX"
		);

		const upsRates = parcelRates.filter(
			parcelRate => parcelRate.carrier === "UPS"
		);

		if (!_.isEmpty(upsRates)) {
			packages = upsRates[0].packages;
		} else if (!_.isEmpty(fedexRates)) {
			packages = fedexRates[0].packages;
		}

		for (let prop of packages) {
			packageCount += !_.isEmpty(prop.qty) ? parseInt(prop.qty) : 1;
			totalWeight += prop.weight * (!_.isEmpty(prop.qty) ? parseInt(prop.qty) : 1);
		}

		tabs.push({
			tabIcon: () => <FontAwesomeIcon icon={faBoxes} size="5x" />,
			tabContent: (
				<span>
					<GridItem xs={12}>
						<h5>Actual Weight: {totalWeight} lbs.</h5>
						<h5>Package Count: {packageCount}</h5>
						<Table
							hover
							tableHead={[
								"Length",
								"Width",
								"Height",
								"Weight",
								"Quantity"
							]}
							tableData={this.getPackages(packages)}
						/>
					</GridItem>
				</span>
			)
		});

		if (upsRates.length) {
			tabs.push({
				tabIcon: () => <FontAwesomeIcon icon={faUps} size="6x" />,
				tabContent: (
					<span>
						<GridItem xs={12}>
							{this.getRatesTable(upsRates[0])}
						</GridItem>
					</span>
				)
			});
		}

		if (fedexRates.length) {
			tabs.push({
				tabIcon: () => <FontAwesomeIcon icon={faFedex} size="6x" />,
				tabContent: (
					<span>
						<GridItem xs={12}>
							{this.getRatesTable(fedexRates[0])}
						</GridItem>
					</span>
				)
			});
		}

		return tabs;
	}
	async handleSearch() {
		this.setState({
			show: false,
			loading: true
		});

		const { search_by, search_terms } = this.state;

		let url = "/index.php?p=api&r=json&c=parcel&m=parcelQuotePanel&d=1/20",
			search = "";

		if (!_.isEmpty(search_by) && !_.isEmpty(search_terms)) {
			search = "&search_by=" + encodeURIComponent(search_by) + "&search_terms=" + encodeURIComponent(search_terms);
		}

		try {
			const response = await axios.get(url + search);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body)
			) {
				const body = response.data.body;
				this.setState({
					show: true,
					loading: false,
					current: body.current.toString() || "1",
					last: body.last.toString() || "1",
					result: body.result,
					active_search: body.active_search || ""
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error searching the panel!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error searching the panel!", {
				variant: "error"
			});
		}
	}
	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch();
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs={12}>
											<Grid container>
												<Grid item xs style={{ paddingRight: "10px", paddingTop: "7px" }}>
													<FormControl
														fullWidth
														className={classes.selectFormControl}
													>
														<Select
															MenuProps={{className: classes.selectMenu}}
															classes={{select: classes.select}}
															value={this.state.search_by}
															inputProps={{
																name: "search_by",
																id: "search_by"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="qid"
															>
																Quote ID
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="date"
															>
																Ship Date
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="shipper"
															>
																Shipper Zip
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="consignee"
															>
																Consignee Zip
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs style={{ paddingTop: "7px" }}>
													<CustomInput
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															id: "search_terms",
															name: "search_terms",
															value: this.state.search_terms || "",
															onChange: this.handleInput("search_terms"),
															onKeyPress: this.handleKeyPress
														}}
														white
													/>
												</Grid>
												<Grid item xs={3} sm={3} md={6}>
													<Button
														size="sm"
														color="linkedin"
														onClick={this.handleSearch}
														style={{ marginLeft: "4px" }}
													>
														<Search /> Search
													</Button>
												</Grid>
											</Grid>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
							 	<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(this.state.result) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.result) ? (
											<Table
												tableHead={[
													"Quote ID",
													"Ship Date",
													"Shipper",
													"Consignee",
													"Actions"
												]}
												tableData={this.getParcelQuotes(this.state.result)}
												customCellClasses={[classes.right]}
												customClassesForCells={[4]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[4]}
											/>
										) : (
											<Badge color="info">No Parcel Quotes</Badge>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve parcel quotes from the server"
									/>
								)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.parcelQuoteModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("parcelQuoteModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							color="transparent"
							onClick={() =>
								this.handleModalClose("parcelQuoteModal")
							}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>
							Quote ID: {this.state.quote_id}
						</h4>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
					>
						<GridContainer>
							{!_.isEmpty(this.state.parcelRates) ? (
								<GridItem xs={12}>
									<NavPills
										color="warning"
										tabs={this.getParcelRates(
											this.state.parcelRates
										)}
									/>
								</GridItem>
							) : (
								<GridItem xs={12} className={classes.center}>
									<Badge color="info">No Parcel Rates</Badge>
								</GridItem>
							)}
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button
							onClick={() =>
								this.handleModalClose("parcelQuoteModal")
							}
							color="linkedin"
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
		hideParcelRateDetails: !!(+getUserSetting('hide_parcel_rate_details', 0)(state)),
	}
};
export default connect(mapStateToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(ParcelQuotePanel)));