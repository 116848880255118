import React from "react";
import _ from "lodash";
import { withSnackbar } from "notistack";

// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";

import OptionList from "./OptionList.jsx";

import optionStyle from "~/assets/jss/empire-tms/views/tms/optionStyle.jsx";

class OptionListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      left: this.props.options,
      right: this.props.selectedOptions,
      allStates: {
        classcharge: {},
        options: {},
        finish: {},
        grouping: {},
        summarization: {},
        date: {},
        filers: {},
      },
    };
    this.handleOptionState = this.handleOptionState.bind(this);
  }
  async componentDidMount() {}

  handleOptionState(stepState) {
    this.setState((prevState) => ({
      allStates: {
        ...prevState.allStates,
        ...prevState.allStates.options,
        options: stepState,
      },
    }));
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        <OptionList
          available={this.props.options}
          selected={this.props.selectedOptions}
          onMove={this.props.handleSelected}
          handleOptionState={this.handleOptionState}
          classes={classes}
          sortable={true}
          searchable={true}
          moveLeftIcon={<i className="fa fa-angle-left" />}
          moveAllLeftIcon={<i className="fa fa-angle-double-left" />}
          moveRightIcon={<i className="fa fa-angle-right" />}
          moveAllRightIcon={<i className="fa fa-angle-double-right" />}
          moveUpIcon={<i className="fa fa-angle-up" />}
          moveTopIcon={<i className="fa fa-angle-double-up" />}
          moveDownIcon={<i className="fa fa-angle-down" />}
          moveBottomIcon={<i className="fa fa-angle-double-down" />}

        />
      </Grid>
    );
  }
}
export default withStyles(optionStyle)(withSnackbar(OptionListView));
