import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import moment from "moment";
import _ from "lodash";

import ArchivedActions from "./ArchivedActions.jsx";
import DateSelect from "./DateSelect.jsx";
import ReactTable from '~/components/ReactTable/ReactTable.jsx';
import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import SearchModal from "~/components/Search/components/Search.jsx";

// core components
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import {
	savePostingPanelColumns,
	setPostingPanelColumns,
	setPostingDefaultColumns
} from "../actions/ArchivedBillsOfLading.jsx";


class ArchivedBillsOfLadingList extends Component {

	state = {
		loading: false,
		selectedRow: null,
		update: false
	};

	columns = () => {
		let cols = [
			{
				Header: "BoL ID",
				accessor: "bol_id",
				Cell: (rowInfo) => {
					if(this.props.userHasBillOfLadingPermission) {
						return <NavLink to={`${basePath}/admin/bol/${rowInfo.row.original.date}/${rowInfo.row.original.id}`}>{rowInfo.row.original.date + rowInfo.row.original.id}</NavLink>
					}
					return rowInfo.row.original.date + rowInfo.row.original.id;
				},
				type: String,
				colDisabled: false
			},
			{
				Header: "Actions",
				accessor: "actions",
				Cell: this.actionsCell,
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Transit Status",
				accessor: "transit_status",
				Cell: this.transitStatusCell,
				type: String,
				colDisabled: false,
				width: 175,
			},
			{
				Header: "Transit Sub Status",
				accessor: "transit_sub_status",
				Cell: this.transitStatusCell,
				type: String,
				colDisabled: false,
				width: 175,
			},
			{
				Header: "Pickup Date",
				accessor: "pickup_date",
				Cell: this.dateCell,
				type: Date,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Expected Delivery Date",
				accessor: "expected_delivery_date",
				type: Date,
				Cell: this.dateCell,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Delivery Date",
				accessor: "delivery_date",
				type: Date,
				Cell: this.dateCell,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Delivery Time",
				accessor: "delivery_time",
				type: Date,
				Cell: this.dateCell,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Late Pickup Reason",
				accessor: "late_pickup_reason",
				type: String,
				Cell: this.latePickupReasonCell,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Late Delivery Reason",
				accessor: "late_delivery_reason",
				type: String,
				Cell: this.lateDeliveryReasonCell,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Carrier",
				accessor: "carrier",
				type: String,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "PRO",
				accessor: "pro",
				Cell: (rowInfo) => {
					if(rowInfo.row.original.tracking_history > 0) {
						return <NavLink to={`${basePath}/admin/bol/HistoryPanel/${rowInfo.row.original.date}${rowInfo.row.original.id}`}>{rowInfo.row.original.pro}</NavLink>
					}
					return rowInfo.row.original.pro;
				},
				colDisabled: false,
				width: 165,
			},
			{
				Header: "PO",
				accessor: "po",
				type: String,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "SO",
				accessor: "so",
				type: String,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Ref",
				accessor: "ref",
				type: String,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Shipper Name",
				accessor: "shipper.name",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Shipper Address",
				accessor: "shipper_addr",
				Cell: (rowInfo) => {
					return `${rowInfo.row.original.shipper.address1} ${rowInfo.row.original.shipper.city}, ${rowInfo.row.original.shipper.state} ${rowInfo.row.original.shipper.zip}`;
				},
				type: String,
				colDisabled: false,
				width: 300,
			},
			{
				Header: "Shipper City",
				accessor: "shipper.city",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Shipper State",
				accessor: "shipper.state",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Shipper ZIP",
				accessor: "shipper.zip",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Consignee Name",
				accessor: "consignee.name",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Consignee Address",
				accessor: "cons_addr",
				Cell: (rowInfo) => {
					return `${rowInfo.row.original.consignee.address1} ${rowInfo.row.original.consignee.city}, ${rowInfo.row.original.consignee.state} ${rowInfo.row.original.consignee.zip}`;
				},
				type: String,
				colDisabled: false,
				width: 300,
			},
			{
				Header: "Consignee City",
				accessor: "consignee.city",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Consignee State",
				accessor: "consignee.state",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Consignee ZIP",
				accessor: "consignee.zip",
				type: String,
				colDisabled: false,
				width: 250,
			},
			{
				Header: "Direction/Terms",
				accessor: "direction",
				type: String,
				Cell: (rowInfo) => {
					if(rowInfo.row.original.direction === "Outbound/Prepaid" && rowInfo.row.original.charge_customer == 1) {
						return `${rowInfo.row.original.direction} & Charge`;
					}
					return rowInfo.row.original.direction;
				},
				colDisabled: false,
				width: 165,
			},
			{
				Header: "BoL Date",
				accessor: "date",
				Cell: (rowInfo) => {
					const date = rowInfo.row.original.custom_date ? rowInfo.row.original.custom_date : rowInfo.row.original.date;
					return moment(date).format("L");
				},
				type: String,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Status",
				accessor: "status",
				type: String,
				colDisabled: false
			},
			{
				Header: "Importance Level",
				accessor: "bol_level_id",
				Cell: this.bolLevelSelectCell,
				type: String,
				colDisabled: !this.props.userHasBolLevelPermission,
				width: 165,
			},
			{
				Header: "Code",
				accessor: "code",
				type: String,
				colDisabled: false,
				width: 165,
			},
			{
				Header: "Shipment Charge",
				accessor: "billed_tc",
				Cell: (rowInfo) => {
					let charge = "";
					if(parseInt(rowInfo.row.original.billed_tc) > 0) {
						charge = `$${rowInfo.row.original.billed_tc}`;
					} else if(!rowInfo.row.original.executed_hide_msg.length && parseInt(rowInfo.row.original.executed_tc) > 0) {
						charge = `$${rowInfo.row.original.executed_tc} (Subject to Audit)`;
					}
					return charge;
				},
				type: String,
				colDisabled: (this.props.user && this.props.user.user_settings.show_tc_in_posting_panel && this.props.user.user_settings.show_tc_in_posting_panel == 1) ? false : true,
				width: 165,
			},
			{
				Header: "Custom #",
				accessor: "custom_id",
				type: String,
				colDisabled: false,
				width: 165,
			},

		];

		cols.forEach((e, i) => {
			if(e.colDisabled) {
				cols.splice(i, 1)
			}
		})

		if(_.isEmpty(this.props.postingPanelDefaultColumns)) {
			this.props.setPostingDefaultColumns(cols)
		}

		if(_.isEmpty(this.props.postingPanelColumns)) {
			return cols;
		} else {
			let customCol = [];
			this.props.postingPanelColumns.forEach((v) => {
				const disabled = v.colDisabled === "false" ? false : (!v.colDisabled ? false : true);
				cols.forEach((e) => {
					if(!customCol.includes(e) && (v.accessor == e.accessor) && !disabled) {
						customCol.push(e)
						return;
					}
				})
			})
			return customCol;
		}
	};

	actionsCell = (rowInfo) => {
		return(
			<ArchivedActions
				row={rowInfo.row}
				user={this.props.user}
				userHasBillOfLadingPermission={this.props.userHasBillOfLadingPermission}
				hasClaimsPermission={this.props.hasClaimsPermission}
				hasCarrierTrackingPermission={this.props.hasCarrierTrackingPermission}
				handlePrint={this.props.handlePrint}
				handleCarrierDocs={this.props.handleCarrierDocs}
				bolsWithNewNotes={this.props.bolsWithNewNotes}
				bolsWithNotes={this.props.bolsWithNotes}
				notesLoading={this.props.bolsWithNotes}
				bolNotesCountLoading={this.props.bolNotesCountLoading}
				classes={this.props.classes}
				getNotes={this.props.getNotes}
				handleHistory={this.props.handleHistory}
				handleRates={this.props.handleRates}
				handleCancel={this.props.handleCancel}
				handleUncancel={this.props.handleUncancel}
				updateBolLevel={this.props.updateBolLevel}
				setSelectedRow={this.setSelectedRow}
			/>
		)
	}

	bolLevelSelectCell = (rowInfo) => {
		const isSelected = rowInfo.row.index == this.state.selectedRow;
		const levelId = rowInfo.row.original.bol_level_id;
		const { levels } = this.props;
		const importanceDots = ["\u2022", "\u2022\u2022", "\u2022\u2022\u2022"];
		if (!Array.isArray(levels) || !levels.length) return null;
		return(
			<>
				{isSelected ?
					<FormControl fullWidth disabled={this.state.loading}>
						<Select
							value={levelId || ""}
							onChange={(e) => this.props.updateBolLevel(rowInfo.row.index, e.target.value)}
						>
							<MenuItem
								key={0}
								value={0}
							>
								None
							</MenuItem>
							{levels.map((level, index) => {
								return (
									<MenuItem
										key={index + 1}
										value={level.id || ""}
									>
										{_.startCase(level.name)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				: <span style={{ color: "red", fontSize: "5em", alignSelf: "center" }}> {levelId >= 1 ? importanceDots[levelId-1] : "" } </span>} 
			</>
		)
	}

	dateCell = (rowInfo) => {
		let date = (!rowInfo.cell.value || rowInfo.cell.value.length < 0) ? null : rowInfo.cell.value;
		const isSelected = rowInfo.row.index == this.state.selectedRow;

		if(date !== null) {
			if(rowInfo.column.id == "delivery_time") {
				date = moment(date, "HH:mm:ss").format("h:mm A");
			} else {
				date = moment(date);
			}
		}

		return (
			<>
				{isSelected ?
					<DateSelect
						classes={this.props.classes}
						rowInfo={rowInfo}
						setTransitTimes={this.setTransitTimes}
						date={date}
					/>
				:<span>{rowInfo.column.id == "delivery_time" ? date : rowInfo.cell.value}</span>}
			</>
		);
	}

	transitStatusCell = (rowInfo) => {
		const isSelected = rowInfo.row.index == this.state.selectedRow;
		const type = rowInfo.column.id;
		const parentStatus = rowInfo.row.original.transit_status;
		return (
			<>
				{isSelected ?
					<FormControl fullWidth disabled={this.state.loading}>
						<Select
							value={rowInfo.row.original[type] || ""}
							onChange={(e) => this.setTransitStatus(e, rowInfo.row.index)}
							name={type}
						>
							<MenuItem
								key={0}
								value={""}
							>
								No Status
							</MenuItem>
							{this.props.transitStatusOpts.map((option, index) => {
								if(type == "transit_sub_status") {
									if(option.parent && option.parent == parentStatus) {
										return (
											<MenuItem
												key={index++}
												value={option.status}
											>
												{option.description}
											</MenuItem>
										);
									}
								} else {
									if(!option.parent) {
										return (
											<MenuItem
												key={index++}
												value={option.status}
											>
												{option.description}
											</MenuItem>
										);
									}
								}
							})}
						</Select>
					</FormControl>
				:<span>{rowInfo.cell.value}</span>}
			</>
		);
	}

	transitSubStatusCell = (rowInfo) => {
		const isSelected = rowInfo.row.index == this.state.selectedRow;
		const parentStatus = rowInfo.row.original.transit_status;
		return (
			<>
				{isSelected ?
					<FormControl fullWidth disabled={this.state.loading}>
						<Select
							value={rowInfo.row.original.transit_sub_status || ""}
							onChange={(e) => this.setTransitStatus(e, rowInfo.row.index)}
							name={"transit_sub_status"}
						>
							<MenuItem
								key={0}
								value={""}
							>
								{"No Status"}
							</MenuItem>
							{this.props.transitStatusOpts.map((option, index) => {
								if (option.parent && option.parent == parentStatus) {
									return (
										<MenuItem
											key={index++}
											value={option.status}
										>
											{option.description}
										</MenuItem>
									);
								}
							})}
						</Select>
					</FormControl>
				:<span>{rowInfo.cell.value}</span>}
			</>
		);
	}

	latePickupReasonCell = (rowInfo) => {
		const isSelected = rowInfo.row.index == this.state.selectedRow;
		return (
			<>
				{isSelected ?
					<FormControl fullWidth disabled={this.state.loading}>
						<Select
							value={rowInfo.row.original.late_pickup_reason || ""}
							onChange={(e) => this.setTransitStatus(e, rowInfo.row.index)}
							name={"late_pickup_reason"}
						>
							{this.props.latePickupOpts.map((option, index) => {
								return (
									<MenuItem
										key={index}
										value={option.reason}
									>
										{option.description}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				:<span>{rowInfo.cell.value}</span>}
			</>
		);
	}

	lateDeliveryReasonCell = (rowInfo) => {
		const isSelected = rowInfo.row.index == this.state.selectedRow;
		return (
			<>
				{isSelected ?
					<FormControl fullWidth disabled={this.state.loading}>
						<Select
							value={rowInfo.row.original.late_delivery_reason || ""}
							onChange={(e) => this.setTransitStatus(e, rowInfo.row.index)}
							name={"late_delivery_reason"}
						>
							{this.props.lateDeliveryOpts.map((option, index) => {
								return (
									<MenuItem
										key={index}
										value={option.reason}
									>
										{option.description}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				:<span>{rowInfo.cell.value}</span>}
			</>
		);
	}

	setTransitTimes = async (name, m, idx) => {
		await this.props.handleDatetime(name, m, idx);
		await this.props.saveTransitInfo(idx, name);
	}

	setTransitStatus = async (event, index) => {
		this.setState({loading:true});
		await this.props.handleChange(event);
		await this.props.saveTransitInfo(index, event.target.name)
		this.setState({loading:false});
	}

	setSelectedRow = (idx) => {
		const {selectedRow} = this.state;
		this.setState({selectedRow: idx == selectedRow ? null : idx});
	}

	updateCols = () => {
		this.setState({update: !!this.state.update})
	}

	handleSearch = (filters) => {
		this.setSelectedRow(null);
		this.props.handleSearch(filters)
	}

	getTransitStatusSearchOptions = (parentOnly = true) => {
		let results = this.props.transitStatusOpts.map((opt) => {
			if(parentOnly && opt.parent == null) {
				return {name: opt.description, value: opt.status}
			} else if(!parentOnly) {
				if(opt.parent == null) {
					return {name: opt.description, value: opt.status, disabled: true}
				} else {
					return {name: opt.description, value: opt.status, disabled: false}
				}
			}
		});
		// filter out empty results.
		results = results.filter(value => value);
		return results;
	}

	getStatusSearchOptions = () => {
		return [
			{ name:"Hold", value:"hold" },
			{ name:"Executed", value:"executed" },
			{ name:"Scheduled", value:"scheduled" },
			{ name:"Confirmed", value:"confirmed" },
			{ name:"TFM Received", value:"tfm_received" },
			{ name:"Auditing", value:"auditing" },
			{ name:"Billed", value:"billed" },
			{ name:"Disputed", value:"disputed" },
			{ name:"Canceled", value:"canceled" },
			{ name:"TTL Received", value:"ttl_received" },
			{ name:"TTL Confirmed", value:"ttl_confirmed" },
		]
	}

	getImportanceSearchOptions = () => {
		return [
			{ name:"None", value: "none" },
			{ name:"Low", value: "low" },
			{ name:"Medium", value: "medium" },
			{ name:"High", value: "high" },
		]
	}

	getDirectionSearchOptions = () => {
		return [
			{ name:"Outbound/Prepaid", value:"Outbound/Prepaid" },
			{ name:"Outbound/Collect", value:"Outbound/Collect" },
			{ name:"Inbound/Collect", value:"Inbound/Collect" },
			{ name:"Third Party", value:"Third Party" },
		]
	}

	getSearchFields = () => {
		let searchFields = [
			{ name: "BoL ID", value: "bol" },
			{ name: "Status", value: "status", type: "select", options: this.getStatusSearchOptions() },
			{ name: "Custom Reference", value: "customReference", type: "select_with_text", options: this.props.refTypes },
			{ name: "Custom #", value: "custom" },
			{ name: "Carrier Name", value: "carrier" },
			{ name: "Shipper Name", value: "shipper" },
			{ name: "Origin Address 1", value: "oAddress1" },
			{ name: "Origin City", value: "oCity" },
			{ name: "Origin State", value: "oState" },
			{ name: "Origin Zip", value: "oZip" },
			{ name: "Consignee Name", value: "consignee" },
			{ name: "Destination Address 1", value: "dAddress1" },
			{ name: "Destination City", value: "dCity" },
			{ name: "Destination State", value: "dState" },
			{ name: "Destination Zip", value: "dZip" },
			{ name: "Pro #", value: "pro" },
			{ name: "Reference #", value: "ref" },
			{ name: "PO #", value: "po" },
			{ name: "ORDER/SO #", value: "so" },
			{ name: "Date", value: "date", type:"date"},
			{ name: "Transit Status", value: "transitStatus", type: "select", options: this.getTransitStatusSearchOptions(true) },
			{ name: "Transit Sub Status", value: "transitSubStatus", type: "select", options: this.getTransitStatusSearchOptions(false) },
			{ name: "Pickup Date", value: "pickupDate", type:"date"},
			{ name: "Pickup Confirmation", value: "pickupConfirmation" },
			{ name: "Expected Delivery Date", value: "expectedDeliveryDate", type:"date"},
			{ name: "Delivery Date", value: "deliveryDate", type:"date"},
			{ name: "Importance Level", value: "bolLevel", type: "select", options: this.getImportanceSearchOptions() },
		];

		if(this.props.user && this.props.user.level === "admin") {
			searchFields.push(
				{ name: "Company", value: "company" },
				{ name: "Warehouse/Facility", value: "warehouse"},
				{ name: "User", value: "user" },
			)
		}

		if(this.props.user && this.props.user.level === "user") {
			searchFields.push(
				{ name: "Direction/Freight Term", value: "direction", type: "select", options: this.getDirectionSearchOptions() },
			)
		}
		return searchFields;
	}

	setPageNumber = (page) => {
		this.props.setPageNumber(page);
		this.setSelectedRow(null);
	}

	render() {
		const searchFields = this.getSearchFields();
		return(
			<GridContainer>
				<GridItem xs={3} sm={3} md={3} className={this.props.classes.left}>
					<ColumnListLayoutModal
						buttonType="primary"
						fetchColumns={() => {}}
						listColumns={this.props.postingPanelDefaultColumns}
						customListColumns={this.props.postingPanelColumns}
						updateCols={this.updateCols}
						saveCustomColumns={(items) => this.props.savePostingPanelColumns(items)}
						applyCustomColumns={(items) => this.props.setPostingPanelColumns(items)}
						loading={this.props.loading}
					/>
					<SearchModal
						fields={searchFields}
						onClear={this.props.clearFilters}
						hideFilterType
						onSearch={this.handleSearch}
						loading={this.props.loading}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<ReactTable
						loading={this.props.loading}
						disabled={this.props.loading}
						columns={this.columns()}
						data={this.props.bols}
						rightPaginationLabelFormat={({ from, to, count }) => ''}
						pageSize={this.props.pageSize}
						pageNumber={this.props.pageNumber}
						setPageNumber={this.setPageNumber}
						visibleRows={15}
						overscan={30}
						enableResizing
						highlightOnSelect={true}
						selectedRow={this.state.selectedRow}
						simplePagination
						nextPage={this.props.nextPage}
						prevPage={this.props.prevPage}
						defaultColumn={{
							align: 'left',
						}}
					/>
				</GridItem>
			</GridContainer>
		)
	}
}

const mapStateToProps = state => {
	return {
		transitStatusOpts: state.ArchivedBillsOfLading.transitStatusOpts,
		lateDeliveryOpts: state.ArchivedBillsOfLading.lateDeliveryOpts,
		latePickupOpts: state.ArchivedBillsOfLading.latePickupOpts,
		postingPanelColumns: state.ArchivedBillsOfLading.postingPanelColumns,
		postingPanelDefaultColumns: state.ArchivedBillsOfLading.postingPanelDefaultColumns,
		refTypes: state.ArchivedBillsOfLading.refTypes,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		savePostingPanelColumns,
		setPostingPanelColumns,
		setPostingDefaultColumns
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(ArchivedBillsOfLadingList));
