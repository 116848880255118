import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

// style for this view
import styles from "~/assets/jss/empire-tms/components/dropzoneStyle.jsx";

const thumbsContainer = {
	position: "relative",
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16
};

const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: "border-box",
	cursor: "pointer"
};

const thumbInner = {
	position: "relative",
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
	cursor: "pointer",
	cursor: "hand"
};

const img = {
	display: "block",
	width: "auto",
	height: "100%"
};

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#777",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#000",
	outline: "none",
	transition: "border .24s ease-in-out"
};

const activeStyle = {
	borderColor: "#2196f3"
};

const acceptStyle = {
	borderColor: "#00e676"
};

const rejectStyle = {
	borderColor: "#ff1744"
};

const buttonStyle = {
	position: "absolute",
	top: "8px",
	left: "12px",
	color: "white"
};

function Dropzone(props) {
	const [files, setFiles] = useState([]);
	const { handleUpload, classes, length } = props;

	const accept = props.accept || "image/jpeg,image/png,image/gif,application/pdf,application/vnd.ms-outlook,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv";
	const contentTypes = accept.split(",");

	const extensions = [];
	for (const mime of contentTypes) {
		let ext = "";
		switch (mime) {
			case "application/vnd.ms-outlook":
				ext = "*.msg";
				break;
			case "application/octet-stream":
				ext = "*.bin";
				break;
			case "application/pdf":
				ext = "*.pdf";
				break;
			case "application/vnd.ms-excel":
				ext = "*.xls";
				break;
			case "application/msword":
				ext = "*.doc";
				break;
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				ext = "*.docx";
				break;
			case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				ext = "*.xlsx";
				break;
			case "image/gif":
				ext = "*.gif";
				break;
			case "image/jpeg":
				ext = "*.jpeg";
				break;
			case "image/png":
				ext = "*.png";
				break;
			case ".csv":
			case "text/csv":
				ext = "*.csv";
				break;
			case "text/plain":
				ext = "*.txt";
				break;
		}
		extensions.push(ext);
	}
	const fileExtensions = extensions.join(", ");

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: acceptedFiles => {
			if (!isNaN(length) && (acceptedFiles.length === 0 || acceptedFiles.length > length)) {
			} else {
				setFiles(
					acceptedFiles.map(file =>
						Object.assign(file, {
							preview: URL.createObjectURL(file)
						})
					)
				);
				handleUpload(acceptedFiles);
			}
		}
	});

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Drag 'n' drop files here, or click to browse</p>
					<em>(Empire TMS will only accept {fileExtensions} files)</em>
				</div>
			</GridItem>
		</GridContainer>
	);
}

Dropzone.propTypes = {
	length: PropTypes.number,
	accept: PropTypes.string,
	classes: PropTypes.object.isRequired,
	handleUpload: PropTypes.func.isRequired
};

export default withStyles(styles)(Dropzone);
