import React from "react";
import PropTypes from 'prop-types';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from 'notistack';

import Admin from "./redux/containers/Admin.jsx";
import Auth from "./layouts/Auth.jsx";
import Rtl from "./layouts/RTL.jsx";
// import Admin from "./layouts/Admin.jsx";
import { basePath, pathname } from "./variables/server.jsx";
import ConnectSnackbar from './views/TMS/ConnectSnackbar.jsx';
import Alert from "./views/Alert/containers/Alert.jsx";
import '@progress/kendo-theme-material/dist/all.css';

export const hist = createBrowserHistory();
const rtlPath = basePath + "/rtl";
const authPath = basePath + "/auth";
const adminPath = basePath + "/admin";

let redirectFrom = basePath + "/";
let redirectTo = basePath + pathname;

if (pathname === "/index.php" || pathname === "/") {
	redirectFrom = basePath + pathname;
	redirectTo = basePath + "/admin";
}

const App = ({ store }) => (
	<Provider store={store}>
		<SnackbarProvider
			maxSnack={10}
			anchorOrigin={{
		        vertical: 'top',
		        horizontal: 'right',
		    }}
		    style={{"minWidth": "auto"}}
		>
			<ConnectSnackbar />
			<Alert />
			<Router history={hist}>
				<Switch>
					<Route path={rtlPath} component={Rtl} />
					<Route path={authPath} component={Auth} />
					<Route path={adminPath} component={Admin} />
					<Redirect from={redirectFrom} to={redirectTo} />
				</Switch>
			</Router>
		</SnackbarProvider>
	</Provider>
);

App.propTypes = {
	store: PropTypes.object.isRequired
};

export default App;
