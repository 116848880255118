import React, { Component, Fragment } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Search from "@material-ui/icons/Search";
import WithTooltip from './WithTooltip.jsx';

class ContactSearch extends Component {
    render() {
        const classes = this.props.classes || {};
        const menuItemClasses = {root: classes.selectMenuItem, selected: classes.selectMenuItemSelected};
        let disabled = this.props.disabled;
        if(this.props.enable_executed_edit) {
            disabled = false;
        }
        return (
            <Fragment>
                <InputLabel className={classes.label}>Search By</InputLabel>
                <br />
                <FormControl style={{ paddingTop: "9px", width: "175px", marginRight: "8px" }} className={classes.selectFormControl} disabled={disabled}>
                    <Select
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={this.props.searchByValue}
                        inputProps={{name: this.props.searchByField, id: this.props.searchByField,
                        }}
                        onChange={this.props.onChangeSearchBy}
                    >
                        <MenuItem classes={menuItemClasses} value="SEARCH_ANY">Intelligent Search</MenuItem>
                        <MenuItem classes={menuItemClasses} value="name">Name</MenuItem>
                        <MenuItem classes={menuItemClasses} value="address">Address</MenuItem>
                        <MenuItem classes={menuItemClasses} value="city">City</MenuItem>
                        <MenuItem classes={menuItemClasses} value="state">State</MenuItem>
                        <MenuItem classes={menuItemClasses} value="zip">Zip</MenuItem>
                    </Select>
                </FormControl>
                <CustomInput
                    id={this.props.searchForField}
                    inputProps={{
                        type: "text",
                        name: this.props.searchForField,
                        value: this.props.searchForValue,
                        onChange: this.props.onChangeSearchFor,
                        onKeyPress: this.props.onKeyPress,
                        disabled: disabled
                    }}
                    white
                />
                <WithTooltip
                    title="Search Contacts"
                    content="Choose the information from saved contacts in your Address Book."
                >
                    <span>
                        <Button justIcon round color="linkedin" className={classes.marginLeft} onClick={this.props.onSearch} disabled={disabled}>
                            <Search />
                        </Button>
                    </span>
                </WithTooltip>
            </Fragment>
        );
    }
}

export default ContactSearch;
