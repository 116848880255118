const dropzoneStyle = theme => ({
	thumbsContainer: {
		position: "relative",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 16
	},
	thumb: {
		display: "inline-flex",
		borderRadius: 2,
		border: "1px solid #eaeaea",
		marginBottom: 8,
		marginRight: 8,
		width: 100,
		height: 100,
		padding: 4,
		boxSizing: "border-box",
		cursor: "pointer"
	},
	thumbInner: {
		position: "relative",
		display: "flex",
		minWidth: 0,
		overflow: "hidden",
		cursor: "pointer",
		cursor: "hand"
	},
	img: {
		display: "block",
		width: "auto",
		height: "100%"
	},
	baseStyle: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "20px",
		borderWidth: 2,
		borderRadius: 2,
		borderColor: "#777",
		borderStyle: "dashed",
		backgroundColor: "#fafafa",
		color: "#000",
		outline: "none",
		transition: "border .24s ease-in-out"
	},
	activeStyle: {
		borderColor: "#2196f3"
	},
	acceptStyle: {
		borderColor: "#00e676"
	},
	rejectStyle: {
		borderColor: "#ff1744"
	},
	buttonStyle: {
		position: "absolute",
		top: "8px",
		left: "12px",
		color: "white"
	}
});

export default dropzoneStyle;