import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getUserSetting } from "../../../redux/selectors/Admin.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import InvoiceList from '../components/InvoiceList.jsx';
import TopActions from '../components/TopActions.jsx';
import HeldBillsListReleaseCard from '../components/HeldBillsListReleaseCard.jsx';
import InvoiceListFilters from "../components/InvoiceListFilters.jsx";
import {
	fetchInvoices,
	setSelectedInvoices,
	setTfmInvoicesPageNumber,
	setTfmInvoicesPageSize,
	setGlCode,
	setMassReleaseComment,
	submitMassRelease,
	fetchUserAssignedTFMCLocations,
	applyInvoiceListFilters,
	fetchCustomInvoiceListColumns,
} from '../actions/CustomerPortal.jsx';


// node modules
import _ from "lodash";


class HeldBillsListContainer extends Component {
	state = {
		initialized: false,
		filtersCollapsed: true,
		pageSize: 1000,
		visibleRows: 10,
		canMassRelease: false,
		requiresGlCode: false,
	}

	async componentDidMount() {
		this.props.setTfmInvoicesPageNumber(1);
		this.props.setTfmInvoicesPageSize(this.state.pageSize);
		this.props.fetchUserAssignedTFMCLocations();
		this.props.fetchCustomInvoiceListColumns();
		await this.props.applyInvoiceListFilters({ "status": 3 });
		await this.props.fetchInvoices(this.props.holdCodes);
		this.setState({initialized: true, canMassRelease: this.canMassRelease(), requiresGlCode: this.requiresGlCode()});
	}

	setPageNumber = async n => {
		this.props.setTfmInvoicesPageNumber(n);
		await this.props.fetchInvoices(this.props.holdCodes);
	}
	
	canMassRelease = () => {
		return !_.isEmpty(this.props.holdCode) && +this.props.holdCode.can_mass_release && this.props.hasWrite;
	}
	
	requiresGlCode = () => {
		return !_.isEmpty(this.props.holdCode) && +this.props.holdCode.requires_gl_code && this.props.hasWrite;
	}

	handleGlCodeChange = e => {
		this.props.setGlCode(e.target.value);
	}
	
	handleMassReleaseCommentChange = e => {
		this.props.setMassReleaseComment(e.target.value);
	}
	
	canRelease = () => {
		return this.props.selectedInvoices.length > 0 && this.props.massReleaseComment.trim() !== "" && (!this.state.requiresGlCode || this.props.glCode.trim() !== "");
	}
	
	release = async (comment, glCode) => {
		this.props.setMassReleaseComment(comment);
		this.props.setGlCode(glCode);
		await this.props.submitMassRelease();
		await this.props.fetchInvoices(this.props.holdCodes);
	}
	
	applyInvoiceListFilters = async (filters) => {
		this.props.setTfmInvoicesPageNumber(1);
		await this.props.applyInvoiceListFilters(filters);
		await this.props.fetchInvoices(this.props.holdCodes);
	}
	
	handleCheckbox = e => {
		const id = +e.target.value;
		let selectedInvoices = this.props.selectedInvoices;
		if (selectedInvoices.indexOf(id) === -1) {
			selectedInvoices.push(id);
		} else {
			selectedInvoices.splice(selectedInvoices.indexOf(id), 1);
		}
		this.props.setSelectedInvoices(selectedInvoices);
	}
	
	handleSelectAll = e => {
		if(e.target.checked) {
			this.props.setSelectedInvoices(this.props.invoices.map(invoice => +invoice.id));
		} else {
			if(this.props.selectedInvoices.length === this.props.invoices.length) {
				this.props.setSelectedInvoices([]);
			}
		}
	}
	
	setFilterCollapse = collapsed => {
		this.setState({ filtersCollapsed: collapsed });
	};

	render() {	
		const wait = !this.state.initialized || this.props.loading;
		return (
			<Fragment>
				<TopActions>
					<InvoiceListFilters 
						loading={wait}
						locations={this.props.locations}
						onChange={this.applyInvoiceListFilters}
						filters={{"status": 3, ...this.props.invoiceListFilters}}
						collapsed={this.props.filtersCollapsed}
						onCollapse={this.setFilterCollapse}
					/>
					<Fragment>
						{this.state.canMassRelease &&
							<HeldBillsListReleaseCard
								loading={wait}
								massReleaseComment={this.props.massReleaseComment}
								requireGlCode={this.state.requiresGlCode}
								onRelease={this.release}
								disabled={wait || this.props.selectedInvoices.length === 0}
							/>
						}
						<GridItem xs={12} sm={12} md={12} style={{ textAlign:"right", color: "#000"}}>
							<h4 style={{marginRight: "5px"}}>{"Total Records: " + (wait? '...' : this.props.totalRecords)}</h4>
						</GridItem>
					</Fragment>
				</TopActions>
				<InvoiceList 
					style={{paddingBottom: 25}}
					loading={wait}
					data={this.props.invoices}
					selectedInvoices={this.props.selectedInvoices}
					totalRecords={this.props.totalRecords}
					pageSize={this.props.pageSize}
					pageNumber={this.props.pageNumber}
					setPageNumber={this.setPageNumber}
					visibleRows={this.props.visibleRows}
					hiddenColumns={this.state.canMassRelease ? [] : ["checkboxes"]}
					canViewBill={this.props.canViewBill}
					canMassRelease={this.state.canMassRelease}
					handleCheckbox={this.handleCheckbox}
					handleSelectAll={this.handleSelectAll}
					heldBills={true}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	
	let writeOverridden = false;
	const overrides = getUserSetting("hold_access_overrides_by_hold_id")(state);
	const holdCode = state.CustomerPortal.holdCode;
	if (holdCode && overrides && overrides[+holdCode.id] && !+overrides[+holdCode.id]['write']) {
		writeOverridden = true;
	}
	let requiresGlCode = false;
	if (typeof holdCode === "object" &&
		holdCode !== null && 
		holdCode.id && 
		typeof overrides === "object" && 
		Object.keys(overrides).indexOf(holdCode.id) !== -1 && 
		typeof overrides[holdCode.id] === "object" &&
		+overrides[holdCode.id].requires_gl_code === 1
	) {
		requiresGlCode = true
	}
	return {
		hasWrite: +getUserSetting("hold_write", 0)(state) && !writeOverridden,
		loading: state.CustomerPortal.fetchInvoicesLoading || state.CustomerPortal.massReleaseLoading,
		invoices: state.CustomerPortal.invoices,
		selectedInvoices: state.CustomerPortal.selectedInvoices,
		holdCode,
		canViewBill: true, //@TODO check permission here when permission exists for viewing docs
		requiresGlCode,
		massReleaseComment: state.CustomerPortal.massReleaseComment,
		glCode: state.CustomerPortal.glcode,
		pageNumber: state.CustomerPortal.tfmInvoicesPageNumber,
		pageSize: state.CustomerPortal.tfmInvoicesPageSize,
		totalRecords: state.CustomerPortal.invoicesTotalRecords,
		locations: state.CustomerPortal.userBrokerLocations,
		invoiceListFilters: state.CustomerPortal.invoiceListFilters
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchInvoices,
		setSelectedInvoices,
		setGlCode,
		setMassReleaseComment,
		submitMassRelease,
		setTfmInvoicesPageNumber,
		setTfmInvoicesPageSize,
		fetchUserAssignedTFMCLocations,
		applyInvoiceListFilters,
		fetchCustomInvoiceListColumns
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HeldBillsListContainer);
