import React, { useRef, useState } from "react";
import { useFrame, useLoader } from '@react-three/fiber';
import { Edges, Line, Html, RenderTexture, Text, PerspectiveCamera } from '@react-three/drei';
import { BackSide, DoubleSide, TextureLoader } from 'three';
import { baseUrl, assetPath } from "~/variables/server.jsx";

function Unit(props) {
	const [hovered, setHover] = useState(false);

	const handlePointerOver = (event) => {
		event.stopPropagation(); 
		setHover(true);
	};

	const handlePointerOut = () => {
		setHover(false);
	}

	return (
		<> 
			<mesh
				{...props}
				onPointerOver={(event) => handlePointerOver(event)}
				onPointerOut={() => handlePointerOut()}
			>
				<boxGeometry args={[inToM(props.w*0.99), inToM(props.h), inToM(props.l*0.99)]} />
				<meshStandardMaterial color='#c19a6c'/>
				{/* <meshStandardMaterial>
					<RenderTexture attach="map" frames={1000}>
						<PerspectiveCamera makeDefault manual aspect={1 / 1} position={[0, 0, 5]} />
						<color attach="background" args={['#c19a6c']} />
						<ambientLight intensity={Math.PI} />
						<Text fontSize={0.4} color="white" overflowWrap='break-word'>
							{props.unitNo}
						</Text>
					</RenderTexture>
				</meshStandardMaterial> */}
				<Edges linewidth={2} threshold={15} color={hovered ? "#c02040" : "black"} />
				{hovered ? (
					<Html 
						distanceFactor={10} 
						position={[0,inToM(props.h), inToM(props.l/2)]}
					>
						<div style={{pointerEvents: 'none', whiteSpace: "nowrap", background: "white", padding: "5px 10px", border:"2px solid black", borderRadius: "10px"}}>
							<div>{props.l} X {props.w} X {props.h}</div> 
						</div>
					</Html>
				):null}
				
			</mesh>
		</>
	)
}

export default function Trailer(props) {
	const service_resp = props.serviceResponse;
	const screenCapture = props.screenCapture;
	const l = service_resp.trailer_length;
	const w = service_resp.trailer_width;
	const h = service_resp.trailer_height;
	let lineY = 0;
	let lineZ = 0;

	const trailerRef = useRef();

	const defaultPoints = [
		[[0,0,0],[0,0,0],[0,0,0]],
		[[0,0,0],[0,0,0],[0,0,0]],
		[[0,0,0],[0,0,0],[0,0,0]],
	]

	const [widthLine, setWidthLine] = useState(defaultPoints);
	const [heightLine, setHeightLine] = useState(defaultPoints);
	const [lengthLine, setLengthLine] = useState(defaultPoints);

	useFrame(() => {

		if(trailerRef.current) {
			const tWidth = trailerRef.current.parameters.width/2;
			const tHeight = trailerRef.current.parameters.height/2;
			const tLength = trailerRef.current.parameters.depth/2;

			setWidthLine([
				[[-tWidth,0,0],[0,0,0],[tWidth,0,0]],
				[[tWidth,-0.1,0],[tWidth,0,0],[tWidth,0.1,0]],
				[[-tWidth,-0.1,0],[-tWidth,0,0],[-tWidth,0.1,0]],
			]);
			setHeightLine([
				[[0,-tHeight,0],[0,0,0],[0,tHeight,0]],
				[[0,tHeight,-0.1],[0,tHeight,0],[0,tHeight,0.1]],
				[[0,-tHeight,-0.1],[0,-tHeight,0],[0,-tHeight,0.1]],
			]);
			setLengthLine([
				[[0,0,-tLength/2],[0,0,0],[0,0,tLength/2]],
				[[0,-0.1,tLength/2],[0,0,tLength/2],[0,0.1,tLength/2]],
				[[0,-0.1,-tLength/2],[0,0,-tLength/2],[0,0.1,-tLength/2]],
			]);
		}
	});

	const materialProps = {
		transparent: true,
		opacity: .8,
		side: BackSide // color the interior, leave the exterior see-through
	}

	function WallImage(props) {
		const texture = screenCapture ? null : useLoader(TextureLoader, baseUrl + assetPath + "/img/wall.jpg");
		const color = screenCapture ? "#9cc4d4" : null;
		return (
			<>
				<meshStandardMaterial { ...materialProps } color={color} attach="material-0" map={texture} />
				<meshStandardMaterial { ...materialProps } color={color} attach="material-1" map={texture} />
				<meshStandardMaterial { ...materialProps } color={color} attach="material-2" map={texture} />
				<meshStandardMaterial { ...materialProps } visible={false} attach="material-4" map={texture} />
				<meshStandardMaterial { ...materialProps } color={color} attach="material-5" map={texture} />
			</>
		)
	}

	function FloorImage(props) {
		const texture = screenCapture ? null : useLoader(TextureLoader, baseUrl + assetPath + "/img/floor.jpg");
		const color = screenCapture ? "#9cc4d4" : null;
		return <meshStandardMaterial { ...materialProps } {...props} map={texture} color={color} />
	}

	function TireImage(props) {
		if(screenCapture) return null;
		const texture = screenCapture ? null : useLoader(TextureLoader, baseUrl + assetPath + "/img/tire.jpg");
		return (
			<>
				<mesh materialProps={materialProps} position={props.position[0]} rotation={[0, Math.PI / 2, 0]}>
					<torusGeometry args={[0.4, 0.3, 16, 18]} />
					<meshStandardMaterial color="#9cc4d4" map={texture } />
					<Edges linewidth={1} threshold={15} color={"black"} />
				</mesh>
				<mesh materialProps={materialProps} position={props.position[1]} rotation={[0, Math.PI / 2, 0]}>
					<torusGeometry args={[0.4, 0.3, 16, 18]} />
					<meshStandardMaterial color="#9cc4d4" map={texture } />
					<Edges linewidth={1} threshold={15} color={"black"} />
				</mesh>
				<Line color="grey" position={[0, inToM(((props.h/2) - props.h)-28), inToM(((props.l/2)-(props.l/4)*3))]} points={props.points} linewidth={8} linecap={'square'} />
			</>
		);
	  }

	return (
		<group scale={.5} rotation={props.rotation}>
			<mesh
				position={[0,0,0]}
				materialProps={materialProps}
			>
				<boxGeometry args={[inToM(w), inToM(h), inToM(l)]} ref={trailerRef} />
				<WallImage />
				<FloorImage {...materialProps} side={DoubleSide} attach="material-3" />
				<Edges linewidth={3} threshold={15} color={"black"} />
				<LineWithText position={[0, inToM(h), inToM((l/2)-l)]} result={parseFloat(w/12).toFixed(2)} linePoints={widthLine} tColor={'black'}/>
				<LineWithText position={[inToM(((w/2)-w) - (w/2)), 0, inToM((l/2)-l)]} result={parseFloat(h/12).toFixed(2)} linePoints={heightLine} tColor={'black'}/>
				<LineWithText position={[inToM(w/2), inToM(h), 0]} result={parseFloat(l/12).toFixed(2)} linePoints={lengthLine} tColor={'black'}/>
			</mesh>
			<TireImage 
				position={[
					[inToM(((w/2)-w)), inToM(((h/2)-h)-28), inToM(((l/2)-(l/4)*3))],
					[inToM((w/2)), inToM(((h/2) - h)-28), inToM(((l/2)-(l/4)*3))]
				]}
				l={l}
				h={h}
				points={widthLine[0]}
			/>
			{(() => {
				const trailer_length = l;
				const trailer_width = w;
				const trailer_height = h;

				return Object.keys(service_resp.load_order).map(boxKey => {
					const length = service_resp.load_order[boxKey].piece_length;
					const width = service_resp.load_order[boxKey].piece_width;
					const height = service_resp.load_order[boxKey].piece_height;
					const name = service_resp.load_order[boxKey].name;
					
					const x = inToM(service_resp.load_order[boxKey].position[1]  - (trailer_width / 2) + (width / 2));
					const y = inToM(service_resp.load_order[boxKey].position[2]  - (trailer_height / 2) + (height / 2));
					const z = inToM(service_resp.load_order[boxKey].position[0]  - (trailer_length / 2) + (length / 2));
					lineY = y - inToM((height / 2));
					lineZ = inToM(props.result * 12 - (trailer_length / 2));
					return(
						<Unit
							key={boxKey}
							position={[x,y,z]}
							l={length}
							w={width}
							h={height}
							unitNo={''}
							getUnitInfo={props.getUnitInfo}
						/>
					);
				});
			})()}
			<LineWithText position={[0,lineY,lineZ]} result={props.result} linePoints={widthLine} tColor={'white'} showSquared/>
		</group>
	)
}

function LineWithText(props) {
	const pts = props.linePoints;
	return(
		<group {...props}>
			<Html style={{pointerEvents: "none"}} position={[0,.7,0.1]} center>
				<h3 style={{color: "white", userSelect: "none"}} >{props.result}ft<sup>{props.showSquared ? '2' : ''}</sup></h3>
			</Html>
			<Line points={pts[0]} color={props.tColor} linewidth={2} linecap={'square'} />
			<Line points={pts[1]} color={props.tColor} linewidth={2} linecap={'square'} />
			<Line points={pts[2]} color={props.tColor} linewidth={2} linecap={'square'} />
		</group>
	)
}

function mToIn(val) {
	return val * 39.3701;
}

function inToM(val) {
	return val / 39.3701
}