import React from "react";
import PropTypes from "prop-types";

// material ui icons
import Close from "@material-ui/icons/Close";

// react-icons
import { FaFile, FaFilePdf, FaFileCsv, FaEnvelope, FaFileExcel, FaFileWord } from "react-icons/fa";

// core components
import Button from "~/components/CustomButtons/Button.jsx";

const buttonStyle = {
	position: "absolute",
	top: "8px",
	left: "12px",
	color: "white"
};

const img = {
	display: "block",
	width: "auto",
	height: "100%"
};

const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: "border-box",
	cursor: "pointer"
};

const thumbInner = {
	position: "relative",
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
	cursor: "pointer",
	cursor: "hand"
};

const thumbName = {
	width: 100,
	fontSize: "9px",
};

const thumbsContainer = {
	position: "relative",
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16
};

const icon = {
	textAlign: "center",
	fontSize: "90px"
};

function Preview(props) {

	const { src, extension, index, name } = props;

	let image = <FaFile style={icon} onClick={() => props.handleClick(index)} />;

	switch (extension) {
		case "pdf":
			image = <FaFilePdf style={icon} onClick={() => props.handleClick(index)} />;
			break;
		case "csv":
			image = <FaFileCsv style={icon} onClick={() => props.handleClick(index)} />;
			break;
		case "msg":
			image = <FaEnvelope style={icon} onClick={() => props.handleClick(index)} />;
			break;
		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
			image = <img src={src} style={img} onClick={() => props.handleClick(index)} />;
			break;
		case "xls":
		case "xlsx":
			image = <FaFileExcel style={icon} onClick={() => props.handleClick(index)} />;
			break;
		case "doc":
		case "docx":
			image = <FaFileWord style={icon} onClick={() => props.handleClick(index)} />;
			break;
		default:
			image = <FaFile style={icon} onClick={() => props.handleClick(index)} />;
			break;
	}

	return (
		<div key={index}>
			<div style={thumb}>
				<div style={thumbInner}>
					{image}
					{props.handleDelete && (
						<Button justIcon round color="danger" size="sm" style={buttonStyle} onClick={() => props.handleDelete(index)}>
							<Close />
						</Button>
					)}
				</div>
			</div>
			<div style={thumbName}>
				{name}
			</div>
		</div>
	);
}

Preview.propTypes = {
	src: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	extension: PropTypes.string.isRequired,
	handleClick: PropTypes.func.isRequired,
	handleDelete: PropTypes.func
};

export default Preview;