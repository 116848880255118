import React from "react";
import qs from "qs";
import { Redirect } from "react-router-dom";
import moment, { tz } from "moment";
import "moment-timezone";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Button from "@material-ui/core/Button";
import ReportScheduler from "~/views/CustomReporting/components/ReportScheduler.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { Paper } from "@material-ui/core";

class ReportSchedulerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      show: false,
      redirect: null,
      loading: true,

      reportId:
        props.match.params.reportId != undefined
          ? props.match.params.reportId
          : "",
      scheduleId: !_.isEmpty(props.match.params.scheduleId)
        ? props.match.params.scheduleId
        : "",

      reportSchedule: {
        name: "",
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        recipients: [""],
        schedule: "",
        reportId: "",
        format: "",
        scheduleId: "",
        time: moment(),
        timeFormat: moment().format('HH:00'),
      },
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
    if (!_.isEmpty(this.state.scheduleId))
      this.getSavedSchedule(this.state.scheduleId);
  }

  getSavedSchedule = async (id) => {
    const localtz = moment.tz.guess();

    const url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitions&d=1/5/" +
      id;

    //this.setState({ mounted: true, id });

    try {
      const response = await axios.get(url);

      if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
        this.props.handleMessages(response);
      }

      if (typeof response.data !== "string" && !_.isEmpty(response.data.user)) {
        var recipients = response.data.body[0].recipients;

        this.setState({
          show: true,
          loading: false,

          reportSchedule: {
            name: response.data.body[0].name,
            startDate: response.data.body[0].start_date,
            endDate: response.data.body[0].end_date,
            recipients:
              recipients != "" ? recipients.split(",") : this.state.reportSchedule.recipients,
            schedule: response.data.body[0].schedule,
            format: response.data.body[0].format,
            time: moment(response.data.body[0].time, 'HH:mm').tz(localtz),
            timeFormat: moment(response.data.body[0].time, 'HH:mm').tz(localtz).format('HH:00'),
          }
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data !", {
        variant: "warning",
      });
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handleChange = (reportSchedule) => {
    this.setState({ reportSchedule });
  }

  saveScheduledReport = async () => {
    this.setState({ loading: true });

    let data = this.state.reportSchedule;
    let scheduleId = this.state.scheduleId;

    delete data.time; // only need formatted time

    if (data.schedule === "") {
      this.setState({ loading: false });
      this.props.setNotification("Reoccurring is required!", {
        variant: "warning",
      });

      return false;
    }

    const startdate = new Date(data.startDate);
    const enddate = new Date(data.endDate);

    if (startdate > enddate && data.schedule !== "Never") {
      this.setState({ loading: false });
      this.props.setNotification(
        "The start date must be less than the end date!",
        {
          variant: "Warning",
        }
      );
      return false;
    }

    if (data.format === "") {
      this.setState({ loading: false });
      this.props.setNotification("Export format is required!", {
        variant: "warning",
      });

      return false;
    }

    let url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=createScheduledReportDefinition";

    if (!_.isEmpty(scheduleId)) {
      url =
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=editScheduledReportDefinitions&d=" +
        scheduleId;
    }

    if (this.state.reportId) {
      data.reportId = this.state.reportId
    }
    
    try {
      const response = await axios.post(url, qs.stringify(data));

      if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
        this.props.handleMessages(response);
      }

      if (typeof response.data !== "string") {
        this.setState({
          loading: false,
          redirect: <Redirect to={basePath + "/admin/reports"} />,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error creating the scheduledReport!",
          { variant: "warning" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error creating the scheduledReport!",
        { variant: "warning" }
      );
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.redirect) {
      return this.state.redirect;
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper elevation={0} style={{ paddingTop: "30px" }}>
            <CardBody>
              <GridContainer>
                <GridItem xs style={{ paddingTop: "10px" }}>
                  <p style={{ fontSize: "20px", fontWeight: "bold" }} className={classes.title}>
                    {this.state.reportSchedule.name}
                  </p>
                </GridItem>
              </GridContainer>
              <ReportScheduler
                reportSchedule={this.state.reportSchedule}
                classes={classes}
                onChange={this.handleChange}
              />
              <GridContainer>
                <GridItem style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.saveScheduledReport}
                  >
                    Done
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(newVolumeQuoteStyle)(
  withSnackbar(ReportSchedulerContainer)
);
