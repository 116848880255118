import React from "react";
import _ from "lodash";
import qs from "qs";
import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { userIsAdmin, adminInUserSession } from "../../redux/selectors/Admin.jsx";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newProductStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewHandlingUnit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			warehouse: "",
			uom: "Pallet",
			label: "",
			stack: "0",
			pieces: "1",
			length: "",
			width: "",
			height: "",
			default: false,
			done: false,
			whMgtId: null
		};
		this.getWarehouses = this.getWarehouses.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.addHandlingUnit = this.addHandlingUnit.bind(this);
	}

	async componentDidMount() {
		this.setState({
			mounted: true,
			whMgtId: JSON.parse(localStorage.getItem("tms_wh_mgt_id")),
		});
		try {
			const whId = this.props.match.params.wid || false;
			let url = "/index.php?p=api&r=json&c=handUnit&m=newHandUnit";
			if(whId) {
				url += `&d=${whId}`;
			}
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
				let warehouse = { code: "" };
				if (response.data.body.warehouses) {
					warehouse = response.data.body.warehouses.filter(warehouse => {
						if(whId) {
							return warehouse.id == whId;
						} else {
							return warehouse.is_default == 1;
						}
					})[0];
				}
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					body: response.data.body,
					warehouse: (_.isEmpty(warehouse)) ? '' : warehouse.code,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}

		localStorage.removeItem("tms_wh_mgt_id");
	}

	componentWillUnmount() {
		if(this.props.isAdminUser && this.state.whMgtId) {
			localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
		}
		this.setState({ mounted: false, whMgtId: null });
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	getWarehouses(warehouses) {
		const { classes } = this.props;
		return warehouses.map((warehouse, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={warehouse.code}
				>
					{warehouse.name}
				</MenuItem>
			);
		});
	}

	getUnits(units) {
		const { classes } = this.props;
		return units.map((unit, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={unit.name}
				>
					{unit.name}
				</MenuItem>
			);
		});
	}

	async addHandlingUnit() {
		this.setState({
			show: true,
			loading: true
		});

		const { warehouse, uom, label, stack, pieces, length, width, height } = this.state;

		const data = {
			warehouse,
			uom,
			label,
			stack,
			pieces,
			length,
			width,
			height
		};

		if (this.state.default) {
			data.default = "1";
		}

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=handUnit&m=newHandUnit", qs.stringify(data));
			if (response.data) {
				this.props.handleMessages(response);
				if (response.data.body) {
					this.setState({
						loading: false,
						done: true
					});
				} else if(_.isEmpty(response.data.message)) {
					this.setState({ loading: false });
					this.props.setNotification("There was an error saving the handling unit!", {
						variant: "error"
					});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the handling unit!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error saving the handling unit!", {
				variant: "error"
			});
		}
	}

	render() {
		if (this.state.done) {
			let redirectTo = basePath;
			if(this.props.isAdminUser && this.state.whMgtId) {
				this.setState({whMgtId: null});
				localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
				redirectTo += "/admin/company/" + this.state.body.warehouses[0].company_name_id;
			} else {
				redirectTo += "/admin/handling-unit-catalog";
			}
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel htmlFor="warehouse" className={classes.label}>
										Warehouse/Facility
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.warehouse}
											inputProps={{
												name: "warehouse",
												id: "warehouse"
											}}
											onChange={this.handleChange}
										>
											{this.state.body && !!this.state.body.warehouses.length ? (
												this.getWarehouses(this.state.body.warehouses)
											) : (
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem
													}}
												>
													No Warehouses
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="uom" className={classes.label}>
										UoM
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.uom}
											inputProps={{
												name: "uom",
												id: "uom"
											}}
											onChange={this.handleChange}
										>
											{this.state.body && !!this.state.body.units.length ? (
												this.getUnits(this.state.body.units)
											) : (
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem
													}}
												>
													No Units
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Handling Unit Label</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "label",
											value: this.state.label,
											onChange: this.handleInput("label")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="stack" className={classes.label}>
										Stackable
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.stack}
											inputProps={{
												name: "stack",
												id: "stack"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem
												}}
												value="0"
											>
												No
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem
												}}
												value="1"
											>
												Yes
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.default}
												onChange={this.handleChecked("default")}
											/>
										}
										classes={{
											label: classes.label
										}}
										label="Default"
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs>
									<InputLabel className={classes.label}>Quantity</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "number",
											name: "pieces",
											value: this.state.pieces,
											onChange: this.handleInput("pieces")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Length</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "length",
											value: this.state.length,
											onChange: this.handleInput("length")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Width</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "width",
											value: this.state.width,
											onChange: this.handleInput("width")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Height</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "height",
											value: this.state.height,
											onChange: this.handleInput("height")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<Button color="linkedin" onClick={this.addHandlingUnit}>
										Add Handling Unit
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	const isAdminUser = userIsAdmin(state) && !adminInUserSession(state);
	return {
		isAdminUser: isAdminUser
	};
}

export default connect(mapStateToProps)(withStyles(newProductStyle)(withSnackbar(NewHandlingUnit)));
