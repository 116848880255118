import React from "react";
import axios from "~/variables/axios.jsx";
import qs from "qs";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Search from "@material-ui/icons/Search";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import { convertToCountryCode } from "../../helpers.jsx";

import palletCheckInStyle from "~/assets/jss/empire-tms/views/tms/palletCheckInStyle.jsx";
import Picky from "react-picky";
import "react-picky/dist/picky.css";

class TransitInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			shipper_zip: "",
			shipper_country: "",
			consignee_zip: "",
			consignee_country: "",
			carriers: [],
			selectedCarriers: [],
			transitData: []
		};
		this.handleCarriers = this.handleCarriers.bind(this);
		this.getTransitInfo = this.getTransitInfo.bind(this);
		this.handleParams = this.handleParams.bind(this);
	}


	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=carrierConnect&m=getCarriers"
			);
			if (response.data) {
				this.props.handleMessages(response);
				const data = response.data;
				await this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					carriers: data.body.carriers
				}, () => this.handleParams());
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	
	handleParams = async () => {
		let carriers = [];
		let shipperZip = "";
		let shipperCountry = "";
		let consigneeZip = "";
		let consigneeCountry = "";
		
		if(!this.props || !this.props.location || !this.props.location.search) {
			// No parameters in URL, so there is nothing to do in this function.
			return false;
		}
		let params = new URLSearchParams(this.props.location.search);
		let value = null;
		if(value = params.getAll("carriers[]")) {
			value.forEach(providedCarrier => {
				this.state.carriers.find(allowedCarrier => {
					if(allowedCarrier.scac == providedCarrier) {
						carriers.push(allowedCarrier);
						return true;
					}
				});
			});
		}
		if(value = params.get("shipperZip")) {
			shipperZip = value;
		}
		if(value = params.get("consigneeZip")) {
			consigneeZip = value;
		}
		
		if(value = params.get("shipperCountry")) {
			shipperCountry = convertToCountryCode(value, 'long');
			
		}
		if(value = params.get("consigneeCountry")) {
			consigneeCountry = convertToCountryCode(value, 'long');
		}

		if(shipperZip || shipperCountry) {
			this.handleZip("shipper", shipperZip, shipperCountry);
		}
		if(consigneeZip || consigneeCountry) {
			this.handleZip("consignee", consigneeZip, consigneeCountry);
		}
		
		await this.setState({
			selectedCarriers: carriers,
			shipper_zip: shipperZip,
			shipper_country: shipperCountry,
			consignee_zip: consigneeZip,
			consignee_country: consigneeCountry,
		})

		return true;
	}

	handleCarriers(selectedCarriers) {
		this.setState({ selectedCarriers });
	}

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
		if (name.indexOf("_zip") !== -1) {
			const type = name.replace("_zip", "");
			const postalCode = event.target.value.replace(" ","").toUpperCase();
			const country = !_.isEmpty(this.state[type + '_country']) ? convertToCountryCode(this.state[type + '_country'], 'long') : null;
			this.handleZip(type, postalCode, country);
		}
		if (name.indexOf("_country") !== -1) {
			const type = name.replace("_country", "");
			const postalCode = this.state[`${type}_zip`];
			const country = convertToCountryCode(event.target.value, 'long');
			this.handleZip(type, postalCode, country);
		}
	};

	async handleZip(name, zip, country = null) {
		if (zip.length && zip.length >= 5) {
			const countryParam = country == null ?  `` : `/${country}`;
			try {
				const response = await axios.get("/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + zip + countryParam);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (response.data.body) {
					const body = response.data.body;
					if (body[zip]) {
						if (!["USA","CAN","MEX"].includes(body[zip][0].country)) {
							this.setState({ loading: false });
							this.props.setNotification("International Shipment - Contact us for information regarding International Shipments.", { variant: "error" });
						}
						this.setState({ [name + "_country"]: convertToCountryCode(body[zip][0].country, 'short') });
					} else {
						this.setState({ loading: false });
						this.props.setNotification(`The ${name} postal code could not be found. Chances are it is not valid.`, { variant: "error" });
					}
				} else {
					this.setState({ loading: false });
					this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an exception searching for zip code info!", { variant: "error" });
			}
		}
	}

	async getTransitInfo() {
		if (!shipper_zip || !consignee_zip) {
			this.setState({
				transitData: null,
				loading: false
			});
			this.props.setNotification("The shipper and consignee zip codes are required!", { variant: "error" });
			return false;
		}

		if (_.isEmpty(this.state.selectedCarriers)) {
			this.setState({
				transitData: null,
				loading: false
			});
			this.props.setNotification("You need to select at least one carrier!", { variant: "error" });
			return false;
		}

		this.setState({
			transitData: null,
			showTransit: true,
			loading: true
		});

		const data = qs.stringify({
			scacs: this.state.selectedCarriers.map(carrier => carrier.scac),
			location: {
				shipper: {
					zip: this.state.shipper_zip,
					country: convertToCountryCode(this.state.shipper_country, 'long')
				},
				consignee: {
					zip: this.state.consignee_zip,
					country: convertToCountryCode(this.state.consignee_country, 'long')
				}
			}
		});

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=carrierConnect&m=transitData", data);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
				this.setState({ loading: false });
			}
			if(response.data.body) {
				const body = response.data.body;
				if (body.scacResponses !== false) {
					this.setState({
						loading: false,
						transitData: body.scacResponses
					});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error getting transit info!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an exception getting transit info!", { variant: "error" });
		}
		return false;
	}

	getHeaders() {
		return [
			"Carrier",
			"SCAC",
			"Transit",
			"Service Type",
			"Origin",
			"Destination"
		];
	}

	getColumns(data) {
		return data.map((transit, key) => {
			let serviceType = "";
			const { origin, destination } = transit.serviceDetail;
			if (origin == "INDIRECT" || destination == "INDIRECT") {
				serviceType = <Badge color="warning">INDIRECT</Badge>;
			} else if (origin == "DIRECT" && destination == "DIRECT") {
				serviceType = <Badge color="success">DIRECT</Badge>;
			}
			return [
				transit.carrierName,
				transit.SCAC,
				transit.transitDays,
				serviceType,
				<Badge color={origin == "DIRECT" ? "success" : "warning"}>{origin}</Badge>,
				<Badge color={destination == "DIRECT" ? "success" : "warning"}>{destination}</Badge>
			];
		});
	}

	render() {
		const { classes } = this.props;
		const { carriers, selectedCarriers } = this.state;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel htmlFor="carrier" className={classes.label}>
										Carriers
									</InputLabel>
									<Picky
										options={carriers}
										value={selectedCarriers}
										onChange={this.handleCarriers}
										valueKey="scac"
										labelKey="carrier"
										multiple={true}
										includeSelectAll={true}
										includeFilter={true}
										dropdownHeight={600}
										manySelectedPlaceholder={
											selectedCarriers.length +
											" selected"
										}
										defaultFocusFilter={true}
										renderList={({
											items,
											selected,
											multiple,
											selectValue,
											getIsSelected
										}) => {
											return items.map(item => (
												<li
													key={item.id}
													onClick={() => selectValue(item)}
												>
													<input
														type="checkbox"
														checked={getIsSelected(item)}
														readOnly
													/>
													{getIsSelected(item) ? (<strong>{item.carrier}</strong>) : (item.carrier)}
												</li>
											));
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Shipper Zip</InputLabel>
									<br />
									<CustomInput
										id="shipper_zip"
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "shipper_zip",
											value: this.state.shipper_zip || "",
											onChange: this.handleInput("shipper_zip")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Shipper Country</InputLabel>
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{ select: classes.select }}
											value={this.state.shipper_country}
											inputProps={{
												name: "shipper_country",
												id: "shipper_country"
											}}
											onChange={this.handleInput("shipper_country")}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="US"
											>
												US
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="CA"
											>
												CA
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="MX"
											>
												MX
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Consignee Zip</InputLabel>
									<br />
									<CustomInput
										id="consignee_zip"
										formControlProps={{ fullWidth: true }}
										inputProps={{
											name: "consignee_zip",
											value: this.state.consignee_zip || "",
											onChange: this.handleInput("consignee_zip")

										}}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Consignee Country</InputLabel>
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{ select: classes.select }}
											value={this.state.consignee_country}
											inputProps={{
												name: "consignee_country",
												id: "consignee_country"
											}}
											onChange={this.handleInput("consignee_country")}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="US"
											>
												US
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="CA"
											>
												CA
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="MX"
											>
												MX
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2} className={classes.center}>
									<Button color="linkedin" onClick={this.getTransitInfo}>
										Submit
									</Button>
								</GridItem>
							</GridContainer>
							{this.state.showTransit && (
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.transitData) ? (
											<GridContainer>
												<GridItem xs={12} sm={12} md={12}>
													<Table
														hover
														tableHead={this.getHeaders()}
														tableData={this.getColumns(this.state.transitData)}
													/>
												</GridItem>
											</GridContainer>
										) : (
											<GridContainer>
												<Spinner
													loading={this.state.loading}
													message="Failed to retrieve transit into from the server"
												/>
											</GridContainer>
										)}
									</GridItem>
								</GridContainer>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(palletCheckInStyle)(withSnackbar(TransitInfo));