import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import qs from "qs";
import { Redirect} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import NavPills from "~/components/NavPills/NavPills.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Accordion from "~/components/Accordion/Accordion.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

class ReportTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            show: false,
            loading: true,
            body: null,
            user: null,
            report_details_template_id: null,
            report_details_type: "bol",
            report_details_name: '',
            report_details_desc: '',
            columns: {
                shipper_code: false,
                shipper_name: false,
                shipper_address1: false,
                shipper_address2: false,
                shipper_city: false,
                shipper_state: false,
                shipper_zip: false,
                consignee_code: false,
                consignee_name: false,
                consignee_address1: false,
                consignee_address2: false,
                consignee_city: false,
                consignee_state: false,
                consignee_zip: false,
                bill_to_name: false,
                bill_to_address1: false,
                bill_to_address2: false,
                bill_to_city: false,
                bill_to_state: false,
                bill_to_zip: false,
                costdiv_name: false,
                costdiv_address1: false,
                costdiv_address2: false,
                costdiv_city: false,
                costdiv_state: false,
                costdiv_zip: false,
                vendor_name: false,
                vendor_address1: false,
                vendor_address2: false,
                vendor_city: false,
                vendor_state: false,
                vendor_zip: false,
                product_sum_weight: false,
                product_sum_weight_nf: false,
                product_avg_class: false,
                product_sum_pieces: false,
                shipment_pcf: false,
                product_description: false,
                rate_rated: false,
                rate_benchmark: false,
                rate_true: false,
                rate_rated_nf: false,
                rate_benchmark_nf: false,
                rate_true_nf: false,
                rate_transit: false,
                lcc_rate_rated: false,
                lcc_rate_benchmark: false,
                lcc_rate_true: false,
                lcc_rate_rated_nf: false,
                lcc_rate_benchmark_nf: false,
                lcc_rate_true_nf: false,
                lcc_rate_transit: false,
                rate_lost_savings: false,
                general_terms: false,
                general_pro: false,
                general_bol: false,
                general_custom: false,
                dynamic_bol_num: false,
                general_ref: false,
                general_so: false,
                general_po: false,
                general_status: false,
                date_executed: false,
                shipped_date: false,
                estimated_delivery: false,
                time_executed: false,
                warehouse_code: false,
                company_username: false,
                special_notes: false,
                ttl_cost_change_note: false,
                carrier_name: false,
                carrier_scac: false,
                lcc_carrier_name: false,
                lcc_carrier_scac: false,
                non_lcc_selection_code: false,
                non_lcc_selection_description: false,
                non_lcc_selection_user_description: false,
                non_lcc_selection_username: false,
                non_lcc_selection_timestamp: false,
                estimated_delivery: false,
                transit_status: false,
                latest_carrier_tracking_msg: false,
                original_pd: false,
                pickup_date: false,
                original_edd: false,
                delivery_date: false,
            },
            filters: {
                company_name: false,
                company_username: false,
                warehouse_code: false,
                date_executed: false,
                shipped_date: false,
                estimated_delivery: false,
                exclude_statuses: false,
                non_lcc_selection: false
            },
            disable_filters: false,
            redirect: null
        };
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleDatetime = this.handleDatetime.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.addCheckbox = this.addCheckbox.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
    }
    async componentDidMount() {
        this.setState({
            mounted: true
        });
        if(!_.isEmpty(this.props.match.params.id)) {
			const id = this.props.match.params.id;
            try {
                const response = await axios.get(
                    "/index.php?p=api&r=json&c=reporting&m=bolReportTemplate&d=" + id
                );
                const data = response.data;
                if (typeof data !== "string" && data.body && data.user) {
                    this.props.handleMessages(response);
                    this.setState({
                        show: true,
                        loading: false,
                        body: data.body,
                        user: data.user,
                        report_details_template_id: data.body.template_id,
                        report_details_type: data.body.type,
                        report_details_name: data.body.name,
                        disable_filters: true
                    });
                    this.props.pageTitle('Edit Template - ' + data.body.name);
                    if(data.body.description) {
                        this.setState({
                            report_details_desc: data.body.description
                        });
                    }
                    _.forEach(data.body.columns, column => {
                        if(this.state.columns.hasOwnProperty(column)) {
                            this.setState({ columns: { ...this.state.columns, [column]: true}});
                        }
                     });
                     _.forEach(data.body.filters, filter => {
                        this.setState({ filters: { ...this.state.filters, [filter]: true}});
                   });
                } else {
                    this.setState({ loading: false });
                    this.props.setNotification(
                        "There was an error while retrieving the bill of lading report template!",
                        {
                            variant: "error"
                        }
                    );
                }
            } catch (error) {
                console.error(error);
                this.setState({ loading: false });
                this.props.setNotification(
                    "There was an unexpected response from the server while retrieving the bill of lading report template!",
                    {
                        variant: "error"
                    }
                );
            }
        } else {
            this.setState({
                show: true,
                loading: false,
            }, () => {
            	this.props.pageTitle('New Template');
        	});
        }
    }
    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    handleModalOpen(modal) {
        this.setState({ [modal]: true });
    }
    handleModalClose(modal) {
        this.setState({ [modal]: false });
    }
    handleChecked = (stateObj, stateProp) => event => {
        this.setState({ [stateObj]: { ...this.state[stateObj], [stateProp]: !!event.target.checked}});
    };
    handleInput = name => event => {
        this.setState({ [name]: event.target.value });
    };
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleDatetime(name, moment) {
        this.setState({ [name]: moment });
    }
    addCheckbox(stateObj, stateProp, label) {
        const { classes } = this.props;
        return (
            <GridItem xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                            checked={this.state[stateObj][stateProp]}
                            onChange={this.handleChecked(stateObj, stateProp)}
                            style={{marginLeft: '15px'}}
                            disabled={stateObj === "filters" ? this.state.disable_filters : false}
                        />
                    }
                    classes={{
                        label: classes.label
                    }}
                    label={label}
                />
            </GridItem>
        )
    }
    async saveTemplate() {

        const templateName = this.state.report_details_name;

        if(templateName.trim().length <= 0) {
            this.props.setNotification("Template is required to have a name!", { variant: "error" });
        }

        const columns = [];
        const filters = [];

        _.forEach(this.state.columns, (value, key) => {
            if(value) { columns.push(key); }
        });
        _.forEach(this.state.filters, (value, key) => {
            if (key === "warehouse_code" && !this.state.filters["company_name"]) { return; } // Skip warehouse code if company name is not selected
            if(value) { filters.push(key); }
        });

        const data = {
            report_id: this.state.report_details_template_id,
            type: this.state.report_details_type,
            name: this.state.report_details_name,
            description: this.state.report_details_desc,
            columns: columns,
            filters: filters
        }

        try {
            const response = await axios.post("/index.php?p=api&c=reporting&m=saveBolReportTemplate", qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
                if(response.data.body !== false) {
                    this.setState({redirect: <Redirect to={basePath + "/admin/report-templates"} />})
                }
            }
        } catch (error) {
                console.error(error);
                this.props.setNotification("There was an error while saving the template!", { variant: "error" });
        }

    }
    render() {
        if (this.state.redirect) {
			return this.state.redirect;
		}
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.show ? (
                    <GridContainer>
                        <GridItem>
                            <Button
                                color={"linkedin"}
                                onClick={() => this.saveTemplate()}
                            >
                                Save Template
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <NavPills
                                color={"linkedin"}
                                horizontal={{
                                    tabsGrid: { xs: 12, sm: 12, md: 4 },
                                    contentGrid: { xs: 12, sm: 12, md: 8 }
                                }}
                                tabs={[
                                    {
                                        tabButton: "General Details",
                                        tabContent: (
                                            <span>
                                                <InputLabel className={classes.label}>Template Name (Max 50 Characters)</InputLabel>
                                                <CustomInput
                                                    id="report_details_name"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "report_details_name",
                                                        value: this.state.report_details_name || "",
                                                        onChange: this.handleInput("report_details_name")
                                                    }}
                                                    required
                                                />
                                                <InputLabel className={classes.label}>Description</InputLabel>
                                                <CustomInput
                                                    id="report_details_desc"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "report_details_desc",
                                                        value: this.state.report_details_desc || "",
                                                        onChange: this.handleInput("report_details_desc")
                                                    }}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "Columns",
                                        tabContent: (
                                            <span>
                                                <Accordion
                                                    collapses={[
                                                        {
                                                            title: "Shipper",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "shipper_code", "Code")}
                                                                    {this.addCheckbox("columns", "shipper_name", "Name")}
                                                                    {this.addCheckbox("columns", "shipper_address1", "Address 1")}
                                                                    {this.addCheckbox("columns", "shipper_address2", "Address 2")}
                                                                    {this.addCheckbox("columns", "shipper_city", "City")}
                                                                    {this.addCheckbox("columns", "shipper_state", "State")}
                                                                    {this.addCheckbox("columns", "shipper_zip", "Zip")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Consignee",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "consignee_code", "Code")}
                                                                    {this.addCheckbox("columns", "consignee_name", "Name")}
                                                                    {this.addCheckbox("columns", "consignee_address1", "Address 1")}
                                                                    {this.addCheckbox("columns", "consignee_address2", "Address 2")}
                                                                    {this.addCheckbox("columns", "consignee_city", "City")}
                                                                    {this.addCheckbox("columns", "consignee_state", "State")}
                                                                    {this.addCheckbox("columns", "consignee_zip", "Zip")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Bill To",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "bill_to_name", "Name")}
                                                                    {this.addCheckbox("columns", "bill_to_address1", "Address 1")}
                                                                    {this.addCheckbox("columns", "bill_to_address2", "Address 2")}
                                                                    {this.addCheckbox("columns", "bill_to_city", "City")}
                                                                    {this.addCheckbox("columns", "bill_to_state", "State")}
                                                                    {this.addCheckbox("columns", "bill_to_zip", "Zip")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Cost Division",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "costdiv_name", "Name")}
                                                                    {this.addCheckbox("columns", "costdiv_address1", "Address 1")}
                                                                    {this.addCheckbox("columns", "costdiv_address2", "Address 2")}
                                                                    {this.addCheckbox("columns", "costdiv_city", "City")}
                                                                    {this.addCheckbox("columns", "costdiv_state", "State")}
                                                                    {this.addCheckbox("columns", "costdiv_zip", "Zip")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Vendor",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "vendor_name", "Name")}
                                                                    {this.addCheckbox("columns", "vendor_address1", "Address 1")}
                                                                    {this.addCheckbox("columns", "vendor_address2", "Address 2")}
                                                                    {this.addCheckbox("columns", "vendor_city", "City")}
                                                                    {this.addCheckbox("columns", "vendor_state", "State")}
                                                                    {this.addCheckbox("columns", "vendor_zip", "Zip")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Product Data",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "product_sum_weight", "SUM(weight)")}
                                                                    {this.addCheckbox("columns", "product_sum_weight_nf", "Sum(weight)(no format)")}
                                                                    {this.addCheckbox("columns", "product_avg_class", "AVG(class)")}
                                                                    {this.addCheckbox("columns", "product_sum_pieces", "SUM(pieces)")}
                                                                    {this.addCheckbox("columns", "shipment_pcf", "Shipment PCF")}
                                                                    {this.addCheckbox("columns", "product_description", "Product Description")}
                                                                    
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Rate Data",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "rate_rated", "Rated")}
                                                                    {this.addCheckbox("columns", "rate_benchmark", "Benchmark")}
                                                                    {this.addCheckbox("columns", "rate_true", "True Cost")}
                                                                    {this.addCheckbox("columns", "rate_rated_nf", "Rated (no format)")}
                                                                    {this.addCheckbox("columns", "rate_benchmark_nf", "Benchmark (no format)")}
                                                                    {this.addCheckbox("columns", "rate_true_nf", "True Cost (no format)")}
                                                                    {this.addCheckbox("columns", "rate_transit", "Transit Time")}
                                                                    {this.addCheckbox("columns", "lcc_rate_rated", "LCC Rated")}
                                                                    {this.addCheckbox("columns", "lcc_rate_benchmark", "LCC Benchmark")}
                                                                    {this.addCheckbox("columns", "lcc_rate_true", "LCC True Cost")}
                                                                    {this.addCheckbox("columns", "lcc_rate_rated_nf", "LCC Rated (no format)")}
                                                                    {this.addCheckbox("columns", "lcc_rate_benchmark_nf", "LCC Benchmark (no format)")}
                                                                    {this.addCheckbox("columns", "lcc_rate_true_nf", "LCC True Cost (no format)")}
                                                                    {this.addCheckbox("columns", "lcc_rate_transit", "LCC Transit Time")}
                                                                    {this.addCheckbox("columns", "rate_lost_savings", "Lost Savings")}
                                                                    {this.addCheckbox("columns", "rate_gross_freight", "Gross Freight")}
                                                                    {this.addCheckbox("columns", "rate_net_freight", "Net Freight")}
                                                                    {this.addCheckbox("columns", "rate_fuel", "Fuel")}
                                                                    {this.addCheckbox("columns", "rate_accessorial_total", "Accessorial Total")}
                                                                    {this.addCheckbox("columns", "rate_discount", "Discount")}
                                                                    {this.addCheckbox("columns", "rate_min", "Min")}
                                                                    {this.addCheckbox("columns", "rate_bm_gross_freight", "Benchmark Gross Freight")}
                                                                    {this.addCheckbox("columns", "rate_bm_net_freight", "Benchmark Net Freight")}
                                                                    {this.addCheckbox("columns", "rate_bm_fuel", "Benchmark Fuel")}
                                                                    {this.addCheckbox("columns", "rate_bm_accessorial_total", "Benchmark Accessorial Total")}
                                                                    {this.addCheckbox("columns", "rate_bm_discount", "Benchmark Discount")}
                                                                    {this.addCheckbox("columns", "rate_bm_min", "Benchmark Min")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "General Data",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "general_terms", "Terms")}
                                                                    {this.addCheckbox("columns", "general_pro", "PRO #")}
                                                                    {this.addCheckbox("columns", "general_bol", "BoL #")}
                                                                    {this.addCheckbox("columns", "general_custom", "Custom #")}
                                                                    {this.addCheckbox("columns", "dynamic_bol_num", "Dynamic BoL #")}
                                                                    {this.addCheckbox("columns", "general_ref", "Reference #")}
                                                                    {this.addCheckbox("columns", "general_so", "Order/SO #")}
                                                                    {this.addCheckbox("columns", "general_po", "PO #")}
                                                                    {this.addCheckbox("columns", "general_status", "Status")}
                                                                    {this.addCheckbox("columns", "date_executed", "Date Executed")}
                                                                    {this.addCheckbox("columns", "shipped_date", "Shipped Date")}
                                                                    {this.addCheckbox("columns", "estimated_delivery", "Expected Delivery Date")}
                                                                    {this.addCheckbox("columns", "time_executed", "Time Executed")}
                                                                    {this.addCheckbox("columns", "warehouse_code", "Warehouse Code")}
                                                                    {this.addCheckbox("columns", "company_username", "Username")}
                                                                    {this.addCheckbox("columns", "special_notes", "Special Instructions")}
                                                                    {this.addCheckbox("columns", "ttl_cost_change_note", "TTL Cost Change Note")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Carrier Data",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "carrier_name", "Name")}
                                                                    {this.addCheckbox("columns", "carrier_scac", "SCAC")}
                                                                    {this.addCheckbox("columns", "lcc_carrier_name", "LCC Name")}
                                                                    {this.addCheckbox("columns", "lcc_carrier_scac", "LCC SCAC")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Tracking",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "estimated_delivery", "Estimated Delivery")}
                                                                    {this.addCheckbox("columns", "transit_status", "Transit Status")}
                                                                    {this.addCheckbox("columns", "latest_carrier_tracking_msg", "Lastest Carrier Tracking Update")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Dates",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "original_pd", "Original Pickup Date")}
                                                                    {this.addCheckbox("columns", "pickup_date", "Pickup Date")}
                                                                    {this.addCheckbox("columns", "original_edd", "Original Estimated Delivery Date")}
                                                                    {this.addCheckbox("columns", "delivery_date", "Delivery Date")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "TFM Central Data",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "billed_amount", "Billed Amount")}
                                                                    {this.addCheckbox("columns", "billed_true_cost", "Billed True Cost")}
                                                                    {this.addCheckbox("columns", "tfm_invoice_num", "TFM Invoice No")}
                                                                    {this.addCheckbox("columns", "gl_code", "GL Code")}
                                                                    {this.addCheckbox("columns", "pc_cc", "PC/CC")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Misc",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("columns", "non_lcc_selection_code", "Non LCC Selection Code")}
                                                                    {this.addCheckbox("columns", "non_lcc_selection_description", "Non LCC Selection Description")}
                                                                    {this.addCheckbox("columns", "non_lcc_selection_user_description", "Non LCC Selection User Description")}
                                                                    {this.addCheckbox("columns", "non_lcc_selection_username", "Non LCC Selection Username")}
                                                                    {this.addCheckbox("columns", "non_lcc_selection_timestamp", "Non LCC Selection Timestamp")}
                                                                </GridContainer>
                                                        }
                                                    ]}
                                                />
                                            </span>
                                        )
                                    },
                                    {
                                        tabButton: "Filters",
                                        tabContent: (
                                            <span>
                                                <Accordion
                                                    collapses={[
                                                        {
                                                            title: "Company",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("filters", "company_name", "Name")}
                                                                    {this.addCheckbox("filters", "company_username", "Username")}
                                                                    {this.state['filters']['company_name'] && this.addCheckbox("filters", "warehouse_code", "Warehouse")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Date",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("filters", "date_executed", "Execution Date")}
                                                                    {this.addCheckbox("filters", "shipped_date", "Shipped Date")}
                                                                    {this.addCheckbox("filters", "estimated_delivery", "Expected Delivery Date")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "BoL Status",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("filters", "exclude_statuses", "Excluded BoLs by Status")}
                                                                    {this.addCheckbox("filters", "transit_status", "Include BoLs by Transit Status")}
                                                                </GridContainer>
                                                        },
                                                        {
                                                            title: "Misc",
                                                            content:
                                                                <GridContainer>
                                                                    {this.addCheckbox("filters", "non_lcc_selection", "Show only BoLs associated with Non LCC Selection Data")}
                                                                </GridContainer>
                                                        }
                                                    ]}
                                                />
                                            </span>
                                        )
                                    }
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                ) : (
                    <Spinner
                        loading={this.state.loading}
                        message="Failed to retrieve previously saved template from the server"
                    />
                )}
            </GridContainer>
        );
    }
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(ReportTemplates));
