// npm packages
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { flushSync } from 'react-dom'; // Note: react-dom, not react
// custom variables
import axios from "~/variables/axios.jsx";
import { basePath, apiUrl } from "~/variables/server.jsx";
import { connect } from "react-redux";
import { clearNotifications } from "~/redux/actions/notifications.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// @material-ui/lab components
import Autocomplete from "@material-ui/lab/Autocomplete";
import Warning from "@material-ui/icons/Warning";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Schedule from "@material-ui/icons/Schedule";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import CallSplit from "@material-ui/icons/CallSplit";
import MonetizationOnOutlined from "@material-ui/icons/MonetizationOnOutlined";
import MoneyOff from "@material-ui/icons/MoneyOff";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Note from "@material-ui/icons/Note";
import Save from "@material-ui/icons/Save";
import Undo from "@material-ui/icons/Undo";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Table from "~/components/Table/Table.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import InputList from "~/components/TMS/InputList.jsx";
import InputTag from "~/components/TMS/InputTag.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import WarehouseManagement from "../../WarehouseManagement/containers/WarehouseManagementContainer.jsx";
import Favorites from "~/components/Favorites/Favorites.jsx";


import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

// react-icons
import { FaFileInvoice, FaTruckMoving, FaWarehouse, FaRoute, FaExchangeAlt, FaTruckLoading, FaMapMarkerAlt, FaGlobe } from "react-icons/fa";

class EditCompany extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			alert: null,
			show: false,
			loading: true,
			user: null,
			company: {},
			company_ftl_scacs: [],
			tfm_scacs: [],
			active: "info",
			splits: [],
			profiles: [],
			assigned_profs: [],
			pallets: {
				company: {},
				records: [],
				filters: [],
				current: "",
				next: "",
				previous: "",
				pages: ""
			},
			info: {},
			rulesMultiple: {},
			linearFeetRules: [],
			carriers: [],
			carrier_paging: {
				current: 1,
				last: 1,
				pages: [1],
			},
			favorite_carriers: [],
			guides: [],
			unedited_guides: [],
			scac_list: [],
			users: [],
			favorite_users: [],
			warehouses: [],
			name: "",
			id: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			billing_type: "",
			calc_type: "",
			calc_value: "",
			min_charge: "",
			min_profit_margin: "",
			passthru: "",
			max_weight: 10000,
			require_dims: false,
			check_residential: false,
			enable_outbound_collect: false,
			enable_prepaid_and_charge: false,
			enable_miler: false,
			enable_claims: false,
			enable_liability: false,
			enable_total_declared_value: false,
			enable_blind_shipments: false,
			enable_fedex_address_validation: false,
			enable_parcel_dg: false,
			enable_dgd: false,
			auto_calc_expected_delivery: false,
			custom_piece_count: false,
			enable_costco_label: false,
			claims_contact_first_name: "",
			claims_contact_last_name: "",
			claims_contact_email: "",
			claims_contact_phone: "",
			pure_abf_bm: false,
			use_special_pallets: false,
			tl_markup_value: "",
			enable_tl_quote: false,
			enable_auto_class: false,
			np_fee_amount: 0,
			np_fee_type: "flat",
			allow_auto_pro: false,
			ctsi_bm_accessorial: false,
			sync_tfmc: false,
			prevent_dup_identifiers: false,
			unique_po: false,
			unique_so: false,
			unique_ref: false,
			enable_new_ln_ft_method: false,
			custom_trailer_length: "336",
			custom_trailer_width: "96",
			custom_trailer_height: "96",
			auto_cancel_bols: false,
			bol_max_life: "",
			show_hus: false,
			show_pcs: false,
			print_uploaded_bol_images: false,
			require_pro: false,
			require_po: false,
			require_so: false,
			require_ref: false,
			require_cons_contact: false,
			require_cons_phone: false,
			require_cons_email: false,
			require_cons_fax: false,
			ref_entry_type: "freeform",
			ref_entry_opts: [],
			so_text_area: false,
			show_execute_warning: false,
			require_pod_for_billing: false,
			non_lcc: {
				company_id: "",
				non_lcc_selection_options: [],
				company_non_lcc_selection_options: [],
				details: {
					require_non_lcc_selection_explanation: 0,
					non_lcc_selection_send_email: 0,
					warehouse_emails: []
				}
			},
			require_non_lcc_selection_explanation: false,
			non_lcc_selection_send_email: false,
			hide_tl_carriers: false,
			enable_acc_recommendations: false,
			p44_formats: [],
			p44_document_format: "PNG",
			warehouse_emails: [],
			selection_options: [],
			non_lcc_loading: false,
			volume_markup: "",
			parcel_weight_limit: 0,
			created: "",
			create_by: "",
			edited: "",
			edit_by: "",
			notes: [],
			currentWarehouse: "",
			currentWarehouseTab: 0,
			currentWarehouseUsers: [],

			// Intl Settings
			require_master_bill: false,
			require_house_bill: false,
			require_airway_bill: false,
			require_isf: false,
			require_intl_po: false,
			require_intl_so: false,
			require_intl_ref: false,


		};
		this.handleSelect = this.handleSelect.bind(this);
		this.handleMultiple = this.handleMultiple.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.getWarehouses = this.getWarehouses.bind(this);
		this.switchUser = this.switchUser.bind(this);
		this.getSplits = this.getSplits.bind(this);
		this.addSplit = this.addSplit.bind(this);
		this.handleSplit = this.handleSplit.bind(this);
		this.cancelSplit = this.cancelSplit.bind(this);
		this.handleDeleteCompanyCarrier = this.handleDeleteCompanyCarrier.bind(this);
		this.disableUser = this.disableUser.bind(this);
		this.enableUser = this.enableUser.bind(this);
		this.handleDisable = this.handleDisable.bind(this);
		this.grantApiKey = this.grantApiKey.bind(this);
		this.disableApiKey = this.disableApiKey.bind(this);
		this.changeWarehouseStatus = this.changeWarehouseStatus.bind(this);
		this.getProfiles = this.getProfiles.bind(this);
		this.handleProfiles = this.handleProfiles.bind(this);
		this.saveProfiles = this.saveProfiles.bind(this);
		this.getRoutingGuides = this.getRoutingGuides.bind(this);
		this.addRoutingGuide = this.addRoutingGuide.bind(this);
		this.editRoutingGuide = this.editRoutingGuide.bind(this);
		this.handleRoutingGuide = this.handleRoutingGuide.bind(this);
		this.cancelRoutingGuide = this.cancelRoutingGuide.bind(this);
		this.saveRoutingGuide = this.saveRoutingGuide.bind(this);
		this.deleteRoutingGuide = this.deleteRoutingGuide.bind(this);
		this.getWarehouseEmails = this.getWarehouseEmails.bind(this);
		this.handleWarehouseEmail = this.handleWarehouseEmail.bind(this);
		this.getSelectionOptions = this.getSelectionOptions.bind(this);
		this.addSelectionOption = this.addSelectionOption.bind(this);
		this.editSelectionOption = this.editSelectionOption.bind(this);
		this.handleSelectionOption = this.handleSelectionOption.bind(this);
		this.cancelSelectionOption = this.cancelSelectionOption.bind(this);
		this.saveSelectionOption = this.saveSelectionOption.bind(this);
		this.deleteSelectionOption = this.deleteSelectionOption.bind(this);
		this.saveOptions = this.saveOptions.bind(this);
		this.loadNotes = this.loadNotes.bind(this);
		this.getNotes = this.getNotes.bind(this);
		this.addNote = this.addNote.bind(this);
		this.handleNote = this.handleNote.bind(this);
		this.cancelNote = this.cancelNote.bind(this);
		this.saveNote = this.saveNote.bind(this);
		this.handleDeleteNote = this.handleDeleteNote.bind(this);
		this.deleteNote = this.deleteNote.bind(this);
		this.setCurrentWarehouse = this.setCurrentWarehouse.bind(this);
		this.setCurrentWarehouseTab = this.setCurrentWarehouseTab.bind(this);
		this.removeUserFromWarehouse = this.removeUserFromWarehouse.bind(this);
		this.addUserToWarehouse = this.addUserToWarehouse.bind(this);
		this.removeCarrierFromWarehouse = this.removeCarrierFromWarehouse.bind(this);
		this.saveCarrierToWarehouse = this.saveCarrierToWarehouse.bind(this);
	}

	async componentDidMount() {
		const { classes } = this.props;
		this.setState({
			id: this.props.match.params.id,
			mounted: true
		});
		if (this.props.match.params.id) {
			try {
				const response = await axios.get("/index.php?p=api&r=json&c=admin&m=editCompany&d=" + this.props.match.params.id);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					const body = response.data.body;
					const companySplits = await axios.get("/index.php?p=api&r=json&c=admin&m=companySplits&d=" + this.props.match.params.id);

					let splits = [];
					if (typeof companySplits.data !== "string" && !_.isEmpty(companySplits.data.body)) {
						splits = companySplits.data.body.splits;
					}

					const accsProfiles = await axios.get("/index.php?p=api&r=json&c=admin&m=updateCompanyAccProfiles&d=" + this.props.match.params.id);

					let profiles = [];
					let assigned_profs = [];

					if (typeof accsProfiles.data !== "string" && !_.isEmpty(accsProfiles.data.body)) {

						assigned_profs = Object.keys(accsProfiles.data.body.assigned_profs);

						profiles = Object.entries(accsProfiles.data.body.profiles).map(e => {
							const prop = e[1];
							prop.checked = false;
							if (assigned_profs.indexOf(prop.id) !== -1) {
								prop.checked = true;
							}
							return prop;
						});

					}

					const companyCarriers = await axios.get("/index.php?p=api&r=json&c=companyCarriers&m=index&d=" + this.props.match.params.id + "/1/1999");

					let carriers = [];
					let carrier_paging = {current: 1, last: 1, pages: [1]};
					if (typeof companyCarriers.data !== "string" && !_.isEmpty(companyCarriers.data.body)) {
						carriers = companyCarriers.data.body.result;
						carrier_paging = {
							current: companyCarriers.data.body.current,
							last: companyCarriers.data.body.last,
							pages: companyCarriers.data.body.pages,
						}
					}

					const routingGuides = await axios.get("/index.php?p=api&r=json&c=admin&m=getRoutingGuides&d=" + this.props.match.params.id);

					let guides = [];
					if (!_.isEmpty(routingGuides.data.body) && !_.isEmpty(routingGuides.data.body.guides)) {
						guides = routingGuides.data.body.guides;
					}

					const search = {
						queries: [
							{
								field: "scac",
								compare: "starts with",
								value: ""
							}
						]
					};

					const scacList = await axios.post("/index.php?p=api&c=search&m=carrier", qs.stringify(search));
					const scac_list = !_.isEmpty(scacList.data.body) ? scacList.data.body : [];

					const nonLccSelection = await axios.get("/index.php?p=api&r=json&c=admin&m=companyNonLccSelection&d=" + this.props.match.params.id);
					let { non_lcc, warehouse_emails, selection_options, require_non_lcc_selection_explanation, non_lcc_selection_send_email } = this.state;
					if (typeof nonLccSelection.data !== "string" && !_.isEmpty(nonLccSelection.data.body)) {
						non_lcc = nonLccSelection.data.body;

						require_non_lcc_selection_explanation = nonLccSelection.data.body.details.require_non_lcc_selection_explanation == 1;
						non_lcc_selection_send_email = nonLccSelection.data.body.details.non_lcc_selection_send_email == 1;
						warehouse_emails = nonLccSelection.data.body.details.warehouse_emails;

						const company_non_lcc_selection_options = nonLccSelection.data.body.company_non_lcc_selection_options;
						selection_options = nonLccSelection.data.body.non_lcc_selection_options.map((prop, key) => {
							prop.checked = false;
							if (company_non_lcc_selection_options.indexOf(prop.id) !== -1) {
								prop.checked = true;
							}
							return prop;
						});
					}

					const ref_entry_opts = body.info[0].ref_entry_opts.map(opt => {
						return opt.value;
					});

					const p44Formats = await axios.get("/index.php?p=api&c=admin&m=getP44DocumentFormats");
					const p44_formats = !_.isEmpty(p44Formats.data.p44_doc_formats) ? p44Formats.data.p44_doc_formats : [];

					const intl_settings = !_.isEmpty(body.info[0].intl_settings) ? body.info[0].intl_settings : false;

					let company_ftl_scacs = body.company_ftl_scacs;
					let tfm_scacs = body.tfm_scacs;
					let filtered_favorite_users = body.favorite_users.filter(fav_user => body.users.some( user => {return fav_user.user_credential_id == user.id}));
					filtered_favorite_users = filtered_favorite_users.map(favorite => favorite.user_credential_id);

					let filtered_favorite_carriers = [];
					let favoritesCompCarrierAccts = await axios.get("/index.php?p=api&r=json&c=admin&m=getFavorites&table_name=company_carrier_accounts");
					if (typeof favoritesCompCarrierAccts !== "string" && !_.isEmpty(favoritesCompCarrierAccts.message)) {
						this.props.handleMessages(favoritesCompCarrierAccts);
					}
					if (typeof favoritesCompCarrierAccts.data !== "string" && !_.isEmpty(favoritesCompCarrierAccts.data.body) && favoritesCompCarrierAccts.data.body.favorites) {
						favoritesCompCarrierAccts.data.body.favorites.forEach((item) => {
							filtered_favorite_carriers.push(item.id);
						});
					}

					let { currentWarehouse, currentWarehouseTab, active } = this.state;

					const whMgtId = JSON.parse(localStorage.getItem("tms_wh_mgt_id"));
					if(whMgtId && body.warehouses) {
						currentWarehouse = body.warehouses.filter((wh) => wh.id == whMgtId.whId)[0] || "";
						if(typeof currentWarehouse === "object") {
							currentWarehouseTab = whMgtId.tab;
							active = 'warehouses';
						}
					}

					localStorage.removeItem("tms_wh_mgt_id");

					this.props.pageTitle('Edit Company - ' + body.info[0].name);
					this.setState({
						show: true,
						loading: false,
						user: response.data.user,
						profiles: !_.isEmpty(profiles) ? _.orderBy(profiles, ["name"], ["asc"]) : [],
						company_ftl_scacs,
						tfm_scacs,
						splits,
						assigned_profs,
						non_lcc,
						require_non_lcc_selection_explanation,
						non_lcc_selection_send_email,
						warehouse_emails,
						selection_options,
						guides,
						carriers,
						carrier_paging,
						favorite_carriers: filtered_favorite_carriers,
						scac_list,
						info: body.info[0] || {},
						linearFeetRules: body.linearFeetRules || [],
						users: body.users || [],
						favorite_users: filtered_favorite_users,
						warehouses: body.warehouses || [],
						name: body.info[0].name || "",
						id: body.info[0].company_name_id || "",
						address1: body.info[0].address1 || "",
						address2: body.info[0].address2 || "",
						city: body.info[0].city || "",
						state: body.info[0].state || "",
						zip: body.info[0].zip || "",
						billing_type: body.info[0].billing_type || "",
						calc_type: body.info[0].calc_type || "",
						calc_value: body.info[0].calc_value || "",
						min_charge: body.info[0].min_charge || "",
						min_profit_margin: body.info[0].min_profit_margin || "",
						passthru: body.info[0].passthru || "",
						max_weight: body.info[0].max_weight || "",
						require_dims: body.info[0].require_dims == 1 ? true : false,
						check_residential: body.info[0].check_residential == 1 ? true : false,
						enable_outbound_collect: body.info[0].enable_outbound_collect == 1 ? true : false,
						enable_prepaid_and_charge: body.info[0].enable_prepaid_and_charge == 1 ? true : false,
						enable_miler: body.info[0].enable_miler == 1 ? true : false,
						enable_claims: body.info[0].enable_claims == 1 ? true : false,
						enable_liability: body.info[0].enable_liability == 1 ? true : false,
						enable_total_declared_value: body.info[0].enable_total_declared_value == 1 ? true : false,
						enable_blind_shipments: body.info[0].enable_blind_shipments == 1 ? true : false,
						enable_fedex_address_validation: body.info[0].enable_fedex_address_validation == 1 ? true : false,
						enable_parcel_dg: body.info[0].enable_parcel_dg == 1 ? true : false,
						enable_dgd: body.info[0].enable_dgd == 1 ? true : false,
						enable_acc_recommendations: body.info[0].enable_acc_recommendations == 1 ? true : false,
						auto_calc_expected_delivery: body.info[0].auto_calc_expected_delivery == 1 ? true : false,
						hide_tl_carriers: body.info[0].hide_tl_carriers == 1 ? true : false,
						p44_formats: p44_formats || [],
						p44_document_format: body.info[0].p44_doc_format_id || "",
						custom_piece_count: body.info[0].custom_piece_count == 1 ? true : false,
						enable_costco_label: body.info[0].enable_costco_label== 1 ? true : false,
						claims_contact_first_name: body.info[0].claims_contact_first_name || "",
						claims_contact_last_name: body.info[0].claims_contact_last_name || "",
						claims_contact_email: body.info[0].claims_contact_email || "",
						claims_contact_phone: body.info[0].claims_contact_phone || "",
						pure_abf_bm: body.info[0].pure_abf_bm == 1 ? true : false,
						use_special_pallets: body.info[0].use_special_pallets == 1 ? true : false,
						tl_markup_value: body.info[0].tl_markup_value || "",
						enable_tl_quote: body.info[0].enable_tl_quote == 1 ? true : false,
						enable_auto_class: body.info[0].enable_auto_class == 1 ? true : false,
						np_fee_amount: body.info[0].np_fee_amount,
						np_fee_type: body.info[0].np_fee_type,
						allow_auto_pro: body.info[0].allow_auto_pro == 1 ? true : false,
						ctsi_bm_accessorial: body.info[0].ctsi_bm_accessorial == 1 ? true : false,
						sync_tfmc: body.info[0].sync_tfmc == 1 ? true : false,
						prevent_dup_identifiers: body.info[0].prevent_dup_identifiers == 1 ? true : false,
						unique_po: body.info[0].unique_po == 1 ? true : false,
						unique_so: body.info[0].unique_so == 1 ? true : false,
						unique_ref: body.info[0].unique_ref == 1 ? true : false,
						enable_new_ln_ft_method: body.info[0].enable_new_ln_ft_method == 1 ? true : false,
						custom_trailer_length: body.info[0].custom_trailer_length || "",
						custom_trailer_width: body.info[0].custom_trailer_width || "",
						custom_trailer_height: body.info[0].custom_trailer_height || "",
						auto_cancel_bols: body.info[0].auto_cancel_bols == 1 ? true : false,
						bol_max_life: body.info[0].bol_max_life || "",
						show_hus: body.info[0].show_hus == 1 ? true : false,
						show_pcs: body.info[0].show_pcs == 1 ? true : false,
						print_uploaded_bol_images: body.info[0].print_uploaded_bol_images == 1 ? true : false,
						require_pro: body.info[0].require_pro == 1 ? true : false,
						require_po: body.info[0].require_po == 1 ? true : false,
						require_so: body.info[0].require_so == 1 ? true : false,
						require_ref: body.info[0].require_ref == 1 ? true : false,
						require_cons_contact: body.info[0].require_cons_contact == 1 ? true : false,
						require_cons_phone: body.info[0].require_cons_phone == 1 ? true : false,
						require_cons_email: body.info[0].require_cons_email == 1 ? true : false,
						require_cons_fax: body.info[0].require_cons_fax == 1 ? true : false,
						ref_entry_type: body.info[0].ref_entry_type,
						ref_entry_opts: ref_entry_opts,
						so_text_area: body.info[0].so_text_area == 1 ? true : false,
						show_execute_warning: body.info[0].show_execute_warning == 1 ? true : false,
						require_pod_for_billing: body.info[0].require_pod_for_billing == 1 ? true : false,
						volume_markup: body.info[0].volume_markup,
						parcel_weight_limit: +body.info[0].parcel_weight_limit,
						created: body.info[0].created,
						create_by: body.info[0].create_by,
						edited: body.info[0].edited,
						edit_by: body.info[0].edit_by,
						require_master_bill: intl_settings && intl_settings.require_master_bill == 1 ? true : false,
						require_house_bill: intl_settings && intl_settings.require_house_bill == 1 ? true : false,
						require_airway_bill: intl_settings && intl_settings.require_airway_bill == 1 ? true : false,
						require_isf: intl_settings && intl_settings.require_isf == 1 ? true : false,
						require_intl_po: intl_settings && intl_settings.require_intl_po == 1 ? true : false,
						require_intl_so: intl_settings && intl_settings.require_intl_so == 1 ? true : false,
						require_intl_ref: intl_settings && intl_settings.require_intl_ref == 1 ? true : false,
						currentWarehouse,
						active,
						currentWarehouseTab
					});
					this.loadNotes();
				} else {
					this.setState({ loading: false });
					this.props.setNotification("There was an error loading the company data!", {
						variant: "error"
					});
				}
			} catch (error) {
				this.setState({ loading: false });
				console.error(error);
				this.props.setNotification("An exception occurred loading the company data!", {
					variant: "error"
				});
			}
		} else {
			this.setState({ loading: false });
			this.props.setNotification("Cannot load company data! Company ID is missing!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleChange = name => event => {
		if(name === "enable_total_declared_value" && this.state.enable_liability === true) {
			this.setState({ enable_liability: false, enable_total_declared_value: event.target.checked  })
		} else if(name === "enable_liability" && this.state.enable_total_declared_value === true ) {
			this.setState({ enable_total_declared_value: false, enable_liability: event.target.checked  })
		}else {
			this.setState({ [name]: event.target.checked });
		}
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleSelect(event) {
		if(event.target.name === 'ref_entry_type') {
			const ref_entry_type = event.target.value;
			const ref_entry_opts = (ref_entry_type === 'select') ? [...this.state.ref_entry_opts] : [];
			this.setState({ ref_entry_type, ref_entry_opts });
		} else {
			this.setState({ [event.target.name]: event.target.value });
		}
	}

	handleFtlScacAssignment = (event, values) => {
		this.setState({ company_ftl_scacs: values });
	}

	handleMultiple(event) {
		const { classes } = this.props;

		const { guides } = this.state;
		const key = guides.findIndex(carrier => carrier[1] == event.target.name);
		const guide = guides[key];

		const lastKey = guide.length - 1;

		guide[lastKey] = (
			<Select
				multiple
				value={event.target.value}
				onChange={this.handleMultiple}
				MenuProps={{ className: classes.selectMenu }}
				classes={{ select: classes.select }}
				inputProps={{
					name: event.target.name,
					id: event.target.id
				}}
			>
				{this.state.rulesMultiple[event.target.name]}
			</Select>
		);

		this.setState({ [event.target.name]: event.target.value });
	}

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}

	displayP44Formats(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<MenuItem
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.format_type}
				</MenuItem>
			];
		});
	}

	getWarehouses(data) {
		return data.map((prop, key) => {
			const editLink = basePath + "/admin/warehouse/edit/" + prop.id;
			return [
				prop.id,
				prop.name,
				prop.code,
				<div className="actions-right">
					{prop.active == 1 ? (
						<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.changeWarehouseStatus(key, 0)}>
							Active
						</Button>
					) : (
						<Button size="sm" color="black" style={{ marginRight: "4px" }} onClick={() => this.changeWarehouseStatus(key, 1)}>
							Disabled
						</Button>
					)}
					<NavLink to={editLink}>
						<Button size="sm" color="info" style={{ marginRight: "4px" }}>
							View/Edit
						</Button>
					</NavLink>
				</div>
			];
		});
	}

	setCurrentWarehouse(wh) {
		this.setState({currentWarehouse: wh});
	}

	setCurrentWarehouseTab(idx) {
		this.setState({currentWarehouseTab: idx})
	}

	async changeWarehouseStatus(i, active) {
		const { warehouses } = this.state;
		status = active ? "enable" : "disable";
		if (_.isEmpty(warehouses[i]) || warehouses[i].active == active) {
			return;
		}
		const url = `/index.php?p=api&r=json&c=admin&m=changeWarehouseStatus&d=${warehouses[i].id}/${status}/company/${this.props.match.params.id}`;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				warehouses[i].active = active;
				this.setState({ warehouses });
			} else {
				this.props.setNotification("There was an error changing the warehouse status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred changing the warehouse status!", { variant: "error" });
		}
	}

	getUsers(data) {
		let allFavorites = this.state.favorite_users;
		// move favoritedResults to the top of the list.
		let favoritedResults = data.filter(user => {
			return allFavorites.includes(user.id)
		});
		let remainingResults = data.filter(user => {
			return !allFavorites.includes(user.id)
		});

		data = favoritedResults.concat(remainingResults);
		return data.map((prop, key) => {
			const link = basePath + "/admin/company-" + this.props.match.params.id + "/user/edit/" + prop.id;
			const copyLink = `${basePath}/admin/company-${this.props.match.params.id}/user/new?copy=${prop.id}`
			const codes = Object.values(prop.codes).map((code, index) => <li key={index}>{code}</li>);
			const buttonStyle = {marginRight: "4px", paddingRight: "10px", paddingLeft: "10px"};
			return [
				prop.id,
				prop.username,
				<ul key={0}>{codes}</ul>,
				prop.permission_group,
				prop.rate_display,
				prop.key,
				<div className="actions-right" key={1}>
					{prop.active == 1 ? (
						<Button size="sm" color="success" style={buttonStyle} onClick={() => this.disableUser(key)}>
							Active
						</Button>
					) : (
						<Button size="sm" color="black" style={buttonStyle} onClick={() => this.enableUser(key)}>
							Disabled
						</Button>
					)}
					<Favorites
						id={prop.id}
						favorites={allFavorites}
						filters={[]}
						tableName={'user_credential'}
						favoriteItem={(results) => this.favoriteItem(results, "user_credential")}
					/>
					<NavLink to={link}>
						<Button size="sm" color="info" style={buttonStyle}>
							View/Edit
						</Button>
					</NavLink>
					<Button size="sm" color="warning" style={buttonStyle} onClick={() => this.switchUser(prop.id)}>
						Switch User
					</Button>
					{_.isEmpty(prop.key) ? (
						<Button size="sm" color="white" style={buttonStyle} onClick={() => this.grantApiKey(key)}>
							Grant API
						</Button>
					) : (
						<Button size="sm" color="black" style={buttonStyle} onClick={() => this.handleDisable(key)}>
							Disable API
						</Button>
					)}
					<NavLink to={copyLink} style={{color: "white"}}>
						<Button color="info" size="sm" style={buttonStyle}>
							Copy
						</Button>
					</NavLink>
				</div>
			];
		});
	}

	favoriteItem(favoriteResults, tableName) {
		if(tableName == "user_credential") {
			this.setState({
				favorite_users: favoriteResults.favorites.map((item) => item.favorite_item == true ? item.user_credential_id : null)
			});

		} else if(tableName == "company_carrier_accounts") {
			this.setState({
				favorite_carriers: favoriteResults.favorites.map((item) => item.favorite_item == true ? item.id : null)
			});
		}
	}

	async enableUser(i) {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || users[i].active == 1) {
			return;
		}

		const url = "/index.php?p=api&r=json&c=admin&m=changeUserStatus&d=" + users[i].id + "/enable/user";

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				users[i].active = 1;
				this.setState({ users });
			} else {
				this.props.setNotification("There was an error enabling the user account!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred enabling the user account!", { variant: "error" });
		}
	}

	async disableUser(i) {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || users[i].active != 1) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&m=changeUserStatus&d=" + users[i].id + "/disable/user";

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				users[i].active = 0;
				this.setState({ users });
			} else {
				this.props.setNotification("There was an error disabling the user account!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred disabling the user account!", { variant: "error" });
		}
	}

	async grantApiKey(i) {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || !_.isEmpty(users[i].key)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&m=grantAPIKey&d=" + users[i].id + "/" + this.props.match.params.id;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const res = await axios.get("/index.php?p=api&r=json&c=admin&m=editCompany&d=" + this.props.match.params.id);
				if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
					this.props.handleMessages(res);
				}
				if (typeof res.data !== "string" && !_.isEmpty(res.data.body)) {
					flushSync(() => {
						this.setState({ users: res.data.body.users });
					});
					this.setState({ users: res.data.body.users });
				} else {
					this.props.setNotification("grantAPI - There was an error getting the company users!", { variant: "error" });
				}
			} else {
				this.props.setNotification("There was an error granting the user an API key!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred granting the user an API key!", { variant: "error" });
		}
	}

	handleDisable(i) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to disable this user's API Key?"
					onConfirm={() => this.disableApiKey(i)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					Their existing API key will be destroyed and cannot be recovered!
				</SweetAlert>
			)
		});
	}

	async disableApiKey(i) {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || _.isEmpty(users[i].key)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&confirm=1&m=disableAPIKey&d=" + users[i].id + "/" + this.props.match.params.id;
		this.setState({ alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				users[i].key = null
				this.setState({ users });
			} else {
				this.props.setNotification("There was an error disabling the user's API key!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred disabling the user's API key!", { variant: "error" });
		}
	}

	removeUserFromWarehouse = async (idx) => {
		const { users, currentWarehouse, id } = this.state;
		const uid = users[idx].id || false;
		const whId = currentWarehouse.id;
		const cid = id;

		if(uid) {
			const url = `/index.php?p=api&r=json&c=admin&m=editWarehouseUserAssignment&uid=${uid}&whId=${whId}&cid=${cid}&action=delete`;
			try {
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.users) {
					delete users[idx].codes[whId];
					flushSync(() => {
						this.setState({
							users,
							currentWarehouseUsers: response.data.body.users
						})
					})
				} else {
					this.props.setNotification("Error removing user from warehouse.", { variant: "error" });
				}
			}catch (error) {
				console.error(error);
				this.props.setNotification("Error removing user from warehouse.", { variant: "error" });
			}
		}
	}

	addUserToWarehouse = async (idx) => {
		const { users, currentWarehouse, id } = this.state;
		const uid = users[idx].id || false;
		const whId = currentWarehouse.id;
		const cid = id;

		if(uid) {
			const url = `/index.php?p=api&r=json&c=admin&m=editWarehouseUserAssignment&uid=${uid}&whId=${whId}&cid=${cid}&action=save`;
			try {
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.users) {
					const warehouseUser = response.data.body.users.find(whUser => whUser.id == uid)
					users[idx].codes = warehouseUser.codes;
					flushSync(() => {
						this.setState({
							users,
							currentWarehouseUsers: response.data.body.users
						})
					})
				} else {
					this.props.setNotification("Error assigning user to warehouse.", { variant: "error" });
				}
			}catch (error) {
				console.error(error);
				this.props.setNotification("Error assigning user to warehouse.", { variant: "error" });
			}
		}
	}

	removeCarrierFromWarehouse = async (whAcctId, idx) => {
		let { carriers } = this.state;
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=companyCarriers&m=deleteWarehouseAccount&d=${whAcctId}`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && data.body) {
				const whIdsIdx = carriers[idx].warehouse_ids.findIndex((whAcct) => whAcct.id == whAcctId);
				carriers[idx].warehouse_ids.splice(whIdsIdx, 1);
				flushSync(() => {
					this.setState({ carriers  });
				})
			} else {
				this.props.enqueueSnackbar("Error removing carrier from selected warehouse!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.enqueueSnackbar("An exception occurred removing carrier from selected warehouse!", {
				variant: "error"
			});
		}
	}

	saveCarrierToWarehouse = async (compAcctId, whId, idx) => {
		let { carriers } = this.state;
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=companyCarriers&m=saveWarehouseAccount&d=${compAcctId}/${whId}`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && data.body.result) {
				const whCarrierAcct = data.body.result
				carriers[idx].warehouse_ids.push(whCarrierAcct);
				flushSync(() => {
					this.setState({ carriers  });
				})
			} else {
				this.props.enqueueSnackbar("Error saving carrier to selected warehouse!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.enqueueSnackbar("An exception occurred saving carrier to selected warehouse!", {
				variant: "error"
			});
		}
	}

	getSplits(data) {
		const { classes } = this.props;
		const splits = data.map((prop, key) => {
			if (!isNaN(parseInt(prop.id))) {
				const link = basePath + "/admin/company-" + this.props.match.params.id + "/lane-splits/" + prop.id;
				return [
					prop.id,
					prop.date,
					prop.newBillingType,
					prop.newCalcType,
					prop.newSplit,
					prop.min,
					prop.minProfit,
					prop.passthru,
					_.isEmpty(prop.applied) || prop.applied === "Never" ? "Never" : prop.applied,
					<div className="actions-right">
						{(_.isEmpty(prop.applied) || prop.applied === "Never") && (
							<Button size="sm" color="danger" style={{ marginRight: "4px" }} onClick={() => this.deleteSplit(key, prop.id)}>
								Delete
							</Button>
						)}
						<NavLink to={link}>
							<Button size="sm" color="info" style={{ marginRight: "4px" }}>
								Lanes
							</Button>
						</NavLink>
					</div>
				];
			} else {
				return [
					"",
					<CustomInput
						formControlProps={{ fullWidth: true }}
						inputProps={{
							type: "text",
							name: "date",
							value: prop.date || "",
							placeholder: "MM/DD/YYYY",
							onChange: e => this.handleSplit(key, "date", e)
						}}
						white
					/>,
					<FormControl fullWidth className={classes.selectFormControl}>
						<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.newBillingType} inputProps={{ name: "newBillingType" }} onChange={e => this.handleSplit(key, "newBillingType", e)}>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value=""
							>
								Select Billing Type
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="Gain Share"
							>
								Gain Share
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="Buy/Sell"
							>
								Buy/Sell
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="Prepaid & Add"
							>
								Prepaid & Add
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="N/A"
							>
								N/A
							</MenuItem>
						</Select>
					</FormControl>,
					<FormControl fullWidth className={classes.selectFormControl}>
						<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.newCalcType} inputProps={{ name: "newCalcType" }} onChange={e => this.handleSplit(key, "newCalcType", e)}>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value=""
							>
								Select Calculation Type
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="% of Savings"
							>
								% of Savings
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="% Mark Up"
							>
								% Mark Up
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="$ Mark Up"
							>
								$ Mark Up
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="% Flat Charge"
							>
								% Flat Charge
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="$ Flat Charge"
							>
								$ Flat Charge
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="% Target Savings"
							>
								% Target Savings
							</MenuItem>
						</Select>
					</FormControl>,
					<CustomInput
						formControlProps={{ fullWidth: true }}
						inputProps={{
							type: "text",
							name: "newSplit",
							value: prop.newSplit || "",
							onChange: e => this.handleSplit(key, "newSplit", e)
						}}
						white
					/>,
					<CustomInput
						formControlProps={{ fullWidth: true }}
						inputProps={{
							type: "text",
							name: "min",
							value: prop.min || "",
							onChange: e => this.handleSplit(key, "min", e)
						}}
						white
					/>,
					<CustomInput
						formControlProps={{ fullWidth: true }}
						inputProps={{
							type: "text",
							name: "minProfit",
							value: prop.minProfit || "",
							onChange: e => this.handleSplit(key, "minProfit", e)
						}}
						white
					/>,
					<FormControl fullWidth className={classes.selectFormControl}>
						<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.passthru} inputProps={{ name: "passthru" }} onChange={e => this.handleSplit(key, "passthru", e)}>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="rated"
							>
								Rated
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="benchmark"
							>
								Benchmark
							</MenuItem>
						</Select>
					</FormControl>,
					"",
					<div className="actions-right">
						<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.saveSplit(key)}>
							Save
						</Button>
						<Button size="sm" color="warning" style={{ marginRight: "4px" }} onClick={() => this.cancelSplit(key)}>
							Cancel
						</Button>
					</div>
				];
			}
		});
		return splits;
	}

	addSplit() {
		const { splits } = this.state;
		splits.push({
			id: "",
			date: "",
			newBillingType: "",
			newCalcType: "",
			newSplit: "",
			min: "",
			minProfit: "",
			passthru: "rated",
			applied: ""
		});
		this.setState({ splits });
	}

	handleSplit(i, name, event) {
		const { splits } = this.state;
		if (splits.length) {
			const split = splits[i];
			split[name] = event.target.value;
			splits[i] = split;
			this.setState({ splits });
		}
	}

	async saveSplit(i) {
		const { splits, id } = this.state;
		const split = splits[i];

		const data = {
			companyId: id,
			date: _.trim(split.date),
			newBillingType: _.trim(split.newBillingType),
			newSplit: _.trim(split.newSplit),
			min: _.trim(split.min),
			minProfit: _.trim(split.minProfit),
			passthru: _.trim(split.passthru),
			newCalcType: _.trim(split.newCalcType)
		};

		const url = "/index.php?p=api&r=json&c=admin&m=addCompanySplit";

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				splits[i] = response.data.body;
				this.setState({ splits });
			} else {
				this.props.setNotification("There was an error saving the split!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred saving the split!", { variant: "error" });
		}
	}

	cancelSplit(key) {
		const { splits } = this.state;
		splits.splice(key, 1);
		this.setState({ splits });
	}

	async deleteSplit(i, id) {
		const url = "/index.php?p=api&r=json&c=admin&m=removeCompanySplit&d=" + id;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				const { splits } = this.state;
				splits.splice(i, 1);
				this.setState({ splits });
			} else {
				this.props.setNotification("There was an error deleting the split!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred deleting the split!", { variant: "error" });
		}
	}

	getProfiles(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot
							}}
							checked={prop.checked}
							onChange={e => this.handleProfiles(key, e)}
						/>
					}
				/>,
				prop.name,
				moment.unix(prop.eff_from).format("MM/DD/YYYY"),
				moment.unix(prop.eff_to).format("MM/DD/YYYY")
			];
		});
	}

	handleProfiles(key, event) {
		const { profiles } = this.state;
		profiles[key].checked = event.target.checked;
		this.setState({ profiles });
	}

	async saveProfiles() {
		if (_.isEmpty(this.state.profiles)) {
			return;
		}
		const profiles = this.state.profiles.filter(prop => prop.checked);
		const data = {
			submit: "1",
			profs: {}
		};

		_.forEach(profiles, (profile) => {
			data.profs[profile.id] = profile.id;
		});

		if(_.isEmpty(data.profs)) {
			this.props.setNotification("At least one accessorial profile is required!", { variant: "error" });
			return false;
		}

		const url = "/index.php?p=api&r=json&c=admin&m=updateCompanyAccProfiles&d=" + this.state.id;
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data === "string" || !response.data.body) {
				this.props.setNotification("There was an error updating the company accessorial profiles!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred updating the company accessorial profiles!", { variant: "error" });
		}
	}

	getLinearFeetRules(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot
							}}
							checked={prop.assigned}
							onChange={e => {
								this.handleLinearFootRule(prop.id);
							}}
						/>
					}
				/>,
				prop.name,
				prop.scac,
				prop.carrier,
				<CustomInput
					formControlProps={{ fullWidth: true }}
					inputProps={{
						type: "text",
						name: "message",
						value: prop.message || "",
						onChange: e => this.handleLinearFootRule(prop.id, e),
						disabled: !prop.assigned
					}}
					white
				/>
			];
		});
	}

	handleLinearFootRule(id, e = null) {
		const linearFeetRulesCopy = this.state.linearFeetRules;

		const index = linearFeetRulesCopy.findIndex(x => x.id === id);

		if( e == null) {
			if (linearFeetRulesCopy[index].assigned == false) {
				linearFeetRulesCopy[index].assigned = true;
			} else {
				linearFeetRulesCopy[index].assigned = false;
				linearFeetRulesCopy[index].message = '';
			}

		} else {
			linearFeetRulesCopy[index].message = e.target.value;
		}
		this.setState({ linearFeetRules: linearFeetRulesCopy });

	}

	async switchUser(id) {
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=user&m=takeAccount&d=${id}/true`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
				this.props.clearNotifications();
			}
			if (typeof data !== "string" && !_.isEmpty(data.user)) {
				window.location.href = basePath + "/admin";
			} else {
				this.props.setNotification("There was an error assuming the user account!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred assuming the user!", {
				variant: "error"
			});
		}
	}

	getCarriers(data) {
		let allFavorites = this.state.favorite_carriers;
		// move favoritedResults to the top of the list.
		let favoritedResults = data.filter(user => {
			return allFavorites.includes(user.id)
		});
		let remainingResults = data.filter(user => {
			return !allFavorites.includes(user.id)
		});

		data = favoritedResults.concat(remainingResults);
		return data.map((prop, key) => {
			const link = basePath + "/admin/company-" + this.props.match.params.id + "/carrier/edit/" + prop.id;
			let licenseKey = prop.license_key || '';
			let meterNum = prop.meter_number || '';

			if(licenseKey.length > 15) {
				licenseKey = licenseKey.substring(0,15) + '...';
			}
			if(licenseKey.length > 15) {
				meterNum = meterNum.substring(0,15) + '...';
			}
			return [
				prop.id,
				prop.carrier,
				prop.user,
				prop.pass,
				prop.bill_to_account,
				licenseKey,
				meterNum,
				prop.p44_account_group_code,
				<div>
					{prop.p44_api_pickup == 1 ? <span title="Pickups"><FaTruckLoading size={25} /></span> : ''}
					{prop.p44_api_tracking == 1 ? <span title="Tracking"><FaMapMarkerAlt size={25} /></span> : ''}
					{prop.p44_api_images == 1 ? <span title="Images"><FaFileInvoice size={25} /></span> : ''}
				</div>,
				<div className="actions-right">
					<Favorites
						id={prop.id}
						favorites={allFavorites}
						filters={[]}
						tableName={'company_carrier_accounts'}
						favoriteItem={(results) => this.favoriteItem(results, "company_carrier_accounts")}
					/>
					<NavLink to={link}>
						<Button
							color="info"
							size="sm"
							justIcon
							round
							style={{ marginRight: "4px" }}
						>
							<Edit />
						</Button>
					</NavLink>
					<Button
						color="danger"
						size="sm"
						justIcon
						round
						style={{ marginRight: "4px" }}
						onClick={e => {this.handleDeleteCompanyCarrier(prop.id, key);}}
					>
	                    <Delete/>
	                </Button>
				</div>
			];
		});
	}

	handleDeleteCompanyCarrier(id, key) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this carrier?"
					onConfirm={() => this.deleteCompanyCarrier(id, key)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async deleteCompanyCarrier(id, key) {
		this.setState({ loading: true });

		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=companyCarriers&m=deleteAccount&d=${id}`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && data.body) {
				const { carriers } = this.state;
				carriers.splice(key, 1);
				this.setState({ carriers: carriers });
			} else {
				this.props.setNotification("There was an error deleting the company-carrier record!", {
					variant: "error"
				});
			}
			this.setState({ loading: false, alert: null });
		} catch (error) {
			console.error(error);
			this.setState({ loading: false, alert: null });

			this.props.setNotification("An exception occurred deleting the company-carrier record!", {
				variant: "error"
			});
		}
	}

	async saveCompany() {
		this.setState({ loading: true });

		const linearFeetRules = this.state.linearFeetRules;
		const assignedFeetRules = [];

		for (let i = 0; i < linearFeetRules.length; i++) {
			if (linearFeetRules[i].assigned == true) {
				const assignedRule = {
					'id': linearFeetRules[i].id,
					'message': linearFeetRules[i].message || null
				};
				assignedFeetRules.push(assignedRule);
			}
		}

		const data = {
			name: this.state.name,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			max_weight: this.state.max_weight,
			claims_contact_first_name: this.state.claims_contact_first_name,
			claims_contact_last_name: this.state.claims_contact_last_name,
			claims_contact_phone: this.state.claims_contact_phone,
			claims_contact_email: this.state.claims_contact_email,
			tl_markup: this.state.tl_markup_value,
			bol_max_life: this.state.bol_max_life,
			linearFeetRules: assignedFeetRules,
			ref_entry_type: this.state.ref_entry_type,
			ref_entry_opts: this.state.ref_entry_opts,
			volume_markup: this.state.volume_markup,
			parcel_weight_limit: this.state.parcel_weight_limit,
			np_fee_amount: this.state.np_fee_amount,
			np_fee_type: this.state.np_fee_type,
			company_ftl_scacs: this.state.company_ftl_scacs,
			custom_trailer_length: this.state.custom_trailer_length,
			custom_trailer_width: this.state.custom_trailer_width,
			custom_trailer_height: this.state.custom_trailer_height,
		};

		if (this.state.require_dims == true) {
			data.require_dims = 1;
		} else {
			data.require_dims = 0;
		}

		if (this.state.auto_cancel_bols == true) {
			data.auto_cancel_bols = 1;
		}

		if (this.state.check_residential == true) {
			data.check_residential = 1;
		}

		if (this.state.enable_outbound_collect == true) {
			data.enable_outbound_collect = 1;
		}

		if (this.state.enable_miler == true) {
			data.enable_miler = 1;
		}

		if (this.state.enable_claims == true) {
			data.enable_claims = 1;
		}

		if (this.state.enable_liability == true) {
			data.enable_liability = 1;
		}

		if (this.state.enable_total_declared_value == true) {
			data.enable_total_declared_value = 1;
		}

		if (this.state.enable_blind_shipments == true) {
			data.enable_blind_shipments = 1;
		}

		if (this.state.enable_fedex_address_validation == true) {
			data.enable_fedex_address_validation = 1;
		}

		if (this.state.enable_parcel_dg == true) {
			data.enable_parcel_dg = 1;
		}

		if (this.state.enable_dgd == true) {
			data.enable_dgd = 1;
		}

		if (this.state.auto_calc_expected_delivery == true) {
			data.auto_calc_expected_delivery = 1;
		}

		if (this.state.pure_abf_bm == true) {
			data.pure_abf_bm = 1;
		}

		if (this.state.use_special_pallets == true) {
			data.use_special_pallets = 1;
		}

		if (this.state.enable_tl_quote == true) {
			data.c_tl_quote = 1;
		}

		if (this.state.enable_auto_class == true) {
			data.c_auto_class = 1;
		}

		if (this.state.pure_abf_bm == true) {
			data.pure_abf_bm = 1;
		}

		if (this.state.allow_auto_pro == true) {
			data.allow_auto_pro = 1;
		}

		if (this.state.ctsi_bm_accessorial == true) {
			data.ctsi_bm_accessorial = 1;
		}

		if (this.state.sync_tfmc == true) {
			data.sync_tfmc = 1;
		}

		if (this.state.prevent_dup_identifiers == true) {
			data.prevent_dup_identifiers = 1;
		}

		if (this.state.unique_po == true) {
			data.unique_po = 1;
		}

		if (this.state.unique_so == true) {
			data.unique_so = 1;
		}

		if (this.state.unique_ref == true) {
			data.unique_ref = 1;
		}
		if (this.state.enable_new_ln_ft_method == true) {
			data.enable_new_ln_ft_method = 1;
		}


		if (this.state.print_uploaded_bol_images == true) {
			data.print_uploaded_bol_images = 1;
		}

		if (this.state.show_hus == true) {
			data.show_hus = 1;
		}

		if (this.state.show_pcs == true) {
			data.show_pcs = 1;
		}

		if (this.state.require_pro == true) {
			data.require_pro = 1;
		}

		if (this.state.require_po == true) {
			data.require_po = 1;
		}

		if (this.state.require_so == true) {
			data.require_so = 1;
		}

		if (this.state.require_ref == true) {
			data.require_ref = 1;
		}

		if (this.state.require_cons_contact == true) {
			data.require_cons_contact = 1;
		}

		if (this.state.require_cons_phone == true) {
			data.require_cons_phone = 1;
		}

		if (this.state.require_cons_fax == true) {
			data.require_cons_fax = 1;
		}

		if (this.state.require_cons_email == true) {
			data.require_cons_email = 1;
		}

		if (this.state.so_text_area == true) {
			data.so_text_area = 1;
		}

		if (this.state.show_execute_warning == true) {
			data.show_execute_warning = 1;
		}

		if (this.state.hide_tl_carriers == true) {
			data.hide_tl_carriers = 1;
		}

		if (this.state.enable_acc_recommendations == true) {
			data.enable_acc_recommendations = 1;
		}

		data.require_pod_for_billing = this.state.require_pod_for_billing ? 1 : 0;
		data.enable_prepaid_and_charge = this.state.enable_prepaid_and_charge ? 1 : 0;
		data.custom_piece_count = this.state.custom_piece_count ? 1 : 0;
		data.enable_costco_label = this.state.enable_costco_label? 1 : 0;
		data.p44_document_format = this.state.p44_document_format;

		// intl settings
		data.require_master_bill = this.state.require_master_bill ? 1 : 0;
		data.require_house_bill = this.state.require_house_bill ? 1 : 0;
		data.require_airway_bill = this.state.require_airway_bill ? 1 : 0;
		data.require_isf = this.state.require_isf ? 1 : 0;
		data.require_intl_po = this.state.require_intl_po ? 1 : 0;
		data.require_intl_so = this.state.require_intl_so ? 1 : 0;
		data.require_intl_ref = this.state.require_intl_ref ? 1 : 0;

		try {
			const response = await axios.post("/index.php?p=api&c=admin&m=editCompany&d=" + this.state.id, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				this.setState({ loading: false });
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the company!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred saving the company!", {
				variant: "error"
			});
		}
	}

	handleActive(active) {
		this.setState({ active });
	}

	getRoutingGuides(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			prop.edit = prop.edit || false;
			prop.loading = prop.loading || false;
			prop.scac_input = prop.scac_input || "";
				if (!isNaN(parseInt(prop.id))) {
					if (prop.edit) {
						prop.scac_input = prop.scac || "";
						return [
							prop.id,
							prop.scac,
							prop.carrier,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "weight_from",
									value: prop.weight_from || "",
									onChange: e => this.handleRoutingGuide(key, "weight_from", e)
								}}
								white
							/>,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "weight_to",
									value: prop.weight_to || "",
									onChange: e => this.handleRoutingGuide(key, "weight_to", e)
								}}
								white
							/>,
							prop.max_cube,
							prop.min_pcf,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "override_max_cube",
									value: prop.override_max_cube || "",
									onChange: e => this.handleRoutingGuide(key, "override_max_cube", e)
								}}
								white
							/>,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "override_min_pcf",
									value: prop.override_min_pcf || "",
									onChange: e => this.handleRoutingGuide(key, "override_min_pcf", e)
								}}
								white
							/>,
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.carrier_type} inputProps={{ name: "carrier_type" }} onChange={e => this.handleRoutingGuide(key, "carrier_type", e)}>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Program"
									>
										Program
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Non-Program"
									>
										Non-Program
									</MenuItem>
								</Select>
							</FormControl>,
							<Checkbox
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot
								}}
								checked={prop.override_program_pricing == "1"}
								onChange={e => this.handleRoutingGuide(key, "override_program_pricing", e)}
								disabled={!(prop.carrier_type == "Program")}
							/>,
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select 
									MenuProps={{ className: classes.selectMenu }} 
									classes={{ select: classes.select }} 
									value={prop.override_fee_type} 
									inputProps={{ name: "override_fee_type" }} 
									onChange={e => this.handleRoutingGuide(key, "override_fee_type", e)}
									disabled={!(prop.carrier_type == "Program")}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="flat"
									>
										Flat $ Amount
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="percent"
									>
										% of Rated Amount
									</MenuItem>
								</Select>
							</FormControl>,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "override_fee",
									value: prop.override_fee || "0",
									onChange: e => this.handleRoutingGuide(key, "override_fee", e),
									startAdornment: (prop.override_fee_type === 'flat') ? (<InputAdornment position="end">$</InputAdornment>) : null,
									endAdornment: (prop.override_fee_type === 'percent') ? (<InputAdornment position="end">%</InputAdornment>) : null,
									disabled: !(prop.carrier_type == "Program"),
								}}
								white
							/>,
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.allow_manual} inputProps={{ name: "allow_manual" }} onChange={e => this.handleRoutingGuide(key, "allow_manual", e)}>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="1"
									>
										Yes
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="0"
									>
										No
									</MenuItem>
								</Select>
							</FormControl>,
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.intl_provider} inputProps={{ name: "intl_provider" }} onChange={e => this.handleRoutingGuide(key, "intl_provider", e)}>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="1"
									>
										Yes
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="0"
									>
										No
									</MenuItem>
								</Select>
							</FormControl>,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "start_date",
									value: prop.start_date || "",
									placeholder: "MM/DD/YYYY",
									onChange: e => this.handleRoutingGuide(key, "start_date", e)
								}}
								white
							/>,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "end_date",
									value: prop.end_date || "",
									placeholder: "MM/DD/YYYY",
									onChange: e => this.handleRoutingGuide(key, "end_date", e)
								}}
								white
							/>,
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									name: "message",
									value: prop.message || "",
									onChange: e => this.handleRoutingGuide(key, "message", e)
								}}
								white
							/>,
							<div className={classes.right}>
								{prop.loading ? (
									<Button size="sm" color="success" style={{ marginRight: "4px", lineHeight: 0 }}>
										<CircularProgress size={16} style={{ color: "white" }} />
									</Button>
								) : (
									<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.saveRoutingGuide(key)}>
										Save
									</Button>
								)}
								<Button size="sm" color="warning" onClick={() => this.cancelRoutingGuide(key)}>
									Cancel
								</Button>
							</div>
						];
					} else {
						return [
							prop.id,
							prop.scac,
							prop.carrier,
							prop.weight_from,
							prop.weight_to,
							prop.max_cube,
							prop.min_pcf,
							prop.override_max_cube,
							prop.override_min_pcf,
							prop.carrier_type,
							prop.override_program_pricing == "1" ? "Yes" : "No",
							prop.override_fee_type == "flat" ? "Flat $ Amount" : "% of Rated Amount",
							prop.override_fee_type == "flat" ? `$${prop.override_fee}` : `${prop.override_fee}%`,
							prop.allow_manual == "1" ?  "Yes" : "No",
							prop.intl_provider == "1" ?  "Yes" : "No",
							prop.start_date,
							prop.end_date,
							prop.message,
							<div className={classes.right}>
								<Button size="sm" color="info" style={{ marginRight: "4px" }} onClick={() => this.editRoutingGuide(key)}>
									Edit
								</Button>
								<Button size="sm" color="danger" onClick={() => this.handleDeleteRoutingGuide(key, prop.id)}>
									Delete
								</Button>
							</div>
						];
					}
				} else {
					return [
						"",
						<Autocomplete
							id={prop.scac}
							style={{ width: "100%" }}
							options={this.state.scac_list}
							classes={{ option: classes.option }}
							autoHighlight
							getOptionLabel={option => option.scac}
							inputValue={prop.scac_input || ""}
							onInputChange={(event, value) => {
								if (event && event.type === "change") {
									const { guides } = this.state;
									guides[key].scac_input = value;
									this.setState({ guides });
								}
							}}
							onChange={(event, option) => {
								const { guides } = this.state;
								if (option) {
									guides[key].scac = option.scac;
									guides[key].scac_input = option.scac;
									guides[key].carrier = option.carrier;
									guides[key].max_cube = option.max_cube;
									guides[key].min_pcf = option.min_pcf;
									this.setState({ guides });
								} else {
									guides[key].scac = "";
									guides[key].scac_input = "";
									guides[key].carrier = "";
									guides[key].max_cube = "";
									guides[key].min_pcf = "";
									this.setState({ guides });
								}
							}}
							renderOption={option => <React.Fragment>{option.scac}</React.Fragment>}
							renderInput={params => (
								<TextField
									{...params}
									variant="standard"
									fullWidth
									inputProps={{
										...params.inputProps
									}}
								/>
							)}
						/>,
						prop.carrier,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "weight_from",
								value: prop.weight_from || "",
								onChange: e => this.handleRoutingGuide(key, "weight_from", e)
							}}
							white
						/>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "weight_to",
								value: prop.weight_to || "",
								onChange: e => this.handleRoutingGuide(key, "weight_to", e)
							}}
							white
						/>,
						prop.max_cube,
						prop.min_pcf,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "override_max_cube",
								value: prop.override_max_cube || "",
								onChange: e => this.handleRoutingGuide(key, "override_max_cube", e)
							}}
							white
						/>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "override_min_pcf",
								value: prop.override_min_pcf || "",
								onChange: e => this.handleRoutingGuide(key, "override_min_pcf", e)
							}}
							white
						/>,
						<FormControl fullWidth className={classes.selectFormControl}>
							<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.carrier_type} inputProps={{ name: "carrier_type" }} onChange={e => this.handleRoutingGuide(key, "carrier_type", e)}>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="Program"
								>
									Program
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="Non-Program"
								>
									Non-Program
								</MenuItem>
							</Select>
						</FormControl>,
						<Checkbox
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot
							}}
							checked={prop.override_program_pricing == "1"}
							onChange={e => this.handleRoutingGuide(key, "override_program_pricing", e)}
							disabled={!(prop.carrier_type == "Program")}
						/>,
						<FormControl fullWidth className={classes.selectFormControl}>
							<Select 
								MenuProps={{ className: classes.selectMenu }} 
								classes={{ select: classes.select }} 
								value={prop.override_fee_type} 
								inputProps={{ name: "override_fee_type" }} 
								onChange={e => this.handleRoutingGuide(key, "override_fee_type", e)}
								disabled={!(prop.carrier_type == "Program")}
							>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="flat"
								>
									Flat $ Amount
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="percent"
								>
									% of Rated Amount
								</MenuItem>
							</Select>
						</FormControl>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "override_fee",
								value: prop.override_fee || "",
								onChange: e => this.handleRoutingGuide(key, "override_fee", e),
								startAdornment: (prop.override_fee_type === 'flat') ? (<InputAdornment position="end">$</InputAdornment>) : null,
								endAdornment: (prop.override_fee_type === 'percent') ? (<InputAdornment position="end">%</InputAdornment>) : null,
								disabled: !(prop.carrier_type == "Program"),
							}}
							white
						/>,
						<FormControl fullWidth className={classes.selectFormControl}>
							<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.allow_manual} inputProps={{ name: "allow_manual" }} onChange={e => this.handleRoutingGuide(key, "allow_manual", e)}>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="1"
								>
									Yes
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="0"
								>
									No
								</MenuItem>
							</Select>
						</FormControl>,
						<FormControl fullWidth className={classes.selectFormControl}>
							<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.intl_provider} inputProps={{ name: "intl_provider" }} onChange={e => this.handleRoutingGuide(key, "intl_provider", e)}>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="1"
								>
									Yes
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									value="0"
								>
									No
								</MenuItem>
							</Select>
						</FormControl>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "start_date",
								value: prop.start_date || "",
								placeholder: "MM/DD/YYYY",
								onChange: e => this.handleRoutingGuide(key, "start_date", e)
							}}
							white
						/>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "end_date",
								value: prop.end_date || "",
								placeholder: "MM/DD/YYYY",
								onChange: e => this.handleRoutingGuide(key, "end_date", e)
							}}
							white
						/>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								name: "message",
								value: prop.message || "",
								onChange: e => this.handleRoutingGuide(key, "message", e)
							}}
							white
						/>,
						<div className={classes.right}>
							<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.createRoutingGuide()}>
								Save
							</Button>
							<Button size="sm" color="warning" onClick={() => this.deleteRoutingGuide()}>
								Cancel
							</Button>
					</div>
				];
			}
		});
	}

	addRoutingGuide() {
		const { guides } = this.state;
		guides.unshift({
			edit: false,
			loading: false,
			id: "",
			scac: "",
			carrier: "",
			weight_from: "0",
			weight_to: "99999999",
			max_cube: "",
			min_pcf: "",
			override_max_cube: "0",
			override_min_pcf: "0",
			carrier_type: "Program",
			override_program_pricing: "0",
			override_fee_type: "flat",
			override_fee: "0.00",
			allow_manual: "0",
			intl_provider: "0",
			start_date: "01/01/2009",
			end_date: "01/01/2038",
			message: ""
		});
		this.setState({ guides });
	}

	async createRoutingGuide() {
		const { guides, id } = this.state;
		const guide = guides[0];

		if(!this.validateRoutingGuide(guide)) return;

		const data = {
			company: id,
			scac: guide.scac,
			carrier: guide.carrier,
			from: guide.weight_from,
			to: guide.weight_to,
			max_cube: guide.max_cube,
			min_pcf: guide.min_pcf,
			cube: guide.override_max_cube,
			pcf: guide.override_min_pcf,
			custom_message: guide.message,
			type: guide.carrier_type,
			override_program_pricing: guide.override_program_pricing,
			override_fee_type: guide.override_fee_type,
			override_fee: guide.override_fee,
			allow_manual: guide.allow_manual,
			intl_provider: guide.intl_provider,
			start_date: guide.start_date,
			end_date: guide.end_date,
		};

		guides[0].loading = true;
		this.setState({ guides });

		const url = "/index.php?p=api&r=json&c=admin&m=saveRoutingGuide";

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body != false) {
				guide.id = response.data.body;
				guide.edit = false;
				guide.loading = false;
				guides.shift();
				guides.push(guide);
				this.setState({ guides });
			} else {
				guides[0].loading = false;
				this.setState({ guides });
				this.props.setNotification("There was an error creating the routing guide!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			guides[0].loading = false;
			this.setState({ guides });
			this.props.setNotification("An exception occurred creating the routing guide!", { variant: "error" });
		}
	}

	editRoutingGuide(i) {
		const { guides, unedited_guides } = this.state;
		unedited_guides[i] = _.clone(guides[i]);
		guides[i].edit = true;
		this.setState({ guides, unedited_guides });
	}

	cancelRoutingGuide(i) {
		const { guides, unedited_guides } = this.state;
		guides[i] = _.clone(unedited_guides[i]);
		this.setState({ guides, unedited_guides });
	}

	handleRoutingGuide(i, name, event) {
		const { guides } = this.state;
		if (guides.length) {
			const guide = guides[i];
			let value = event.target.value;
			if(name == "override_program_pricing") {
				value = event.target.checked ? "1" : "0";
			}
			if(name == "carrier_type" && value == "Non-Program") {
				guide["override_program_pricing"] = "0";
				guide["override_fee"] = "0.00";
				guide["override_fee_type"] = "flat";
			}
			guide[name] = value;
			guides[i] = guide;
			this.setState({ guides });
		}
	}

	validateRoutingGuide(guide) {
		if(guide.message != null && guide.message.length > 250) {
			this.props.setNotification("Custom message is too long. Please limit to 250 characters.", { variant: "error" });
			return false;
		} else if(guide.override_program_pricing == "1" && guide.carrier_type != "Program") {
			this.props.setNotification("Override Program Pricing is only applicable for In-Program carriers.", { variant: "error" });
			return false;
		} else if(isNaN(guide.override_fee)) {
			this.props.setNotification("Invalid Override Program Fee amount", { variant: "error" });
			return false;
		}else if(!(moment(guide.start_date, 'MM/DD/YYYY', true).isValid()) || !(moment(guide.end_date, 'MM/DD/YYYY', true).isValid())) {
			this.props.setNotification("Invalid start or end date, The date format is MM/DD/YYYY!", { variant: "warning" });
			return false;
		}
		return true;
	}

	async saveRoutingGuide(i) {
		const { guides, id } = this.state;
		const guide = guides[i];

		if(!this.validateRoutingGuide(guide)) return;

		const data = {
			company: id,
			id: guide.id,
			scac: guide.scac,
			carrier: guide.carrier,
			from: guide.weight_from,
			to: guide.weight_to,
			max_cube: guide.max_cube,
			min_pcf: guide.min_pcf,
			cube: guide.override_max_cube,
			pcf: guide.override_min_pcf,
			custom_message: guide.message,
			type: guide.carrier_type,
			override_program_pricing: guide.override_program_pricing,
			override_fee_type: guide.override_fee_type,
			override_fee: guide.override_fee,
			allow_manual: guide.allow_manual,
			intl_provider: guide.intl_provider,
			start_date: guide.start_date,
			end_date: guide.end_date,
		};

		guides[i].loading = true;
		this.setState({ guides });

		const url = "/index.php?p=api&r=json&c=admin&m=saveRoutingGuide&d=" + data.id;

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string") {
				if(!_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				} else {
					this.props.setNotification("There was an error updating the routing guide!", { variant: "error" });
				}
			}
			if (typeof response.data !== "string" && response.data.body != false) {
				guides[i].edit = false;
				guides[i].loading = false;
				this.setState({ guides });
			} else {
				guides[i].loading = false;
				this.setState({ guides });
			}
		} catch (error) {
			console.error(error);
			guides[i].loading = false;
			this.setState({ guides });
			this.props.setNotification("An exception occurred updating the routing guide!", { variant: "error" });
		}
	}

	handleDeleteRoutingGuide(key, id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this routing guide?"
					onConfirm={() => this.deleteRoutingGuide(key, id)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async deleteRoutingGuide(i, id) {
		const { guides } = this.state;
		i = i || 0;
		id = id || null;
		this.setState({ alert: null });
		if (id && id > 0) {
			const url = "/index.php?p=api&r=json&c=admin&m=deleteRoutingGuide&d=" + id;
			try {
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && response.data.body) {
					guides.splice(i, 1);
					this.setState({ guides });
				} else {
					this.props.setNotification("There was an error deleting the routing guide!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("An exception occurred deleting the routing guide!", { variant: "error" });
			}
		} else {
			guides.splice(0, 1);
			this.setState({ guides });
		}
	}

	getWarehouseEmails(data) {
		return data.map((prop, key) => {
			return [
				prop.name,
				<CustomInput
					formControlProps={{ fullWidth: true }}
					inputProps={{
						type: "text",
						value: prop.email || "",
						onChange: e => this.handleWarehouseEmail(key, e)
					}}
					white
				/>
			];
		});
	}

	handleWarehouseEmail(i, event) {
		const { warehouse_emails } = this.state;
		if (warehouse_emails.length) {
			const warehouse_email = warehouse_emails[i];
			warehouse_email.email = event.target.value;
			warehouse_emails[i] = warehouse_email;
			this.setState({ warehouse_emails });
		}
	}

	getSelectionOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			prop.edit = prop.edit || false;
			prop.loading = prop.loading || false;
			if (!isNaN(parseInt(prop.id))) {
				if (prop.edit) {
					return [
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
									checked={prop.checked}
									disabled
								/>
							}
						/>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								value: prop.code_input || "",
								onChange: e => this.handleSelectionOption(key, "code_input", e)
							}}
							white
						/>,
						<CustomInput
							formControlProps={{ fullWidth: true }}
							inputProps={{
								type: "text",
								value: prop.description_input || "",
								onChange: e => this.handleSelectionOption(key, "description_input", e)
							}}
							white
						/>,
						<div className={classes.right}>
							{prop.loading ? (
								<Button size="sm" color="success" style={{ marginRight: "4px", lineHeight: 0 }}>
									<CircularProgress size={16} style={{ color: "white" }} />
								</Button>
							) : (
								<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.saveSelectionOption(key)}>
									Save
								</Button>
							)}
							<Button size="sm" color="warning" onClick={() => this.cancelSelectionOption(key)}>
								Cancel
							</Button>
						</div>
					];
				} else {
					return [
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
									checked={prop.checked}
									onChange={e => this.handleSelectionOption(key, "checked", e)}
								/>
							}
						/>,
						prop.code,
						prop.description,
						<Button color="info" size="sm" onClick={() => this.editSelectionOption(key)}>
							Edit
						</Button>
					];
				}
			} else {
				return [
					"",
					<CustomInput
						formControlProps={{ fullWidth: true }}
						inputProps={{
							type: "text",
							value: prop.code_input || "",
							onChange: e => this.handleSelectionOption(key, "code_input", e)
						}}
						white
					/>,
					<CustomInput
						formControlProps={{ fullWidth: true }}
						inputProps={{
							type: "text",
							value: prop.description_input || "",
							onChange: e => this.handleSelectionOption(key, "description_input", e)
						}}
						white
					/>,
					<div className={classes.right}>
						{prop.loading ? (
							<Button size="sm" color="success" style={{ marginRight: "4px", lineHeight: 0 }}>
								<CircularProgress size={16} style={{ color: "white" }} />
							</Button>
						) : (
							<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.saveSelectionOption(key)}>
								Save
							</Button>
						)}
						<Button size="sm" color="warning" onClick={() => this.deleteSelectionOption(key)}>
							Cancel
						</Button>
					</div>
				];
			}
		});
	}

	addSelectionOption() {
		const { selection_options } = this.state;
		selection_options.push({
			edit: false,
			loading: false,
			checked: false,
			id: "",
			code: "",
			code_input: "",
			description: "",
			description_input: ""
		});
		this.setState({ selection_options });
	}

	editSelectionOption(i) {
		const { selection_options } = this.state;
		selection_options[i].edit = true;
		selection_options[i].code_input = selection_options[i].code;
		selection_options[i].description_input = selection_options[i].description;
		this.setState({ selection_options });
	}

	cancelSelectionOption(i) {
		const { selection_options } = this.state;
		selection_options[i].edit = false;
		selection_options[i].code_input = "";
		selection_options[i].description_input = "";
		this.setState({ selection_options });
	}

	handleSelectionOption(i, name, event) {
		const { selection_options } = this.state;
		if (selection_options.length) {
			const selection_option = selection_options[i];
			selection_option[name] = name === "checked" ? event.target.checked : event.target.value;
			selection_options[i] = selection_option;
			this.setState({ selection_options });
		}
	}

	async saveSelectionOption(i) {
		const { selection_options, id } = this.state;
		const selection_option = selection_options[i];

		if (_.isEmpty(selection_option.code_input)) {
			this.props.setNotification("Missing Code Field!", { variant: "error" });
		}

		if (_.isEmpty(selection_option.description_input)) {
			this.props.setNotification("Missing Description Field!", { variant: "error" });
		}

		if (_.isEmpty(selection_option.code_input) || _.isEmpty(selection_option.description_input)) {
			return;
		}

		const data = {
			id: selection_option.id || "",
			code: selection_option.code_input,
			description: selection_option.description_input
		};

		selection_options[i].loading = true;
		this.setState({ selection_options });

		const url = "/index.php?p=api&r=json&c=admin&m=saveNonLccSelectionOption";

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.option)) {
				selection_options[i].id = response.data.body.option.id;
				selection_options[i].code = response.data.body.option.code;
				selection_options[i].description = response.data.body.option.description;
				selection_options[i].edit = false;
				selection_options[i].loading = false;
				this.setState({ selection_options });
			} else {
				selection_options[i].loading = false;
				this.setState({ selection_options });
				this.props.setNotification("There was an error saving the Non LCC Selection option!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			selection_options[i].loading = false;
			this.setState({ selection_options });
			this.props.setNotification("An exception occurred saving the Non LCC Selection option!", { variant: "error" });
		}
	}

	deleteSelectionOption(i) {
		const { selection_options } = this.state;
		selection_options.splice(i, 1);
		this.setState({ selection_options });
	}

	async saveOptions() {
		const { id, warehouse_emails, selection_options } = this.state;

		const warehouse_email = {};
		for (let i = 0; i < warehouse_emails.length; i++) {
			warehouse_email[warehouse_emails[i].id] = warehouse_emails[i].email;
		}

		const options = selection_options.filter(prop => prop.checked).map(prop => prop.id);

		const data = {
			companyId: id,
			warehouse_email,
			options
		};

		if (this.state.require_non_lcc_selection_explanation) {
			data.require_non_lcc_selection_explanation = "1";
		}

		if (this.state.non_lcc_selection_send_email) {
			data.non_lcc_selection_send_email = "1";
		}

		this.setState({ non_lcc_loading: true });

		const url = "/index.php?p=api&r=json&c=admin&m=saveCompanyNonLccSelectionOptions";

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.success) {
				this.props.setNotification("Options successfully saved!", { variant: "success" });
			} else {
				this.props.setNotification("There was an error assigning the options!", { variant: "error" });
			}
			this.setState({ non_lcc_loading: false });
		} catch (error) {
			console.error(error);
			this.setState({ non_lcc_loading: false });
			this.props.setNotification("An exception occurred assigning the options!", { variant: "error" });
		}
	}

	async loadNotes() {
		const url = "/index.php?p=api&r=json&c=admin&m=getCompanyNotes&d=" + this.state.id;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({ notes: !_.isEmpty(response.data.body) ? response.data.body : [] });
			} else {
				this.props.setNotification("There was an error loading the company notes!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred loading the company notes!", { variant: "error" });
		}
	}

	getNotes(data) {
		const { classes } = this.props;
		const types = {
			general: "General",
			autopro: "Auto-Pro",
			pur: "Pickup Request"
		};
		return data.map((prop, key) => {
			if (prop.id === "") {
				return (
					<GridItem xs={12} key={key} style={{ margin: "10px 0px" }}>
						<Paper className={classes.paper} elevation={1}>
							<GridContainer>
								<GridItem xs={6}>
									<Chip icon={<Person />} label={prop.username} />
									{" - "}
									<FormControl style={{ width: "175px", marginRight: "8px" }} className={classes.selectFormControl}>
										<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={prop.type} inputProps={{ name: "type" }} onChange={e => this.handleNote("type", e)}>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value=""
											/>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="general"
											>
												General
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="pur"
											>
												Pickup Request
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={6} className={classes.right}>
									<Button justIcon round size="sm" color="warning" className={classes.marginRight} onClick={() => this.cancelNote()}>
										<Undo />
									</Button>
									<Button justIcon round size="sm" color="success" onClick={() => this.saveNote()}>
										<Save />
									</Button>
								</GridItem>
								<GridItem xs={12} style={{ marginTop: "10px" }}>
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "note",
											value: prop.note || "",
											multiline: true,
											onChange: e => this.handleNote("note", e)
										}}
										white
									/>
								</GridItem>
							</GridContainer>
						</Paper>
					</GridItem>
				);
			} else {
				let type = prop.type;
				switch (prop.type) {
					case "general":
					case "pur":
						type = types[prop.type];
						break;
				}
				const note = prop.note.replace(/(?:\r\n|\r|\n)/g, "<br />");
				return (
					<GridItem xs={12} key={key} style={{ margin: "10px 0px" }}>
						<Paper className={classes.paper} elevation={1}>
							<GridContainer>
								<GridItem xs={6}>
									<Chip icon={<Person />} label={prop.username} />
									{" - " + type}
								</GridItem>
								<GridItem xs={6} className={classes.right}>
									<i>{prop.create_time}</i>
									{prop.user_credential_id == this.state.user.id && (
										<Button justIcon round size="sm" color="danger" className={classes.marginLeft} onClick={() => this.handleDeleteNote(key, prop.id)}>
											<Clear />
										</Button>
									)}
								</GridItem>
								<GridItem xs={12} style={{ marginTop: "10px" }}>
									<div dangerouslySetInnerHTML={{ __html: note }} />
								</GridItem>
							</GridContainer>
						</Paper>
					</GridItem>
				);
			}
		});
	}

	addNote() {
		const { notes } = this.state;
		notes.unshift({
			id: "",
			type: "",
			note: "",
			username: this.state.user.name,
			user_credential_id: this.state.user.id,
			create_time: ""
		});
		this.setState({ notes });
	}

	handleNote(name, event) {
		const { notes } = this.state;
		if (notes.length) {
			const note = notes[0];
			note[name] = event.target.value;
			notes[0] = note;
			this.setState({ notes });
		}
	}

	cancelNote() {
		const { notes } = this.state;
		notes.shift();
		this.setState({ notes });
	}

	async saveNote() {
		const { notes, id } = this.state;

		const data = {
			id: "",
			note: notes[0].note,
			type: notes[0].type,
			companyId: id
		};

		const url = "/index.php?p=api&r=json&c=admin&m=saveCompanyNote";

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				this.loadNotes();
			} else {
				this.props.setNotification("There was an error saving the company note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred saving the company note!", { variant: "error" });
		}
	}

	handleDeleteNote(key, id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this note?"
					onConfirm={() => this.deleteNote(key, id)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					It cannot be recovered!
				</SweetAlert>
			)
		});
	}

	async deleteNote(key, id) {
		this.setState({ alert: null });

		const url = "/index.php?p=api&r=json&c=admin&m=deleteCompanyNote&d=" + id;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				const { notes } = this.state;
				notes.splice(key, 1);
				this.setState({ notes });
			} else {
				this.props.setNotification("There was an error deleting the company note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred deleting the company note!", { variant: "error" });
		}
	}

	exportCarriers = () => {
		window.location.href = `${apiUrl}/index.php?p=api&c=companyCarriers&m=export&companyId=${this.state.id}`;
	}

	async handlePageClick(page, name) {
		let endpoint = "";
		if(name == "carriers") {
			endpoint = "/index.php?p=api&r=json&c=companyCarriers&m=index&d=" + this.props.match.params.id + "/" + page + "/1999";
		}

		if(endpoint != "") {
			try {
				const response = await axios.get(endpoint);
				const data = response.data;
				if (typeof data !== "string" && !_.isEmpty(data.body)) {
					this.props.handleMessages(response);
					if(name == "carriers") {
						let carriers = data.body.result;
						let carrier_paging = {
							current: data.body.current,
							last: data.body.last,
							pages: data.body.pages,
						}
						this.setState({
							carriers,
							carrier_paging
						});
					}
				} else {
					this.setState({ loading: false });
					this.props.enqueueSnackbar("There was an error loading company carriers!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.enqueueSnackbar("There was an exception loading company carriers!", {
					variant: "error"
				});
			}
		}
	}

	getPages(pageInfo) {
		let current = 1;
		let last = 1;

		if (!_.isEmpty(pageInfo)) {
			current = pageInfo.current;
			last = pageInfo.last;
		}

		let pages = [{ active: true, text: "..." }];
		const nextPage = { text: ">" };
		const prevPage = { text: "<" };

		if (last > 1 && !_.isEmpty(pageInfo) && !_.isEmpty(pageInfo.pages)) {
			pages = pageInfo.pages.map(index => {
				const page = { text: index };
				if (index == parseInt(current)) {
					page.active = true;
				} else {
					page.onClick = e => this.handlePageClick(index, this.state.active);
				}
				return page;
			});
		}

		if (current < last) {
			nextPage.onClick = e => this.handlePageClick(parseInt(current) + 1, this.state.active);
		} else {
			nextPage.disabled = true;
		}

		if (parseInt(current) - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(parseInt(current) - 1, this.state.active);
		} else {
			prevPage.disabled = true;
		}

		pages.push(nextPage);
		pages.unshift(prevPage);

		return pages;
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
					<GridContainer justify="center">
						<GridItem xs={12} className={classes.center}>
							{this.state.active === "info" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<InfoOutlined />
									Info
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("info")}>
									<InfoOutlined />
									Info
								</Button>
							)}
							{this.state.active === "notes" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<Note />
									Notes
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("notes")}>
									<Note />
									Notes
								</Button>
							)}
							{this.state.active === "bol" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<FaFileInvoice />
									BOL
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("bol")}>
									<FaFileInvoice />
									BOL
								</Button>
							)}
							{this.state.active === "users" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<People />
									Users
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("users")}>
									<People />
									Users
								</Button>
							)}
							{this.state.active === "warehouses" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<FaWarehouse />
									Warehouses
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("warehouses")}>
									<FaWarehouse />
									Warehouses
								</Button>
							)}
							{this.state.active === "splits" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<CallSplit />
									Splits
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("splits")}>
									<CallSplit />
									Splits
								</Button>
							)}
							{this.state.active === "carriers" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<FaTruckMoving />
									Carriers
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("carriers")}>
									<FaTruckMoving />
									Carriers
								</Button>
							)}
							{this.state.active === "accessorials" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<MonetizationOnOutlined />
									Accessorials
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("accessorials")}>
									<MonetizationOnOutlined />
									Accessorials
								</Button>
							)}
							{this.state.active === "routingGuides" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<FaRoute />
									Routing Guides
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("routingGuides")}>
									<FaRoute />
									Routing Guides
								</Button>
							)}
							{this.state.active === "nonLcc" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<MoneyOff />
									Non-LCC Selection
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("nonLcc")}>
									<MoneyOff />
									Non-LCC Selection
								</Button>
							)}
							{this.state.active === "linearFootRules" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<FaExchangeAlt />
									Linear Feet Rules
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("linearFootRules")}>
									<FaExchangeAlt />
									Linear Feet Rules
								</Button>
							)}
							{this.state.active === "international" ? (
								<Button size="lg" color="warning" className={classes.marginRight}>
									<FaGlobe />
									International
								</Button>
							) : (
								<Button size="lg" className={classes.marginRight} onClick={() => this.handleActive("international")}>
									<FaGlobe />
									International
								</Button>
							)}
						</GridItem>
					</GridContainer>
				</GridItem>
				{this.state.show ? (
					<GridItem xs={12} sm={12} md={12}>
						{this.state.active === "info" && (
							<Card>
								<CardBody>
									<GridContainer style={{ marginBottom: "10px" }}>
										<GridItem xs={12} sm={12} md={6}>
											<Paper className={classes.paper} elevation={1}>
												<Grid container>
													<Grid item xs={12} style={{ marginBottom: "5px" }}>
														<GridContainer style={{ marginBottom: "5px" }}>
															<GridItem xs={3}>
																<Chip variant="outlined" size="small" icon={<Schedule />} label="Created" />
															</GridItem>
															<GridItem xs={9}>{this.state.created}</GridItem>
														</GridContainer>
														<GridContainer style={{ marginBottom: "5px" }}>
															<GridItem xs={3}>
																<Chip variant="outlined" size="small" icon={<Person />} label="Created" />
															</GridItem>
															<GridItem xs={9}>{this.state.create_by}</GridItem>
														</GridContainer>
													</Grid>
													<Grid item xs={12}>
														<GridContainer style={{ marginBottom: "5px" }}>
															<GridItem xs={3}>
																<Chip variant="outlined" size="small" icon={<Schedule />} label="Updated" />
															</GridItem>
															<GridItem xs={9}>{this.state.edited}</GridItem>
														</GridContainer>
														<GridContainer style={{ marginBottom: "5px" }}>
															<GridItem xs={3}>
																<Chip variant="outlined" size="small" icon={<Person />} label="Updated" />
															</GridItem>
															<GridItem xs={9}>{this.state.edit_by}</GridItem>
														</GridContainer>
													</Grid>
												</Grid>
											</Paper>
										</GridItem>
										<GridItem xs={12} sm={12} md={6} className={classes.right}>
											<Button
												color="info"
												style={{
													marginRight: "4px"
												}}
												onClick={() => {
													this.saveCompany();
												}}
											>
												Save Changes
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs>
											<InputLabel className={classes.label}>Name</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "name",
													value: this.state.name || "",
													onChange: this.handleInput("name")
												}}
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel className={classes.label}>Company Code/ID</InputLabel>
											<br />
											<CustomInput
												id="company_name_id"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "company_name_id",
													value: this.state.id || "",
													disabled: true
												}}
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel className={classes.label}>Address 1</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "address1",
													value: this.state.address1 || "",
													onChange: this.handleInput("address1")
												}}
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel className={classes.label}>Address 2</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "address2",
													value: this.state.address2 || "",
													onChange: this.handleInput("address2")
												}}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs>
											<InputLabel className={classes.label}>Zip</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "zip",
													value: this.state.zip || "",
													onChange: this.handleInput("zip")
												}}
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel className={classes.label}>City</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "city",
													value: this.state.city || "",
													onChange: this.handleInput("city")
												}}
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel className={classes.label}>State</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "state",
													value: this.state.state || "",
													onChange: this.handleInput("state")
												}}
											/>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "bol" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<Button
												color="info"
												style={{
													marginRight: "4px"
												}}
												onClick={e => {
													this.saveCompany();
												}}
											>
												Save Changes
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel htmlFor="billing_type" className={classes.label}>
												Billing Type
											</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl} disabled>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.billing_type}
													inputProps={{
														name: "billing_type",
														id: "billing_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value=""
													>
														Select Billing Type
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Prepaid & Add"
													>
														Prepaid & Add
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Gain Share"
													>
														Gain Share
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Buy/Sell"
													>
														Buy/Sell
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="N/A"
													>
														N/A
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel htmlFor="calc_type" className={classes.label}>
												Calculation Type
											</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl} disabled>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.calc_type}
													inputProps={{
														name: "calc_type",
														id: "calc_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value=""
													>
														Select Calculation Type
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% of Savings"
													>
														% of Savings
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% Mark Up"
													>
														% Mark Up
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="$ Mark Up"
													>
														$ Mark Up
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% Flat Charge"
													>
														% Flat Charge
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="$ Flat Charge"
													>
														$ Flat Charge
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% Target Savings"
													>
														% Target Savings
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Calculation Value</InputLabel>
											<br />
											<CustomInput
												id="calc_value"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "calc_value",
													value: this.state.calc_value || "",
													onChange: this.handleInput("calc_value"),
													disabled: true
												}}
												helpText="This is the % in TFM's favour, or the amount added to the True Cost."
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Min Charge</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "min_charge",
													value: this.state.min_charge || "",
													onChange: this.handleInput("min_charge"),
													disabled: true,
													startAdornment: <InputAdornment position="end">$</InputAdornment>
												}}
												helpText='Minimum mark up charge allowed (only applies to "% Mark Up")'
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Min Profit Margin</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "min_profit_margin",
													value: this.state.min_profit_margin || "",
													onChange: this.handleInput("min_profit_margin"),
													disabled: true,
													endAdornment: <InputAdornment position="end">%</InputAdornment>
												}}
												helpText="Minimum profit margin that TFM can make on a shipment (Only applies to % Target Savings)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Passthru Value</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "passthru",
													value: this.state.passthru || "",
													onChange: this.handleInput("passthru"),
													disabled: true
												}}
												helpText="Only applicable with % Target Savings, this is the field to display as the customer's LCC Rated amount when the actual rate is higher than the benchmark."
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Volume Markup</InputLabel>
											<br />
											<CustomInput
												id="volume_markup"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "volume_markup",
													value: this.state.volume_markup || "",
													onChange: this.handleInput("volume_markup"),
													endAdornment: <InputAdornment position="end">%</InputAdornment>
												}}
												helpText="Markup percentage applied to volume quotes"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Max. Shipment Weight</InputLabel>
											<br />
											<CustomInput
												id="max_weight"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "max_weight",
													value: this.state.max_weight || "",
													onChange: this.handleInput("max_weight"),
													endAdornment: <InputAdornment position="end">lbs.</InputAdornment>
												}}
												helpText="Defaults to 10,000 lbs."
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Parcel Weight Limit</InputLabel>
											<br />
											<CustomInput
												id="parcel_weight_limit"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "parcel_weight_limit",
													value: this.state.parcel_weight_limit || "0",
													onChange: this.handleInput("parcel_weight_limit"),
													endAdornment: <InputAdornment position="end">lbs</InputAdornment>
												}}
												helpText="Set a weight limit in pounds for this company's parcel shipments. If 0, no weight restriction will apply."
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Non Program Fee</InputLabel>
											<br />
											<CustomInput
												id="np_fee_amount"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "np_fee_amount",
													value: this.state.np_fee_amount || "0",
													onChange: this.handleInput("np_fee_amount"),
													startAdornment: (this.state.np_fee_type === 'flat') ? (<InputAdornment position="end">$</InputAdornment>) : null,
													endAdornment: (this.state.np_fee_type === 'percent') ? (<InputAdornment position="end">%</InputAdornment>) : null,
												}}
												helpText="Non-Program Fee amount added to True Cost (For Display Only)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Non Program Fee Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.np_fee_type || ""}
													inputProps={{
														id: "np_fee_type",
														name: "np_fee_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="flat"
													>
														Flat $ amount
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="percent"
													>
														% of Rated Amount
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_dims}
														onChange={this.handleChange("require_dims")}
														value="require_dims"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Quick Rate Dimensions"
											/>
											<FormHelperText>Require dimensions (L x W x H) for the LCC Calculator (does not affect BoL entry).</FormHelperText>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.check_residential}
														onChange={this.handleChange("check_residential")}
														value="check_residential"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Check for Residential Address"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_outbound_collect}
														onChange={this.handleChange("enable_outbound_collect")}
														value="enable_outbound_collect"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Outbound Collect"
											/>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_prepaid_and_charge}
														onChange={this.handleChange("enable_prepaid_and_charge")}
														value="enable_prepaid_and_charge"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Prepaid & Charge"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_miler}
														onChange={this.handleChange("enable_miler")}
														value="enable_miler"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Miler"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_claims}
														onChange={this.handleChange("enable_claims")}
														value="enable_claims"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Claims"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_liability}
														onChange={this.handleChange("enable_liability")}
														value="enable_liability"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Liability
														<WithTooltip content="Enabling this option will disable Total Declared Value if enabled">
															<InfoOutlined fontSize="small"/>
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_total_declared_value}
														onChange={this.handleChange("enable_total_declared_value")}
														value="enable_total_declared_value"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Total Declared Value
														<WithTooltip content="Enabling this option will disable Carrier Liability Support if enabled">
															<InfoOutlined fontSize="small"/>
														</WithTooltip>
													</>
												}
											/>
										</GridItem>

										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_blind_shipments}
														onChange={this.handleChange("enable_blind_shipments")}
														value="enable_blind_shipments"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Blind Shipments"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_fedex_address_validation}
														onChange={this.handleChange("enable_fedex_address_validation")}
														value="enable_fedex_address_validation"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="FedEx Address Validation (Parcel)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_parcel_dg}
														onChange={this.handleChange("enable_parcel_dg")}
														value="enable_parcel_dg"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Dangerous Goods (Parcel)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_dgd}
														onChange={this.handleChange("enable_dgd")}
														value="enable_dgd"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="DGD"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_acc_recommendations}
														onChange={this.handleChange("enable_acc_recommendations")}
														value="enable_acc_recommendations"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Shiplify Accessorial Recommendations"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.auto_calc_expected_delivery}
														onChange={this.handleChange("auto_calc_expected_delivery")}
														value="auto_calc_expected_delivery"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Auto-Calculate Expected Delivery Date"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.custom_piece_count}
														onChange={this.handleChange("custom_piece_count")}
														value="custom_piece_count"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Custom Piece Count"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_costco_label}
														onChange={this.handleChange("enable_costco_label")}
														value="enable_costco_label"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Costco Label Printing"
											/>
										</GridItem>
									</GridContainer>
									<GridContainer style={{ marginTop: "20px" }}>
										<GridItem xs={12} sm={12} md={12}>
											<legend>Claims Contact</legend>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<InputLabel className={classes.label}>First Name</InputLabel>
													<br />
													<CustomInput
														id="claims_contact_first_name"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "claims_contact_first_name",
															value: this.state.claims_contact_first_name || "",
															onChange: this.handleInput("claims_contact_first_name")
														}}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<InputLabel className={classes.label}>Last Name</InputLabel>
													<br />
													<CustomInput
														id="claims_contact_last_name"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "claims_contact_last_name",
															value: this.state.claims_contact_last_name || "",
															onChange: this.handleInput("claims_contact_last_name")
														}}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<InputLabel className={classes.label}>Email</InputLabel>
													<br />
													<CustomInput
														id="claims_contact_email"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "claims_contact_email",
															value: this.state.claims_contact_email || "",
															onChange: this.handleInput("claims_contact_email")
														}}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<InputLabel className={classes.label}>Phone</InputLabel>
													<br />
													<CustomInput
														id="claims_contact_phone"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "claims_contact_phone",
															value: this.state.claims_contact_phone || "",
															onChange: this.handleInput("claims_contact_phone")
														}}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.pure_abf_bm}
														onChange={this.handleChange("pure_abf_bm")}
														value="pure_abf_bm"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Pure ABF Benchmark"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.use_special_pallets}
														onChange={this.handleChange("use_special_pallets")}
														value="use_special_pallets"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Special Pallets"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Truckload Markup</InputLabel>
											<br />
											<CustomInput
												id="tl_markup"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "tl_markup_value",
													value: this.state.tl_markup_value || "",
													onChange: this.handleInput("tl_markup_value")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_tl_quote}
														onChange={this.handleChange("enable_tl_quote")}
														value="enable_tl_quote"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="TFM Truckload API"
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_auto_class}
														onChange={this.handleChange("enable_auto_class")}
														value="enable_auto_class"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="PCF Calculator"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.allow_auto_pro}
														onChange={this.handleChange("allow_auto_pro")}
														value="allow_auto_pro"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="PRO Books Feature (Auto-PRO)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.ctsi_bm_accessorial}
														onChange={this.handleChange("ctsi_bm_accessorial")}
														value="ctsi_bm_accessorial"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="CTSI Benchmark Accessorial"
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.sync_tfmc}
														onChange={this.handleChange("sync_tfmc")}
														value="sync_tfmc"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Sync BoLs with TFMCentral"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.auto_cancel_bols}
														onChange={this.handleChange("auto_cancel_bols")}
														value="auto_cancel_bols"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Auto-Cancel BOLs"
											/>
											<div>
												<Fade in={this.state.auto_cancel_bols}>
													<GridContainer>
														<GridItem xs={12} sm={12} md={12}>
															<InputLabel className={classes.label}>BOL Max Life</InputLabel>
															<br />
															<CustomInput
																id="bol_max_life"
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "number",
																	name: "bol_max_life",
																	value: this.state.bol_max_life || "",
																	onChange: this.handleInput("bol_max_life"),
																	endAdornment: <InputAdornment position="end">Day(s)</InputAdornment>
																}}
															/>
														</GridItem>
													</GridContainer>
												</Fade>
											</div>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.show_hus}
														onChange={this.handleChange("show_hus")}
														value="show_hus"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Show Total Handling Units"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.show_pcs}
														onChange={this.handleChange("show_pcs")}
														value="show_pcs"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Show Total Pieces"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.print_uploaded_bol_images}
														onChange={this.handleChange("print_uploaded_bol_images")}
														value="print_uploaded_bol_images"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Include Uploaded Images"
											/>
										</GridItem>
									</GridContainer>
									<GridContainer style={{ marginTop: "20px" }}>
										<GridItem xs={12} sm={12} md={12}>
											<legend>Additional Required BOL/Parcel Fields</legend>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_pro}
																onChange={this.handleChange("require_pro")}
																value="require_pro"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label={
															<>
																Require PRO #
																<WithTooltip content="N/A to Parcel">
																	<InfoOutlined fontSize="small"/>
																</WithTooltip>
															</>
														}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_po}
																onChange={this.handleChange("require_po")}
																value="require_po"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Require PO #"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_so}
																onChange={this.handleChange("require_so")}
																value="require_so"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Require Order/SO #"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_ref}
																onChange={this.handleChange("require_ref")}
																value="require_ref"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Require Reference #"
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_cons_contact}
																onChange={this.handleChange("require_cons_contact")}
																value="require_cons_contact"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Require Consignee Contact Name"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_cons_phone}
																onChange={this.handleChange("require_cons_phone")}
																value="require_cons_phone"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label={
															<>
																Require Consignee Contact Phone
																<WithTooltip content="Always required for Parcel">
																	<InfoOutlined fontSize="small"/>
																</WithTooltip>
															</>
														}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_cons_email}
																onChange={this.handleChange("require_cons_email")}
																value="require_cons_email"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label={
															<>
																Require Consignee Contact Email
																<WithTooltip content="N/A to Parcel">
																	<InfoOutlined fontSize="small"/>
																</WithTooltip>
															</>
														}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_cons_fax}
																onChange={this.handleChange("require_cons_fax")}
																value="require_cons_fax"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label={
														<>
															Require Consignee Contact Fax
															<WithTooltip content="N/A to Parcel">
																<InfoOutlined fontSize="small"/>
															</WithTooltip>
														</>
														}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
									<GridContainer style={{ marginTop: "20px"}}>
										<GridItem xs={12} sm={12} md={12}>
											<legend>Prevent Duplicate Identifiers</legend>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.prevent_dup_identifiers}
																onChange={this.handleChange("prevent_dup_identifiers")}
																value="prevent_dup_identifiers"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Ask to Cancel Duplicates"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.unique_po}
																onChange={this.handleChange("unique_po")}
																value="unique_po"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Unique PO #"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.unique_so}
																onChange={this.handleChange("unique_so")}
																value="unique_so"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Unique Order/SO #"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.unique_ref}
																onChange={this.handleChange("unique_ref")}
																value="unique_ref"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Unique Reference #"
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
									<GridContainer style={{ marginTop: "20px" }}>
										<GridItem xs={12} sm={12} md={12} lg={12}>
											<legend>New Linear Foot Calculation</legend>
										</GridItem>
										<GridItem xs={12} sm={12} md={6} lg={12}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.enable_new_ln_ft_method}
														onChange={this.handleChange("enable_new_ln_ft_method")}
														value="enable_new_ln_ft_method"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable New Linear Feet Calculation Method"
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={6} lg={3}>
											<InputLabel className={classes.label}>Custom Trailer Length (inches)</InputLabel>
											<br />
											<CustomInput
												id="custom_trailer_length"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "number",
													name: "custom_trailer_length",
													value: this.state.custom_trailer_length || "",
													onChange: this.handleInput("custom_trailer_length")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={6} lg={3}>
											<InputLabel className={classes.label}>Custom Trailer Width (inches)</InputLabel>
											<br />
											<CustomInput
												id="custom_trailer_width"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "number",
													name: "custom_trailer_width",
													value: this.state.custom_trailer_width || "",
													onChange: this.handleInput("custom_trailer_width")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={6} lg={3}>
											<InputLabel className={classes.label}>Custom Trailer Height (inches)</InputLabel>
											<br />
											<CustomInput
												id="custom_trailer_height"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "number",
													name: "custom_trailer_height",
													value: this.state.custom_trailer_height || "",
													onChange: this.handleInput("custom_trailer_height")
												}}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer style={{ marginTop: "20px" }}>
										<GridItem xs={12} sm={12} md={12}>
											<legend>Other BOL Options</legend>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.so_text_area}
																onChange={this.handleChange("so_text_area")}
																value="so_text_area"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Order/SO # Text Area"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.show_execute_warning}
																onChange={this.handleChange("show_execute_warning")}
																value="show_execute_warning"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Execute BOL Warning"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.require_pod_for_billing}
																onChange={this.handleChange("require_pod_for_billing")}
																value="require_pod_for_billing"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Enable Require POD for Billing"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<InputLabel htmlFor="ref_entry_type" className={classes.label}>
														Project44 Preferred Document Format
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select
															}}
															value={this.state.p44_document_format}
															inputProps={{
																name: "p44_document_format",
																id: "p44_document_format"
															}}
															onChange={this.handleSelect}
														>
														{this.displayP44Formats(this.state.p44_formats)}
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={!!this.state.hide_tl_carriers}
																onChange={this.handleChange("hide_tl_carriers")}
																value="hide_tl_carriers"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label={
															<>
																Hide FTL Carriers From Rate Display
																<WithTooltip content={'If enabled, the selected SCACS will be removed from the users view if provided in a rate.'}
																>
																	<InfoOutlined fontSize="small"/>
																</WithTooltip>
															</>
															}
													/>
												</GridItem>
												{(this.state.hide_tl_carriers) && (
													<>
														<GridItem xs>
															<InputTag
																inputLabel={'Assign FTL SCACs to Company'}
																classes={classes}
																value={this.state.company_ftl_scacs}
																options={this.state.tfm_scacs}
																onChange={this.handleFtlScacAssignment}
																getOptionSelected={(option, value) => option == value}
																getOptionLabel={(option) => `${option}`}
																renderOption={(option, { selected }) => (
																	<>
																		<Checkbox
																			checkedIcon={<Check className={this.props.classes.checkedIcon} />}
																			icon={<Check className={this.props.classes.uncheckedIcon} />}
																			style={{ marginRight: 8 }}
																			checked={selected}
																		/>
																		{option}
																	</>
																)}
															/>
														</GridItem>
													</>
												)}
											</GridContainer>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<InputLabel htmlFor="ref_entry_type" className={classes.label}>
														Reference # Entry Type
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select
															}}
															value={this.state.ref_entry_type}
															inputProps={{
																name: "ref_entry_type",
																id: "ref_entry_type"
															}}
															onChange={this.handleSelect}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="freeform"
															>
																Freeform Input
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="select"
															>
																Select Option
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={12} sm={6} md={9}>
													{(this.state.ref_entry_type === 'select') ? (
														<div>
															<InputLabel className={classes.label}>
																Reference # Entry Options
															</InputLabel>
															<InputList
																values={this.state.ref_entry_opts}
																onChange={values => this.setState({ ref_entry_opts: values })}
															/>
														</div>
													): ''}
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "users" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<NavLink to={basePath + "/admin/company-" + this.props.match.params.id + "/user/new"}>
												<Button
													color="linkedin"
													style={{
														marginRight: "4px"
													}}
												>
													New User
												</Button>
											</NavLink>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.users) ?
												<Table
													tableHead={["ID", "Username", "Code", "Permission Group", "Rate Display", "API Key", "Actions"]}
													tableData={this.getUsers(this.state.users)}
													customCellClasses={[classes.right]}
													customClassesForCells={[6]}
													customHeadCellClasses={[classes.right]}
													customHeadClassesForCells={[6]}
												/> :
												<Badge color="info">No Users</Badge>}
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "warehouses" && (
							<Card>
								<CardBody>
									<WarehouseManagement
										warehouses={this.state.warehouses}
										warehouse={this.state.currentWarehouse}
										tabIndex={this.state.currentWarehouseTab}
										cid={this.props.match.params.id}
										companyUsers={this.state.users}
										currentWarehouseUsers={this.state.currentWarehouseUsers}
										carriers={this.state.carriers}
										enableUser={this.enableUser}
										disableUser={this.disableUser}
										grantApiKey={this.grantApiKey}
										disableApiKey={this.disableApiKey}
										setCurrentWarehouse={this.setCurrentWarehouse}
										setCurrentWarehouseTab={this.setCurrentWarehouseTab}
										removeUserFromWarehouse={this.removeUserFromWarehouse}
										addUserToWarehouse={this.addUserToWarehouse}
										removeCarrierFromWarehouse={this.removeCarrierFromWarehouse}
										saveCarrierToWarehouse={this.saveCarrierToWarehouse}
									/>
								</CardBody>
							</Card>
						)}
						{this.state.active === "splits" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<Button color="linkedin" style={{ marginRight: "4px" }} onClick={this.addSplit}>
												New Split Change
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.splits) ? (
												<Table tableHead={["ID", "Change Date", "New Billing Type", "New Calc Type", "New Split", "Min Charge", "Min Profit Margin", "Passthru", "Applied", "Actions"]} tableData={this.getSplits(this.state.splits)} customCellClasses={[classes.right]} customClassesForCells={[9]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[9]} />
											) : (
												<Badge color="info">No Splits</Badge>
											)}
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "accessorials" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<Button color="info" onClick={this.saveProfiles}>
												Save Changes
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12}>{!_.isEmpty(this.state.profiles) && <Table tableHead={["Assign", "Name", "Effective From", "Effective To"]} tableData={this.getProfiles(this.state.profiles)} />}</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "carriers" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={3}>
											<NavLink to={basePath + "/admin/company-" + this.props.match.params.id + "/carrier/new"}>
												<Button color="linkedin" style={{ marginRight: "4px" }}>
													New Carrier
												</Button>
											</NavLink>
											<Button color="linkedin" style={{ marginRight: "4px" }} onClick={this.exportCarriers}>
												Export
											</Button>
											<NavLink to={basePath + `/admin/company/${this.props.match.params.id}/importCompanyCarriers`}>
												<Button color="linkedin" style={{ marginRight: "4px" }}>
													Import
												</Button>
											</NavLink>
										</GridItem>
										<GridItem xs={6} className={classes.center}>
											<Pagination pages={this.getPages(this.state.carrier_paging)} />
										</GridItem>
										<GridItem xs={3}>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.carriers) ? (
												<Table
													tableHead={["ID", "Carrier", "User", "Pass", "Acct. #", "License Key", "Meter #", "P44 Code", "P44 Enabled", "Actions"]}
													tableData={this.getCarriers(this.state.carriers)}
													customCellClasses={[classes.right]}
													customClassesForCells={[9]}
													customHeadCellClasses={[classes.right]}
													customHeadClassesForCells={[9]}
												/>
											) : (
												<Badge color="info">No Carriers</Badge>
											)}
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "routingGuides" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<Button color="linkedin" onClick={this.addRoutingGuide} disabled={!_.isEmpty(this.state.guides) && this.state.guides[0].id === ""}>
												New Routing Guide
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.guides) ? (
												<Table
													tableHead={[
														"ID", "SCAC", "Carrier", "Weight From", "Weight To", "Max. Cube",
														"Min. PCF", "Override Max. Cube", "Override Min. PCF", "Carrier Type", 
														"Override Program Pricing", "Fee Type", "Fee Value", 
														"Allow Manual", "Int'l Provider", "Start Date", "End Date",
														<Tooltip
															title={
																<React.Fragment>
																	<Typography color="inherit">Note:</Typography>
																	If a rule has a custom message set, then this message will be shown in place of the default message on the quote panel when the rule is enforced. This portion of the default message will always be shown, and does not need to be included in your custom message: <b>"Shipment hits (rule) exclusion rules"</b>
																</React.Fragment>
															}
															placement="bottom"
														>
															<span>
																Custom Message
																<Warning style={{ height: "16px" }}/>
															</span>
														</Tooltip>,
														"Actions"
													]}
													tableData={this.getRoutingGuides(this.state.guides)}
													customCellClasses={[classes.right]}
													customClassesForCells={[12]}
													customHeadCellClasses={[classes.right]}
													customHeadClassesForCells={[12]}
												/>
											) : (
												<Badge color="info">No Routing Guides</Badge>
											)}
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "nonLcc" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<Button color="info" style={{ marginLeft: "4px" }} onClick={this.saveOptions}>
												Save Changes
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={!!this.state.require_non_lcc_selection_explanation}
														onChange={this.handleChecked("require_non_lcc_selection_explanation")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require Explanation when User Does Not Select LCC"
											/>
										</GridItem>
										<GridItem xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={!!this.state.non_lcc_selection_send_email}
														onChange={this.handleChecked("non_lcc_selection_send_email")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Email Manager when Non LCC selected"
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<h5>Warehouse Manager Emails</h5>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12} className={classes.center}>
											{!_.isEmpty(this.state.warehouse_emails) ? <Table tableHead={["Warehouse", "Email"]} tableData={this.getWarehouseEmails(this.state.warehouse_emails)} /> : <Badge color="info">No Warehouse Emails</Badge>}
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={6}>
											<h5>Configure Available Non LCC Selection Options</h5>
										</GridItem>
										<GridItem xs={6} className={classes.right}>
											<Button color="info" size="sm" onClick={this.addSelectionOption}>
												<Add /> New Option
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12} className={classes.center}>
											{!_.isEmpty(this.state.selection_options) ? (
												<Table tableHead={["Assign", "Code", "Description", "Actions"]} tableData={this.getSelectionOptions(this.state.selection_options)} customCellClasses={[classes.right]} customClassesForCells={[3]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[3]} />
											) : (
												<Badge color="info">No Selection Options</Badge>
											)}
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "linearFootRules" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<Button
												color="info"
												onClick={e => {
													this.saveCompany();
												}}
											>
												Save Changes
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											<Table tableHead={["Assigned", "Name", "SCAC", "Carrier",
												<Tooltip
													title={
														<React.Fragment>
															<Typography color="inherit">Note:</Typography>
																Rule must be assigned to set a custom message. If a rule has a custom message set, then this message will be shown in place of the default message on the quote panel when the rule is enforced. This portion of the default message will always be shown, and does not need to be included in your custom message: <b>"Shipment hits Linear Feet exclusion rules"</b>
														</React.Fragment>
													}
													placement="bottom"
												>
													<span>
														Custom Message
														<Warning style={{ height: "16px" }}/>
													</span>
												</Tooltip>
											]}
								 				tableData={this.getLinearFeetRules(this.state.linearFeetRules)} customCellClasses={[classes.right]} customClassesForCells={[8]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[8]} />
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "notes" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<Button color="linkedin" onClick={this.addNote} disabled={!_.isEmpty(this.state.notes[0]) && this.state.notes[0].id === ""}>
												New Note
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer justify="center">
										{!_.isEmpty(this.state.notes) ? this.getNotes(this.state.notes) : (
											<GridItem xs={12} className={classes.center}>
												<Badge color="info">No Notes</Badge>
											</GridItem>
										)}
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === "international" && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<Button
												color="info"
												style={{
													marginRight: "4px"
												}}
												onClick={e => {
													this.saveCompany();
												}}
											>
												Save Changes
											</Button>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<legend>Required International Fields</legend>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_master_bill}
														onChange={this.handleChange("require_master_bill")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Require Master Bill #"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_house_bill}
														onChange={this.handleChange("require_house_bill")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="House Bill #"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_airway_bill}
														onChange={this.handleChange("require_airway_bill")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Airway Bill #"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_isf}
														onChange={this.handleChange("require_isf")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="ISF/ITN #"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_intl_po}
														onChange={this.handleChange("require_intl_po")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="PO #"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_intl_so}
														onChange={this.handleChange("require_intl_so")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="SO #"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.state.require_intl_ref}
														onChange={this.handleChange("require_intl_ref")}
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="REF #"
											/>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
					</GridItem>
				) : (
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardBody>
								<GridContainer>
									<Spinner loading={this.state.loading} message="Failed to retrieve company settings from the server" />
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				)}
			</GridContainer>
		);
	}
}

export default connect(null, {clearNotifications})(withStyles(style)(withSnackbar(EditCompany)));
