import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { createHashHistory } from "history";
import createRootReducer from "./reducers.jsx";
import thunk from 'redux-thunk';
// import sagas from "./sagas.jsx";

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(preloadedState) {
	const middlewares = [
		thunk,
		// loggerMiddleware,
		sagaMiddleware,
		routerMiddleware(history) // for dispatching history actions
	];
	const middlewareEnhancer = applyMiddleware(...middlewares);
	const enhancers = [middlewareEnhancer];
	const composedEnhancers = composeWithDevTools(...enhancers);
	const store = createStore(
		createRootReducer(history), // root reducer with router state
		preloadedState,
		composedEnhancers
	);
	// sagaMiddleware.run(sagas);
	return store;
}
