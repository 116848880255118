import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import axios from "~/variables/axios.jsx";
import { Redirect } from "react-router-dom";
import { basePath, apiUrl } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import "formdata-polyfill";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

// material ui icons
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewClaim extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			saved: false,
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			alert: null,
			redirect: null,
			type: "",
			carrier: "",
			status: "Open",
			denial_reason: "",
			company: "",
			bol: "",
			pro: "",
			claimNumber: "",
			direction: "",
			shipperZip: "",
			consigneeZip: "",
			contacts: [
				{
					first_name: "",
					last_name: "",
					phone: "",
					phone_ext: "",
					email: ""
				}
			],
			products: [
				{
					quantity: "",
					weight: "",
					price: "",
					description: ""
				}
			],
			description: "",
			files: [],
			isTTMSBoL: false,
			view_max_liability: false,
			loadingLiability: false,
			showLiability: false,
			max_liability: [],
			total: "0.00"
		};
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleContact = this.handleContact.bind(this);
		this.getContacts = this.getContacts.bind(this);
		this.addContact = this.addContact.bind(this);
		this.removeContact = this.removeContact.bind(this);
		this.handleProduct = this.handleProduct.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.addProduct = this.addProduct.bind(this);
		this.removeProduct = this.removeProduct.bind(this);
		this.submitClaim = this.submitClaim.bind(this);
		this.getImages = this.getImages.bind(this);
		this.getBol = this.getBol.bind(this);
		this.getMaxLiability = this.getMaxLiability.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.setContacts = this.setContacts.bind(this);
		this.duplicatePro = this.duplicatePro.bind(this);
		this.updateTotal = this.updateTotal.bind(this);
	}

	async componentDidMount() {
		let { bol, alert } = this.state;
		if (!_.isEmpty(this.props.match.params.bol)) {
			bol = this.props.match.params.bol;
			alert = (
				<SweetAlert
					info
					style={{
						display: "block",
						color: "black"
					}}
					title="BoL is loading."
					onConfirm={() => {}}
					showConfirm={false}
				>
					Please wait...
					<br />
					<div style={{ textAlign: "center", margin: 5 }}>
						<CircularProgress />
					</div>
				</SweetAlert>
			);
		}
		this.setState({ alert, bol, mounted: true });
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=claims&m=create");
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body && response.data.user && this.state.mounted) {
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					body: response.data.body
				});
				if (!_.isEmpty(bol)) {
					this.getBol();
				}
			} else {
				this.setState({ loading: false, alert: null });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false, alert: null });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		let { isTTMSBoL } = this.state;
		if (name === "bol") {
			isTTMSBoL = false;
		}
		this.setState({ [name]: event.target.value, isTTMSBoL });
		if (name === "pro") {
			this.duplicatePro(event.target.value);
		}
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
		if (event.target.name === "carrier") {
			this.getMaxLiability(event.target.value);
		} else if (event.target.name === "company") {
			this.setContacts(event.target.value);
		}
	}

	handleUpload(files) {
		this.setState({ files: this.state.files.concat(files) });
	}

	handleContact(i, name, event) {
		const { contacts } = this.state;
		const prod = contacts[i];
		prod[name] = event.target.value;
		contacts[i] = prod;
		this.setState({ contacts });
	}

	setContacts(companyId) {
		if (_.isEmpty(this.state.body) || _.isEmpty(this.state.body.companies) || _.isEmpty(companyId)) {
			return;
		}
		const companies = this.state.body.companies;
		const company = companies.filter(prop => prop.id == companyId)[0];
		const contacts = [
			{
				first_name: company.claims_contact_first_name,
				last_name: company.claims_contact_last_name,
				phone: company.claims_contact_phone,
				phone_ext: "",
				email: company.claims_contact_email
			}
		];
		this.setState({ contacts });
	}

	getContacts(contacts) {
		const { classes } = this.props;
		return contacts.map((prod, key) => {
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={classes.package}>
						<GridContainer>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>First Name</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "first_name",
										value: prod.first_name || "",
										onChange: e => this.handleContact(key, "first_name", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Last Name</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "last_name",
										value: prod.last_name || "",
										onChange: e => this.handleContact(key, "last_name", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Phone</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "phone",
										value: prod.phone || "",
										onChange: e => this.handleContact(key, "phone", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Extension</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "phone_ext",
										value: prod.phone_ext || "",
										onChange: e => this.handleContact(key, "phone_ext", e)
									}}
									white
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Email</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "email",
										value: prod.email || "",
										onChange: e => this.handleContact(key, "email", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs={1} className={classes.right}>
								{key == 0 ? (
									<Button color="success" size="sm" justIcon round className={classes.chip} onClick={() => this.addContact()}>
										<Add />
									</Button>
								) : (
									<Button color="danger" size="sm" justIcon round className={classes.chip} onClick={() => this.removeContact(key)}>
										<Close />
									</Button>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			);
		});
	}

	addContact() {
		const { contacts } = this.state;
		contacts.push({
			first_name: "",
			last_name: "",
			phone: "",
			phone_ext: "",
			email: ""
		});
		this.setState({ contacts });
	}

	removeContact(i) {
		const { contacts } = this.state;
		if (i !== 0) {
			contacts.splice(i, 1);
			this.setState({ contacts });
		}
	}

	handleProduct(i, name, event) {
		const { products } = this.state;
		const prod = products[i];
		prod[name] = event.target.value;
		products[i] = prod;
		this.setState({ products });
		this.updateTotal();
	}

	getProducts(data) {
		const { classes } = this.props;
		return data.map((prod, key) => {
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={classes.package}>
						<GridContainer>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Quantity</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "quantity",
										value: prod.quantity || "",
										onChange: e => this.handleProduct(key, "quantity", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Weight Per Item</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "weight",
										value: prod.weight || "",
										onChange: e => this.handleProduct(key, "weight", e),
										endAdornment: <InputAdornment position="end">lbs.</InputAdornment>
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Price Per Item</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "price",
										value: prod.price || "",
										onChange: e => this.handleProduct(key, "price", e),
										startAdornment: <InputAdornment position="start">$</InputAdornment>
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								<small style={{ marginTop: "10px" }}>Description/Part #</small>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "description",
										value: prod.description || "",
										onChange: e => this.handleProduct(key, "description", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs={1} className={classes.right}>
								{key == 0 ? (
									<Button color="success" size="sm" justIcon round className={classes.chip} onClick={() => this.addProduct()}>
										<Add />
									</Button>
								) : (
									<Button color="danger" size="sm" justIcon round className={classes.chip} onClick={() => this.removeProduct(key)}>
										<Close />
									</Button>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			);
		});
	}

	addProduct() {
		const { products } = this.state;
		products.push({
			quantity: "",
			weight: "",
			price: "",
			description: ""
		});
		this.setState({ products });
		this.updateTotal();
	}

	removeProduct(i) {
		const { products } = this.state;
		if (i !== 0) {
			products.splice(i, 1);
			this.setState({ products });
			this.updateTotal();
		}
	}

	getCarriers(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name} | {prop.scac}
				</MenuItem>
			);
		});
	}

	getCompanies(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getStatusOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.status}
				>
					{prop.status}
				</MenuItem>
			);
		});
	}

	getDenialReasonOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.reason}
				>
					{prop.reason}
				</MenuItem>
			);
		});
	}

	async submitClaim() {
		this.setState({
			show: true,
			loading: true
		});

		const data = {
			isTTMSBoL: this.state.isTTMSBoL,
			general: {
				type: this.state.type,
				carrier: this.state.carrier,
				bol: this.state.bol,
				pro: this.state.pro
			}
		};

		if (_.isEmpty(data.general.type)) {
			this.props.setNotification("Please select the claim type.", {
				variant: "error"
			});
			this.setState({loading: false});
			return;
		}

		if (_.isEmpty(data.general.carrier)) {
			this.props.setNotification("Please select the carrier associated with this claim.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		if (_.isEmpty(data.general.pro)) {
			this.props.setNotification("Please provide the PRO# associated with this claim.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		if (!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin" && _.isEmpty(this.state.status)) {
			this.props.setNotification("Please select the claim status.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		if (!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin") {
			data.general.status = this.state.status;
		}

		if (!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin") {
			data.general.denial_reason = this.state.denial_reason;
		}

		if (!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin" && _.isEmpty(this.state.company)) {
			this.props.setNotification("Please select a company associated with the claim.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		if (!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin") {
			data.general.company = this.state.company;
			if (!_.isEmpty(this.state.company)) {
				data.general.claimNumber = this.state.claimNumber;
			}
		}

		if (_.isEmpty(this.state.contacts)) {
			this.props.setNotification("You cannot submit a claim with no contacts on it.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		data.contacts = this.state.contacts;

		if (_.isEmpty(this.state.products)) {
			this.props.setNotification("You cannot submit a claim with no product information on it.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		const empty_prices = this.state.products.filter(prod => _.isEmpty(prod.price));

		if (!_.isEmpty(empty_prices)) {
			this.props.setNotification("Please enter a price per item for all products on the claim.", { variant: "error" });
			this.setState({loading: false});
			return;
		}

		data.products = this.state.products;
		data.description = this.state.description;

		const url = "/index.php?p=api&r=json&c=claims&m=save";
		let fd = new FormData(); // FormData is needed to upload files
		let config = {};

		let imgCount = 0;
		for (let i = 0; i < this.state.files.length; i++) {
			let file = this.state.files[i];
			if (file.preview) {
				fd.append("imgFile_" + i.toString(), file);
				imgCount++;
			} else if (file.id && file.filename) {
				const imgRes = await axios.get("/index.php?p=api&c=billoflading&m=downloadImg&d=" + file.id);
				const blob = new Blob([imgRes.data]);
				file = new File([blob], file.filename);
				fd.append("imgFile_" + i.toString(), file);
				imgCount++;
			}
		}
		if (imgCount > 0) {
			fd.append("form_data_post", JSON.stringify(data)); // if you don't stringify the POST data, it is ignored
			config = {
				headers: {
					"content-type": "multipart/form-data"
				}
			};
		} else {
			fd = qs.stringify(data);
		}

		try {
			const response = await axios.post(url, fd, config);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body && response.data.body.id) {
				this.setState({
					loading: false,
					saved: true
				});
				this.props.setNotification("Claim successfully created!", { variant: "success" });
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the claim!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error saving the claim!", { variant: "error" });
		}
	}

	getImages(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			const src = prop.preview ? prop.preview : apiUrl + "/index.php?p=api&c=billoflading&m=downloadImg&d=" + prop.id + "thumb=1";
			const extension = prop.name.match(/\.[0-9a-z]+$/i)[0].replace(".", "");
			return <Preview key={key} src={src} extension={extension} index={key} handleClick={this.handleImageClick.bind(this)} handleDelete={this.handleDeleteImage.bind(this)} />;
		});
	}

	handleImageClick(i) {
		const { files } = this.state;
		const image = files[i];
		if (image.id) {
			const url = apiUrl + "/index.php?p=api&c=billoflading&m=downloadImg&d=" + image.id;
			const title = "Image Preview";
			const options = "resizable=1, scrollbars=1, fullscreen=0, status=0";
			window.open(url, title, options);
		}
	}

	handleDeleteImage(key) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure?"
					onConfirm={() => this.deleteImage(key)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this file!
				</SweetAlert>
			)
		});
	}

	deleteImage(i) {
		const { files } = this.state;
		files.splice(i, 1);
		this.setState({ alert: null, files });
	}

	async getBol() {
		const { bol } = this.state;
		if (_.isEmpty(bol)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=claims&m=getBoL&d=" + bol;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				if (!_.isEmpty(response.data.body.general)) {
					const body = response.data.body;
					const status = body.general.status;

					const allowedStatus = ["tfm_received", "auditing", "billed", "third_party_payor", "ttl_received", "ttl_confirmed"];

					if (allowedStatus.indexOf(status) == -1) {
						this.props.setNotification('This BoL cannot be associated with a claim, because its status is "' + status + '"', { variant: "error" });
						return;
					}

					let company = "";

					if (!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin") {
						company = body.company.id;
					}

					// Get products
					let { products } = this.state;
					const unitProducts = [];
					for (const unit of body.units.unit) {
						for (const product of unit.products.product) {
							unitProducts.push({
								quantity: product.pieces,
								weight: Math.round((product.weight / product.pieces) * 100, 2) / 100,
								price: "",
								description: product.name
							});
						}
					}

					if (!_.isEmpty(unitProducts)) {
						products = unitProducts;
					}

					// Get image files
					let files = [];
					const imgResponse = await axios.get("/index.php?p=api&c=billoflading&m=imageCollection&d=" + bol);
					if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.message)) {
						this.props.handleMessages(imgResponse);
					}
					if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.body) && !_.isEmpty(imgResponse.data.body.images)) {
						files = imgResponse.data.body.images;
					}

					this.setState({
						alert: null,
						carrier: body.general.carrier.id,
						pro: body.general.pro,
						isTTMSBoL: true,
						company,
						products,
						files
					});

					this.updateTotal();

					if (!_.isEmpty(company) && !_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin") {
						this.setContacts(company);
					}

					if (!_.isEmpty(body.general.carrier.id)) {
						this.getMaxLiability(body.general.carrier.id);
					}
				} else {
					this.setState({ alert: null });
				}
			} else {
				this.setState({ alert: null });
				this.props.setNotification("Could not find the BoL you were looking for. Either it does not exist or it does not belong to your organization.", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ alert: null });
			this.props.setNotification("There was an error getting the BoL!", {
				variant: "error"
			});
		}
	}

	getColumns(data) {
		return data.map((prop, key) => [prop.classNumber, "$" + prop.amount]);
	}

	async getMaxLiability(carrier) {
		if (_.isEmpty(carrier)) {
			this.setState({
				view_max_liability: false,
				loadingLiability: false
			});
			return;
		}
		this.setState({
			view_max_liability: true,
			loadingLiability: true
		});
		const url = "/index.php?p=api&r=json&c=claims&m=getMaxLiability&carrierId=" + carrier;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				if (!_.isEmpty(response.data.body.records)) {
					this.setState({
						max_liability: response.data.body.records,
						loadingLiability: false,
						showLiability: false
					});
				} else {
					this.setState({ view_max_liability: false, loadingLiability: false });
				}
			} else {
				this.setState({ view_max_liability: false, loadingLiability: false });
				this.props.setNotification("There was an error getting max liability!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ view_max_liability: false, loadingLiability: false });
			this.props.setNotification("There was an error getting max liability!", { variant: "error" });
		}
	}

	async duplicatePro(pro) {
		if (_.isEmpty(pro)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=claims&m=view&filters[pro]=" + encodeURIComponent(pro) + "&limit=9999999";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				if (!_.isEmpty(response.data.body.records)) {
					this.props.setNotification("There is all ready a claim with this PRO#. Submit at your own risk!", { variant: "warning" });
				}
			} else {
				this.props.setNotification("There was an error searching for PROs!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching for PROs!", { variant: "error" });
		}
	}

	updateTotal() {
		let total = 0;
		for (const product of this.state.products) {
			let price = 0;
			if (!_.isEmpty(product.price) && !isNaN(product.price)) {
				price = parseFloat(product.price);
			}
			if (!_.isEmpty(product.quantity) && !isNaN(product.quantity)) {
				price *= product.quantity;
			}
			total += price;
		}
		total = (Math.round(total * 100) / 100).toFixed(2);
		this.setState({ total });
	}

	render() {
		if (this.state.saved) {
			const redirectTo = basePath + "/admin/support/claims";
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>Claim Type</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.type || ""}
											inputProps={{
												name: "type",
												id: "type"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												disabled
												classes={{
													root: classes.selectMenuItem
												}}
											>
												Select Type
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Concealed Damage"
											>
												Concealed Damage
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Damage"
											>
												Damage
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Damage / Shortage"
											>
												Damage / Shortage
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Shortage"
											>
												Shortage
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Lost"
											>
												Lost
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Lost / Damage"
											>
												Lost / Damage
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Service Failure"
											>
												Service Failure
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Other"
											>
												Other
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>Carrier</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.carrier || ""}
											inputProps={{
												name: "carrier",
												id: "carrier"
											}}
											onChange={this.handleChange}
										>
											{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.carriers) && this.getCarriers(this.state.body.carriers)}
										</Select>
									</FormControl>
								</GridItem>
								{!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin" && (
									<GridItem xs={12} sm={6} md={4}>
										<InputLabel className={classes.label}>Status</InputLabel>
										<br />
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select
												MenuProps={{ className: classes.selectMenu }}
												classes={{ select: classes.select + " " + classes.requiredSelect }}
												value={this.state.status || ""}
												inputProps={{
													name: "status",
													id: "status"
												}}
												onChange={this.handleChange}
											>
												{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.status_options) && this.getStatusOptions(this.state.body.status_options)}
											</Select>
										</FormControl>
									</GridItem>
								)}
								{!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin" && (
									<GridItem xs={12} sm={6} md={4}>
										<InputLabel className={classes.label}>Company</InputLabel>
										<br />
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select
												MenuProps={{ className: classes.selectMenu }}
												classes={{ select: classes.select + " " + classes.requiredSelect }}
												value={this.state.company || ""}
												inputProps={{
													name: "company",
													id: "company"
												}}
												onChange={this.handleChange}
											>
												{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.companies) && this.getCompanies(this.state.body.companies)}
											</Select>
										</FormControl>
									</GridItem>
								)}
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>PRO</InputLabel>
									<br />
									<CustomInput
										id="pro"
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "pro",
											value: this.state.pro || "",
											onChange: this.handleInput("pro")
										}}
										required
									/>
								</GridItem>
								{!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin" && (
									<GridItem xs={12} sm={6} md={4}>
										<InputLabel className={classes.label}>Denial Reason</InputLabel>
										<br />
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select
												MenuProps={{ className: classes.selectMenu }}
												classes={{ select: classes.select }}
												value={this.state.denial_reason || ""}
												inputProps={{
													name: "denial_reason",
													id: "denial_reason"
												}}
												onChange={this.handleChange}
											>
												{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.denial_reason_options) && this.getDenialReasonOptions(this.state.body.denial_reason_options)}
											</Select>
										</FormControl>
									</GridItem>
								)}
								{!_.isEmpty(this.state.user) && !_.isEmpty(this.state.user.level) && this.state.user.level == "admin" && (
									<GridItem xs={12} sm={6} md={4}>
										<InputLabel className={classes.label}>Carrier Claim #</InputLabel>
										<br />
										<CustomInput
											id="claimNumber"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "claimNumber",
												value: this.state.claimNumber || "",
												onChange: this.handleInput("claimNumber")
											}}
											white
										/>
									</GridItem>
								)}
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>BOL</InputLabel>
									<Grid container>
										<Grid item xs>
											<CustomInput
												id="bol"
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "bol",
													value: this.state.bol || "",
													onChange: this.handleInput("bol")
												}}
												white
											/>
										</Grid>
										<Grid item xs={2} style={{ marginTop: "0px", marginBottom: "7px" }}>
											<Button justIcon round size="sm" color="linkedin" className={classes.marginLeft} onClick={this.getBol}>
												<Search />
											</Button>
										</Grid>
									</Grid>
								</GridItem>
								{this.state.view_max_liability && (
									<GridItem xs={12}>
										{!this.state.loadingLiability ? (
											<GridContainer>
												<GridItem xs={12}>
													<Button
														color="linkedin"
														size="sm"
														simple
														onClick={() => {
															this.setState({ showLiability: !this.state.showLiability });
														}}
													>
														View Max Liability For Carriers
													</Button>
												</GridItem>
												{this.state.showLiability && <GridItem xs={12}>{!_.isEmpty(this.state.max_liability) && <Table tableHead={["Class", "Amount / lb."]} tableData={this.getColumns(this.state.max_liability)} />}</GridItem>}
											</GridContainer>
										) : (
											<Spinner loading message="" />
										)}
									</GridItem>
								)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<h5>Contacts</h5>
									<small>Contacts added here will receive emails for all future updates to this claim.</small>
								</GridItem>
								{this.state.contacts.length && (
									<GridItem xs={12} sm={12} md={12} className={classes.unitContainer}>
										{this.getContacts(this.state.contacts)}
									</GridItem>
								)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<h5>Products</h5>
								</GridItem>
								{this.state.products.length && (
									<GridItem xs={12} sm={12} md={12} className={classes.unitContainer}>
										{this.getProducts(this.state.products)}
									</GridItem>
								)}
								<GridItem xs={12} sm={12} md={12} className={classes.right}>
									<h5>
										<strong>Total Amount Requested:</strong> <strong style={{ color: "green" }}>${this.state.total}</strong>
									</h5>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<InputLabel className={classes.label}>Additional Notes</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "description",
											multiline: true,
											value: this.state.description || "",
											onChange: this.handleInput("description")
										}}
										white
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<h5>Upload Files</h5>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<Dropzone handleUpload={this.handleUpload} />
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<aside className={classes.thumbsContainer}>{!_.isEmpty(this.state.files) && this.getImages(this.state.files)}</aside>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									<Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.submitClaim} disabled={this.state.loading}>
										Submit
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(NewClaim));