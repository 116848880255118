import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";

import Badge from "~/components/Badge/Badge.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Transition from "~/components/TMS/ModalTransition.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class SurfaceAreaModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Surface Area Usage Calculator</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <GridContainer>
                        <GridItem xs={2} className={classes.center}>
                            <GridContainer>
                                <GridItem xs={12} style={{ marginTop: "25px", textAlign: "center" }}>
                                    <Badge color="gray">Product</Badge>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} style={{ marginTop: "55px", textAlign: "center" }}>
                                    <Badge color="gray">Pallet</Badge>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={7}>
                            <GridContainer className={classes.dividerBottom}>
                                <GridItem xs={5}>
                                    <InputLabel className={classes.label}>Length</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            name: "productLength",
                                            value: this.props.productLength || "",
                                            onChange: this.props.productLengthChange
                                        }}
                                        white
                                    />
                                </GridItem>
                                <GridItem xs={2} style={{ marginTop: "30px" }}>
                                    <Close />
                                </GridItem>
                                <GridItem xs={5}>
                                    <InputLabel className={classes.label}>Width</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            name: "productWidth",
                                            value: this.props.productWidth || "",
                                            onChange: this.props.productWidthChange
                                        }}
                                        white
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={5} style={{ marginTop: "8px" }}>
                                    <InputLabel className={classes.label}>Length</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            name: "palletLength",
                                            value: this.props.palletLength || "",
                                            onChange: this.props.palletLengthChange
                                        }}
                                        white
                                    />
                                </GridItem>
                                <GridItem xs={2} style={{ marginTop: "33px" }}>
                                    <Close />
                                </GridItem>
                                <GridItem xs={5} style={{ marginTop: "8px" }}>
                                    <InputLabel className={classes.label}>Width</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            name: "palletWidth",
                                            value: this.props.palletWidth || "",
                                            onChange: this.props.palletWidthChange
                                        }}
                                        white
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={2} style={{ paddingTop: "45px" }}>
                            <h5>{this.props.surfaceArea}%</h5>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => this.props.onClose()} color="danger" style={{ marginRight: "5px" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(SurfaceAreaModal);