import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Search from "@material-ui/icons/Search";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Grid from "@material-ui/core/Grid";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class MergeBolModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Merge BoL Tool</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <GridContainer>
                        <Grid item xs={4} style={{ paddingRight: "10px", width: "250px" }}>
                            <FormControl className={classes.selectFormControl} fullWidth>
                                <Select
                                    MenuProps={{ className: classes.selectMenu }}
                                    classes={{ select: classes.select }}
                                    value={this.props.searchBolMergeBy || ""}
                                    inputProps={{
                                        name: "search_bol_merge_by",
                                        id: "search_bol_merge_by"
                                    }}
                                    onChange={this.props.handleChange}
                                >
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="bol"
                                    >
                                        BoL #
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="status"
                                    >
                                        Status
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="customReference"
                                    >
                                        Custom Reference
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="custom"
                                    >
                                        Custom #
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="carrier"
                                    >
                                        Carrier Name
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="shipper"
                                    >
                                        Shipper Name
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="oCity"
                                    >
                                        Origin City
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="oState"
                                    >
                                        Origin State
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="consignee"
                                    >
                                        Consignee Name
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="dCity"
                                    >
                                        Destination City
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="dState"
                                    >
                                        Destination State
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="pro"
                                    >
                                        PRO #
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="ref"
                                    >
                                        Reference #
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="po"
                                    >
                                        PO #
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="so"
                                    >
                                        Order/SO #
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="date"
                                    >
                                        Date
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="transitStatus"
                                    >
                                        Transit Status
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="pickupDate"
                                    >
                                        Pickup Date
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="expectedDeliveryDate"
                                    >
                                        Expected Delivery Date
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="deliveryDate"
                                    >
                                        Delivery Date
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {this.props.searchBolMergeBy != 'customReference' ? (
                            <Grid item xs={6} style={{ paddingTop: "1px", width: "500px" }}>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        id: "search_bol_merge",
                                        name: "search_bol_merge",
                                        type: "text",
                                        value: this.props.searchBolMerge || "",
                                        onChange: this.props.handleInput("search_bol_merge"),
                                        onKeyPress: e => this.props.handleKeyPress("bolSearchMerge", e)
                                    }}
                                    white
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={6} style={{ paddingTop: "1px", width: "250px" }}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                inputProps={{
                                                    name: "search_bol_merge_reference_type",
                                                    id: "search_bol_merge_reference_type"
                                                }}
                                                classes={{ select: classes.select }}
                                                value={this.props.searchBolMergeReferenceType || ""}
                                                onChange={this.props.handleChange}
                                            >
                                                {this.props.getRefTypes()}
                                            </Select>
                                       </FormControl>
                                    </GridItem>
                                    <GridItem xs={6} style={{ paddingTop: "1px", width: "500px" }}>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                name: "search_bol_merge",
                                                value: this.props.searchBolMerge || "",
                                                onChange: this.props.handleInput("search_bol_merge"),
                                                onKeyPress: e => this.props.handleKeyPress("bolSearchMerge", e)
                                            }}
                                            white
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Grid>
                        )}
                        <Grid item xs={2}>
                            <Button justIcon round color="linkedin" className={classes.marginLeft} onClick={this.props.handleSearchMerge()}>
                                <Search />
                            </Button>
                        </Grid>
                    </GridContainer>
                    <GridContainer>{!_.isEmpty(this.props.bolsMerge) && this.props.getBolsMerge()}</GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="warning" onClick={() => this.props.onClose()} className={classes.marginRight}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(MergeBolModal);