import React from "react";
import { connect } from "react-redux";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { hasPermission } from "../../redux/selectors/Admin.jsx";

class CannedNotes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			alert: null,
			id: null,
			data: [],
			warehouse: null,
			warehouses: []
		};
		this.handleChange = this.handleChange.bind(this);
		this.getNotes = this.getNotes.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.deleteNote = this.deleteNote.bind(this);
		this.getWarehouses = this.getWarehouses.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}


	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const url = "/index.php?p=api&r=json&c=userSettings&m=notes";
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user) && !_.isEmpty(data.body) && this.state.mounted) {
				const { warehouses } = data.body;
				let warehouse = warehouses.filter(warehouse => {
					return warehouse.is_default == 1;
				});
				if(_.isEmpty(warehouse)) {
					warehouse = data.body.warehouses;
				}
				this.setState({
					show: true,
					loading: false,
					data: data.body.notes,
					warehouse: warehouse[0].id,
					warehouses
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", { variant: "error" });
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
		if (event.target.name === "warehouse") {
			this.handleSearch(event.target.value);
		}
	}

	getNotes(data) {
		return data.map((prop, key) => {
			const link = basePath + "/admin/note/" + prop.id;
			return [
				prop.id,
				prop.name,
				prop.note,
				prop.default == 1 ? "*" : "",
				<div className="actions-right">
					<NavLink to={link}>
						<Button size="sm" color="info">
							{this.props.hasWrite ? "View/Edit" : "View"}
						</Button>
					</NavLink>
					{this.props.hasWrite && <Button size="sm" color="danger" onClick={() => this.handleDelete(prop.id)}>
						Delete
					</Button>}
				</div>
			];
		});
	}

	handleDelete(id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this note?"
					onConfirm={() => this.deleteNote(id)}
					onCancel={() => this.deleteNote()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async deleteNote(id) {
		this.setState({ alert: null });

		if (_.isEmpty(id)) {
			return;
		}

		const url = "/index.php?p=api&r=json&c=userSettings&m=deleteNote&d=" + id;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				const data = this.state.data.filter((prop, key) => {
					return prop.id != id;
				});
				this.setState({ data });
			} else {
				this.props.setNotification("There was an error deleting the note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the note!", { variant: "error" });
		}
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	async handleSearch(warehouse) {
		let url = "/index.php?p=api&r=json&c=userSettings&m=notes";

		if (!_.isEmpty(warehouse)) {
			url += "&d=" + encodeURIComponent(warehouse);
		}

		this.setState({
			show: false,
			loading: true,
			warehouse
		});

		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user) && !_.isEmpty(data.body)) {
				const { warehouses } = data.body;
				const warehouse = warehouses.filter(warehouse => {
					return warehouse.is_default == 1;
				});
				this.setState({
					show: true,
					loading: false,
					data: data.body.notes,
					warehouses
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", { variant: "error" });
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer style={{ marginBottom: "15px" }}>
								<GridItem xs={12} sm={6} md={6}>
									<GridContainer>
										{this.props.hasWrite && <GridItem xs={4}>
											<NavLink to={basePath + "/admin/note/new"}>
												<Button color="linkedin">New Note</Button>
											</NavLink>
										</GridItem>}
										<GridItem xs>
											<InputLabel htmlFor="warehouse" className={classes.label}>
												Warehouse/Facility
											</InputLabel>
											<br />
											<FormControl className={classes.selectFormControl} style={{ width: "175px" }}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{ select: classes.select }}
													value={this.state.warehouse || 0}
													inputProps={{
														name: "warehouse",
														id: "warehouse"
													}}
													onChange={this.handleChange}
												>
													{!_.isEmpty(this.state.warehouses) ? (
														this.getWarehouses(this.state.warehouses)
													) : (
														<MenuItem disabled classes={{ root: classes.selectMenuItem }}>
															No Warehouses
														</MenuItem>
													)}
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} sm={12} md={12} className={!_.isEmpty(this.state.data) ? classes.left : classes.center}>
										{!_.isEmpty(this.state.data) ? <Table tableHead={["ID", "Title", "Note", "Default", "Actions"]} tableData={this.getNotes(this.state.data)} customCellClasses={[classes.right]} customClassesForCells={[4]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[4]} /> : <Badge color="info">No Notes</Badge>}
									</Grid>
								) : (
									<Spinner loading={this.state.loading} message="Failed to retrieve notes from the server" />
								)}
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasWrite: hasPermission("CANNED_NOTES_WRITE")(state),
	};
}

export default connect(mapStateToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(CannedNotes)));
