import axios from '../../../variables/axios.jsx';

export const getCarrierScacs = () => {
	return {
		'FEDEX': 'FEDX',
		'UPS': 'UPSN',
		'USPS': 'USPS',
	};
}

export const loadServices = (carrier, selectedServiceName, residential, shipCountry, consCountry, is_inbound, enable_fedex_smartpost) => {
	let services = [];
	let messages = {};
	
	//FEDEX SERVICES
	const FedExServices = [
		{name: 'FedEx Express Saver', value: 'FEDEX_EXPRESS_SAVER'},
		{name: 'FedEx 2 Day', value: 'FEDEX_2_DAY'},
		{name: 'FedEx 2 Day AM', value: 'FEDEX_2_DAY_AM'},
		{name: 'Standard Overnight' , value: 'STANDARD_OVERNIGHT'},
		{name: 'Priority Overnight', value: 'PRIORITY_OVERNIGHT'},
		{name: 'First Overnight', value: 'FIRST_OVERNIGHT'},
	];
	const FedExIntlServices = [
		{name: 'International Priority', value: 'INTERNATIONAL_PRIORITY'},
		{name: 'International Economy' , value: 'INTERNATIONAL_ECONOMY'},
	];
	const FedExCanDomServices = [
		{name: 'FedEx Ground', value: 'FEDEX_GROUND'},
		{name: 'FedEx Priority Overnight', value: 'PRIORITY_OVERNIGHT'},
		{name: 'FedEx Economy' , value: 'FEDEX_EXPRESS_SAVER'},
		{name: 'FedEx Standard Overnight' , value: 'STANDARD_OVERNIGHT'},
		{name: 'FedEx 2Day' , value: 'FEDEX_2_DAY'},
	];
	const FedExMexDomServices = [
		{name: 'FedEx Priority Overnight', value: 'PRIORITY_OVERNIGHT'},
		{name: 'FedEx Economy' , value: 'FEDEX_EXPRESS_SAVER'},
		{name: 'FedEx Standard Overnight' , value: 'STANDARD_OVERNIGHT'},
	];

	//UPS SERVICES
	const UpsServices = [
		{name: 'UPS Ground', value: '03'},
		{name: 'UPS Next Day Air', value: '01'},
		{name: 'UPS 2nd Day Air', value: '02'},
		{name: 'UPS 3 Day Select', value: '12'},
		{name: 'UPS Next Day Air Saver', value: '13'},
		{name: 'UPS Next Day Air Early', value: '14'},
		{name: 'UPS 2nd Day Air AM', value: '59'},
	];
	const UspsServices = [
		{name: 'USPS Priority (1-3 Days)', value: 'usps_priority'},
		{name: 'USPS Priority Mail Express', value: 'usps_express'},
		{name: 'USPS First Class', value: 'usps_first_class'},
	];
	const UpsUsaIntlServices = [
		{name: 'UPS Standard', value: '11'},
		{name: 'UPS Worldwide Express', value: '07'},
		{name: 'UPS Worldwide Expedited', value: '08'},
		{name: 'UPS Worldwide Express Plus', value: '54'},
		{name: 'UPS Worldwide Saver', value: '65'}
	];
	const UpsCanIntlServices = [
		{name: 'UPS Express Saver', value: '65'},
		{name: 'UPS Standard', value: '11'},
		{name: 'UPS Worldwide Expedited', value: '08'},
		{name: 'UPS Worldwide Express', value: '07'},
		{name: 'UPS Worldwide Express Plus', value: '54'},
	];
	const UpsCanDomServices = [
		{name: 'UPS Expedited', value: '02'},
		{name: 'UPS Express Saver', value: '13'},
		{name: 'UPS 3 Day Select', value: '12'},
		{name: 'UPS Access Point Economy', value: '70'},
		{name: 'UPS Express', value: '01'},
		{name: 'UPS Express Early', value: '14'},
		{name: 'UPS Standard', value: '11'},
	];
	const UpsCanToUsaServices = [
		{name: 'UPS 3 Day Select', value: '12'},
		{name: 'UPS Express Early', value: '54'}
	];
	const UpsMexDomServices = [
		{name: 'UPS Access Point Economy', value: '70'},
	];
	const UpsMexServices = [
		{name: 'UPS Expedited', value: '08'},
		{name: 'UPS Express', value: '07'},
		{name: 'UPS Standard', value: '11'},
		{name: 'UPS Worldwide Express Plus', value: '54'},
		{name: 'UPS Worldwide Saver', value: '65'}	
	];

	
	if(is_inbound) {
		let tempCons = consCountry;
		consCountry = shipCountry;
		shipCountry = tempCons;
	}

	if(shipCountry == consCountry) {
		if(shipCountry == "US") {
			//domestic usa
			if(carrier == 'FEDEX') {
				services = [...FedExServices];
				if(residential) {
					services.unshift({name: 'FedEx Home Delivery', value: 'GROUND_HOME_DELIVERY'});
				} else {
					services.unshift({name: 'FedEx Ground', value: 'FEDEX_GROUND'});
				}
				if(enable_fedex_smartpost) {
					services.push({name: 'Smart Post', value: 'SMART_POST'});
				}
			} else if(carrier == 'UPS') {
				services = [...UpsServices];
			} else {
				services = [...UspsServices];
			}
		} else if(shipCountry == "CA") {
			//domestic canadian
			if(carrier == 'FEDEX') {
				services = [...FedExCanDomServices];
			} else {
				services = [...UpsCanDomServices];
			}
		} else if(shipCountry == "MX") {
			//domestic mexico
			if(carrier == 'FEDEX') {
				services = [...FedExMexDomServices];
				// Documentation is unclear if these shipment types are valid for domestic Mexico shipments. 
			} else {
				//UPS
				services = [...UpsMexServices];
				services.unshift({name: 'UPS Access Point Economy', value: '70'})
			}
		} 
	} else {
		// @todo: remove if(shipCountry == 'US') { when UPS international can be tested.
		//international
		if(carrier == 'FEDEX') {
			//fedex int'l should have all of the same services available unless it is US->Canada
			services = [...FedExIntlServices];
			if((shipCountry == "US" && consCountry == "CA") || (consCountry == "US" && shipCountry == "CA")) {
				services.unshift({name: 'FedEx International Ground', value: 'FEDEX_GROUND'}); //not sure on proper value here.
			}
		} else if(shipCountry == "US") {
		//intl usa				
			if(carrier == 'UPS') {
				services = [...UpsUsaIntlServices];
			} else {
				//USPS
				//@todo: usps international?
				// services = [...UspsServices];
			}

		} else if(shipCountry == "CA") {
			//intl canadian
			//UPS
			services = [...UpsCanIntlServices];
			if(consCountry == "US") {
				services = services.concat(UpsCanToUsaServices);
			}
		} else if(shipCountry == "MX") {
			//intl mexico UPS
			services = [...UpsMexServices];

		} else if(carrier=='UPS') {
			let messages = {
				data: {
					message: [{
						message: "UPS Shipments originating outside of North America are not currently supported.",
						type: 'warning'
					}]
				}
			};
			return {messages: messages};
		}
	}
	
	let selectedService = [];
	if(!_.isEmpty(services)) {
		selectedService = services.filter(serviceType => serviceType.value == selectedServiceName);
		if(Array.isArray(selectedService) && !_.isEmpty(selectedService)) {
			selectedService = selectedService[0];
		}
		if(selectedService.hasOwnProperty('value')) {
			selectedService = selectedService.value;
		}
		if(!_.isEmpty(selectedService)) {
			return ({services: services, service: selectedService, hazService: selectedService, messages: {}});
		} else {
			return ({services: services, service: services[0].value, hazService: services[0].value, messages: {}});
		}

	} else {
		return ({services: services, service: "", hazService: "", messages: messages});
	}
	
}

export const loadCarriers = async (warehouse, dg_enabled = false) => {
	try {
		const url = "/index.php?p=api&r=json&c=parcel&m=getCarriers&d=" + encodeURIComponent(warehouse);
		const response = await axios.get(url);
		let messages = {};
		if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
			messages = {
				data: {
					message: response.data.message
				}
			};
		}
		const { body } = response.data;
		let hazCarriers = [];
		let carriers = [];
		let accounts = [];
		let showHaz = false;
		if(!_.isEmpty(body)) {
			for(let i = 0; i < body.length; i ++) {
				carriers.push(body[i].name);
				accounts.push({
					name: body[i].name,
					account: body[i].account,
					hubId: body[i].hubId || ""
				});
				if(body[i].hazSupported == 1 && dg_enabled) {
					hazCarriers.push(body[i].name);
					showHaz = true;
				}
			}
			return({
				hazCarriers: hazCarriers,
				carriers: carriers,
				carrier: carriers[0],
				accounts: accounts,
				account: accounts[0],
				showHaz: showHaz,
				messages: messages,
			});
		}
		
		return({
			messages: messages,
		});
	} catch(error) {
		console.error(error);
		let messages = {
			data: {
				message: [{
					message: "There was an error loading the carriers for this warehouse.",
					type: 'error'
				}]
			}
		};
		return {messages: messages};
	}
}
