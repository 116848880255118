import Dashboard from "~/views/Dashboard/Dashboard.jsx";
import PasswordResetPage from "~/views/Pages/PasswordResetPage.jsx";
import ErrorPage from "~/views/Pages/ErrorPage.jsx";
import LoginPage from "~/views/Pages/LoginPage.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";

import BusinessCenter from "@material-ui/icons/BusinessCenter"; // Management
import Settings from "@material-ui/icons/Settings"; // Settings
import Build from "@material-ui/icons/Build"; // Shipping Tools
import Assignment from "@material-ui/icons/Assignment"; // Auditing
import AssignmentLate from "@material-ui/icons/AssignmentLate"; // Reporting
import Computer from "@material-ui/icons/Computer"; // System

// react-icons
import { FaScroll, FaClipboardCheck } from "react-icons/fa";
import { GoReport } from "react-icons/go";

// TMS - Admin
import Users from "~/views/TMS/Admin/Users.jsx";
import ManageUser from "~/views/TMS/Admin/ManageUser.jsx";
import UserPortalSettingsContainer from "~/views/TMS/Admin/UserPortalSettingsContainer.jsx";
import Companies from "~/views/TMS/Admin/Companies.jsx";
import NewCompany from "~/views/TMS/Admin/NewCompany.jsx";
import EditCompany from "~/views/TMS/Admin/EditCompany.jsx";
import Carriers from "~/views/TMS/Admin/Carriers.jsx";
import NewCarrier from "~/views/TMS/Admin/NewCarrier.jsx";
import EditCarrier from "~/views/TMS/Admin/EditCarrier.jsx";
import EditWarehouse from "~/views/TMS/Admin/EditWarehouse.jsx";
import EditCompanyCarrier from "~/views/TMS/Admin/EditCompanyCarrier.jsx";
import LccQuotes from "~/views/TMS/Admin/LccQuotes.jsx";
import ReportTemplates from "~/views/TMS/Admin/ReportTemplates.jsx";
import ScheduledReports from "~/views/TMS/Admin/ScheduledReports.jsx";
import ScheduledReport from "~/views/TMS/Admin/ScheduledReport.jsx";
import ScheduledReportHistory from "~/views/TMS/Admin/ScheduledReportHistory.jsx";
import BolReportTemplate from "~/views/TMS/Admin/BolReportTemplate.jsx";
import PermissionGroups from "~/views/TMS/Admin/PermissionGroups.jsx";
import NewRole from "~/views/TMS/Admin/NewRole.jsx";
import EditRole from "~/views/TMS/Admin/EditRole.jsx";
import Accessorials from "~/views/TMS/Admin/Accessorials.jsx";
import NewAccessorial from "~/views/TMS/Admin/NewAccessorial.jsx";
import AccessorialProfiles from "~/views/TMS/Admin/AccessorialProfiles.jsx";
import NewAccessorialProfile from "~/views/TMS/Admin/NewAccessorialProfile.jsx";
import UnitMaster from "~/views/TMS/Admin/UnitMaster.jsx";
import NewUnit from "~/views/TMS/Admin/NewUnit.jsx";
import AdminCannedNotes from "~/views/TMS/Admin/CannedNotes.jsx";
import AdminNewNote from "~/views/TMS/Admin/NewNote.jsx";
import NmfcClassMaps from "~/views/TMS/Admin/NmfcClassMaps.jsx";
import NewNmfcClassMap from "~/views/TMS/Admin/NewNmfcClassMap.jsx";
import ProRanges from "~/views/TMS/Admin/ProRanges.jsx";
import NewProRange from "~/views/TMS/Admin/NewProRange.jsx";
import RateDisplays from "../views/RateDisplays/containers/RateDisplays.jsx";
import RateDisplay from "../views/RateDisplays/containers/RateDisplay.jsx";
import LinearFeetRules from "~/views/TMS/Admin/LinearFeetRules.jsx";
import LinearFeetRuleDates from "~/views/TMS/Admin/LinearFeetRuleDates.jsx";
import NewRuleSet from "~/views/TMS/Admin/NewRuleSet.jsx";
import AutoCalcAccessorials from "~/views/TMS/Admin/AutoCalcAccessorials.jsx";
import GlobalNotifications from "~/views/TMS/Admin/GlobalNotifications.jsx";
import NewSystemMessage from "~/views/TMS/Admin/NewSystemMessage.jsx";
import SessionManagement from "~/views/TMS/Admin/SessionManagement.jsx";
import Permissions from "~/views/TMS/Admin/Permissions.jsx";
import NewCompanyCarrier from "~/views/TMS/Admin/NewCompanyCarrier.jsx";
import ImportLocations from "~/views/TMS/Admin/ImportLocations.jsx";
import ImportProducts from "~/views/TMS/Admin/ImportProducts.jsx";
import ImportUnits from "~/views/TMS/Admin/ImportUnits.jsx";
import ImportCompanyCarriers from "../views/TMS/Admin/ImportCompanyCarriers.jsx";
import LaneSplits from "~/views/TMS/Admin/LaneSplits.jsx";
import EditLaneSplit from "~/views/TMS/Admin/EditLaneSplit.jsx";
import ViewAutoCalcAccessorial from "~/views/TMS/Admin/ViewAutoCalcAccessorial.jsx";

// TMS - User
import TrackShipment from "~/views/TMS/TrackShipment.jsx";
import MonitoredPros from "~/views/TMS/MonitoredPros.jsx";
import TransitInfo from "~/views/TMS/TransitInfo.jsx";
import Miler from "~/views/TMS/Miler.jsx";
import Claims from "~/views/TMS/Claims.jsx";
import NewClaim from "~/views/TMS/NewClaim.jsx";
import ViewClaim from "~/views/TMS/ViewClaim.jsx";
import ArchivedBillsOfLading from "~/views/ArchivedBillsOfLading/containers/ArchivedBillsOfLading.jsx";
import InternalCSTracking from "~/views/InternalCSTracking/containers/InternalCSTracking.jsx";
import BillOfLading from "~/views/BillOfLading/containers/BillOfLading.jsx";
import EditAddress from "../views/TMS/EditAddress.jsx";
import EditProduct from "~/views/TMS/EditProduct.jsx";
import NewHandlingUnit from "~/views/TMS/NewHandlingUnit.jsx";
import EditHandlingUnit from "~/views/TMS/EditHandlingUnit.jsx";

import HistoryPanel from "~/views/TMS/HistoryPanel.jsx";

import { basePath } from "~/variables/server.jsx";
import ReportsContainer from "../views/CustomReporting/containers/ReportsContainer.jsx";
import RunReportContainer from "../views/CustomReporting/containers/RunReportContainer.jsx";
import ScrollableReportBuilder from "../views/CustomReporting/containers/ScrollableReportBuilder.jsx";
import ReportSchedulerContainer from "../views/CustomReporting/containers/ReportSchedulerContainer.jsx";
import CarrierInvoiceLandingPage from "~/views/TMS/Admin/CarrierInvoiceLandingPage.jsx";
import invoiceHoldStatus from "~/views/TMS/Admin/invoiceHoldStatus.jsx";
import agingReport from "~/views/TMS/Admin/agingReport.jsx";
import invoiceRemittanceByDate from "~/views/TMS/Admin/invoiceRemittanceByDate.jsx";
import invoiceRemittanceByCheckNum from "~/views/TMS/Admin/invoiceRemittanceByCheckNum.jsx";
import invoiceRemittanceByProNum from "~/views/TMS/Admin/invoiceRemittanceByProNum.jsx";
import invoiceStandardCarrierByInvoiceNum  from "~/views/TMS/Admin/invoiceStandardCarrierByInvoiceNum.jsx";
import invoiceStandardCarrierByInvoiceDate  from "~/views/TMS/Admin/invoiceStandardCarrierByInvoiceDate.jsx";
import invoiceStandardCarrierByDeliveryDate   from "~/views/TMS/Admin/invoiceStandardCarrierByDeliveryDate.jsx";
import invoiceStandardCarrierByShipDate    from "~/views/TMS/Admin/invoiceStandardCarrierByShipDate.jsx";
import invoiceStandardCarrierByProNum   from "~/views/TMS/Admin/invoiceStandardCarrierByProNum.jsx";
import CarrierInvoiceDetail from "~/views/TMS/Admin/CarrierInvoiceDetail.jsx";

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: basePath + "/admin",
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "Pages",
    icon: Image,
    state: "pageCollapse",
    invisible: true,
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        mini: "L",
        rtlName: "Login Page",
        rtlMini: "L",
        component: LoginPage,
        layout: basePath + "/auth",
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "Error Page",
        mini: "E",
        rtlMini: "E",
        component: ErrorPage,
        layout: basePath + "/auth",
      },
      {
        path: "/change-password/:token?",
        name: "Password Reset Page",
        mini: "P",
        rtlName: "Password Reset Page",
        rtlMini: "P",
        component: PasswordResetPage,
        layout: basePath + "/auth"
      },
    ],
  },
  {
    collapse: true,
    name: "Management",
    rtlName: "Management",
    state: "openManagement",
    icon: BusinessCenter,
    views: [
      {
        path: "/management/companies",
        name: "Companies",
        mini: "C",
        rtlName: "Companies",
        rtlMini: "C",
        component: Companies,
        layout: basePath + "/admin",
      },
      {
        path: "/company/new",
        name: "New Company",
        mini: "NC",
        rtlName: "New Company",
        rtlMini: "NC",
        component: NewCompany,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company/:id/importCompanyCarriers",
        name: "Import Company Carriers",
        mini: "IU",
        rtlName: "Import Company Carriers",
        rtlMini: "IU",
        component: ImportCompanyCarriers,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company/:id",
        name: "Edit Company",
        mini: "EC",
        rtlName: "Edit Company",
        rtlMini: "EC",
        component: EditCompany,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/management/users",
        name: "Users",
        mini: "U",
        rtlName: "Users",
        rtlMini: "U",
        component: Users,
        layout: basePath + "/admin",
      },
      {
        path: "/user/edit/:id",
        name: "Edit User",
        mini: "EU",
        rtlName: "Edit User",
        rtlMini: "EU",
        component: ManageUser,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/user/new",
        name: "New User",
        mini: "NU",
        rtlName: "New User",
        rtlMini: "NU",
        component: ManageUser,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/import-locations/:warehouse?",
        name: "Import Locations",
        mini: "IL",
        rtlName: "Import Locations",
        rtlMini: "IL",
        component: ImportLocations,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/management/carriers",
        name: "Carriers",
        mini: "C",
        rtlName: "Carriers",
        rtlMini: "C",
        component: Carriers,
        layout: basePath + "/admin",
      },
      {
        path: "/carrier/edit/:id",
        name: "Edit Carrier",
        mini: "EC",
        rtlName: "Edit Carrier",
        rtlMini: "EC",
        component: EditCarrier,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/carrier/new",
        name: "New Carrier",
        mini: "NC",
        rtlName: "New Carrier",
        rtlMini: "NC",
        component: NewCarrier,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/carrier/edit/:id",
        name: "Edit Company Carrier",
        mini: "EC",
        rtlName: "Edit Company Carrier",
        rtlMini: "EC",
        component: EditCompanyCarrier,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/carrier/new",
        name: "New Company Carrier",
        mini: "NC",
        rtlName: "New Company Carrier",
        rtlMini: "NC",
        component: NewCompanyCarrier,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/lane-splits/:id",
        name: "Lane Splits",
        mini: "LS",
        rtlName: "Lane Splits",
        rtlMini: "LS",
        component: LaneSplits,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/edit-lane-split/:splitId/:laneId?",
        name: "Edit Lane Split",
        mini: "EL",
        rtlName: "Edit Lane Split",
        rtlMini: "EL",
        component: EditLaneSplit,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/user/edit/:id",
        name: "Edit User",
        mini: "EU",
        rtlName: "Edit User",
        rtlMini: "EU",
        component: ManageUser,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/user/new",
        name: "New User",
        mini: "NU",
        rtlName: "New User",
        rtlMini: "NU",
        component: ManageUser,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/warehouse-:warehouse/user/new",
        name: "New User",
        mini: "NU",
        rtlName: "New User",
        rtlMini: "NU",
        component: ManageUser,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/company-:company/warehouse/new",
        name: "New Warehouse",
        mini: "NW",
        rtlName: "New Warehouse",
        rtlMini: "NW",
        component: EditWarehouse,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/warehouse/edit/:id",
        name: "Edit Warehouse",
        mini: "EW",
        rtlName: "Edit Warehouse",
        rtlMini: "EW",
        component: EditWarehouse,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/locations/import",
        name: "Import Locations",
        mini: "IL",
        rtlName: "Import Locations",
        rtlMini: "IL",
        component: ImportLocations,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/products/import",
        name: "Import Products",
        mini: "IP",
        rtlName: "Import Products",
        rtlMini: "IP",
        component: ImportProducts,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/units/import",
        name: "Import Units",
        mini: "IU",
        rtlName: "Import Units",
        rtlMini: "IU",
        component: ImportUnits,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/location/new/:companyId/:warehouseId/:referer?",
        name: "New Location",
        mini: "NL",
        rtlName: "New Location",
        rtlMini: "NL",
        component: EditAddress,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/location/edit/:id/:warehouseId/:referer?",
        name: "Edit Location",
        mini: "EL",
        rtlName: "Edit Location",
        rtlMini: "EL",
        component: EditAddress,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/change-password/:id?",
        name: "Change Password",
        mini: "CP",
        rtlName: "Change Password",
        rtlMini: "CP",
        component: PasswordResetPage,
        layout: basePath + "/admin",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Shipping Tools",
    rtlName: "",
    state: "openShippingTools",
    icon: Build,
    views: [
      {
        path: "/shipping/track-shipment",
        name: "Track Shipment",
        mini: "TS",
        rtlName: "Track Shipment",
        rtlMini: "TS",
        component: TrackShipment,
        layout: basePath + "/admin",
      },
      {
        path: "/shipping/monitored-pros",
        name: "Monitored PROs",
        mini: "MP",
        rtlName: "Monitored PROs",
        rtlMini: "MP",
        component: MonitoredPros,
        layout: basePath + "/admin",
      },
      {
        path: "/shipping/transit-info",
        name: "Transit Info",
        mini: "TI",
        rtlName: "Transit Info",
        rtlMini: "TI",
        component: TransitInfo,
        layout: basePath + "/admin",
      },
      {
        path: "/shipping/miler",
        name: "Miler",
        mini: "M",
        rtlName: "",
        rtlMini: "",
        component: Miler,
        layout: basePath + "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Auditing",
    rtlName: "Auditing",
    state: "openAuditing",
    icon: Assignment,
    views: [
      {
        path: "/audit/posting-panel?:filters",
        name: "Posting Panel",
        mini: "PP",
        rtlName: "Posting Panel",
        rtlMini: "PP",
				invisible: true,
        component: ArchivedBillsOfLading,
        layout: basePath + "/admin",
      },
      {
        path: "/audit/posting-panel",
        name: "Posting Panel",
        mini: "PP",
        rtlName: "Posting Panel",
        rtlMini: "PP",
        component: ArchivedBillsOfLading,
        layout: basePath + "/admin",
      },
      {
				path: "/bol/HistoryPanel/:bol",
				name: "BOL Tracking History",
				mini: "BT",
				rtlName: "BOL Tracking History",
				rtlMini: "BT",
				component: HistoryPanel,
				layout: basePath + "/admin",
				invisible: true,
			},
      {
        path: "/bol/:date/:id",
        name: "View BOL",
        mini: "VB",
        rtlName: "View BOL",
        rtlMini: "VB",
        invisible: true,
        component: BillOfLading,
        layout: basePath + "/admin",
      },
      {
        path: "/lcc-quotes",
        name: "LCC Quotes",
        mini: "LQ",
        rtlName: "LCC Quotes",
        rtlMini: "LQ",
        component: LccQuotes,
        layout: basePath + "/admin",
      },
      {
				path: "/cs-tracking",
				name: "Internal Tracking",
				mini: "IT",
				rtlName: "Internal Tracking",
				rtlMini: "IT",
				component: InternalCSTracking,
				layout: basePath + "/admin"
			},
    ],
  },
  {
    collapse: true,
    name: "Reporting",
    rtlName: "Reporting",
    state: "openReporting",
    icon: AssignmentLate,
    views: [
      {
        path: "/report-templates",
        name: "Report Templates",
        mini: "RT",
        rtlName: "Report Templates",
        rtlMini: "RT",
        component: ReportTemplates,
        layout: basePath + "/admin",
      },
      {
        path: "/scheduled-reports",
        name: "Scheduled Reports",
        mini: "SR",
        rtlName: "Scheduled Reports",
        rtlMini: "SR",
        component: ScheduledReports,
        layout: basePath + "/admin",
      },

      {
        path: "/report-scheduler/:reportId",
        name: "Schedule",
        mini: "CRS",
        rtlName: "Schedule",
        rtlMini: "CRS",
        component: ReportSchedulerContainer,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/report-scheduler-edit/:scheduleId",
        name: "Schedule",
        mini: "CRS",
        rtlName: "Schedule",
        rtlMini: "CRS",
        component: ReportSchedulerContainer,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/schedule-report/:tplId?",
        name: "Scheduled Report",
        mini: "RP",
        rtlName: "Scheduled Report",
        rtlMini: "RP",
        component: ScheduledReport,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/reports",
        name: "Report Building",
        mini: "RB",
        rtlName: "Report",
        rtlMini: "RB",
        component: ReportsContainer,
        layout: basePath + "/admin",
      },
      {
        path: "/run-report/:reportId",
        name: "Run On Demand Report",
        mini: "RODB",
        rtlName: "Run Report",
        rtlMini: "RODB",
        component: RunReportContainer,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/report-build/new/:reportType/:reportBehavior",
        name: "Report Builder",
        mini: "RSB",
        rtlName: "Report Builder",
        rtlMini: "RSB",
        component: ScrollableReportBuilder,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/report-build/:reportId",
        name: "Report Builder",
        mini: "RSB",
        rtlName: "Report Builder",
        rtlMini: "RSB",
        component: ScrollableReportBuilder,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/scheduled-report/:id?",
        name: "Scheduled Report",
        mini: "RP",
        rtlName: "Scheduled Report",
        rtlMini: "RP",
        component: ScheduledReport,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/scheduled-report-history/:id",
        name: "Scheduled Report History",
        mini: "RH",
        rtlName: "Scheduled Report History",
        rtlMini: "RH",
        component: ScheduledReportHistory,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/bol-report-template/:id?",
        name: "Bill of Lading Report Template",
        mini: "BT",
        rtlName: "Bill of Lading Report Template",
        rtlMini: "BT",
        component: BolReportTemplate,
        layout: basePath + "/admin",
        invisible: true,
      },
    ],
  },
  {
    path: "/carrier-invoice",
    name: "Carrier Portal",
    rtlName: "Carrier Portal",
    icon: DashboardIcon,
    component: CarrierInvoiceLandingPage,
    layout: basePath + "/admin",
  },
  {
    path: "/carrier-report-invoice-status/search",
    name: "Invoice Hold Status Report",
    mini: "IS",
    rtlName: "Invoice Hold Status",
    rtlMini: "IS",
    component: invoiceHoldStatus,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-aging-report/search",
    name: "Invoice Aging Report",
    mini: "IS",
    rtlName: "Invoice Hold Status",
    rtlMini: "IS",
    component: agingReport,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-remittance-check/search/",
    name: "Invoice Remittance Report",
    mini: "IS",
    rtlName: "Remittance Report By Check Number",
    rtlMini: "IS",
    component: invoiceRemittanceByCheckNum,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-remittance-pro/search/",
    name: "Invoice Remittance Report",
    mini: "IS",
    rtlName: "Remittance Report By Pro Number",
    rtlMini: "IS",
    component: invoiceRemittanceByProNum,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-remittance-date/search/",
    name: "Invoice Remittance Report",
    mini: "IS",
    rtlName: "Remittance Report By Date",
    rtlMini: "IS",
    component: invoiceRemittanceByDate,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-Standard-report-pro-num/search",
    name: "Standard Carrier Invoice Report",
    mini: "IS",
    rtlName: "Standard Carrier Invoice Report",
    rtlMini: "IS",
    component: invoiceStandardCarrierByProNum,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-Standard-report-invoice-num/search",
    name: "Standard Carrier Invoice Report",
    mini: "IS",
    rtlName: "Standard Carrier Invoice Report",
    rtlMini: "IS",
    component: invoiceStandardCarrierByInvoiceNum,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-Standard-report-invoice-date/search",
    name: "Standard Carrier Invoice Report",
    mini: "IS",
    rtlName: "Standard Carrier Invoice Report",
    rtlMini: "IS",
    component: invoiceStandardCarrierByInvoiceDate,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-Standard-report-delivery-date/search",
    name: "Standard Carrier Invoice Report",
    mini: "IS",
    rtlName: "Standard Carrier Invoice Report",
    rtlMini: "IS",
    component: invoiceStandardCarrierByDeliveryDate ,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/carrier-Standard-report-ship-date/search",
    name: "Standard Carrier Invoice Report",
    mini: "IS",
    rtlName: "Standard Carrier Invoice Report",
    rtlMini: "IS",
    component: invoiceStandardCarrierByShipDate ,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    path: "/Carrier_Invoice_Detail",
    name: "Invoice Detail",
    mini: "IS",
    rtlName: "Invoice Detail",
    rtlMini: "IS",
    component: CarrierInvoiceDetail ,
    layout: basePath + "/admin",
    invisible: true
  },
  {
    collapse: true,
    name: "Settings",
    rtlName: "Settings",
    state: "openSettings",
    icon: Settings,
    views: [
      {
        path: "/settings/permission-groups",
        name: "Permission Groups",
        mini: "PG",
        rtlName: "Permission Groups",
        rtlMini: "PG",
        component: PermissionGroups,
        layout: basePath + "/admin",
      },
      {
        path: "/role/new",
        name: "New Role",
        mini: "NR",
        rtlName: "New Role",
        rtlMini: "NR",
        component: NewRole,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/settings/accessorials",
        name: "Accessorials",
        mini: "A",
        rtlName: "Accessorials",
        rtlMini: "A",
        component: Accessorials,
        layout: basePath + "/admin",
      },

      {
        path: "/accessorial/new",
        name: "New Accessorial",
        mini: "NA",
        rtlName: "New Accessorial",
        rtlMini: "NA",
        component: NewAccessorial,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/settings/accessorial-profiles",
        name: "Accessorial Profiles",
        mini: "AP",
        rtlName: "Accessorial Profiles",
        rtlMini: "AP",
        component: AccessorialProfiles,
        layout: basePath + "/admin",
      },
      {
        path: "/accessorial-profile/new",
        name: "New Accessorial Profile",
        mini: "NP",
        rtlName: "New Accessorial Profile",
        rtlMini: "NP",
        component: NewAccessorialProfile,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/accessorial-profile/edit/:id",
        name: "Edit Accessorial Profile",
        mini: "EP",
        rtlName: "Edit Accessorial Profile",
        rtlMini: "EP",
        component: NewAccessorialProfile,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/accessorial-profile/copy/:copy",
        name: "Copy Accessorial Profile",
        mini: "CP",
        rtlName: "Copy Accessorial Profile",
        rtlMini: "CP",
        component: NewAccessorialProfile,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/settings/unit-master",
        name: "Unit Master",
        mini: "UM",
        rtlName: "Unit Master",
        rtlMini: "UM",
        component: UnitMaster,
        layout: basePath + "/admin",
      },
      {
        path: "/unit/new",
        name: "New Unit",
        mini: "NU",
        rtlName: "New Unit",
        rtlMini: "NU",
        component: NewUnit,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/unit/:id",
        name: "Edit Unit",
        mini: "EU",
        rtlName: "Edit Unit",
        rtlMini: "NU",
        component: NewUnit,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/settings/canned-notes",
        name: "Canned Notes",
        mini: "CN",
        rtlName: "Canned Notes",
        rtlMini: "CN",
        component: AdminCannedNotes,
        layout: basePath + "/admin",
      },
      {
        path: "/note/new",
        name: "New Note",
        mini: "NN",
        rtlName: "New Note",
        rtlMini: "NN",
        component: AdminNewNote,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/note/edit/:id",
        name: "Edit Note",
        mini: "EN",
        rtlName: "Edit Note",
        rtlMini: "EN",
        component: AdminNewNote,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/settings/nmfc-class-map",
        name: "NMFC Class Maps",
        mini: "CM",
        rtlName: "NMFC Class Maps",
        rtlMini: "CM",
        component: NmfcClassMaps,
        layout: basePath + "/admin",
      },
      {
        path: "/nmfc-class-map/new",
        name: "New NMFC Class Map",
        mini: "NM",
        rtlName: "New NMFC Class Map",
        rtlMini: "NM",
        component: NewNmfcClassMap,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/settings/pro-ranges",
        name: "PRO Ranges",
        mini: "PR",
        rtlName: "PRO Ranges",
        rtlMini: "PR",
        component: ProRanges,
        layout: basePath + "/admin",
      },
      {
        path: "/pro-range/new",
        name: "New PRO Range",
        mini: "NR",
        rtlName: "New PRO Range",
        rtlMini: "NR",
        component: NewProRange,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/pro-range/:id",
        name: "Edit PRO Range",
        mini: "ER",
        rtlName: "Edit PRO Range",
        rtlMini: "ER",
        component: NewProRange,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/role/:id",
        name: "Edit Role",
        mini: "ER",
        rtlName: "Edit Role",
        rtlMini: "ER",
        component: EditRole,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/accessorial/:id",
        name: "Edit Accessorial",
        mini: "EA",
        rtlName: "Edit Accessorial",
        rtlMini: "EA",
        component: NewAccessorial,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/nmfc-class-map/:id",
        name: "Edit NMFC Class Map",
        mini: "EM",
        rtlName: "Edit NMFC Class Map",
        rtlMini: "EM",
        component: NewNmfcClassMap,
        layout: basePath + "/admin",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Rate Rules",
    rtlName: "Rate Rules",
    state: "openRateRules",
    icon: FaScroll,
    views: [
      {
        path: "/rate-displays",
        name: "Rate Displays",
        mini: "RD",
        rtlName: "Rate Displays",
        rtlMini: "RD",
        component: RateDisplays,
        layout: basePath + "/admin",
      },
      {
        path: "/rate-display/new",
        name: "New Rate Display",
        mini: "ND",
        rtlName: "New Rate Display",
        rtlMini: "ND",
        component: RateDisplay,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/rate-display/:id",
        name: "Edit Rate Display",
        mini: "ED",
        rtlName: "Edit Rate Display",
        rtlMini: "ED",
        component: RateDisplay,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/linear-feet-rules",
        name: "Linear Feet Rules",
        mini: "LR",
        rtlName: "Linear Feet Rules",
        rtlMini: "LR",
        component: LinearFeetRules,
        layout: basePath + "/admin",
      },
      {
        path: "/rule-set/new/:setId/:id",
        name: "New Rule Set",
        mini: "NS",
        rtlName: "New Rule Set",
        rtlMini: "NS",
        component: NewRuleSet,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/rule-set/new",
        name: "New Rule Set",
        mini: "NS",
        rtlName: "New Rule Set",
        rtlMini: "NS",
        component: NewRuleSet,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/rule-set/new-subset/:setId",
        name: "New Rule Set",
        mini: "NS",
        rtlName: "New Rule Set",
        rtlMini: "NS",
        component: NewRuleSet,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/linear-feet-rule-dates/:id",
        name: "Edit Rule Set",
        mini: "ES",
        rtlName: "Edit Rule Set",
        rtlMini: "ES",
        component: LinearFeetRuleDates,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/auto-calc-accessorials/view/:id",
        name: "View Auto Calc Accessorial",
        mini: "VA",
        rtlName: "View Auto Calc Accessorial",
        rtlMini: "VA",
        component: ViewAutoCalcAccessorial,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/auto-calc-accessorials/view-copy/:id",
        name: "View Auto Calc Accessorial",
        mini: "VA",
        rtlName: "View Auto Calc Accessorial",
        rtlMini: "VA",
        component: ViewAutoCalcAccessorial,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/auto-calc-accessorials",
        name: "Auto Calc Accessorials",
        mini: "AA",
        rtlName: "Auto Calc Accessorials",
        rtlMini: "AA",
        component: AutoCalcAccessorials,
        layout: basePath + "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "System",
    rtlName: "System",
    state: "openSystem",
    icon: Computer,
    views: [
      {
        path: "/system/global-notifications",
        name: "Global Notifications",
        mini: "GN",
        rtlName: "Global Notifications",
        rtlMini: "GN",
        component: GlobalNotifications,
        layout: basePath + "/admin",
      },
      {
        path: "/system/message/new",
        name: "New System Message",
        mini: "NM",
        rtlName: "New System Message",
        rtlMini: "NM",
        component: NewSystemMessage,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/system/message/:id",
        name: "Edit System Message",
        mini: "EM",
        rtlName: "Edit System Message",
        rtlMini: "EM",
        component: NewSystemMessage,
        layout: basePath + "/admin",
        invisible: true,
      },
      {
        path: "/system/session-management",
        name: "Session Management",
        mini: "SM",
        rtlName: "Session Management",
        rtlMini: "SM",
        component: SessionManagement,
        layout: basePath + "/admin",
      },
      {
        path: "/system/permissions",
        name: "Permissions",
        mini: "P",
        rtlName: "Permissions",
        rtlMini: "P",
        component: Permissions,
        layout: basePath + "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Claims",
    rtlName: "",
    state: "openClaims",
    icon: GoReport,
    views: [
      {
        path: "/support/claims",
        name: "Manage Claims",
        mini: "MC",
        rtlName: "",
        rtlMini: "",
        component: Claims,
        layout: basePath + "/admin",
      },
      {
        path: "/claim/new",
        name: "Submit a Claim",
        mini: "SC",
        rtlName: "Submit a Claim",
        rtlMini: "SC",
        component: NewClaim,
        layout: basePath + "/admin",
      },
      {
        path: "/claim/:id",
        name: "View Claim",
        mini: "VC",
        rtlName: "View Claim",
        rtlMini: "VC",
        component: ViewClaim,
        layout: basePath + "/admin",
        invisible: true,
      },
    ],
  },
  {
    name: "New Product",
    path: "/product/new/:wid?",
    component: EditProduct,
		layout: basePath + "/admin",
    invisible: true,
  },
  {
    name: "Edit Product",
    path: "/product/edit/:id/:wid",
    component: EditProduct,
		layout: basePath + "/admin",
    invisible: true,
  },
  {
    name: "New Product",
    path: "/product/parcel/new/:wid",
    component: EditProduct,
		layout: basePath + "/admin",
    invisible: true,
  },
  {
    name: "Edit Product",
    path: "/product/parcel/edit/:id/:wid",
    component: EditProduct,
		layout: basePath + "/admin",
    invisible: true,
  },
  {
    name: "New Handling Unit",
    path: "/handling-unit/new/:wid",
    component: NewHandlingUnit,
    layout: basePath + "/admin",
    invisible: true,
  },
  {
    name: "Edit Handling Unit",
    path: "/handling-unit/edit",
    component: EditHandlingUnit,
		layout: basePath + "/admin",
    invisible: true,
  },
];


