import React, { Component, Fragment } from "react";
import Slide from "@material-ui/core/Slide";
import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components.
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

//style for this view
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUps, faFedex, faUsps } from "@fortawesome/free-brands-svg-icons";
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons';


class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

class ParcelServices extends Component {

    getCarriers = (carriers, hazCarriers, hazmat = false) => {
        const { classes } = this.props;
        const icons = [
            {carrier: 'FEDEX', icon: faFedex},
            {carrier: 'UPS', icon: faUps},
            {carrier: 'USPS', icon: faUsps},
        ];
        if(carriers == undefined || _.isEmpty(carriers)) {
            return false;
        }

        return carriers.map((carrier, key) => {
            var icon;
            for(var i = 0; i < icons.length; i++) {
                if(icons[i].carrier == carrier) {
                    icon = icons[i].icon;
                }
            }

            if(!hazmat || hazCarriers.includes(carrier)) {
                return (
                    <MenuItem
                        key={key}
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        value={carrier}
                        style={{paddingTop: 0, paddingBottom: 0}}

                    >
                        &nbsp;&nbsp;<FontAwesomeIcon icon={icon} size="4x" />
                    </MenuItem>
                );
            }
        });
    }



    getServices = (access, hazmat, services, classes) => {
        return services.map((service, key) => {
            if(access == "ACCESSIBLE" && hazmat === true ) {
                if(service.value != "FEDEX_GROUND" && service.value != "PRIORITY_OVERNIGHT" && service.value != "FIRST_OVERNIGHT") {
                    return false;
                }
            }

            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={service.value}
                >
                    {service.name}
                </MenuItem>
            );
        });
    }


    render() {
        const { classes } = this.props;
        let access = "";
        let services = [];
        let hazmat = false;
        if(this.props.hasOwnProperty('access')) {
            access = this.props.access;
        }
        if(this.props.hasOwnProperty('services')) {
            services = [...this.props.services];
        }
        if(this.props.hasOwnProperty('hazmat')) {
            hazmat = this.props.hazmat;
        }

        return (
            <>
                <GridContainer style={{paddingBottom: '5px'}}>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label} style={{paddingBottom: '5px'}}>Carrier</InputLabel>
                        <br />
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select + " " + classes.requiredSelect
                                }}
                                value={this.props.carrier}
                                inputProps={{
                                    name: "carrier",
                                    id: "carrier"
                                }}
                                style={{paddingTop: 0, paddingBottom: 0}}
                                onChange={this.props.onChange}
                            >
                                {this.getCarriers(this.props.carriers)}
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={6}>
                        <InputLabel className={classes.label} style={{paddingBottom: '5px'}}>Service Type</InputLabel>
                        <br />
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select + " " + classes.requiredSelect
                                }}
                                value={this.props.service}
                                inputProps={{
                                    name: "service",
                                    id: "service"
                                }}
                                onChange={this.props.onChange}
                            >
                                {this.getServices(access, hazmat, services, classes)}
                            </Select>
                        </FormControl>
                    </GridItem>
                    {this.props.showRates && (
                        <>
                            <GridItem xs={3}>
                                <InputLabel className={classes.label}>Calculate Rate</InputLabel>
                                {this.props.loadingRate ? (
                                    <Button size="sm" color="success">
                                        <CircularProgress size={16} style={{ color: "white" }} />
                                    </Button>
                                ) : (
                                    <Button size="sm" color="success" onClick={this.props.calculateRate} disabled={this.props.exceedsWeightLimit()}>
                                        <FontAwesomeIcon icon={faSearchDollar} size="2x" />
                                    </Button>
                                )}
                            </GridItem>
                            <GridItem xs={3}>
                                <InputLabel className={classes.label}>Quoted Rate</InputLabel><br/>
                                <span style={{fontWeight: 'bold'}}>$ {this.props.rateQuote}</span>
                            </GridItem>
                        </>
                    )}
                </GridContainer>
            </>
        );
    }
}

export default (withStyles(ltlQuoteStyle)(withSnackbar(ParcelServices)));