import React, { Component } from "react";
import Datetime from "react-datetime";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "~/components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import "./DatePromptModal.css";

class DatePromptModal extends Component {

    state = {
        date: null,
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({date: null});
        }
    }

    onChangeDate = momentObj => {
        if (typeof this.props.onChange === "function") this.props.onChange(momentObj);
        this.setState({date: momentObj})
    }

    onClose = () => {
        if (typeof this.props.onClose === "function") this.props.onClose();
    }

    onSubmit = () => {
        if (typeof this.props.onSubmit === "function" && this.state.date) this.props.onSubmit(this.state.date);
    }

    render() {
        return (
            <Dialog
                open={!!this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogContent id="classic-modal-slide-description">
                    <InputLabel>
                        {this.props.title || "Choose a date"}
                    </InputLabel>
                    <br />
                    <Datetime
                        value={this.state.date || ""}
                        onChange={this.onChangeDate}
                        timeFormat={false}
                        className="date-prompt-modal"
                        input={false}
                    />
                    {this.state.date && <center><div><b>{this.state.date.format("L")}</b></div></center>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="white">
                        Cancel
                    </Button>
                    <Button onClick={this.onSubmit} disabled={!this.state.date} color={this.state.date ? "primary" : "white"}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

export default DatePromptModal;