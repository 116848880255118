import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Badge from "~/components/Badge/Badge.jsx";

const styles = () => ({
	claimsBadge: {
        backgroundColor: props => {
			let color = props.color;
			if( typeof color !== 'string'
				|| color.length > 7
				|| color.length < 4
				|| !isNaN(Number('0x' + color))) {
					color = '#00acc1';
			}
			return color;
		},
        color: "white"
	},
});

function ClaimsBadge(props) {
	const { classes, status } = props;
	return <Badge classes={{ badge: classes.claimsBadge }}>{status}</Badge>
}
export default withStyles(styles)(ClaimsBadge);
