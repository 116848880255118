import axios from '../../../variables/axios.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import { basePath, apiUrl } from '../../../variables/server.jsx';
import qs from "qs";

export const setShipments = shipments => {
	return {type: "SET_INTL_SHIPMENT", shipments};
}
const setGetIntlShipmentsLoading = val => {
	return {type: "SET_GET_INTL_SHIPMENTS_LOADING", val};
}
const setIntlShipmentsNotesLoading = val => {
	return {type: "SET_INTL_SHIPMENTS_NOTES_LOADING", val};
}
const setShipmentRecordTotal = totalRecords => {
	return {type: "SET_INTL_SHIPMENT_TOTAL_RECORDS", totalRecords};
}
export const setLoadBoardDefaultColumns = loadBoardDefaultColumns => {
	return {type: "SET_INTL_SHIPMENT_DEFAULT_COLUMNS", loadBoardDefaultColumns};
}
const setLoadBoardCustomColumns = loadBoardCustomColumns => {
	return {type: "SET_INTL_SHIPMENT_CUSTOM_COLUMNS", loadBoardCustomColumns};
}
export const applyLoadBoardCustomColumns = loadBoardCustomColumns => {
	return {type: "SET_INTL_SHIPMENT_CUSTOM_COLUMNS", loadBoardCustomColumns};
}
export const setShipmentPage = pageNum => {
	return {type: "SET_INTL_SHIPMENT_PAGE_NUMBER", pageNum};
}
const setLoadBoardFilterOptions = loadBoardFilterOptions => {
	return {type: "SET_INTL_SHIPMENT_LOAD_BOARD_FILTER_OPTIONS", loadBoardFilterOptions};
}
export const setLoadBoardFilters = loadBoardFilters => {
	return {type: "SET_INTL_SHIPMENT_LOAD_BOARD_FILTERS", loadBoardFilters};
}
export const setLoadBoardPresetFilters = loadBoardPresetFilters => {
	return {type: "SET_INTL_SHIPMENT_LOAD_BOARD_PRESET_FILTERS", loadBoardPresetFilters};
}
const setTransitStatusOptions = transitStatusOptions => {
	return {type: "SET_INTL_SHIPMENT_TRANSIT_STATUS_OPTIONS", transitStatusOptions};
}
const setWarehouseOptions = warehouseOptions => {
	return {type: "SET_INTL_SHIPMENT_WAREHOUSE_OPTIONS", warehouseOptions};
}
const setShipmentNoteCounts = shipmentNoteCounts => {
	return {type: "SET_INTL_SHIPMENT_NOTE_COUNTS", shipmentNoteCounts};
}
const setShipmentHistory = history => {
	return {type: "SET_INTL_SHIPMENT_HISTORY", history};
}

export const getIntlShipments = () => {
	return async (dispatch, getState) => {
		const state = getState().InternationalLoadBoard;
		const pageNum = state.pageNum;
		const limit = state.pageSize;
		const filters = state.loadBoardFilters;
		let url = `/index.php?p=api&r=json&c=international&m=loadCollection&page=${pageNum}&limit=${limit}`;
		
		if (Array.isArray(filters) && filters.length) {
			url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
		}

		dispatch(setGetIntlShipmentsLoading(true));
		try {
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && Array.isArray(response.data.body.shipments) && response.data.body.records) {
				dispatch(setShipments(response.data.body.shipments));
				dispatch(setShipmentRecordTotal(response.data.body.records));
				dispatch(getShipmentNoteCount());
			} else {
				dispatch(setShipments([]));
				dispatch(setShipmentRecordTotal(0));
			}
		} catch (err){ 
			dispatch(setNotification("Error fetching International Shipments", {variant: "error"}));
			console.error(err);
		} finally {
			dispatch(setGetIntlShipmentsLoading(false));
		}
	}
}

export const saveLoadBoardListColumns = (cols) => {
	return async (dispatch, getState) => {
		let url = `index.php?p=api&r=json&c=international&m=saveLoadBoardListColumns`;
		const payload = cols;
		const customColumns = getState().InternationalLoadBoard.loadBoardCustomColumns;
		try {
			const response = await axios.post(url, qs.stringify(payload));
			if (response && response.data && response.data.body && response.data.body.updated) {
				if (response.data.body.updated) {
					dispatch(setNotification("Custom layout saved!", {variant: "success"}));
					if(_.isEmpty(customColumns)) {
						dispatch(setLoadBoardCustomColumns(cols));
					}
				}
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error saving custom layout.", {variant: "error"}));
			console.error(err);
		} 
	}
}

export const getLoadBoardListColumns = () => {
	return async (dispatch) => {
		let url = `index.php?p=api&r=json&c=international&m=getLoadBoardListColumns`;
		try {
			const response = await axios.get(url);
			if (response && response.data && response.data.body && response.data.body.columns) {
				dispatch(setLoadBoardCustomColumns(response.data.body.columns));
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error getting custom layout", {variant: "error"}));
			console.error(err);
		} 
	}
}

export const getLoadBoardFilterOptions = (cols) => {
	return (dispatch) => {
		const defaultColumns = cols;
		const filters = [];

		if(!_.isEmpty(defaultColumns)) {
			defaultColumns.map((col) => {
				if(col.Header !== "Notes" && col.Header !== "History") {
                    filters.push({headerDisplayName: col.Header, accessor: col.accessor, type: col.type.name, value: "", data: col.accessor})
				}
			});
			dispatch(setLoadBoardFilterOptions(filters));
		}
	}
}

export const getLoadBoardOptions = () => {
	return async (dispatch) => {
		let url = `index.php?p=api&r=json&c=international&m=getOptions`;
		try {
			const response = await axios.get(url);
			if (response && response.data && response.data.body && response.data.body.options) {
				if(response.data.body.options.transitStatusOptions) {
					dispatch(setTransitStatusOptions(response.data.body.options.transitStatusOptions));
				}
				if(response.data.body.options.warehouseOptions) {
					dispatch(setWarehouseOptions(response.data.body.options.warehouseOptions));
				}
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error getting load board options", {variant: "error"}))
			console.error(err);
		} 
	}
}

export const getFilterPresets = () => {
	return async (dispatch, getState) => {
		let url = `index.php?p=api&r=json&c=international&m=getLoadBoardPresetFilters`;
		try {
			const response = await axios.get(url);
			if (response && response.data && response.data.body && response.data.body.presetFilters) {
				dispatch(setLoadBoardPresetFilters(response.data.body.presetFilters));
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error getting preset filters!", {variant: "error"}));
			console.error(err);
		} 
	}
}

export const updateTransitStatus = (status, type, id) => {
	return async (dispatch, getState) => {
		let url = `index.php?p=api&r=json&c=international&m=updateTransitStatus&id=${id}&type=${type}&status=${status}`;
		try {
			const response = await axios.post(url);
			if (response && response.data && response.data.body) {
				if(response.data.body) {
					const state = getState();
					let shipments = [...state.InternationalLoadBoard.shipments];
					const idx = shipments.findIndex(ship => ship.id === id);
					shipments[idx][type] = status;
					if(type == 'transit_status') {
						shipments[idx].transit_sub_status = null;
					}
					dispatch(setShipments(shipments));
				}
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error updating transit status.", {variant: "error"}));
			console.error(err);
		}
	}
}

export const exportToCSV = () => {
	return async (dispatch, getState) => {
		const filters = getState().InternationalLoadBoard.loadBoardFilters;

		if(Array.isArray(filters) && filters.length) {
			try {
				let url = `${apiUrl}/index.php?p=api&r=json&c=international&m=export&filters=${encodeURIComponent(JSON.stringify(filters))}`;
				window.location = url;
			} catch (err) {
				dispatch(setNotification("Error exporting records", {variant: "error"}))
				console.error(err);
			} 
		} else {
			dispatch(setNotification("You must apply at least one filter to export.", {variant: "error"}))
			return;
		}

	}
}

export const getShipmentNoteCount = () =>  {
	return async (dispatch, getState) => {
		dispatch(setIntlShipmentsNotesLoading(true));
		const shipments = getState().InternationalLoadBoard.shipments;
		if(!_.isEmpty(shipments)) {
			try {
				let url = `index.php?p=api&r=json&c=internationalNotes&m=getNoteCount`;
				
				if(Array.isArray(shipments)) {
					shipments.forEach(el => {
						url += `&ids[]=${el.id}`;
					});
				}
				const response = await axios.get(url);
				dispatch(handleMessages(response));
				if (response && response.data && response.data.body && response.data.body.response) {
					dispatch(setShipmentNoteCounts(response.data.body.response));
				}
			} catch (err) {
				dispatch(setNotification("Error fetching shipment notes count", {variant: "error"}))
				console.error(err);
			} finally {
				dispatch(setIntlShipmentsNotesLoading(false));
			}
		}
	}
}

export const getShipmentHistory = (id) =>  {
	return async (dispatch, getState) => {
		dispatch(setShipmentHistory([]));
		if(!id.length) {
			return 
		}
		try {
			let url = `index.php?p=api&r=json&c=international&m=getHistory&id=${id}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.history) {
				const shipHistory = response.data.body.history.map((prop, key) => {
					return [prop.date, prop.username, prop.action, prop.message];
				});
				dispatch(setShipmentHistory(shipHistory));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching shipment history", {variant: "error"}))
			console.error(err);
		}
	}
}

