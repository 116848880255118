import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';

// core components
import Badge from "~/components/Badge/Badge.jsx";

const styles = theme => ({
	center: {
		textAlign: "center"
	},
	progress: {
		margin: theme.spacing(2)
	},
	searchButton: {
		[theme.breakpoints.down("sm")]: {
			top: "-50px !important",
			marginRight: "38px",
			float: "right"
		}
	},
	top: {
		zIndex: "4"
	}
});

function Spinner(props) {
	const { classes, loading, message } = props;
	return (
		<Grid container justify="center">
			{message ? (
				<Grid item xs={12} className={classes.center}>
					{loading ? (
						<CircularProgress className={classes.progress} />
					) : (
						<Badge color="danger">{message}</Badge>
					)}
				</Grid>
			) : (
				<Grid item xs={12} className={classes.center}>
					{loading && (
						<CircularProgress className={classes.progress} />
					)}
				</Grid>
			)}
		</Grid>
	);
}

Spinner.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	message: PropTypes.string
};

export default withStyles(styles)(Spinner);
