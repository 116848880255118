import React from "react";
import axios from "~/variables/axios.jsx";
import { Redirect, Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import _ from "lodash";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import { basePath } from "~/variables/server.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormControl, Grid } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import ClearAll from "@material-ui/icons/ClearAll";

const ITEM_HEIGHT = 48;
class ReportBuilding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      redirect: null,
      body: null,
      user: null,
      alert: null,
      modal: false,
      reportType: "",
      reportBehavior: "",
      reportTypes: null,
      current: "1",
      last: "1",
      pages: [],
      wizard: false,
      open: false,
      anchorEl: false,
      currentRow: 0,
      search: false,
      filters: [
        {
          field: "name",
          compare: "starts with",
          value: "",
        },
      ],
      editLink: "",
    };
  }

  async componentDidMount() {
    localStorage.setItem('reportId',"")
    this.setState({ mounted: true });
    this.getReportTypeData();
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinition&m=reportDefinitions"
      );
      const data = response.data;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
        this.setState({
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  getReportTypeData = async () => {
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportype&m=index"
      );
      const data = response.data;
      if (typeof data !== "string" && data.body && data.user) {
        this.setState({
          show: true,
          loading: false,
          body: response.data.body,
          user: response.data.user,
          reportTypes: response.data.body.result,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error loading the Report type!",
          {
            variant: "warning",
          }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  handleClick = (e, row) => {
    this.setState({
      currentRow: row,
      anchorEl: e.currentTarget,
      editLink:
        basePath +
        "/admin/report-build/" +
        row.id,
    });
  }

  handleClose = (event) => {
    this.setState({ anchorEl: false });
  }

  selectReport = async () => {
    this.setState({
      loading: false,
      redirect: (
        <Redirect
          to={
            basePath +
            "/admin/report-build/new/" +
            this.state.reportType +
            "/" +
            this.state.reportBehavior
          }
        />
      ),
    });
  }

  handleTabChange = (event, index) => {
    this.setState({ tabindex: index });
  }

  handleChecked = (name) => (event) => {
    this.setState({ [name]: !!event.target.checked });
  }

  handleInput = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleDatetime = (name, moment) => {
    this.setState({ [name]: moment });
  }

  deleteReport = async (id) => {
    const url =
      "/index.php?p=api&r=json&c=reportDefinition&m=deleteReportDefinition&d=" +
      id;
    this.setState({ show: false, loading: true, alert: null });
    try {
      const response = await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.setState({ currentRow: 0, anchorEl: false });
        this.props.handleMessages(response);
        if(response.data.body) {
          this.handlePageClick(this.state.current);
        } else {
          this.setState({ show: true, loading: false });
        }
      }
    } catch (error) {
      this.setState({ currentRow: 0, anchorEl: false, show: true, loading: false });
      this.props.setNotification(
        "There was an error deleting the Report Definition!",
        { variant: "warning" }
      );
    }
  }

  handleDeleteReport = (id) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{
            display: "block",
            color: "black",
          }}
          title="Are you sure you want to delete this Report Definition?"
          onConfirm={() => this.deleteReport(id)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, delete it!"
          cancelBtnText="No!"
          showCancel
        >
          This action cannot be undone!
        </SweetAlert>
      ),
    });
  }

  getPages = () => {
    const { current, last } = this.state;

    let pages = [{ active: true, text: "..." }];
    const nextPage = { text: ">" };
    const prevPage = { text: "<" };

    if (last > 1 && !_.isEmpty(this.state.pages)) {
      pages = this.state.pages.map((index) => {
        const page = { text: index };
        if (index == parseInt(current)) {
          page.active = true;
        } else {
          page.onClick = (e) => this.handlePageClick(index);
        }
        return page;
      });
    }

    if (current < last) {
      nextPage.onClick = (e) => this.handlePageClick(parseInt(current) + 1);
    } else {
      nextPage.disabled = true;
    }

    if (parseInt(current) - 1 !== 0) {
      prevPage.onClick = (e) => this.handlePageClick(parseInt(current) - 1);
    } else {
      prevPage.disabled = true;
    }

    pages.push(nextPage);
    pages.unshift(prevPage);

    return pages;
  }

  getFilters = (filters) => {
    const { classes } = this.props;
    return filters.map((prop, key) => {
      return (
        <GridItem xs={12} key={key}>
          <GridContainer>
            <GridItem xs={2}>
              {key == 0 ? (
                <IconButton
                  aria-label="add filter"
                  size="small"
                  color="primary"
                  className={classes.marginRight}
                  style={{float: "right"}}
                  onClick={() => this.addFilter()}
                >
                  <Add />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="remove filter"
                  size="small"
                  color="secondary"
                  className={classes.marginRight}
                  style={{ float: "right" }}
                  onClick={() => this.removeFilter(key)}
                >
                  <Close />
                </IconButton>
              )}
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={prop.field || ""}
                  inputProps={{ name: "field" }}
                  onChange={(e) => this.handleFilters(key, "field", e)}
                >
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="name"
                  >
                    Report Name
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="type"
                  >
                    Type
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="schedule"
                  >
                    Schedule
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="last_run"
                  >
                    Last Run
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="created_at"
                  >
                    Created
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="owner"
                  >
                    Run on
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={prop.compare || ""}
                  inputProps={{ name: "compare" }}
                  onChange={(e) => this.handleFilters(key, "compare", e)}
                >
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="starts with"
                  >
                    starts with
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="contains"
                  >
                    contains
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="ends with"
                  >
                    ends with
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="equals"
                  >
                    equals
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "text",
                  value: prop.value || "",
                  onChange: (e) => this.handleFilters(key, "value", e),
                  onKeyPress: (e) => this.handleKeyPress(e),
                }}
                white
              />
            </GridItem>
            <GridItem xs={3}>
              {key == 0 && (
                <Button
                  size="sm"
                  color="linkedin"
                  className={classes.marginLeft}
                  onClick={() => this.handleSearch()}
                >
                  <Search /> Search
                </Button>
              )}
              {key == 0 &&
                (this.state.filters.length > 1 || prop.value !== "") && (
                  <Button
                    size="sm"
                    color="white"
                    onClick={() => this.clearFilters()}
                  >
                    <ClearAll /> Clear Filters
                  </Button>
                )}
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    });
  }

  addFilter = () => {
    const { filters } = this.state;
    filters.push({
      field: "name",
      compare: "starts with",
      value: "",
    });
    this.setState({ filters });
  }

  removeFilter = (key) => {
    const { filters } = this.state;
    if (!_.isEmpty(filters) && key != 0) {
      filters.splice(key, 1);
      this.setState({ filters });
    }
  }

  clearFilters = async () => {
    this.setState({
      show: false,
      loading: true,
      search:false,
      filters: [
        {
          field: "name",
          compare: "starts with",
          value: "",
        },
      ],
    });
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinition&m=reportDefinitions"
      );
      const { data } = response;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (typeof data !== "string" && !_.isEmpty(data.body)) {
        this.setState({
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error loading the report histories!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error loading the report histories!",
        {
          variant: "error",
        }
      );
    }
  }

  handleFilters = (key, name, event) => {
    const { filters } = this.state;
    filters[key][name] = event.target.value;
    this.setState({ filters });
  }

  handleSearch = async () => {
    const { filters } = this.state;
    const data = { queries: filters };
    const url =
      "/index.php?p=api&r=json&c=reportDefinition&m=filterReportDefinition";
    this.setState({
      show: false,
      loading: true,
    });

    try {
      const response = await axios.post(url, qs.stringify(data));
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.body) &&
        !_.isEmpty(response.data.user)
      ) {
        this.setState({
          search: true,
          show: true,
          loading: false,
          user: response.data.user,
          current: response.data.body.current
            ? response.data.body.current.toString()
            : "1",
          last: response.data.body.last
            ? response.data.body.last.toString()
            : "1",
          pages: response.data.body.pages || [],
          result: response.data.body.result || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error searching for report!", {
        variant: "error",
      });
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSearch();
    }
  }

  getHeaders = () => {
    return [
      "#",
      "Report Name",
      "Type",
      "Schedule",
      "Last Run",
      "Created",
      "Owner",
      "Action",
    ];
  }
  getColumns = (data) => {
    if (data.result != undefined) {
      return data.result.map((prop, key) => {
        return [
          prop.id,
          prop.name,
          prop.type,
          prop.schedule,
          prop.last_run != null
            ? moment(new Date(prop.last_run)).format("MM/DD/YY hh:mm:ss A")
            : "",
          prop.created_at != null
            ? moment(new Date(prop.created_at)).format("MM/DD/YYYY")
            : "",
          prop.owner,

          <div key={key}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => this.handleClick(event, prop)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              keepMounted
              anchorEl={this.state.anchorEl}
              open={this.state.anchorEl}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem component={Link} to={this.state.editLink}>
                Edit
              </MenuItem>
              <MenuItem
                component={Link}
                to={basePath + "/admin/run-report/" + this.state.currentRow.id}
              >
                Run
              </MenuItem>
              <MenuItem
                component={Link}
                to={
                  basePath +
                  "/admin/report-scheduler/" +
                  this.state.currentRow.id
                }
              >
                Schedule
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.handleDeleteReport(this.state.currentRow.id)
                }
              >
                Delete
              </MenuItem>
            </Menu>
          </div>,
        ];
      });
    }

    return data.map((prop, key) => {
      return [
        prop.id,
        prop.name,
        prop.type,
        prop.schedule,
        prop.last_run != null
          ? moment(new Date(prop.last_run)).format("MM/DD/YY hh:mm:ss A")
          : "",
        prop.created_at != null
          ? moment(new Date(prop.created_at)).format("MM/DD/YY")
          : "",
        prop.owner,

        <div key={key}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => this.handleClick(event, prop)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            keepMounted
            anchorEl={this.state.anchorEl}
            open={this.state.anchorEl}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem
              component={Link}
              to={
                basePath +
                "/admin/report-build/" +
                this.state.currentRow.id
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              component={Link}
              to={basePath + "/admin/run-report/" + this.state.currentRow.id}
            >
              Run
            </MenuItem>
            <MenuItem
              component={Link}
              to={
                basePath + "/admin/report-scheduler/" + this.state.currentRow.id
              }
            >
              Schedule
            </MenuItem>
            <MenuItem
              onClick={() => this.handleDeleteReport(this.state.currentRow.id)}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>,
      ];
    });
  }

  handlePageClick = async (page) => {
    this.setState({ show: false, loading: true });
    const { filters, search } = this.state;
    const data = { queries: filters };
    const url = search
      ? "/index.php?p=api&r=json&c=reportDefinition&m=filterReportDefinition&d=" +
        page +
        "/5"
      : "/index.php?p=api&r=json&c=reportDefinition&m=reportDefinitions&d=" +
        page +
        "/5";
    try {
      const response = search
        ? await axios.post(url, qs.stringify(data))
        : await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
        this.setState({
          show: true,
          loading: false,
          user: response.data.user,
          current: response.data.body.current
            ? response.data.body.current.toString()
            : "1",
          last: response.data.body.last
            ? response.data.body.last.toString()
            : "1",
          pages: response.data.body.pages || [],
          result: response.data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading!", {
        variant: "warning",
      });
    }
  }

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    if (this.state.reportTypes == null) {
      return <div />;
    }
    if (this.state.redirect) {
      return this.state.redirect;
    }
    return (
      <GridContainer>
        <GridItem style={{paddingTop: "10px"}} xs={12} sm={12} md={12}>
          {!_.isEmpty(this.state.filters) &&
            this.getFilters(this.state.filters)}
        </GridItem>

        <GridItem xs={12} sm={12} md={12} className={classes.center}>
          <Pagination pages={this.getPages()} />
        </GridItem>

        {this.state.alert}

        <GridItem xs={12} sm={12} md={12}>
          <Card style={{margin: 0}}>
            <CardBody>
              <GridContainer>
                {this.state.show ? (
                  <GridItem xs={12} sm={12} md={12}>
                    {!_.isEmpty(this.state.result) ? (
                      <TableContainer>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="simple table"
                        >
                          <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                          </colgroup>

                          <TableHead>
                            <TableRow>
                              {this.getHeaders().map((cell,key) => (
                                <TableCell key={key}>{cell}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.getColumns(this.state.result).map(
                              (row, key) => (
                                <TableRow key={key}>
                                  {row.map((cell,key) => (
                                    <TableCell key={key} component="th" scope="row">
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <p className={classes.center}>
                        <b>{"No Report To Display"}</b>
                      </p>
                    )}
                  </GridItem>
                ) : (
                  <Spinner
                    loading={this.state.loading}
                    message="Failed to retrieve Report from the server"
                  />
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(ReportBuilding)));
