import {
  defaultFont,
  container,
  containerFluid,
  whiteColor,
  grayColor,
  tfmOrangeColor,
  tfmBlueColor
} from "assets/jss/empire-tms.jsx";



const footerStyle = {
  block: {},
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px"
  },
  footer: {
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    backgroundColor: tfmBlueColor,
    padding: "0",
    ...defaultFont,
    zIndex: 4,
    left: 0,
    right: 0,
    position: 'fixed',
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative",
    fontWeight: '500'
  },
  a: {
    color: "#c15027",
    textDecoration: "none",
    backgroundColor: "transparent",
    fontWeight: '500',
	"&:visited": {
	  color: tfmOrangeColor
	}
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor
    }
  },
  poweredByImg: {
    width: '30px',
  },
};
export default footerStyle;
