import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hasPermission } from "../../redux/selectors/Admin.jsx";
import qs from "qs";
import _ from "lodash";

import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { TextField } from "@material-ui/core";

// material ui icons
import { HelpOutlined, Check } from "@material-ui/icons";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import { handleMessages, setNotification } from "../../redux/actions/notifications.jsx";
import { userIsAdmin, adminInUserSession } from "../../redux/selectors/Admin.jsx";
import AccessorialsSelect from "../../components/TMS/AccessorialsSelect.jsx";
import WithTooltip from "../../components/TMS/WithTooltip.jsx";
import { loadAutoSelectAccessorialMaps, loadParcelData } from "../WarehouseLocation/actions/WarehouseLocation.jsx";
import { loadCarriers as loadParcelCarriers, loadServices as loadParcelServices } from "../ParcelServices/actions/ParcelServices.jsx"
import ParcelServices from "../ParcelServices/containers/ParcelServices.jsx"
import { convertToCountryCode } from "../../helpers.jsx"
import AdditionalContacts from "../../components/TMS/AdditionalContacts.jsx";
// style for this view
import newProductStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

/**
 * Note: this component is a little more complicated than one might assume it should be
 * This is because there are 2 endpoints for loading a location, 4 endpoints for saving a location, and one for loading warehouses
 * The endpoints used depend on whether this.state.id is empty (whether we are creating or updating) and whether or not this is
 * an admin user (admin users in user sessions don't count here)
 *
 * @todo : revisit this once we update the API to be less redundant
 */
class EditAddress extends React.Component {

	state = {
		//should we redirect on the next render
		redirect: false,
		//are we waiting for the response of a request for the location or for warehouse data
		loading: false,
		//are we waiting for the response of a request for accessorial data
		accsloading: false,
		//location id
		id: "",
		//warehouse id
		warehouse: "",
		//location name
		name: "",
		//location code
		code: "",
		//location type
		type: "shipper",
		//location address 1
		address1: "",
		//location address2
		address2: "",
		//location city
		city: "",
		//location state
		state: "",
		//location zip
		zip: "",
		//location country
		country: "US",
		//is this the default location for the type
		default: false,
		//is this a reciprocal location or should this create one
		reciprocal: false,
		//location contact phone num
		phone: "",
		//location contact fax num
		fax: "",
		//location contact email
		email: "",
		//location contact id
		contact_id: "",
		//location contact name
		contact_name: "",
		//string username of user that created this location
		create_by: "",
		//string date/time in which this was created
		created: "",
		//string username of user that edited this location
		edit_by: "",
		//note displayed to the user when they choose this address while creating an LTL BoL
		internal_note: "",
		//note appended to the existing special instructions input when this address is chosen while creating an LTL BoL
		external_note: "",
		//string date/time in which this was created
		edited: "",
		//history format: {actions: [], companyId: null, userId: null, warehouseId: string}
		log: {},
		//array of warehouses to choose from when creating a location as a user
		warehouses: [],
		//string - informs where we redirect to after save in certain scenarios
		referer: null,
		//accessorial data from the related company's accessorial profile
		accessorials: [],
		//auto select accessorial maps
		autoSelectAccessorialMaps: [],
		// auto tracking and emails
		auto_tracking: false,
		additional_tracking_emails: [],
		//parcel related fields
		parcel: {
			offeror: '',
			emergency_phone: '',
			intl_emergency_phone: '',
			emergency_response_registrant: '',
			signatory_contact_name: '',
			signatory_contact_title: '',
			signatory_place: '',
			residential: false,
			cod: false,
			cod_collection_type: "",
			signature: false,
			signature_type: "",
			carriers: [],
			carrier: "",
			services: [],
			service: "",
		},
		whMgtId: null,
	};

	async componentDidMount() {
		const parsed = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});

		/**
		 * For non admin users, check if the end of the path is edit or new
		 * If it is edit, load the location by the query param d
		 * If it is new, load warehouses for selecting a new warehouse.
		 *
		 * For admin users, just load the warehouse if we can get an ID from the url.
		 * When creating a location as an admin, we just use the warehouse ID in the url,
		 * so we don't need to load a list of warehouses to choose from.
		 */
		if (!this.props.isAdmin) {
			const parts = this.props.location.pathname.split("/");
			if (parts.length && typeof parts[parts.length - 1] == "string" && parts[parts.length - 1].toLowerCase() === "edit") {
				let d = parsed.d ? parsed.d.split("/") : [];
				if (_.isEmpty(d)) {
					this.props.setNotification("Unable to load location - invalid URL query string.", {variant: "error"});
				} else {
					this.setState({
						id: d[0],
						warehouse: d[1],
					}, function() {
						this.loadAddress();
					});
				}
				this.loadWarehouses();

			} else {
				//if last part of path is not edit, assume this is a new address
				this.setState({
					warehouse: parsed.d ? parsed.d : "",
				}, function(){
					this.loadWarehouses();
					this.loadAccessorials();
				})
			}

		} else {
			const id = (!_.isEmpty(this.props.match.params.id)) ? this.props.match.params.id : null;
			const cid = (!_.isEmpty(this.props.match.params.companyId)) ? this.props.match.params.companyId : null;
			const warehouse = (!_.isEmpty(this.props.match.params.warehouseId)) ? this.props.match.params.warehouseId : null;
			this.setState({
				id,
				warehouse,
				referer: !_.isEmpty(this.props.match.params.referer) ? this.props.match.params.referer : null,
				whMgtId: JSON.parse(localStorage.getItem("tms_wh_mgt_id")),
				company_name_id: cid
			}, function() {
				if (!_.isEmpty(id)) {
					this.loadAddress();
				} else {
					this.loadAccessorials();
				}
				if(this.props.hasParcelEnabled) this.updateParcelCarriers();
			});
		}
		localStorage.removeItem("tms_wh_mgt_id");
	}

	loadWarehouseFromId = async (id = "") => {
		if (id == "") {
			id = this.state.warehouse;
		}
		try {
			const warehouseResp = await axios.get("/index.php?p=api&r=json&c=admin&m=getWarehouse&d=" + id + "/true");
			this.props.handleMessages(warehouseResp);
			if (warehouseResp && warehouseResp.data && warehouseResp.data.body && !_.isEmpty(warehouseResp.data.body)) {
				const body = warehouseResp.data.body;
				//NOTE: this endpoint does not return warehouse:is_default.
				let warehouse = {
						code: body.code,
						default_direction_terms: body.default_direction_terms,
						enable_product_part_no: body.enable_product_part_no,
						id: body.warehouse_id,
						name: body.name,
						override_custom_piece_count: body.override_custom_piece_count,
						prevent_exclusion_execution: body.prevent_exclusion_execution,
						require_pod_for_billing: body.require_pod_for_billing,
						warehouse_cusom_references: body.default_custom_references,
				}
				return warehouse;
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading warehouse data!", {variant: "error"});
		}
		return false;
	}

	getWarehouseFromId = async (id = "") => {
		if(id == "") {
			id = this.state.warehouse;
		}
		let warehouse = this.state.warehouses.filter(wh => {
			return wh.id == id
		});
		if(Array.isArray(warehouse)) {
			warehouse = warehouse[0];
		}
		if(_.isEmpty(warehouse)) {
			return await this.loadWarehouseFromId();
		}
		return warehouse;
	}

	updateParcelCarriers = async (warehouse_code = "", warehouse_id = "") => {
		if(warehouse_code == "") {
			let warehouse = await this.getWarehouseFromId(warehouse_id);
			if(warehouse !== false) {
				warehouse_code = warehouse.code;
			} else {
				this.props.setNotification("There was an error loading the carriers!", {variant: "error"});
				return false;
			}
		}

		let carriers = await loadParcelCarriers(warehouse_code);
		let parcel = {...this.state.parcel};

		if(!_.isEmpty(carriers.messages)) {
			this.props.handleMessages(carriers.messages);
		}
		if(carriers.hasOwnProperty('carriers') && carriers.hasOwnProperty('carrier')) {
			parcel.carriers = carriers.carriers;
			parcel.carrier = carriers.carrier;

			await this.setState({
				parcel: parcel,
			});
		}

		this.loadParcelServices();
	}


	loadParcelServices() {
		const parcel = {...this.state.parcel};
		// may have to address this if we have shippers shipping FROM CA/MX
		let shipperCountry = "US";
		let consigneeCountry = "US";
		if(this.state.type == "shipper") {
			shipperCountry = convertToCountryCode(this.state.country, 'short');
		} else if(this.state.type == "consignee") {
			consigneeCountry = convertToCountryCode(this.state.country, 'short');
		}

		let services = loadParcelServices(parcel.carrier, parcel.service, parcel.residential, shipperCountry, consigneeCountry, false);

		if(!_.isEmpty(services.messages)) {
			this.props.handleMessages(services.messages);
		}
		if(services.hasOwnProperty('services') && services.hasOwnProperty('service')) {
			parcel.services = services.services;
			parcel.service = services.service;
			this.setState({
				parcel: parcel
			});
		}
	}

	handleChecked = name => async event => {
		if(name.includes('parcel.') !== false) {
			name = name.substring(name.indexOf('.') + 1);
			const parcel = {...this.state.parcel};
			parcel[name] = !!event.target.checked;
			await this.setState({ parcel: parcel});
			if(this.props.hasParcelEnabled && name == 'residential') {
				this.loadParcelServices();
			}
		} else {
			this.setState({ [name]: !!event.target.checked });
		}
	};

	handleInput = name => async event => {
		//set event.target to a variable, because after the set state, event doesn't persist.
		let target = event.target;
		if(name.includes('parcel.') !== false) {
			name = name.substring(name.indexOf('.') + 1);
			const parcel = {...this.state.parcel};
			parcel[name] = target.value;
			this.setState({ parcel: parcel});
		} else {
			await this.setState({ [name]: target.value });
			if(this.props.hasParcelEnabled && name == 'country' && target.value.length >= 2) {
				this.loadParcelServices();
			}
			if (name === 'zip') {
				const country = /[a-z]/i.test(target.value) ? 'CA' : this.state.country;
				this.setState({
					state: '',
					country,
				}, () => this.handleZip(target.value, country));
			}
		}
	};

	async handleZip(zip, country = null) {
		zip = zip.replace(" ", "").toUpperCase();
		let isNum = false;
		isNum = !(Number.isNaN(zip) || Number.isNaN(parseInt(zip)));
		if (!_.isEmpty(zip) && ((isNum) && zip.length >= 5) || zip.length >= 6) {
			try {
				let url = "/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + encodeURIComponent(zip);
				url += country != null ? `/${convertToCountryCode(country, 'long')}` : ``;
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					const { body } = response.data;
					if (body[zip]) {
						this.setState({
							city: body[zip][0].city,
							state: body[zip][0].state,
							country: convertToCountryCode(body[zip][0].country, 'short'),
						});
					} else {
						this.props.setNotification("The postal code could not be found. Chances are it is not valid.", { variant: "error" });
					}
				} else {
					this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
			}
		}
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleParcelChange = async (event) => {
		let name = event.target.name;
		const parcel = {...this.state.parcel};
		if(name.includes('parcel.') !== false) {
			name = name.substring(name.indexOf('.') + 1);
		}
		parcel[name] = event.target.value;
		await this.setState({ parcel: parcel});
		if(name == 'warehouse') {
			this.updateParcelCarriers();
		}
		if(name == 'carrier') {
			this.loadParcelServices();
		}
	}

	loadAddress = async () => {
		this.setState({loading: true}, async () => {
			const url = !this.props.isAdmin
				? "/index.php?p=api&r=json&c=location&m=edit&d=" + this.state.id + "/" + encodeURIComponent(this.state.warehouse)
				: "/index.php?p=api&r=json&c=admin&m=editLocation&d=" + this.state.id + "/" + encodeURIComponent(this.state.warehouse);
			try {
				const response = await axios.get(url);
				this.props.handleMessages(response);
				if (response && response.data && response.data.body && !_.isEmpty(response.data.body[0])) {
					const address = response.data.body[0];
					let auto_tracking = false;
                    if(address.auto_tracking == 1) {
                        auto_tracking = true;
                    }

					this.setState({
						name: address.name || "",
						code: address.code || "",
						type: address.type || "shipper",
						address1: address.address1 || "",
						address2: address.address2 || "",
						city: address.city || "",
						state: address.state || "",
						zip: address.zip || "",
						country: address.country || "US",
						default: address.default == 1,
						reciprocal: address.reciprocal == 1,
						phone: address.phone || "",
						fax: address.fax || "",
						email: address.email || "",
						contact_id: address.contact_id || "",
						contact_name: address.contact_name || "",
						create_by: address.create_by || "",
						created: address.created || "",
						edit_by: address.edit_by || "",
						internal_note: address.internal_note || "",
						external_note: address.external_note || "",
						edited: address.edited || "",
						log: address.log || {},
						company_name_id: address.company_name_id || "",
						auto_tracking,
						additional_tracking_emails: address.additional_tracking_emails || [],
					}, function() {
						this.loadAccessorials();
					});
				} else {
					this.props.setNotification("There was an error loading the data!", {variant: "error"});
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error loading the data!", {variant: "error"});
			} finally {
				this.setState({loading: false});
			}
		});
	}

	loadAccessorials = async () => {
		this.setState({accsloading: true}, async () => {
			let url = "/index.php?p=api&r=json&c=billoflading&m=getCompanyAccessorialProfile";
			if (!_.isEmpty(this.state.company_name_id)) {
				url += `&companyid=${this.state.company_name_id}`;
			} else if(!_.isEmpty(this.props.match.params.companyId)) {
				url += `&companyid=${this.props.match.params.companyId}`;
			}
			try {
				const response = await axios.get(url);
				this.props.handleMessages(response);
				if (response && response.data && response.data.body && response.data.body.accessorials) {
					let accessorials = response.data.body.accessorials;
					if (typeof accessorials === "object") {
						accessorials = Object.values(accessorials);
					}
					this.setState({accessorials: [...accessorials]}, function() {
						this.loadAccMaps();
						if(this.props.hasParcelEnabled) this.loadParcel();
					});
				}

			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error loading accessorial data!", {variant: "error"});
			} finally {
				this.setState({accsloading: false});
			}
		});
	}

	loadAccMaps = async () => {
		this.setState({autoSelectAccessorialMaps: []}, async () => {
			if (!_.isEmpty(this.state.id)) {
				const result = await this.props.loadAutoSelectAccessorialMaps(this.state.id);
				if (Array.isArray(result)) {
					this.setState({autoSelectAccessorialMaps: [...result]});
				}
			}
		})
	}

	loadParcel = async () => {
		if (!_.isEmpty(this.state.id)) {
			const result = await this.props.loadParcelData(this.state.id);
			if (Array.isArray(result)) {
				if(!_.isEmpty(result)) {
					let resObj = result;
					if(result.hasOwnProperty(0)) {
						resObj = result[0];
					}
					let parcelResult = this.state.parcel;
					parcelResult.offeror = resObj.offeror;
					parcelResult.emergency_phone = resObj.emergency_phone;
					parcelResult.intl_emergency_phone = resObj.intl_emergency_phone;
					parcelResult.emergency_response_registrant = resObj.emergency_response_registrant;
					parcelResult.signatory_contact_name = resObj.signatory_contact_name;
					parcelResult.signatory_contact_title = resObj.signatory_contact_title;
					parcelResult.signatory_place = resObj.signatory_place;
					parcelResult.residential = resObj.residential == "1" ? true : false;
					parcelResult.cod = resObj.cod == "1" ? true : false;
					parcelResult.cod_collection_type = resObj.cod_collection_type;
					parcelResult.signature = resObj.signature == "1" ? true : false;
					parcelResult.signature_type = resObj.signature_type;
					parcelResult.carrier = resObj.carrier;
					parcelResult.service = resObj.service;

					await this.setState({
						parcel: parcelResult
					});
					this.loadParcelServices();
				}
			}
		}
	}

	loadWarehouses = async () => {
		await this.setState({loading: true}, async () => {
			try {
				const response = await axios.get("/index.php?p=api&r=json&c=location&m=add");
				this.props.handleMessages(response);
				if (response.data.body && Array.isArray(response.data.body.warehouses)) {
					const warehouses = response.data.body.warehouses;
					await this.setState({
						warehouses: warehouses,
					});
					if(this.props.hasParcelEnabled) this.updateParcelCarriers();
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error loading the data!", {variant: "error"});
			} finally {
				this.setState({ loading: false });
			}
		})
	}

	saveAddress = async () => {
		this.setState({ loading: true });

		let url = "";

		let data = {
			name: this.state.name,
			code: this.state.code,
			type: this.state.type,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			country: this.state.country,
			contact_name: this.state.contact_name,
			phone: this.state.phone,
			fax: this.state.fax,
			email: this.state.email,
			parcel: this.state.parcel,
			internal_note: this.state.internal_note,
			external_note: this.state.external_note,
			additional_tracking_emails: this.state.additional_tracking_emails,
		};
		data.auto_tracking = this.state.auto_tracking ? "1" : "0";

		if (!this.props.isAdmin) {
			if (!_.isEmpty(this.state.id)) {
				data.id = this.state.id;
				data.contact_id = this.state.contact_id;
				data.warehouse_id = this.state.warehouse;
				if (this.state.default) data.default = '1';
				if (this.state.reciprocal) data.reciprocal = '1';
				url = "/index.php?p=api&r=json&c=location&m=edit&d=" + this.state.id + "/" + encodeURIComponent(this.state.warehouse);
			} else {
				data.warehouse = this.state.warehouse;
				data.reciprocal = this.state.reciprocal ? "1" : "0";
				if (this.state.default) data.default = '1';
				data.auto_select_acc_ids = [];
				this.state.autoSelectAccessorialMaps.forEach(acc => data.auto_select_acc_ids.push(acc['acc_name_id']));
				url = "/index.php?p=api&r=json&c=location&m=add&d=" +
					encodeURIComponent(this.state.warehouse);
			}
		} else {
			data.id = this.state.id;
			data.contact_id = this.state.contact_id;
			data.warehouse_id = this.state.warehouse;
			if (this.state.default) data.default = '1';
			if (this.state.reciprocal) data.reciprocal = '1';
			data.auto_select_acc_ids = [];
			this.state.autoSelectAccessorialMaps.forEach(acc => data.auto_select_acc_ids.push(acc['acc_name_id']));
			url = !_.isEmpty(this.state.id)
			? "/index.php?p=api&r=json&c=admin&m=editLocation&d=" + this.state.id + "/" + encodeURIComponent(this.state.warehouse)
			: "/index.php?p=api&r=json&c=admin&m=newLocation&d=" + encodeURIComponent(this.state.warehouse);
		}

		try {
			const response = await axios.post(url, qs.stringify(data));
			this.props.handleMessages(response);

			if (typeof response.data !== "string" && response.data.body !== false) {
				if(this.state.whMgtId) {
					localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
					this.setState({whMgtId: null});
					this.props.history.push(basePath + "/admin/company/" + this.state.company_name_id);
				}
				this.props.setNotification("Address successfully saved!", {variant: "success"});
				this.setState({ redirect: true });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("Unexpected error saving the address!", {variant: "error"});
		} finally {
			this.setState({loading:false})
		}
	}

	createAccessorialMap = async accId => {

		if(!_.isEmpty(this.state.id)) {

			this.setState({accsloading: true}, async () => {
				let url = "/index.php?p=api&r=json&c=autoSelectAccessorialLocationMap&m=save";

				let payload = {
					location_name_id: this.state.id,
					acc_name_id: accId,
				};

				try {
					const response = await axios.post(url, payload, {headers: {'Content-Type': 'application/json'}});
					this.props.handleMessages(response);

					if (response && response.data && response.data.body && response.data.body.response) {
						this.setState({autoSelectAccessorialMaps: [...this.state.autoSelectAccessorialMaps, response.data.body.response]});
					}
				} catch (error) {
					console.error(error);
					this.props.setNotification("There was an error saving the accessorial map!", {variant: "error"});
				} finally {
					this.setState({accsloading: false});
				}
			});

		} else {

			this.setState({autoSelectAccessorialMaps: [...this.state.autoSelectAccessorialMaps, {acc_name_id: accId}]});

		}
	}

	deleteAccessorialMap = async mapId => {

		if(!_.isEmpty(this.state.id)) {

			this.setState({accsloading: true}, async () => {
				let url = `/index.php?p=api&r=json&c=autoSelectAccessorialLocationMap&m=delete&id=${mapId}`;
				try {
					const response = await axios.get(url);
					this.props.handleMessages(response);
					if (response && response.data && response.data.body && response.data.body.deleted) {
						this.setState({autoSelectAccessorialMaps: [...this.state.autoSelectAccessorialMaps.filter(map => +map.id !== +mapId)]});
					}

				} catch (error) {
					console.error(error);
					this.props.setNotification("There was an error deleting the accessorial map!", {variant: "error"});
				} finally {
					this.setState({accsloading: false});
				}
			});

		} else {

			this.setState({autoSelectAccessorialMaps: [...this.state.autoSelectAccessorialMaps.filter(map => +map.acc_name_id !== +mapId)]});

		}
	}

	getAccessorials = () => {
		let accs = [...this.state.accessorials];
		let maps = [...this.state.autoSelectAccessorialMaps];
		let mapIds = maps.map(map => +map.acc_name_id);
		return accs.map(acc => {
			acc.checked = typeof acc === "object" && mapIds.indexOf(+acc.id) !== -1;
			return acc;
		});
	}

	getAccMapFromAccId = accId => {
		let theMap = null;
		this.state.autoSelectAccessorialMaps.map(map => {
			if (+map.acc_name_id === +accId) {
				theMap = map;
			}
		});
		return theMap;
	}

	handleAccChecked = key => {
		let acc = this.state.accessorials[key];
		if (acc) {
			if (acc.checked) {
				let map = this.getAccMapFromAccId(+acc.id);
				if (map) {
					!_.isEmpty(this.state.id) ? this.deleteAccessorialMap(+map.id) : this.deleteAccessorialMap(+map.acc_name_id);
				}
			} else {
				this.createAccessorialMap(+acc.id);
			}
		}
	}

	handleTrackingEmailInput = (key = null) => event => {
		let additional_tracking_emails = [...this.state.additional_tracking_emails];
		if (key == null) {
			additional_tracking_emails.push("");
		} else {
			if(additional_tracking_emails.length > key) {
				additional_tracking_emails[key] = event.target.value
			}
		}
		this.setState({additional_tracking_emails});
	}

    removeTrackingEmail = (key) => {
        let additional_tracking_emails = [...this.state.additional_tracking_emails];
        additional_tracking_emails.splice(key, 1);
        this.setState({additional_tracking_emails});
    }

	render() {
		if (this.state.redirect) {
			let redirectTo = basePath + "/admin/address-book";
			if (this.props.isAdmin) {
				redirectTo = this.state.referer === "warehouse"
					? basePath + "/admin/warehouse/edit/" + this.state.warehouse
					: basePath + "/admin/management/locations";
			}
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					{!this.props.isAdmin && _.isEmpty(this.state.id) &&
						<GridContainer>
							<GridItem xs={6}>
								<InputLabel htmlFor="warehouse" className={classes.label}>
									Warehouse/Facility
								</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{className: classes.selectMenu}}
										classes={{select: classes.select}}
										value={this.state.warehouse}
										inputProps={{name: "warehouse",id: "warehouse"}}
										onChange={this.handleChange}
									>
										{this.state.warehouses.length ? (
											this.state.warehouses.map((warehouse, key) => {
												return (
													<MenuItem
														key={key}
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value={warehouse.id}
													>
														{warehouse.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem
												disabled
												classes={{
													root:
														classes.selectMenuItem
												}}
											>
												No Warehouses
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</GridItem>
						</GridContainer>
					}
					<GridContainer>
						{this.props.hasWrite &&
							<>
								<GridItem xs = {10}>
								</GridItem>
								<GridItem xs = {2}>
									<br />
									<Button
										color="linkedin"
										onClick={this.saveAddress}
									>
										Save Address
									</Button>
								</GridItem>
							</>
						}
						<Card style={{marginTop:'10px', marginBottom:'10px'}}>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<h5><b>Location Information</b></h5>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="name" className={classes.label}>
											Location Name
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "name",
												value: this.state.name,
												onChange: this.handleInput("name"),
												readOnly: !this.props.hasWrite
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="code" className={classes.label}>
											Location Code
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "code",
												value: this.state.code,
												onChange: this.handleInput("code"),
												readOnly: !this.props.hasWrite
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="type" className={classes.label}>
											Type
										</InputLabel>
										<br />
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<Select
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select
												}}
												value={this.state.type}
												inputProps={{
													name: "type",
													id: "type"
												}}
												onChange={this.handleChange}
												readOnly={!this.props.hasWrite}
											>
												<MenuItem
													classes={{
														root:
															classes.selectMenuItem,
														selected:
															classes.selectMenuItemSelected
													}}
													value="shipper"
												>
													Shipper
												</MenuItem>
												<MenuItem
													classes={{
														root:
															classes.selectMenuItem,
														selected:
															classes.selectMenuItemSelected
													}}
													value="consignee"
												>
													Consignee
												</MenuItem>
												<MenuItem
													classes={{
														root:
															classes.selectMenuItem,
														selected:
															classes.selectMenuItemSelected
													}}
													value="bill_to"
												>
													Bill To
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="address1" className={classes.label}>
											Address
										</InputLabel>
										<br />
										<CustomInput
											id="address1"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "address1",
												value: this.state.address1,
												onChange: this.handleInput(
													"address1"
												),
												readOnly: !this.props.hasWrite,

											}}
											white
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="address2" className={classes.label}>
											Extended Address
										</InputLabel>
										<br />
										<CustomInput
											id="address2"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "address2",
												value: this.state.address2,
												onChange: this.handleInput(
													"address2"
												),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="zip" className={classes.label}>
											Zip
										</InputLabel>
										<br />
										<CustomInput
											id="zip"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "zip",
												value: this.state.zip,
												onChange: this.handleInput("zip"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="city" className={classes.label}>
											City
										</InputLabel>
										<br />
										<CustomInput
											id="city"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "city",
												value: this.state.city,
												onChange: this.handleInput("city"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="state" className={classes.label}>
											State
										</InputLabel>
										<br />
										<CustomInput
											id="state"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "state",
												value: this.state.state,
												onChange: this.handleInput("state"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel
											htmlFor="country"
											className={classes.label}
										>
											Country
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												id: "country",
												type: "text",
												name: "country",
												value: this.state.country || "",
												onChange: this.handleInput("country"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={
														<Check className={classes.checkedIcon} />
													}
													icon={
														<Check className={classes.uncheckedIcon} />
													}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.default}
													onChange={this.handleChecked("default")}
													disabled={!this.props.hasWrite}
												/>
											}
											classes={{ label: classes.label }}
											label="Make Default"
										/>
									</GridItem>
									{!this.props.isAdmin && <GridItem xs>
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={
														<Check
															className={
																classes.checkedIcon
															}
														/>
													}
													icon={
														<Check
															className={
																classes.uncheckedIcon
															}
														/>
													}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.reciprocal}
													onChange={this.handleChecked(
														"reciprocal"
													)}
													disabled={!this.props.hasWrite}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Add Reciprocal Address (valid with shipper/consignee type only)"
										/>
									</GridItem>}
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<h5>Contact Information</h5>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel
											htmlFor="contact_name"
											className={classes.label}
										>
											Contact Name
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												id: "contact_name",
												name: "contact_name",
												value: this.state.contact_name,
												onChange: this.handleInput(
													"contact_name"
												),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="phone" className={classes.label}>
											Phone
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												id: "phone",
												name: "phone",
												value: this.state.phone,
												onChange: this.handleInput("phone"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="email" className={classes.label}>
											Email
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												id: "email",
												name: "email",
												value: this.state.email,
												onChange: this.handleInput("email"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<InputLabel htmlFor="fax" className={classes.label}>
											Fax
										</InputLabel>
										<br />
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												id: "fax",
												name: "fax",
												value: this.state.fax,
												onChange: this.handleInput("fax"),
												readOnly: !this.props.hasWrite,
											}}
											white
										/>
									</GridItem>

									{this.state.type == 'consignee' && (
										<React.Fragment>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<WithTooltip
													title="Automatically Send Public Tracking Link"
													content="If this is checked, when the public tracking link becomes available, the consignee email address and any additional contacts will be sent an email with the tracking link when it becomes available. Note: This can be overridden on any given bill"
												>
												<FormControlLabel
														control={
															<Checkbox
																tabIndex={-1}
																checkedIcon={
																	<Check
																		className={
																			classes.checkedIcon
																		}
																	/>
																}
																icon={
																	<Check
																		className={
																			classes.uncheckedIcon
																		}
																	/>
																}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
																checked={this.state.auto_tracking}
																onChange={this.handleChecked(
																	"auto_tracking"
																)}
																disabled={!this.props.hasWrite}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Automatically Send Public Tracking Link"
													/>
												</WithTooltip>
											</GridItem>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<AdditionalContacts
													handleTrackingEmailInput={this.handleTrackingEmailInput}
													removeTrackingEmail={this.removeTrackingEmail}
													additional_tracking_emails={this.state.additional_tracking_emails}
													updating_tracking={false}
												/>
											</GridItem>
										</React.Fragment>
									)}
								</GridContainer>
							</CardBody>
						</Card>
						<Card style={{marginTop:'10px', marginBottom:'10px'}}>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<h5><b>LTL Settings</b></h5>
									</GridItem>
								</GridContainer>
								{!!this.state.accessorials.length &&
									<GridContainer>
										<GridItem xs={12}>
											<WithTooltip
												title="Auto-Select Accessorials"
												content="Accessorials selected here will automatically be checked on the bill of lading page when using this location"
											>
												<h5>Auto-Select Accessorials <HelpOutlined fontSize='small'/></h5>
											</WithTooltip>
											<AccessorialsSelect
												accessorials={this.getAccessorials()}
												disabled={this.state.accsloading}
												handleChecked={(key, event) => this.handleAccChecked(key, event)}
											/>
										</GridItem>
									</GridContainer>
								}
							</CardBody>
						</Card>

						<Card style={{marginTop:'10px', marginBottom:'10px'}}>
							<CardBody>
								<GridContainer>
									<GridItem>
										<h5><b>Location Note</b></h5>
									</GridItem>
									<GridContainer style={{margin:'3px'}}>
										<GridItem xs={6}>
											<WithTooltip
												title="Internal Location Note"
												content="This note is displayed to the user when they choose this address from the Address Book while creating an LTL Bill of Lading. The user will be required to dismiss the note before proceeding"
											>
												<h5>Internal: <HelpOutlined fontSize='small'/></h5>
											</WithTooltip>
											<TextField
												fullWidth
												minRows="6"
												variant="outlined"
												size="large"
												multiline
												inputProps={{
													maxLength: 500,
													type: "text",
													id: "internal_note",
													name: "internal_note",
													value: this.state.internal_note,
													onChange: this.handleInput("internal_note"),
												}}
											/>
										</GridItem>
										<GridItem xs={6}>
											<WithTooltip
												title="External Location Note"
												content="This note will be appended to the existing Special Instructions input when a user chooses this address from the Address Book while creating an LTL Bill of Lading"
											>
												<h5>External: <HelpOutlined fontSize='small'/></h5>
											</WithTooltip>
											<TextField
												fullWidth
												minRows="6"
												variant="outlined"
												size="large"
												multiline
												inputProps={{
													maxLength: 500,
													type: "text",
													id: "external_note",
													name: "external_note",
													value: this.state.external_note,
													onChange: this.handleInput("external_note"),
												}}
											/>
										</GridItem>
									</GridContainer>
								</GridContainer>
							</CardBody>
						</Card>

						{this.props.hasParcelEnabled && (
							<Card style={{marginTop:'10px', marginBottom:'10px'}}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<h5><b>Parcel Settings</b></h5>
										</GridItem>
										<GridContainer>
											{this.state.type == 'shipper' && (
												<>
													<GridItem xs={1}></GridItem>
													<GridItem xs={10} className={classes.hazmat} style={{paddingTop: '5px', paddingBottom: '5px'}}>
														<GridContainer>
															<GridItem xs={12}>
																<b>Hazardous Options</b>
															</GridItem>
															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="Offeror" className={classes.label}>
																	Offeror
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "offeror",
																		name: "offeror",
																		value: this.state.parcel.offeror,
																		onChange: this.handleInput("parcel.offeror"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="Emergency Phone" className={classes.label}>
																	Emergency Phone
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "emergency_phone",
																		name: "emergency_phone",
																		value: this.state.parcel.emergency_phone,
																		onChange: this.handleInput("parcel.emergency_phone"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="International Emergency Phone" className={classes.label}>
																	International Emergency Phone
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "intl_emergency_phone",
																		name: "intl_emergency_phone",
																		value: this.state.parcel.intl_emergency_phone,
																		onChange: this.handleInput("parcel.intl_emergency_phone"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>

															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="Emergency Response Registrant #" className={classes.label}>
																	Emergency Response Registrant #
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "emergency_response_registrant",
																		name: "emergency_response_registrant",
																		value: this.state.parcel.emergency_response_registrant,
																		onChange: this.handleInput("parcel.emergency_response_registrant"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="Signatory Contact Name" className={classes.label}>
																	Signatory Contact Name
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "signatory_contact_name",
																		name: "signatory_contact_name",
																		value: this.state.parcel.signatory_contact_name,
																		onChange: this.handleInput("parcel.signatory_contact_name"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="Signatory Contact Title" className={classes.label}>
																	Signatory Contact Title
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "signatory_contact_title",
																		name: "signatory_contact_title",
																		value: this.state.parcel.signatory_contact_title,
																		onChange: this.handleInput("parcel.signatory_contact_title"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={4} lg={3}>
																<InputLabel htmlFor="Signatory Place" className={classes.label}>
																	Signatory Place
																</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{ fullWidth: true }}
																	inputProps={{
																		type: "text",
																		id: "signatory_place",
																		name: "signatory_place",
																		value: this.state.parcel.signatory_place,
																		onChange: this.handleInput("parcel.signatory_place"),
																		readOnly: !this.props.hasWrite,
																	}}
																	white
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem xs={1}></GridItem>
												</>
											)}
											<GridItem xs={1}></GridItem>
											<GridItem xs={10} style={{paddingTop: '5px', paddingBottom: '5px'}}>
												<GridContainer>
													<GridItem xs={12}>
														<b>Special Services</b>
													</GridItem>
													{this.state.type == 'consignee' && (
													<GridItem xs={4}>
														<FormControlLabel
															control={
																<Checkbox
																	tabIndex={-1}
																	checkedIcon={<Check className={classes.checkedIcon} />}
																	icon={<Check className={classes.uncheckedIcon} />}
																	classes={{
																		checked: classes.checked,
																		root: classes.checkRoot
																	}}
																	checked={this.state.parcel.residential}
																	onChange={this.handleChecked("parcel.residential")}
																/>
															}
															classes={{ label: classes.label }}
															label="This is a residential  address"
														/>
													</GridItem>
													)}
													{this.state.type == 'shipper' && (
														<>
															<GridItem xs={4}>
																<GridContainer>
																	<GridItem>
																		<FormControlLabel
																			control={
																				<Checkbox
																					tabIndex={-1}
																					checkedIcon={<Check className={classes.checkedIcon} />}
																					icon={<Check className={classes.uncheckedIcon} />}
																					classes={{
																						checked: classes.checked,
																						root: classes.checkRoot
																					}}
																					checked={this.state.parcel.cod}
																					onChange={this.handleChecked("parcel.cod")}
																				/>
																			}
																			classes={{
																				label: classes.label
																			}}
																			label="C.O.D."
																		/>
																		{this.state.parcel.cod && (
																			<GridItem xs={12}>
																				<InputLabel className={classes.label}>C.O.D. Collection Type</InputLabel>
																				<br />
																				<FormControl fullWidth className={classes.selectFormControl}>
																					<Select
																						MenuProps={{ className: classes.selectMenu }}
																						classes={{
																							select: classes.select
																						}}
																						value={this.state.parcel.cod_collection_type || ''}
																						inputProps={{ name: "parcel.cod_collection_type" }}
																						onChange={this.handleParcelChange}
																					>
																						<MenuItem
																							classes={{
																								root: classes.selectMenuItem,
																								selected: classes.selectMenuItemSelected
																							}}
																							value="ANY"
																						>
																							Any (Unsecured Payment)
																						</MenuItem>
																						<MenuItem
																							classes={{
																								root: classes.selectMenuItem,
																								selected: classes.selectMenuItemSelected
																							}}
																							value="GUARANTEED_FUNDS"
																						>
																							Guaranteed Funds (Secured Payment)
																						</MenuItem>
																					</Select>
																				</FormControl>
																			</GridItem>
																		)}
																	</GridItem>
																</GridContainer>
															</GridItem>
															<GridItem xs={4}>
																<GridContainer>
																	<GridItem xs={12}>
																		<FormControlLabel
																			control={
																				<Checkbox
																					tabIndex={-1}
																					checkedIcon={<Check className={classes.checkedIcon} />}
																					icon={<Check className={classes.uncheckedIcon} />}
																					classes={{
																						checked: classes.checked,
																						root: classes.checkRoot
																					}}
																					checked={this.state.parcel.signature}
																					onChange={this.handleChecked("parcel.signature")}
																				/>
																			}
																			classes={{ label: classes.label }}
																			label="Require Signature"
																		/>
																		{this.state.parcel.signature && (
																			<GridItem xs={12}>
																				<InputLabel className={classes.label}>Signature Type</InputLabel>
																				<br />
																				<FormControl fullWidth className={classes.selectFormControl}>
																					<Select
																						MenuProps={{ className: classes.selectMenu }}
																						classes={{
																							select: classes.select
																						}}
																						value={this.state.parcel.signature_type || ''}
																						inputProps={{ name: "parcel.signature_type" }}
																						onChange={this.handleParcelChange}
																					>
																						<MenuItem
																							classes={{
																								root: classes.selectMenuItem,
																								selected: classes.selectMenuItemSelected
																							}}
																							value="INDIRECT"
																						>
																							Indirect Signature Required
																						</MenuItem>
																						<MenuItem
																							classes={{
																								root: classes.selectMenuItem,
																								selected: classes.selectMenuItemSelected
																							}}
																							value="DIRECT"
																						>
																							Direct Signature Required
																						</MenuItem>
																						<MenuItem
																							classes={{
																								root: classes.selectMenuItem,
																								selected: classes.selectMenuItemSelected
																							}}
																							value="ADULT"
																						>
																							Adult Signature Required
																						</MenuItem>
																					</Select>
																				</FormControl>
																			</GridItem>
																		)}
																	</GridItem>
																</GridContainer>
															</GridItem>
														<GridItem xs={12}>
															<b>Default Carrier and Service</b>
														</GridItem>
														<GridItem xs={12}>
															<ParcelServices
																accessibility={""}
																dg_enabled={false}
																warehouse={this.state.warehouse}
																carriers={this.state.parcel.carriers}
																carrier={this.state.parcel.carrier}
																services={this.state.parcel.services}
																service={this.state.parcel.service}
																onChange={this.handleParcelChange}
															/>
														</GridItem>
													</>
													)}
												</GridContainer>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{!_.isEmpty(this.state.log) && Array.isArray(this.state.log.actions) && this.state.log.actions.length ? (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<h5>Log Information</h5>
											<GridContainer>
												{this.state.log.actions.map((prop, key) => {
													return (
														<GridItem xs={12} key={key} style={{ marginBottom: "10px" }}>
															<Paper className={classes.paper} elevation={1}>
																<b>{prop.username}</b><br />
																<i>{prop.timestamp}</i><br />
																<span><pre> - {prop.action}</pre></span>
															</Paper>
														</GridItem>
													);
												})}
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						) : null}
					</GridContainer>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	const isAdminUser = userIsAdmin(state) && !adminInUserSession(state);
	return {
		hasWrite: hasPermission("ADDRESS_BOOK_WRITE")(state) || isAdminUser,
		isAdmin: isAdminUser,
		hasParcelEnabled: hasPermission("USE_PARCEL_RATING")(state) || hasPermission("USE_PARCEL_SHIPPING")(state) || isAdminUser,
	};
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	handleMessages,
    	setNotification,
    	loadAutoSelectAccessorialMaps,
		loadParcelData,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(newProductStyle)(EditAddress));