import {
	uiColors,
	makeBoxShadow,
	whiteColor,
	blackColor,
	grayColor,
	hexToRgb
} from "assets/jss/empire-tms.jsx";

const customSelectStyle = {
	select: {
		padding: "7px 0 7px 0",
		fontSize: ".75rem",
		fontWeight: "400",
		lineHeight: "1.42857",
		textDecoration: "none",
		textTransform: "uppercase",
		backgroundColor: "transparent",
		color: grayColor[14],
		borderBottom: "1px solid",
		borderBottomColor: grayColor[3],
		// color: grayColor[2],
		letterSpacing: "0",
		"&:focus": {
			// backgroundColor: "transparent"
		},
		"&[aria-owns] + input + svg": {
			transform: "rotate(180deg)"
		},
		"& + input + svg": {
			transition: "all 300ms linear"
		}
	},
	greenIcon: {
		color:"#4caf50",
		fontSize:"2.5rem",
	},
	yellowIcon: {
		color:"#ff9800",
		fontSize:"2.5rem",
	},
	redIcon: {
		color:"#ff9800",
		fontSize:"2.5rem",
	},
	requiredSelect: {
		backgroundColor: uiColors.yellow.lightest,
		borderBottom: "2px solid",
		borderBottomColor: uiColors.warning.darkest,
		color: grayColor[7],
		"&:after": {
			color: grayColor[2],
			borderBottom: "3px solid",
			borderBottomColor: uiColors.warning.darkest,
		},
	},
	selectFormControl: {
		margin: "0 0 0 0",
		//padding: "1px 0 13px 0",
		"& > div": {
			"&:before": {
				borderBottom: "none !important"
			},
			"&:after": {
				borderBottom: "none !important"
			}
		}
	},
	selectLabel: {
		// fontSize: "12px",
		// textTransform: "uppercase",
		color: grayColor[2] + " !important",
		zIndex: "2"
		// top: "16px"
	},
	selectMenu: {
		"& > div > ul": {
			border: "0",
			padding: "5px 0",
			margin: "0",
			boxShadow: "none",
			minWidth: "100%",
			borderRadius: "4px",
			boxSizing: "border-box",
			display: "block",
			fontSize: "14px",
			textAlign: "left",
			listStyle: "none",
			backgroundColor: whiteColor,
			// backgroundColor: grayColor[13],
			backgroundClip: "padding-box"
		},
		"& $selectPaper $selectMenuItemSelectedMultiple": {
			backgroundColor: "inherit"
		},
		"& > div + div": {
			maxHeight: "730px !important"
		}
	},
	selectMenuItem: {
		fontSize: "13px",
		// padding: "10px 20px",
		margin: "0 5px",
		borderRadius: "2px",
		transition: "all 150ms linear",
		display: "block",
		clear: "both",
		fontWeight: "400",
		lineHeight: "2",
		whiteSpace: "nowrap",
		color: grayColor[7],
		paddingRight: "30px",
		"&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.warning.default, // added
			...makeBoxShadow(uiColors.warning.default) // added
		}
	},
	selectMenuItemSelected: {
		backgroundColor: uiColors.warning.default + "!important",
		color: whiteColor
	},
	selectMenuItemSelectedMultiple: {
		backgroundColor: "transparent !important",
		"&:hover": {
			color: whiteColor,
			"&:after": {
				color: whiteColor
			},
			backgroundColor: uiColors.warning.default + "!important",
			...makeBoxShadow(uiColors.warning.default) // added
		},
		"&:after": {
			top: "16px",
			right: "12px",
			width: "12px",
			height: "5px",
			borderLeft: "2px solid currentColor",
			transform: "rotate(-45deg)",
			opacity: "1",
			color: grayColor[2],
			position: "absolute",
			content: "''",
			borderBottom: "2px solid currentColor",
			transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
		}
	},
	selectPaper: {
		boxSizing: "borderBox",
		borderRadius: "4px",
		padding: "0",
		minWidth: "100%",
		display: "block",
		border: "0",
		boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
		backgroundClip: "padding-box",
		margin: "2px 0 0",
		fontSize: "14px",
		textAlign: "left",
		listStyle: "none",
		backgroundColor: "transparent",
		maxHeight: "266px"
	},
	selectMenuItemParent: {
		fontWeight: "700"
	},
};

export default customSelectStyle;
