import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import qs from "qs";
import _ from "lodash";
import axios from "~/variables/axios.jsx";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath } from "~/variables/server.jsx";
import { getCustomTableHeaders, getPages } from "~/helpers.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import SearchModal from "~/components/Search/components/Search.jsx";
import Favorites from "~/components/Favorites/Favorites.jsx";

// style
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Carriers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			alert: null,
			redirect: null,
			user: null,
			body: null,
			columns: [
				{ Header: "ID", accessor: "id", colDisabled: false },
				{ Header: "SCAC", accessor: "scac", colDisabled: false },
				{ Header: "Carrier Name", accessor: "carrier", colDisabled: false },
				{ Header: "800 #", accessor: "phone", colDisabled: false },
				{ Header: "Min. Cubic Ft.", accessor: "max_cube", colDisabled: false },
				{ Header: "Min. PCF", accessor: "min_pcf", colDisabled: false },
				{ Header: "API Tracking", accessor: "api_tracking", colDisabled: false },
				{ Header: "P44 Tracking", accessor: "p44_api_tracking", colDisabled: false },
				{ Header: "API Pickup", accessor: "api_pickup", colDisabled: false },
				{ Header: "P44 Pickup", accessor: "p44_api_pickup", colDisabled: false },
				{ Header: "Auto PRO", accessor: "auto_pro", colDisabled: false },
				{ Header: "Actions", accessor: "actions", colDisabled: false }
			],
			customColumns: [],
			searchFields: [
				{ name: "Name", value: "scac_master.carrier" },
				{ name: "SCAC", value: "scac_master.scac" },
				{ name: "API Tracking", value: "scac_master.api_tracking" },
				{ name: "P44 Tracking", value: "scac_master.p44_api_tracking" },
				{ name: "API Pickup", value: "scac_master.api_pickup" },
				{ name: "P44 Pickup", value: "scac_master.p44_api_pickup" },
				{ name: "Auto PRO", value: "scac_master.auto_pro" },
			],
			favorites: [],
			filters: [
				{
					field: "scac_master.carrier",
					compare: "starts with",
					value: ""
				}
			]
		};

		this.setHeaders = this.setHeaders.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleDeleteCarrier = this.handleDeleteCarrier.bind(this);
		this.deleteCarrier = this.deleteCarrier.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		const filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);
		if(filters.length) {
			this.handleSearch(filters);
			return;
		}
		
		try {
			let favoriteResults = await this.getFavorites();
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=scacMaster");
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					favorites: favoriteResults,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the carriers!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the carriers!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	setHeaders(customColumns) {
		this.setState({ customColumns });
	}

	getColumns(data) {
		let favorites = this.state.favorites.map(favorite => favorite.id);
		const { customColumns, columns, filters } = this.state;
		let cols = customColumns.length ? customColumns : columns;
		return data.map((prop, key) => {
			return cols.map((col) => {
				const disabled = col.colDisabled === "false" ? false : (!col.colDisabled ? false : true);
				if (!disabled) {
					switch (col.accessor) {
						case "actions":
							return (
								<div>
									<Favorites
										id={prop.id}
										favorites={favorites}
										filters={filters}
										tableName={'scac_master'}
										favoriteItem={this.favoriteItem.bind(this)}
									/>
									<NavLink to={basePath + "/admin/carrier/edit/" + prop.id}>
										<Button size="sm" color="info">
											View/Edit
										</Button>
									</NavLink>
									<Button size="sm" color="danger" style={{ marginLeft: "5px" }} onClick={() => this.handleDeleteCarrier(prop.id)}>
										Delete
									</Button>
								</div>
							);
						case "api_tracking":
						case "p44_api_tracking":
						case "api_pickup":
						case "p44_api_pickup":
						case "auto_pro":
						case "feed_status":
							return prop[col.accessor] == 1 || prop[col.accessor] == "On" ? <Check /> : "";
						default:
							return prop[col.accessor];
					}
				}
			});
		});
	}

	async favoriteItem(favoriteResults) {
		const { filters } = this.state;
		favoriteResults.doSearch ? this.doSearch(true, filters) : this.setState({favorites: favoriteResults.favorites});
	}

	saveCustomColumns(columnResult) {
		this.setState({customColumns: columnResult});
	}

	getCustomColumns(columnResult) {
		this.setState({customColumns: columnResult});
	}

	async getFavorites() {
		let favorites = await axios.get("/index.php?p=api&r=json&c=admin&m=getFavorites&table_name=scac_master");
		let favoriteResults = [];
		if (typeof favorites !== "string" && !_.isEmpty(favorites.data) && !_.isEmpty(favorites.data.message)) {
			this.props.handleMessages(favorites);
		}
		if (typeof favorites.data !== "string" && !_.isEmpty(favorites.data.body) && favorites.data.body.favorites) {
			favorites.data.body.favorites.forEach((item) => {
				item.favorite_item = true;
				favoriteResults.push(item);
			});
		}

		return favoriteResults;
	}

	async handlePageClick(page) {
		this.setState({
			show: false,
			loading: true
		});
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=scacMaster&d=" + page + "/20");
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the carriers!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the carriers!", {
				variant: "error"
			});
		}
	}

	async clearFilters() {
		this.setState({
			show: false,
			loading: true,
			filters: [
				{
					field: "scac_master.carrier",
					compare: "starts with",
					value: ""
				}
			]
		});
		const url = "/index.php?p=api&r=json&c=admin&m=scacMaster";
		try {
			let favoriteResults = await this.getFavorites();
			this.setState({
				favorites: favoriteResults
			});
			const response = await axios.get(url);
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the carriers!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the carriers!", {
				variant: "error"
			});
		}
	}

    async handleSearch(filters) {
        this.setState({
            show: false,
            loading: true,
			filters: filters
        });

        let searchResult = await this.doSearch(false, filters);

		if(searchResult) {
			await this.doSearch(true, filters);
			this.setState({
				show: true,
        	});
		}
		this.setState({
			loading: false
		});

    }

    async doSearch(favorites = false, filters) {
		let isEmpty = false;

		filters.forEach(filter => {
			if (!filter.value.trim()) isEmpty = true;
		});

		if (isEmpty) {
			this.setState({show: true});
			this.props.setNotification("Search values are required", { variant: "error" });
			return false;
		}

		const data = { queries: filters };
		let url = "/index.php?p=api&r=json&c=search&m=carrier";
		if(favorites == true) {
			url = url + "&favorites=true";
		}

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user)) {
				if(favorites == true) {
					this.setState({
						favorites: response.data.body,
					});
				} else {
					const body = {
						result: response.data.body,
						current: 1,
						last: 1,
						pages: []
					};
					this.setState({
						body,
						user: response.data.user
					});
				}
				return true;
			} else {
				this.props.setNotification("There was an error searching for carriers!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching for carriers!", { variant: "error" });
		}
        return false;
    }

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.handleSearch();
		}
	}

	handleDeleteCarrier(id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this carrier?"
					onConfirm={() => this.deleteCarrier(id)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async deleteCarrier(id) {
		const url = "/index.php?p=api&r=json&c=admin&m=deleteScac&d=" + id;
		this.setState({ show: false, loading: true, alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const res = await axios.get("/index.php?p=api&r=json&c=admin&m=scacMaster&d=" + this.state.body.current + "/20");
				if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
					this.props.handleMessages(res.data.message);
				}
				if (typeof res.data !== "string" && !_.isEmpty(res.data.body)) {
					this.setState({ show: true, loading: false, body: res.data.body });
				} else {
					this.setState({ show: true, loading: false });
					this.props.setNotification("There was an error getting the carriers!", { variant: "error" });
				}
			} else {
				this.setState({ show: true, loading: false });
				this.props.setNotification("There was an error removing the carrier!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ show: true, loading: false });
			this.props.setNotification("There was an error removing the carrier!", { variant: "error" });
		}
	}

	render() {
		const { classes } = this.props;
		const { customColumns, columns} = this.state;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<Grid container>
								<GridItem xs={3} sm={3} md={3} className={classes.left}>
									<ColumnListLayoutModal
										buttonType="primary"
										type={"carriers"}
										fetchColumns={(items) => this.getCustomColumns(items)}
										listColumns={this.state.columns}
										customListColumns={this.state.customColumns}
										updateCols={() => {}}
										saveCustomColumns={(items) => this.saveCustomColumns(items)}
										applyCustomColumns={(items) => this.setHeaders(items)}
									/>

									<SearchModal
										fields={this.state.searchFields}
										onClear={this.clearFilters}
										onSearch={this.handleSearch}
										loading={false}
									/>
								</GridItem>
								<Grid item xs={6} sm={6} md={6} className={classes.center}>
									<Pagination pages={getPages(this.state.body, this.handlePageClick)} />
								</Grid>
								<GridItem xs={3} sm={3} md={3} className={classes.right}>
									<NavLink to={basePath + "/admin/carrier/new"}>
										<Button color="linkedin">New SCAC</Button>
									</NavLink>
								</GridItem>
							</Grid>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} sm={12} md={12} className={classes.center}>
									{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.result) ?
										(this.state.body.current == 1 ?
											<Table tableHead={getCustomTableHeaders(customColumns, columns)} tableFavorites={this.getColumns(this.state.favorites)} tableData={this.getColumns(this.state.body.result)} />
										:
											<Table tableHead={getCustomTableHeaders(customColumns, columns)} tableData={this.getColumns(this.state.body.result)} />
										)
									:
										<Badge color="info">No carriers</Badge>}
									</Grid>
								) : (
									<Spinner loading={this.state.loading} message="Failed to retrieve carriers from the server" />
								)}
							</Grid>
							<Grid container>
								<Grid item xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={getPages(this.state.body, this.handlePageClick)} />
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		searchFilters: state.Search.filters,
	};
};

export default connect(mapStateToProps)(withStyles(style)(withSnackbar(Carriers)));
