import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import { basePath } from "~/variables/server.jsx";

import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class LinearFeetRules extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			rules: [],
			alert: null,
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=linearFeetRules"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user)
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					rules: data.body.rules,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getHeaders() {
		return [
			"ID",
			"Set Name",
			"Carrier",
			"SCAC",
			"Actions"
		];
	}
	getColumns(data) {
		return data.map((prop, key) => {
			const editLink = basePath + "/admin/linear-feet-rule-dates/" + prop.id;
			return [
				prop.id,
				prop.name,
				prop.carrier,
				prop.scac,
				<div className="actions-right">
					<NavLink to={editLink}>
						<Button
							size="sm"
							color="info"
							style={{
								marginRight: "4px"
							}}
						>
							View/Edit
						</Button>
					</NavLink>
					<Button
						size="sm"
						color="danger"
						style={{
							marginRight: "4px"
						}}
						onClick={(e) => {this.deleteRule(prop.id, key)}}
					>
						Delete
					</Button>
				</div>
			];
		});
	}
	async handlePageClick(page) {
		this.setState({
			loading: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=linearFeetRules&d=" + page + "/20"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user)
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	getPages() {
		let current = 1,
			last = 1;

		if (!_.isEmpty(this.state.body)) {
			current = this.state.body.current;
			last = this.state.body.last;
		}

		let pages = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" };

		if (
			last > 1 &&
			!_.isEmpty(this.state.body) &&
			!_.isEmpty(this.state.body) &&
			!_.isEmpty(this.state.body.pages)
		) {
			pages = this.state.body.pages.map(index => {
				let page = { text: index };
				if (index == parseInt(current)) {
					page.active = true;
				} else {
					page.onClick = e => this.handlePageClick(index);
				}
				return page;
			});
		}

		if (current < last) {
			nextPage.onClick = e => this.handlePageClick(parseInt(current) + 1);
		} else {
			nextPage.disabled = true;
		}

		if (parseInt(current) - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(parseInt(current) - 1);
		} else {
			prevPage.disabled = true;
		}

		pages.push(nextPage);
		pages.unshift(prevPage);

		return pages;
	}
	deleteRule(id, index) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this linear foot rule?"
					onConfirm={() => this.handleDeleteRule(id, index)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async handleDeleteRule(id, index) {
		this.setState({alert: null});

		const linearFeetRules = this.state.rules;

		try {
			const response = await axios.get('/index.php?p=api&r=json&c=admin&m=deleteLinearFeetRule&d=' + id);

			if (typeof response.data !== "string") {
				this.props.handleMessages(response);
				linearFeetRules.splice(index, 1);
				this.setState({rules: linearFeetRules});
			} else {
				this.props.setNotification("Unexpected response when attempting to delete the rule!", {
					variant: "error"
				});
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the rule!", {
				variant: "error"
			});
		}
	}
	render() {
		const { classes } = this.props;
		const link = basePath + "/admin/rule-set/new";
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											style={{
												marginRight: "4px"
											}}
										>
											New Rule Set
										</Button>
									</NavLink>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.rules) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(
													this.state.rules
												)}
												customCellClasses={[
													classes.right
												]}
												customClassesForCells={[4]}
												customHeadCellClasses={[
													classes.right
												]}
												customHeadClassesForCells={[4]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{"No rule sets to display"}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve rule sets from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(LinearFeetRules));