import React from "react";
import _ from "lodash";
import axios from "~/variables/axios.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ContactUs extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            routingsContact: "",
            customerServiceContact: "",
            claimsContact: "",
            salesRepContact: "",
            tlContact: "",
            auditContact: "",
	    };
        this.isValidEmail = this.isValidEmail.bind(this);
    }

    isValidEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }



	async componentDidMount() {
		this.setState({loading: true}, async () => {
			const url = "/index.php?p=api&r=json&c=userSettings&m=getSupportContacts"
			try {
				const response = await axios.get(url);
				this.props.handleMessages(response);
				if (response && response.data && response.data.body && !_.isEmpty(response.data.body)) {
					const body = response.data.body;
                    Object.keys(body).forEach(key => {
                        if(this.isValidEmail(body[key])) {
                            body[key] = <a href={"mailto:" + body[key]}>{body[key]}</a>;
                        }
                    })
					this.setState({
                        routingsContact: body.routingsContact || "",
                        customerServiceContact: body.customerServiceContact || "",
                        claimsContact: body.claimsContact || "",
                        salesRepContact: body.salesRepContact || "",
                        tlContact: body.tlContact || "",
                        auditContact: body.auditContact || "",
					});
				} else {
                    this.props.setNotification("Error loading support contacts. Please call us at 1-888-653-1323.", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
                this.props.setNotification("Exception loading support contacts. Please call us at 1-888-653-1323.", { variant: "error" });
			} finally {
				this.setState({loading: false});
			}
		});
	}

	render() {
		const { classes } = this.props;
        let row = 0;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
                            <TableContainer >
                                <Table className={classes.table}>
                                    <TableBody>
                                        {!_.isEmpty(this.state.routingsContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>Routings:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>{this.state.routingsContact}</TableCell>
                                            </TableRow>
                                        )}
                                        {!_.isEmpty(this.state.customerServiceContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>Customer Service:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>{this.state.customerServiceContact}</TableCell>
                                            </TableRow>
                                        )}
                                        {!_.isEmpty(this.state.claimsContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>Claims:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>{this.state.claimsContact}</TableCell>
                                            </TableRow>
                                        )}
                                        {!_.isEmpty(this.state.salesRepContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>Sales Rep:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>{this.state.salesRepContact}</TableCell>
                                            </TableRow>
                                        )}
                                        {!_.isEmpty(this.state.tlContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>Target Truckload:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>{this.state.tlContact}</TableCell>
                                            </TableRow>
                                        )}
                                        {!_.isEmpty(this.state.auditContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>TFM Auditing:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>{this.state.auditContact}</TableCell>
                                            </TableRow>
                                        )}
                                        {_.isEmpty(this.state.routingsContact) &&
                                        _.isEmpty(this.state.customerServiceContact) &&
                                        _.isEmpty(this.state.claimsContact) &&
                                        _.isEmpty(this.state.salesRepContact) &&
                                        _.isEmpty(this.state.auditContact) &&
                                        _.isEmpty(this.state.tlContact) && (
                                            <TableRow key={"contact_" + row++}>
                                                <TableCell align="right" style={{ width: "50%" }}><strong>Email us to set up your support contacts:</strong></TableCell>
                                                <TableCell align="left" style={{ width: "50%" }}>customerservice@targetfmi.com</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(ContactUs);