import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { NavLink } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import _ from "lodash";

import Button from "~/components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table-6";
import Add from "@material-ui/icons/Add";
import { FaAsterisk } from "react-icons/fa";

import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

class WarehouseUnitCatalog extends Component {
	state = {};

	newUnitCell = () => {
		const link = basePath + "/admin/handling-unit/new/" + this.props.warehouseId;
		return (
			<NavLink to={link}>
				<Button 
					color="success" 
					size="sm" 
					justIcon 
					round 
					onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': this.props.warehouseId, 'tab': this.props.currentTab }))}
				>
					<Add/>
				</Button>
			</NavLink>
		)
	}

	unitRow = (prod) => {
		return prod.map((data, key) => {
			const link = basePath + "/admin/handling-unit/edit?d=" + data.id + "/" + this.props.warehouseId;
			return {
				add: "",
				id: data.id,
				label: data.label || "",
				type: data.type || "",
				stack: data.stack == '1' ? "Yes" : "No",
				pieces: data.pieces,
				l: data.length || "",
				w: data.width || "",
				h: data.height || "",
				default: data.default == "1" ? <FaAsterisk /> : "",
				actions: (
					<div className="actions-center">
						<NavLink to={link}>
							<Button size="sm" color="info" className={this.props.classes.marginRight} onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': this.props.warehouseId, 'tab': this.props.currentTab }))}>
								View/Edit
							</Button>
						</NavLink>
						<Button size="sm" color="danger" onClick={() => this.props.handleDelete(data.id, this.props.warehouseId, "unit")}>
							Delete
						</Button>
					</div>
				)
			}
		})
	}

	getUnitTable = (prod) => {
		return (
			<div>
				<ReactTable
					data={this.unitRow(prod)}
					columns={[
						{
							Header: this.newUnitCell,
							accessor: "add",
							width: 50,
							sortable: false,
						},
						{
							Header: "ID",
							accessor: "id",
						},
						{
							Header: "Label",
							accessor: "label",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type={"search"}
										onChange={e => onChange(e.target.value)}
										value={filter ? filter.value : ""}
									/>
								)
							}
						},
						{
							Header: "Type",
							accessor: "type",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type={"search"}
										onChange={e => onChange(e.target.value)}
										value={filter ? filter.value : ""}
									/>
								)
							}
						},
						{
							Header: "Stack",
							accessor: "stack",
							sortable: false,
						},
						{
							Header: "Pieces",
							accessor: "pieces",
							sortable: false,
						},
						{
							Header: "Length",
							accessor: "l",
							sortable: false,
						},
						{
							Header: "Width",
							accessor: "w",
							sortable: false,
						},
						{
							Header: "Height",
							accessor: "h",
							sortable: false,
						},
						{
							Header: "Default",
							accessor: "default",
						},
						{
							Header: "Actions",
							accessor: "actions",
							sortable: false,
							resizable: false,
							headerStyle: {textAlign: 'left'}
						},
						
					]}
					defaultPageSize={20}
					loading={this.props.loading}
					showPaginationTop
					showPaginationBottom
					className={this.props.className || "-striped -highlight"}
				/>
			</div>
		)
	}

	render() {
		return(
			<>
				{this.getUnitTable(this.props.products)}
			</>
		)
	}
}

export default withStyles(style)(withSnackbar(WarehouseUnitCatalog));