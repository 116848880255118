
import React, { Component } from "react";
import axios from "~/variables/axios.jsx";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

//material-ui
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

// style for this view
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";

//icons
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";

import {
    setLookupSearchBy, setLookupReferenceType, setLookupSearchTerms,
    setLookupReferenceTypes, clearParcelLookup, performLookup
} from "../actions/ParcelLookup.jsx";

class ParcelLookup extends Component {


    static defaultProps = {
        referenceTypes: [],
    };

    state = {
        referenceTypes: this.props.referenceTypes || [],
    };

	constructor(props) {
		super(props);
        this.state = {
            search_by: "",
            search_terms: "",
            reference_type: "",
            refTypes: [],
            references: []
        }
		this.handleSearchTerms = this.handleSearchTerms.bind(this);
		this.handleReferenceType = this.handleReferenceType.bind(this);
		this.searchByChange = this.searchByChange.bind(this);
		this.getRefTypes = this.getRefTypes.bind(this);
    }



    searchByChange = (event) => {
        window.localStorage.setItem('searchBy', event.target.value);
        this.props.setLookupSearchBy(event.target.value);
    }

    handleSearchTerms = event => {
        this.props.setLookupSearchTerms(event.target.value);
    };

    handleKeyPress = event => {
		if (event.key === 'Enter') {
            this.props.handleSearch();
		}
    }

    handleReferenceType = (type) => {
        this.props.setLookupReferenceType(type);
        window.localStorage.setItem('referenceType', type);
    }

	getRefTypes(refs) {
		const { classes } = this.props;
		return refs.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.value}
				</MenuItem>
			);
		});
	}

    async componentDidMount() {
        const mostRecentSearchByType = window.localStorage.getItem('searchBy');
		const mostRecentReferenceType = window.localStorage.getItem('referenceType');
        if(!_.isEmpty(mostRecentSearchByType)) {
            this.props.setLookupSearchBy(mostRecentSearchByType);
            if(mostRecentSearchByType == 'customReference' && !_.isEmpty(mostRecentReferenceType)) {
                this.props.setLookupReferenceType(mostRecentReferenceType);
            }
		}
        // reset search terms when switching between archived shipments and parcel scheduler.
        this.props.setLookupSearchTerms("");
    }

    render() {
		const { classes } = this.props;

        let refTypes = this.props.refTypes;
        if(_.isEmpty(refTypes) && !_.isEmpty(this.props.referenceTypes)) {
            refTypes = this.props.referenceTypes;
        }

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Grid container>
                        <Grid item xs style={{ paddingRight: "10px", paddingTop: "7px" }}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={this.props.search_by}
                                    inputProps={{
                                        name: "search_by",
                                        id: "search_by"
                                    }}
                                    onChange={this.searchByChange}
                                >
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="so"
                                    >
                                        Order/SO#
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="customReference"
                                    >
                                        Custom Reference
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="sid"
                                    >
                                        Shipment ID
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="date"
                                    >
                                        Date
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="carrier"
                                    >
                                        Carrier
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="po"
                                    >
                                        PO#
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="dept"
                                    >
                                        Department
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="ref"
                                    >
                                        Ref#
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="shipper_name"
                                    >
                                        Shipper Name
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="shipper_zip"
                                    >
                                        Orig. Zip
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="shipper_city"
                                    >
                                        Orig. City
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="shipper_state"
                                    >
                                        Orig. State
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="consignee_name"
                                    >
                                        Consignee Name
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="consignee_zip"
                                    >
                                        Dest. Zip
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="consignee_city"
                                    >
                                        Dest. City
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="consignee_state"
                                    >
                                        Dest. State
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="status"
                                    >
                                        Status
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {this.props.search_by == 'status' && (
                            <Grid item xs style={{ paddingTop: "7px" }}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        value={this.props.search_terms}
                                        inputProps={{
                                            id: "search_terms",
                                            name: "search_terms",
                                            value: this.props.search_terms || "",
                                            onChange: this.handleSearchTerms,
                                            onKeyPress: this.handleKeyPress
                                        }}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="hold"
                                        >
                                            Hold
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="executed"
                                        >
                                            Executed
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="cancelled"
                                        >
                                            Cancelled
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {this.props.search_by == 'customReference' && (
                            <Grid item xs style={{ paddingTop: "7px" }}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{ select: classes.select }}
                                                value={this.props.reference_type || ""}
                                                onChange={(e) => this.handleReferenceType(e.target.value)}
                                            >
                                                {this.getRefTypes(refTypes)}
                                            </Select>
                                       </FormControl>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                name: "reference_value",
                                                value: this.props.search_terms || "",
                                                onChange: this.handleSearchTerms,
                                                onKeyPress: this.handleKeyPress
                                            }}
                                            white
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Grid>
                        )}
                        {(this.props.search_by != 'status' && this.props.search_by != 'customReference') && (
                            <Grid item xs style={{ paddingTop: "7px" }}>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        type: "text",
                                        id: "search_terms",
                                        name: "search_terms",
                                        value: this.props.search_terms || "",
                                        onChange: this.handleSearchTerms,
                                        onKeyPress: this.handleKeyPress
                                    }}
                                    white
                                />
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            {(this.props.loading ? (
                                <Button size="sm" color="linkedin" style={{ marginLeft: "4px" }}>
                                    <CircularProgress size={16} style={{ color: "white" }} />
                                </Button>
                            ) : (
                                <Button size="sm" color="linkedin" onClick={this.props.handleSearch} style={{ marginLeft: "4px" }}>
                                    <Search /> Search
                                </Button>
                            ))}
                            {(this.search_by !== "so" || !_.isEmpty(this.search_terms)) && (
                                <Button size="sm" color="white" onClick={this.props.clearFilters} style={{ marginLeft: "4px" }}>
                                    <ClearAll /> Clear
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </GridItem>
            </GridContainer>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setLookupSearchBy,
        setLookupReferenceType,
        setLookupSearchTerms,
        setLookupReferenceTypes,
        clearParcelLookup,
        performLookup
    }, dispatch);
}

const mapStateToProps = (state) => {
	return {
		search_by: state.ParcelLookup.search_by,
		search_terms: state.ParcelLookup.search_terms,
		reference_type: state.ParcelLookup.reference_type,
		refTypes: state.ParcelLookup.refTypes,
	};
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(ltlQuoteStyle)((ParcelLookup)));