import {
	uiColors,
	grayColor,
	whiteColor,
	blackColor,
	hexToRgb
} from "assets/jss/empire-tms.jsx";

const navPillsStyle = theme => ({
	root: {
		marginTop: "20px",
		paddingLeft: "0",
		marginBottom: "0",
		overflow: "visible !important"
	},
	flexContainer: {
		[theme.breakpoints.down("xs")]: {
			display: "flex",
			flexWrap: "wrap"
		}
	},
	displayNone: {
		display: "none !important"
	},
	fixed: {
		overflowX: "visible"
	},
	horizontalDisplay: {
		display: "block"
	},
	pills: {
		float: "left",
		position: "relative",
		display: "block",
		borderRadius: "3px",
		minWidth: "100px",
		textAlign: "center",
		transition: "all .3s",
		padding: "10px 15px",
		color: grayColor[6],
		height: "auto",
		opacity: "1",
		maxWidth: "100%",
		margin: "0 5px"
	},
	pillsWhiteText: {
		float: "left",
		position: "relative",
		display: "block",
		borderRadius: "3px",
		minWidth: "100px",
		textAlign: "center",
		transition: "all .3s",
		padding: "10px 15px",
		color: whiteColor,
		height: "auto",
		opacity: "1",
		maxWidth: "100%",
		margin: "0 5px",
	},
	pillsWithIcons: {
		borderRadius: "3px"
	},
	tabIcon: {
		width: "30px",
		height: "30px",
		display: "block",
		margin: "15px 0"
	},
	horizontalPills: {
		width: "100%",
		float: "none !important",
		"& + button": {
			margin: "10px 0"
		}
	},
	labelContainer: {
		padding: "0!important",
		color: "inherit"
	},
	label: {
		lineHeight: "24px",
		textTransform: "uppercase",
		fontSize: "12px",
		fontWeight: "500",
		position: "relative",
		display: "block",
		color: "inherit"
	},
	contentWrapper: {
		marginTop: "20px"
	},
	primary: {
		"&,&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.primary.default,
			boxShadow:
				"0 4px 20px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.14), 0 7px 10px -5px rgba(" +
				hexToRgb(uiColors.primary.default) +
				", 0.4)"
		}
	},
	info: {
		"&,&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.info.default,
			boxShadow:
				"0 4px 20px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.14), 0 7px 10px -5px rgba(" +
				hexToRgb(uiColors.success.default) +
				", 0.4)"
		}
	},
	success: {
		"&,&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.success.default,
			boxShadow:
				"0 2px 2px 0 rgba(" +
				hexToRgb(uiColors.success.default) +
				", 0.14), 0 3px 1px -2px rgba(" +
				hexToRgb(uiColors.success.default) +
				", 0.2), 0 1px 5px 0 rgba(" +
				hexToRgb(uiColors.success.default) +
				", 0.12)"
		}
	},
	warning: {
		"&,&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.warning.default,
			boxShadow:
				"0 4px 20px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.14), 0 7px 10px -5px rgba(" +
				hexToRgb(uiColors.warning.default) +
				", 0.4)"
		}
	},
	danger: {
		"&,&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.danger.default,
			boxShadow:
				"0 4px 20px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.14), 0 7px 10px -5px rgba(" +
				hexToRgb(uiColors.warning.default) +
				", 0.4)"
		}
	},
	rose: {
		"&,&:hover": {
			color: whiteColor,
			backgroundColor: uiColors.rose.default,
			boxShadow:
				"0 4px 20px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.14), 0 7px 10px -5px rgba(" +
				hexToRgb(uiColors.rose.default) +
				", 0.4)"
		}
	},
	linkedin: {
		backgroundColor: uiColors.primary.mid,
		color: whiteColor,
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(uiColors.primary.mid) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(uiColors.primary.mid) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(uiColors.primary.mid) +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: uiColors.primary.mid,
			color: whiteColor,
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(uiColors.primary.mid) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(uiColors.primary.mid) +
				", 0.2)"
		}
	},
	alignCenter: {
		alignItems: "center",
		justifyContent: "center"
	}
});

export default navPillsStyle;