import React, { Component } from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

import WarehouseProductCatalog from "./WarehouseProductCatalog.jsx";
import WarehouseUnitCatalog from "./WarehouseUnitCatalog.jsx";

class WarehouseCatalogs extends Component {
	state = {
		tabIndex: 0,
		products: [],
		currentProdTab: 'ltl',
		alert: null,
		loading: true
	};

	async componentDidMount() {
		await this.getCatalog();
	}

	getCatalog = async () => {
		const {currentProdTab} = this.state;
		const whId = this.props.warehouseId;
		const parcel = currentProdTab == "parcel" ? 1 : 0;
		let products = []
		let url;
		if(currentProdTab == "unit") {
			url =  `/index.php?p=api&r=json&c=handUnit&m=getWarehouseUnitCatalog&wh=${whId}`;
		}else {
			url = `/index.php?p=api&r=json&c=product&m=getWarehouseCatalog&wh=${whId}&parcel=${parcel}`;
		}
		try {
			const response = await axios.get(url);
			if(typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			
			if(typeof response.data !== "string" && !_.isEmpty(response.data.body) && (response.data.body.products || response.data.body.units)) {
				products = response.data.body.products || response.data.body.units;
			}

		} catch(error) {
			console.error(error);
			this.props.setNotification("Error loading warehouse products.", { variant: "error" });
		}
		this.setState({
			products: products,
			loading: false
		})
	}

	handleTabChange = (event, index) => {
		if(index == this.state.tabIndex) return;
		let tab = 'ltl';
		switch (index) {
			case 0:
				tab = 'ltl'
				break;
			case 1:
				tab = 'parcel'
				break;
			case 2:
				tab = 'unit'
				break;
			default:
				break;
		}
		this.setState({ tabIndex: index, currentProdTab: tab, loading: true}, () => {this.getCatalog()});
	}

	handleDelete = (id, warehouse, type) => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title={`Are you sure you want to delete this ${type}?`}
					onConfirm={() => this.deleteProduct(id, warehouse, type)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	deleteProduct = async (id, warehouse, type) => {
		this.setState({ alert: null });
		if (_.isEmpty(id) || _.isEmpty(warehouse)) {
			return;
		}
		try {
			let url;
			if(type == "product") {
				url = "/index.php?p=api&r=json&c=product&m=delete&d=" + id + "/" + warehouse;
			} else {
				url = "/index.php?p=api&r=json&c=handUnit&m=delete&d=" + id + "/" + warehouse;
			}
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.deleted) {
				let products = this.state.products.filter((prop, key) => {
					return prop.id != id;
				});
				this.setState({ products });
			} else {
				this.props.setNotification(
					`There was an error deleting the ${type}.`,
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				`There was an error deleting the ${type}.`,
				{ variant: "error" }
			);
		}
	}

	render() {
		return(
	
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12}>
					<Tabs
						value={this.state.tabIndex}
						onChange={this.handleTabChange}
						textColor="secondary"
						indicatorColor="secondary"
					>
						<Tab label="LTL Product Catalog" />
						<Tab label="Parcel Product Catalog" />
						<Tab label="Handling Unit Catalog" />
					</Tabs>
				</GridItem>
				{(this.state.tabIndex == 0 || this.state.tabIndex == 1)  && (
					<GridItem xs={12}>
						<WarehouseProductCatalog
							products={this.state.products}
							warehouseId={this.props.warehouseId}
							currentTab={this.props.currentTab}
							currentProdTab={this.state.currentProdTab}
							handleDelete={this.handleDelete}
							loading={this.state.loading}
						/>
					</GridItem>
				)}
				{this.state.tabIndex == 2 && (
					<GridItem xs={12}>
						<WarehouseUnitCatalog
							products={this.state.products}
							warehouseId={this.props.warehouseId}
							currentTab={this.props.currentTab}
							handleDelete={this.handleDelete}
							loading={this.state.loading}
						 />
					</GridItem>
				)}
			</GridContainer>
		)
	}
}

export default withStyles(style)(withSnackbar(WarehouseCatalogs));