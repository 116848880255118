const setOpen = val => {
	return {type: "SET_RATE_DETAILS_MODAL_OPEN", val};
}

const setCarrier = val => {
	return {type: "SET_RATE_DETAILS_MODAL_CARRIER", val};
}

export const closeModal = () => {
	return (dispatch) => {
		dispatch(setOpen(false));
	}
}

export const openModal = (carrier) => {
	return (dispatch) => {
		dispatch(setCarrier(carrier));
		dispatch(setOpen(true));
	}
}