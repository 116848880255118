import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomLinearProgress from "~/components/CustomLinearProgress/CustomLinearProgress.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

class ProRanges extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			showEmptyBooks: false,
			shownType: 'general',
			body: null,
			user: null
		};
		this.fetchBooks = this.fetchBooks.bind(this);
		this.setShownType = this.setShownType.bind(this);
		this.toggleEmptyBooks = this.toggleEmptyBooks.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.delete = this.delete.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		this.fetchBooks();
	}
	async fetchBooks() {
		try {
			var url = "/index.php?p=api&r=json&c=admin&m=proRanges";
			if(this.state.showEmptyBooks) {
				url += "&d=showEmptyBooks";
			}
			const response = await axios.get(url);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user)
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getHeaders() {
		const { classes } = this.props;
		var headers = [];
		headers.push("ID");
		headers.push("SCAC");
		headers.push("Carrier");
		if(this.state.shownType === 'warehouse') {
			headers.push("Warehouses");
		} else if(this.state.shownType === 'terminal') {
			headers.push('Terminals');
		}
		headers.push("Start");
		headers.push("End");
		headers.push("Inventory Status");
		headers.push("Carrier Notes");
		headers.push(
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<WithTooltip
						title="Notification Threshold"
						content="When this % of the pro book is consumed, a background process will send an email to the email(s) in the Notification Email field."
					>
						<span>
							Notification Threshold
						</span>
					</WithTooltip>
				</GridItem>
			</GridContainer>
		);
		headers.push("Notification Emails");
		headers.push("Actions");
		return headers;
	}
	getColumns(data) {
		const shownType = this.state.shownType;
		return data.sort(function(a, b){
		    var keyA = a.id,
		        keyB = b.id;
		    if(keyA < keyB) return -1;
		    if(keyA > keyB) return 1;
		    return 0;
		}).map((prop, key) => {
			var cols = [];
			cols.push(prop.id);
			cols.push(prop.scac);
			cols.push(prop.carrier);

			if(shownType === 'warehouse') {
				if(_.isEmpty(prop.warehouses)) {
					cols.push('');
				} else {
					var whDisplay = [];
					for(var key in prop.warehouses) {
						whDisplay.push(<span key={"pr_wh_" + key}>{prop.warehouses[key].company_name} - {prop.warehouses[key].name}<br /></span>);
					}
					cols.push(whDisplay);
				}
			} else if(shownType === 'terminal') {
				cols.push(prop.terminalCode);
			}

			cols.push(prop.start);
			cols.push(prop.end);

			cols.push(<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					{prop.used + " of " + ((prop.end - prop.start) / prop.increment + 1) + " - " + _.round(prop.used_percent) + "%"}
					<br />
					<CustomLinearProgress
						variant="determinate"
						color="success"
						value={100}
						style={{
							width: prop.used_progress.green + "%",
							display: "inline-block",
							marginBottom: "5px"
						}}
					/>
					<CustomLinearProgress
						variant="determinate"
						color="warning"
						value={100}
						style={{
							width: prop.used_progress.yellow + "%",
							display: "inline-block",
							marginBottom: "5px"
						}}
					/>
					<CustomLinearProgress
						variant="determinate"
						color="danger"
						value={100}
						style={{
							width: prop.used_progress.red + "%",
							display: "inline-block",
							marginBottom: "5px"
						}}
					/>
					<CustomLinearProgress
						variant="determinate"
						color="danger"
						value={0}
						style={{
							width: (100 - prop.used_progress.green - prop.used_progress.yellow - prop.used_progress.red) + "%",
							display: "inline-block",
							marginBottom: "5px"
						}}
					/>
					<br />
					<span style={{ textAlign: "center" }}>
                        {prop.used_percent <= 60 && (
                        	<Badge color="success">OK</Badge>
                        )}
                        {prop.used_percent > 60 && prop.used_percent < 85 && (
                        	<Badge color="warning">LOW</Badge>
                        )}
                        {prop.used_percent > 85 && (
                        	<Badge color="danger">ORDER</Badge>
                        )}
                    </span>
				</GridItem>
			</GridContainer>);

			cols.push(!_.isEmpty(prop.carrier_ap_notes)
				? <div dangerouslySetInnerHTML={{ __html: prop.carrier_ap_notes.replace(/(?:\r\n|\r|\n)/g, "<br />") }} />
				: '');

			cols.push(prop.notification_threshold + "%");
			cols.push(prop.notification_email);

			cols.push(<div className="actions-right">
				<NavLink to={basePath + "/admin/pro-range/" + prop.id}>
					<Button
						size="sm"
						color="info"
						style={{
							marginRight: "4px"
						}}
					>
						View/Edit
					</Button>
				</NavLink>
				<Button
					size="sm"
					color="danger"
					style={{
						marginRight: "4px"
					}}
					onClick={() => this.delete(prop.id)}
				>
					Delete
				</Button>
			</div>);

			return cols;

		});
	}
	async delete(id) {
		if(!confirm('Are you sure you want to delete this PRO Block? This action cannot be undone.')) {
			return false;
		}
		this.setState({ loading: true });
		const url = 'index.php?p=api&r=json&c=admin&m=deleteProRange&d=' + id;
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string") {
				this.props.handleMessages(response);
				if(data.body) {
					this.fetchBooks();
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"An error occurred deleting the PRO Book!",
					{
						variant: "error"
					}
				);
			}
		}  catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred deleting the PRO Book!", {
				variant: "error"
			});
		}
	}
	async handlePageClick(page) {
		this.setState({
			loading: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=proRanges&d=" + page + "/20"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user)
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	getPages() {
		let current = 1,
			last = 1;

		if (!_.isEmpty(this.state.body)) {
			current = this.state.body.current;
			last = this.state.body.last;
		}

		let pages = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" };

		if (
			last > 1 &&
			!_.isEmpty(this.state.body) &&
			!_.isEmpty(this.state.body) &&
			!_.isEmpty(this.state.body.pages)
		) {
			pages = this.state.body.pages.map(index => {
				let page = { text: index };
				if (index == parseInt(current)) {
					page.active = true;
				} else {
					page.onClick = e => this.handlePageClick(index);
				}
				return page;
			});
		}

		if (current < last) {
			nextPage.onClick = e => this.handlePageClick(parseInt(current) + 1);
		} else {
			nextPage.disabled = true;
		}

		if (parseInt(current) - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(parseInt(current) - 1);
		} else {
			prevPage.disabled = true;
		}

		pages.push(nextPage);
		pages.unshift(prevPage);

		return pages;
	}
	setShownType(shownType) {
		this.setState({ shownType });
	}
	toggleEmptyBooks() {
		const showEmptyBooks = this.state.showEmptyBooks;
		this.setState({ showEmptyBooks: !showEmptyBooks }, this.fetchBooks);
	}
	render() {
		const { classes } = this.props;
		const link = basePath + "/admin/pro-range/new";
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											style={{
												marginRight: "4px"
											}}
										>
											New Pro Range
										</Button>
									</NavLink>
									<Button
										color={(this.state.showEmptyBooks) ? "linkedin" : "white"}
										style={{
											marginRight: "4px"
										}}
										onClick={() => this.toggleEmptyBooks()}
									>
										Show Empty Books
									</Button>
								</GridItem>
								<GridItem xs={6}>
									<Button
										color={(this.state.shownType === 'general') ? "linkedin" : "white"}
										style={{
											marginRight: "4px"
										}}
										onClick={() => this.setShownType('general')}
									>
										General Use
									</Button>
									<Button
										color={(this.state.shownType === 'warehouse') ? "linkedin" : "white"}
										style={{
											marginRight: "4px"
										}}
										onClick={() => this.setShownType('warehouse')}
									>
										Warehouse Assignment
									</Button>
									<Button
										color={(this.state.shownType === 'terminal') ? "linkedin" : "white"}
										style={{
											marginRight: "4px"
										}}
										onClick={() => this.setShownType('terminal')}
									>
										Terminal Assignment
									</Button>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) &&
										!_.isEmpty(this.state.body.books) &&
										!_.isEmpty(this.state.body.books[this.state.shownType]) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(
													Object.values(this.state.body.books[this.state.shownType])
												)}
												customCellClasses={[
													classes.right
												]}
												customClassesForCells={[7]}
												customHeadCellClasses={[
													classes.right
												]}
												customHeadClassesForCells={[7]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{"No PRO ranges to display"}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve PRO ranges from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(ProRanges));