import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { NavLink } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/core components
import Chip from "@material-ui/core/Chip";

// react-icons
import { FaFax, FaAsterisk } from "react-icons/fa";

// material ui icons
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Add from "@material-ui/icons/Add";

// core components
import Button from "~/components/CustomButtons/Button.jsx";

// react component for creating dynamic tables
import ReactTable from "react-table-6";


// style for this view
import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

class WarehouseLocations extends Component {
	state = {
		alert: null,
	}

	componentDidMount() {
		localStorage.removeItem("tms_wh_mgt_id");
	}

	deleteAddress = async (id) => {
		this.setState({ alert: null });
		await this.props.deleteWarehouseLocation(id);
	}

	handleDeleteAddress = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this address?"
					onConfirm={() => this.deleteAddress(id)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	newAddressCell = () => {
		return (
			<NavLink to={basePath + "/admin/location/new/" + this.props.company + "/" + this.props.warehouseId + "/warehouse"}>
				<Button 
					color="success" 
					size="sm" 
					justIcon 
					round 
					onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': this.props.warehouseId, 'tab': this.props.currentTab }))}
				>
					<Add/>
				</Button>
			</NavLink>
		)
	}

	getLocations = (data) => {
		return data.map((prop, key) => {
			const link = basePath + "/admin/location/edit/" + prop.loc_id + "/" + this.props.warehouseId + "/warehouse";
			return {
				add: "",
				code: prop.code,
				name: prop.name,
				address: (
					<address>
						<div dangerouslySetInnerHTML={{ __html: prop.address1 }} />
						{!_.isEmpty(prop.address2) && <div dangerouslySetInnerHTML={{ __html: prop.address2 }} />}
						<div dangerouslySetInnerHTML={{ __html: prop.city + ", " + prop.state + " " + prop.zip }} />
					</address>
				),
				contact: (
					<div>
						{!_.isEmpty(prop.contact_name) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<Person />} label={prop.contact_name} />
							</div>
						)}
						{!_.isEmpty(prop.phone) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<Phone />} label={prop.phone} />
							</div>
						)}
						{!_.isEmpty(prop.email) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<AlternateEmail />} label={prop.email} />
							</div>
						)}
						{!_.isEmpty(prop.fax) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<FaFax />} label={prop.fax} />
							</div>
						)}
					</div>
				),
				type: prop.type,
				default: prop.default == 1 ? <FaAsterisk /> : "",
				actions: (
					<div className="actions-right">
						<NavLink to={link}>
							<Button size="sm" color="info" className={this.props.classes.marginRight} onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': this.props.warehouseId, 'tab': this.props.currentTab }))}>
								View/Edit
							</Button>
						</NavLink>
						<Button size="sm" color="danger" onClick={() => this.handleDeleteAddress(prop.loc_id)}>
							Delete
						</Button>
					</div>
				)
			};
		});
	}

	render() {
		const { classes } = this.props;
		return(
			<div>
				{this.state.alert}
				<ReactTable
					data={this.getLocations(this.props.locations)}
					columns={[
						{
							Header: this.newAddressCell,
							accessor: "add",
							width: 50,
							sortable: false,
						},
						{
							Header: "Code",
							accessor: "code",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type={"search"}
										onChange={e => onChange(e.target.value)}
										value={filter ? filter.value : ""}
									/>
								)
							}
						},
						{
							Header: "Name",
							accessor: "name",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type={"search"}
										onChange={e => onChange(e.target.value)}
										value={filter ? filter.value : ""}
									/>
								)
							}
						},
						{
							Header: "Address",
							accessor: "address",
						},
						{
							Header: "Contact",
							accessor: "contact",
							sortable: false,
						},
						{
							Header: "Type",
							accessor: "type",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<FormControl
										fullWidth
										className={classes.selectFormControl}
									>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.type}
											inputProps={{
												name: "type",
												id: "type"
											}}
											onChange={e => onChange(e.target.value)}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value=""
											>
												None
											</MenuItem>
											<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="shipper"
												>
													Shipper
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="consignee"
											>
												Consignee
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="bill_to"
											>
												Bill To
											</MenuItem>
										</Select>
									</FormControl>
								)
							}
						},
						{
							
							Header: "Default",
							accessor: "default",
							width: 80,
							sortable: false,
							filterable: true,
							filterMethod: (filter, row) => {
								return filter.value ? row[filter.id] !== "" : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type="checkbox"
										onChange={(e) => onChange(e.target.checked)}
									/>
								)
							}
						},
						{
							Header: "Actions",
							accessor: "actions",
							sortable: false,
						}
					]}
					defaultPageSize={20}
					showPaginationTop
					showPaginationBottom
					className={this.props.className || "-striped -highlight"}
				/>
			</div>
		)
	}
}

export default withStyles(style)(withSnackbar(WarehouseLocations));
