import React, { Component } from "react";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { basePath } from "~/variables/server.jsx";
import { NavLink } from "react-router-dom";

import Button from "~/components/CustomButtons/Button.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';

// material ui icons
import Add from "@material-ui/icons/Add";
import { FaFileInvoice, FaTruckLoading, FaMapMarkerAlt } from "react-icons/fa";


// style for this view
import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

class WarehouseCarriers extends Component {
	state = {
		warehouseCarriers: [],
		companyCarriers: [],
		onEdit: false,
	}

	async componentDidMount() {
		localStorage.removeItem("tms_wh_mgt_id");
		this.sortCarriers();
	}

	sortCarriers = () => {
		const id = this.props.warehouseId;
		let carriers = this.props.carriers;
		let warehouseCarriers = [];
		let companyCarriers = [];
		let newCarrierRow = this.state.warehouseCarriers.find((scac) => scac.onEdit == true) || false;

		if(newCarrierRow) {
			warehouseCarriers.push(newCarrierRow);
		}

		carriers.forEach((carrier, idx) => {
			let found = false;
			carrier.warehouse_ids.forEach(whAccount => {
				if(whAccount.warehouse_name_id === id) {
					carrier.warehouse_acct_id = whAccount.id;
					warehouseCarriers.push(carrier);
					found = true;
					return;
				}
			});
			if(!found) {
				companyCarriers.push(carrier);
			}
		})

		this.setState({
			warehouseCarriers: warehouseCarriers,
			companyCarriers: companyCarriers,
			onEdit: newCarrierRow ? true : false
		});
	}

	addNewCarrierRow = () => {
		this.setState({
			warehouseCarriers:[
				{ onEdit: true},
				...this.state.warehouseCarriers,
			],
			onEdit: true,
		})
	}

	removeNewCarrierRow = (idx) => {
		let { warehouseCarriers } = this.state;
		warehouseCarriers.splice(idx, 1);
		this.setState({
			warehouseCarriers,
			onEdit: false,
		})
	}

	searchCarrier = (e, val, idx) => {
		let { warehouseCarriers, companyCarriers } = this.state;
		if(val !== null) {
			let carrier = companyCarriers.find((scac) => scac.id == val.id) || false;
			if(carrier) {
				carrier = Object.assign(warehouseCarriers[idx], carrier);
				warehouseCarriers[idx] = carrier;
			}
		} else {
			warehouseCarriers[idx] = {onEdit: true};
		}

		this.setState({
			warehouseCarriers,
		});
	};

	async removeCarrierFromWarehouse(idx) {
		let { warehouseCarriers } = this.state;
		const whAcctId = warehouseCarriers[idx].warehouse_acct_id || false;
		const companyCarrierIdx = this.props.carriers.findIndex((carrier) => carrier.id == warehouseCarriers[idx].id);

		this.setState({ alert: null });
		await this.props.removeCarrierFromWarehouse(whAcctId, companyCarrierIdx);
		this.sortCarriers();
	}

	async saveCarrierToWarehouse(idx) {
		let { warehouseCarriers } = this.state;
		const companyAccountId = warehouseCarriers[idx].id || false;
		const whId = this.props.warehouseId;
		const companyCarrierIdx = this.props.carriers.findIndex((carrier) => carrier.id == warehouseCarriers[idx].id);

		await this.props.saveCarrierToWarehouse(companyAccountId, whId, companyCarrierIdx);
		warehouseCarriers[idx].onEdit = false;
		this.setState({ warehouseCarriers });
		this.sortCarriers();
	}

	handleAlert = (idx) => {
		let { warehouseCarriers } = this.state;
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title={`Are you sure you want to remove ${warehouseCarriers[idx].carrier} from this warehouse?`}
					onConfirm={() => this.removeCarrierFromWarehouse(idx)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Confirm"
					cancelBtnText="Cancel"
					showCancel
				>
				</SweetAlert>
			)
		});
	}

	handleMessages(messages) {
		if (!messages) {
			return;
		}
		for (const prop of messages) {
			const { type, message } = prop;
			let variant = "default"; // oneOf(['default', 'error', 'success', 'warning', 'info'])
			switch (type) {
				case "info":
					variant = "info";
					break;
				case "success":
					variant = "success";
					break;
				case "warning":
					variant = "warning";
					break;
				case "danger":
				case "error":
					variant = "error";
					break;
			}
			this.props.enqueueSnackbar(<span dangerouslySetInnerHTML={{ __html: message }} />, {
				variant,
				autoHideDuration: 5000
			});
		}
	}

	render() {
		return(
			<TableContainer component={Paper}>
				{this.state.alert}
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
							{!this.state.onEdit && !_.isEmpty(this.state.companyCarriers) && (
								<Button color="success" size="sm" justIcon round onClick={() => this.addNewCarrierRow()}>
									<Add/>
								</Button>
							)}
							</TableCell>
							<TableCell>ID</TableCell>
							<TableCell>Carrier</TableCell>
							<TableCell>User</TableCell>
							<TableCell>Pass</TableCell>
							<TableCell>Acct. #</TableCell>
							<TableCell>License Key</TableCell>
							<TableCell>Meter #</TableCell>
							<TableCell>P44 Code</TableCell>
							<TableCell>P44 Enabled</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.warehouseCarriers.map((scac, key) => {
							return(
								<CarrierRow 
									key={key}
									idx={key}
									scac={scac}
									company={this.props.company}
									warehouse={this.props.warehouseId}
									onEdit={this.props.onEdit}
									companyCarriers={this.state.companyCarriers}
									currentTab={this.props.currentTab}
									searchCarrier={(e, val) => this.searchCarrier(e, val, key)}
									removeNewCarrierRow={() => this.removeNewCarrierRow(key)}
									handleAlert={() => this.handleAlert(key)}
									saveCarrierToWarehouse={() => this.saveCarrierToWarehouse(key)}
								/>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}

class CarrierRow extends Component {

	render() {
		const { scac, company, warehouse, companyCarriers, idx, currentTab } = this.props;
		const link = basePath + "/admin/company-" + company + "/carrier/edit/" + scac.id;
		let licenseKey = scac.license_key || '';
		let meterNum = scac.meter_number || '';

		if(licenseKey.length > 10) licenseKey = licenseKey.substring(0, 10) + '...';
		if(meterNum.length > 10) meterNum = meterNum.substring(0, 10) + '...';

		return(
			<TableRow style={{ verticalAlign: 'baseline'}}>
				<TableCell></TableCell>
				<TableCell>{scac.id}</TableCell>
				{!scac.onEdit ? (
					<TableCell>{scac.carrier}</TableCell>
				):(
					<TableCell>
						<Autocomplete
							id={"new_carrier_" + idx}
							style={{ width: "100%" }}
							options={companyCarriers}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option.carrier}
							isOptionEqualToValue={(option, selected) => option.id === selected.id}
							autoComplete
							disabled={_.isEmpty(companyCarriers)}
							includeInputInList
							onChange={this.props.searchCarrier}
							renderInput={(params) => (
								<TextField {...params} label="Company Carriers" variant="standard" />
							)}
						/>
					</TableCell>
				)}
				<TableCell>{scac.user}</TableCell>
				<TableCell>{scac.pass}</TableCell>
				<TableCell>{scac.edi_account_reference}</TableCell>
				<TableCell>{licenseKey}</TableCell>
				<TableCell>{meterNum}</TableCell>
				<TableCell>{scac.p44_account_group_code}</TableCell>
				<TableCell>
					{scac.p44_api_pickup == 1 ? <span title="Pickups"><FaTruckLoading size={25} /></span> : ''}
					{scac.p44_api_tracking == 1 ? <span title="Tracking"><FaMapMarkerAlt size={25} /></span> : ''}
					{scac.p44_api_images == 1 ? <span title="Images"><FaFileInvoice size={25} /></span> : ''}
				</TableCell>
				<TableCell>
					{!scac.onEdit ? (
						<ButtonGroup variant="text">
							<Button
								color="info"
								size="sm"
								component={NavLink}
								to={link}
								onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': warehouse, 'tab': currentTab }))}
							>
								View/Edit
							</Button>
							<Button 
								size="sm" 
								color="danger"
								onClick={() => this.props.handleAlert()}
							>
								Remove
							</Button>
						</ButtonGroup>
					) : (
						<ButtonGroup variant="text">
							{!_.isEmpty(scac.id) && (
								<Button 
									size="sm" 
									color="success"
									onClick={() => this.props.saveCarrierToWarehouse()}
								>
									Save
								</Button>
							)}
							<Button
								size="sm" 
								color="warning" 
								onClick={() => this.props.removeNewCarrierRow()}
							>
								Cancel
							</Button>
						</ButtonGroup>
					)}
				</TableCell>
			</TableRow>
		);
	}
}

export default withStyles(style)(withSnackbar(WarehouseCarriers));