import React from "react";
import qs from "qs";
import { NavLink } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import ClearAll from "@material-ui/icons/ClearAll";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

function Transition(props) {
	return <Slide direction="down" {...props} />;
}

class MonitoredPros extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			company: "",
			tracking_code: "",
			all_updates: false,
			monitorEmailsModal: false,
			emails: [],
			key: null,
			filters: [
				{
					field: "pro",
					compare: "starts with",
					value: ""
				}
			],
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.getMonitoredPros = this.getMonitoredPros.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.getEmails = this.getEmails.bind(this);
		this.handleEmail = this.handleEmail.bind(this);
		this.addEmail = this.addEmail.bind(this);
		this.removeEmail = this.removeEmail.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
		this.getFilters = this.getFilters.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.removeFilter = this.removeFilter.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleFilters = this.handleFilters.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=tracking&m=monitoredPros"
			);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.result) &&
				this.state.mounted
			) {
				this.setState({
					show: true,
					loading: false,
					body: response.data.body,
					user: response.data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	async handlePageClick(page) {
		let url = "/index.php?p=api&r=json&c=tracking&m=monitoredPros&d=" + page;
		url += '&filters=' + encodeURIComponent(JSON.stringify(this.state.filters));
		this.setState({
			show: false,
			loading: true
		});
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.user)
			) {
				const data = response.data;
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the monitored PROs!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the monitored PROs!",
				{ variant: "error" }
			);
		}
	}
	getPagination() {
		const delta = 5;
		let currentPage = 1,
			pageCount = 1;

		if (!_.isEmpty(this.state.body)) {
			currentPage = parseInt(this.state.body.current);
			pageCount = parseInt(this.state.body.last);
		}

		let pagination = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" },
			pages = [];

		const separate = (a, b) => [
			a,
			...({
				0: [],
				1: [b],
				2: [a + 1, b]
			}[b - a] || ["...", b])
		];

		pages = Array(delta * 2 + 1)
			.fill()
			.map((_, index) => currentPage - delta + index)
			.filter(page => 0 < page && page <= pageCount)
			.flatMap((page, index, { length }) => {
				if (!index) {
					return separate(1, page);
				}
				if (index === length - 1) {
					return separate(page, pageCount);
				}
				return [page];
			});

		if (pageCount > 1 && !_.isEmpty(pages)) {
			pagination = pages.map((prop, key) => {
				let page = { text: prop };
				if (prop == currentPage) {
					page.active = true;
				} else if (prop === "...") {
					page.disabled = true;
				} else {
					page.onClick = e => this.handlePageClick(prop);
				}
				return page;
			});
		}

		if (currentPage < pageCount) {
			nextPage.onClick = e => this.handlePageClick(currentPage + 1);
		} else {
			nextPage.disabled = true;
		}

		if (currentPage - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(currentPage - 1);
		} else {
			prevPage.disabled = true;
		}

		pagination.push(nextPage);
		pagination.unshift(prevPage);

		return pagination;
	}
	getMonitoredPros(data) {
		return data.map((prop, key) => {
			const link = basePath + "/admin/shipping/track-shipment?d=" + prop.tracking_code + "/" + prop.scac;
			return [
				<NavLink to={link}>{prop.pro}</NavLink>,
				prop.scac,
				prop.po,
				prop.so,
				prop.ref,
				prop.tracking_data && prop.tracking_data.shipped_by ? prop.tracking_data.shipped_by.name : "",
				prop.tracking_data && prop.tracking_data.received_by ? prop.tracking_data.received_by.name : "",
				(!_.isEmpty(prop.pickup_date)) ? moment(prop.pickup_date).format("L hh:mm a") : "Unknown",
				(!_.isEmpty(prop.tracking_data.delivery_date.date)) ? moment(prop.tracking_data.delivery_date.date).format("L hh:mm a") : "Unknown",
				(!_.isEmpty(prop.updated_timestamp)) ? moment.unix(prop.updated_timestamp).format("L hh:mm a") : "Never",
				prop.notify_email,
				prop.all_updates == 1 ? "*" : "",
				prop.status,
				<div className={this.props.classes.right}>
					<Button
						size="sm"
						color="info"
						onClick={() => this.handleUpdate(key)}
					>
						Update
					</Button>
				</div>
			];
		});
	}
	handleUpdate(key) {
		const { result } = this.state.body;
		const pro = result[key];
		this.setState({
			key,
			tracking_code: pro.tracking_code,
			all_updates: pro.all_updates == 1,
			monitorEmailsModal: true,
			emails: pro.notify_email.split(',')
		});
	}
	getEmails(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={classes.package}>
						<GridContainer>
							<GridItem xs={9}>
								<InputLabel className={classes.label}>Email</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										value: prop || "",
										onChange: e => this.handleEmail(key, e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs={3} className={classes.right}>
								{key == 0 ? (
									<Button
										color="success"
										size="sm"
										justIcon
										round
										className={classes.chip}
										onClick={() => this.addEmail()}
									>
										<Add />
									</Button>
								) : (
									<Button
										color="danger"
										size="sm"
										justIcon
										round
										className={classes.chip}
										onClick={() => this.removeEmail(key)}
									>
										<Close />
									</Button>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			);
		});
	}
	handleEmail(i, event) {
		let { emails } = this.state;
		emails[i] = event.target.value;
		this.setState({ emails });
	}
	addEmail() {
		let { emails } = this.state;
		emails.push("");
		this.setState({ emails });
	}
	removeEmail(i) {
		let { emails } = this.state;
		if (i !== 0) {
			emails.splice(i, 1);
			this.setState({ emails });
		}
	}
	async saveChanges() {

		if(this.state.user.level === "admin" && _.isEmpty(this.state.company)) {
            this.props.setNotification(
				"You need to choose a company!",
				{
					variant: "error"
				}
			);
            return false;
        }

		if(_.isEmpty(this.state.emails) || _.isEmpty(this.state.emails[0])) {
            this.props.setNotification(
				"An email is required!",
				{
					variant: "error"
				}
			);
            return false;
        }

        let emails = [];
        let result = true;
        const regex = /\S+@\S+\.\S+/;

        for (let email of this.state.emails) {

            if(_.isEmpty(email)) {
                continue;
            }

            if(!regex.test(email)) {
                this.props.setNotification(
					"The following email address is invalid: " + email, 'error',
					{
						variant: "error"
					}
				);
                result = false;
                break;
            } else {
            	emails.push(email.replace(/(^,)|(,$)/g, ""));
            }

        };

        if(result === false || _.isEmpty(emails)) {
            return;
        }

        const data = {
            notifyEmail : emails.join(","),
            allUpdates : this.state.all_updates ? 1 : 0,
            companyId : this.state.company || ""
        };

		try {
			let url = "/index.php?p=api&r=json&c=tracking&m=monitor&d=" + this.state.tracking_code;
			const response = await axios.post(url, qs.stringify(data));
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				response.data.body
			) {
				let { body } = this.state;
				let { result } = body;
				let pro = result[this.state.key];
				pro.notify_email = emails.join(", ");
				pro.all_updates = this.state.all_updates ? 1 : 0;
				result[this.state.key] = pro;
				body.result = result;
				this.setState({
					body,
					key: null,
					tracking_code: "",
					all_updates: false,
					monitorEmailsModal: false,
					emails: []
				});
			} else {
				this.props.setNotification(
					"There was an error saving the changes!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error saving the changes!",
				{
					variant: "error"
				}
			);
		}

        return false;

	}
	getFilters(filters) {
		const { classes } = this.props;
		return filters.map((prop, key) => {
			return (
				<Grid item xs={12} key={key}>
					<Grid container>
						<GridItem xs>
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<Select
									MenuProps={{ className: classes.selectMenu }}
									classes={{ select: classes.select }}
									value={prop.field || ""}
									inputProps={{ name: "field" }}
									onChange={e => this.handleFilters(key, "field", e)}
								>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="pro"
									>
										PRO #
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="shipper"
									>
										Shipper
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="consignee"
									>
										Consignee
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="scac"
									>
										SCAC
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="status"
									>
										Status
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="po"
									>
										PO #
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="so"
									>
										SO #
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="ref"
									>
										REF #
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<Select
									MenuProps={{ className: classes.selectMenu }}
									classes={{ select: classes.select }}
									value={prop.compare || ""}
									inputProps={{ name: "compare" }}
									onChange={e => this.handleFilters(key, "compare", e)}
								>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="starts with"
									>
										starts with
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="contains"
									>
										contains
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="ends with"
									>
										ends with
									</MenuItem>
									<MenuItem
										classes={{ root: classes.selectMenuItem }}
										value="equals"
									>
										equals
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<CustomInput
								formControlProps={{ fullWidth: true }}
								inputProps={{
									type: "text",
									value: prop.value || "",
									onChange: e => this.handleFilters(key, "value", e),
									onKeyPress: e => this.handleKeyPress(e)
								}}
								white
							/>
						</GridItem>
						<GridItem xs={6}>
							{key == 0 ? (
								<Button
									size="sm"
									color="success"
									className={classes.marginLeft}
									onClick={() => this.addFilter()}
								>
									<Add /> Add Filter
								</Button>
							) : (
								<Button
									size="sm"
									color="danger"
									className={classes.marginLeft}
									onClick={() => this.removeFilter(key)}
								>
									<Close /> Remove Filter
								</Button>
							)}
							{key == 0 && (
								<Button
									size="sm"
									color="linkedin"
									className={classes.marginLeft}
									onClick={() => this.handleSearch()}
								>
									<Search /> Search
								</Button>
							)}
							{key == 0 && (this.state.filters.length > 1 || prop.value !== "") && (
								<Button
									size="sm"
									color="white"
									className={classes.marginLeft}
									onClick={() => this.clearFilters()}
								>
									<ClearAll /> Clear Filters
								</Button>
							)}
						</GridItem>
					</Grid>
				</Grid>
			);
		});
	}
	addFilter() {
		let { filters } = this.state;
		filters.push({
			field: "pro",
			compare: "starts with",
			value: ""
		});
		this.setState({ filters });
	}
	removeFilter(key) {
		let { filters } = this.state;
		if (!_.isEmpty(filters) && key != 0) {
			filters.splice(key, 1);
			this.setState({ filters });
		}
	}
	async clearFilters() {
		this.setState({
			show: false,
			loading: true,
			filters: [
				{
					field: "pro",
					compare: "starts with",
					value: ""
				}
			]
		});
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=tracking&m=monitoredPros");
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the companies!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the companies!", {
				variant: "error"
			});
		}
	}
	handleFilters(key, name, event) {
		let { filters } = this.state;
		filters[key][name] = event.target.value;
		this.setState({ filters });
	}
	async handleSearch() {
		const { filters } = this.state;
		let isEmpty = false;

		filters.forEach(filter => {
			if (!filter.value.trim()) isEmpty = true;
		});

		if (isEmpty) {
			this.props.setNotification("Search values are required", { variant: "error" });
			return false;
		}

		let url = "/index.php?p=api&r=json&c=tracking&m=monitoredPros&d=1";
		url += '&filters=' + encodeURIComponent(JSON.stringify(filters));
		this.setState({
			show: false,
			loading: true
		});
		try {
			const response = await axios.get(url);
			const { data } = response;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					show: true,
					loading: false,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error searching for monitored PROs!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error searching for monitored PROs!",
				{ variant: "error" }
			);
		}
	}
	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch();
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										{!_.isEmpty(this.state.filters) && this.getFilters(this.state.filters)}
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.result) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.result) ? (
											<Table
												tableHead={[
													"PRO",
													"SCAC",
													"PO",
													"SO",
													"REF",
													"Shipper",
													"Consignee",
													"Picked Up",
													"Delivery Date",
													"Last Updated",
													"Notifications To",
													"All Updates",
													"Status",
													"Update Notifications"
												]}
												tableData={this.getMonitoredPros(this.state.body.result)}
												customCellClasses={[classes.right]}
												customClassesForCells={[14]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[14]}
											/>
										) : (
											<Badge color="info">No Montiored PROs</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve montiored PROs from the server"
									/>
								)}
							</Grid>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.monitorEmailsModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("monitorEmailsModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							color="transparent"
							onClick={() =>
								this.handleModalClose("monitorEmailsModal")
							}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>
							Monitor E-mail(s)
						</h4>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
					>
						<GridContainer>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								className={classes.unitContainer}
							>
								{!_.isEmpty(this.state.emails) && this.getEmails(this.state.emails)}
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								className={classes.left}
							>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={
												<Check className={classes.checkedIcon} />
											}
											icon={
												<Check className={classes.uncheckedIcon} />
											}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot
											}}
											inputProps={{
												name: "all_updates"
											}}
											checked={this.state.all_updates}
											onChange={this.handleChecked("all_updates")}
										/>
									}
									classes={{
										label: classes.label
									}}
									label="All Updates"
								/>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button
							color="white"
							style={{ marginRight: "4px" }}
							onClick={() =>
								this.handleModalClose("monitorEmailsModal")
							}
						>
							Close
						</Button>
						<Button
							color="linkedin"
							style={{ marginRight: "4px" }}
							onClick={() => this.saveChanges()}
						>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</GridContainer>
		);
	}
}

export default withStyles(archivedBillsOfLadingStyle)(
	withSnackbar(MonitoredPros)
);