const setOpen = val => {
	return {type: "SET_ALERT_MODAL_OPEN", val};
}

const setMessage= val => {
	return {type: "SET_ALERT_MODAL_MESSAGE", val};
}

const setOnConfirm = val => {
	return {type: "SET_ALERT_MODAL_ON_CONFIRM", val};
}

export const openAlertModal = (message, callback) => {
	return (dispatch) => {
        dispatch(setMessage(message));
        dispatch(setOnConfirm(callback));
		dispatch(setOpen(true));
	}
}

export const onConfirm = () => {
    return (dispatch, getState) => {
		const callback = getState().Alert.onConfirm;
		if(typeof callback === 'function') {
			callback();
		}
		dispatch(setOpen(false));
    }
}

export const onClose = () => {
	return (dispatch) => {
		dispatch(setOpen(false));
	}
}