import React from "react";

import cx from "classnames";
import qs from "qs";

import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

// material ui icons
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Input from "@material-ui/icons/Input";
import Save from "@material-ui/icons/Save";
import Add from "@material-ui/icons/Add";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Search from "@material-ui/icons/Search";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardText from "~/components/Card/CardText.jsx";
import CardIcon from "~/components/Card/CardIcon.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CardFooter from "~/components/Card/CardFooter.jsx";
import SnackbarContent from "~/components/Snackbar/SnackbarContent.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Table from "~/components/Table/Table.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class TemplateDefinition extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			format: (!_.isEmpty(props.format)) ? props.format : '',
			columns: (!_.isEmpty(props.columns)) ? props.columns : [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.reset = this.reset.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true,
			show: true,
			loading: false,
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps.format, this.props.format) || !_.isEqual(prevProps.columns, this.props.columns)) {
			this.setState({
				format: this.props.format,
				columns: this.props.columns,
			});
		} else if(!_.isEqual(prevState, this.state)) {
			if(typeof this.props.onChange === 'function') {
				this.props.onChange(this.state.format);
			}
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    reset() {
    	this.setState({ format: '' });
    }
    applyDefaults() {
    	if(_.isEmpty(this.state.columns)) {
    		this.setState({ format: '' });
    	} else {
    		const format = '"%' + this.state.columns.join('%","%') + '%"';
    		this.setState({ format });
    	}
    }
    toggleColumn(col) {
    	if(this.state.columns.indexOf(col) == -1) {
    		return;
    	}
    	col = '"%' + col + '%"';
    	let { format } = this.state;
    	let pos = format.indexOf(col);
    	if(pos > -1) {
			// remove the column name and comma delimited format string
			if((pos + col.length) == format.length) {
				format = format.substring(0, pos - 1) + format.substring((pos + col.length + 1));
			} else {
				format = format.substring(0, pos) + format.substring((pos + col.length + 1));
			}
    	} else {
    		format += (format.length) ? (',' + col) : col;
    	}
    	this.setState({ format });
    }
	getColumnButtons() {
		const { classes } = this.props;
		const { format } = this.state;
		return this.state.columns.map((col, key) => {
			return (
				<Button
					key={"col_btn_" + key}
					size="sm"
					className={classes.marginRight}
					{...(((format.indexOf('%' + col + '%') > -1)) ? {color: 'linkedin'} : {})}
					onClick={() => this.toggleColumn(col)}
				>
					{col.replace('_', ' ')}
				</Button>
			);
		});
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Button
						size="sm"
						className={classes.marginRight}
						color="warning"
						onClick={this.applyDefaults}
					>
						Apply Defaults
					</Button>
					<Button
						size="sm"
						className={classes.marginRight}
						color="danger"
						onClick={this.reset}
					>
						Reset
					</Button>
				</GridItem>
				<br />
				<br />
				<GridItem xs={12} sm={12} md={12} lg={12}>
					{this.getColumnButtons()}
				</GridItem>
				<br />
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            type: "text",
                            id: "format",
                            name: "format",
                            value: this.state.format || "",
                            multiline: true,
                            onChange: this.handleChange,
                        }}
                        white
                    />
				</GridItem>
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		handleMessages,
		setNotification,
	}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(TemplateDefinition)));
