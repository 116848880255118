import React, { Component } from "react";
import Datetime from "react-datetime";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "~/components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";



  
class DoubleDatePromptModal extends Component {

    state = {
        pickupDate: null,
        deliveryDate: null,
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({pickupDate: null,deliveryDate: null});
        }
    }

    onPickupChangeDate = momentObj => {
        if (typeof this.props.onChange === "function") this.props.onChange(momentObj);
        this.setState({pickupDate: momentObj})
    }
    onDeliveryChangeDate = momentObj => {
        if (typeof this.props.onChange === "function") this.props.onChange(momentObj);
        this.setState({deliveryDate: momentObj})
    }

    onClose = () => {
        if (typeof this.props.onClose === "function") this.props.onClose();
    }

    onSubmit = () => {
        if (typeof this.props.onSubmit === "function" && this.state.pickupDate && this.state.deliveryDate) this.props.onSubmit(this.state.pickupDate, this.state.deliveryDate);
    }

    render() {
        return (
            <Dialog
                open={!!this.props.open}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
            <DialogContent id="classic-modal-slide-description">
                <center>
                    <InputLabel>
                            {"Please provide the pickup and delivery dates "}
                    </InputLabel>
                </center>   
                <br />
                <br />
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                <center>
                    <InputLabel>
                        <b>
                            {"Pickup Date"}
                        </b>
                    </InputLabel>
                </center>   
                <Datetime
                    value={this.state.pickupDate || ""}
                    onChange={this.onPickupChangeDate}
                    timeFormat={false}
                    className="date-prompt-modal"
                    input={false}
                />
                {this.state.pickupDate && <center><div><b>{this.state.pickupDate.format("L")}</b></div></center>}
                
                </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                <center>
                    <InputLabel>
                        <b>
                            {"Delivery Date"}
                        </b>
                    </InputLabel>
                </center>   
                    <Datetime
                        value={this.state.deliveryDate || ""}
                        onChange={this.onDeliveryChangeDate}
                        timeFormat={false}
                        className="date-prompt-modal"
                        input={false}
                    />
                    {this.state.deliveryDate && <center><div><b>{this.state.deliveryDate.format("L")}</b></div></center>}
                    </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="white">
                        Cancel
                    </Button>
                    <Button onClick={this.onSubmit} disabled={!this.state.pickupDate && !this.state.deliveryDate} color={(this.state.pickupDate && this.state.deliveryDate) ? "primary" : "white"}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

export default DoubleDatePromptModal;