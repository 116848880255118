import {
	cardTitle,
	dangerColor,
	warningColor,
	successColor,
	infoColor,
	whiteColor,
	blackColor,
	grayColor,
	defaultFont,
	linkedinColor,
	tfmBlueColor,
	tfmOrangeColor,
	blackBoxShadow
} from "~/assets/jss/material-dashboard-pro-react.jsx";
import {
	uiColors,
	hexToRgb
} from "assets/jss/empire-tms.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "~/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import modalStyle from "~/assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const ltlQuoteStyle = theme => ({
	...customSelectStyle,
	...customCheckboxRadioSwitch,
	cardTitle: {
		...cardTitle,
		color: whiteColor
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	formCategory: {
		marginBottom: "0",
		color: grayColor[0],
		fontSize: "14px",
		padding: "10px 0 10px"
	},
	cardHeader: {
		zIndex: "3"
	},
	cardSubtitle: {
		...defaultFont,
		color: grayColor[0],
		fontSize: "14px",
		margin: "0 0 10px"
	},
	center: {
		textAlign: "center"
	},
	justifyContentCenter: {
		justifyContent: "center"
	},
	registerButton: {
		float: "right"
	},
	center: {
		textAlign: "center"
	},
	right: {
		textAlign: "right"
	},
	left: {
		textAlign: "left"
	},
	buttonGroup: {
		position: "relative",
		margin: "10px 1px",
		display: "inline-block",
		verticalAlign: "middle"
	},
	firstButton: {
		borderTopRightRadius: "0",
		borderBottomRightRadius: "0",
		margin: "0",
		position: "relative",
		float: "left",
		"&:hover": {
			zIndex: "2"
		}
	},
	middleButton: {
		borderRadius: "0",
		margin: "0",
		position: "relative",
		float: "left",
		"&:hover": {
			zIndex: "2"
		}
	},
	lastButton: {
		borderTopLeftRadius: "0",
		borderBottomLeftRadius: "0",
		margin: "0",
		"&:hover": {
			zIndex: "2"
		}
	},
	marginLeft: {
		marginLeft: "5px"
	},
	marginRight: {
		marginRight: "5px"
	},
	modalSectionTitle: {
		marginTop: "30px"
	},
	chip: {
		marginTop: "25px"
	},
	warning: {
		backgroundColor: warningColor[0]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: "flex",
		alignItems: "center"
	},
	unit: {
		backgroundColor: infoColor[5],
		color: blackColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14]
	},
	unitHeader: {
		backgroundColor: linkedinColor,
		color: whiteColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14]
	},
	package: {
		backgroundColor: infoColor[5],
		color: blackColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14],
		paddingTop: "5px",
		paddingBottom: "5px",
	},
	removed: {
		backgroundColor: dangerColor[6],
		color: blackColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14],
		paddingTop: "5px",
		paddingBottom: "5px",
	},
	hazmat: {
		backgroundColor: dangerColor[6],
		color: blackColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14]
	},
	hazmatHeader: {
		backgroundColor: "#a74240",
		color: whiteColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14]
	},
	product: {
		backgroundColor: whiteColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14]
	},
	productHeader: {
		backgroundColor: whiteColor,
		color: blackColor,
		borderBottomWidth: "1px",
		borderBottomStyle: "dotted",
		borderBottomColor: grayColor[14]
	},
	unitContainer: {
		margin: "15px",
		...blackBoxShadow
	},
	unitWrapper: {
		borderTopWidth: "1px",
		borderLeftWidth: "1px",
		borderRightWidth: "1px",
		borderTopStyle: "dotted",
		borderLeftStyle: "dotted",
		borderRightStyle: "dotted",
		borderTopColor: grayColor[7],
		borderLeftColor: grayColor[7],
		borderRightColor: grayColor[7]
	},
	search: {
		margin: "0",
		paddingTop: "7px",
		paddingBottom: "7px",
		[theme.breakpoints.down("sm")]: {
			margin: "10px 15px",
			float: "none !important",
			paddingTop: "1px",
			paddingBottom: "1px",
			padding: "10px 15px",
			width: "auto"
		},
		zIndex: "4"
	},
	searchButton: {
		[theme.breakpoints.down("sm")]: {
			top: "-50px !important",
			marginRight: "38px",
			float: "right"
		},
		zIndex: "4"
	},
	requiredAdornment: {
		backgroundColor: uiColors.yellow.lightest,
		borderBottom: "1px solid",
		borderBottomColor: uiColors.warning.darkest,
		color: blackColor
	},
	datetime: {
		/*backgroundColor: whiteColor*/
	},
	required: {
		backgroundColor: uiColors.yellow.lightest,
		borderBottom: "1px solid",
		borderBottomColor: uiColors.warning.darkest,
	},
	white: {
		backgroundColor: whiteColor
	},
	paper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		color: blackColor
	},
	infoPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: infoColor[5],
		color: blackColor
	},
	dangerPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: dangerColor[5],
		color: dangerColor[0]
	},
	warningPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: warningColor[5],
		color: blackColor
	},
	successPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: successColor[5],
		color: blackColor
	},
	bluePaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: tfmBlueColor,
		color: whiteColor
	},
	lightBluePaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: "#d9edf7",
		color: whiteColor
	},
	orangePaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: tfmOrangeColor,
		color: blackColor
	},
	thumbsContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 16
	},
	thumb: {
		display: "inline-flex",
		borderRadius: 2,
		border: "1px solid #eaeaea",
		marginBottom: 8,
		marginRight: 8,
		width: 100,
		height: 100,
		padding: 4,
		boxSizing: "border-box"
	},
	thumbInner: {
		display: "flex",
		minWidth: 0,
		overflow: "hidden"
	},
	img: {
		display: "block",
		maxWidth: "300px",
		height: "auto",
		padding: "15px"
	},
	programCarriers: {
		backgroundColor: infoColor[6],
		marginRight: 5
	},
	programCarriersAlt: {
		backgroundColor: infoColor[5],
		marginRight: 5
	},
	nonProgramCarriers: {
		backgroundColor: warningColor[6],
		marginRight: 5
	},
	nonProgramCarriersAlt: {
		backgroundColor: warningColor[5],
		marginRight: 5
	},
	dividerTop: {
	    borderTop: `2px solid ${theme.palette.divider}`,
	    padding: theme.spacing(1, 2),
	},
	dividerRight: {
	    borderRight: `2px solid ${theme.palette.divider}`,
	    padding: theme.spacing(1, 2),
	},
	dividerBottom: {
	    borderBottom: `2px solid ${theme.palette.divider}`
	},
	dividerLeft: {
	    borderLeft: `2px solid ${theme.palette.divider}`,
	    padding: theme.spacing(1, 2),
	},
	spacing: {
		padding: theme.spacing(2, 2, 2, 2),
	},
	hover: {
		cursor: "pointer",
	},
	progress: {
		margin: theme.spacing(2)
	},
	pre: {
		fontFamily: "Consolas",
		whiteSpace: "pre-wrap",
		whiteSpace: "-moz-pre-wrap",
		whiteSpace: "-pre-wrap",
		whiteSpace: "-o-pre-wrap",
		wordWrap: "break-word"
	},
	white: {
		backgroundColor: whiteColor
	},
	customBadge: {
		backgroundColor: "green",
		color: "white"
	  },
	error: {
		backgroundColor: "transparent",
		color: dangerColor[0],
	},
	...modalStyle(theme),
	...buttonStyle,
});

export default ltlQuoteStyle;
