import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Datetime from "react-datetime";
import moment from "moment";
import { useSnackbar } from 'notistack';
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

const FilterQueryText = ({
    addQueryText,
    clearQueryText,
    param,
    value,
    placeholder,
    isUpdate,
	fieldsWithAutoComplete,
	fieldsWithDate,
	optionsLoading}) => {

    const { filterName, operator, options } = useSelector(state => ({
        ...state.FilterQueryModal
    }));
	const { enqueueSnackbar } = useSnackbar();
    const [isAutocomplete, setIsAutocomplete] = useState(false);
    const [isPicker, setIsPicker] = useState(false);

	useEffect(() => {
		if (!isUpdate && isAutocomplete) {
			handleUpdateFilterValue(options[0]);
			setFormControl();
		}
	 }, [isUpdate]);

	useEffect(() => {
        if (filterName) setFormControl();
    }, [filterName]);

    useEffect(() => {
		if (isUpdate && isAutocomplete) handleUpdateFilterValue(value);
	}, [options]);

    useEffect(() => {
        if (isPicker) {
			if(value === '') value = new moment();
			onChangeDate(value);
		}
	}, [isPicker]);

	useEffect(() => {
		if (filterName) setFormControl();
	}, [operator]);

	const setFormControl = () => {
		if (!isUpdate) {
			clearQueryText();
		}
		setIsPicker(false);
		setIsAutocomplete(false);
		if(fieldsWithAutoComplete.includes(filterName.text)) {
			if(operator.value === 'equals' || operator.value === 'not_equals') {
				setIsAutocomplete(true);
			}
		} else if(fieldsWithDate.includes(filterName.text)) {
			setIsPicker(true);
		} else {
			setIsPicker(false);
			setIsAutocomplete(false);
		}
	}

    const onChangeDate = (momentObj) => {
		const dateVal = moment(momentObj, "MM/DD/YYYY").format("MM/DD/YYYY");
		if (dateVal === "Invalid date") {
			enqueueSnackbar('Invalid Date Format', { variant: 'error' });
			return;
		} else {
			if(param) {
				param === "param1" ? addQueryText(dateVal, false) : addQueryText(dateVal, true);
			} else {
				addQueryText(dateVal);
			}
		}
    }

    const handleUpdateFilterValue = (e, val) => {
		let targetVal = '';
		if(typeof e === 'object') {
			if(val !== undefined && val !== null) {
				targetVal = val
			} else {
				e.persist();
				if(e.target.value !== undefined) targetVal = e.target.value;
			}
		} else {
			targetVal = e;
		}
		if(param) {
			param === "param1" ? addQueryText(targetVal, false) : addQueryText(targetVal, true);
		} else {
			addQueryText(targetVal)
		}
    }

    return (
		<>
			<FormControl style={{ width: "100%" }}>
				{optionsLoading ? (
					<GridContainer spacing={1} style={{ width: "inherit" }}>
						<GridItem xs={10} style={{ padding: 0, marginLeft: 15 }}>
							<TextField
								style={{ width: "100%" }}
								value="Loading options..."
								disabled
							/>
						</GridItem>
						<GridItem xs={1}>
							<CircularProgress size={25} />
						</GridItem>
					</GridContainer>
				) : isAutocomplete ? (
					<CustomInput
						inputProps={{
							type: "text",
							name: "search_value",
							value: value || '',
							onChange: handleUpdateFilterValue,
						}}
						freeSolo={false}
						id={"search_value"}
						autoComplete
						options={options}
					/>
				) : isPicker ? (
					<Datetime
						value={value || ''}
						onChange={onChangeDate}
						timeFormat={false}
						input={true}
						closeOnSelect={true}
					/>
				) : (
					<TextField
						style={{ width: "inherit" }}
						placeholder={placeholder}
						required={true}
						value={value ? value : ""}
						onChange={handleUpdateFilterValue}
					/>
				)}
			</FormControl>
		</>
	);
}

export default FilterQueryText;