const setHeaderObj = (headerData) => {
	return { type: 'SET_FILTER_HEADER_VALUES', payload: headerData }
}
const setFilterValue = (filter) => {
	return { type: 'UPDATE_FILTERS_VALUE', payload: filter }
}
const setOptions = (val) => {
	return { type: 'SET_OPTIONS', payload: val }
}
const updateOperatorValue = (val) => {
	return { type: 'UPDATE_OPERATOR_VALUE', payload: val }
}
const updateFilterNameValue = (val) => {
	return { type: 'UPDATE_FILTER_NAME_VALUE', payload: val }
}

const allActions = {
	setHeaderObj,
	setFilterValue,
	setOptions,
	updateOperatorValue,
	updateFilterNameValue,
}

export default allActions;