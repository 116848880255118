import moment from "moment";
import fedHolidays from "@18f/us-federal-holidays";


const allFedHolidays = fedHolidays.allForYear();
const recognizedFedHolidaysNames = new Set([
    "New Year's Day",
    "Birthday of Martin Luther King, Jr.",
    "Washington's Birthday",
    "Memorial Day",
    // "Juneteenth National Independence Day",
    "Independence Day",
    "Labor Day",
    "Columbus Day",
    "Veterans Day",
    "Thanksgiving Day",
    "Christmas Day"
]);
const recognizedFedHolidays = new Set(
    allFedHolidays.filter(d => recognizedFedHolidaysNames.has(d.name)).map(d => d.dateString)
);

moment.prototype.isFederalHoliday = function() {
    let dateString = this.clone().format('YYYY-MM-DD');
    return recognizedFedHolidays.has(dateString);
}

moment.prototype.isBusinessDay = function() {
    return this.isoWeekday() < 6 && !this.isFederalHoliday();
}

moment.prototype.addBusinessDays = function(days) {
    //iterate over date period, add one day to $datePeriod for any holiday / weekend
    const startDate = this.clone();
    this.add(days, 'days');
    for (let date = startDate; date.isBefore(this, 'day'); date.add(1, 'days')) {
        if (!date.isBusinessDay()) {
            this.add(1, 'days');
        }
    }

    //prevent infinite loop if something goofy happens
    let tries = 0;

    //make sure expected delivery lands on a business day
    while (!this.isBusinessDay() && tries < 7) {
        this.add(1, 'days');
        tries += 1;
    }
}

moment.prototype.dateIsSame = function(date) {
    return this.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
}

const __format = moment.prototype.format;
moment.prototype.format = function (f) {
    if (f === 'mysql') {
        return __format.call(this, 'YYYY-MM-DD');
    } else if (f === 'mysql-ts') {
        const r =  __format.call(this, 'x');
        return this.isValid() ? r.slice(0, -3) : r;
    } else {
        return __format.call(this, f);
    }
}

// wrap constructor
const __moment = function(date, ...args) {
    const mysqlTimestamp = /^\d{10}$/;
    if (mysqlTimestamp.test(date)) {
        return moment(parseInt(date) * 1000, ...args)
    }
    return moment(date, ...args);
}


export default __moment;