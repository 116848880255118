import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "~/components/Table/Table.jsx";
import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class PrintDgdModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><strong>Print DGD</strong></h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {this.props.step === 1 && (
                        <GridContainer justify="center" direction="column">
                            <GridItem>
                                <InputLabel className={classes.label}>Which references should be on the document?</InputLabel>
                                <br />
                                <br />
                            </GridItem>
                            <GridItem>
                                <GridContainer alignItems="center" style={{marginBottom:"10px", textAlign: "right"}}>
                                    <GridItem xs={4}>
                                        <InputLabel
                                            htmlFor="dgd_pro"
                                            className={classes.label}
                                        >
                                                PRO #
                                        </InputLabel>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                name: "dgd_pro",
                                                id: "dgd_pro",
                                                value: this.props.dgdPro,
                                                onChange: this.props.handleInput("dgd_pro"),
                                                margin: "dense"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                <GridContainer alignItems="center" style={{marginBottom:"10px", textAlign: "right"}}>
                                    <GridItem xs={4}>
                                        <InputLabel
                                            htmlFor="dgd_bol"
                                            className={classes.label}>
                                                BOL #
                                        </InputLabel>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                name: "dgd_bol",
                                                id: "dgd_bol",
                                                value: this.props.dgdBol,
                                                onChange: this.props.handleInput("dgd_bol"),
                                                margin: "dense"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                <GridContainer alignItems="center" style={{marginBottom:"10px", textAlign: "right"}}>
                                    <GridItem xs={4}>
                                        <InputLabel
                                            htmlFor="dgd_po"
                                            className={classes.label}>
                                                PO #
                                        </InputLabel>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                name: "dgd_po",
                                                id: "dgd_po",
                                                value: this.props.dgdPo,
                                                onChange: this.props.handleInput("dgd_po"),
                                                margin: "dense"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                <GridContainer alignItems="center" style={{marginBottom:"10px", textAlign: "right"}}>
                                    <GridItem xs={4}>
                                        <InputLabel
                                            htmlFor="dgd_so"
                                            className={classes.label}>
                                                Order/SO #
                                        </InputLabel>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                name: "dgd_so",
                                                id: "dgd_so",
                                                value: this.props.dgdSo,
                                                onChange: this.props.handleInput("dgd_so"),
                                                margin: "dense"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                <GridContainer alignItems="center" style={{marginBottom:"10px", textAlign: "right"}}>
                                    <GridItem xs={4}>
                                        <InputLabel
                                            htmlFor="dgd_ref"
                                            className={classes.label}>
                                                REF #
                                        </InputLabel>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                name: "dgd_ref",
                                                id: "dgd_ref",
                                                value: this.props.dgdRef,
                                                onChange: this.props.handleInput("dgd_ref"),
                                                margin: "dense"
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    )}
                    {this.props.step === 2 && (
                        <GridContainer className={classes.left}>
                            <GridItem xs={12} style={{ textAlign: "center" }}>
                                <InputLabel
                                    className={classes.label}>Which products should be on the document?
                                </InputLabel>
                                <br />
                                <br />
                                {!_.isEmpty(this.props.dgdUnits) &&
                                    <Table
                                        tableData={this.props.dgdGetUnits()}
                                        customCellClasses={[classes.right]}
                                        customClassesForCells={[3]}
                                        customHeadCellClasses={[classes.right]}
                                        customHeadClassesForCells={[3]}
                                    />}
                            </GridItem>
                        </GridContainer>
                    )}
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => this.props.onClose()} color="white">
                        Cancel
                    </Button>
                    {this.props.step === 1 && (
                        <Button onClick={() => this.props.next()} color="info">
                            Next
                        </Button>
                    )}
                    {this.props.step === 2 && (
                        <Button onClick={() => this.props.back()} color="info">
                            Back
                        </Button>
                    )}
                    {this.props.loading ? (
                        <Button color="linkedin" style={{ lineHeight: 0 }}>
                            <CircularProgress size={16} style={{ color: "white" }} />
                        </Button>
                    ) : (
                        this.props.step === 2 && (
                            <Button onClick={() => this.props.printDgd()} color="linkedin">
                                Print
                            </Button>
                        )
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(PrintDgdModal);