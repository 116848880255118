import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import Grid from '@material-ui/core/Grid';
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import Button from "~/components/CustomButtons/Button.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class RequireEmailModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                keepMounted
                fullWidth={true}
                maxWidth='lg'
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.closeModal()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Set Consignee Email for BoL</h4>
                </DialogTitle>

                <GridContainer style={{marginBottom: "6px"}}>
                <GridItem xs={12}>
                        <Card>
                            <CardContent style={{paddingBottom: "6px"}}>
                                    <TextField
                                        label={"Add Email to Consignee on BoL"}
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        fullWidth={true}
                                        onChange = {() => this.props.handleInput("consignee_contact_email")}
                                        style={{marginBottom: "6px"}}
                                        inputProps={{
                                            type: "text",
                                            name: "consignee_contact_email",
                                            id: "consignee_contact_email",
                                            value: this.props.consignee_contact_email,
                                            onChange: this.props.handleInput("consignee_contact_email"),
                                            margin: "dense"
                                        }}
                                    />
                                <Grid container justify="flex-end" style={{marginRight: "15px"}}>
                                <Button
                                        color="success"
                                        size="sm"
                                        onClick={() => this.props.onSubmit()}
                                    >
                                        Save
                                    </Button><Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => this.props.closeModal()}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Dialog>
        );
    }
}

export default withStyles(style)(RequireEmailModal);