import axios from "~/variables/axios.jsx";
import { flushSync } from 'react-dom';

function setResponse(payload) {
	return {type: "SET_RESPONSE", payload};
}

function setUser(data) {
	return {type: "SET_USER_SESSION_DATA", data};
}

export function setError(payload) {
	return {type: "SET_ERROR",payload};
}

export function getUserSession(lite) {
	return async dispatch => {
		let url = "/index.php?p=api&r=json&c=landing&m=index";
		if(lite) {
			url += "&lite";
		}
		try {
			const response = await axios.get(url);
			const result = response && typeof response.data !== "string" ? response : {};
			flushSync(() => {
				dispatch(setResponse(result));
				if (response.data && response.data.user) {
					dispatch(setUser(response.data.user));
				}
			});
		} catch (error) {
			return dispatch(setError(error));
		}
	};
}
