import axios from '../../../variables/axios.jsx';
import { apiUrl } from '../../../variables/server.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';

export const LOCAL_STORAGE_KEY = "TMS_APP_CS_TRACKING_STATE";

const setTrackingLoading = val => {
	return {type: "SET_INTERNAL_CS_DATA_LOADING", val};
}
export const setInternalTrackingRows = val => {
	return {type: "SET_INTERNAL_CS_DATA_ROWS", val};
}
export const setInternalTrackingNumRecords = val => {
	return {type: "SET_INTERNAL_CS_DATA_NUM_RECORDS", val};
}
export const setInternalTrackingPageSize = val => {
	return {type: "SET_INTERNAL_CS_DATA_PAGE_SIZE", val};
}
export const setInternalTrackingPageNum = val => {
	return async dispatch => {
		dispatch({type: "SET_INTERNAL_CS_DATA_PAGE_NUM", val});
		dispatch(storeState());
	}
}
export const setInternalTrackingSortBy = val => {
	return async dispatch => {
		dispatch({type: "SET_INTERNAL_CS_DATA_SORT_BY", val});
		dispatch(storeState());
	}
}
export const setInternalTrackingSortDir = val => {
	return async dispatch => {
		dispatch({type: "SET_INTERNAL_CS_DATA_SORT_DIR", val});
		dispatch(storeState());
	}
}
const setFilters = val => {
	return async dispatch => {
		dispatch({type: "SET_INTERNAL_CS_DATA_FILTERS", val});
		dispatch(storeState());
	}
}
export const setFilterPresets = val => {
	return {type: "SET_INTERNAL_CS_FILTER_PRESETS", val};
}
export const setFilterPresetsLoading = val => {
	return {type: "SET_INTERNAL_CS_FILTER_PRESETS_LOADING", val};
}
const setStateFromStoredState = val => {
	return {type: "INTERNAL_CS_LOAD_STORED_STATE", val};
}

const sortOptions = (val, filterType) => {
	return val.map(item => {
		return item.name.trim() == '' ? `${filterType} Name Missing: ID ${item.id}` : item.name.trim();
	}).sort();
}

/**
 * Retrieving menu options that appear in the filter modal.
 * Also fetching company, warehouse, and carrier IDs to improve read time of stored query and any subsequent queries
 */
export const getOptions = () => async(dispatch) => {
	try{
		let url = 'index.php?p=api&r=json&c=internalcstracking&m=getOptions';
		dispatch({ type: 'SET_OPTIONS_LOADING', val: true });
		const response = await axios.get(url);
		if (response && response.data && response.data.body) {
			const body = response.data.body;
			dispatch(handleMessages(response));
			let sortedCompanyOptions = [];
			if(Array.isArray(body.companies) && body.companies.length) {
				sortedCompanyOptions = sortOptions(body.companies, 'Company');
				dispatch({ type: `STORE_COMPANIES`, val: body.companies });
				dispatch({ type: `SET_SORTED_COMPANY_OPTIONS`, val: sortedCompanyOptions });
			}
			if(Array.isArray(body.warehouses) && body.warehouses.length) {
				dispatch({ type: `STORE_WAREHOUSES`, val: body.warehouses });
				dispatch({ type: `SET_SORTED_WAREHOUSE_OPTIONS`, val: sortOptions(body.warehouses, 'Warehouse') });
			}
			if(Array.isArray(body.codes) && body.codes.length) {
				dispatch({ type: `STORE_CODES`, val: body.codes });
				dispatch({ type: `SET_SORTED_CODE_OPTIONS`, val: sortOptions(body.codes, 'Code') });
			}
			if(Array.isArray(body.carriers) && body.carriers.length) {
				dispatch({ type: `STORE_CARRIERS`, val: body.carriers });
				dispatch({ type: `SET_SORTED_CARRIER_OPTIONS`, val: sortOptions(body.carriers, 'Carrier') });
			}
			if(Array.isArray(body.transitStatuses) && body.transitStatuses.length) {
				dispatch({ type: `STORE_TRANSIT_STATUSES`, val: body.transitStatuses });
				dispatch({ type: `SET_SORTED_TRANSIT_STATUS_OPTIONS`, val: sortOptions(body.transitStatuses, 'Transit Status') });
			}
		}
		dispatch({ type: 'SET_OPTIONS_LOADING', val: false });
	} catch(err) {
		dispatch(setNotification("Error retrieving filter modal menu options", {variant: "error"}))
		dispatch({ type: 'SET_OPTIONS_LOADING', val: false });
		console.error(err);
	}
}

const defaultFilter = element => {
	return {
		field: element.field,
		operator: element.operator,
		param1: element.param1,
		param2: element.param2
	};
}

/**
 * Get statuses for bols provided
 */
export const getTrackingData = () => {
	return async (dispatch, getState) => {
		dispatch(setTrackingLoading(true));
		
		const filters = getState().InternalCSTracking.filters;
		const pageNum = getState().InternalCSTracking.pageNum;
		const pageSize = getState().InternalCSTracking.pageSize;
		// const sortBy = getState().InternalCSTracking.sortBy;
		// const sortDesc = getState().InternalCSTracking.desc;
		const companies = getState().InternalCSTracking.companies;
		const warehouses = getState().InternalCSTracking.warehouses;
		const codes = getState().InternalCSTracking.codes;
		const carriers = getState().InternalCSTracking.carriers;
		
		try {
			let url = 'index.php?p=api&r=json&c=internalcstracking&m=getTrackingData';

			// Replace strings with primary keys to increase query read time
			let replacedFilters = new Array();
			if (Array.isArray(filters) && filters.length) {
				let replaceNamesWithIds = false;
				replacedFilters = filters.map(element => {
					switch(element.field) {
						case 'COMPANY NAME':
							const company = companies.filter(obj => obj.name === element.param1);
							if(company.length === 1) {
								replaceNamesWithIds = true;
								return {
									field: 'COMPANY ID',
									operator: element.operator,
									param1: company[0].id,
									param2: element.param2
								};
							} else {
								return defaultFilter(element);
							}
						case 'WAREHOUSE NAME':
							const warehouse = warehouses.filter(obj => obj.name === element.param1);
							if(warehouse.length === 1) {
								replaceNamesWithIds = true;
								return {
									field: 'WAREHOUSE ID',
									operator: element.operator,
									param1: warehouse[0].id,
									param2: element.param2
								};
							} else {
								return defaultFilter(element);
							}
						case 'WAREHOUSE CODE':
							const code = codes.filter(obj => obj.name === element.param1);
							if(code.length === 1) {
								replaceNamesWithIds = true;
								return {
									field: 'WAREHOUSE ID',
									operator: element.operator,
									param1: code[0].id,
									param2: element.param2
								};
							} else {
								return defaultFilter(element);
							}
						case 'CARRIER NAME':
							const carrier = carriers.filter(obj => obj.name === element.param1);
							if(carrier.length === 1) {
								replaceNamesWithIds = true;
								return {
									field: 'CARRIER ID',
									operator: element.operator,
									param1: carrier[0].id,
									param2: element.param2
								};
							} else {
								return defaultFilter(element);
							}
						default:
							return defaultFilter(element);
					}
				});

				url += replaceNamesWithIds ? `&filters=${getFilterStringFromFilters(replacedFilters)}` : `&filters=${getFilterStringFromFilters(filters)}`;
			}

			url += "&pageNum=" + pageNum;
			url += "&pageSize=" + pageSize;

			// No longer sorting in effort to achieve better performance
			// if (sortBy != "") {
			// 	url += "&sortBy=" + sortBy;
			// 	url += "&sortDir=" + (sortDesc ? "descending" : "ascending");
			// }

			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body ) {
				if(response.data.body.items) {
					dispatch(setInternalTrackingRows(response.data.body.items));
				}
				if(response.data.body.numRecords) {
					dispatch(setInternalTrackingNumRecords(response.data.body.numRecords));
				}
				if(response.data.body.pageSize) {
					dispatch(setInternalTrackingPageSize(response.data.body.pageSize));
				}
			}
		} catch (err) {
			dispatch(setNotification("Error fetching tracking info.", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setTrackingLoading(false));
		}
	}
}

/**
 * Set the filters, reset to page 1, and reload the table data
 * @param  array filters
 */
export const updateFilters = filters => {
	return async (dispatch) => {
		dispatch(setInternalTrackingPageNum(1));
		dispatch(setFilters(filters));
		dispatch(getTrackingData());
	}
}

/**
 * Load stored filter presets
 */
export const fetchFilterPresets = () => {
	return async (dispatch) => {
		dispatch(setFilterPresetsLoading(true));
		try {
			const response = await axios.get('index.php?p=api&r=json&c=internalcstracking&m=getFilterPresets');
			dispatch(handleMessages(response));
			if (typeof response.data === "object") {
				dispatch(setFilterPresets(response.data.body))
			}
		} catch (err) {
			dispatch(setNotification("Error fetching filter presets.", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setFilterPresetsLoading(false));
		}
	}
}

/**
 * Export this collection to CSV
 */
export const exportCsv = () => {
	return async (dispatch, getState) => {
		const filters = getState().InternalCSTracking.filters;
		if (filters.length) {
			const filterString = getFilterStringFromFilters(filters);
			// const sortBy = getState().InternalCSTracking.sortBy;
			// const sortDesc = getState().InternalCSTracking.desc;
			let url = `${apiUrl}/index.php?p=api&r=json&c=internalcstracking&m=export&filters=${filterString}`;

			// No longer sorting in effort to achieve better performance
			// if (sortBy != "") {
			// 	url += "&sortBy=" + sortBy;
			// 	url += "&sortDir=" + (sortDesc ? "descending" : "ascending");
			// }
			window.location = url;
		} else {
			dispatch(setNotification("Filters are required to export", {variant: "warning"}));
		}
	}
}

const getFilterStringFromFilters = filters => {
	return encodeURIComponent(JSON.stringify(filters));
}

const storeState = () => {
	return async (dispatch, getState) => {
		if (typeof localStorage !== "undefined") {
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState().InternalCSTracking));
		}
	}
}

/**
 * Get the state from localStorage and populate the store
 */
export const loadStoredState = () => {
	return async dispatch => {
		if (typeof localStorage !== "undefined") {
			const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
			if (typeof storedState === "string" && storedState.length) {
				let decoded = null;
				try {
					decoded = JSON.parse(storedState);
				} catch (err) {
					//do nothing
				}
				if (decoded && typeof decoded === "object") {
					dispatch(setStateFromStoredState(decoded));
				}
			}
		}
	}
}