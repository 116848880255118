import React from "react";

import { NavLink } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/ClearAll";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Accessorials extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			redirect: null,
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			search: "",
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.deleteAccessorial = this.deleteAccessorial.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleClear = this.handleClear.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		this.handleSearch(true);
	}

	async handleSearch(pageLoad) {
		const { search } = this.state;

		if (!pageLoad && !search.trim()) {
			this.props.setNotification("Search values are required", { variant: "error" });
			return false;
		}

		this.setState({
			show: false,
			loading: true
		});

		let url = `/index.php?p=api&r=json&c=admin&m=accessorials&d=1/20/${search}`;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				const data = response.data;
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: response.data.user,
					result: data.body.result || [],
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading accessorials!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an exception loading accessorials!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getHeaders() {
		return [
			"ID",
			"Name",
			"Code",
			"Actions"
		];
	}
	getColumns(data) {
		return Object.values(data).map((prop, key) => {
			const link = basePath + "/admin/accessorial/" + prop.id;
			return [
				prop.id,
				prop.name,
				prop.code,
				<div className="actions-right">
					<NavLink to={link}>
						<Button
							size="sm"
							color="info"
							style={{ marginRight: "5px" }}
						>
							View/Edit
						</Button>
					</NavLink>
					<Button
						size="sm"
						color="danger"
						style={{ marginRight: "5px" }}
						onClick={() => this.handleDelete(prop.id)}
					>
						Delete
					</Button>
				</div>
			];
		});
	}
	async handlePageClick(page) {
		this.setState({ show: false, loading: true });
		let url = "/index.php?p=api&r=json&c=admin&m=accessorials&d=" + page + "/20";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				this.setState({
					show: true,
					loading: false,
					body:  response.data.body,
					result: response.data.body.result,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the accessorials!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the accessorials!",
				{ variant: "error" }
			);
		}
	}
	getPagination() {
		const delta = 5;
		let currentPage = 1,
			pageCount = 1;

		if (!_.isEmpty(this.state.body)) {
			currentPage = parseInt(this.state.body.current);
			pageCount = parseInt(this.state.body.last);
		}

		let pagination = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" },
			pages = [];

		const separate = (a, b) => [
			a,
			...({
				0: [],
				1: [b],
				2: [a + 1, b]
			}[b - a] || ["...", b])
		];

		pages = Array(delta * 2 + 1)
			.fill()
			.map((_, index) => currentPage - delta + index)
			.filter(page => 0 < page && page <= pageCount)
			.flatMap((page, index, { length }) => {
				if (!index) {
					return separate(1, page);
				}
				if (index === length - 1) {
					return separate(page, pageCount);
				}
				return [page];
			});

		if (pageCount > 1 && !_.isEmpty(pages)) {
			pagination = pages.map((prop, key) => {
				let page = { text: prop };
				if (prop == currentPage) {
					page.active = true;
				} else if (prop === "...") {
					page.disabled = true;
				} else {
					page.onClick = e => this.handlePageClick(prop);
				}
				return page;
			});
		}

		if (currentPage < pageCount) {
			nextPage.onClick = e => this.handlePageClick(currentPage + 1);
		} else {
			nextPage.disabled = true;
		}

		if (currentPage - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(currentPage - 1);
		} else {
			prevPage.disabled = true;
		}

		pagination.push(nextPage);
		pagination.unshift(prevPage);

		return pagination;
	}
	handleClear() {
		this.setState({ search: ""}, () => this.handleSearch(true))
	}
	handleDelete(id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this accessorial?"
					onConfirm={() => this.deleteAccessorial(id)}
					onCancel={() => this.deleteAccessorial()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async deleteAccessorial(id) {
		this.setState({ alert: null });

		if (_.isEmpty(id)) {
			return;
		}

		try {
			const url = "/index.php?p=api&r=json&c=admin&m=deleteAccessorial&d=" + id;
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				let result = this.state.result.filter((prop, key) => prop.id != id);
				this.setState({ result });
			} else {
				this.props.setNotification(
					"There was an error deleting the accessorial!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error deleting the accessorial!",
				{ variant: "error" }
			);
		}
	}
	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch(false);
		}
	}
	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		const link = basePath + "/admin/accessorial/new";
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={7}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											className={classes.marginRight}
										>
											New Accessorial
										</Button>
									</NavLink>
								</GridItem>
								<GridItem xs={5}>
									<GridContainer justify="flex-end">
										<GridItem xs={4} style={{ paddingTop: "8px" }}>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													id: "search",
													name: "search",
													value: this.state.search || "",
													onChange: this.handleInput("search"),
													onKeyPress: this.handleKeyPress
												}}
												white
											/>
										</GridItem>
										<GridItem style={{paddingLeft: "5px"}}>
											<Button
												size="sm"
												color="linkedin"
												onClick={() => this.handleSearch(false)}
											>
												<Search /> Search
											</Button>
										</GridItem>
										<GridItem style={{paddingLeft: "5px"}}>
											<Button
												size="sm"
												color="white"
												onClick={() => this.handleClear()}
											>
												<Clear /> CLear
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} sm={12} md={12}
										className={!_.isEmpty(this.state.result) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.result) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(this.state.result)}
												customCellClasses={[classes.right]}
												customClassesForCells={[3]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[3]}
											/>
										) : (
											<Badge color="info">No Accessorials</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve accessorials from the server"
									/>
								)}
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(Accessorials));
