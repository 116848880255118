import React from "react";
import qs from "qs";

import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FastRewind from "@material-ui/icons/FastRewind";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ImportUnits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            show: false,
            mounted: false,
            loading: true,
            user: null,
            body: null,
            warehouses: [],
            company: "",
            warehouse: "",
            units: ""
        };
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.massImport = this.massImport.bind(this);
        this.getWarehouse = this.getWarehouse.bind(this);
    }

    async componentDidMount() {
        const parsed = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });
        const d = parsed.d ? parsed.d.split("/") : [];
        let id = "";
        if (!_.isEmpty(d)) {
            id = d[0];
        }
        this.setState({
            mounted: true,
            id
        });
        let url = "/index.php?p=api&r=json&c=admin&m=importUnits";
        if (!_.isEmpty(id)) {
            url += "&d=" + id;
        }
        try {
            const response = await axios.get(url);
            const data = response.data;
            if (typeof data !== "string" && !_.isEmpty(data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
                this.setState({
                    show: true,
                    loading: false,
                    user: data.user,
                    body: data.body
                });
                if (!_.isEmpty(data.body.companies)) {
                    this.getWarehouse(data.body.companies[0].id);
                }
            } else {
                this.setState({ loading: false });
                this.props.setNotification("There was an error loading the data!", {
                    variant: "error"
                });
            }
        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
            this.props.setNotification("There was an error loading the data!", {
                variant: "error"
            });
        }
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }



    handleModalOpen(modal) {
        this.setState({ [modal]: true });
    }

    handleModalClose(modal) {
        this.setState({ [modal]: false });
    }

    handleChecked = name => event => {
        this.setState({ [name]: !!event.target.checked });
    };

    handleInput = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === "company") {
            this.getWarehouse(event.target.value);
        }
    }

    async getWarehouse(companyId) {
        try {
            const response = await axios.get("/index.php?p=api&r=json&c=admin&m=getWarehouse&d=" + companyId);
            const data = response.data;
            if (typeof data !== "string" && !_.isEmpty(data.body)) {
                this.props.handleMessages(response);
                this.setState({
                    warehouses: data.body
                });
            } else {
                this.props.setNotification("There was an error loading the warehouses!", {
                    variant: "error"
                });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the warehouses!", {
                variant: "error"
            });
        }
    }

    getCompanies(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.id}
                >
                    {prop.name}
                </MenuItem>
            );
        });
    }

    getWarehouses(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.id}
                >
                    {prop.name}
                </MenuItem>
            );
        });
    }

    handleUpload(files) {
        const units = files[0];
        this.setState({ units });
    }

    async massImport() {
        if (_.isEmpty(this.state.units)) {
            this.props.setNotification("File is required!", {
                variant: "error"
            });
            return;
        }

        let url = "/index.php?p=api&r=json&c=admin&m=importUnits";
        if (!_.isEmpty(this.state.body.warehouse)) {
            url += "&d=" + this.state.body.warehouse.warehouse_id;
        }

        const data = new FormData();

        if (!_.isEmpty(this.state.body.warehouse)) {
            data.append("warehouse", this.state.body.warehouse.warehouse_id);
        } else {
            data.append("warehouse", this.state.warehouse);
            data.append("company", this.state.company);
        }

        data.append("units", this.state.units);

        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        try {
            const response = await axios.post(url, data, config);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
            } else {
                this.props.setNotification("There was an error importing the units!", {
                    variant: "error"
                });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error importing the units!", {
                variant: "error"
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {!_.isEmpty(this.state.id) && (
                    <GridItem xs={12} sm={12} md={12} className={classes.left} style={{ marginTop: "10px" }}>
                        <NavLink to={basePath + "/admin/warehouse/edit/" + this.state.id}>
                            <Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
                                <FastRewind /> Back to Warehouse
                            </Button>
                        </NavLink>
                    </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        {!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.companies) && (
                                            <GridItem xs={12} sm={6} md={4}>
                                                <InputLabel htmlFor="company" className={classes.label}>
                                                    Select Company
                                                </InputLabel>
                                                <br />
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select + " " + classes.requiredSelect
                                                        }}
                                                        value={this.state.company || ""}
                                                        inputProps={{
                                                            id: "company",
                                                            name: "company"
                                                        }}
                                                        onChange={this.handleChange}
                                                    >
                                                        {this.getCompanies(this.state.body.companies)}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                        )}
                                        {!_.isEmpty(this.state.body) && _.isEmpty(this.state.body.warehouse) ? (
                                            <GridItem xs={12} sm={6} md={4}>
                                                <InputLabel htmlFor="warehouse" className={classes.label}>
                                                    Select Warehouse
                                                </InputLabel>
                                                <br />
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select + " " + classes.requiredSelect
                                                        }}
                                                        value={this.state.warehouse || ""}
                                                        inputProps={{
                                                            id: "warehouse",
                                                            name: "warehouse"
                                                        }}
                                                        onChange={this.handleChange}
                                                    >
                                                        {!_.isEmpty(this.state.warehouses) ? (
                                                            this.getWarehouses(this.state.warehouses)
                                                        ) : (
                                                            <MenuItem
                                                                disabled
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                            >
                                                                Select a Company First
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                        ) : (
                                            <GridItem xs={12} sm={6} md={4}>
                                                <InputLabel className={classes.label}>Warehouse</InputLabel>
                                                <br />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "warehouse",
                                                        value: !_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.warehouse) ? this.state.body.warehouse.name : "",
                                                        disabled: true
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                        )}
                                        <GridItem xs={12} sm={6} md={4}>
                                            <a href={apiUrl + "/index.php?p=api&c=handUnit&m=exportTemplate"}>
                                                <Button color="linkedin" style={{ marginRight: "5px" }}>
                                                    Download Template File
                                                </Button>
                                            </a>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h5>Units File</h5>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <Dropzone accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" handleUpload={this.handleUpload.bind(this)} />
                                        </GridItem>
                                        {!_.isEmpty(this.state.units) && (
                                            <GridItem xs={12} sm={12} md={6}>
                                                <aside className={classes.thumbsContainer}>
                                                    <Preview src="#" index={0} extension="csv" handleClick={() => {}} />
                                                </aside>
                                            </GridItem>
                                        )}
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button color="info" style={{ marginRight: "5px" }} onClick={this.massImport}>
                                        Import
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(style)(withSnackbar(ImportUnits));