import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import axios from "~/variables/axios.jsx";
import cx from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ReferenceList extends React.Component {

    static defaultProps = {
        refTypeUrl: '',
        data: [],
        onUpdate: () => {},
        headerWeight: '',
        refTypes: [],
    };

    state = {
        references: this.props.data,
        refTypes: this.props.refTypes || [],
    };

	constructor(props) {
        super(props);
        this.handleNewReference = this.handleNewReference.bind(this);
        this.handleReference = this.handleReference.bind(this);
        this.handleReferenceType = this.handleReferenceType.bind(this);
        this.removeReference = this.removeReference.bind(this);
        this.getReferences = this.getReferences.bind(this);
    }

    async componentDidMount() {

    	if(this.props.refTypeUrl && this.props.refTypeUrl !== '') {

    		let url = this.props.refTypeUrl;

    		try {
	    		const response = await axios.get(url);
	            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
	                this.props.handleMessages(response);
	            }
	            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
	            	let types = response.data.body;
	            	let refTypes = [];
	            	for(var i = 0; i < types.length; i++) {
	            		refTypes.push({value: types[i]});
	            	}
	            	this.setState({refTypes: refTypes});
	            }
	        } catch (error) {
	            console.error(error);
	            this.props.setNotification("There was an error loading the defaults!", { variant: "error" });
	        }
    	}

    }

    /**
     * Handle updates to the component
     * If state has changed, check for and execute the onUpdate callback in props
     * else, if the references stored in props differ from the references in state:
     *     pass props' references into state
     *     (this will occur when the parent componenet re-renders this component)
     * @param  {object} prevProps [description]
     * @param  {object} prevState [description]
     */
    componentDidUpdate(prevProps, prevState) {
    	if(!_.isEqual(prevState, this.state)) { // call state update callback if this state has changed
        	if(typeof this.props.onUpdate === 'function') {
                this.props.onUpdate(this.state);
            }
    	} else if(!_.isEqual(this.props.data, this.state.references)) { // if ReferenceList state hasn't changed, check if props were updated and pass the references in props to state
            this.setState({references: this.props.data});
        }
    }

    handleNewReference() {
    	let refs = this.state.references;
    	this.setState({references: [...refs, {type: '', value: '', required: 0, cust_ref_entry_type: "freeform"}]});
    }

    handleReference(e, key) {
        let refs = _.cloneDeep(this.state.references);
        refs[key].value = e.target.value;
        this.setState({references: refs});
    }

    removeReference(e, key) {
        let refs = _.cloneDeep(this.state.references);
        refs.splice(key, 1);
        this.setState({references: refs});
    }

    handleReferenceType(e, key, val) {
        let refs = _.cloneDeep(this.state.references);
        refs[key].type = val;
        this.setState({references: refs});
    }

    render() {
    	const { classes } = this.props;

        let refTypes = this.state.refTypes;
        if(_.isEmpty(refTypes) && !_.isEmpty(this.props.refTypes)) {
            refTypes = this.props.refTypes;
        }

        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <h5 style={this.props.headerWeight == '' ? {fontWeight: 'lighter'} : {fontWeight: this.props.headerWeight}}>
                                Custom References
                                <Button color="success" size="sm" justIcon round className={classes.marginLeft} onClick={e => this.handleNewReference()}>
                                    <Add/>
                                </Button>
                            </h5>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            {this.getReferences(refTypes)}
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }

    getReferences(refTypes) {
        const { classes } = this.props;
    	return this.state.references.map((ref, key) => {
            return (
                <GridContainer key={"reference_" + key} style={{marginBottom: "15px"}}>
                    <GridItem xs={5} sm={5} md={5} lg={5}>
                        <Tooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">Reference Name</Typography>
                                    {"What is this reference called?"}
                                </React.Fragment>
                            }
                            placement="bottom"
                        >
                            <span>
                                <InputLabel className={classes.label}>Type</InputLabel>
                            </span>
                        </Tooltip>
                        <br />
                        <FormControl fullWidth>
                            {ref.required === '1' ? (
                                <CustomInput
                                    inputProps={{
                                        type: "text",
                                        name: "reference_name_" + key,
                                        value: ref.type,
                                        disabled: true,
                                    }}
                                    id={"reference_name_" + key}
                                />
                            ) : (
                                <CustomInput
                                    inputProps={{
                                        type: "text",
                                        name: "reference_name_" + key,
                                        value: ref.type,
                                        onInputChange: (e, val) => this.handleReferenceType(e, key, val),
                                    }}
                                    id={"reference_name_" + key}
                                    autoComplete
                                    options={refTypes}
                                    white
                                />
                            )}
                        </FormControl>
                    </GridItem>
                    <GridItem xs={5} sm={5} md={6} lg={6}>
                        <Tooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">Reference Value</Typography>
                                    {"Enter your reference here"}
                                </React.Fragment>
                            }
                            placement="bottom"
                        >
                            <span>
                                <InputLabel className={classes.label}>Value</InputLabel>
                            </span>
                        </Tooltip>
                            {(ref.cust_ref_entry_type === undefined || ref.cust_ref_entry_type !== 'select') ? (
                                <FormControl fullWidth>
                                    {ref.required === '1' ? (
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                id: "reference_value_" + key,
                                                name: "reference_value_" + key,
                                                value: ref.value,
                                                onChange: e => {this.handleReference(e, key)},
                                            }}
                                            required
                                        />
                                    ) : (
                                        <CustomInput
                                            inputProps={{
                                                type: "text",
                                                id: "reference_value_" + key,
                                                name: "reference_value_" + key,
                                                value: ref.value,
                                                onChange: e => {this.handleReference(e, key)},
                                            }}
                                            white
                                        />
                                    )}
                                </FormControl>
                            ) : (
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select
                                        value={ref.value || ''}
                                        inputProps={{ name: "cust_ref_entry_opts" }}
                                        onChange={ e => {this.handleReference(e, key)}}
                                        classes={(() => {
                                            let selectNoUpper = {...classes.select}
                                            selectNoUpper.textTransform = 'none';
                                            return {
                                                select: cx({
                                                    [selectNoUpper]: true,
                                                    [classes.requiredSelect]: ref.required === '1'
                                                })
                                            }
                                        })()}
                                    >
                                        {ref.required !== '1' && (
                                            <MenuItem
                                            key={"reference_name_" + key}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value=""
                                        >
                                            <em>None</em>
                                        </MenuItem>
                                        )}
                                        {ref.cust_ref_entry_opts.map((val, key) => {
                                            return (
                                                <MenuItem
                                                    key={"reference_name_" + key}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value={val}
                                                >
                                                    {((_.isEmpty(val)) ? (<span style={{color: 'transparent'}}>none</span>) : val)}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                    </GridItem>
                    <GridItem container xs={2} sm={2} md={1} lg={1} alignItems="center">
                        {ref.required !== '1' && (
                            <Button
                                color="danger" size="sm" justIcon round className={classes.marginLeft} onClick={e => this.removeReference(e, key)}>
                                <Delete/>
                            </Button>
                        )}
                    </GridItem>
                </GridContainer>
            );
        });
    }

}

ReferenceList.propTypes = {
    data: PropTypes.array,
    refTypeUrl: PropTypes.string,
    onUpdate: PropTypes.func,
    headerWeight: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(ReferenceList));
