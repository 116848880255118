import { createSelector } from 'reselect'

const user = state => state.Admin.user;

/**
 * Get a setting from one of the settings objects in user data
 */
const _getUserSetting = (userData, getFrom, setting, def = null) => {
	let result = def;
	if (typeof userData === 'object' && 
		userData !== null && 
		userData[getFrom] &&
		userData[getFrom] !== null &&
		typeof userData[getFrom] === 'object' &&
		Object.keys(userData[getFrom]).indexOf(setting) != -1
	) {
		result = userData[getFrom][setting];
	}
	return result;
}

const _getUserCompanySetting = (userData, setting, def) => {
	return _getUserSetting(userData, "user_company_settings", setting, def);
}

/**
 * Get a setting from user_settings
 */
export const getUserSetting = (setting, def = null) => {
	return createSelector(user, userData => {
		return _getUserSetting(userData, "user_settings", setting, def);
	});
}

/**
 * Get a setting from user_company_settings
 */
export const getUserCompanySetting = (setting, def = null) => {
	return createSelector(user, userData => {
		return _getUserCompanySetting(userData, setting, def);
	});
}

/**
 * Check if the current user has the provided permission
 */
export const hasPermission = permission => {
	return createSelector(user, userData => {
		if (userData && typeof userData.permissions === "object" && userData.permissions !== null) {
			return userData.permissions[permission] ? true : false;
		}
		return false;
	});
}

/**
 * Return the rate display object for the provided mode
 * Or a default object of similar format if not found
 */
export const getRateDisplayByMode = mode => {
	return createSelector(user, userData => {
		if (userData && 
			typeof userData.rate_displays === "object" &&
			userData.rate_displays !== null &&
			typeof userData.rate_displays[mode] === "object" &&
			userData.rate_displays[mode] !== null
		) {
			const rateDisplay = userData.rate_displays[mode];
			if (typeof rateDisplay.alias !== "object" || rateDisplay.alias === null) {
				rateDisplay.alias = {};
			}
			return rateDisplay;
		}
		return {alias:{}};
	});
}

/**
 * Return if the user is an admin in or out of user session
 */
export const userIsAdmin = createSelector(user, userData => {
	if (typeof userData === "object") {
		return userData.level === "admin" || userData.parent_id;
	}
	return false;
});

/**
 * Return if the user is an admin in a user session
 */
export const adminInUserSession = createSelector(user, userData => {
	if (typeof userData === "object") {
		return userData.parent_id ? true : false;
	}
	return false;
});

/**
 * Return if the user is user level and NOT an admin in a user session
 */
export const isNotAdmin = createSelector(user, userData => {
	if (typeof userData === "object") {
		return userData.level == "user" && !userData.parent_id;
	}
	return false;
});


/**
 * Return if the user is a carrier
 */
export const userIsCarrier = createSelector(user, userData => {
	if(typeof userData === "object") return userData.level === "carrier";
	return false;
});

/**
 * Returns the user ID OR parent ID if admin in user session
 */
export const activeUserId = createSelector(user, userData => {
	if (typeof userData === "object") {
		return userData.parent_id ? +userData.parent_id : +userData.id;
	}
	return 0;
});

export const parcelWeightLimit = createSelector(user, userData => {
	const weightLimit = +_getUserCompanySetting(userData, "parcel_weight_limit", 0);
	return weightLimit > 0 ? weightLimit : Infinity;
});

export const addNpFee = createSelector(user, userData => {
	return !!+_getUserCompanySetting(userData, "add_np_fee", 0);
});

export const npFeeAmount = createSelector(user, userData => {
	return +_getUserCompanySetting(userData, "np_fee_amount", 0);
});

export const npFeeType = createSelector(user, userData => {
	return _getUserCompanySetting(userData, "np_fee_type", "flat");
});
