import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    controlBtn: {
        marginTop: 100,
    },
}));


class OptionList extends Component {
    static defaultProps = {
        leftLabel: "Available",
        rightLabel: "Selected",
        sortable: true,
        searchable: true,
        moveLeftIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>{"<"}</span>
        ),
        moveAllLeftIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>{"<<"}</span>
        ),
        moveRightIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>{">"}</span>
        ),
        moveAllRightIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>{">>"}</span>
        ),
        moveUpIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
                &#xffea;
            </span>
        ),
        moveTopIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
                &#x21c8;
            </span>
        ),
        moveDownIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
                &#xffec;
            </span>
        ),
        moveBottomIcon: (
            <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
                &#x21ca;
            </span>
        ),
        leftSearchPlaceholder: "Search available options",
        rightSearchPlaceholder: "Search selected options",
    };

    state = {
        searchStringLeft: "",
        searchStringRight: "",
        leftSelected: [],
        rightSelected: [],
        valo: []
    };






    onLeftSearch = (event) => {
        this.setState({ searchStringLeft: event.target.value });
    };

    onRightSearch = (event) => {
        this.setState({ searchStringRight: event.target.value });
    };

    handleToggleAllLeft(items, e) {
        const values = [];
        for (let i = 0, l = items.length; i < l; i++) {
            if (e.target.checked) {
                values.push(items[i]);
            } else {
                values.pop(items[i]);
            }
        }
        this.setState({
            leftSelected: values,
        });
    }

    handleToggleLeft(value) {
        const currentIndex = this.state.leftSelected.indexOf(value);
        const newChecked = [...this.state.leftSelected];

        if ((value != "pro_number") && (value != "invoice_number")) {

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            this.setState({
                leftSelected: newChecked,
            });

        }


    }

    handleToggleAllRight(items, e) {
        const values = [];
        for (let i = 0, l = items.length; i < l; i++) {
            if (e.target.checked) {
                values.push(items[i]);
            } else {
                values.pop(items[i]);
            }
        }
        this.setState({
            rightSelected: values,
        });
    }

    handleToggleRight(value) {
        const currentIndex = this.state.rightSelected.indexOf(value);
        const newChecked = [...this.state.rightSelected];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            rightSelected: newChecked,
        });
    }

    onMoveAllRight = () => {
        const { selected, onMove } = this.props;
        const selectedOptions = selected.map((op) => op.title);
        selectedOptions.splice(0, 2);
        onMove(selectedOptions);
        this.setState({ leftSelected: [] });
    };


    onMoveRight = () => {
        const { available, onMove, selected } = this.props;
        const selectedOptions = [...available, ...this.state.leftSelected];
        selectedOptions.filter(
            (op) => op === "Invoice#" || op === "Pro#"  ? selectedOptions.splice(selectedOptions.indexOf("Invoice#"), 1) : op
        );


        onMove(selectedOptions);
        this.setState({ leftSelected: [] });


    };


    onMoveLeft = () => {
        const { available, onMove, selected } = this.props;
        const selectedOptions = available.filter(
            (op) => !this.state.rightSelected.includes(op)
        );
        onMove(selectedOptions);
        this.setState({ rightSelected: [] });


    };

    onMoveAllLeft = () => {
        const { onMove } = this.props;
        onMove([]);
        this.setState({ rightSelected: [] });
    };

    onMoveUp = () => {
        const { selected, onMove } = this.props;
        const newSelected = selected;
        const currentIndex = selected.indexOf(this.state.rightSelected[0]);
        newSelected.splice(currentIndex, 1);
        newSelected.splice(currentIndex - 1, 0, this.state.rightSelected[0]);
        onMove(newSelected);
    };

    onMoveAllUp = () => {
        const { selected, onMove } = this.props;
        const newSelected = selected;
        const currentIndex = selected.indexOf(this.state.rightSelected[0]);
        newSelected.splice(currentIndex, 1);
        newSelected.splice(0, 0, this.state.rightSelected[0]);
        onMove(newSelected);
    };

    onMoveDown = () => {
        const { selected, onMove } = this.props;
        const newSelected = selected;
        const currentIndex = selected.indexOf(this.state.rightSelected[0]);
        newSelected.splice(currentIndex, 1);
        newSelected.splice(currentIndex + 1, 0, this.state.rightSelected[0]);
        onMove(newSelected);
    };

    onMoveAllDown = () => {
        const { selected, onMove } = this.props;
        const newSelected = selected;
        const currentIndex = selected.indexOf(this.state.rightSelected[0]);
        newSelected.splice(currentIndex, 1);
        newSelected.splice(newSelected.length, 0, this.state.rightSelected[0]);
        onMove(newSelected);
    };
    not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    intersection(a, b) {
        return a.filter((value) => {
            return b.indexOf(value) !== -1;
        });
    }

    union(a, b) {
        return [...a, ...this.not(b, a)];
    }

    renderLeftList = () => {
        const { available, selected } = this.props;
        const visibleOptions = selected.filter((op) => {
            if (
                this.state.searchStringLeft &&
                !op.title
                    .toLowerCase()
                    .includes(this.state.searchStringLeft.toLowerCase())
            ) {
                return false;
            } else if (available.includes(op.title)) {
                return false;
            }
            return true;
        });
        return (
            <>
                <Card>
                    <Grid container>
                        {/* <Grid item xs={2}>
                            <Checkbox
                                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                onClick={(e) => this.handleToggleAllLeft(selected, e)}
                                checked={
                                    this.state.leftSelected.length === selected.length &&
                                    selected.length !== 0
                                }
                            />
                        </Grid> */}
                        <Grid item xs={5} style={{ paddingLeft: "15px" }}>
                            {visibleOptions.length + " Items "}
                        </Grid>
                        <Grid item xs={5}>

                        </Grid>
                    </Grid>

                    <FormControl fullWidth variant="filled">
                        <TextField
                            label="Search"
                            id="outlined-size-small"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            width="200%"
                            onChange={this.onLeftSearch}
                        />
                    </FormControl>
                    <Divider />
                    <Paper style={{ maxHeight: 200 }}>
                        <List>
                            <div
                                style={{
                                    maxHeight: "200px",
                                    minHeight: "200px",
                                    minWidth: "200px",
                                    overflow: "auto",
                                }}
                                role="list"
                            >
                                {visibleOptions.map((op) => {
                                    return (
                                        <div
                                            key={op.title}
                                            role="listitem"
                                            onClick={() => this.handleToggleLeft(op.title)}

                                        >


                                            <Checkbox
                                                checked ={
                                                    ((op.field) === "pro_number" ||  (op.title) === "Invoice#") ?  this.state.leftSelected.indexOf(op.field) === -1  :
                                                    this.state.leftSelected.indexOf(op.title) !== -1

                                                }
                                                disabled =
                                                {
                                                  (op.field) === "pro_number" ||  (op.field) === "invoice_number" ? true : false
                                                }

                                                tabIndex={-1}


                                            />
                                            {op.title}
                                        </div>
                                    );
                                })}
                            </div>
                        </List>
                    </Paper>
                </Card>
            </>
        );
    };

    renderRightList = () => {
        const { available, selected } = this.props;
        const selectedOptions = [];

        available.forEach((selection) => {
            if (
                !this.state.searchStringRight ||
                (this.state.searchStringRight &&
                    selection
                        .toLowerCase()
                        .includes(this.state.searchStringRight.toLowerCase()))
            ) {
                selectedOptions.push(selected.find((av) => av.title === selection));
            }
        });
        return (
            <>
                <Card>
                    <Grid container>
                        {/* <Grid item xs={2}>
                            <Checkbox
                                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                onClick={(e) => this.handleToggleAllRight(available, e)}
                                checked={
                                    this.state.rightSelected.length === available.length &&
                                    available.length !== 0
                                }
                            />
                        </Grid> */}
                        <Grid item xs={5} style={{ paddingLeft: "15px" }}>
                            {selectedOptions.length + " Selected          "}
                        </Grid>
                        <Grid item xs={5}>

                        </Grid>
                    </Grid>

                    <FormControl fullWidth variant="filled">
                        <TextField
                            label="Search"
                            id="outlined-size-small"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            onChange={this.onRightSearch}
                        />
                    </FormControl>
                    <Divider />
                    <Paper style={{ maxHeight: 200 }}>
                        <List>
                            <div
                                style={{
                                    maxHeight: "200px",
                                    minHeight: "200px",
                                    minWidth: "200px",
                                    overflow: "auto",
                                }}
                                role="list"
                            >
                                {selectedOptions.map((op) => {

                                    if (op != undefined) {
                                        return (

                                            <div
                                                key={op.field}
                                                role="listitem"
                                                onClick={() => this.handleToggleRight(op.title)}
                                            >
                                                <Checkbox
                                                    checked={
                                                        this.state.rightSelected.indexOf(op.title) !== -1
                                                    }
                                                    tabIndex={-1}
                                                />
                                                {op.title}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </List>
                    </Paper>
                </Card>
            </>
        );
    };

    render() {

        const {
            leftLabel,
            rightLabel,
            sortable,
            searchable,
            moveLeftIcon,
            moveAllLeftIcon,
            moveRightIcon,
            moveAllRightIcon,
            moveUpIcon,
            moveTopIcon,
            moveDownIcon,
            moveBottomIcon,
            available,
            selected,
            leftSearchPlaceholder,
            rightSearchPlaceholder,
            classes,
        } = this.props;

        if (selected.length < 1) return <div></div>

        return (
            <>
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <Paper className={classes.paper}> {this.renderRightList()}</Paper>
                        </Grid>
                        <Grid item xs={1} style={{ marginTop: "100px" }}>
                            <Paper className={classes.paper}>
                                {" "}
                                <Button onClick={this.onMoveAllRight}>{moveAllLeftIcon}</Button>
                                <Button onClick={this.onMoveRight}>{moveLeftIcon}</Button>
                                <Button onClick={this.onMoveLeft}>{moveRightIcon}</Button>
                                <Button onClick={this.onMoveAllLeft}>
                                    {moveAllRightIcon}
                                </Button>



                            </Paper>

                        </Grid>
                        <Grid item xs={5}>
                            <Paper className={classes.paper}> {this.renderLeftList()}</Paper>
                        </Grid>

                        <Grid item xs={1} style={{ marginTop: "100px" }}>
                            <Paper className={classes.paper}>
                                {" "}
                                <Button
                                    className="btnmove"
                                    disabled
                                    onClick={this.onMoveAllUp}
                                >
                                    {moveTopIcon}
                                </Button>
                                <Button
                                    className="btnmove"
                                    disabled
                                    onClick={this.onMoveUp}
                                >
                                    {moveUpIcon}
                                </Button>
                                <Button
                                    className="btnmove"
                                    disabled
                                    onClick={this.onMoveDown}
                                >
                                    {moveDownIcon}
                                </Button>
                                <Button
                                    className="btnmove"
                                    disabled
                                    onClick={this.onMoveAllDown}
                                >
                                    {moveBottomIcon}
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }

}

export default OptionList;