const initialState = {
	levels: [],
	loading: false,
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "LEVELS_SET_LEVELS": {
			const levels = Array.isArray(action.val) ? action.val : [];
			return {...newState, levels: levels};
		}
		case "LEVELS_SET_LOADING": {
			return {...newState, loading: !!action.val};
		}
		default:
			return state;
	}
}