import {
  uiColors,
  drawerWidth,
  drawerMiniWidth,
  notificationsWidth,
  transition,
  tfmBlueColor,
  tfmOrangeColor,
  containerFluid,
  whiteColor,
  blackColor,
} from "assets/jss/empire-tms.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    },
    color: whiteColor,
    //backgroundColor: tfmBlueColor,
    "&:a": {
	  	color: uiColors.info.default,
		"&:visited": {
		  color: tfmOrangeColor
		}
	}
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "fixed",
    left: drawerWidth,
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    backgroundColor: whiteColor
  },
  withNotifications: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px - ${notificationsWidth}px)`
    }
  },
  content: {
    marginTop: "5px",
    minHeight: "100%",
    maxWidth: "calc(100% - 30px)"
  },
  contentWrapper: {
    marginTop: "70px",
    minHeight: "calc(100vh - 80px)",
    maxWidth: "100%",
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    left: drawerMiniWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithNotificationsSidebarMini: {
    left: drawerMiniWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px - ${notificationsWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: blackColor,
  },
  infoPaper: {
    padding: theme.spacing(1),
    backgroundColor: uiColors.info.light,
    color: blackColor,
  },
});

export default appStyle;
