import React from "react";
import _ from "lodash";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Checkbox from "@material-ui/core/Checkbox";

import optionStyle from "~/assets/jss/empire-tms/views/tms/optionStyle.jsx";

import OptionListView from "../MultiSelect/OptionListView.jsx";

class OptionStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportType: this.props.reportType,
      columns: [],
      loading: true,
      options: [],
      mounted: false,
      selectedOptions: this.props.selectedOptions,
    };
  }

  handleOptions = (value) => {
    this.setState({ options: value });
  }

  handleSelected = (value) => {
    this.setState({ selectedOptions: value },
      () => {
        this.props.handleStepState(this.state, 'options');
        this.props.handleOptionSelected(value);
      }
    );
  }

  async componentDidMount() {
    this.setState({ mounted: true });

    this.getOptions().then(() => {
      this.props.handleStepState(this.state, 'options');
    });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  

  replaceStr(str, find, replace) {
    for (var i = 0; i < find.length; i++) {
      str = str.replace(new RegExp(find[i], "gi"), replace[i]);
    }
    return str;
  }

  getOptions = async () => {
    let url = "/index.php?p=api&r=json&c=searchtype&m=columnfilters&d=" + this.props.reportType;
    try {
      const response = await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.user) &&
        !_.isEmpty(response.data.body)
      ) {
        if (this.state.mounted) {
          var col = [];
          let selectedOptions = this.state.selectedOptions;
          let selectedOptionIndex = null;
          let userHasRestrictedAccess = response.data.user.user_settings.restrict_report_building_fields;
          response.data.body.map((item) => {
            if(!userHasRestrictedAccess || item.is_restricted==0) {
              col.push({
                value: item.name,
                label: item.aliasname,
              });
            } else if((selectedOptionIndex = selectedOptions.indexOf(item.name)) >= 0) {
              selectedOptions.splice(selectedOptionIndex, 1);
            }
          });
          this.handleSelected(selectedOptions);

          this.setState({
            loading: false,
            options: col,
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error loading the data!" + error,
        {
          variant: "warning",
        }
      );
    }
    return this.state.options;
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <OptionListView
            options={this.state.options}
            selectedOptions={this.state.selectedOptions}
            handleSelected={this.handleSelected}
            handleOptions={this.handleOptions}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(optionStyle)(withSnackbar(OptionStep)));