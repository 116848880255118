import {
	uiColors,
	tooltip,
	cardTitle,
	whiteColor,
	blackColor,
	grayColor
} from "assets/jss/empire-tms.jsx";

import hoverCardStyle from "assets/jss/empire-tms/hoverCardStyle.jsx";

const dashboardStyle = theme => ({
	...hoverCardStyle,
	tooltip,
	cardTitle: {
		...cardTitle,
		marginTop: "0px",
		marginBottom: "3px"
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	cardProductTitle: {
		...cardTitle,
		marginTop: "0px",
		marginBottom: "3px",
		textAlign: "center"
	},
	cardCategory: {
		color: grayColor[0],
		fontSize: "14px",
		paddingTop: "10px",
		marginBottom: "0",
		marginTop: "0",
		margin: "0"
	},
	cardProductDesciprion: {
		textAlign: "center",
		color: grayColor[0]
	},
	stats: {
		color: grayColor[0],
		fontSize: "12px",
		lineHeight: "22px",
		display: "inline-flex",
		"& svg": {
			position: "relative",
			top: "4px",
			width: "16px",
			height: "16px",
			marginRight: "3px"
		},
		"& .fab,& .fas,& .far,& .fal,& .material-icons": {
			position: "relative",
			top: "4px",
			fontSize: "16px",
			marginRight: "3px"
		}
	},
	productStats: {
		paddingTop: "7px",
		paddingBottom: "7px",
		margin: "0"
	},
	successText: {
		color: uiColors.success.default
	},
	upArrowCardCategory: {
		width: 14,
		height: 14
	},
	underChartIcons: {
		width: "17px",
		height: "17px"
	},
	price: {
		color: "inherit",
		"& h4": {
			marginBottom: "0px",
			marginTop: "0px"
		}
	},
	paper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		color: blackColor
	},
	infoPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: uiColors.info.light,
		color: blackColor
	},
	dangerPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: uiColors.danger.dark,
		color: uiColors.danger.default
	},
	warningPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: uiColors.warning.light,
		color: blackColor
	},
	successPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: uiColors.success.dark,
		color: blackColor
	}
});

export default dashboardStyle;
