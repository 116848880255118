import React, { Component, Fragment } from "react";
import Dialog from '../../../components/TMS/Dialog.jsx';
import GridItem from "~/components/Grid/GridItem.jsx";
import InvoiceImageLink from './InvoiceImageLink.jsx';

class ViewImagesModal extends Component {
    render() {
        const images = Array.isArray(this.props.images) ? this.props.images : [];
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} title="View Documents">
                {images.map((image, index) => {
                    return (
                        <GridItem xs={12} key={index} style={{paddingBottom:"12px"}}>
                            <InvoiceImageLink name={image.name} imageId={image.id} />
                        </GridItem>
                    );
                })}
            </Dialog>
        );
    }
}

export default ViewImagesModal;
