import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";

import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import Grid from '@material-ui/core/Grid';
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "~/components/CustomButtons/Button.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class CustomInputModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                keepMounted
                fullWidth={this.props.fullWidth}
                maxWidth='md'
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    {this.props.showCloseIcon && (
                        <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.closeModal()}>
                            <Close className={classes.modalClose} />
                        </Button>
                    )}
                    <h4 className={classes.modalTitle}>{this.props.title}</h4>
                </DialogTitle>

                <GridContainer style={{marginBottom: "6px"}}>
                <GridItem xs={12}>
                        <Card>
                            <CardContent style={{paddingBottom: "6px"}}>
                                    <CustomInput
										formControlProps={{ fullWidth: true }}
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        fullWidth={true}
                                        onChange = {() => this.props.handleInput(this.props.name)}
                                        style={{marginBottom: "6px"}}
                                        inputProps={{
                                            type: this.props.type,
                                            name: this.props.name,
											placeholder: this.props.placeholder,
                                            value: this.props.value || '',
                                            onChange: this.props.handleInput(this.props.name),
                                            margin: "dense",
										    startAdornment: this.props.startAdornment ? <InputAdornment position="start">{this.props.startAdornment}</InputAdornment> : null,
										    endAdornment: this.props.endAdornment ? <InputAdornment position="end">{this.props.endAdornment}</InputAdornment> : null,
                                        }}
                                    />
                                <Grid container justify="flex-end" style={{marginRight: "15px"}}>
                                    <Button
                                        color="success"
                                        size="sm"
                                        onClick={() => this.props.onSubmit()}
                                    >
                                        Save
                                    </Button>
                                    {this.props.showCloseButton && (
                                        <Button
                                            color="danger"
                                            size="sm"
                                            onClick={() => this.props.closeModal()}
                                        >
                                            Close
                                        </Button>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Dialog>
        );
    }
}

export default withStyles(style)(CustomInputModal);