import React, { Component } from "react";

import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class InputTag extends Component {
	render() {
		return (
			<GridContainer style={{marginTop: "10px", marginBottom: "10px"}}>
                <GridItem xs={12}>
                    <InputLabel className={this.props.classes.label || ""}>{this.props.inputLabel}</InputLabel>
                    <Autocomplete
                        loading={this.props.loading}
                        multiple
                        value={this.props.value}
                        options={this.props.options}
                        onChange={this.props.onChange}
                        disableCloseOnSelect
                        groupBy={this.props.groupBy}
                        getOptionLabel={this.props.getOptionLabel}
                        getOptionSelected={this.props.getOptionSelected || undefined}
                        renderOption={this.props.renderOption}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" fullWidth/>
                        )}
                    />
                </GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(InputTag));