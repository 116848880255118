import React from 'react';

// @material-ui/core components
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";

// 3rd party
import {
    addDays,
    startOfWeek,
    endOfWeek,
    addWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfYear,
    endOfYear,
    addYears,
  } from 'date-fns';
import { DateRangePicker as _DateRangePicker } from "materialui-daterange-picker";

// lib
import moment from "~/lib/moment.js";


const today = new Date();
const predefinedRanges = {
	'Today': {
		startDate: moment(today).startOf('day'),
		endDate: moment(today).startOf('day'),
	},
	'Yesterday': {
		startDate: moment(addDays(today, -1)).startOf('day'),
		endDate: moment(addDays(today, -1)).startOf('day'),
	},
	'This Week' : {
		startDate: moment(startOfWeek(today)).startOf('day'),
		endDate: moment(endOfWeek(today)).startOf('day'),
	},
	'Last Week': {
		startDate: moment(startOfWeek(addWeeks(today, -1))).startOf('day'),
		endDate: moment(endOfWeek(addWeeks(today, -1))).startOf('day'),
	},
	'Last 7 Days': {
		startDate: moment(addWeeks(today, -1)).startOf('day'),
		endDate: moment(today).startOf('day'),
	},
	'Last 30 Days': {
		startDate: moment(addDays(today, -30)).startOf('day'),
		endDate: moment(today).startOf('day'),
	},
	'This Month': {
		startDate: moment(startOfMonth(today)).startOf('day'),
		endDate: moment(endOfMonth(today)).startOf('day'),
	},
	'Last Month': {
		startDate: moment(startOfMonth(addMonths(today, -1))).startOf('day'),
		endDate: moment(endOfMonth(addMonths(today, -1))).startOf('day'),
	},
	'This Year': {
		startDate: moment(startOfYear(today)).startOf('day'),
		endDate: moment(endOfYear(today)).startOf('day'),
	},
	'Last Year': {
		startDate: moment(startOfYear(addYears(today, -1))).startOf('day'),
		endDate: moment(endOfYear(addYears(today, -1))).startOf('day'),
	},
}

const aliases = [
	['Current Day', 'Today'],
	['Previous Day', 'Yesterday'],
	['Current Week', 'This Week'],
	['Past Week', 'Last Week'],
	['Past 7 Days', 'Last 7 Days'],
	['Past 30 Days', 'Last 30 Days'],
	['Current Month', 'This Month'],
	['Past Month', 'Last Month'],
	['Current Year', 'This Year'],
	['Past Year', 'Last Year'],
];

for (const labels of aliases) {
	predefinedRanges[labels[0]] = predefinedRanges[labels[1]];
}


function compileRangeOptions(customRanges) {
    const rangeOptions = [];
    for (const range of customRanges) {
        if (typeof range === 'object') {
            rangeOptions.push(range)
        } else if (typeof range === 'string' && range in predefinedRanges) {
            const { startDate, endDate } = predefinedRanges[range];
            rangeOptions.push({
                label: range,
                startDate,
                endDate
            });
        }
    }
    return rangeOptions;
}


function matchDateRange(startDate, endDate, rangeOptions) {
    for (const range of rangeOptions) {
        if (range.startDate.dateIsSame(startDate) && range.endDate.dateIsSame(endDate)) {
            return range;
        }
    }
    return null;
}


const defaultProps = {
    onChange: (dates) => {},
    dateFormat: 'MM/DD/YYYY',
    rangeOptions: ['Today', 'Yesterday', 'This Week', 'Last Week', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'This Year', 'Last Year'],
    initialRangeSelection: null,
    initialStartDate: null,
    initialEndDate: null,
    minDate: null,
    maxDate: null,
    width: null,
    disabled: false
};


const DateRangePicker = ({ onChange, dateFormat, rangeOptions, initialRangeSelection, initialStartDate, initialEndDate, minDate, maxDate, width, disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayText, setDisplayText] = React.useState('');
    const [initialDateRange, _setInitialDateRange] = React.useState({startDate: null, endDate: null});
    const setInitialDateRange = (range) => {
        if (!(range.startDate.isValid() && range.endDate.isValid())) {
            _setInitialDateRange(null);
            return;
        }
        range.startDate = range.startDate.toDate();
        range.endDate = range.endDate.toDate();
        _setInitialDateRange(range);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDatePick = (range) => {
        const rangeSelection = range.label || null;
        const startDate = moment(range.startDate).startOf('day');
        const endDate = moment(range.endDate).endOf('day');
        const sameDay = startDate.dateIsSame(endDate);

        let _displayText = '';
        if (rangeSelection) {
            _displayText = rangeSelection;
        } else {
            _displayText = sameDay ?
                startDate.format(dateFormat)
                :
                startDate.format(dateFormat) + ' - ' + endDate.format(dateFormat);
        }
        onChange({
            rangeSelection,
            startDate,
            endDate,
            sameDay,
            empty: false
        });
        setDisplayText(_displayText);
    }

    const onKeyDown = (event) => {
        if (event.key === "Backspace" || event.key === "Delete") {
            const dates = {
                rangeSelection: null,
                startDate: moment(null),
                endDate: moment(null),
                sameDay: false,
                empty: true
            };
            onChange(dates);
            setDisplayText('');
            handleClose();
        }
    }

    // on component mount
    rangeOptions = compileRangeOptions(rangeOptions);
    React.useEffect(() => {
        if (initialRangeSelection) {
            const definedDateRange = rangeOptions.find(r => r.label === initialRangeSelection);
            if (definedDateRange) {
                setDisplayText(definedDateRange.label);
                setInitialDateRange(definedDateRange);
                return;
            }
        }

        [initialStartDate, initialEndDate] = [moment(initialStartDate), moment(initialEndDate)];
        if (!initialStartDate.isValid()) {
            return;
        }
        if (!initialEndDate.isValid()) {
            initialEndDate = initialStartDate.clone();
        }
        if (initialStartDate && initialEndDate) {
            const definedDateRange = matchDateRange(initialStartDate, initialEndDate, rangeOptions);
            if (definedDateRange) {
                setDisplayText(definedDateRange.label);
                setInitialDateRange(definedDateRange);
                return;
            }
        }

        let _displayText = initialStartDate.format(dateFormat);
        if (!initialEndDate.isValid()) {
            initialEndDate = initialStartDate.clone();
        }
        if (!initialStartDate.dateIsSame(initialEndDate)) {
            _displayText += ' - ' + initialEndDate.format(dateFormat);
        }
        setDisplayText(_displayText);
        setInitialDateRange({startDate: initialStartDate, endDate: initialEndDate});
    }, [initialRangeSelection, initialStartDate, initialEndDate]);

    const placeHolder = `${dateFormat} - ${dateFormat}`;
    const open = !disabled && Boolean(anchorEl);
    const id = open ? 'dp-popover' : undefined;

    return (
        <div>
            <TextField
                readOnly
                value={displayText}
                placeholder={placeHolder}
                onClick={handleClick}
                InputProps={{
                    endAdornment: (
                        <InputAdornment>
                            <IconButton
                                aria-describedby={id}
                                onClick={handleClick}
                                disabled={disabled}
                            >
                                <DateRangeIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    onKeyDown
                }}
                style={{ width: width || placeHolder.length * 10 + 48 }}
                disabled={disabled}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onKeyDown={onKeyDown}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        handleClose();
                    } 
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <_DateRangePicker
                    open={open}
                    toggle={handleClose}
                    onChange={onDatePick}
                    definedRanges={rangeOptions}
                    minDate={minDate}
                    maxDate={maxDate}
                    initialDateRange={initialDateRange}
                />
            </Popover>
        </div>
    );
}

DateRangePicker.defaultProps = defaultProps;


export default DateRangePicker;