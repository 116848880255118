import React from "react";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CarrierBillContainer from "../../CustomerPortal/containers/CarrierBillContainer.jsx"
import jsPDF from 'jspdf'
import html2canvas from "html2canvas";


class CarrierInvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      body: null,
      exportOptions: null,
      pro_number: this.props.location.state.pro_number,
    };

    this.generatePDF = this.generatePDF.bind(this);

  }

  async componentDidMount() {
    this.setState({ mounted: true });
  }


  generatePDF = e => {
    let doc = new jsPDF("p", "mm", "a4");
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();


    html2canvas( document.querySelector("#capture")).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.setFontSize(15);


      pdf.addImage(imgData, 'PNG', 0, 10, width, height);
      pdf.text(5, 10, "Invoice Detail");
      pdf.save("Invoice_Detail.pdf");
    });

  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  render() {
    return (

      <div>

        <div style={{ paddingLeft: "20px", paddingTop : "25px" }}>
          <button
            className="k-button k-primary"
            onClick={this.generatePDF}

          >
            Export to PDF
          </button>
        </div>

        <div id="capture" style={{ paddingBottom : "25px" }} >
          <CardBody  >
            <CarrierBillContainer id={this.state.pro_number} />
          </CardBody>

        </div>
      </div>
    );
  }
}

export default withStyles(archivedBillsOfLadingStyle)(
  withSnackbar(CarrierInvoiceDetail)
);
