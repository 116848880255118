import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MUIDialog from "@material-ui/core/Dialog";
import MUIDialogTitle from "@material-ui/core/DialogTitle";
import MUIDialogContent from "@material-ui/core/DialogContent";
import MUIDialogActions from "@material-ui/core/DialogActions";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import modalStyle from "~/assets/jss/empire-tms/modalStyle.jsx";
import Fade from "@material-ui/core/Fade";

const style = theme => {
    return modalStyle(theme);
}

class Dialog extends Component {
    render() {
        return (
            <MUIDialog
                fullScreen={this.props.fullScreen || false}
                open={this.props.open}
                TransitionComponent={this.props.TransitionComponent ? this.props.TransitionComponent : Fade}
                fullWidth={this.props.fullWidth ? this.props.fullWidth : false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "sm"}
            >
                <MUIDialogTitle disableTypography className={this.props.classes.modalHeader}>
                    <Button justIcon key="close" aria-label="Close" color="transparent" onClick={this.props.onClose} className={this.props.classes.modalCloseButton}>
                        <Close />
                    </Button>
                    <center><h4>{this.props.title}</h4></center>
                </MUIDialogTitle>
                <MUIDialogContent className={this.props.classes.modalBody} style={{paddingBottom:"12px"}}>
                    <GridContainer>
                        {this.props.children}
                    </GridContainer>
                </MUIDialogContent>
                <MUIDialogActions className={this.props.classes.modalFooter}>
                    {this.getActions()}
                    <Button onClick={this.props.onClose} color="danger" style={{ marginRight: "5px" }}>
                        Close
                    </Button>
                </MUIDialogActions>
            </MUIDialog>
        );
    }

    getActions = () => {
        let actions = null;
        if (Array.isArray(this.props.actions)) {
            actions = this.props.actions.map((action, index) => {
                return <Button key={index} {...action}>{action.title}</Button>
            })
        }
        return actions;
    }
}

export default withStyles(style)(Dialog);
