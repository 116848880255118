import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';

class BillOfLadingNoteInput extends Component {
    render() {
        return (
            <TextField
                label={this.props.label || ""}
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                onChange={this.props.onChange}
                style={{marginBottom: "6px"}}
                value={this.props.value || ""}
                disabled={this.props.disabled}
                inputProps={{maxLength: 1000}}
            />
        );
    }
}

export default BillOfLadingNoteInput;