import React from "react";
import axios from "~/variables/axios.jsx";
import { useDispatch } from "react-redux";
import { handleMessages } from '~/redux/actions/notifications.jsx';

import { uiColors } from "~/assets/jss/empire-tms.jsx"; //needed for star/favorite colors because mui Icon colors are weird

import Button from "~/components/CustomButtons/Button.jsx";
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';

export default function Favorites({
	id,
	filters,
	favorites,
	tableName,
	favoriteItem
}) {
	const dispatch = useDispatch();
	const setFavoriteItem = async () => {
		const url = `/index.php?p=api&r=json&c=admin&m=toggleFavorite&table_name=${tableName}&record_id=${id}`;
		let result = {
			favorites: [],
			doSearch: true, 
		};
	
		await axios.get(url).then((res) => {
			dispatch(handleMessages(res));
			if (typeof res.data !== "string" && !_.isEmpty(res.data.body) && res.data.body.favorites) {
				result.favorites = res.data.body.favorites.map((item) => {
					 item.favorite_item = true;
					 return item;
				});

				filters.forEach(filter => {
					if (!filter.value.trim()) result.doSearch = false;
				});
				favoriteItem(result);
			} 
		});
	}

	return(
		<>
			{favorites.includes(id) ? (
				<Button justIcon round size="sm" color="transparent" onClick={() => setFavoriteItem()}>
					<Star stroke={"black"} stroke-width={1} style={{ color: uiColors.yellow.light}}/>
				</Button>
			) : (
				<Button justIcon round size="sm" color="transparent" onClick={() => setFavoriteItem()}>
					<StarBorder style={{ color: "black"}}/>
				</Button>
			)}
		</>
	)
}
