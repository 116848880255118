import React, { Fragment } from "react";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import moment from "moment";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class UserPortalSettingsContainer extends React.Component {
	handleDatetime = (name, m) => {
        this.props.handleDatetime(name, m);
    }
	getSpecificHoldCodeSettings = (holdCodes) => {
        const { classes } = this.props;
		const holdCodeRows = [];
		let key = 0;
		for(const holdCode in holdCodes) {
			holdCodeRows.push(
				<GridItem md={12} lg={6} key={key} style={{marginBottom: "10px", paddingLeft: "5px", paddingRight: "5px"}}>
					<Card style={{margin: 0}}>
						<Typography xs={12} style={{fontSize: 15, paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px"}}>
							{holdCodes[holdCode].code} - {holdCodes[holdCode].name}
						</Typography>
                        <CardBody key={holdCodes[holdCode].id} style={{paddingTop: 0, paddingBottom: 0}}>
							<FormControlLabel
								control={
									<Switch
										checked={!!holdCodes[holdCode].read}
										onChange={this.props.handleSpecificHoldCodeSettings(holdCode)}
										value="read"
										classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
									/>
								}
								classes={{ label: classes.label }}
								label="Read"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={!!holdCodes[holdCode].write}
										onChange={this.props.handleSpecificHoldCodeSettings(holdCode)}
										value="write"
										classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
									/>
								}
								classes={{ label: classes.label }}
								label="Write"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={!!holdCodes[holdCode].delete}
										onChange={this.props.handleSpecificHoldCodeSettings(holdCode)}
										value="delete"
										classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
									/>
								}
								classes={{ label: classes.label }}
								label="Delete"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={!!holdCodes[holdCode].decline}
										onChange={this.props.handleSpecificHoldCodeSettings(holdCode)}
										value="decline"
										classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
									/>
								}
								classes={{ label: classes.label }}
								label="Decline"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={!!holdCodes[holdCode].can_mass_release}
										onChange={this.props.handleSpecificHoldCodeSettings(holdCode)}
										value="can_mass_release"
										classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
									/>
								}
								classes={{ label: classes.label }}
								label="Mass Release"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={!!holdCodes[holdCode].requires_gl_code}
										onChange={this.props.handleSpecificHoldCodeSettings(holdCode)}
										value="requires_gl_code"
										classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
									/>
								}
								classes={{ label: classes.label }}
								label="Requires GL Code"
							/>
						</CardBody>
					</Card>
				</GridItem>
			);
			key++;
		}

		return holdCodeRows;
	}

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
					<Typography style={{fontSize: 20, fontWeight: "bold"}}>Customer Portal Settings</Typography>
					{!_.isEmpty(this.props.userHoldSettings) ? (
						<>
							<Typography style={{marginTop: "20px"}}>
								General Hold Code Permissions   <WithTooltip
									title="Specific Hold Code Permissions"
									content="Disabling a top-level, general hold code permission disables the same permission for all hold codes; enabling a specific hold code permission does not override it."
								>
									<InfoOutlined fontSize="small" />
								</WithTooltip>
							</Typography>
							<GridContainer>
								<GridItem>
									<FormControlLabel
										control={
											<Switch
												checked={!!this.props.userHoldSettings.settings.read}
												onChange={this.props.handleGeneralPortalSettings("read")}
												value="read"
												classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
											/>
										}
										classes={{ label: classes.label }}
										label="Read"
									/>
									<FormControlLabel
										control={
											<Switch
												checked={!!this.props.userHoldSettings.settings.write}
												onChange={this.props.handleGeneralPortalSettings("write")}
												value="write"
												classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
											/>
										}
										classes={{ label: classes.label }}
										label="Write"
									/>
									<FormControlLabel
										control={
											<Switch
												checked={!!this.props.userHoldSettings.settings.delete}
												onChange={this.props.handleGeneralPortalSettings("delete")}
												value="delete"
												classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
											/>
										}
										classes={{ label: classes.label }}
										label="Delete"
									/>
									<FormControlLabel
										control={
											<Switch
												checked={!!this.props.userHoldSettings.settings.decline}
												onChange={this.props.handleGeneralPortalSettings("decline")}
												value="decline"
												classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
											/>
										}
										classes={{ label: classes.label }}
										label="Decline"
									/>
								</GridItem>
							</GridContainer>
							<Typography style={{marginTop: "20px", marginBottom: "10px"}}>Specific Hold Code Permissions</Typography>
							<GridContainer>
								{this.getSpecificHoldCodeSettings(this.props.userHoldSettings.settings.hold_codes)}
							</GridContainer>
							<Typography style={{marginTop: "20px"}}>Held Bill Reminders</Typography>
							<GridContainer>
								<GridItem  xs={12} sm={2} md={2} >
									<FormControlLabel
										control={
											<Switch
												checked={!!this.props.notificationActive}
												onChange={this.props.handleEmailToggle}
												value="notificationActive"
												classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
											/>
										}
										classes={{ label: classes.label }}
										label="Active"
									/>
								</GridItem>
								<GridItem xs={12} sm={4} md={4} >
									<InputLabel className={classes.label}>Frequency</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{ select: classes.select }}
											value={this.props.notificationFrequency || ""}
											inputProps={{
												id: "notificationFrequency",
												name: "notificationFrequency"
											}}
											onChange={this.props.handleChange}
										>
											<MenuItem
												classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
												value="Daily"
											>
												Daily
											</MenuItem>
											<MenuItem
												classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
												value="Weekly"
											>
												Weekly
											</MenuItem>
											<MenuItem
												classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
												value="Monthly"
											>
												Monthly
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={4} md={4} >
									<InputLabel className={classes.label}>Next Run Date/Time</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											timeFormat
											value={moment(this.props.notificationNextRun) || ""}
											onChange={m => this.handleDatetime("notificationNextRun", m)}
											className={classes.datetime}
											readOnly="true"
											inputProps={{
												id: "notificationNextRun",
												name: "notificationNextRun",
												placeholder: "Next Run Time"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={2} md={2} >
									{this.props.notificationLastRun && (
										<Fragment>
											<InputLabel className={classes.label}>Last Run</InputLabel>
											<br />
											<span>{this.props.notificationLastRun}</span>
										</Fragment>
									)}
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem>
									<Typography style={{marginTop: "20px"}}>View TFM Invoices</Typography>
									<GridItem>
										<FormControlLabel
											control={
												<Switch
													checked={!!this.props.userHoldSettings.settings.view_tfm_invoices}
													onChange={this.props.handleGeneralPortalSettings("view_tfm_invoices")}
													value="viewTfmInvoices"
													classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
												/>
											}
											classes={{ label: classes.label }}
											label="Active"
										/>
									</GridItem>
								</GridItem>
								<GridItem>
									<Typography style={{marginTop: "20px"}}>
										Hide TFM Invoice Amounts   <WithTooltip
											title="Hide TFM Invoice Amounts"
											content='Enabling this option will hide the "Amount" column located on the View TMS Invoices landing page.'
										>
											<InfoOutlined fontSize="small" />
										</WithTooltip>
									</Typography>
									<GridItem>
										<FormControlLabel
											control={
												<Switch
													checked={!!this.props.userHoldSettings.settings.hide_tfm_invoice_amounts}
													onChange={this.props.handleGeneralPortalSettings("hide_tfm_invoice_amounts")}
													value="hideTfmInvoiceAmounts"
													classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
												/>
											}
											classes={{ label: classes.label }}
											label="Active"
										/>
									</GridItem>
								</GridItem>
								<GridItem>
									<Typography style={{marginTop: "20px"}}>
										Change TFM Invoice Locations   <WithTooltip
											title="Change TFM Invoice Locations"
											content="Enabling this option allows a user to change the TFMC Company Location assigned to any bill on hold for review in the Customer Portal. The user may choose from ANY location associated with the bill's Broker ID, regardless of their configured TFMC Location restrictions. The user may NOT change the TFMC Broker ID on any bills."
										>
											<InfoOutlined fontSize="small" />
										</WithTooltip>
									</Typography>
									<GridItem>
										<FormControlLabel
											control={
												<Switch
													checked={!!this.props.userHoldSettings.settings.hold_change_locations}
													onChange={this.props.handleGeneralPortalSettings("hold_change_locations")}
													value="changeHeldBillLocation"
													classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
												/>
											}
											classes={{ label: classes.label }}
											label="Active"
										/>
									</GridItem>
								</GridItem>
								<GridItem>
									<Typography style={{marginTop: "20px"}}>
										Hide Run Standard Reports   <WithTooltip
											title="Hide Run Standard Reports"
											content="When enabled, the Run Standard Reports option will not be available in the customer portal for this user."
										>
											<InfoOutlined fontSize="small" />
										</WithTooltip>
									</Typography>
									<GridContainer>
										<GridItem>
											<FormControlLabel
												control={
													<Switch
														checked={!!this.props.userHoldSettings.settings.hide_run_std_rpts}
														onChange={this.props.handleGeneralPortalSettings("hide_run_std_rpts")}
														value="hide_run_std_rpts"
														classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
													/>
												}
												classes={{ label: classes.label }}
												label="Hide"
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem>
									<Typography style={{marginTop: "20px"}}>User Restriction Settings.</Typography>
									<GridContainer>
										<GridItem>
											<Typography style={{marginTop: "10px"}}>
												Restrict Payment Info Access  <WithTooltip
												title="Restrict Payment Info Access"
												content="When enabled, View Held Bill and View Carrier Bill views for non-carrier users will exclude the 'Amount'field from both the Customer and Carrier payment information in the Financial Info panel."
											>
												<InfoOutlined fontSize="small" />
											</WithTooltip>
											</Typography>
											<GridItem>
												<FormControlLabel
													control={
														<Switch
															checked={!!this.props.userHoldSettings.settings.restrict_payment_info_access}
															onChange={this.props.handleGeneralPortalSettings("restrict_payment_info_access")}
															value="restrictPaymentInfoAccess"
															classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
														/>
													}
													classes={{ label: classes.label }}
													label="Active"
												/>
											</GridItem>
										</GridItem>
										<GridItem>
											<Typography style={{marginTop: "10px"}}>
												Restrict Shipment Products Access  <WithTooltip
												title="Restrict Shipment Products Access"
												content="When enabled, View Held Bill and View Carrier Bill pages will hide the Handling units, products on the units, and loose items from the Product Detail pane."
											>
												<InfoOutlined fontSize="small" />
											</WithTooltip>
											</Typography>
											<GridItem>
												<FormControlLabel
													control={
														<Switch
															checked={!!this.props.userHoldSettings.settings.restrict_shipment_products_access}
															onChange={this.props.handleGeneralPortalSettings("restrict_shipment_products_access")}
															value="restrictShipmentProductsAccess"
															classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
														/>
													}
													classes={{ label: classes.label }}
													label="Active"
												/>
											</GridItem>
										</GridItem>
										<GridItem>
											<Typography style={{marginTop: "10px"}}>
												Restrict Carrier Document Access  <WithTooltip
												title="Restrict Carrier Document Access"
												content="When enabled, users should not see or have access to any carrier document images from TFMC that are classified as an invoice ('INV') or not classified at all."
											>
												<InfoOutlined fontSize="small" />
											</WithTooltip>
											</Typography>
											<GridItem>
												<FormControlLabel
													control={
														<Switch
															checked={!!this.props.userHoldSettings.settings.restrict_carrier_document_access}
															onChange={this.props.handleGeneralPortalSettings("restrict_carrier_document_access")}
															value="restrictCarrierDocumentAccess"
															classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
														/>
													}
													classes={{ label: classes.label }}
													label="Active"
												/>
											</GridItem>
										</GridItem>
										<GridItem>
											<Typography style={{marginTop: "10px"}}>
												Report Building Field Restrictions  <WithTooltip
												title="Report Building Field Restrictions"
												content="When enabled, users will be unable to include certain charge information when using the Customer Reporting Tool. This does not effect existing reports, but does prevent the fields from being added to new reports. The restricted fields generally relate to carrier billing/charge amounts."
											>
												<InfoOutlined fontSize="small" />
											</WithTooltip>
											</Typography>
											<GridItem>
												<FormControlLabel
													control={
														<Switch
															checked={!!this.props.userHoldSettings.settings.restrict_report_building_fields}
															onChange={this.props.handleGeneralPortalSettings("restrict_report_building_fields")}
															value="restrictReportBuildingFields"
															classes={{ switchBase: classes.switchBase, checked: classes.switchChecked }}
														/>
													}
													classes={{ label: classes.label }}
													label="Active"
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</>
					) : (
						<Spinner loading={true} message="Failed to retrieve customer portal settings from the server" />
					)}
                </GridItem>
			</GridContainer>
        );
    }
}
export default withStyles(style)(UserPortalSettingsContainer);