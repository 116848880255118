const initialState = {
	id: null,
	loading: false,
	name: "",
	rated: false,
	true_cost: false,
	true_cost_minus_markup: false,
	benchmark: false,
	discount: false,
	min: false,
	gross: false,
	accessorials: false,
	fuel: false,
	only_show_chosen_carrier: false,
	transit_calendar_days: false,
	rated_alias: null,
	true_cost_alias: null,
	true_cost_minus_markup_alias: null,
	benchmark_alias: null,
	discount_alias: null,
	min_alias: null,
	gross_alias: null,
	collection: [],
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_RATE_DISPLAY_ID": {
			return {...newState, id: action.val}
		}
		case "SET_RATE_DISPLAY_LOADING": {
			return {...newState, loading: action.val}
		}
		case "SET_RATE_DISPLAY_NAME": {
			return {...newState, name: action.val}
		}
		case "SET_RATE_DISPLAY_RATED": {
			return {...newState, rated: action.val}
		}
		case "SET_RATE_DISPLAY_TRUE_COST": {
			return {...newState, true_cost: action.val}
		}
		case "SET_RATE_DISPLAY_TRUE_COST_MINUS_MARKUP": {
			return {...newState, true_cost_minus_markup: action.val}
		}
		case "SET_RATE_DISPLAY_BENCHMARK": {
			return {...newState, benchmark: action.val}
		}
		case "SET_RATE_DISPLAY_DISCOUNT": {
			return {...newState, discount: action.val}
		}
		case "SET_RATE_DISPLAY_MIN": {
			return {...newState, min: action.val}
		}
		case "SET_RATE_DISPLAY_GROSS": {
			return {...newState, gross: action.val}
		}
		case "SET_RATE_DISPLAY_ACCESSORIALS": {
			return {...newState, accessorials: action.val}
		}
		case "SET_RATE_DISPLAY_FUEL": {
			return {...newState, fuel: action.val}
		}
		case "SET_RATE_DISPLAY_ONLY_SHOW_CHOSEN_CARRIER": {
			return {...newState, only_show_chosen_carrier: action.val}
		}
		case "SET_RATE_DISPLAY_TRANSIT_CALENDAR_DAYS": {
			return {...newState, transit_calendar_days: action.val}
		}
		case "SET_RATE_DISPLAY_RATED_ALIAS": {
			return {...newState, rated_alias: action.val}
		}
		case "SET_RATE_DISPLAY_TRUE_COST_ALIAS": {
			return {...newState, true_cost_alias: action.val}
		}
		case "SET_RATE_DISPLAY_TRUE_COST_MINUS_MARKUP_ALIAS": {
			return {...newState, true_cost_minus_markup_alias: action.val}
		}
		case "SET_RATE_DISPLAY_BENCHMARK_ALIAS": {
			return {...newState, benchmark_alias: action.val}
		}
		case "SET_RATE_DISPLAY_DISCOUNT_ALIAS": {
			return {...newState, discount_alias: action.val}
		}
		case "SET_RATE_DISPLAY_MIN_ALIAS": {
			return {...newState, min_alias: action.val}
		}
		case "SET_RATE_DISPLAY_GROSS_ALIAS": {
			return {...newState, gross_alias: action.val}
		}
		case "SET_RATE_DISPLAY_COLLECTION": {
			if (!Array.isArray(action.val)) {
				action.val = [];
			}
			return {...newState, collection: [...action.val]}
		}
		case "CLEAR_RATE_DISPLAY": {
			return initialState;
		}
		default:
			return state;
	}
}