import React from "react";
import _ from "lodash";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import ReportProblem from "@material-ui/icons/ReportProblem";
import Search from "@material-ui/icons/Search";
import AddCircle from "@material-ui/icons/AddCircle";
import Warning from "@material-ui/icons/Warning";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Build from "@material-ui/icons/Build";
import Save from "@material-ui/icons/Save";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import FreightClassSelect from "~/components/TMS/FreightClassSelect.jsx";

import { capitalizePhrase } from "../../helpers.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class HandlingUnit extends React.Component {
    getUomOptions(uomType, val, name, key, index) {
        const classes = this.props.classes
        let options = [];
        let prod = false;
        let isImperial = this.props.uom_opt == 'imperial' ? true : false;

        if(uomType == "uom_length_type") {
            options = isImperial ? this.props.imp_length_types : this.props.met_length_types
        }else {
            options = isImperial ? this.props.imp_weight_types : this.props.met_weight_types
            prod = true
        }

        return (
            <FormControl className={classes.selectFormControl} style={{ width: "65px", backgroundColor: "white", textAlign: "center" }}>
                <Select
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    value={val}
                    inputProps={{
                        name: name,
                        id: val,
                    }}
                    onChange={e => prod ? this.props.handleProduct(key, index, name, e) : this.props.handleUnit(key, name, e)}
                >
                    {options.map((option, key) => {
                        return (
                            <MenuItem
                                key={`${uomType}_${key}`}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                }}
                                value={option.name}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        )
    }

    getUnitTypes(uom) {
        const { classes } = this.props;
        return uom.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.name}
                >
                    {prop.name}
                </MenuItem>
            );
        });
    }

    getHandlingUnits(executed) {
        const { classes } = this.props;
        const selectClasses = cx({
            [classes.select]: true,
            [classes.requiredSelect]: true
        });
        return this.props.units.map((unit, key) => {
            const unitNum = key + 1;
            let products = [];
            if (!_.isEmpty(unit.products)) {
                products = unit.products.map((product, index) => {
                    const productClassName = product.hazardous ? classes.ltlHazmat : classes.product;
                    const productHeader = product.hazardous ? classes.hazmatHeader : classes.productHeader;
                    const productNum = index + 1;
                    return (
                        <Grid container key={index} className={productClassName} style={{ padding: "1px 0px 5px" }}>
                            <GridItem xs={12} className={productHeader}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <h5>
                                            <strong>{"PRODUCT #" + productNum}</strong>
                                            {!_.isEmpty(product.errors) && (
                                                <WithTooltip
                                                    title="Missing Liability Requirements!"
                                                    content={this.props.getLiabilityErrors(product.errors)}
                                                >
                                                    <span>
                                                        <Warning style={{ height: "16px" }} />
                                                    </span>
                                                </WithTooltip>
                                            )}
                                        </h5>
                                    </GridItem>
                                    {!executed && (
                                        <GridItem xs={8} className={classes.right}>
                                            {index === unit.products.length - 1 && !executed && (
                                                <WithTooltip
                                                    title="Add Product (Commodity)"
                                                    content={"Add a Product (Commodity) to Handling Unit #" + unitNum}
                                                >
                                                    <span>
                                                        <Button color="success" size="sm" className={classes.marginRight} onClick={e => this.props.addProduct(key)}>
                                                            Add Product
                                                        </Button>
                                                    </span>
                                                </WithTooltip>
                                            )}
                                            <WithTooltip
                                                title="Clear Product (Commodity)"
                                                content={"Remove all information from Product #" + productNum}
                                            >
                                                <span>
                                                    <Button color="warning" size="sm" className={classes.marginRight} onClick={e => this.props.clearProduct(key, index)}>
                                                        Clear Product
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                            <WithTooltip
                                                title="Delete Product (Commodity)"
                                                content={"Remove Product #" + productNum + " from Handling Unit #" + unitNum}
                                            >
                                                <span>
                                                    <Button color="danger" size="sm" className={classes.marginRight} onClick={e => this.props.removeProduct(key, index)}>
                                                        Delete Product
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                            <WithTooltip
                                                title={product.hazardous ? "No Hazardous Materials" : "Hazardous Materials"}
                                                content={product.hazardous ? "Do not include hazmat information on this product." : "Include hazmat information on this product."}
                                            >
                                                <span>
                                                    <Button color={product.hazardous ? "white" : "reddit"} size="sm" justIcon round className={classes.marginRight} onClick={e => this.props.toggleHazardous(key, index)}>
                                                        <ReportProblem />
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={3}>
                                        <WithTooltip
                                            title="Product Packaging Type"
                                            content={"Select a packaging material type for the product."}
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Type</small>
                                            </span>
                                        </WithTooltip>
                                        <FormControl fullWidth className={classes.selectFormControl}>
                                            <Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={product.uom || unit.type} inputProps={{ name: "uom" }} onChange={e => this.props.handleProduct(key, index, "uom", e)} disabled={executed}>
                                                {!_.isEmpty(this.props.body) && !_.isEmpty(this.props.body.uom) && this.getUnitTypes(this.props.body.uom)}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <Grid container>
                                            <Grid item xs>
                                                <WithTooltip
                                                    title="Product Description"
                                                    content="Enter a detailed description for the product."
                                                >
                                                    <span>
                                                        <small style={{ marginTop: "10px" }}>Product</small>
                                                    </span>
                                                </WithTooltip>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "description",
                                                        value: product.description || "",
                                                        onChange: e => this.props.handleProduct(key, index, "description", e),
                                                        disabled: executed,
                                                    }}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={3} style={{ paddingLeft: "5px" }}>
                                                {this.props.hasProductRead && <WithTooltip
                                                    title="Load A Product (Commodity)"
                                                    content="Choose a pre-configured Product (Commodity) from the Product Catalog."
                                                >
                                                    <span>
                                                        <Button color="white" size="sm" justIcon round style={{ marginTop: "20px" }} onClick={e => this.props.handleProductSearch(key, index)} disabled={executed}>
                                                            <Search />
                                                        </Button>
                                                    </span>
                                                </WithTooltip>}
                                            </Grid>
                                        </Grid>
                                    </GridItem>
                                        <GridItem xs={3} className={classes.right}>
                                            {this.props.hasProductWrite && <WithTooltip
                                                title="Save Product (Commodity)"
                                                content="Save a pre-configured Product (Commodity) to the Product Catalog."
                                            >
                                                <span>
                                                    <Button color="success" size="sm" className={classes.chip + " " + classes.marginRight} onClick={() => this.props.saveProduct(key, index)}>
                                                        <Save /> Save Product
                                                    </Button>
                                                </span>
                                            </WithTooltip>}
                                        </GridItem>
                                    {(this.props.showHsCode) && (
                                          <GridItem xs={3} sm={2}>
                                            <WithTooltip
                                                title="HS Code"
                                                content="Enter the HS Code for the product."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>HS Code</small>
                                                </span>
                                            </WithTooltip>
                                             <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "hs_code",
                                                    value: product.hs_code|| "",
                                                    onChange: e => this.props.handleProduct(key, index, "hs_code", e),
                                                    disabled: executed,
                                                }}
                                                style={{ marginTop: "0" }}
                                                required
                                            />
                                          </GridItem>
                                    )}
                                    <GridItem xs={6} sm={this.props.user.user_company_settings.enable_dgd == 1 ? 2 : 3}>
                                        <WithTooltip
                                            title="NMFC Code"
                                            content="Enter the NMFC Code for the product."
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>
                                                    NMFC{" "}
                                                </small>
                                            </span>
                                        </WithTooltip>
                                        <WithTooltip
                                            title="Important Notice!"
                                            content={this.props.nmfc_notice_message}
                                        >
                                            <small>
                                                <span>
                                                    <Warning style={{ height: "16px" }} />
                                                </span>
                                            </small>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                name: "nmfc",
                                                value: product.nmfc || "",
                                                onChange: e => this.props.handleProduct(key, index, "nmfc", e),
                                                disabled: executed,
                                            }}
                                            style={{ marginTop: "0" }}
                                            required={this.props.requireNmfc ? true:false}
                                        />
                                    </GridItem>
                                    {(this.props.showSubCode) && (
                                        <GridItem xs={3} sm={2}>
                                            <WithTooltip
                                                title="Sub-NMFC Code"
                                                content="Enter the Sub-NMFC Code for the product."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Sub</small>
                                                </span>
                                            </WithTooltip>
                                            {!_.isEmpty(this.props.user) && this.props.user.user_company_settings.enable_liability == 1 ? (
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "sub_nmfc",
                                                        value: product.sub_nmfc || "",
                                                        onChange: e => this.props.handleProduct(key, index, "sub_nmfc", e),
                                                        disabled: executed,
                                                    }}
                                                    style={{ marginTop: "0" }}
                                                    required
                                                />
                                            ) : (
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "sub_nmfc",
                                                        value: product.sub_nmfc || "",
                                                        onChange: e => this.props.handleProduct(key, index, "sub_nmfc", e),
                                                        disabled: executed,
                                                    }}
                                                    style={{ marginTop: "0" }}
                                                    white
                                                />
                                            )}
                                        </GridItem>
                                    )}
                                    {(this.props.showClass) && (
                                        <GridItem xs={3} sm={2}>
                                            <WithTooltip
                                                title="Freight Class"
                                                content="Enter the freight class for the product."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Class</small>
                                                </span>
                                            </WithTooltip>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <FreightClassSelect
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: this.props.enable_auto_class ? selectClasses : classes.select}}
                                                    value={product.class || ""}
                                                    inputProps={{ name: "class" }}
                                                    onChange={e => this.props.handleProduct(key, index, "class", e)}
                                                    disabled={executed}
                                                />
                                            </FormControl>
                                        </GridItem>
                                    )}
                                    <GridItem xs={this.props.user.user_company_settings.enable_dgd == 1 ? 4 : 6} sm={2}>
                                        <WithTooltip
                                            title="Product Quantity"
                                            content="Enter the quantity of each item contained for the product."
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Product Quantity</small>
                                            </span>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                name: "pieces",
                                                inputProps: { min: 0 },
                                                value: product.pieces || "",
                                                onChange: e => this.props.handleProduct(key, index, "pieces", e),
                                                disabled: executed,
                                            }}
                                            required
                                        />
                                    </GridItem>
                                    {this.props.showOverallPiece && (
                                        <GridItem xs={3} sm={2}>
                                            <WithTooltip
                                                title="Overall Piece Quantity"
                                                content="Enter Overall Piece Quantity."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Overall Piece Quantity</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "number",
                                                    name: "overall_piece_qty",
                                                    inputProps: { min: 0 },
                                                    value: product.overall_piece_qty || "",
                                                    onChange: e => this.props.handleProduct(key, index, "overall_piece_qty", e),
                                                    disabled: executed,
                                                }}
                                                white
                                            />
                                        </GridItem>
                                    )}
                                    <GridItem xs={this.props.user.user_company_settings.enable_dgd == 1 ? 4 : 6} sm={this.props.user.user_company_settings.enable_dgd == 1 ? 2 : 3}>
                                        <WithTooltip
                                            title="Weight"
                                            content={ this.props.uomSelect ?  `Enter the weight for the product in (${product.weight_uom}.)` : "Enter the weight for the product in pounds (lbs.)"}
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Weight</small>
                                            </span>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                name: "weight",
                                                value: product.weight || "",
                                                onChange: e => this.props.handleProduct(key, index, "weight", e),
                                                onBlur: e => this.props.updateTotals(true),
                                                endAdornment: this.props.uomSelect ? this.getUomOptions("uom_weight_type", product.weight_uom, "weight_uom", key, index) :  <InputAdornment position="end">lbs.</InputAdornment>,
                                                disabled: executed,
                                            }}
                                            required
                                        />
                                    </GridItem>
                                    {!_.isEmpty(this.props.user) && this.props.user.user_company_settings.enable_dgd == 1 && this.props.user.user_company_settings.enable_liability != 1 && this.props.showClass && this.props.showNetWeight && (
                                        <GridItem xs={4} sm={2}>
                                            <WithTooltip
                                                title="Net Weight"
                                                content="Enter the net weight for the product in pounds (lbs.)"
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Net Weight</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "net_weight",
                                                    value: product.net_weight || "",
                                                    onChange: e => this.props.handleProduct(key, index, "net_weight", e),
                                                    endAdornment: <InputAdornment position="end">lbs.</InputAdornment>,
                                                    disabled: executed,
                                                }}
                                                white
                                            />
                                        </GridItem>
                                    )}
                                    {!_.isEmpty(this.props.user) && this.props.user.user_company_settings.enable_liability == 1 && (
                                        <GridItem xs={12}>
                                            <GridContainer>
                                                {this.props.showNetWeight && (
                                                    <GridItem xs={2}>
                                                        <WithTooltip
                                                            title="Net Weight"
                                                            content="Enter the net weight for the product in pounds (lbs.)"
                                                        >
                                                            <span>
                                                                <small style={{ marginTop: "10px" }}>Net Weight</small>
                                                            </span>
                                                        </WithTooltip>
                                                        <CustomInput
                                                            error={typeof product.errors !== 'undefined' && product.errors.indexOf("net_weight") > -1}
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                type: "text",
                                                                name: "net_weight",
                                                                value: product.net_weight || "",
                                                                onChange: e => this.props.handleProduct(key, index, "net_weight", e),
                                                                endAdornment:
                                                                    typeof product.errors !== 'undefined' && product.errors.indexOf("net_weight") > -1 ? (
                                                                        <InputAdornment position="end" className={classes.error}>
                                                                            <Close style={{ color: "red" }} />
                                                                        </InputAdornment>
                                                                    ) : (
                                                                        <InputAdornment position="end">lbs.</InputAdornment>
                                                                    ),
                                                                disabled: executed,
                                                            }}
                                                            required
                                                        />
                                                    </GridItem>
                                                )}
                                                {this.props.showCondition && (
                                                    <GridItem xs={2}>
                                                        <WithTooltip
                                                            title="Condition"
                                                            content="Select the condition of the Product (Commodity)."
                                                        >
                                                            <span>
                                                                <small style={{ marginTop: "10px" }}>Condition</small>
                                                            </span>
                                                        </WithTooltip>
                                                        <FormControl fullWidth className={classes.selectFormControl}>
                                                            <Select
                                                                MenuProps={{
                                                                    className: classes.selectMenu
                                                                }}
                                                                classes={{
                                                                    select: classes.select + " " + classes.requiredSelect
                                                                }}
                                                                value={product.condition || ""}
                                                                inputProps={{
                                                                    name: "condition"
                                                                }}
                                                                onChange={e => this.props.handleProduct(key, index, "condition", e)}
                                                                disabled={executed}
                                                            >
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value=""
                                                                />
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value="New"
                                                                >
                                                                    New
                                                                </MenuItem>
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value="Not New"
                                                                >
                                                                    Not New
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </GridItem>
                                                )}
                                                {this.props.showPackaging && (
                                                    <GridItem xs={2}>
                                                        <WithTooltip
                                                            title="Packaging"
                                                            content="Select the packaging of the Product (Commodity)."
                                                        >
                                                            <span>
                                                                <small style={{ marginTop: "10px" }}>Packaging</small>
                                                            </span>
                                                        </WithTooltip>
                                                        <FormControl fullWidth className={classes.selectFormControl}>
                                                            <Select
                                                                MenuProps={{
                                                                    className: classes.selectMenu
                                                                }}
                                                                classes={{
                                                                    select: classes.select + " " + classes.requiredSelect
                                                                }}
                                                                value={product.packaging || ""}
                                                                inputProps={{
                                                                    name: "packaging"
                                                                }}
                                                                onChange={e => this.props.handleProduct(key, index, "packaging", e)}
                                                                disabled={executed}
                                                            >
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value=""
                                                                />
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value="Crated"
                                                                >
                                                                    Crated
                                                                </MenuItem>
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value="Uncrated"
                                                                >
                                                                    Uncrated
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </GridItem>
                                                )}
                                                {(this.props.showValuePer) && (
                                                    <GridItem xs={2}>
                                                        <WithTooltip
                                                            title="Declared Value"
                                                            content="Enter the declared value for each item on the Product (Commodity)."
                                                        >
                                                            <span>
                                                                <small style={{ marginTop: "10px" }}>Value Per Quantity</small>
                                                            </span>
                                                        </WithTooltip>
                                                        <CustomInput
                                                            error={typeof product.errors !== 'undefined' && product.errors.indexOf("declared_value") > -1}
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                type: "text",
                                                                name: "declared_value",
                                                                value: product.declared_value || "",
                                                                onChange: e => this.props.handleProduct(key, index, "declared_value", e),
                                                                placeholder: "0.00",
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                endAdornment:
                                                                    typeof product.errors !== 'undefined' && product.errors.indexOf("declared_value") > -1 ? (
                                                                        <InputAdornment position="end" className={classes.error}>
                                                                            <Close style={{ color: "red" }} />
                                                                        </InputAdornment>
                                                                    ) : (
                                                                        undefined
                                                                    ),
                                                                disabled: executed,
                                                            }}
                                                            required
                                                        />
                                                    </GridItem>
                                                )}
                                                {(this.props.showDeclaredIntl) && (
                                                    <GridItem xs={2}>
                                                        <WithTooltip
                                                            title="Declared Value"
                                                            content="Enter the declared value for each item on the Product (Commodity)."
                                                        >
                                                            <span>
                                                                <small style={{ marginTop: "10px" }}>Declared Value</small>
                                                            </span>
                                                        </WithTooltip>
                                                        <CustomInput
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                type: "text",
                                                                name: "declared_value",
                                                                value: product.declared_value || "",
                                                                onChange: e => this.props.handleProduct(key, index, "declared_value", e),
                                                                placeholder: "0.00",
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                disabled: executed,
                                                            }}
                                                            required={product.full_value_coverage}
                                                        />
                                                    </GridItem>
                                                )}
                                            </GridContainer>
                                        </GridItem>
                                    )}
                                    {((this.props.enable_product_part_no) && (
                                        <GridItem xs={2}>
                                            <WithTooltip
                                                title="Part #"
                                                content="Enter an optional Part # for the product"
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Part #</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "part_no",
                                                    value: product.part_no || "",
                                                    onChange: e => this.props.handleProduct(key, index, "part_no", e),
                                                    disabled: executed,
                                                }}
                                                required={this.props.require_part_no}
                                            />
                                        </GridItem>
                                    ))}
                                    {(!this.props.display_custom_piece_count) && (
                                        <GridItem xs={2}>
                                            <WithTooltip
                                                title="Auto Weight"
                                                content="When checked, the weight of the product will be multiplied by the Product Quantity."
                                            >
                                                <span>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        value={product.auto_weight || false}
                                                        classes={{ label: classes.label }}
                                                        label="Handle Weight Calculation"
                                                        onChange={e => this.props.handleProduct(key, index, "auto_weight", e)}
                                                        checked={product.auto_weight}
                                                        disabled={executed}
                                                    />
                                                </span>
                                            </WithTooltip>
                                        </GridItem>
                                    )}
                                    {(this.props.showFullValueCoverage) && (
                                        <GridItem xs={2}>
                                            <WithTooltip
                                                title="Full Value Coverage"
                                                content="When checked, the product will be Full Value Coverage."
                                            >
                                                <span>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        value={product.full_value_coverage || false}
                                                        classes={{ label: classes.label }}
                                                        label="Full Value Coverage"
                                                        onChange={e => this.props.handleProduct(key, index, "full_value_coverage", e)}
                                                        checked={product.full_value_coverage}
                                                        disabled={executed}
                                                    />
                                                </span>
                                            </WithTooltip>
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </GridItem>
                            {product.hazardous === true && (
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={3} sm={3}>
                                            <WithTooltip
                                                title="Hazard Class"
                                                content="Enter the Hazard Class or Division Number for the Product (Commodity)."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Class #</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "class",
                                                    value: product.hazmat.class || "",
                                                    onChange: e => this.props.handleHazardous(key, index, "class", e),
                                                    disabled: executed,
                                                }}
                                                required
                                            />
                                        </GridItem>
                                        <GridItem xs={3} sm={3}>
                                            <WithTooltip
                                                title="United Nations Number"
                                                content="Enter the United Nations number for the Product (Commodity)."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>UN #</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "un",
                                                    value: product.hazmat.un || "",
                                                    onChange: e => this.props.handleHazardous(key, index, "un", e),
                                                    disabled: executed,
                                                }}
                                                required
                                            />
                                        </GridItem>
                                        <GridItem xs={3} sm={3}>
                                            <WithTooltip
                                                title="Packing Group"
                                                content="Enter the Packing Group according to the degree of danger for the Product (Commodity)."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Packing Group</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "packingGroup",
                                                    value: product.hazmat.packingGroup || "",
                                                    onChange: e => this.props.handleHazardous(key, index, "packingGroup", e),
                                                    disabled: executed,
                                                }}
                                                required={this.props.require_packing_group}
                                            />
                                        </GridItem>
                                        <GridItem xs={3} sm={3}>
                                            <WithTooltip
                                                title="Emergency Response Telephone Number"
                                                content="Enter the Emergency Response Telephone Number for the Product (Commodity)."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Emergency #</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "emergencyNumber",
                                                    value: product.hazmat.emergencyNumber || "",
                                                    onChange: e => this.props.handleHazardous(key, index, "emergencyNumber", e),
                                                    onBlur: e => this.props.validatePhoneNumber(e.target.value),
                                                    disabled: executed,
                                                }}
                                                required
                                            />
                                        </GridItem>
                                        <GridItem xs={8} sm={8}>
                                            <WithTooltip
                                                title="Packing Instructions"
                                                content="Enter the Packing Instructions for the Product (Commodity)."
                                            >
                                                <span>
                                                    <small style={{ marginTop: "10px" }}>Packing Instructions</small>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    name: "packingInstructions",
                                                    value: product.hazmat.packingInstructions || "",
                                                    onChange: e => this.props.handleHazardous(key, index, "packingInstructions", e),
                                                    disabled: executed,
                                                }}
                                                required
                                            />
                                        </GridItem>
                                        <GridItem xs={4} sm={4}>
                                            <WithTooltip
                                                title="Overpack Used"
                                                content="Check to signify if the product is inside an overpack."
                                            >
                                                <span>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                                disabled={executed}
                                                                checked={product.hazmat.overpackUsed}
                                                            />
                                                        }
                                                        classes={{ label: classes.label }}
                                                        label="Overpack Used"
                                                        value={product.hazmat.overpackUsed || false}
                                                        onChange={e => this.props.handleHazardous(key, index, "overpackUsed", e)}
                                                    />
                                                </span>
                                            </WithTooltip>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            )}
                        </Grid>
                    );
                });
            }
            return(
                <GridContainer key={key} className={classes.unitWrapper}>
                    <GridItem xs={12} className={classes.unit}>
                        <GridContainer>
                            <GridItem xs={12} className={classes.unitHeader}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <h5>
                                            <strong>{"UNIT #" + (key + 1)}</strong>
                                        </h5>
                                    </GridItem>
                                    {!executed && (
                                        <GridItem xs={8} className={classes.right}>
                                            <WithTooltip
                                                title="Delete Handling Unit"
                                                content={"Remove Handling Unit #" + unitNum}
                                            >
                                                <span>
                                                    <Button color="danger" size="sm" className={classes.marginRight} onClick={e => this.props.removeUnit(key)}>
                                                        Delete Unit
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                            {this.props.hasHandlingUnitRead && <WithTooltip
                                                title="Load A Handling Unit"
                                                content="Choose a pre-configured Unit from the Handling Unit Catalog."
                                            >
                                                <span>
                                                    <Button color="white" size="sm" justIcon round className={classes.marginRight} onClick={e => this.props.handleUnitSearch(key)} disabled={executed}>
                                                        <Search />
                                                    </Button>
                                                </span>
                                            </WithTooltip>}
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={4} sm={2}>
                                        <WithTooltip
                                            title="Unit Packaging Type"
                                            content="Select a packaging material type for the handling unit."
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Type</small>
                                            </span>
                                        </WithTooltip>
                                        <FormControl fullWidth className={classes.selectFormControl}>
                                            <Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={unit.type || "Pallet"} inputProps={{ name: "type" }} onChange={e => this.props.handleUnit(key, "type", e)} disabled={executed}>
                                                {!_.isEmpty(this.props.body) && !_.isEmpty(this.props.body.uom) && this.getUnitTypes(this.props.body.uom)}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={4} sm={2}>
                                        <WithTooltip
                                            title="Stackable"
                                            content="Choose 'Yes' if the handling unit is stackable or 'No' if the handling unit is non-stackable."
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Stackable</small>
                                            </span>
                                        </WithTooltip>
                                        <FormControl fullWidth className={classes.selectFormControl}>
                                            <Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={unit.stackable || false} inputProps={{ name: "stackable" }} onChange={e => this.props.handleUnit(key, "stackable", e)} disabled={executed}>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value
                                                >
                                                    Yes
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value={false}
                                                >
                                                    No
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={4} sm={2}>
                                        <WithTooltip
                                            title="Quantity"
                                            content="Enter the quantity of the handling unit."
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Quantity</small>
                                            </span>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                name: "pieces",
                                                value: unit.pieces || 1,
                                                inputProps: { min: 1 },
                                                onChange: e => this.props.handleUnit(key, "pieces", e),
                                                onBlur: e => this.props.updateTotals(true),
                                                disabled: executed,
                                            }}
                                            required
                                        />
                                    </GridItem>
                                    <GridItem xs={4} sm={2}>
                                        <WithTooltip
                                            title="Length"
                                            content={ this.props.uomSelect ? `Enter the length of the handling unit in linear (${unit.length_uom}.)` : "Enter the length of the handling unit in linear inches (in.)"}
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Length</small>
                                            </span>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                name: "length",
                                                value: unit.length || "",
                                                onChange: e => this.props.handleUnit(key, "length", e),
                                                onBlur: e => this.props.updateTotals(true),
                                                endAdornment: this.props.uomSelect ? this.getUomOptions("uom_length_type", unit.length_uom, "length_uom", key) :  <InputAdornment position="end">in.</InputAdornment>,
                                                disabled: executed,
                                            }}
                                            required
                                        />
                                    </GridItem>
                                    <GridItem xs={4} sm={2}>
                                        <WithTooltip
                                            title="Width"
                                            content={ this.props.uomSelect ?  `Enter the width of the handling unit in linear (${unit.width_uom}.)` : "Enter the width of the handling unit in linear inches (in.)"}
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Width</small>
                                            </span>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                name: "width",
                                                value: unit.width || "",
                                                onChange: e => this.props.handleUnit(key, "width", e),
                                                onBlur: e => this.props.updateTotals(true),
                                                endAdornment: this.props.uomSelect ? this.getUomOptions("uom_length_type", unit.width_uom, "width_uom", key) :  <InputAdornment position="end">in.</InputAdornment>,
                                                disabled: executed,
                                            }}
                                            required
                                        />
                                    </GridItem>
                                    <GridItem xs={4} sm={2}>
                                        <WithTooltip
                                            title="Height"
                                            content={ this.props.uomSelect ?  `Enter the height of the handling unit in linear (${unit.height_uom}.)` : "Enter the height of the handling unit in linear inches (in.)"}
                                        >
                                            <span>
                                                <small style={{ marginTop: "10px" }}>Height</small>
                                            </span>
                                        </WithTooltip>
                                        <CustomInput
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "text",
                                                name: "height",
                                                value: unit.height || "",
                                                onChange: e => this.props.handleUnit(key, "height", e),
                                                onBlur: e => this.props.updateTotals(true),
                                                endAdornment: this.props.uomSelect ? this.getUomOptions("uom_length_type", unit.height_uom, "height_uom", key) :  <InputAdornment position="end">in.</InputAdornment>,
                                                disabled: executed,
                                            }}
                                            required
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    {!_.isEmpty(products) ? (
                        <Grid item xs={12}>
                            {products}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid container className={classes.product}>
                                <GridItem xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: "white", color: "red", textAlign: "center", paddingBottom: "10px" }}>
                                    <h5>
                                        <strong>NO PRODUCTS!</strong>
                                    </h5>
                                    <Button color="success" size="lg" className={classes.marginRight} onClick={e => this.props.addProduct(key)} disabled={executed}>
                                        Add Product
                                    </Button>
                                </GridItem>
                            </Grid>
                        </Grid>
                    )}
                </GridContainer>
            );
        });
    }

    render() {
        const classes = this.props.classes || {};
        let executed = this.props.executed;

        if(this.props.enable_executed_edit) {
            executed = false;
        }
        return(
            <React.Fragment>
                {this.props.uomSelect && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer style={{justifyContent: "flex-end"}}>
                            <GridItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                            checked={this.props.uom_opt === 'imperial'}
                                            onChange={this.props.onChangeChecked('uom_opt')}
                                            value={'imperial'}
                                        />
                                    }
                                    classes={{ label: classes.label }}
                                    label={"Imperial"}
                                />
                            </GridItem>
                            <GridItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                            checked={this.props.uom_opt === 'metric'}
                                            onChange={this.props.onChangeChecked('uom_opt')}
                                            value={'metric'}
                                        />
                                    }
                                    classes={{ label: classes.label }}
                                    label={"Metric"}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                )}
                {!_.isEmpty(this.props.units) ? (
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.unitContainer}>
                        {this.getHandlingUnits(executed)}
                    </GridItem>
                ) : (
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.unitContainer}>
                        <GridContainer className={classes.unitWrapper}>
                            <GridItem className={classes.unitHeader} xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: "white", color: "red", textAlign: "center", paddingBottom: "10px" }}>
                                <h5>
                                    <strong>NO HANDLING UNITS!</strong>
                                </h5>
                                <WithTooltip
                                    title="New Handling Unit"
                                    content="Create a new handling unit onto which to add products."
                                >
                                    <span>
                                        <Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.props.addUnit} disabled={executed}>
                                            Add Unit
                                        </Button>
                                    </span>
                                </WithTooltip>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={8} md={12} lg={12}>
                            <WithTooltip
                                title="New Handling Unit"
                                content="Create a new handling unit onto which to add products."
                            >
                                <span>
                                    <Button color="linkedin" onClick={this.props.addUnit} style={{ marginBottom: "10px" }} className={classes.marginRight} disabled={executed}>
                                        <AddCircle /> Add Unit
                                    </Button>
                                </span>
                            </WithTooltip>
                            {this.props.enable_auto_class && (
                                <WithTooltip
                                    title="Auto Class"
                                    content="Calculate the Sub-NMFC as well as the Class for all products at once using Shipment Weight and Total Handling Unit Dimensions for configured NMFC numbers (only used for density-rated freight.)"
                                >
                                    <span>
                                        <Button color={this.props.classification ? "white" : "reddit"} style={{ marginBottom: "10px" }} onClick={this.props.classifyProducts}>
                                            <Build /> Classify Products
                                        </Button>
                                    </span>
                                </WithTooltip>
                            )}
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(HandlingUnit);
