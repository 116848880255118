import {
	uiColors,
	cardTitle,
	whiteColor,
	grayColor,
	defaultFont
} from "~/assets/jss/empire-tms.jsx";

import customSelectStyle from "assets/jss/empire-tms/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "~/assets/jss/empire-tms/customCheckboxRadioSwitch.jsx";
import modalStyle from "~/assets/jss/empire-tms/modalStyle.jsx";

const validationFormsStyle = theme => ({
	...customSelectStyle,
	...customCheckboxRadioSwitch,
	cardTitle: {
		...cardTitle,
		color: whiteColor
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	formCategory: {
		marginBottom: "0",
		color: grayColor[0],
		fontSize: "14px",
		padding: "10px 0 10px"
	},
	cardHeader: {
		zIndex: "3"
	},
	cardSubtitle: {
		...defaultFont,
		color: grayColor[0],
		fontSize: "14px",
		margin: "0 0 10px"
	},
	center: {
		textAlign: "center"
	},
	justifyContentCenter: {
		justifyContent: "center"
	},
	registerButton: {
		float: "right"
	},
	danger: {
		color: uiColors.danger.default + "!important"
	},
	center: {
		textAlign: "center"
	},
	right: {
		textAlign: "right"
	},
	left: {
		textAlign: "left"
	},
	buttonGroup: {
		position: "relative",
		margin: "10px 1px",
		display: "inline-block",
		verticalAlign: "middle"
	},
	firstButton: {
		borderTopRightRadius: "0",
		borderBottomRightRadius: "0",
		margin: "0",
		position: "relative",
		float: "left",
		"&:hover": {
			zIndex: "2"
		}
	},
	middleButton: {
		borderRadius: "0",
		margin: "0",
		position: "relative",
		float: "left",
		"&:hover": {
			zIndex: "2"
		}
	},
	lastButton: {
		borderTopLeftRadius: "0",
		borderBottomLeftRadius: "0",
		margin: "0",
		"&:hover": {
			zIndex: "2"
		}
	},
	marginRight: {
		marginRight: "5px"
	},
	modalSectionTitle: {
		marginTop: "30px"
	},
	...modalStyle(theme)
});

export default validationFormsStyle;
