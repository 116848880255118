import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// material ui icons
import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Table from "~/components/Table/Table.jsx";

import WithTooltip from "~/components/TMS/WithTooltip.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ParcelPackageButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			showModal: false,
			size: !_.isEmpty(props.size) ? props.size : 'sm',
			btnText: !_.isEmpty(props.btnText) ? props.btnText : '',
			showLabel: !_.isEmpty(props.showLabel) ? props.showLabel : false,
			warehouseCode: props.warehouseCode,
			packages: (!_.isEmpty(props.packages)) ? props.packages : [],
		};
		this.getHeaders = this.getHeaders.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.handleSelectedPackage = this.handleSelectedPackage.bind(this);
		this.getPackage = this.getPackage.bind(this);
		this.returnPackage = this.returnPackage.bind(this);
		this.getDefaultPackages = this.getDefaultPackages.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		if(!_.isEmpty(this.props.warehouseCode)) {
			await this.getDefaultPackages(this.props.warehouseCode);
		}
	}

	async getDefaultPackages(whCode) {
		this.setState({warehouseCode: whCode});
		const url = '/index.php?p=api&r=json&c=parcel&m=getWarehousePackages&d=' + whCode;
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && data.body !== false) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					packages: data.body,
				});
			} else if(typeof data !== "string" && data.body === false) {
				this.props.handleMessages(response);
				this.setState({
					show: false,
					loading: false,
				});
			} else{
				this.props.setNotification("There was an error retrieving the default packages for the selected warehouse!", {
					variant: "error"
				});
				this.setState({
					show: false,
					loading: false,
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false, show: false});
			this.props.setNotification("An unexpected error occurred while loading the package data!", {
				variant: "error"
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.warehouseCode !== this.state.warehouseCode) {
			this.getDefaultPackages(prevProps.warehouseCode);
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}



	getHeaders() {
		return ['Type', 'Length', 'Width', 'Height', 'Weight', '']
	}

	getColumns(data) {
		return data.map((prop, key) => {
			return [
				prop.type,
				prop.length,
				prop.width,
				prop.height,
				prop.weight,
				<Button onClick={() => this.handleSelectedPackage(prop.id)} color="linkedin" size="sm">
					Add
				</Button>
			];
		});
	}

	async getPackage(id) {
		if(_.isEmpty(this.state.warehouseCode) || _.isEmpty(id)) {
			this.props.setNotification("Cannot get package, either no warehouse or package selected!", {
				variant: "error"
			});
			return false;
		}
		const url = '/index.php?p=api&r=json&c=parcel&m=getWarehousePackages&d=' + this.state.warehouseCode + '/' + id;
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && data.body !== false && !_.isEmpty(data.body)) {
				return data.body[0];
			} else if(typeof data !== "string" && data.body === false) {
				this.props.handleMessages(response);
				return false;
			} else {
				this.props.setNotification("There was an error retrieving selected package!", { variant: "error" });
				return false
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An unexpected error occurred while loading the selected package!", {
				variant: "error"
			});
			return false;
		}
	}

	handleModalOpen() {
		this.setState({ showModal: true });
	}

	handleModalClose() {
		this.setState({ showModal: false });
	}

	returnPackage(pkg) {
		this.props.parentCallback(pkg);
	}

	async handleSelectedPackage(id) {
		const pkg = await this.getPackage(id);
		if(pkg !== false) {
			this.returnPackage(pkg);
			this.handleModalClose();
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				{this.state.show === true && (
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							{this.state.showLabel && (
								<InputLabel className={classes.label}>
									Choose Package
								</InputLabel>
							)}
							{this.props.disabled ? (
								<WithTooltip
									content="Multi-package Hazmat/Dangerous Goods Shipments are unsupported"
								>
									<span>
										<Button size={this.state.size} color="linkedin" disabled>
											<AddCircle /> {!_.isEmpty(this.state.btnText) && (this.state.btnText)}
										</Button>
									</span>
								</WithTooltip>
							) : (
								<Button size={this.state.size} color="linkedin" onClick={() => this.handleModalOpen()}>
									<AddCircle /> {!_.isEmpty(this.state.btnText) && (this.state.btnText)}
								</Button>
							)}
						</GridItem>
						<Dialog
							classes={{
		                        root: classes.center + " " + classes.modalRoot,
		                        paper: classes.modal + " " + classes.modalMedium
		                    }}
							open={this.state.showModal}
							keepMounted
							onClose={() => this.handleModalClose()}
		                    aria-labelledby="package_modal_slide_title"
		                    aria-describedby="classic_modal_slide_description"
						>
							<DialogTitle id="package_modal_slide_title" disableTypography  className={classes.modalHeader}>
								<h4 className={classes.modalTitle}>Default Packages</h4>
							</DialogTitle>
							<DialogContent id="classic_modal_slide_description" className={classes.modalBody}>
								<Table hover striped tableHead={this.getHeaders()} tableData={this.getColumns(this.state.packages)} />
							</DialogContent>
							<DialogActions className={classes.modalFooter}>
								<Button onClick={() => this.handleModalClose()} style={{ marginRight: "5px" }}>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</GridContainer>
				)}
			</div>
		);

	}
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(ParcelPackageButton)));