import React from "react";
import { createRoot } from 'react-dom/client';

import "classlist-polyfill"; // IE Support for Perfect Scrollbar
import "./assets/scss/material-dashboard-pro-react.scss";

import App from "./App.jsx";
import configureStore from './redux/configureStore.jsx';
import { setTFMRIndexURL, setYATIndexURL, setTTLIndexURL, setYATEnabled } from "./redux/actions/Environment.jsx";
const store = configureStore();

//get embedded tfmr url
try {
	const tfmr_url_span = document.getElementById("tfmr_url");
	if (tfmr_url_span) {
		store.dispatch(setTFMRIndexURL(tfmr_url_span.innerHTML));
	}
	const ttl_url_span = document.getElementById("ttl_url");
	if (ttl_url_span) {
		store.dispatch(setTTLIndexURL(ttl_url_span.innerHTML));
	}
	const yat_enabled_span = document.getElementById("yat_enabled");
	if (yat_enabled_span) {
		store.dispatch(setYATEnabled(yat_enabled_span.innerHTML));
	}
	const yat_url_span = document.getElementById("yat_url");
	if (yat_url_span) {
		store.dispatch(setYATIndexURL(yat_url_span.innerHTML));
	}
} catch (err) {
	console.error(err);
}

const root = createRoot(document.getElementById("root"));
root.render(<App store={store} />);
