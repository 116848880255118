import React from "react";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
// core components
import GridItem from "~/components/Grid/GridItem.jsx";

import Button from "~/components/CustomButtons/Button.jsx";
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class SummarizationStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      loading: true,
      summaryList: [],
      summaries: [
        {
          _id: 0,
          summarizationcolumunColumn: "",
          summaryType: "",
        },
        {
          _id: 1,
          summarizationcolumunColumn: "",
          summaryType: "",
        },
        {
          _id: 2,
          summarizationcolumunColumn: "",
          summaryType: "",
        },
        {
          _id: 3,
          summarizationcolumunColumn: "",
          summaryType: "",
        },
        {
          _id: 4,
          summarizationcolumunColumn: "",
          summaryType: "",
        },
      ],
    };
    this.getColumns = this.getColumns.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  handleDelete = (i) => (e) => {
    e.preventDefault();
    let summaries = [
      ...this.state.summaries.slice(0, i),
      ...this.state.summaries.slice(i + 1),
    ];
    this.setState({
      summaries,
    },()=>{
      this.props.handleStepState(this.state,"summarization");
    });
  };

  addCriteria = (e) => {
    e.preventDefault();
    let summaries = this.state.summaries.concat([
      {
        _id: this.state.summaries.length,
        summarizationcolumunColumn: "",
        summaryType: "",
      },
    ]);
    this.setState({
      summaries,
    });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.props.handleStepState(this.state, 'summarization');
    });
  }

  componentDidMount() {
    this.setState({ mounted: true });

    this.getOptions(this.props.selectedOption).then((res) => {
      this.props.handleStepState(this.state,'summarization');
    });

    const summarization =
      this.props.summarization != null
        ? this.props.summarization
        : this.state.summarization;
    const summaries = this.state.summaries;

    if (summarization != null) {
      for (var j in this.state.summaries) {
        var i = Number(j);
        if (summarization.length > i) {
          summaries[j]._id = summarization[i].id;
          summaries[j].summarizationcolumunColumn = summarization[i].column;
          summaries[j].summaryType = summarization[i].type;
        }
      }
      this.setState({
        summaries: summaries,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedOption !== prevProps.selectedOption) {
      this.getOptions(this.props.selectedOption);
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  replaceStr(str, find, replace) {
    for (var i = 0; i < find.length; i++) {
      str = str.replace(new RegExp(find[i], "gi"), replace[i]);
    }
    return str;
  }

  async getOptions(data) {
    try {
      if (typeof data !== "string" && !_.isEmpty(data)) {
        var summarizationColumun = [];

        data.map((item) =>
          summarizationColumun.push({
            value: item,
            label:
              item.charAt(0).toUpperCase() +
              this.replaceStr(item, ["#", "_"], ["", " "]).slice(1),
          })
        );

        this.setState({
          loading: false,
          summaryList: summarizationColumun,
        });
      } else {
        this.setState({
          loading: false,
          summaryList: null,
        });
      }
    } catch (error) {}
  }

  getColumns(data) {
    const { classes } = this.props;

    return data.map((prop, key) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={prop.value}
          key={prop.value}
        >
          {prop.label}
        </MenuItem>
      );
    });
  }

  setSummarizationcolumunColumn = (event, index) => {
    this.setState((prevState) => ({
      summaries: prevState.summaries.map((obj) =>
        obj._id === index
          ? Object.assign(obj, {
              summarizationcolumunColumn: event.target.value,
            })
          : obj
      ),
    }), () => {
      this.props.handleStepState(this.state, 'summarization');
    });
  };

  setSumaarizationType = (event, index) => {
    this.setState((prevState) => ({
      summaries: prevState.summaries.map((obj) =>
        obj._id === index
          ? Object.assign(obj, { summaryType: event.target.value })
          : obj
      ),
    }), () => {
      this.props.handleStepState(this.state, 'summarization');
    });
  };

  render() {
    const { classes } = this.props;
    if (_.isEmpty(this.state.summaryList)) {
      return <div />;
    }
    return (
      <div>
        <Grid item xs={12}>
          <Button color="primary" onClick={this.addCriteria}>
            Add new criteria
          </Button>
          {this.state.summaries.map((summary, index) => (
            <Box key={summary._id} mt={0} mb={-5} ml={0} mr={0}>
              <Grid container key={index}>
                <GridItem xs>
                  <FormControl
                    className={classes.selectFormControl}
                    style={{ width: "210px" }}
                  >
                    <InputLabel className={classes.label}>
                      Summarization criteria
                    </InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={summary.summarizationcolumunColumn}
                      onChange={(e) =>
                        this.setSummarizationcolumunColumn(e, summary._id)
                      }
                      inputProps={{
                        id: "summarizationcolumunColumn",
                        name: "summarizationcolumunColumn",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value=""
                      >
                        Summarization criteria
                      </MenuItem>
                      {this.getColumns(this.state.summaryList)}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs>
                  <FormControl
                    className={classes.selectFormControl}
                    style={{ width: "210px" }}
                  >
                    <InputLabel className={classes.label}>
                      Summary type
                    </InputLabel>

                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={summary.summaryType}
                      onChange={(e) =>
                        this.setSumaarizationType(e, summary._id)
                      }
                      inputProps={{
                        id: "summaryType",
                        name: "summaryType",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value=""
                      >
                        Summary type
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="sum"
                      >
                        Sum
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="count"
                      >
                        Count
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs>
                  <Button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "30px",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#474747",
                      borderRadius: "0px 0px 0px 0px",
                      boxShadow: "none",
                    }}
                    onClick={this.handleDelete(index)}
                  >
                    Remove
                  </Button>
                </GridItem>
              </Grid>
            </Box>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(ltlQuoteStyle)(withSnackbar(SummarizationStep));
