import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Table from "~/components/Table/Table.jsx";


import { basePath } from "~/variables/server.jsx";

import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class LinearFeetRuleDates extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			rule_set: {},
			subsets: [],
			alert: null,
		};
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		const id = this.props.match.params.id;
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=linearFeetRuleDates&d=" + id
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user)
			) {
				this.props.handleMessages(response);
				this.props.pageTitle('Linear Feet Rule Set - ' + data.body.rule_set.id + ' - ' + data.body.rule_set.carrier + ' | ' + data.body.rule_set.scac);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					rule_set: data.body.rule_set,
					subsets: data.body.subsets,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"Unexpected response received while loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	getColumns(data) {
		return data.map((prop, key) => {
			const editLink = basePath + "/admin/rule-set/new/" + this.state.rule_set.id + "/" + prop.id;
			return [
				prop.start,
				prop.end,
				<div className="actions-right">
					<NavLink to={editLink}>
						<Button
							size="sm"
							color="info"
							style={{
								marginRight: "4px"
							}}
						>
							View/Edit
						</Button>
					</NavLink>
					<Button
						size="sm"
						color="danger"
						style={{
							marginRight: "4px"
						}}
						onClick={(e) => {this.deleteSubset(prop.id, key)}}
					>
						Delete
					</Button>
				</div>
			];
		});
	}
	deleteSubset(id, index) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this linear foot subset?"
					onConfirm={() => this.handleDeleteSubset(id, index)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async handleDeleteSubset(id, index) {
		this.setState({alert: null});

		const subsets = this.state.subsets;

		try {
			const response = await axios.get('/index.php?p=api&r=json&c=admin&m=deleteLinearFeetRuleSubset&d=' + id);

			if (typeof response.data !== "string") {
				this.props.handleMessages(response);
				subsets.splice(index, 1);
				this.setState({subsets: subsets});
			} else {
				this.props.setNotification("Unexpected response when attempting to delete the subset!", {
					variant: "error"
				});
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the subset!", {
				variant: "error"
			});
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<NavLink to={basePath + "/admin/linear-feet-rules"}>
					<Button size="sm" color="linkedin">
						Rule Sets
					</Button>
				</NavLink>
				<NavLink to={basePath + "/admin/rule-set/new-subset/" + this.state.rule_set.id + "/"}>
					<Button size="sm" color="linkedin">
						New Rule Sub-Set
					</Button>
				</NavLink>
				<GridItem xs={12}>
						<Table
							tableHead={[
								"Start",
								"End",
								"",
							]}
							tableData={this.getColumns(
								this.state
									.subsets
							)}
							customCellClasses={[
								classes.right
							]}
							customClassesForCells={[
								5
							]}
							customHeadCellClasses={[
								classes.right
							]}
							customHeadClassesForCells={[
								5
							]}
						/>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(LinearFeetRuleDates));