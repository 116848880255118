import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textArea: {
    marginTop: "10px",
    width: "100%",
    padding: "19px",
    backgroundColor: "#f5f5f5",
  }
}));

const FilterQueryTextArea = ({ addQueryText, value }) => {
  const classes = useStyles();

  const handleUpdateFilterValue = (e) => {
    addQueryText(e.target.value)
  }

  return (

    <div>
      <TextareaAutosize 
        className={classes.textArea}
        aria-label="maximum height"
        spellCheck="false"
        placeholder="Comma separated list"
        onChange={handleUpdateFilterValue}
        value={value || ''}
      />
    </div>
  );
}

export default FilterQueryTextArea;