export function handleMessages(apiResponse) {
	return dispatch => {
		if (apiResponse && apiResponse.data && Array.isArray(apiResponse.data.message)) {
			apiResponse.data.message.map(message => {
				dispatch(setNotification(message.message, {variant: message.type}));
			});
		}
	}
}

/**
 * Add a notification to the stack
 */
export function setNotification(message, options = {}) {
	return (dispatch, getState) => {
		let optsObject = typeof options === "object" && options !== null ? options : {};

		if (optsObject.preventDuplicate) {
			const notifs = getState().notifications.notifications;
			for (let i = 0; i < notifs.length; i++) {
				if (notifs[i].message == message) {

					return false;
				}
			}
		}

		dispatch({
			type: "SNACKBAR_NOTIFY",
			message,
			options: {
				...optsObject,
				id: new Date().getTime() + Math.random().toString(36).substr(2, 5),
			}
		});
	}
}

/**
 * Clear the notification stack when switching users or logging out
 */
export function clearNotifications() {
	return {type: "SNACKBAR_CLEAR"};
}

/**
 * Move a notification from the notifications array to the processed array in the store
 * this lets us know what notifications are active
 */
export const processNotificationById = id => {
	return {type: "SNACKBAR_MOVE_TO_PROCESSED", id};
}