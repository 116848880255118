import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { NavLink } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import _ from "lodash";

import Button from "~/components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";

import ReactTable from "react-table-6";

import Add from "@material-ui/icons/Add";
import { FaAsterisk } from "react-icons/fa";

import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

class WarehouseProductCatalog extends Component {
	state = {};

	productRow = (prod) => {
		const { currentProdTab } = this.props;
		
		return prod.map((data, key) => {
			let type = currentProdTab == "parcel" ? "/parcel/":'/';
			const isDefault = (currentProdTab == "parcel" ? (data.parcel_default == "1" ? <FaAsterisk /> : "") : (data.default == "1" ? <FaAsterisk /> : ""));
			const link = basePath + "/admin/product" + type +"edit/" + data.id + "/" + this.props.warehouseId;
			return {
				add: "",
				id: data.id,
				code: data.code || "",
				name: data.name,
				l: data.length || '',
				w: data.width || '',
				h: data.height || '',
				weight: data.weight,
				declared_value: data.declared_value,
				haz: data.haz_class,
				default: isDefault,
				actions: (
					<div className="actions-center">
						<NavLink to={link}>
							<Button size="sm" color="info" className={this.props.classes.marginRight} onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': this.props.warehouseId, 'tab': this.props.currentTab }))}>
								View/Edit
							</Button>
						</NavLink>
						<Button size="sm" color="danger" onClick={() => this.props.handleDelete(data.id, this.props.warehouseId, "product")}>
							Delete
						</Button>
					</div>
				)
			}
		})
	}

	newProductCell = () => {
		const { currentProdTab } = this.props;
		let type = currentProdTab == "parcel" ? "/parcel/":'/'
		const link = basePath + "/admin/product" + type + "new/" + this.props.warehouseId;
		return (
			<NavLink to={link}>
				<Button 
					color="success" 
					size="sm" 
					justIcon 
					round 
					onClick={() => localStorage.setItem("tms_wh_mgt_id", JSON.stringify({ 'whId': this.props.warehouseId, 'tab': this.props.currentTab }))}
				>
					<Add/>
				</Button>
			</NavLink>
		)
	}

	getProductTable = (prod) => {
		return (
			<div>
				<ReactTable
					data={this.productRow(prod)}
					columns={[
						{
							Header: this.newProductCell,
							accessor: "add",
							width: 50,
							sortable: false,
						},
						{
							Header: "ID",
							accessor: "id",
						},
						{
							Header: "Code",
							accessor: "code",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type={"search"}
										onChange={e => onChange(e.target.value)}
										value={filter ? filter.value : ""}
									/>
								)
							}
						},
						{
							Header: "Name",
							accessor: "name",
							filterable: true,
							filterMethod: (filter, row) => {
								return row[filter.id] !== undefined ? String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
							},
							Filter: ({ filter, onChange }) => {
								return(
									<input
										type={"search"}
										onChange={e => onChange(e.target.value)}
										value={filter ? filter.value : ""}
									/>
								)
							}
						},
						{
							Header: "Length",
							accessor: "l",
							sortable: false,
						},
						{
							Header: "Width",
							accessor: "w",
							sortable: false,
						},
						{
							Header: "Height",
							accessor: "h",
							sortable: false,
						},
						{
							Header: "Weight",
							accessor: "weight",
							sortable: false,
						},
						{
							Header: "Declared Value",
							accessor: "declared_value",
							sortable: false,
						},
						{
							Header: "Hazmat",
							accessor: "haz",
						},
						{
							Header: "Default",
							accessor: "default",
						},
						{
							Header: "Actions",
							accessor: "actions",
							sortable: false,
							resizable: false,
							headerStyle: {textAlign: 'left'}
						},
						
					]}
					defaultPageSize={20}
					loading={this.props.loading}
					showPaginationTop
					showPaginationBottom
					className={this.props.className || "-striped -highlight"}
				/>
			</div>
		)
	}

	render() {
		return(
			<>
				{this.getProductTable(this.props.products)}
			</>
		)
	}
}

export default withStyles(style)(withSnackbar(WarehouseProductCatalog));