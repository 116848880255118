import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import allActions from '../actions/FilterQueryModal.jsx';

const operators = [
    { value: 'equals', text: '=' },
    { value: 'not_equals', text: '!=' },
    { value: 'like', text: 'LIKE' },
    { value: 'not_like', text: 'NOT LIKE' },
    { value: 'begins_with', text: 'BEGINS WITH' },
    { value: 'ends_with', text: 'ENDS WITH' },
    { value: 'greater_than', text: '>' },
    { value: 'greater_than_equal_to', text: '>=' },
    { value: 'less_than', text: '<' },
    { value: 'less_than_equal_to', text: '<=' },
    { value: 'between', text: 'BETWEEN' },
    { value: 'not_between', text: 'NOT BETWEEN' },
    { value: 'in', text: 'IN' },
    { value: 'not_in', text: 'NOT IN' },
    { value: 'is_null', text: 'IS NULL' },
    { value: 'is_not_null', text: 'IS NOT NULL' },
];

const limitedOperators = [
    { value: 'equals', text: '=' },
    { value: 'not_equals', text: '!=' },
    { value: 'like', text: 'LIKE' },
    { value: 'not_like', text: 'NOT LIKE' },
    { value: 'begins_with', text: 'BEGINS WITH' },
    { value: 'ends_with', text: 'ENDS WITH' },
    { value: 'in', text: 'IN' },
    { value: 'not_in', text: 'NOT IN' },
    { value: 'is_null', text: 'IS NULL' },
    { value: 'is_not_null', text: 'IS NOT NULL' },
];

const FilterQuerySelect = ({ isUpdate, fieldsWithLimitedOperators }) => {
    const [op, setOp] = useState([]);
    const [currentOperators, setCurrentOperators] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { operator, filterName } = useSelector(state => ({
        ...state.FilterQueryModal
    }));
   
    useEffect(() => {
        setCurrentOperators(limitedOperators)
        setOp(limitedOperators[0])
        dispatch(allActions.updateOperatorValue(limitedOperators[0]));
    }, []);

    useEffect(() => {
		if(filterName) {
            setCurrentOperators(operators);
            if(isUpdate) {
                // Must use the exact object from the list to avoid Material UI warning
                operators.forEach((element, index) => {
                    if(element.value === operator.value) {
                        setOp(operators[index]);
                    }
                });
            } else {
                setOp(operator.value);
            }

			fieldsWithLimitedOperators.find((item) => {
				if (item === filterName.text) {
                    setCurrentOperators(limitedOperators);
                    if(isUpdate) {
                        // Must use the exact object from the list to avoid Material UI warning
                        let limitedOpIndex = limitedOperators.findIndex(limitedOp => limitedOp.value == operator.value);
                        if(limitedOpIndex == -1) limitedOpIndex = 0;
                        setOp(limitedOperators[limitedOpIndex]);
                        dispatch(allActions.updateOperatorValue(limitedOperators[limitedOpIndex]));
                    } else {
                        setOp(limitedOperators[0]);
                        // Prevents invalid operator when switching to filter that must use the limited operators
                        dispatch(allActions.updateOperatorValue(limitedOperators[0]));
                    }
				}
			});
		}
	}, [filterName]);

    const getVal = () => {
        return operator ? operator.text : operators[0].text;
    }

    const handleChange = (e) => {
        setOp(e.target.value);
        dispatch(allActions.updateOperatorValue(e.target.value));
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    return (
		<>
			{currentOperators.length ? (
				<FormControl style={{ width: "100%" }}>
					<Select
						style={{ width: "inherit" }}
                        defaultValue=""
						open={open}
						value={op || ""}
						onClose={handleClose}
						renderValue={getVal}
						onOpen={handleOpen}
						onChange={handleChange}
					>
						{currentOperators.map((child, i) => (
							<MenuItem key={i} value={child}>
								{child.text}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}
		</>
	);
}

export default FilterQuerySelect;