import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { fetchHeldBillsSummary, exportCategoryCSV, uploadHoldFile, applyInvoiceListFilters } from '../actions/CustomerPortal.jsx';
import TopActions from '../components/TopActions.jsx';
import GridItem from '~/components/Grid/GridItem.jsx';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import { basePath } from '../../../variables/server.jsx';
import "react-table-6/react-table.css";
import ExportSummaryCSVModal from '../components/ExportSummaryCSVModal.jsx';
import Button from "~/components/CustomButtons/Button.jsx";
import Skeleton from 'react-loading-skeleton';
import { getUserSetting } from "../../../redux/selectors/Admin.jsx";
import UploadHeldBillsCSV from '../components/UploadHeldBillsCSV.jsx';

class HeldBillsSummaryContainer extends Component {

	state = {
		exportModalOpen: false,
		initialized: false,
	}

	async componentDidMount() {
		await this.props.fetchHeldBillsSummary();
		this.setState({initialized: true});
	}

	render() {
		if (this.props.loading || !this.state.initialized) return this.loading();
		const columns = [
			{Header: "", sortable: false, Cell: this.continueCell},
			{Header: "Bill Count",    accessor: "billCount"},
			{Header: "Category",      accessor: "category"},
			{Header: "Amount Billed", accessor: "amountBilled"},
			{Header: "Description",   accessor: "description"},
		];

		return (
			<Fragment>
				<ExportSummaryCSVModal 
					open={this.state.exportModalOpen} 
					onClose={this.onCloseExportModal}
					heldBillsByCode={this.props.heldBillsByCode}/>
				<TopActions>
					<GridItem xs={8} sm={8} md={8}>
						<Button
							round
							color="success"
							size="sm"
							onClick={this.onOpenExportModal}
							disabled={!this.state.initialized || !this.props.heldBillsByCode.length}
						>Export to CSV</Button>
						{this.props.hasWrite && (
							<UploadHeldBillsCSV loading={this.props.fileUploadLoading} onFileSelect={this.onFileSelect}/>
						)}
					</GridItem>
					{this.state.initialized && this.props.totalHeldBills && this.props.heldBillsByCode.length && this.props.totalUniqueHeldBills && <GridItem xs={4} sm={4} md={4} style={{ textAlign:"right", color: "#000"}}>
							<h4 style={{marginRight: "5px"}}>{`Invoices: ${this.props.totalUniqueHeldBills}`}  {`Holds: ${this.props.totalHeldBills}`}</h4>
					</GridItem>}
				</TopActions>
				<div className="react-table-condensed">
					<ReactTable
						data={this.props.heldBillsByCode || []}
						columns={columns}
						className={this.props.className || "-striped -highlight"}
						defaultPageSize={99999}
						showPaginationBottom={false}
						loading={this.props.loading}
						minRows={5}
					/>
				</div>
			</Fragment>
		);
	}

	loading() {
		return (
			<Fragment>
				<TopActions />
				<Skeleton height={200} />
			</Fragment>
		);
	}

	continueCell = rowInfo => {
        return (
        	<div style={{marginLeft: "5px"}}>
        		<Link onClick={e => {this.props.applyInvoiceListFilters(null)}} to={`${basePath}/admin/customer-portal/held-bills/${rowInfo.original.code}`}>View</Link>
        	</div>
        );
	}

	onOpenExportModal = () => {
		if (this.props.heldBillsByCode.length === 1) {
			exportCategoryCSV(this.props.heldBillsByCode[0].code);
		} else {
			this.setState({exportModalOpen: true});	
		}
	}

	onCloseExportModal = () => {
		this.setState({exportModalOpen: false});
	}

	onFileSelect = file => {
		this.props.uploadHoldFile(file);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.CustomerPortal.heldBillsSummaryLoading,
		hasWrite: +getUserSetting("hold_write", 0)(state),
		fileUploadLoading: state.CustomerPortal.fileUploadLoading,
		heldBillsByCode: state.CustomerPortal.heldBillsByCode,
		totalHeldBills: state.CustomerPortal.totalHeldBills,
		totalUniqueHeldBills: state.CustomerPortal.totalUniqueHeldBills,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchHeldBillsSummary,
		uploadHoldFile,
		applyInvoiceListFilters
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HeldBillsSummaryContainer);
