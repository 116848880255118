import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import axios from "~/variables/axios.jsx";
import { Redirect, NavLink } from "react-router-dom";
import qs from "qs";
import { basePath } from "~/variables/server.jsx";
import { handleMessages, setNotification } from '../../redux/actions/notifications.jsx';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardFooter from "~/components/Card/CardFooter.jsx";
import { InputAdornment, IconButton, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Spinner from "~/components/TMS/Spinner.jsx";
import PasswordField from "~/components/TMS/PasswordField.jsx";
import { validatePassword } from "../../helpers.jsx";
import loginPageStyle from "~/assets/jss/empire-tms/views/loginPageStyle.jsx";

class PasswordResetPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			token: '',
			id: '',
			showForm: false,
			password: '',
            confirmPassword: '',
            showConfirmPasswordValue: false,
			oldPassword: '',
			showOldPasswordField: true,
			showOldPasswordValue: false,
			uppercase: false,
			lowercase: false,
			digit: false,
			specialCharacter: false,
			redirect: false,
		};
	}
	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function() {
				this.setState({ cardAnimation: "" });
			}.bind(this),
			700
		);
		// Users who are logged in changing password
		if(this.props.match.params.hasOwnProperty('id')) {
			const userId = this.props.match.params.id;
			if(userId === undefined) {
				this.setState({ redirect: true });
			} else {
				this.setState({
					loading: false,
					showForm: true,
					id: userId,
				});
			}
		// Users who are not logged in changing password
		} else if(this.props.match.params.hasOwnProperty('token')) {
			const token = this.props.match.params.token;
			if(token === undefined) {
				this.setState({ loading: false });
			} else {
				this.validateToken(token);
			}
		} else {
			this.setState({ loading: false });
		};
	}
	validateToken = async (token) => {
		try {
			const response = await axios.post(
				'/index.php?p=api&r=json&c=user&m=validateToken',
				qs.stringify({ token })
			);
			this.props.handleMessages(response);
			if(response.data) {
				if(!_.isEmpty(response.data.body)) {
					this.setState({
						loading: false,
						showForm: true,
						showOldPasswordField: false, // not needed for users with token
						token: token,
						id: response.data.body.id,
					});
				} else {
					this.setState({ loading: false });
				}
			} else {
				this.props.setNotification("Error while validating", { variant: "error" });
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("Exception caught while validating", { variant: "error" });
		}
	}
	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}
	toggle = name => () => {
        this.setState({ [name]: !this.state[name] });
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	}
	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleKeyPress = (event) => {
		if(event.key === 'Enter') this.resetPassword();
	}
	resetPassword = async () => {
		const { oldPassword, password, confirmPassword, token, id } = this.state;
		if(!token && !oldPassword) {
			this.props.setNotification("Please provide current password", { variant: "error" });
			return;
		}
        if(!password || !confirmPassword) {
            this.props.setNotification("Please fill in both new password fields", { variant: "error" });
			return;
        }
		if(password !== confirmPassword) {
			this.props.setNotification("Please ensure both new password fields match", { variant: "error" });
			return;
		}
		if(!token && password === oldPassword) {
			this.props.setNotification("New password cannot match current password", { variant: "error" });
			return;
		}
		if(password.length < 8) {
			this.props.setNotification("New password must be at least 8 characters", { variant: "error" });
			return;
		}
		if(!validatePassword(password, this.props.username)) {
			this.props.setNotification("New password does not meet character requirements", { variant: "error" });
			return;
		}
		try {
			const response = await axios.post(
				"/index.php?p=api&r=json&c=user&m=resetPassword",
				qs.stringify({ oldPassword, password, token, id })
			);
			this.props.handleMessages(response);
			if(response.data) {
				if(response.data.body === true) {
					this.setState({ redirect: true });
				}
			} else {
				this.props.setNotification("Error while updating password", { variant: "error" });
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("Exception caught while updating password", { variant: "error" });
		}
	}
	render() {
		const {
			loading,
			token,
			showForm,
			cardAnimation,
			showOldPasswordField,
			showOldPasswordValue,
			showConfirmPasswordValue,
			redirect,
		} = this.state;
		const { classes } = this.props;
		if(redirect) {
			if(!token) {
				return <Redirect to={`${basePath}/admin/dashboard`}/>;
			} else {
				return <Redirect to={`${basePath}/auth/login-page`}/>;
			}
		}
		return (
			<div className={classes.container}>
				<GridContainer justifyContent="center">
					{loading ? (
						<GridItem>
							<Card login className={classes[cardAnimation]}>
								<CardHeader
									className={`${classes.cardHeader} ${classes.textCenter}`}
									color="tfmOrange"
								>
									<Typography variant="h6">
										Validating
									</Typography>
								</CardHeader>
								<CardBody>
									<Typography variant="body2">
										Please wait patiently while we validate your token...
									</Typography>
									<Spinner loading={loading} message="Failed to validate token" />
								</CardBody>
							</Card>
						</GridItem>
					) : (
						<GridItem xs={12} sm={6} md={4}>
							<Card login className={classes[cardAnimation]}>
								{showForm ? (
									<>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="tfmOrange"
										>
											<Typography variant="h6">
												Change Password
											</Typography>
										</CardHeader>
										<CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
											{showOldPasswordField && (
												<CustomInput
													labelText="Current Password"
													id="oldPassword"
													formControlProps={{ fullWidth: true }}
													inputProps={{
														name: "oldPassword",
														type: showOldPasswordValue ? "text" : "password",
														onChange: this.handleInput('oldPassword'),
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	onClick={this.toggle('showOldPasswordValue')}
																	edge="end"
																>
																{showOldPasswordValue ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											)}
											<PasswordField
												label="New Password"
												password={this.state.password}
												handleChange={(e) => this.handleChange(e)}
											/>
											<CustomInput
												labelText="Confirm New Password"
												id="confirmPassword"
												formControlProps={{ fullWidth: true }}
												inputProps={{
													name: "confirmPassword",
													type: showConfirmPasswordValue ? "text" : "password",
													onChange: this.handleInput('confirmPassword'),
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																onClick={this.toggle('showConfirmPasswordValue')}
																edge="end"
															>
															{showConfirmPasswordValue ? <VisibilityOff /> : <Visibility />}
															</IconButton>
														</InputAdornment>
													),
													onChange: this.handleInput('confirmPassword'),
													onKeyPress: this.handleKeyPress
												}}
											/>
										</CardBody>
										<CardFooter>
											<GridContainer>
												<GridItem xs={12}>
													<Button
														fullWidth
														color="tfmBlue"
														variant="outlined"
														onClick={this.resetPassword}
													>
														Update
													</Button>
												</GridItem>
											</GridContainer>
										</CardFooter>
									</>
								) : (
									<>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="tfmOrange"
										>
											<Typography variant="h6">
												Error
											</Typography>
										</CardHeader>
										<CardBody>
											<Typography variant="body2">
												This password reset link has expired or there was a problem validating your token. You may request a new link from the login page. If this continues to be a problem, please send us an email at customerservice@targetfmi.com for assistance.
											</Typography>
										</CardBody>
										<CardFooter>
											<GridContainer>
												<GridItem xs={12}>
													<NavLink to={`${basePath}/auth/login-page`}>
														<Button
															fullWidth
															color="tfmBlue"
															variant="outlined"
														>
															Return to Login Page
														</Button>
													</NavLink>
												</GridItem>
											</GridContainer>
										</CardFooter>
									</>
								)}
							</Card>
						</GridItem>
					)}
				</GridContainer>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		username: (state.Admin && state.Admin.response && state.Admin.response.data && state.Admin.response.data.user && state.Admin.response.data.user.name) ? state.Admin.response.data.user.name : ""
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(PasswordResetPage));