import React from "react";
import { 
	useTable, 
	useSortBy, 
	useResizeColumns, 
	useFlexLayout, 
} from "react-table"
import { FixedSizeList } from 'react-window'

// @material-ui/core components
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Pagination, Skeleton } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import SimplePagination from "~/components/Pagination/Pagination.jsx";


// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

// lib
import utils from '~/lib/utils.js';

import './ReactTable.css';


function setColumnDefaults(columns, defaultColumn) {
	const setDefaultIdentifier = (c) => {
		if (!Object.hasOwn(c, 'indentifier')) {
			c.indentifier = typeof c.Header === 'string' ? c.Header.toLowerCase() : '';
		}
	}

	const setDefaultType = (c) => {
		if (!Object.hasOwn(c, 'type')) {
			c.type = String;
		}
	}

	const setDefaultWidth = (c) => {
		if (!Object.hasOwn(c, 'width') && typeof c.Header === 'string') {
			c.width = parseInt(c.Header.length * 20.0);
		}
		c.originalWidth = c.width;
	}

	const setDefaultResizing = (c) => {
		if (!Object.hasOwn(c, 'resizable')) {
			c.resizable = true;
		}
		c.resetWidth = (e) => {
			// TODO:
		}
	}
	
	const setDefaultSorting = (c) => {
		if (!Object.hasOwn(c, 'sortable')) {
			c.sortable = true;
		}
		if (!c.sortable) {
			return;
		}
		if (!Object.hasOwn(c, 'sortValue')) {
			if (c.type === Number) {
				c.sortValue = (r, id) => utils.toFloat(r[id]);
			} else if (c.type === Date) {
				c.sortValue = (r, id) => utils.toDate(r[id]);
			} else if (c.type === String) {
				c.sortValue = (r, id) => utils.toString(r[id]);
			}
		}
		if (!Object.hasOwn(c, 'sortFunction')) {
			if (c.type === Number || c.type === Date) {
				c.sortFunction = (a, b) => a - b;
			} else if (c.type === String) {
				c.sortFunction = (a, b) => a.localeCompare(b);
			}
		}
		c.sortType = (rowA, rowB, id, desc) => {
			const a = c.sortValue(rowA.original, id);
			const b = c.sortValue(rowB.original, id);
			return c.sortFunction(a, b); // desc or asc handled by react-table
		};
	}
	
	const setDefaultAlign = (c) => {
		if (!Object.hasOwn(c, 'align')) {
			if (c.type === Number) {
				c.align = 'right';
			} else if (c.type === Date) {
				c.align = 'right';
			} else if (c.type === String) {
				c.align = 'left';
			}
		}
		if (!Object.hasOwn(c, 'Cell')) {
			// const paddingRight = enableColumnSorting && c.sortable && c.align !== "left"? "32px" : "0px";
			c.Cell = row => <div style={{ textAlign: c.align }} >{row.value}</div>
		}
	}

	for (let c of columns) {
		Object.assign(c, defaultColumn);
		setDefaultIdentifier(c);
		setDefaultType(c);
		setDefaultWidth(c);
		setDefaultResizing(c);
		setDefaultSorting(c);
		setDefaultAlign(c);
	}
}

function hideColumns(columns, hiddenColumns) {
	hiddenColumns = new Set(hiddenColumns);
	for (let i = columns.length - 1; i > -1; i--) {
		if (hiddenColumns.has(columns[i].identifier)) {
			columns.splice(i, 1);
		}
	}
}

function getPagination(prev, next, setPageNumber) {
	let nextPage = { text: ">" };

	if (next !== "" && !isNaN(next)) {
		nextPage.onClick = e => setPageNumber(next);
	} else {
		nextPage.disabled = true;
	}

	let prevPage = { text: "<" };

	if (prev !== "" && !isNaN(prev)) {
		prevPage.onClick = e => setPageNumber(prev);
	} else {
		prevPage.disabled = true;
	}

	return [prevPage, nextPage];
}

function getScrollbarWidth() {
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}



const defaultProps = {
	/* states */
	loading: false,
	disabled: false,
	
	/* page options */
	leftPaginationLabelFormat: ({ from, to, count }) => '',
	leftPaginationLabelLoadingFormat: ({ from, to, count }) => '',
	rightPaginationLabelFormat: ({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`,
	rightPaginationLabelLoadingFormat: ({ from, to, count }) => "loading...",
	setPageNumber: () => {},
	
	/* column options */
	enableSorting: false,
	enableResizing: false,
	hiddenColumns: [],
	defaultColumn: {},
	
	/* row options */
	visibleRows: 10,
	rowHeight: 53,
	overscan: 10,
	simplePagination: false,
}


const ReactTable = ({
	loading,
	disabled,
	columns,
	data,
	leftPaginationLabelFormat,
	leftPaginationLabelLoadingFormat,
	rightPaginationLabelFormat,
	rightPaginationLabelLoadingFormat,
	pageSize,
	pageNumber,
	setPageNumber,
	enableSorting,
	enableResizing,
	defaultColumn,
	hiddenColumns,
	totalRows,
	visibleRows,
	rowHeight,
	overscan,
	highlightOnSelect,
	selectedRow,
	simplePagination,
	nextPage,
	prevPage
}) => {

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		allColumns,
		totalColumnsWidth,
		rows,
		prepareRow,
		state: { 
			columnResizing
			// sortBy,
		}
	} = useTable({
		columns: React.useMemo(() => {
			setColumnDefaults(columns, defaultColumn);
			hideColumns(columns, hiddenColumns);
			return columns;
		}),
		data: React.useMemo(() => data),
	}, 
		useSortBy, 
		useResizeColumns,
		useFlexLayout,
	)

	const scrollBarWidth = React.useMemo(() => getScrollbarWidth());

	const rowCount = rows.length
	const rowStart = (pageNumber - 1) * pageSize;
	const rowEnd = rowStart + rowCount;

	const pageWidth = totalColumnsWidth + scrollBarWidth;
	const pageHeight = visibleRows * rowHeight;
	const pageCount = Math.ceil(totalRows / pageSize);
	
	const canShowSorting = !loading && !disabled && rows.length !== 0 && enableSorting;
	const canShowResizing = !loading && !disabled && enableResizing;

	const renderDataRow = React.useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);
			return (
				<TableRow
					style={style}
					className={index%2 ? "row ListItemOdd" : 'row'}
					{...row.getRowProps({
						style
					})}
				>
					{row.cells.map((cell, colIndex) => (
						<TableCell 
							className={allColumns[colIndex].isResizing ? 'column-resizing' : 'column'} 
							{...cell.getCellProps({
								style: disabled ? {
									opacity : .5, 
									pointerEvents: 'none',
									userSelect: 'none'
								} : (highlightOnSelect && index == selectedRow) ? { backgroundColor: "#fcf7c2"} : {}
							})} 
						>	
							{cell.render('Cell')}
						</TableCell>
					))}
				</TableRow>
			);
		},
		[allColumns, rows, prepareRow, disabled]
	);

	const renderDummyRow = React.useMemo(() => (
		<TableRow className={"row"} style={{height: rowHeight}}>
			{Array(allColumns.length).fill(null).map((nullVal, colIndex) => (
				<TableCell style={{width: allColumns[colIndex].width}}>
					<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
				</TableCell>
			))}
		</TableRow>
	), [allColumns, rowHeight]);

	return (
		<div className={"ReactTable"} >
			<GridContainer className={"pagination"}>
				<GridItem xs={4} className="text">
					{
						loading ? 
						leftPaginationLabelLoadingFormat({ from: rowCount > 0? rowStart + 1 : rowStart, to: rowEnd, rowCount: totalRows}) 
						: 
						leftPaginationLabelFormat({ from: rowCount > 0? rowStart + 1 : rowStart, to: rowEnd, rowCount: totalRows})
					}
				</GridItem>
				<GridItem xs={4} className="pages">
					{ simplePagination ? 
						<SimplePagination pages={getPagination(prevPage, nextPage, setPageNumber)}/>
					: 
						<Pagination 
							size="medium"
							page={pageNumber}
							count={pageCount} 
							shape="rounded" 
							color="primary"
							showFirstButton 
							showLastButton
							onChange={async (e, newPageNumber) => setPageNumber(newPageNumber)}
							disabled={disabled}
						/>
					}
				</GridItem>
				<GridItem xs={4} className="text">
					{
						loading ? 
						rightPaginationLabelLoadingFormat({ from: rowCount > 0? rowStart + 1 : rowStart, to: rowEnd, rowCount: totalRows}) 
						: 
						rightPaginationLabelFormat({ from: rowCount > 0? rowStart + 1 : rowStart, to: rowEnd, rowCount: totalRows})
					}
				</GridItem>
			</GridContainer>
			<TableContainer className="container">
				<Table className="table" {...getTableProps()} >
					<TableHead className="head">
						{headerGroups.map(headerGroup => (
							<TableRow className="row" {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<TableCell 
										className="head-column"
										{...column.getHeaderProps()} 
										sortDirection={column.isSorted? (column.isSortedDesc? 'desc': 'asc'): false}
									>
										{
											canShowSorting && column.sortable?
											<div className="column-sort" {...column.getSortByToggleProps({title: undefined})}>
												{column.render("Header")}
												<TableSortLabel
													className="column-sort-label"
													active={column.isSorted}
													direction={column.isSortedDesc? 'desc': 'asc'}
													// onClick={() => setDisabled(true)}
												></TableSortLabel>
											</div>
											:
											column.render("Header")
										}
										{
											canShowResizing && column.resizable &&
											<div
												className={column.isResizing? 'resizer isResizing': (columnResizing.isResizingColumn? '' : 'resizer')}
												onClick={(event)=> event.stopPropagation()}
												onDoubleClick={() => {column.resetWidth.bind(this)}}
												{...column.getResizerProps()}
											/>
										}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody className="body" {...getTableBodyProps()} >
						{
							loading?
							<Box className="table-view" sx={{ width: pageWidth, height: pageHeight }}>
								{Array(visibleRows).fill(null).map(() => renderDummyRow)}
							</Box>
							:
							<FixedSizeList
								className="table-view"
								height={pageHeight}
								width={pageWidth}
								itemCount={rowCount}
								itemSize={rowHeight}
								overscanCount={overscan}
								style={{borderBottom: "1px solid rgba(224, 224, 224, 1)"}}
							>
								{renderDataRow}
							</FixedSizeList>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

ReactTable.defaultProps = defaultProps;


export default ReactTable;
