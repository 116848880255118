export const capitalizePhrase = phrase => {
    return phrase.split(" ").map(str => {return str.charAt(0).toUpperCase() + str.slice(1);}).join(" ");
}

/**
 * For use with react-datetime onChange handlers
 * If the user selects from the datepicker, react-datetime returns a moment object
 * if the user manually types into the datepicker, it returns a string
 * @return string
 */
export const handleReactDatetimeChange = (moment, format) => {
	const val =  (typeof moment === "object" && typeof moment.format === "function")
		? (moment.isValid() ? moment.format(format) : null)
		: moment;
	return val;
}

export const getDateFromBol = (bol) => {
	return typeof bol === "string" ? bol.substring(0, 8) : "";
}

export const getIdFromBol = (bol) => {
	return typeof bol === "string" ? bol.substring(8) : "";
}

/**
 * recursively retrieve a value from an object using dot notation
 * @param  object search Object to search
 * @param  string keyStr search string
 * @param  mixed  def    default to return if not found
 */
export const searchObject = (search, keyStr, def = null) => {
	let keys = keyStr.split(".");
	if (!search || typeof search !== "object" || !search.hasOwnProperty(keys[0])) {
		return def;
	}
	let value = search[keys[0]];
	if (keys.length === 1) {
		return value;
	}
	keys.shift();
	return searchObject(value, keys.join("."), def);
}

/**
 * Format a number as currency using the Intl API
 */
export const formatCurrency = (number, locale, options) => {
	locale = typeof locale === "undefined" ? "en-US" : locale;
	options = typeof options === "object" ? options : {style: "currency", currency: "USD"};
	return new Intl.NumberFormat(locale, options).format(+number);
}

//because unfortunately, typeof null = "object"
export const isObject = (obj) => {
	return typeof obj === "object" && obj !== null;
}

// Convert common country values to a desired code format
export const convertToCountryCode = (country = "", format) => {
	const uppercaseCountry = country.trim().toUpperCase();
	switch(uppercaseCountry) {
		case 'MX':
		case 'MEX':
		case 'MEXICO':
			switch(format) {
				case 'short':
					return 'MX';
				case 'long':
					return 'MEX';
			}
		case 'CA':
		case 'CAN':
		case 'CANADA':
			switch(format) {
				case 'short':
					return 'CA';
				case 'long':
					return 'CAN';
			}
		case 'US':
		case 'UNI': // sometimes this shows up because of truncation
		case 'USA':
		case 'U.S.':
		case 'U.S.A.':
		case 'UNITED STATES':
		case 'UNITED STATES OF AMERICA':
			switch(format) {
				case 'short':
					return 'US';
				case 'long':
					return 'USA';
			}
    }
    return country;
}

export const formatHazUoM = (uom) => {
	let unit = uom;
	let allowed = []
	switch (uom) {
		case "quart":
			unit = 'qt';
			break;
		case 'pound':
			unit = 'lbs';
			break;
		case 'ounce':
			unit = 'oz';
			break;
		case 'ml':
			unit = 'mL';
			break;
		case 'kg G':
			unit = 'kgG';
			break;
	}
	allowed.push(unit)
	return allowed;
}

/**
 *
 * @param string offset  ex: '-7', '-0900', '-0330', '12'
 * @returns string offset converted to minutes.
 */
export const getTimezoneOffset = (offset, dst) => {

	let newOffset = false;
	if(offset.length == 5) {
		switch(offset)
		{
			// these are all of the offsets that are not full hours.
			case '-0930':
			case '-0330':
			case '+0330':
			case '+0430':
			case '+0530':
			case '+0630':
			case '+0930':
			case '+1030':
				let newTime = '.5'
				offset = offset.substring(0,3);
				offset = offset + newTime;
			default:
				offset = offset.replaceAll('0','');
				newOffset = parseFloat(offset) * 60;
		}
	} else if (offset.length <= 3) {
		if(!isNaN(offset)) {
			newOffset = parseInt(offset) * 60;
		}
	}
	if(dst && isDST()) newOffset += 60;
	return newOffset;
}

/**
 * Determines if daylight savings is in effect on a date (with today as default)
 * Note: getTimezoneOffset is not the above function, but a method of Date
 * @param date Date object
 * @returns boolean
 */
export const isDST = (date = new Date()) => {
    const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== date.getTimezoneOffset();
}

// Check if provided value meets current password policies
export const validatePassword = (password, username) => {
	let lowerPassword = password.toLowerCase();
	let lowerUsername = username.toLowerCase();
	const uppercaseRegExp = /[A-Z]/;
	const lowercaseRegExp = /[a-z]/;
	const digitRegExp = /\d/;
	const specialCharacterRegExp = /[`~!@#%&_=:"'<>,\-\.\+\*\?\^\$\(\)\[\]\{\}\|\\\/]/;
	const uppercase = password.match(uppercaseRegExp) ? true : false;
	const lowercase = password.match(lowercaseRegExp) ? true : false;
	const digit = password.match(digitRegExp) ? true : false;
	const specialCharacter = password.match(specialCharacterRegExp) ? true : false;
	if(lowerUsername && lowerPassword.includes(lowerUsername)) {
		return false;
	}
	if ([uppercase, lowercase, digit, specialCharacter].filter(Boolean).length < 3) return false;
	return true;
}

// Prevents invalid values from imported products from populating state.
export const validateItem = (item, type) => {
    const innerContainer = [
        "AluminiumBag",
		"AluminiumCans",
		"AluminiumTin",
		"AluminiumTube",
		"Earthenware",
		"FiberBox",
		"FiberCan",
		"GlassAmpoule",
		"GlassTube",
		"MetalCans",
		"MetalFlexibleTube",
		"MetalTin",
		"MetalTube",
		"MultiWallPaperBag",
		"PaperBag",
		"PlasticBag",
		"PlasticContainer",
		"PlasticFlexibleTube",
		"PlasticTube",
		"Receptacle"
    ];

	const classes = [
		"1.4",
		"1.4A",
		"1.4B",
		"1.4C",
		"1.4D",
		"1.4E",
		"1.4F",
		"1.4G",
		"1.4H",
		"1.4J",
		"1.4K",
		"1.4L",
		"1.4S",
		"1.4N",
		"2.1",
		"2.2",
		"3",
		"3,6.1",
		"3,8",
		"4.1",
		"4.2",
		"4.3",
		"5.1",
		"5.2",
		"6.1",
		"6.1,3",
		"6.1,8",
		"6.2",
		"7",
		"8",
		"8,3",
		"9",
	];

	const packingGroup = [
		"I",
		"II",
		"III",
	];

	const packagingType = [
		"CYLINDER",
		"FIBERBOARD",
		"PLASTIC BOX",
		"PLASTIC DRUM",
		"PLASTIC JERRICAN",
		"STEEL DRUM",
		"WOODEN BOX",
	];

	const regulatoryQty = [
		"FR",
		"LQ",
	];

	const uom = [
		"cylinder",
		"g",
		"gallon",
		"kg",
		"kg G",
		"L",
		"ml",
		"ounce",
		"pint",
		"pound",
		"quart",
	];

	const accessibility = [
		"ACCESSIBLE",
		"INACCESSIBLE",
	];

	const transMode = [
		"Ground",
		"Passenger",
		"Cargo",
	];

	if (type == 'innerContainer' && innerContainer.includes(item)) {
		return item;
	} else if (type == 'class' && classes.includes(item)) {
		return item;
	} else if (type == 'pkgGroup' && packingGroup.includes(item)) {
		return item;
	} else if (type == 'pkgType' && packagingType.includes(item)) {
		return item;
	} else if (type == 'regQty' && regulatoryQty.includes(item)) {
		return item;
	} else if (type == 'uom' && uom.includes(item)) {
		return item;
	} else if (type == 'accessibility' && accessibility.includes(item)) {
		return item;
	} else if (type == 'transMode' && transMode.includes(item)) {
		return item;
	} else {
		return "";
	}
}

// ADMIN VIEW HELPERS
export const getCustomTableHeaders = (customColumns, columns) => {
	let cols = customColumns.length ? customColumns : columns;
	return cols.map((col) => {
		const disabled = col.colDisabled === "false" ? false : (!col.colDisabled ? false : true);
		if (!disabled) {
			return col.Header;
		}
	});
}

export const getPages = (body, handlePageClick) => {
	let current = 1;
	let last = 1;

	if (!_.isEmpty(body)) {
		current = body.current;
		last = body.last;
	}

	let pages = [{ active: true, text: "..." }];
	const nextPage = { text: ">" };
	const prevPage = { text: "<" };

	if (last > 1 && !_.isEmpty(body) && !_.isEmpty(body.pages)) {
		pages = body.pages.map(index => {
			const page = { text: index };
			if (index == parseInt(current)) {
				page.active = true;
			} else {
				page.onClick = e => handlePageClick(index);
			}
			return page;
		});
	}

	if (current < last) {
		nextPage.onClick = e => handlePageClick(parseInt(current) + 1);
	} else {
		nextPage.disabled = true;
	}

	if (parseInt(current) - 1 !== 0) {
		prevPage.onClick = e => handlePageClick(parseInt(current) - 1);
	} else {
		prevPage.disabled = true;
	}

	pages.push(nextPage);
	pages.unshift(prevPage);

	return pages;
}