import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { hasPermission } from "~/redux/selectors/Admin.jsx";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";
import SaveAlt from "@material-ui/icons/SaveAlt";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import ClaimsBadge from "~/components/CustomBadges/ClaimsBadge.jsx";

import { basePath, apiUrl } from "~/variables/server.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Claims extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			data: [],
			user: null,
			next: "",
			prev: "",
			filters: [
				{
					field: "claim_id",
					value: ""
				}
			],
			status_options: [],
		};
		this.getPages = this.getPages.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.getFilters = this.getFilters.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.removeFilter = this.removeFilter.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleFilters = this.handleFilters.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const url = "/index.php?p=api&r=json&c=claims&m=view";
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.user) &&
				!_.isEmpty(response.data.body) &&
				this.state.mounted
			) {
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					data: response.data.body.records,
					next: response.data.body.next || "",
					prev: response.data.body.prev || "",
					status_options: response.data.body.status_options,
					denial_reason_options: response.data.body.denial_reason_options,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	getClaims(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			const link = basePath + "/admin/claim/" + prop.id;
			return [
				prop.id,
				prop.company_name,
				prop.carrier_name,
				prop.pro,
				prop.claim_no,
				"$" + prop.amount,
				prop.payout ? "$" + prop.payout : "",
				prop.claim_type,
				<ClaimsBadge classes={classes} status={prop.status} color={prop.status_color}/>,
				prop.denial_reason,
				prop.create_time,
				<div className="actions-right">
					<NavLink to={link}>
						<Button
							size="sm"
							color="info"
						>
							View/Edit
						</Button>
					</NavLink>
				</div>
			];
		});
	}
	getPages(next, prev) {
		let nextPage = { text: ">" };

		if (next) {
			nextPage.onClick = e => this.handlePageClick(next);
		} else {
			nextPage.disabled = true;
		}

		let prevPage = { text: "<" };

		if (prev) {
			prevPage.onClick = e => this.handlePageClick(prev);
		} else {
			prevPage.disabled = true;
		}

		return [prevPage, nextPage];
	}
	async handlePageClick(page) {
		this.setState({
			show: false,
			loading: true
		});
		const url = "/index.php?p=api&r=json&c=claims&m=view&page=" + page + this.prepareFilterUri();
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				this.setState({
					show: true,
					loading: false,
					data: response.data.body.records,
					next: response.data.body.next || "",
					prev: response.data.body.prev || ""
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the data!",
				{ variant: "error" }
			);
		}
	}

	prepareFilterUri() {
		const { filters } = this.state;
		let filterURI = "";

		for (const filter of filters) {
			if (_.isEmpty(filter.value.trim())) {
				continue;
			}
			filterURI += "&filters[" + filter.field + "]=" + encodeURIComponent(filter.value);
		}

		return filterURI;
	}

	async handleSearch() {
		const filterUri = this.prepareFilterUri();

		let url = "/index.php?p=api&r=json&c=claims&m=view" + this.prepareFilterUri();
		this.setState({
			show: false,
			loading: true
		});
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				this.setState({
					show: true,
					loading: false,
					data: response.data.body.records,
					next: response.data.body.next || "",
					prev: response.data.body.prev || ""
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the data!",
				{ variant: "error" }
			);
		}
	}
	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch();
		}
	}
	getStatusOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.status}
				>
					{prop.status}
				</MenuItem>
			);
		});
	}
	getDenialReasonOptions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.reason}
				>
					{prop.reason}
				</MenuItem>
			);
		});
	}
	getFilters(filters) {
		const { classes } = this.props;
		return filters.map((prop, key) => {
			return (
				<Grid item xs={12} key={key}>
					<Grid container>
						<GridItem xs>
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<Select
									MenuProps={{ className: classes.selectMenu }}
									classes={{ select: classes.select }}
									value={prop.field || ""}
									inputProps={{ name: "field" }}
									onChange={e => this.handleFilters(key, "field", e)}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="claim_id"
									>
										Claim ID
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="type"
									>
										Type
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="status"
									>
										Status
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="denial_reason"
									>
										Denial Reason
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="claim_no"
									>
										Claim #
									</MenuItem>
									{(!_.isEmpty(this.state.user) && (!_.isEmpty(this.state.user.parent_id) || this.state.user.level == "admin")) && (
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="company"
										>
											Company
										</MenuItem>
									)}
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="carrier"
									>
										Carrier
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="pro"
									>
										PRO #
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="bol"
									>
										BOL #
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="amount"
									>
										Amount
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="check_no"
									>
										TFM Check #
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						{prop.field == "type" && (
							<GridItem xs>
								<FormControl
									fullWidth
									className={classes.selectFormControl}
								>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{ select: classes.select }}
										value={prop.value || ""}
										inputProps={{ name: "value" }}
										onChange={e => this.handleFilters(key, "value", e)}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Concealed Damage"
										>
											Concealed Damage
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Damage"
										>
											Damage
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Damage / Shortage"
										>
											Damage / Shortage
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Shortage"
										>
											Shortage
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Lost"
										>
											Lost
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Lost / Damage"
										>
											Lost / Damage
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Service Failure"
										>
											Service Failure
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="Other"
										>
											Other
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
						)}
						{prop.field == "status" && (
							<GridItem xs>
								<FormControl
									fullWidth
									className={classes.selectFormControl}
								>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{ select: classes.select }}
										value={prop.value || ""}
										inputProps={{ name: "value" }}
										onChange={e => this.handleFilters(key, "value", e)}
									>
										{!_.isEmpty(this.state.status_options) && this.getStatusOptions(this.state.status_options)}
									</Select>
								</FormControl>
							</GridItem>
						)}
						{prop.field == "denial_reason" && (
							<GridItem xs>
								<FormControl
									fullWidth
									className={classes.selectFormControl}
								>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{ select: classes.select }}
										value={prop.value || ""}
										inputProps={{ name: "value" }}
										onChange={e => this.handleFilters(key, "value", e)}
									>
										{!_.isEmpty(this.state.denial_reason_options) && this.getDenialReasonOptions(this.state.denial_reason_options)}
									</Select>
								</FormControl>
							</GridItem>
						)}
						{prop.field !== "type" && prop.field !== "status" && prop.field !== "denial_reason" && (
							<GridItem xs>
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										value: prop.value || "",
										onChange: e => this.handleFilters(key, "value", e),
										onKeyPress: e => this.handleKeyPress(e),
										startAdornment: prop.field === "amount" ? <InputAdornment position="start">$</InputAdornment> : undefined,
									}}
									white
								/>
							</GridItem>
						)}
						<GridItem xs={5}>
							{key == 0 ? (
								<Button
									size="sm"
									color="success"
									className={classes.marginLeft}
									onClick={() => this.addFilter()}
								>
									<Add /> Add Filter
								</Button>
							) : (
								<Button
									size="sm"
									color="danger"
									className={classes.marginLeft}
									onClick={() => this.removeFilter(key)}
								>
									<Close /> Remove Filter
								</Button>
							)}
							{key == 0 && (
								<Button
									size="sm"
									color="linkedin"
									className={classes.marginLeft}
									onClick={() => this.handleSearch()}
								>
									<Search /> Search
								</Button>
							)}
							{key == 0 && (this.state.filters.length > 1 || prop.value !== "") && (
								<Button
									size="sm"
									color="white"
									className={classes.marginLeft}
									onClick={() => this.clearFilters()}
								>
									<ClearAll /> Clear Filters
								</Button>
							)}
							{key == 0 && this.state.user && (this.state.user.level === 'admin' || this.props.hasClaimsExport) && (
								<Button
									size="sm"
									color="success"
									className={classes.marginLeft}
									onClick={() => this.export()}
								>
									<SaveAlt /> Export
								</Button>
							)}
						</GridItem>
					</Grid>
				</Grid>
			);
		});
	}
	addFilter() {
		let { filters } = this.state;
		filters.push({
			field: "claim_id",
			value: ""
		});
		this.setState({ filters });
	}
	removeFilter(key) {
		let { filters } = this.state;
		if (!_.isEmpty(filters) && key != 0) {
			filters.splice(key, 1);
			this.setState({ filters });
		}
	}
	async clearFilters() {
		this.setState({
			show: false,
			loading: true,
			filters: [
				{
					field: "claim_id",
					value: ""
				}
			]
		});
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=claims&m=view");
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				this.setState({
					show: true,
					loading: false,
					data: response.data.body.records,
					next: response.data.body.next || "",
					prev: response.data.body.prev || ""
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the claims!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the claims!", {
				variant: "error"
			});
		}
	}
	handleFilters(key, name, event) {
		let { filters } = this.state;
		filters[key][name] = event.target.value;
		this.setState({ filters });
	}
	export() {
		let filterUri = this.prepareFilterUri();
		if(!filterUri) {
			this.props.setNotification("Search values are required", { variant: "error" });
			return false;
		}
		let url = apiUrl + "/index.php?p=api&r=json&c=claims&m=export" + filterUri;
		window.location = url;
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={3}>
									<NavLink to={basePath + "/admin/claim/new"}>
										<Button color="linkedin">
											New Claim
										</Button>
									</NavLink>
								</GridItem>
								<GridItem xs={12} sm={12} md={9}>
									<GridContainer>
										{!_.isEmpty(this.state.filters) && this.getFilters(this.state.filters)}
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPages(this.state.next, this.state.prev)} />
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={_.isEmpty(this.state.data) ? classes.center : classes.left}
									>
										{!_.isEmpty(this.state.data) ? (
											<Table
												tableHead={[
													"ID",
													"Company",
													"Carrier",
													"PRO",
													"Claim Number",
													"Amount",
													"Payout",
													"Type",
													"Status",
													"Denial Reason",
													"Create Time",
													"Actions"
												]}
												tableData={this.getClaims(this.state.data)}
												customCellClasses={[classes.right]}
												customClassesForCells={[10]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[10]}
											/>
										) : (
											<Badge color="warning">No Claims</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve claims from the server"
									/>
								)}
							</Grid>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPages(this.state.next, this.state.prev)} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
        hasClaimsExport: hasPermission("USE_EXPORT_CLAIMS")(state),
    };
}

export default connect(mapStateToProps)(withStyles(style)(withSnackbar(Claims)));
