import React from "react";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

class HeldBillsListReleaseCard extends React.Component {

	state = {
		comment: this.props.comment || '',
		gl: this.props.gl || '',
	}

	render() {
		return (
			<GridContainer justify="center">
				<GridItem xs={6}>
					<Card style={{marginBottom:"10px"}}>
						<CardBody>
							<TextField
								fullWidth
								autoFocus
								label="Comment"
								value={this.state.comment}
								required={true}
								onKeyPress={this.handleKeyPress}
								onChange={this.handleCommentChange}
							/>
							{this.props.requireGlCode ? (
								<TextField
									style={{marginTop:"10px"}}
									fullWidth
									label="GL Code"
									required={true}
									value={this.state.gl}
									onKeyPress={this.handleKeyPress}
									onChange={this.handleGlChange}
								/>
							) : null}
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} style={{ textAlign: "center" }}>
					<Button
						round
						color="success"
						size="sm"
						disabled={this.props.disabled || this.props.loading || (!this.state.comment.length) || (this.props.requireGlCode && !this.state.gl.length)}
						onClick={this.release}
					>
						{this.props.loading ? <CircularProgress color="inherit" size={20}/> : <span>Release</span>}
					</Button>
				</GridItem>
			</GridContainer>
		);
	}

	handleKeyPress = e  => {
		if (e.key === "Enter") { 
			this.release();
		}
	}

	release = () => {
		this.props.onRelease(this.state.comment, this.state.gl);
	}

	handleCommentChange = e => {
		this.setState({ comment: e.target.value });
	}

	handleGlChange = e => {
		this.setState({ gl: e.target.value });
	}
}

export default HeldBillsListReleaseCard;