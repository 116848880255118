import React, { Component } from "react";

import Datetime from "react-datetime";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";


import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
class PostPickupDetails extends Component {

    getWeightUomOpts(prop, val, disabled) {
        const classes = this.props.classes
        const options = [{name:"lbs", label:"LBS"},{name:"kg", label:"KG"}];

        return (
            <FormControl className={classes.selectFormControl} style={{ width: "65px", backgroundColor: "white", textAlign: "center" }}>
                <Select
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    value={val}
                    inputProps={{
                        name: prop,
                        id: val,
                        disabled: disabled
                    }}
                    onChange={this.props.onChangeSelect}
                >
                    {options.map((option, key) => {
                        return (
                            <MenuItem
                                key={`${option.name}_${key}`}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                }}
                                value={option.name}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        )
    }

    getContainerDetails() {
        const classes = this.props.classes
        const disabled = this.props.disabled;
        return this.props.containers.map((cnt, key) => {
            return (
                <GridContainer key={key}>
                    <GridItem md={12} lg={12}>
                        <h5><strong>Container# {key+1}</strong></h5>
                    </GridItem>
                    <GridItem xs={12} lg={12}>
                        <InputLabel className={classes.label}>Container #:</InputLabel>
                        <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "text",
                                id: "container_num",
                                name: "container_num",
                                value: cnt.container_num || "",
                                disabled: disabled,
                                onChange: e => this.props.handleContainer(key, 'container_num', e)
                            }}
                            white
                        />
                    </GridItem>
                    <GridItem xs={12} lg={12}>
                        <InputLabel className={classes.label}>Seal:</InputLabel>
                        <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "text",
                                id: "seal_num",
                                name: "seal_num",
                                value: cnt.seal_num || "",
                                disabled: disabled,
                                onChange: e => this.props.handleContainer(key, 'seal_num', e)
                            }}
                            white
                        />
                    </GridItem>
                </GridContainer>
            )
        })
    }

    render() {
        const classes = this.props.classes || {};
        const disabled = this.props.disabled;
        return(
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} lg={12}>
                            <GridContainer>
                                <GridItem xs={12} lg={4}  style={{ padding: "0 5px" }}>
                                    <Paper className={classes.paper} elevation={1} >
                                        <GridContainer style={{ padding: "0 5px" }}>
                                            <GridItem xs={12} lg={12}>
                                                <h5> Method Details:</h5>
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Vessel/Airline Name:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        id: "vehicle_name",
                                                        name: "vehicle_name",
                                                        value: this.props.vehicle_name || "",
                                                        disabled: disabled,
                                                        onChange: this.props.handleInput("vehicle_name"),
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Voyage/Flight Number:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        id: "vehicle_num",
                                                        name: "vehicle_num",
                                                        value: this.props.vehicle_num || "",
                                                        disabled: disabled,
                                                        onChange: this.props.handleInput("vehicle_num"),
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Origin Airport/Port:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        id: "origin_port",
                                                        name: "origin_port",
                                                        value: this.props.origin_port || "",
                                                        disabled: disabled,
                                                        onChange: this.props.handleInput("origin_port")
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Destination Airport/Port:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        id: "dest_port",
                                                        name: "dest_port",
                                                        value: this.props.dest_port || "",
                                                        disabled: disabled,
                                                        onChange: this.props.handleInput("dest_port")
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Booking Number:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "text",
                                                        id: "booking_num",
                                                        name: "booking_num",
                                                        value: this.props.booking_num || "",
                                                        disabled: disabled,
                                                        onChange: this.props.handleInput("booking_num")
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                {this.props.shipping_mode == "ocean" && (
                                                    this.getContainerDetails()
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                    </Paper>
                                </GridItem>
                                <GridItem xs={12} lg={5}  style={{ padding: "0 5px" }}>
                                    <Paper className={classes.paper} elevation={1} >
                                        <GridContainer style={{ padding: "0 25px" }}>
                                            <GridItem xs={12} lg={12}>
                                                <h5>Date Timeline Details:</h5>
                                            </GridItem>
                                            <GridItem  xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Cargo Ready Date:</InputLabel>
                                                <Datetime
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    value={moment(this.props.cargo_ready_date).isValid() ? moment(this.props.cargo_ready_date).format("MM/DD/YYYY") : ""}
                                                    onChange={m => this.props.onChangeDate("cargo_ready_date", m)}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "cargo_ready_date",
                                                        name: "cargo_ready_date",
                                                        placeholder: "Select Cargo Ready Date",
                                                        disabled: disabled
                                                    }}
                                                    />
                                            </GridItem>
                                            <GridContainer>
                                                <GridItem  xs={1} lg={1} />
                                                <GridItem xs={11} lg={11}>
                                                    <InputLabel className={classes.label}>Actual PU Date:</InputLabel>
                                                    <Datetime
                                                        dateFormat={"MM/DD/YYYY"}
                                                        timeFormat={false}
                                                        value={moment(this.props.actual_pu_date).isValid() ? moment(this.props.actual_pu_date).format("MM/DD/YYYY") : ""}
                                                        onChange={m => this.props.onChangeDate("actual_pu_date", m)}
                                                        className={classes.datetime}
                                                        inputProps={{
                                                            id: "actual_pu_date",
                                                            name: "actual_pu_date",
                                                            placeholder: "Select Actual PU Date",
                                                            disabled: disabled,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            {(this.props.isExport) && (
                                               <React.Fragment>
                                                    <GridItem xs={12} lg={12}>
                                                        <InputLabel className={classes.label}>Documentation cutoff at origin:</InputLabel>
                                                        <Datetime
                                                            dateFormat={"MM/DD/YYYY"}
                                                            timeFormat={false}
                                                            value={moment(this.props.doc_cutoff_origin_date).isValid() ? moment(this.props.doc_cutoff_origin_date).format("MM/DD/YYYY") : ""}
                                                            onChange={m => this.props.onChangeDate("doc_cutoff_origin_date", m)}
                                                            className={classes.datetime}
                                                            inputProps={{
                                                                id: "doc_cutoff_origin_date",
                                                                name: "doc_cutoff_origin_date",
                                                                placeholder: "Select Documentation Cutoff At Origin Date",
                                                                disabled: disabled,
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridContainer>
                                                        <GridItem xs={1} lg={1} />
                                                        <GridItem xs={11} lg={11}>
                                                            <InputLabel className={classes.label}>Cargo cutoff at origin:</InputLabel>
                                                            <Datetime
                                                                dateFormat={"MM/DD/YYYY"}
                                                                timeFormat={false}
                                                                value={moment(this.props.cargo_cutoff_origin_date).isValid() ? moment(this.props.cargo_cutoff_origin_date).format("MM/DD/YYYY") : ""}
                                                                onChange={m => this.props.onChangeDate("cargo_cutoff_origin_date", m)}
                                                                className={classes.datetime}
                                                                inputProps={{
                                                                    id: "cargo_cutoff_origin_date",
                                                                    name: "cargo_cutoff_origin_date",
                                                                    placeholder: "Select Cargo Cutoff At Origin Date",
                                                                    disabled: disabled,
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridItem xs={11} lg={11}>
                                                        <InputLabel className={classes.label}>Actual receipt date at warehouse/port/airport - origin:</InputLabel>
                                                        <Datetime
                                                            dateFormat={"MM/DD/YYYY"}
                                                            timeFormat={false}
                                                            value={moment(this.props.actual_receipt_origin_date).isValid() ? moment(this.props.actual_receipt_origin_date).format("MM/DD/YYYY") : ""}
                                                            onChange={m => this.props.onChangeDate("actual_receipt_origin_date", m)}
                                                            className={classes.datetime}
                                                            inputProps={{
                                                                id: "actual_receipt_origin_date",
                                                                name: "actual_receipt_origin_date",
                                                                placeholder: "Select Actual Receipt Date - origin",
                                                                disabled: disabled,
                                                            }}
                                                        />
                                                    </GridItem>
                                                </React.Fragment>
                                            )}
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>ETD (Estimated date of departure from port/airport - origin):</InputLabel>
                                                <Datetime
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    value={moment(this.props.etd_depart_origin_date).isValid() ? moment(this.props.etd_depart_origin_date).format("MM/DD/YYYY") : ""}
                                                    onChange={m => this.props.onChangeDate("etd_depart_origin_date", m)}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "etd_depart_origin_date",
                                                        name: "etd_depart_origin_date",
                                                        placeholder: "Select ETD - origin",
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridContainer>
                                                <GridItem xs={1} lg={1} />
                                                <GridItem xs={11} lg={11}>
                                                    <InputLabel className={classes.label}>COB (Actual date of departure from port/airport - origin):</InputLabel>
                                                    <Datetime
                                                        dateFormat={"MM/DD/YYYY"}
                                                        timeFormat={false}
                                                        value={moment(this.props.cob_depart_origin_date).isValid() ? moment(this.props.cob_depart_origin_date).format("MM/DD/YYYY") : ""}
                                                        onChange={m => this.props.onChangeDate("cob_depart_origin_date", m)}
                                                        className={classes.datetime}
                                                        inputProps={{
                                                            id: "cob_depart_origin_date",
                                                            name: "cob_depart_origin_date",
                                                            placeholder: "Select COB - origin",
                                                            disabled: disabled,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>ETA (Estimated date of arrival at warehouse/port/airport of discharge at destination):</InputLabel>
                                                <Datetime
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    value={moment(this.props.eta_arrival_dest_date).isValid() ? moment(this.props.eta_arrival_dest_date).format("MM/DD/YYYY") : ""}
                                                    onChange={m => this.props.onChangeDate("eta_arrival_dest_date", m)}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "eta_arrival_dest_date",
                                                        name: "eta_arrival_dest_date",
                                                        placeholder: "Select Estimated Date Of Arrival - destination",
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridContainer>
                                            <GridItem xs={1} lg={1} />
                                            <GridItem xs={11} lg={11}>
                                                <InputLabel className={classes.label}>Actual date of arrival at port/airport of discharge at destination:</InputLabel>
                                                <Datetime
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    value={moment(this.props.actual_arrival_dest_date).isValid() ? moment(this.props.actual_arrival_dest_date).format("MM/DD/YYYY") : ""}
                                                    onChange={m => this.props.onChangeDate("actual_arrival_dest_date", m)}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "actual_arrival_dest_date",
                                                        name: "actual_arrival_dest_date",
                                                        placeholder: "Select Actual Date Of Arrival - destination",
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                            </GridContainer>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>ETA final warehouse/port/airport at destination:</InputLabel>
                                                <Datetime
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    value={moment(this.props.eta_final_dest_date).isValid() ? moment(this.props.eta_final_dest_date).format("MM/DD/YYYY") : ""}
                                                    onChange={m => this.props.onChangeDate("eta_final_dest_date", m)}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "eta_final_dest_date",
                                                        name: "eta_final_dest_date",
                                                        placeholder: "Select Final Estimated Date - destination",
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridContainer>
                                                <GridItem xs={1} lg={1} />
                                                <GridItem xs={11} lg={11}>
                                                    <InputLabel className={classes.label}>Actual date of arrival at final warehouse/port/airport at destination:</InputLabel>
                                                    <Datetime
                                                        dateFormat={"MM/DD/YYYY"}
                                                        timeFormat={false}
                                                        value={moment(this.props.actual_final_dest_date).isValid() ? moment(this.props.actual_final_dest_date).format("MM/DD/YYYY") : ""}
                                                        onChange={m => this.props.onChangeDate("actual_final_dest_date", m)}
                                                        className={classes.datetime}
                                                        inputProps={{
                                                            id: "actual_final_dest_date",
                                                            name: "actual_final_dest_date",
                                                            placeholder: "Select Final Actual Date - destination",
                                                            disabled: disabled,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            {!this.props.isExport && (
                                                <GridItem xs={12} lg={12}>
                                                    <InputLabel className={classes.label}>Last Free Day:</InputLabel>
                                                    <Datetime
                                                        dateFormat={"MM/DD/YYYY"}
                                                        timeFormat={false}
                                                        value={moment(this.props.last_free_day).isValid() ? moment(this.props.last_free_day).format("MM/DD/YYYY") : ""}
                                                        onChange={m => this.props.onChangeDate("last_free_day", m)}
                                                        className={classes.datetime}
                                                        inputProps={{
                                                            id: "last_free_day",
                                                            name: "last_free_day",
                                                            placeholder: "Select Last Free Day",
                                                            disabled: disabled,
                                                        }}
                                                    />
                                                </GridItem>
                                            )}
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>ETA Door:</InputLabel>
                                                <Datetime
                                                    dateFormat={"MM/DD/YYYY"}
                                                    timeFormat={false}
                                                    value={moment(this.props.eta_door_date).isValid() ? moment(this.props.eta_door_date).format("MM/DD/YYYY") : ""}
                                                    onChange={m => this.props.onChangeDate("eta_door_date", m)}
                                                    className={classes.datetime}
                                                    inputProps={{
                                                        id: "eta_door_date",
                                                        name: "eta_door_date",
                                                        placeholder: "Select ETA Door Date",
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridContainer>
                                                <GridItem xs={1} lg={1} />
                                                <GridItem xs={11} lg={11}>
                                                    <InputLabel className={classes.label}>Actual Door Delivery:</InputLabel>
                                                    <Datetime
                                                        dateFormat={"MM/DD/YYYY"}
                                                        timeFormat={false}
                                                        value={moment(this.props.actual_door_date).isValid() ? moment(this.props.actual_door_date).format("MM/DD/YYYY") : ""}
                                                        onChange={m => this.props.onChangeDate("actual_door_date", m)}
                                                        className={classes.datetime}
                                                        inputProps={{
                                                            id: "actual_door_date",
                                                            name: "actual_door_date",
                                                            placeholder: "Select Actual Door Delivery Date",
                                                            disabled: disabled,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridContainer>
                                    </Paper>
                                </GridItem>
                                <GridItem xs={12} lg={3}  style={{ padding: "0 5px" }}>
                                    <Paper className={classes.paper} elevation={1} >
                                        <GridContainer style={{ padding: "0 5px" }}>
                                            <GridItem xs={12} lg={12}>
                                                <h5>Product Actuals:</h5>
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Pallet Count:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{
                                                        type: "number",
                                                        id: "post_pallet_count",
                                                        name: "post_pallet_count",
                                                        value: this.props.post_pallet_count || "",
                                                        onChange: this.props.handleInput("post_pallet_count"),
                                                        disabled: disabled,
                                                    }}
                                                    white
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Total Weight:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "post_total_weight",
                                                        value: this.props.post_total_weight || "",
                                                        onChange: this.props.handleInput("post_total_weight"),
                                                        endAdornment: this.getWeightUomOpts("post_weight_type", this.props.post_weight_type, disabled),
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} lg={12}>
                                                <InputLabel className={classes.label}>Air Chargeable Weight:</InputLabel>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "post_air_weight",
                                                        value: this.props.post_air_weight || "",
                                                        onChange: this.props.handleInput("post_air_weight"),
                                                        endAdornment: this.getWeightUomOpts("post_air_weight_type", this.props.post_air_weight_type, disabled),
                                                        disabled: disabled,
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </Paper>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        {this.props.canUpdate && (
                            <GridItem xs={12} lg={12}>
                                <GridContainer style={{justifyContent: "flex-end"}}>
                                    <GridItem>
                                        <Button
                                            color="success"
                                            size="lg"
                                            onClick={() => this.props.updatePostPickup()}
                                        >
                                            UPDATE POST PICKUP DETAILS
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        )}
                    </GridContainer>
                </CardBody>
            </Card>
        )
    }
}

export default withStyles(style)(PostPickupDetails);