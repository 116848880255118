import React, { Component } from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";


import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ImportExportInformation extends Component {

    getCheckboxes(options, value, update) {
        const classes = this.props.classes
        return options.map((option, key) => {
            return(
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                            checked={option.name === value}
                            onChange={this.props.onChangeChecked(update)}
                            value={option.name}
                        />
                    }
                    classes={{ label: classes.label }}
                    label={option.label}
                    key={`${value}_${key}`}
                />
            );
        });
    }

    getContainers() {
        const classes = this.props.classes
        return this.props.containers.map((cont, key) => {
            return (
                <Card key={key}>
                    <CardBody style={{padding: "5px"}}>
                        <GridItem xs={12} lg={12}>
                            <GridContainer>
                                <GridItem xs={10} lg={10} style={{padding: "5px"}}><h5><strong>{`Container# ${key+1}`}</strong></h5></GridItem>
                                {(!this.props.executed) && (
                                    <GridItem xs={2} lg={2} style={{display: "flex", justifyContent: "flex-end", padding: "5px"}}>
                                        {(this.props.containers.length > 1) && ( 
                                            <Button style={{margin: "5px"}} color="danger" size="sm" justIcon round onClick={e => this.props.removeContainer(key)}>
                                                <Delete/>
                                            </Button>
                                        )}
                                        {key == (this.props.containers.length - 1) && (
                                            <Button style={{margin: "5px"}} color="success" size="sm" justIcon round onClick={this.props.addContainer}>
                                                <Add/>
                                            </Button>
                                        )}
                                    </GridItem>
                                )}
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} lg={12}>
                            <GridContainer>
                                <GridItem xs={12} lg={6}>
                                    <WithTooltip
                                        title="Container Size"
                                        content="Select container size."
                                    >
                                        <InputLabel className={classes.label}>Container Size</InputLabel>
                                    </WithTooltip>
                                    <br/>
                                    <FormControl className={classes.selectFormControl} fullWidth>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: `${classes.select} ${this.props.container_load == "FCL" ? classes.requiredSelect : ""}` }}
                                            value={cont.container_size}
                                            inputProps={{
                                                name: 'container_size',
                                                id: cont.container_size
                                            }}
                                            onChange={e => this.props.handleContainer(key, 'container_size', e)}
                                        >
                                            {this.props.getMenuItems(this.props.container_sizes, 'container_size')}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <br/>
                                <GridItem xs={12} lg={6}>
                                    <WithTooltip
                                        title="Container Type"
                                        content="Select container type."
                                    >
                                        <InputLabel className={classes.label}>Container Type</InputLabel>
                                    </WithTooltip>
                                    <br/>
                                    <FormControl className={classes.selectFormControl} fullWidth>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: `${classes.select} ${this.props.container_load == "FCL" ? classes.requiredSelect : ""}` }}
                                            value={cont.container_type}
                                            inputProps={{
                                                name: 'container_type',
                                                id: cont.container_type
                                            }}
                                            onChange={e => this.props.handleContainer(key, 'container_type', e)}
                                        >
                                            {this.props.getMenuItems(this.props.container_types, 'container_type')}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <br/>
                        </GridItem>
                    </CardBody>
                </Card>
            )
        })
    }
    render() {
        const classes = this.props.classes || {};
        return(
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={4} style={{ marginTop: "30px" }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <WithTooltip
                                        title="Import/Export"
                                        content="Select the direction of this shipment"
                                    >
                                        <InputLabel className={classes.label}>Import/Export</InputLabel>
                                    </WithTooltip>
                                    <br/>
                                    <FormControl className={classes.selectFormControl} fullWidth>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            value={this.props.direction}
                                            inputProps={{
                                                name: 'direction',
                                                id: this.props.direction
                                            }}
                                            onChange={this.props.onChangeSelect}
                                        >
                                            <MenuItem
                                                key={"import"}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value="import"
                                            >
                                                Import
                                            </MenuItem>
                                            <MenuItem
                                                key={"export"}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value="export"
                                            >
                                                Export
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <WithTooltip
                                        title="Shipping Mode"
                                        content="Select shipping mode"
                                    >
                                        <InputLabel className={classes.label}>Shipping Mode</InputLabel>
                                    </WithTooltip>
                                    <br/>
                                    <FormControl className={classes.selectFormControl} fullWidth>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            value={this.props.shipping_mode}
                                            inputProps={{
                                                name: 'shipping_mode',
                                                id: this.props.shipping_mode
                                            }}
                                            onChange={this.props.onChangeSelect}
                                        >
                                            <MenuItem
                                                key={"ocean"}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value="ocean"
                                            >
                                                Ocean
                                            </MenuItem>
                                            <MenuItem
                                                key={"air"}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value="air"
                                            >
                                                Air
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                {this.props.shipping_mode === 'ocean' && (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <WithTooltip
                                            title="Container Load"
                                            content="Select container load"
                                        >
                                            <InputLabel className={classes.label}>Container Load</InputLabel>
                                        </WithTooltip>
                                        <br/>
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <Select
                                                MenuProps={{ className: classes.selectMenu }}
                                                classes={{ select: classes.select }}
                                                value={this.props.container_load}
                                                inputProps={{
                                                    name: 'container_load',
                                                    id: this.props.container_load
                                                }}
                                                onChange={this.props.onChangeSelect}
                                            >
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value=""
                                                >
                                                    None
                                                </MenuItem>
                                                <MenuItem
                                                    key={"FCL"}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value="FCL"
                                                >
                                                    FCL
                                                </MenuItem>
                                                <MenuItem
                                                    key={"LCL"}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value="LCL"
                                                >
                                                    LCL
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                 )}
                                <GridItem xs={12} sm={12} md={12}>
                                    <WithTooltip
                                        title="Named Place"
                                        content=""
                                    >
                                        <span>
                                            <InputLabel className={classes.label}>Named Place</InputLabel>
                                        </span>
                                    </WithTooltip>
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            id: "named_place",
                                            name: "named_place",
                                            value: this.props.named_place || "",
                                            onChange: this.props.handleInput("named_place")
                                        }}
                                        white
                                    />
                                </GridItem>
                                {this.props.direction === 'import' && (
                                     <>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <WithTooltip
                                                title="Customs Broker Name"
                                                content=""
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Customs Broker Name</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    id: "cb_name",
                                                    name: "cb_name",
                                                    value: this.props.cb_name || "",
                                                    onChange: this.props.handleInput("cb_name")
                                                }}
                                                required
                                                white
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <WithTooltip
                                                title="Customs Broker Phone #"
                                                content=""
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Customs Broker Phone #</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    id: "cb_phone",
                                                    name: "cb_phone",
                                                    value: this.props.cb_phone || "",
                                                    onChange: this.props.handleInput("cb_phone")
                                                }}
                                                required
                                                white
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <WithTooltip
                                                title="Custom Brokers Email"
                                                content=""
                                            >
                                                <span>
                                                    <InputLabel className={classes.label}>Custom Brokers Email</InputLabel>
                                                </span>
                                            </WithTooltip>
                                            <CustomInput
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    type: "text",
                                                    id: "cb_email",
                                                    name: "cb_email",
                                                    value: this.props.cb_email || "",
                                                    onChange: this.props.handleInput("cb_email")
                                                }}
                                                required
                                                white
                                            />
                                        </GridItem>
                                    </>
                                  )}
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4}>
                            <Card>
                                <CardBody>
                                    {this.getCheckboxes(this.props.service_types, this.props.service_type, "service_type")}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <WithTooltip
                                        title="Inco Terms"
                                        content="Select Inco Terms."
                                    >
                                        <InputLabel className={classes.label}>Inco Terms *</InputLabel>
                                    </WithTooltip>
                                    <br/>
                                    {this.getCheckboxes(this.props.inco_terms, this.props.inco_term, "inco_term")}
                                </CardBody>
                            </Card>
                        </GridItem>
                        {this.props.shipping_mode === 'ocean' && (
                            <GridItem xs={12} sm={12} md={12} lg={4} >
                            
                                 {this.getContainers()}
                             
                            </GridItem>
                        )}
                    </GridContainer>
                </CardBody>
            </Card>
        )
    }
}

export default withStyles(style)(ImportExportInformation);