import axios from '../../../variables/axios.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';

const setLevels = val => {
	return {type: "LEVELS_SET_LEVELS", val};
}
const setLoading = val => {
	return {type: "LEVELS_SET_LOADING", val};
}

/**
 * Load the levels into the store
 */
export const loadLevels = () => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=billoflading&m=getBolLevels");
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body) {
				dispatch(setLevels(response.data.body));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching bol importance levels", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setLoading(false));
		}
	}
}

/**
 * Load the levels if they have not already been loaded
 */
export const loadLevelsIfEmpty = () => {
	return async (dispatch, getState) => {
		const levels = getState().ImportanceLevel.levels;
		if (!Array.isArray(levels) || !levels.length) {
			dispatch(loadLevels())
		}
	}
}

/**
 * Update the level on the provided bol
 */
export const updateBolLevel = (bol, levelId) => {
	return async (dispatch) => {
		let result = false;
		try {
			
			const response = await axios.post(
				`/index.php?p=api&r=json&c=billoflading&m=saveBolLevel&bol=${bol}`,
				{level:levelId},
				{headers: {'Content-Type': 'application/json'}}
			);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.result) {
				result = true;
			}
		} catch (err) {
			dispatch(setNotification("Error updating bol importance level", {variant: "error"}))
			console.error(err);
		}
		return result;
	}
}

/**
 * Update the level on the provided international Shipment
 */
export const updateIntlLevel = (intlId, levelId) => {
	return async (dispatch) => {
		let result = false;
		try {
			const response = await axios.post(
				`/index.php?p=api&r=json&c=international&m=saveIntlLevel&id=${intlId}`,
				{level:levelId},
				{headers: {'Content-Type': 'application/json'}}
			);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.result) {
				result = true;
			}
		} catch (err) {
			dispatch(setNotification("Error updating International importance level", {variant: "error"}))
			console.log(err);
		}
		return result;
	}
}