import React, { Component, Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

class WithTooltip extends Component {
    render() {
        return (
            <Tooltip
                title={
                    <Fragment>
                        <Typography color="inherit">{this.props.title || ""}</Typography>
                        {this.props.content || ""}
                    </Fragment>
                }
                placement={this.props.placement || "bottom"}
            >
                {this.props.children}
            </Tooltip>
        );
    }
}

export default WithTooltip;