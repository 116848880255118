import React, { Component, Fragment } from "react";
import Button from "~/components/CustomButtons/Button.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Dialog from '../../../components/TMS/Dialog.jsx';
import Typography from "@material-ui/core/Typography";

const borderColor = "rgba(0,0,0,0.12)";

const editableFields = [
	"Dist Code",
	"Cost/Profit Center",
	"BL #",
	"PO #",
	"Ret Authorization",
	"Vendor #",
	"Remark",
	"REF #",
	"Cust Reference 1",
	"Cust Reference 2",
	"Store #",
	"Shipper Company",
	"Origin Address 1",
	"Origin Address 2",
	"Origin City",
	"Origin State",
	"Origin Zip",
	"Consignee Company",
	"Destination Address 1",
	"Destination Address 2",
	"Destination City",
	"Destination State",
	"Destination Zip",
];

class UploadHeldBillsCSV extends Component {

	state = {
		modalOpen: false,
	}

	render() {
		return (
			<Fragment>
				<Button
					round
					color="success"
					size="sm"
					onClick={this.onClick}
				>
					 Upload CSV
				</Button>
				<input type="file" ref="file_upload_input" style={{display: "none"}} accept=".csv" onChange={this.onFileSelect}/>
				<Dialog 
					open={this.state.modalOpen} 
					onClose={this.onCloseModal} 
					title="Upload Held Bills CSV"
					actions={[{
						disabled: this.props.loading ? true : false,
	                    color: "success",
	                    title: this.props.loading ? <Fragment><span>Uploading...</span><CircularProgress color="inherit" size={20}/></Fragment> : "Select File",
	                    onClick: this.onOpenFileSelect,
	                }]}
				>
	          		<GridContainer>
	          			<GridItem xs={12} style={{
          					borderBottom: `1px solid ${borderColor}`, 
          					marginBottom: "10px",
          					paddingBottom: "10px",
	          			}}>
	          				<Typography color="inherit">
								Use this feature to release bills by uploading a CSV exported from the customer portal. Note that every bill in the uploaded file will be released from <b>all holds defined in the hold code column.</b> For example: if a bill has a GL Code hold (D), a Miscellaneous hold (N), an Accessorial hold (A), and the hold code column of the upload has "D, A" then the GL (D) and Accessorial (A) holds will be released, but not the Miscellaneous hold.
	          				</Typography>
	          			</GridItem>
	          			{editableFields.map((field, index) => {
	          				return (
          						<GridItem xs={4} key={index}>
          							<Typography color="inherit">
          								{field}
          							</Typography>
          						</GridItem>
	          				)
	          			})}
	          		</GridContainer>
	            </Dialog>
			</Fragment>
		);
	}

	onClick = () => {
		this.setState({modalOpen: true});
	}

	onCloseModal = () => {
		this.setState({modalOpen: false});
	}

	onOpenFileSelect = () => {
		this.refs.file_upload_input.click();
	}

	onFileSelect = e => {
		const files = e.target.files;
		if (files[0]) {
			this.props.onFileSelect(files[0]);
			this.refs.file_upload_input.value = null;
		}
	}
}

export default UploadHeldBillsCSV;
