import {
	drawerWidth,
	drawerMiniWidth,
	transition,
	boxShadow,
	defaultFont,
	uiColors,
	makeBoxShadow,
	whiteColor,
	blackColor,
	grayColor,
	hexToRgb,
	tfmBlueColor,
	container,
	defaultBoxShadow,
} from "assets/jss/empire-tms.jsx";


const notificationBoxShadow = {
	border: "0",
	borderRadius: "3px",
	boxShadow:
		"0 10px 20px -12px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.42), 0 3px 20px 0px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.12), 0 8px 10px -5px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.2)",
	padding: "5px 0",
	transition: "all 150ms ease 0s"
};

const cardBodyStyle = {
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    wordWrap: "break-word"
  },
  cardBodyBackground: {
    position: "relative",
    zIndex: "2",
    minHeight: "280px",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "440px",
    margin: "0 auto"
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyFormHorizontal: {
    paddingLeft: "15px",
    paddingRight: "15px",
    "& form": {
      margin: "0"
    }
  },
  cardPricing: {
    padding: "15px!important",
    margin: "0px!important"
  },
  cardSignup: {
    padding: "0px 30px 0px 30px"
  },
  cardBodyColor: {
    borderRadius: "6px",
    "&": {
      "h1, h2, h3": {
        "& small": {
          color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
        }
      }
    }
  },
  cardBodyProfile: {
    marginTop: "15px"
  },
  cardBodyCalendar: {
    padding: "0px !important",
    height: "800px",
  },
	primary: {
		backgroundColor: tfmBlueColor,
		color: whiteColor,
		...notificationBoxShadow,
		borderRadius: "0",
	},
	info: {
		backgroundColor: uiColors.info.dark,
		color: whiteColor,
		...notificationBoxShadow,
	},
	success: {
		backgroundColor: uiColors.success.dark,
		color: whiteColor,
		...notificationBoxShadow,
	},
	warning: {
		backgroundColor: uiColors.warning.dark,
		color: whiteColor,
		...notificationBoxShadow,
	},
	misc: {
		backgroundColor: uiColors.misc.dark,
		color: whiteColor,
		...notificationBoxShadow,
	},
	danger: {
		backgroundColor: uiColors.danger.dark,
		color: whiteColor,
		...notificationBoxShadow,
	},
	error: {
		backgroundColor: uiColors.danger.dark,
		color: whiteColor,
		...notificationBoxShadow,
	}
};

export default cardBodyStyle;
