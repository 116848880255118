import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Dialog from '../../TMS/Dialog.jsx';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import Grid from '@material-ui/core/Grid';
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Slide from "@material-ui/core/Slide";
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import Button from "~/components/CustomButtons/Button.jsx";
import moment from "moment";
import { userIsAdmin, adminInUserSession, activeUserId } from "../../../redux/selectors/Admin.jsx";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoteInput from "../components/NoteInput.jsx";
import IconButton from "../components/IconButton.jsx";

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

class NotesModal extends Component {

    onClose = () => {
        this.props.closeModal();
    }

    onNewNoteValueChange = e => {
        this.props.setNewNoteMessage(e.target.value);
    }

    onEditedNoteValueChange = (id, value) => {
        this.props.setEditNoteValue(id, value);
    }

    onCreateNote = () => {
        this.props.saveNote(null, this.props.newNoteMessage, false);
    }

    onCreateInternalNote = () => {
        this.props.saveNote(null, this.props.newNoteMessage, true);
    }

    onSaveEditedNote = (id, message) => {
        this.props.saveNote(id, message)
    }

    onDeleteNote = id => {
        this.props.deleteNote(id);
    }

    onEditNote = id => {
        this.props.editNote(id);
    }

    onCancelEdit = id => {
        this.props.cancelEdit(id);
    }

    editingNote = id => {
        return Object.keys(this.props.editingNotes).indexOf(id) !== -1;
    }

    isAnythingLoading = () => {
        return this.props.notesLoading || this.props.saveNoteLoading || this.props.deleteNoteLoading ? true : false;
    }

    noteBelongsToUser = id => {
        return +id === +this.props.activeUserId;
    }

    isAdminNote = note => {
        return note.user && note.user.role === "admin";
    }

    isInternalNote = note => {
        return !!+note.internal;
    }

    render() {

        return (
            <Dialog 
                open={this.props.open} 
                onClose={this.onClose} 
                TransitionComponent={Transition} 
                title={this.props.title}
                fullWidth={true}
                maxWidth="lg"
            >
                <GridContainer style={{marginBottom: "6px"}}>
                    <GridItem xs={12}>
                        <Card>
                            <CardContent style={{paddingBottom: "6px"}}>
                                    <NoteInput
                                        label={this.props.adminInUserSession ? "New Note (Creating as admin user)" : "New Note"}
                                        onChange={this.onNewNoteValueChange}
                                        value={this.props.newNoteMessage}
                                        disabled={this.props.saveNoteLoading ? true : false}
                                    />
                                <Grid container justify="flex-end" style={{marginRight: "15px"}}>
                                    {this.isAnythingLoading() && <CircularProgress />}
                                    <Button
                                        color="success"
                                        size="sm"
                                        onClick={this.onCreateNote}
                                        disabled={this.props.newNoteMessage === "" || this.isAnythingLoading()}
                                    >
                                        Save
                                    </Button>
                                    {this.props.userIsAdmin &&
                                        <Button
                                            color="success"
                                            size="sm"
                                            onClick={this.onCreateInternalNote}
                                            disabled={this.props.newNoteMessage === "" || this.isAnythingLoading()}
                                        >
                                            Save Internal
                                        </Button>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.props.notesLoading
                    ? (<GridContainer>
                        <GridItem xs={12} style={{marginBottom: "6px"}}>
                            <Skeleton height={100} />
                        </GridItem>
                    </GridContainer>) 
                    : (<GridContainer>
                    {this.props.notes.map((note, index) => {
                        return (
                            <GridItem xs={12} key={index} style={{marginBottom: "12px"}}>
                                <Card>
                                    <CardContent>
                                        <Grid justify="space-between" container>
                                            <Grid item>
                                                <Typography color="textSecondary">
                                                    <b>{note.user.username}</b> 
                                                    {this.isAdminNote(note) && <span style={{color: "gray"}}><small> <i>admin</i></small></span>}
                                                    {this.isInternalNote(note) && <span style={{color:"red"}}> <small>INTERNAL</small></span>}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <span style={{color: "gray"}}>
                                                    {moment(note.timestamp).format("LLL")}

                                                    {this.editingNote(note.id)
                                                        ?
                                                            <Fragment>
                                                                {this.noteBelongsToUser(note.user.id) && 
                                                                    <IconButton
                                                                        icon={SaveIcon}
                                                                        tooltip="Save Note"
                                                                        disabled={this.isAnythingLoading()}
                                                                        onClick={() => this.onSaveEditedNote(note.id, this.props.editingNotes[note.id].message)}
                                                                    />
                                                                }
                                                                {this.noteBelongsToUser(note.user.id) && 
                                                                    <IconButton
                                                                        icon={ClearIcon}
                                                                        tooltip="Cancel Edit"
                                                                        disabled={this.isAnythingLoading()}
                                                                        onClick={() => this.onCancelEdit(note.id)}
                                                                    />
                                                                }
                                                            </Fragment>
                                                        : (
                                                            <Fragment>
                                                                {this.noteBelongsToUser(note.user.id) && 
                                                                    <IconButton
                                                                        icon={EditIcon}
                                                                        tooltip="Edit Note"
                                                                        disabled={this.isAnythingLoading()}
                                                                        onClick={() => this.onEditNote(note.id)}
                                                                    />
                                                                }
                                                                {this.noteBelongsToUser(note.user.id) && 
                                                                    <IconButton
                                                                        icon={DeleteIcon}
                                                                        tooltip="Delete Note"
                                                                        disabled={this.isAnythingLoading()}
                                                                        onClick={() => this.onDeleteNote(note.id)}
                                                                    />
                                                                }
                                                            </Fragment>
                                                        )
                                                    }
                                                </span>
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                            {this.editingNote(note.id) 
                                                ? (
                                                    <NoteInput
                                                        label={"Edit note"}
                                                        onChange={e => this.onEditedNoteValueChange(note.id, e.target.value)}
                                                        value={this.props.editingNotes[note.id].message}
                                                        disabled={this.props.saveNoteLoading ? true : false}
                                                    />
                                                )
                                                : (
                                                    <Typography style={{wordWrap: "break-word"}} color="textSecondary" component="p">
                                                        {note.message}
                                                    </Typography>
                                            )}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </GridItem>
                        );
                    })}
                    </GridContainer>)}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        userIsAdmin: userIsAdmin(state),
        adminInUserSession: adminInUserSession(state),
        activeUserId: activeUserId(state),
    };
}

export default connect(mapStateToProps)(NotesModal);