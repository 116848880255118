import React from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Grid from "@material-ui/core/Grid";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormControl } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import ClearAll from "@material-ui/icons/ClearAll";
import IconButton from "@material-ui/core/IconButton";

class ScheduleHistoryLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      body: null,
      user: null,
      anchorEl: false,
      currentRow: 0,
      current: "1",
      last: "1",
      pages: [],
      search: false,
      filters: [
        {
          field: "report_name",
          compare: "contains",
          value: "",
        },
      ],
    };
  }

  async componentDidMount() {
    this.setState({ mounted: true });
    localStorage.setItem("NewReportRun", false);
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitionsHistory"
      );
      const data = response.data;
      if (typeof data !== "string" && data.body && data.user) {
        this.props.handleMessages(response);
        this.setState({
          show: true,
          loading: false,
          body: data.body,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error while retrieving the scheduled report history!",
          {
            variant: "warning",
          }
        );
      }
    } catch (error) {
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an unexpected response from the server while retrieving the scheduled report history!",
        {
          variant: "warning",
        }
      );
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handleClick = (event, id) => {
    this.setState({ currentRow: id });
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: false });
  }

  handleModalOpen = (modal) => {
    this.setState({ [modal]: true });
  }

  handleModalClose = (modal) => {
    this.setState({ [modal]: false });
  }

  handleChecked = (name) => (event) => {
    this.setState({ [name]: !!event.target.checked });
  }

  handleInput = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDatetime = (name, moment) => {
    this.setState({ [name]: moment });
  }

  downloadReport = (history_id, format) => {
    window.open(
      basePath +
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=downloadScheduledReportDefinitions&d=" +
        history_id +
        "/" +
        format
    );
  }

  getPages = () => {
    const { current, last } = this.state;
    let pages = [{ active: true, text: "..." }];
    const nextPage = { text: ">" };
    const prevPage = { text: "<" };

    if (last > 1 && !_.isEmpty(this.state.pages)) {
      pages = this.state.pages.map((index) => {
        const page = { text: index };
        if (index == parseInt(current)) {
          page.active = true;
        } else {
          page.onClick = (e) => this.handlePageClick(index);
        }
        return page;
      });
    }

    if (current < last) {
      nextPage.onClick = (e) => this.handlePageClick(parseInt(current) + 1);
    } else {
      nextPage.disabled = true;
    }

    if (parseInt(current) - 1 !== 0) {
      prevPage.onClick = (e) => this.handlePageClick(parseInt(current) - 1);
    } else {
      prevPage.disabled = true;
    }

    pages.push(nextPage);
    pages.unshift(prevPage);

    return pages;
  }

  getFilters = (filters) => {
    const { classes } = this.props;
    return filters.map((prop, key) => {
      return (
        <GridItem xs={12} key={key}>
          <GridContainer>
            <GridItem xs={2}>
              {key == 0 ? (
                <IconButton
                  aria-label="add filter"
                  size="small"
                  color="primary"
                  className={classes.marginRight}
                  style={{ float: "right" }}
                  onClick={() => this.addFilter()}
                >
                  <Add />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="remove filter"
                  size="small"
                  color="secondary"
                  className={classes.marginRight}
                  style={{ float: "right" }}
                  onClick={() => this.removeFilter(key)}
                >
                  <Close />
                </IconButton>
              )}
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={prop.field || ""}
                  inputProps={{ name: "field" }}
                  onChange={(e) => this.handleFilters(key, "field", e)}
                >
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="report_name"
                  >
                    Report Name
                  </MenuItem>

                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="run_timestamp"
                  >
                    Run on
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="report_type"
                  >
                    Type
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={prop.compare || ""}
                  inputProps={{ name: "compare" }}
                  onChange={(e) => this.handleFilters(key, "compare", e)}
                >
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="contains"
                  >
                    contains
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="ends with"
                  >
                    ends with
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="equals"
                  >
                    equals
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="starts with"
                  >
                    starts with
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "text",
                  value: prop.value || "",
                  onChange: (e) => this.handleFilters(key, "value", e),
                  onKeyPress: (e) => this.handleKeyPress(e),
                }}
                white
              />
            </GridItem>
            <GridItem xs={3}>
              {key == 0 && (
                <Button
                  size="sm"
                  color="linkedin"
                  className={classes.marginLeft}
                  onClick={() => this.handleSearch()}
                >
                  <Search /> Search
                </Button>
              )}
              {key == 0 &&
                (this.state.filters.length > 1 || prop.value !== "") && (
                  <Button
                    size="sm"
                    color="white"
                    onClick={() => this.clearFilters()}
                  >
                    <ClearAll /> Clear Filters
                  </Button>
                )}
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    });
  }

  handlePageClick = async (page) => {
    this.setState({ show: false, loading: true });
    const { filters, search } = this.state;
    const data = { queries: filters };
    let url = !search
      ? "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitionsHistory&d=" +
        page +
        "/5"
      : "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=filterReportScheduleHistory&d=" +
        page +
        "/5";
    try {
      const response = search
        ? await axios.post(url, qs.stringify(data))
        : await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
        this.setState({
          show: true,
          loading: false,
          body: response.data.body,
          current: response.data.body.current
            ? response.data.body.current.toString()
            : "1",
          last: response.data.body.last
            ? response.data.body.last.toString()
            : "1",
          pages: response.data.body.pages || [],
          result: response.data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error loading the report histories!",
        { variant: "warning" }
      );
    }
  }

  addFilter = () => {
    const { filters } = this.state;
    filters.push({
      field: "company_name.name",
      compare: "starts with",
      value: "",
    });
    this.setState({ filters });
  }

  removeFilter = (key) => {
    const { filters } = this.state;
    if (!_.isEmpty(filters) && key != 0) {
      filters.splice(key, 1);
      this.setState({ filters });
    }
  }

  clearFilters = async () => {
    this.setState({
      show: false,
      loading: true,
      search: false,
      filters: [
        {
          field: "company_name.name",
          compare: "starts with",
          value: "",
        },
      ],
    });
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitionsHistory"
      );
      const { data } = response;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (typeof data !== "string" && !_.isEmpty(data.body)) {
        this.setState({
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error loading the report histories!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error loading the report histories!",
        {
          variant: "error",
        }
      );
    }
  }

  handleFilters = (key, name, event) => {
    const { filters } = this.state;
    filters[key][name] = event.target.value;
    this.setState({ filters });
  }

  handleSearch = async () => {
    const { filters } = this.state;
    const data = { queries: filters };
    const url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=filterReportScheduleHistory";

    this.setState({
      show: false,
      loading: true,
    });

    try {
      const response = await axios.post(url, qs.stringify(data));
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string") {
        this.setState({
          search: true,
          show: true,
          loading: false,
          user: response.data.user,
          body: response.data.body,
          current: response.data.body.current
            ? response.data.body.current.toString()
            : "1",
          last: response.data.body.last
            ? response.data.body.last.toString()
            : "1",
          pages: response.data.body.pages || [],
          result: response.data.body.result || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error searching for report histories!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error searching for report histories!",
        { variant: "error" }
      );
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSearch();
    }
  }

  getHeaders = () => {
    return ["#", "Report Name", "Type", "Run On", "Action"];
  }

  getColumns = (data) => {
    if (data.result != undefined) {
      return data.result.map((prop, key) => {
        return [
          prop.id,
          prop.report_name,
          prop.report_type,
          <div key={key} width="30%">
            {" "}
            {prop.run_timestamp != null ? prop.run_timestamp : ""}{" "}
          </div>,
          <>
            <GridContainer>
              <GridItem>
                <Link
                  to={{
                    pathname:
                      basePath + "/admin/run-report/" + prop.reportdefinitionid,
                    state: { isHistory: "true" },
                  }}
                >
                  <Button variant="contained" size="sm" color="linkedin">
                    View
                  </Button>
                </Link>
              </GridItem>
              <GridItem>
                <div>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    size="sm"
                    color="linkedin"
                    onClick={(event) => this.handleClick(event, prop.id)}
                  >
                    Export
                  </Button>
                  <Menu
                    id="customized-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={this.state.anchorEl}
                    onClose={this.handleClose}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <MenuItem
                        onClick={() =>
                          this.downloadReport(this.state.currentRow, "csv")
                        }
                      >
                        CSV
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.downloadReport(this.state.currentRow, "xls")
                        }
                      >
                        XLS
                      </MenuItem>
                    </Grid>
                  </Menu>
                </div>
              </GridItem>
            </GridContainer>
          </>,
        ];
      });
    }

    return data.map((prop, key) => {
      return [
        prop.id,
        prop.report_name,
        prop.report_type,
        <div key={key} width="30%">
          {" "}
          {prop.run_timestamp != null ? prop.run_timestamp : ""}{" "}
        </div>,
        <>
          <GridContainer>
            <GridItem>
              <Link
                to={{
                  pathname:
                    basePath + "/admin/run-report/" + prop.reportdefinitionid,
                  state: { isHistory: "true" },
                }}
              >
                <Button variant="contained" size="sm" color="linkedin">
                  View
                </Button>
              </Link>
            </GridItem>
            <GridItem>
              <div>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  size="sm"
                  color="linkedin"
                  onClick={(event) => this.handleClick(event, prop.id)}
                >
                  Export
                </Button>
                <Menu
                  id="customized-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={this.state.anchorEl}
                  onClose={this.handleClose}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <MenuItem
                      onClick={() =>
                        this.downloadReport(this.state.currentRow, "pdf")
                      }
                    >
                      PDF
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.downloadReport(this.state.currentRow, "csv")
                      }
                    >
                      CSV
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.downloadReport(this.state.currentRow, "xls")
                      }
                    >
                      XLS
                    </MenuItem>
                  </Grid>
                </Menu>
              </div>
            </GridItem>
          </GridContainer>
        </>,
      ];
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem style={{ paddingTop: "10px" }} xs={12} sm={12} md={12}>
          <Grid container>
            {!_.isEmpty(this.state.filters) &&
              this.getFilters(this.state.filters)}
          </Grid>
        </GridItem>
        <Grid container>
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Pagination pages={this.getPages()} />
          </GridItem>
        </Grid>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                {this.state.show ? (
                  <GridItem xs={12} sm={12} md={12}>
                    {!_.isEmpty(this.state.result) ? (
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              {this.getHeaders().map((cell) => (
                                <TableCell>{cell}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.getColumns(this.state.result).map(
                              (row, key) => (
                                <TableRow key={key}>
                                  {row.map((cell) => (
                                    <TableCell component="th" scope="row">
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <p className={classes.center}>
                        <b>{"No history to display"}</b>
                      </p>
                    )}
                  </GridItem>
                ) : (
                  <Spinner
                    loading={this.state.loading}
                    message="Failed to retrieve scheduled report from the server"
                  />
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(ScheduleHistoryLog)));