import axios from '../../../variables/axios.jsx';
import _ from "lodash";
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import { basePath, apiUrl } from '../../../variables/server.jsx';
import { searchObject } from "../../../helpers.jsx";
import { userIsCarrier } from "../../../redux/selectors/Admin.jsx";
import qs from "qs";

const setFetchInvoicesLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_LOADING", val};
}
const setInvoices = invoices => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICES", invoices};
}
export const setSelectedInvoices = ids => {
	return {type: "SET_CUSTOMER_PORTAL_SELECTED_INVOICES", ids};
}
const setHeldBillsByCode = bills => {
	return {type: "SET_CUSTOMER_PORTAL_HELD_BILLS_BY_CODE", bills};
}
const setTotalHeldBills = val => {
	return {type: "SET_CUSTOMER_PORTAL_TOTAL_HELD_BILLS", val};
}
const setTotalUniqueHeldBills = val => {
	return {type: "SET_CUSTOMER_PORTAL_TOTAL_UNIQUE_HELD_BILLS", val};
}
const setHeldBillsSummaryLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_HELD_BILLS_SUMMARY_LOADING", val};
}
const setFetchBillLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_FETCH_BILL_LOADING", val};
}
const setViewingBill = val => {
	return {type: "SET_CUSTOMER_PORTAL_VIEWING_BILL", val};
}
export const setGlCode = val => {
	return {type: "SET_CUSTOMER_PORTAL_GL_CODE", val};
}
export const setMassReleaseComment = val => {
	return {type: "SET_CUSTOMER_PORTAL_MASS_RELEASE_COMMENT", val};
}
const setHoldCode = val => {
	return {type: "SET_CUSTOMER_PORTAL_HOLD_CODE", val};
}
const setMassReleaseLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_MASS_RELEASE_LOADING", val};
}
const setCannedReports = cannedReports => {
	return {type: "SET_CANNED_REPORTS", cannedReports};
}
const setCannedReportsLoading = val => {
	return {type: "SET_CANNED_REPORTS_LOADING", val};
}
const setRunCannedReportLoading = val => {
	return {type: "SET_RUN_CANNED_REPORT_LOADING", val};
}
const setNewCannedReportId = val => {
	return {type: "SET_NEW_CANNED_REPORT_ID", val};
}
const setRefEntryType = val => {
	return {type: "SET_REFERENCE_ENTRY_TYPE", val};
}
const setRefEntryOpts = val => {
	return {type: "SET_REFERENCE_ENTRY_OPTIONS", val};
}
export const setUpdateDist = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_DIST", val};
}
export const setUpdateCostCenter = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_CC", val};
}
export const setUpdateBol = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_BOL", val};
}
export const setUpdatePo = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_PO", val};
}
export const setUpdateRef = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_REF", val};
}
export const setUpdateVendor = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_VENDOR", val};
}
export const setUpdateRa = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_RA", val};
}
export const setUpdateNote = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_NOTE", val};
}
export const setDeclinePayment = val => {
	return {type: "SET_CUSTOMER_PORTAL_DECLINE_PAYMENT", val};
}
export const setCorrectionCode = val => {
	return {type: "SET_CUSTOMER_PORTAL_CORRECTION_CODE", val};
}
export const setAdjustmentNote = val => {
	return {type: "SET_CUSTOMER_PORTAL_ADJUSTMENT_NOTE", val};
}
export const setReleaseForPayment = val => {
	return async (dispatch) => {
		if (!val) {
			dispatch(setHoldsToRelease([]));
		}
		dispatch({type: "SET_CUSTOMER_PORTAL_RELEASE_FOR_PAYMENT", val});
	}
}
export const setNotApproved = val => {
	return {type: "SET_CUSTOMER_PORTAL_NOT_APPROVED", val};
}
export const setNotApprovedNote = val => {
	return {type: "SET_CUSTOMER_PORTAL_NOT_APPROVED_NOTE", val};
}
export const setUpdateBillLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_UPDATE_BILL_LOADING", val};
}
export const setHoldsToRelease = val => {
	return {type: "SET_CUSTOMER_PORTAL_HOLDS_TO_RELEASE", val};
}
export const setFileUploadLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_FILE_UPLOAD_LOADING", val};
}
const setTfmInvoices = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICES", val};
}
export const setTfmInvoicesPageNumber = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICES_PAGE_NUMBER", val};
}
export const setTfmInvoicesPageSize = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICES_PAGE_SIZE", val};
}
const setTfmInvoicesLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICES_LOADING", val};
}
const setTfmInvoicesTotalRecords = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICES_TOTAL_RECORDS", val};
}
const setInvoicesTotalRecords = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICES_TOTAL_RECORDS", val};
}
const setDownloadCarrierImagesLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_DOWNLOAD_CARRIER_IMAGES_LOADING", val};
}
export const setCustomerPortalTfmInvoiceCarrierBillsPageNumber = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICE_CARRIER_BILLS_PAGE_NUMBER", val};
}
export const setCustomerPortalTfmInvoiceCarrierBillsTotalRecords = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICE_CARRIER_BILLS_TOTAL_RECORDS", val};
}
const setCustomerPortalTfmILoadingImages = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_LOADING_IMAGES", val};
}
const setCustomerPortalTfmReadyImages = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_READY_IMAGES", val};
}
export const setInvoiceImageTypes = val => {
	return {type: "SET_CUSTOMER_PORTAL_TFM_INVOICE_IMAGE_TYPES", val};
}

export const setCustomerPortalInvoiceSearchResults = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_RESULTS", val};
}
export const setCustomerPortalInvoiceSearchLoading = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_LOADING", val};
}
export const setCustomerPortalInvoiceSearchPageNumber = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_PAGE_NUMBER", val};
}
export const setCustomerPortalInvoiceSearchTotalRecords = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_TOTAL_RECORDS", val};
}
export const setCustomerPortalUserBrokerLocations = val => {
	return {type: "SET_CUSTOMER_PORTAL_USER_BROKER_LOCATIONS", val};
}
export const setCompanyLocations = val => {
	return {type: "SET_CUSTOMER_PORTAL_COMPANY_LOCATIONS", val};
}
export const setSelectedCompanyLocation = val => {
	return {type: "SET_CUSTOMER_PORTAL_COMPANY_BILLED_LOCATION", val};
}
export const applyInvoiceListFilters = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_LIST_FILTERS", val};
}
export const applyInvoiceListColumns = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_LIST_COLUMNS", val};
}
const setInvoiceListCustomColumns = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_LIST_CUSTOM_COLUMNS", val};
}
export const applyInvoiceListCustomColumns = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_LIST_CUSTOM_COLUMNS", val};
}
export const setCustomerPortalUploadedFiles = val => {
	return {type: "SET_CUSTOMER_PORTAL_INVOICE_UPLOADED_FILES", val};
}


export const fetchBill = id => {
	return async (dispatch) => {
		dispatch(setFetchBillLoading(true));
		await dispatch(setViewingBill(null));
		dispatch(setHeldBillUpdateFieldDefaults()); //clear
		try {
			await dispatch(_fetchBill(id));
		} catch (err) {
			dispatch(setNotification("Error fetching bill", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setFetchBillLoading(false));
		}
	}
}

export const _fetchBill = id => {
	return async (dispatch, getState) => {
		const response = await axios.get(`index.php?p=api&r=json&c=billing&m=loadBilledShipment&id=${id}`);
		dispatch(handleMessages(response));
		if (response && response.data && response.data.body && response.data.body.id) {
			if(!userIsCarrier) {
				dispatch(setWarehouseDefaults(response.data.body.tmsCode));
				dispatch(setHeldBillUpdateFieldDefaults());
			}

			await dispatch(setViewingBill(response.data.body));
			dispatch(setHeldBillUpdateFieldDefaults());
		} else {
			await dispatch(setViewingBill(null));
		}
	}
}

const addToLoadingList = (invoiceNumber) => {
	return async (dispatch, getState) => {
		let loadingInvoiceList = [...getState().CustomerPortal.imagesLoading];
		let index = loadingInvoiceList.indexOf(invoiceNumber);
		if(index == -1) {
			loadingInvoiceList.push(invoiceNumber);
			dispatch(setCustomerPortalTfmILoadingImages(loadingInvoiceList));
		}
	};
}

const removeFromLoadingList = (invoiceNumber) => {
	return async (dispatch, getState) => {
		let loadingInvoiceList = [...getState().CustomerPortal.imagesLoading];
		let index = loadingInvoiceList.indexOf(invoiceNumber);
		while(index > -1) {
			loadingInvoiceList.splice(index, 1);
			index = loadingInvoiceList.indexOf(invoiceNumber);
		}
		dispatch(setCustomerPortalTfmILoadingImages(loadingInvoiceList));
	};
}

const addToReadyList = (invoiceNumber) => {
	return async (dispatch, getState) => {
		let readyInvoiceList = [...getState().CustomerPortal.imagesReady];
		let index = readyInvoiceList.indexOf(invoiceNumber);

		if(index == -1) {
			readyInvoiceList.push(invoiceNumber);
			dispatch(setCustomerPortalTfmReadyImages(readyInvoiceList));
		}
	};
}

export const updateBill = () => {
	return async (dispatch, getState) => {
		const state = getState().CustomerPortal;
		dispatch(setUpdateBillLoading(true));
		try {
			const formData = new FormData();
			let imgCount = 0;
			let config = {};                
			let data = {
				invoiceId: state.viewingBill.id,
				glCode: state.updateDist,
				distCode: state.updateCostCenter,
				bolNumber: state.updateBol,
				poNumber: state.updatePo,
				refNumber: state.updateRef,
				raNumber: state.updateRa,
				customerNotes: state.updateNote,
				vendorNumber: state.updateVendor,
				decline: state.declinePayment ? 1 : 0,
				correctionCode: state.declinePayment ? state.correctionCode : null,
				adjNote: state.declinePayment ? state.adjustmentNote : null,
				holdsToRelease: state.releaseForPayment ? state.holdsToRelease : "",
				notApproved: state.notApproved ? 1 : 0,
				notApprovedNote: state.notApprovedNote,
				billedLocation: state.tfmInvoiceBilledLocation,
			};

			if(state.uploadedFiles && !_.isEmpty(state.uploadedFiles)) {
				state.uploadedFiles.forEach((file, index) => {formData.append("file" + index, file[0])})
                config = {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                };
			}

			for(const prop in data) {
				formData.append(prop, data[prop]);
			}
		
			const response = await axios.post(`index.php?p=api&r=json&c=billing&m=updateBilledShipment`, formData, config);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.result) {
				dispatch(setFetchBillLoading(true));
				await dispatch(_fetchBill(state.viewingBill.id));
				dispatch(setHeldBillUpdateFieldDefaults());
			}
		} catch (err) {
			dispatch(setNotification("Error updating bill", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setUpdateBillLoading(false));
			dispatch(setFetchBillLoading(false));
		}
	}
}

export const uploadHoldFile = file => {
	return async (dispatch) => {
		dispatch(setFileUploadLoading(true));
		try {
			const formdata = new FormData();
			formdata.append('file', file);
			const response = await axios.post(`index.php?p=api&r=json&c=billing&m=importUpdatedHoldFile`, formdata, { timeout: 300000 });
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.updated) {
				if (response.data.body.updated) {
					dispatch(setNotification("Update submitted", {variant: "success"}));
				}
				if (response.data.body.resultFile) {
					downloadUploadResultFile(response.data.body.resultFile);
				}
				dispatch(fetchHeldBillsSummary());
			}
		} catch (err) {
			dispatch(setNotification("Error uploading file", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setFileUploadLoading(false));
		}
	}
}

export const downloadUploadResultFile = file => {
	window.location.href = `${apiUrl}/index.php?p=api&c=billing&m=downloadUploadResultFile&file=${file}`;
}

export const setWarehouseDefaults = tmsCode => {
	return async (dispatch) => {
		const response = await axios.get(`index.php?p=api&r=json&c=warehouse&m=getDefaults&d=${tmsCode}`);
		dispatch(handleMessages(response));
		if (response && response.data && response.data.body && response.data.body.ref_entry_type) {
			dispatch(setRefEntryType(response.data.body.ref_entry_type));
			dispatch(setRefEntryOpts(response.data.body.ref_entry_opts));
		}
	}
}

export const setHeldBillUpdateFieldDefaults = () => {
	return async (dispatch, getState) => {
		const bill = getState().CustomerPortal.viewingBill;
		dispatch(setCustomerPortalUploadedFiles([]));
		if (bill) {
			dispatch(setUpdateDist(bill.glCode || ""));
			dispatch(setUpdateCostCenter(bill.distCode || ""));
			dispatch(setUpdateBol(bill.bolNumber || ""));
			dispatch(setUpdatePo(bill.poNumber || ""));
			dispatch(setUpdateRef(bill.refNumber || ""));
			dispatch(setUpdateRa(bill.raNumber || ""));
			dispatch(setUpdateNote(""));
			dispatch(setUpdateVendor(bill.vendorNumber || ""));
			dispatch(setUpdateNote(bill.notes.customerNotes));
			dispatch(setDeclinePayment(false));
			dispatch(setCorrectionCode("I"));
			dispatch(setAdjustmentNote(""));
			dispatch(setReleaseForPayment(false));
			dispatch(setNotApproved(false));
			dispatch(setNotApprovedNote(""));
			dispatch(setCompanyLocations(bill.locations || []));
			dispatch(setSelectedCompanyLocation(bill.location.id || 0));
		}
	}
}

export const fetchHeldBillsSummary = () => {
	return async (dispatch) => {
		dispatch(setHeldBillsByCode([]));
		dispatch(setTotalHeldBills(0));
		dispatch(setHeldBillsSummaryLoading(true));
		try {
			const response = await axios.get('index.php?p=api&r=json&c=billing&m=getAllHoldTotals');
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.summary) {
				dispatch(setHeldBillsByCode(response.data.body.summary));
				dispatch(setTotalHeldBills(response.data.body.total));
				dispatch(setTotalUniqueHeldBills(response.data.body.uniqueTotal));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching held bills summary", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setHeldBillsSummaryLoading(false));
		}
	}
}

export const fetchInvoices = (holdCode) => {
	return async (dispatch, getState) => {
		const pageNum = getState().CustomerPortal.tfmInvoicesPageNumber;
		const limit = getState().CustomerPortal.tfmInvoicesPageSize;
		const filters = getState().CustomerPortal.invoiceListFilters;
		dispatch(setFetchInvoicesLoading(true));
		dispatch(setInvoices([]));
		dispatch(setSelectedInvoices([]));
		dispatch(setHoldCode(null));

		let dataUrl = `index.php?p=api&r=json&c=billing&m=getBilledShipments&page=${pageNum}&limit=${limit}&filters[code]=${holdCode}`;
		if(filters.status && filters.status > 0) {
			dataUrl += `&filters[status]=${filters.status}`;
		}
		if(filters.pro_number && filters.pro_number.length) {
			dataUrl += `&filters[pro]=${filters.pro_number}`;
		}
		if(filters.invoice_number && filters.invoice_number.length) {
			dataUrl += `&filters[invnbr]=${filters.invoice_number}`;
		}
		if(filters.account_number && filters.account_number.length) {
			dataUrl += `&filters[acct]=${filters.account_number}`;
		}
		if(filters.bol_number && filters.bol_number.length) {
			dataUrl += `&filters[bol]=${filters.bol_number}`;
		}
		if(filters.po_number && filters.po_number.length) {
			dataUrl += `&filters[po]=${filters.po_number}`;
		}
		if(filters.carrier && filters.carrier.length) {
			dataUrl += `&filters[carrier]=${filters.carrier}`;
		}
		if(filters.location_ids && filters.location_ids.length) {
			const locString = filters.location_ids.join(',');
			dataUrl += `&filters[location]=${locString}`;
		}

		try {
			//fetch hold code
			const holdCodeResponse = await axios.get(`index.php?p=api&r=json&c=billing&m=getHoldCode&code=${holdCode}`);
			dispatch(handleMessages(holdCodeResponse));
			if (!holdCodeResponse.data.body) {
				return false;
			}
			dispatch(setHoldCode(holdCodeResponse.data.body));
			//fetch invoices
			const response = await axios.get(dataUrl);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && Array.isArray(response.data.body.records)) {
				dispatch(setInvoices(response.data.body.records))
				if (response.data.body.stats && response.data.body.stats.records) {
					dispatch(setInvoicesTotalRecords(response.data.body.stats.records))
				}  else {
					// set the record count to 0 if there is an error or the property doesn't exist
					dispatch(setInvoicesTotalRecords(0))
				}
			}  else {
				dispatch(setInvoicesTotalRecords(0))
			}
		} catch (err) {
			// set the record count to 0 if there is an exception
			dispatch(setInvoicesTotalRecords(0))
			dispatch(setNotification("Error fetching invoices", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setFetchInvoicesLoading(false));
		}
	}
}

export const clearMassReleaseFields = () => {
	return (dispatch) => {
		dispatch(setGlCode(""));
		dispatch(setMassReleaseComment(""));
	}
}

export const submitMassRelease = () => {
	return async (dispatch, getState) => {
		dispatch(setMassReleaseLoading(true));
		const state = getState().CustomerPortal;
		const payload = {
			holdCodeId: state.holdCode.id,
			invoices: state.selectedInvoices,
			comment: state.massReleaseComment,
			glCode: state.glcode,
		};

		try {
			const response = await axios.post(`index.php?p=api&r=json&c=billing&m=bulkReleaseHolds`, qs.stringify(payload));
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error releasing held bills", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setMassReleaseLoading(false));
		}
	}
}

export const fetchCannedReports = () => {
	return async (dispatch) => {
		dispatch(setCannedReports([]));
		try {
			const response = await axios.get('index.php?p=api&r=json&c=billing&m=getCannedReports');
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.reports) {
				dispatch(setCannedReports(response.data.body.reports));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching previous reports", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setCannedReportsLoading(false));
		}
	}
}

export const runCannedReport = (report, startDate, endDate) => {
	return async (dispatch) => {
		dispatch(setRunCannedReportLoading(true));
		const url = `index.php?p=api&c=billing&m=runCannedReport&report=${report}&startDate=${startDate}&endDate=${endDate}`
		try {
			const response = await axios.get(url, { timeout: 180000 });
			dispatch(handleMessages(response));
			if (response && response.data) {
				if(response.data.body) {
					dispatch(setNewCannedReportId(response.data.body));
				} // Messages should be populated if just a false or empty body returns
			} else{
				dispatch(setNotification("Unexpected response received while running report", {variant: "error"}))
			}
		} catch (err) {
			dispatch(setNotification("Unable to Run Report!", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setRunCannedReportLoading(false));
		}

	}
}

export const dlCannedReportFile = (reportId) => {
	return (dispatch) => {
		if(reportId > 0) {
			window.open(`${apiUrl}/index.php?p=api&c=billing&m=dlCannedReport&report=${reportId}`, "_self");
			dispatch(setNewCannedReportId(null));
		} else {
			dispatch(setNotification("Unable to download report, no ID provided!", {variant: "error"}))
		}
	}
}

export const exportCategoryCSV = codes => {
	window.location.href = `${apiUrl}/index.php?p=api&c=billing&m=exportHoldCategory&filters[code]=${codes}`;
}

export const fetchTFMInvoices = () => {
	return async (dispatch, getState) => {
		const pageNum = getState().CustomerPortal.tfmInvoicesPageNumber;
		const limit = getState().CustomerPortal.tfmInvoicesPageSize;
		dispatch(setTfmInvoicesLoading(true));
		dispatch(setTfmInvoices([]));
		try {
			const response = await axios.get(`index.php?p=api&r=json&c=tfmInvoices&m=collection&page=${pageNum}&limit=${limit}`);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				dispatch(setTfmInvoices(Array.isArray(response.data.body.response.records) ? response.data.body.response.records : []));
				if (response.data.body.response.stats && response.data.body.response.stats.records) {
					dispatch(setTfmInvoicesTotalRecords(response.data.body.response.stats.records));
				}
			} else {
				throw new Error("Unexpected response");
			}
		} catch (err) {
			dispatch(setNotification("Error fetching TFM invoices", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setTfmInvoicesLoading(false));
		}
	}
}

export const fetchImageTypes = () => {
	return async (dispatch) => {
		dispatch(setTfmInvoicesLoading(true));
		setInvoiceImageTypes([]);
		try {
			const response = await axios.get(`index.php?p=api&r=json&c=tfmInvoices&m=getImageTypes`);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				if (response.data.body.response.types) {
					dispatch(setInvoiceImageTypes(response.data.body.response.types));
				}
			} else {
				throw new Error("Unexpected response");
			}
		} catch (err) {
			dispatch(setNotification("Error fetching TFM carrier image types.", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setTfmInvoicesLoading(false));
		}
	}
}

export const downloadCarrierImages = (brokerId, invoiceNumber, id) => {
	return async (dispatch) => {
		dispatch(setDownloadCarrierImagesLoading(true));
		try {
			const url = `/index.php?p=api&r=text&c=tfmInvoices&m=downloadCarrierImages&brokerId=${brokerId}&invoiceNumber=${invoiceNumber}&id=${id}`;
			window.location = basePath + url;
		} catch (err) {
			dispatch(setNotification("Error downloading carrier documents", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setDownloadCarrierImagesLoading(false));
		}
	}
}

export const prepareCarrierImages = (brokerId, invoiceNumber, id) => {
	return async (dispatch, getState) => {
		dispatch(setNotification("Please wait while your images are prepared. This may take several minutes.", {variant: "info"}));
		dispatch(addToLoadingList(invoiceNumber));
		const types = getState().CustomerPortal.imageTypes;
		const restrictDocs = getState().Admin.user.user_settings.restrict_carrier_document_access;
		let allowedTypes = [];

		if(types.length && restrictDocs) {
			types.forEach(function(type) { 
				if(type.code !== 'INV' && type.code !== 'DOC') {
					allowedTypes.push(type.code);
				}
			});
		}

		const url = 'index.php?p=api&r=json&c=tfmInvoices&m=prepareCarrierImages&brokerId=' + brokerId + '&invoiceNumber=' + invoiceNumber + "&id=" + id + "&types=" + allowedTypes;

		let response = await axios.get(url);
		dispatch(handleMessages(response));

	}
}

export const checkReadyImages = invoices => {
	return async (dispatch, getState) => {
		let url ='index.php?p=api&r=json&c=tfmInvoices&m=checkReadyImages';
		let count = 0;
		invoices.map(invNum => {
			url += "&i[" + count + "][num]=" + invNum.invoice_number + "&i[" + count + "][id]=" + invNum.invoice_id + "&i[" + count + "][bid]=" + invNum.broker_id;
			count++;
		});
		let response = await axios.get(url);
		dispatch(handleMessages(response));
		let readyImages = response.data.body.response.readyImages;
		let loadingImages = response.data.body.response.loadingImages;
		let retVal = false;
		if(typeof(loadingImages) == "object" && Array.isArray(loadingImages)) {
			dispatch(setCustomerPortalTfmILoadingImages(loadingImages));
			retVal = true;
		}
		if(typeof(readyImages) == "object" && Array.isArray(readyImages)) {
			dispatch(setCustomerPortalTfmReadyImages(readyImages));
			retVal = true;
		}
		return retVal;
	}
}

export const fetchTfmInvoiceCarrierBills = (brokerId, invoiceNumber) => {
	return async (dispatch, getState) => {
		dispatch(setFetchInvoicesLoading(true));
		dispatch(setInvoices([]));
		try {
			const pageNum = getState().CustomerPortal.tfmInvoiceCarrierBillsPageNumber;
			const limit = getState().CustomerPortal.tfmInvoiceCarrierBillsPageSize;
			const url = `index.php?p=api&r=json&c=tfmInvoices&m=getBills&page=${pageNum}&limit=${limit}&brokerId=${brokerId}&invoiceNumber=${invoiceNumber}`;
			//fetch invoices
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			const records = searchObject(response, "data.body.response.records");
			const count = searchObject(response, "data.body.response.stats.records");
			if (Array.isArray(records)) {
				dispatch(setInvoices(records));
				dispatch(setCustomerPortalTfmInvoiceCarrierBillsTotalRecords(count));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching carrier bills", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setFetchInvoicesLoading(false));
		}
	}
}

export const searchTfmInvoices = (searchBy, searchValues) => {
	return async (dispatch, getState) => {
		if(!searchValues.length) {
			dispatch(setNotification(`Please enter values to search`, {variant: 'error'}));
			return;
		}
		dispatch(setCustomerPortalInvoiceSearchLoading(true));
		dispatch(setInvoices([]));
		try{
			const pageNum = getState().CustomerPortal.tfmInvoiceSearchPageNumber;
			const limit = getState().CustomerPortal.tfmInvoiceSearchPageSize;
			const restrictDocs = getState().Admin.user.user_settings.restrict_carrier_document_access;
			let url = `index.php?p=api&r=json&c=tfmInvoices&m=catalog&page=${pageNum}&limit=${limit}`;
			let index = 0;
			searchValues.map(val => {
				url += `&filters[${searchBy}][${index}]=${val}`;
				index++;
			});
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			const records = searchObject(response, "data.body.response.records");
			const count = searchObject(response, "data.body.response.stats.records");
			if (Array.isArray(records) && Number.isInteger(count)) {
				dispatch(setCustomerPortalInvoiceSearchResults(records));
				dispatch(setCustomerPortalInvoiceSearchTotalRecords(count));
			} else {
				throw('Invalid response format.');
			}
		} catch(err) {
			dispatch(setNotification('Error searching for invoices', {variant: 'error'}));
			dispatch(setCustomerPortalInvoiceSearchResults([]));
			dispatch(setCustomerPortalInvoiceSearchTotalRecords(0));
			console.error(err);
		} finally {
			dispatch(setCustomerPortalInvoiceSearchLoading(false));
		}
	}
}

export const exportTfmInvoices = (searchBy, searchValues, reportType) => {
	return async (dispatch, getState) => {
		if(!searchValues.length) {
			dispatch(setNotification(`Please enter values to search`, {variant: 'error'}));
			return;
		}
		try{
			let url = `index.php?p=api&r=json&c=tfmInvoices&m=export`;
			let index = 0;
			searchValues.map(val => {
				url += `&filters[${searchBy}][${index}]=${val}`;
				index++;
			});
			url += `&reportType=${reportType}`;
			axios.get(url, {responseType: 'blob'}).then((response) => {
				const filename = response.headers["content-disposition"].split('; filename=')[1];
				const href = URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', filename);
				link.click();
				link.remove();
			}).catch(error => {
				dispatch(handleMessages(error));
			});
			
		} catch(err) {
			dispatch(setNotification('Error exporting invoices', {variant: 'error'}));
			console.error(err);
		}
	}
}

export const _fetchUserAssignedTFMCLocations = () => {
	return async (dispatch, getState) => {
		try{
			let url = `index.php?p=api&r=json&c=billing&m=getUserBrokerLocations`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			const locs = searchObject(response, "data.body");
			if (Array.isArray(locs)) {
				dispatch(setCustomerPortalUserBrokerLocations(locs));
			}
		} catch(err) {
			dispatch(setNotification('Error fetching user location assignment data', {variant: 'error'}));
			console.error(err);
		}
	}
}

export const fetchUserAssignedTFMCLocations = () => {
	return (dispatch, getState) => {
		if(getState().CustomerPortal.shouldFetchBrokerLocationIds) {
			dispatch(_fetchUserAssignedTFMCLocations());
		}
	}
}

export const saveCustomInvoiceListColumns = (cols) => {
	return async (dispatch) => {
		let url = `index.php?p=api&r=json&c=tfmInvoices&m=saveInvoiceListColumns`;
		const payload = cols;
		try {
			const response = await axios.post(url, qs.stringify(payload));
		
			if (response && response.data && response.data.body && response.data.body.updated) {
				if (response.data.body.updated) {
					dispatch(setNotification("Custom layout saved!", {variant: "success"}));
				}
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error saving custom layout.", {variant: "error"}));
			console.error(err);
		} 
	}
}

export const fetchCustomInvoiceListColumns = () => {
	return async (dispatch) => {
		let url = `index.php?p=api&r=json&c=tfmInvoices&m=getInvoiceListColumns`;

		try {
			const response = await axios.get(url);;
			if (response && response.data && response.data.body && response.data.body.columns) {
				dispatch(setInvoiceListCustomColumns(response.data.body.columns));
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error getting custom layout", {variant: "error"}))
			console.error(err);
		} 
	}
}