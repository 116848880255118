import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { withSnackbar } from "notistack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Typography from "@material-ui/core/Typography";

class AccessorialsSelect extends Component {

    handleChecked(key, event) {
        this.props.handleChecked(key, event);
    }

    render() {
        let { classes, accessorials, disabled } = this.props;
        if (typeof classes !== "object") classes = {};
        if (!Array.isArray(accessorials)) accessorials = [];
        return (
            <GridContainer>
                {accessorials.map((accs, key) => {
                    if (typeof accs !== "object") accs = {};
                    if(accs.common == 0) return null;
                    let color = "inherit";
                    if(this.props.recommended_accessorials) {
                        this.props.recommended_accessorials.map((recommendedAcc) => {
                            if(recommendedAcc.code == accs.code) {
                                color = "yellow";
                            }
                        });
                    }
                    return (
                        <GridItem xs={6} sm={4} md={4} key={key}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"yellow"}
                                        tabIndex={-1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot
                                        }}
                                        value={accs.code}
                                        checked={accs.checked ? true : false}
                                        onChange={event => this.handleChecked(key, event)}
                                        disabled={!!disabled}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label={<Typography style={{backgroundColor: color}}>{accs.name}</Typography>}
                            />
                        </GridItem>
                    );
                })}
            </GridContainer>
        );
    }
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(AccessorialsSelect));