import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "~/components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

class HazardousOptionsModal extends Component {

    state = {
        selectionKey: null,
    }

    onChangeSelection = e => {
        this.setState({selectionKey: e.target.value});
    }

    onClose = () => {
        if (typeof this.props.onClose === "function") this.props.onClose();
    }

    onSubmit = () => {
        if (typeof this.props.onSubmit === "function" && this.state.selectionKey) this.props.onSubmit(this.state.selectionKey);
    }

    render() {
        let myData = [...this.props.data];
        let transMode = this.props.transMode;
        return (
            <Dialog
                open={!!this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
                fullWidth
                maxWidth="md"
            >
                <DialogContent id="classic-modal-slide-description">
                    <InputLabel>
                        {this.props.title || "Choose a date"}
                    </InputLabel>
                    <br />
                    <GridContainer>
                        {(Array.isArray(myData)) && (
                            <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={2}>
                                </GridItem>
                                <GridItem xs={2}>
                                    <b>Proper Shipping Name: </b>
                                </GridItem>
                                <GridItem xs={1}>
                                    <b>Hazard Class: </b>
                                </GridItem>
                                <GridItem xs={2}>
                                    <b>Labels: </b>
                                </GridItem>
                                <GridItem xs={1}>
                                    <b>Packing Group: </b>
                                </GridItem>
                                <GridItem xs={4}>
                                    <b>Packing Instructions: </b>
                                </GridItem>
                            </GridContainer>
                                {myData.map((row, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <GridContainer>
                                                {(row.Forbidden || (row.CargoAircraftOnlyForbidden && transMode == "Cargo") || (row.PassengerAndCargoAircraftPackingInstructions && transMode == "Passenger") || typeof row.ProductObject.LabelsRequired === 'undefined') &&  (
                                                <GridItem xs={2}>
                                                        <b>This option is not available for the selected transport mode</b>
                                                    </GridItem>
                                                )}
                                                
                                                {(!(row.Forbidden || (row.CargoAircraftOnlyForbidden && transMode == "Cargo") || (row.PassengerAndCargoAircraftPackingInstructions && transMode == "Passenger") || typeof row.ProductObject.LabelsRequired === 'undefined')) && (
                                                <GridItem xs={2}>
                                                    <input type="radio" value={index} name="unData" onChange={this.onChangeSelection}></input> 
                                                    </GridItem>
                                                )}
                                                    <GridItem xs={2}>
                                                        {row.ProductObject.ProperShippingName}
                                                            <br/>
                                                        </GridItem>
                                                        <GridItem xs={1}>
                                                            {row.ProductObject.HazardClass}
                                                        </GridItem>
                                                        <GridItem xs={2}>
                                                            {row.ProductObject.LabelsRequired}
                                                            <br/>
                                                        </GridItem>
                                                        <GridItem xs={1}>
                                                            {row.ProductObject.PackingGroup}
                                                        </GridItem>
                                                        {(row.ProductObject.CargoAircraftOnlyPackingInstructions !== undefined && row.ProductObject.CargoAircraftOnlyPackingInstructions !== "Forbidden" && row.ProductObject.PassengerAndCargoAircraftPackingInstructions !== undefined && row.ProductObject.PassengerAndCargoAircraftPackingInstructions !== "Forbidden") && (
                                                            <GridItem xs={4}>
                                                                <b>Cargo Only</b><br/>
                                                                {row.ProductObject.CargoAircraftOnlyPackingInstructions}
                                                                <br/>
                                                                <b>Passenger and Aircraft</b><br/>
                                                                {row.ProductObject.PassengerAndCargoAircraftPackingInstructions}
                                                                <br/>
                                                            </GridItem>
                                                        )}
                                                        {((row.ProductObject.CargoAircraftOnlyPackingInstructions == undefined || row.ProductObject.CargoAircraftOnlyPackingInstructions == "Forbidden") && row.ProductObject.PassengerAndCargoAircraftPackingInstructions !== undefined) &&(
                                                            <GridItem xs={4}>
                                                                <b>Cargo Only</b><br/>
                                                                {row.ProductObject.PassengerAndCargoAircraftPackingInstructions}
                                                                <br/>
                                                            </GridItem>
                                                        )}
                                                        {(row.ProductObject.CargoAircraftOnlyPackingInstructions !== undefined && row.ProductObject.CargoAircraftOnlyPackingInstructions !== "Forbidden" && (row.ProductObject.PassengerAndCargoAircraftPackingInstructions == undefined || row.ProductObject.PassengerAndCargoAircraftPackingInstructions == "Forbidden")) && (
                                                            <GridItem xs={4}>
                                                                <b>Passenger and Aircraft</b><br/>
                                                                {row.ProductObject.CargoAircraftOnlyPackingInstructions}
                                                                <br/>
                                                            </GridItem>
                                                        )}
                                                <br/>
                                            </GridContainer>
                                            <br />
                                        </Fragment>
                                    )
                                })}
                            </GridItem>
                        )}
                    </GridContainer>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="white">
                        Cancel
                    </Button>
                    <Button onClick={this.onSubmit} disabled={!this.state.selectionKey} color={this.state.selectionKey ? "primary" : "white"}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}
export default HazardousOptionsModal;