import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from "lodash";
import { userIsAdmin, getRateDisplayByMode, isNotAdmin, getUserCompanySetting } from "../../../redux/selectors/Admin.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Paper from "@material-ui/core/Paper";
import Badge from "~/components/Badge/Badge.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { closeModal } from "../actions/RateDetailsModal.jsx"
import { searchObject } from "../../../helpers.jsx";

class RateDetailsModal extends Component {

    hasQuoteId = () => this.props.carrier.hasOwnProperty('carrier_quote_id') && !_.isEmpty(this.props.carrier['carrier_quote_id']);
    hasBillToAddress = () => this.props.carrier.hasOwnProperty('bill_to_address') && !_.isEmpty(this.props.carrier['bill_to_address']);
    showRated = () => !!+this.props.rateDisplay.rated || this.props.userIsAdmin;
    showBenchmark = () => !!+this.props.rateDisplay.benchmark || this.props.userIsAdmin;
    showGross = () => !!+this.props.rateDisplay.gross;
    showDiscount = () => !!+this.props.rateDisplay.discount;
    showMin = () => !!+this.props.rateDisplay.min;
    showTruecost = () => !!+this.props.rateDisplay.true_cost;
    showRatedOrBenchmark = () => this.showRated() || this.showBenchmark();
    showFuel = () => (!!+this.props.rateDisplay.fuel && this.showRatedOrBenchmark()) || this.props.userIsAdmin;
    showAccessorials = () => (!!+this.props.rateDisplay.accessorials && this.showRatedOrBenchmark()) || this.props.userIsAdmin;
    isNotTargetSavings = () => !this.props.isTargetSavings;

    /**
     * Check for an alias in rate display and return a default if not found
     */
    getHeaderText = (aliasSearchString, def) => {
        let alias = searchObject(this.props.rateDisplay.alias, aliasSearchString);
        return alias ? alias : def;
    }

    getRateDetails = () => {
        const classes = this.props.classes || {};
        const carrier = this.props.carrier;
        const parent = this.props.userIsAdmin;
        if (_.isEmpty(carrier)) {
            return null;
        }
        if (_.isEmpty(this.props.rateDisplay) && !this.props.userIsAdmin) {
            return null
        }

        return (
            <GridContainer>
                <GridContainer>
                    {(this.hasQuoteId() || this.hasBillToAddress()) && (
                        <GridItem xs={12}>
                            <h5>Important Information:</h5>
                        </GridItem>
                    )}
                    {this.hasQuoteId() && (
                        <GridItem xs={12}>
                            <Paper className={classes.warningPaper} elevation={1} style={{ color: "#000000"}}>
                                <Badge color="warning">Carrier Quote ID</Badge>
                                <br />{carrier.carrier_quote_id}
                            </Paper>
                        </GridItem>
                    )}
                    {this.hasBillToAddress() && (
                        <GridItem xs={12}>
                            <Paper className={classes.warningPaper} elevation={1} style={{ color: "#000000"}}>
                                <Badge color="warning">Bill To Address</Badge>
                                <br />{carrier.bill_to_address.billToName}
                                <br />{carrier.bill_to_address.billToAddress1}
                                <br />{carrier.bill_to_address.billToAddress2}
                                <br />{carrier.bill_to_address.billToCity} {carrier.bill_to_address.billToState}, {carrier.bill_to_address.billToCountry} {carrier.bill_to_address.billToZip}
                            </Paper>
                        </GridItem>
                    )}
                </GridContainer>
                {!carrier.hide && <GridContainer>
                    {(parent || this.showRatedOrBenchmark()) && (
                        <RateResultRow 
                            classes={classes}
                            header={(parent || this.isNotTargetSavings()) ? "Freight Charges" : ""}
                            showRatedRow={this.showRated()}
                            ratedText={`$${carrier.rate.freight}`}
                            showBenchmarkRow={parent || (this.showBenchmark() && this.isNotTargetSavings())}
                            benchmarkText={`$${carrier.rate.bm_freight}`}
                        />
                    )}
                    {(parent || (this.showGross() && this.showRatedOrBenchmark())) && (
                        <RateResultRow 
                            classes={classes}
                            header={this.getHeaderText("gross", "Gross Charge")}
                            showRatedRow={this.showRated()}
                            ratedText={!_.isEmpty(carrier.rate.gross) ? "$" + carrier.rate.gross : "N/A"}
                            showBenchmarkRow={this.showBenchmark()}
                            benchmarkText={!_.isEmpty(carrier.rate.bm_gross) ? "$" + carrier.rate.bm_gross : "N/A"}
                        />
                    )}
                    {(parent || (this.showDiscount() && this.showRatedOrBenchmark())) && (
                        <RateResultRow 
                            classes={classes}
                            header={this.getHeaderText("discount", "Freight Discount")}
                            showRatedRow={this.showRated()}
                            ratedText={!_.isEmpty(carrier.rate.discount) ? carrier.rate.discount + "%" : "N/A"}
                            showBenchmarkRow={this.showBenchmark()}
                            benchmarkText={!_.isEmpty(carrier.rate.bm_discount) ? carrier.rate.bm_discount + "%" : "N/A"}
                        />
                    )}
                    {(parent || (this.showMin() && this.showRatedOrBenchmark())) && (
                        <RateResultRow 
                            classes={classes}
                            header={this.getHeaderText("min", "Min Freight Charge")}
                            showRatedRow={this.showRated()}
                            ratedText={!_.isEmpty(carrier.rate.min) ? "$" + carrier.rate.min : "N/A"}
                            showBenchmarkRow={this.showBenchmark()}
                            benchmarkText={!_.isEmpty(carrier.rate.bm_min) ? "$" + carrier.rate.bm_min : "N/A"}
                        />
                    )}
                    {parent && this.showRatedOrBenchmark() && (
                        <Fragment>
                            <RateResultRow 
                                classes={classes}
                                header={"Move ID"}
                                showRatedRow={this.showRated()}
                                ratedText={carrier.rate && carrier.rate.actual_move_id ? carrier.rate.actual_move_id : "N/A"}
                                showBenchmarkRow={this.showBenchmark()}
                                benchmarkText={carrier.rate && carrier.rate.bm_move_id ? carrier.rate.bm_move_id : "N/A"}
                            />
                            <RateResultRow 
                                classes={classes}
                                header={"FSC ID"}
                                showRatedRow={this.showRated()}
                                ratedText={carrier.rate && carrier.rate.actual_fsc_id ? carrier.rate.actual_fsc_id : "N/A"}
                                showBenchmarkRow={this.showBenchmark()}
                                benchmarkText={carrier.rate && carrier.rate.bm_fsc_id ? carrier.rate.bm_fsc_id : "N/A"}
                            />
                        </Fragment>
                    )}
                    
                        <GridItem xs={12}>
                            <GridContainer>
                                {this.showFuel() && (this.showRated() || this.showBenchmark()) && (
                                    <RateResultRow 
                                        classes={classes}
                                        header={"Fuel Charges"}
                                        showRatedRow={this.showRated()}
                                        ratedText={!_.isEmpty(carrier.rate.fuel) ? "$" + carrier.rate.fuel : "N/A"}
                                        showBenchmarkRow={this.showBenchmark()}
                                        benchmarkText={!_.isEmpty(carrier.rate.bm_fuel) ? "$" + carrier.rate.bm_fuel : "N/A"}
                                    />
                                )}
                                {!_.isEmpty(carrier.accs) && this.showAccessorials() && (this.showRated() || this.showBenchmark()) && (
                                    <GridItem xs={12}>
                                        <h5>Accessorial Charges</h5>
                                        {Object.keys(carrier.accs).map(code => {
                                            const acc = carrier.accs[code];
                                            let cost = parseFloat(acc.cost).toFixed(2);
                                            let bm_cost = cost;
                                            if (!_.isEmpty(acc.bm_cost) && !isNaN(acc.bm_cost)) {
                                                bm_cost = parseFloat(acc.bm_cost).toFixed(2);
                                            }
                                            return (
                                                <GridContainer key={code}>
                                                    <GridItem xs>
                                                        <Paper className={classes.paper} elevation={1}>
                                                            <Badge color="gray">Accessorial</Badge>
                                                            <br />
                                                            {acc.name} [{code}]
                                                        </Paper>
                                                    </GridItem>
                                                    {this.showRated() && (
                                                        <GridItem xs>
                                                            <Paper className={classes.successPaper} elevation={1}>
                                                                <Badge color="success">Cost</Badge>
                                                                <br />${cost}
                                                            </Paper>
                                                        </GridItem>
                                                    )}
                                                    {this.showBenchmark() && (
                                                        <GridItem xs>
                                                            <Paper className={classes.warningPaper} elevation={1}>
                                                                <Badge color="warning">Benchmark</Badge>
                                                                <br />${bm_cost}
                                                            </Paper>
                                                        </GridItem>
                                                    )}
                                                </GridContainer>
                                            );
                                        })}
                                    </GridItem>
                                )}
                                {!_.isEmpty(carrier.accs) && this.showAccessorials() &&  (this.showRated() || this.showBenchmark()) && (
                                    <RateResultRow 
                                        classes={classes}
                                        header={"Accessorial Total"}
                                        showRatedRow={this.showRated()}
                                        ratedText={carrier.acc_total != undefined ? `$${carrier.acc_total}` : 'N/A'}
                                        showBenchmarkRow={this.showBenchmark()}
                                        benchmarkText={carrier.bm_acc_total != undefined ? `$${parseFloat(carrier.bm_acc_total).toFixed(2)}` : 'N/A'}
                                    />
                                )}
                                {(parent || (this.showTruecost() && this.showRated() && this.showBenchmark())) && (
                                    <Fragment>
                                        <GridItem xs={12}>
                                            <h5>Savings</h5>
                                            <GridContainer>
                                                <RateResultPaper 
                                                    classes={this.props.classes}
                                                    paperClass={"successPaper"}
                                                    badgeColor={"success"}
                                                    badgeText="Cost"
                                                    resultText={`$${carrier.rate.savings}`}
                                                />
                                            </GridContainer>
                                        </GridItem>
                                        <RateResultRow 
                                            classes={classes}
                                            header={"Total Charges"}
                                            showRatedRow={this.showRated()}
                                            ratedText={carrier.rate.rated != undefined ? `$${carrier.rate.rated}` : 'N/A'}
                                            showBenchmarkRow={this.showBenchmark()}
                                            benchmarkText={carrier.rate.benchmark != undefined ? `$${carrier.rate.benchmark}` : 'N/A'}
                                        />
                                    </Fragment>
                                )}
                            </GridContainer>
                        </GridItem>
                    
                </GridContainer>}
            </GridContainer>
        );
    }

    onClose = () => {
        this.props.closeModal();
    }

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.onClose}
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={this.onClose}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Rate Details</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {this.getRateDetails()}
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={this.onClose} color="white">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

/**
 * Displays a single rate result
 */
class RateResultPaper extends Component {
    render() {
        const classes = this.props.classes || {};
        return (
            <GridItem xs>
                <Paper className={classes[this.props.paperClass]} elevation={1}>
                    <Badge color={this.props.badgeColor}>{this.props.badgeText}</Badge>
                    <br />
                    {this.props.resultText === "$undefined" ? "N/A" : this.props.resultText}
                </Paper>
            </GridItem>
        );
    }
}

/**
 * Displays rated value
 */
class RatedCard extends Component {
    render() {
        return (
            <RateResultPaper 
                classes={this.props.classes}
                paperClass={"lightInfoPaper"}
                badgeColor={"info"}
                badgeText="Rated"
                resultText={this.props.text}
            />
        )
    }
}

/**
 * Displays benchmark value
 */
class BenchmarkCard extends Component {
    render() {
        return (
            <RateResultPaper 
                classes={this.props.classes}
                paperClass={"lightWarningPaper"}
                badgeColor={"warning"}
                badgeText="Benchmark"
                resultText={this.props.text}
            />
        )
    }
}

/**
 * Displays a row of rate results with rated and benchmark values
 */
class RateResultRow extends Component {
    render() {
        return (
            <GridItem xs={12}>
                <h5>{this.props.header}</h5>
                <GridContainer>
                    {this.props.showRatedRow && (
                        <RatedCard classes={this.props.classes} text={this.props.ratedText} />
                    )}
                    {this.props.showBenchmarkRow && (
                        <BenchmarkCard classes={this.props.classes} text={this.props.benchmarkText} />
                    )}
                </GridContainer>
            </GridItem>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const mode = ownProps.volume ? "volume" : "ltl";

    return {
        open: state.RateDetailsModal.open,
        carrier: state.RateDetailsModal.carrier || {},
        userIsAdmin: userIsAdmin(state),
        rateDisplay: getRateDisplayByMode(mode)(state),
        isTargetSavings: !!+getUserCompanySetting("target_savings")(state) && isNotAdmin(state),
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        closeModal
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RateDetailsModal);