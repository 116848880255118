import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//import { updateFilterNameValue } from '../actions/Search.jsx';

class SearchFilter extends Component {
    state = {
       fields: this.props.fields || [],
    };

    handleChange = (e) => {
        let value = e.target.value;
        this.props.updateFilter(value);
    }

    render() {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    style={{ width: "inherit" }}
                    defaultValue=""
                    value={this.props.value}
                    onChange={this.handleChange}
                    placeholder="Select Filter"
                >
                    {this.state.fields.map((field, i) => (
                        <MenuItem key={i} value={field.value}>
                            {field.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
}

const mapStateToProps = (state) => {
	return {
        ...state.Search
	};
};

export default connect(mapStateToProps, mapDispatchToProps) (SearchFilter);
