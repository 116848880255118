import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Transition from "~/components/TMS/ModalTransition.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Grid from "@material-ui/core/Grid";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class LocationCodeModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>{this.props.locationType} Location Code</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                        <Grid container justify="center">
                            <GridItem xs={12} className={classes.center} style={{marginBottom: "20px"}}>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        type: "text",
                                        name: "saveLocationCode",
                                        id: "saveLocationCode",
                                        value: this.props.saveLocationCode || "",
                                        onChange: this.props.handleInput("saveLocationCode"),
                                        placeholder: "Enter Code"
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} className={classes.center}>
                                <Button fullWidth color="danger" onClick={() => this.props.onSkip(this.props.locationType)}>
                                    Skip
                                </Button>
                            </GridItem>
                            <GridItem xs={6} className={classes.center}>
                                <Button fullWidth color="success" onClick={() => this.props.onSubmit(this.props.locationType, this.props.saveLocationCode)}>
                                    Submit
                                </Button>
                            </GridItem>
                        </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(style)(LocationCodeModal);