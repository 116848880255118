import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment";
import Datetime from "react-datetime";

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class SearchFilterValue extends Component {
	state = {
		value: this.props.value,
		value2: this.props.value2,
		placeholder: this.props.placeholder || "Enter search value",
		loading: false,
	};

	handleDateFilters = (m) => {
		const date = moment(m).format("YYYY-MM-DD");
		this.setState({ value: date });
		this.props.updateFilterValue(date, '');
	}

	handleChange = (e, setValueTwo) => {
		let {value, value2} = this.state;

		if(setValueTwo) {
			value2 = e.target.value;
		}else{
			value = e.target.value;
		}
		this.setState({ value, value2 });
		this.props.updateFilterValue(value, value2);
	};

	getInput = () => {
		const {  placeholder } = this.state;
		const { field, value, value2 } = this.props;
		const fieldType = field.type || ""

		if(fieldType == "date") {
			return (
				<Datetime
					style={{ width: "inherit" }}
					dateFormat={"MM/DD/YYYY"}
					closeOnSelect={true}
					timeFormat={false}
					value={value}
					onChange={this.handleDateFilters}
				/>
			);
		} else if(fieldType == "select") {
			return(
				<Select
					style={{ width: "inherit" }}
					defaultValue=""
					value={value}
					onChange={(e) => this.handleChange(e, false)}
					placeholder={placeholder}
				>
					{field.options.map((opt, i) => (
						this.getMenuItem(opt, i)
					))}
				</Select>
			);
		
		} else if(fieldType == "select_with_text") {
			return(
				<GridContainer>
					<GridItem xs={6}>
						<Select
							defaultValue=""
							value={value2}
							onChange={(e) => this.handleChange(e, true)}
							placeholder={placeholder}
							style={{ width: "100%" }}
						>
							{field.options.map((opt, i) => (
								<MenuItem key={i} value={opt.value}>
									{opt.name}
								</MenuItem>
							))}
						</Select>
					</GridItem>
					<GridItem xs={6}>
						<TextField
							style={{ width: "100%" }}
							placeholder={placeholder}
							required={true}
							value={value}
							onChange={this.handleChange}
							autoFocus={this.props.setFocus}
						/>
					</GridItem>
				</GridContainer>
			);
		
		} else {
			return (
				<TextField
					style={{ width: "inherit" }}
					placeholder={placeholder}
					required={true}
					value={value}
					onChange={(e) => this.handleChange(e, false)}
					autoFocus={this.props.setFocus}
				/>
			);
		}
	}

	getMenuItem = (opt, i) => {
        const classes = this.props.classes;
		if(opt.hasOwnProperty('disabled')) {
			if(opt.disabled) {
				return (
					<MenuItem
						key={i}
						value={opt.value}
						disabled
						classes={{
							root: classes.selectMenuItemParent,
							selected: classes.selectMenuItemSelected,
						}}>
						<b>{opt.name}</b>
					</MenuItem>
				);
			} else {
				return (
					<MenuItem
						key={i}
						value={opt.value}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected,
						}}
					>
						{opt.name}
					</MenuItem>
				);
			}
		} else {
			return (
				<MenuItem key={i} value={opt.value}>
					{opt.name}
				</MenuItem>
			);
		}
	}

	render() {
		return (
			<>
				<FormControl style={{ width: "100%" }}>
					{this.state.loading ? (
						<GridContainer spacing={1} style={{ width: "inherit" }}>
							<GridItem xs={10} style={{ padding: 0, marginLeft: 15 }}>
								<TextField
									style={{ width: "100%" }}
									value="Loading..."
									disabled
								/>
							</GridItem>
							<GridItem xs={1}>
								<CircularProgress size={25} />
							</GridItem>
						</GridContainer>
					) : (
						<>{this.getInput()}</>
					)}
				</FormControl>
			</>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
}

const mapStateToProps = (state) => {
	return {
        ...state.Search
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ltlQuoteStyle)(SearchFilterValue));
