import React from "react";

import { withSnackbar } from "notistack";
import { isEqual } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class InputList extends React.Component {
	state = {
		values: this.props.values || [],
	}
	componentDidUpdate = (prevProps, prevState) => {
		if(!isEqual(prevState.values, this.state.values)) {
			if(typeof this.props.onChange === 'function') {
				this.props.onChange(this.state.values);
			}
		}
		if(!isEqual(prevProps.values, this.props.values)) {
			this.setState({ values: this.props.values });
		}
	}
	onChange = (e, key) => {
		let values = [...this.state.values];
		values[key] = (e.target.value);
		this.setState({ values });
	}
	remove = (e, key) => {
		let values = [...this.state.values];
		values.splice(key, 1);
		this.setState({ values });
	}
	add = e => {
		let values = [...this.state.values];
		values.push('');
		this.setState({ values });
	}
	getInputs = () => {
		return this.state.values.map((val, key) => (
			<GridItem xs={12} key={`ipval_${key}`}>
				<GridContainer>
					<GridItem xs={11}>
						<CustomInput
							formControlProps={{ fullWidth: true }}
		                    inputProps={{
		                        type: "text",
		                        name: "description",
		                        value: val,
		                        onChange: e => this.onChange(e, key),
		                    }}
						/>
					</GridItem>
					<GridItem xs={1}>
						<Button color="danger" size="sm" justIcon round onClick={e => this.remove(e, key)}>
		                    <Delete/>
		                </Button>
		            </GridItem>
		        </GridContainer>
		    </GridItem>
		));
	}
	render = () => {
		return (
			<GridContainer>
				<GridItem xs={12}>
					<Button color="success" size="sm" justIcon round onClick={this.add}>
                        <Add/>
                    </Button>
				</GridItem>
				{this.getInputs()}
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(InputList));