import axios from "~/variables/axios.jsx";
import adminRoutes from "~/routes/admin.js";
import userRoutes from "~/routes/user.js";
import carrierRoutes from "~/routes/carrier.js";

async function getSession() {
	try {
		return await axios.get("/index.php?p=api&r=json&c=landing&m=index");
	} catch (error) {
		console.error(error);
		return null;
	}
}

function getRoutes(user) {

	if (!user) {
		return userRoutes;
	}

	if (user.level === 'admin') {
		return adminRoutes;
	}


	if (user.level === 'carrier') {
		return carrierRoutes;
	}

	if (user === false) {
		return userRoutes;
	}

	const { permissions, user_company_settings } = user;

	const getIndexById = (objects, identifier) => {
		for(let i = 0; i < objects.length; i++) {
			if (objects[i].identifier === identifier) return i;
		}
	}

	const getRouteIndexById = identifier => {
		return getIndexById(userRoutes, identifier);
	}

	// Allow menu options to be repositioned without having to update all the indexes
	const dashboardIndex = getRouteIndexById("Dashboard");

	const billOfLadingIndex = getRouteIndexById("Freight");
	const billOfLadingViews = userRoutes[billOfLadingIndex].views;

	const shippingToolsIndex = getRouteIndexById("Shipping Tools");
	const shippingToolsViews = userRoutes[shippingToolsIndex].views;

	const reportsIndex = getRouteIndexById("Reporting");
	const reportsViews = userRoutes[reportsIndex].views;

	const parcelIndex = getRouteIndexById("Parcel");
	const parcelViews = userRoutes[parcelIndex].views;

	const internationalIndex = getRouteIndexById("International");

	const settingsIndex = getRouteIndexById("Settings");
	const settingsViews = userRoutes[settingsIndex].views;

	const claimsIndex = getRouteIndexById("Claims")

	// Dashboard
	userRoutes[dashboardIndex].invisible = !permissions['ALLOW_DASHBOARD_ACCESS'];

	// Bill of Lading / Freight
	userRoutes[billOfLadingIndex].invisible = !permissions['USE_BILL_OF_LADING'] && !permissions['USE_POSTING_PANEL'] && !permissions['USE_LCC_CALCULATOR'] && !permissions['USE_QUOTE_PANEL'];
	userRoutes[billOfLadingIndex].views[getIndexById(billOfLadingViews, "Create Shipment")].invisible = !permissions['USE_BILL_OF_LADING'];
	userRoutes[billOfLadingIndex].views[getIndexById(billOfLadingViews, "Load Board")].invisible = !permissions['USE_POSTING_PANEL'];
	userRoutes[billOfLadingIndex].views[getIndexById(billOfLadingViews, "Freight Quote")].invisible = !permissions['USE_LCC_CALCULATOR'];
	userRoutes[billOfLadingIndex].views[getIndexById(billOfLadingViews, "Freight Quote Panel")].invisible = !permissions['USE_QUOTE_PANEL'];

	// Parcel
	userRoutes[parcelIndex].invisible = !permissions['USE_PARCEL_RATING'] && !permissions['USE_PARCEL_SHIPPING'] && !permissions['PRODUCT_CATALOG_READ'];
	if(!userRoutes[parcelIndex].invisible) {
		userRoutes[parcelIndex].views[getIndexById(userRoutes[parcelIndex].views, 'ParcelQuote')].invisible = !permissions['USE_PARCEL_RATING'];
		userRoutes[parcelIndex].views[getIndexById(userRoutes[parcelIndex].views, 'ParcelQuotePanel')].invisible = !permissions['USE_PARCEL_RATING'];
		userRoutes[parcelIndex].views[getIndexById(userRoutes[parcelIndex].views, 'ParcelScheduler')].invisible = !permissions['USE_PARCEL_SHIPPING'];
		userRoutes[parcelIndex].views[getIndexById(userRoutes[parcelIndex].views, 'ArchivedParcelShipments')].invisible = !permissions['USE_PARCEL_SHIPPING'];
		userRoutes[parcelIndex].views[getIndexById(userRoutes[parcelIndex].views, 'Manage Printers')].invisible = !permissions['USE_PARCEL_SHIPPING'];
		userRoutes[parcelIndex].views[getIndexById(userRoutes[parcelIndex].views, 'ProductCatalogParcel')].invisible = !permissions['PRODUCT_CATALOG_READ'];
	}

	// International
	userRoutes[internationalIndex].invisible = !permissions['USE_TMS_INTL'];

	// Shipping Tools
	userRoutes[shippingToolsIndex].invisible = !permissions['USE_CARRIER_TRACKING'] &&
		!permissions['ACCESS_CARRIER_CONNECT'] &&
		!permissions['USE_MILER'];
	userRoutes[shippingToolsIndex].views[getIndexById(shippingToolsViews, "Track Shipment")].invisible = !permissions['USE_CARRIER_TRACKING'];
	userRoutes[shippingToolsIndex].views[getIndexById(shippingToolsViews, "Monitored PROs")].invisible = !permissions['USE_CARRIER_TRACKING'];
	userRoutes[shippingToolsIndex].views[getIndexById(shippingToolsViews, "Transit Info")].invisible = !permissions['ACCESS_CARRIER_CONNECT'];
	userRoutes[shippingToolsIndex].views[getIndexById(shippingToolsViews, "Miler")].invisible = !permissions['USE_MILER'] || user_company_settings.enable_miler != 1;

	// Reports
	userRoutes[reportsIndex].invisible = !permissions['VIEW_COMPANY_REPORTS'] && !permissions['USE_DATA_DUMP'] && !permissions['USE_REPORT_BUILDER'];
	userRoutes[reportsIndex].views[getIndexById(reportsViews, "ManifestReports")].invisible = !permissions['VIEW_COMPANY_REPORTS'];
	userRoutes[reportsIndex].views[getIndexById(reportsViews, "DataDump")].invisible = !permissions['USE_DATA_DUMP'];
	userRoutes[reportsIndex].views[getIndexById(reportsViews, "ReportBuilding")].invisible = !permissions['USE_REPORT_BUILDER'];

	// Settings
	userRoutes[settingsIndex].invisible = !permissions['HANDLING_UNIT_READ'] &&
		!permissions['ADDRESS_BOOK_READ'] &&
		!permissions['CANNED_NOTES_READ'] &&
		!permissions['SUBMIT_PICKUP_REQUEST'];
	userRoutes[settingsIndex].views[getIndexById(settingsViews, "HandlingUnitCatalog")].invisible = !permissions['HANDLING_UNIT_READ'];
	userRoutes[settingsIndex].views[getIndexById(settingsViews, "AddressBook")].invisible = !permissions['ADDRESS_BOOK_READ'];
	userRoutes[settingsIndex].views[getIndexById(settingsViews, "CannedNotes")].invisible = !permissions['CANNED_NOTES_READ'];
	userRoutes[settingsIndex].views[getIndexById(settingsViews, "PickupTimes")].invisible = !permissions['SUBMIT_PICKUP_REQUEST']
	// userRoutes[settingsIndex].views[getIndexById(settingsViews, "ProductCatalog")].invisible = !permissions['PRODUCT_CATALOG_READ'];

	// Claims
	userRoutes[claimsIndex].invisible = !permissions['USE_CLAIMS'] || user_company_settings.enable_claims != 1;

	// Customer Portal
	userRoutes[getRouteIndexById("CustomerPortal")].invisible = !permissions['HOLD_RELEASE'];

	return userRoutes;
}

export default {
	getSession,
	getRoutes
};
