import React from "react";
import Datetime from "react-datetime";
import qs from "qs";

import { NavLink } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";

// material ui icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import SnackbarContent from "~/components/Snackbar/SnackbarContent.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewRuleSet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			redirect: false,
			user: {},
			body: {},
			editing: false,
			addingToCurrent: false,
			id: "",
			setId: "",
			carriers: [],
			name: "",
			scac: "",
			displayScac: "",
			start: "",
			end: "",
			rules: [
				{
					selector: "Total Linear Ft.",
					compare: "==",
					value: "",
					chain_oper: ""
				}
			]
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.getRules = this.getRules.bind(this);
		this.addRule = this.addRule.bind(this);
		this.handleRule = this.handleRule.bind(this);
		this.removeRule = this.removeRule.bind(this);
		this.save = this.save.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		let id = '';
		let setId = '';

		if (!_.isEmpty(this.props.match.params.id)) {
			id = this.props.match.params.id;
			this.setState({id: id});
		}

		if (!_.isEmpty(this.props.match.params.setId)) {
			setId = this.props.match.params.setId;
			this.setState({setId: setId});
		}

		let url = "/index.php?p=api&r=json&c=admin&m=newLinearFeetRule";

		if(!_.isEmpty(setId)) {
			url = '/index.php?p=api&r=json&c=admin&m=getNewLinearFeetRule&d=' + setId;
			if(_.isEmpty(id)) {
				this.setState({addingToCurrent: true});
			}
		}

		if(!_.isEmpty(id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editLinearFeetRule&d=" + id;
		}

		try {
			const response = await axios.get(url);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {
				this.props.handleMessages(response);

				if(!_.isEmpty(id)) {
					this.props.pageTitle('Edit Rule Set - ' + data.body.detail[0].name);
					this.setState({
						name: data.body.detail[0].name,
						scac: data.body.detail[0].scac + " | " + data.body.detail[0].scac_master_id,
						displayScac: data.body.detail[0].scac,
						start: data.body.detail[0].start,
						end: data.body.detail[0].end,
						rules: data.body.rules,
						editing: true,
					});
				} else {
					if(!this.state.addingToCurrent) {
						this.setState({
							show: true,
							loading: false,
							user: data.user,
							carriers: data.body.carriers,
						});
					} else {
						this.setState({
							name: data.body.rule_set.name,
							scac: data.body.rule_set.scac + " | " + data.body.rule_set.carrier_id,
							displayScac: data.body.rule_set.scac,
							start: data.body.rule_set.start,
							end: data.body.rule_set.end,
						});
					}
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value.trim() });
	}
	async handleDatetime(name, moment) {
        await this.setState({ [name]: moment });
    }
	getCarriers(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.scac + " | " + prop.id}
				>
					{prop.scac} | {prop.carrier}
				</MenuItem>
			);
		});
	}
	getRules(data) {
		const { classes } = this.props;
		return data.map((prod, key) => {

			let unitType = '';

			switch(prod.selector) {
				case 'Total Pallets':
					break;

				case 'Square Ft.':
				case 'Total Square Ft.':
					unitType = 'Ft^2';
					break;

				case 'Total Linear Ft.':
				case 'Linear Ft.':
				case 'Length':
				case 'Width':
				case 'Height':
				case 'Total Height':
					unitType = 'Ft.';
					break;

				case 'Total Wt.':
				case 'Weight':
				case 'Total PCF':
					unitType = 'Lb.';
					break;

				case 'Total Cube':
				case 'Cube':
					unitType = 'Ft^3';
					break;
				case 'Stackable':
					unitType = '';
					break;

				case 'GROUP':
					break;
			}
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={classes.package}>
						<GridContainer>
							<GridItem xs={(prod.selector == "Stackable" ? 5 : (prod.selector != "GROUP" ? 3 : 8))}>
								<FormControl
									fullWidth
									className={classes.selectFormControl}
								>
									<Select
										MenuProps={{
											className: classes.selectMenu
										}}
										classes={{
											select:
												classes.select +
												" " +
												classes.chip
										}}
										value={prod.selector}
										onChange={e =>
											this.handleRule(key, "selector", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total Linear Ft."
										>
											Total Linear Ft.
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Linear Ft."
										>
											Linear Ft.
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total Square Ft."
										>
											Total Square Ft.
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Square Ft."
										>
											Square Ft.
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total Wt."
										>
											Total Wt.
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Weight"
										>
											Weight
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total Cube"
										>
											Total Cube
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Cube"
										>
											Cube
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Stackable"
										>
											Stackable
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total PCF"
										>
											Total PCF
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Length"
										>
											Length
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Width"
										>
											Width
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Height"
										>
											Height
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total Height"
										>
											Total Height
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="Total Pallets"
										>
											Total Pallets
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="GROUP"
										>
											GROUP
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							{prod.selector != "GROUP" && prod.selector != "Stackable" && <GridItem xs={2}>
								<FormControl
									fullWidth
									className={classes.selectFormControl}
								>
									<Select
										MenuProps={{
											className: classes.selectMenu
										}}
										classes={{
											select:
												classes.select +
												" " +
												classes.chip
										}}
										value={prod.compare}
										onChange={e =>
											this.handleRule(key, "compare", e)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="=="
										>
											==
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="==="
										>
											===
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="!="
										>
											!=
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="!=="
										>
											!==
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value=">"
										>
											{">"}
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value=">="
										>
											{">="}
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="<"
										>
											{"<"}
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="<="
										>
											{"<="}
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>}
							{prod.selector != "GROUP" && prod.selector !== "Stackable" && <GridItem xs={3}>
								<InputLabel className={classes.label}>&nbsp;</InputLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										value: prod.value || "",
										onChange: e =>
											this.handleRule(key, "value", e),
										endAdornment: (
											<InputAdornment position="end">
												{unitType}
											</InputAdornment>
										),
									}}
									required
								/>
							</GridItem>}
							{prod.selector == "Stackable" && <GridItem xs={3}>
									<FormControl
										fullWidth
										className={classes.selectFormControl}
									>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select:
													classes.select +
													" " +
													classes.chip
											}}
											value={prod.value || ""}
											onChange={e =>
												this.handleRule(key, "value", e)
											}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected:
														prod.value
												}}
												value=""
											/>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected:
														prod.value
												}}
												value="0"
											>
												No
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected:
														prod.value
												}}
												value="1"
											>
												Yes
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								}

							<GridItem xs={2}>
								<FormControl
									fullWidth
									className={classes.selectFormControl}
								>
									<Select
										MenuProps={{
											className: classes.selectMenu
										}}
										classes={{
											select:
												classes.select +
												" " +
												classes.chip
										}}
										value={prod.chain_oper}
										onChange={e =>
											this.handleRule(
												key,
												"chain_oper",
												e
											)
										}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value=""
										/>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="&&"
										>
											&&
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value="||"
										>
											||
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs={2}>
								{key == 0 ? (
									<Button
										color="success"
										size="sm"
										justIcon
										round
										className={classes.chip}
										onClick={this.addRule}
									>
										<Add />
									</Button>
								) : (
									<Button
										color="danger"
										size="sm"
										justIcon
										round
										className={classes.chip}
										onClick={e => this.removeRule(key, e)}
									>
										<Close />
									</Button>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			);
		});
	}
	addRule() {
		let { rules } = this.state;
		rules.push({
			selector: "",
			compare: "",
			value: "",
			chain_oper: ""
		});
		this.setState({ rules });
	}
	handleRule(i, name, event) {
		let { rules } = this.state;
		if (!_.isEmpty(rules) && rules[i]) {
			let rule = rules[i];
			rule[name] = event.target.value;
			rules[i] = rule;
			this.setState({ rules });
		}
	}
	removeRule(i) {
		let { rules } = this.state;
		if (!_.isEmpty(rules)) {
			rules.splice(i, 1);
			this.setState({ rules });
		}
	}
	async save() {
		this.setState({ loading: true });
		const { name, scac, start, end, rules, editing, addingToCurrent } = this.state
		if(name === '') {
			this.setState({ loading: false });
			this.props.setNotification("A name must be provided for the rule set!", { variant: "error" });
			return false;
		}
		if(scac === '') {
			this.setState({ loading: false });
			this.props.setNotification("A carrier must be selected for the rule set!", { variant: "error" });
			return false;
		}
		if(start === '') {
			this.setState({ loading: false });
			this.props.setNotification("A subset start date be provided for the rule set!", { variant: "error" });
			return false;
		}
		if(end === '') {
			this.setState({ loading: false });
			this.props.setNotification("A subset end date must be provided for the rule set!", { variant: "error" });
			return false;
		}

		let fail = false;
		for(let i = 0; i < rules.length; i ++) {

			if(rules[i].selector == "Stackable") {
				if(rules[i].value !== "0" && rules[i].value !== "1") {
					this.setState({ loading: false });
					this.props.setNotification("Invalid stackable condition!", { variant: "error" });
					fail = true;
				}
			}

			if (typeof rules[i].value === "string") {
				rules[i].value = rules[i].value.trim();
			}
		}

		if(fail) return false;

		const data = {
			name: name.trim(),
			scac: scac,
			start: moment(start).format("YYYY-MM-DD"),
			end: moment(end).format("YYYY-MM-DD"),
			rules: rules,
		}

		let url = '/index.php?p=api&r=json&c=admin&m=newLinearFeetRule';

		if(addingToCurrent) {
			data.setId = this.state.setId;
		}

		if(editing) {
			url = '/index.php?p=api&r=json&c=admin&m=editLinearFeetRule';
			data.subsetId = this.state.id;
			data.id = this.state.setId;
		}

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			this.setState({ loading: false });
			if (typeof response.data !== "string" && response.data.body) {
				// Redirect if no createdSet (editing) or createdSet == true (creating)
				if(typeof response.data.body.createdSet == "undefined" || response.data.body.createdSet) {
					this.setState({ redirect: true });
				}
			}
		} catch (error) { console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occured while saving the rule set!", { variant: "error" });
		}
	}
	render() {
		const { classes } = this.props;
		let returnToLink = "";
		if(this.state.addingToCurrent || this.state.editing) {
			returnToLink = basePath + "/admin/linear-feet-rule-dates/" + this.props.match.params.setId;
		} else {
			returnToLink = basePath +"/admin/linear-feet-rules";
		}
		if (this.state.redirect) {
			return <Redirect to={returnToLink} />;
		}
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<NavLink to={returnToLink}>
						<Button size="sm" color="linkedin">
							{this.state.displayScac} Rule Set
						</Button>
					</NavLink>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<SnackbarContent
										message="WARNING - Check and double check your entries here. There is absolutely no way for the system to verify that the logical expressions you create here will evaluate the way you expect them to! The only thing the system is capable of doing, and in fact will do, is test the syntactical validity of the expressions you submit."
										color="danger"
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<SnackbarContent
										message="WARNING - Do not leave blank/unused rows behind. The last column of your last row should always be empty."
										color="warning"
									/>
								</GridItem>
								<GridItem xs={12}>
									<GridContainer>
										<GridItem xs>
										<InputLabel className={classes.label}>Set Name</InputLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													id: "name",
													name: "name",
													value:
														this.state.name ||
														"",
													onChange: this.handleInput(
														"name"
													),
													disabled: this.state.addingToCurrent,
												}}
												required

											/>
										</GridItem>
										<GridItem xs>
											<InputLabel className={classes.label}>Carrier</InputLabel>
											{!this.state.editing && !this.state.addingToCurrent ? (
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.scac || ""}
														inputProps={{
															name: "scac",
															id: "carrier"
														}}
														onChange={this.handleChange}
														disabled={this.state.editing}
													>
														{!_.isEmpty(this.state.carriers) && !_.isEmpty(this.state.carriers) && (
															this.getCarriers(this.state.carriers)
														)}
													</Select>
												</FormControl>
											) : (
												<CustomInput
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "text",
														id: "carrier",
														name: "scac",
														value:
															this.state.scac ||
															"",

														disabled: true,
													}}
													required
												/>
											)}
										</GridItem>
										<GridItem xs>
											<InputLabel
												className={classes.label}
											>
												Subset Start
											</InputLabel>
											<br />
											<FormControl fullWidth>
												<Datetime
													value={
														this.state.start || ""
													}
													timeFormat={false}
													onChange={m => this.handleDatetime("start", m)}
													className={classes.datetime}
													inputProps={{
														id: "start",
														name: "start"
													}}
													required
												/>
											</FormControl>
										</GridItem>
										<GridItem xs>
											<InputLabel
												className={classes.label}
											>
												Subset End
											</InputLabel>
											<br />
											<FormControl fullWidth>
												<Datetime
													value={
														this.state.end ||
														""
													}
													timeFormat={false}
													onChange={m => this.handleDatetime("end", m)}
													className={classes.datetime}
													inputProps={{
														id: "end",
														name: "end"
													}}
													required
												/>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
								{!_.isEmpty(this.state.rules) && (
									<GridItem
										xs={12}
										sm={12}
										md={12}
										className={classes.unitContainer}
									>
										{this.getRules(this.state.rules)}
									</GridItem>
								)}
								<GridItem xs>
									<Button
										color="linkedin"
										onClick={this.save}
										style={{
											marginRight: "4px"
										}}
									>
										Save Set
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(NewRuleSet));