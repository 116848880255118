import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import { setJSPM, setAvailablePrinters, jspmStatus, setUpPrinters } from "../actions/PrintZplModal.jsx";

import "formdata-polyfill";

import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from '@material-ui/core/Slide';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// material ui icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

// style for this view
import ltlQuoteStyle from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

const SlideTransition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});

class PrintZplModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			alert: null,
			showManagePrinters: false,
			loading: true,
			JSPM: null,
			zpl: "",
			printLabelModal: false,
			printDocModal: false,
			printer: "",
			default_printer_save: false,
			showWarningText: false,
		}
		this.handleChange = this.handleChange.bind(this);
	}

	async componentDidMount() {
	}

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	printerCheck() {
		const { printerType, label_type } = this.props;
		const printer = window.localStorage.getItem(printerType.toUpperCase());

		if (!_.isEmpty(printer) && label_type == "ZPL") {
			this.autoPrint(printer);
		} else {
			// this.viewLabel();
		}
	}

	displayPrinters(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	printLabel() {
		const { JSPM, zpl, default_printer_save } = this.props;
		const { printer } = this.state;

		const cpj = new JSPM.ClientPrintJob();
		cpj.clientPrinter = new JSPM.InstalledPrinter(printer);

		if (default_printer_save === true) {
			window.localStorage.setItem(this.props.printerType.toUpperCase(), printer);
			handleMessages("Default label printer has been set for " + this.props.printerType + ".", { variant: "success" });
		}

		cpj.printerCommands = zpl; // Set printer commands...
		cpj.sendToClient(); // Send print job to printer!
	}

	setShowManagePrinters = () => {
		this.setState({showManagePrinters: true});
	}
	closeManagePrinters = () => {
		this.setState({showManagePrinters: false});
	}

    handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
    }
	

	copyToClipboard() {
		navigator.clipboard.writeText(this.props.zpl);
	}

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={(this.props.open) ? true : false}
				TransitionComponent={SlideTransition}
				keepMounted
				onClose={() => this.props.handleModalClose(this.props.modalName)}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
					<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.handleModalClose(this.props.modalName)}>
						<Close className={classes.modalClose} />
					</Button>
					<h4 className={classes.modalTitle}>Print to Thermal Label Printer - {this.props.printerType}</h4>
				</DialogTitle>
				<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
					<GridContainer className={classes.left}>
						<GridItem xs={12} sm={12} md={12}>
							<InputLabel className={classes.label}>Select a Printer:</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{ className: classes.selectMenu }}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.state.printer || ""}
									inputProps={{ name: "printer" }}
									onChange={this.handleChange}
								>
									{!_.isEmpty(this.props.printers) ? (
										this.displayPrinters(this.props.printers)
									) : (
										<MenuItem disabled classes={{ root: classes.selectMenuItem }}>
											No Printers
										</MenuItem>
									)}
								</Select>
							</FormControl>
						</GridItem>
						{this.props.show_default_printer_save !== false && (
							<GridItem xs={12} sm={12} md={12}>
								<FormControlLabel
									control={
										<Checkbox
											tabIndex={-1}
											checkedIcon={<Check className={classes.checkedIcon} />}
											icon={<Check className={classes.uncheckedIcon} />}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot
											}}
											checked={this.props.default_printer_save}
											onChange={(e) => {this.props.handleChecked("default_printer_save", e)}}
										/>
									}
									classes={{ label: classes.label }}
									label={"Make this my default printer type for " + this.props.printerType}
								/>
							</GridItem>
						)}
					</GridContainer>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<Button onClick={() => this.props.handleModalClose(this.props.modalName)} color="white" className={classes.marginRight}>
						Close
					</Button>
					{this.props.show_copy_to_clipboard && (
						<Button onClick={() => this.copyToClipboard()} className={classes.marginRight}>
							Copy To Clipboard
						</Button>
					)}
					{!_.isEmpty(this.props.printers) && !_.isEmpty(this.state.printer) && (
						<Button onClick={() => this.printLabel()} color="success" className={classes.marginRight}>
							Print Label
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setJSPM,
        setAvailablePrinters,
        jspmStatus,
        setUpPrinters
    }, dispatch);
}

const mapStateToProps = state => {
	return {
        printers: state.PrintZplModal.printers,
        JSPM: state.PrintZplModal.JSPM,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(PrintZplModal)));