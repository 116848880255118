import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import CircularProgress from "@material-ui/core/CircularProgress";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class PickupRequestModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {this.props.loading && "Sending Request..."}
                        {this.props.step === 1 && !this.props.loading && "Choose Pickup Date & Ready Time"}
                        {this.props.step === 2 && !this.props.loading && "Set Dock Close Time"}
                    </h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBodyTall}>
                    <GridContainer justify="center">
                        <GridItem xs={12} className={classes.left}>
                            <Paper className={classes.infoPaper} elevation={1}>
                                {this.props.loading && "Please wait..."}
                                {this.props.step === 1 && !this.props.loading && (
                                    <span>
                                        <strong>Instructions:</strong> Your ready time (local to the shipper location). The time when your shipment can be ready for carrier to pickup. Restrictions may apply. Contact carrier for details.
                                    </span>
                                )}
                                {this.props.step === 2 && !this.props.loading && (
                                    <span>
                                        <strong>Instructions:</strong> Your close time (local to the shipper location). The time when your loading docks will close, the latest time a carrier will arrive. Restrictions may apply. Contact carrier for details.
                                    </span>
                                )}
                            </Paper>
                        </GridItem>
                        <GridItem xs={6} className={classes.center} style={{ marginTop: "20px", marginBottom: "20px" }}>
                            {this.props.step === 1 && (
                                <div>
                                    <InputLabel className={classes.label}>Pickup Date Time</InputLabel>
                                    <br />
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat
                                            value={this.props.pickupDateTime || ""}
                                            onChange={m => this.props.dateTimeChange("pickup_date_time", m)}
                                            className={classes.datetime}
                                            inputProps={{
                                                name: "pickup_date_time",
                                                placeholder: "Pickup Date Time"
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            )}
                            {this.props.step === 2 && !this.props.loading && (
                                <div>
                                    <InputLabel className={classes.label}>Dock Close Time</InputLabel>
                                    <br />
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat
                                            value={this.props.dockCloseTime || ""}
                                            onChange={m => this.props.dateTimeChange("dock_close_time", m)}
                                            className={classes.datetime}
                                            inputProps={{
                                                name: "dock_close_time",
                                                placeholder: "Dock Close Time"
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            )}
                            {(!_.isEmpty(this.props.shipperDateTime) && this.props.shipperDateTime != false) && (
                                <div>
                                    <br />
                                    <InputLabel className={classes.label}>Shipper's Local Time: {this.props.shipperDateTime.format('h:mm:ss a')}</InputLabel>
                                    <br />
                                </div>
                            )}
                            {this.props.step === 2 && this.props.loading && <CircularProgress />}
                        </GridItem>
                        <GridItem xs={12} className={classes.left}>
                            <Paper className={classes.warningPaper} elevation={1}>
                                <span>
                                    <strong>Notice:</strong> You will need to contact the carrier to make any changes or to cancel pickup in accordance with any policies they may have prior to the scheduled date and time.
                                </span>
                            </Paper>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {!this.props.loading && (
                        <Button onClick={() => this.props.resetPickup()} color="white">
                            Reset
                        </Button>
                    )}
                    {!this.props.loading && (
                        <Button onClick={() => this.props.onClose()} color="warning">
                            Cancel
                        </Button>
                    )}
                    {this.props.step === 1 && (
                        <Button onClick={() => this.props.nextStep()} color="linkedin">
                            Next
                        </Button>
                    )}
                    {this.props.step === 2 && !this.props.loading && (
                        <Button onClick={() => this.props.schedulePickup()} color="linkedin">
                            Schedule
                        </Button>
                    )}
                    {this.props.step === 2 && this.props.loading && (
                        <Button color="linkedin" style={{ lineHeight: 0 }}>
                            <CircularProgress size={16} style={{ color: "white" }} />
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(PickupRequestModal);