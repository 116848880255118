import React from "react";
import { withSnackbar } from "notistack";
import _ from "lodash";

import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";

import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Alert from "@material-ui/lab/Alert";

import Button from "~/components/CustomButtons/Button.jsx";
import ReportBuilding from "../components/ReportTabs/ReportBuilding.jsx";
import ScheduledReport from "../components/ReportTabs/ScheduledReport.jsx";
import ScheduleHistoryLog from "../components/ReportTabs/ScheduleHistoryLog.jsx";
import SelectReportModal from "~/views/CustomReporting/components/SelectReportModal.jsx";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newReportRun: false,
      mounted: false,
      show: false,
      loading: true,
      redirect: null,
      body: null,
      user: null,
      alert: null,
      modal: false,
      tabIndex: 0,
      reportType: "invoice",
      reportBehavior: "summary",
      reportTypes: null,
      wizard: false,
      showWarning: true,
    };
  }

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleModalOpen = () => {
    this.setState({ modal: true });
  }

  handleModalClose = () => {
    this.setState({ modal: false });
    this.setState({ reportBehavior: "" });
    this.setState({ reportType: "" });
  }

  handleTabChange = (event, index) => {
    this.setState({ tabIndex: index,newReportRun:false });
  }

  async componentDidMount() {
    this.setState({ mounted: true,newReportRun:localStorage.getItem('NewReportRun') });
    this.getReportTypeData();
     (this.state.newReportRun);
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  getReportTypeData = async () => {
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportype&m=index"
      );
      const data = response.data;
      if (typeof data !== "string" && data.body && data.user) {
        this.setState({
          show: true,
          loading: false,
          body: response.data.body,
          user: response.data.user,
          reportTypes: response.data.body.result,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error loading the report type!",
          {
              variant: "warning",
          }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error loading the search types!",
        {
            variant: "warning",
        }
      );
    }
  }

  selectReport = async () => {
    this.setState({
      loading: false,
      redirect: (
        <Redirect
          to={
            basePath +
            "/admin/report-build/new/" +
            this.state.reportType +
            "/" +
            this.state.reportBehavior
          }
        />
      ),
    });
  }

  handleClose = () => {
		this.setState({ showWarning: false });
	}

  render() {
    const { classes } = this.props;

    if (this.state.redirect) {
      return this.state.redirect;
    }
    return (
      <>
        {this.state.showWarning && (
          <Alert severity="info" onClose={this.handleClose}>
            Any reports ran here should not be used when remitting payment, please refer to the weekly file that is sent by your auditor.
          </Alert>
        )}
        <Tabs
          value={this.state.tabIndex}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Report" />
          <Tab label="Scheduled Report" />
          <Tab
            label={
              <Badge classes={{ badge: classes.customBadge }} variant="dot" invisible={this.state.newReportRun==="true"?false:true}>
                History Log
              </Badge>
            }
          />
          <Button size="sm" style={{marginLeft: 'auto'}} onClick={this.handleModalOpen} color="linkedin">
            Create Report
          </Button>
        </Tabs>

        <SelectReportModal
          open={this.state.modal}
          onClose={() => this.handleModalClose()}
          handleSelect={(e) => this.handleSelect(e)}
          handleChange={(e) => this.handleChange(e)}
          reportType={this.state.reportType}
          reportBehavior={this.state.reportBehavior}
          selectReport={() => this.selectReport()}
          loading={this.state.loading}
          reportTypes={this.state.reportTypes}
          wizard={this.state.wizard}
        />

        {this.state.tabIndex == 0 && (
          <>
            <ReportBuilding
              modal={this.state.modal}
              handleModalClose={this.handleModalClose}
            />
          </>
        )}
        {this.state.tabIndex == 1 && (
          <>
            <ScheduledReport />
          </>
        )}
        {this.state.tabIndex == 2 && (
          <>
            <ScheduleHistoryLog />
          </>
        )}
      </>
    );
  }
}

export default withStyles(style)(withSnackbar(ReportsContainer));
