import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Permissions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null
		};
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=roleManagement&m=permissions"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an unexpected response from the server while retrieving permissions!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error while retrieving permissions!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	getHeaders() {
		return ["ID", "Name", "Description"];
	}
	getColumns(data) {
		return data.map(prop => {
			return [
				prop.id,
				prop.name,
				prop.description,
			];
		});
	}


	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(this.state.body)}
												customCellClasses={[classes.right]}
												customClassesForCells={[3]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[3]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{
														"No permissions to display"
													}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve permissions from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(Permissions));
