import {
	defaultFont,
	uiColors,
	makeBoxShadow,
	blackBoxShadow,
	whiteColor,
	blackColor,
	grayColor,
	hexToRgb
} from "assets/jss/empire-tms.jsx";

function makeSnackbarColor(color) {
	return {
		backgroundColor: color,
		color: whiteColor,
		...makeBoxShadow(color)
	};
}

const snackbarContentStyle = {
	root: {
		...defaultFont,
		flexWrap: "unset",
		position: "relative",
		padding: "20px 15px",
		lineHeight: "20px",
		marginBottom: "20px",
		fontSize: "14px",
		backgroundColor: whiteColor,
		color: grayColor[6],
		borderRadius: "3px",
		boxShadow:
			"0 12px 20px -10px rgba(" +
			hexToRgb(whiteColor) +
			", 0.28), 0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 7px 8px -5px rgba(" +
			hexToRgb(whiteColor) +
			", 0.2)"
	},
	top20: {
		top: "20px"
	},
	top40: {
		top: "40px"
	},
	info: makeSnackbarColor(uiColors.info.dark),
	success: makeSnackbarColor(uiColors.success.dark),
	warning: makeSnackbarColor(uiColors.warning.mid),
	danger: makeSnackbarColor(uiColors.danger.dark),
	primary: makeSnackbarColor(uiColors.primary.dark),
	rose: makeSnackbarColor(uiColors.rose.dark),
	white: {
		backgroundColor: whiteColor,
		color: blackColor,
		...blackBoxShadow
	},
	message: {
		padding: "0",
		display: "block",
		maxWidth: "89%"
	},
	close: {
		width: "11px",
		height: "11px"
	},
	iconButton: {
		width: "24px",
		height: "24px",
		padding: "0"
	},
	icon: {
		width: "38px",
		height: "38px",
		display: "block",
		left: "15px",
		position: "absolute",
		//marginTop: "-39px",
		fontSize: "20px",
		backgroundColor: whiteColor,
		padding: "9px",
		borderRadius: "50%",
		maxWidth: "38px",
		boxShadow:
			"0 10px 30px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.42), 0 4px 25px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)"
	},
	infoIcon: {
		color: uiColors.info.dark
	},
	successIcon: {
		color: uiColors.success.dark
	},
	warningIcon: {
		color: uiColors.warning.dark
	},
	dangerIcon: {
		color: uiColors.danger.dark
	},
	primaryIcon: {
		color: uiColors.primary.dark
	},
	roseIcon: {
		color: uiColors.rose.dark
	},
	iconMessage: {
		paddingLeft: "50px",
		display: "block"
	}
};

export default snackbarContentStyle;
