import React from "react";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

import OptionListView from "./selectOption/OptionListView.jsx";
import SearchByChip from "~/views/TMS/SearchByChip.jsx";

class StandardCarrierFilterPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mounted: false,
      show: false,
      loading: true,
      StandardCarrierInvoice: "ProNumber",
      proNum: "",
      invoiceNum: "",
      startDate: new Date(),
      endDate: new Date(),
      options: [],
      selectedOptions: [
        {
          field: "pro_number",
          title: "Pro#",
          width: "120px",
          locked: true,
        },
        {
          field: "invoice_number",
          title: "Invoice#",
          width: "110px",
        },
        {
          field: "company_name",
          title: "Invoice Customer",
          width: "150px",
        },
        {
          field: "origin_name",
          title: "Origin Customer",
          width: "150px",
        },
        {
          field: "origin_city",
          title: "Origin City",
          width: "120px",
        },
        {
          field: "origin_state",
          title: "Origin State",
          width: "120px",
        },
        {
          field: "origin_postal_code",
          title: "Origin Zip",
          width: "120px",
        },
        {
          field: "origin_country_code",
          title: "Origin Country",
          width: "150px",
        },
        {
          field: "destination_name",
          title: "Destination Customer",
          width: "180px",
        },
        {
          field: "destination_city",
          title: "Destination City",
          width: "160px",
        },
        {
          field: "destination_state",
          title: "Destination State",
          width: "150px",
        },
        {
          field: "destination_postal_code",
          title: "Destination Zip",
          width: "140px",
        },
        {
          field: "destination_country_code",
          title: "Destination Country",
          width: "180px",
        },
        {
          field: "invoice_date",
          title: "Invoice Date",
          width: "120px",
        },
        {
          field: "date_posted",
          title: "Process date",
          width: "130px",
        },
        {
          field: "payment_date",
          title: "Paid Date",
          width: "130px",
        },
        {
          field: "cust_to_carrier_check_info",
          title: "Customer Payment Info",
          width: "180px",
        },
        {
          field: "adj_note",
          title: "Adjustment Notes",
          width: "180px",
        }
      ],

      unSelected: [
        {
          field: "company_name",
          title: "Invoice Customer",
          width: "150px",
        },
        {
          field: "origin_name",
          title: "Origin Customer",
          width: "150px",
        },
        {
          field: "origin_city",
          title: "Origin City",
          width: "120px",
        },
        {
          field: "origin_state",
          title: "Origin State",
          width: "120px",
        },
        {
          field: "origin_postal_code",
          title: "Origin Zip",
          width: "120px",
        },
        {
          field: "origin_country_code",
          title: "Origin Country",
          width: "150px",
        },
        {
          field: "destination_name",
          title: "Destination Customer",
          width: "180px",
        },
        {
          field: "destination_city",
          title: "Destination City",
          width: "160px",
        },
        {
          field: "destination_state",
          title: "Destination State",
          width: "150px",
        },
        {
          field: "destination_postal_code",
          title: "Destination Zip",
          width: "140px",
        },
        {
          field: "destination_country_code",
          title: "Destination Country",
          width: "180px",
        },
        {
          field: "invoice_date",
          title: "Invoice Date",
          width: "120px",
        },
        {
          field: "date_posted",
          title: "Process date",
          width: "130px",
        },
        {
          field: "payment_date",
          title: "Paid Date",
          width: "130px",
        },
        {
          field: "cust_to_carrier_check_info",
          title: "Customer Payment Info",
          width: "180px",
        },
        {
          field: "adj_note",
          title: "Adjustment Notes",
          width: "180px",
        }
      ],


      tags: []
    }


    this.handleValue = this.handleValue.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.changeTagsList = this.changeTagsList.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.replacer = this.replacer.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }


  async componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }


  handleValue(name, value) {
    this.setState({
      [name]: value.target.value,
    })
  }

  handleInput = name => event => {
    this.setState({ [name]: event.target.value });
  };


  replacer(key, value) {
    if (typeof value === 'function') {
      return value.toString()
    }
    return value
  }




  handleSearch = (event) => {



    const start_date = this.state.startDate;
    const end_date = this.state.endDate;


    if ((this.state.StandardCarrierInvoice === "InvoiceDate") || (this.state.StandardCarrierInvoice === "ShipDate") || (this.state.StandardCarrierInvoice === "DeliveryDate")) {

      {
        if (start_date == null || end_date == null) {
          this.props.setNotification("date must be provided", { variant: "warning" });
          event.preventDefault();
          return false;
        }

        else if (!(moment(start_date, 'MM-DD-YYYY').isValid()) || !(moment(end_date, 'MM-DD-YYYY').isValid())) {
          this.props.setNotification("Invalid date, The date format is MM/DD/YYYY!", { variant: "warning" });
          event.preventDefault();
          return false;

        }

        else if (start_date > end_date) {
          this.props.setNotification("The start date must be less than the end date!", { variant: "warning" });
          event.preventDefault();
          return false;

        }
      }


    }

    else if (this.state.StandardCarrierInvoice === "ProNumber" && this.state.tags.length === 0) {
      this.props.setNotification("Pro number must be provided", { variant: "warning" });
      event.preventDefault();
      return false;
    }


    else if (this.state.StandardCarrierInvoice === "InvoiceNumber" && this.state.tags.length === 0) {
      this.props.setNotification("invoice number must be provided", { variant: "warning" });
      event.preventDefault();
      return false;
    }


  }



  async clearFilters() {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      StandardCarrierInvoice: "ProNumber",

    })



    if (this.state.StandardCarrierInvoice === "ProNumber" || this.state.StandardCarrierInvoice === "InvoiceNumber") {
      this.setState({ tags: [] });
    }
  }


  async clearAllFilters() {

    this.setState({ tags: [] });

  }

  handleDateChange = (name, moment) => {
    this.setState(
      {
        [name]: moment
      }
    )
  };




  handleOptions(value) {
    this.setState({ selectedOptions: value });
  }

  changeTagsList(value) {
    this.setState({ tags: value });
  };

  handleSelected(value) {
    var col = [];
    this.setState(
      {
        options: value,
      },
      () => {
        if (this.props.handleOptionState) {
          this.props.handleOptionState(this.state);
        }
      }
    );

    this.setState({ options: value });
    value.map((prop, key) => {
      col.push(this.state.selectedOptions[prop]);
    }, {});

    if (this.props.handleOptionSelected) {
      this.props.handleOptionSelected(value);
    }

    const sel = this.state.selectedOptions.filter(

      (op) => op.title !="Pro#" &&  op.title !="Invoice#" && !value.includes(op.title)
    );


    this.setState({ unSelected: sel })

    let left_search_fields = []
    sel.forEach(element => left_search_fields.push(element.field));


  }


  render() {
    const { classes } = this.props;
    const { StandardCarrierInvoice } = this.state;
    return (

      <GridContainer style={{ paddingLeft: "40px" }}>
        <GridContainer >
          <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "30px" }}>
            <InputLabel fontWeight="bold" className={classes.label}>

              Bill Search by

            </InputLabel>
            <FormControl
              style={{ paddingTop: "10px" }}
              fullWidth
              className={classes.selectFormControl}
            >
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={this.state.StandardCarrierInvoice}
                onChange={(m) =>
                  this.handleValue("StandardCarrierInvoice", m)
                }
                inputProps={{
                  id: "StandardCarrierInvoice",
                  name: "StandardCarrierInvoice",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="ProNumber"
                >
                  Pro Number
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="InvoiceNumber"
                >
                  Invoice Number
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="InvoiceDate"
                >
                  Invoice Date
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="DeliveryDate"
                >
                  Delivery Date
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="ShipDate"
                >
                  Ship Date
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>




        </GridContainer>


        <GridContainer>
          {['InvoiceDate','DeliveryDate','ShipDate'].includes(StandardCarrierInvoice) ? (
            <GridContainer style={{ paddingLeft: "20px" }}>
              <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "30px" }}>
                <InputLabel fontWeight="bold" className={classes.label} >
                  Select Invoice Date Range
                </InputLabel>
              </GridItem>
              <GridContainer style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                <GridItem >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="none"
                      id="date-picker-inline"
                      label="From"
                      placeholder="MM-DD-YYYY"
                      value={this.state.startDate}
                      onChange={m => this.handleDateChange("startDate", m)}
                      KeyboardButtonProps={{'aria-label':'change date'}}
                      InputProps={{style:{fontSize: 14}}}
                      style={{ width: "300px" }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem >
                  <MuiPickersUtilsProvider style={{ width: "100px", height: "44px", }} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="none"
                      id="date-picker-inline"
                      label="To"
                      placeholder="MM-DD-YYYY"
                      value={this.state.endDate}
                      onChange={m => this.handleDateChange("endDate", m)}
                      KeyboardButtonProps={{'aria-label':'change date'}}
                      InputProps={{style:{fontSize: 14}}}
                      style={{ width: "300px" }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
              </GridContainer>
            </GridContainer>
          ) : (
            <GridContainer style={{ paddingLeft: "15px" }}>
              <GridItem sm={12} md={5} style={{ paddingTop: "30px" }}>
                <SearchByChip
                  inputChipLabel={`Enter ${StandardCarrierInvoice === `InvoiceNumber` ? `Invoice` : `PRO`} Numbers *`}
                  placeholder={`Enter ${StandardCarrierInvoice === 'InvoiceNumber' ? `Invoice` : `PRO`} Number then press enter`}
                  searchValues={this.state.tags}
                  changeSearchValues={this.changeTagsList}
                  clearAllSearchValues={this.clearAllFilters}
                />
              </GridItem>
            </GridContainer>
          )}
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ paddingTop: "30px" }}>
            <OptionListView
              options={this.state.options}
              selectedOptions={this.state.selectedOptions}
              handleSelected={this.handleSelected}
              handleOptions={this.handleOptions}
            />
          </GridItem>
        </GridContainer>


        <GridContainer style={{ paddingTop: "50px" }}>
          <GridItem>
            <Button size="md" color="white" onClick={this.clearFilters}>  <ClearAll /> Clear </Button>
          </GridItem>

          <GridItem>
            {(this.state.StandardCarrierInvoice === "InvoiceDate") ?
              <Link to={{
                pathname: basePath + "/admin/carrier-Standard-report-invoice-date/search",

                state: {
                  startDate: this.state.startDate,
                  endDate: this.state.endDate,
                  unSelected: this.state.unSelected,
                },
              }} >
                <Button size="md" color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
              </Link> :
              (this.state.StandardCarrierInvoice === "DeliveryDate") ?

                <Link to={{
                  pathname: basePath + "/admin/carrier-Standard-report-delivery-date/search",

                  state: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    unSelected: this.state.unSelected,

                  },
                }} >
                  <Button size="md" color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                </Link>
                : (this.state.StandardCarrierInvoice === "ShipDate") ?

                  <Link to={{
                    pathname: basePath + "/admin/carrier-Standard-report-ship-date/search",

                    state: {
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      unSelected: this.state.unSelected,
                    },
                  }} >
                    <Button size="md" color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                  </Link>
                  : (this.state.StandardCarrierInvoice === "InvoiceNumber") ?
                    <Link to={{
                      pathname: basePath + "/admin/carrier-Standard-report-invoice-num/search",

                      state:
                      {
                        invoiceNum: this.state.tags,
                        unSelected: this.state.unSelected,
                      },
                    }} >
                      <Button size="md" color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                    </Link>

                    :

                    <Link to={{
                      pathname: basePath + "/admin/carrier-Standard-report-pro-num/search",

                      state: {
                        proNum: this.state.tags,
                        unSelected: this.state.unSelected,
                      },
                    }} >
                      <Button size="md" color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                    </Link>

            }
          </GridItem>
        </GridContainer>
      </GridContainer>
    )

  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(StandardCarrierFilterPage)));