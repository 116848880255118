import React from "react";

import { NavLink } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class PermissionGroups extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			redirect: null,
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.deleteRole = this.deleteRole.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=roleManagement&m=index"
			);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.user)
			) {
				this.setState({
					show: true,
					loading: false,
					body: response.data.body,
					user: response.data.user,
					roles: Object.values(response.data.body)
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the roles!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the roles!",
				{ variant: "error" }
			);
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getHeaders() {
		return [
			"ID",
			"Name",
			"Permissions",
			"Actions"
		];
	}
	getColumns(data) {
		return data.map((prop, key) => {
			const link = basePath + "/admin/role/" + prop.id;
			return [
				prop.id,
				prop.name,
				Object.values(prop.permissions).join(", "),
				<div className="actions-right">
					<NavLink to={link}>
						<Button
							size="sm"
							color="info"
							style={{ marginRight: "4px" }}
						>
							View/Edit
						</Button>
					</NavLink>
					<Button
						size="sm"
						color="danger"
						style={{ marginRight: "4px" }}
						onClick={() => this.handleDelete(prop.id)}
					>
						Delete
					</Button>
				</div>
			];
		});
	}
	handleDelete(id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this role?"
					onConfirm={() => this.deleteRole(id)}
					onCancel={() => this.deleteRole()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async deleteRole(id) {
		this.setState({ alert: null });

		if (_.isEmpty(id)) {
			return;
		}

		let url = "/index.php?p=api&r=json&c=roleManagement&m=deleteRole&d=" + id;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				let roles = this.state.roles.filter((prop, key) => {
					return prop.id != id;
				});
				this.setState({ roles });
			} else {
				this.props.setNotification(
					"There was an error deleting the role!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error deleting the role!",
				{ variant: "error" }
			);
		}
	}
	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		const link = basePath + "/admin/role/new";
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<NavLink to={link}>
										<Button color="linkedin" style={{ marginRight: "5px" }}>
											New Role
										</Button>
									</NavLink>
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.roles) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(this.state.roles)}
												customCellClasses={[classes.right]}
												customClassesForCells={[3]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[3]}
											/>
										) : (
											<Badge color="warning">No Roles</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve permission groups from the server"
									/>
								)}
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(PermissionGroups));
