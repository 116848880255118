import React, { Component } from "react";
import WithTooltip from "../../../components/TMS/WithTooltip.jsx";

class IconButton extends Component {

    render() {
        const Icon = this.props.icon;
        return (
                <WithTooltip
                    content={this.props.tooltip}
                >
                    <Icon 
                        style={this.props.disabled ? {opacity: .7} : {cursor: "pointer"}} 
                        onClick={this.props.disabled ? () => {} : this.props.onClick}
                    />
                </WithTooltip>                                          
        );
    }
}

export default IconButton;