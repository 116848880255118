import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Footer from "~/components/Footer/Footer.jsx";
import routes from "~/routes.js";
import pagesStyle from "~/assets/jss/empire-tms/layouts/authStyle.jsx";
import { basePath, baseUrl, assetPath } from "~/variables/server.jsx";

const tfmBackground = baseUrl + assetPath + "/img/tfm-background.png";
class Pages extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			routes: [],
			pageTitle: "",
		};
	}
	async componentDidMount() {
		document.body.style.overflow = "unset";
		try {
			const session = await routes.getSession();
			if(session && session.data.user) {
				const isAdmin = session.data.user.level === "admin";
				this.setState({
					routes: routes.getRoutes(session.data.user),
					pageTitle: !_.isEmpty(this.props.pageTitle) ? this.props.pageTitle : this.activeRoute(routes.getRoutes(session.data.user)),
				});
			} else {
				this.setState({ routes: routes.getRoutes(false), pageTitle: !_.isEmpty(this.props.pageTitle) ? this.props.pageTitle : this.activeRoute(routes.getRoutes(session.data.user)) });
			}
		} catch (error) {
			console.error(error);
			this.setState({ routes: routes.getRoutes(false) });
		}
	}
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname && this.refs.mainPanel !== undefined) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false, pageTitle: !_.isEmpty(this.props.pageTitle) ? this.props.pageTitle : this.activeRoute(this.state.routes) });
            } else {
                this.setState({pageTitle: !_.isEmpty(this.props.pageTitle) ? this.props.pageTitle : this.activeRoute(this.state.routes)});
            }
        }
    }
	routes(routes) {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.routes(prop.views);
			}
			if (prop.layout === basePath + "/auth") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
						pageTitle={(pageTitle) => this.setState({ pageTitle: pageTitle })}
					/>
				);
			} else {
				return null;
			}
		});
	}
	activeRoute(routes) {
		let activeRoute = "TMS";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.activeRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(
						routes[i].layout + routes[i].path
					) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	}
	render() {
		const { classes, ...rest } = this.props;
		return (
			<div>
				<div className={classes.wrapper} ref="wrapper">
					<div
						className={classes.fullPage}
						style={{
							backgroundImage: `url(${tfmBackground})`
						}}
					>
						<Switch>{this.routes(this.state.routes)}</Switch>
						<Footer fluid white />
					</div>
				</div>
			</div>
		);
	}
}

Pages.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
