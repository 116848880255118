const initialState = {
	open: false,
	carrier: {},
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_RATE_DETAILS_MODAL_OPEN": {
			return {...newState, open: action.val};
		}
		case "SET_RATE_DETAILS_MODAL_CARRIER": {
			if (typeof action.val !== "object") action.val = {};
			return {...newState, carrier: {...action.val}};
		}
		default:
			return state;
	}
}