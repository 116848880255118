import React from "react";
import { connect } from "react-redux";
import { hasPermission, userIsAdmin, adminInUserSession } from "../../redux/selectors/Admin.jsx";
import _ from "lodash";
import qs from "qs";
import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newProductStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class EditHandlingUnit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			id: "",
			warehouse: "",
			current_warehouse: "",
			uom: "Pallet",
			label: "",
			stack: "0",
			pieces: "1",
			length: "",
			width: "",
			height: "",
			default: false,
			done: false,
			whMgtId: null,
		};
		this.getWarehouses = this.getWarehouses.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveHandlingUnit = this.saveHandlingUnit.bind(this);
	}

	async componentDidMount() {
		const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
		const d = parsed.d ? parsed.d.split("/") : [];
		if (_.isEmpty(d)) {
			this.setState({ loading: false });
			this.props.setNotification("Product ID and warehouse are required!", {
				variant: "error"
			});
			return;
		}
		const id = d[0];
		const current_warehouse = d[1];
		this.setState({
			mounted: true,
			id,
			current_warehouse,
			whMgtId: JSON.parse(localStorage.getItem("tms_wh_mgt_id")),
		});
		const url = "/index.php?p=api&r=json&c=handUnit&m=edit&d=" + id + "/" + encodeURIComponent(current_warehouse);
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.user)) {
				const unit = response.data.body.handUnit;
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					body: response.data.body,
					warehouse: unit.code || "",
					uom: unit.type || "Piece",
					label: unit.label || "",
					stack: unit.stack == 1 ? "1" : "0",
					pieces: unit.pieces || "1",
					length: unit.length || "",
					width: unit.width || "",
					height: unit.height || "",
					default: unit.default == 1
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
		localStorage.removeItem("tms_wh_mgt_id");
	}

	componentWillUnmount() {
		if(this.props.isAdminUser && this.state.whMgtId) {
			localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
		}
		this.setState({ mounted: false, whMgtId: null });
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	getWarehouses(warehouses) {
		const { classes } = this.props;
		return warehouses.map((warehouse, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={warehouse.code}
				>
					{warehouse.name}
				</MenuItem>
			);
		});
	}

	getUnits(units) {
		const { classes } = this.props;
		return units.map((unit, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={unit.name}
				>
					{unit.name}
				</MenuItem>
			);
		});
	}

	async saveHandlingUnit() {
		this.setState({
			show: true,
			loading: true
		});

		const { id, warehouse, uom, label, stack, pieces, length, width, height } = this.state;

		const data = {
			warehouse,
			uom,
			label,
			stack,
			pieces,
			length,
			width,
			height
		};

		if (this.state.default) {
			data.default = "1";
		}

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=handUnit&m=edit&d=" + id + "/" + warehouse, qs.stringify(data));
			if (response.data) {
				this.props.handleMessages(response);
				if (response.data.body) {
					this.setState({
						loading: false,
						done: true
					});
				} else if(_.isEmpty(response.data.message)) {
					this.setState({ loading: false });
					this.props.setNotification("There was an error saving the handling unit!", {
						variant: "error"
					});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the handling unit!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error saving the handling unit!", {
				variant: "error"
			});
		}
	}

	render() {
		if (this.state.done) {
			let redirectTo = basePath;
			if(this.props.isAdminUser && this.state.whMgtId) {
				this.setState({whMgtId: null});
				localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
				redirectTo += "/admin/company/" + this.state.body.warehouses[0].company_name_id;
			} else {
				redirectTo += "/admin/handling-unit-catalog";
			}
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel htmlFor="warehouse" className={classes.label}>
										Warehouse/Facility
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.warehouse}
											inputProps={{
												name: "warehouse",
												id: "warehouse"
											}}
											onChange={this.handleChange}
											readOnly={!this.props.hasWrite}
										>
											{this.state.body && !!this.state.body.warehouses.length ? (
												this.getWarehouses(this.state.body.warehouses)
											) : (
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem
													}}
												>
													No Warehouses
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="uom" className={classes.label}>
										UoM
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{ select: classes.select }}
											value={this.state.uom}
											inputProps={{
												name: "uom",
												id: "uom"
											}}
											onChange={this.handleChange}
											readOnly={!this.props.hasWrite}
										>
											{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.units) ? (
												this.getUnits(this.state.body.units)
											) : (
												<MenuItem disabled classes={{ root: classes.selectMenuItem }}>
													No Units
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="label" className={classes.label}>
										Handling Unit Label
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											id: "label",
											name: "label",
											value: this.state.label,
											onChange: this.handleInput("label"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="stack" className={classes.label}>
										Stackable
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{
												select: classes.select
											}}
											value={this.state.stack}
											inputProps={{
												name: "stack",
												id: "stack"
											}}
											onChange={this.handleChange}
											readOnly={!this.props.hasWrite}
										>
											<MenuItem classes={{ root: classes.selectMenuItem }} value="0">
												No
											</MenuItem>
											<MenuItem classes={{ root: classes.selectMenuItem }} value="1">
												Yes
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.default}
												onChange={this.handleChecked("default")}
												disabled={!this.props.hasWrite}
											/>
										}
										classes={{
											label: classes.label
										}}
										label="Default"
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs>
									<InputLabel htmlFor="pieces" className={classes.label}>
										Quantity
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "number",
											id: "pieces",
											name: "pieces",
											value: this.state.pieces,
											onChange: this.handleInput("pieces"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="length" className={classes.label}>
										Length
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "length",
											name: "length",
											value: this.state.length,
											onChange: this.handleInput("length"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="width" className={classes.label}>
										Width
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "width",
											name: "width",
											value: this.state.width,
											onChange: this.handleInput("width"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="height" className={classes.label}>
										Height
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "height",
											name: "height",
											value: this.state.height,
											onChange: this.handleInput("height"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								{this.props.hasWrite && <GridItem xs>
									<Button color="linkedin" onClick={this.saveHandlingUnit}>
										Save Handling Unit
									</Button>
								</GridItem>}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	const isAdminUser = userIsAdmin(state) && !adminInUserSession(state);
	return {
		hasWrite: hasPermission("HANDLING_UNIT_WRITE")(state) || isAdminUser,
		isAdminUser: isAdminUser
	};
}

export default connect(mapStateToProps)(withStyles(newProductStyle)(withSnackbar(EditHandlingUnit)));