import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import moment from "moment";
import Datetime from "react-datetime";

// material ui
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import FilterQueryModal from "~/components/FilterQueryModal/components/FilterQueryModal.jsx";
import allActions from "../../../components/FilterQueryModal/actions/FilterQueryModal.jsx";
import { setNotification } from "../../../redux/actions/notifications.jsx";

import { 
	setLoadBoardFilters,
} from '../actions/InternationalLoadBoard.jsx';

// Fields that use like when preforming simple search.
const simpleFiltersLike = [
	"shipper_name",
	"shipper_addr1",
	"shipper_city",
	"shipper_state",
	"shipper_country",
	"consignee_name",
	"consignee_addr1",
	"consignee_city",
	"consignee_state",
	"consignee_country",
	"carrier",
	"origin_port",
	"dest_port",
	"vehicle_name",
	"notes",
	"hs_code",
	"seal_num",
	"container_num",
];

class InternationalLoadBoardFilters extends Component {
	state = {
		simpleFilters: []
	}

	getFilters = (loadBoardFilterOptions) => {
		if(_.isEmpty(this.state.simpleFilters)) {
			this.addFilter()
		}
		const transitStatusOptions = this.props.transitStatusOptions;
		const warehouseOptions = this.props.warehouseOptions;
		return this.state.simpleFilters.map((filter, key) => {
			const showClear = key == 0 && (this.props.loadBoardFilters.length);
			const isDate = filter.type == "Date";
			return (
				<GridContainer>
					<GridItem xs={5} sm={5} md={5}>
						<FormControl fullWidth>
							<Select
								value={filter.field}
								inputProps={{
									name: "filter",
								}}
								onChange={(e) => this.setFilter(e, key)}
							>
								{loadBoardFilterOptions.map((option, index) => {
									return (
										<MenuItem
											key={`${option.accessor}_${index}`}
											value={option.accessor}
										>
											{option.headerDisplayName}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</GridItem>
					<GridItem xs={7} sm={7} md={7}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<GridContainer>
									<GridItem xs={8} sm={8} md={8}>
										{isDate ? (
											<FormControl fullWidth>
												<Datetime
													dateFormat={"MM/DD/YYYY"}
													timeFormat={false}
													value={filter.param}
													onChange={m => this.handleDatetimeFilters(key, "param1", m)}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
												/>
											</FormControl>
										) : filter.field == "warehouse" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													{warehouseOptions.map((option, index) => {
														return (
															<MenuItem
																key={`${option.name}_${index}`}
																value={option.name}
															>
																{option.name}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										) : filter.field == "direction" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={"import"}
													>
														Import
													</MenuItem>
													<MenuItem
														value={"export"}
													>
														Export
													</MenuItem>
												</Select>
											</FormControl>
										) : filter.field == "shipping_mode" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={"ocean"}
													>
														Ocean
													</MenuItem>
													<MenuItem
														value={"air"}
													>
														Air
													</MenuItem>
												</Select>
											</FormControl>
										) : filter.field == "container_load" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={""}
													>
														None
													</MenuItem>
													<MenuItem
														value={"FCL"}
													>
														FCL
													</MenuItem>
													<MenuItem
														value={"LCL"}
													>
														LCL
													</MenuItem>
												</Select>
											</FormControl>
										) : filter.field == "status" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={"hold"}
													>
														Hold
													</MenuItem>
													<MenuItem
														value={"executed"}
													>
														Executed
													</MenuItem>
												</Select>
											</FormControl>
										) : filter.field == "uom_option" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={"imperial"}
													>
														Imperial
													</MenuItem>
													<MenuItem
														value={"metric"}
													>
														Metric
													</MenuItem>
												</Select>
											</FormControl>
										) : filter.field == "container_size" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={"20"}
													>
														20
													</MenuItem>
													<MenuItem
														value={"40"}
													>
														40
													</MenuItem>
													<MenuItem
														value={"over_size"}
													>
														Oversize Other
													</MenuItem>
												</Select>
											</FormControl>
										) : filter.field == "transit_status" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													{transitStatusOptions.map((option, index) => {
														if(!option.parent) {
															return (
																<MenuItem
																	key={`${option.status}_${index}`}
																	value={option.status}
																>
																	{option.description}
																</MenuItem>
															);
														}
													})}
												</Select>
											</FormControl>
										) : filter.field == "transit_sub_status" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													{transitStatusOptions.map((option, index) => {
														if(option.parent) {
															return (
																<MenuItem
																	key={`${option.status}_${index}`}
																	value={option.status}
																>
																	{option.description}
																</MenuItem>
															);
														}
													})}
												</Select>
											</FormControl>
										) : filter.field == "intl_ship_level_id" ? (
											<FormControl fullWidth>
												<Select
													value={filter.param1}
													inputProps={{
														name: "filter_value",
														onKeyPress: e => this.handleKeyPress(e),
													}}
													onChange={(e) => this.setFilter(e, key)}
												>	
													<MenuItem
														value={"1"}
													>
														Low
													</MenuItem>
													<MenuItem
														value={"2"}
													>
														Medium
													</MenuItem>
													<MenuItem
														value={"3"}
													>
														High
													</MenuItem>
												</Select>
											</FormControl>
										) : (
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "filter_value",
													value:  filter.param1 || "",
													onChange: e => this.setFilter(e, key),
													onKeyPress: e => this.handleKeyPress(e),
												}}
												white
											/>
										)}
									</GridItem>
									<GridItem xs={4} sm={4} md={4} style={{ alignContent: "space-between"}}>
										{key == 0 ? (
											<>
												<Button justIcon round size="sm" color="success" onClick={() => this.addFilter()}>
													<Add />
												</Button>
												<Button justIcon round size="sm" color="linkedin" style={{marginLeft: '5px'}} onClick={() => this.handleSearch()}>
													<Search />
												</Button>
												{!!showClear && (
													<Button justIcon round size="sm" color="white" style={{marginLeft: '5px'}} onClick={() => this.clearFilters()}>
														<ClearAll />
													</Button>
												)}
											</>
										) : (
											<Button justIcon round size="sm" color="danger" onClick={() => this.deleteFilter(key)}>
												<Close />
											</Button>
										)}
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			)
		})
	}

	addFilter = () => {
		const filters = this.state.simpleFilters;
		const filter = this.props.loadBoardFilterOptions.at(0);

		filters.push({field: filter.accessor, operator: "equals", type: filter.type, param1: ""});
		this.setState({ simpleFilters: filters});
	}

	setFilter = (e, i) => {
		const allFilters = this.props.loadBoardFilterOptions;
		const filters = this.state.simpleFilters;
		let filter = filters[i];

		if(e.target.name == "filter") {
			for(const el of allFilters) {
				if(e.target.value == el.accessor) {
					const simpleOperator = simpleFiltersLike.includes(el.accessor) ? "like" : "equals"
					filter = {field: el.accessor, operator: simpleOperator, type: el.type, param1: el.value};
					break
				}
			}
		} else if(e.target.name == "filter_value") {
			filter.param1 = e.target.value;
		}
		filters[i] = filter;
		this.setState({ simpleFilters: filters});
	}

	handleKeyPress = e => {
		if (e.key === "Enter") this.handleSearch();
	};

	handleDatetimeFilters(key, name, m) {
		const filters = this.state.simpleFilters;
		filters[key][name] = moment(m).format("YYYY-MM-DD");
		this.setState({ simpleFilters: filters});
	}

	handleSearch = () => {
		const filters = this.state.simpleFilters;
		let invalidSearch = "";

		for(const el of filters) {
			if(simpleFiltersLike.includes(el.field) && !el.param1.trim().length) {
				invalidSearch = el.field;
				break;
			}
		}

		if(!invalidSearch.length) {
			this.props.search(filters);
		} else {
			this.props.setNotification(`Invalid search value set for ${invalidSearch}`, {variant: "error"});
		}
	}

	deleteFilter = (i) => {
		const filters = this.state.simpleFilters;
		filters.splice(i, 1);
		this.setState({ simpleFilters: filters});

		if(this.props.loadBoardFilters.length) {
			this.handleSearch();
		}
	}

	updateComplexFilters = (filters) => {
		this.setState({ simpleFilters: []});
		this.props.search(filters);
	}

	clearFilters = () => {
		this.setState({ simpleFilters: []});
		this.props.search([]);
	}

	handlePresetSelect = (id) => {
		const presets = this.props.loadBoardPresetFilters;
		for(const preset of presets) {
			if(preset.id == id) {
				let filters = JSON.parse(preset.filters);
				this.updateComplexFilters(filters);
				break;
			}
		}
	}

	getAppliedPreset = () => {
		if (this.props.loadBoardFilters.length) {
			const presets = this.props.loadBoardPresetFilters;
			const appliedFilters = JSON.stringify(this.props.loadBoardFilters);
			for(const preset of presets) {
				if(preset.filters === appliedFilters) {
					return preset;
				}
			}
		}
		return null;
	}

	setComplexFilterOptions = (text) => {
		const warehouseOpts = this.props.warehouseOptions.map(item => {return item.name.trim()}).sort();
		switch(text) {
            case "Warehouse":
                this.props.setOptions(warehouseOpts);
                break;
            case "Import/Export":
                this.props.setOptions([
                    "import",
                    "export",
                ]);
                break;
            case "Ocean/Air":
                this.props.setOptions([
                    "ocean",
                    "air"
                ]);
                break;
            case "Status":
                this.props.setOptions([
                    "hold",
                    "executed"
                ]);
                break;
            case "Container Size":
                this.props.setOptions([
                    "20",
                    "40",
                    "over_size"
                ]);
                break;
            case "UOM":
                this.props.setOptions([
                    "imperial",
                    "metric"
                ]);
                break;
            case "Transit Status":
            case "Transit Sub Status":
				const transitOpts = this.props.transitStatusOptions.map(item => {return item.status.trim()}).sort();
                this.props.setOptions(transitOpts);
                break;
			default:
				this.props.setOptions(warehouseOpts);
				break;
        }
	}

	render() {
		const appliedPreset = this.getAppliedPreset();
		const loadBoardFilterOptions = this.props.loadBoardFilterOptions;
		return(
			<GridContainer>
				<CardBody>
					<GridItem xs={12} sm={12} md={12}>
						{!!appliedPreset && (
							<small style={{color: "gray" }}>{`Preset applied: ${appliedPreset.name}`}</small>
						)}
						<GridContainer style={{display: "flex", justifyContent: "space-between"}}>
							<GridItem style={{padding: 0 }} hidden>
								<FilterQueryModal
									headerData={loadBoardFilterOptions}
									filters={this.props.loadBoardFilters}
									clearPreset={this.clearFilters}
									updateFilters={this.updateComplexFilters}
									setFilterOptions={(text) => this.setComplexFilterOptions(text)}
									fieldsWithLimitedOperators={this.props.fieldsWithLimitedOperators}
									fieldsWithAutoComplete={this.props.fieldsWithAutoComplete}
									fieldsWithDate={this.props.fieldsWithDate}
									optionsLoading={false}
								/>
							</GridItem>
							{!!this.props.loadBoardPresetFilters.length && (
								<GridItem hidden>
									<Select
										value=""
										displayEmpty
										onChange={e => {this.handlePresetSelect(e.target.value)}}
										style={{padding: 0}}
									>	
										<MenuItem value="" disabled>
											Filter Presets
										</MenuItem>
										{this.props.loadBoardPresetFilters.map((preset, key) => {
											return (
												<MenuItem key={key} value={preset.id}>
													<div style={{maxWidth: "300px", padding: "5px"}}>
														<div><b>{preset.name}</b></div>
														{typeof preset.description === "string" && preset.description.length ? (
															<div style={{whiteSpace: "normal"}}>{preset.description}</div>
														) : null}
													</div>
												</MenuItem>
											)
										})}
									</Select>
								</GridItem>
							)}
							<GridItem xs={12} sm={12} md={8}>
								{!_.isEmpty(loadBoardFilterOptions) &&(
									this.getFilters(loadBoardFilterOptions)
								)}
							</GridItem>
							<GridItem xs={2} sm={2} md={2} style={{display: "flex", justifyContent: "center"}}>
								<Button color="success" size="sm" onClick={() => this.props.export()} >
									EXPORT TO CSV
								</Button>
							</GridItem>
						</GridContainer>
					</GridItem>
				</CardBody>
			</GridContainer>
		)
	}
}

const mapStateToProps = state => {
	return {
		loadBoardFilterOptions: state.InternationalLoadBoard.loadBoardFilterOptions,
		loadBoardFilters: state.InternationalLoadBoard.loadBoardFilters,
		loadBoardPresetFilters: state.InternationalLoadBoard.loadBoardPresetFilters,
		isComplexFilters: state.InternationalLoadBoard.isComplexFilters,
		transitStatusOptions: state.InternationalLoadBoard.transitStatusOptions,
		warehouseOptions: state.InternationalLoadBoard.warehouseOptions,
		fieldsWithLimitedOperators: state.InternationalLoadBoard.fieldsWithLimitedOperators,
		fieldsWithAutoComplete: state.InternationalLoadBoard.fieldsWithAutoComplete,
		fieldsWithDate: state.InternationalLoadBoard.fieldsWithDate,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setLoadBoardFilters,
		setNotification,
		...allActions,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InternationalLoadBoardFilters);
