import axios from 'axios';
import qs from "qs";

export default class YatClient {
    static EquipmentCodes = [
        "DV_53",
        "DV_48",
        "DV_45",
        "DV_43",
        "DV_40",
        "DV_28_RD",
        "DV_28_SD",
        "CONT_20",
        "CONT_40",
        "CONT_45",
        "CONT_48",
        "CONT_53",
        "FB_26",
        "FB_40",
        "FB_45",
        "FB_48",
        "STRGHT_TRK_22",
        "STRGHT_TRK_26"
    ];

    static PieceArrangementAlgorithms = [
        "NAIVE",
        "GREEDY_STACK"
    ];

    static ShipmentArrangementAlgorithms = [
        "NAIVE",
        "NO_STACK_BIN_PACK",
        "SLIDE_BACK",
        "GREEDY_LOAD"
    ];

    constructor(url) {
        this.source = axios.CancelToken.source();
        this.requestActive = false;
        this.url = url;
    }

    setURL(url) {
        this.url = url;
    }

    /**
     * Format a shipment to ensure it meets the required structure.
     *
     * @param {Object} shipment
     *
     * @return {Object|boolean}
     */
    static formatShipment(shipment) {
        if (typeof shipment !== 'object') {
            return false;
        }
        let prodWeight = shipment.products.map(prod => prod.weight);
        let weight = 0;
        if(prodWeight.length) {
            weight = shipment.products.map(prod => prod.weight).reduce((prev, next) => parseInt(prev) + parseInt(next));
        }
        return {
            num_pieces: parseInt(shipment.pieces),
            length: parseFloat(shipment.length),
            width: parseFloat(shipment.width),
            height: parseFloat(shipment.height),
            weight:  (isNaN(parseInt(weight)) || parseInt(weight) < 0) ? 1 : parseInt(weight),
            packing: shipment.type === 'Pallet' ? 'PALLET' : 'BOX',
            stack_limit: (shipment.stackable == 'true' || shipment.stackable == 1) ? 999 : 1
        };
    }

    async optimizeTrailerLoad(shipments, equipmentCode, trailerDims = {}, allowRotations = false, arrangmentAlgorithm, shipmentOptimization) {
        trailerDims = {
            inner_length: parseFloat(trailerDims.inner_length) || 336,
            inner_width: parseFloat(trailerDims.inner_width) || 96,
            inner_height: parseFloat(trailerDims.inner_height) || 96,
            max_weight: trailerDims.weight || 42500
        };

        // Validation
        for (const i in shipments) {
            const l = shipments[i].length;
            const w = shipments[i].width;
            const h = shipments[i].height;
            const wgt = shipments[i].weight;
            const pcs = shipments[i].num_pieces;

            // return false if necessary data is not provided
            if( (isNaN(l) || l < 0) || (isNaN(w) || w < 0) || (isNaN(h) || h < 0) || isNaN(wgt) || (isNaN(pcs) || pcs < 0) ) {
                return false;

            // return false if unit dims exceed trailer dims.
            } else if(l > trailerDims.inner_length || w > trailerDims.inner_width || h > trailerDims.inner_height || wgt > trailerDims.max_weight) {
                return false;
            }
        };


        if (equipmentCode && !YatClient.EquipmentCodes.includes(equipmentCode)) {
            throw new Error("Invalid equipment code");
        }

        if (!trailerDims.inner_length || !trailerDims.inner_width || !trailerDims.inner_height) {
            throw new Error("Invalid trailer dimensions");
        }

        if (!trailerDims.max_weight) {
            throw new Error("Invalid trailer weight");
        }

        if (!equipmentCode && !trailerDims) {
            throw new Error("Equipment code or trailer dimensions required");
        }

        if (typeof allowRotations !== 'boolean') {
            throw new Error("Invalid allow rotations");
        }

        if (arrangmentAlgorithm && !YatClient.PieceArrangementAlgorithms.includes(arrangmentAlgorithm)) {
            throw new Error("Invalid piece arrangement algorithm");
        }

        if (shipmentOptimization) {
            if (typeof shipmentOptimization !== 'object') {
                throw new Error("Invalid shipment optimization");
            }

            if (shipmentOptimization.algorithm && !YatClient.ShipmentArrangementAlgorithms.includes(shipmentOptimization.algorithm)) {
                throw new Error("Invalid shipment arrangement algorithm");
            }

            if (!(shipmentOptimization.max_iter) || !(shipmentOptimization.timeout)) {
                throw new Error("Invalid iterations and timeout values");
            }
        }

        this.requestActive && this.source.cancel('Operation canceled due to new request.');
        this.source = axios.CancelToken.source();

        const config = {
            headers: {
                "content-type": "application/json"
            },
            cancelToken: this.source.token
        };

        let requestData = {
            shipment_list: shipments,
            equipment_code: equipmentCode,
            trailer_dims: trailerDims,
            allow_rotations: allowRotations,
            piece_arrangement_algorithm: arrangmentAlgorithm,
            shipment_optimization_ls: shipmentOptimization
        };
        const response = await axios.post(
            this.url + 'optimize-trailer-load/',
            requestData,
            config
        ).catch(thrown => {
            if(!axios.isCancel(thrown)) {
                console.error('LNFT API ERROR: ', thrown);
            }
        }).finally(() => {
            this.requestActive = false;
        });
        return response.data.linear_feet;
    }
}


