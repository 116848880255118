// ##############################
// // // Badge component styles
// #############################

import {
  uiColors,
  grayColor,
  whiteColor,
  tfmBlueColor,
  tfmOrangeColor
} from "~/assets/jss/empire-tms.jsx";

const badgeStyle = {
  badge: {
    borderRadius: "12px",
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "1",
    color: whiteColor,
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block"
  },
  primary: {
    backgroundColor: uiColors.primary.default
  },
  warning: {
    backgroundColor: uiColors.warning.default
  },
  danger: {
    backgroundColor: uiColors.danger.default
  },
  success: {
    backgroundColor: uiColors.success.default
  },
  info: {
    backgroundColor: uiColors.info.default
  },
  rose: {
    backgroundColor: uiColors.rose.default
  },
  gray: {
    backgroundColor: grayColor[0]
  },
  tfmBlue: {
    backgroundColor: tfmBlueColor
  },
  tfmOrange: {
    backgroundColor: tfmOrangeColor
  }
};

export default badgeStyle;
