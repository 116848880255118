import React from "react";
import PropTypes from "prop-types";

// @progress/kendo-react-charts
import {
    Chart,
    ChartArea,
    ChartTitle,
    ChartTooltip,
    ChartLegend,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip
} from "@progress/kendo-react-charts";

import Fade from "@material-ui/core/Fade";

import { grayColor } from "~/assets/jss/empire-tms.jsx";

class FacilityShipments extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Fade in>
                <Chart>
                    <ChartTitle text="Shipments by Facility" color="black" visible />
                    <ChartArea background="#fff" margin={10} height={400} />
                    <ChartAxisDefaults
                        color="black"
                        visible
                        majorGridLines={{
                            color: grayColor[4],
                            visible: true,
                            width: 1
                        }}
                        labels={{ rotation: "-45" }}
                    />
                    <ChartTooltip />
                    <ChartLegend position="bottom" orientation="horizontal" visible labels={{ color: "black" }} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={this.props.categories} max={20} labels={{ rotation: "auto" }} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem type="bar" color="orange" name="Number of Shipments" gap={2} spacing={0.25} data={this.props.data}>
                            <ChartSeriesItemTooltip format="{0}" background="orange" color="white" visible />
                        </ChartSeriesItem>
                    </ChartSeries>
                </Chart>
            </Fade>
        );
    }
}

FacilityShipments.propTypes = {
    categories: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export default FacilityShipments;