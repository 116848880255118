import React from "react";
import { connect } from "react-redux";
import { hasPermission, userIsAdmin, adminInUserSession } from "../../redux/selectors/Admin.jsx";

import qs from "qs";

import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

import moment from "moment";
import { basePath, apiUrl } from "~/variables/server.jsx";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Edit from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";

// material ui icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

// react-icons
import { FaCalendarAlt, FaTruck, FaTruckLoading, FaShippingFast, FaTruckMoving, FaCheckCircle, FaBox, FaBan, FaMapMarkerAlt, FaInfoCircle } from "react-icons/fa";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class HistoryPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			showLatestStatus: false,
			showP44Data: false,
			showBolData: false,
			loading: true,
			body: null,
			user: null,
			alert: null,
			company: {},
			bols: [],
			displayPreferences: {
				bol_num: true,
				pro: true,
				po: true,
				so: true,
				ref: true,
				bol_date: true,
				code: true,
				carrier: true,
				shipper: true,
				consignee: true,
				status: true,
				actions: true,
				transit_status: true,
				pickup_date: true,
				expected_delivery_date: true,
				delivery_date: true
			},
			showBol: true,
			showPro: true,
			showPo: true,
			showSo: true,
			showRef: true,
			showBolDate: true,
			showCode: true,
			showCarrier: true,
			showShipper: true,
			showConsignee: true,
			showBolStatus: true,
			showActions: true,
			showTransitStatus: true,
			showPickupDate: true,
			showEstDeliveryDate: true,
			showDeliveryDate: true,
			bolHistoryModal: false,
			history: [],
			rateDataModal: false,
			bolId: "",
			date: "",
			trackingData: null,
			displayStatus: true,
			latestShipmentStatus: null,
		};
		this.handleTracking = this.handleTracking.bind(this);
		this.getTrackingStatus = this.getTrackingStatus.bind(this);
		this.resizeFunction = this.resizeFunction.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });

		const bolNumber = this.props.match.params.bol;

		const urlProjectFourFour = `/index.php?p=api&r=json&c=tracking&m=p44TrackingHistory&d=${bolNumber}`;
		// const url = "/index.php?p=api&r=json&c=postingPanel&m=index&d=1/20";
		const url = "/index.php?p=api&r=json&c=postingPanel&m=index&filters[bol]=" + bolNumber;
		let bolId = "";

		if (!_.isEmpty(this.props.match.params.bol)) {
			bolId = this.props.match.params.bol;
		}

		try {
			const response = await axios.get(url);
			const responseFourFour = await axios.get(urlProjectFourFour);
			const responseStatus = await axios.get(`/index.php?p=api&r=json&c=tracking&m=p44LatestShipmentStatus&d=${bolNumber}`);

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if (typeof responseFourFour.data !== "string" && !_.isEmpty(responseFourFour.data.message)) {
				this.props.handleMessages(responseFourFour.data.message);
			}

			// handle responseFourFour data
			if (typeof responseFourFour.data !== "string"
				&& !_.isEmpty(responseFourFour.data.body)) {

				const trackingData = responseFourFour.data.body;

				let historyCollection = [];
				let trackingHistory = [];

				for (let i = 0; i < trackingData.trackingHistory.length; i++) {
					const history = trackingData.trackingHistory[i];
					let data = history.status.statusCode;
					if (!_.isEmpty(history.location)) {
						if (history.location.city != null) {
							data += " " + history.location.city.toUpperCase();
						}
						if (history.location.state != null) {
							data += " " + history.location.state.toUpperCase();
						}
					}
					if (!_.isEmpty(history.status.statusReasonDesc)) {
						data += " " + history.status.statusReasonDesc;
					}
					if (historyCollection.indexOf(data) === -1) {
						trackingHistory.push(history);
						historyCollection.push(data);
					}
				}

				trackingData.trackingHistory = trackingHistory;
				this.setState({
					trackingData: trackingData,
					showP44Data: true,
				});
			} else {
				this.setState({
					trackingData: null,
					showP44Data: false,
				});
			}


			// handle responseStatus data
			if (typeof responseStatus.data !== "string"
				&& !_.isEmpty(responseStatus.data.body)
				&& !_.isEmpty(responseStatus.data.body.latestShipmentStatus)) {

				this.setState({
					latestShipmentStatus: responseStatus.data.body.latestShipmentStatus,
					showLatestStatus: true,
				});
			} else {
				this.setState({
					latestShipmentStatus: null,
					showLatestStatus: false,
				});
			}

			// handle response data
			if (typeof response.data !== "string"
				&& !_.isEmpty(response.data.user)
				&& !_.isEmpty(response.data.body)
				&& !_.isEmpty(response.data.body.result)
			) {
				const data = response.data;
				const displayPreferences = data.body.displayPreferences;
				this.setState({
					body: data.body,
					bols: data.body.result,
					showBolData: true,
				});

				this.resizeFunction();
				window.addEventListener("resize", this.resizeFunction);
			} else {
				this.setState({
					body: null,
					bols: [],
					showBolData: false,
				});
			}

			this.setState({
				loading: false,
				alert: null,
			});

		} catch (error) {
			console.error(error);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeFunction);
	}

	async saveTransitInfo(index, type) {
		const { bols } = this.state;

		const { id, date } = bols[index];
		const value = type === "transit_status" ? this.state[type] : moment(this.state[type]).format("L");
		const data = { [type]: value };
		const url = "/index.php?p=api&c=billoflading&m=saveTransitInfo&d=" + id + "/" + date;

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				bols[index][type] = value;
				bols[index][type + "_edit"] = false;
				this.setState({
					bols,
					[type]: ""
				});
			} else {
				this.props.setNotification("There was an error saving the transit status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the transit status!", { variant: "error" });
		}
	}

	getHeaders() {
		const headers = [];
		const headerNames = ["BoL ID :", "PRO :", "PO :", "SO :", "Reference :", "BoL Date :", "Code :", "Carrier :", "Shipper :", "Consignee :", "Status :", "Transit Status :", "Pickup Date :", "Expected Delivery Date :", "Delivery Date :", "Actions :"];
		const headerShow = [
			this.state.showBol,
			this.state.showPro,
			this.state.showPo,
			this.state.showSo,
			this.state.showRef,
			this.state.showBolDate,
			this.state.showCode,
			this.state.showCarrier,
			this.state.showShipper,
			this.state.showConsignee,
			this.state.showBolStatus,
			this.state.showTransitStatus,
			this.state.showPickupDate,
			this.state.showEstDeliveryDate,
			this.state.showDeliveryDate,
			this.state.showActions
		];

		for (let i = 0; i < headerShow.length; i++) {
			for (let j = 0; j < headerNames.length; j++) {
				if (headerShow[i]) {
					headers.push(headerNames[j]);
				}
			}
		}

		return headers;
	}

	handleDatetimeFilters(key, name, m) {
		const { filters } = this.state;
		filters[key][name] = moment(m).format("L");
		this.setState({ filters });
	}

	async handleTracking() {

		const { bols } = this.state;

		this.setState({
			bolId: "",
			date: "",
			bols
		});

		const bolNumber = this.date + this.bolId;

		const urlProjectFourFour = `/index.php?p=api&r=json&c=tracking&m=p44TrackingHistory&d=${bolNumber}`;

		await axios.get(urlProjectFourFour).then(responseFourFour => {
				return responseFourFour.data.message[0].message;
			}).catch(error => {
				console.error(error);
			});
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}

	/*
	 *THIS FUNCTION IS USED TO CHANGE THE CLASS NAMES OF
	 *THE ICONS THAT CORRELATE WITH TRANSIT STATUS
	 *
	 *@param {returnIcons}
	 */

	grabTransitStatusIcon(transit_status, transit_sub_status) {
		switch (transit_status) {
			case "booked":
				return <FaCalendarAlt />;

			case "in transit":
				return <FaTruckMoving />;

			case "delivered":
				return <FaCheckCircle />;

			case "canceled":
				return <FaBan />;

			case "":
			case null:
				return; // For when there is no status
		}
		switch (transit_sub_status) {
			case "pending appt":
			case "appt set":
				return <FaCalendarAlt />;

			case "dispatched":
				return <FaTruckLoading />;

			case "pickup missed":
			case "departure missed":
			case "delivery missed":
			case "interline missed":
			case "delayed":
			case "held":
			case "exception":
			case "tracking ended":
				return <FaBan />;

			case "out to stop":
				return <FaBox />;

			case "at terminal":
			case "at stop":
			case "at pickup":
			case "at delivery":
				return <FaTruck />;

			case "":
			case null:
				return; // For when there is no status

			default:
				return <FaTruck />; // For any un-handled status
		}
	}

	grabTransitStatusColors(transit_status, transit_sub_status) {
		switch (transit_status) {
			case "booked":
				return { color: "#e91e63", fontSize: "4.5rem" };

			case "in transit":
				return { color: "#002D55", fontSize: "2.5rem" };

			case "delivered":
				return { color: "#4caf50", fontSize: "2.5rem" };

			case "canceled":
				return { color: "#f44336", fontSize: "2.5rem" };
		}
		switch (transit_sub_status) {
			case "dispatched":
				return { color: "#9c27b0", fontSize: "2.5rem" };

			case "out to stop":
				return { color: "#ff9800", fontSize: "2.5rem" };

			case "at terminal":
				return { color: "#002D55", fontSize: "2.5rem" };

			default:
				return { fontSize: "2.5rem" };
		}
	}

	grabTrackingStatusIcon(returnTrackingIcons) {
		switch (returnTrackingIcons) {
			case "AT_STOP":
				return <FaMapMarkerAlt />;

			case "INFO":
				return <FaInfoCircle />;

			case "OUT_TO_STOP":
				return <FaBox />;

			case "IN_TRANSIT":
				return <FaShippingFast />;

			case "COMPLETED":
				return <FaCheckCircle />;
		}
	}

	grabTrackingStatusColor(returnTrackingColor) {
		switch (returnTrackingColor) {
			case "AT_STOP":
				return "#f44336";

			case "INFO":
				return "#00acc1";

			case "OUT_TO_STOP":
				return "#ff9800";

			case "IN_TRANSIT":
				return "#002D55";

			case "COMPLETED":
				return "#4caf50";
		}
	}

	grabTrackingRouteStatusColor(returnRouteStatusColor) {
		switch (returnRouteStatusColor) {
			case "AT_STOP":
				return { color: "#f44336", fontSize: "2.5rem", textAlign: "center" };

			case "INFO":
				return { color: "#00acc1", fontSize: "2.5rem", textAlign: "center" };

			case "OUT_TO_STOP":
				return { color: "#ff9800", fontSize: "2.5rem", textAlign: "center" };

			case "IN_TRANSIT":
				return { color: "#002D55", fontSize: "2.5rem", textAlign: "center" };

			case "COMPLETED":
				return { color: "#4caf50", fontSize: "2.5rem", textAlign: "center" };
		}
	}

	grabTrackingStatusLocation(returnTrackingStatusLocation) {
		switch (returnTrackingStatusLocation) {
			case "AT_STOP":
				return <FaMapMarkerAlt />;

			case "INFO":
				return <FaInfoCircle />;

			case "OUT_TO_STOP":
				return <FaBox />;

			case "IN_TRANSIT":
				return <FaShippingFast />;

			case "COMPLETED":
				return <FaCheckCircle />;

			default:
				return;
		}
	}

	grabTrackingCurrentStop(returnTrackingCurrentStop) {
		if (!this.state.trackingData) {
			return;
		}

		const trackingStatus = this.state.trackingData.trackingHistory;

		switch (returnTrackingCurrentStop) {
			case trackingStatus < trackingStatus.length:
				return { backgroundColor: "#002d55", height: "30px", width: "30px", borderRadius: "50%", textAlign: "center" };

			case trackingStatus === trackingStatus.length:
				return { backgroundColor: "rgb(255, 152, 0)", height: "30px", width: "30px", borderRadius: "50%", textAlign: "center" };
		}
	}

	returnTrackingStatus() {
		if (!this.state.trackingData) {
			return;
		}

		const { latestShipmentStatus } = this.state;
		const shipmentStops = latestShipmentStatus.shipment.shipmentStops;
		const statusUpdate = latestShipmentStatus.latestStatusUpdate;
		const outThree = [];

		let lastStopNumber = shipmentStops.length;
		for (let i = 0; i < shipmentStops.length; i++) {
			let stop = typeof shipmentStops[i] === "object" ? shipmentStops[i] : {};
			let atStop = false;
			let completed = false;
			if (statusUpdate.statusCode == "COMPLETED") {
				completed = true;
			}
			if(statusUpdate.address != null) {
				if ((statusUpdate.stopNumber == stop.stopNumber || _.isEmpty(statusUpdate.stopNumber)) && !completed && stop.location != null && stop.location.address != null &&
					statusUpdate.address.city == stop.location.address.city &&
					statusUpdate.address.state == stop.location.address.state &&
					statusUpdate.address.country == stop.location.address.country &&
					statusUpdate.address.postalCode == stop.location.address.postalCode &&
					lastStopNumber == shipmentStops.length) {
					lastStopNumber = i;
				}
			}
			if (statusUpdate.statusCode == "AT_STOP") {
				atStop = true;
			}
			const dotStyle = {
				position: "relative",
				backgroundColor: lastStopNumber >= i ? "#002d55" : "#999",
				height: "30px",
				width: "30px",
				borderRadius: "50%",
				margin: "10px auto",
				textAlign: "center",
				zIndex: "99",
				verticalAlign: "middle",
			};
			let bgColor = lastStopNumber >= i ? "#002d55" : "#999";
			if (i === lastStopNumber && atStop) {
				bgColor = "#999";
			}
			outThree.push(
				<GridItem style={{ display: "block", fontSize: "1rem", fontWeight: "900", alignItems: "center", marginTop: "0px", position: "relative", width: "100%", zIndex: "99" }} key={'stop_' + i}>
					{i === 0 ? (
						<div style={{ height: "15px", marginTop: "82px", alignItems: "center", background: "#fff", left: "0", top: "0", bottom: "0", position: "absolute", width: "50%" }}></div>
					) : (
						<div style={{ height: "15px", marginTop: "82px", alignItems: "center", background: lastStopNumber >= i ? "#002d55" : "#999", left: "0", top: "0", bottom: "0", position: "absolute", width: "50%" }}></div>
					)}
					{i === (shipmentStops.length - 1) ? (
						<div style={{ height: "15px", marginTop: "82px", alignItems: "center", background: "#fff", right: "0", top: "0", bottom: "0", position: "absolute", width: "50%" }}></div>
					) : (
						<div style={{ height: "15px", marginTop: "82px", alignItems: "center", background: bgColor, right: "0", top: "0", bottom: "0", position: "absolute", width: "50%" }}></div>
					)}
					<div style={{ height: "65px", fontSize: "2.5rem", textAlign: atStop || completed ? "center" : "right", }}>
						{((i === lastStopNumber) || (i == (shipmentStops.length - 1) && completed)) && (
							<FaMapMarkerAlt />
						)}
					</div>
					<div style={dotStyle} />
					<div style={{ height: "65px", textAlign: "center" }}>
						<div style={{ fontSize: "16px" }}>
							{stop.stopType}
						</div>
						{(stop.location != null && stop.location.address != null && stop.location.hasOwnProperty('address') ? (
							<span style={{ fontSize: "12px" }}>
							{stop.location.address.city != null && stop.location.address.city.length > 0 ? stop.location.address.city : ''}
							{stop.location.address.state != null && stop.location.address.state.length > 0 ? ', ' + stop.location.address.state : ''}
							<br />
							{stop.location.address.country != null && stop.location.address.country.length > 0 ? stop.location.address.country : ''}
							{stop.location.address.postalCode != null && stop.location.address.postalCode.length > 0 ? stop.location.address.postalCode : ''}
							</span>
						) : <span></span>)}
					</div>
				</GridItem>
			);
		}

		return outThree;
	}

	getTrackingStatus() {

		if (!this.state.trackingData) {
			return;
		}

		const trackingStatus = this.state.trackingData.trackingHistory;

		const outTwo = [];

		for (let i = 0; i < trackingStatus.length; i++) {

			const dateString = !_.isEmpty(trackingStatus[i].timestamp.tfmTimestamp)
				? moment(trackingStatus[i].timestamp.tfmTimestamp).format("MM/DD/YYYY h:mm:ss a")
				: "";

			let carrierDateString = '';
			let updateDate = '';
			let updateTime = '';

			if(!_.isEmpty(trackingStatus[i].timestamp.carrier)) {

				updateDate = (trackingStatus[i].timestamp.carrier.hasOwnProperty('updateDate')
				&& trackingStatus[i].timestamp.carrier.updateDate != null
				&& trackingStatus[i].timestamp.carrier.updateDate.length > 0)
				? trackingStatus[i].timestamp.carrier.updateDate : '';

				updateTime = (trackingStatus[i].timestamp.carrier.hasOwnProperty('updateTime')
				&& trackingStatus[i].timestamp.carrier.updateTime != null
				&& trackingStatus[i].timestamp.carrier.updateTime.length > 0)
				? trackingStatus[i].timestamp.carrier.updateTime : '';

				if(updateDate.length > 0 && updateTime.length > 0) {
					carrierDateString = moment(updateDate + " " + updateTime).format("MM/DD/YYYY h:mm:ss a");
				} else if(updateDate.length > 0) {
					carrierDateString = moment(updateDate).format("MM/DD/YYYY");
				}

			}

			const showStop = !_.isEmpty(trackingStatus[i].stop) && false; // Hide the stop until we can the total stops from the logged request

			outTwo.push(
				<Grid container item style={{ marginBottom: "25px", display: "flex", alignContent: "center", backgroundColor: "#fbfbfb", border: "1px solid #cecece", borderRadius: "10px", width: "100%", padding: "20px", boxShadow: "0 0 8px #bfbfbf" }} key={'status_' + i}>
					<Grid container item style={{ flex: 1, display: "block", maxWidth: "200px", marginBottom: "25px" }}>
						<Grid container item style={{ display: "block", width: "60%", textAlign: "center", fontSize: "4.5rem", color: this.grabTrackingStatusColor(trackingStatus[i].status.statusCode) }}>
							{this.grabTrackingStatusIcon(trackingStatus[i].status.statusCode)}
							<Grid item style={{ fontSize: "1rem", fontWeight: "900", color: "black" }}>
								{trackingStatus[i].status.statusCode.replace(/_/g, " ")}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={9} lg={10} >
						{!_.isEmpty(trackingStatus[i].location) && (
							<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
								{trackingStatus[i].location.city != null ? trackingStatus[i].location.city + ', ' : ''}{trackingStatus[i].location.state} {trackingStatus[i].location.country} {trackingStatus[i].location.postalCode}
							</Grid>
						)}
						{!_.isEmpty(trackingStatus[i].status.statusReasonDesc) && (
							<Grid container item style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
								<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
									{trackingStatus[i].status.statusReasonDesc}
								</Grid>
							</Grid>
						)}
						{!_.isEmpty(trackingStatus[i].status.exceptionSummaryCode) && (
							<Grid container item style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", color: "red" }}>
								<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
									<ErrorOutline /> {trackingStatus[i].status.exceptionSummaryCode.replace(/_/g, " ")}
									{!_.isEmpty(trackingStatus[i].status.exceptionDetailCode) && " | " + trackingStatus[i].status.exceptionDetailCode.replace(/_/g, " ")}
								</Grid>
							</Grid>
						)}
						<Grid container item style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
							<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
								{!_.isEmpty(carrierDateString) && carrierDateString}
								{!_.isEmpty(carrierDateString) && _.isEmpty(carrierDateString) && dateString}
							</Grid>
						</Grid>
					</Grid>
					{showStop && (
						<Grid container item style={{ flex: 1 }}>
							<Grid container item style={{ display: "flex", flexWrap: "wrap" }}>
								<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
									Stop Number:&nbsp;
								</Grid>
								<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
									{trackingStatus[i].stop.stopNumber}
								</Grid>
							</Grid>
							<Grid container item style={{ display: "flex", flexWrap: "wrap" }}>
								<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
									Stop Type:&nbsp;
								</Grid>
								<Grid item style={{ fontSize: "1rem", fontWeight: "900" }}>
									{trackingStatus[i].stop.stopType}
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			);
		}
		return outTwo;
	}

    resizeFunction() {
        if (window.innerWidth < 960 && this.state.displayStatus === true) {
            this.setState({ displayStatus: false });
        } else if (window.innerWidth >= 960 && this.state.displayStatus === false) {
        	this.setState({ displayStatus: true });
        }
    }

	render() {
		const headers = this.getHeaders(this.state.headers);
		const b = this.state.bols[0];
		let lastUpdateReceived = "";

		if (this.state.showP44Data && this.state.trackingData) {
			const trackingStatus = this.state.trackingData.trackingHistory;
			const index = trackingStatus.length - 1;
			lastUpdateReceived = !_.isEmpty(trackingStatus[index].timestamp.tfmTimestamp) ? moment(trackingStatus[index].timestamp.tfmTimestamp).format("MM/DD/YYYY | h:mm:ss a") : "";
		}

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
					{!!this.props.userIsAdmin && !this.props.adminInUserSession && (
						<NavLink to={basePath + "/admin/audit/posting-panel"}>
							<Button color="linkedin">
								Back to Posting Panel
							</Button>
						</NavLink>
					)}
					{(!this.props.userIsAdmin || this.props.adminInUserSession) && (
						<NavLink to={basePath + "/admin/bol/archived"}>
							<Button color="linkedin">
								Back to Archived BOLs
							</Button>
						</NavLink>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<Grid item container>
								{this.state.showBolData ? (
									<Card style={{ backgroundColor: "#fbfbfb", border: "1px solid #cecece", borderRadius: "10px", width: "100%", padding: "20px", boxShadow: "0 0 8px #bfbfbf" }}>
										<Grid item container style={{ marginBottom: "15px", display: "flex", justifyContent: "space-between", alignContent: "center", flexWrap: "wrap" }}>
											<Grid item xs={12} sm={8} md={8} lg={9}>
												<Grid item container style={{ display: "flex", flexWrap: "wrap" }}>
													<Grid item style={{ fontSize: "1.5rem", color: "#002d55", fontWeight: "900", marginBottom: "5px" }}>
														{headers[8]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1.5rem", color: "#0058A6", fontWeight: "500" }}>
														{b.shipper.name}
													</Grid>
												</Grid>
												<Grid item container style={{ display: "flex", flexWrap: "wrap" }}>
													<Grid item style={{ fontSize: "1.3rem", color: "#002d55", fontWeight: "900", marginBottom: "15px" }}>
														{headers[0]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1.3rem", color: "#000", fontWeight: "500" }}>
														<NavLink to={basePath + "/admin/bol/" + b.date + "/" + b.id}>
															{b.date + b.id}
														</NavLink>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} sm={4} md={4} lg={3}>
												<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900", textAlign: "center", width: "100%" }}>
													{headers[11]}
												</Grid>
												{(typeof b.transit_status === "string") && (
													<Grid item style={{ fontSize: "1rem", fontWeight: "900", textAlign: "center", width: "100%" }}>
														<Grid item style={this.grabTransitStatusColors(b.transit_status, b.transit_sub_status)}>
															{this.grabTransitStatusIcon(b.transit_status, b.transit_sub_status)}
														</Grid>
														<Grid item style={{ fontSize: "1rem", color: "#000 !important", fontWeight: "500", color: "red", textAlign: "center" }}>
															{b.transit_status.toUpperCase()} {b.transit_sub_status ? b.transit_sub_status.toUpperCase() : ""}
														</Grid>
													</Grid>
												)}
											</Grid>
										</Grid>
										<GridContainer>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%", flexWrap: "wrap" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[1]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.pro}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%", flexWrap: "wrap" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[2]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.po}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%", flexWrap: "wrap" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[3]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.so}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%", flexWrap: "wrap" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[5]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.custom_date}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[6]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.code}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[7]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.carrier}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[9]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.consignee.name}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", width: "100%", overflow: "hidden" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[10]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.status}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", alignContent: "center", alignItems: "center", width: "100%" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[12]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.pickup_date}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", alignContent: "center", alignItems: "center", width: "100%" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[13]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.expected_delivery_date}
													</Grid>
												</Grid>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={3}>
												<Grid item container style={{ display: "flex", justifyContent: "flex-start", alignContent: "center", alignItems: "center", width: "100%" }}>
													<Grid item style={{ fontSize: "1rem", color: "#002d55", fontWeight: "900" }}>
														{headers[14]}&nbsp;&nbsp;
													</Grid>
													<Grid item style={{ fontSize: "1rem", color: "#000", fontWeight: "500" }}>
														{b.delivery_date}
													</Grid>
												</Grid>
											</GridItem>
											{lastUpdateReceived != "" && (
												<GridItem xs={12} style={{ marginTop: "15px", fontSize: "1rem", fontWeight: "500", color: "#999", textAlign: "right" }}>
													<i>Last Update Received on {lastUpdateReceived}</i>
												</GridItem>
											)}
										</GridContainer>
									</Card>
								) : (
									<Spinner loading={this.state.loading} message="Unable to load BOL: Either the shipment does not exist or you do not have access to view it." />
								)}
							</Grid>
							{this.state.showP44Data && this.state.showLatestStatus && this.state.displayStatus && (
								<Grid container  style={{ margin: "10px 0px" }}>
									<h2 style={{ fontWeight: "900", color: "#002d55", marginTop: "10px", marginBottom: "70px" }}>Route Status:</h2>
									<GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px", marginBottom: "40px" }}>
										<div style={{ height: "15px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>{this.returnTrackingStatus()}</div>
									</GridItem>
								</Grid>
							)}
							{this.state.showP44Data ? (
								<Grid>
									<Grid item container>
										<h2 style={{ fontWeight: "900", color: "#002d55", marginTop: "30px" }}>Route Details:</h2>
										{this.getTrackingStatus()}
									</Grid>
								</Grid>
							) : (
								<Spinner loading={this.state.loading} message="No tracking information available for this shipment." />
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
        hasClaims: hasPermission("USE_CLAIMS")(state),
        userIsAdmin: userIsAdmin(state),
        adminInUserSession: adminInUserSession(state),
    };
}

export default connect(mapStateToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(HistoryPanel)));
