import React from "react";
import { connect } from "react-redux";
import { flushSync } from 'react-dom';
import { hasPermission, parcelWeightLimit } from "../../redux/selectors/Admin.jsx";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import { withSnackbar } from "notistack";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import ManagePrinters from "~/views/TMS/ManagePrinters.jsx";
import PrintZplModal from "~/views/PrintZplModal/containers/PrintZplModal.jsx";
import { setJSPM, setAvailablePrinters, jspmStatus, setUpPrinters, autoPrint, printDocument } from "~/views/PrintZplModal/actions/PrintZplModal.jsx";

import "formdata-polyfill";

import _ from "lodash";
import qs from "qs";
import moment from "moment";
import axios from "~/variables/axios.jsx";
import { basePath, apiUrl } from "~/variables/server.jsx";

import { formatHazUoM, searchObject, validateItem } from "../../helpers.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from '@material-ui/core/Slide';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import AddCircle from "@material-ui/icons/AddCircle";
import Warning from "@material-ui/icons/Warning";
import Search from "@material-ui/icons/Search";
import ReportProblem from "@material-ui/icons/ReportProblem";
import SwapVert from "@material-ui/icons/SwapVert";
import LocationOn from "@material-ui/icons/LocationOn";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";

import AlternateEmail from "@material-ui/icons/AlternateEmail";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import InfoArea from "~/components/InfoArea/InfoArea.jsx";
import ReferenceList from "~/components/TMS/ReferenceList.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import { openAlertModal } from "../Alert/actions/Alert.jsx";
import Table from "~/components/Table/Table.jsx";

import ProductSearchModal from "../BillOfLading/components/ProductSearchModal.jsx";
import ParcelPackageButton from "~/components/TMS/ParcelPackageButton.jsx";

import ParcelLookup from "../../components/ParcelLookup/components/ParcelLookup.jsx";
import { performLookup, clearParcelLookup } from "../../components/ParcelLookup/actions/ParcelLookup.jsx";

import { loadCarriers, loadServices, getCarrierScacs } from "../ParcelServices/actions/ParcelServices.jsx";
import ParcelServices from "../ParcelServices/containers/ParcelServices.jsx";
import SignatoryInformation from "../SignatoryInformation/containers/SignatoryInformation.jsx";
import { convertToCountryCode } from "../../helpers.jsx"

// style for this view
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import ContactSearch from "../../components/TMS/ContactSearch.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchLocation, faSearch } from '@fortawesome/free-solid-svg-icons';
import HazmatInfo from "../../components/HazmatInfo/HazmatInfo.jsx";

const SlideTransition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});


const ExtraRequiredFieldsMap = [
	{settingName: "require_so", inputName: "so_num"},
	{settingName: "require_po", inputName: "po_num"},
	{settingName: "require_ref", inputName: "ref_num"},
	{settingName: "require_cons_contact", inputName: "consignee_contact"},
];

class ParcelScheduler extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			alert: null,
			showManagePrinters: false,
			lookup: '',
			loading: true,
			carriers: [],
			hazCarriers: [],
			carrier: '',
			service: '',
			services:[],
			billing_type: 'account',
			billing_account: '',
			billing_zip: '',
			accounts: [],
			account: '',
			cod: false,
			cod_collection_type: '',
			cod_amount: '',
			signature: false,
			signature_type: '',
			saturday_delivery: false,
			carbon_neutral: false,
			packaging_type: 'BOX',
			standard_units: true,
			ship_date: '',
			customs: {
				customs_value: 0.00,
				commodity_units: 0.00,
				unit_value: 0.00,
				origin_country: "",
				part_number: "",
				description: "",
				import_uom: "",
				export_reason: "",
			},
			hazmat: false,
			showHaz: false,
			selectedKey: null,
			productSearchModal: false,
			products: [],
			productKey: "",
			regulation_set: "iata",
			allowedUnitsOfMeasure: [],
			packages: [
				{
					id: '',
					length: '',
					width: '',
					height: '',
					weight: '',
					quantity: 1,
					declared_value: "",
					hazardous: false,
					part_no: "",
					description: "",
					hazmat: {
						un: "",
						class: "",
						subHazardClass: "",
						properShippingName: "",
						packingGroup: "",
						packingInstructionCode: "",
						technicalName: "",
						packagingType: "",
						innerContainerType: "",
						regulatoryQuantity: "",
						uom: "",
						quantity: "",
						transportationMode: "",
						accessibility: "",
						requiredLabel: "",
					}
				},
			],
			required: [
				"shipper_company",
				"shipper_address1",
				"shipper_zip",
				"shipper_city",
				"shipper_state",
				"shipper_country",
				"shipper_phone",
				"consignee_company",
				"consignee_address1",
				"consignee_zip",
				"consignee_city",
				"consignee_state",
				"consignee_country",
				"consignee_phone",
				"billing_type",
				"label_type",
				"label_size"
			],
			consignee_company: '',
			consignee_contact: '',
			consignee_email: '',
			consignee_address1: '',
			consignee_address2: '',
			consignee_zip: '',
			consignee_state: '',
			consignee_city: '',
			consignee_country: 'US',
			consignee_phone: '',
			shipper_company: '',
			shipper_contact: '',
			shipper_email: '',
			shipper_address1: '',
			shipper_address2: '',
			shipper_zip: '',
			shipper_state: '',
			shipper_city: '',
			shipper_country: '',
			shipper_phone: '',
			return_addresses: [],
			return_name: '',
			return_company: '',
			return_contact: '',
			return_email: '',
			return_address1: '',
			return_address2: '',
			return_zip: '',
			return_state: '',
			return_city: '',
			return_country: '',
			return_phone: '',
			residential: false,
			loadingResidentialCheck: false,
			fedex_address_validation: false,
			user: {},
			warehouses: [],
			warehouse: '',
			selected_warehouse: '',
			reference_type: '',
			refTypes: [],
			loadingLookup: false,
			loadingRate: false,
			rateQuote: '',
			default_label_save: false,
			include_costco_label: false,
			asn: false,
			asn_email: '',
			label_type: "STOCK",
			label_size: "",
			default_label_type: "STOCK",
			default_label_size_fedex: "",
			default_label_size_ups: "",
			references: [],
			so_num: '',
			po_num: '',
			dept_num: '',
			ref_num: '',
			scheduleShipment: false,
			zpl: "",
			docZpl: "",
			printLabelModal: false,
			printDocModal: false,
			default_printer_save: false,
			general: null,
			shipmentInfo: {
				shipmentId: "",
				trackingId: "",
				totalCharge: "",
				billingType: "",
				acctNo: "",
				labelType: "",
				carrier: "",
				serviceType: "",
				shipDate: ""
			},
			third_party_accounts: [],
			third_party_billing_account: "",
			third_party_account_name: "",
			third_party_account_number: "",
			third_party_account_zip: "",
			savingThirdPartyAccount: false,
			dg_enabled: false,
			is_inbound: false,
			enable_product_part_no: false,
			emergencyPhone: "",
			intlEmergencyPhone: "",
			emergencyResponseRegistrant: "",
			offeror: "",
			signatoryContactName: "",
			signatoryTitle: "",
			signatoryPlace: "",
			inbound_offeror: "",
			inbound_emergency_phone: "",
			inbound_intl_emergency_phone: "",
			inbound_emergency_response_registrant: "",
			inbound_signatory_contact_name: "",
			inbound_signatory_contact_title: "",
			inbound_signatory_place: "",
			consignee_or_shipper_save: false,
			consignee_or_shipper_search_for: "",
			consignee_or_shipper_search_by: "SEARCH_ANY",
			contactSearchModal: false,
			contacts: [],
			contactSearchType: "",
			showConfirmShipmentDuplication: false,
			updateShipmentData: [],
			showWarningText: false,
			fedexScac: "",
			upsScac: "",
			uspsScac: "",
		}
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.checkForResi = this.checkForResi.bind(this);
		this.handlePackage = this.handlePackage.bind(this);
		this.addPackage = this.addPackage.bind(this);
		this.updateCarriers = this.updateCarriers.bind(this);
		this.getAccount = this.getAccount.bind(this);
		this.calculateRate = this.calculateRate.bind(this);
		this.handleReference = this.handleReference.bind(this);
		this.getRefTypes = this.getRefTypes.bind(this);
		this.getLabelSizes = this.getLabelSizes.bind(this);
		this.lookupShipment = this.lookupShipment.bind(this);
		this.loadServices = this.loadServices.bind(this);
		this.getCarrierScacs = this.getCarrierScacs.bind(this);
		this.getCustomRefs = this.getCustomRefs.bind(this);
		this.scheduleShipment = this.scheduleShipment.bind(this);
		this.printerCheck = this.printerCheck.bind(this);
		this.getProducts = this.getProducts.bind(this);
        this.handleProductSelect = this.handleProductSelect.bind(this);
		this.handleZip = this.handleZip.bind(this);
		this.saveNewThirdPartyAccount = this.saveNewThirdPartyAccount.bind(this);
		this.getThirdPartyAccounts = this.getThirdPartyAccounts.bind(this);
		this.loadThirdPartyAccounts = this.loadThirdPartyAccounts.bind(this);
		this.handleProductSearch = this.handleProductSearch.bind(this);
		this.performLookup = this.performLookup.bind(this);
	}

	getCarrierScacs() {
		let parcelScacs = getCarrierScacs();
		flushSync(() => {
			this.setState({
				fedexScac: parcelScacs['FEDEX'] || "",
				upsScac: parcelScacs['UPS'] || "",
				uspsScac: parcelScacs['USPS'] || "",
			});
		});
	}

	async componentDidMount() {
		let { required } = this.state;
		let { JSPM } = this.props;
		if(JSPM== null) {
			JSPM = window.JSPM;
			this.props.setJSPM(JSPM);
		}

		this.setState({ mounted: true});
		try {
			this.getCarrierScacs();
			const response = await axios.get("/index.php?p=api&r=json&c=parcel&m=parcelScheduler");
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user) && this.state.mounted) {
				this.props.handleMessages(response);

				let warehouse = { code: '' };
				let enable_product_part_no = 0;

				if (!_.isEmpty(data.body.warehouses)) {

					this.setState({warehouses: data.body.warehouses, user_company_settings: data.user.user_company_settings});

					if (!_.isEmpty(data.body.warehouses.filter(warehouse => warehouse.is_default == "1"))) {
						warehouse = data.body.warehouses.filter(warehouse => warehouse.is_default == "1")[0];
						enable_product_part_no = warehouse.enable_product_part_no
					} else {
						warehouse = data.body.warehouses[0];
						enable_product_part_no = warehouse.enable_product_part_no
					}

					if (enable_product_part_no == "1") {
						this.setState({enable_product_part_no: true})
					}

					if (data.user.user_company_settings.enable_parcel_dg == "1") {
						this.setState({ dg_enabled: true });
					}

					await this.setState({warehouse: warehouse.code});
					await this.updateCarriers(warehouse.code);

					await this.loadWarehouseDefaults(warehouse.code);

					if (data.user.user_settings.dont_apply_addr_book_defaults != "1") {
						await this.loadLocationDefaults(warehouse.code);
					}

					await this.getReferences();
					await this.getDefaultProduct();

					if (data.user.user_company_settings.enable_fedex_address_validation == "1") {
						this.setState({ fedex_address_validation: true });
					}

					const default_label_type = response.data.user.default_label_sizes.type || "STOCK";

					const default_label_size_fedex = response.data.user.default_label_sizes.FEDEX || '';
					const default_label_size_ups = response.data.user.default_label_sizes.UPS || '';

					const carrier = this.state.carrier;
					const validCarrierLabelSizes = this.getLabelSizes(carrier, default_label_type, this.state.hazmat);
					let defaultLabelSize = '4X6';
					if (carrier === "FEDEX" && validCarrierLabelSizes.some(l =>l.value === default_label_size_fedex)) {
						defaultLabelSize = default_label_size_fedex;
					}
					else if (carrier === "UPS" && validCarrierLabelSizes.some(l =>l.value === default_label_size_ups)) {
						defaultLabelSize = default_label_size_ups;
					}

					this.setState({
						default_label_type,
						label_type: default_label_type,
						default_label_size_fedex,
						default_label_size_ups,
						label_size: defaultLabelSize,
					});
				}

				ExtraRequiredFieldsMap.map(field => {
					if(data.body.extra_required_fields.hasOwnProperty(field.settingName) && data.body.extra_required_fields[field.settingName] == 1) {
						required.push(field.inputName);
					}
				});

				// this.setState({required}); Seems like this should be necessary but isn't - why?

				if (!_.isEmpty(this.props.match.params.shipmentId)) {
					let shipmentId = this.props.match.params.shipmentId;
					let searchResults = await this.performLookup(false, 'sid', shipmentId, '', true);
					if(searchResults != false) {
						let result = searchResults.result[0];
						this.updateShipment(result, true);
					}
				}

			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}

		this.setState({loading: false});
	}
	handleModalOpen(modal) {
        this.setState({ [modal]: true });
    }

	handleModalClose(modal) {
        this.setState({ [modal]: false });
		if(modal == "printLabelModal") {
			this.newShipment();
		} else if(modal == "printDocModal") {
			this.setState({docZpl:""})
		}
	}

	setHazUNInfo = (responseObj, key, selection = null) => {

		let transMode = this.state.packages[0].hazmat.transportationMode;
		if(transMode == "") {
			this.props.setNotification("Transportation mode required to lookup UN info.")
			return false;
		}

		if(transMode == "Ground" && responseObj.Forbidden) {
			this.props.setNotification("Selected Service Option is not available for Ground transport mode.", { variant: "error" });
			return false;
		} else if(transMode == "Passenger" && responseObj.PassengerAndCargoAircraftPackingInstructions == "Forbidden") {
			this.props.setNotification("Selected Service Option is not available for Cargo and Passenger Aircraft transport mode.", { variant: "error" });
			return false;
		} else if(transMode == "Cargo" && responseObj.CargoAircraftOnlyPackingInstructions == "Forbidden") {
			this.props.setNotification("Selected Service Option is not available for Cargo Only transport mode.", { variant: "error" });
			return false;
		}
		if(typeof responseObj.LabelsRequired !== 'undefined') {
			responseObj.LabelsRequired = responseObj.LabelsRequired.trim();
		} else {
			this.props.setNotification("Selected Service Option is not available because Required Label Information is not available.", { variant: "error" });
			return false;
		}

		const { packages } = {...this.state};
		if (!_.isEmpty(packages)) {
			const prop = packages[key];

			prop.hazmat.class = responseObj.HazardClass.trim();
			prop.hazmat.subHazardClass = responseObj.HazardClass.trim();
			prop.hazmat.properShippingName = responseObj.ProperShippingName.trim();
			prop.hazmat.packingGroup = responseObj.PackingGroup.trim();
			prop.hazmat.requiredLabel = responseObj.LabelsRequired.trim();

			let allowedUnits = [];

			let hazNum = prop.hazmat.class;
			if(isNaN(prop.hazmat.class) && prop.hazmat.class.length > 2) {
				hazNum = hazNum.substring(0, hazNum.length - 1);
			}
			if(hazNum >= 3) {
				hazNum = Math.trunc(hazNum);
			}

			if(hazNum == "1.4" ||
				hazNum == "2.1" ||
				hazNum == "2.2" ||
				hazNum == "3" ||
				hazNum == "4" ||
				hazNum == "5" ||
				hazNum == "8" ) {
					prop.hazmat.accessibility = "ACCESSIBLE";
			} else {
				prop.hazmat.accessibility = "INACCESSIBLE";
			}

			switch(responseObj.LtdQtyUnitOfMeasureId) {
				case "None":
				default:
					prop.hazmat.regulatoryQuantity = "";
					break;
				case "L":
					prop.hazmat.regulatoryQuantity = "LQ";
					allowedUnits.push(responseObj.LtdQtyUnitOfMeasureId);
					break;
				case "F":
					prop.hazmat.regulatoryQuantity = "FR";
					allowedUnits.push(responseObj.LtdQtyUnitOfMeasureId);
					break;
			}

			let instructionCode = "";

			if(typeof responseObj.ExceptedMaxInnerLiquidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxInnerLiquidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxInnerSolidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxInnerSolidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxOuterLiquidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxOuterLiquidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxOuterSolidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxOuterSolidUnitOfMeasureId);
			}

			if(transMode == "Cargo") {
				if(typeof responseObj.CargoAircraftOnlyUnitOfMeasureId !== 'undefined') {
					allowedUnits.push(responseObj.CargoAircraftOnlyUnitOfMeasureId);
				}

				if(typeof responseObj.CargoAircraftOnlyPackingInstructions !== 'undefined') {
					instructionCode = responseObj.CargoAircraftOnlyPackingInstructions.trim();
				}

			} else if(transMode == "Passenger") {
				if(typeof responseObj.PassengerAndCargoAircraftUnitOfMeasureId !== 'undefined') {
					allowedUnits.push(responseObj.PassengerAndCargoAircraftUnitOfMeasureId);
				}
				if(typeof responseObj.PassengerAndCargoAircraftPackingInstructions !== 'undefined') {
					instructionCode = responseObj.PassengerAndCargoAircraftPackingInstructions.trim();
				}
			} else {
				//ground shipments can take any of the units of measure.
				allowedUnits = [
					"cylinder",
					"g",
					"gallon",
					"kg",
					"kgG",
					"L",
					"mL",
					"oz",
					"pint",
					"lbs",
					"qt"
				];
			}


			if(instructionCode == "None") {
				instructionCode = "";
			}

			if(allowedUnits.length == 0) {
				this.props.setNotification("This package may not be shipped with this transportation mode.", { variant: "error" });
				return false;
			}
			prop.hazmat.packingInstructionCode = instructionCode;

			const access = this.state.packages[0].hazmat.accessibility;
			let serviceMode = this.state.service;
			if(access == "ACCESSIBLE") {
				if(this.state.carrier == "FEDEX" && serviceMode != "PRIORITY_OVERNIGHT" && serviceMode != "FEDEX_GROUND" && serviceMode != "FIRST_OVERNIGHT") {
					//prevents us from setting it multiple times as we iterate through the list.
					serviceMode = "PRIORITY_OVERNIGHT";
				}
			}
			packages[key] = prop;
			this.setState({
				selectedKey: selection,
				packages: packages,
				allowedUnitsOfMeasure: allowedUnits,
				service: serviceMode
			});
		}
	}

	printerCheck = async() => {
		const { carrier, label_type } = this.state;
		const printer = window.localStorage.getItem(carrier.toUpperCase());
		if (!_.isEmpty(printer) && label_type == "ZPL") {
			await this.autoPrint(printer, this.state.shipmentInfo.shipmentId, this.props.JSPM);
		} else {
			this.viewLabel();
		}
	}

	openAlert = (message, callback) => {
        this.props.openAlertModal(message, callback);
    }

	handleProductAlert(key, productId) {
        const { products } = this.state;
		if(products[key].alert_message != null && products[key].alert_message.length > 0) {
			this.openAlert(products[key].alert_message, () => {this.handleProductSelect(productId)});
		} else {
			this.handleProductSelect(productId);
		}
    }

	getProducts(products) {
		const enableProductPartNo = this.state.enable_product_part_no
        return products.map((product, key) => {
            let row = [
                <div dangerouslySetInnerHTML={{ __html: product.code }} />,
                <div dangerouslySetInnerHTML={{ __html: product.name }} />,
                <div dangerouslySetInnerHTML={{ __html: product.length }} />,
                <div dangerouslySetInnerHTML={{ __html: product.width }} />,
                <div dangerouslySetInnerHTML={{ __html: product.height }} />,
                <div dangerouslySetInnerHTML={{ __html: product.weight }} />,
                <div dangerouslySetInnerHTML={{ __html: product.hazmat }} />,
                <div className="actions-right">
                    <Button size="sm" color="linkedin" onClick={() => this.handleProductAlert(key, product.id)}>
                        Select
                    </Button>
                </div>
            ];
			if(enableProductPartNo) {
				row.splice(1, 0, (
					<div dangerouslySetInnerHTML={{ __html: product.part_no }} />
				));
			}
            return  product.is_parcel == 1 ? row : [];
        });
    }

	async getDefaultProduct() {
        const { warehouse, packages } = this.state;
		let defaultPkg = {}
        if (_.isEmpty(warehouse)) {
            return;
        }
        try {
            const url = "/index.php?p=api&r=json&c=product&m=defaults&d=" + warehouse + "/1";
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
				const res = response.data.body;
				if (res.id) {
					defaultPkg = {
						length: res.length,
						width: res.width,
						height: res.height,
						weight: res.weight,
						quantity: res.pieces || 1,
						declared_value: res.declared_value || '',
						hazardous: res.haz_class ? true : false,
						part_no: res.part_no || '',
						description: res.name || '',
						hazmat: {
							un: res.un_num,
							class: res.haz_class,
							requiredLabel: res.required_label,
							subHazardClass: res.sub_haz_class,
							properShippingName: res.proper_shipping_name,
							packingGroup: res.group,
							packingInstructionCode: res.packing_inst,
							technicalName: res.technical_name,
							packagingType: res.pkg_type,
							regulatoryQuantity: res.regulatory_qty,
							uom: res.haz_uom,
							quantity: res.quantity,
							transportationMode: res.trans_mode,
							accessibility: res.accessibility,
							innerContainerType: res.inner_ctr_type
						}
					};
				} else {
					defaultPkg = packages[0]
				}
				packages[0] = defaultPkg
				this.setState({
					packages,
					allowedUnitsOfMeasure: formatHazUoM(res.haz_uom),
					hazmat:  res.haz_class ? true : false
				});
            } else {
                this.props.setNotification("There was an error loading the default product!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the default product!", { variant: "error" });
        }
    }


	async handleProductSearch(i) {
        const { warehouse, packages } = this.state;
        const product = packages[i].description;

        const url = "/index.php?p=api&r=json&c=product&m=find&d=" + warehouse + "/" + product;

        try {
            const response = await axios.get(url);


            if (typeof response.data !== "string") {

                if (response.data.body.length == 1 && response.data.body[0].is_parcel != 0) {

                    if(response.data.body[0].alert_message != null && response.data.body[0].alert_message.length > 0) {

                        this.setState({
                            products: response.data.body,
                            productKey: i,
                        }, this.openAlert(response.data.body[0].alert_message, () => {this.handleProductSelect(response.data.body[0].id)}));

                    } else {

                        this.setState({
                            products: response.data.body,
                            productKey: i,
                        });

                        this.handleProductSelect(response.data.body[0].id);

                    }

                } else if (response.data.body.length > 1) {

                    this.setState({
                        products: response.data.body,
                        productKey: i,
                        productSearchModal: true
                    });

                } else {
                    this.props.setNotification("No products found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching products!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching products!", { variant: "error" });
        }
    }

	async handleProductSelect(productId) {

        const { warehouse, productKey, packages } = this.state;

        const url = "/index.php?p=api&r=json&c=product&m=load&d=" + warehouse + "/" + productId;

        try {

            const response = await axios.get(url);
            if (typeof response.data !== "string") {

				if(!_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
                }
				const res = response.data.body;
				let selectedPkg = {
					length: res.length,
					width: res.width,
					height: res.height,
					weight: res.weight,
					quantity: res.pieces || 1,
					declared_value: res.declared_value || '',
					part_no: res.part_no || '',
					hazardous: res.haz_class ? true : false,
					description: res.name,
					hazmat: {
						un: res.un_num,
						class: validateItem(res.haz_class, 'class'),
						requiredLabel: res.required_label,
						subHazardClass: res.sub_haz_class,
						properShippingName: res.proper_shipping_name,
						packingGroup: validateItem(res.group, 'pkgGroup'),
						packingInstructionCode: res.packing_inst,
						technicalName: res.technical_name,
						packagingType: validateItem(res.pkg_type, 'pkgType'),
						regulatoryQuantity: validateItem(res.regulatory_qty, 'regQty'),
						uom: validateItem(res.haz_uom, 'uom'),
						quantity: res.quantity,
						transportationMode: validateItem(res.trans_mode, 'transMode'),
						accessibility: validateItem(res.accessibility, 'accessibility'),
						innerContainerType: validateItem(res.inner_ctr_type, "innerContainer")
					}
				};
				packages[productKey] = selectedPkg;
                this.setState({
					packages,
					productSearchModal: false,
					allowedUnitsOfMeasure: formatHazUoM(res.haz_uom),
					hazmat:  res.haz_class ? true : false
				}, this.setHazardousLabelSizes);
            } else {

                this.props.setNotification("There was an error loading the product!", {variant: "error"});

            }

        } catch (error) {

            console.error(error);

            this.props.setNotification("There was an exception loading the product!", { variant: "error" });

        }

    }

	getPackagingTypes = () => {
		const { classes } = this.props;
		const carrier = this.state.carrier;
		const service = this.state.service;
		const nonExclusivePackaging = [
			{
				'name': 'Envelope',
				'value' : 'ENVELOPE'
			},
			{
				'name': 'Pak',
				'value' : 'PAK'
			},
			{
				'name': 'Tube',
				'value' : 'TUBE'
			},
			{
				'name': 'Carrier Large Box',
				'value' : 'LARGE_BOX'
			},
			{
				'name': 'Carrier Medium Box',
				'value' : 'MEDIUM_BOX'
			},
			{
				'name': 'Carrier Small Box',
				'value' : 'SMALL_BOX'
			},
		];
		const fedexPackTypes = [
			{
				'name': 'FedEx Extra Large Box',
				'value' : 'FEDEX_EXTRA_LARGE_BOX'
			},
		];
		const intlPackTypes = [
			{
				'name': '10KG Box',
				'value' : '10KG_BOX'
			},
			{
				'name': '25KG Box',
				'value' : '25KG_BOX'
			},
		];

		const nonGroundPackTypes = [
			{
				'name': 'Express Box',
				'value' : 'EXPRESS_BOX'
			},
		];

		const upsIntlPackTypes = [
			{
				'name': 'Pallet',
				'value' : 'PALLET'
			},
		];

		/**
		 * these special packaging types require priority or first class services from UPS
		 * which we aren't supporting right now. Leaving here for future use.
		const upsSpecialPackTypes = [
			{
				'name': 'Priority',
				'value' : 'UPS_PRIORITY'
			},
			{
				'name': 'Flats 57 Parcel',
				'value' : 'UPS_57_BOX'
			},
			{
				'name': 'BPM First Class',
				'value' : 'UPS_BPM'
			},
			{
				'name': 'Standard Flat',
				'value' : 'UPS_STD_FLAT'
			},
			{
				'name': 'Parcel Post',
				'value' : 'UPS_PARCEL_POST'
			},
			{
				'name': 'Machinables',
				'value' : 'UPS_MACHINABLES'
			},
			{
				'name': 'Irregulars',
				'value' : 'UPS_IRREGULAR'
			},
			{
				'name': 'BPM Parcel',
				'value' : 'UPS_BPM_PARCEL'
			},
			{
				'name': 'UPS Media Mail',
				'value' : 'UPS_MEDIA_MAIL'
			},
			{
				'name': 'UPS BPM Flat',
				'value' : 'UPS_BPM_FLAT'
			},
		];
		 */

		let iterateArr = [];

		if(carrier == "FEDEX") {
			if(!service.includes("GROUND")) {
				iterateArr = nonExclusivePackaging;
				//express box is not valid for ground or EXPRESS Saver
				if(service != "FEDEX_EXPRESS_SAVER") {
					nonGroundPackTypes.map((prop) => {
						iterateArr.push(prop)
					});
				}
				//other fedEx pack types that are valid for everything but ground
				fedexPackTypes.map((prop) => {
					iterateArr.push(prop)
				});

				//packageTypes that are exclusive to international FedEx.

				if(service.includes("INTERNATIONAL")) {
					intlPackTypes.map((prop) => {
						iterateArr.push(prop)
					});
				};
			}
		} else if(carrier == "UPS") {
			//Not ground or 3 day.
			if(service !== "03" && service !== "12") {
				iterateArr = nonExclusivePackaging;
				nonGroundPackTypes.map((prop) => {
					iterateArr.push(prop)
				});
				if(this.state.consignee_country != this.state.shipper_country) {
					intlPackTypes.map((prop) => {
						iterateArr.push(prop);
					});
				}

			}

		}

		return iterateArr.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	autoPrint = async (printer, shipmentId, JSPM, labelType = 'label') => {
		this.props.autoPrint(printer, shipmentId, JSPM, labelType, this.newShipment());
	}

	async viewLabel() {
		const { shipmentId, shipDate, labelType } = this.state.shipmentInfo;
		if (labelType == "image") {
			const labelImgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId, "Shipping label(s)", "resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
			if (labelImgWindow) {
				try {
					labelImgWindow.focus();
				} catch (error) {
					console.error(error);
					this.props.setNotification("There was an error loading the labels!", { variant: "error" });
				}
			}
			this.newShipment();
		} else {
			try {
				const url = "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId;
				const response = await axios.get(url);

				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}

				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					this.setState({
						zpl: response.data.body.commands,
						printer: "",
						printLabelModal: true
					});
				} else {
					this.newShipment();
					this.props.setNotification("There was an error viewing the label!", { variant: "error" });
				}
			} catch (error) {
				this.newShipment();
				console.error(error);
				this.props.setNotification("There was an error viewing the label!", { variant: "error" });
			}
		}
	}

	viewDgDocs = async () => {
		const { shipmentId } = this.state.shipmentInfo;

		let docTypes = await this.getDocumentTypes(shipmentId);

		for(var option in docTypes) {
			if(this.props.dgDocTypes.includes(option)) {
				let imgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewDgDocuments&id=" + shipmentId + "&type=" + option, "Dangerous Goods Documents " + option,"resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
				if (imgWindow) {
					try {
						imgWindow.focus();
					} catch (error) {
						console.error(error);
						this.props.setNotification("There was an error loading the documents!", { variant: "error" });
					}
				}
				else {
					this.props.setNotification("If multiple windows did not open, check your browsers popup blocker.", { variant: "info" })
				}
			}
		}

	}

	viewNonDgDocs = async () => {

		const { shipmentId } = this.state.shipmentInfo;

		const printer = window.localStorage.getItem('DOCUMENTS');

		let availableDocs = await this.getDocumentTypes(shipmentId);
		let zpl = "";
		for(var type in availableDocs) {
			// for now we only have the DG docs and costco label, but in the future we could support other doc types (ZPL the same as below, or PDF/image docs as we do in viewDgDocs)
			// get the ZPL of each file and combine it into a single string to print below.
			if(!this.props.dgDocTypes.includes(type)) {
				if(this.props.zplDocTypes.includes(type)) {
					const response = await axios.get("/index.php?p=api&c=parcelShipService&m=viewDocuments&id=" + shipmentId + "&type=" + type);
					if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
						this.handleMessages(response.data.message);
					}
					if(response && response.data && response.data.body && response.data.body.document) {
						zpl = zpl + response.data.body.document;
					}
				}
			}
		}

		if(zpl != "") {
			if (!_.isEmpty(printer)) {
				await this.props.printDocument(printer, zpl, this.props.JSPM);
			} else {
				this.setState({
					docZpl: zpl,
					printer: "",
					printDocModal: true
				});
			}
		}

	}

	getDocumentTypes = async (shipmentId) => {
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=parcel&m=getDocumentTypes&parcelId=" + shipmentId);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);

			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.options)) {
				const opts = response.data.body.options;
				return opts;
			} else {
			 	this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	async newShipment() {
		await this.setState({
			consignee_company: '',
			consignee_contact: '',
			consignee_email: '',
			consignee_address1: '',
			consignee_address2: '',
			consignee_city: '',
			consignee_zip: '',
			consignee_state: '',
			consignee_phone: '',
			consignee_country: "US",
			dropoff: "REGULAR_PICKUP",
			packaging_type: "BOX",
			standard_units: true,
			packages: [
				{
					id: "",
					length: "",
					width: "",
					height: "",
					weight: "",
					declared_value: '',
					quantity: 1,
					hazardous: false,
					description: "",
					hazmat: {
						un: "",
						class: "",
						subHazardClass: "",
						properShippingName: "",
						packingGroup: "",
						packingInstructionCode: "",
						technicalName: "",
						packagingType: "",
						innerContainerType: "",
						regulatoryQuantity: "",
						uom: "",
						quantity: "",
						transportationMode: "",
						accessibility: "",
						requiredLabel: "",
						emergencyPhone: "",
						intlEmergencyPhone: "",
						emergencyResponseRegistrant: "",
						offeror: "",
						signatoryContactName: "",
						signatoryTitle: "",
						signatoryPlace: ""
					}
				}
			],
			general: null,
			shipmentInfo: {
				shipmentId: "",
				trackingId: "",
				totalCharge: "",
				billingType: "",
				acctNo: "",
				labelType: "",
				carrier: "",
				serviceType: "",
				shipDate: ""
			},
			lookup: '',
			residential: false,
			signature: false,
			cod: false,
			ship_date: '',
			customs: {
				customs_value: 0.00,
				commodity_units: 0.00,
				unit_value: 0.00,
				origin_country: "",
				part_number: "",
				description: "",
				import_uom: "",
				export_reason: "",
			},
			references: [],
			declared_value: '',
			description: '',
			rateQuote: '',
			billing_type: 'account',
			third_party_billing_account: "",
			default_label_save: false,
			include_costco_label: false,
			asn: false,
			so_num: '',
			po_num: '',
			dept_num: '',
			ref_num: '',
			zpl: "",
			hazmat: false,
		});
		this.loadWarehouseDefaults(this.state.warehouse);
		this.loadLocationDefaults(this.state.warehouse);
	}

	getPrinters(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	printLabel() {
		const { JSPM, printer, zpl, default_printer_save } = this.state;
		const { carrier } = this.state.shipmentInfo;

		const cpj = new JSPM.ClientPrintJob();
		cpj.clientPrinter = new JSPM.InstalledPrinter(printer);

		if (default_printer_save === true) {
			window.localStorage.setItem(carrier.toUpperCase(), printer);
			this.props.setNotification("Default label printer has been set for " + carrier + ".", { variant: "success" });
		}

		cpj.printerCommands = zpl; // Set printer commands...
		cpj.sendToClient(); // Send print job to printer!

		this.setState({ printLabelModal: false });
		this.newShipment();
	}


	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleInput(name, e) {
		let signatoryInfo = [
			'offeror',
			'emergency_phone',
			'intl_emergency_phone',
			'emergency_response_registrant',
			'signatory_contact_name',
			'signatory_contact_title',
			'signatory_place',
		];
		if(name in this.state.customs) {
			let customs = {...this.state.customs};
			customs[name] = e.target.value;
			this.setState({
				customs
			});
		} else {
			if(this.state.is_inbound && signatoryInfo.includes(name)) {
				name = 'inbound_' + name;
			}
			this.setState({ [name]: e.target.value });

			if (name.indexOf("zip") !== -1 && name != 'third_party_account_zip') {
				const type = name.replace("_zip", "");
				const zip = e.target.value;
				// Update country to Canada if zip has a letter
				const regex = /[a-z]/i;
				const country = regex.test(zip) ? 'CA' : this.state[`${type}_country`];
				this.handleZip(name, zip, country);
				this.setState({ rateQuote: '' });
			} else if (name.indexOf("consignee") !== -1) {
				this.setState({ rateQuote: '' });
			}
		}
	}

	handleCommodityDescription(e) {
		let value = e.target.value.trim();
		let words = value.split(" ");
		if(words.length <= 3 && words.length != 0) {
			this.setState({showWarningText: true});
		} else {
			this.setState({showWarningText: false});
		}
	}

	async handleZip(name, zip, country = null) {
		zip = zip.replace(" ", "").toUpperCase();
		let isNum = false;
		isNum = !(Number.isNaN(zip) || Number.isNaN(parseInt(zip)));
		if (!_.isEmpty(zip) && ((isNum) && zip.length >= 5) || zip.length >= 6) {
			try {
				let url = "/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + encodeURIComponent(zip);
				url += country != null ? `/${convertToCountryCode(country, 'long')}` : ``;
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					const { body } = response.data;
					if (body[zip]) {
						if (!["USA","CAN","MEX"].includes(body[zip][0].country)) {
							this.setState({ loading: false });
							this.props.setNotification("International Shipment - Contact us for information regarding International Shipments.", { variant: "error" });
						}
						if (name.indexOf("shipment_zip") !== -1) {
							name = name.split("_")[0] + "_shipment"; // get name from the first index and append with _shipment
						} else {
							name = name.split("_")[0]; // get name from the first index
						}

						const state = {
							[name + "_country"]: convertToCountryCode(body[zip][0].country, 'short'),
							[name + "_state"]: body[zip][0].state,
							[name + "_city"]: body[zip][0].city
						};

						this.setState(state);
						this.loadServices();
					} else {
						this.props.setNotification("The postal code could not be found. Chances are it is not valid.", { variant: "error" });
					}
				} else {
					this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
			}
		}
	}

	async handleChange(e) {
		if(this.state.is_inbound && this.state.hazmat && e.target.name == 'consignee_country' && e.target.value != 'US') {
			this.props.setNotification("We currently do not support HazMat or Dangerous Goods import shipments. Either change this to an outbound shipment, or remove any hazardous packages from the shipment.", { variant: "error" });
			return;
		}
		await this.setState({ [e.target.name]: e.target.value });
		let JSPM = this.props.JSPM;

		if(e.target.name in this.state.customs) {
			let customs = {...this.state.customs};
			customs[e.target.name] = e.target.value;
			this.setState({customs});
		}
		if (e.target.name == 'consignee_country') {
			this.setState({
				rateQuote: '',
				consignee_city: '',
				consignee_state: '',
			}, () => this.handleZip('consignee', this.state.consignee_zip, e.target.value));
		}

		if(e.target.name == 'warehouse') {
			this.loadWarehouseDefaults(e.target.value);
			await this.loadLocationDefaults(e.target.value);
			this.loadServices();
			this.updateCarriers(e.target.value);
			this.setState({ rateQuote: '' });
			if(this.state.billing_type === 'third_party') {
				this.setState({ third_party_billing_account: '' });
			}
		}
		if(e.target.name == 'consignee_country') {
			this.loadServices(e.target.value);
		}
		if(e.target.name == 'carrier') {
			this.loadServices();
			if(e.target.value == 'USPS') {
				this.setState({billing_type: 'account'});
			} else if(e.target.value == 'UPS') {
				if(this.state.signature_type == 'INDIRECT') {
					this.setState({signature_type: 'DIRECT'});
				}
			}
			if(e.target.value == "FEDEX") {
				this.setState({
					cod: false,
					cod_collection_type: '',
					cod_amount: '',
				});
			}
			if(this.state.billing_type == 'third_party') {
				this.setState({ third_party_billing_account: '' });
				if(e.target.name != 'USPS') {
					this.getThirdPartyAccounts(this.state.third_party_accounts);
				}
			}
			// Preventing an invalid label size being sent to the back end when it appears there is no size selected
			await this.setState({label_size: '4X6', rateQuote: ''});
		}

		if (e.target.name == 'service') {
			await this.setState({
				rateQuote: '',
				packaging_type: 'BOX',
			});
		}

		if (e.target.name == 'packaging_type') {
			this.setState({ rateQuote: '' });
		}

		if(e.target.name == 'label_type') {
			if(e.target.value == 'ZPL') {
				if(JSPM == null) {
					JSPM = window.JSPM;
					this.props.setJSPM(JSPM);
				}
				this.props.setAvailablePrinters(JSPM);

			}
			this.setHazardousLabelSizes();
		}


		if(e.target.name == 'return_name') {
			this.setState({ rateQuote: '' });

			if(e.target.value != 'sender') {
				await this.setState({
					return_company: this.state.return_addresses[e.target.value].name,
					return_contact: this.state.return_addresses[e.target.value].contact_name,
					return_email: this.state.return_addresses[e.target.value].contact_email,
					return_address1: this.state.return_addresses[e.target.value].address1,
					return_address2: this.state.return_addresses[e.target.value].address2,
					return_city: this.state.return_addresses[e.target.value].city,
					return_zip: this.state.return_addresses[e.target.value].zip,
					return_state: this.state.return_addresses[e.target.value].state,
					return_country: this.state.return_addresses[e.target.value].country || 'US',
					return_phone: this.state.return_addresses[e.target.value].contact_phone,
				});
			} else {
				await this.setState({
					return_company: this.state.shipper_company,
					return_contact:	this.state.shipper_contact,
					return_email:	this.state.shipper_email,
					return_address1: this.state.shipper_address1,
					return_address2: this.state.shipper_address2,
					return_city: this.state.shipper_city,
					return_zip:	this.state.shipper_zip,
					return_state: this.state.shipper_state,
					return_country: this.state.shipper_country,
					return_phone: this.state.shipper_phone,
				});
			}
		}

		if(e.target.name == 'billing_type') {
			if(e.target.value == 'third_party') {
				if(_.isEmpty(this.state.third_party_accounts)) {
					this.loadThirdPartyAccounts();
				}
			}
		}
	}

	async loadWarehouseDefaults(warehouseCode) {
		const required = [...this.state.required];
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=warehouse&m=getDefaults&d=${warehouseCode}`);
			if(typeof response.data !== 'string' && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				let customReferences = [];
				response.data.body.custom_references.forEach(reference => {
					customReferences.push({
						'type': reference.name,
						'value': reference.value || '',
						'required': reference.required,
						'cust_ref_entry_type': reference.cust_ref_entry_type,
						'cust_ref_entry_opts': reference.cust_ref_entry_opts,
					});
				});
				// Sort to put any required warehouse references at the top
				customReferences.sort(function(a,b){
					if(a.required == 1 && b.required == 0) {
						return -1;
					}
					if(a.required == 0 && b.required == 1) {
						return 1;
					}
					return 0;
				});

				ExtraRequiredFieldsMap.map(field => {
					if(response.data.body.require_refs.hasOwnProperty(field.settingName)) {
						let requireRefIndex = required.indexOf(field.inputName)
						if(response.data.body.require_refs[field.settingName] == 1) {
							if(requireRefIndex < 0) {
								required.push(field.inputName);
							}
						} else {
							if(requireRefIndex >= 0) {
								required.splice(requireRefIndex, 1)
							}
						}
					}
				});

				this.setState({
					references: customReferences,
					enable_product_part_no : response.data.body.enable_product_part_no == '1' ? true : false,
					required,
				});
			}
			this.loadThirdPartyAccounts();
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error loading warehouse defaults!", { variant: "error" });
		}
	}

	handleReference(refList) {
		this.setState({references: refList.references});
	}

	async handleChecked(name, event) {
		await this.setState({ [name]: event.target.checked });

		let clearQuoteCheckboxes = ["residential", "cod", "signature", "saturday_delivery", "asn", "carbon_neutral"]

		if (clearQuoteCheckboxes.some(checkbox => { return name.indexOf(checkbox) !== -1 })) {
			this.setState({ rateQuote: '' });
		}
		if(name == 'residential') {
			this.loadServices();
		}
		if(name == 'signature') {
			if(this.state.carrier == 'UPS') {
				this.setState({signature_type: 'DIRECT'});
			} else {
				this.setState({signature_type: 'INDIRECT'});
			}
		}

		if(name == 'include_costco_label') {
			let JSPM = this.props.JSPM;
			if(JSPM == null) {
				JSPM = window.JSPM;
				this.props.setJSPM(JSPM);
			}
			this.props.setAvailablePrinters(JSPM);
		}
	}


	async handleDatetime(name, moment) {
		await this.setState({ [name]: moment });
	}

	handlePackage(i, name, event) {
		const { packages } = this.state;
		const prop = packages[i];
		const exceededWeightLimit = this.exceedsWeightLimit();
		prop[name] = event.target.value;
		packages[i] = prop;
		this.setState({ packages, rateQuote: '' }, function() {
			if (!exceededWeightLimit && this.exceedsWeightLimit()) {
				this.props.setNotification("This shipment cannot be rated or scheduled because it exceeds your organization's weight limit for parcel shipments.", { variant: "error" });
			}
		});
	}

	async toggleHazardous() {
		if(this.state.is_inbound && this.state.consignee_country != this.state.shipper_country) {
			this.props.setNotification("We currently do not support HazMat or Dangerous Goods import shipments. Either change this to an outbound shipment, or change the shipper to a US shipper.", { variant: "error" });
			return;
		}
		const { packages } = this.state;
		packages[0].hazardous = !packages[0].hazardous;
		packages.length = 1;
		const hazmat = packages[0].hazardous;
		if(hazmat) {
			if(this.state.carrier == "FEDEX") {
				this.setState({
					packages,
					hazmat,
					carrier: this.state.carrier,
					service: this.state.service,
				});
			} else {
				await this.setState({
					packages,
					hazmat,
					carrier: "FEDEX",
					service: "FEDEX_GROUND",
				});
				this.loadServices();
			}
			this.setHazardousLabelSizes();
		} else {
			this.setState({
				packages,
				hazmat,
				carrier: this.state.carrier,
				service: this.state.service,
			});
		}
	}

	shipmentIsHazardous() {
		for(var key in this.state.packages) {
			if(this.state.packages[key].hazardous) {
				return true;
			}
		}
		return false;
	}

	setHazardousLabelSizes() {
		const { label_type, label_size } = this.state;

		if(this.shipmentIsHazardous()) {

			if((label_type == 'PAPER' && label_size == '4X6.75') || (label_type == 'STOCK' && (label_size == '4X6.75' || label_size == '4X9'))) {
				this.setState({ label_size: "4X6" });
			}
		}
	}

	getTotalWeight() {
		let total = 0;
		let packages = Array.isArray(this.state.packages) ? this.state.packages : [];
		packages.map(pack => {
			let thisPack = typeof pack === "object" ? pack : {};
			const packWeight = +thisPack.weight * +thisPack.quantity;
			total += isNaN(packWeight) ? 0 : packWeight;
		})
		return total;
	}
	exceedsWeightLimit = () => {
		return this.getTotalWeight() > this.props.weightLimit;
	}

	async loadThirdPartyAccounts() {
		try {
			const response = await axios.get('/index.php?p=api&r=json&c=parcel&m=getThirdPartyAccounts&d=' + this.state.warehouse);
			if(typeof response.data !== 'string' && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				this.setState({third_party_accounts: response.data.body});
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error loading the defaults!", { variant: "error" });
		}
	}

	async saveNewThirdPartyAccount() {
		this.setState({savingThirdPartyAccount: true});

		const name = this.state.third_party_account_name;
		const account = this.state.third_party_account_number;
		const zip = this.state.third_party_account_zip;

		if(_.isEmpty(name)) {
			this.props.setNotification("Name cannot be empty!", { variant: "error" });
			this.setState({savingThirdPartyAccount: false});
			return;
		}

		if(_.isEmpty(account)) {
			this.props.setNotification("Account number cannot be empty!", { variant: "error" });
			this.setState({savingThirdPartyAccount: false});
			return;
		}

		if(_.isEmpty(zip)) {
			this.props.setNotification("Zip code cannot be empty!", { variant: "error" });
			this.setState({savingThirdPartyAccount: false});
			return;
		}

		let data = {
			warehouse: this.state.warehouse,
			name: name,
			carrier: this.state.carrier,
			account: account,
			zip: zip,
		}

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=parcel&m=addThirdPartyAccount", qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if(response.data.body == true) {

				this.loadThirdPartyAccounts();
				this.handleModalClose('thirdPartyModal');

				this.setState({third_party_account_name: '', third_party_account_number: '', third_party_account_zip: ''});

			}

			this.setState({savingThirdPartyAccount: false});

		} catch(error) {
			console.error(error);
		}
	}

	async getReferences() {
		try {
			const response = await axios.get('/index.php?p=api&r=json&c=userSettings&m=getReferenceTypes');
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				let types = response.data.body;
				let refTypes = [];
				for(var i = 0; i < types.length; i++) {
					refTypes.push({value: types[i]});
				}
				this.setState({refTypes: refTypes, reference_type: refTypes[0].value});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the defaults!", { variant: "error" });
		}
	}

	getCustomRefs(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridItem key={key} xs={6}>
					<b>{prop.type} :</b><br/> {prop.value}
				</GridItem>
			)
		});
	}

	loadServices(consCountry = null) {
		let consignee_country = this.state.consignee_country;
		const enable_fedex_smartpost = this.state.account.hubId ? true : false;

		if(consCountry != null) {
			consignee_country = consCountry;
		}
		let services = loadServices(this.state.carrier, this.state.service, this.state.residential, this.state.shipper_country, consignee_country, this.state.is_inbound, enable_fedex_smartpost);
		if(!_.isEmpty(services.messages) && !_.isEmpty(services.messages.data) && !_.isEmpty(services.messages.data.message)) {
			this.props.handleMessages(services.messages.data.message);
		}
		if(services.hasOwnProperty('services') && services.hasOwnProperty('service') && services.hasOwnProperty('hazService')) {
			this.setState({
				services: services.services,
				service: services.service,
				hazService: services.hazService
			});
		}
	}

	getRefTypes(refs) {
		const { classes } = this.props;
		return refs.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.value}
				</MenuItem>
			);
		});
	}

	renderLabelSizes() {
		const { classes } = this.props;
		const { carrier, label_type } = this.state;
		let labels = this.getLabelSizes(carrier, label_type, this.shipmentIsHazardous());
		return labels.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getLabelSizes(carrier, label_type, hazardous) {
		let labels = [];
		if (carrier === "FEDEX") {
			let stockLabels = [
				{ value: "4X6", name: "4X6" },
				{ value: "4X6.75", name: "4X6.75" }, // already supported for normal shipments, but does not work with Hazmat
				{ value: "4X8", name: "4X8" },
				{ value: "4X9", name: "4X9" }, // already supported for normal shipments, but does not work with Hazmat
			];

			let paperLabels = [
				{ value: "4X6", name: "4X6" },
				{ value: "4X6.75", name: "4X6.75" }, // already supported for normal shipments, but does not work with Hazmat
				{ value: "4X8", name: "4X8" },
				{ value: "4X9", name: "4X9" },
				{ value: "7X4.75", name: "7X4.75"},
				{ value: "8.5X11_BOTTOM_HALF_LABEL", name: "8.5X11 with Trailing Label" },
				{ value: "8.5X11_TOP_HALF_LABEL", name: "8.5X11 with Leading Label" },
			]

			let zplLabels = [
				{ value: "4X6", name: "4X6" },
				{ value: "4X6.75_TRAILING_DOC_TAB", name: "4X6.75 With Trailing Doc-Tab" },
				{ value: "4X6.75_LEADING_DOC_TAB", name: "4X6.75 With Leading Doc-Tab" },
				{ value: "4X8", name: "4X8" },
				{ value: "4X9_TRAILING_DOC_TAB", name: "4X9 With Trailing Doc-Tab" },
				{ value: "4X9_LEADING_DOC_TAB", name: "4X9 With Leading Doc-Tab" },
			];

			// set label opts and remove unsupported types if a pkg is hazardous
			if (label_type === "PAPER") {

				labels = paperLabels;
				if (hazardous) {
					labels = labels.filter((labelOpt) => !(['4X6.75'].includes(labelOpt.value)));
				}

			} else if (label_type === "STOCK") {

				labels = stockLabels;
				if (hazardous) {
					labels = labels.filter((labelOpt) => !(['4X6.75', '4X9'].includes(labelOpt.value)));
				}

			} else if (label_type === "ZPL") {
				labels = zplLabels;
			}
		} else if (carrier === "UPS") {
			labels = [{ value: "4X6", name: "4X6" }, { value: "4X8.25_TRAILING_DOC_TAB", name: "4X8.25 With Trailing Doc Tab" }];
			if (label_type === "PAPER" || label_type === "STOCK") {
				labels.splice(1, 1);
			}
		} else if (carrier === "USPS") {
			labels = [{value: "4X6", name: "4X6"}];
		}
		return labels;
	}

	async handleContactSearch(type) {
		let url = "";
		let searchType = "";

		const { warehouse } = this.state;
		const searchBy = this.state["consignee_or_shipper_search_by"];
		const searchFor = this.state["consignee_or_shipper_search_for"];

		switch (type) {
			case "shipper":
				searchType = "shipper";
				break;
			case "consignee":
				searchType = "consignee";
				break;
			default:
				return false;
		}

		if (searchBy == "SEARCH_ANY") {
			url = "/index.php?p=api&r=json&c=location&m=findAnything&d=" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
		} else {
			url = "/index.php?p=api&r=json&c=location&m=find&d=" + searchBy + "/" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
		}
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string") {
				if (response.data.body.length == 1) {
					const contact = response.data.body[0];
					let country = convertToCountryCode(contact.country, 'short');
					if (country == '' && (country != 'CA' && country != 'MX')) {
						country = 'US';
					}

					//the fields to update will always be the consignee fields because
					//we use consignee fields as shipper when creating inbound shipments.
					this.setState({
						"consignee_company": contact.hasOwnProperty('location_name') ? contact.location_name : '',
						"consignee_email": contact.hasOwnProperty('email') ? contact.email : '',
						"consignee_address1": contact.hasOwnProperty('address1') ? contact.address1 : '',
						"consignee_address2": contact.hasOwnProperty('address2') ? contact.address2 : '',
						"consignee_zip": contact.hasOwnProperty('zip') ? contact.zip : '',
						"consignee_city": contact.hasOwnProperty('city') ? contact.city : '',
						"consignee_state": contact.hasOwnProperty('state') ? contact.state : '',
						"consignee_country": country,
						"consignee_contact": contact.hasOwnProperty('contact_name') ? contact.contact_name : '',
						"consignee_phone": contact.hasOwnProperty('phone') ? contact.phone : '',
						residential: (contact.hasOwnProperty('residential') && contact.residential == true) ? true : false,
						contactSearchModal: false,
						contacts: [],
						contactSearchType: "",
						inbound_offeror: contact.offeror || '',
						inbound_emergency_phone: contact.emergency_phone || '',
						inbound_intl_emergency_phone: contact.intl_emergency_phone || '',
						inbound_emergency_response_registrant: contact.emergency_response_registrant || '',
						inbound_signatory_contact_name: contact.signatory_contact_name || '',
						inbound_signatory_contact_title: contact.signatory_contact_title || '',
						inbound_signatory_place: contact.signatory_place || ''
					});
				} else if (response.data.body.length > 1) {

					this.setState({
						contacts: response.data.body,
						contactSearchType: type,
						contactSearchModal: true
					});
				} else {
					this.props.setNotification("No contacts found!", {
						variant: "info"
					});
				}
			} else {
				this.props.setNotification("There was an error searching contacts!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching contacts!", { variant: "error" });
		}
	}

	clearAddress(type) {
		this.setState({
			[type + "_company"]: "",
			[type + "_contact"]: "",
			[type + "_phone"]: "",
			[type + "_email"]: "",
			[type + "_address1"]: "",
			[type + "_address2"]: "",
			[type + "_city"]: "",
			[type + "_state"]: "",
			[type + "_zip"]: "",
			[type + "_country"]: "US",
			[type + "_shipment_zip"]: "",
			[type + "_shipment_city"]: "",
			[type + "_shipment_state"]: "",
			[type + "_shipment_country"]: "US"
		});
	}

	handleAddress(type) {
		const address = {
			address1: this.state[type + "_address1"],
			address2: this.state[type + "_address2"],
			zip: this.state[type + "_shipment_zip"],
			city: this.state[type + "_shipment_city"],
			state: this.state[type + "_shipment_state"]
		};

		let addressSearchString = "";

		for (const key in address) {
			const val = address[key];
			if (typeof val === "string" && _.trim(val) !== "") {
				if (addressSearchString === "") {
					addressSearchString = _.trim(val);
				} else {
					addressSearchString += " " + _.trim(val);
				}
			}
		}

		if (addressSearchString !== "") {
			const addressSearchURL = "http://www.google.com/maps/place/" + encodeURIComponent(addressSearchString);
			const addressSearchTitle = "Address Search";
			const addressSearchOpts = "resizable=1, scrollbars=1, fullscreen=0, status=0";

			window.open(addressSearchURL, addressSearchTitle, addressSearchOpts);
		}
	}

	getContacts(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<div dangerouslySetInnerHTML={{ __html: prop.code }} />,
				<address>
					<strong>
						<div
							dangerouslySetInnerHTML={{
								__html: prop.location_name
							}}
						/>
					</strong>
					<div dangerouslySetInnerHTML={{ __html: prop.address1 }} />
					{!_.isEmpty(prop.address2) && <div dangerouslySetInnerHTML={{ __html: prop.address2 }} />}
					<div
						dangerouslySetInnerHTML={{
							__html: prop.city + ", " + prop.state + " " + prop.zip + ", " + prop.country
						}}
					/>
				</address>,
				<GridContainer>
					{!_.isEmpty(prop.contact_name) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<Person />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.contact_name }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.phone) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<Phone />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.phone }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.email) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<AlternateEmail />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.email }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
				</GridContainer>,
				<div className="actions-right">
					<Button size="sm" color="linkedin" onClick={e => this.handleContactSelect(key, this.state.contactSearchType)}>
						Select
					</Button>
				</div>
			];
		});
	}


	handleContactSelect(contactKey, type) {
		const { contacts } = this.state;
		const contact = {};
		Object.keys(contacts[contactKey]).map(key => {
			const prop = contacts[contactKey][key];
			if (prop && typeof prop === "string") {
				contact[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
			}
		});
		let country = convertToCountryCode(contact.country, 'short');
		if (country == '' && (country != 'CA' && country != 'MX')) {
			country = 'US';
		}

		//the fields to update will always be the consignee fields because
		//we use consignee fields as shipper when creating inbound shipments.
		this.setState({
			"consignee_company": contact.hasOwnProperty('location_name') ? contact.location_name : '',
			"consignee_email": contact.hasOwnProperty('email') ? contact.email : '',
			"consignee_address1": contact.hasOwnProperty('address1') ? contact.address1 : '',
			"consignee_address2": contact.hasOwnProperty('address2') ? contact.address2 : '',
			"consignee_zip": contact.hasOwnProperty('zip') ? contact.zip : '',
			"consignee_city": contact.hasOwnProperty('city') ? contact.city : '',
			"consignee_state": contact.hasOwnProperty('state') ? contact.state : '',
			"consignee_country": country,
			"consignee_contact": contact.hasOwnProperty('contact_name') ? contact.contact_name : '',
			"consignee_phone": contact.hasOwnProperty('phone') ? contact.phone : '',
			residential: (contact.hasOwnProperty('residential') && contact.residential == true) ? true : false,
			contactSearchModal: false,
			contacts: [],
			contactSearchType: "",
			inbound_offeror: contact.offeror || '',
			inbound_emergency_phone: contact.emergency_phone || '',
			inbound_intl_emergency_phone: contact.intl_emergency_phone || '',
			inbound_emergency_response_registrant: contact.emergency_response_registrant || '',
			inbound_signatory_contact_name: contact.signatory_contact_name || '',
			inbound_signatory_contact_title: contact.signatory_contact_title || '',
			inbound_signatory_place: contact.signatory_place || ''
		});
		if (!_.isEmpty(contact.zip) && contact.zip.length >= 5) {
			this.handleZip(type, contact.zip);
		}
	}

	getClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.state;
		let data = ["1.4","1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N",
			"2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.2", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	getSubClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.state;
		let data = ["1.4","1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N",
			"2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.2", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		if (regulation_set === "cfr") { // Hanging on to this in case it is a UPS thing?
			data = ["1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N", "2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		}
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	addPackage() {

		const { packages } = this.state;
		packages.push({
			length: '',
			width: '',
			height: '',
			weight: '',
			quantity: 1,
			declared_value: "",
			hazardous: false,
			description: "",
			hazmat: {
				un: "",
				class: "",
				subHazardClass: "",
				properShippingName: "",
				packingGroup: "",
				packingInstructionCode: "",
				technicalName: "",
				packagingType: "",
				innerContainerType: "",
				regulatoryQuantity: "",
				uom: "",
				quantity: "",
				transportationMode: "",
				accessibility: "",
				requiredLabel: "",
			}
		});

		this.setState({ packages });

	}

	handleDeletePackage(i) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this package?"
					onConfirm={() => this.deletePackage(i)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	removePackage(i) {
		const { packages } = this.state;

		if (!_.isEmpty(packages[i]) && !_.isEmpty(packages[i].id)) {
			this.handleDeletePackage(i);
		} else {
			this.deletePackage(i);
		}
	}

	async deletePackage(i) {
		this.setState({ alert: null });

		const { packages } = this.state;
		if (!_.isEmpty(packages[i]) && !_.isEmpty(packages[i].id)) {
			try {
				const response = await axios.post("/index.php?p=api&c=parcelShipService&m=deletePackage&d=" + packages[i].id);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && response.data.body.deleted) {
					packages.splice(i, 1);
					this.setState({ packages });

					if (packages.length == 0) {
						this.setState({
							packages: [
								{
									id: "",
									length: "",
									width: "",
									height: "",
									weight: "",
									declared_value: "",
									quantity: 1,
								}
							]
						});
					}
				} else {
					this.props.setNotification("The package was not deleted.", {
						variant: "error"
					});
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error removing the package!", {
					variant: "error"
				});
			}
		} else {
			if (i !== 0) {
				packages.splice(i, 1);
				this.setState({ packages });
			}
		}
	}

	getPackages(packages) {
		const { classes } = this.props;
		const { packaging_type } = this.state;
		return packages.map((prod, key) => {
			const packageClassName = prod.hazardous ? classes.hazmat : classes.package;
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={packageClassName} style={{paddingTop: key != 0 ? '5px' : '0px', paddingBottom: key != 0 ? '0px' : '5px'}}>
						<GridContainer>
							<GridItem xs>
								{key == 0 && (
									<small>Length</small>
								)}
								{/* packaging_type "BOX" is custom packaging. Other than that, the carrier will already know the dimensions.*/}
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "length",
										value: packaging_type === "BOX" ? prod.length : '',
										disabled: packaging_type !== "BOX",
										endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'in.' : 'cm'}</InputAdornment>,
										onChange: e => this.handlePackage(key, "length", e)
									}}

									required={this.state.packaging_type == 'BOX'}
								/>
							</GridItem>
							<GridItem xs>
								{key == 0 && (
									<small>Width</small>
								)}
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "width",
										value: packaging_type === "BOX" ? prod.width : '',
										disabled: packaging_type !== "BOX",
										endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'in.' : 'cm'}</InputAdornment>,
										onChange: e => this.handlePackage(key, "width", e)
									}}
									required={this.state.packaging_type == 'BOX'}
								/>
							</GridItem>
							<GridItem xs>
								{key == 0 && (
									<small>Height</small>
								)}
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "height",
										value: packaging_type === "BOX" ? prod.height : '',
										disabled: packaging_type !== "BOX",
										endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'in.' : 'cm'}</InputAdornment>,
										onChange: e => this.handlePackage(key, "height", e)
									}}
									required={this.state.packaging_type == 'BOX'}
								/>
							</GridItem>
							<GridItem xs>
								{key == 0 && (
									<small>Weight</small>
								)}
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "weight",
										value: prod.weight,
										endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'lbs' : 'kg'}</InputAdornment>,
										onChange: e => this.handlePackage(key, "weight", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								{key == 0 && (
									<small>Qty</small>
								)}
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "number",
										inputProps: { min: 1, max: 30 },
										name: "quantity",
										value: prod.quantity,
										onChange: e => this.handlePackage(key, "quantity", e)
									}}
									required
								/>
							</GridItem>
							<GridItem xs>
								{key == 0 && (
									<small>Decl. Val</small>
								)}
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "declared_value",
										value: prod.declared_value,
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										onChange: e => this.handlePackage(key, "declared_value", e)
									}}
									required={this.state.consignee_country != this.state.shipper_country}
								/>
							</GridItem>
							<GridItem xs>
								{key === 0 && (
									<WithTooltip
										title="Toggle Hazmat"
										content="Note: Multi-package Hazmat/Dangerous Goods Shipments are unsupported"
									>
										<span>
											<Button
												size="sm"
												justIcon
												round
												color="danger"
												style={{ paddingTop: "2px", display: this.state.showHaz == true ? "" : "none" }}
												className={classes.chip}
												onClick={() => this.toggleHazardous()}
												disabled={this.state.packages.length > 1}
											>
												<ReportProblem />
											</Button>
										</span>
									</WithTooltip>
								)}
								{(key !== 0 || !_.isEmpty(prod.id)) && (
									<Button
										size="sm"
										justIcon
										round
										color="danger"
										onClick={() => this.removePackage(key)}
									>
										<Close />
									</Button>
								)}
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={6}>
								<InputLabel className={classes.label}>Description</InputLabel>
								<br/>
								<CustomInput
									formControlProps={{ fullWidth: false }}
									inputProps={{
										type: "text",
										name: "description",
										value: prod.description || '',
										onChange: (e) => {
											this.handlePackage(key, "description", e);
										},
									}}
								/>
								<WithTooltip
									title="Load A Product (Commodity)"
									content="Choose a pre-configured Product (Commodity) from the Product Catalog."
								>

									<span>
										<Button color="white" size="sm" justIcon round onClick={e => this.handleProductSearch(key)}  >
											<Search />
										</Button>
									</span>

								</WithTooltip>
							</GridItem>
							<GridItem xs={4}>
								<small>Part #</small>
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "part_no",
										value: prod.part_no,
										onChange: e => this.handlePackage(key, "part_no", e)
									}}
								/>
							</GridItem>
						</GridContainer>
						{prod.hazardous === true && (
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<HazmatInfo
										packages={this.state.packages}
										getTotalWeight={this.getTotalWeight}
										prod={prod}
										pos={key}
										state={this.state}
										setHazUNInfo={this.setHazUNInfo}
									/>
								</GridItem>
							</GridContainer>
						)}
					</GridItem>
				</GridContainer>
			);
		});
	}

	getAccount(carrier) {
		let account = '';
		for(let i = 0; i < this.state.accounts.length; i ++) {
			if(this.state.accounts[i].name == carrier) {
				account = this.state.accounts[i].account;
			}
		}
		return (account);
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.code}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getReturnAddresses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={key}
				>
					{prop.name} - {prop.code}
				</MenuItem>
			);
		});
	}

	getThirdPartyAccounts(data) {
		const { classes } = this.props;

		let carrierAccounts = data[this.state.carrier];

		if(!_.isEmpty(carrierAccounts)) {

			return carrierAccounts.map((prop, key) => {
				return (
					<MenuItem
						key={key}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected
						}}
						value={prop.account}
					>
						{prop.name} - {prop.account}
					</MenuItem>
				);
			});
		}

	}

	async updateCarriers(warehouse) {
		let carriers = await loadCarriers(warehouse, this.state.dg_enabled);

		if(!_.isEmpty(carriers.messages) && !_.isEmpty(carriers.messages.data) && !_.isEmpty(carriers.messages.data.message)) {
			this.props.handleMessages(carriers.messages.data.message);
		}
		if(carriers.hasOwnProperty('carriers') && carriers.hasOwnProperty('carrier')) {
			flushSync(() => {
				this.setState({
					hazCarriers: carriers.hazCarriers,
					carriers: carriers.carriers,
					carrier: carriers.carriers[0],
					accounts: carriers.accounts,
					account: carriers.accounts[0],
					showHaz: carriers.showHaz,
				});
			});
		}

		this.loadServices();
	}

	async loadLocationDefaults(warehouse) {
		try {
			this.setState({selected_warehouse: warehouse});
			const url = "/index.php?p=api&r=json&c=location&m=defaults&d=" + encodeURIComponent(warehouse) + "&returns=1";
			const response = this.props.hasAddressBookRead ? await axios.get(url) : null;
			if (response && typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (response && typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				const { body } = response.data;
				if (!_.isEmpty(body.shipper)) {
					flushSync(() => {
						this.setState({ // maybe update here
							shipper_company: body.shipper.name || '',
							shipper_contact: body.shipper.contact_name || '',
							shipper_email: body.shipper.contact_email || '',
							shipper_address1: body.shipper.address1 || '',
							shipper_address2: body.shipper.address2 || '',
							shipper_city: body.shipper.city || '',
							shipper_zip: body.shipper.zip || '',
							shipper_state: body.shipper.state || '',
							shipper_country: convertToCountryCode(body.shipper.country, 'short') || 'US',
							shipper_phone: body.shipper.contact_phone || '',
							return_company: body.shipper.name || '',
							return_contact: body.shipper.contact_name || '',
							return_email: body.shipper.contact_email || '',
							return_address1: body.shipper.address1 || '',
							return_address2: body.shipper.address2 || '',
							return_city: body.shipper.city || '',
							return_zip: body.shipper.zip || '',
							return_state: body.shipper.state || '',
							return_country: convertToCountryCode(body.shipper.country, 'short') || 'US',
							return_phone: body.shipper.contact_phone || '',
							return_name: 'sender',
							carrier: body.shipper.carrier || '',
							service: body.shipper.service || '',
							cod: body.shipper.cod || false,
							cod_collection_type: body.shipper.cod_collection_type || '',
							signature: body.shipper.signature || false,
							signature_type: body.shipper.signature_type || '',
						});
					});

					if(!this.state.is_inbound) {
						this.setState({
							offeror: body.shipper.offeror || '',
							emergency_phone: body.shipper.emergency_phone || '',
							intl_emergency_phone: body.shipper.intl_emergency_phone || '',
							emergency_response_registrant: body.shipper.emergency_response_registrant || '',
							signatory_contact_name: body.shipper.signatory_contact_name || '',
							signatory_contact_title: body.shipper.signatory_contact_title || '',
							signatory_place: body.shipper.signatory_place || ''
						});
					}
				} else {
					this.props.setNotification("There is not a default shipper assigned for this location!", { variant: "error" });
					this.setState({
						shipper_company: '',
						shipper_contact: '',
						shipper_email: '',
						shipper_address1: '',
						shipper_address2: '',
						shipper_city: '',
						shipper_zip: '',
						shipper_state: '',
						shipper_country: 'US',
						shipper_phone: '',
						return_company: '',
						return_contact: '',
						return_email: '',
						return_address1: '',
						return_address2: '',
						return_city: '',
						return_zip: '',
						return_state: '',
						return_country: '',
						return_phone: '',
						return_name: 'sender',
						carrier: '',
						service: '',
						offeror: '',
						emergency_phone: '',
						intl_emergency_phone: '',
						emergency_response_registrant: '',
						signatory_contact_name: '',
						signatory_contact_title: '',
						signatory_place: '',
						cod: false,
						cod_collection_type: '',
						signature: false,
						signature_type: '',
					});
				}

				if(this.state.carrier == "") {
					this.updateCarriers(warehouse);
				}
				if(!_.isEmpty(body.returns)) {
					this.setState({return_addresses: body.returns})
				}
			}
			this.loadServices();
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the zip code for this warehouse!", { variant: "error" });
		}
	}

	async checkForResi() {
		this.setState({ loadingResidentialCheck: true });

		const resiData = {
			street: this.state.consignee_address1,
			city: this.state.consignee_city,
			state: this.state.consignee_state,
			zip: this.state.consignee_zip,
			country: this.state.consignee_country,
			code: this.state.warehouse,
		};

		if (resiData.street == '' || resiData.city == '' || resiData.zip == '') {
			this.props.setNotification("Street, city and zip are required to check an address!", { variant: "error" });
		} else {
			try {
				if (this.state.fedex_address_validation == true) {
					const url = "/index.php?p=api&r=json&c=parcelShipService&m=fedexAddressValidation";
					const response = await axios.post(url, qs.stringify(resiData));

					if (typeof response.data !== "string") {
						this.props.handleMessages(response);
						const data = response.data;

						if (!_.isEmpty(data.body.classification)) {
							if (data.body.classification != "UNKNOWN") {
								if (data.body.classification == "RESIDENTIAL") {
									this.props.setNotification("This address is considered residential by FedEx.", { variant: "info" });
									this.setState({ residential: true });
								} else {
									this.props.setNotification("This address is considered non-residential by FedEx.", { variant: "info" });
									this.setState({ residential: false });
								}
							} else {
								this.props.setNotification("Could not locate " + resiData.street, { variant: "error" });
							}
						} else {
							this.props.setNotification("An error occurred checking this address!", { variant: "error" });
						}
					} else {
						this.props.setNotification("An error occurred checking this address!", { variant: "error" });
					}
				} else {
					const url = "https://api.smartystreets.com/street-address?auth-id=13814496317761852&street=" + resiData.street + "&zipcode=" + resiData.zip + "&candidates=2";

					// Not sure about this, but on localhost this causes a network error with axios
					axios.defaults.withCredentials = false;
					const response = await axios.get(url);

					if (response.data.length === 1) {
						if (response.data[0].metadata.rdi === "Residential") {
							this.props.setNotification("This address is considered residential by USPS.", { variant: "info" });
							this.setState({ residential: true });
						} else {
							this.props.setNotification("This address is considered non-residential by USPS.", { variant: "info" });
							this.setState({ residential: false });
						}
					} else {
						this.props.setNotification("Could not locate " + resiData.street, { variant: "error" });
					}
				}
			} catch (error) {
				console.error(error);

				this.props.setNotification("There was an error checking this address!", { variant: "error" });
			}
		}

		this.setState({ loadingResidentialCheck: false });
	}

	clearFilters = async () => {
		await this.props.clearParcelLookup();
	}

	performLookup = async (clear, search_by, search_terms, reference_type) => {

		let searchResults = await this.props.performLookup(clear, search_by, search_terms, reference_type, true);

		if(searchResults == false || _.isEmpty(searchResults) || _.isEmpty(searchResults.result) || _.isEmpty(searchResults.result[0])) {
			this.props.setNotification("No shipment found for this reference!", { variant: "error" });
			return false;
		} else {
			return searchResults;
		}
	}

	lookupShipment = async (clear = false) => {
		if(clear !== true) {
			clear = false;
		}

		this.setState({
			loadingLookup: true
		});

		let {search_by, search_terms, reference_type } = this.props;
		let shipments = await this.performLookup(clear, search_by, search_terms, reference_type, true);
			if(shipments !== false) {
			let result = shipments.result[0];
			if(result.status == 'hold' && result.cancelled != '1') {
				this.updateShipment(result);
			} else {
				this.setState({
					showConfirmShipmentDuplication: true,
					updateShipmentData: result,
				});
			}

			this.setState({
				result: shipments.result,
			});
		}

		this.setState({
			loadingLookup: false
		});
	}

	updateShipment = async (result, duplicate = false) => {
		if(_.isEmpty(result)) {
			this.props.setNotification("No shipment found for this reference!", { variant: "error" });
		}
		try{
			this.setState(prevState => ({
				shipmentInfo: {
					...prevState.shipmentInfo,
					shipmentId: duplicate ? "" : result.shipment_id,
				},
				consignee_company: result.consignee_company_name,
				consignee_contact: result.consignee_contact_name,
				consignee_phone: result.consignee_phone,
				consignee_email: result.consignee_email,
				consignee_address1: result.consignee_address1,
				consignee_address2: result.consignee_address2,
				consignee_city: result.consignee_city,
				consignee_state: result.consignee_state,
				consignee_zip: result.consignee_zip,
				consignee_country: result.consignee_country,
				so_num: result.so,
				po_num: result.po,
				dept_num: result.dept,
				ref_num: result.ref,
				billing_type: result.billing_type,
			}));

			if(duplicate) {
				// check that user is allowed to use result's warehouse
				let warehouseCode = this.state.warehouses.filter(warehouse => {return warehouse.code === result.code});
				if(!_.isEmpty(warehouseCode)) {
					await this.setState({
						warehouse: result.code,
					});
					await this.updateCarriers(result.code);
				}

				// convert scac to carrier code.
				let carrierCode = '';
				switch(result.scac) {
					case this.state.fedexScac:
						carrierCode = 'FEDEX';
						break;
					case this.state.upsScac:
						carrierCode = 'UPS';
						break;
					default:
						break;
				}
				// confirm warehouse can use this carrier.
				if(this.state.carriers.indexOf(carrierCode) >= 0) {
					await this.setState({
						carrier: carrierCode
					})
					await this.loadServices();
				}

				if(result.billing_type == 'recipient') {
					this.setState({ billing_account: result.billing_account_number });
				}
				if(result.billing_type == 'third_party') {
					await this.loadThirdPartyAccounts();
					this.setState({ third_party_billing_account: result.billing_account_number })
				}

				// update service to match the shipment we are duplicating.
				let serviceCode = this.state.services.filter(service => {
					return (
						service.name.toUpperCase() === result.serviceType.toUpperCase()
						|| service.value.toUpperCase() === result.serviceType.toUpperCase()
					)
				});

				if(!_.isEmpty(serviceCode)) {
					this.setState({
						service: serviceCode[0].value,
					});
				}
			}


			let packages = [];

			for(let i = 0; i < result.packages.packages.length; i ++) {

				if(result.packages.packages[i].is_dg) {
					this.setState({
						regulation_set: result.packages.packages[i].regulation_set,
						hazmat: true,
					});

					this.setState({
						allowedUnitsOfMeasure: formatHazUoM(result.packages.packages[i].uom)
					});

					let transMode = result.packages.packages[i].transport_mode;
					if(transMode.toUpperCase() == 'GROUND') {
						transMode = 'Ground';
					}
					if(transMode.toUpperCase() == 'PASSENGER') {
						transMode = 'Passenger';
					}
					if(transMode.toUpperCase() == 'CARGO') {
						transMode = 'Cargo';
					}

					packages.push({
						id: duplicate ? "" : result.packages.packages[i].id,
						length: result.packages.packages[i].length,
						width: result.packages.packages[i].width,
						height: result.packages.packages[i].height,
						weight: result.packages.packages[i].weight,
						declared_value: result.packages.packages[i].declared_value || '',
						part_no: result.packages.packages[i].part_no || '',
						description: result.packages.packages[i].description || '',
						quantity: 1,
						hazardous: true,
						hazmat: {
							un: result.packages.packages[i].un_num,
							class: result.packages.packages[i].hazard_class,
							subHazardClass: result.packages.packages[i].sub_hazard_class,
							properShippingName: result.packages.packages[i].proper_ship_name,
							packingGroup: result.packages.packages[i].packing_group,
							packingInstructionCode: result.packages.packages[i].packing_inst_code,
							technicalName: result.packages.packages[i].tech_name,
							packagingType: result.packages.packages[i].package_type,
							innerContainerType: result.packages.packages[i].inner_container_type,

							uom: result.packages.packages[i].uom,
							quantity: result.packages.packages[i].units,
							transportationMode: transMode,
							requiredLabel: result.packages.packages[i].required_label,
							accessibility: result.packages.packages[i].accessibility,
							regulatoryQuantity: result.packages.packages[i].regulatory_qty,
						}
					});
				} else {
					packages.push({
						id: duplicate ? "" : result.packages.packages[i].id,
						length: result.packages.packages[i].length,
						width: result.packages.packages[i].width,
						height: result.packages.packages[i].height,
						weight: result.packages.packages[i].weight,
						declared_value: result.packages.packages[i].declared_value || '',
						part_no: result.packages.packages[i].part_no || '',
						description: result.packages.packages[i].description || '',
						quantity: 1,
						hazardous: false,
						hazmat: {
							un: '',
							class: '',
							subHazardClass: '',
							properShippingName: '',
							packingGroup: '',
							packingInstructionCode: '',
							technicalName: '',
							packagingType: '',
							innerContainerType: '',

							uom: '',
							quantity: '',
							transportationMode: '',
							requiredLabel: '',
							accessibility: '',
							regulatoryQuantity: '',
						}
					});
				}
			}

			this.setState({packages: packages});

			if(!_.isEmpty(result.references)) {
				let references = result.references;
				for(let i = 0; i < result.references.length; i++) {
					if(result.references[i].type == 'Fedex Residential Flag') {

						if(result.references[i].value == 'TRUE' || result.references[i].value == 'True') {

							this.setState({residential: true});
							this.loadServices();

						}
					} else if(result.references[i].type == 'Fedex Signature') {

						if(result.references[i].value == '2') {

							this.setState({signature: true, signature_type: 'INDIRECT'});

						}

					} else {
						// For any existing warehouse reference of same type, update value instead of duplicating it
						let found = false;
						references.forEach((ref, key) => {
							if(result.references[i].type == ref.type) {
								found = true;
								references[key].value = result.references[i].value;
							}
						})
						if(!found) {
							references.push({
								'type': result.references[i].type,
								'value': result.references[i].value,
								'required': '0'
							});
						}
					}
				}

				this.setState({references});
			}

		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error loading the shipment for this reference!", { variant: "error" });
		}

		this.setState({loadingLookup: false});
	}

	async calculateRate() {
		this.setState({loadingRate: true});
		const isInbound = this.state.is_inbound;

		const shipper = {
			state: isInbound ? this.state.consignee_state : this.state.shipper_state,
			zip: isInbound ? this.state.consignee_zip : this.state.shipper_zip,
			country: isInbound ? this.state.consignee_country : this.state.shipper_country,
		};
		const consignee = {
			address: isInbound ? this.state.shipper_address1 : this.state.consignee_address1,
			state: isInbound ? this.state.shipper_state : this.state.consignee_state,
			zip: isInbound ? this.state.shipper_zip : this.state.consignee_zip,
			country: isInbound ? this.state.shipper_country : this.state.consignee_country,
		};

		const warehouseCode = this.state.warehouse;
		const shipDate = !_.isEmpty(this.state.ship_date) ? moment(this.state.ship_date).format("YYYY-MM-DD") : "";
		const customs = {...this.state.customs};
		const standardUnits = this.state.standard_units;
		const packagingType = this.state.packaging_type;
		const cod = this.state.cod;
		const signature = this.state.signature;
		const saturdayDelivery = this.state.saturday_delivery;
		const carbonNeutral = this.state.carbon_neutral;
		const residential = this.state.residential == true ? 1 : 0;
		const carrier = this.state.carrier;
		const service = this.state.service;
		const dropoffType = 'REGULAR_PICKUP';

		const specialServices = [];
		const packages = [];

		if (cod) {
			specialServices.push({
				type: "COD",
				additionalInfo: null,
				displayText: "Collect On Delivery"
			});
		}

		if (signature) {
			specialServices.push({
				type: "SIGNATURE_OPTION",
				additionalInfo: this.state.signature_type,
				displayText: this.state.signature_type + " Signature Required"
			});
		}

		if (saturdayDelivery && carrier != 'USPS') {
			specialServices.push({
				type: "SATURDAY_DELIVERY",
				additionalInfo: null,
				displayText: "Saturday Delivery"
			});
		}
		if (carbonNeutral && carrier == 'UPS') {
			specialServices.push({
				type: "CARBON_NEUTRAL",
				additionalInfo: null,
				displayText: "Carbon Neutral"
			});
		}
		for (const prop of this.state.packages) {
			const length = prop.length.trim();
			const width = prop.width.trim();
			const height = prop.height.trim();
			const weight = prop.weight.trim();
			const qty = prop.quantity;
			const declaredValue = prop.declared_value;
			const part_no = prop.part_no;
			const description = prop.description;

			let dgInfo = {};

			const hazard = prop.hazardous;

			if (hazard) {
				dgInfo = {
					selectedKey: this.state.selectedKey,
					regulationSet: this.state.regulation_set,
					unNum: prop.hazmat.un,
					classNum: prop.hazmat.class,
					subriskClassNum: prop.hazmat.subHazardClass,
					properShippingName: prop.hazmat.properShippingName,
					technicalName: prop.hazmat.technicalName,
					transportMode: prop.hazmat.transportationMode,
					packagingType: prop.hazmat.packagingType,
					innerContainerType: prop.hazmat.innerContainerType,
					regulatoryQuantity: prop.hazmat.regulatoryQuantity,
					packingInstructionCode: prop.hazmat.packingInstructionCode,
					packagingGroup: prop.hazmat.packingGroup,
					accessibility: prop.hazmat.accessibility,
					requiredLabel: prop.hazmat.requiredLabel,

					emergencyPhone: this.state.is_inbound ? this.state.inbound_emergency_phone : this.state.emergency_phone,
					intlEmergencyPhone: this.state.is_inbound ? this.state.inbound_intl_emergency_phone  : this.state.intl_emergency_phone,
					emergencyResponseRegistrant: this.state.is_inbound ? this.state.inbound_emergency_response_registrant : this.state.emergency_response_registrant,
					offeror: this.state.is_inbound ? this.state.inbound_offeror : this.state.offeror,
					signatoryContactName: this.state.is_inbound ? this.state.signatory_contact_name : this.state.signatory_contact_name,
					signatoryTitle: this.state.is_inbound ? this.state.inbound_signatory_contact_title : this.state.signatory_contact_title,
					signatoryPlace: this.state.is_inbound ? this.state.inbound_signatory_place : this.state.signatory_place,
					packagingTypeQty: {
						uom: prop.hazmat.uom,
						qty: prop.hazmat.quantity
					}
				};
			}

			packages.push({
				length,
				width,
				height,
				weight,
				standardUnits,
				qty,
				declaredValue,
				part_no,
				description,
			});
		}

		if(_.isEmpty(packages)) {

			this.props.setNotification("No package information provided!", { variant: "error" });

		} else if(_.isEmpty(service) || service == '') {

			this.props.setNotification("No service selected!", { variant: "error"});

		} else {

			let data = {
				shipper,
				consignee,
				warehouseCode,
				shipper,
				consignee,
				packages,
				residential,
				shipDate,
				customs,
				specialServices,
				packagingType,
				carrier,
				service,
				dropoffType,
				DNR: 1
			};

			if(service == 'SMART_POST') {
				if(!this.validateSmartPost()) {
					this.setState({rateQuote: '', loadingRate: false});
					return;
				}
			}

			try {
				const response = await axios.post("/index.php?p=api&r=json&c=parcelRateRequest&m=getParcelRates", qs.stringify(data));
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.user)) {
					let parcelRates = response.data.body.parcelRates[0];

					if(carrier == 'FEDEX' || carrier == 'USPS') {
						this.setState({ rateQuote: parseFloat(parcelRates.rates[0].totalCharge).toFixed(2) });
					} else if(carrier == 'UPS') {
						if(parcelRates.hasOwnProperty('residential')) {
							this.setState({
								residential: parcelRates.residential == true ? true : false
							});
						}
						parcelRates.rates.forEach(rate => {
							if (rate.serviceCode == service) {
								if (saturdayDelivery && rate.delivery.guaranteed && !rate.delivery.dayOfWeek.startsWith('Sat')) {
									this.props.setNotification("The selected service has a guaranteed delivery date other than Saturday", { variant: "warning" });
								}

								this.setState({ rateQuote: rate.totalCharge });
							}
						});
					}
				} else if(_.isEmpty(response.data.message)) {
					this.props.setNotification("An error occurred loading the parcel rates! Please contact support.", { variant: "error" });
					this.setState({rateQuote: ''});
				}
			} catch (error) {
				console.error(error);
	 			this.setState({rateQuote: ''});
				this.props.setNotification("An error occurred loading the parcel rates!", { variant: "error" });
			}
		}


		this.setState({loadingRate: false});
	}

	setShowManagePrinters = () => {
		this.setState({showManagePrinters: true});
	}
	closeManagePrinters = () => {
		this.setState({showManagePrinters: false});
	}


	async scheduleShipment() {
		this.setState({schedulingShipment: true});

		const { billing_type, cod, asn_email, carrier, is_inbound, references } = this.state;
		const serviceCode = this.state.service;

		const required = [...this.state.required];
		let valid = true;

		if (billing_type == "shipper" || billing_type == 'recipient') {
			required.push("billing_account");
			required.push("billing_zip");
		}

		if (billing_type == "third_party") {
			required.push("third_party_billing_account");
		}

		if (cod) {
			required.push("cod_amount");
			required.push("cod_collection_type");
		}

		if (carrier == "UPS" && serviceCode == 14) {
			required.push("consignee_contact")
		}

		for (const input of required) {
			if (_.isEmpty(this.state[input])) {
				valid = false;
			}
		}

		for (const ref in references) {
			if(references[ref].required == 1 && _.isEmpty(references[ref].value)) {
				valid = false;
			}
		}

		if(serviceCode == "SMART_POST") {
			valid = this.validateSmartPost();
			if (!valid) {
				this.setState({schedulingShipment: false});
			return;
			}
		}

		if (!valid) {
			this.props.setNotification("All required inputs must be entered for a parcel shipment!", { variant: "error" });
			this.setState({schedulingShipment: false});
			return;
		}

		const shipper = {
			company: is_inbound ? this.state.consignee_company : this.state.shipper_company,
			contact: is_inbound ? this.state.consignee_contact : this.state.shipper_contact,
			email: is_inbound ? this.state.consignee_email : this.state.shipper_email,
			streetLineOne: is_inbound ? this.state.consignee_address1 : this.state.shipper_address1,
			streetLineTwo: is_inbound ? this.state.consignee_address2 : this.state.shipper_address2,
			phone: is_inbound ? this.state.consignee_phone : this.state.shipper_phone,
			state: is_inbound ? this.state.consignee_state : this.state.shipper_state,
			zip: is_inbound ? this.state.consignee_zip : this.state.shipper_zip,
			city: is_inbound ? this.state.consignee_city : this.state.shipper_city,
			country: is_inbound ? this.state.consignee_country : this.state.shipper_country,
		};

		if(is_inbound && this.state.consignee_or_shipper_save) {
			shipper.save = true;
		}
		const consignee = {
			company: is_inbound ? this.state.shipper_company : this.state.consignee_company,
			contact: is_inbound ? this.state.shipper_contact : this.state.consignee_contact,
			email: is_inbound ? this.state.shipper_email : this.state.consignee_email,
			streetLineOne: is_inbound ? this.state.shipper_address1 : this.state.consignee_address1,
			streetLineTwo: is_inbound ? this.state.shipper_address2 : this.state.consignee_address2,
			phone: is_inbound ? this.state.shipper_phone : this.state.consignee_phone,
			city: is_inbound ? this.state.shipper_city : this.state.consignee_city,
			state: is_inbound ? this.state.shipper_state : this.state.consignee_state,
			zip: is_inbound ? this.state.shipper_zip : this.state.consignee_zip,
			country: is_inbound ? this.state.shipper_country : this.state.consignee_country,
		};
		if(!is_inbound && this.state.consignee_or_shipper_save) {
			consignee.save = true;
		}

		if(carrier == 'UPS') {
			if (consignee.company.length > 30) {
				this.props.setNotification("Consignee company name cannot exceed 30 characters for UPS shipments.", { variant: "error" });
				valid = false;
			}
			if (consignee.contact.length > 30) {
				this.props.setNotification("Consignee contact name cannot exceed 30 characters for UPS shipments.", { variant: "error" });
				valid = false;
			}
		}
		if(!valid) {
			this.setState({schedulingShipment: false});
			return;
		}

		let return_to = {};

		if(this.state.return_name != 'sender') {

			return_to = {
				company: this.state.return_company,
				contact: this.state.return_contact,
				email: this.state.return_email,
				streetLineOne: this.state.return_address1,
				streetLineTwo: this.state.return_address2,
				city: this.state.return_city,
				zip: this.state.return_zip,
				state: this.state.return_state,
				country: this.state.return_country,
				phone: this.state.return_phone,
			}

		}

		const shipmentId = this.state.shipmentInfo.shipmentId != "" ? this.state.shipmentInfo.shipmentId : null;

		const warehouseCode = this.state.warehouse;
		const shipDate = !_.isEmpty(this.state.ship_date) ? moment(this.state.ship_date).format("YYYY-MM-DD") : "";
		const customs = {...this.state.customs};
		const packagingType = this.state.packaging_type;
		const signature = this.state.signature;
		const saturdayDelivery = this.state.saturday_delivery;
		const carbonNeutral = this.state.carbon_neutral;
		const residential = this.state.residential == true ? 1 : 0;
		const dropoffType = 'REGULAR_PICKUP';
		const saveLabel = this.state.default_label_save ? 1 : 0;
		const costcoLabel = this.state.include_costco_label ? 1 : 0;
		const billing = {
			type: billing_type,
		}

		if(shipper.country != consignee.country) {

			if(description == "") {
				this.props.setNotification("Package commodity description is required for international shipments.", { variant: "error" });
				valid = false;
			}

			if(carrier == 'FEDEX') {
				if(customs.customs_value == "" || customs.customs_value == 0) {
					this.props.setNotification("Customs value is required for FedEx international shipments.", { variant: "error" });
					valid = false;
				}
				if(customs.commodity_units == "" || customs.commodity_units == 0) {
					this.props.setNotification("# of Commodity units is required for FedEx international shipments.", { variant: "error" });
					valid = false;
				}
				if(customs.unit_value == "" || customs.unit_value == 0) {
					this.props.setNotification("Unit value is required for FedEx international shipments.", { variant: "error" });
					valid = false;
				}
			} else if(carrier == 'UPS') {
				if(consignee.contact == "") {
					this.props.setNotification("Consignee Contact Name is required for UPS international shipments.", { variant: "error" });
					valid = false;
				}
			} else if(carrier == 'USPS') {
				this.props.setNotification("USPS international shipments are not supported.", { variant: "error" });
				valid = false;
			}

			if(!valid) {
				this.setState({schedulingShipment: false});
				return;
			}
		}

		if (billing.type == "recipient" || billing.type == 'shipper') {
			billing.account = this.state.billing_account;
			billing.zip = this.state.billing_zip;
		}

		if(billing.type == "third_party") {
			billing.account = this.state.third_party_billing_account;

			let account = this.state.third_party_accounts[carrier].filter(accounts => {return accounts.account === this.state.third_party_billing_account});
			billing.zip = account[0].zip;
		}

		const specialServices = [];
		const packages = [];
		let codDetail = {};
		let asnEmail = '';

		if (cod) {
			specialServices.push({
				type: "COD",
				additionalInfo: null,
				displayText: "Collect On Delivery"
			});

			codDetail = {
				amount: this.state.cod_amount,
				collectionType: this.state.cod_collection_type
			};
		}

		if (signature) {
			specialServices.push({
				type: "SIGNATURE_OPTION",
				additionalInfo: this.state.signature_type,
				displayText: this.state.signature_type + " Signature Required"
			});
		}

		// We don't support USPS Saturday delivery currently
		if (saturdayDelivery && carrier != "USPS") {
			specialServices.push({
				type: "SATURDAY_DELIVERY",
				additionalInfo: null,
				displayText: "Saturday Delivery"
			});
		}

		// CarbonNeutral is only a UPS Service
		if (carbonNeutral && carrier == "UPS") {
			specialServices.push({
				type: "CARBON_NEUTRAL",
				additionalInfo: null,
				displayText: "Carbon Neutral"
			});
		}

		// This FedEx ASN format has been retained, but we no longer use FedEx, and instead send our own custom ASN.

		if (!_.isEmpty(asn_email)) {

			// We no longer use FedEx to send the ASN; we send our own custom ASN.

			// specialServices.push({
			// 	type: "EVENT_NOTIFICATION",
			// 	additionalInfo: asn_email,
			// 	displayText: "Advanced Shipment Notification"
			// });

			asnEmail = asn_email;

		}

		let shipmentIsHazardous = false;
		for (const prop of this.state.packages) {
			if(shipper.country != consignee.country && (prop.declared_value == "" || prop.declared_value == 0)) {
				this.props.setNotification("All packages for international shipments must have a declared value.", { variant: "error" });
				this.setState({schedulingShipment: false});
				return;
			}

			const id = prop.id || '';
			const length = prop.length.trim();
			const width = prop.width.trim();
			const height = prop.height.trim();
			const weight = prop.weight.trim();
			const qty = prop.quantity;
			const declaredValue = prop.declared_value;
			const part_no = prop.part_no;
			const description = prop.description;

			let dgInfo = {};

			const hazard = prop.hazardous;

			if(this.state.packaging_type == 'BOX') {
				if(_.isEmpty(length) || _.isEmpty(length) || _.isEmpty(length)) {
					this.props.setNotification("Dimensions must be provided for Customer Packaging.", { variant: "error" });
					this.setState({schedulingShipment: false});
					return;
				}
			}

			if (hazard) {
				shipmentIsHazardous = true;
				dgInfo = {
					selectedKey: this.state.selectedKey,
					regulationSet: this.state.regulation_set,
					unNum: prop.hazmat.un,
					classNum: prop.hazmat.class,
					subriskClassNum: prop.hazmat.subHazardClass,
					properShippingName: prop.hazmat.properShippingName,
					technicalName: prop.hazmat.technicalName,
					transportMode: prop.hazmat.transportationMode,
					packagingType: prop.hazmat.packagingType,
					innerContainerType: prop.hazmat.innerContainerType,
					regulatoryQuantity: prop.hazmat.regulatoryQuantity,
					packingInstructionCode: prop.hazmat.packingInstructionCode,
					packagingGroup: prop.hazmat.packingGroup,
					accessibility: prop.hazmat.accessibility,
					requiredLabel: prop.hazmat.requiredLabel,
					emergencyPhone: this.state.is_inbound ? this.state.inbound_emergency_phone : this.state.emergency_phone,
					intlEmergencyPhone: this.state.is_inbound ? this.state.inbound_intl_emergency_phone  : this.state.intl_emergency_phone,
					emergencyResponseRegistrant: this.state.is_inbound ? this.state.inbound_emergency_response_registrant : this.state.emergency_response_registrant,
					offeror: this.state.is_inbound ? this.state.inbound_offeror : this.state.offeror,
					signatoryContactName: this.state.is_inbound ? this.state.signatory_contact_name : this.state.signatory_contact_name,
					signatoryTitle: this.state.is_inbound ? this.state.inbound_signatory_contact_title : this.state.signatory_contact_title,
					signatoryPlace: this.state.is_inbound ? this.state.inbound_signatory_place : this.state.signatory_place,
					packagingTypeQty: {
						uom: prop.hazmat.uom,
						qty: prop.hazmat.quantity
					}
				};
			}

			packages.push({
				id,
				length,
				width,
				height,
				weight,
				standardUnits : this.state.standard_units,
				qty,
				declaredValue,
				dgInfo,
				part_no,
				description,
			});
		}

		const { label_type, label_size } = this.state;

		const print_type = label_type === "ZPL" ? "print_language" : "image";

		let labelSpec = {};
		labelSpec.dims = label_size;
		labelSpec.type = label_type;
		labelSpec.printType = print_type;

		labelSpec.includeDocTab = labelSpec.dims.indexOf("DOC_TAB") != -1 ? 1 : 0;

		let so = !_.isEmpty(this.state.so_num) ? _.trim(this.state.so_num) : "";
		let po = !_.isEmpty(this.state.po_num) ? _.trim(this.state.po_num) : "";
		let dept = !_.isEmpty(this.state.dept_num) ? _.trim(this.state.dept_num) : "";
		let ref = !_.isEmpty(this.state.ref_num) ? _.trim(this.state.ref_num) : "";

		if (_.isEmpty(label_size) || !this.getLabelSizes(carrier, label_type, shipmentIsHazardous).some(l =>l.value === label_size)) {
			this.props.setNotification("Invalid label size for carrier and label type selected.", { variant: "error" });
		} else if(_.isEmpty(packages)) {

			this.props.setNotification("No package information provided!", { variant: "error" });

		} else if(_.isEmpty(service) || service == '') {

			this.props.setNotification("No service selected!", { variant: "error"});

		} else {

			let data = {
				shipper,
				consignee,
				return_to,
				warehouseCode,
				shipper,
				consignee,
				packages,
				residential,
				shipDate,
				customs,
				specialServices,
				asnEmail,
				packagingType,
				carrier,
				serviceCode,
				dropoffType,
				is_inbound,
				saveLabel,
				costcoLabel,
				labelSpec,
				references,
				billing,
				shipmentId,
				codDetail,
				so,
				po,
				dept,
				ref
			};

			try {
				const url = "/index.php?p=api&r=json&c=parcelShipService&m=createShipment";
				const response = await axios.post(url, qs.stringify(data));
				this.setState({ schedulingShipment: false });
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.general) && !_.isEmpty(response.data.body.shipmentInfo)) {
					flushSync(() => {
						this.setState({
							general: response.data.body.general,
							shipmentInfo: response.data.body.shipmentInfo,
							schedulingShipment: false,
						});
					});
					if(this.state.hazmat) {
						this.viewDgDocs()
					}
					if(costcoLabel) {
						await this.viewNonDgDocs()
					}
					if (print_type === "print_language") {
						await this.printerCheck(carrier);
					} else {
						this.viewLabel();
					}
				} else if(_.isEmpty(response.data.message)) {
					this.props.setNotification("There was an error scheduling the shipment!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.setState({ schedulingShipment: false });
				this.props.setNotification("There was an exception scheduling the shipment!", { variant: "error" });
			}
		}

		this.setState({schedulingShipment: false});
	}

	addDefaultPackage = (pkg) => {

		const { packages, packaging_type} = this.state;

		// If there is only one package, replace it
		if(packages.length == 1) {

			// Not checking for packages[0] in case it was already removed
			let first = packages.shift();

			// If there were values filled out, add it back
			if(first.length != '' || first.width != '' || first.height != '' || first.weight != '') {
				packages.push(first);
			}

		}

		let thisPkg = {
			length: pkg.length,
			width: pkg.width,
			height: pkg.height,
			weight: pkg.weight,
			quantity: 1,
			declared_value: "",
			part_no: "",
			description: "",
			hazardous: false,
			hazmat: {
				un: "",
				class: "",
				subHazardClass: "",
				properShippingName: "",
				packingGroup: "",
				packingInstructionCode: "",
				technicalName: "",
				packagingType: "",
				regulatoryQuantity: "",
				uom: "",
				quantity: "",
				transportationMode: ""
			}
		};

		if(pkg.type != '') {
			this.setState({packaging_type: pkg.type});
		}

		packages.push(thisPkg);

		this.setState({ packages });

	}



	swapConsignee = () => {
		let inbound = this.state.is_inbound;
		let billingType = this.state.billing_type;

		if(this.state.hazmat && (this.state.consignee_country != this.state.shipper_country || this.state.shipper_country != 'US' || this.state.consignee_country != 'US')) {
			this.props.setNotification("We currently do not support HazMat or Dangerous Goods import shipments. Remove any hazardous packages from the shipment or change the country field to US.", { variant: "error" });
			return;
		}
		//need to update billing type unless it is 3rd party/collect.
		if((billingType == 'recipient' || billingType == 'shipper')) {
			//switch billing information to keep the same account billed that is currently selected.
			if(billingType == 'recipient') {
				billingType = 'shipper';
			} else if(billingType == 'shipper') {
				billingType = 'recipient';
			}
			this.setState({
				is_inbound: !inbound,
				billing_type: billingType,
				rateQuote: ''
			});
		} else {
			//only update the inbound flag.
			this.setState({
				is_inbound: !inbound,
				rateQuote: ''
			});
		}
		if(this.state.shipper_country != this.state.consignee_country) {
			this.loadServices();
		}
	}

	getImportUnitofMeasure = () => {
		const { classes } = this.props;
		let uom = [
			{
				name: 'Barrel',
				value: 'BA'
			},
			{
				name: 'Bundle',
				value: 'BE'
			},
			{
				name: 'Bag',
				value: 'BG'
			},
			{
				name: 'Bunch',
				value: 'BH'
			},
			{
				name: 'Box',
				value: 'BOX'
			},
			{
				name: 'Bolt',
				value: 'BT'
			},
			{
				name: 'Butt',
				value: 'BU'
			},
			{
				name: 'Canister',
				value: 'CI'
			},
			{
				name: 'Centimeter',
				value: 'CM'
			},
			{
				name: 'Container',
				value: 'CON'
			},
			{
				name: 'Crate',
				value: 'CR'
			},
			{
				name: 'Case',
				value: 'CS'
			},
			{
				name: 'Carton',
				value: 'CT'
			},
			{
				name: 'Cylinder',
				value: 'CY'
			},
			{
				name: 'Dozen',
				value: 'DOZ'
			},
			{
				name: 'Each',
				value: 'EA'
			},
			{
				name: 'Envelope',
				value: 'EN'
			},
			{
				name: 'Feet',
				value: 'Ft'
			},
			{
				name: 'Kilogram',
				value: 'KG'
			},
			{
				name: 'Kilograms',
				value: 'KGS'
			},
			{
				name: 'Pound',
				value: 'LB'
			},
			{
				name: 'Pounds',
				value: 'LBS'
			},
			{
				name: 'Liters',
				value: 'L'
			},
			{
				name: 'Meter',
				value: 'M'
			},
			{
				name: 'Number',
				value: 'NMB'
			},
			{
				name: 'Packet',
				value: 'PA'
			},
			{
				name: 'Pallet',
				value: 'PAL'
			},
			{
				name: 'Piece',
				value: 'PC'
			},
			{
				name: 'Pieces',
				value: 'PCS'
			},
			{
				name: 'Proof Liters',
				value: 'PF'
			},
			{
				name: 'Package',
				value: 'PKG'
			},
			{
				name: 'Pair',
				value: 'PR'
			},
			{
				name: 'Pairs',
				value: 'PRS'
			},
			{
				name: 'Roll',
				value: 'RL'
			},
			{
				name: 'Set',
				value: 'SET'
			},
			{
				name: 'Sq Meters',
				value: 'SME'
			},
			{
				name: 'Sq Yards',
				value: 'SYD'
			},
			{
				name: 'Tube',
				value: 'TU'
			},
			{
				name: 'Yard',
				value: 'YD'
			},
		];
		return uom.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getExportReasons = () => {
		const { classes } = this.props;
		let reasons = [
			{
				name: 'Sale',
				value: 'SALE'
			},
			{
				name: 'Gift',
				value: 'GIFT'
			},
			{
				name: 'Sample',
				value: 'SAMPLE'
			},
			{
				name: 'Return',
				value: 'RETURN'
			},
			{
				name: 'Repair',
				value: 'REPAIR'
			},
			{
				name: 'InterCompany Data',
				value: 'INTERCOMPANYDATA'
			},
		];
		return reasons.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}


	closeDuplicateShipmentModal = (event) => {
		this.setState({
			updateShipmentData: {},
			showConfirmShipmentDuplication: false,
		});
	}
	confirmDuplicateShipmentModal = () => {
		this.updateShipment(this.state.updateShipmentData, true);
		this.closeDuplicateShipmentModal();
	}

	validateSmartPost() {
		let packages = Array.isArray(this.state.packages) ? this.state.packages : [];
		let valid = true;

		if(packages.length > 1) {
			this.props.enqueueSnackbar("SmartPost is not available for this shipment: Number of packages exceeds maximum of 1.", { variant: "info" });
			valid = false;
		}

		packages.forEach(prop => {
			const length = isNaN(prop.length) ? 0 : parseInt(prop.length);
			const width = isNaN(prop.width) ? 0 : parseInt(prop.width);
			const height = isNaN(prop.height) ? 0 : parseInt(prop.height);
			const weight = isNaN(prop.weight) ? 0 : parseInt(prop.weight);
			const declared_value = isNaN(prop.declared_value) ? 0 : parseInt(prop.declared_value);
			const girth = (width + height) * 2;

			if(prop.quantity > 1) {
				this.props.enqueueSnackbar("SmartPost is not available for this shipment: Number of packages exceeds maximum of 1.", { variant: "info" });
				valid = false;
			}

			if(declared_value > 0) {
				this.props.enqueueSnackbar("SmartPost is not available for this shipment because it does not accept declared values.", { variant: "info" });
				valid = false;
			}
			if(length < 6 || width < 4) {
				this.props.enqueueSnackbar("SmartPost is not available for this shipment: Minimum dimensions of 6 x 4 x 1 IN must be entered.", { variant: "info" });
				valid = false;
			}
			if(length > 60 || width > 60 || height > 60) {
				this.props.enqueueSnackbar("SmartPost is not available for this shipment: Package length, width, or height exceeds the maximum of 60 IN.", { variant: "info" });
				valid = false;
			}
			if((length + girth) > 130) {
				this.props.enqueueSnackbar("SmartPost is not available for this shipment: Dimensions exceed length and girth IN limit of 130.", { variant: "info" });
				valid = false;
			}
			if(weight > 70) {
				this.props.enqueueSnackbar("SmartPost is not available for this shipment: Package exceeds the max weight limit of 70.0 LB.", { variant: "info" });
				valid = false;

			}
		});

		return valid;
	}

	render() {
		const { classes } = this.props;
		let prodCatCols = ["Code", "Name", "Length", "Width", "Height", "Weight", "Hazmat", "Actions"]
		if(this.state.enable_product_part_no) {
            prodCatCols.splice(1, 0, "Part #");
        }

		return (
			<GridContainer>
			{this.state.alert}
			{(this.state.showConfirmShipmentDuplication &&
            	<Dialog
                	open={this.state.showConfirmShipmentDuplication}
				>
					<DialogContent id="classic-modal-slide-description">
                    <InputLabel>
						Duplicate Shipment
                    </InputLabel>
                    <br />
						This shipment has already been executed. Would you like to duplicate the shipment's details?
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeDuplicateShipmentModal} color="white">
							Cancel
						</Button>
						<Button onClick={this.confirmDuplicateShipmentModal} color="primary">
							Copy Details
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{(this.state.showManagePrinters &&
				<Dialog
					open={this.state.showManagePrinters}
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader} style={{ paddingTop: 12 }}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={this.closeManagePrinters}>
							<Close className={classes.modalClose} />
						</Button>
						<h3>Manage Printers</h3>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description">
						<ManagePrinters />
					</DialogContent>
				</Dialog>
			)}
				<GridItem xs={9}>
					<ParcelLookup
						handleSearch={this.lookupShipment}
						enqueueSnackbar={this.props.enqueueSnackbar}
						handleMessages={this.props.handleMessages}
						clearFilters={this.clearFilters}
						loading={this.state.loadingLookup}
						referenceTypes={this.state.refTypes}
					/>
				</GridItem>
				<GridContainer>
					<GridItem xs={5} sm={5} md={5} lg={5} style={{paddingTop: '0px', marginTop: '0px'}}>
						<Card>
							<CardBody>
								<GridContainer>
									{this.state.is_inbound ? (
										<GridItem>
											<h5 style={{fontWeight: 'bold'}}>Shipper Information</h5>
										</GridItem>
									) : (
										<GridItem>
											<h5 style={{fontWeight: 'bold'}}>Consignee Information</h5>
										</GridItem>
									)}
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											{this.props.hasAddressBookRead && (
												<GridItem xs={12}>
													<ContactSearch
														classes={classes}
														searchByField="consignee_or_shipper_search_by"
														searchByValue={this.state.consignee_or_shipper_search_by}
														onChangeSearchBy={this.handleChange}
														searchForField="consignee_or_shipper_search_for"
														searchForValue={this.state.consignee_or_shipper_search_for || ""}
														onChangeSearchFor={e => this.handleInput("consignee_or_shipper_search_for", e)}
														onSearch={e => this.handleContactSearch(this.state.is_inbound ? "shipper" : "consignee")}
														onKeyPress={e => {
															if (e.which === 13) {
																this.handleContactSearch(this.state.is_inbound ? "shipper" : "consignee");
															}
														}}
													/>
												</GridItem>
											)}
										</GridContainer>
										<GridContainer>
											<GridItem xs={12}>
												{this.props.hasAddressBookWrite && <FormControlLabel
													control={
														<Checkbox
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot
															}}
															checked={this.state.consignee_or_shipper_save ? true : false}
															onChange={(e) => {
																this.handleChecked("consignee_or_shipper_save", e);
															}}
														/>
													}
													classes={{ label: classes.label }}
													label="Save to Address Book"
												/>}
												<Button size="sm" color="warning" onClick={() => this.clearAddress("consignee")} className={classes.marginRight}>
													Clear
												</Button>
												<Tooltip
													title={
														<React.Fragment>
															<Typography color="inherit">Search Address</Typography>
															{"Search for the street view of this address"}
														</React.Fragment>
													}
													placement="bottom"
												>
													<span>
														<Button justIcon round size="sm" color="info" onClick={() => this.handleAddress("consignee")} className={classes.marginRight}>
															<LocationOn />
														</Button>
													</span>
												</Tooltip>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>Company: </span>
										<CustomInput
											id="consignee_company"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_company",
												value: this.state.consignee_company || '',
												onChange: (e) => {
													this.handleInput("consignee_company", e);
												},
											}}
											required
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={6} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>Contact: </span>
										<CustomInput
											id="consignee_contact"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_contact",
												value: this.state.consignee_contact || '',
												onChange: (e) => {
													this.handleInput("consignee_contact", e);
												},
											}}
											required={(this.state.carrier == "UPS" && (this.state.service == 14 || (!this.state.is_inbound && this.state.consignee_country != this.state.shipper_country))) || this.state.required.includes('consignee_contact')}
										/>
									</GridItem>
									<GridItem xs={6} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>Phone: </span>
										<CustomInput
											id="consignee_phone"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_phone",
												value: this.state.consignee_phone || '',
												onChange: (e) => {
													this.handleInput("consignee_phone", e);
												},
											}}
											required
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>Email: </span>
										<CustomInput
											id="consignee_email"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_email",
												value: this.state.consignee_email || '',
												onChange: (e) => {
													this.handleInput("consignee_email", e);
												},
											}}
											// required
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>Address 1: </span>
										<CustomInput
											id="consignee_address1"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_address1",
												value: this.state.consignee_address1 || '',
												onChange: (e) => {
													this.handleInput("consignee_address1", e);
												},
											}}
											required
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>Address 2: </span>
										<CustomInput
											id="consignee_address2"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_address2",
												value: this.state.consignee_address2 || '',
												onChange: (e) => {
													this.handleInput("consignee_address2", e);
												},
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} style={{paddingBottom: '5px'}}>
										<span className={classes.label}>City: </span>
										<CustomInput
											id="consignee_city"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_city",
												value: this.state.consignee_city || '',
												onChange: (e) => {
													this.handleInput("consignee_city", e);
												},
											}}
											required
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={4} sm={4} md={4} lg={4}>
										<InputLabel className={classes.label}>State:</InputLabel>
										<CustomInput
											id="consignee_state"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_state",
												value: this.state.consignee_state || '',
												onChange: (e) => {
													this.handleInput("consignee_state", e);
												},
											}}
											required
										/>
									</GridItem>
									<GridItem xs={4} sm={4} md={4} lg={4}>
										<InputLabel className={classes.label}>Zip Code:</InputLabel>
										<CustomInput
											id="consignee_zip"
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "consignee_zip",
												value: this.state.consignee_zip || '',
												onChange: (e) => {
													this.handleInput("consignee_zip", e);
												},
											}}
											required
										/>
									</GridItem>
									<GridItem xs={4} sm={4} md={4} lg={4}>
										<InputLabel className={classes.label}>Country:</InputLabel>
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select + " " + classes.requiredSelect
												}}
												value={this.state.consignee_country}
												inputProps={{
													name: "consignee_country",
													id: "consignee_country"
												}}
												onChange={this.handleChange}
											>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="US"
												>
													US
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="CA"
												>
													CA
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="MX"
												>
													MX
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.residential}
													onChange={(e) => {
														this.handleChecked("residential", e);
													}}
												/>
											}
											classes={{ label: classes.label }}
											label="This is a residential address"
										/>
										<Tooltip
											title={
												<React.Fragment>
													<Typography color="inherit">Check For Residential Location</Typography>
												</React.Fragment>
											}
											placement="bottom">
											<span>
												{this.state.loadingResidentialCheck ? (
													<Button justIcon size="sm" color="linkedin">
														<CircularProgress size={16} style={{ color: "white" }} />
													</Button>
												) : (
													<Button justIcon color="linkedin" onClick={() => this.checkForResi()} disabled={this.state.loadingResidentialCheck}>
														<FontAwesomeIcon icon={faSearchLocation} size="2x" />
													</Button>
												)}
											</span>
										</Tooltip>
									</GridItem>
								</GridContainer>

								{this.state.hazmat && this.state.is_inbound && (
									<GridItem style={{paddingTop: '5px', paddingBottom: '5px'}}>
										<Paper className={classes.paper + " " + classes.hazmat} elevation={1}>
											<SignatoryInformation
												onChange={this.handleInput}
												offeror={this.state.inbound_offeror}
												emergency_phone={this.state.inbound_emergency_phone}
												intl_emergency_phone={this.state.inbound_intl_emergency_phone}
												emergency_response_registrant={this.state.inbound_emergency_response_registrant}
												signatory_contact_name={this.state.inbound_signatory_contact_name}
												signatory_contact_title={this.state.inbound_signatory_contact_title}
												signatory_place={this.state.inbound_signatory_place}
											/>
										</Paper>
									</GridItem>
								)}

								<Grid
									container
									spacing={0}
									direction="column"
									alignItems="center"
									justify="center">
									<GridItem>
										<Tooltip
											title={
												<React.Fragment>
													<Typography color="inherit">{this.state.is_inbound ? "Make this an outbound shipment" : "Make this an inbound shipment"}</Typography>
												</React.Fragment>
											}
											placement="bottom">
											<span>
												<Button justIcon round color="info" onClick={this.swapConsignee}>
													<SwapVert fontSize="small"/>
												</Button>
											</span>
										</Tooltip>
									</GridItem>
								</Grid>
								<hr style={{height:'1px',border:'none',color:'#333',backgroundColor:'#333'}}/>
								<GridContainer>
									{this.state.is_inbound ? (
										<GridItem>
											<h5 style={{fontWeight: 'bold'}}>Consignee Information</h5>
										</GridItem>
									) : (
										<GridItem>
											<h5 style={{fontWeight: 'bold'}}>Shipper Information</h5>
										</GridItem>
									)}
								</GridContainer>
								{(this.state.is_inbound && this.state.carrier == 'UPS' && (this.state.service == 14 || this.state.consignee_country != this.state.shipper_country)) && (
									<GridContainer>
										<GridItem xs={6} style={{paddingBottom: '5px'}}>
											<span className={classes.label}>Attention To: </span>
											<CustomInput
												id="shipper_contact"
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "shipper_contact",
													value: this.state.shipper_contact || '',
													onChange: (e) => {
														this.handleInput("shipper_contact", e);
													},
												}}
												required={this.state.carrier == "UPS" && (this.state.service == 14 || this.state.consignee_country != this.state.shipper_country)}
											/>
										</GridItem>
									</GridContainer>
								)}
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={6} style={{paddingBottom: '5px'}}>
												<InputLabel className={classes.label}>Sender:</InputLabel>
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.warehouse}
														inputProps={{
															name: "warehouse",
															id: "warehouse"
														}}
														onChange={this.handleChange}
													>
														{this.getWarehouses(this.state.warehouses)}
													</Select>
												</FormControl>
											</GridItem>
											<GridItem xs={6}>
												<InputLabel className={classes.label}>Return To:</InputLabel>
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.return_name}
														defaultValue="sender"
														inputProps={{
															name: "return_name",
															id: "return_name"
														}}
														onChange={this.handleChange}
													>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="sender"
														>
															Sender
														</MenuItem>
														{this.getReturnAddresses(this.state.return_addresses)}
													</Select>
												</FormControl>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={6}>
												<Paper className={classes.paper} elevation={1}>
													<Typography variant="subtitle2">{this.state.shipper_company}</Typography>
													<Typography variant="subtitle2">{this.state.shipper_address1}</Typography>
													{!_.isEmpty(this.state.shipper_address2) && (
														<Typography variant="subtitle2">{this.state.shipper_address2}</Typography>
													)}
													<Typography variant="subtitle2">
														{!_.isEmpty(this.state.shipper_city) && (<>{this.state.shipper_city}</>)}
														{!_.isEmpty(this.state.shipper_state) && (<>, {this.state.shipper_state}</>)}
														{!_.isEmpty(this.state.shipper_zip) && (<> {this.state.shipper_zip}</>)}
														{!_.isEmpty(this.state.shipper_country) && (<> {this.state.shipper_country}</>)}
													</Typography>
													<Typography variant="body2">{this.state.shipper_email}</Typography>
												</Paper>
											</GridItem>
											<GridItem xs={6}>
												<Paper className={classes.paper} elevation={1} style={{minHeight: '100%'}}>
													<Typography variant="subtitle2">{this.state.return_company}</Typography>
													<Typography variant="subtitle2">{this.state.return_address1}</Typography>
													{!_.isEmpty(this.state.return_address2) && (
														<Typography variant="subtitle2">{this.state.return_address2}</Typography>
													)}
													<Typography variant="subtitle2">
														{!_.isEmpty(this.state.return_city) && (<>{this.state.return_city}</>)}
														{!_.isEmpty(this.state.return_state) && (<>, {this.state.return_state}</>)}
														{!_.isEmpty(this.state.return_zip) && (<> {this.state.return_zip}</>)}
														{!_.isEmpty(this.state.return_country) && (<> {this.state.return_country}</>)}
													</Typography>
												</Paper>
											</GridItem>
										</GridContainer>
									</GridItem>

								{this.state.hazmat && !this.state.is_inbound && (
									<GridItem style={{paddingTop: '5px', paddingBottom: '5px'}}>
										<Paper className={classes.paper + " " + classes.hazmat} elevation={1}>
											<SignatoryInformation
												onChange={this.handleInput}
												offeror={this.state.offeror}
												emergency_phone={this.state.emergency_phone}
												intl_emergency_phone={this.state.intl_emergency_phone}
												emergency_response_registrant={this.state.emergency_response_registrant}
												signatory_contact_name={this.state.signatory_contact_name}
												signatory_contact_title={this.state.signatory_contact_title}
												signatory_place={this.state.signatory_place}
											/>
										</Paper>
									</GridItem>
								)}
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={5} sm={5} md={5} lg={5} style={{paddingTop: '0px', marginTop: '0px'}} >
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem>
										<GridContainer>
											<GridItem xs={12}>
												<h5 style={{fontWeight: 'bold'}}>Package and Shipment Details</h5>
											</GridItem>
										</GridContainer>
										<ParcelServices
											accessibility={searchObject(this.state.packages, '0.hazmat.accessibility', '')}
											hazmat={this.state.hazmat}
											dg_enabled={this.state.dg_enabled}
											warehouse={this.state.warehouse}
											carriers={this.state.carriers}
											carrier={this.state.carrier}
											services={this.state.services}
											service={this.state.service}
											onChange={this.handleChange}
											showRates={this.state.billing_type == 'account'}
											rateQuote={this.state.rateQuote}
											calculateRate={this.calculateRate}
											exceedsWeightLimit={this.exceedsWeightLimit}
											loadingRate={this.state.loadingRate}
										/>
										<GridContainer>
											<GridItem xs={4}>
												<InputLabel htmlFor="packaging_type" className={classes.label} style={{paddingBottom: '5px'}}>
													Packaging Type
												</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.packaging_type}
														inputProps={{
															name: "packaging_type",
															id: "packaging_type"
														}}
														onChange={this.handleChange}
													>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="BOX"
														>
															Customer Packaging
														</MenuItem>
														{this.getPackagingTypes()}
													</Select>
												</FormControl>
											</GridItem>
											<GridItem xs={6}>
												{this.state.hazmat && (
													<span>
														<InputLabel htmlFor="regulation_set" className={classes.label} style={{paddingBottom: '5px'}}>
															HazMat Regulation Set
														</InputLabel>
														<br />
														<FormControl fullWidth className={classes.selectFormControl}>
															<Select
																MenuProps={{
																	className: classes.selectMenu
																}}
																classes={{
																	select: classes.select + " " + classes.requiredSelect
																}}
																value={this.state.regulation_set}
																inputProps={{
																	name: "regulation_set",
																	id: "regulation_set"
																}}
																onChange={this.handleChange}
															>
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="iata"
																>
																	IATA
																</MenuItem>
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="dot"
																>
																	DOT
																</MenuItem>
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="adr"
																>
																	ADR
																</MenuItem>
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="ormd"
																>
																	ORMD
																</MenuItem>
															</Select>
														</FormControl>
													</span>
												)}
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={3}>
												<InputLabel className={classes.label}>
													Add Package
												</InputLabel>
												{this.state.hazmat ? (
													<WithTooltip
														content="Multi-package Hazmat/Dangerous Goods Shipments are unsupported"
													>
														<span>
															<Button size="sm" color="linkedin" disabled>
																<AddCircle />
															</Button><br/>
														</span>
													</WithTooltip>
												) : (
													<Button size="sm" color="linkedin" onClick={this.addPackage}>
														<AddCircle />
													</Button>
												)}
											</GridItem>
											{!_.isEmpty(this.state.selected_warehouse) && (
												<GridItem xs={3}>
													<ParcelPackageButton
														parentCallback={this.addDefaultPackage}
														warehouseCode={this.state.selected_warehouse}
														showLabel="true"
														disabled={this.state.hazmat}
													/>
												</GridItem>
											)}
											<GridItem xs={3}>
												<InputLabel className={classes.label}>
													Package Units
												</InputLabel>
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.standard_units}
														inputProps={{
															name: "standard_units",
															id: "standard_units"
														}}
														onChange={this.handleChange}
													>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value={true}
														>
															Standard (in./lbs.)
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value={false}
														>
															Metric (cm./kgs.)
														</MenuItem>
													</Select>
												</FormControl>
											</GridItem>
										</GridContainer>
										<GridContainer>
											{this.props.weightLimit < Infinity && (
												<GridItem xs={12} sm={12} md={12} style={{marginBottom: "0px"}}>
													<div style={{
														padding: "5px 15px",
														border: "1px #333 dotted"
													}}>
														<b>
															{`The maximum weight allowed for parcel shipping in your organization is ${this.props.weightLimit} lbs.`}
															{this.props.canCreateBol && (
																<span>
																	{` For shipments greater than ${this.props.weightLimit} lbs, please `}
																		<NavLink to={basePath + "/admin/bol/new"}>
																			{"create a bill of lading"}
																		</NavLink>
																	{' for an LTL shipment.'}
																</span>
															)}
														</b>
													</div>
												</GridItem>
											)}
											<GridItem xs={12} sm={12} md={12} className={classes.unitContainer} style={{marginTop:"0px"}}>
												{this.getPackages(this.state.packages)}
											</GridItem>
										</GridContainer>
											{this.state.shipper_country != this.state.consignee_country && (
												<GridContainer>
													<GridItem xs={4}>
														<InputLabel className={classes.label}>Description</InputLabel>
														<CustomInput
															id="description"
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "description",
																placeholder: "Description of commodity",
																value: this.state.customs.description || "",
																onChange: (e) => {this.handleInput("description", e)},
																onBlur: (e) => {this.handleCommodityDescription(e)}
															}}
															required
														/>
													</GridItem>
													{this.state.carrier == "FEDEX" && (
													<React.Fragment>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Customs Value</InputLabel>
															<CustomInput
																id="customs_value"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "number",
																	name: "customs_value",
																	placeholder: "",
																	value: this.state.customs.customs_value || "",
																	startAdornment: <InputAdornment position="start">$</InputAdornment>,
																	onChange: (e) => {this.handleInput("customs_value", e)}
																}}
																required
															/>
														</GridItem>
														<GridItem xs={2}>
															<InputLabel className={classes.label}># of Units</InputLabel>
															<CustomInput
																id="commodity_units"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "commodity_units",
																	placeholder: "",
																	value: this.state.customs.commodity_units || "",
																	onChange: (e) => {this.handleInput("commodity_units", e)}
																}}
																required
																/>
														</GridItem>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Unit Value</InputLabel>
															<CustomInput
																id="unit_value"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "number",
																	name: "unit_value",
																	placeholder: "",
																	value: this.state.customs.unit_value || "",
																	startAdornment: <InputAdornment position="start">$</InputAdornment>,
																	onChange: (e) => {this.handleInput("unit_value", e)}
																}}
																required
															/>
														</GridItem>
													</React.Fragment>
													)}
													{this.state.carrier == "UPS" && this.state.is_inbound && this.state.consignee_country != this.state.shipper_country && (
													<React.Fragment>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Part #</InputLabel>
															<CustomInput
																id="part_number"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "part_number",
																	placeholder: "",
																	value: this.state.customs.part_number || "",
																	onChange: (e) => {this.handleInput("part_number", e)}
																}}
																required
															/>
														</GridItem>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Origin Country</InputLabel>
															<CustomInput
																id="origin_country"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "origin_country",
																	placeholder: "",
																	value: this.state.customs.origin_country || "",
																	onChange: (e) => {this.handleInput("origin_country", e)}
																}}
																required
															/>
														</GridItem>
														</React.Fragment>
													)}
													{this.state.showWarningText && (
														<Card style={{ backgroundColor:'#ffa21a'}}>
															<CardBody>
																<Typography>
																	Is this description accurate? Customs agents like to see precise and accurate descriptions. FedEx may reject your shipment request if they detect your description is too generic.
																	<a target="blank" href="https://www.fedex.com/en-us/shipping/international/create-documents.html"> Here are some examples from FedEx</a>
																</Typography>
															</CardBody>
														</Card>
													)}
												</GridContainer>

											)}
											{this.state.carrier == "UPS" && this.state.is_inbound && this.state.consignee_country != this.state.shipper_country && (
												<GridContainer>
														<GridItem xs={3}>
															<InputLabel className={classes.label}># of Units</InputLabel>
															<CustomInput
																id="commodity_units"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "commodity_units",
																	placeholder: "",
																	value: this.state.customs.commodity_units || "",
																	onChange: (e) => {this.handleInput("commodity_units", e)}
																}}
																required
															/>
														</GridItem>
														<GridItem>
															<InputLabel className={classes.label}>Units of Measure</InputLabel>
															<br />
															<Select
																MenuProps={{
																	className: classes.selectMenu
																}}
																classes={{
																	select: classes.select + " " + classes.requiredSelect
																}}
																value={this.state.customs.import_uom}
																inputProps={{
																	name: "import_uom",
																	id: "import_uom"
																}}
																onChange={this.handleChange}
															>
																{this.getImportUnitofMeasure()}
															</Select>
														</GridItem>
														<GridItem>
															<InputLabel className={classes.label}>Export Reason</InputLabel>
															<br />
															<Select
																MenuProps={{
																	className: classes.selectMenu
																}}
																classes={{
																	select: classes.select + " " + classes.requiredSelect
																}}
																value={this.state.customs.export_reason}
																inputProps={{
																	name: "export_reason",
																	id: "export_reason"
																}}
																onChange={this.handleChange}
															>
																{this.getExportReasons()}
															</Select>
														</GridItem>
													</GridContainer>
											)}
										<GridContainer>
											{this.state.carrier != 'UPS' && (
												<GridItem xs={6}>
													<InputLabel className={classes.label}>Ship Date (Leave Blank for Current Date)</InputLabel>
													<FormControl fullWidth>
														<Datetime
															value={this.state.ship_date || ""}
															timeFormat={false}
															onChange={m => this.handleDatetime("ship_date", m)}
															className={classes.datetime}
															inputProps={{
																id: "ship_date",
																name: "ship_date",
															}}
														/>
													</FormControl>
												</GridItem>
											)}
										</GridContainer>
										<hr style={{height:'1px',border:'none',color:'#333',backgroundColor:'#333'}}/>
											<GridContainer>
												<GridItem xs={12}>
													<h5 style={{fontWeight: 'bold'}}>Billing Details</h5>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													<InputLabel className={classes.label}>Billing Type</InputLabel>
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{ className: classes.selectMenu }}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.billing_type || ''}
															inputProps={{ name: "billing_type" }}
															onChange={this.handleChange}
															disabled={this.state.carrier == 'USPS'}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="account"
															>
																Account - {this.getAccount(this.state.carrier)}
															</MenuItem>
															{(this.state.is_inbound ? (
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="shipper"
																>
																	Shipper
																</MenuItem>
															) : (
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="recipient"
																>
																	Recipient
																</MenuItem>
															))}
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="third_party"
															>
																Third Party
															</MenuItem>
															{this.state.carrier == 'FEDEX' && (
																<MenuItem
																	classes={{
																		root: classes.selectMenuItem,
																		selected: classes.selectMenuItemSelected
																	}}
																	value="collect"
																>
																	Collect
																</MenuItem>
															)}
														</Select>
													</FormControl>
												</GridItem>
												{(this.state.billing_type == 'recipient' || this.state.billing_type == 'shipper') && (
													<React.Fragment>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Account #</InputLabel>
															<br />
															<CustomInput
																id="billing_account"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "billing_account",
																	placeholder: "",
																	value: this.state.billing_account || "",
																	onChange: (e) => {this.handleInput("billing_account", e)}
																}}
																required
															/>
														</GridItem>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Zip Code</InputLabel>
															<br />
															<CustomInput
																id="billing_zip"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "billing_zip",
																	placeholder: "",
																	value: this.state.billing_zip || "",
																	onChange: (e) => {this.handleInput("billing_zip", e)}
																}}
																helpText="Must match billing account's zip code"
																required
															/>
														</GridItem>
													</React.Fragment>
												)}
												{this.state.billing_type == "third_party" && (
													<GridItem xs={6}>
														<InputLabel className={classes.label}>Account</InputLabel>
														{!_.isEmpty(this.state.third_party_accounts) ? (
															<GridContainer>
																<GridItem xs={10}>
																	<FormControl fullWidth className={classes.selectFormControl}>
																		<Select
																			MenuProps={{ className: classes.selectMenu }}
																			classes={{
																				select: classes.select + " " + classes.requiredSelect
																			}}
																			value={this.state.third_party_billing_account || ""}
																			inputProps={{ name: "third_party_billing_account" }}
																			onChange={this.handleChange}
																		>
																			{this.getThirdPartyAccounts(this.state.third_party_accounts)}
																		</Select>
																	</FormControl>
																</GridItem>
																<GridItem xs={2}>
																	<Tooltip
																		title={
																			<React.Fragment>
																				<Typography color="inherit">Add New Third Party Account</Typography>
																			</React.Fragment>
																		}
																		placement="bottom">
																		<span tabIndex="0">
																			<Button size="sm" color="success" onClick={() => {this.handleModalOpen("thirdPartyModal")}}>
																				<AddCircle />
																			</Button>
																		</span>
																	</Tooltip>
																</GridItem>
															</GridContainer>
														) : (
															<GridItem>
																<Tooltip
																	title={
																		<React.Fragment>
																			<Typography color="inherit">Add New Third Party Account</Typography>
																		</React.Fragment>
																	}
																	placement="bottom">
																	<span tabIndex="0">
																		<Button size="sm" color="success" onClick={() => {this.handleModalOpen("thirdPartyModal")}}>
																			<AddCircle />
																		</Button>
																	</span>
																</Tooltip>
															</GridItem>
														)}
													</GridItem>
												)}
											</GridContainer>
										<hr style={{height:'1px',border:'none',color:'#333',backgroundColor:'#333'}}/>
										<GridContainer>
											<GridItem xs={12}>
												<h5 style={{fontWeight: 'bold'}}>References</h5>
											</GridItem>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={6}>
														<InputLabel className={classes.label}>Order/SO #</InputLabel>
														<br />
														<CustomInput
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "so_num",
																value: this.state.so_num || "",
																onChange: (e) => {this.handleInput("so_num", e)}
															}}
															white
															required={this.state.required.includes('so_num')}
														/>
													</GridItem>
													<GridItem xs={6}>
														<InputLabel className={classes.label}>PO #</InputLabel>
														<br />
														<CustomInput
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "po_num",
																value: this.state.po_num || "",
																onChange: (e) => {this.handleInput("po_num", e)}
															}}
															white
															required={this.state.required.includes('po_num')}
														/>
													</GridItem>
													<GridItem xs={6}>
														<InputLabel className={classes.label}>Department </InputLabel>
														<WithTooltip
															content="For any UPS shipments or FedEx hazmat shipments, this field will be omitted from labels if more than 2 other references are provided."
														>
															<small>
																<span>
																	<Warning style={{ height: "16px" }} />
																</span>
															</small>
														</WithTooltip>

														<CustomInput
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "dept_num",
																value: this.state.dept_num || "",
																onChange: (e) => {this.handleInput("dept_num", e)}
															}}
															white
														/>
													</GridItem>
													<GridItem xs={6}>
														<InputLabel className={classes.label}>Reference #</InputLabel>
														<br />
														<CustomInput
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "ref_num",
																value: this.state.ref_num || "",
																onChange: (e) => {this.handleInput("ref_num", e)}
															}}
															white
															required={this.state.required.includes('ref_num')}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
											<GridItem>
												<ReferenceList
													refTypeUrl={''}
													data={this.state.references}
													onUpdate={this.handleReference}
													headerWeight="bold"
													refTypes={this.state.refTypes}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={2} sm={2} md={2} lg={2} style={{paddingTop: '0px', marginTop: '0px'}} >
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<h5 style={{fontWeight: 'bold'}}>Special Services</h5>
									</GridItem>
									<GridItem xs={12}>
										{this.state.carrier != "FEDEX" && (
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.cod}
														onChange={(e) => {
															this.handleChecked("cod", e);
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="C.O.D."
											/>
										)}
										{this.state.cod && (
											<GridItem xs={12}>
												<InputLabel className={classes.label}>C.O.D. Amount</InputLabel>
												<br />
												<CustomInput
													id="cod_amount"
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
														name: "cod_amount",
														placeholder: '',
														value: this.state.cod_amount || '',
														onChange: (e) => {
															this.handleInput("cod_amount", e);
														},
													}}
													required
												/>
											</GridItem>
										)}
										{this.state.cod && (
											<GridItem xs={12}>
												<InputLabel className={classes.label}>C.O.D. Collection Type</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{ className: classes.selectMenu }}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.cod_collection_type || ''}
														inputProps={{ name: "cod_collection_type" }}
														onChange={this.handleChange}
													>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="ANY"
														>
															Any (Unsecured Payment)
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="GUARANTEED_FUNDS"
														>
															Guaranteed Funds (Secured Payment)
														</MenuItem>
													</Select>
												</FormControl>
											</GridItem>
										)}
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.signature}
												onChange={(e) => {
													this.handleChecked("signature", e);
												}}
											/>
										}
										classes={{ label: classes.label }}
										label="Require Signature"
									/>
									{this.state.signature && (
										<GridItem xs={12}>
											<InputLabel className={classes.label}>Signature Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.signature_type || ''}
													inputProps={{ name: "signature_type" }}
													onChange={this.handleChange}
												>
													{this.state.carrier != "UPS" && (
													<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="INDIRECT"
														>
															Indirect Signature Required
														</MenuItem>
													)}
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="DIRECT"
													>
														Direct Signature Required
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="ADULT"
													>
														Adult Signature Required
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
									)}
									{this.state.carrier != "USPS" && (
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.saturday_delivery}
													onChange={(e) => {
														this.handleChecked("saturday_delivery", e);
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Saturday Delivery"
										/>
									)}
									{this.state.carrier == "UPS" && (

										<WithTooltip
											title="Carbon Neutral"
											content="Carbon Neutral is only a supported service option for UPS."
										>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.carbon_neutral}
														onChange={(e) => {
															this.handleChecked("carbon_neutral", e);
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Carbon Neutral"
											/>
										</WithTooltip>
									)}
									{this.state.saturday_delivery && this.state.carrier == "FEDEX" && (
										<InfoArea
											title="Saturday Delivery"
											description="FedEx requires matching the service type and ship date such that the shipment would arrive on a Saturday"
											icon={Warning}
											iconColor="rose"
										/>
									)}
									{this.state.saturday_delivery && this.state.carrier == "UPS" && (
										<InfoArea
											title="Saturday Delivery"
											description="UPS will allow you to select Saturday Delivery regardless of whether your delivery date would be Saturday"
											icon={Warning}
											iconColor="warning"
										/>
									)}
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.asn}
												onChange={(e) => {
													this.handleChecked("asn", e);
												}}
											/>
										}
										classes={{
											label: classes.label
										}}
										label="Shipment Notifications"
									/>
									{this.state.asn && (
										<GridItem xs={12}>
											<CustomInput
												id="asn_email"
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "asn_email",
													placeholder: "",
													value: this.state.asn_email || "",
													onChange: (e) => {this.handleInput("asn_email", e)},
												}}
												helpText="Enter up to 5 email addresses seperated by commas"
												white
											/>
										</GridItem>
									)}
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<h5 style={{fontWeight: 'bold'}}>Label Type</h5>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select
												MenuProps={{ className: classes.selectMenu }}
												classes={{
													select: classes.select + " " + classes.requiredSelect
												}}
												value={this.state.label_type || ""}
												inputProps={{ name: "label_type", id: "label_type" }}
												onChange={this.handleChange}
												>
												{this.state.carrier != 'USPS' && (
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="STOCK"
													>
														Label Stock (PDF)
													</MenuItem>
												)}
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="PAPER"
												>
													Plain Paper
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value="ZPL"
												>
													ZPL (Zebra Thermal Printer)
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12}>
										<InputLabel className={classes.label}>Label Size</InputLabel>
										<br />
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select
												MenuProps={{ className: classes.selectMenu }}
												classes={{
													select: classes.select + " " + classes.requiredSelect
												}}
												value={this.state.label_size || ""}
												inputProps={{ name: "label_size", id: "label_size" }}
												onChange={this.handleChange}
											>
												{this.renderLabelSizes()}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12}>
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.default_label_save}
													onChange={(e) => {
														this.handleChecked("default_label_save", e);
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label={"Set Default For " + this.state.carrier}
										/>
									</GridItem>
									{this.state.user_company_settings && this.state.user_company_settings.enable_costco_label == 1 && (
										<GridItem xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.include_costco_label}
														onChange={(e) => {
															this.handleChecked("include_costco_label", e);
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={"Include Costco Wholesale Shipping Label"}
											/>
										</GridItem>
									)}
									<GridItem xs={12}>
										<Button size="sm" color="linkedin" onClick={ () => {this.setShowManagePrinters()}}>
											Manage Printers
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Grid item xs={12} container align="center">
							<GridItem xs={12}>
								{this.state.schedulingShipment ? (
									<Button size="lg" color="linkedin">
										<CircularProgress size={16} style={{ color: "white" }} />
									</Button>
								) : (
									<Button size="lg" color="linkedin" disabled={this.exceedsWeightLimit()} onClick={ () => {this.scheduleShipment()}}>
										Ship
									</Button>
								)}
							</GridItem>
						</Grid>
					</GridItem>
				</GridContainer>
				<PrintZplModal
					open={this.state.printLabelModal ? true: false}
					modalName="printLabelModal"
					default_printer_save={this.state.default_printer_save}
					printerType={this.state.shipmentInfo.carrier}
					zpl={this.state.zpl}
					handleChange={this.handleChange}
					handleChecked={this.handleChecked}
					handleModalClose={this.handleModalClose}
				/>
				<PrintZplModal
					open={this.state.printDocModal ? true: false}
					modalName="printDocModal"
					default_printer_save={this.state.default_printer_save}
					printerType="documents"
					zpl={this.state.docZpl}
					handleChange={this.handleChange}
					handleChecked={this.handleChecked}
					handleModalClose={this.handleModalClose}
				/>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.thirdPartyModal ? true : false}
					TransitionComponent={SlideTransition}
					keepMounted
					onClose={() => this.handleModalClose("thirdPartyModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("thirdPartyModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Add Third Party Account For {this.state.carrier}</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						<GridContainer className={classes.left}>
							<GridItem xs={4}>
								<InputLabel className={classes.label}>Name</InputLabel>
								<br />
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "third_party_account_name",
										value: this.state.third_party_account_name || "",
										onChange: (e) => {this.handleInput("third_party_account_name", e)}
									}}
									required
								/>
							</GridItem>
							<GridItem xs={4}>
								<InputLabel className={classes.label}>Account #</InputLabel>
								<br />
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "third_party_account_number",
										value: this.state.third_party_account_number || "",
										onChange: (e) => {this.handleInput("third_party_account_number", e)}
									}}
									required
								/>
							</GridItem>
							<GridItem xs={4}>
								<InputLabel className={classes.label}>Zip Code</InputLabel>
								<br />
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "third_party_account_zip",
										value: this.state.third_party_account_zip || "",
										onChange: (e) => {this.handleInput("third_party_account_zip", e)}
									}}
									required
								/>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("thirdPartyModal")} color="white" className={classes.marginRight}>
							Close
						</Button>
						{" "}
						{this.state.savingThirdPartyAccount ? (
							<Button size="sm" color="linkedin">
								<CircularProgress size={16} style={{ color: "white" }} />
							</Button>
						) : (
							<Button
								onClick={ () => {
									this.saveNewThirdPartyAccount();
								}}
								color="linkedin"
							>
								Save
							</Button>
						)}
					</DialogActions>
				</Dialog>
				<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.contactSearchModal}
						TransitionComponent={SlideTransition}
						keepMounted
						onClose={() => this.handleModalClose("contactSearchModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("contactSearchModal")}>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.modalTitle}>Found Multiple Contacts</h4>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
							{!_.isEmpty(this.state.contacts) && <Table tableHead={["Code", "Address", "Contact", "Actions"]} tableData={this.getContacts(this.state.contacts)} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} />}
						</DialogContent>
						<DialogActions className={classes.modalFooter}>
							<Button onClick={() => this.handleModalClose("contactSearchModal")} color="white">
								Close
							</Button>
						</DialogActions>
					</Dialog>
				<ProductSearchModal
                	open={this.state.productSearchModal}
                	onClose={() => this.handleModalClose("productSearchModal")}
                	products={this.state.products}
                	getProducts={() => this.getProducts(this.state.products)}
                	categoryColumns={prodCatCols}
                />
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        openAlertModal,
        clearParcelLookup,
		performLookup,
        setJSPM,
        setAvailablePrinters,
        jspmStatus,
        setUpPrinters,
		autoPrint,
		printDocument,
    }, dispatch);
}

const mapStateToProps = state => {
	return {
		hasAddressBookRead: hasPermission("ADDRESS_BOOK_READ")(state),
        hasAddressBookWrite: hasPermission("ADDRESS_BOOK_WRITE")(state),
		canCreateBol: hasPermission("USE_BILL_OF_LADING")(state),
		weightLimit: parcelWeightLimit(state),
		search_by: state.ParcelLookup.search_by,
		search_terms: state.ParcelLookup.search_terms,
		reference_type: state.ParcelLookup.reference_type,
		refTypes: state.ParcelLookup.refTypes,
        printers: state.PrintZplModal.printers,
        JSPM: state.PrintZplModal.JSPM,
        zplDocTypes: state.PrintZplModal.zplDocTypes,
        dgDocTypes: state.PrintZplModal.dgDocTypes,
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(ParcelScheduler)));
