import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FREIGHT_CLASSES } from "../../constants.jsx";

class FreightClassSelect extends Component {
    render() {
        return (
            <Select {...this.props}>
                {[""].concat(FREIGHT_CLASSES).map((classCode, index) => {
                    return (
                        <MenuItem key={index} value={classCode}>
                            {classCode !== "" ? classCode : "Select Class"}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
}

export default FreightClassSelect;
