import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Table from "~/components/Table/Table.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class DuplicateBolsModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Duplicate BOLs Found</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5>
                                We found {this.props.duplicates.length} BOL(s) with duplicate identifier(s)
                            </h5>
                            {!_.isEmpty(this.props.duplicates) && <Table tableHead={["BoL #", "PO #", "SO #", "Ref #", "Warehouse", "Status", "Actions"]} tableData={this.props.getDuplicates()} customCellClasses={[classes.right]} customClassesForCells={[6]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[6]} />}
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => this.props.continueExecution()} color="linkedin">
                        Continue Execution
                    </Button>
                    <Button onClick={() => this.props.cancelAllAndExecute()} color="danger">
                        Cancel All and Execute
                    </Button>
                    <Button onClick={() => this.props.onClose()} color="white">
                        Cancel Execution
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(DuplicateBolsModal);