const initialState = {
	bol: "",
	modalOpen: false,
	bolNotesLoading: false,
	bolNotes: [],
	saveBolNoteLoading: false,
	deleteBolNoteLoading: false,
	newNoteMessage: "",
	editingNotes: {},
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_BOL_NOTES_MODAL_OPEN": {
			return {...newState, modalOpen: action.val};
		}
		case "SET_BOL_NOTES_MODAL_NOTES_LOADING": {
			return {...newState, bolNotesLoading: action.val};
		}
		case "SET_BOL_NOTES_MODAL_NOTES": {
			return {...newState, bolNotes: [...action.val]};
		}
		case "SET_BOL_NOTES_MODAL_SAVE_BOL_NOTE_LOADING": {
			return {...newState, saveBolNoteLoading: action.val};
		}
		case "SET_BOL_NOTES_MODAL_DELETE_BOL_NOTE_LOADING": {
			return {...newState, deleteBolNoteLoading: action.val};
		}
		case "SET_BOL_NOTES_MODAL_BOL": {
			return {...newState, bol: action.val};
		}
		case "SET_BOL_NOTES_NEW_NOTE_MESSAGE": {
			return {...newState, newNoteMessage: action.val};
		}
		case "SET_BOL_NOTES_EDITING_NOTES": {
			return {...newState, editingNotes: {...action.val}};
		}
		case "CLEAR_BOL_NOTES_MODAL": {
			return initialState;
		}
		default:
			return state;
	}
}