import React from "react";
import { withSnackbar } from "notistack";
import qs from "qs";
import _ from "lodash";
import axios from "~/variables/axios.jsx";
import { Redirect } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import moment from "moment";
import { flushSync } from 'react-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import DateRangeStep from "~/views/CustomReporting/components/CommonSteps/DateRangeStep.jsx";
import FilterStep from "~/views/CustomReporting/components/CommonSteps/FilterStep.jsx";
import FinishStep from "~/views/CustomReporting/components/CommonSteps/FinishStep.jsx";
import OptionStep from "~/views/CustomReporting/components/CommonSteps/OptionStep.jsx";
import GroupingStep from "~/views/CustomReporting/components/SummarySteps/GroupingStep.jsx";
import SummarizationStep from "~/views/CustomReporting/components/SummarySteps/SummarizationStep.jsx";
import ClassChargeStep from "~/views/CustomReporting/components/DetailSteps/ClassChargeStep.jsx";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ScrollableReportBuilder extends React.Component {
  _fieldNum = 0;
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      loading: true,
      optionSelected: [],
      allStates: {
        classcharge: {},
        options: {},
        finish: {},
        grouping: {},
        summarization: {},
        date: {},
        filter: {},
      },
      grouping: [],
      filters: [],
      summarization: [],
      redirect: null,
      reportId: this.props.match.params.reportId,
      reportBehavior: _.upperFirst(this.props.match.params.reportBehavior),
      reportType: _.upperFirst(this.props.match.params.reportType),
      reportName: "",
      classcharges: [],
      date: {},
      expanded: "panel1",
      scrollValue: "panel1",
      wizard: 0,
    };
  }

  async componentDidMount() {
    if (this.state.reportId != null && this.state.reportId != 0) {
      this.getReport().then((_) => {
        this.setState({ mounted: true });
      });
    } else {
      this.setState({
        mounted: true,
        reportType: _.upperFirst(this.props.match.params.reportType) // this replaced a localstorage call, but I don't think we need it at all
      });
    }
  }

  getReport = async () => {
    let url =
      "/index.php?p=api&r=json&c=reportDefinition&m=reportDefinitionById&d=" +
      this.state.reportId;

    try {
      const response = await axios.get(url);

      if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
        let result = response.data.body;
        let grouping = result.grouping ? result.grouping.split(",").map(val => val.trim()) : [];
        if(grouping.length > 0 && response.data.user.user_settings.restrict_report_building_fields) {
          if(result.restrictedColumns.length > 0) {
            result.restrictedColumns.forEach((item) => {
              let index = -1;
              if((index = grouping.indexOf(item)) >= 0) {
                grouping.splice(index, 1);
              }
            })
          }
        }

        this.setState({
          reportBehavior: result.report_behaviour,
          reportType: result.type,
          grouping: grouping,
          summarization: result.summary,
          classcharges: result.classcharges ? result.classcharges.split(",").map(val => val.trim()) : [],
          optionSelected: result.column,
          reportName: result.name,
          filters: result.filter,
          date: {
            date_type: result.date_type,
            start_date: result.start_date,
            end_date: result.end_date,
            date_offset: result.date_offset,
            date_operator: result.date_operator,
            date_column: result.date_column,
          },
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  replaceStr(str, find, replace) {
    for (var i = 0; i < find.length; i++) {
      str = str.replace(new RegExp(find[i], "gi"), replace[i]);
    }
    return str;
  }

  handleChange = (panel) => (event, isExpanded) => {
    const allState = this.state.allStates;
    const prevPanel = this.state.expanded;

    if (!panel) { panel = event.target.value }

    const startdate = new Date(allState.date.startDate);
    const enddate = new Date(allState.date.endDate);

    if (prevPanel !== panel) { // If we are switching panels, validate current panel before switching
      switch (prevPanel) {
        case 'panel1': // Fields
          if (this.state.optionSelected.length == 0) {
            this.props.setNotification("Please select at least one field first!", { variant: "warning" });
            isExpanded = false;
          }
          break;
        case 'panel2': // Date Range
          if (!allState.date.dateColumn) {
            this.props.setNotification("The date type must be provided!", { variant: "warning" });
            isExpanded = false;
          } else if (allState.date.isDynamic) { // Dynamic
            if (!allState.date.dateOperator) {
              this.props.setNotification("The date operator must be provided!", { variant: "warning" });
              isExpanded = false;
            } else if (!allState.date.dynamicValues) {
              this.props.setNotification("The dynamic values must be provided!", { variant: "warning" });
              isExpanded = false;
            }
          } else if (!allState.date.isDynamic) { // Standard
            if (!allState.date.startDate) {
              this.props.setNotification("The start date must be provided!", { variant: "warning" });
              isExpanded = false;
            } else if (!allState.date.isStartDateValid) {
              this.props.setNotification("Invalid start date, The date format is YYYY-MM-DD!", { variant: "warning" });
              isExpanded = false;
            } else if (!allState.date.endDate) {
              this.props.setNotification("The end date must be provided!", { variant: "warning" });
              isExpanded = false;
            } else if (!allState.date.isEndDateValid) {
              this.props.setNotification("Invalid end date, The date format is YYYY-MM-DD!", { variant: "warning" });
              isExpanded = false;
            } else if (startdate > enddate) {
              this.props.setNotification("The start date must be less than the end date!", { variant: "warning" });
              isExpanded = false;
            }
          }
          break;
        case 'panel3': // Class/Charge
          // No validation necessary
          break;
        case 'panel4': // Grouping
          // TODO: Validate at least one group is selected
          break;
        case 'panel5': // Filters
          // check filters for trailing logical operators (AND or OR)
          if (typeof allState.filter !== 'undefined') {
            let filters = allState.filter.filters;

            let trailingOperator = filters.find((filter, index) => {
              if (filter.filterColumn !== 'company_id' && filter.logicalOperator) { // company_id is hardcoded with an AND operator
                let nextFilter = filters[index + 1]

                if (!nextFilter.filterColumn || !nextFilter.operator || !nextFilter.inputValue) {
                  return true;
                }
              }
            });

            if (trailingOperator) {
              this.props.setNotification("Please fill the next filter field !", { variant: "warning" });
              isExpanded = false;
            }
          }
          break;
        case 'panel6': // Summary
          // No validation necessary
          break;
        case 'panel7': // Report Output
          // TODO: validate finishing step
          break;
        default:

          break;
      }

      if (isExpanded) {
        this.setState({ expanded: panel, scrollValue: panel });
      }
    }
  };

  validateReport = () => {
    const allState = this.state.allStates;

    if (_.isEmpty(allState.options) || _.isEmpty(allState.options.selectedOptions)) {
      this.props.setNotification("Option values missing, please try again!", { variant: "warning" });
      return false;
    } else if (this.state.reportBehavior == "Summary" && _.isEmpty(allState.grouping.columns)) {
      this.props.setNotification("Grouping values missing, please try again!", { variant: "warning" });
      return false;
    } else if (allState.finish.selectedValue === undefined) {
      this.props.setNotification("One radio button should be selected!", { variant: "warning" });
      return false;
    } else if (allState.date.isDynamic == false) {
      if (allState.date.endDate == "" || allState.date.startDate == "" || allState.date.dateColumn == "") {
        this.props.setNotification("Date values missing, please try again!", { variant: "warning" });
        return false;
      }
    } else if (allState.date.isDynamic == true) {
      if (allState.date.dateOperator == "" || !allState.date.dynamicValues || allState.date.dateColumn == "") {
        this.props.setNotification("Date values missing, please try again!", { variant: "warning" });
        return false;
      }
    } else if (this.state.reportBehavior == "Detail") {
      if (_.isEmpty(allState.classcharge) || _.isEmpty(allState.classcharge.classcharges)) {
        this.props.setNotification("Class/Charge values missing, please try again!", { variant: "warning" });
        return false;
      }
    } else if (this.state.reportBehavior == "Summary") {
      if (_.isEmpty(allState.grouping) || _.isEmpty(allState.grouping.columns)) {
        this.props.setNotification("Grouping values missing, please try again!", { variant: "warning" });
        return false;
      }

      if (_.isEmpty(allState.summarization.summaries[0].summarizationcolumunColumn) || _.isEmpty(allState.summarization.summaries[0].summaryType)) {
        this.props.setNotification("Summary values, missing please try again!", { variant: "warning" });
        return false;
      }
    }

    if (allState.finish.SaveReport) {
      if (_.isEmpty(allState.finish) || _.isEmpty(allState.finish.reportSchedule.name)) {
        this.props.setNotification("Report name  missing, please try again!", { variant: "warning" });
        return false;
      }

      if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(allState.finish.reportSchedule.name)) {
        this.props.setNotification("The report name must not contain symbol!", { variant: "warning" });
        return false;
      }
    } else if (allState.finish.Schedule) {
      if (_.isEmpty(allState.finish) || _.isEmpty(allState.finish.reportSchedule.name)) {
        this.props.setNotification("Report name  missing, please try again!", { variant: "warning" });
        return false;
      }

      if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(allState.finish.reportSchedule.name)) {
        this.props.setNotification("The report name must not contain symbol!", { variant: "warning" });
        return false;
      }

      if (allState.finish.reportSchedule.schedule === "") {
        this.props.setNotification("Reoccurring is required!", { variant: "warning" });
        return false;
      }

      const startdate = allState.finish.reportSchedule.startDate;
      const enddate = allState.finish.reportSchedule.endDate;

      if (startdate === "") {
        this.props.setNotification("The start date must be provided!", { variant: "warning" });
        return false;
      } else if (!this.isValidDate(startdate)) {
        this.props.setNotification("Invalid start date, The date format is YYYY-MM-DD!", { variant: "warning" });
        return false;
      } else if (enddate === "" && allState.finish.reportSchedule.schedule !== "Never") {
        this.props.setNotification("The end date must be provided!", { variant: "warning" });
        return false;
      } else if (!this.isValidDate(enddate)) {
        this.props.setNotification("Invalid end date, The date format is YYYY-MM-DD!", { variant: "warning" });
        return false;
      } else if (startdate > enddate && allState.finish.reportSchedule.schedule !== "Never") {
        this.props.setNotification("The start date must be less than the end date!", { variant: "warning" });
        return false;
      } else if (allState.finish.reportSchedule.format === "") {
        this.props.setNotification("Export format is required!", { variant: "warning" });
        return false;
      }
    }
    return true;
  };

  saveGenerateReport = async () => {
    const data = this.state.allStates;
    let reportType = this.state.reportType;
    let reportBehavior = this.state.reportBehavior;

    this.setState({ loading: true });

    if (_.isEmpty(data.classcharge) || _.isEmpty(data.classcharge.classcharges)) {
      data.classcharge.classcharges = this.state.classcharges;
    }

    if (!this.validateReport()) {
      this.setState({ loading: false });
      return;
    }

    data.filter.filtersList = [];
    data.options.options = [];
    data.grouping.columnsList = [];
    data.summarization.summaryList = [];

    if (data.date.isDynamic) {
      data.date.dynamicValues = data.date.dynamicValues.join(",");
    } else {
      data.date.startDate = moment(data.date.startDate).toDate();
      data.date.endDate = moment(data.date.endDate).toDate();
    }

    if(data.startDate != "") {
      data.startDate = moment(data.startDate).toDate();
    }

    if (data.finish.Schedule) {
      delete data.finish.reportSchedule.time; // only need formatted time
      data.finish.reportSchedule.recipients = data.finish.reportSchedule.recipients.filter((e) => e);
    }

    let wizard = 0;
    let reportdata = { data, reportType, reportBehavior, wizard };

    let url =
      this.state.reportId && 
      this.state.reportId != "0" &&
      data.finish.SaveAs != true &&
      data.finish.RunNow != true
        ? "/index.php?p=api&r=json&c=reportDefinition&m=saveReportDefinition&d=" +
          this.state.reportId
        : "/index.php?p=api&r=json&c=reportDefinition&m=saveReportDefinition";

    try {
      const response = await axios.post(url, qs.stringify(reportdata));

      if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
        this.props.handleMessages(response);
      }

      if (response.status == 200 && !_.isEmpty(response.data) && !_.isEmpty(response.data.body)) {
        let report_id = response.data.body;

        if (report_id != false) {
          let generateurl = basePath + "/admin/reports";

          if (data.finish.RunNow) {
            generateurl = basePath + "/admin/run-report/" + report_id;
          } else if (data.finish.SaveReport) {
            generateurl = basePath + "/admin/run-report/" + report_id;
          } else if (data.finish.Schedule) {
            generateurl = basePath + "/admin/reports";
          }

          this.setState({
            loading: false,
            redirect: <Redirect to={generateurl} />,
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.setNotification("Please try again!", {
          variant: "warning",
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
      this.props.setNotification("Please try again!", {
        variant: "warning",
      });
    }
  }

  isValidDate = (dateString) => {
    const dateFormat = "YYYY-MM-DD";
    return moment(dateString, dateFormat, true).isValid();
  }

  handleStepState = (stepState, stepid) => {
    flushSync(() => {
      this.setState({
        allStates: {
         ...this.state.allStates,
          [stepid]: stepState,
        }
      });
    })
  };

  handleOptionSelected = (columns) => {
    this.setState({ optionSelected: columns });
  }

  render() {
    if (this.state.redirect) {
      return this.state.redirect;
    }
    if (!this.state.mounted) {
      return null;
    }
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {this.state.reportBehavior == "Summary" ? (
                    <h4 className={classes.title}>Summary Report</h4>
                  ) : (
                    <h4 className={classes.title}>Detail Report</h4>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.selectFormControl}
                    style={{ width: "225px", marginLeft: "50%" }}
                  >
                    <InputLabel className={classes.label}>Jump to</InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={this.state.scrollValue || ""}
                      onChange={this.handleChange()}
                      inputProps={{
                        id: "jumpto",
                        name: "jumpto",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="panel1"
                      >
                        Step 1 -Field
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="panel2"
                      >
                        Step 2 - Date Range
                      </MenuItem>
                      {this.state.reportBehavior == "Detail" ? (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="panel3"
                        >
                          Step 3 - Class/Charge
                        </MenuItem>
                      ) : null}
                      {this.state.reportBehavior == "Summary" ? (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="panel4"
                        >
                          Step {this.state.reportBehavior == "Summary" ? 3 : 4} -
                          Grouping
                        </MenuItem>
                      ) : null}

                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="panel5"
                      >
                        Step 4 - Filter
                      </MenuItem>
                      {this.state.reportBehavior == "Summary" ? (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="panel6"
                        >
                          Step 5 - Summarization
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="panel7"
                      >
                        Step{this.state.reportBehavior == "Summary" ? 6 : 5} - Report
                        Output
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <div style={{ width: "100%" }}>
                  <Accordion
                    expanded={this.state.expanded === "panel1"}
                    onChange={this.handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h4 className={classes.title}>Step 1 - Field</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GridItem xs={12} sm={12} md={12}>
                        <OptionStep
                          handleStepState={this.handleStepState}
                          handleOptionSelected={this.handleOptionSelected}
                          reportType={this.state.reportType}
                          reportId={this.state.reportId}
                          selectedOptions={this.state.optionSelected}
                        />
                      </GridItem>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={this.state.expanded === "panel2"}
                    onChange={this.handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <h4 className={classes.title}>Step 2 - Date Range</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GridItem xs={12} sm={12} md={12}>
                        <DateRangeStep
                          handleStepState={this.handleStepState}
                          date={this.state.date}
                          reportId={this.state.reportId}
                          reportType={this.state.reportType}
                        />
                      </GridItem>
                    </AccordionDetails>
                  </Accordion>
                  {this.state.reportBehavior == "Detail" ? (
                    <Accordion
                      expanded={this.state.expanded === "panel3"}
                      onChange={this.handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <h4 className={classes.title}>Step 3 - Class/Charge</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} sm={12} md={12}>
                          <ClassChargeStep
                            handleStepState={this.handleStepState}
                            classcharges={this.state.classcharges}
                            reportId={this.state.reportId}
                          />
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                  {this.state.reportBehavior == "Summary" ? (
                    <Accordion
                      expanded={this.state.expanded === "panel4"}
                      onChange={this.handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <h4 className={classes.title}>Step 3 - Grouping</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} sm={12} md={12}>
                          <GroupingStep
                            handleStepState={this.handleStepState}
                            selectedOption={this.state.optionSelected}
                            allOptions={this.state.allStates.options.options}
                            grouping={this.state.grouping}
                            reportId={this.state.reportId}
                            from={"grouping"}
                          />
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}

                  <Accordion
                    expanded={this.state.expanded === "panel5"}
                    onChange={this.handleChange("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                    >
                      <h4 className={classes.title}>Step 4 - Filter </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GridItem xs={12} sm={12} md={12}>
                        <FilterStep
                          handleStepState={this.handleStepState}
                          filters={this.state.filters}
                          reportId={this.state.reportId}
                          reportType={this.state.reportType}
                        />
                      </GridItem>
                    </AccordionDetails>
                  </Accordion>
                  {this.state.reportBehavior == "Summary" ? (
                    <Accordion
                      expanded={this.state.expanded === "panel6"}
                      onChange={this.handleChange("panel6")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                      >
                        <h4 className={classes.title}>
                          Step 5 - Summarization
                        </h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} sm={12} md={12}>
                          <SummarizationStep
                            handleStepState={this.handleStepState}
                            selectedOption={this.state.optionSelected}
                            summarization={this.state.summarization}
                            reportId={this.state.reportId}
                          />
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                  <Accordion
                    expanded={this.state.expanded === "panel7"}
                    onChange={this.handleChange("panel7")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7bh-content"
                      id="panel7bh-header"
                    >
                      <h4 className={classes.title}>
                        Step {this.state.reportBehavior == "Summary" ? 6 : 5} - Report
                        Output
                      </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.state.mounted == true ? (
                          <FinishStep
                            handleStepState={this.handleStepState}
                            reportName={this.state.reportName}
                            reportId={this.state.reportId}
                          />
                        ) : null}
                        <Button
                          color="linkedin"
                          onClick={this.saveGenerateReport}
                        >
                          Generate Report
                        </Button>
                      </GridItem>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(withSnackbar(ScrollableReportBuilder));
