import React, { Component } from "react";
import { withSnackbar } from "notistack";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

// style for an arbitrary view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Error403Container extends Component {

    render() {
        const classes = this.props.classes || {};
        return (
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <h3>403 - Access Denied</h3>
                                </GridItem>
                                <GridItem xs={12}>
                                    <strong>You do not have permission to view this page.</strong>
                                </GridItem>
                                <GridItem xs={6} className={classes.left}>
                                    <br />
                                    If you followed this link directly,
                                    email our support team at <a href="mailto:customerservice@targetfmi.com">customerservice@targetfmi.com</a>.
                                    If you need access to this page, your supervisor can request it be made available by
                                    calling Target Freight Management at <strong>1-888-653-1323</strong>.
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}

export default (withStyles(style)(withSnackbar(Error403Container)));