import React from "react";
import qs from "qs";

import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";

// material ui icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newPermissionGroupStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewAccessorial extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			alert: null,
			redirect: null,
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			name: "",
			code: "",
			weight: "1",
			add_all: false,
			common: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveAccessorial = this.saveAccessorial.bind(this);
	}
	async componentDidMount() {
		let id = this.props.match.params.id || "";

		let url = "/index.php?p=api&r=json&c=admin&m=newAccessorial";

		if (!_.isEmpty(id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editAccessorial&d=" + id;
		}

		this.setState({ mounted: true, id });

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user)) {
				let name = "", code = "";
				if (!_.isEmpty(response.data.body) && !_.isEmpty(id)) {
					name = response.data.body[0].name;
					code = response.data.body[0].code;
					this.props.pageTitle('Edit Accessorial - ' + response.data.body[0].name);
				}
				this.setState({
					show: true,
					loading: false,
					body: response.data.body,
					user: response.data.user,
					id,
					name,
					code,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	async saveAccessorial() {
		this.setState({ loading: true });

		const { name, code, weight, add_all, common, id } = this.state;

		let data = { name, code };

		if (_.isEmpty(id)) {
			data.weight = weight;
			if(add_all) data.add_all = 1;
			if(common) data.common = 1;
		}

		let url = "/index.php?p=api&r=json&c=admin&m=newAccessorial";

		if (!_.isEmpty(id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editAccessorial&d=" + id;
		}

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({
					loading: false,
					redirect: <Redirect to={basePath + "/admin/settings/accessorials"} />
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error creating the accessorial!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error creating the accessorial!",
				{ variant: "error" }
			);
		}
	}
	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel className={classes.label}>Name</InputLabel><br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											id: "name",
											name: "name",
											value: this.state.name || "",
											onChange: this.handleInput("name"),
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Code</InputLabel><br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											id: "code",
											name: "code",
											value: this.state.code || "",
											onChange: this.handleInput("code"),
										}}
										white
									/>
								</GridItem>
								{_.isEmpty(this.state.id) && (
									<GridItem xs>
										<InputLabel className={classes.label}>Add with Weight</InputLabel><br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "number",
												id: "weight",
												name: "weight",
												value: this.state.weight || 1,
												onChange: this.handleInput("weight"),
											}}
											white
										/>
									</GridItem>
								)}
								<GridItem xs>
									<Button color="linkedin" onClick={this.saveAccessorial}>
										Save
									</Button>
								</GridItem>
							</GridContainer>
							{_.isEmpty(this.state.id) && (
								<GridContainer>
									<GridItem xs={3}>
										<FormControlLabel
											control={
												<Checkbox
													checkedIcon={
														<Check className={classes.checkedIcon} />
													}
													icon={
														<Check className={classes.uncheckedIcon} />
													}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.add_all}
													onChange={this.handleChecked("add_all")}
												/>
											}
											classes={{ label: classes.label }}
											label="Add to all existing profiles"
										/>
									</GridItem>
									<GridItem xs={3}>
										<FormControlLabel
											control={
												<Checkbox
													checkedIcon={
														<Check className={classes.checkedIcon} />
													}
													icon={
														<Check className={classes.uncheckedIcon} />
													}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.common}
													onChange={this.handleChecked("common")}
												/>
											}
											classes={{ label: classes.label }}
											label="Add as common"
										/>
									</GridItem>
								</GridContainer>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newPermissionGroupStyle)(
	withSnackbar(NewAccessorial)
);
