import React from "react";
import { connect } from "react-redux";
import qs from "qs";
import { hasPermission, userIsAdmin, adminInUserSession } from "../../redux/selectors/Admin.jsx";

import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";

import { formatHazUoM } from "../../helpers.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Search from "@material-ui/icons/Search";


// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";

import FreightClassSelect from "../../components/TMS/FreightClassSelect.jsx";

import HazmatInfo from "../../components/HazmatInfo/HazmatInfo.jsx";

// style for this view
import newProductStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class EditProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			show: false,
			mounted: false,
			loading: true,
			selectedKey: null,
			user: null,
			body: null,
			selected_warehouse: "",
			regulation_set: "iata",
			warehouse: "",
			warehouses: [],
			code: "",
			part_no: "",
			product: "",
			pieces: "1",
			units: [],
			uom: "",
			length: "",
			width: "",
			height:"",
			weight: "",
			net_weight: "",
			class: "",
			nmfc: "",
			allowedUnitsOfMeasure: [],
			sub_nmfc: "",
			declared_value: "",
			default: false,
			parcel_default: false,
			alert_message: "",
			hazardous: false,
			haz_class: "",
			subHazardClass: "",
			un_num: "",
			accessibility: "",
			emergency: "",
			group: "",
			packageing_type: "",
			packing_inst: "",
			hs_code: "",
			packages: [
				{
					hazmat: {
						un: "",
						class: "",
						subHazardClass: "",
						properShippingName: "",
						packingGroup: "",
						packingInstructionCode: "",
						technicalName: "",
						packagingType: "",
						innerContainerType: "",
						regulatoryQuantity: "",
						uom: "",
						quantity: "",
						transportationMode: "",
						accessibility: "",
						requiredLabel: "",
					}
				}
			],
			log: [],
			done: false,
			is_parcel: false,
			parcel_view: false,
			whMgtId: null,
		};
		this.getWarehouses = this.getWarehouses.bind(this);
		this.getSubClasses = this.getSubClasses.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveProduct = this.saveProduct.bind(this);
		this.getLog = this.getLog.bind(this);
		this.getClasses = this.getClasses.bind(this);
		this.validateHaz = this.validateHaz.bind(this);


	}
	async componentDidMount() {
		if(window.location.pathname.includes('parcel')) {
			this.setState({parcel_view: true});
		}
		const id = this.props.match.params.id || false;
		const selected_warehouse = this.props.match.params.wid || false;

		this.setState({
			mounted: true,
			id,
			selected_warehouse,
			whMgtId: JSON.parse(localStorage.getItem("tms_wh_mgt_id")),
		});
		try {
			if(id) {
				if(!selected_warehouse) {
					this.setState({ loading: false });
					this.props.setNotification("No Warehouse ID was provided!", {
						variant: "error"
					});
					return false;
				}
				const response = await axios.get(
					"/index.php?p=api&r=json&c=product&m=edit&d=" + id + "/" + selected_warehouse
				);
				if (
					typeof response.data !== "string" &&
					!_.isEmpty(response.data.message)
				) {
					this.props.handleMessages(response);
				}
				if (
					typeof response.data !== "string" &&
					!_.isEmpty(response.data.body) &&
					this.state.mounted
				) {
					let warehouse = { id: selected_warehouse },
						product = response.data.body[0],
						uom = { id: "" };

					if (!_.isEmpty(response.data.body.warehouses)) {
						warehouse = response.data.body.warehouses.filter(
							warehouse => {
								return warehouse.id == product.warehouse_name_id;
							}
						)[0];
					}

					if (!_.isEmpty(response.data.body.units)) {
						uom = response.data.body.units.filter(
							unit => {
								return unit.name == product.unit;
							}
						)[0];
					}

					this.setState({
						show: true,
						loading: false,
						user: response.data.user,
						body: response.data.body,
						warehouse: warehouse.id || "",
						warehouses: response.data.body.warehouses,
						code: product.code || "",
						part_no: product.part_no || "",
						product: product.name || "",
						pieces: product.pieces || "",
						units: response.data.body.units || [],
						uom: uom.id || "",
						weight: product.weight || "",
						width: product.width || "",
						height: product.height || "",
						length: product.length || "",
						net_weight: product.net_weight || "",
						class: product.class || "",
						nmfc: product.nmfc || "",
						sub_nmfc: product.sub_nmfc || "",
						declared_value: product.declared_value,
						default: product.default == 1 ? true : false,
						parcel_default: product.parcel_default == 1 ? true : false,
						alert_message: product.alert_message || "",
						haz_class: product.haz_class || "",
						un_num: product.un_num,
						packages: [
							{
									hazmat: {
									un: product.un_num || "",
									class: product.haz_class || "",
									subHazardClass: product.sub_haz_class || "",
									properShippingName: product.proper_shipping_name || "",
									packingGroup: product.group || "",
									packingInstructionCode: product.packing_inst || "",
									technicalName: product.technical_name || "",
									packagingType: product.pkg_type || "",
									innerContainerType: product.inner_ctr_type || "",
									regulatoryQuantity: product.regulatory_qty || "",
									uom: product.haz_uom || "",
									quantity: product.quantity || "",
									transportationMode: product.trans_mode || "",
									accessibility: product.accessibility || "",
									requiredLabel: product.required_label || "",
								}
							}
						],
						allowedUnitsOfMeasure: formatHazUoM(product.haz_uom),
						emergency: product.emergency,
						group: product.group,
						packing_inst: product.packing_inst,
						hs_code: product.hs_code,
						log: product.log.actions || [],
						is_parcel: product.is_parcel == 1 ? true : false,
						hazardous: product.un_num ? true : false,
					});
					this.checkForHazardousData(this.state.packages[0].hazmat)
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an unexpected response from the server while retrieving the product!",
						{ variant: "error" }
					);
				}
			} else {
				let url = "/index.php?p=api&r=json&c=product&m=newProduct&d=";
				if(selected_warehouse) {
					url += selected_warehouse;
				}
				const response = await axios.get(url);
				if (typeof response.data !== "string") {
					this.props.handleMessages(response);
					if (
						response.data.body &&
						response.data.user &&
						this.state.mounted
					) {
						let warehouse = { id: "" };
						if (response.data.body.warehouses) {
							const match = response.data.body.warehouses.filter(
								wh => {
									return (
										wh.id ==
										response.data.body.selected_wh
									);
								}
							);
							if(match.length) {
								warehouse = match[0];
							}
						}
						this.setState({
							show: true,
							loading: false,
							user: response.data.user,
							body: response.data.body,
							units: response.data.body.units || [],
							warehouse: warehouse.id || "",
							warehouses: response.data.body.warehouses,
						});
					}
					// Set default unit of measure to 'Piece'
					if(response.data.body.units) {
						let pieceId = null;
						response.data.body.units.forEach(unit => {
							if(unit.name == 'Piece') {
								pieceId = unit.id;
							}
						});
						if(pieceId != null) {
							this.setState({ uom: pieceId });
						}
					}
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an unexpected response from the server while retrieving product data!",
						{
							variant: "error"
						}
					);
				}
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading page data!", {
				variant: "error"
			});
		}

		localStorage.removeItem("tms_wh_mgt_id");
	}
	componentWillUnmount() {
		if(this.props.isAdminUser && this.state.whMgtId) {
			localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
		}
		this.setState({ mounted: false, whMgtId: null });
	}
	handleHazInfo = () => {

		this.setState({
			hazardous: !this.state.hazardous
		});
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	}
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getWarehouses(warehouses) {
		const { classes } = this.props;
		return warehouses.map((warehouse, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={warehouse.id}
				>
					{warehouse.name}
				</MenuItem>
			);
		});
	}

	checkForHazardousData = (prod) => {
		for(var key in prod) {
			if(prod[key] != "") {
				this.setState({ hazardous: true });
			}
		}
	}


	getProduct = (product, warehouseCode) => {
		return product.map((prod, key) => {
			return (
				<HazmatInfo
					key={key}
					pos={key}
					prod={prod}
					state={this.state}
					setHazUNInfo={this.setHazUNInfo}
					warehouseCode={warehouseCode}
				/>
			)
		})
	}


	async validateHaz() {
		const qty = this.state.pieces;
		const packages = [];
		let dgInfo = {};
		const haz = this.state.packages[0].hazmat
		const wh = this.getSelectedWarehouse();
		const warehouseCode = wh.code;

		if(haz.transportationMode == "") {
			this.props.setNotification("A Transportation Mode is required for validation", { variant: "warning" });
			return false;
		} else if(haz.un == "" )  {
			this.props.setNotification("A UN# is required for validation", { variant: "warning" });
			return false;
		} else if(haz.class == "" )  {
			this.props.setNotification("A Hazardous Class is required for validation", { variant: "warning" });
			return false;
		} else if(haz.subHazardClass == "" )  {
			this.props.setNotification("A Sub Hazard Class is required for validation", { variant: "warning" });
			return false;
		} else if(haz.packagingType == "" )  {
			this.props.setNotification("A Packaging Type is required for validation", { variant: "warning" });
			return false;
		} else if(haz.properShippingName == "" )  {
			this.props.setNotification("A Proper Shipping Name is required for validation", { variant: "warning" });
			return false;
		} else if(haz.quantity == "" )  {
			this.props.setNotification("A Quantity is required for validation", { variant: "warning" });
			return false;
		} else if(haz.accessibility == "" )  {
			this.props.setNotification("Accessibility required for validation", { variant: "warning" });
			return false;
		} else if(haz.requiredLabel == "" )  {
			this.props.setNotification("A Required Label required for validation", { variant: "warning" });
			return false;
		}


		dgInfo = {
			selectedKey: this.state.selectedKey,
			regulationSet: this.state.regulation_set,
			unNum: haz.un,
			classNum: haz.class,
			subriskClassNum: haz.subHazardClass,
			properShippingName: haz.properShippingName,
			technicalName: haz.technicalName,
			transportMode: haz.transportationMode,
			packagingType: haz.packagingType,
			innerContainerType: haz.innerContainerType,
			regulatoryQuantity: haz.regulatoryQuantity,
			packingInstructionCode: haz.packingInstructionCode,
			packagingGroup: haz.packingGroup,
			accessibility: haz.accessibility,
			requiredLabel: haz.requiredLabel,
			emergencyPhone: '',
			intlEmergencyPhone: '',
			offeror: '',
			signatoryContactName: '',
			signatoryTitle: '',
			signatoryPlace: '',
			packagingTypeQty: {
				uom: haz.uom,
				qty: haz.quantity
			}
		};

		packages.push({
			dgInfo,
			qty,
		});

		let data = {
			warehouseCode,
			packages,
		};

		try {
			const url = "/index.php?p=api&r=json&c=parcelShipService&m=validateParcelCatalogHaz";
			const response = await axios.post(url, data);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			} else {
				this.props.setNotification("There was an error validating this product", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an exception validating this product", { variant: "error" });
		}

	}

	setHazUNInfo = (responseObj, key, selection = null) => {

		let transMode = this.state.packages[0].hazmat.transportationMode;
		if(transMode == "") {
			this.props.setNotification("Transportation mode required to lookup UN info.")
			return false;
		}

		if(transMode == "Ground" && responseObj.Forbidden) {
			this.props.setNotification("Selected Service Option is not available for Ground transport mode.", { variant: "error" });
			return false;
		} else if(transMode == "Passenger" && responseObj.PassengerAndCargoAircraftPackingInstructions == "Forbidden")  {
			this.props.setNotification("Selected Service Option is not available for Cargo and Passenger Aircraft transport mode.", { variant: "error" });
			return false;
		} else if(transMode == "Cargo" && responseObj.CargoAircraftOnlyPackingInstructions == "Forbidden")  {
			this.props.setNotification("Selected Service Option is not available for Cargo Only transport mode.", { variant: "error" });
			return false;
		}
		if(typeof responseObj.LabelsRequired !== 'undefined') {
			responseObj.LabelsRequired = responseObj.LabelsRequired.trim();
		} else {
			this.props.setNotification("Selected Service Option is not available because Required Label Information is not available.", { variant: "error" });
			return false;
		}

		const { packages } = {...this.state};
		if (!_.isEmpty(packages)) {
			const prop = packages[key];

			prop.hazmat.class = responseObj.HazardClass.trim();
			prop.hazmat.subHazardClass = responseObj.HazardClass.trim();
			prop.hazmat.properShippingName = responseObj.ProperShippingName.trim();
			prop.hazmat.packingGroup = responseObj.PackingGroup.trim();
			prop.hazmat.requiredLabel = responseObj.LabelsRequired.trim();

			let allowedUnits = [];

			let hazNum = prop.hazmat.class;
			if(isNaN(prop.hazmat.class) && prop.hazmat.class.length > 2) {
				hazNum = hazNum.substring(0, hazNum.length - 1);
			}
			if(hazNum >= 3) {
				hazNum = Math.trunc(hazNum);
			}

			if(hazNum == "1.4" ||
				hazNum == "2.1" ||
				hazNum == "2.2" ||
				hazNum == "3" ||
				hazNum == "4" ||
				hazNum == "5" ||
				hazNum == "8" ) {
					prop.hazmat.accessibility = "ACCESSIBLE";
			} else {
				prop.hazmat.accessibility = "INACCESSIBLE";
			}

			switch(responseObj.LtdQtyUnitOfMeasureId) {
				case "None":
				default:
					prop.hazmat.regulatoryQuantity = "";
					break;
				case "L":
					prop.hazmat.regulatoryQuantity = "LQ";
					allowedUnits.push(responseObj.LtdQtyUnitOfMeasureId);
					break;
				//not actually sure of this value yet for fully regulated quantities
				case "F":
					prop.hazmat.regulatoryQuantity = "FR";
					allowedUnits.push(responseObj.LtdQtyUnitOfMeasureId);
					break;
			}

			let instructionCode = "";

			if(typeof responseObj.ExceptedMaxInnerLiquidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxInnerLiquidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxInnerSolidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxInnerSolidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxOuterLiquidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxOuterLiquidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxOuterSolidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxOuterSolidUnitOfMeasureId);
			}

			if(transMode == "Cargo") {
				if(typeof responseObj.CargoAircraftOnlyUnitOfMeasureId !== 'undefined') {
					allowedUnits.push(responseObj.CargoAircraftOnlyUnitOfMeasureId);
				}

				if(typeof responseObj.CargoAircraftOnlyPackingInstructions !== 'undefined') {
					instructionCode = responseObj.CargoAircraftOnlyPackingInstructions.trim();
				}

			} else if(transMode == "Passenger") {
				if(typeof responseObj.PassengerAndCargoAircraftUnitOfMeasureId !== 'undefined') {
					allowedUnits.push(responseObj.PassengerAndCargoAircraftUnitOfMeasureId);
				}
				if(typeof responseObj.PassengerAndCargoAircraftPackingInstructions !== 'undefined') {
					instructionCode = responseObj.PassengerAndCargoAircraftPackingInstructions.trim();
				}
			} else {
				//ground shipments can take any of the units of measure.
				allowedUnits = [
					"cylinder",
					"g",
					"gallon",
					"kg",
					"kgG",
					"L",
					"mL",
					"oz",
					"pint",
					"lbs",
					"qt"
				];
			}

			if(instructionCode == "None") {
				instructionCode = "";
			}

			if(allowedUnits.length == 0) {
				this.props.setNotification("This package may not be shipped with this transportation mode.", { variant: "error" });
				return false;
			}
			prop.hazmat.packingInstructionCode = instructionCode;

			const access = this.state.packages[0].hazmat.accessibility;
			let serviceMode = this.state.service;
			if(access == "ACCESSIBLE") {
				if(this.state.carrier == "FEDEX" && serviceMode != "PRIORITY_OVERNIGHT" && serviceMode != "FEDEX_GROUND" && serviceMode != "FIRST_OVERNIGHT") {
					//prevents us from setting it multiple times as we iterate through the list.
					serviceMode = "PRIORITY_OVERNIGHT";
				}
			}
			packages[key] = prop;
			this.setState({
				selectedKey: selection,
				packages: packages,
				allowedUnitsOfMeasure: allowedUnits,
				service: serviceMode

			});
		}

	}

	getClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.state;
		let data = ["1.4","1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N",
			"2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.2", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	getSubClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.state;
		let data = [];
		if (regulation_set === "cfr") {
			data = ["1.4C", "1.4D", "1.4E", "1.4S", "1.4N", "2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		}
		if (regulation_set === "iata") {
			let data = ["1.4","1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N",
				"2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.2", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		}
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}


	getUnits(units) {
		const { classes } = this.props;
		return units.map((unit, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={unit.id}
				>
					{unit.name}
				</MenuItem>
			);
		});
	}
	async saveProduct() {
		this.setState({
			show: true,
			loading: true
		});

		const {
			id,
			selected_warehouse,
			code,
			part_no,
			product,
			pieces,
			uom,
			nmfc,
			sub_nmfc,
			declared_value,
			weight,
			width,
			length,
			height,
			warehouse,
			alert_message,
			haz_class,
			un_num,
			group,
			emergency,
			packing_inst,
			hs_code,
		} = this.state;

		if(product.length < 1) {
			this.setState({ loading: false });
			this.props.setNotification("Please provide a product name!", { variant: "error" });
			return;
		}

		if(uom.length < 1) {
			this.setState({ loading: false });
			this.props.setNotification("Please select a unit of measure!", { variant: "error" });
			return;
		}

		if(code.length > 30) {
			this.setState({ loading: false });
			this.props.setNotification("Product codes must be 30 characters or less.", { variant: "error" });
			return;
		}

		if(pieces < 0) {
			this.setState({ loading: false });
			this.props.setNotification("Quantity cannot be negative.", { variant: "error" });
			return;
		}


		const prod = this.state.packages[0].hazmat;
		const isParcel = this.state.parcel_view;
		let data = {
			code,
			part_no,
			product,
			pieces,
			uom,
			nmfc,
			sub_nmfc,
			declared_value,
			weight,
			length,
			width,
			height,
			warehouse,
			alert_message,
			haz_class: this.state.parcel_view ? prod.class : haz_class,
			sub_haz_class: prod.subHazardClass,
			accessibility: prod.accessibility,
			proper_shipping_name: prod.properShippingName,
			technical_name: prod.technicalName,
			trans_mode:prod.transportationMode,
			required_label: prod.requiredLabel,
			pkg_type: prod.packagingType,
			inner_ctr_type: prod.innerContainerType,
			regulatory_qty: prod.regulatoryQuantity,
			haz_uom: prod.uom,
			quantity: prod.quantity,
			un_num:  isParcel ? prod.un : un_num,
			group:  isParcel ? prod.packingGroup : group,
			emergency,
			packing_inst: isParcel ? prod.packingInstructionCode : packing_inst,
			hs_code: hs_code,
			pclass: this.state.class,
			is_parcel: 0,
		};
		if (!_.isEmpty(this.state.net_weight)) {
			data.net_weight = this.state.net_weight;
		}

		if(this.state.default === true) {
			data.default = 1;
		}

		if(this.state.parcel_default === true) {
			data.parcel_default = 1;
		}

		if(this.state.parcel_view) {
			data.is_parcel = 1;
		}

		if (!_.isEmpty(this.state.net_weight)) {
			data.net_weight = this.state.net_weight;
		}

		const url = (this.state.id)
			? "/index.php?p=api&r=json&c=product&m=edit&d=" + id + "/" + selected_warehouse
			: "/index.php?p=api&r=json&c=product&m=newProduct&d=" + selected_warehouse;

		try {
			const response = await axios.post(url, qs.stringify(data));

			if (response.data) {

				this.props.handleMessages(response);

				let errorMessage = false;

				if(!_.isEmpty(response.data.message)) {
					response.data.message.forEach(error => {
						if(error.type == "error") {
							errorMessage = true;
						}
					})
				}

				if (response.data.body) {
					this.setState({
						loading: false,
						done: errorMessage ? false: true
					});
				}

			} else {

				this.setState({ loading: false });
				this.props.setNotification("Unexpected response while saving the product!", {
					variant: "error"
				});

			}

		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an exception while saving the product!", {
				variant: "error"
			});
		}
	}

	getLog(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridItem xs={12} key={key} style={{ marginBottom: "10px" }}>
					<Paper className={classes.paper} elevation={1}>
						<b>{prop.username}</b><br />
						<i>{prop.timestamp}</i><br />
						<span><pre> - {prop.action}</pre></span>
					</Paper>
				</GridItem>
			);
		});
	}

	parcelOptions = (enablePartNo) => {
		const {classes} = this.props
			return (
				<>
					<GridContainer>
						<GridItem xs>
							<InputLabel htmlFor="warehouse" className={classes.label}>
								Warehouse/Facility
							</InputLabel>
							<br />
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<Select
									MenuProps={{
										className: classes.selectMenu,
									}}
									classes={{
										select: classes.select,
									}}
									value={this.state.warehouse}
									inputProps={{
										name: "warehouse",
										id: "warehouse",
									}}
									onChange={this.handleChange}
									readOnly={!this.props.hasWrite}
								>
									{this.state.body && !!this.state.body.warehouses.length ? (
										this.getWarehouses(this.state.body.warehouses)
									) : (
										<MenuItem
											disabled
											classes={{
												root: classes.selectMenuItem,
											}}
										>
											No Warehouses
										</MenuItem>
									)}
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="code" className={classes.label}>
								Product Code
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "code",
									name: "code",
									value: this.state.code || "",
									inputProps: { maxLength: 30 },
									onChange: this.handleInput("code"),
									readOnly: !this.props.hasWrite,
								}}
								helpText="This is only for searching and must be unique. MAX: 30 Characters."
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="product" className={classes.label}>
								Product Name
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "product",
									name: "product",
									value: this.state.product,
									onChange: this.handleInput("product"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>

						<GridItem xs>
							<InputLabel htmlFor="length" className={classes.label}>
								Length
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "length",
									name: "length",
									inputProps: {
										min: 0,
										step: 0.01
									},
									value: this.state.length || "",
									onChange: this.handleInput("length"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="width" className={classes.label}>
								Width
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "width",
									name: "width",
									inputProps: {
										min: 0,
										step: 0.01
									},
									value: this.state.width || "",
									onChange: this.handleInput("width"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="height" className={classes.label}>
								Height
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "height",
									name: "height",
									inputProps: {
										min: 0,
										step: 0.01
									},
									value: this.state.height || "",
									onChange: this.handleInput("height"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel htmlFor="weight" className={classes.label}>
								Weight
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "weight",
									name: "weight",
									value: this.state.weight,
									inputProps: {
										min: 0,
										step: 0.01
									},
									onChange: this.handleInput("weight"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						{enablePartNo && (
							<GridItem xs>
								<InputLabel htmlFor="code" className={classes.label}>
									Part #
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										id: "part_no",
										name: "part_no",
										value: this.state.part_no || "",
										inputProps: { maxLength: 50 },
										onChange: this.handleInput("part_no"),
										readOnly: !this.props.hasWrite,
									}}
									helpText="MAX: 50 Characters."
									white
								/>
							</GridItem>
						)}
						<GridItem xs>
							<InputLabel htmlFor="pieces" className={classes.label}>
								Quantity
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "pieces",
									name: "pieces",
									inputProps: { min: 1 },
									value: this.state.pieces,
									onChange: this.handleInput("pieces"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>

						<GridItem xs>
							<InputLabel
								htmlFor="declared_value"
								className={classes.label}
							>
								Value Per Piece
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "declared_value",
									name: "declared_value",
									value: this.state.declared_value || "",
									onChange: this.handleInput("declared_value"),
									readOnly: !this.props.hasWrite,
									startAdornment: (
										<InputAdornment position="start">
											<AttachMoney />
										</InputAdornment>
									),
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										disabled={!this.props.hasWrite}
										tabIndex={-1}
										checkedIcon={
											<Check className={classes.checkedIcon} />
										}
										icon={
											<Check className={classes.uncheckedIcon} />
										}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot,
										}}
										checked={this.state.parcel_default}
										onChange={this.handleChecked("parcel_default")}
									/>
								}
								classes={{
									label: classes.label,
								}}
								label="Default"
							/>
						</GridItem>
					</GridContainer>
					<GridContainer style={{ marginTop: "10px" }}>
						 <GridItem xs={12} sm={12} md={12}>
							<Paper className={classes.lightWarningPaper} elevation={1}>
								<InputLabel className={classes.label}>
									Alert Message
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "alert_message",
										value: this.state.alert_message,
										onChange: this.handleInput("alert_message"),
									}}
									white
								/>
							</Paper>
						</GridItem>
					</GridContainer>
				</>
			);
	}

	ltlOptions = (enablePartNo) => {
		const {classes} = this.props
			return (
				<>
					<GridContainer>
						<GridItem xs>
							<InputLabel htmlFor="warehouse" className={classes.label}>
								Warehouse/Facility
							</InputLabel>
							<br />
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<Select
									MenuProps={{
										className: classes.selectMenu,
									}}
									classes={{
										select: classes.select,
									}}
									value={this.state.warehouse}
									inputProps={{
										name: "warehouse",
										id: "warehouse",
									}}
									onChange={this.handleChange}
									readOnly={!this.props.hasWrite}
								>
									{this.state.body && !!this.state.body.warehouses.length ? (
										this.getWarehouses(this.state.body.warehouses)
									) : (
										<MenuItem
											disabled
											classes={{
												root: classes.selectMenuItem,
											}}
										>
											No Warehouses
										</MenuItem>
									)}
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="code" className={classes.label}>
								Product Code
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "code",
									name: "code",
									value: this.state.code || "",
									inputProps: { maxLength: 30 },
									onChange: this.handleInput("code"),
									readOnly: !this.props.hasWrite,
								}}
								helpText="This is only for searching and must be unique. MAX: 30 Characters."
								white
							/>
						</GridItem>
						{enablePartNo && (
							<GridItem xs>
								<InputLabel htmlFor="code" className={classes.label}>
									Part #
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										id: "part_no",
										name: "part_no",
										value: this.state.part_no || "",
										inputProps: { maxLength: 50 },
										onChange: this.handleInput("part_no"),
										readOnly: !this.props.hasWrite,
									}}
									helpText="MAX: 50 Characters."
									white
								/>
							</GridItem>
						)}
						<GridItem xs>
							<InputLabel htmlFor="product" className={classes.label}>
								Product Name
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "product",
									name: "product",
									value: this.state.product,
									onChange: this.handleInput("product"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="pieces" className={classes.label}>
								Quantity
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "pieces",
									name: "pieces",
									inputProps: { min: 1 },
									value: this.state.pieces,
									onChange: this.handleInput("pieces"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="uom" className={classes.label}>
								UoM
							</InputLabel>
							<br />
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<Select
									MenuProps={{
										className: classes.selectMenu,
									}}
									classes={{
										select: classes.select,
									}}
									value={this.state.uom}
									inputProps={{
										name: "uom",
										id: "uom",
									}}
									onChange={this.handleChange}
									readOnly={!this.props.hasWrite}
								>
									{!_.isEmpty(this.state.units) ? (
										this.getUnits(this.state.units)
									) : (
										<MenuItem
											disabled
											classes={{ root: classes.selectMenuItem }}
										>
											No Units
										</MenuItem>
									)}
								</Select>
							</FormControl>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel htmlFor="weight" className={classes.label}>
								Weight
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "weight",
									name: "weight",
									value: this.state.weight,
									inputProps: {
										min: 0,
										step: 0.01
									},
									onChange: this.handleInput("weight"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="net_weight" className={classes.label}>
								Net Weight
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "number",
									id: "net_weight",
									name: "net_weight",
									value: this.state.net_weight,
									inputProps: {
										min: 0,
										step: 0.01
									},
									onChange: this.handleInput("net_weight"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Class</InputLabel>
							<br />
							<FormControl
								fullWidth
								className={classes.selectFormControl}
							>
								<FreightClassSelect
									MenuProps={{ className: classes.selectMenu }}
									classes={{ select: classes.select }}
									value={this.state.class}
									inputProps={{ name: "class" }}
									onChange={this.handleChange}
									readOnly={!this.props.hasWrite}
								/>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="nmfc" className={classes.label}>
								NMFC
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "nmfc",
									name: "nmfc",
									value: this.state.nmfc || "",
									onChange: this.handleInput("nmfc"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="sub_nmfc" className={classes.label}>
								Sub-NMFC
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "sub_nmfc",
									name: "sub_nmfc",
									value: this.state.sub_nmfc || "",
									onChange: this.handleInput("sub_nmfc"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel
								htmlFor="declared_value"
								className={classes.label}
							>
								Value Per Piece
							</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "declared_value",
									name: "declared_value",
									value: this.state.declared_value || "",
									onChange: this.handleInput("declared_value"),
									readOnly: !this.props.hasWrite,
									startAdornment: (
										<InputAdornment position="start">
											<AttachMoney />
										</InputAdornment>
									),
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<WithTooltip
								title="HS Code"
								content="HS Code will only be applied to International Shipment products."
							>
								<InputLabel
									htmlFor="hs_code"
									className={classes.label}
								>
									HS Code
								</InputLabel>
							</WithTooltip>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "text",
									id: "hs_code",
									name: "hs_code",
									value: this.state.hs_code || "",
									onChange: this.handleInput("hs_code"),
									readOnly: !this.props.hasWrite,
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										disabled={!this.props.hasWrite}
										tabIndex={-1}
										checkedIcon={
											<Check className={classes.checkedIcon} />
										}
										icon={
											<Check className={classes.uncheckedIcon} />
										}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot,
										}}
										checked={this.state.default}
										onChange={this.handleChecked("default")}
									/>
								}
								classes={{
									label: classes.label,
								}}
								label="Default"
							/>
						</GridItem>
					</GridContainer>
					<GridContainer style={{ marginTop: "10px" }}>
						<GridItem xs={12}>
							<Paper className={classes.lightWarningPaper} elevation={1}>
								<InputLabel className={classes.label}>
									Alert Message
								</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: "text",
										name: "alert_message",
										value: this.state.alert_message,
										onChange: this.handleInput("alert_message"),
									}}
									white
								/>
							</Paper>
						</GridItem>
					</GridContainer>
				</>
			);
	}

	setWarehouseCode(code) {
		this.setState({
			warehouse_code: code,
		})
	}

	getSelectedWarehouse() {
		let wh = false;
		if(this.state.warehouse) {
			const match = this.state.warehouses.filter(obj => {
				return obj.id == this.state.warehouse;
			});
			if(match.length) {
				wh = match[0];
			}
		}
		return wh;
	}
	render() {
		const parcelView = this.state.parcel_view;
		const wh = this.getSelectedWarehouse();
		if (this.state.done) {
			let redirectTo = basePath;
			if(this.props.isAdminUser && this.state.whMgtId) {
				localStorage.setItem("tms_wh_mgt_id", JSON.stringify(this.state.whMgtId));
				this.setState({whMgtId: null});
				redirectTo += "/admin/company/" + wh.company_name_id;
			}else {
				let path = parcelView ? "/admin/parcel/product-catalog-parcel" : "/admin/ltl/product-catalog"
				redirectTo += path;	
			}
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		const enablePartNo = (wh && wh.enable_product_part_no == 1) ? true : false;
		const warehouseCode = wh.code;

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							{!parcelView ? this.ltlOptions(enablePartNo) : this.parcelOptions(enablePartNo)}
							<GridContainer style={{ marginTop: "10px" }}>
								<GridItem xs={12}>
									<Paper className={classes.hazmatPaper} elevation={1}>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12} >
												<GridContainer  direction='row' jsutify="space-between">
														<GridItem xs={8}>
															<h5>Hazmat Information <Button justIcon round size="sm" onClick={this.handleHazInfo} color="danger"><Warning /></Button></h5>
														</GridItem>
														{this.state.hazardous && parcelView && (
															<GridItem xs={4} className={classes.right}>
																<Button
																	color="linkedin"
																	size="sm"
																	onClick={this.validateHaz}
																>
																	Validate
																</Button>
															</GridItem>
														)}
												</GridContainer>
											</GridItem>
										</GridContainer>
										{parcelView ? (
											<GridContainer>
												<GridItem xs={12} sm={12} md={12}>
													{this.state.hazardous ? this.getProduct(this.state.packages, warehouseCode) : null}
												</GridItem>
											</GridContainer>

										) : (
											<GridContainer>
												<GridItem xs>
													<InputLabel htmlFor="haz_class" className={classes.label}>
														Class #
													</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															id: "haz_class",
															name: "haz_class",
															value: this.state.haz_class,
															onChange: this.handleInput(
																"haz_class"
															),
															readOnly: !this.props.hasWrite,
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="un_num" className={classes.label}>
														UN #
													</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															id: "un_num",
															name: "un_num",
															value: this.state.un_num,
															onChange: this.handleInput("un_num"),
															readOnly: !this.props.hasWrite,
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="group" className={classes.label}>
														Packing Group
													</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															id: "group",
															name: "group",
															value: this.state.group,
															onChange: this.handleInput("group"),
															readOnly: !this.props.hasWrite,
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="emergency" className={classes.label}>
														Emergency #
													</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															id: "emergency",
															name: "emergency",
															value: this.state.emergency,
															onChange: this.handleInput(
																"emergency"
															),
															readOnly: !this.props.hasWrite,
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="packing_inst" className={classes.label}>
														Packing Instructions
													</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															id: "packing_inst",
															name: "packing_inst",
															value: this.state.packing_inst || "",
															onChange: this.handleInput(
																"packing_inst"
															),
															readOnly: !this.props.hasWrite,
														}}
														white
													/>
												</GridItem>
											</GridContainer>
										)}
									</Paper>
									{this.props.hasWrite && (
									<GridContainer>
										<GridItem xs>
											<Button
												color="linkedin"
												onClick={this.saveProduct}
											>
												Save Product
											</Button>
										</GridItem>
									</GridContainer>)}
								</GridItem>
							</GridContainer>
							{!_.isEmpty(this.state.log) && (
								<GridContainer>
									<GridItem xs={12}>
										<h5>Log Information</h5>
										<GridContainer>
											{this.getLog(this.state.log)}
										</GridContainer>
									</GridItem>
								</GridContainer>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	const isAdminUser = userIsAdmin(state) && !adminInUserSession(state);
	return {
		hasWrite: hasPermission("PRODUCT_CATALOG_WRITE")(state) || isAdminUser,
		isAdminUser: isAdminUser
	};
}

export default connect(mapStateToProps)(withStyles(newProductStyle)(withSnackbar(EditProduct)));