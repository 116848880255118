import React from "react";
import qs from "qs";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newPermissionGroupStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewUnit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			name: "",
			plural_name: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveUnit = this.saveUnit.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		if(!_.isEmpty(this.props.match.params.id)) {
			const id = this.props.match.params.id;
			try {
				const response = await axios.get(
					"/index.php?p=api&r=json&c=admin&m=editUnit&d=" + id
				);
				const data = response.data;
				if (typeof data !== "string" && !_.isEmpty(data.body)) {
					this.props.handleMessages(response);
					this.props.pageTitle('Edit Unit - ' + data.body.name);
					this.setState({
						show: true,
						loading: false,
						id: id,
						name: data.body.name,
						plural_name: data.body.plural_name,
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error loading the data!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	async saveUnit() {
		this.setState({
			loading: true
		});

		const { id, name, plural_name } = this.state;

		let data = { name, plural_name };
		let url = (id === null) ? "/index.php?p=api&r=json&c=admin&m=newUnit" : "/index.php?p=api&r=json&c=admin&m=editUnit&d=" + id;
		try {
			const response = await axios.post(
				url,
				qs.stringify(data)
			);
			if (!_.isEmpty(response.data)) {
				this.props.handleMessages(response);
				if (response.data.body !== false) {
					this.props.handleMessages(response);
					this.setState({
						loading: false,
						id: (id === null) ? response.data.body : id,
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error creating the unit!",
						{
							variant: "error"
						}
					);
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error creating the unit!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error creating the unit!",
				{
					variant: "error"
				}
			);
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<CustomInput
										labelText="Unit Name"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "name",
											name: "name",
											value: this.state.name || "",
											onChange: this.handleInput("name")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<CustomInput
										labelText="Unit Name (Plural)"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "plural_name",
											name: "plural_name",
											value: this.state.plural_name || "",
											onChange: this.handleInput("plural_name")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<Button
										color="linkedin"
										onClick={this.saveUnit}
									>
										Save
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newPermissionGroupStyle)(
	withSnackbar(NewUnit)
);
