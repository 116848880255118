import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { basePath, apiUrl } from "~/variables/server.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import FastRewind from "@material-ui/icons/FastRewind";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { handleMessages } from "../../../redux/actions/notifications.jsx";

class ImportCompanyCarriers extends React.Component {
    state = {
        companyId: null,
        companyName: "",
        file: null,
        loading: false,
    };

    async componentDidMount() {
        this.setState({companyId: this.props.match.params.id});
        await this.getCompany();
    }

    getCompany = async () => {
        const response = await axios.get(`/index.php?p=api&r=json&c=admin&m=getCompany&companyId=${this.props.match.params.id}`);
        this.props.handleMessages(response);
        if (response.data.body && response.data.body.name) {
            this.setState({
                companyName: response.data.body.name,
            });
        }
    }

    handleUpload = (files) => {
        this.setState({file: files[0]});
    }

    massImport = async () => {
        let url = `/index.php?p=api&r=json&c=companyCarriers&m=import&companyId=${this.state.companyId}`;
        const data = new FormData();
        data.append("file", this.state.file);

        this.setState({loading: true});
        const response = await axios.post(url, data, {headers: {"content-type": "multipart/form-data"}});
        this.setState({loading: false});
        this.props.handleMessages(response);
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.companyId !== null && (
                    <GridItem xs={12} sm={12} md={12} className={classes.left} style={{ marginTop: "10px" }}>
                        <NavLink to={basePath + `/admin/company/${this.state.companyId}`}>
                            <Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
                                <FastRewind /> Back to Company
                            </Button>
                        </NavLink>
                    </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={4}>
                                    <InputLabel className={classes.label}>Company</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "warehouse",
                                            value: this.state.companyName || "",
                                            disabled: true
                                        }}
                                        white
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={4}>
                                    <a href={apiUrl + "/index.php?p=api&c=companyCarriers&m=exportTemplate"}>
                                        <Button color="linkedin" style={{ marginRight: "5px" }}>
                                            Download Template File
                                        </Button>
                                    </a>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h5>Company Carriers File</h5>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <Dropzone accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" handleUpload={this.handleUpload} />
                                        </GridItem>
                                        {this.state.file ? (
                                            <GridItem xs={12} sm={12} md={6}>
                                                <aside className={classes.thumbsContainer}>
                                                    <Preview src="#" index={0} extension="csv" handleClick={() => {}} />
                                                </aside>
                                            </GridItem>
                                        ) : null}
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <span>Use this tool to import company carrier accounts and assign them to warehouses.  All accounts in the uploaded CSV will be added to the company.  This tool cannot be used for updating or deleting existing company carrier accounts.</span>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button color="info" style={{ marginRight: "5px" }} onClick={this.massImport} disabled={!this.state.file || this.state.loading}>
                                        {this.state.loading ? <CircularProgress color="inherit" size={20}/> : <span>Import</span>}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(ImportCompanyCarriers));