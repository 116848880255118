import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from "@material-ui/core/Select";

import customInputStyle from "~/assets/jss/empire-tms/components/customInputStyle.jsx";

class CustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.key = null;
        this.input = null;
        this.handleClick = this.handleClick.bind(this);
        this.isFunction = this.isFunction.bind(this);
    }

    componentDidMount() {
        this.key = this.props.inputProps.name + "_" + (Math.random() * 1000000000000000000).toString();
    }

    handleClick() {
        if (this.input !== null) {
            this.input.focus();
        }
    }

    isFunction(func) {
        return func && {}.toString.call(func) === "[object Function]";
    }

    render() {
        const { classes, formControlProps, labelText, id, labelProps, inputProps, error, white, required, inputRootCustomClasses, success, helpText, autoComplete, options, outlined, freeSolo } = this.props;
        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error
        });
        const underlineClasses = classNames({
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true
        });
        const marginTop = classNames({
            [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
            [classes.whiteInput]: white,
            [classes.requiredInput]: required
        });
        const inputClasses = classNames({
            [classes.input]: true,
            [classes.whiteInput]: white,
            [classes.requiredInput]: required
        });
        var formControlClasses;
        var formControlLabel = labelText !== undefined ? classes.formControl : classes.formControlLabel;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(formControlProps.className, formControlLabel);
        } else {
            formControlClasses = formControlLabel;
        }
        var helpTextClasses = classNames({
            [classes.labelRootError]: error,
            [classes.labelRootSuccess]: success && !error
        });

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                        onClick={() => {
                            if (this.isFunction(this.props.handleClick)) {
                                if (this.isFunction(this.props.setRef) && this.input !== null) {
                                    this.props.setRef(this.key, this.input);
                                }
                                this.props.handleClick(this.key);
                            } else {
                                this.handleClick();
                            }
                        }}
                    >
                        {labelText}
                    </InputLabel>
                ) : null}

                {inputProps.type === "select" ? (
                    <Select
                        id={id}
                        {...inputProps}
                    >
                        {options ? options : null}
                    </Select>
                ) :

                (autoComplete
                    ? (<Autocomplete
                        options={options}
                        getOptionLabel={option => option.value || option}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    id={id + "_tf"}
                                    fullWidth
                                />
                            );
                        }}
                        id={id}
                        value={inputProps.value || ''}
                        onChange={(typeof inputProps.onChange === 'function') ? inputProps.onChange : null}
                        onInputChange={(typeof inputProps.onInputChange === 'function') ? inputProps.onInputChange : null}
                        freeSolo={freeSolo !== undefined ? freeSolo : true}
                    />)
                    : (outlined
                        ? (<OutlinedInput
                            {...inputProps}
                            classes={{
                                root: marginTop,
                            }}
                            id={id}
                            fullWidth
                            />)
                        : (<Input
                        {...inputProps}
                        classes={{
                            root: marginTop,
                        }}
                        id={id}
                        fullWidth
                        />)
                    )
                )}
                {helpText !== undefined ? (
                    <FormHelperText id={id + "-text"} className={helpTextClasses}>
                        {helpText}
                    </FormHelperText>
                ) : null}
            </FormControl>
        );
    }
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    setRef: PropTypes.func,
    focusRef: PropTypes.func,
    handleClick: PropTypes.func,
    autoComplete: PropTypes.bool,
    options: PropTypes.array,
    outlined: PropTypes.bool,
};

export default withStyles(customInputStyle)(CustomInput);
