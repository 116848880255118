const initialState = {
	open: false,
	loading: {},
	origin: {},
	destination: {},
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_TERMINAL_INFO_MODAL_OPEN": {
			return {...newState, open: action.val};
		}
		case "SET_TERMINAL_INFO_MODAL_LOADING": {
			return {...newState, loading: action.val};
		}
		case "SET_TERMINAL_INFO_ORIGIN": {
			if (typeof action.val !== "object") action.val = {};
			return {...newState, origin: {...action.val}};
		}
		case "SET_TERMINAL_INFO_DESTINATION": {
			if (typeof action.val !== "object") action.val = {};
			return {...newState, destination: {...action.val}};
		}
		default:
			return state;
	}
}