import React, { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from '@material-ui/core/Badge';
import FilterListIcon from "@material-ui/icons/FilterList";
import Check from "@material-ui/icons/Check";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Info from "@material-ui/icons/InfoOutlined";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import TextField from '@material-ui/core/TextField';
import InputTag from "~/components/TMS/InputTag.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Button from "~/components/CustomButtons/Button.jsx";


/**
 * Card containing support filters for viewing collections of held bills 
 * Props:
 * 	locations - array of objects to populate a locations select filter element. Objects expected to have an "id", "broker", and "description" property
 */
class InvoiceListFilters extends Component {

	constructor(props) {
		super(props);
		let selected_locations = [];
		let locations = props.locations || [];
		let location_ids = props.filters.location_ids || [];
		for(var key in locations) {
			if(location_ids.find(id => id == locations[key].id) !== undefined) {
				selected_locations.push({ ...locations[key] });
			}
		}
		this.state = {
			"filters" : {
				"pro_number": props.filters.pro_number || "",
				"invoice_number": props.filters.invoice_number || "",
				"account_number": props.filters.account_number || "",
				"bol_number": props.filters.bol_number || "",
				"po_number": props.filters.po_number || "",
				"carrier": props.filters.carrier || "",
				"status": props.filters.status || false,
				"location_ids": location_ids
			},
			"selected_locations": selected_locations, // loc objects chosen in the InputTag
			"collapsed": props.collapsed !== undefined ? props.collapsed : true,
		};
	} 

	render() {
		const locations = this.props.locations || [];
		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
					<Card>
						<CardHeader style={{"cursor":"pointer"}} onClick={this.toggleCollapse}>
							<h4 style={{"width":"100%"}}>
								<FilterListIcon />
								<Badge 
									badgeContent={this.getFilterCount()}
									color={"primary"}
									showZero={false}
								>
									<span>Add Filters</span>
								</Badge>
								{(this.state.collapsed) ? <Add style={{"float":"right"}} /> : <Remove style={{"float":"right"}} />}
							</h4>
						</CardHeader>
						<Collapse in={!this.state.collapsed}>
							<CardBody style={{"paddingTop":"0px"}}>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
										<InputTag
											inputLabel={
												<>
													Filter by Billing Location
													<WithTooltip
														content="Filter held bills by one or more of your TFM billing locations"
													>
														<Info style={{ height: 16, marginLeft: 4 }} />
													</WithTooltip>
												</>
											}
											loading={false}
											value={this.state.selected_locations}
											options={locations}
											onChange={this.handleFilterLocationChange}
											getOptionLabel={(option) => option.description}
											getOptionSelected={(option, value) => option.id == value.id}
											renderOption={(option, { selected }) => (
												<>
													<Checkbox
														checkedIcon={<Check />}
														style={{ marginRight: 8 }}
														checked={selected}
													/>
													{option.description}
												</>
											)}
										/>
										<TextField
											fullWidth
											autoFocus
											label="CARRIER"
											value={this.state.filters.carrier || ""}
											onKeyPress={this.handleKeyPress}
											onChange={(e) => this.handleFilterInputChange("carrier", e.target.value, e)}
										/>
										<TextField
											fullWidth
											autoFocus
											label="INV#"
											value={this.state.filters.invoice_number || ""}
											onKeyPress={this.handleKeyPress}
											onChange={(e) => this.handleFilterInputChange("invoice_number", e.target.value, e)}
										/>
										
									</GridItem>
									<GridItem xs={12} sm={12} md={6} lg={6} lg={6}>
										<TextField
											fullWidth
											autoFocus
											label="PRO#"
											value={this.state.filters.pro_number || ""}
											onKeyPress={this.handleKeyPress}
											onChange={(e) => this.handleFilterInputChange("pro_number", e.target.value, e)}
										/>
										<TextField
											fullWidth
											autoFocus
											label="BL#"
											value={this.state.filters.bol_number || ""}
											onKeyPress={this.handleKeyPress}
											onChange={(e) => this.handleFilterInputChange("bol_number", e.target.value, e)}
										/>
										<TextField
											fullWidth
											autoFocus
											label="PO#"
											value={this.state.filters.po_number || ""}
											onKeyPress={this.handleKeyPress}
											onChange={(e) => this.handleFilterInputChange("po_number", e.target.value, e)}
										/>
										<TextField
											fullWidth
											autoFocus
											label="ACCT#"
											value={this.state.filters.account_number || ""}
											onKeyPress={this.handleKeyPress}
											onChange={(e) => this.handleFilterInputChange("account_number", e.target.value, e)}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
										{this.getFilterCount() ? (
											<Button
												round
												color="default"
												size="sm"
												onClick={this.clearInvoiceListFilters}
												disabled={this.props.loading}
											>
												{(this.props.loading) ? <CircularProgress color="inherit" size={20}/> : <span>Clear</span>}
											</Button>
										) : null}
										<Button
											round
											color="linkedin"
											size="sm"
											onClick={this.applyInvoiceListFilters}
											disabled={this.props.loading}
										>
											{(this.props.loading) ? <CircularProgress color="inherit" size={20}/> : <span>Apply</span>}
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Collapse>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}

	handleFilterInputChange = (key, val, e) => {
		let filters = { ...this.state.filters };
		filters[key] = val;
		this.setState({ filters });
	};

	handleFilterLocationChange = (e, vals) => {
		let ids = [];
		let filters = { ...this.state.filters };
		for(var key in vals) {
			ids.push(vals[key].id);
		}
		filters.location_ids = ids;
		this.setState({ filters: filters, selected_locations: vals });
	}

	handleKeyPress = e => {
		if (e.key === "Enter") this.applyInvoiceListFilters();
	};

	applyInvoiceListFilters = e => {
		if(typeof this.props.onChange === 'function') this.props.onChange(this.state.filters);
	}

	clearInvoiceListFilters = e => {
		let filters = { ...this.state.filters } 
		for(var key in filters) {
			if(typeof filters[key] === 'object') {
				filters[key] = [];
			} else {
				filters[key] = '';
			}
		}
		this.setState({ filters, selected_locations: [] }, () => {
			this.applyInvoiceListFilters();
		});
	}

	getFilterCount = () => {
		let count = 0;
		let filters = {...this.state.filters}
		for(var key in filters) {
			if(filters[key].length) count++;
		}
		return count;
	}

	toggleCollapse = e => {
		let collapsed = this.state.collapsed
		this.setState({ collapsed: !collapsed });
		if(typeof this.props.onCollapse === 'function') {
			this.props.onCollapse(!collapsed);
		}
	}
}

export default InvoiceListFilters;
