import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import LinkCard from '../../../components/TMS/LinkCard.jsx';
import Update from "@material-ui/icons/Update";
import ShowChart from "@material-ui/icons/ShowChart"; 
// import Event from "@material-ui/icons/Event"; 
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import { getUserSetting } from '../../../redux/selectors/Admin.jsx';
import { basePath } from '../../../variables/server.jsx';


class CustomerPortalLandingContainer extends Component {

	render() {
		const links = [
			{
				icon: ShowChart,
				path: `${basePath}/admin/customer-portal/canned-reports`,
				title: "Run Standard Reports",
				show: this.props.canRunStdReports,
			},
			// {
			// 	icon: Event,
			// 	path: `${basePath}/admin/customer-portal`,
			// 	title: "View Weekly Reports",
			// 	show: true,
			// },
			{
				icon: Update,
				path: `${basePath}/admin/customer-portal/held-bills`,
				title: "Update / Release Held Bills",
				show: this.props.hasRead,
			},
			{
				icon: DescriptionIcon,
				path: `${basePath}/admin/customer-portal/view-invoices`,
				title: "View TFM Invoices",
				show: this.props.canViewTfmInvoices,
			},
			{
				icon: SearchIcon,
				path: `${basePath}/admin/customer-portal/search-invoices`,
				title: "Search PROs",
				show: this.props.canViewTfmInvoices,
			},
		]
		return (
			<div style={{marginTop: "10px"}}>
				<Typography align="center" color="primary" style={{color: "#000", marginBottom: "10px"}}>
					Choose from the tools below to run reports and update bills.
				</Typography>
				<GridContainer>
					{links.map((link, key) => {
						if (link.show) {
							return (
								<Fragment key={key}>
									<GridItem xs={1} sm={2} md={3} lg={4} />
									<GridItem xs={10} sm={8} md={6} lg={4} style={{marginBottom: "10px"}}>
										<LinkCard 
											icon={link.icon}
											path={link.path}
											title={link.title}
										/>	
									</GridItem>
									<GridItem xs={1} sm={2} md={3} lg={4} />
								</Fragment>
							);	
						}
					})}
				</GridContainer>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasRead: +getUserSetting("hold_read", 0)(state),
		canViewTfmInvoices: +getUserSetting("view_tfm_invoices", 0)(state),
		canRunStdReports: !getUserSetting("hide_run_std_rpts", 0)(state),
	};
}
export default connect(mapStateToProps)(CustomerPortalLandingContainer);