import React, { Component } from "react";
import { Link } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import moment from "moment";
import _ from "lodash";

import ReactTable from '~/components/ReactTable/ReactTable.jsx';

// core components
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";

// style
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

class ArchivedParcelShipmentsList extends Component {

	columns = () => {
		let cols = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: this.actionsCell,
				width: 90,
				colDisabled: false,
			},
			{
				Header: "Shipment ID",
				accessor: "shipment_id",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Order/SO#",
				accessor: "so",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "PO#",
				accessor: "po",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "REF#",
				accessor: "ref",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Date",
				accessor: "ship_date",
				Cell: (rowInfo) => {
					return !_.isEmpty(rowInfo.row.original.ship_date) ? moment(rowInfo.row.original.ship_date).format("YYYY-MM-DD") : "";
				},
				width: 110,
				colDisabled: false,
			},
			{
				Header: "Code",
				accessor: "code",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Carrier",
				accessor: "carrier",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Shipper Name",
				accessor: "shipper_company_name",
				width: 220,
				colDisabled: false,
			},
			{
				Header: "Shipper City",
				accessor: "shipper_city",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Shipper State",
				accessor: "shipper_state",
				width: 120,
				colDisabled: false,
			},
			{
				Header: "Consignee Name",
				accessor: "consignee_company_name",
				width: 220,
				colDisabled: false,
			},
			{
				Header: "Consignee City",
				accessor: "consignee_city",
				width: 175,
				colDisabled: false,
			},
			{
				Header: "Consignee State",
				accessor: "consignee_state",
				width: 140,
				colDisabled: false,
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: (rowInfo) => {
					return rowInfo.row.original.cancelled == 1 ? "Cancelled" : (rowInfo.row.original.status == 'hold' ? "Hold" : "Executed");
				},
				width: 175,
				colDisabled: false,
			},
		];

		cols.forEach((e, i) => {
			if(e.colDisabled) {
				cols.splice(i, 1)
			}
		});

		if(_.isEmpty(this.props.defaultColumns)) {
			this.props.setDefaultColumns(cols)
		}

		if(_.isEmpty(this.props.customColumns)) {
			return cols;
		} else {
			let customCol = [];
			this.props.customColumns.forEach((v) => {
				const disabled = v.colDisabled === "false" ? false : (!v.colDisabled ? false : true);
				cols.forEach((e) => {
					if(!customCol.includes(e) && (v.accessor == e.accessor) && !disabled) {
						customCol.push(e)
						return;
					}
				})
			})
			return customCol;
		}
	}

	setPageNumber = (page) => {
		this.props.setPageNumber(page);
	}

	actionsCell = (rowInfo) => {
		return (
			<ParcelActions
				row={rowInfo.row}
				user={this.props.user}
				loadShipment={(key) => this.props.loadShipment(key)}
				viewLabels={(id, labelType) => this.props.viewLabels(id, labelType)}
				showDocsModal={(id) => this.props.showDocsModal(id)}
				handleVoid={(id) => this.props.handleVoid(id)}
				trackShipment={(id) => this.props.trackShipment(id)}
				handleReopen={(id) => this.props.handleReopen(id)}
			/>
		)
	}

	render() {
		
		return(
			<ReactTable
				loading={this.props.loading}
				columns={this.columns()}
				data={this.props.results}
				rightPaginationLabelFormat={({ from, to, count }) => ''}
				pageSize={this.props.pageSize} //limit
				pageNumber={this.props.pageNumber} //current
				setPageNumber={this.setPageNumber} //handlePageClick
				visibleRows={16}
				overscan={30}
				enableResizing
				simplePagination
				nextPage={this.props.next} //next
				prevPage={this.props.prev} //prev
				defaultColumn={{
					align: 'left',
				}}
			/>
		)
	}

}

class ParcelActions extends Component {
	state = {
		anchorEl: null,
		open: false
	}

	
	handleClick(e){
		this.setState({
			currentTarget: e.target,
			open: true
		})
	}

	handleActionSelect = (action) => {
		const {index, original} = this.props.row;
		
		this.setState({ 
			anchorEl: null,
			open: false
		});

		switch (action) {
			case "details":
				this.props.loadShipment(index);
				break;
			case "labels":
				this.props.viewLabels(original.shipment_id, original.label_type);
				break;
			case "docs":
				this.props.showDocsModal(original.shipment_id);
				break;
			case "void":
				this.props.handleVoid(original.shipment_id);
				break;
			case "open":
				this.props.handleReopen(original.shipment_id);
				break;
			case "tracking":
				if(original.carrier != 'United States Postal Service') {
					this.props.trackShipment(original.shipment_id);
				} else {
					window.open('https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=' + original.tracking_id, '_blank');
				}
				break;
			default:
				break;
		}
	}

	getActions = (data) => {
		const actions = [];
		
		actions.push(
			<MenuItem 
				onClick={() => this.handleActionSelect("details")}
			>
				<ListItemText>Details</ListItemText>
			</MenuItem>
		);
		if(data.cancelled != 1 && !_.isEmpty(data.carrier)){
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("labels")}
				>
					<ListItemText>View Labels</ListItemText>
				</MenuItem>
			);
		}
		if(data.cancelled != 1 && !_.isEmpty(data.carrier) && (data.has_dg_document == 1 || (this.props.user && this.props.user.user_company_settings && this.props.user.user_company_settings.enable_costco_label))){
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("docs")}
				>
					<ListItemText>View Docs</ListItemText>
				</MenuItem>
			);
		}
		if(data.cancelled != 1 && data.status !== "hold") {
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("void")}
				>
					<ListItemText>Void</ListItemText>
				</MenuItem>
			);
		}
		if(data.cancelled != 1 && !_.isEmpty(data.carrier)){
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("tracking")}
				>
					<ListItemText>Track</ListItemText>
				</MenuItem>
			);
		}
		if(data.cancelled != 1 && data.status === "hold") {
			actions.push(
				<MenuItem 
					component={Link}
					to={`${basePath}/admin/parcel/get-quote/${data.shipment_id}/`}
					onClick={() => this.handleActionSelect()}
				>
					<ListItemText>Rate & Schedule Shipment</ListItemText>
				</MenuItem>
			)
		}
		if(data.cancelled == 1) {
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("open")}
				>
					<ListItemText>Re-Open Shipment</ListItemText>
				</MenuItem>
			);
		}
		if(data.status !== "hold") {
			actions.push(
				<MenuItem 
					component={Link}
					to={`${basePath}/admin/parcel/parcel-scheduler/${data.shipment_id}/`}
					onClick={() => this.handleActionSelect()}
				>
					<ListItemText>Duplicate</ListItemText>
				</MenuItem>
			)
		}
		return actions;
	}


	render() {
		const { original } = this.props.row;
		const loading = original.reopening || original.voiding || original.tracking || original.loading;
		return(
			<ButtonGroup disableElevation variant="contained">
				<IconButton
					id="long-button"
					aria-label="more"
					aria-haspopup="true"
					onClick={(e) => this.handleClick(e)}
				>
					{loading ? <CircularProgress size={16} style={{ color: "grey" }} /> : <MoreVertIcon fontSize="inherit" />}
				</IconButton>
				<Menu
					id="menu-appbar"
					anchorEl={this.state.currentTarget}
					keepMounted
					open={this.state.open}
					onClose={(e) => this.handleActionSelect(e)}
				>
					{(this.state.open) && (
						this.getActions(original)
					)}
				</Menu>
			</ButtonGroup>
		)
	}
}


export default withStyles(style)(ArchivedParcelShipmentsList);