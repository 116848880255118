import React from "react";
import {Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";
import InputChip from "~/views/CarrierPortal/components/InputChip.jsx";



class RemittanceFilterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      invoiceReport: "checkIssueDate",
      selectedValue: "",
      startDate: new Date(),
      endDate: new Date(),
      checkNum:[],
      proNum:[],
      tags: []
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelectHere = this.handleSelectHere.bind(this);
    this.changeTagsList = this.changeTagsList.bind(this);
  }

  async componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  changeTagsList(value) {
    this.setState({ tags: value});
  };

  

  handleSearch= (event) => {

    const start_date = this.state.startDate;
    const end_date = this.state.endDate;

    if (this.state.invoiceReport === "checkIssueDate") {

        if (start_date == null || end_date == null) {

          this.props.setNotification("Date must be provided", { variant: "warning" });
          event.preventDefault();
          return false;

        } else if (!(moment(start_date, 'MM-DD-YYYY').isValid()) || !(moment(end_date, 'MM-DD-YYYY').isValid())) {

          this.props.setNotification("Invalid date, The date format is MM/DD/YYYY!", { variant: "warning" });
          event.preventDefault();
          return false;

        } else if (start_date.getTime() > end_date.getTime()) {

          this.props.setNotification("The start date must be less than the end date!", { variant: "warning" });
          event.preventDefault();
          return false;

        }
    } else if (this.state.invoiceReport === "checkIssuedNumber" && this.state.tags.length === 0) {

      this.props.setNotification("check number must be provided", { variant: "warning" });
      event.preventDefault();
      return false;

    } else if (this.state.invoiceReport === "proNumber" && this.state.tags.length === 0) {

      this.props.setNotification("pro number must be provided", { variant: "warning" });
      event.preventDefault();
      return false;

    }
}

  async clearFilters() {
    this.setState({
        startDate: new Date(),
        endDate: new Date(),
    })
   if(this.state.invoiceReport === "checkIssuedNumber" || this.state.invoiceReport === "proNumber"){
      this.setState({tags:[],invoiceReport:"checkIssueDate"});
    }
  }

  async clearAllFilters() {
   if(this.state.invoiceReport === "checkIssuedNumber" || this.state.invoiceReport === "proNumber"){
      this.setState({tags:[]});
    }
  }

  handleSelectHere(name, value) {
    this.setState({
        ["invoiceReport"]: value.target.value,
        startDate: new Date(),
        endDate: new Date(),
        checkNum: [],
        proNum:[],
        tags:[]});
  }

  handleDateChange = (name, moment) => {
    this.setState({[name]: moment})
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer style={{ paddingLeft: "30px" }}>
            <GridContainer style={{ paddingLeft: "10px" }}>
              <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "30px" }}>
                <InputLabel fontWeight="bold" className={classes.label}>
                  Check search by
                </InputLabel>
                    <FormControl
                      style={{ paddingTop: "10px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={this.state.invoiceReport}
                        onChange={(m) =>
                          this.handleSelectHere("invoiceHoldStatus", m)
                        }
                        inputProps={{
                          id: " invoiceReport ",
                          name: " InvoiceReport ",
                        }}
                      >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="checkIssueDate"
                          >
                            Check Issued Date
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="checkIssuedNumber"
                          >
                            Check Number
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="proNumber"
                          >
                            Pro Number
                          </MenuItem>
                      </Select>
                  </FormControl>
                </GridItem>
            </GridContainer>

            <GridContainer>
                  {this.state.invoiceReport === "checkIssueDate" ? (
                    <GridContainer style={{ paddingLeft: "20px" }}>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={4}
                        style={{ paddingTop: "50px", paddingLeft: "20px" }}
                      >
                        <InputLabel fontWeight="bold" className={classes.label}>
                          Select Check Issued Date
                        </InputLabel>
                      </GridItem>

                      <GridContainer
                        style={{ paddingTop: "10px", paddingLeft: "20px" }}
                      >
                        <GridItem>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="From *"
                              placeholder="MM/DD/YYYY"
                              value={this.state.startDate}
                              onChange={(m) =>
                                this.handleDateChange("startDate", m)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              InputProps={{
                                  style: {
                                      fontSize: 14,

                                  }
                              }}
                               style={{width:"300px"}}
                            />
                          </MuiPickersUtilsProvider>
                        </GridItem>

                        <GridItem>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="To *"
                              placeholder="MM/DD/YYYY"
                              value={this.state.endDate}
                              onChange={(m) =>
                                this.handleDateChange("endDate", m)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              InputProps={{
                                  style: {
                                      fontSize: 14,

                                  }
                              }}
                               style={{width:"300px"}}
                            />
                          </MuiPickersUtilsProvider>
                        </GridItem>
                      </GridContainer>
                    </GridContainer>
                  ) : this.state.invoiceReport === "checkIssuedNumber" ?(
                    <GridContainer style={{ paddingLeft: "20px" }}>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={4}
                        style={{ paddingTop: "50px", paddingLeft: "20px" }}>
                        <InputLabel fontWeight="bold" className={classes.label}>
                         Enter Check Numbers *
                        </InputLabel>
                      </GridItem>

                      <GridContainer style={{ paddingTop: "20px", paddingLeft: "20px" }}>
                        <GridItem
                          xs={4}
                          sm={12}
                          md={4}
                          style={{ paddingTop: "0px" }}>
                          <div >
                              <InputChip tags={this.state.tags} placehold={"Enter Check Number then press enter"} changeTagsList={this.changeTagsList} />
                              <div>
                                <a href="#" onClick={this.clearAllFilters} style={{float:"right",marginTop:"10px"}}>Clear All</a>
                              </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridContainer>
                  ) : this.state.invoiceReport === "proNumber" ?(
                    <GridContainer style={{ paddingLeft: "20px" }}>
                      <GridItem
                        xs={4}
                        sm={12}
                        md={4}
                        style={{ paddingTop: "50px", paddingLeft: "20px"}}>
                        <InputLabel fontWeight="bold" className={classes.label}>
                         Enter Pro Numbers *
                        </InputLabel>
                      </GridItem>

                      <GridContainer style={{ paddingTop: "20px", paddingLeft: "20px" }}>
                        <GridItem
                          xs={4}
                          sm={12}
                          md={4}
                          style={{ paddingTop: "0px" }}>
                          <div>
                              <InputChip tags={this.state.tags} placehold={"Enter PRO Number then press enter"}  changeTagsList={this.changeTagsList} />
                              <div>
                                <a href="#" onClick={this.clearAllFilters} style={{float:"right",marginTop:"10px"}}>Clear All</a>
                              </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridContainer>
                  )
                  :(
                    <p></p>
                  )}
            </GridContainer>

            <GridContainer style={{ paddingTop: "50px" }}>
              <GridItem>
                {(this.state.invoiceReport === "checkIssueDate") ?
                  <Button size="md" color="white" onClick={this.clearFilters}>  <ClearAll /> Clear </Button>

              : (this.state.invoiceReport === "checkIssuedNumber") ?
                  <Button size="md" color="white" onClick={this.clearFilters}>   <ClearAll /> Clear </Button>

              : (this.state.invoiceReport === "proNumber") ?
                  <Button size="md" color="white" onClick={this.clearFilters}>   <ClearAll /> Clear </Button>

              : <Button size="md" color="white">  <ClearAll /> Clear </Button>}
              </GridItem>


              <GridItem>
                {(this.state.invoiceReport === "checkIssueDate") ?
                <Link to={{
                  pathname: basePath + "/admin/carrier-remittance-date/search/",
                  state: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                  },
                }} >
                  <Button color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                </Link> :
                (this.state.invoiceReport === "checkIssuedNumber") ?
                  <Link to={{
                    pathname: basePath + "/admin/carrier-remittance-check/search/",
                    state: {
                      checkNum: this.state.tags,
                    },
                  }} >
                    <Button color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                  </Link> :
                  (this.state.invoiceReport === "proNumber") ?
                    <Link to={{
                      pathname: basePath + "/admin/carrier-remittance-pro/search/",
                      state: {
                        proNum:  this.state.tags,
                      },
                    }} >
                      <Button color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                    </Link> :
                  <Button size="md" color="linkedin">  <Search /> Search </Button>}
              </GridItem>
            </GridContainer>


      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(RemittanceFilterPage)));