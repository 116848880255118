import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import Transition from "~/components/TMS/ModalTransition.jsx";

import Button from "~/components/CustomButtons/Button.jsx";
import Table from "~/components/Table/Table.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ProductSearchModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Found Multiple Products</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBodyX}>
                    {!!this.props.products.length && <Table nonResponsive tableHead={this.props.categoryColumns} tableData={this.props.getProducts()} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} />}
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => this.props.onClose()} color="white">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(ProductSearchModal);