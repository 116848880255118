import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { hist } from "../../../App.jsx";

import qs from "qs";

import moment from "moment";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { basePath, apiUrl } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import GetApp from "@material-ui/icons/GetApp";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";

import ArchivedBillsOfLadingList from "../components/ArchivedBillsOfLadingList.jsx";
import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import {
	clearState,
	getBolNotesStatus,
	setBolsWithNewNotes,
	getBolsNoteCount,
	setBolsWithNotes,
	getTransitStatusOptions,
	getLatePickupOptions,
	getLateDeliveryOptions,
	getReferences,
	getPostingPanelColumns,
} from "../actions/ArchivedBillsOfLading.jsx";
import { loadNotesModal } from "../../BillOfLadingNotesModal/actions/BillOfLadingNotes.jsx";
import BillOfLadingNotesModal from "../../BillOfLadingNotesModal/containers/BillOfLadingNotesModal.jsx";
import DatePromptModal from "../../../components/DatePromptModal/DatePromptModal.jsx";
import DoubleDatePromptModal from "../../../components/DoubleDatePromptModal/DoubleDatePromptModal.jsx";
import RateResults from "../../RateResults/containers/RateResults.jsx";
import { userIsAdmin, adminInUserSession, hasPermission } from "../../../redux/selectors/Admin.jsx";
import { loadLevelsIfEmpty, updateBolLevel } from "../../../components/ImportanceLevel/actions/ImportanceLevel.jsx";
import { isObject } from "../../../helpers.jsx";

class Transition extends Component {
	render() {
		return <Slide direction="down" {...this.props} />;
	}
}

axios.defaults.timeout = 60000;

class ArchivedBillsOfLading extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			alert: null,
			company: {},
			bols: [],
			next: "",
			prev: "",
			current: "",
			last: "",
			filters: [
				{
					field: "bol",
					value: ""
				}
			],
			filterURI: "",
			limit: "50",
			displayPreferences: {
				bol_num: true,
				pro: true,
				po: true,
				so: true,
				ref: true,
				bol_date: true,
				code: true,
				carrier: true,
				shipper: true,
				consignee: true,
				status: true,
				actions: true,
				transit_status: true,
				transit_sub_status: true,
				pickup_date: true,
				expected_delivery_date: true,
				delivery_date: true,
				delivery_time: true,
			},
			bolHistoryModal: false,
			deliveryDatePromptModalOpen: false,
			pickupDatePromptModalOpen: false,
			tempTransitStatus: "",
			history: [],
			rateDataModal: false,
			carrierDocsModal: false,
			carrierDocs: {
				carrier: "",
				scac: "",
				documents: []
			},
			bolId: "",
			date: "",
			rateData: [],
			transit_status: "",
			transit_sub_status: "",
			pickup_date: "",
			expected_delivery_date: "",
			delivery_date: "",
			delivery_time: "",
			late_pickup_reason: "",
			late_delivery_reason: "",

			updatingTransitStatusIndex: null, //index of bol that the user is currently attempting to update the transit status of
			viewingVolumeRate: false,

		};

		this.handleSearch = this.handleSearch.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.saveTransitInfo = this.saveTransitInfo.bind(this);
		this.handleRates = this.handleRates.bind(this);
		this.handleHistory = this.handleHistory.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.handleCarrierDocs = this.handleCarrierDocs.bind(this);
		this.getCarrierDocs = this.getCarrierDocs.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.cancelBol = this.cancelBol.bind(this);
		this.handleUncancel = this.handleUncancel.bind(this);
		this.uncancelBol = this.uncancelBol.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.saveDisplayPreferences = this.saveDisplayPreferences.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleCancelDispatch = this.handleCancelDispatch.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.updateBolLevel = this.updateBolLevel.bind(this);
	}

	handleMessages(messages) {
		if (!messages) {
			return;
		}
		for (const prop of messages) {
			const { type, message } = prop;
			let variant = "default";
			switch (type) {
				case "info":
					variant = "info";
					break;
				case "success":
					variant = "success";
					break;
				case "warning":
					variant = "warning";
					break;
				case "danger":
				case "error":
					variant = "error";
					break;
			}
			this.props.enqueueSnackbar(<span dangerouslySetInnerHTML={{ __html: message }} />, {
				variant,
				autoHideDuration: 5000
			});
		}
	}

	/**
	 * Updates the BOL filters to use the values found in the GET params or the defaults from local storage.
	 * When there are search filters in the GET params, both the search keys and the values will be used (e.g. a user searches by PO# '123', loads the bol and then clicks the back button, the page will load with BOLs with PO# '123' again.)
	 * When a user navigates to /bol/archived the defaults from window.localStorage will be used such that the last used search_by field is used but no search value will be pre-entered.
	 */
	async updateFiltersFromParams() {
		let search = false;
		let windowDefaults = false;
		if(this.props.location.search) {
			search = this.props.location.search;
			if(search.indexOf("?") === 0) {
				search = search.substring(1);
			}
		} else {
			search = window.localStorage.getItem('BOLSearchBy');
			if(search && search.indexOf("?") === 0) {
				search = search.substring(1);
			}
			windowDefaults = true;
		}
		if(search) {
			let filters = [];
			let getCustRefs = false;
			let filterPairs = search.split('&');
			for (let i = 0; i < filterPairs.length; i++) {
				if(!filterPairs[i]) {
					continue;
				}
				let filterParts = filterPairs[i].split('=');
				let key = filterParts[0].replace("filters[", "").replace("]","");
				let value = "";
				// when we use window storage, a user navigated to the BOL page but has not performed any searches. Load the last search keys by default, but do not set the values
				if(!windowDefaults) {
					value = decodeURIComponent(filterParts[1]);
				}
				let custRefType = "";
				// customReferences will appear as filters[customReference{index}][customReferenceType]=value and need special handling as opposed to other filters which are only filters[key]=value
				if(key.includes("customReference")) {
					// get the key found after customReference
					custRefType = key.substring(key.indexOf("[", key.indexOf("customReference")))
					// remove [ and ] from the key
					custRefType = custRefType.replace("[","").replace("]","");
					// decode the encoded URI portions of reference
					custRefType = decodeURIComponent(custRefType);
					key = "customReference";
					filters.push({
						search_by: key,
						customReferenceType: custRefType,
						search_input: value
					});
					// set flag to load custReferences so they are rendered properly
					getCustRefs = true;
				} else {
					filters.push({
						search_by: key,
						search_input: value
					});
				}
			}
			if(!_.isEmpty(filters)) {
				if(getCustRefs) {
					this.getReferences();
				}
				await this.setState({ filters });
			}
		}
	}

	async componentDidMount() {
		this.props.clearState();
		const url = "/index.php?p=api&r=json&c=postingPanel&m=index&d=1/" + this.state.limit;
		try {
			const filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);
			await this.props.getReferences();
			await this.props.getTransitStatusOptions();
			await this.props.getLatePickupOptions();
			await this.props.getLateDeliveryOptions();
			await this.props.getPostingPanelColumns();

			let filterURI = this.buildFilterURI(filters).searchUri;
			const response = await axios.get(url + filterURI);

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body) && (typeof response.data.body.result !== 'undefined')) {
				const data = response.data;
				const displayPreferences = data.body.displayPreferences;
				this.setState({
					body: data.body,
					bols: data.body.result,
					user: data.user,
					next: data.body.next || "",
					prev: data.body.prev || "",
					current: data.body.current || "",
					displayPreferences,
					filterURI
				}, () => {
					this.getNotesData();
					if (this.props.userHasBolLevelPermission) {
						this.props.loadLevelsIfEmpty();
					}
					this.setState({loading: false, show: true})
				});
				if(data.user.user_settings.show_tc_in_posting_panel == 0) {
					this.setState({ hideShipmentCharges: 'none '});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			this.setState({ loading: false });
			console.error(error);
			this.props.setNotification("Error: " + error, { variant: "error" });
		}
		this.setState({ mounted: true });
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	getBolNotesStatus = () => {
		if (Array.isArray(this.state.bols) && this.state.bols.length) {
			let bolNumbers = this.state.bols.map(bol => {
				return typeof bol === "object" && bol.date && bol.id
					? `${bol.date}${bol.id}`
					: null
			}).filter(bol => {
				return bol !== null;
			});
			if (bolNumbers.length) {
				this.props.getBolNotesStatus(bolNumbers);
			}
		}
	}

	getBolsNoteCount= () => {
		if (Array.isArray(this.state.bols) && this.state.bols.length) {
			let bolNumbers = this.state.bols.map(bol => {
				return typeof bol === "object" && bol.date && bol.id
					? `${bol.date}${bol.id}`
					: null
			}).filter(bol => {
				return bol !== null;
			});
			if (bolNumbers.length) {
				this.props.getBolsNoteCount(bolNumbers);
			}
		}
	}
	getNotes = bolNumber => {
		let bolsWithNewNotes = [...this.props.bolsWithNewNotes];
		if (bolsWithNewNotes.indexOf(bolNumber) !== -1) {
			bolsWithNewNotes.splice(bolsWithNewNotes.indexOf(bolNumber), 1);
		}
		this.props.setBolsWithNewNotes(bolsWithNewNotes);
		this.props.loadNotesModal(bolNumber);
	}
	getNotesData = () =>
	{
		this.getBolsNoteCount();
		this.getBolNotesStatus();
	}

	hasNewNotes = bolNumber => {
		return this.props.bolsWithNewNotes.indexOf(bolNumber) !== -1;
	}

	getNumNotes = bolNumber => {
		return this.props.bolsWithNotes[bolNumber];
	}

	handleEdit(index, type, value, edit) {
		const { bols } = this.state;
		bols.forEach(bol => bol[type + "_edit"] = false);
		bols[index][type + "_edit"] = edit;
		this.setState({
			bols,
			[type]: value
		});
	}

	async saveTransitInfo(index, type, settingPickupAndDeliveryDate = false) {
		const { bols } = this.state;

		const { id, date, delivery_date, pickup_date } = bols[index];
		let value = null;
		let updateTransitStatusToDelivered = false;

		if (moment.isMoment(this.state[type])) {
			value = moment(this.state[type]).format("L");
			// if there wasn't a delivery date before and one is now being added, then also update transit status to delivered (handled by db trigger)
			if(type === 'delivery_date' && _.isEmpty(delivery_date)) {
				updateTransitStatusToDelivered = true;
			}
		// The delivery date value is not a moment object if user didn't edited it
		} else if (type === 'transit_status' || type === 'transit_sub_status' || type === 'delivery_date' || type === 'late_pickup_reason' || type === 'late_delivery_reason') {
			value = this.state[type];
		} else if (type === 'delivery_time') {
			if(!_.isEmpty(this.state[type]) && !moment(this.state[type], "HH:mm:ss", true).isValid()) {
				this.props.setNotification("Invalid delivery time", { variant: "error" });
				return;
			} else {
				value = this.state[type];
			}

		}

		let data = { [type]: value };

		// Remove transit sub status if the parent transit status is changed
		if(type == "transit_status") {
			data = {...data, transit_sub_status: null};
			bols[index]["transit_sub_status"] = null
			this.setState({bols, transit_sub_status: null});
		}


		let needPickupDate = ((value !== "" && value != "booked" && value != "pickup missed" && value != "canceled") && (type == "transit_status" || type == "transit_sub_status") &&  _.isEmpty(pickup_date));
		let needDeliveryDate =(((type === "transit_status" && value === "delivered") || (type === "delivery_time" && value !== null)) && _.isEmpty(delivery_date));

        if((needPickupDate || needDeliveryDate) && !settingPickupAndDeliveryDate) {
			if(needPickupDate) {
				this.setState({
					pickupDatePromptModalOpen: true,
					updatingTransitStatusIndex: index,
					tempTransitStatus: value,
				});
			}

			if (needDeliveryDate) {
				this.setState({
					deliveryDatePromptModalOpen: true,
					updatingTransitStatusIndex: index,
				});
			}
			return;
		}

		const url = "/index.php?p=api&c=billoflading&m=saveTransitInfo&d=" + id + "/" + date;

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				bols[index][type] = value;
				bols[index][type + "_edit"] = false;
				if(updateTransitStatusToDelivered) {
					bols[index]['transit_status'] = 'delivered';
					bols[index]['transit_sub_status'] = null;
					this.setState({transit_sub_status: null});
				}
				this.setState({
					bols,
					[type]: ""
				});
				return true;
			} else {
				this.props.setNotification("There was an error saving the transit status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the transit status!", { variant: "error" });
		}
	}

	/**
	 * on submit, save the delivery date for the bol at index that is being edited
	 * then proceed in saving the transit status to delivered
	 */
	handleDeliveryDatePrompt = async (date) => {
		this.closeDeliveryDatePrompt();
		const index = this.state.updatingTransitStatusIndex;
		const type = "delivery_date";

		this.setState({delivery_date: date}, async () => {
			const deliveryDateResult = await this.saveTransitInfo(index, type);
			if (deliveryDateResult === true) {
				this.setState({transit_status: "delivered"}, async () => {
					const transitStatusResult = await this.saveTransitInfo(index, "transit_status");
					if(transitStatusResult === true && (this.state.delivery_time != null && this.state.delivery_time != undefined && this.state.delivery_time.length > 0)) {
						const deliveryTimeResult = await this.saveTransitInfo(index, "delivery_time");
						if(deliveryTimeResult !== true) {
							this.setState({"delivery_time": ""});
						}
					} else {
						this.setState({"transit_status": ""});
					}
				});
			} else {
				this.setState({"delivery_date": ""});
			}
		});
		return true;
	}

	handlePickupDatePrompt = async (date) => {
		this.closePickupDatePrompt();
		const { bols } = this.state;

		const index = this.state.updatingTransitStatusIndex;
		const transStatus = this.state.tempTransitStatus;
		const type = "pickup_date";
		this.setState({pickup_date: date}, async () => {
			const pickupDateResult = await this.saveTransitInfo(index, type);
			if (pickupDateResult === true) {
				const statusOption = this.props.transitStatusOpts.filter(type => transStatus == type.status && type.parent);
				let transitStatusType = "transit_status";
				if(statusOption.length) {
					transitStatusType = "transit_sub_status";
				}
				this.setState({[transitStatusType]: transStatus}, async () => {
					
					const transitStatusResult = await this.saveTransitInfo(index, transitStatusType);

				});
			} else {
				this.setState({"pickup_date": ""});
			}
		});
		return true;
	}

    handleDoubleDatePrompt = async (pickupObj, deliveryObj) => {
		const index = this.state.updatingTransitStatusIndex;
		this.setState({delivery_date: deliveryObj}, async () => {
			const deliveryDateResult = await this.saveTransitInfo(index, "delivery_date", true);
			if (deliveryDateResult === true) {
				this.setState({transit_status: "delivered"}, async () => {
					const transitStatusResult = await this.saveTransitInfo(index, "transit_status", true);
					if(transitStatusResult === true && (this.state.delivery_time != null && this.state.delivery_time != undefined && this.state.delivery_time.length > 0)) {
						const deliveryTimeResult = await this.saveTransitInfo(index, "delivery_time", true);
						if(deliveryTimeResult !== true) {
							this.setState({"delivery_time": ""});
						}
					} else {
						this.setState({"transit_status": ""});
					}
				});
			} else {
				this.setState({"delivery_date": ""});
			}
			this.setState({pickup_date: pickupObj}, async () => {
				const pickupDateResult = await this.saveTransitInfo(index, "pickup_date", true);
				if (pickupDateResult === true) {
					this.setState({transit_status: transStatus}, async () => {
						const transitStatusResult = await this.saveTransitInfo(index, "transit_status", true);

					});
				} else {
					this.setState({"pickup_date": ""});
				}
			});
			this.closeDoubleDatePrompt();
		});
    }

	closeDeliveryDatePrompt = () => {
		this.setState({deliveryDatePromptModalOpen: false, updatingTransitStatusIndex: null});
	}

    closePickupDatePrompt = () => {
        this.setState({
			pickupDatePromptModalOpen: false,
			updatingTransitStatusIndex: null,
			tempTransitStatus: "",
		});
    }

    closeDoubleDatePrompt = async () => {
        this.setState({
            pickupDatePromptModalOpen: false,
            deliveryDatePromptModalOpen: false,
			tempTransitStatus: "",
			updatingTransitStatusIndex: null,
        });
    }

	addFilter() {
		const { filters } = this.state;
		filters.push({
			search_by: "bol",
			search_input: ""
		});
		this.setState({ filters });
	}

	removeFilter(key) {
		const { filters } = this.state;
		if (!_.isEmpty(filters) && key != 0) {
			filters.splice(key, 1);
			this.setState({ filters });
		}
	}

	async clearFilters() {
		this.handleSearch(this.state.filters);
	}

	async updateBolLevel(key, level) {
		const { bols } = this.state;
		const bol = bols[key];
		const bolNumber = bol.date + bol.id;
		let newLevelId = +level === +bol.bol_level_id ? (+bol.bol_level_id - 1) : +level;
		if (newLevelId < 1) newLevelId = null;

		const updateResult = await this.props.updateBolLevel(bolNumber, newLevelId);
		if (updateResult) {
			bols[key].bol_level_id = newLevelId
			this.setState({bols: [...bols]});
		}
	}

	async handlePageClick(page) {
		this.setState({
			show: false,
			loading: true
		});

		let { filterURI, limit } = this.state;

		const url = "/index.php?p=api&r=json&c=postingPanel&m=index&d=" + page + "/" + limit + filterURI;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && (typeof response.data.body.result !== 'undefined')) {
				const data = response.data;
				if (!_.isEmpty(data.body.filterURI)) {
					filterURI = data.body.filterURI;
				}
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					bols: data.body.result,
					next: data.body.next || "",
					prev: data.body.prev || "",
					current: data.body.current || "",
					filterURI
				},()=>{
					this.getNotesData();
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	buildFilterURI = (filters) => {
		let filterUri = "";
		let historyUri = "";
		let index = 0;
		for (const filter of filters) {
			index++;
			if (_.isEmpty(filter.value)) {
				continue;
			}
			let input = filter.value;
			let search_by = filter.field;
			if(index == 1) {
				// if the first parameter is not preceeded by a question mark, the routes will treat all parameters as if they are not encoded.
				// for example a date 08/04/2023 and 08%2F04%2F2023 are treated the same and this causes extremely unexpected behavior
				historyUri = "?";
			} else {
				historyUri += "&";
			}
			if (search_by == "customReference") {
				let referenceType = filter.value2 || ""
				input = referenceType + "/" + input;
				search_by = "customReference" + index;
				// using filters[search_by]=referenceType/value breaks routes when added to the history. Work around this by using array key/value pairs for customReference
				historyUri += "filters[" + search_by + "][" + referenceType + "]=" + encodeURIComponent(input);
			} else {
				historyUri += "filters[" + search_by + "]=" + encodeURIComponent(input);
			}
			filterUri += "&filters[" + search_by + "]=" + encodeURIComponent(input);
		}
		let retValue = {
			searchUri: filterUri,
			historyUri: historyUri,
		};
		return retValue;
	}

	async handleSearch(filters) {

		let url = `/index.php?p=api&r=json&c=postingPanel&m=index&d=1/${this.state.limit}`;
		let filterURI = this.buildFilterURI(filters);
		let historyURI = filterURI.historyUri;
		filterURI = filterURI.searchUri;
		url += filterURI;

		let path = basePath + '/admin/bol/archived';
		if(this.props.userIsAdmin && !this.props.adminInUserSession) {
			path = basePath + '/admin/audit/posting-panel';
		}
		path = path + historyURI;
		// don't keep adding the same url over and over to the history if a user presses search repeatedly.
		if(path != hist.location.pathname + hist.location.search) {
			hist.push(path)
			localStorage.setItem("BOLSearchBy", historyURI);
		}


		this.setState({
			show: false,
			loading: true
		});

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && (typeof response.data.body.result !== 'undefined')) {
				this.setState({
					show: true,
					loading: false,
					body: response.data.body,
					bols: response.data.body.result,
					next: response.data.body.next || "",
					prev: response.data.body.prev || "",
					current: response.data.body.current || "",
					filterURI
				}, () => {
					this.getNotesData();
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error searching the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error searching the data!", {
				variant: "error"
			});
		}
	}

	async handleRates(index, rateId, date, bolId) {
		const { bols } = this.state;

		bols[index].loadingRates = true;

		this.setState({
			rateDataModal: false,
			carriers: [],
			tfmr_internal_id: false,
			distance: 0,
			bolId: "",
			date: "",
			bols,
			viewingVolumeRate: false,
		});

		const url = `/index.php?p=api&r=json&c=billoflading&m=load&d=${bolId}/${date}`;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.user)) {
				bols[index].loadingRates = false;
				const selected_scac = response.data.body.general.carrier_scac;
				const viewingVolumeRate = !!+response.data.body.general.volume;
				let tfmr_internal_id = +response.data.body.tfmr_internal_id || false;
				let allowed_carriers = response.data.body.allowed_carriers || [];

				// If a bol was executed via the API with a selected SCAC that is currently restricted, add SCAC to allowed carriers.
				if (!_.isEmpty(selected_scac) && !allowed_carriers.includes(selected_scac)) {
					allowed_carriers.push(selected_scac)
				}

				this.setState({
					rateDataModal: true,
					carriers: (!viewingVolumeRate) ? this.filterRestrictedCarriers(response.data.body.carriers, allowed_carriers) : response.data.body.carriers,
					tfmr_internal_id,
					distance: response.data.body.distance,
					bolId,
					date,
					bols,
					viewingVolumeRate: viewingVolumeRate,
				});
			} else {
				bols[index].loadingRates = false;
				this.setState({ bols });
				this.props.setNotification("There was an error loading the rates!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			bols[index].loadingRates = false;
			this.setState({ bols });
			this.props.setNotification("There was an error loading the rates!", {
				variant: "error"
			});
		}
	}

	// check if any carriers restrictions were found.
	filterRestrictedCarriers = (carriers, allowed_carriers) => {
		if (!Array.isArray(carriers)) return [];
		return carriers = carriers.filter(carrier => {
			return allowed_carriers.includes(carrier.scac);
		})
	}

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	async handleHistory(index, date, id) {
		const { bols } = this.state;

		bols[index].loadingHistory = true;

		this.setState({
			bolHistoryModal: false,
			history: [],
			bols
		});

		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=billoflading&m=history&d=${id}/${date}`);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const data = response.data;
				const bolHistoryModal = !_.isEmpty(data.body);
				bols[index].loadingHistory = false;
				this.setState({
					bols,
					history: data.body || [],
					bolHistoryModal: bolHistoryModal
				});
				if (!bolHistoryModal) {
					this.props.setNotification("This BoL has no history on file.", {
						variant: "info"
					});
				}
			} else {
				bols[index].loadingHistory = false;
				this.setState({
					bols,
					bolHistoryModal: false
				});
				this.props.setNotification("There was an error loading the history!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			bols[index].loadingHistory = false;
			this.setState({
				bols,
				bolHistoryModal: false
			});
			this.props.setNotification("There was an error loading the history!", {
				variant: "error"
			});
		}
	}

	getHistory(history) {
		return history.map((prop, key) => {
			return [prop.time, prop.user, prop.action];
		});
	}

	async handlePrint(index, date, id) {
		if (typeof date === "undefined" || date == "" || typeof id === "undefined" || id == "") {
			return;
		}

		const { bols } = this.state;

		bols[index].loadingPrint = true;

		this.setState({ bols });

		const url = `/index.php?p=api&c=billoflading&m=pdf&d=${id}/${date}`;

		bols[index].loadingPrint = false;

		try {
			const response = await axios.get(url);
			const data = response.data;
			this.props.handleMessages(response);
			if (typeof data === "string") {
				this.setState({ bols });
				window.location.href = apiUrl + url;
			} else {
				this.setState({ bols });
				this.props.setNotification("There was an error loading the BoL!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ bols });
			this.props.setNotification("There was an error loading the BoL!", {
				variant: "error"
			});
		}
	}
	async handleCarrierDocs(index) {

		const { bols } = this.state;
		const documents = [];
		bols[index].loadingCarrierDocs = true;
		this.setState({
			carrierDocsModal: false,
			carrierDocs: {
				carrier: "",
				scac: "",
				documents: []
			},
			bols
		});

		const date = bols[index].date;
		const id = bols[index].id;
		const scac = bols[index].scac;
		const pro = bols[index].pro;
		const consigneeZip = bols[index].consignee.zip;

		try {
			const response = await axios.get(`/index.php?p=api&c=tracking&m=p44RequestDocImages&date=${date}&id=${id}&scac=${scac}&pro=${pro}&consigneeZip=${consigneeZip}`);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an exception checking for new carrier documents!", {
				variant: "error"
			});
		}

		try {
			const response = await axios.get(`/index.php?p=api&c=tracking&m=getCarrierDocs&d=${id}/${date}`);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const data = response.data;
				if(data.body.hasOwnProperty("documents")) {
					if(data.body.documents.length > 0) {
						data.body.documents.forEach(doc =>
							documents.push(doc)
						);
						bols[index].loadingCarrierDocs = false;
						this.setState({
							bols,
							carrierDocs: {
								carrier: data.body.carrier,
								scac: data.body.scac,
								documents: documents
							},
							carrierDocsModal: true
						});
					}
				} else {
					this.props.setNotification("This BoL has no carrier documents on file.", {
						variant: "error"
					});
				}
			} else {
				bols[index].loadingCarrierDocs = false;
				this.setState({
					bols,
					carrierDocsModal: false
				});
				this.props.setNotification("There was an error loading the carrier documents!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			bols[index].loadingCarrierDocs = false;
			this.setState({
				bols,
				carrierDocsModal: false
			});
			this.props.setNotification("There was an exception loading the carrier documents!", {
				variant: "error"
			});
		}
	}
	getCarrierDocs(documents) {
		return documents.map((prop, key) => {
			let docType = '';
			if (_.includes(prop.file_name, 'BILL_OF_LADING')) {
				docType = 'BILL OF LADING';
			} else if (_.includes(prop.file_name, 'DELIVERY_RECEIPT')) {
				docType = 'DELIVERY RECEIPT';
			} else if (_.includes(prop.file_name, 'WEIGHT_CERTIFICATE')) {
				docType = 'WEIGHT CERTIFICATE';
			} else if (_.includes(prop.file_name, 'INVOICE')) {
				docType = 'INVOICE';
			} else if (_.includes(prop.file_name, 'INSPECTION_CERTIFICATE')) {
				docType = 'INSPECTION CERTIFICATE';
			} else if (_.includes(prop.file_name, 'LETTER_OF_AUTHORIZATION')) {
				docType = 'LETTER OF AUTHORIZATION';
			} else if (_.includes(prop.file_name, 'LUMPER_CERTIFICATE')) {
				docType = 'LUMPER CERTIFICATE';
			} else if (_.includes(prop.file_name, 'PACKING_SLIP')) {
				docType = 'PACKING SLIP';
			}
			return [
				docType,
				moment(prop.timestamp).format("LLL"),
				<Button size="sm" color="white" onClick={() => this.downloadCarrierDoc(prop.file_name)}>
					<GetApp />
				</Button>
			];
		});
	}
	async downloadCarrierDoc(fileName) {
		const scac = this.state.carrierDocs.scac;
		const url = `/index.php?p=api&r=json&c=tracking&m=downloadCarrierDoc&d=${scac}/${fileName}`;
		try {
			const response = await axios.get(url);
			if (typeof response.data === "string") {
				window.location.href = apiUrl + url; // What is this doing?
			} else {
				this.props.setNotification("There was an error downloading the carrier document!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an exception downloading the carrier document!", {
				variant: "error"
			});
		}
	}

	handleUncancel(index, date, bolId) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to uncancel this BoL?"
					onConfirm={() => this.uncancelBol(index, date, bolId)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, uncancel it!"
					cancelBtnText="No!"
					showCancel
				>
					This action will uncancel this BoL.
				</SweetAlert>
			)
		});
	}

	async uncancelBol(index, date, bolId) {
		const url = "/index.php?p=api&c=billoflading&m=uncancel&d=" + bolId + "/" + date;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				const { bols } = this.state;
				bols[index].status = response.data.body["status"];
				bols[index]['transit_status'] = response.data.body["transit_status"];
				this.setState({
					bols,
					alert: null
				});
			} else {
				this.props.setNotification("There was an error uncanceling the BoL!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error uncanceling the BoL!", { variant: "error" });
		}
	}

	handleCancel(index, date, bolId) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to cancel this BoL?"
					onConfirm={() => this.cancelBol(index, date, bolId)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, cancel it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone. Any files attached to this BoL will be lost!
				</SweetAlert>
			)
		});
	}

	async cancelBol(index, date, bolId) {
		const url = "/index.php?p=api&r=json&no_redirect=1&c=billoflading&m=cancel&d=" + bolId + "/" + date;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				const { bols } = this.state;
				bols[index].status = "canceled";
				bols[index]['transit_status'] = "canceled";
				this.setState({
					bols,
					alert: null
				});
				this.handleCancelDispatch(response.data.message, index)
			} else {
				this.props.setNotification("There was an error canceling the BoL!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error canceling the BoL!", { variant: "error" });
		}
	}

	handleChange(event) {
		const name = [event.target.name];
		this.setState({ [name]: event.target.value });
	}

	async handleDatetime(name, momentObj) {
		if(name === 'delivery_time') {
			moment.isMoment(momentObj) ? this.setState({ [name]: momentObj.format("HH:mm:ss") }) : this.setState({ [name]: null });
		} else {
			this.setState({ [name]: momentObj });
		}
	}

	async saveDisplayPreferences(displayPreferences) {
		const url = "/index.php?p=api&c=postingPanel&m=saveDisplayPreferences";
		try {
			const response = await axios.post(url, qs.stringify(displayPreferences));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data === "string" || _.isEmpty(response.data.body) || !response.data.body.result) {
				this.props.setNotification("There was an error saving the display preferences!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the display preferences!", { variant: "error" });
		}
	}

	handleCancelDispatch(messages, index) {
		let showAlert = false
		let msg = [];

		if (!messages) {
            return;
        }
        for (const prop of messages) {
            const { type, message, traceroute} = prop;
			if(type == "error" && traceroute[1] == "p44CancelDispatch") {
				msg.push(message)
				showAlert = true;
			}
		}

		if(showAlert) {
			const { bols } = this.state;
			bols[index]['transit_status'] = "booked";
			this.setState({
				alert: (
					<SweetAlert
						warning
						style={{
							display: "block",
							color: "black"
						}}
						title="Error Canceling Dispatch"
						onConfirm={() => this.setState({ alert: null, bols })}
						confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
						confirmBtnText="Okay"
						showCancel={false}
					>
						{msg.join("\n")}
					</SweetAlert>
				)
			});
		}
	}

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.handleSearch();
		}
	}

	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;

		return (
			<GridContainer>
				{this.state.alert}
				<BillOfLadingNotesModal />
                {(this.state.deliveryDatePromptModalOpen && !this.state.pickupDatePromptModalOpen) && (
                    <DatePromptModal
                    title={<span>Please provide the shipment <b>delivery date</b></span>}
                    open={this.state.deliveryDatePromptModalOpen && !this.state.pickupDatePromptModalOpen}
                    onSubmit={this.handleDeliveryDatePrompt}
                    onClose={this.closeDeliveryDatePrompt}
                />
                )}
                {(this.state.pickupDatePromptModalOpen && !this.state.deliveryDatePromptModalOpen) && (
                <DatePromptModal
                    title={<span>Please provide the shipment <b>pickup date</b></span>}
                    open={this.state.pickupDatePromptModalOpen && !this.state.deliveryDatePromptModalOpen}
                    onSubmit={this.handlePickupDatePrompt}
                    onClose={this.closePickupDatePrompt}
                />
                )}
                {(this.state.pickupDatePromptModalOpen && this.state.deliveryDatePromptModalOpen) && (
                <DoubleDatePromptModal
                    title={<span>Please provide the shipment <b>pickup date</b></span>}
                    open={this.state.pickupDatePromptModalOpen && this.state.deliveryDatePromptModalOpen}
                    onSubmit={this.handleDoubleDatePrompt}
                    onClose={this.closeDoubleDatePrompt}
                />
                )}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<Grid container>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<ArchivedBillsOfLadingList
										bols={this.state.bols}
										pages={this.state.last}
										pageSize={this.state.limit}
										nextPage={this.state.next}
										prevPage={this.state.prev}
										pageNumber={this.state.current}
										loading={this.state.loading}
										user={this.state.user}
										userHasBillOfLadingPermission={this.props.userHasBillOfLadingPermission}
										hasClaimsPermission={this.props.hasClaimsPermission}
										userHasBolLevelPermission={this.props.userHasBolLevelPermission}
										hasCarrierTrackingPermission={this.props.hasCarrierTrackingPermission}
										bolsWithNewNotes={this.props.bolsWithNewNotes}
										bolsWithNotes={this.props.bolsWithNotes}
										notesLoading={this.props.notesLoading}
										bolNotesCountLoading={this.props.bolNotesCountLoading}
										levels={this.props.levels}
										getNotes={this.getNotes}
										setPageNumber={this.handlePageClick}
										saveTransitInfo={this.saveTransitInfo}
										handleChange={this.handleChange}
										handleDatetime={this.handleDatetime}
										handlePrint={this.handlePrint}
										handleCarrierDocs={this.handleCarrierDocs}
										handleHistory={this.handleHistory}
										handleRates={this.handleRates}
										handleCancel={this.handleCancel}
										handleUncancel={this.handleUncancel}
										updateBolLevel={this.updateBolLevel}
										handleSearch={this.handleSearch}
										clearFilters={this.clearFilters}
									/>
								</GridItem>
							</Grid>
						</CardBody>
					</Card>
					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.bolHistoryModal ? true : false}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleModalClose("bolHistoryModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("bolHistoryModal")}>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.modalTitle}>BoL History</h4>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
							{!_.isEmpty(this.state.history) && <Table tableHead={["Date", "User", "Action"]} tableData={this.getHistory(this.state.history)} />}
						</DialogContent>
						<DialogActions className={classes.modalFooter}>
							<Button onClick={() => this.handleModalClose("bolHistoryModal")} color="white">
								Close
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.carrierDocsModal ? true : false}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleModalClose("carrierDocsModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("carrierDocsModal")}>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.modalTitle}>{this.state.carrierDocs.carrier} Documents</h4>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
							{!_.isEmpty(this.state.carrierDocs.documents) &&
							<Table
								tableHead={["Type", "Received", "Download"]}
								tableData={
									this.getCarrierDocs(this.state.carrierDocs.documents)}
							/>}
						</DialogContent>
						<DialogActions className={classes.modalFooter}>
							<Button onClick={() => this.handleModalClose("carrierDocsModal")} color="white">
								Close
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.rateDataModal ? true : false}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleModalClose("rateDataModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("rateDataModal")}>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.modalTitle}>BOL # {this.state.date + this.state.bolId} Rate Data</h4>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
							{!_.isEmpty(this.state.carriers) && (
								<RateResults
									volume={this.state.viewingVolumeRate}
									carriers={this.state.carriers}
									tfmrId={+this.state.tfmr_internal_id}
									distance={this.state.distance}
									showHideMessage={true}
									showDetails={false}
									hideTransitBadges={true}
								/>
							)}
						</DialogContent>
						<DialogActions className={classes.modalFooter}>
							<Button onClick={() => this.handleModalClose("rateDataModal")} color="white">
								Close
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.trackingInfoModal ? true : false}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleModalClose("trackingInfoModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("trackingInfoModal")}>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.modalTitle}>Tracking Information</h4>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
							{!_.isEmpty(this.state.tracking_info) && this.state.tracking_info.no_tracking_data && (
								<GridContainer>
									<GridItem xs={12}>
										<Paper className={classes.infoPaper} elevation={1}>
											<strong>Tracking Not Yet Available!</strong> This shipment has not been entered into the carrier system yet. Tracking will be available when this happens.
										</Paper>
									</GridItem>
								</GridContainer>
							)}
							{!_.isEmpty(this.state.tracking_info) && !this.state.tracking_info.no_tracking_data && (
								<GridContainer>
									<GridItem xs={12}>
										<Paper className={classes.infoPaper} elevation={1}>
											<GridContainer className={classes.left}>
												<GridItem xs>
													<strong>Tracking Number: </strong> {this.state.tracking_info.trackingId}
													<br />
													<strong>Pickup Date: </strong> {this.state.tracking_info.pickupDate}
												</GridItem>
												<GridItem xs>
													<strong>Carrier: </strong> {this.state.tracking_info.carrier}
													<br />
													<strong>Service Type: </strong> {this.state.tracking_info.service}
												</GridItem>
											</GridContainer>
										</Paper>
									</GridItem>
									<GridItem xs={12}>
										<h5>Status Updates</h5>
										<GridContainer className={classes.left}>{!_.isEmpty(this.state.tracking_info.status) && this.getStatusUpdates(this.state.tracking_info.status)}</GridContainer>
									</GridItem>
								</GridContainer>
							)}
							{_.isEmpty(this.state.tracking_info) && (
								<GridContainer>
									<GridItem xs={12}>
										<Paper className={classes.infoPaper} elevation={1}>
											<strong>PRO Not Found!</strong> Some carriers have a check digit at the end of their PRO number. This is often sepparated by a hyphen (-) or a blank space. In most cases, entering this number will have no effect, however some carriers do not provide tracking information when this digit is provided. Try typing the PRO again without the last digit, and try
											again. If that does not work please <a href="https://targetfmi.com/contact-us/">Contact Us</a>.
										</Paper>
									</GridItem>
								</GridContainer>
							)}
						</DialogContent>
						<DialogActions className={classes.modalFooter}>
							<Button onClick={() => this.handleModalClose("trackingInfoModal")} color="white">
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	const adminUser = userIsAdmin(state);
	const adminAsUser = adminInUserSession(state);
	return {
		notesLoading: state.ArchivedBillsOfLading.bolNotesStatusesLoading,
		bolsWithNewNotes: state.ArchivedBillsOfLading.bolsWithNewNotes,
		bolNotesCountLoading: state.ArchivedBillsOfLading.bolNotesCountLoading,
		bolsWithNotes: state.ArchivedBillsOfLading.bolsWithNotes,
		refTypes: state.ArchivedBillsOfLading.refTypes,
		transitStatusOpts: state.ArchivedBillsOfLading.transitStatusOpts,
		userIsAdmin: adminUser,
		adminInUserSession: adminAsUser,
		userHasBillOfLadingPermission: !!hasPermission("USE_BILL_OF_LADING")(state) || adminUser,
		hasCarrierTrackingPermission: hasPermission("USE_CARRIER_TRACKING")(state),
		userHasBolLevelPermission: !!hasPermission("USE_BOL_LEVEL")(state),
		hasClaimsPermission: hasPermission("USE_CLAIMS")(state),
		levels: state.ImportanceLevel.levels,
		searchFilters: state.Search.filters,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		clearState,
		getBolNotesStatus,
		setBolsWithNewNotes,
		getBolsNoteCount,
		setBolsWithNotes,
		loadNotesModal,
		loadLevelsIfEmpty,
		updateBolLevel,
		getTransitStatusOptions,
		getLatePickupOptions,
		getLateDeliveryOptions,
		getReferences,
		getPostingPanelColumns,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle /* darkModeStyle */)(withSnackbar(ArchivedBillsOfLading)));
