/*!

 =========================================================
 * Material Dashboard PRO React - v1.5.0 based on Material Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * Converts a hex color to a rgb color
 * @param {string} input hex color value
 * @returns {string} rgb color value
 * @throws {Error} if input is not a valid hex color
 * @example
 * hexToRgb("#9c27b0") => "156, 39, 176"
 * hexToRgb("9c27b0") => "156, 39, 176"
 * hexToRgb("#999") => "153, 153, 153"
 * hexToRgb("999") => "153, 153, 153"
 */
const hexToRgb = input => {
	input = input + "";
	input = input.replace("#", "");
	let hexRegex = /[0-9A-Fa-f]/g;
	if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
		throw new Error("input is not a valid hex color.");
	}
	if (input.length === 3) {
		let first = input[0];
		let second = input[1];
		let last = input[2];
		input = first + first + second + second + last + last;
	}
	input = input.toUpperCase(input);
	let first = input[0] + input[1];
	let second = input[2] + input[3];
	let last = input[4] + input[5];
	return (
		parseInt(first, 16) +
		", " +
		parseInt(second, 16) +
		", " +
		parseInt(last, 16)
	);
};

/**
 * Converts a hex color to a rgba color
 * @param {string} input hex color value
 * @param {number} alpha alpha value
 * @returns {string} rgba color value
 */
const hexToRgbAlpha = (input, alpha) => {
	return "rgba(" + hexToRgb(input) + ", " + alpha + ")";
};

/* =============================================================
*	Variables - Styles that are used on more than one component
*  =============================================================

/* --------------------- Colors --------------------- */
const tfmBlueColor = "#002D55";
const tfmOrangeColor = "#E4801D";

const empireBlueColor = "#051B2B";
const empireOrangeColor = "#BA5029";

class ColorArray {
	// Each color array is a gradient of 5 colors from lightest to darkest
	constructor(lightest, light, mid, dark, darkest, defaultShade = 'light') {
		this.colorArray = {
			'lightest': lightest,
			'light': light,
			'mid': mid,
			'dark': dark,
			'darkest': darkest
		};

		this.lightest = lightest;
		this.light = light;
		this.mid = mid;
		this.dark = dark;
		this.darkest = darkest;

		this.default = this[defaultShade];
	}

	getShade(index) {
		return this.colorArray[index];
	}
}

const uiColors = {
	'primary': new ColorArray("#1986D4", "#156CAB", "#105282", "#0B3959", "#051B2B", "darkest"),
	'secondary': new ColorArray("#E86333", "#BA5029", "#964021", "#6E2F18", "#451D0F"),
	'tertiary': new ColorArray("#FAFAEE", "#CCCCC2", "#BDBDB3", "#A6A69E", "#7D7D77", "lightest"),

	'danger': new ColorArray("#FBDCDC", "#F88E86", "#E53935", "#D32F2F", "#B71C1C", "mid"), //Reds
	'warning': new ColorArray("#FFE2CC", "#FFD3B2", "#FF8F00", "#FF6F00", "#DB5F00", "mid"), //Oranges
	'success': new ColorArray("#D1EAD2", "#5CB860", "#4CAF50", "#43A047", "#388E3C"), //Greens
	'info': new ColorArray("#D9EDF7", "#C4E3F3", "#00BDD6",  "#00B2C9", "#00A0B5", "mid"), //Light Blues
	'misc': new ColorArray("#8638FC", "#6329BA", "#662ABF", "#5B26AB", "#5B26AB"), //Purples
	'yellow': new ColorArray("#FCF8E3", "#FFF331", "#E4D600", "#CABE00", "#B1A600"), //Yellows
	'rose': new ColorArray("#F09CB8", "#EB3573", "#E91E63", "#D81B60", "#C2185B"), // Pinks
}

const referenceColors = {
	'black': "#000",
	'darkGray': "#333",
	'gray': "#777",
	'lightGray': "#999",
	'white': "#FFF",
}

const brandColors = {
	'tfmBlue': "#002D55",
	'tfmOrange': "#E4801D",

	'empireBlue': "#051B2B",
	'empireOrange': "#BA5029",

	'twitter': "#55acee",
	'facebook': "#3b5998",
	'google': "#dd4b39",
	'linkedin': "#0976b4",
	'pinterest': "#cc2127",
	'youtube': "#e52d27",
	'tumblr': "#35465c",
	'github': "#333",
	'behance': "#1769ff",
	'dribbble': "#ea4c89",
	'reddit': "#ff4500",
}

// Gray
//const grayColors = new ColorArray("#999", "#777", "#3C4858", "#AAAAAA", "#D2D2D2");
//const grayColor = grayColors.getShade('light');

const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#555555",
  "#333",
  "#eee",
  "#ccc",
  "#e4e4e4",
  "#E5E5E5",
  "#f9f9f9",
  "#f5f5f5",
  "#495057",
  "#e7e7e7",
  "#212121",
  "#c8c8c8",
  "#505050"
];

// Reference Colors
const blackColor = "#000";
const whiteColor = "#FFF";

/* --------------------- Typography --------------------- */

const defaultFont = {
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	fontWeight: "300",
	lineHeight: "1.5em"
};

const title = {
	color: grayColor[2],
	textDecoration: "none",
	fontWeight: "300",
	marginTop: "30px",
	marginBottom: "25px",
	minHeight: "32px",
	fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	"& small": {
		color: grayColor[1],
		fontSize: "65%",
		fontWeight: "400",
		lineHeight: "1"
	}
};

const tooltip = {
	padding: "10px 15px",
	minWidth: "130px",
	color: whiteColor,
	lineHeight: "1.7em",
	background: "rgba(" + hexToRgb(grayColor[6]) + ",0.9)",
	border: "none",
	borderRadius: "3px",
	opacity: "1!important",
	boxShadow:
		"0 8px 10px 1px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.14), 0 3px 14px 2px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.12), 0 5px 5px -3px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.2)",
	maxWidth: "200px",
	textAlign: "center",
	fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
	fontSize: "12px",
	fontStyle: "normal",
	fontWeight: "400",
	textShadow: "none",
	textTransform: "none",
	letterSpacing: "normal",
	wordBreak: "normal",
	wordSpacing: "normal",
	wordWrap: "normal",
	whiteSpace: "normal",
	lineBreak: "auto"
};

/* --------------------- Containers --------------------- */

const container = {
	paddingRight: "15px",
	paddingLeft: "15px",
	marginRight: "auto",
	marginLeft: "auto",
	"@media (min-width: 768px)": {
		width: "750px"
	},
	"@media (min-width: 992px)": {
		width: "970px"
	},
	"@media (min-width: 1200px)": {
		width: "1170px"
	},
	"&:before,&:after": {
		display: "table",
		content: '" "'
	},
	"&:after": {
		clear: "both"
	}
};

const containerFluid = {
	paddingRight: "15px",
	paddingLeft: "15px",
	marginRight: "auto",
	marginLeft: "auto",
	"&:before,&:after": {
		display: "table",
		content: '" "'
	},
	"&:after": {
		clear: "both"
	}
};

/* --------------------- Shadow --------------------- */

const defaultBoxShadow = {
	border: "0",
	borderRadius: "3px",
	boxShadow:
		"0 10px 20px -12px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.42), 0 3px 20px 0px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.12), 0 8px 10px -5px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.2)",
	padding: "10px 0",
	transition: "all 150ms ease 0s"
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
};

/**
 * Creates a box-shadow css property
 * @param {string} color hex color value
 * @returns {object} box-shadow css property
 */
const makeBoxShadow = (color) => {
	return {
		boxShadow:
			"0 4px 20px 0 rgba(" +
			hexToRgb(blackColor) +
			",.14), 0 7px 10px -5px rgba(" +
			hexToRgb(color) +
			",.4)"
	}
}

const tfmBlueBoxShadow = makeBoxShadow(tfmBlueColor);
const tfmOrangeBoxShadow = makeBoxShadow(tfmOrangeColor);

const blackBoxShadow = makeBoxShadow(blackColor);

/* --------------------- Card --------------------- */

const card = {
	display: "inline-block",
	position: "relative",
	width: "100%",
	margin: "25px 0",
	boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
	borderRadius: "6px",
	color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
	background: whiteColor
};

const cardActions = {
	margin: "0 20px 10px",
	paddingTop: "10px",
	borderTop: "1px solid " + grayColor[8],
	height: "auto",
	...defaultFont
};

const cardHeader = {
	margin: "-20px 15px 0",
	borderRadius: "3px",
	padding: "15px"
};

const makeCardHeader = (colorArray) => {
	return {
		background: "linear-gradient(60deg, " + colorArray.getShade('light') + ", " + colorArray.getShade('mid') + ")",
		...makeBoxShadow(colorArray.getShade('light'))
	}
};

const warningCardHeader = makeCardHeader(uiColors.warning);
const successCardHeader = makeCardHeader(uiColors.success);
const dangerCardHeader = makeCardHeader(uiColors.danger);
const infoCardHeader = makeCardHeader(uiColors.info);
const primaryCardHeader = makeCardHeader(uiColors.primary);
const roseCardHeader = makeCardHeader(uiColors.rose);

// TFM Colors
const tfmBlueCardHeader = {
	background: "linear-gradient(60deg, #0E3E69, #002D55)",
	...tfmBlueBoxShadow
};
const tfmOrangeCardHeader = {
	background: "linear-gradient(60deg, #F29638, #E4801D)",
	...tfmOrangeBoxShadow
};

const cardTitle = {
	...title,
	marginTop: "0",
	marginBottom: "3px",
	minHeight: "auto",
	"& a": {
		...title,
		marginTop: ".625rem",
		marginBottom: "0.75rem",
		minHeight: "auto"
	}
};

const cardSubtitle = {
	marginTop: "-.375rem"
};

const cardLink = {
	"& + $cardLink": {
		marginLeft: "1.25rem"
	}
};

/* --------------------- Misc --------------------- */

const drawerWidth = 260;

const drawerMiniWidth = 80;

const notificationsWidth = 400;

const transition = {
	transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

export {
	// Functions
	hexToRgb,
	hexToRgbAlpha,
	makeBoxShadow,

	// Variables
	uiColors,
	brandColors,
	referenceColors,
	grayColor,
	tfmBlueColor,
	tfmOrangeColor,
	blackColor,
	whiteColor,

	defaultFont,
	title,
	tooltip,

	drawerWidth,
	drawerMiniWidth,
	notificationsWidth,
	transition,

	container,
	containerFluid,

	card,
	cardActions,
	cardHeader,
	cardTitle,
	cardSubtitle,
	cardLink,

	boxShadow,
	defaultBoxShadow,
	blackBoxShadow,

	warningCardHeader,
	successCardHeader,
	dangerCardHeader,
	infoCardHeader,
	primaryCardHeader,
	roseCardHeader,
	tfmBlueCardHeader,
	tfmOrangeCardHeader,
};
