import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withSnackbar } from "notistack";
import { processNotificationById } from "../../redux/actions/notifications.jsx";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
/**
 * This component connects snackbar to redux so that we can set notifications globally by dispatching setNotification 
 * Instead of having to wrap every single component with "withSnackbar"
 */
class ConnectSnackbar extends Component {

	state = {
		queuedNotifications: [],
	}

	handleClose = (id) => {
		let matchingNotification = this.state.queuedNotifications.find(queuedNotif => queuedNotif.id == id);
		if (typeof(matchingNotification) !== "undefined") {
			this.props.closeSnackbar(matchingNotification.snackbarId);
		}
	}

	async componentDidUpdate() {
		for (let i = 0; i < this.props.notifications.length; i++) {
			let notif = this.props.notifications[i];

			notif.options.onExit = () => {
				this.props.processNotificationById(notif.options.id);
				this.setState({queuedNotifications: this.state.queuedNotifications.filter(id => id !== notif.options.id)});
			}

			let matchingNotification = this.state.queuedNotifications.find(queuedNotif => queuedNotif.id == notif.options.id);
			if (typeof(matchingNotification) == "undefined") {
				let snackbarId = this.props.enqueueSnackbar(
					<React.Fragment>
						<span dangerouslySetInnerHTML={{ __html: notif.message }} style={{"marginRight": "10px"}}/>
						<IconButton onClick={() => this.handleClose(notif.options.id)} size="small">
							<CloseIcon />
						</IconButton>
			  		</ React.Fragment>, notif.options);
				await this.setState({queuedNotifications: [...this.state.queuedNotifications, {id: notif.options.id, snackbarId: snackbarId}]});
			}
		}
	}

	render() {
		return null;
	}
}

const mapStateToProps = state => {
	return {
		notifications: state.notifications.notifications,
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	processNotificationById,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(ConnectSnackbar));