import React, { Component } from "react";
import CarrierBillContainer from "./CarrierBillContainer.jsx";

class ViewHeldBillContainer extends Component {
	render() {
		return (
			<CarrierBillContainer id={this.props.match.params.id} canUpdate={true}/>
		)
	}
}

export default ViewHeldBillContainer;
