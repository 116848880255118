import React, { Component } from "react";
import HeldBillsListContainer from './HeldBillsListContainer.jsx';

class HeldBills extends Component {
	render() {
		return (
			<HeldBillsListContainer holdCodes={this.props.match.params.code}/>
		);
	}
}

export default HeldBills;
