import React from "react";
import _ from "lodash";
import { withSnackbar } from "notistack";
import axios from "~/variables/axios.jsx";

// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";

import OptionList from "./OptionList.jsx";

import optionStyle from "~/assets/jss/material-dashboard-pro-react/views/tms/optionStyle.jsx";

class OptionListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      left: this.props.options,
      right: this.props.selectedOptions,
    };
  }
  async componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <OptionList
        available={this.props.options}
        selected={this.props.selectedOptions}
        onMove={this.props.handleSelected}
        classes={classes}
        sortable={true}
        searchable={true}
        moveLeftIcon={<i className="fa fa-angle-left" />}
        moveAllLeftIcon={<i className="fa fa-angle-double-left" />}
        moveRightIcon={<i className="fa fa-angle-right" />}
        moveAllRightIcon={<i className="fa fa-angle-double-right" />}
        moveUpIcon={<i className="fa fa-angle-up" />}
        moveTopIcon={<i className="fa fa-angle-double-up" />}
        moveDownIcon={<i className="fa fa-angle-down" />}
        moveBottomIcon={<i className="fa fa-angle-double-down" />}
      />
    );
  }
}
export default withStyles(optionStyle)(withSnackbar(OptionListView));
