import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import axios from "~/variables/axios.jsx";

import moment from "moment";
import ReactTable from 'react-table-6';
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from 'react-loading-skeleton';
import { basePath } from "~/variables/server.jsx";
import { Link } from 'react-router-dom'
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { setNotification } from "../../../redux/actions/notifications.jsx";
import CustomPagination from "./CustomPagination.jsx";
import { loadNotesModal } from "../../BillOfLadingNotesModal/actions/BillOfLadingNotes.jsx";
import BillOfLadingNotesModal from "../../BillOfLadingNotesModal/containers/BillOfLadingNotesModal.jsx";
import { getBolsNoteCount } from "../../ArchivedBillsOfLading/actions/ArchivedBillsOfLading.jsx";
import {
	getOptions,
	getTrackingData,
	setInternalTrackingPageNum,
	setInternalTrackingSortBy,
	setInternalTrackingSortDir,
	fetchFilterPresets,
	exportCsv,
	loadStoredState,
} from "../actions/InternalCSTracking.jsx";

axios.defaults.timeout = 60000;
const DEFAULT_PAGE_SIZE = 100;

class InternalCSTracking extends Component {

	state = {
		initialized: false,
	}

	async componentDidMount() {
		this.props.fetchFilterPresets();
		this.props.loadStoredState();
		await this.props.getOptions();
		await this.loadTableData();
		this.setState({initialized: true});
	}

	/**
	 * Load BOLs, then fetch BOL notes data
	 */
	loadTableData = async () => {
		await this.props.getTrackingData();
		this.getBolsNoteCount();
	}

	numberPages = () => {
		return +this.props.pageSize > this.props.totalRecords
			? 1
			: Math.ceil(+this.props.totalRecords / +this.props.pageSize);
	}

	onPageChange = index => {
		this.props.setInternalTrackingPageNum(index+1);
		this.loadTableData();
	}

	onSortChange = column => {
		if(this.props.sortBy == column) {
			this.props.setInternalTrackingSortDir(!this.props.descending);
		} else {
			this.props.setInternalTrackingSortDir(true);
		}
		this.props.setInternalTrackingSortBy(column);
		this.props.setInternalTrackingPageNum(1);
		this.loadTableData();
	}

	getBolsNoteCount = async () => {
		let bols = this.props.trackingRows.map(row => {
			return row.bol_date + row.bol_id;
		});

		if (bols.length) {
			this.props.getBolsNoteCount(bols);
		}
	}

	getNumNotes = (bolNum) => {
		let noteCount = this.props.bolsWithNotes[bolNum];

		if (noteCount == undefined) {
			noteCount = 0;
		}
		return noteCount;
	}
	
    showNotesModal = (bol_num) => {
        this.props.loadNotesModal(bol_num);
    }

    /**
     * Get props to pass to each column config
     */
    getColumnProps = (headerDisplayName, data) => {
    	// const iconStyle = {position: "relative", top: "5px"};
    	let Header = headerDisplayName;
    	// if (this.props.sortBy === data) {
    	// 	Header = <span>{this.props.descending ? <ArrowDownwardIcon fontSize="small" style={iconStyle}/> : <ArrowUpwardIcon fontSize="small" style={iconStyle}/>} {headerDisplayName}</span>
    	// }
    	return {
    		Header,
			headerDisplayName,
			data,
    		style: {cursor: "pointer"},
    		// title: "Click to sort on this column",
    		// getHeaderProps: () => {
		    // 	return {
		    // 		onClick: () => {
		    // 			this.onSortChange(data);
		    // 		},
		    // 	};
	    	// },
    	};
    }

	render() {
		if (!this.state.initialized) return this.loading();
		const columns = [
			{...this.getColumnProps("Company", "COMPANY NAME"), accessor: "company_name"},
			{...this.getColumnProps("Warehouse", "WAREHOUSE NAME"), accessor: "warehouse_name"},
			{...this.getColumnProps("Code", "WAREHOUSE CODE"), accessor: "code"},
			{...this.getColumnProps("Ship Date", "SHIP DATE"), Cell: rowInfo => {
				let date = rowInfo.original.pickup_date;
				if (typeof date === "string" && date.length >= 10) {
					date = moment(date.substring(0, 10), "YYYY-MM-DD").format("MM/DD/YYYY");
				}
				return date;
			}},
			{...this.getColumnProps("Guaranteed Date", "DELIVER BY (CUSTOM)"), Cell: rowInfo => {
				let date = rowInfo.original.guaranteed_date;
				let time = "";
				if (typeof date === "string" && date.length >= 10 && date != null) {
					time = moment(date.substring(10).trim(), "hh:mm:ss").format("h:mm A");
					date = moment(date.substring(0, 10), "YYYY-MM-DD").format("MM/DD/YYYY");
					date = date + " " + time;
				}
				return date;
			}},
			{...this.getColumnProps("Direction/Terms", "DIRECTION/TERMS"), accessor: "direction"},
			{...this.getColumnProps("BOL Status", "BOL STATUS"), accessor: "status"},
			{...this.getColumnProps("BOL ID","TMS BOL ID"), accessor: "bol_date,bol_id", Cell: rowInfo => {
				return (
					<Link to={`${basePath}/admin/bol/${rowInfo.original.bol_date}/${rowInfo.original.bol_id}`}>
						{rowInfo.original.bol_date + rowInfo.original.bol_id}
					</Link>
				);
			}},
			{...this.getColumnProps("Custom BOL ID", "CUSTOM BOL ID"), accessor: "custom_id"},
			{...this.getColumnProps("Pro", "PRO"), accessor: "pro"},
			{...this.getColumnProps("So", "SO"), accessor: "so", },
			{...this.getColumnProps("Po", "PO"), accessor: "po", },
			{...this.getColumnProps("Reference", "REF"), accessor: "ref", },
			{...this.getColumnProps("User Custom Date", "USER CUSTOM DATE"), accessor: "user_defined_date", },
			{...this.getColumnProps("Expected Delivery Date", "EXPECTED DELIVERY"), accessor: "expected_delivery_date"},
			{...this.getColumnProps("Transit Status", "TRANSIT STATUS"), accessor: "transit_status"},
			{...this.getColumnProps("Latest Tracking Update", "LATEST CARRIER TRACKING UPDATE"), accessor: "status_reason_desc"},
			{...this.getColumnProps("Carrier", "CARRIER NAME"), accessor: "carrier"},
			{...this.getColumnProps("Shipper", "SHIPPER NAME"), accessor: "shipper_company_name"},
			{...this.getColumnProps("Shipper Address", "SHIPPER ADDRESS 1"), accessor: "shipper_address1"},
			{...this.getColumnProps("Shipper Address 2", "SHIPPER ADDRESS 2"), accessor: "shipper_address2"},
			{...this.getColumnProps("Shipper City", "SHIPPER CITY"), accessor: "shipper_city"},
			{...this.getColumnProps("Shipper State", "SHIPPER STATE"), accessor: "shipper_state"},
			{...this.getColumnProps("Shipper Zip", "SHIPPER ZIP"), accessor: "shipper_zip"},
			{...this.getColumnProps("Consignee", "CONSIGNEE NAME"), accessor: "consignee_company_name"},
			{...this.getColumnProps("Consignee Address", "CONSIGNEE ADDRESS 1"), accessor: "consignee_address1"},
			{...this.getColumnProps("Consignee Address 2", "CONSIGNEE ADDRESS 2"), accessor: "consignee_address2"},
			{...this.getColumnProps("Consignee City", "CONSIGNEE CITY"), accessor: "consignee_city"},
			{...this.getColumnProps("Consignee State", "CONSIGNEE STATE"), accessor: "consignee_state"},
			{...this.getColumnProps("Consignee Zip", "CONSIGNEE ZIP"), accessor: "consignee_zip"},
			{...this.getColumnProps("Execution Date", "DATETIME EXECUTED"), accessor: "original_execution_date"},
			{...this.getColumnProps("Importance Level", "IMPORTANCE LEVEL"), accessor: "importance"},
			{...this.getColumnProps("Total Pieces", "TOTAL PIECES"), accessor: "total_pieces"},
			{...this.getColumnProps("Total Weight", "TOTAL WEIGHT"), accessor: "total_weight"},
			{Header: "Special Instructions", accessor: "notes"},
			{Header: "View Tracking History", sortable: false, Cell: rowInfo => {
					if(rowInfo.original.status_reason_desc != "" && rowInfo.original.status_reason_desc != null)  {
						return (
							<Link to={`${basePath}/admin/bol/HistoryPanel/${rowInfo.original.bol_date}${rowInfo.original.bol_id}`}>
								Track Shipment
							</Link>
						);
					} else {
						return null;
					}
				}
			},
			{Header: "", sortable: false, 
				Cell: rowInfo => {
					return (
						<Fragment>
							{(this.props.bolNotesCountLoading || this.props.rowsLoading) && (
								<CircularProgress size={8} />
							)}
							{(!this.props.bolNotesCountLoading && !this.props.rowsLoading) && (
								<a 
									href="#" 
									onClick={e => {
										e.preventDefault();
										this.showNotesModal(rowInfo.original.bol_date + rowInfo.original.bol_id)}
									}
								>
									Notes&nbsp; 
									<span> 
										({this.getNumNotes(rowInfo.original.bol_date + rowInfo.original.bol_id)}) 
									</span>
								</a>
							)}
						</Fragment>
					);
				}
			},
		];

		return (
			<Fragment>
				<BillOfLadingNotesModal />
				<GridContainer> 
					<GridItem xs={3} style={{color: "#000"}}>
						{(this.props.totalRecords >= 0) && (
							<h4 style={{marginRight: "5px"}}>{`Records: ${this.props.totalRecords}`}</h4>
						)}
					</GridItem>
					<GridItem xs={6} > </GridItem>
					<GridItem xs={3} style={{textAlign: "right"}}>
						<Button disabled={!this.props.filters.length || !this.props.trackingRows.length} color="success" onClick={() => {this.props.exportCsv()}}>
							Export CSV &nbsp; <SystemUpdateAltOutlinedIcon /> 
						</Button>   
					</GridItem>
				</GridContainer>
				<div className="react-table-condensed">
					<ReactTable
						data={this.props.trackingRows || []}
						columns={columns}
						pages={this.numberPages()}
						loading={this.props.rowsLoading}
						className={this.props.className || "-striped -highlight"}
						defaultPageSize={this.props.pageSize || DEFAULT_PAGE_SIZE}
						showPaginationTop={true}
						showPaginationBottom={false}
						showPageSizeOptions={false}
						sortable={false}
						manual={true}
						onPageChange={this.onPageChange}
						page={this.props.pageNum-1}
						minRows={this.props.minRows || this.props.pageSize}
						defaultSorted={[{
							id:this.props.sortBy,
							desc: this.props.descending,
						}]}
						PaginationComponent={CustomPagination}
						style={{maxHeight: window.innerHeight - 270}}
					/>
				</div>
			</Fragment>
		);
	}

	loading() {
		return (
			<div style={{marginTop: "40px"}}>
				<Skeleton height={window.innerHeight - 260} />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		rowsLoading: state.InternalCSTracking.internalCSLoading,
		trackingRows: state.InternalCSTracking.trackingRows,
		sortBy: state.InternalCSTracking.sortBy,
		descending: state.InternalCSTracking.desc,
		totalRecords: state.InternalCSTracking.numRecords,
		pageSize: state.InternalCSTracking.pageSize,
		pageNum: state.InternalCSTracking.pageNum,
		bolsWithNotes: state.ArchivedBillsOfLading.bolsWithNotes,
		bolNotesCountLoading: state.ArchivedBillsOfLading.bolNotesCountLoading,
		filters: state.InternalCSTracking.filters,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getOptions,
		getTrackingData,
		setInternalTrackingPageNum,
		setInternalTrackingSortBy,
		setInternalTrackingSortDir,
		loadNotesModal,
		getBolsNoteCount,
		setNotification,
		fetchFilterPresets,
		exportCsv,
		loadStoredState,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalCSTracking);