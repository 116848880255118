import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { NavLink, Redirect } from "react-router-dom";
import { flushSync } from 'react-dom';
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import { hasPermission, userIsAdmin, getRateDisplayByMode } from "../../../redux/selectors/Admin.jsx";
import PrintZplModal from "~/views/PrintZplModal/containers/PrintZplModal.jsx";
import { setJSPM, setAvailablePrinters, jspmStatus, setUpPrinters, autoPrint, printDocument } from "~/views/PrintZplModal/actions/PrintZplModal.jsx";

import "formdata-polyfill";

import _ from "lodash";
import qs from "qs";
import cx from "classnames";
import moment from "moment";
import axios from "~/variables/axios.jsx";
import { basePath, apiUrl } from "~/variables/server.jsx";

// lib
import YatClient from '~/lib/yat.js';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// material ui icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Phone from "@material-ui/icons/Phone";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Save from "@material-ui/icons/Save";
import SwapVert from "@material-ui/icons/SwapVert";
import LocationOn from "@material-ui/icons/LocationOn";
import Person from "@material-ui/icons/Person";
import Book from "@material-ui/icons/Book";
import FastRewind from "@material-ui/icons/FastRewind";
import FastForward from "@material-ui/icons/FastForward";
import FileCopy from "@material-ui/icons/FileCopy";
import MergeType from "@material-ui/icons/MergeType";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// react-icons
import { FaFax } from "react-icons/fa";

import { IoIosCalculator } from "react-icons/io";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import ContactSearch from "~/components/TMS/ContactSearch.jsx";
import AccessorialsSelect from "../../../components/TMS/AccessorialsSelect.jsx";
import { openAlertModal } from "../../Alert/actions/Alert.jsx";
import BillOfLadingNotesModal from "../../BillOfLadingNotesModal/containers/BillOfLadingNotesModal.jsx";
import { loadNotesModal } from "../../BillOfLadingNotesModal/actions/BillOfLadingNotes.jsx";
import RateResults from "../../RateResults/containers/RateResults.jsx";
import { loadTerminalModal } from "../../RateResults/actions/TerminalInformationModal.jsx";
import TerminalInformationModal from "../../RateResults/containers/TerminalInformationModal.jsx";
import LiabilityModal from "../../RateResults/containers/LiabilityModal.jsx";
import DatePromptModal from "../../../components/DatePromptModal/DatePromptModal.jsx";
import DoubleDatePromptModal from "../../../components/DoubleDatePromptModal/DoubleDatePromptModal.jsx";
import ShipmentSearchCard from "~/components/ShipmentSearchCard/ShipmentSearchCard.jsx";
import ShipmentHistoryCard from "~/components/ShipmentHistoryCard/ShipmentHistoryCard.jsx";

import AddressFields from "~/components/AddressFields/AddressFields.jsx";
import ContactFields from "~/components/ContactFields/ContactFields.jsx";

import SpecialInformationFields from "~/components/SpecialInformationFields/SpecialInformationFields.jsx";
import DGDFields from "../components/DGDFields.jsx";
import GeneralInformation from "~/components/GeneralInformation/GeneralInformation.jsx";
import HandlingUnit from "~/components/HandlingUnit/HandlingUnit.jsx";

// Modals
import ContactSearchModal from "~/components/ContactSearchModal/ContactSearchModal.jsx";
import FeetToInchesModal from "../components/FeetToInchesModal.jsx";
import SurfaceAreaModal from "../components/SurfaceAreaModal.jsx";
import UnitSearchModal from "../components/UnitSearchModal.jsx";
import ProductSearchModal from "../components/ProductSearchModal.jsx";
import DuplicateBolsModal from "../components/DuplicateBolsModal.jsx";
import RequireEmailModal from "../components/RequireEmailModal.jsx";
import CustomInputModal from "../components/CustomInputModal.jsx";
import PickupRequestModal from "../components/PickupRequestModal.jsx";
import PrintDgdModal from "../components/PrintDgdModal.jsx";
import TruckloadAuthModal from "../components/TruckloadAuthModal.jsx";
import CreateLoadModal from "../components/CreateLoadModal.jsx";
import NonLccModal from "../components/NonLccModal.jsx";
import SearchBolModal from "../components/SearchBolModal.jsx";
import MergeBolModal from "../components/MergeBolModal.jsx";
import PrintBolModal from "../components/PrintBolModal.jsx";
import SaveAsNewModal from "../components/SaveAsNewModal.jsx";
import LocationCodeModal from "../components/LocationCodeModal.jsx";
import LnftLoadPlanModal from "~/components/LnftLoadPlanModal/LnftLoadPlanModal.jsx";
import { loadLevelsIfEmpty } from "../../../components/ImportanceLevel/actions/ImportanceLevel.jsx";
import { loadAutoSelectAccessorialMaps } from "../../WarehouseLocation/actions/WarehouseLocation.jsx";
import { isObject, convertToCountryCode, getTimezoneOffset } from "../../../helpers.jsx";

import AdditionalContacts from "../../../components/TMS/AdditionalContacts.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class BillOfLading extends Component {
    constructor(props) {
        super(props);
        this.yat = new YatClient(this.props.yatUrl);
        this.state = {
            show: false,
            mounted: false,
            loading: true,
            lnftLoading: false,
            loadingRecommendedAccs: false,
            printDocModal: false,
            showPrintMenu: false,
            showSaveModal: false,
            default_printer_save: false,
            docZpl: "",
            printer: "",
            updating_tracking: false,
            alert: null,
            redirect: null,
            bolId: null,
            date: null,
            saved_bol_num: "",
            status: "",
            level: null,
            username: "",
            date_created: "",
            time_created: "",
            search: false,
            bolsToCancel: [],
            active: 0,
            canceled: false,
            // settings
            user: null,
            body: null,
            company: null,
            nmfc_notice_message: "To accurately assess the Cubic Capacity, Density or Linear Foot Charges the dimensions are required. Target Freight Management is not responsible for the additional charges incurred due to missing dimensions. For an accurate quote on the shipments or to make the dimensions mandatory please call us at 1-888-653-1323.",
            defaultPickupTimes: {},
            totals: {
                units: 0,
                weight: 0,
                volume: 0,
                density: 0,
                linear_feet: 0,
                value: 0
            },
            total_declared_value: 0,
            // profile info
            warehouse: "",
            // search
            search_bol_by: "bol",
            search_bol: "",
            search_bol_merge_by: "bol",
            search_bol_merge_reference_type: "",
            search_bol_merge: "",
            // general info
            direction: "Outbound/Prepaid",
            charge_customer: 0,
            pro_num: "",
            pro_book_flag: false,
            proBookPro: "",
            bol_num: "",
            custom_date: "",
            original_execution_date: "",
            po_num: "",
            so_num: "",
            ref_num: "",
            references: [],
            blind_shipment: false,
            // transit info
            transit_status: "",
            transit_sub_status: "",
            late_pickup_reason: "",
            late_delivery_reason: "",
            pickup_date: moment().format('MM/DD/YYYY'),
            original_pd: "",
            expected_delivery_date: "",
            original_edd: "",
            delivery_date: "",
            delivery_time: "",
            // shipper info
            shipper_name: "",
            shipper_address1: "",
            shipper_address2: "",
            shipper_zip: "",
            shipper_city: "",
            shipper_state: "",
            shipper_country: "US",
            shipper_contact_name: "",
            shipper_contact_phone: "",
            shipper_contact_email: "",
            shipper_contact_fax: "",
            shipper_save: false,
            shipper_search_by: "SEARCH_ANY",
            shipper_search_for: "",
            shipper_pickup_confirm: false,
            // blind shipper info
            blind_shipper_name: "",
            blind_shipper_address1: "",
            blind_shipper_address2: "",
            blind_shipper_zip: "",
            blind_shipper_city: "",
            blind_shipper_state: "",
            blind_shipper_country: "US",
            blind_shipper_contact_name: "",
            blind_shipper_contact_phone: "",
            blind_shipper_contact_email: "",
            blind_shipper_contact_fax: "",
            blind_shipper_search_by: "SEARCH_ANY",
            blind_shipper_search_for: "",
            // consignee information
            consignee_name: "",
            consignee_address1: "",
            consignee_address2: "",
            consignee_zip: "",
            consignee_city: "",
            consignee_state: "",
            consignee_country: "US",
            consignee_contact_name: "",
            consignee_contact_phone: "",
            consignee_contact_email: "",
            consignee_contact_fax: "",
            consignee_save: false,
            consignee_search_by: "SEARCH_ANY",
            consignee_search_for: "",
            consignee_pickup_confirm: false,
            // blind consignee information
            blind_consignee_name: "",
            blind_consignee_address1: "",
            blind_consignee_address2: "",
            blind_consignee_zip: "",
            blind_consignee_city: "",
            blind_consignee_state: "",
            blind_consignee_country: "US",
            blind_consignee_contact_name: "",
            blind_consignee_contact_phone: "",
            blind_consignee_contact_email: "",
            blind_consignee_contact_fax: "",
            blind_consignee_search_by: "SEARCH_ANY",
            blind_consignee_search_for: "",
            // billing information
            billing_name: "",
            billing_address1: "",
            billing_address2: "",
            billing_zip: "",
            billing_city: "",
            billing_state: "",
            billing_country: "US",
            billing_contact_name: "",
            billing_contact_phone: "",
            billing_contact_email: "",
            billing_contact_fax: "",
            billing_save: false,
            billing_search_by: "SEARCH_ANY",
            billing_search_for: "",
            bill_to_updated: false,
            // blind billing information
            blind_billing_name: "",
            blind_billing_address1: "",
            blind_billing_address2: "",
            blind_billing_zip: "",
            blind_billing_city: "",
            blind_billing_state: "",
            blind_billing_country: "US",
            blind_billing_contact_name: "",
            blind_billing_contact_phone: "",
            blind_billing_contact_email: "",
            blind_billing_contact_fax: "",
            blind_billing_save: false,
            blind_billing_search_by: "SEARCH_ANY",
            blind_billing_search_for: "",
            additional_tracking_emails: [],
            auto_tracking: false,
            // handling units
            units: [
                {
                    id: null,
                    type: "Pallet",
                    pieces: "1",
                    stackable: false,
                    length: "",
                    width: "",
                    height: "",
                    density: "",
                    cube: "",
                    products: [
                        {
                            nmfc: "",
                            sub_nmfc: "",
                            class: "",
                            description: "",
                            uom: "Piece",
                            pieces: "1",
                            weight: "",
                            net_weight: "",
                            condition: "",
                            packaging: "",
                            declared_value: "",
                            part_no: "",
                            hazardous: false,
                            auto_weight: false,
                            hazmat: {
                                class: "",
                                un: "",
                                packingGroup: "",
                                emergencyNumber: "",
                                packingInstructions: "",
                                overpackUsed: false
                            },
                            errors: []
                        }
                    ],
                    errors: []
                }
            ],
            classification: false,
            accessorials: [],
            recommended_accs_id: null,
            location_types: [],
            recommended_accessorials: [],
            // custom piece count
            display_custom_piece_count: false,
            custom_pallet_count: "",
            custom_piece_count: "",
            custom_loose_count: "",
            custom_unit_master: [],
            custom_unit_master_name: "Piece",
            custom_unit_master_id: null,
            // dangerous goods custom shipper information
            dgd_custom_shipper: false,
            dgd_shipper_name: "",
            dgd_shipper_address1: "",
            dgd_shipper_address2: "",
            dgd_shipper_zip: "",
            dgd_shipper_city: "",
            dgd_shipper_state: "",
            dgd_shipper_country: "US",
            dgd_shipper_contact_name: "",
            dgd_shipper_contact_phone: "",
            dgd_shipper_contact_email: "",
            dgd_shipper_contact_fax: "",
            dgd_shipper_search_by: "SEARCH_ANY",
            dgd_shipper_search_for: "",
            // dangerous goods custom consignee information
            dgd_custom_consignee: false,
            dgd_consignee_name: "",
            dgd_consignee_address1: "",
            dgd_consignee_address2: "",
            dgd_consignee_zip: "",
            dgd_consignee_city: "",
            dgd_consignee_state: "",
            dgd_consignee_country: "US",
            dgd_consignee_contact_name: "",
            dgd_consignee_contact_phone: "",
            dgd_consignee_contact_email: "",
            dgd_consignee_contact_fax: "",
            dgd_consignee_search_by: "SEARCH_ANY",
            dgd_consignee_search_for: "",
            // dgd air
            dgd_air_declarant: "",
            dgd_air_place: "",
            dgd_air_date: "",
            dgd_air_departure_airport: "",
            dgd_air_destination_airport: "",
            dgd_air_cargo_only: false,
            dgd_air_radioactive: false,
            // dgd ocean
            dgd_ocean_declarant: "",
            dgd_ocean_place: "",
            dgd_ocean_date: "",
            dgd_ocean_trans_doc_number: "",
            dgd_ocean_freight_forwarders_reference: "",
            dgd_ocean_carrier: "",
            dgd_ocean_vessel_flight_and_date: "",
            dgd_ocean_port_place_handling: "",
            dgd_ocean_ctu_number: "",
            dgd_ocean_seal_number: "",
            dgd_ocean_ctu_size_and_type: "",
            dgd_ocean_tare_mass: "",
            dgd_ocean_receiving_remarks: "",
            dgd_ocean_company_name: "",
            dgd_ocean_haulers_name: "",
            dgd_ocean_preparing_company_name: "",
            dgd_ocean_vehicle_registration_number: "",
            dgd_ocean_driver_name_date: "",
            // special information
            cod_by: "",
            cod_type: "",
            cod_amount: "",
            notes: "",
            canned_note: "",
            dgd_type: "",
            non_program_carrier: "",
            non_program_rate: "",
            pickup_request: false,
            imageFiles: [],
            // pickup information update
            confirmation_number: "",
            transaction_id: "",
            pickup_window_start: "",
            pickup_window_end: "",
            pickup_confirm: false,
            pickup_timestamp: "",
            // carrier selection
            data_type: "ltl",
            query_id: "",
            showCarriers: false,
            showVolumeCarriers: false,
            loadingCarriers: false,
            carriers: [],
            volume_carriers: [],
            allowed_carriers: [],
            max_weight: false,
            scac: "",
            executingBol: false,
            bolData: null,
            showTruckloadTab: false,
            tl_equipment: "Van",
            showQuote: false,
            loadingQuote: false,
            quoteId: "",
            tfmr_internal_id: null,
            distance: 0,
            quote: "",
            // validation
            required: {
                general_info: {
                    direction: "Direction/Freight Term"
                    // pro_num: "",
                    // po_num: "",
                    // so_num: "",
                    // ref_num: "",
                },
                shipment_details: {
                    shipper_name: "Shipper Name",
                    shipper_address1: "Shipper Address",
                    shipper_zip: "Shipper Zip",
                    shipper_city: "Shipper City",
                    shipper_state: "Shipper State",
                    consignee_name: "Consignee Name",
                    consignee_address1: "Consignee Address",
                    consignee_zip: "Consignee Zip",
                    consignee_city: "Consignee City",
                    consignee_state: "Consignee State",
                    billing_name: "Billing Name",
                    billing_address1: "Billing Address",
                    billing_zip: "Billing Zip",
                    billing_city: "Billing City",
                    billing_state: "Billing State"
                }
            },
            rates: ["direction", "warehouse", "shipper_address1", "shipper_zip", "shipper_city", "shipper_state", "shipper_country", "consignee_address1", "consignee_zip", "consignee_city", "consignee_state", "consignee_country", "custom_pallet_count", "custom_piece_count", "custom_loose_count", "tl_equipment", "allow_excess_liability", "blind_shipment"],
            pro_state: "",
            po_state: "",
            so_state: "",
            ref_state: "",
            custom_ref_state: [],
            shipper_name_state: "",
            shipper_address1_state: "",
            shipper_zip_state: "",
            shipper_city_state: "",
            shipper_state_state: "",
            shipper_contact_name_state: "",
            shipper_contact_phone_state: "",
            shipper_contact_email_state: "",
            shipper_contact_fax_state: "",
            consignee_name_state: "",
            consignee_address1_state: "",
            consignee_zip_state: "",
            consignee_city_state: "",
            consignee_state_state: "",
            consignee_contact_name_state: "",
            consignee_contact_phone_state: "",
            consignee_contact_email_state: "",
            consignee_contact_fax_state: "",
            blind_shipper_name_state: "",
            blind_shipper_address1_state: "",
            blind_shipper_zip_state: "",
            blind_shipper_city_state: "",
            blind_shipper_state_state: "",
            blind_consignee_name_state: "",
            blind_consignee_address1_state: "",
            blind_consignee_zip_state: "",
            blind_consignee_city_state: "",
            blind_consignee_state_state: "",
            billing_name_state: "",
            billing_address1_state: "",
            billing_zip_state: "",
            billing_city_state: "",
            billing_state_state: "",
            general_information_status: "",
            shipment_details_status: "",
            product_information_status: "",
            special_instructions_status: "",
            choose_carrier_status: "",
            // modals
            deliveryDatePromptOpen: false,
            deliveryDatePromptCallback: null,
            pickupDatePromptOpen: false,
            pickupDatePromptCallback: null,
            doubleDatePromptCallback: null,
            contactSearchModal: false,
            contacts: [],
            contactSearchType: "",
            feetToInchesModal: false,
            feet: "",
            inches: "",
            surfaceAreaModal: false,
            productLength: "",
            productWidth: "",
            palletLength: "",
            palletWidth: "",
            surfaceArea: 0,
            productSearchModal: false,
            products: [],
            productKey: "",
            productUnitKey: "",
            unitSearchModal: false,
            handling_units: [],
            unitKey: "",
            rateKey: "",
            locationCodeModal: false,
            saveLocationType: "",
            saveLocationCode: "",
            saveShipperCode: "",
            saveConsigneeCode: "",
            saveBillingCode: "",
            // display buttons
            showExecute: true,
            showSave: true,
            showExecuteUpdate: false,
            showTransitUpdate: false,
            showGetPro: false,
            showDraftPdf: false,
            showPrint: false,
            showPrintQuote: false,
            showPrintLabel: false,
            showPrintDgd: false,
            showPrintPkList: false,
            showSendAsn: false,
            autoSendTracking: false,
            trackingSent: false,
            p44Url: "",
            p44DispatchCanceled: false,
            showCancel: false,
            showUncancel: false,
            showPickupRequest: false,
            showSendToTl: false,
            printBolModal: false,
            saveAsNewModal: false,
            requireEmailModal: false,
            resendTracking: false,
            customInputModal: false,
            draft: false,
            duplicateBolsModal: false,
            duplicates: [],
            reference: null,
            pickupDateTime: "",
            dockCloseTime: "",
            pickupRequestModal: false,
            pickup_date_time: "",
            dock_close_time: "",
            pickup_step: 1,
            local_pickup_time: "",
            loadingPickup: false,
            printDgdModal: false,
            dgd_step: 1,
            dgd_pro: "",
            dgd_bol: "",
            dgd_po: "",
            dgd_so: "",
            dgd_ref: "",
            dgd_units: [],
            loadingBol: false,
            load_quote_id: null,
            defaultUnit: {},
            defaultProduct: {},
            loadingTl: false,
            tlAuthModal: false,
            username: "",
            password: "",
            loadingAuth: false,
            createLoadModal: false,
            customerId: "",
            customer_input: "",
            carrierId: "",
            carrier_input: "",
            shipmentType: "",
            equipment: "",
            pickupDockOpen: "",
            pickupDockClose: "",
            dropoffDockOpen: "",
            dropoffDockClose: "",
            serviceOptions: [],
            services: [],
            shipmentTypes: [],
            equipmentTypes: [],
            customers: [],
            carrierTypes: [],
            creatingShipment: false,
            loadId: null,
            nonLccModal: false,
            option: "",
            custom: "",
            searchBolModal: false,
            mergeBolModal: false,
            bols: [],
            bolsMerge: [],
            // carrier liability
            liabilityModal: false,
            allow_excess_liability: false,
            liability_modal_title: "",
            liability: null,
            disableRequestExcess: false,
            reference_type: "",
            refTypes: [],
            volume_total_length: "",
            volume_total_width: "",
            volume_total_height: "",
            rating_after_execute: false,
            enable_auto_class: false,
            require_pod_for_billing: false, // The setting on this particular BOL
            warehouse_require_pod_for_billing: false, // The warehouse setting that determines checkbox default
            company_require_pod_for_billing: false, // Company setting determines display
            enable_acc_recommendations: false,
            require_packing_group: true,
            ref_entry_type: "freeform", // whether the reference # input should be an input or a select
            ref_entry_opts: [],
            enable_product_part_no: false,
            prevent_exclusion_execution: false,
            dont_apply_addr_book_defaults: false,
            enable_executed_edit: false,
            trailerDims: {},
            companyNameId: null,

        };
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
		this.handleZplChecked = this.handleZplChecked.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleContactSearch = this.handleContactSearch.bind(this);
        this.handleContactSelect = this.handleContactSelect.bind(this);
        this.clearGeneralInfo = this.clearGeneralInfo.bind(this);
        this.clearAddress = this.clearAddress.bind(this);
        this.handleSwap = this.handleSwap.bind(this);
        this.handleAccessorials = this.handleAccessorials.bind(this);
        this.addUnit = this.addUnit.bind(this);
        this.removeUnit = this.removeUnit.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.toggleHazardous = this.toggleHazardous.bind(this);
        this.handleHazardous = this.handleHazardous.bind(this);
        this.handleDataType = this.handleDataType.bind(this);
        this.classifyProducts = this.classifyProducts.bind(this);
        this.updateTotals = this.updateTotals.bind(this);
        this.findCarriers = this.findCarriers.bind(this);
        this.buildPayload = this.buildPayload.bind(this);
        this.executeBol = this.executeBol.bind(this);
        this.getUnitTypes = this.getUnitTypes.bind(this);
        this.handleCustomUnitOfMeasure = this.handleCustomUnitOfMeasure.bind(this);
        this.getPro = this.getPro.bind(this);
        this.handleConversion = this.handleConversion.bind(this);
        this.handleSurfaceArea = this.handleSurfaceArea.bind(this);
        this.handleUnitSearch = this.handleUnitSearch.bind(this);
        this.handleUnitSelect = this.handleUnitSelect.bind(this);
        this.handleProductSearch = this.handleProductSearch.bind(this);
        this.handleProductSelect = this.handleProductSelect.bind(this);
        this.clearProduct = this.clearProduct.bind(this);
        this.addNote = this.addNote.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.saveAsNew = this.saveAsNew.bind(this);
        this.getTruckloadQuote = this.getTruckloadQuote.bind(this);
        this.submitToTruckload = this.submitToTruckload.bind(this);
        this.handleZip = this.handleZip.bind(this);
        this.handleLcc = this.handleLcc.bind(this);
        this.checkResidential = this.checkResidential.bind(this);
        this.thirdPartyRes = this.thirdPartyRes.bind(this);
        this.beginExecution = this.beginExecution.bind(this);
        this.checkForDuplicatesByReference = this.checkForDuplicatesByReference.bind(this);
        this.transitUpdate = this.transitUpdate.bind(this);
        this.handleDraft = this.handleDraft.bind(this);
        this.draftPdf = this.draftPdf.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.printBol = this.printBol.bind(this);
        this.printLabel = this.printLabel.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleCancelAll = this.handleCancelAll.bind(this);
        this.cancelDuplicate = this.cancelDuplicate.bind(this);
        this.handlePickupRequest = this.handlePickupRequest.bind(this);
        this.schedulePickup = this.schedulePickup.bind(this);
        this.handleDgd = this.handleDgd.bind(this);
        this.printDgd = this.printDgd.bind(this);
        this.getDgdUnits = this.getDgdUnits.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.cancelBol = this.cancelBol.bind(this);
        this.handleUncancel = this.handleUncancel.bind(this);
        this.uncancelBol = this.uncancelBol.bind(this);
        this.loadQuote = this.loadQuote.bind(this);
        this.loadDefaults = this.loadDefaults.bind(this);
        this.getDefaultPickupTimes = this.getDefaultPickupTimes.bind(this);
        this.setDefaultPickupTimes = this.setDefaultPickupTimes.bind(this);
        this.getDefaultUnit = this.getDefaultUnit.bind(this);
        this.setDefaultUnit = this.setDefaultUnit.bind(this);
        this.getDefaultProduct = this.getDefaultProduct.bind(this);
        this.setDefaultProduct = this.setDefaultProduct.bind(this);
        this.getUnits = this.getUnits.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.handleClassification = this.handleClassification.bind(this);
        this.handleDeclaredValue = this.handleDeclaredValue.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
        this.handleTlAuth = this.handleTlAuth.bind(this);
        this.tlAuthenticate = this.tlAuthenticate.bind(this);
        this.handleCreateLoad = this.handleCreateLoad.bind(this);
        this.loadBol = this.loadBol.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.saveProduct = this.saveProduct.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchMerge = this.handleSearchMerge.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.submitNonLcc = this.submitNonLcc.bind(this);
        this.getBols = this.getBols.bind(this);
        this.getBolsMerge = this.getBolsMerge.bind(this);
        this.mergeBolData = this.mergeBolData.bind(this);
        this.getShipmentTypes = this.getShipmentTypes.bind(this);
        this.getEquipmentTypes = this.getEquipmentTypes.bind(this);
        this.getServiceOptions = this.getServiceOptions.bind(this);
        this.getServices = this.getServices.bind(this);
        this.addService = this.addService.bind(this);
        this.handleService = this.handleService.bind(this);
        this.removeService = this.removeService.bind(this);
        this.createShipment = this.createShipment.bind(this);
        this.viewShipment = this.viewShipment.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleReference = this.handleReference.bind(this);
        this.handleLiability = this.handleLiability.bind(this);
        this.checkLiability = this.checkLiability.bind(this);
        this.requestExcess = this.requestExcess.bind(this);
        this.getLiabilityErrors = this.getLiabilityErrors.bind(this);
        this.handleReferenceType = this.handleReferenceType.bind(this);
        this.getRefTypes = this.getRefTypes.bind(this);
        this.openLocationCodeModal = this.openLocationCodeModal.bind(this);
        this.getShipperTime = this.getShipperTime.bind(this);
        this.handleCancelDispatch = this.handleCancelDispatch.bind(this);
        this.handleProduct = this.handleProduct.bind(this);
        this.handleUnit = this.handleUnit.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    async componentDidMount() {
        let load_quote_id = null;
        let bolId = "";
        let date = "";
        let alert = null;

		let { JSPM } = this.props;
		if(JSPM== null) {
			JSPM = window.JSPM;
			this.props.setJSPM(JSPM);
		}
        this.props.setAvailablePrinters(JSPM);

        const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        if (!_.isEmpty(parsed.quote)) {
            load_quote_id = parsed.quote;
        }

        if (!_.isEmpty(this.props.match.params.id)) {
            bolId = this.props.match.params.id;
        }

        if (!_.isEmpty(this.props.match.params.date)) {
            date = this.props.match.params.date;
        }

        let url = "/index.php?p=api&r=json&c=billoflading&m=newBOL";

        if (!_.isEmpty(bolId) && !_.isEmpty(date)) {
            url = "/index.php?p=api&r=json&c=billoflading&m=load&d=" + bolId + "/" + date;
            alert = (
                <SweetAlert
                    info
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="BoL is loading."
                    onConfirm={() => {}}
                    showConfirm={false}
                >
                    Please wait...
                    <br />
                    <div style={{ textAlign: "center", margin: 5 }}>
                        <CircularProgress />
                    </div>
                </SweetAlert>
            );
        }

        this.setState({
            mounted: true,
            alert
        });

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response)
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body) && this.state.mounted) {
                const data = response.data;
                let {
                    alert,
                    required,
                    data_type,
                    // entry info
                    saved_bol_num,
                    status,
                    level,
                    // history info
                    username,
                    date_created,
                    time_created,
                    // pickup info
                    confirmation_number,
                    transaction_id,
                    pickup_window_start,
                    pickup_window_end,
                    pickup_timestamp,
                    // general info
                    direction,
                    charge_customer,
                    pro_num,
                    bol_num,
                    custom_date,
                    original_execution_date,
                    po_num,
                    so_num,
                    ref_num,
                    blind_shipment,
                    // transit info
                    transit_status,
                    transit_sub_status,
                    late_pickup_reason,
                    late_delivery_reason,
                    pickup_date,
                    original_pd,
                    expected_delivery_date,
                    original_edd,
                    delivery_date,
                    delivery_time,
                    // shipper info
                    shipper_name,
                    shipper_address1,
                    shipper_address2,
                    shipper_zip,
                    shipper_city,
                    shipper_state,
                    shipper_country,
                    shipper_contact_name,
                    shipper_contact_phone,
                    shipper_contact_email,
                    shipper_contact_fax,
                    // blind shipper info
                    blind_shipper_name,
                    blind_shipper_address1,
                    blind_shipper_address2,
                    blind_shipper_zip,
                    blind_shipper_city,
                    blind_shipper_state,
                    blind_shipper_country,
                    blind_shipper_contact_name,
                    blind_shipper_contact_phone,
                    blind_shipper_contact_email,
                    blind_shipper_contact_fax,
                    // consignee information
                    consignee_name,
                    consignee_address1,
                    consignee_address2,
                    consignee_zip,
                    consignee_city,
                    consignee_state,
                    consignee_country,
                    consignee_contact_name,
                    consignee_contact_phone,
                    consignee_contact_email,
                    consignee_contact_fax,
                    // blind consignee information
                    blind_consignee_name,
                    blind_consignee_address1,
                    blind_consignee_address2,
                    blind_consignee_zip,
                    blind_consignee_city,
                    blind_consignee_state,
                    blind_consignee_country,
                    blind_consignee_contact_name,
                    blind_consignee_contact_phone,
                    blind_consignee_contact_email,
                    blind_consignee_contact_fax,
                    // billing information
                    billing_name,
                    billing_address1,
                    billing_address2,
                    billing_zip,
                    billing_city,
                    billing_state,
                    billing_country,
                    billing_contact_name,
                    billing_contact_phone,
                    billing_contact_email,
                    billing_contact_fax,
                    // blind billing information
                    blind_billing_name,
                    blind_billing_address1,
                    blind_billing_address2,
                    blind_billing_zip,
                    blind_billing_city,
                    blind_billing_state,
                    blind_billing_country,
                    blind_billing_contact_name,
                    blind_billing_contact_phone,
                    blind_billing_contact_email,
                    blind_billing_contact_fax,
                    //additional tracking emails,
                    additional_tracking_emails,
                    auto_tracking,
                    // custom piece count
                    custom_pallet_count,
                    custom_piece_count,
                    custom_loose_count,
                    custom_unit_master,
                    custom_unit_master_name,
                    custom_unit_master_id,
                    // product info
                    units,
                    // dangerous goods
                    dgd_type,
                    dgd_custom_shipper,
                    dgd_shipper_name,
                    dgd_shipper_address1,
                    dgd_shipper_address2,
                    dgd_shipper_zip,
                    dgd_shipper_city,
                    dgd_shipper_state,
                    dgd_shipper_country,
                    dgd_shipper_contact_name,
                    dgd_shipper_contact_phone,
                    dgd_shipper_contact_email,
                    dgd_shipper_contact_fax,
                    dgd_custom_consignee,
                    dgd_consignee_name,
                    dgd_consignee_address1,
                    dgd_consignee_address2,
                    dgd_consignee_zip,
                    dgd_consignee_city,
                    dgd_consignee_state,
                    dgd_consignee_country,
                    dgd_consignee_contact_name,
                    dgd_consignee_contact_phone,
                    dgd_consignee_contact_email,
                    dgd_consignee_contact_fax,
                    dgd_air_declarant,
                    dgd_air_place,
                    dgd_air_date,
                    dgd_air_departure_airport,
                    dgd_air_destination_airport,
                    dgd_air_cargo_only,
                    dgd_air_radioactive,
                    dgd_ocean_declarant,
                    dgd_ocean_place,
                    dgd_ocean_date,
                    dgd_ocean_trans_doc_number,
                    dgd_ocean_freight_forwarders_reference,
                    dgd_ocean_carrier,
                    dgd_ocean_vessel_flight_and_date,
                    dgd_ocean_port_place_handling,
                    dgd_ocean_ctu_number,
                    dgd_ocean_seal_number,
                    dgd_ocean_ctu_size_and_type,
                    dgd_ocean_tare_mass,
                    dgd_ocean_receiving_remarks,
                    dgd_ocean_company_name,
                    dgd_ocean_haulers_name,
                    dgd_ocean_preparing_company_name,
                    dgd_ocean_vehicle_registration_number,
                    dgd_ocean_driver_name_date,
                    // special info
                    cod_by,
                    cod_type,
                    cod_amount,
                    notes,
                    canned_note,
                    non_program_carrier,
                    non_program_rate,
                    pickup_request,
                    imageFiles,
                    showCarriers,
                    showVolumeCarriers,
                    carriers,
                    volume_carriers,
                    allowed_carriers,
                    scac,
                    query_id,
                    showQuote,
                    quote,
                    quoteId,
                    showExecute,
                    showSave,
                    showExecuteUpdate,
                    showTransitUpdate,
                    showGetPro,
                    showDraftPdf,
                    showPrint,
                    showPrintQuote,
                    showPrintLabel,
                    showPrintDgd,
                    showPrintPkList,
                    showSendAsn,
                    autoSendTracking,
                    trackingSent,
                    p44Url,
                    p44DispatchCanceled,
                    showCancel,
                    showUncancel,
                    showPickupRequest,
                    showSendToTl,
                    loadId,
                    volume_total_length,
                    volume_total_width,
                    volume_total_height,
                    references,
                    enable_auto_class,
                    allow_auto_pro,
                    display_custom_piece_count,
                    require_pod_for_billing,
                    require_packing_group,
                    warehouse_require_pod_for_billing,
                    company_require_pod_for_billing,
                    enable_acc_recommendations,
                    prevent_exclusion_execution,
                    tfmr_internal_id,
                    tl_equipment,
                    total_declared_value,
                    dont_apply_addr_book_defaults,
                    enable_executed_edit,
                    trailerDims,
                    companyNameId,
                } = this.state;
                // const liability = (!_.isEmpty(data.user) && data.user.user_company_settings.enable_liability == 1);

                // default warehouse
                let warehouse = { code: "" };
                if (!_.isEmpty(data.body.warehouses)) {
                    if (data.body.warehouses.length === 1) {
                        warehouse = data.body.warehouses[0];
                    } else {
                        warehouse = data.body.warehouses.filter(warehouse => warehouse.is_default == "1")[0];
                        if (warehouse === undefined) {
                            warehouse = {
                                code: "",
                                po_num: "",
                                so_num: "",
                                ref_num: "",
                            };
                        }
                    }
                }

                // user settings
                const user_settings = data.user.user_settings;
                dont_apply_addr_book_defaults = user_settings.dont_apply_addr_book_defaults == 1;

                // company setting needs turned on for warehouse default to take effect
                company_require_pod_for_billing = data.company.require_pod_for_billing == 1 ? true : false;
                warehouse_require_pod_for_billing = warehouse.require_pod_for_billing == 1 && company_require_pod_for_billing ? true : false;
                enable_acc_recommendations = data.company.enable_acc_recommendations == 1 ? true : false;

                // default product class
                if (data.body.default_class) {
                    units[0].products[0].class = data.body.default_class;
                }

                prevent_exclusion_execution = warehouse.prevent_exclusion_execution == 1 ? true : false;

                // default notes
                if (!_.isEmpty(data.body.notes)) {
                    const defaultNotes = data.body.notes.filter(note => note.default == 1);
                    for (const note of defaultNotes) {
                        notes += (!_.isEmpty(note.note)) ? note.note + "\n" : "";
                    }
                }

                const { extra_required_fields } = response.data.body;

                if (!_.isEmpty(extra_required_fields)) {
                    if (extra_required_fields.require_pro == 1) {
                        required.general_info.pro_num = "PRO #";
                    }
                    if (extra_required_fields.require_po == 1) {
                        required.general_info.po_num = "PO #";
                    }
                    if (extra_required_fields.require_so == 1) {
                        required.general_info.so_num = "SO #";
                    }
                    if (extra_required_fields.require_ref == 1) {
                        required.general_info.ref_num = "REF #";
                    }
                }

                let accessorials = (!_.isEmpty(data.body.accessorials.accessorials)) ? this.handleAccessorials(data.body.accessorials.accessorials) : [];
                let recommended_accessorials = [];
                let recommended_accs_id = null;
                if(!_.isEmpty(data.body.recommended_accessorials)) {
                    recommended_accs_id = data.body.recommended_accessorials.id;
                    recommended_accessorials = this.handleRecommendedAccessorials(data.body.recommended_accessorials.accessorials);
                }
                let location_types = [];
                if(!_.isEmpty(data.body.locationTypes)) {
                    location_types =data.body.locationTypes;
                }

                // load BoL
                if (!_.isEmpty(bolId) && !_.isEmpty(date)) {
                    let { general, location, blind_location, special, dgd, carriers, distance } = data.body;

                    //make sure carriers is an array because the rest of this function assumes it is
                    if (!Array.isArray(carriers)) carriers = [];

                    const saved_units = data.body.units;

                    // entry info
                    saved_bol_num = general.date + general.id || "";
                    status = general.status || "";
                    level = general.bol_level_id || null,

                    // history info
                    username = general.username || "";
                    date_created = !_.isEmpty(general.date) ? moment(general.date).format("L") : "";
                    time_created = !_.isEmpty(general.create_time) ? moment(general.create_time).format("h:mm A") : "";

                    // pickup info
                    confirmation_number = general.pickup_confirmation_number || "";
                    transaction_id = general.pickup_transaction_number || "";
                    pickup_timestamp = general.pickup_timestamp || "";
                    pickup_window_start = general.pickup_window_start || "";
                    pickup_window_end = general.pickup_window_end || "";

                    warehouse = (data.body.warehouses.filter(warehouse => warehouse.code == general.warehouse_code)[0] === undefined)
                    	? { code: "" }
                    	: data.body.warehouses.filter(warehouse => warehouse.code == general.warehouse_code)[0] ;
                    // transit info
                    transit_status = general.transit_status || "";
                    transit_sub_status = general.transit_sub_status || "";
                    late_pickup_reason = general.late_pickup_reason || "";
                    late_delivery_reason = general.late_delivery_reason || "";
                    pickup_date = general.pickup_date || "";
                    original_pd = general.original_pd || "";
                    expected_delivery_date = general.expected_delivery_date || "";
                    original_edd = general.original_edd || "";
                    delivery_date = general.delivery_date || "";
                    delivery_time = general.delivery_time || "";

                    // general info
                    direction = general.direction || "";
                    charge_customer = general.charge_customer || 0;
                    pro_num = general.pro || "";
                    bol_num = general.custom_id || "";
                    custom_date = general.user_defined_date || "";
                    original_execution_date = general.original_execution_date || "";
                    po_num = general.po || "";
                    so_num = general.so || "";
                    ref_num = general.ref || "";
                    companyNameId = general.company_name_id || null;

                    blind_shipment = general.blind == 1;

                    // shipper info
                    shipper_name = location.shipper.name || "";
                    shipper_address1 = location.shipper.address1 || "";
                    shipper_address2 = location.shipper.address2 || "";
                    shipper_zip = location.shipper.zip || "";
                    shipper_city = location.shipper.city || "";
                    shipper_state = location.shipper.state || "";
                    shipper_country = location.shipper.country || "US";
                    shipper_contact_name = location.shipper.contact_name || "";
                    shipper_contact_phone = location.shipper.contact_phone || "";
                    shipper_contact_email = location.shipper.contact_email || "";
                    shipper_contact_fax = location.shipper.contact_fax || "";

                    // consignee information
                    consignee_name = location.consignee.name || "";
                    consignee_address1 = location.consignee.address1 || "";
                    consignee_address2 = location.consignee.address2 || "";
                    consignee_zip = location.consignee.zip || "";
                    consignee_city = location.consignee.city || "";
                    consignee_state = location.consignee.state || "";
                    consignee_country = location.consignee.country || "US";
                    consignee_contact_name = location.consignee.contact_name || "";
                    consignee_contact_phone = location.consignee.contact_phone || "";
                    consignee_contact_email = location.consignee.contact_email || "";
                    consignee_contact_fax = location.consignee.contact_fax || "";
                    additional_tracking_emails = location.consignee.additional_tracking_emails || [];

                    // billing information
                    billing_name = location.billing.name || "";
                    billing_address1 = location.billing.address1 || "";
                    billing_address2 = location.billing.address2 || "";
                    billing_zip = location.billing.zip || "";
                    billing_city = location.billing.city || "";
                    billing_state = location.billing.state || "";
                    billing_country = location.billing.country || "US";
                    billing_contact_name = location.billing.contact_name || "";
                    billing_contact_phone = location.billing.contact_phone || "";
                    billing_contact_email = location.billing.contact_email || "";
                    billing_contact_fax = location.billing.contact_fax || "";

                    // blind shipper info
                    blind_shipper_name = blind_location.shipper.name || "";
                    blind_shipper_address1 = blind_location.shipper.address1 || "";
                    blind_shipper_address2 = blind_location.shipper.address2 || "";
                    blind_shipper_zip = blind_location.shipper.zip || "";
                    blind_shipper_city = blind_location.shipper.city || "";
                    blind_shipper_state = blind_location.shipper.state || "";
                    blind_shipper_country = blind_location.shipper.country || "US";
                    blind_shipper_contact_name = blind_location.shipper.contact_name || "";
                    blind_shipper_contact_phone = blind_location.shipper.contact_phone || "";
                    blind_shipper_contact_email = blind_location.shipper.contact_email || "";
                    blind_shipper_contact_fax = blind_location.shipper.contact_fax || "";

                    // blind consignee information
                    blind_consignee_name = blind_location.consignee.name || "";
                    blind_consignee_address1 = blind_location.consignee.address1 || "";
                    blind_consignee_address2 = blind_location.consignee.address2 || "";
                    blind_consignee_zip = blind_location.consignee.zip || "";
                    blind_consignee_city = blind_location.consignee.city || "";
                    blind_consignee_state = blind_location.consignee.state || "";
                    blind_consignee_country = blind_location.consignee.country || "US";
                    blind_consignee_contact_name = blind_location.consignee.contact_name || "";
                    blind_consignee_contact_phone = blind_location.consignee.contact_phone || "";
                    blind_consignee_contact_email = blind_location.consignee.contact_email || "";
                    blind_consignee_contact_fax = blind_location.consignee.contact_fax || "";

                    // blind billing information
                    blind_billing_name = blind_location.billing.name || "";
                    blind_billing_address1 = blind_location.billing.address1 || "";
                    blind_billing_address2 = blind_location.billing.address2 || "";
                    blind_billing_zip = blind_location.billing.zip || "";
                    blind_billing_city = blind_location.billing.city || "";
                    blind_billing_state = blind_location.billing.state || "";
                    blind_billing_country = blind_location.billing.country || "US";
                    blind_billing_contact_name = blind_location.billing.contact_name || "";
                    blind_billing_contact_phone = blind_location.billing.contact_phone || "";
                    blind_billing_contact_email = blind_location.billing.contact_email || "";
                    blind_billing_contact_fax = blind_location.billing.contact_fax || "";

                    if(response.data.body.location.hasOwnProperty("tracking") && !_.isEmpty(response.data.body.location.tracking)) {
                        let trackingEmails = response.data.body.location.tracking.map((prop, key) => {
                            if(prop.contact_email != "") {
                                return prop.contact_email;
                            }
                        });
                        if(!_.isEmpty(trackingEmails)) {
                            additional_tracking_emails = trackingEmails;
                        }
                    }

                    // Auto-class
                    enable_auto_class = data.body.enable_auto_class;

                    // Enable/show the Auto-PRO button
                    allow_auto_pro = data.body.allow_auto_pro;

                    //Enable/show custom piece count
                    display_custom_piece_count = data.body.display_custom_piece_count == 1 ? true : false;

                    // Require POD for billing
                    require_pod_for_billing = general.require_pod_for_billing == 1 ? true : false;

                    // dynamic (custom) references
                    references = general.references;

                    // highlight custom references required by warehouse
                    let requiredWarehouseRefTypes = [];

                    if(!_.isEmpty(warehouse) && warehouse.hasOwnProperty('warehouse_custom_references')) {
                        warehouse.warehouse_custom_references.forEach(ref => {
                            if(ref.required == "1") {
                                requiredWarehouseRefTypes.push(ref.name);
                            }
                        });

                        references.forEach((reference, index) => {
                            if(requiredWarehouseRefTypes.includes(reference.type)) {
                                reference.required = "1";
                            } else {
                                reference.required = "0";
                            }
                            references[index] = reference;
                        });

                        let requiredWarehouseRefs =  this.handleWarehouseReferences(warehouse);
                        requiredWarehouseRefs.forEach((whRef) => {
                            if(!references.some(ref => ref.type == whRef.type ) && whRef.required == '1' && status == 'hold') {
                                references.push(whRef)
                            }
                        })
                    }

                    if(!_.isEmpty(data.body.trailerDims)) {
                        trailerDims = data.body.trailerDims;
                    }

                    if (!_.isEmpty(saved_units)) {
                        units = [];

                        for (const savedUnit of saved_units) {
                            // convert everything to feet
                            const length = parseFloat(savedUnit.details.length) / 12;
                            const width = parseFloat(savedUnit.details.width) / 12;
                            const height = parseFloat(savedUnit.details.height) / 12;
                            const weight = parseFloat(savedUnit.details.weight);

                            // do the math for this unit
                            const cube = length * width * height;
                            const pcf = weight / cube;

                            const unit = {
                                type: savedUnit.details.type,
                                pieces: savedUnit.details.pieces,
                                stackable: savedUnit.details.stack == 1,
                                length: savedUnit.details.length,
                                width: savedUnit.details.width,
                                height: savedUnit.details.height,
                                density: pcf.toFixed(2),
                                cube: cube.toFixed(2),
                                products: []
                            };

                            if (!_.isEmpty(savedUnit.products)) {
                                for (const unitProduct of savedUnit.products) {
                                    var productDescription = "Commodity";

                                    if (!_.isEmpty(_.trim(unitProduct.name))) {
                                        productDescription = _.trim(unitProduct.name);
                                    }

                                    const product = {
                                        nmfc: unitProduct.nmfc,
                                        sub_nmfc: unitProduct.sub_nmfc,
                                        class: unitProduct.class,
                                        description: productDescription,
                                        uom: unitProduct.uom,
                                        pieces: unitProduct.pieces,
                                        weight: unitProduct.weight,
                                        net_weight: unitProduct.net_weight || "",
                                        condition: unitProduct.condition || "",
                                        packaging: unitProduct.packaging || "",
                                        declared_value: unitProduct.declared_value || "",
                                        part_no: unitProduct.part_no || "",
                                        hazardous: !_.isEmpty(unitProduct.hazmat.class),
                                        auto_weight: false,
                                        hazmat: {
                                            class: unitProduct.hazmat.class,
                                            un: unitProduct.hazmat.un_num,
                                            packingGroup: unitProduct.hazmat.group,
                                            emergencyNumber: unitProduct.hazmat.emergency,
                                            packingInstructions: unitProduct.hazmat.packing_inst,
                                            overpackUsed: !!unitProduct.overpack_used
                                        }
                                    };

                                    unit.products.push(product);
                                }
                            }

                            units.push(unit);
                        }
                    }

                    if(display_custom_piece_count) {
                        custom_pallet_count = general.custom_pallet_count || "";
                        custom_piece_count = general.custom_piece_count || "";
                        custom_loose_count = general.custom_loose_count || "";
                    }

                    if (general.custom_unit_master_id) {

                        custom_unit_master_id = general.custom_unit_master_id;

                        data.body.custom_unit_master.forEach($uom => {

                            if ($uom['id'] === custom_unit_master_id ) {

                                custom_unit_master_name = $uom['name'];

                            }

                        });

                    }

                    if (!_.isEmpty(data.body.accs) && !_.isEmpty(accessorials)) {
                        const accs = Object.keys(data.body.accs);
                        accessorials = accessorials.map((prop, key) => {
                            if (accs.indexOf(prop.id) > -1) {
                                prop.checked = true;
                            }
                            return prop;
                        });
                    }

                    if (!_.isEmpty(dgd)) {
                        dgd_type = dgd.type || "";
                        dgd_custom_shipper = !!(parseInt(dgd.use_custom_shipper));
                        dgd_custom_consignee = !!(parseInt(dgd.use_custom_consignee));

                        if (!_.isEmpty(dgd.location)) {
                            // shipper info
                            dgd_shipper_name = dgd.location.shipper.name || "";
                            dgd_shipper_address1 = dgd.location.shipper.address1 || "";
                            dgd_shipper_address2 = dgd.location.shipper.address2 || "";
                            dgd_shipper_zip = dgd.location.shipper.zip || "";
                            dgd_shipper_city = dgd.location.shipper.city || "";
                            dgd_shipper_state = dgd.location.shipper.state || "";
                            dgd_shipper_country = dgd.location.shipper.country || "US";
                            dgd_shipper_contact_name = dgd.location.shipper.contact_name || "";
                            dgd_shipper_contact_phone = dgd.location.shipper.contact_phone || "";
                            dgd_shipper_contact_email = dgd.location.shipper.contact_email || "";
                            dgd_shipper_contact_fax = dgd.location.shipper.contact_fax || "";

                            // consignee information
                            dgd_consignee_name = dgd.location.consignee.name || "";
                            dgd_consignee_address1 = dgd.location.consignee.address1 || "";
                            dgd_consignee_address2 = dgd.location.consignee.address2 || "";
                            dgd_consignee_zip = dgd.location.consignee.zip || "";
                            dgd_consignee_city = dgd.location.consignee.city || "";
                            dgd_consignee_state = dgd.location.consignee.state || "";
                            dgd_consignee_country = dgd.location.consignee.country || "US";
                            dgd_consignee_contact_name = dgd.location.consignee.contact_name || "";
                            dgd_consignee_contact_phone = dgd.location.consignee.contact_phone || "";
                            dgd_consignee_contact_email = dgd.location.consignee.contact_email || "";
                            dgd_consignee_contact_fax = dgd.location.consignee.contact_fax || "";
                        }

                        if (dgd_type == "ocean") {
                            dgd_ocean_declarant = dgd.declarant || "";
                            dgd_ocean_place = dgd.place || "";
                            dgd_ocean_date = dgd.date || "";
                            dgd_ocean_trans_doc_number = dgd.trans_doc_number || "";
                            dgd_ocean_freight_forwarders_reference = dgd.freight_forwards_reference || "";
                            dgd_ocean_carrier = dgd.carrier || "";
                            dgd_ocean_vessel_flight_and_date = dgd.vessel_flight_and_date || "";
                            dgd_ocean_port_place_handling = dgd.port_place_handling || "";
                            dgd_ocean_ctu_number = dgd.ctu_number || "";
                            dgd_ocean_seal_number = dgd.seal_number || "";
                            dgd_ocean_ctu_size_and_type = dgd.ctu_size_and_type || "";
                            dgd_ocean_tare_mass = dgd.tare_mass || "";
                            dgd_ocean_receiving_remarks = dgd.receiving_remarks || "";
                            dgd_ocean_company_name = dgd.company_name || data.user.user_company_settings.company_name || "";
                            dgd_ocean_haulers_name = dgd.hauler_name || "";
                            dgd_ocean_preparing_company_name = dgd.preparing_company_name || data.user.user_company_settings.company_name || "";
                            dgd_ocean_vehicle_registration_number = dgd.vehicle_registration_number || "";
                            dgd_ocean_driver_name_date = dgd.driver_name_date || "";
                        }

                        if (dgd_type == "air") {
                            dgd_air_declarant = dgd.declarant || "";
                            dgd_air_place = dgd.place || "";
                            dgd_air_date = dgd.date || "";
                            dgd_air_departure_airport = dgd.departure_airport || "";
                            dgd_air_destination_airport = dgd.destination_airport || "";
                            dgd_air_cargo_only = dgd.cargo_only == 1;
                            dgd_air_radioactive = dgd.radioactive == 1;
                        }
                    }

                    cod_by = special.cod_by;
                    cod_type = special.cod_type;
                    cod_amount = special.cod_amount;
                    if (!_.isEmpty(special.notes)) {
                        notes = special.notes;
                    }
                    // files = special;

                    if (!_.isEmpty(data.user) && this.props.hasVolumeQuotePermission && general.volume == 1) {
                        data_type = "volume";
                        volume_total_length = general.shipmentTotalLength;
                        volume_total_width = general.shipmentTotalWidth || "";
                        volume_total_height = general.shipmentTotalHeight || "";
                        volume_carriers = !_.isEmpty(data.body.carriers) ? data.body.carriers : [];
                    }

                    if (general.non_program == 1) {
                        data_type = "manual";
                        if (!_.isEmpty(general.carrier_scac) && general.carrier_scac !== "0001") {

                            let isScacInList = false;
                            data.body.manual_carriers.forEach(carrier => {
                                if(carrier.scac === general.carrier_scac) {
                                    isScacInList = true;
                                }
                            })

                            if(!isScacInList) {
                                non_program_carrier = 'TFMT';
                            } else {
                                non_program_carrier = general.carrier_scac;
                            }

                        }
                        if (!_.isEmpty(general.non_program_rate)) {
                            non_program_rate = general.non_program_rate;
                        }
                        if(!_.isEmpty(general.ttl_customer_rated) && !_.isEmpty(general.ttl_load_id)) {
                            non_program_rate = general.ttl_customer_rated;
                        }
                    }

                    if (!_.isEmpty(general.carrier_scac) && general.carrier_scac !== "0001") {
                        scac = general.carrier_scac;
                    }

                    if (!_.isEmpty(data.user) && this.props.hasSubmitPickupPermission) {
                        if (!_.isEmpty(scac) && !_.isEmpty(carriers)) {
                            const carrier = carriers.filter(carrier => carrier.scac == scac)[0];
                            if (typeof carrier !== 'undefined' && (carrier.api_pickup_enabled || carrier.email_pickup_enabled)) {
                                showPickupRequest = true;
                            }
                        }
                    }
                    if (!_.isEmpty(general.ctsi_query_id) && general.ctsi_query_id != 0) {
                        query_id = general.ctsi_query_id;
                    }

                    if (!_.isEmpty(general.total_declared_value) && general.total_declared_value != 0) {
                        total_declared_value = general.total_declared_value;
                    }

                    if (!_.isEmpty(data.body.carriers)) {
                        if(general.volume != 1) {
                            allowed_carriers = data.body.allowed_carriers || [];
                            // If a bol was executed via the API with a selected SCAC that is currently restricted, add SCAC to allowed carriers.
                            if (status !== "hold" && !_.isEmpty(scac) && !allowed_carriers.includes(scac)) {
                                allowed_carriers.push(scac)
                            }
                            carriers = this.filterRestrictedCarriers(data.body.carriers, allowed_carriers);
                            showCarriers = true;

                            volume_carriers = [];
                            showVolumeCarriers = false;
                        } else {
                            carriers = [];

                            showVolumeCarriers = true;
                            showCarriers = false;
                        }
                    }

                    if (!_.isEmpty(data.body.tl_equipment) && data.body.enable_tl_quote == true) {
                        tl_equipment = data.body.tl_equipment;
                    }

                    if (general.truckload == 1 && !general.ttl_load_id) {
                        showQuote = true;
                        showCarriers = false;
                        showVolumeCarriers = false;
                        data_type = "truckload";
                        quote = general.truckstop_quote;
                        quoteId = general.truckstop_quote_id;
                    }

                    if (this.props.hasTlIntegrationPermission) {
                        quoteId = !_.isEmpty(general.truckstop_quote_id) ? general.truckstop_quote_id : quoteId;
                        loadId = !_.isEmpty(general.ttl_load_id) ? general.ttl_load_id : loadId;
                        if (status == "hold" || status == "canceled") {
                            showSendToTl = false;
                        } else {
                            if (_.isEmpty(quoteId) || _.isEmpty(loadId)) {
                                showSendToTl = true;
                            } else {
                                showSendToTl = false;
                            }
                        }
                    }

                    if(status == 'hold') {

                        showDraftPdf = true;

                        if(!_.isEmpty(scac) && !_.isEmpty(carriers)) {
                            showExecute = true;
                        }

                    }

                    if (data.user.user_company_settings.enable_dgd == 1 && !_.isEmpty(dgd_type)) {
                        showPrintDgd = true;
                    }

                    if(general.auto_track_when_executed == 1) {
                        auto_tracking = true;
                    } else {
                        auto_tracking = false;
                    }

                    if(general.autosend_tracking == 1) {
                        autoSendTracking = true;
                    } else {
                        autoSendTracking = false;
                    }

                    if(general.tracking_sent == 1) {
                        trackingSent = true;
                    } else {
                        trackingSent = false;
                    }

                    p44Url = general.p44_url;

                    if (!_.isEmpty(general.p44_dispatch_canceled) && general.p44_dispatch_canceled == 1) {
                        p44DispatchCanceled = true;
                    }

                    if (status == "canceled") {
                        showUncancel = true;
                    }

                    if (status !== 'hold' && status !== 'canceled') {
                        showCancel = true;
                        showDraftPdf = false;
                        showSave = false;
                        showSendAsn = true;
                        showPrint = true;
                        showPrintLabel = true;
                        showPrintPkList = true;
                        showExecuteUpdate = true;
                        showTransitUpdate = true;
                        showGetPro = true;
                        if(status !== 'scheduled') {
                        	const carrier = carriers.filter(carrier => carrier.scac == scac)[0];
                            if (!_.isEmpty(data.user) && this.props.hasSubmitPickupPermission && typeof carrier !== 'undefined') {
                                if (carrier.api_pickup_enabled || carrier.email_pickup_enabled) {
                                    showPickupRequest = true;
                                }
                            }
                        }
                        if(!_.isEmpty(general.ctsi_query_id) && general.ctsi_query_id != 0 && !_.isEmpty(general.carrier_scac)) {
                            showPrintQuote = true;
                        }
                    } else {
                        showPickupRequest = false;
                    }

                    if(status != 'hold') {
                        showExecute = false;
                    }

                    enable_executed_edit = general.enable_executed_edit == 1 ? true : false;
                    if (_.isEmpty(data.user.parent_id) && data.user.level == "admin") {
                        showSave = false;
                        showSendAsn = false;
                        showExecute = false;
                        showGetPro = false;
                        showDraftPdf = false;
                        showPrint = true;
                        showPrintLabel = true;
                        showPrintPkList = true;
                        showExecuteUpdate = false;
                        showTransitUpdate = false;
                        showPickupRequest = false;
                        showCancel = false;
                        showUncancel = false;
                        showPrintDgd = false;
                        showSendToTl = false;
                        enable_executed_edit = false;
                        alert = (
                            <SweetAlert
                                warning
                                style={{
                                    display: "block",
                                    color: "black"
                                }}
                                title="Admin BoL View"
                                onConfirm={() => this.setState({ alert: null })}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                                showConfirm
                            >
                                <p style={{ color: "#a74240" }}><strong>DO NOT USE THIS PAGE FOR AUDITING OR VERIFICATION PURPOSES!</strong></p>
                                <p style={{ textAlign: "left" }}>This purpose of this page is to provide admins with a quick way of referencing a BoL to get basic information. <strong>If you are looking to audit or review the customer's rate for this BoL, leave this page, and log in as the <NavLink to={basePath + "/admin/management/users"}>customer</NavLink>.</strong> This page will not be maintained or enhanced under most circumstances.</p>
                            </SweetAlert>
                        );
                    } else {
                        alert = null;
                    }
                    if(!_.isEmpty(date) && !_.isEmpty(bolId)) {
                        this.props.pageTitle('Bill of Lading - ' + date + bolId);
                    }

                    let tfmr_internal_id = (typeof data.body.tfmr_internal_id !== 'undefined') ? +data.body.tfmr_internal_id : null;

                    flushSync(() => {
                        this.setState({
                            show: true,
                            loading: false,
                            user: data.user,
                            body: data.body,
                            company: data.company || null,
                            warehouse: warehouse.code || "",
                            accessorials,
                            recommended_accs_id,
                            location_types,
                            recommended_accessorials,
                            units,
                            notes,
                            required,
                            load_quote_id,
                            direction,
                            charge_customer,
                            saved_bol_num,
                            status,
                            level,
                            username,
                            date_created,
                            time_created,
                            confirmation_number,
                            transaction_id,
                            pickup_window_start,
                            pickup_window_end,
                            pickup_timestamp,
                            transit_status,
                            transit_sub_status,
                            late_pickup_reason,
                            late_delivery_reason,
                            pickup_date,
                            original_pd,
                            expected_delivery_date,
                            original_edd,
                            delivery_date,
                            delivery_time,
                            pro_num,
                            bol_num,
                            custom_date,
                            original_execution_date,
                            po_num,
                            so_num,
                            ref_num,
                            blind_shipment,
                            shipper_name,
                            shipper_address1,
                            shipper_address2,
                            shipper_zip,
                            shipper_city,
                            shipper_state,
                            shipper_country,
                            shipper_contact_name,
                            shipper_contact_phone,
                            shipper_contact_email,
                            shipper_contact_fax,
                            consignee_name,
                            consignee_address1,
                            consignee_address2,
                            consignee_zip,
                            consignee_city,
                            consignee_state,
                            consignee_country,
                            consignee_contact_name,
                            consignee_contact_phone,
                            consignee_contact_email,
                            consignee_contact_fax,
                            additional_tracking_emails,
                            auto_tracking,
                            billing_name,
                            billing_address1,
                            billing_address2,
                            billing_zip,
                            billing_city,
                            billing_state,
                            billing_country,
                            billing_contact_name,
                            billing_contact_phone,
                            billing_contact_email,
                            billing_contact_fax,
                            blind_shipper_name,
                            blind_shipper_address1,
                            blind_shipper_address2,
                            blind_shipper_zip,
                            blind_shipper_city,
                            blind_shipper_state,
                            blind_shipper_country,
                            blind_shipper_contact_name,
                            blind_shipper_contact_phone,
                            blind_shipper_contact_email,
                            blind_shipper_contact_fax,
                            blind_consignee_name,
                            blind_consignee_address1,
                            blind_consignee_address2,
                            blind_consignee_zip,
                            blind_consignee_city,
                            blind_consignee_state,
                            blind_consignee_country,
                            blind_consignee_contact_name,
                            blind_consignee_contact_phone,
                            blind_consignee_contact_email,
                            blind_consignee_contact_fax,
                            blind_billing_name,
                            blind_billing_address1,
                            blind_billing_address2,
                            blind_billing_zip,
                            blind_billing_city,
                            blind_billing_state,
                            blind_billing_country,
                            blind_billing_contact_name,
                            blind_billing_contact_phone,
                            blind_billing_contact_email,
                            blind_billing_contact_fax,
                            additional_tracking_emails,
                            custom_pallet_count,
                            custom_piece_count,
                            custom_loose_count,
                            custom_unit_master,
                            custom_unit_master_name,
                            custom_unit_master_id,
                            dgd_type,
                            dgd_custom_shipper,
                            dgd_custom_consignee,
                            dgd_shipper_name,
                            dgd_shipper_address1,
                            dgd_shipper_address2,
                            dgd_shipper_zip,
                            dgd_shipper_city,
                            dgd_shipper_state,
                            dgd_shipper_country,
                            dgd_shipper_contact_name,
                            dgd_shipper_contact_phone,
                            dgd_shipper_contact_email,
                            dgd_shipper_contact_fax,
                            dgd_consignee_name,
                            dgd_consignee_address1,
                            dgd_consignee_address2,
                            dgd_consignee_zip,
                            dgd_consignee_city,
                            dgd_consignee_state,
                            dgd_consignee_country,
                            dgd_consignee_contact_name,
                            dgd_consignee_contact_phone,
                            dgd_consignee_contact_email,
                            dgd_consignee_contact_fax,
                            dgd_ocean_declarant,
                            dgd_ocean_place,
                            dgd_ocean_date,
                            dgd_ocean_trans_doc_number,
                            dgd_ocean_freight_forwarders_reference,
                            dgd_ocean_carrier,
                            dgd_ocean_vessel_flight_and_date,
                            dgd_ocean_port_place_handling,
                            dgd_ocean_ctu_number,
                            dgd_ocean_seal_number,
                            dgd_ocean_ctu_size_and_type,
                            dgd_ocean_tare_mass,
                            dgd_ocean_receiving_remarks,
                            dgd_ocean_company_name,
                            dgd_ocean_haulers_name,
                            dgd_ocean_preparing_company_name,
                            dgd_ocean_vehicle_registration_number,
                            dgd_ocean_driver_name_date,
                            dgd_air_declarant,
                            dgd_air_place,
                            dgd_air_date,
                            dgd_air_departure_airport,
                            dgd_air_destination_airport,
                            dgd_air_cargo_only,
                            dgd_air_radioactive,
                            cod_by,
                            cod_type,
                            cod_amount,
                            imageFiles,
                            scac,
                            showCarriers,
                            showVolumeCarriers,
                            data_type,
                            carriers,
                            volume_carriers,
                            allowed_carriers,
                            query_id,
                            tfmr_internal_id,
                            distance,
                            showQuote,
                            quote,
                            quoteId,
                            showExecute,
                            showSave,
                            showExecuteUpdate,
                            showTransitUpdate,
                            showGetPro,
                            showDraftPdf,
                            showPrint,
                            showPrintQuote,
                            showPrintLabel,
                            showPrintDgd,
                            showPrintPkList,
                            showSendAsn,
                            autoSendTracking,
                            trackingSent,
                            p44Url,
                            p44DispatchCanceled,
                            showCancel,
                            showUncancel,
                            showPickupRequest,
                            showSendToTl,
                            loadId,
                            bolId,
                            date,
                            volume_total_length,
                            volume_total_width,
                            volume_total_height,
                            alert,
                            references,
                            non_program_carrier,
                            non_program_rate,
                            enable_auto_class,
                            allow_auto_pro,
                            display_custom_piece_count,
                            require_pod_for_billing,
                            warehouse_require_pod_for_billing,
                            company_require_pod_for_billing,
                            enable_acc_recommendations,
                            require_packing_group,
                            tl_equipment,
                            total_declared_value,
                            enable_executed_edit,
                            trailerDims,
                            companyNameId
                        });
                    });
                    this.updateTotals(true);
                    this.checkLiability();
                } else {
                    if (!_.isEmpty(accessorials)) {
                        accessorials = accessorials.map((prop, key) => {
                            if (prop.always_checked) {
                                prop.checked = true;
                            }
                            return prop;
                        });
                    }

                    references = this.handleWarehouseReferences(warehouse);

                    if(!_.isEmpty(data.company.trailerDims)) {
                        trailerDims = data.company.trailerDims;
                    }

                    // If both settings are on, default the BOL flag to true
                    require_pod_for_billing = company_require_pod_for_billing && warehouse_require_pod_for_billing ? true : false;
                    flushSync(() => {
                        this.setState({
                            show: true,
                            loading: false,
                            user: data.user,
                            body: data.body,
                            company: data.company || null,
                            warehouse: warehouse.code || "",
                            accessorials,
                            location_types,
                            recommended_accessorials,
                            units,
                            notes,
                            required,
                            load_quote_id,
                            direction,
                            alert: null,
                            references,
                            warehouse_require_pod_for_billing,
                            company_require_pod_for_billing,
                            require_pod_for_billing,
                            require_packing_group,
                            dont_apply_addr_book_defaults,
                            po_num: warehouse.po_number,
                            so_num: warehouse.so_number,
                            ref_num: warehouse.ref_number,
                            trailerDims,
                            companyNameId: data.user.user_company_settings.company_id || null,
                            enable_acc_recommendations,
                        });
                    });
                }

                this.getDefaultPickupTimes();
                if (!_.isEmpty(load_quote_id)) {
                    this.loadQuote();
                } else if (!_.isEmpty(warehouse.code)) {
                    if(_.isEmpty(saved_bol_num)) {
                        this.loadDefaults();
                        if (this.props.hasHandlingUnitRead) {
                            this.getDefaultUnit();
                        }
                        if (this.props.hasProductRead) {
                            this.getDefaultProduct();
                        }
                        this.updateTotals(true);
                        this.checkLiability();
                    } else {
                        this.loadDefaults(false, {
                            terms: false,
                        });
                    }
                }

                if (!_.isEmpty(saved_bol_num)) {
                    this.loadSavedImages();
                }

                if (this.props.hasBolLevelPermission) {
                    this.props.loadLevelsIfEmpty();
                }
            } else {
                this.setState({
                    loading: false,
                    alert: null
                });
                this.props.setNotification("There was an error loading the data!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({
                loading: false,
                alert: null
            });
            this.props.setNotification("There was an exception loading the data!", { variant: "error" });
        }
    }

    toggleMenu(event, name) {
        let value = null;
        if(event != false) {
            if(event && event.currentTarget) {
                value = event.currentTarget
            }
        }
        switch (name) {
            case "showPrintMenu":
                this.setState({
                    showPrintMenu: value,
                });
                break;
            case "showSaveMenu":
                this.setState({
                    showSaveMenu: value,
                });
                break;
        }
    }

    handleReferenceType(type) {
        this.setState({reference_type: type});
    }

    handleWarehouseReferences(warehouse) {

        let references = [];

        if(warehouse.hasOwnProperty('warehouse_custom_references') && !_.isEmpty(warehouse.warehouse_custom_references)) {

            warehouse.warehouse_custom_references.forEach(reference => {

                references.push({
                    'type': reference.name,
                    'value': reference.default_value,
                    'required': reference.required,
                    'cust_ref_entry_type': reference.cust_ref_entry_type,
                    'cust_ref_entry_opts': reference.options,
                });

            });

            // Sort to put any required warehouse references at the top
            references.sort(function(a,b){
                if(a.required == 1 && b.required == 0) {
                    return -1;
                }
                if(a.required == 0 && b.required == 1) {
                    return 1;
                }
                return 0;
            });

        }

        return references;

    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    handleModalOpen(modal) {
        this.setState({ [modal]: true });
    }

    handleModalClose(modal) {
        this.setState({ [modal]: false });
    }

	handleZplChecked = (name, event) => {
		this.setState({ [name]: event.target.checked });
	}

    handleChecked = name => event => {
        this.setState({ [name]: !!event.target.checked });

        if(['shipper_save','consignee_save','billing_save'].includes(name)) {
            if(!this.state[name]) {
                this.openLocationCodeModal(name);
            } else {
                const saveLocationCode = `save${_.startCase(name.substring(0, name.length - 5))}Code`;
                this.setState({ [saveLocationCode]: "" })
            }
        }

        for (const input of this.state.rates) {
            if (name === input) {
                this.clearCarriersAndSetState();
            }
        }
    }

    openLocationCodeModal = locationType => {
        let formattedLocationType = _.startCase(locationType);
        formattedLocationType = formattedLocationType.substring(0, formattedLocationType.length - 5);
        const saveCodeName = 'save' + formattedLocationType + 'Code';
        this.setState({
            saveLocationType: formattedLocationType,
            saveLocationCode: this.state[saveCodeName],
            locationCodeModal: true,
        });
    }

    skipLocationCode = () => {
        this.setState({
            saveLocationType: "",
            saveLocationCode: "",
            locationCodeModal: false,
        })
    }

    submitLocationCode = (locationType, locationCode) => {
        const saveLocation = `save${locationType}Code`;
        if(locationCode != undefined && locationCode.length > 0) locationCode = locationCode.toUpperCase();
        this.setState({
            [saveLocation]: locationCode,
            saveLocationType: "",
            locationCode: "",
            locationCodeModal: false,
        })
    }

    handleBlur = name => event => {
        if(name.indexOf("_country") !== -1) {
			const type = name.replace("_country", "");
			const postalCode = this.state[`${type}_zip`];
            this.setState({
                [name]: convertToCountryCode(event.target.value, 'short')
            }, () => {
                this.handleZip(type, postalCode, this.state[name]);
            });
        }
    }

    handleInput = name => event => {
        this.setState({
            [name]: event.target.value
        }, function() {
            if (name === "custom_pallet_count") {
                this.updateTotals();
            }
        });
        const stateFields = [
            'shipper_state',
            'consignee_state',
            'billing_state',
            'blind_shipper_state',
            'blind_consignee_state',
            'blind_billing_state',
            'dgd_shipper_state',
            'dgd_consignee_state',
        ];
        if (name.indexOf("_zip") !== -1) {
            const type = name.replace("_zip", "");
            // Update country to Canada if zip has a letter
			const regex = /[a-z]/i;
			const countryCode = regex.test(event.target.value) ? 'CA' : this.state[`${type}_country`];
            this.handleZip(type, event.target.value, countryCode);
        }
        if (stateFields.indexOf(name) > -1) {
            this.setState({ [name]: event.target.value.toUpperCase()});
        }

        if (name === 'custom_unit_master_name') {

            this.state.body.custom_unit_master.forEach($uom => {

                if ($uom['name'] === event.target.value ) {

                    this.setState({
                        custom_unit_master_id: $uom['id'],
                    });

                }

            });

        }

        if (name === 'pro_num') {
            if(this.state.pro_book_flag && event.target.value != this.state.proBookPro) {
                this.setState({
                    pro_book_flag: false,
                })
            } else if(!this.state.pro_book_flag && event.target.value == this.state.proBookPro) {
                this.setState({
                    pro_book_flag: true,
                })
            }
        }

        for (const input of this.state.rates) {
            if (name === input) {
                this.clearCarriersAndSetState();
            }
        }
    };

    async getReferences() {
        try {
            const response = await axios.get('/index.php?p=api&r=json&c=userSettings&m=getReferenceTypes');
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                let types = response.data.body;
                let refTypes = [];
                for(var i = 0; i < types.length; i++) {
                    refTypes.push({value: types[i]});
                }
                this.setState({refTypes: refTypes});
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("An exception occurred loading custom reference types!", { variant: "error" });
        }
    }

    toggleAutoTracking() {

        if(this.state.consignee_contact_email == "" && this.state.additional_tracking_emails.length == 0 && !this.state.autoSendTracking) {
            this.handleRequireEmail();
        } else {
            this.updateTracking();
        }
    }

    updateOrResendTracking = async() => {
        if (this.state.resendTracking) {
            this.resendTracking();
        } else {
            this.updateTracking();
        }
    }

    updateTracking = async() =>{
        const response = await axios.get('/index.php?p=api&r=json&c=billoflading&m=toggleAutoSendTracking&d=' +  this.state.bolId + "/" + this.state.date);
        if (typeof response.data !== "string") {
            if(!_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if(!_.isEmpty(response.data.body)) {
                this.setState({
                    autoSendTracking: response.data.body.autosend_tracking,
                    trackingSent: response.data.body.tracking_sent,
                    p44Url: response.data.body.p44_url,
                });
            }
            return true;
        }
        else{
            return false;
        }
    }

    prepareResendTracking = async() => {
        await this.setState({
            requireTracking:true
        });
        if(this.state.consignee_contact_email == "" && this.state.additional_tracking_emails.length == 0) {
            this.handleRequireEmail();
        } else {
            this.resendTracking();
        }
    }

    resendTracking = async() =>{
        const response = await axios.get('/index.php?p=api&r=json&c=billoflading&m=resendTracking&d=' +  this.state.bolId + "/" + this.state.date);

        if (typeof response.data !== "string") {
            if(!_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if(!_.isEmpty(response.data.body)) {
                this.setState({
                    autoSendTracking: response.data.body.autosend_tracking,
                    trackingSent: response.data.body.tracking_sent,
                    p44Url: response.data.body.p44_url,
                });
            }
            return true;
        }
        else{
            return false;
        }
    }

    async getDefaultPickupTimes() {
        try {
            const response = await axios.get('/index.php?p=api&r=json&c=userSettings&m=defaultPickupTimes');
            let defaultPickupTimes = {};
            if (typeof response.data !== "string") {
                if(!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }

                const body = response.data.body;
                if(!_.isEmpty(body)) {
                    for (const prop of body) {
                        if (!prop.warehouse_code) continue;

                        if (!(prop.warehouse_code in defaultPickupTimes)) {
                            defaultPickupTimes[prop.warehouse_code] = {}
                        }
                        defaultPickupTimes[prop.warehouse_code][prop.scac] = {
                            open: {
                                hour: prop.ready_hour,
                                minute: prop.ready_minute
                            },
                            close: {
                                hour: prop.close_hour,
                                minute: prop.close_minute
                            }
                        }
                    };

                }
            }
            this.setState({ defaultPickupTimes });
        } catch (error) {
            console.error(error);
            this.props.setNotification("An exception occurred loading default pickup times!", { variant: "error" });
        }
    }

    getShipperTime = async () => {
        let zip = this.state.shipper_zip;

        let url = "/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + encodeURIComponent(zip);
        url += (this.state.shipper_country != '' && this.state.shipper_country != null) ? `/${convertToCountryCode(this.state.shipper_country, 'long')}` : ``;

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string") {
                if (!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                if(!_.isEmpty(response.data.body[zip])
                    && response.data.body[zip].length >= 1 && response.data.body[zip][0].timeZone != '') {
                        const timeZone = response.data.body[zip][0].timeZone;
                        const dst = response.data.body[zip][0].dst;
                        let offset = getTimezoneOffset(timeZone, dst);
                        if(offset != false) {
                            const time = moment().utcOffset(offset);
                            return time;
                        } else {
                            this.props.setNotification("Unable to convert timezone offset!", {
                                variant: "error"
                            });
                        }
                } else {
                    this.props.setNotification("No timezone found for shipper's postal code and country!", {
                        variant: "error"
                    });
                }
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error getting shipper's local time!", {
                variant: "error"
            });
        }
        return false
    }

    setDefaultPickupTimes(cb) {
        let open = '';
        let openDate = '';
        let close = '';
        let closeDate = '';
        const now = moment();
        const nowDate = now.format('MM/DD/YYYY');
        const nowHour = now.format('HH');
        const nowMinute = now.format('mm');
        if(!_.isEmpty(this.state.pickup_date)) {
            openDate = moment(this.state.pickup_date).format('MM/DD/YYYY');
            closeDate = moment(this.state.pickup_date).format('MM/DD/YYYY');
        } else {
            let customDate = moment(this.state.custom_date);
            if (customDate.isValid() && customDate.isAfter(now)) {
                openDate = customDate.format('MM/DD/YYYY');
                closeDate = customDate.format('MM/DD/YYYY');
            } else {
                openDate = nowDate;
                closeDate = nowDate;
            }
        }

        const havePickupTime = (
            !_.isEmpty(this.state.scac) &&
            !_.isEmpty(this.state.warehouse) &&
            !_.isEmpty(this.state.defaultPickupTimes[this.state.warehouse]) &&
            !_.isEmpty(this.state.defaultPickupTimes[this.state.warehouse][this.state.scac])
        );
        if (havePickupTime) {
            const defTimes = this.state.defaultPickupTimes[this.state.warehouse][this.state.scac];
            const openHour = (!_.isEmpty(defTimes.open.hour)) ? defTimes.open.hour : nowHour;
            const openMinute = (!_.isEmpty(defTimes.open.minute)) ? defTimes.open.minute : nowMinute;
            const closeHour = (!_.isEmpty(defTimes.close.hour)) ? defTimes.close.hour : nowHour;
            const closeMinute = (!_.isEmpty(defTimes.close.minute)) ? defTimes.close.minute : nowMinute;

            open = moment(openDate + " " + openHour + ":" + openMinute);
            if (open < now.clone().add(10, 'minutes')) {
                open = now.clone().add(10, 'minutes');
            }

            close = moment(closeDate + " " + closeHour + ":" + closeMinute);
            if (close.diff(open, 'minutes') < 120) {
                close = open.clone().add(120, 'minutes');
            }
        }
        else {
            open = moment(openDate + " " + nowHour + ":" + nowMinute).add(10, 'minutes');
            close = moment(closeDate + " " + nowHour + ":" + nowMinute).add(130, 'minutes');
        }
        this.setState({
            pickup_date_time: open,
            dock_close_time: close,
        }, () => {
            if(typeof cb === 'function') {
                cb();
            }
        });
    }

    getRefTypes(refs) {
        const { classes } = this.props;
        return refs.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.value}
                >
                    {prop.value}
                </MenuItem>
            );
        });
    }

    handleChange(event) {
        const name = event.target.name;
        if(name === 'direction') {

            // TTMS-1475 Outbound/Prepaid & Charge support for Cooley
            if(event.target.value === 'PPC') {
                this.setState({
                    direction: 'Outbound/Prepaid',
                    charge_customer: 1,
                });
            } else {
                this.setState({
                    direction: event.target.value,
                    charge_customer: 0,
                });
            }

        } else if(name === 'transit_status') {
            var value = JSON.parse(event.target.value);
            this.setState({
                transit_status: value.parent ? value.parent : value.status,
                transit_sub_status: value.parent ? value.status : null,
            });
        } else {

            this.setState({ [name]: event.target.value }, () => {

            	// If changing the warehouse, do this in callback to ensure the state is properly set prior
            	// loading the warehouse info
            	if(name == 'warehouse') {

                    this.loadDefaults(true, {
    	                shipper: true,
    	                consignee: true,
    	                billing: true,
    	                notifyOnBillingChange: false,
    	                references: true,
                    });

                    let references = [];

                    this.state.body.warehouses.forEach(warehouse => {
                        if(warehouse.code == event.target.value) {
                            references = this.handleWarehouseReferences(warehouse);
                        }
                    })
                    this.setState({
                        references: references,
                    });
            	}

                if(name == 'search_bol_merge_by' && event.target.value == 'customReference') {
                    this.getReferences();
                }

            });
        }

        for (const input of this.state.rates) {
            if (name === input) {
                this.clearCarriersAndSetState();
            }
        }

        if (name === "scac" && !_.isEmpty(event.target.value) && (this.state.status == 'hold' || _.isEmpty(this.state.status))) {
            this.setState({ showExecute: true });
        } else if (name === "non_program_carrier" && !_.isEmpty(event.target.value) && this.state.data_type == "manual" && (this.state.status == 'hold' || _.isEmpty(this.state.status))) {
            this.setState({ showExecute: true });
        } else if (name === "direction") {
            const direction = (event.target.value === 'PPC') ? 'Outbound/Prepaid' : event.target.value; // TTMS-1475 Outbound/Prepaid & Charge support for Cooley
            const { billing_address1, billing_address2, billing_zip } = this.state;
            let billingIsTfm = false;
            if ((billing_address1.indexOf("6360 library") > -1 || billing_address2.indexOf("6360 library") > -1) && billing_zip == "15129") {
                billingIsTfm = true;
            }

            if (direction === "Outbound/Collect") {
                // if the billing info is TFM, erase it, warn the user, and show the button to pull cons info
                if (billingIsTfm) {
                    this.setState({
                        billing_name: "",
                        billing_address1: "",
                        billing_address2: "",
                        billing_zip: "",
                        billing_city: "",
                        billing_state: "",
                        billing_country: "",
                        billing_contact_name: "",
                        billing_contact_phone: "",
                        billing_contact_fax: "",
                        billing_contact_email: "",
                        consignee_to_billing: true
                    });
                    this.props.setNotification("Please enter the billing information for this shipment.", { variant: "warning" });
                }
            } else {
                this.setState({ consignee_to_billing: false });
                if (direction === "Outbound/Prepaid" || direction === "Inbound/Collect") {
                    // set the default billing address and warn the user
                    this.loadDefaults(true, {
                        shipper: false,
                        consignee: false,
                        terms: false,
                        billing: true,
                        notifyOnBillingChange: true
                    });
                }
            }
        }
    }

    handleDatetime(name, momentObj) {
        if(name === 'delivery_time') {
            moment.isMoment(momentObj) ? this.setState({ [name]: momentObj.format("HH:mm:ss") }) : this.setState({ [name]: momentObj });
        } else {
            this.setState({ [name]: momentObj });

            if (name === "pickup_date_time" &&
                typeof momentObj === "object" &&
                momentObj.isValid() &&
                moment(this.state.dock_close_time).isValid() &&
                momentObj.isAfter(moment(this.state.dock_close_time))
                ) {
                this.setState({dock_close_time: momentObj});
            }
            if (name === "pickup_date" && this.state.status == 'hold') {
                this.clearCarriersAndSetState();
            }
        }
    }

    getTrackingEmails(contact, type) {
        if(type == 'consignee') {
            // add any new emails from the consignee's additional_tracking_emails to the state.additional_tracking_emails, skipping duplicates
            let trackingEmails = [...this.state.additional_tracking_emails];
            let skippedEmails = [];
            for(let i=0; i<contact.additional_tracking_emails.length; i++) {
                if(!trackingEmails.includes(contact.additional_tracking_emails[i])) {
                    // only allow 10 tracking emails
                    if(trackingEmails.length >= 10) {
                        skippedEmails.push(contact.additional_tracking_emails[i])
                    } else {
                        trackingEmails.push(contact.additional_tracking_emails[i]);
                    }
                }
            }

            if(skippedEmails.length > 0) {
                this.props.setNotification("The following emails were omitted because only 10 additional tracking emails are permitted: " + skippedEmails.join("\n"), { variant: "info" });
            }

            this.setState({
                additional_tracking_emails: trackingEmails,
                auto_tracking: contact.auto_tracking == 1 ? true : false,
            });

        }
    }

    async handleContactSearch(type) {
        let url = "";
        let searchType = "";

        const { warehouse } = this.state;
        const searchBy = this.state[type + "_search_by"];
        const searchFor = this.state[type + "_search_for"];

        switch (type) {
            case "billing":
            case "blind_billing":
                searchType = "bill_to";
                break;
            case "shipper":
            case "dgd_shipper":
            case "blind_shipper":
                searchType = "shipper";
                break;
            case "consignee":
            case "dgd_consignee":
            case "blind_consignee":
                searchType = "consignee";
                break;
        }

        if (searchBy == "SEARCH_ANY") {
            url = "/index.php?p=api&r=json&c=location&m=findAnything&d=" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
        } else {
            url = "/index.php?p=api&r=json&c=location&m=find&d=" + searchBy + "/" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
        }

        try {
            const response = await axios.get(url);

            if (typeof response.data !== "string") {
                if (response.data.body.length == 1) {
					const contact = response.data.body[0];
                    this.handleLocationNotes(contact);
                    this.getTrackingEmails(contact, type);
                    this.clearCarriersAndSetState({
                        [type + "_name"]: contact.location_name || "",
                        [type + "_address1"]: contact.address1 || "",
                        [type + "_address2"]: contact.address2 || "",
                        [type + "_zip"]: contact.zip || "",
                        [type + "_city"]: contact.city || "",
                        [type + "_state"]: contact.state || "",
                        [type + "_country"]: contact.country || "US",
                        [type + "_contact_name"]: contact.contact_name || "",
                        [type + "_contact_phone"]: contact.phone || "",
                        [type + "_contact_email"]: contact.email || "",
                        [type + "_contact_fax"]: contact.fax || "",
                        contactSearchModal: false,
                        contacts: [],
                        contactSearchType: "",
                    }, () => {
                        this.applyAutoSelectAccessorialsForLocation(contact.id);
                    });
                } else if (response.data.body.length > 1) {
                    this.setState({
                        contacts: response.data.body,
                        contactSearchType: type,
                        contactSearchModal: true
                    });
                } else {
                    this.props.setNotification("No contacts found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching contacts!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching contacts!", {
                variant: "error"
            });
        }
    }

    openInternalNoteAlert(notes) {
        var noteItems;
        if(notes === undefined || notes === '') return false;
        if(notes.bill_to || notes.shipper || notes.consignee) {
            var notesArray = Object.entries(notes)
            noteItems = notesArray.filter(function(elem) { // filter out empty values
                if(elem[1][0] === '' && elem[1][1] === '') {
                    return false;
                }
                return true;
            }).map((note) => { // map each note to an accordion element
                var loc = note[1];
                var name = loc[0];
                var note = loc[1];
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {note}
                        </AccordionDetails>
                    </Accordion>
                )
            });
            this.setState({
                alert: (
                    <SweetAlert
                        style={{
                            display: "block",
                            color: "black"
                        }}
                        title="Location Note"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                        confirmBtnText="Okay"
                    >
                        {noteItems}
                    </SweetAlert>
                )
            })
        } else {
            this.setState({
                alert: (
                    <SweetAlert
                        style={{
                            display: "block",
                            color: "black"
                        }}
                        title="Location Note"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                        confirmBtnText="Okay"
                    >
                        <Typography variant="body1">
                            {notes}
                        </Typography>
                    </SweetAlert>
                )
            })
        }
    }

    handleLocationNotes(contact) {
        var internalNotes = {};
        if(contact.internalNoteCount === 1) {
            if(contact.shipper.internal_note) {
                this.openInternalNoteAlert(contact.shipper.internal_note);
            } else if(contact.consignee.internal_note) {
                this.openInternalNoteAlert(contact.consignee.internal_note);
            } else if(contact.bill_to.internal_note) {
                this.openInternalNoteAlert(contact.bill_to.internal_note);
            }
        } else if(contact.internalNoteCount > 1){
            internalNotes.shipper = [contact.shipper.name, contact.shipper.internal_note];
            internalNotes.consignee = [contact.consignee.name, contact.consignee.internal_note];
            internalNotes.bill_to = [contact.bill_to.name, contact.bill_to.internal_note];
            this.openInternalNoteAlert(internalNotes);
        } else {
            this.openInternalNoteAlert(contact.internal_note);
        }

        var externalNote = this.state.notes;
        if(contact.external_notes) { // if multiple notes (if there are multiple default locations with external notes)
            contact.external_notes.map((ext) => {
                if(!this.state.notes.includes(ext)) {
                    externalNote +=  " - " + ext;
                }
            })
            this.setState({ notes: externalNote })
        } else { // if single note (user selected an address with an external note from the contact search)
            if(contact.external_note) {
                if(!this.state.notes.includes(contact.external_note)) {
                    externalNote += " - " + contact.external_note
                }
                this.setState({ notes: externalNote })
            }
        }
    }

    handleContactSelect(contactKey, type) {
        const { contacts } = this.state;
        const contact = {};
        Object.keys(contacts[contactKey]).map(key => {
            const prop = contacts[contactKey][key];
            if (prop) {
                if(!Array.isArray(prop)) {
                    contact[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
                } else {
                    contact[key] = prop;
                }
            }
        });

        this.handleLocationNotes(contact);
        this.getTrackingEmails(contact, type);

        this.clearCarriersAndSetState({
            [type + "_name"]: contact.location_name || '',
            [type + "_address1"]: contact.address1 || '',
            [type + "_address2"]: contact.address2 || '',
            [type + "_zip"]: contact.zip || '',
            [type + "_city"]: contact.city || '',
            [type + "_state"]: contact.state || '',
            [type + "_country"]: contact.country || '',
            [type + "_contact_name"]: contact.contact_name || '',
            [type + "_contact_phone"]: contact.phone || '',
            [type + "_contact_email"]: contact.email || '',
            [type + "_contact_fax"]: contact.fax || '',
            contactSearchModal: false,
            contacts: [],
            contactSearchType: "",
        }, () => {
            this.applyAutoSelectAccessorialsForLocation(contact.id);
        });
        if (!_.isEmpty(contact.zip) && contact.zip.length >= 5) {
            this.handleZip(type, contact.zip, contact.country);
        }
    }

    applyAutoSelectAccessorialsForLocation = async locationId => {
        const maps = await this.props.loadAutoSelectAccessorialMaps(locationId);
        if (Array.isArray(maps)) {
            const accIds = maps.map(map => +map.acc_name_id);
            this.setState({accessorials: this.state.accessorials.map(acc => {
                if (accIds.indexOf(+acc.id) !== -1 && acc.common == 1) {
                    acc.checked = true;
                }
                return acc;
            })});
        }
    }

    clearGeneralInfo() {
        this.clearCarriersAndSetState({
            direction: "Outbound/Prepaid",
            pro_num: "",
            bol_num: "",
            custom_date: "",
            po_num: "",
            so_num: "",
            ref_num: "",
            blind_shipment: false,
            transit_status: "",
            transit_sub_status: "",
            late_pickup_reason: "",
            late_delivery_reason: "",
            pickup_date: "",
            expected_delivery_date: "",
            delivery_date: "",
            delivery_time: "",
        });
    }

    /**
     * Clear carrier fields from state
     * @param  object override - optionally provide more fields to set in state
     * @param  function callback - optional callback
     */
    clearCarriersAndSetState = (override, callback) => {
        const overrideObject = isObject(override) ? override : {};
        flushSync(() => {
            this.setState({
                carriers: [],
                volume_carriers: [],
                max_weight: false,
                showCarriers: false,
                showVolumeCarriers: false,
                loadingCarriers: false,
                quote: "",
                quoteId: "",
                showQuote: false,
                loadingQuote: false,
                location_types: [],
                recommended_accessorials: [],
                recommended_accs_id: null,
                ...overrideObject
            }, () => {
                if (typeof callback === "function") {
                    callback();
                }
            });
        })
    }

    clearAddress(type) {
        this.clearCarriersAndSetState({
            [type + "_name"]: "",
            [type + "_address1"]: "",
            [type + "_address2"]: "",
            [type + "_zip"]: "",
            [type + "_city"]: "",
            [type + "_state"]: "",
            [type + "_country"]: "",
            saveShipperCode: "",
            saveConsigneeCode: "",
            saveBillingCode: "",
        });
    }

    handleSwap() {
        const {
            shipper_name,
            shipper_address1,
            shipper_address2,
            shipper_zip,
            shipper_city,
            shipper_state,
            shipper_country,
            shipper_contact_name,
            shipper_contact_phone,
            shipper_contact_email,
            shipper_contact_fax,
            saveShipperCode,
            consignee_name,
            consignee_address1,
            consignee_address2,
            consignee_zip,
            consignee_city,
            consignee_state,
            consignee_country,
            consignee_contact_name,
            consignee_contact_phone,
            consignee_contact_email,
            consignee_contact_fax,
            saveConsigneeCode,
        } = this.state;

        this.clearCarriersAndSetState({
            shipper_name: consignee_name || "",
            shipper_address1: consignee_address1 || "",
            shipper_address2: consignee_address2 || "",
            shipper_zip: consignee_zip || "",
            shipper_city: consignee_city || "",
            shipper_state: consignee_state || "",
            shipper_country: consignee_country || "",
            shipper_contact_name: consignee_contact_name || "",
            shipper_contact_phone: consignee_contact_phone || "",
            shipper_contact_email: consignee_contact_email || "",
            shipper_contact_fax: consignee_contact_fax || "",
            saveShipperCode: saveConsigneeCode || "",
            consignee_name: shipper_name || "",
            consignee_address1: shipper_address1 || "",
            consignee_address2: shipper_address2 || "",
            consignee_zip: shipper_zip || "",
            consignee_city: shipper_city || "",
            consignee_state: shipper_state || "",
            consignee_country: shipper_country || "",
            consignee_contact_name: shipper_contact_name || "",
            consignee_contact_phone: shipper_contact_phone || "",
            consignee_contact_email: shipper_contact_email || "",
            consignee_contact_fax: shipper_contact_fax || "",
            saveConsigneeCode: saveShipperCode || "",
        });
    }

    handleAddress(type) {
        const address = {
            address1: this.state[type + "_address1"],
            address2: this.state[type + "_address2"],
            zip: this.state[type + "_zip"],
            city: this.state[type + "_city"],
            state: this.state[type + "_state"]
        };

        let addressSearchString = "";

        for (const key in address) {
            const val = address[key];
            if (typeof val === "string" && _.trim(val) !== "") {
                if (addressSearchString === "") {
                    addressSearchString = _.trim(val);
                } else {
                    addressSearchString += " " + _.trim(val);
                }
            }
        }

        if (addressSearchString !== "") {
            const addressSearchURL = "http://www.google.com/maps/place/?q=" + encodeURIComponent(addressSearchString);
            const addressSearchTitle = "Address Search";
            const addressSearchOpts = "resizable=1, scrollbars=1, fullscreen=0, status=0";

            window.open(addressSearchURL, addressSearchTitle, addressSearchOpts);
        }
    }

    removeUnit(i) {
        const { units } = this.state;
        if (units.length) {
            const index = i + 1;
            units.splice(i, 1);
            this.setState({
                units,
                classification: false
            });
            this.updateTotals(true);
            this.props.setNotification(`Unit #${index} has been removed.`, { variant: "info" });
        }
    }

    addUnit() {
        const { warehouse, defaultUnit, defaultProduct, units } = this.state;
        const newUnit = {
            type: "Pallet",
            pieces: 1,
            stackable: false,
            length: "",
            width: "",
            height: "",
            density: "",
            cube: "",
            products: [
                {
                    nmfc: "",
                    sub_nmfc: "",
                    class: "",
                    description: "",
                    uom: "Piece",
                    pieces: "1",
                    weight: "",
                    net_weight: "",
                    condition: "",
                    packaging: "",
                    declared_value: "",
                    hazardous: false,
                    auto_weight: false,
                    hazmat: {
                        class: "",
                        un: "",
                        packingGroup: "",
                        emergencyNumber: "",
                        packingInstructions: "",
                        overpackUsed: false
                    },
                    errors: []
                }
            ],
            errors: []
        };
        if (!_.isEmpty(warehouse)) {
            if (!_.isEmpty(defaultUnit[warehouse])) {
                Object.assign(newUnit, defaultUnit[warehouse]);
            }
            if (!_.isEmpty(defaultProduct[warehouse])) {
                newUnit.products[0] = structuredClone(defaultProduct[warehouse]);
            }
        }

        units.push(newUnit);
        this.setState({
            units,
            classification: false
        });
        this.updateTotals(true);
        this.checkLiability();
    }

    addProduct(i) {
        const { units } = this.state;
        if (units.length) {
            units[i].products.push({
                nmfc: "",
                sub_nmfc: "",
                class: "",
                weight: "",
                net_weight: "",
                uom: units[i].type,
                pieces: 1,
                description: "",
                condition: "",
                packaging: "",
                declared_value: "",
                hazardous: false,
                auto_weight: false,
                hazmat: {
                    class: "",
                    un: "",
                    packingGroup: "",
                    emergencyNumber: "",
                    packingInstructions: "",
                    overpackUsed: false
                }
            });
            this.setState({
                units,
                classification: false
            });
            this.updateTotals(true);
            this.checkLiability();
        }
    }

    handleUnit(i, name, event) {
        const { units } = this.state;
        if (units.length) {
            const unit = units[i];
            unit[name] = event.target.value;
            units[i] = unit;
            this.clearCarriersAndSetState({
                units,
                classification: false,
            });
            this.updateTotals();
        }
    }

    handleProduct(i, j, name, event) {
        const { units } = this.state;
        if (units.length) {
            const unit = units[i];
            if (unit.products.length) {
                const product = unit.products[j];
                if (name === "auto_weight") {
                    product[name] = !product[name];
                } else {
                    product[name] = event.target.value;
                }
                unit.products[j] = product;
                units[i] = unit;
                this.clearCarriersAndSetState({
                    units,
                    classification: false,
                });
                this.updateTotals();
                this.checkLiability();
            }
        }
    }

    clearProduct(i, j) {
        const { units } = this.state;
        if (!_.isEmpty(units)) {
            const unit = units[i];
            if (!_.isEmpty(unit.products)) {
                const product = {
                    nmfc: "",
                    sub_nmfc: "",
                    class: "",
                    weight: "",
                    net_weight: "",
                    uom: units[i].type,
                    pieces: 1,
                    description: "",
                    condition: "",
                    packaging: "",
                    declared_value: "",
                    hazardous: false,
                    auto_weight: false,
                    hazmat: {
                        class: "",
                        un: "",
                        packingGroup: "",
                        emergencyNumber: "",
                        packingInstructions: "",
                        overpackUsed: false
                    }
                };
                unit.products[j] = product;
                units[i] = unit;
                this.clearCarriersAndSetState({
                    units,
                    classification: false,
                });
                this.updateTotals();
                this.checkLiability();
            }
        }
    }

    removeProduct(i, j) {
        const { units } = this.state;
        if (units.length && !_.isEmpty(units[i]) && !_.isEmpty(units[i].products[j])) {
            units[i].products.splice(j, 1);
            this.setState({
                units,
                classification: false
            });
            this.updateTotals(true);
            const productIndex = j + 1;
            const unitIndex = i + 1;
            this.props.setNotification(`Product #${productIndex} has been removed from Unit #${unitIndex}.`, { variant: "info" });
            this.checkLiability();
        }
    }

    toggleHazardous(i, j) {
        const { accessorials, units } = this.state;
        if (units.length) {
            const product = units[i].products[j];
            product.hazardous = !product.hazardous;
            units[i].products[j] = product;
            this.setState({ units });
        }
        let hazAcc = false;
        outerloop:
        for(let unit of units) {
            for(let product of unit.products) {
                if(product.hazardous == true) {
                    hazAcc = true;
                    break outerloop;
                }
            }
        }
        for(let accessorial of accessorials) {
            if(accessorial.code == '420') {
                accessorial.checked = hazAcc ? true : false;
                break;
            }
        }
        this.setState({ accessorials });
    }

    handleHazardous(i, j, name, event) {
        const { units } = this.state;
        if (units.length) {
            const unit = units[i];
            if (unit.products.length) {
                const product = unit.products[j];
                if (name === "overpackUsed") {
                    product.hazmat[name] = !product.hazmat[name];
                } else {
                    product.hazmat[name] = event.target.value;
                }
                unit.products[j] = product;
                units[i] = unit;
                this.setState({ units });
            }
        }
    }

    validatePhoneNumber(phoneNumber) {
        const phoneRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d{1,6}))?/;
        if(phoneNumber == null || !phoneNumber.match(phoneRegex)) {
            this.props.setNotification("Invalid hazmat emergency contact. Please enter a valid phone number in a valid format (e.g. ###-###-#### ext.####)", { variant: "error" });
        } else {
            return true;
        }
    }

    handleDataType(data_type) {
        this.setState({ data_type });
    }

    handleRecommendedAccessorials = (recommendedAccs) => {
        let recommended_accessorials = [];
        if (!_.isEmpty(recommendedAccs.shipper)) {
            recommended_accessorials = recommended_accessorials.concat(recommendedAccs.shipper)
        }
        if (!_.isEmpty(recommendedAccs.consignee)) {
            recommended_accessorials = recommended_accessorials.concat(recommendedAccs.consignee)
        }
        return recommended_accessorials;
    }

    getRecommendedAccessorials = async () => {
        this.setState({
            loadingRecommendedAccs: true
        });
        let pickup_date = this.state.pickup_date;
        if(typeof pickup_date !== "string" && moment.isMoment(pickup_date)) {
            pickup_date = pickup_date.format('MM/DD/YYYY')
        }
        const shiplifyUrl = "/index.php?p=api&r=json&c=billoflading&m=getRecommendedAccessorials";
        let data = {
            warehouseCode: this.state.warehouse,
            pickupDate: pickup_date,
            shipper: {
                name: this.state.shipper_name,
                address: this.state.shipper_address1,
                address2: this.state.shipper_address2,
                city: this.state.shipper_city,
                state: this.state.shipper_state,
                zipCode: this.state.shipper_zip
            },
            consignee: {
                name: this.state.consignee_name,
                address: this.state.consignee_address1,
                address2: this.state.consignee_address2,
                city: this.state.consignee_city,
                state: this.state.consignee_state,
                zipCode: this.state.consignee_zip
            }
        };
        try {
            const response = await axios.post(shiplifyUrl, qs.stringify(data));
            if (typeof response.data !== "string") {
                if(!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                if (!_.isEmpty(response.data.body) && response.data.body.id) {
                    const { body } = response.data;
                    let recommended_accs_id = body.id;

                    let recommended_accessorials = this.handleRecommendedAccessorials(body.recommendedAccessorials)
                    let location_types = body.locationTypes ?? [];

                    this.setState({
                        recommended_accs_id,
                        recommended_accessorials,
                        location_types,
                    });
                } else {
                    this.props.enqueueSnackbar("No accessorials were recommended for the shipper or consignee addresses.", { variant: "info" });
                }
            }
        } catch (error) {
            console.error(error);
            this.props.enqueueSnackbar("There was an error getting recommended accessorials!", { variant: "error" });
        }
        this.setState({
            loadingRecommendedAccs: false
        });
    }

    handleAccsChecked(key, event) {
        const { accessorials, recommended_accs_id, recommended_accessorials } = this.state;
        accessorials[key].checked = event.target.checked;
        this.clearCarriersAndSetState({
            accessorials,
            recommended_accs_id,
            recommended_accessorials
        });
    }

    handleAccessorials(accessorials) {
        return Object.keys(accessorials).map(key => {
            const acc = accessorials[key];
            return {
                id: key,
                code: acc.code,
                name: acc.name,
                common: acc.common,
                checked: false,
                always_checked: acc.always_checked,
            };
        });
    }

    approveRecommendedAccs = () => {
        let commonAcc = [];
        let { accessorials } = this.state;
        accessorials = accessorials.map((profileAcc) => {
            if(profileAcc.common == 1) {
                this.state.recommended_accessorials.map((recommendedAcc) => {
                    if(recommendedAcc.code == profileAcc.code) {
                        profileAcc.checked = true;
                    }
                });
            }
            return profileAcc;
        });
        this.clearCarriersAndSetState({
            accessorials: [...accessorials],
            recommended_accs_id: this.state.recommended_accs_id,
            recommended_accessorials: this.state.recommended_accessorials
        })
    }

    getLocationMessages = () => {
        let messages = [];
        if(!_.isEmpty(this.state.location_types)) {
            for (const [key, type] of Object.entries(this.state.location_types)) {
                if(!_.isEmpty(type) && !type.toLowerCase().includes('commercial')) {
                    messages.push('The ' + key + ' location has been identified as a(n) ' + type + '. An appointment may be required. Please reach out to the ' + key + ' for confirmation.');
                }
            }
        }
        return messages.map(message => {
            return (<>
                <GridItem xs={10} style={{ marginTop: "15px", textAlign: "left" }}>
                    {message}
                </GridItem>
            </>);
        })

    }

    displayAccData = (accessorials) => {
        const { classes } = this.props;
        if(accessorials.length > 0) {
            return accessorials.map(acc => {
                let commonAcc = [];
                this.state.accessorials.map((profileAcc, key) => {
                    if(acc.code == profileAcc.code && profileAcc.common == 1) {
                        commonAcc.push({
                            name: profileAcc.name,
                        });
                    }
                })
                if(commonAcc.length > 0) {
                    let accText = commonAcc[0].name ?
                    acc.code + " - " + commonAcc[0].name
                    : acc.code;
                    return (<>
                        <GridItem xs={8} style={{ marginTop: "15px", textAlign: "center" }}>
                            {accText}
                        </GridItem>
                        <GridItem xs={4} style={{ marginTop: "15px", textAlign: "center" }}>
                            {acc.confidence}%
                        </GridItem>
                    </>);
                } else {
                    return (
                        <GridItem xs={12} style={{ marginTop: "15px", textAlign: "center", backgroundColor:"error"}}>
                            <b>
                                {acc.code + " - Accessorial not available to be selected, please contact your sales representative for immediate assistance."}
                            </b>
                        </GridItem>
                        );
                }
            });
        } else {
            return (
                <GridItem xs={8} style={{ marginTop: "25px", textAlign: "center" }}>
                    No recommended accessorials
                </GridItem>
            );
        }
    }

    async updateTotals(requestLnft) {
        const useYatApi = requestLnft == true ? requestLnft : false;
        const { units, user, trailerDims } = this.state;

        let totalUnits = 0;
        let totalWeight = 0;
        let totalCube = 0;
        let totalPCF = 0;
        let totalLNFT = 0;
        let totalLength = 0;
        let totalValue = 0;

        let shipmentList = [];

        const liability = !_.isEmpty(user) && user.user_company_settings.enable_liability == 1;

        for (const i in units) {
            const unit = units[i];
            let weight = 0;
            let cube = 0;
            let pcf = 0;
            let { length, width, height, pieces, stackable } = unit;

            length = parseFloat(length);
            width = parseFloat(width);
            height = parseFloat(height);

            // SUM the longest sides
            totalLength += length > width ? length * pieces : width * pieces;

            unit.products.forEach(product => {
                if (product.auto_weight) {
                    weight += parseFloat(product.weight) * product.pieces;
                } else {
                    weight += parseFloat(product.weight);
                }
                if (liability && !_.isEmpty(product.declared_value)) {
                    const quantity = parseFloat(_.trim(product.pieces));
                    const declared_value = parseFloat(_.trim(product.declared_value));
                    totalValue += declared_value <= 0 ? 0 : declared_value * quantity * pieces;
                }
            });

            totalWeight += weight * pieces;

            shipmentList.push(YatClient.formatShipment(unit));

            // convert everything to feet for this unit
            length = length / 12;
            width = width / 12;
            height = height / 12;

            // do the math for this unit
            cube = length * width * height;
            pcf = weight / cube;

            totalCube += cube * pieces;
            totalUnits += isNaN(parseInt(pieces)) ? 1 : parseInt(pieces);

            unit.density = pcf.toFixed(2);
            unit.cube = cube.toFixed(2);
            units[i] = unit;
        }

        // do the rest of the math and update the page
        totalLNFT = totalLength / 12;
        totalPCF = totalWeight / totalCube;
        if(this.props.yatEnabled && this.state.company.enable_new_ln_ft_method == 1) {
            let yatReqCanceled = false
            try {
                if(useYatApi) {
                    this.setState({lnftLoading: true});
                    let response = await this.yat.optimizeTrailerLoad(shipmentList, null, trailerDims);
                    totalLNFT = response || totalLNFT;
                }else{
                    totalLNFT = this.state.totals.linear_feet <= 0 ? totalLNFT : parseFloat(this.state.totals.linear_feet);
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    yatReqCanceled = true;
                } else {
                    console.error("Error optimizing trailer load:", error);
                }
            }
            if(!yatReqCanceled) {
				this.setState({lnftLoading: false});
			}
        }

        //if user entered a custom pallet count, display custom count for total pallets.
        if (this.state.display_custom_piece_count && !isNaN(parseInt(this.state.custom_pallet_count))) {
            totalUnits = parseInt(this.state.custom_pallet_count);
        }

        const totals = {
            units: isNaN(totalUnits) || !isFinite(totalUnits) ? units.length : totalUnits,
            weight: isNaN(totalWeight) || !isFinite(totalWeight) ? 0.0 : totalWeight.toFixed(2),
            volume: isNaN(totalCube) || !isFinite(totalCube) ? 0.0 : totalCube.toFixed(2),
            density: isNaN(totalPCF) || !isFinite(totalPCF) ? 0.0 : totalPCF.toFixed(2),
            linear_feet: isNaN(totalLNFT) || !isFinite(totalLNFT) ? 0.0 : totalLNFT.toFixed(2),
            value: isNaN(totalValue) || !isFinite(totalValue) ? 0.0 : totalValue.toFixed(2)
        };

        this.setState({ totals, units });
    }

    handleCustomUnitOfMeasure(event, customUnitOfMeasure){
        customUnitOfMeasure.forEach($uom => {
            if (event.target.value == $uom['name']) {
                this.setState({
                    custom_unit_master_id: $uom['id'],
                });
            }
        });
    }

    async classifyProducts() {
        const { units } = this.state;
        let shipVolume = 0;
        const nmfcList = {};
        const shipWeight = this.state.totals.weight;
        let hasError = false;
        let products = 0;

        if (_.isEmpty(units)) {
            this.props.setNotification("No handling units provided!", { variant: "error" });
            hasError = true;
        }

        for (const unit of units) {
            shipVolume += ((unit.length * unit.width * unit.height) / Math.pow(12, 3)) * unit.pieces;
            if (!_.isEmpty(unit.products)) {
                products += unit.products.length;
                for (const product of unit.products) {
                    if (!_.isEmpty(product.nmfc)) {
                        nmfcList[product.nmfc] = product.nmfc;
                    }
                }
            }
        }

        if (_.isEmpty(units)) {
            this.props.setNotification("No handling units provided!", { variant: "error" });
            hasError = true;
        }

        for (const nmfc in nmfcList) {
            const calcClassUrl = "/index.php?p=api&r=json&c=billoflading&m=calcClass&d=" + nmfc + "/" + shipWeight + "/" + shipVolume;
            try {
                const response = await axios.get(calcClassUrl);
                if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                    const { body } = response.data;
                    for (const i in units) {
                        const unit = units[i];
                        for (let index = 0; index < unit.products.length; index++) {
                            const product = unit.products[index];
                            if (product.nmfc === nmfc && body.class > 0) {
                                product.nmfc = nmfc;
                                product.sub_nmfc = body.sub;
                                product.class = body.class;
                                unit.products[index] = product;
                            }
                        }
                        units[i] = unit;
                    }
                }
            } catch (error) {
                console.error(error);
                this.props.setNotification("There was an error classifying products!", { variant: "error" });
                hasError = true;
            }
        }

        if (!hasError) {
            this.setState({
                units,
                classification: true
            });
        }
    }

    async findCarriers(volume) {
        const general = {
            code: this.state.warehouse,
            shipper: this.state.shipper_zip,
            shipper_country: convertToCountryCode(this.state.shipper_country, 'long'),
            consignee: this.state.consignee_zip,
            consignee_country: convertToCountryCode(this.state.consignee_country, 'long'),
            shipment_type: this.state.direction,
            allow_excess_liability: (this.state.allow_excess_liability) ? 1 : 0,
            bolId: this.state.bolId,
            bolDate: this.state.date,
        };

        if (_.isEmpty(this.state.shipper_zip)) {
            this.props.setNotification("Shipper zip code is required!", { variant: "error" });
            this.setState({ active: 1 });
            this.props.scrollTop(80);
            return;
        }

        if (_.isEmpty(this.state.consignee_zip)) {
            this.props.setNotification("Consignee zip code is required!", { variant: "error" });
            this.setState({ active: 1 });
            this.props.scrollTop(80);
            return;
        }

        let customCounts = {
            custom_pallet_count: "",
            custom_piece_count: "",
            custom_loose_count: ""
        };

        if (typeof volume !== 'undefined' && volume === true) {
            general.volume = 1;
            general.shipmentTotalLength = this.state.volume_total_length;
            general.shipmentTotalWidth = this.state.volume_total_width
            general.shipmentTotalHeight = this.state.volume_total_height;
        }

        if (this.state.display_custom_piece_count) {
            customCounts = {
                custom_pallet_count: (!isNaN(this.state.custom_pallet_count) && this.state.custom_pallet_count > 0) ? this.state.custom_pallet_count : "",
                custom_piece_count: (!isNaN(this.state.custom_piece_count) && this.state.custom_piece_count > 0) ? this.state.custom_piece_count : "",
                custom_loose_count: (!isNaN(this.state.custom_loose_count) && this.state.custom_loose_count > 0) ? this.state.custom_loose_count : "",
            };
        }

        const accessorials = this.state.accessorials.filter(accs => accs.checked).map((prop, key) => prop.code);

        if(this.state.blind_shipment === true) {

            accessorials.push('BLD');

        }

        const units = this.state.units.map((prop, key) => {
            let unit = {};
            let { length, width, height, pieces, stackable, type } = prop;

            length = parseFloat(_.trim(length)) / 12;
            width = parseFloat(_.trim(width)) / 12;
            height = parseFloat(_.trim(height)) / 12;

            const products = prop.products.map((product, index) => {
                let nmfc = _.trim(product.nmfc);
                if (_.trim(product.sub_nmfc)) {
                    nmfc = _.trim(product.nmfc) + "-" + _.trim(product.sub_nmfc);
                }
                return {
                    pieces: product.pieces,
                    weight: _.trim(product.weight),
                    class: product.class,
                    condition: _.trim(product.condition),
                    packaging: _.trim(product.packaging),
                    net_weight: _.trim(product.net_weight),
                    declared_value: _.trim(product.declared_value),
                    nmfc: nmfc,
                    sub_nmfc: _.trim(product.sub_nmfc),
                    description: _.trim(product.description)
                };
            });

            return {
                num_of: pieces,
                type: type,
                length: length,
                width: width,
                height: height,
                stack: stackable ? "Yes" : "No",
                products: products
            };
        });

        const data = {
            general,
            customCounts,
            accessorials,
            units
        };

        const executed = this.state.status != 'hold' && !_.isEmpty(this.state.status);

        this.clearCarriersAndSetState({
            showCarriers: true,
            showVolumeCarriers: true,
            loadingCarriers: true,
            scac: null,
            rating_after_execute: executed ? true : false,
            tfmr_internal_id: null,
            distance: 0,
            recommended_accs_id: this.state.recommended_accs_id,
            recommended_accessorials: this.state.recommended_accessorials,
        });

        let lccUrl = "/index.php?p=api&r=json&c=ctsi&m=lcc";

        if((this.state.pickup_date.length > 0) || (typeof this.state.pickup_date === "object")) {

            const shipDate =  (typeof this.state.pickup_date === "object" && typeof this.state.pickup_date.format === "function")
    		? this.state.pickup_date.format('L')
            : this.state.pickup_date;

            const validDate = moment(shipDate, 'M/D/YYYY', true).isValid();

            if(!validDate) {
                this.props.setNotification("Please provide valid pickup date in MM/DD/YYYY format.", { variant: "error" });
                this.clearCarriersAndSetState();
                return;
            }

            lccUrl += "&date=" + shipDate;

        }

        try {
            const response = await axios.post(lccUrl, qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
                let query_id = "";
                let carriers = [];
                let allowed_carriers = [];
                let distance = 0;
                let max_weight = false;
                let tfmr_internal_id = null;
                let body = this.state.body
                if (response.data.body === null) {
                    max_weight = true;
                } else if (!_.isEmpty(response.data.body)) {
                    query_id = response.data.body.query_id;
                    allowed_carriers = response.data.body.allowed_carriers || [];
                    carriers = this.filterRestrictedCarriers(response.data.body.carriers, allowed_carriers);
                    distance = response.data.body.distance;
                    body.carriers = response.data.body.carriers;
                    if (response.data.body.tfmr_internal_id) {
                        tfmr_internal_id = response.data.body.tfmr_internal_id;
                    }
                }
                this.setState({
                    query_id,
                    carriers,
                    allowed_carriers,
                    body,
                    distance,
                    max_weight,
                    showCarriers: true,
                    showVolumeCarriers: false,
                    loadingCarriers: false,
                    tfmr_internal_id,
                    showTruckloadTab: true
                });
                if (typeof volume !== 'undefined' && volume === true) {
                    this.setState({volume_carriers: response.data.body.carriers, showVolumeCarriers: true, carriers: [], showCarriers: false});
                }
            } else {
                this.props.setNotification("There was an error searching for carriers!", { variant: "error" });
                this.setState({ loadingCarriers: false, showTruckloadTab: true });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching for carriers!", { variant: "error" });
            this.setState({ loadingCarriers: false, showTruckloadTab: true });
        }
    }

    rateDisplay = () => {
        return this.state.data_type === "volume" ? this.props.volumeRateDisplay : this.props.ltlRateDisplay;
    }

    showTruecost = () => {
        return !!+this.rateDisplay().true_cost;
    }

    onlyShowChosenCarrier = () => {
        return !this.props.userIsAdmin && !!+this.rateDisplay().only_show_chosen_carrier;
    }

    // check if any carriers restrictions were found.
    filterRestrictedCarriers = (carriers, allowed_carriers) => {
        if (!Array.isArray(carriers)) return [];
        return carriers = carriers.filter(carrier => {
            return allowed_carriers.includes(carrier.scac);
        })
    }

    renderRateResults = (carriers) => {

        if (!Array.isArray(carriers)) return null;

        if (this.onlyShowChosenCarrier()) {
            carriers = carriers.filter(carrier => {
                const executed = this.state.status != 'hold' && !_.isEmpty(this.state.status);
                if (executed && !this.state.rating_after_execute) {
                    if (this.state.scac.toUpperCase() !== carrier.scac.toUpperCase()) {
                        return false;
                    }
                }
                return true;
            });
        }

        return (
            <RateResults
               carriers={carriers}
               distance={this.state.distance}
               tfmrId={+this.state.tfmr_internal_id}
               volume={this.state.data_type === "volume"}
               showDetails={true}
               showLiability={true}
               pickupDate={this.state.pickup_date}
               showEstDelivery={this.state.user.user_company_settings.auto_calc_expected_delivery == 1}
               actionsHeader={this.state.quoteId ? "" : "Select"}
               showHideMessage={true}
               canSelect={true}
               selectIsRadio={true}
               onSelect={this.handleChange}
               selectedCarrierScac={this.state.scac}
               classes={this.props.classes}
               canGetTerminalInfo={true}
               onOpenLiability={carrier => {
                    this.handleLiability(carrier.scac);
               }}
               onClickTerminal={carrier => {
                    this.props.loadTerminalModal(carrier.scac, this.buildPayload());
               }}
               orderRatesBy={this.state.user.user_settings.order_rates_by}
			   orderRatesFrom={this.state.user.user_settings.order_rates_from}
			   displayNCarriers={this.state.user.user_settings.display_n_carriers}
               disableExclusions={this.state.prevent_exclusion_execution}
           />
        );
    }

    getUnitTypes(uom) {
        const { classes } = this.props;
        return uom.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.name}
                >
                    {prop.name}
                </MenuItem>
            );
        });
    }

    async checkForDuplicatesByReference() {
        const po = this.state.po_num;
        const so = this.state.so_num;
        const ref = this.state.ref_num;
        const bol = this.state.saved_bol_num;

        try {
            const url = `/index.php?p=api&r=json&c=billoflading&m=checkDupRefs&po=${po}&so=${so}&ref=${ref}&ignoreBoL=${bol}`;
            const response = await axios.get(url);

            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }

            if (typeof response.data !== "string") {
                if (!_.isEmpty(response.data.body)) {
                    this.setState({
                        duplicates: response.data.body,
                        duplicateBolsModal: true
                    });
                } else {
                    this.executeBol();
                }
            } else {
                this.props.setNotification("There was an error checking for duplicate BOLs by reference number. Please contact IS immediately! Your current BOL is still executing...", { variant: "error" });
                this.executeBol();
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("An exception occurred checking for duplicate BOLs by reference number. Please contact IS immediately! Your current BOL is still executing...", {
                variant: "error"
            });

            this.executeBol();
        }
    }

    buildPayload(execute, bolData) {
        const { data_type, query_id } = this.state;

        const data = typeof bolData !== "undefined" ? bolData : {};
        const liability = !_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1;
        const pickupDate = !_.isEmpty(this.state.pickup_date) ? moment(this.state.pickup_date).format("LLL") : "";
        const expectedDeliveryDate = !_.isEmpty(this.state.expected_delivery_date) ? moment(this.state.expected_delivery_date).format("LLL") : "";

        execute = typeof execute !== "undefined" ? execute : false;

        if (data_type == "ltl") {
            data.carrier = {
                ctsi_query_id: query_id,
                carrier_scac: this.state.scac
            };
        } else if (data_type == "volume") {
            data.carrier = {
                ctsi_query_id: query_id,
                carrier_scac: this.state.scac
            };
        } else if (data_type == "manual") {
            data.carrier = {
                non_program: true,
                carrier_scac: this.state.non_program_carrier,
                non_program_rate: this.state.non_program_rate
            };
        } else if (data_type == "truckload") {
            if (execute) {
                // make sure the shipper and consignee contacts at least have a phone number or email
                if (_.isEmpty(this.state.shipper_contact_phone) && _.isEmpty(this.state.shipper_contact_email)) {
                    this.props.setNotification("Please provide a phone number or email for the shipper to be contacted.", { variant: "error" });
                    return false;
                }

                if (_.isEmpty(this.state.consignee_contact_phone) && _.isEmpty(this.state.consignee_contact_email)) {
                    this.props.setNotification("Please provide a phone number or email for the consignee to be contacted.", { variant: "error" });
                    return false;
                }
            }
            data.carrier = {
                user: this.state.user['name'],
                truckload: true,
                equipment: this.state.tl_equipment,
                ctsi_query_id: query_id,
                distance: this.state.distance,
                spec_notes: this.state.notes,
                carrier_scac: '',
            };
        } else {
            this.props.setNotification("Select either LTL, Manual, Truckload, or Volume in the Carrier Selection section, then choose a carrier and try again.", { variant: "error" });
            return false;
        }

        data.customCounts = {
            custom_pallet_count: "",
            custom_piece_count: "",
            custom_loose_count: ""
        };

        if (this.state.display_custom_piece_count) {
            data.customCounts = {
                custom_pallet_count: (!isNaN(this.state.custom_pallet_count) && this.state.custom_pallet_count > 0) ? this.state.custom_pallet_count : "",
                custom_piece_count: (!isNaN(this.state.custom_piece_count) && this.state.custom_piece_count > 0) ? this.state.custom_piece_count : "",
                custom_loose_count: (!isNaN(this.state.custom_loose_count) && this.state.custom_loose_count > 0) ? this.state.custom_loose_count : "",
            };
        }

        data.custom_unit_master_id = this.state.custom_unit_master_id;

        data.special = {
            cod_amount: this.state.cod_amount,
            cod_by: this.state.cod_by,
            cod_type: this.state.cod_type,
            notes: this.state.notes
        };

        data.general = {
            pro: this.state.pro_num,
            pro_book_flag: this.state.pro_book_flag ? 1 : 0,
            saved_bol: this.state.saved_bol_num,
            bol: this.state.bol_num,
            po: this.state.po_num,
            so: this.state.so_num,
            ref: this.state.ref_num,
            status: this.state.status,
            bol_level_id: this.state.level,
            warehouse: this.state.warehouse,
            direction: this.state.direction,
            charge_customer: this.state.charge_customer,
            custom_date: !_.isEmpty(this.state.custom_date) ? moment(this.state.custom_date).format("YYYY-MM-DD") : "",
            references: this.state.references,
            require_pod_for_billing: this.state.require_pod_for_billing ? 1 : 0,
            add_np_fee: this.state.user.user_company_settings.add_np_fee,
            np_fee_amount: this.state.user.user_company_settings.np_fee_amount,
            total_declared_value: this.state.total_declared_value,
            auto_track_when_executed: this.state.auto_tracking ? 1 : 0,
            enable_executed_edit: this.state.enable_executed_edit,
        };

        if (liability && this.checkLiability()) {
            data.general.allow_excess_liability = this.state.allow_excess_liability ? 1 : 0;
        }

        data.transit = {
            status: this.state.transit_status,
            sub_status: this.state.transit_sub_status,
            late_pickup_reason: this.state.late_pickup_reason,
            late_delivery_reason: this.state.late_delivery_reason,
            pickup_date: pickupDate,
            original_pd: pickupDate,
            expected_delivery_date: expectedDeliveryDate,
            original_edd: expectedDeliveryDate,
            delivery_date: !_.isEmpty(this.state.delivery_date) ? moment(this.state.delivery_date).format("LLL") : "",
            delivery_time: this.state.delivery_time,
        };

        if(!_.isEmpty(data.transit.delivery_time) && !moment(data.transit.delivery_time, "HH:mm:ss", true).isValid()) {
            this.props.setNotification("Invalid delivery time", { variant: "error" });
            return false;
        }

        data.location = {};
        data.blind_location = {};

        data.location.shipper = {
            name: this.state.shipper_name,
            address1: this.state.shipper_address1,
            address2: this.state.shipper_address2 || "",
            city: this.state.shipper_city,
            state: this.state.shipper_state,
            zip: this.state.shipper_zip,
            country: this.state.shipper_country,
            contact_name: this.state.shipper_contact_name || "",
            contact_phone: this.state.shipper_contact_phone || "",
            contact_fax: this.state.shipper_contact_fax || "",
            contact_email: this.state.shipper_contact_email || "",
            save: (this.state.shipper_save) ? 'true' : 0,
            code: this.state.saveShipperCode || "",
            pickup_confirm_email: !!this.state.shipper_pickup_confirm
        };

        data.location.consignee = {
            name: this.state.consignee_name,
            address1: this.state.consignee_address1,
            address2: this.state.consignee_address2 || "",
            city: this.state.consignee_city,
            state: this.state.consignee_state,
            zip: this.state.consignee_zip,
            country: this.state.consignee_country,
            contact_name: this.state.consignee_contact_name || "",
            contact_phone: this.state.consignee_contact_phone || "",
            contact_fax: this.state.consignee_contact_fax || "",
            contact_email: this.state.consignee_contact_email || "",
            save: (this.state.consignee_save) ? 'true' : 0,
            code: this.state.saveConsigneeCode || "",
            pickup_confirm_email: !!this.state.consignee_pickup_confirm
        };

        data.location.billing = {
            name: this.state.billing_name,
            address1: this.state.billing_address1,
            address2: this.state.billing_address2 || "",
            city: this.state.billing_city,
            state: this.state.billing_state,
            zip: this.state.billing_zip,
            country: this.state.billing_country,
            contact_name: this.state.billing_contact_name || "",
            contact_phone: this.state.billing_contact_phone || "",
            contact_fax: this.state.billing_contact_fax || "",
            contact_email: this.state.billing_contact_email || "",
            save: (this.state.billing_save) ? 'true' : 0,
            code: this.state.saveBillingCode || "",
        };

        data.blind_location.shipper = {
            name: this.state.blind_shipper_name,
            address1: this.state.blind_shipper_address1,
            address2: this.state.blind_shipper_address2 || "",
            city: this.state.blind_shipper_city,
            state: this.state.blind_shipper_state,
            zip: this.state.blind_shipper_zip,
            country: this.state.blind_shipper_country,
            contact_name: this.state.blind_shipper_contact_name || "",
            contact_phone: this.state.blind_shipper_contact_phone || "",
            contact_fax: this.state.blind_shipper_contact_fax || "",
            contact_email: this.state.blind_shipper_contact_email || ""
        };

        data.blind_location.consignee = {
            name: this.state.blind_consignee_name,
            address1: this.state.blind_consignee_address1,
            address2: this.state.blind_consignee_address2 || "",
            city: this.state.blind_consignee_city,
            state: this.state.blind_consignee_state,
            zip: this.state.blind_consignee_zip,
            country: this.state.blind_consignee_country,
            contact_name: this.state.blind_consignee_contact_name || "",
            contact_phone: this.state.blind_consignee_contact_phone || "",
            contact_fax: this.state.blind_consignee_contact_fax || "",
            contact_email: this.state.blind_consignee_contact_email || ""
        };

        data.blind_location.billing = {
            name: this.state.blind_billing_name,
            address1: this.state.blind_billing_address1,
            address2: this.state.blind_billing_address2 || "",
            city: this.state.blind_billing_city,
            state: this.state.blind_billing_state,
            zip: this.state.blind_billing_zip,
            country: this.state.blind_billing_country,
            contact_name: this.state.blind_billing_contact_name || "",
            contact_phone: this.state.blind_billing_contact_phone || "",
            contact_fax: this.state.blind_billing_contact_fax || "",
            contact_email: this.state.blind_billing_contact_email || ""
        };

        data.location.tracking = this.state.additional_tracking_emails;

        this.setState({
            shipper_save: false,
            consignee_save: false,
            billing_save: false,
            saveShipperCode: "",
            saveConsigneeCode: "",
            saveBillingCode: "",
        });

        data.accessorials = [];

        if (this.state.accessorials.length) {
            data.accessorials = this.state.accessorials.filter(accs => accs.checked).map((prop, key) => prop.code);
        }

        data.recommended_accs_id = this.state.recommended_accs_id;

        data.units = [];

        if (!_.isEmpty(this.state.units)) {
            data.units = this.state.units.map((prop, key) => {
                let { length, width, height } = prop;
                const unit = {};
                let unit_weight = 0;

                unit.products = prop.products.map((product, key) => {
                    unit_weight += parseFloat(product.weight, 10);

                    let overpackUsed = 0;
                    if (product.hazardous && product.hazmat.overpackUsed) {
                        overpackUsed = 1;
                    }

                    return {
                        product: product.description,
                        pieces: product.pieces,
                        uom: product.uom,
                        nmfc: product.nmfc,
                        sub_nmfc: product.sub_nmfc,
                        hazmat: {
                            class: (product.hazardous) ? product.hazmat.class : "",
                            un_num: (product.hazardous) ? product.hazmat.un : "",
                            group: (product.hazardous) ? product.hazmat.packingGroup : "",
                            emergency: (product.hazardous) ? product.hazmat.emergencyNumber : "",
                            packing_inst: (product.hazardous) ? product.hazmat.packingInstructions : ""
                        },
                        class: product.class,
                        weight: product.weight,
                        overpack_used: overpackUsed,
                        condition: product.condition,
                        packaging: product.packaging,
                        net_weight: product.net_weight,
                        declared_value: product.declared_value,
                        part_no: (this.state.enable_product_part_no) ? product.part_no : '',
                    };
                });

                length = parseFloat(length);
                width = parseFloat(width);
                height = parseFloat(height);

                unit.details = {
                    pieces: prop.pieces,
                    stack: prop.stackable ? 1 : 0,
                    type: prop.type,
                    length: length,
                    width: width,
                    height: height,
                    weight: unit_weight * parseInt(prop.pieces, 10)
                };

                return unit;
            });
        }

        if (execute) {
            if (_.isEmpty(data.units)) {
                this.props.setNotification("A BoL cannot execute with no handling units!", { variant: "error" });
                return false;
            }

            let hazmatValid = true;

            for (const i in data.units) {
                const unit = data.units[i];
                if (typeof unit.products === "undefined" || _.isEmpty(unit.products)) {
                    this.props.setNotification("At least one handling unit is missing a product. Empty handling units cannot be present!", { variant: "error" });
                    return false;
                }

                unit.products.forEach(product => {
                    // If there's a value for any hazmat field on a product, assume that product is hazardous and ensure all required hazmat fields have values
                    if(
                        (product.hazmat.class != null && product.hazmat.class.length > 0) ||
                        (product.hazmat.emergency != null && product.hazmat.emergency.length > 0) ||
                        (product.hazmat.group != null && product.hazmat.group.length > 0) ||
                        (product.hazmat.packing_inst != null && product.hazmat.packing_inst.length > 0) ||
                        (product.hazmat.un_num != null && product.hazmat.un_num.length > 0)
                    ) {
                        if(product.hazmat.class == null || product.hazmat.class.length < 1) {
                            this.props.setNotification("Hazmat class is required for any hazardous product!", { variant: "error" });
                            hazmatValid = false;
                        }
                        if(!this.validatePhoneNumber(product.hazmat.emergency)) {
                            hazmatValid = false;
                        }
                        if(this.state.require_packing_group && (product.hazmat.group == null || product.hazmat.group.length < 1)) {
                            this.props.setNotification("Hazmat packing group is required for any hazardous product!", { variant: "error" });
                            hazmatValid = false;
                        }
                        if(product.hazmat.un_num == null || product.hazmat.un_num.length < 1) {
                            this.props.setNotification("Hazmat UN number is required for any hazardous product!", { variant: "error" });
                            hazmatValid = false;
                        }
                    }
                });
            }

            if(!hazmatValid){
                return false;
            }
        }

        data.general.blind = this.state.blind_shipment ? 1 : 0;

        if (!_.isEmpty(this.state.dgd_type)) {
            if (this.state.dgd_type == "ocean") {
                data.dgd = {
                    type: "ocean",
                    dgdDeclarant: this.state.dgd_ocean_declarant,
                    dgdPlace: this.state.dgd_ocean_place,
                    dgdDate: !_.isEmpty(this.state.dgd_ocean_date) ? moment(this.state.dgd_ocean_date).format("L") : "",
                    dgdTransDocNumber: this.state.dgd_ocean_trans_doc_number,
                    dgdFreightForwardsReference: this.state.dgd_ocean_freight_forwarders_reference,
                    dgdCarrier: this.state.dgd_ocean_carrier,
                    dgdVesselFlightAndDate: this.state.dgd_ocean_vessel_flight_and_date,
                    dgdPortPlaceHandling: this.state.dgd_ocean_port_place_handling,
                    dgdCTUNumber: this.state.dgd_ocean_ctu_number,
                    dgdSealNumber: this.state.dgd_ocean_seal_number,
                    dgdCTUSizeAndType: this.state.dgd_ocean_ctu_size_and_type,
                    dgdTareMass: this.state.dgd_ocean_tare_mass,
                    dgdReceivingRemarks: this.state.dgd_ocean_receiving_remarks,
                    dgdCompanyName: this.state.dgd_ocean_company_name,
                    dgdHaulersName: this.state.dgd_ocean_haulers_name,
                    dgdPreparingCompanyName: this.state.dgd_ocean_preparing_company_name,
                    dgdVehicleRegistrationNumber: this.state.dgd_ocean_vehicle_registration_number,
                    dgdDriverNameDate: this.state.dgd_ocean_driver_name_date
                };
            }

            if (this.state.dgd_type == "air") {
                data.dgd = {
                    type: "air",
                    dgdDeclarant: this.state.dgd_air_declarant,
                    dgdPlace: this.state.dgd_air_place,
                    dgdDate: !_.isEmpty(this.state.dgd_air_date) ? moment(this.state.dgd_air_date).format("L") : "",
                    dgdDepartureAirport: this.state.dgd_air_departure_airport,
                    dgdDestinationAirport: this.state.dgd_air_destination_airport,
                    dgdCargoOnly: this.state.dgd_air_cargo_only ? 1 : 0,
                    dgdRadioactive: this.state.dgd_air_radioactive ? 1 : 0,
                };
            }

            if (data.dgd) {
                // get DGD address stuff
                data.dgd.dgdUseCustomShipper = this.state.dgd_custom_shipper ? 1 : 0;
                data.dgd.dgdUseCustomConsignee = this.state.dgd_custom_consignee ? 1 : 0;

                if (data.dgd.dgdUseCustomShipper || data.dgd.dgdUseCustomConsignee) {
                    data.dgd.location = {};
                }

                if (data.dgd.dgdUseCustomShipper === 1) {
                    data.dgd.location.shipper = {
                        name: this.state.dgd_shipper_name || "",
                        address1: this.state.dgd_shipper_address1 || "",
                        address2: this.state.dgd_shipper_address2 || "",
                        city: this.state.dgd_shipper_city || "",
                        state: this.state.dgd_shipper_state || "",
                        zip: this.state.dgd_shipper_zip || "",
                        country: this.state.dgd_shipper_country || "",
                        contact_name: this.state.dgd_shipper_contact_name || "",
                        contact_phone: this.state.dgd_shipper_contact_phone || "",
                        contact_fax: this.state.dgd_shipper_contact_fax || "",
                        contact_email: this.state.dgd_shipper_contact_email || ""
                    };
                }

                if (data.dgd.dgdUseCustomConsignee === 1) {
                    data.dgd.location.consignee = {
                        name: this.state.dgd_consignee_name || "",
                        address1: this.state.dgd_consignee_address1 || "",
                        address2: this.state.dgd_consignee_address2 || "",
                        city: this.state.dgd_consignee_city || "",
                        state: this.state.dgd_consignee_state || "",
                        zip: this.state.dgd_consignee_zip || "",
                        country: this.state.dgd_consignee_country || "",
                        contact_name: this.state.dgd_consignee_contact_name || "",
                        contact_phone: this.state.dgd_consignee_contact_phone || "",
                        contact_fax: this.state.dgd_consignee_contact_fax || "",
                        contact_email: this.state.dgd_consignee_contact_email ||""
                    };
                }
            }
        }

        return data;
    }

    async executeBol(bolsToCancel, nonLccSelectionData, callback) {
        const payload = this.buildPayload(true);

        if (!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_total_declared_value == 1) {
            if (this.state.total_declared_value <= 0) {
                this.props.setNotification("Invalid value entered for total declared amount.", { variant: "error" });
                this.handleDeclaredValue();
                return false;
            }
        }

        if (!payload) {
            return false;
        }

        if (this.promptForDates(payload.transit.status, payload.transit.pickup_date, payload.transit.delivery_time, payload.transit.delivery_date, () => {this.executeBol(bolsToCancel, nonLccSelectionData);})) {
            return;
        }

        bolsToCancel = bolsToCancel || [];

        this.setState({
            bolsToCancel,
            executingBol: true,
            showGetPro: false
        });

        // if not previously executed
        if ((this.state.status === "" || this.state.status === "hold") && this.showTruecost()) {
            const isExpRequired = this.checkIfNonLccExplanationRequired(payload.carrier.carrier_scac); //returns LCC carries or false
            if (!_.isEmpty(isExpRequired) && _.isEmpty(nonLccSelectionData)) {
                if(!this.isLccRestrictedByUser(isExpRequired)) {
                    this.setState({ nonLccModal: true });
                }
                return;
            }
        }

        this.setState({ bolsToCancel: [] });

        // if Estes is the carrier and RES was selected, make sure a consignee name & email was provided
        if (payload.carrier.carrier_scac === "EXLA" && payload.accessorials.indexOf("RES") > -1) {
            const name = payload.location.consignee.contact_name;
            const email = payload.location.consignee.contact_email;

            if (_.isEmpty(name) || _.isEmpty(email)) {
                this.props.setNotification("Consignee NAME and EMAIL are required when shipping with ESTES and requesting a residential accessorial.", { variant: "error" });
                this.setState({ executingBol: false });
                return false;
            }
        }

        if (typeof bolsToCancel !== "undefined" && !_.isEmpty(bolsToCancel)) {
            payload.bolsToCancel = bolsToCancel;
        }
        if (typeof nonLccSelectionData !== "undefined") {
            payload.nonLccSelectionData = nonLccSelectionData;
        }

        // If selected carrier has bill to address in rater response that doesn't match, (1) update the bill to, (2) notify the user, and (3) call this function again

        let billTo = [];

        const chosenCarrierScac = payload.carrier.carrier_scac;

        this.state.carriers.forEach(carrierQuote => {

            if(chosenCarrierScac == carrierQuote.scac) {

                billTo = carrierQuote.bill_to_address;

            }

        })

        if(!_.isEmpty(billTo) && !this.state.bill_to_updated) {

            if(
                payload.location.billing.billing_name != billTo['billToName'] ||
                payload.location.billing.billing_address_1 != billTo['billToAddress1'] ||
                payload.location.billing.billing_address_2 != billTo['billToAddress2'] ||
                payload.location.billing.billing_city != billTo['billToCity'] ||
                payload.location.billing.billing_state != billTo['billToState'] ||
                payload.location.billing.billing_country != billTo['billToCountry'] ||
                payload.location.billing.billing_zip != billTo['billToZip']
            ) {

                this.props.setNotification("Please note the bill to address has been updated as necessary for this rate and should not be changed.",
                {
                    variant: "info"
                });

                this.setState({
                    billing_name: billTo['billToName'],
                    billing_address1: billTo['billToAddress1'],
                    billing_address2: billTo['billToAddress2'],
                    billing_city: billTo['billToCity'],
                    billing_state: billTo['billToState'],
                    billing_country: billTo['billToCountry'],
                    billing_zip: billTo['billToZip'],
                    bill_to_updated: true,
                }, () => {this.executeBol(bolsToCancel, nonLccSelectionData)});

                return false;

            }

        }

        let config = {};
        let data = {};

        if (!_.isEmpty(this.state.imageFiles)) {
            let imgCount = 0;
            data = new FormData();
            for (let i = 0; i < this.state.imageFiles.length; i++) {
                const file = this.state.imageFiles[i];
                if (file.preview) {
                    data.append("imgFile_" + i.toString(), file);
                    imgCount++;
                }
            }
            if (imgCount > 0) {
                data.append("form_data_post", JSON.stringify(payload));
                config = {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                };
            } else {
                data = qs.stringify(payload);
            }
        } else {
            data = qs.stringify(payload);
        }

        try {
            const response = await axios.post("/index.php?p=api&r=json&c=billoflading&m=execute", data, config);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && response.data.body) {
                let showCancel = false;
                let showUncancel = false;
                let showPickupRequest = false;
                let original_execution_date = "";
                let showPrintQuote = false;
                let showSendToTl = false;
                let carrier = {};
                let enable_executed_edit = false;
                let { loadId, quoteId } = this.state;

                const date = response.data.body.date;
                const bolId = response.data.body.bolId;
                const saved_bol_num = date + bolId;
                const status = response.data.body.status;

                if (response.data.body.status == "executed" || response.data.body.status == "ttl_received" || response.data.body.status == "third_party_payor") {
                    showCancel = true;
                    // attempt to turn auto tracking on
                    if(this.props.hasAutoTrackingPermission && this.state.auto_tracking) {
                        this.toggleAutoTracking();
                    }
                }
                if (response.data.body.status == "canceled") {
                    showUncancel = true;
                }

                // Remove all carriers except for chosen carriers for users
                // with the rate display option to only show chosen carrier
                if (!_.isEmpty(this.state.user) && this.props.hasSubmitPickupPermission) {
                    if (!_.isEmpty(this.state.scac) && !_.isEmpty(this.state.carriers)) {
                        carrier = this.state.carriers.filter(carrier => carrier.scac == this.state.scac)[0];
                        if (typeof carrier !== 'undefined' && (carrier.api_pickup_enabled || carrier.email_pickup_enabled)) {
                            showPickupRequest = true;
                        }
                    } else if (!_.isEmpty(this.state.scac) && this.state.data_type === "volume" && !_.isEmpty(this.state.volume_carriers)) {
                        let volCarriers = this.state.volume_carriers.filter(carrier => carrier.scac == this.state.scac)
                        if (volCarriers.length && volCarriers[0] && (volCarriers[0].api_pickup_enabled || volCarriers[0].email_pickup_enabled)) {
                            showPickupRequest = true;
                        }
                    }
                }

                if(this.state.data_type === 'truckload') {
                    showPickupRequest = false;
                }

                /**
                 * Load executed BOL here to handle updating the UI in the following ways:
                 *
                 * 1: If setting to update expected delivery date is turned on, update the expected delivery date field
                 * 2: If user has USE_TL_INTEGRATION permission, check handle showing button here:
                 *     a. Dont show "Send to TL" if the status is on "hold" or "canceled"
                 *     b. Dont show "Send to TL" if a load ID exists for the shipment
                 *     c. Show "Send to TL" if no load ID or quote ID exists for the shipment
                 * 3. Update the original execution date
                 */
                let expected_delivery_date = "";
                let original_edd = "";

                const bolResponse = await axios.get("/index.php?p=api&c=billoflading&m=load&lite=1&d=" + bolId + "/" + date);
                if (typeof bolResponse.data !== "string" && !_.isEmpty(bolResponse.data.message)) {
                    this.props.handleMessages(bolResponse);
                }
                if (typeof bolResponse.data !== "string" && !_.isEmpty(bolResponse.data.body) && !_.isEmpty(bolResponse.data.body.general)) {
                    const { general } = bolResponse.data.body;

                    quoteId = !_.isEmpty(general.truckstop_quote_id) ? general.truckstop_quote_id : quoteId;
                    loadId = !_.isEmpty(general.ttl_load_id) ? general.ttl_load_id : loadId;

                    original_execution_date = general.original_execution_date;

                    if (this.state.user.user_company_settings.auto_calc_expected_delivery == 1) {
                        expected_delivery_date = general.expected_delivery_date;
                        original_edd = general.original_edd;
                    }

                    if (this.props.hasTlIntegrationPermission) {
                        if (status == "hold" || status == "canceled" || status == "ttl_received") {
                            showSendToTl = false;
                        } else {
                            if (_.isEmpty(quoteId) || _.isEmpty(loadId)) {
                                showSendToTl = true;
                            } else {
                                showSendToTl = false;
                            }
                        }
                    }

                    enable_executed_edit = general.enable_executed_edit == 1 ? true : false;
                }

                this.setState({
                    executingBol: false,
                    bolData: response.data.body,
                    showExecute: false,
                    showSave: false,
                    showExecuteUpdate: true,
                    showTransitUpdate: true,
                    showGetPro: true,
                    showDraftPdf: false,
                    showPrint: true,
                    showPrintLabel: true,
                    showPrintQuote: (typeof payload.carrier.ctsi_query_id !== 'undefined' && payload.carrier.ctsi_query_id > 0 && !_.isEmpty(payload.carrier.carrier_scac)) ? true : false,
                    showSendAsn: true,
                    showPrintDgd: !_.isEmpty(this.state.dgd_type),
                    showPrintPkList: true,
                    showPickupRequest,
                    showCancel,
                    showUncancel,
                    saved_bol_num,
                    date,
                    bolId,
                    status,
                    expected_delivery_date,
                    original_edd,
                    loadId,
                    quoteId,
                    showSendToTl,
                    original_execution_date,
                    enable_executed_edit
                });

                if (!_.isEmpty(this.state.imageFiles)) {
                    this.loadSavedImages();
                }
                this.syncTransitStatus();

                if(typeof callback !== 'undefined'){
                    callback();
                }

            } else {
                this.props.setNotification("An error occurred executing the BoL!", { variant: "error" });
                this.setState({ executingBol: false });
                return false;
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("An exception occurred executing the BoL!", {
                variant: "error"
            });
            this.setState({ executingBol: false });
            return false;
        }
    }

    async getPro() {
        if (!_.isEmpty(this.state.pro_num)) {
            const response = confirm("There is a PRO # already applied to this BoL. Are you sure you want to overwrite it?");
            if (!response) {
                return false;
            }
        }

        const bolData = this.buildPayload(false);
        if (!bolData) {
            return false;
        }

        if (typeof bolData.carrier.carrier_scac === "undefined") {
            this.props.setNotification("You do not appear to have chosen a carrier. We are unable to provide a PRO without knowing which carrier will be used.", { variant: "error" });
            return false;
        }

        try {
            const response = await axios.get("/index.php?p=api&r=json&c=billoflading&m=getProNumber&d=" + bolData.carrier.carrier_scac + "/" + bolData.general.saved_bol);
            if (typeof response.data !== "string") {
                if (!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                if (!response.data.body) {
                    return false;
                }
                this.setState({
                    pro_num: response.data.body,
                    pro_book_flag: true,
                    proBookPro: response.data.body,
                }, () => {
                    this.beginExecution(true);
                });
            } else {
                this.props.setNotification("An error occurred fetching the PRO!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("An error occurred fetching the PRO!", {
                variant: "error"
            });
            return false;
        }

        return false;
    }

    handleConversion() {
        let { feet, inches } = this.state;
        if (inches.length && !feet) {
            feet = (parseFloat(inches) / 12).toFixed(1);
        } else if (feet.length) {
            inches = (parseFloat(feet) * 12).toFixed(1);
        }
        this.setState({ feet, inches });
    }

    handleSurfaceArea(name, event) {
        this.setState({ [name]: event.target.value }, () => {
	        const { productLength, productWidth, palletLength, palletWidth } = this.state;
	        if (!_.isEmpty(palletLength) && !_.isEmpty(palletWidth) && !_.isEmpty(productLength) && !_.isEmpty(productWidth)) {
	            let surfaceArea = ((parseFloat(productLength) * parseFloat(productWidth)) / (parseFloat(palletLength) * parseFloat(palletWidth))) * 100;
	            surfaceArea = isNaN(surfaceArea) ? 0 : surfaceArea.toFixed(1);
	            this.setState({ surfaceArea });
	        } else {
	            this.setState({ surfaceArea: 0 });
	        }
        });
    }

    getUnits(data) {
        return data.map((prop, key) => {
            return [
                prop.label,
                prop.type,
                prop.stack == 1 ? "Yes" : "No",
                prop.pieces,
                prop.length,
                prop.width,
                prop.height,
                <div className="actions-right">
                    <Button size="sm" color="linkedin" onClick={() => this.handleUnitSelect(key)}>
                        Select
                    </Button>
                </div>
            ];
        });
    }

    getProducts(products) {
        const { classes } = this.props;
        const enableProductPartNo = this.state.enable_product_part_no;
        return products.map((product, key) => {
            let row = [
                <div dangerouslySetInnerHTML={{ __html: product.code }} />,
                <div dangerouslySetInnerHTML={{ __html: product.name }} />,
                <div dangerouslySetInnerHTML={{ __html: product.nmfc }} />,
                <div dangerouslySetInnerHTML={{ __html: product.sub_nmfc }} />,
                <div dangerouslySetInnerHTML={{ __html: product.pClass }} />,
                <div dangerouslySetInnerHTML={{ __html: product.weight }} />,
                <div dangerouslySetInnerHTML={{ __html: product.hazmat }} />,
                <div className="actions-right">
                    <Button size="sm" color="linkedin" onClick={() => this.handleProductAlert(key, product.id)}>
                        Select
                    </Button>
                </div>
            ];
            if(enableProductPartNo == 1) {
                row.splice(1, 0, (
                    <div dangerouslySetInnerHTML={{ __html: product.part_no }} />
                ));
            }
            return  product.is_parcel == 0 ? row : [];
        });
    }

    async handleProductSearch(i, j) {
        const { warehouse, units } = this.state;
        const product = units[i].products[j];

        const url = "/index.php?p=api&r=json&c=product&m=find&d=" + warehouse + "/" + product.description;

        try {
            const response = await axios.get(url);

            if (typeof response.data !== "string") {

                if (response.data.body.length == 1 && response.data.body[0].is_parcel != 1) {

                    if(response.data.body[0].alert_message != null && response.data.body[0].alert_message.length > 0) {

                        this.setState({
                            units,
                            products: response.data.body,
                            productUnitKey: i,
                            productKey: j,
                        }, this.openAlert(response.data.body[0].alert_message, () => {this.handleProductSelect(response.data.body[0].id)}));

                    } else {

                        this.setState({
                            products: response.data.body,
                            productUnitKey: i,
                            productKey: j,
                        }, () => {this.handleProductSelect(response.data.body[0].id)});

                    }

                } else if (response.data.body.length > 1) {

                    this.setState({
                        products: response.data.body,
                        productUnitKey: i,
                        productKey: j,
                        productSearchModal: true
                    });

                } else {
                    this.props.setNotification("No products found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching products!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching products!", { variant: "error" });
        }
    }

    handleProductAlert(key, productId) {
        const { products } = this.state;
		if(products[key].alert_message != null && products[key].alert_message.length > 0) {
			this.openAlert(products[key].alert_message, () => {this.handleProductSelect(productId)});
		} else {
			this.handleProductSelect(productId);
		}
    }

    async handleProductSelect(productId) {

        const { warehouse, productUnitKey, productKey } = this.state;

        const url = "/index.php?p=api&r=json&c=product&m=load&d=" + warehouse + "/" + productId;

        try {

            const response = await axios.get(url);

            if (typeof response.data !== "string") {

                if(!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }

                const units = _.cloneDeep(this.state.units);

                const productSelect = {};

                Object.keys(response.data.body).map(key => {

                    const prop = response.data.body[key];

                    if (prop) {
                        productSelect[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
                    } else {
                        productSelect[key] = ''; // Added to avoid undefined index (e.g. if no weight) when saving BOL.
                    }

                });

                const product = units[productUnitKey].products[productKey];

                product.nmfc = productSelect.nmfc;
                product.sub_nmfc = productSelect.sub_nmfc;
                product.class = productSelect.class;
                product.description = productSelect.name;
                product.weight = productSelect.weight;
                product.net_weight = productSelect.net_weight;
                product.declared_value = productSelect.declared_value;
                product.part_no = productSelect.part_no;
                product.uom = productSelect.unit;
                product.hazardous = false;
                product.hazmat.overpackUsed = false;
                product.hazmat.class = '';
                product.hazmat.un = '';
                product.hazmat.packingGroup = '';
                product.hazmat.emergencyNumber = '';
                product.hazmat.packingInstructions = '';

                if (productSelect.haz_class.length > 0 ||
                    productSelect.un_num.length > 0 ||
                    productSelect.group.length > 0 ||
                    productSelect.emergency.length > 0 ||
                    productSelect.packing_inst.length > 0) {

                    product.hazardous = true;
                    product.hazmat.class = productSelect.haz_class;
                    product.hazmat.un = productSelect.un_num;
                    product.hazmat.packingGroup = productSelect.group;
                    product.hazmat.emergencyNumber = productSelect.emergency;
                    product.hazmat.packingInstructions = productSelect.packing_inst;

                }

                units[productUnitKey].products[productKey] = product;
                //auto select haz
                let accessorials = this.state.accessorials;
                if (product.hazardous === true) {
                    accessorials.map((acc, index) => {
                        if (acc.code == "420") {
                            accessorials[index].checked = true;
                        }
                    });
                }

                this.clearCarriersAndSetState({
                    units,
                    products: [],
                    productUnitKey: "",
                    productKey: "",
                    productSearchModal: false,
                    classification: false,
                    accessorials: [...accessorials],
                    location_types: [],
                    recommended_accessorials: [],
                });

                this.updateTotals(true);
                this.checkLiability();

            } else {

                this.props.setNotification("There was an error loading the product!", {variant: "error"});

            }

        } catch (error) {

            console.error(error);

            this.props.setNotification("There was an exception loading the product!", { variant: "error" });

        }

    }

    async handleUnitSearch(i) {
        const { warehouse } = this.state;
        const url = "/index.php?p=api&r=json&c=handUnit&m=catalog&d=" + warehouse;
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                const handling_units = Object.values(response.data.body); // convert body object to array
                handling_units.pop(); // remove warehouse from the end
                if (handling_units.length == 1) {
                    const { units } = this.state;
                    const unitSelect = handling_units[0];
                    const unit = units[i];

                    unit.type = unitSelect.type;
                    unit.pieces = unitSelect.pieces;
                    unit.stackable = unitSelect.stack == 1;
                    unit.length = unitSelect.length;
                    unit.width = unitSelect.width;
                    unit.height = unitSelect.height;

                    units[i] = unit;

                    this.setState({
                        units,
                        unitKey: "",
                        handling_units: [],
                        unitSearchModal: false
                    });
                    this.updateTotals(true);
                } else if (handling_units.length > 1) {
                    this.setState({
                        handling_units,
                        unitKey: i,
                        unitSearchModal: true
                    });
                } else {
                    this.props.setNotification("No handling units found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching handling units!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error searching handling units!", { variant: "error" });
        }
    }

    handleUnitSelect(unitSelectKey) {
        const { handling_units, units, unitKey } = this.state;
        const unit = units[unitKey];
        const unitSelect = handling_units[unitSelectKey];

        unit.type = unitSelect.type;
        unit.pieces = unitSelect.pieces;
        unit.stackable = unitSelect.stack == 1;
        unit.length = unitSelect.length;
        unit.width = unitSelect.width;
        unit.height = unitSelect.height;

        units[unitKey] = unit;

        this.clearCarriersAndSetState({
            units,
            unitKey: "",
            handling_units: [],
            unitSearchModal: false,
            classification: false,
        });
        this.updateTotals(true);
    }

    getManualCarriers(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.scac}
                >
                    {prop.carrier}
                </MenuItem>
            );
        });
    }

    showVolumeTab() {
        return (
            (!_.isEmpty(this.state.user) && this.props.hasVolumeQuotePermission) &&
            (['US','USA','CA','CAN'].includes(this.state.shipper_country) && ['US','USA','CA','CAN'].includes(this.state.consignee_country))
        );
    }

    showTruckloadTab() {
        return (
            (!_.isEmpty(this.state.body) &&
            this.state.body.enable_tl_quote == 1 &&
            !_.isEmpty(this.state.user) &&
            this.state.showTruckloadTab &&
            this.props.hasTlQuotePermission) &&
            (['US','USA','CA','CAN'].includes(this.state.shipper_country) && ['US','USA','CA','CAN'].includes(this.state.consignee_country))
        );
    }

    async getTruckloadQuote() {
        if (_.isEmpty(this.state.tl_equipment)) {
            this.props.setNotification("Please select an equipment category to get a truckload quote.", { variant: "error" });
            this.setState({ loadingCarriers: false });
            return false;
        }

        const required = ["shipper_city", "shipper_state", "shipper_country", "consignee_city", "consignee_state", "consignee_country"];

        let addressData = true;

        for (const value of required) {
            if (_.isEmpty(this.state[value])) {
                addressData = false;
                break;
            }
        }

        if (addressData === false) {
            this.props.setNotification("Please provide city, state, and country for the shipper and consignee.", { variant: "error" });
            this.setState({ loadingCarriers: false });
            return false;
        }

        this.clearCarriersAndSetState({
            showQuote: true,
            loadingQuote: true,
        });
        // Not currently supporting Mexico here
        const data = {
            shipCity: this.state.shipper_city,
            shipState: this.state.shipper_state,
            shipCountry: this.state.shipper_country && this.state.shipper_country.length > 0 ? this.state.shipper_country : "US",
            consCity: this.state.consignee_city,
            consState: this.state.consignee_state,
            consCountry: this.state.consignee_country && this.state.consignee_country.length > 0 ? this.state.consignee_country : "US",
            equipment: this.state.tl_equipment
        };

        try {
            const response = await axios.post("/index.php?p=api&r=json&c=rater&m=tlQuote", qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response)
            }
            if (typeof response.data !== "string") {
                if (!_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.quote)) {
                    this.setState({
                        loadingQuote: false,
                        quoteId: response.data.body.quoteId.toString(),
                        quote: response.data.body.quote
                    });
                } else {
                    this.setState({ loadingQuote: false });
                    this.props.setNotification("No quote was found!", { variant: "info" });
                }
            } else {
                this.setState({ loadingQuote: false });
                this.props.setNotification("There was an error getting a truckload quote!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ loadingQuote: false });
            this.props.setNotification("There was an error getting a truckload quote!", { variant: "error" });
        }
    }

    addNote() {
        let { canned_note, notes } = this.state;
        if (!_.isEmpty(canned_note)) {
            notes += "\n" + canned_note;
            this.setState({ notes });
        }
    }

    async handleUpload(files) {

        if(!_.isEmpty(this.state.saved_bol_num)) {

            const data = new FormData();

            for (let i = 0; i < files.length; i++) {
                data.append("imgFile_" + i.toString(), files[i]);
            }

            const config = {
                headers: {
                    "content-type": "multipart/form-data"
                }
            };

            const imgResponse = await axios.post("/index.php?p=api&c=billoflading&m=uploadImg&d=" + this.state.saved_bol_num, data, config);

            if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.message)) {

                this.props.handleMessages(imgResponse);

            }

            if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.body) && imgResponse.data.body.uploadedImages) {

                this.loadSavedImages();

            } else {

                this.props.setNotification("There was an error saving images to the BoL!", { variant: "error" });

            }


        } else {

            this.setState({ imageFiles: this.state.imageFiles.concat(files) });

        }

    }

    async saveDraft() {
        const payload = this.buildPayload();
        const isNew = _.isEmpty(this.state.saved_bol_num);
        const trackingEmailSet = [... new Set(
            this.state.additional_tracking_emails.filter(
                email => email.trim() != ""
            )
        )];

        if (this.promptForDates(payload.transit.status, payload.transit.pickup_date, payload.transit.delivery_time, payload.transit.delivery_date, () => {this.saveDraft;})) {
            return;
        }

        let uploadingImages = false;
        let config = {};
        let data = {};

        if (!_.isEmpty(this.state.imageFiles)) {
            let imgCount = 0;
            data = new FormData();
            for (let i = 0; i < this.state.imageFiles.length; i++) {
                const file = this.state.imageFiles[i];
                if (file.preview) {
                    data.append("imgFile_" + i.toString(), file);
                    imgCount++;
                }
            }
            if (imgCount > 0) {
                uploadingImages = true;
                data.append("form_data_post", JSON.stringify(payload));
                config = {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                };
            } else {
                data = qs.stringify(payload);
            }
        } else {
            data = qs.stringify(payload);
        }

        try {
            const response = await axios.post("/index.php?p=api&r=json&c=billoflading&m=save", data, config);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                const { date, bolId, status } = response.data.body;
                const saved_bol_num = date + "" + bolId;

                let showCancel = false;
                let showUncancel = false;

                if (response.data.body.status == "executed" || response.data.body.status == "ttl_received" || response.data.body.status == "third_party_payor") {
                    showCancel = true;
                }
                if (response.data.body.status == "canceled") {
                    showUncancel = true;
                }

                //if the trackingEmailSet is less than that of additional_tracking_emails,
                // we should notify the user that some have been removed.
                if(trackingEmailSet.length != this.state.additional_tracking_emails.length) {
                    this.props.setNotification("Duplicate Tracking Emails have been removed!", { variant: "warning" });
                }

                this.setState({
                    loadId: null,
                    showDraftPdf: true,
                    showPrintDgd: !_.isEmpty(this.state.dgd_type),
                    showSave: true,
                    showCancel,
                    showUncancel,
                    saved_bol_num,
                    status,
                    date,
                    bolId,
                    //remove duplicates here because they would have been removed in the backend save().
                    additional_tracking_emails: trackingEmailSet,
                });
                this.props.scrollTop(80);
                this.props.pageTitle('Bill of Lading - ' + date + bolId);
                if (uploadingImages && !isNew) {
                    data = new FormData();
                    config = {
                        headers: {
                            "content-type": "multipart/form-data"
                        }
                    };
                    for (let i = 0; i < this.state.imageFiles.length; i++) {
                        data.append("imgFile_" + i.toString(), this.state.imageFiles[i]);
                    }
                    const imgResponse = await axios.post("/index.php?p=api&c=billoflading&m=uploadImg&d=" + saved_bol_num, data, config);
                    if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.message)) {
                        this.props.handleMessages(imgResponse);
                    }
                    if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.body) && imgResponse.data.body.uploadedImages) {
                        this.loadSavedImages();
                    } else {
                        this.props.setNotification("There was an error saving images to the BoL!", { variant: "error" });
                    }
                }

                this.syncTransitStatus();
            } else {
                this.props.setNotification("There was an error saving the BoL!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an exception saving the BoL!", { variant: "error" });
        }
    }

    handleSaveAsNew() {
        if(this.state.pro_num !== "") {
            this.setState({saveAsNewModal: true});
        } else {
            this.saveAsNew();
        }
    }

    async saveAsNew(copyPro = true) {

    	// Store these values before defaulting them so they can be restored on failure
    	const {carriers, volume_carriers, max_weight, showCarriers, showVolumeCarriers, loadingCarriers, quote, quoteId, showQuote, loadingQuote,
    		scac, classification, bill_to_updated, non_program_carrier, non_program_rate, data_type, showTruckloadTab, body } = this.state;

    	// Use this to determine whether to restore state to avoid duplicate code
		let hasError = false;

        this.clearCarriersAndSetState({
            scac: "",
            classification: false,
            bill_to_updated: false,
            saveAsNewModal: false,
        });

        const payload = this.buildPayload();

        // empty transit data
        payload.transit = {
            status: "",
            pickup_date: "",
            expected_delivery_date: "",
            delivery_date: "",
            delivery_time: ""
        };

        if(!copyPro) {
            payload.general.pro = "";
            this.setState({
                pro_num: "",
            });
        }

        let copyImages = false;
        const isNew = _.isEmpty(this.state.saved_bol_num);

        if (!_.isEmpty(this.state.imageFiles) && !isNew) {
            if (confirm("Would you like to copy attached files to the new BoL?")) {
                copyImages = true;
            }
        }

        let uploadingImages = false;
        let config = {};
        let data = {};

        if (isNew && !_.isEmpty(this.state.imageFiles)) {
            let imgCount = 0;
            data = new FormData();
            for (let i = 0; i < this.state.imageFiles.length; i++) {
                const file = this.state.imageFiles[i];
                if (file.preview) {
                    data.append("imgFile_" + i.toString(), file);
                    imgCount++;
                }
            }
            if (imgCount > 0) {
                uploadingImages = true;
                data.append("form_data_post", JSON.stringify(payload));
                config = {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                };
            } else {
                data = qs.stringify(payload);
            }
        } else {
            data = qs.stringify(payload);
        }

        try {

            const response = await axios.post("/index.php?p=api&r=json&c=billoflading&m=saveAs", data, config);

            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }

            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {

                const { date, bolId, status } = response.data.body;
                const saved_bol_num = date + bolId;
                body.carriers = [];

                let showCancel = false;
                let showUncancel = false;
                const old_bol_num = this.state.saved_bol_num;

                if (response.data.body.status == "executed" || response.data.body.status == "ttl_received" || response.data.body.status == "third_party_payor") {
                    showCancel = true;
                }
                if (response.data.body.status == "canceled") {
                    showUncancel = true;
                }

                this.setState({
                    confirmation_number: "",
                    transaction_id: "",
                    pickup_window_start: "",
                    pickup_window_end: "",
                    pickup_confirm: false,
                    transit_status: "",
                    transit_sub_status: "",
                    late_pickup_reason: "",
                    late_delivery_reason: "",
                    pickup_date: "",
                    original_pd: "",
                    non_program_carrier: "",
                    non_program_rate: "",
                    data_type: "ltl",
                    original_execution_date: "",
                    expected_delivery_date: "",
                    original_edd: "",
                    delivery_date: "",
                    delivery_time: "",
                    executingBol: false,
                    showExecute: false,
                    showSave: true,
                    showExecuteUpdate: false,
                    showTransitUpdate: false,
                    showGetPro: false,
                    showDraftPdf: true,
                    showPrint: false,
                    showPrintQuote: false,
                    showPrintLabel: false,
                    showSendAsn: false,
                    showPrintDgd: false,
                    showPrintPkList: false,
                    showPickupRequest: false,
                    showTruckloadTab: false,
                    showSendToTl: false,
                    loadId: null,
                    autoSendTracking: false,
                    trackingSent: false,
                    status,
                    showCancel,
                    showUncancel,
                    saved_bol_num,
                    date,
                    bolId,
                    p44DispatchCanceled: false,
                    enable_executed_edit: false,
                    body
                });

                this.props.scrollTop(80);
                this.props.pageTitle('Bill of Lading - ' + date + bolId);
                if (!_.isEmpty(this.state.imageFiles)) {
                    if (isNew && uploadingImages) {
                        this.loadSavedImages();
                    } else if (copyImages) {
                        const copyResponse = await axios.get("/index.php?p=api&c=billoflading&m=copyImages&oldBol=" + old_bol_num + "&newBol=" + saved_bol_num);
                        if (typeof copyResponse !== "string") {
                            if (!_.isEmpty(copyResponse.data.message)) {
                                this.props.handleMessages(copyResponse);
                            }
                            if (copyResponse.data.body && copyResponse.data.body.copied) {
                                this.loadSavedImages();
                            }
                        } else {
                            this.props.setNotification("There was an error copying images!", { variant: "error" });
                        }
                    }
                }

                var exceptPro = "";
                if(!copyPro) {
                    exceptPro = " (except Pro)";
                }
	            if (this.state.pro_num !== "" || this.state.bol_num !== "" || this.state.po_num !== "" || this.state.so_num !== "" || this.state.ref_num !== "" || this.state.custom_date !== "") {
	                this.props.setNotification("All fields including references in the General Information panel were copied to create the new bill of lading" + exceptPro + ".", { variant: "info" });
	            }

            } else {

            	hasError = true;
                this.props.setNotification("There was an error saving the BoL!", { variant: "error" });

            }

        } catch (error) {
            console.error(error);
            hasError = true;
            this.props.setNotification("There was an unexpected error saving the BoL!", { variant: "error" });
        }

    	// On failure, restore prior state
        if(hasError) {
        	this.setState({
        		carriers,
        		volume_carriers,
        		max_weight,
        		showCarriers,
        		showVolumeCarriers,
        		loadingCarriers,
                non_program_carrier,
                non_program_rate,
                data_type,
                showTruckloadTab,
        		quote,
        		quoteId,
        		showQuote,
        		loadingQuote,
	    		scac,
                classification,
                bill_to_updated,
                body
	    	});
        }

    }

    handleRequireEmail() {
        this.setState({
            requireEmailModal: true,
        });
    }

    executeFromConsEmailModal = async() =>
    {
        this.handleModalClose("requireEmailModal");
        this.executeBol(null,null,this.updateOrResendTracking);

    }

    async cancelUpdateConsigneeEmail()
    {
        this.state.consignee_contact_email = "";
        this.handleModalClose("requireEmailModal");
    }


    async loadSavedImages(bol) {
        const url = "/index.php?p=api&c=billoflading&m=imageCollection&d=" + this.state.saved_bol_num;
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.images)) {
                this.setState({ imageFiles: response.data.body.images });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the images!", { variant: "error" });
        }
    }

    submitToTruckload() {
        let hasError = false;

        if (_.isEmpty(this.state.shipper_contact_name)) {
            this.props.setNotification("Please provide a name for the customer to be contacted!", { variant: "error" });
            hasError = true;
        }
        if (_.isEmpty(this.state.shipper_contact_phone) || _.isEmpty(this.state.shipper_contact_email)) {
            this.props.setNotification("Please provide a phone number or email for the customer to be contacted!", { variant: "error" });
            hasError = true;
        }
        if (!hasError) {
            this.beginExecution();
        }
    }

    async handleZip(name, zip, country = null) {
        zip = zip.replace(" ", "").toUpperCase();
		let isNum = false;
		isNum = !(Number.isNaN(zip) || Number.isNaN(parseInt(zip)));
		if (!_.isEmpty(zip) && ((isNum) && zip.length >= 5) || zip.length >= 6) {
            try {
                let url = "/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + encodeURIComponent(zip);
				url += (country != '' && country != null) ? `/${convertToCountryCode(country, 'long')}` : ``;
                const response = await axios.get(url);
                if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                    const { body } = response.data;
                    if (body[zip]) {
						if (!["USA","CAN","MEX"].includes(body[zip][0].country)) {
                            this.setState({ loading: false });
                            this.props.setNotification("Contact us for information regarding this unsupported international shipment.", { variant: "error" });
                        }
                        this.setState({
                            [name + "_city"]: body[zip][0].city,
                            [name + "_state"]: body[zip][0].state,
                            [name + "_country"]: convertToCountryCode(body[zip][0].country, 'short'),
                        }, () => {
                            this.showVolumeTab();
                            this.showTruckloadTab();
                        });
                    } else {
                        this.props.setNotification("Postal code could not be found. Postal code search is supported with US, CA, and MX.", { variant: "error" });
                    }
                } else {
                    this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
                }
            } catch (error) {
                console.error(error);
                this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
            }
        }
    }

    handleLcc() {
        if (!this.checkLiability()) {
            this.props.setNotification("Your products are missing information that is required for carrier liability rates!", { variant: "warning" });
        }
        if (!_.isEmpty(this.state.company) && this.state.company.check_residential == '1') {
            const { accessorials } = this.state;
            const residential = accessorials.filter(accs => accs.code == "RES" && !accs.checked);
            if (residential.length) {
                if (this.state.direction === "Third Party") {
                    this.checkResidential(true);
                } else {
                    this.checkResidential(false);
                }
            } else {
                this.findCarriers();
            }
        } else {
            this.findCarriers();
        }
    }

    async checkResidential(thirdParty) {
        const street = encodeURIComponent(this.state.consignee_address1);
        const zip = _.trim(this.state.consignee_zip);
        if (_.isEmpty(zip) || _.isEmpty(street)) {
            this.props.setNotification("Consignee residential address verification cannot be performed without an address. Rating will continue, but accessorial charges may not be accurate.", { variant: "warning" });
        }
        try {
            const url = "/street-address?auth-id=13814496317761852&street=" + street + "&zipcode=" + encodeURIComponent(zip) + "&candidates=2";
            const response = await axios.get(url, {
                withCredentials: false,
                baseURL: "https://api.smartystreets.com"
            });
            if (typeof response.data !== "string" && !_.isEmpty(response.data)) {
                if (response.data[0].metadata.rdi === "Residential") {
                    if (confirm(response.data[0].delivery_line_1 + " is a residential location.\n\n Press Okay to add RES as an accessorial. \n\n")) {
                        const accessorials = this.state.accessorials.map((prop, key) => {
                            if (prop.code == "RES") {
                                prop.checked = true;
                            }
                            return prop;
                        });
                        this.setState({ accessorials });
                    }
                }
            } else {
                this.props.setNotification("Unable to determine if Consignee is residential, be advised that while rates may still return, accessorial pricing may not be accurate. Please contact us to report this error!", { variant: "error" });
            }
            if (thirdParty === true) {
                this.thirdPartyRes();
            } else {
                this.findCarriers();
            }
        } catch (error) {
            console.error(error);
            if (thirdParty === true) {
                this.thirdPartyRes();
            } else {
                this.findCarriers();
            }
        }
    }

    async thirdPartyRes() {
        const street = encodeURIComponent(this.state.shipper_address1);
        const zip = _.trim(this.state.shipper_zip);
        if (!_.isEmpty(zip) || !_.isEmpty(street)) {
            this.props.setNotification("Shipper residential address verification cannot be performed without an address. Rating will continue, but accessorial charges may not be accurate.", { variant: "warning" });
        }
        try {
            const url = "https://api.smartystreets.com/street-address?auth-id=13814496317761852&street=" + street + "&zipcode=" + encodeURIComponent(zip);
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data)) {
                if (response.data[0].metadata.rdi === "Residential") {
                    if (confirm(response[0].delivery_line_1 + " is a residential location.\n\n Press Okay to add RES as an accessorial. \n\n")) {
                        const accessorials = this.state.accessorials.map((prop, key) => {
                            if (prop.code == "RES") {
                                prop.checked = true;
                            }
                            return prop;
                        });
                        this.setState({ accessorials });
                    }
                }
            } else {
                this.props.setNotification("Unable to determine if Shipper is residential, be advised that while rates may still return, accessorial pricing may not be accurate. Please contact us to report this error!", { variant: "error" });
            }
            this.findCarriers();
        } catch (error) {
            console.error(error);
            this.findCarriers();
        }
    }

    checkIfNonLccExplanationRequired(chosenScac) {
        const required = this.state.company.non_lcc_tracking.required == 1;
        if (!required) {
            return false;
        }
        if (this.state.data_type !== "ltl") {
            return false;
        }

        const carrierRowData = [];

        for (const carrier of this.state.body.carriers) {
            if(!_.isEmpty(carrier.hide)) {
                continue;
            }
            const { fmt_true_cost } = carrier.rate;
            const trueCost = parseFloat(fmt_true_cost.replace("$", "").replace(",", ""));
            if (isNaN(trueCost)) {
                continue;
            }
            if (_.isEmpty(carrier.scac)) {
                continue;
            }
            carrierRowData.push({
                trueCost,
                scac: carrier.scac
            });
        }

        /**
         * If we can't find any true costs, we don't need an explanation because we don't know
         * if the user selected the LCC or not.
         */
        if (!carrierRowData.length) {
            return false;
        }

        // array of scacs
        const lccs = [];

        // LCC always the first row
        const leastTrueCost = carrierRowData[0].trueCost;

        for (const prop of carrierRowData) {
            if (prop.trueCost <= leastTrueCost) {
                lccs.push(prop.scac);
            }
        }

        if (lccs.indexOf(chosenScac) > -1) {
            return false;
        }

        // return the list of LCCs to determine if any were restricted by the user.
        return lccs;
    }

    isLccRestrictedByUser(lccScacs) {
        if(_.isEmpty(this.state.allowed_carriers)){
            return false;
        }
        // check if LCC is included in restricted carriers
        let scacFound = false;
        lccScacs.forEach((scac) => {
            if(this.state.allowed_carriers.includes(scac)) {
                scacFound = true;
                return;
            }
        })

        // if found, require the user to enter the nonLcc selection reason.
        if(scacFound) return false;

        // Otherwise excute with custom nonLCC reason data
        this.executeBol(this.state.bolsToCancel, {
            option: 'AUTO_RESTRICTED',
            custom: '',
            skipEmail: true //Skip sending email if user was configured to do so
        });

        return true;
    }

    beginExecution() {
        const { references, required } = this.state;
        let cancelExec = false;

        // make sure the shipper and consignee contacts at least have a phone number or email
        if (this.state.data_type == "truckload" && _.isEmpty(this.state.query_id)) {
            if (_.isEmpty(this.state.shipper_contact_phone) && _.isEmpty(this.state.shipper_contact_email)) {
                this.props.setNotification("Please provide a phone number or email for the shipper to be contacted.", { variant: "error" });
                cancelExec = true;
            }

            if (_.isEmpty(this.state.consignee_contact_phone) && _.isEmpty(this.state.consignee_contact_email)) {
                this.props.setNotification("Please provide a phone number or email for the consignee to be contacted.", { variant: "error" });
                cancelExec = true;
            }
        }

        for (const input in references) {
            if(references[input].required == 1 && _.isEmpty(references[input].value)) {
                this.props.setNotification("Your warehouse requires a value for the " + references[input].type + " custom reference type.", { variant: "error" });
                cancelExec = true;
            }
        }

        for (const input in required.general_info) {
            if (_.isEmpty(this.state[input])) {
                this.props.setNotification("Your organization requires the " + required.general_info[input] + " field to be filled.", { variant: "error" });
                cancelExec = true;
            }
        }

        for (const input in required.shipment_details) {
            if (_.isEmpty(this.state[input])) {
                this.props.setNotification(required.shipment_details[input] + " cannot be empty.", { variant: "error" });
                cancelExec = true;
            }
        }

        if (cancelExec) {
            return false;
        }

        /* if($('#carriers').find('input[name=carrier]:checked').parent().parent().hasClass('cell-light-orange')) {

            var res = confirm("Non-program carriers can only be used for up to 10% of your LTL shipments and should only be selected with your Manager’s approval.\n\nClick OK if you have the necessary approval.\nClick Cancel to choose a Program carrier or to wait until you have approval for this action.");

            if(!res) {

                setError('Canceled Execute', 'You chose not to execute this BoL at this time. It was not saved.', 'warning');

                return false;

            }

        } */

        if ((_.isEmpty(this.state.status) || this.state.status === "hold") && this.state.data_type != "truckload") {
            if (!_.isEmpty(this.state.user) && this.state.user.user_company_settings.show_execute_warning == 1) {
                const res = confirm('This will change the BoL Status to "Executed." Once done, this cannot be undone, and future changes cannot be saved.\n\nTo continue, click OK, to review or make changes click Cancel.');
                if (!res) {
                    this.props.setNotification("You chose not to execute this BoL at this time. It was not saved.", { variant: "warning" });
                    return false;
                }
            }
        }

        if (this.state.data_type == "ltl") {
            if (_.isEmpty(this.state.scac) && _.isEmpty(this.state.query_id)) {
                // check for a quote
                if (_.isEmpty(this.state.scac)) {
                    this.props.setNotification("No LTL carrier selected. You first need to find the LTL carriers and select one before executing a bill of lading.", { variant: "error" });
                    return false;
                }
            }
        } else if (this.state.data_type == "manual") {
            if (_.isEmpty(this.state.non_program_carrier)) {
                this.props.setNotification("A carrier needs to be chosen from the list of Non-Program Carriers in the dropdown to execute a bill of lading.", { variant: "error" });
                return false;
            }
        }

        if (!_.isEmpty(this.state.body) && this.state.body.prevent_dup_references && !_.isEmpty(this.state.saved_bol_num)) {
            this.checkForDuplicatesByReference();
        } else {
            this.executeBol();
        }
    }

    async transitUpdate() {
        if (_.isEmpty(this.state.saved_bol_num)) {
            return false;
        }

        const { bolId, date } = this.state;

        const data = {
            transit_status: this.state.transit_status,
            transit_sub_status: this.state.transit_sub_status,
            late_pickup_reason: this.state.late_pickup_reason,
            late_delivery_reason: this.state.late_delivery_reason,
            pickup_date: !_.isEmpty(this.state.pickup_date) ? moment(this.state.pickup_date).format("LLL") : "",
            expected_delivery_date: !_.isEmpty(this.state.expected_delivery_date) ? moment(this.state.expected_delivery_date).format("LLL") : "",
            delivery_date: !_.isEmpty(this.state.delivery_date) ? moment(this.state.delivery_date).format("LLL") : "",
            delivery_time: this.state.delivery_time,
        };

        if(!_.isEmpty(data.delivery_time) && !moment(data.delivery_time, "HH:mm:ss", true).isValid()) {
            this.props.setNotification("Invalid delivery time", { variant: "error" });
            return;
        }

        if (this.promptForDates(data.transit_status, data.pickup_date, data.delivery_time, data.delivery_date, () => {this.transitUpdate();})) {
            return;
        }

        try {
            const url = "/index.php?p=api&c=billoflading&m=saveTransitInfo&d=" + bolId + "/" + date;
            const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if(response.data.body && response.data.body.result && response.data.body.result == 1) {
                this.syncTransitStatus();
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error updating the transit!", { variant: "error" });
        }
    }

    /**
     * Get transit status from backend and update here
     *
     * this is to reflect when the transit status is automatically updated on the backend
     */
    syncTransitStatus = async () => {
        if (_.isEmpty(this.state.saved_bol_num)) {
            return false;
        }
        const { bolId, date } = this.state;
        try {
            const bol = await axios.post("/index.php?p=api&c=billoflading&m=load&lite&d=" + bolId + "/" + date);
            if (typeof bol.data !== "string" && !_.isEmpty(bol.data.message)) {
                this.props.handleMessages(bol);
            }
            if(bol.data.body && bol.data.body.general) {
                this.setState({transit_status: bol.data.body.general.transit_status});
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error retrieving BOL information!", { variant: "error" });
        }
    }

    /**
     * For preventing saving a BOL with a delivery time or a transit status Delivered and no delivery date
     *
     * if the user is performing an action that saves the BOL in this manner,
     * open the delivery date prompt and set the callback to the original action
     * that the user was attempting to perform i.e. save draft, execute, save transit fields, etc.
     */
    promptForDeliveryDate = (status, time, date, callback) => {
        if ((status === "delivered" || !_.isEmpty(time)) && _.isEmpty(date)) {
            this.setState({
                deliveryDatePromptOpen: true,
                deliveryDatePromptCallback: callback,
            });
            return true
        }
        return false;
    }
    promptForDates = (status, pickupDate, deliveryTime, deliveryDate, callback) => {
        let promptedForDelivery = this.promptForDeliveryDate(status, deliveryTime, deliveryDate, callback);
        let promptedForPickup = this.promptForPickupDate(status, pickupDate, callback);
        if (promptedForDelivery && promptedForPickup) {
            this.setState({
                doubleDatePromptCallback: callback,
            });
        }
    }

    promptForPickupDate = (status, date, callback) => {
        if ((!_.isEmpty(status) && status != "booked" && status != "pickup missed" && status != "canceled") && _.isEmpty(date) ) {
            this.setState({
                pickupDatePromptOpen: true,
                pickupDatePromptCallback: callback,
            });
            return true
        }
        return false;
    }

    handlePickupDatePromptSubmit = momentObj => {
        this.closePickupDatePrompt();
        this.setState({pickup_date: momentObj.format("L")}, () => {
            if (typeof this.state.pickupDatePromptCallback === "function") {
                this.state.pickupDatePromptCallback();
                this.setState({pickupDatePromptCallback: null});
            }
        });
    }

    handleDoubleDatePromptSubmit = (pickupObj, deliveryObj) => {
        this.closeDoubleDatePrompt();
        this.setState({
            pickup_date: pickupObj.format("L"),
            delivery_date: deliveryObj.format("L")
        }, () => {
            if (typeof this.state.doubleDatePromptCallback === "function") {
                this.state.doubleDatePromptCallback();
                this.setState({
                    doubleDatePromptCallback: null,
                    pickupDatePromptCallback: null,
                    deliveryDatePromptCallback: null
                });
            }
        });
    }

    handleDeliveryDatePromptSubmit = momentObj => {
        this.closeDeliveryDatePrompt();
        this.setState({delivery_date: momentObj.format("L")}, () => {
            if (typeof this.state.deliveryDatePromptCallback === "function") {
                this.state.deliveryDatePromptCallback();
                this.setState({deliveryDatePromptCallback: null});
            }
        });
    }

    getIsExecuted = () => {
        if(this.state.status !='' && this.state.status != 'hold' && this.state.status != 'cancelled') {
            return true;
        }
        return false;
    }

    closeDeliveryDatePrompt = () => {
        this.setState({deliveryDatePromptOpen: false});
    }

    closePickupDatePrompt = () => {
        this.setState({pickupDatePromptOpen: false});
    }
    closeDoubleDatePrompt = () => {
        this.setState({
            pickupDatePromptOpen: false,
            deliveryDatePromptOpen: false
        });
    }

    handleDraft() {
        if (this.state.blind_shipment) {
            this.setState({
                draft: true,
                printBolModal: true
            });
        } else {
            this.draftPdf("original");
        }
    }

    draftPdf(type) {
        if (_.isEmpty(this.state.saved_bol_num)) {
            return false;
        }
        window.open(apiUrl + "/index.php?p=api&c=billoflading&m=draftPdf&d=" + this.state.bolId + "/" + this.state.date + "/" + type);
    }

    handlePrint() {
        if(this.state.status != "hold" && this.state.status != "canceled") {
            if (this.state.blind_shipment) {
                this.setState({ printBolModal: true });
            } else {
                this.printBol();
            }
        } else {
            this.props.setNotification("A BoL cannot be printed until after is has been executed!", { variant: "error" });
        }
    }

    async printBol(blind) {
        const { date, bolId } = this.state;
        blind = !!blind;
        let url = "/index.php?p=api&c=billoflading&m=pdf&d=" + bolId + "/" + date;

        if (blind) {
            url += "&blind=1";
        }

        // $('.get-pro').hide(); // shouldn't be able to click this right now

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data === "string" && !_.isEmpty(response.data)) {
                window.location = apiUrl + url;
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error printing the BoL", { variant: "error" });
        }
    }

    async sendAsn() {
        const { date, bolId } = this.state;

        const url = "/index.php?p=api&r=json&c=billoflading&m=advancedShipmentNotification&d=" + bolId + "/" + date + "/1";

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error sending the ASN!", { variant: "error" });
        }
    }

    getDuplicates(data) {
        return data.map((prop, key) => {
            let actions = "";
            if (prop.status !== "canceled") {
                actions = (
                    <Button size="sm" onClick={() => this.cancelDuplicate(key)} color="danger">
                        Cancel
                    </Button>
                );
            }
            return [prop.date + prop.id, prop.po, prop.so, prop.ref, prop.warehouse, prop.status, actions];
        });
    }

    handleContinue() {
        this.setState({ duplicateBolsModal: false });
        this.executeBol();
    }

    handleCancelAll() {

        const bolsToCancel = [];

        for (const bol of this.state.duplicates) {
            const { id, date, status } = bol;
            if (_.isEmpty(id) || _.isEmpty(date) || status === "canceled") {
                continue;
            }
            bolsToCancel.push({ date, id });
        }

        if (_.isEmpty(bolsToCancel)) {
            this.props.setNotification("No BOLs were found to cancel. If this is an error, please contact IS immediately. Execution of this BOL has been cancelled.", { variant: "info" });
            return false;
        }

        if (!confirm("You are about to cancel " + bolsToCancel.length + " BOL(s) and execute the one you are currently working on. This cannot be undone, and further changes cannot be saved. Press 'Okay' to continue")) {
            return false;
        }

        this.setState({ duplicateBolsModal: false });
        this.executeBol(bolsToCancel);
    }

    async cancelDuplicate(key) {
        const { duplicates } = this.state;
        const bol = duplicates[key];
        const { id, date, status } = bol;

        if (_.isEmpty(id) || _.isEmpty(date) || status === "canceled") {
            this.props.setNotification("Could not find BOL number to cancel!", { variant: "error" });
            return false;
        }

        const url = "/index.php?p=api&r=json&c=billoflading&m=cancel&no_redirect=1&d=" + id + "/" + date;

        if (!confirm("Are you sure you want to cancel this BoL? This cannot be undone.")) {
            return false;
        }

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && response.data.body) {
                bol.status = "canceled";
                duplicates[key] = bol;
                this.setState({ duplicates });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error cancelling the BOL!", { variant: "error" });
        }
    }

    printLabel() {
        const { date, bolId } = this.state;

        let url = apiUrl + "/index.php?p=api&c=billoflading&m=labelPdf&d=" + bolId + "/" + date + "/1";

        if (!_.isEmpty(this.state.custom_pallet_count)) {
            url += "&customPalletCount=" + this.state.custom_pallet_count;
        }

        if (this.state.status != "hold" && this.state.status != "canceled") {
            window.location = url;
        } else {
            this.props.setNotification("A label cannot be printed until after the BoL has been executed!", { variant: "error" });
        }
    }

    printCostcoLabel = async () => {
        const { date, bolId } = this.state;
        this.props.setUpPrinters();
        let url = apiUrl + "/index.php?p=api&c=billoflading&m=labelZpl&d=" + bolId + "/" + date + "/1";
        if (!_.isEmpty(this.state.custom_pallet_count)) {
            url += "&customPalletCount=" + this.state.custom_pallet_count;
        }

        const response = await axios.get(url);
		let zpl = "";
        if(response && response.data && response.data.body && response.data.body.document) {
            zpl = zpl + response.data.body.document;
        }

		if(zpl != "") {
            const printer = window.localStorage.getItem('DOCUMENTS');
            let JSPM = { ...this.props.JSPM };
            if(JSPM == null) {
                JSPM = window.JSPM;
                this.props.setJSPM(JSPM);
                this.props.setAvailablePrinters(JSPM);
            }
            if (!_.isEmpty(printer)) {
                await this.props.printDocument(printer, zpl, this.props.JSPM);
            } else {
                this.setState({
                    docZpl: zpl,
                    printer: "",
                    printDocModal: true
                });
            }
		}
    }



    handlePickupRequest = async () => {
        let shipperLocalTime = await this.getShipperTime()
        this.setDefaultPickupTimes(() => {
            this.setState({
                pickup_step: 1,
                pickupRequestModal: true,
                local_pickup_time: shipperLocalTime,
            });
        });
    }

    async schedulePickup() {
        const data = this.buildPayload();

        if (_.isEmpty(data.carrier.carrier_scac)) {
            this.props.setNotification("There was an error getting the quote!", { variant: "error" });
            return;
        }

        data.schedulePickup = {
            pickupDateTime: moment(this.state.pickup_date_time).format("LLL"),
            dockCloseTime: moment(this.state.dock_close_time).format("LLL")
        };

        if(data.schedulePickup.pickupDateTime == 'Invalid date') {
            if (_.isEmpty(this.state.pickup_date_time)) {
                this.props.setNotification("Ready date and time is required!", { variant: "error" });
            } else {
                this.props.setNotification(this.state.pickup_date_time + " is an invalid ready date and time!", { variant: "error" });
            }
            this.setState({
                pickup_step: 1,
            });
            return;
        }

        if(data.schedulePickup.dockCloseTime == 'Invalid date') {
            if (_.isEmpty(this.state.dock_close_time)) {
                this.props.setNotification("Dock close date and time is required!", { variant: "error" });
            } else {
                this.props.setNotification(this.state.dock_close_time + " is an invalid dock close date and time!", { variant: "error" });
            }
            return;
        }

        if(moment(data.schedulePickup.dockCloseTime).isBefore(data.schedulePickup.pickupDateTime)) {
            this.props.setNotification("Dock close time must be after ready time!", { variant: "error" });
            return;
        }

        this.setState({ loadingPickup: true });

        const url = "/index.php?p=api&r=json&c=pickupRequest&m=schedulePickup";

        try {
            const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string") {
                if(!_.isEmpty(response.data.message)) {
                    this.props.handleMessages(response);
                }
                if (!_.isEmpty(response.data.body)) {
                    const body = response.data.body;
                    this.setState({
                        loadingPickup: false,
                        pro_num: body.ProNumber,
                        confirmation_number: body.ConfirmationNumber,
                        transaction_id: body.TransactionID,
                        pickup_date: !_.isEmpty(body.PickupDate) && moment(body.PickupDate).isValid() ? moment(body.PickupDate) : this.state.pickup_date,
                        expected_delivery_date: !_.isEmpty(body.ExpectedDeliveryDate) && moment(body.ExpectedDeliveryDate).isValid() ? moment(body.ExpectedDeliveryDate) : this.state.expected_delivery_date,
                        pickup_window_start: body.PickupWindowStart,
                        pickup_window_end: body.PickupWindowEnd,
                        pickup_confirm: true,
                        pickupRequestModal: false,
                        status: "scheduled"
                    });
                } else {
                    this.setState({ loadingPickup: false });
                    if(_.isEmpty(response.data.message)) {
                        this.props.setNotification("There was an error scheduling the pickup!", { variant: "error" });
                    }
                }
            } else {
                this.setState({ loadingPickup: false });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("An exception occurred scheduling the pickup!", { variant: "error" });
            this.setState({ loadingPickup: false });
            if(error.code === 'ECONNABORTED') {
                this.props.enqueueSnackbar("The pickup request timed out!", { variant: "error" });
            } else {
                this.props.enqueueSnackbar("An exception occurred scheduling the pickup!", { variant: "error" });
            }
        }
    }

    async handleDgd() {
        if (_.isEmpty(this.state.saved_bol_num)) {
            return false;
        }

        const { bolId, date } = this.state;

        this.setState({ loadingBol: true });

        const url = "/index.php?p=api&c=billoflading&m=load&d=" + bolId + "/" + date;

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.general)) {
                let dgdUnits = [];
                if (!_.isEmpty(response.data.body.units)) {
                    dgdUnits = response.data.body.units;
                    dgdUnits.forEach(unit => {
                        unit.checked = true;
                        if(!_.isEmpty(unit.products)) {
                            unit.products.forEach(product => {
                                product.checked = true;
                            });
                        }
                    })
                }
                this.setState({
                    loadingBol: false,
                    dgd_step: 1,
                    dgd_units: dgdUnits,
                    products: [],
                    printDgdModal: true,
                    dgd_pro: this.state.pro_num,
                    dgd_bol: this.state.bol_num || (this.state.date + this.state.bolId),
                    dgd_po: this.state.po_num,
                    dgd_so: this.state.so_num,
                    dgd_ref: this.state.ref_num
                });
            } else {
                this.props.setNotification("There was an error loading the BoL!", { variant: "error" });
                this.setState({ loadingBol: false });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the BoL!", { variant: "error" });
            this.setState({ loadingBol: false });
        }
    }

    getDgdUnits(data) {
        const { classes } = this.props;
        const products = [];
        for (const [unitKey, unit] of Object.entries(data)) {
            const typeToUse = !_.isEmpty(unit.details.plural_type) && !_.isEmpty(unit.details.pieces) && parseInt(unit.details.pieces) > 1 ? unit.details.pluralType : unit.details.type;
            const stackableString = unit.details.stack == 1 ? "Stackable" : "Non-Stackable";
            const unitString = unit.details.pieces + " " + typeToUse + " " + stackableString + " " + unit.details.length + "X" + unit.details.width + "X" + unit.details.height;

            products.push([
                <FormControlLabel
                    key={'dgd_unit_' + unitKey}
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                            checked={unit.checked}
                            onChange={e => this.handleUnitChecked(unitKey, e)}
                        />
                    }
                />,
                <b className={classes.unit}>{unitString}</b>
            ]);

            if (!_.isEmpty(unit.products)) {

                for (const [productKey, product] of Object.entries(unit.products)) {
                    const productHtml = (
                        <span>
                            {product.pieces} {product.uom}(s) {product.name}
                            <br />
                            <b>Weight:</b> {product.weight}
                            {!_.isEmpty(product.net_weight) && " (Net: " + product.net_weight + ")"}
                            {!_.isEmpty(product.hazmat) && !_.isEmpty(product.hazmat.class) && <br />}
                            {!_.isEmpty(product.hazmat) && !_.isEmpty(product.hazmat.class) && (
                                <span>
                                    <b>Hazmat Class:</b> {product.hazmat.class}
                                    <br />
                                    <b>UN #:</b> {product.hazmat.un_num}
                                    <br />
                                    <b>Group:</b> {product.hazmat.group}
                                    <br />
                                    <b>Emergency # Class:</b> {product.hazmat.emergency}
                                    <br />
                                    <b>Packing Inst:</b> {product.hazmat.packing_inst}
                                </span>
                            )}
                        </span>
                    );

                    products.push([
                        <FormControlLabel
                            key={'dgd_product_' + productKey}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                    checked={product.checked}
                                    onChange={e => this.handleProductChecked(unitKey, productKey, e)}
                                />
                            }
                        />,
                        productHtml
                    ]);
                }
            }
        }

        return products;
    }

    handleUnitChecked(unitKey, event) {
        const { dgd_units } = this.state;
        if(event.target.checked) {
            dgd_units[unitKey].checked = true;
            dgd_units[unitKey].products.forEach(product => {
                product.checked = true;
            })
        } else if (!event.target.checked) {
            dgd_units[unitKey].checked = false;
            dgd_units[unitKey].products.forEach(product => {
                product.checked = false;
            })
        }
        this.setState({ dgd_units });
    }

    handleProductChecked(unitKey, productKey, event) {
        const { dgd_units } = this.state;
        if(event.target.checked) {
            dgd_units[unitKey].products[productKey].checked = true;
        } else if (!event.target.checked) {
            dgd_units[unitKey].products[productKey].checked = false;
        }
        this.setState({ dgd_units });
    }

    async printDgd() {
        const { bolId, date, dgd_units } = this.state;

        let includedProducts = [];

        dgd_units.forEach(unit => {
            unit.products.forEach(product => {
                if(product.checked == true) {
                    includedProducts.push(product.id);
                }
            })
        })

        let url = "/index.php?p=api&r=json&c=billoflading&m=printDGD&d=" + bolId + "/" + date;
        url += "&pro=" + encodeURIComponent(this.state.dgd_pro);
        url += "&bol=" + encodeURIComponent(this.state.dgd_bol);
        url += "&po=" + encodeURIComponent(this.state.dgd_po);
        url += "&so=" + encodeURIComponent(this.state.dgd_so);
        url += "&ref=" + encodeURIComponent(this.state.dgd_ref);
        url += "&products=" + encodeURIComponent(includedProducts.join());

        this.setState({ loadingDgd: true });

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data === "string") {
                this.setState({
                    loadingDgd: false,
                    dgd_step: 1,
                    dgd_units: [],
                    printDgdModal: false
                });
                window.location = apiUrl + url;
            } else {
                this.props.setNotification("There was an error generating the DGD!", { variant: "error" });
                this.setState({ loadingBol: false });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an exception generating the DGD!", { variant: "error" });
            this.setState({ loadingBol: false });
        }
    }

    handleUncancel() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="Are you sure you want to uncancel this BoL?"
                    onConfirm={() => this.uncancelBol()}
                    onCancel={() => this.setState({ alert: null })}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    confirmBtnText="Yes, uncancel it!"
                    cancelBtnText="No!"
                    showCancel
                >
                   This action will uncancel this BoL.
                </SweetAlert>
            )
        });
    }

    handleCancel() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="Are you sure you want to cancel this BoL?"
                    onConfirm={() => this.cancelBol()}
                    onCancel={() => this.setState({ alert: null })}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    confirmBtnText="Yes, cancel it!"
                    cancelBtnText="No!"
                    showCancel
                >
                    This action cannot be undone. Any files attached to this BoL will be lost!
                </SweetAlert>
            )
        });
    }

    async uncancelBol() {
        if (_.isEmpty(this.state.saved_bol_num)) {
            return false;
        }
        const { bolId, date } = this.state;

		const url = "/index.php?p=api&c=billoflading&m=uncancel&d=" + bolId + "/" + date;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				this.setState({
					canceled: true,
					alert: null
				});
			} else {
				this.props.setNotification("There was an error uncanceling the BoL!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error uncanceling the BoL!", { variant: "error" });
		}
	}

    async cancelBol() {
        if (_.isEmpty(this.state.saved_bol_num)) {
            return false;
        }

        const { bolId, date } = this.state;

        const url = "/index.php?p=api&r=json&no_redirect=1&c=billoflading&m=cancel&d=" + bolId + "/" + date;

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && response.data.body) {
                this.handleCancelDispatch(response.data.message)
            } else {
                this.props.setNotification("There was an error canceling the BoL!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error canceling the BoL!", { variant: "error" });
        }
    }

    async loadQuote() {
        const { load_quote_id, warehouse } = this.state;
        try {
            const url = "/index.php?p=api&r=json&c=postingPanel&m=quoteDetail&d=" + load_quote_id + "/" + warehouse;
            const response = await axios.get(url);

            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.post)) {
                this.loadDefaults(true, {
                    shipper: false,
                    consignee: false,
                    billing: true,
                    terms: false,
                });
                const quoteDetails = response.data.body.post;
                const data = response.data.body;
                let { accessorials, blind_shipment, data_type, volume_total_length, volume_total_width, volume_total_height } = this.state;
                const units = [];

                if (quoteDetails.accessorials && !_.isEmpty(quoteDetails.accessorials)) {
                    accessorials = this.state.accessorials.map((prop, key) => {
                        if (quoteDetails.accessorials.indexOf(prop.code) !== -1) {
                            prop.checked = true;
                        }
                        return prop;
                    });

                    if (quoteDetails.accessorials.indexOf("BLD") > -1) {
                        blind_shipment = true;
                    }
                }

                for (const quoteUnit of quoteDetails.units) {
                    const unit = {
                        type: quoteUnit.type.toLowerCase(),
                        pieces: quoteUnit.num_of,
                        stackable: quoteUnit.stack.toLowerCase() !== "no",
                        length: quoteUnit.length * 12,
                        width: quoteUnit.width * 12,
                        height: quoteUnit.height * 12,
                        density: "",
                        cube: "",
                        products: []
                    };

                    if (!_.isEmpty(quoteUnit.products)) {
                        let nonDescriptProductsFound = false;

                        for (const quoteProduct of quoteUnit.products) {
                            var quoteProductDescription = "Commodity";
                            var nmfc = "";
                            var sub_nmfc = "";

                            if (_.isEmpty(_.trim(quoteProduct.description))) {
                                nonDescriptProductsFound = true;
                            } else {
                                quoteProductDescription = _.trim(quoteProduct.description);
                            }

                            if (quoteProduct.nmfc.indexOf("-") > -1) {
                                const nmfcData = quoteProduct.nmfc.split("-", 2);
                                (nmfc = nmfcData[0]), (sub_nmfc = nmfcData[1]);
                            } else {
                                nmfc = quoteProduct.nmfc;
                            }

                            const product = {
                                nmfc,
                                sub_nmfc,
                                class: quoteProduct.class,
                                description: quoteProductDescription,
                                uom: "Piece",
                                pieces: quoteProduct.pieces,
                                weight: quoteProduct.weight,
                                net_weight: quoteProduct.netWeight || "",
                                declared_value: quoteProduct.declared_value || "",
                                condition: quoteProduct.condition || "",
                                packaging: quoteProduct.packaging || "",
                                hazardous: false,
                                auto_weight: false,
                                hazmat: {
                                    class: "",
                                    un: "",
                                    packingGroup: "",
                                    emergencyNumber: "",
                                    packingInstructions: "",
                                    overpackUsed: false
                                }
                            };

                            unit.products.push(product);
                        }

                        if (nonDescriptProductsFound) {
                            this.props.setNotification('One or more products has been entered with the description "Commodity" because no description was provided when obtaining the quote.', { variant: "info" });
                        }
                    }

                    units.push(unit);
                }
                if (!_.isEmpty(this.state.user) && this.props.hasVolumeQuotePermission && quoteDetails.general.volume) {
                    data_type = "volume";
                }
                if (!_.isEmpty(quoteDetails.general.shipmentTotalLength)) {
                    volume_total_length = quoteDetails.general.shipmentTotalLength;
                }
                if (!_.isEmpty(quoteDetails.general.shipmentTotalWidth)) {
                    volume_total_width = quoteDetails.general.shipmentTotalWidth;
                }
                if (!_.isEmpty(quoteDetails.general.shipmentTotalHeight)) {
                    volume_total_height = quoteDetails.general.shipmentTotalHeight;
                }

                flushSync(() => {
                    this.setState({
                        warehouse: quoteDetails.general.warehouse_code,
                        direction: quoteDetails.general.shipment_type,
                        shipper_zip: quoteDetails.location.shipper.zip,
                        consignee_zip: quoteDetails.location.consignee.zip,
                        accessorials,
                        blind_shipment,
                        units,
                        data_type,
                        volume_total_length,
                        volume_total_width,
                        volume_total_height,
                        ref_entry_type: data.ref_entry_type,
                        ref_entry_opts: data.ref_entry_opts,
                    });
                })
                this.updateTotals(true);
                this.checkLiability();

                if (!_.isEmpty(quoteDetails.location.shipper.zip) && quoteDetails.location.shipper.zip.length >= 5) {
                    this.handleZip("shipper", quoteDetails.location.shipper.zip);
                }

                if (!_.isEmpty(quoteDetails.location.consignee.zip) && quoteDetails.location.consignee.zip.length >= 5) {
                    this.handleZip("consignee", quoteDetails.location.consignee.zip);
                }
            } else {
                this.props.setNotification("There was an error getting the quote for quote ID " + load_quote_id + "!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error getting the quote for quote ID " + load_quote_id + "!", { variant: "error" });
        }
    }

    async loadDefaults(override, options) {
        const { dont_apply_addr_book_defaults, warehouse } = this.state;
        if (_.isEmpty(warehouse)) {
            return;
        }

        override = !!override;
        options = options || {};

        const opts = _.merge(
            {
                shipper: true,
                consignee: true,
                billing: true,
                terms: true,
                autoClass: true,
                autoPro: true,
                customPieceCount: true,
                requirePod: true,
                requirePackingGroup: true,
                refEntryType: true,
                enableProductPartNo: true,
                notifyOnBillingChange: false,
                preventExclusionExecution: true,
                references: false,
            },
            options
        );
        try {
            const url = "/index.php?p=api&r=json&c=warehouse&m=getDefaults&d=" + warehouse;
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                const { data } = response;
                let {
                    bolId,
                    accessorials,
                    shipper_name,
                    shipper_address1,
                    shipper_address2,
                    shipper_city,
                    shipper_state,
                    shipper_zip,
                    shipper_country,
                    shipper_contact_name,
                    shipper_contact_phone,
                    shipper_contact_fax,
                    shipper_contact_email,
                    consignee_name,
                    consignee_address1,
                    consignee_address2,
                    consignee_city,
                    consignee_state,
                    consignee_zip,
                    consignee_country,
                    consignee_contact_name,
                    consignee_contact_phone,
                    consignee_contact_fax,
                    consignee_contact_email,
                    additional_tracking_emails,
                    auto_tracking,
                    billing_name,
                    billing_address1,
                    billing_address2,
                    billing_city,
                    billing_state,
                    billing_zip,
                    billing_country,
                    billing_contact_name,
                    billing_contact_phone,
                    billing_contact_fax,
                    billing_contact_email,
                    notes, // what appears in special instructions text field
                    body, // needed for drop down of canned notes,
                    enable_auto_class,
                    allow_auto_pro,
                    display_custom_piece_count,
                    custom_pallet_count,
                    custom_piece_count,
                    custom_loose_count,
                    custom_unit_master_name,
                    units,
                    require_pod_for_billing,
                    warehouse_require_pod_for_billing,
                    required,
                    require_packing_group,
                    override_custom_piece_count,
                    ref_entry_type,
                    ref_entry_opts,
                    enable_product_part_no,
                    prevent_exclusion_execution,
                    direction,
                    po_num,
                    so_num,
                    ref_num,
                } = this.state;

                var locationNotes = {
                    'internalNoteCount': 0,
                    'external_notes': [],
                    'shipper': {
                        'name': '',
                        'internal_note': '',
                    },
                    'consignee': {
                        'name': '',
                        'internal_note': '',
                    },
                    'bill_to': {
                        'name': '',
                        'internal_note': '',
                    }
                };

                if (!dont_apply_addr_book_defaults) {
                    if ((_.isEmpty(shipper_name) || override) && !_.isEmpty(data.body.locations.shipper) && opts.shipper) {
                        shipper_name = data.body.locations.shipper.name;
                        shipper_address1 = data.body.locations.shipper.address1;
                        shipper_address2 = data.body.locations.shipper.address2;
                        shipper_city = data.body.locations.shipper.city;
                        shipper_state = data.body.locations.shipper.state;
                        shipper_zip = data.body.locations.shipper.zip;
                        shipper_country = convertToCountryCode(data.body.locations.shipper.country, 'short');
                        shipper_contact_name = data.body.locations.shipper.contact_name;
                        shipper_contact_phone = data.body.locations.shipper.contact_phone;
                        shipper_contact_fax = data.body.locations.shipper.contact_fax;
                        shipper_contact_email = data.body.locations.shipper.contact_email;

                        if(!_.isEmpty(data.body.locations.shipper.internal_note)) {
                            locationNotes.shipper.internal_note = data.body.locations.shipper.internal_note;
                            locationNotes.shipper.name = data.body.locations.shipper.name;
                            locationNotes.internalNoteCount++;
                        }

                        if(!_.isEmpty(data.body.locations.shipper.external_note)) {
                            locationNotes.external_notes.push(data.body.locations.shipper.external_note);
                        }

                        if(data.body.locations.shipper.accessorials) {
                            data.body.locations.shipper.accessorials.map((defaultId) => {
                                accessorials.map((acc, key) => {
                                    if(acc.id == defaultId) {
                                        accessorials[key].checked = true;
                                    }
                                })
                            });
                        }
                    }

                    if ((_.isEmpty(consignee_name) || override) && !_.isEmpty(data.body.locations.consignee) && opts.consignee) {
                        consignee_name = data.body.locations.consignee.name;
                        consignee_address1 = data.body.locations.consignee.address1;
                        consignee_address2 = data.body.locations.consignee.address2;
                        consignee_city = data.body.locations.consignee.city;
                        consignee_state = data.body.locations.consignee.state;
                        consignee_zip = data.body.locations.consignee.zip;
                        consignee_country = convertToCountryCode(data.body.locations.consignee.country, 'short');
                        consignee_contact_name = data.body.locations.consignee.contact_name;
                        consignee_contact_phone = data.body.locations.consignee.contact_phone;
                        consignee_contact_fax = data.body.locations.consignee.contact_fax;
                        consignee_contact_email = data.body.locations.consignee.contact_email;
                        additional_tracking_emails = data.body.locations.consignee.additional_tracking_emails || [];
                        auto_tracking = data.body.locations.consignee.auto_tracking;

                        if(!_.isEmpty(data.body.locations.consignee.internal_note)) {
                            locationNotes.consignee.internal_note = data.body.locations.consignee.internal_note;
                            locationNotes.consignee.name = data.body.locations.consignee.name;
                            locationNotes.internalNoteCount++;
                        }

                        if(!_.isEmpty(data.body.locations.consignee.external_note)) {
                            locationNotes.external_notes.push(data.body.locations.consignee.external_note);
                        }

                        if(data.body.locations.consignee.accessorials) {
                            data.body.locations.consignee.accessorials.map((defaultId) => {
                                accessorials.map((acc, key) => {
                                    if(acc.id == defaultId) {
                                        accessorials[key].checked = true;
                                    }
                                })
                            });
                        }
                    }

                    if ((_.isEmpty(billing_name) || override) && !_.isEmpty(data.body.locations.bill_to) && opts.billing) {
                        billing_name = data.body.locations.bill_to.name;
                        billing_address1 = data.body.locations.bill_to.address1;
                        billing_address2 = data.body.locations.bill_to.address2;
                        billing_city = data.body.locations.bill_to.city;
                        billing_state = data.body.locations.bill_to.state;
                        billing_zip = data.body.locations.bill_to.zip;
                        billing_country = convertToCountryCode(data.body.locations.bill_to.country, 'short');
                        billing_contact_name = data.body.locations.bill_to.contact_name;
                        billing_contact_phone = data.body.locations.bill_to.contact_phone;
                        billing_contact_fax = data.body.locations.bill_to.contact_fax;
                        billing_contact_email = data.body.locations.bill_to.contact_email;

                        if(!_.isEmpty(data.body.locations.bill_to.internal_note)) {
                            locationNotes.bill_to.internal_note = data.body.locations.bill_to.internal_note;
                            locationNotes.bill_to.name = data.body.locations.bill_to.name;
                            locationNotes.internalNoteCount++;
                        }

                        if(!_.isEmpty(data.body.locations.bill_to.external_note)) {
                            locationNotes.external_notes.push(data.body.locations.bill_to.external_note);
                        }

                        if (opts.notifyOnBillingChange) {
                            this.props.setNotification("The billing information has been updated to your default settings.", { variant: "warning" });
                        }
                    }
                }

                // for new/unsaved BOL, clear special notes
                if(bolId == null) notes = "";

                body.notes = data.body.notes;

                // append default canned notes to special notes if BOL is new/unsaved
                if(bolId == null && !_.isEmpty(body.notes)) {
                    const defaultNotes = body.notes.filter(note => note.default == 1);
                    for (const note of defaultNotes) {
                        notes += (!_.isEmpty(note.note)) ? note.note + "\n" : "";
                    }
                }

                if(opts.autoClass) {
                	enable_auto_class = data.body.enable_auto_class;
                }
                if(opts.autoPro) {
                    allow_auto_pro = data.body.allow_auto_pro;
                }
                if(data.body.require_refs) {
                    body.extra_required_fields.require_po = data.body.require_refs.require_po;
                    body.extra_required_fields.require_so = data.body.require_refs.require_so;
                    body.extra_required_fields.require_ref = data.body.require_refs.require_ref;
                    // update required for warehouse overrides
                    if(data.body.require_refs.require_po) {
                        required.general_info.po_num = "PO #";
                    } else {
                        if(!_.isEmpty(required) && !_.isEmpty(required.general_info) && required.general_info.hasOwnProperty('po_num')) {
                            // remove from the required.general_info if the override is false.
                            delete required.general_info.po_num;
                        }
                    }
                    if(data.body.require_refs.require_so) {
                        required.general_info.so_num = "SO #";
                    } else {
                        if(!_.isEmpty(required) && !_.isEmpty(required.general_info) && required.general_info.hasOwnProperty('so_num')) {
                            // remove from the required.general_info if the override is false.
                            delete required.general_info.so_num;
                        }
                    }
                    if(data.body.require_refs.require_ref) {
                        required.general_info.ref_num = "REF #";
                    } else {
                        if(!_.isEmpty(required) && !_.isEmpty(required.general_info) && required.general_info.hasOwnProperty('ref_num')) {
                            // remove from the required.general_info if the override is false.
                            delete required.general_info.ref_num;
                        }
                    }

                }
                if(opts.customPieceCount) {
                    display_custom_piece_count = data.body.display_custom_piece_count == 1 ? true : false;
                    if(display_custom_piece_count) {
                        units.forEach(unit => {
                            unit.products.forEach(product => {
                                product.auto_weight = false;
                            });
                        });
                    } else {
                        custom_pallet_count = "";
                        custom_piece_count = "";
                        custom_loose_count = "";
                        custom_unit_master_name = "Piece";
                    }
                }
                if(opts.requirePod) {
                    warehouse_require_pod_for_billing = data.body.require_pod_for_billing == 1 && this.state.company_require_pod_for_billing ? true : false;
                	require_pod_for_billing = warehouse_require_pod_for_billing; // default to the warehouse setting
                }
                if(opts.requirePackingGroup) {
                	require_packing_group = data.body.require_packing_group == 1 ? true : false;
                }
                if(opts.refEntryType) {
                    ref_entry_type = data.body.ref_entry_type;
                    ref_entry_opts = data.body.ref_entry_opts;
                }
                if(opts.enableProductPartNo) {
                    enable_product_part_no = data.body.enable_product_part_no == 1 ? true : false;
                }
                if(opts.preventExclusionExecution) {
                    prevent_exclusion_execution = data.body.prevent_exclusion_execution == 1 ? true : false;
                }

                if(!_.isEmpty(data.body.custom_piece_count)) {
                    custom_piece_count = data.body.custom_piece_count;
                }
                if(opts.terms && data.body.terms !== "" && data.body.terms !== undefined && (this.state.status === 'hold' || this.state.status === '')) {
                    direction = data.body.terms;
                }

                if(opts.references) {
                    po_num = data.body.po_number;
                    so_num = data.body.so_number;
                    ref_num = data.body.ref_number;
                }

                this.setState({
                    shipper_name,
                    shipper_address1,
                    shipper_address2,
                    shipper_city,
                    shipper_state,
                    shipper_zip,
                    shipper_country,
                    shipper_contact_name,
                    shipper_contact_phone,
                    shipper_contact_fax,
                    shipper_contact_email,
                    consignee_name,
                    consignee_address1,
                    consignee_address2,
                    consignee_city,
                    consignee_state,
                    consignee_zip,
                    consignee_country,
                    consignee_contact_name,
                    consignee_contact_phone,
                    consignee_contact_fax,
                    consignee_contact_email,
                    additional_tracking_emails,
                    auto_tracking,
                    billing_name,
                    billing_address1,
                    billing_address2,
                    billing_city,
                    billing_state,
                    billing_zip,
                    billing_country,
                    billing_contact_name,
                    billing_contact_phone,
                    billing_contact_fax,
                    billing_contact_email,
                    notes, // what appears in special instructions text field
                    body, // needed for drop down of canned notes
                    enable_auto_class,
                    allow_auto_pro,
                    display_custom_piece_count,
                    required,
                    custom_pallet_count,
                    custom_piece_count,
                    custom_loose_count,
                    custom_unit_master_name,
                    units,
                    require_pod_for_billing,
                    warehouse_require_pod_for_billing,
                    require_packing_group,
                    override_custom_piece_count,
                    ref_entry_type,
                    ref_entry_opts,
                    enable_product_part_no,
                    prevent_exclusion_execution,
                    direction,
                    po_num,
                    so_num,
                    ref_num,
                    accessorials,
                }, () => this.handleLocationNotes(locationNotes));

            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the defaults!", { variant: "error" });
        }
    }

    async getDefaultUnit() {
        const { warehouse, defaultUnit } = this.state;
        if (_.isEmpty(warehouse)) {
            return;
        }
        try {
            const url = "/index.php?p=api&r=json&c=handUnit&m=defaults&d=" + warehouse;
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
                const { data } = response;
                if (data.body.id) {
                    defaultUnit[warehouse] = {
                        id: data.body.id,
                        type: data.body.unit_type,
                        pieces: data.body.pieces,
                        stackable: data.body.stack == 1,
                        length: data.body.length,
                        width: data.body.width,
                        height: data.body.height
                    };
                } else {
                    defaultUnit[warehouse] = {};
                }
                this.setState({ defaultUnit });
                if (!_.isEmpty(defaultUnit[warehouse])) {
                    this.setDefaultUnit();
                }
            } else {
                this.props.setNotification("There was an error loading the default unit!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the default unit!", { variant: "error" });
        }
    }

    setDefaultUnit() {
        const { warehouse, defaultUnit } = this.state;
        if (!_.isEmpty(warehouse) && defaultUnit[warehouse] && defaultUnit[warehouse].id) {
            const unit = defaultUnit[warehouse];
            const units = [
                {
                    type: unit.type,
                    pieces: unit.pieces,
                    stackable: unit.stackable,
                    length: unit.length,
                    width: unit.width,
                    height: unit.height,
                    density: "",
                    cube: "",
                    products: [
                        {
                            nmfc: "",
                            sub_nmfc: "",
                            class: "",
                            description: "",
                            uom: unit.type,
                            pieces: "1",
                            weight: "",
                            net_weight: "",
                            condition: "",
                            packaging: "",
                            declared_value: "",
                            hazardous: false,
                            auto_weight: false,
                            hazmat: {
                                class: "",
                                un: "",
                                packingGroup: "",
                                emergencyNumber: "",
                                packingInstructions: "",
                                overpackUsed: false
                            }
                        }
                    ]
                }
            ];
            flushSync(() => {this.setState({ units })})
            this.updateTotals(true);
        }
    }

    async getDefaultProduct() {
        const { warehouse, defaultProduct } = this.state;
        if (_.isEmpty(warehouse)) {
            return;
        }
        try {
            const url = "/index.php?p=api&r=json&c=product&m=defaults&d=" + warehouse;
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
                const { data } = response;
                if (data.body.id) {
                    const prop = data.body;
                    defaultProduct[warehouse] = {
                        id: prop.id,
                        description: prop.name,
                        pieces: prop.pieces,
                        uom: prop.unit,
                        nmfc: prop.nmfc,
                        sub_nmfc: prop.sub_nmfc,
                        class: prop.class,
                        weight: prop.weight,
                        haz_class: prop.haz_class,
                        un_num: prop.un_num,
                        group: prop.group,
                        emergency: prop.emergency,
                        packing_inst: prop.packing_inst,
                        net_weight: prop.net_weight ? prop.net_weight : "",
                        declared_value: prop.declared_value ? prop.declared_value : "",
                        part_no: prop.part_no
                    };
                } else {
                    defaultProduct[warehouse] = {};
                }
                this.setState({ defaultProduct });
                if (!_.isEmpty(defaultProduct[warehouse])) {
                    this.setDefaultProduct();
                }
            } else {
                this.props.setNotification("There was an error loading the default product!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error loading the default product!", { variant: "error" });
        }
    }

    setDefaultProduct() {
        let { warehouse, defaultProduct, units } = this.state;
        if (!_.isEmpty(warehouse) && defaultProduct[warehouse] && defaultProduct[warehouse].id) {
            const prop = defaultProduct[warehouse];
            const products = [
                {
                    nmfc: prop.nmfc,
                    sub_nmfc: prop.sub_nmfc,
                    class: prop.class,
                    description: prop.description,
                    uom: prop.uom,
                    pieces: prop.pieces,
                    weight: prop.weight,
                    net_weight: prop.net_weight ? prop.net_weight : "",
                    condition: "",
                    packaging: "",
                    declared_value: prop.declared_value ? prop.declared_value : "",
                    hazardous: !_.isEmpty(prop.haz_class),
                    auto_weight: false,
                    part_no: !_.isEmpty(prop.part_no) ? prop.part_no : "",
                    hazmat: {
                        class: !_.isEmpty(prop.haz_class) ? prop.haz_class : "",
                        un: !_.isEmpty(prop.haz_class) ? prop.un_num : "",
                        packingGroup: !_.isEmpty(prop.haz_class) ? prop.group : "",
                        emergencyNumber: !_.isEmpty(prop.haz_class) ? prop.emergency : "",
                        packingInstructions: !_.isEmpty(prop.haz_class) ? prop.packing_inst : "",
                        overpackUsed: false
                    }
                }
            ];
            if (_.isEmpty(units)) {
                units = [
                    {
                        type: unit.unit_type,
                        pieces: unit.pieces,
                        stackable: unit.stackable,
                        length: unit.length,
                        width: unit.width,
                        height: unit.height,
                        density: "",
                        cube: "",
                        products
                    }
                ];
            } else {
                units[0].products = products;
            }
            this.setState({ units });
            defaultProduct[warehouse] = products[0];
            this.setState({ defaultProduct });
            this.updateTotals(true);
            this.checkLiability();
        }
    }

    handleDeclaredValue() {
        this.setState({customInputModal:true})
    }

    handleClassification() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="Warning!"
                    onConfirm={() => this.setState({ alert: null })}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    confirmBtnText="OK"
                >
                    <Button
                        color="reddit"
                        size="sm"
                        onClick={() => {
                            this.setState({ alert: null });
                            this.classifyProducts();
                        }}
                    >
                        Classify Products
                    </Button>
                    <br />
                    {"You must classify all products before you can get a quote!"}
                </SweetAlert>
            )
        });
    }

    getImages(data) {
        return data.map((prop, key) => {
            const src = prop.preview ? prop.preview : apiUrl + "/index.php?p=api&c=billoflading&m=downloadImg&d=" + prop.id + "&thumb=1";
            const filename = !_.isEmpty(prop.filename) ? prop.filename : prop.name;
            const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
            return <Preview key={key} src={src} index={key} extension={extension} handleClick={this.handleImageClick.bind(this)} name={filename} handleDelete={this.handleDeleteImage.bind(this)} />;
        });
    }

    handleImageClick(i) {
        const { imageFiles } = this.state;
        const image = imageFiles[i];
        if (image.id) {
            const url = apiUrl + "/index.php?p=api&c=billoflading&m=downloadImg&d=" + image.id;
            const title = "Image Preview";
            const options = "resizable=1, scrollbars=1, fullscreen=0, status=0";
            window.open(url, title, options);
        }
    }

    handleDeleteImage(key) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{
                        display: "block",
                        color: "black"
                    }}
                    title="Are you sure?"
                    onConfirm={() => this.deleteImage(key)}
                    onCancel={() => this.setState({ alert: null })}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    You will not be able to recover this file!
                </SweetAlert>
            )
        });
    }

    async deleteImage(i) {
        const { imageFiles } = this.state;
        const image = imageFiles[i];
        this.setState({ alert: null });

        if (image.preview) {
            imageFiles.splice(i, 1);
            this.setState({ imageFiles });
            return;
        }

        try {
            const response = await axios.get("/index.php?p=api&r=json&c=billoflading&m=deleteImg&d=" + image.id);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.deleted === 1) {
                imageFiles.splice(i, 1);
                this.setState({ imageFiles });
                this.props.setNotification("Image successfully deleted!", {
                    variant: "success"
                });
            } else {
                this.props.setNotification("There was an error deleting the image!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error deleting the image!", { variant: "error" });
        }
    }

    async handleTlAuth() {
        this.setState({ loadingTl: true });
        try {
            const response = await axios.get("/index.php?p=api&r=json&c=user&m=ttl_checkAuth");
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
                if (response.data.body.result == 1) {
                    this.handleCreateLoad();
                } else {
                    this.setState({
                        loadingTl: false,
                        username: "",
                        password: "",
                        tlAuthModal: true
                    });
                }
            } else {
                this.setState({ loadingTl: false });
                this.props.setNotification("There was an error checking authentication!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ loadingTl: false });
            this.props.setNotification("There was an error checking authentication!", { variant: "error" });
        }
    }

    async tlAuthenticate() {
        const { username, password } = this.state;
        const data = qs.stringify({ username, password });
        const url = "/index.php?p=api&r=json&c=user&m=ttl_auth";
        this.setState({ loadingAuth: true });
        try {
            const response = await axios.post(url, data);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result == 1) {
                this.handleCreateLoad();
            } else {
                this.setState({ loadingAuth: false });
                this.props.setNotification("There was an error authenticating to TFM Truckload!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ loadingAuth: false });
            this.props.setNotification("There was an error authenticating to TFM Truckload!", { variant: "error" });
        }
    }

    async handleCreateLoad() {
        this.setState({
            shipmentType: "",
            equipment: "",
            customerId: "",
            carrierId: "",
            pickupDockOpen: "",
            pickupDockClose: "",
            dropoffDockOpen: "",
            dropoffDockClose: "",
            serviceOptions: []
        });
        const url = "/index.php?p=api&r=json&c=billoflading&m=ttl_getCreateShipmentModalData";
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.shipmentTypes)) {
                const { saved_bol_num, scac } = this.state;
                let { carrierId, carrier_input } = this.state;
                const { serviceOptions, shipmentTypes, equipmentTypes, customers, carriers } = response.data.body;

                // default carrier
                if (!_.isEmpty(carriers)) {
                    carrierId = carriers[0].id;
                    carrier_input = carriers[0].name;
                    if (!_.isEmpty(scac)) {
                        const carrier = carriers.filter(prop => !_.isEmpty(prop.scac) && prop.scac.toUpperCase() == scac.toUpperCase())[0];
                        if (!_.isEmpty(carrier) && !_.isEmpty(carrier.id)) {
                            carrierId = carrier.id;
                            carrier_input = carrier.name;
                        }
                    }
                }

                this.setState({
                    serviceOptions,
                    shipmentTypes,
                    equipmentTypes,
                    customers,
                    carrierId,
                    carrier_input,
                    shipmentType: !_.isEmpty(shipmentTypes) ? shipmentTypes[0].value : "",
                    equipment: !_.isEmpty(equipmentTypes) ? equipmentTypes[0].value : "",
                    customerId: customers[0].id,
                    carrierTypes: carriers,
                    loadingAuth: false,
                    tlAuthModal: false
                });

                // Attempt to get SCAC and broker ID here in order to automatically select the
                // carrier and company. If we can't get them for any reason, just show the
                // modal and let the user select the correct carrier and company.
                if (!_.isEmpty(saved_bol_num)) {
                    this.loadBol(true);
                } else {
                    this.setState({
                        loadingTl: false,
                        loadingAuth: false,
                        createLoadModal: true
                    });
                }
            } else {
                this.setState({ loadingAuth: false, loadingTl: false });
                this.props.setNotification("There was an error getting the shipment data from truckload!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ loadingAuth: false, loadingTl: false });
            this.props.setNotification("There was an error getting the shipment data from truckload!", { variant: "error" });
        }
    }

    async loadBol(createLoadModal) {
        createLoadModal = createLoadModal || false;
        const { bolId, date } = this.state;
        const url = "/index.php?p=api&c=billoflading&m=load&d=" + bolId + "/" + date;
        this.setState({ loadingTl: true });
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.general)) {
                let { shipmentType, ref_num, customerId, carrierId, carrierTypes, customers, customer_input, carrier_input } = this.state;

                const { broker_id, carrier_scac, company_name_id, volume, ref } = response.data.body.general;

                if (volume == 1) {
                    shipmentType = "volume";
                }

                if (!_.isEmpty(carrier_scac)) {
                    const carrier = carrierTypes.filter(prop => prop.scac.toUpperCase() == carrier_scac.toUpperCase())[0];
                    if (!_.isEmpty(carrier) && carrier.id) {
                        carrierId = carrier.id;
                        carrier_input = carrier.name;
                    }
                }

                if (!_.isEmpty(broker_id)) {
                    const customer = customers.filter(prop => prop.broker_id == broker_id)[0];
                    if (!_.isEmpty(customer) && customer.id) {
                        customerId = customer.id;
                        customer_input = customer.name;
                    }
                }

                this.setState({
                    loadingAuth: false,
                    loadingTl: false,
                    ref_num: !_.isEmpty(ref) ? ref : ref_num,
                    customerId,
                    customer_input,
                    carrierId,
                    carrier_input,
                    shipmentType,
                    createLoadModal
                });
            } else {
                this.setState({ loadingAuth: false, loadingTl: false });
            }
        } catch (error) {
            this.setState({ loadingAuth: false, loadingTl: false });
            console.error(error);
            this.props.setNotification("There was an error loading the bill of lading!", { variant: "error" });
        }
    }

    handleActive(active) {
        this.setState({ active });
        this.props.scrollTop(80);
    }

    async saveProduct(i, j) {
        const { units } = this.state;
        const unit = units[i];
        const product = unit.products[j];

        if (this.state.enable_auto_class && _.isEmpty(product.class)) {
            this.props.setNotification("You did not select a class for this item!", { variant: "error" });
            return false;
        }

        if (this.state.user.user_company_settings.enable_liability == 1 && _.isEmpty(product.condition)) {
            this.props.setNotification("You have not selected the condition of the product. This is required because carrier liability coverage is enabled for your organization.", { variant: "error" });
            return false;
        }

        if (this.state.user.user_company_settings.enable_liability == 1 && _.isEmpty(product.packaging)) {
            this.props.setNotification("You have not selected the packaging of the product. This is required because carrier liability coverage is enabled for your organization.", { variant: "error" });
            return false;
        }

        if (this.state.user.user_company_settings.enable_liability == 1 && _.isEmpty(product.net_weight)) {
            this.props.setNotification("You did not provide a Net Weight for your product. This is required because carrier liability coverage is enabled for your organization.", { variant: "error" });
            return false;
        }

        if (this.state.user.user_company_settings.enable_liability == 1 && _.isEmpty(product.nmfc)) {
            this.props.setNotification("You did not provide an NMFC number for your product. This is required because carrier liability coverage is enabled for your organization.", { variant: "error" });
            return false;
        }

        if (this.state.user.user_company_settings.enable_liability == 1 && (isNaN(product.declared_value) || product.declared_value <= 0)) {
            this.props.setNotification("Declared value must be an integer/floating point number greater than zero!", { variant: "error" });
            return false;
        }

        // product validation
        if (_.isEmpty(product.description)) {
            this.props.setNotification("Description field is empty!", { variant: "error" });
            return false;
        }

        if (_.isEmpty(product.pieces)) {
            this.props.setNotification("Quantity field is empty!", { variant: "error" });
            return false;
        } else if (isNaN(product.pieces)) {
            this.props.setNotification("Quantity must be an integer!", { variant: "error" });
            return false;
        }

        if (_.isEmpty(product.weight)) {
            this.props.setNotification("Weight field is empty!", { variant: "error" });
            return false;
        } else if (isNaN(product.weight) || product.weight <= 0) {
            this.props.setNotification("Weight must be an integer/floating point number greater than zero!", { variant: "error" });
            return false;
        }


        // hazmat validation
        if (product.hazardous) {
            if (_.isEmpty(product.hazmat.class)) {
                this.props.setNotification("Hazmat Class field is empty!", { variant: "error" });
                return false;
            }

            if (_.isEmpty(product.hazmat.un)) {
                this.props.setNotification("Hazmat UN # field is empty!", { variant: "error" });
                return false;
            }

            if (_.isEmpty(product.hazmat.packingGroup)) {
                this.props.setNotification("Hazmat Packing Group field is empty!", { variant: "error" });
                return false;
            }

            if (_.isEmpty(product.hazmat.emergencyNumber)) {
                this.props.setNotification("Hazmat Emergency # field is empty!", { variant: "error" });
                return false;
            }

            // auto check hazmat accessorial if hazardous information provided (input:checkbox[value=420])
            // if($('input:checkbox[value=420]').length){
            //     $('input:checkbox[value=420]').prop('checked', true);
            // }
        }

        const data = {
            product: product.description,
            pieces: product.pieces,
            uom: product.uom,
            nmfc: product.nmfc,
            sub_nmfc: product.sub_nmfc,
            pclass: product.class,
            weight: product.weight,
            warehouse: this.state.warehouse,
            haz_class: product.hazmat.class,
            un_num: product.hazmat.un,
            group: product.hazmat.packingGroup,
            emergency: product.hazmat.emergencyNumber,
            packing_inst: product.hazmat.packingInstructions,
        };

        if (!_.isEmpty(product.net_weight)) {
            data.net_weight = product.net_weight;
        }
        if (!_.isEmpty(product.declared_value)) {
            data.declared_value = product.declared_value;
        }
        if (!_.isEmpty(product.part_no)) {
            data.part_no = product.part_no;
        }

        const url = "/index.php?p=api&r=json&c=product&m=add";
        try {
            const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.product)) {
                this.props.setNotification("Product successfully saved!", { variant: "success" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error saving the product!", { variant: "error" });
        }

        // Handle calculating weight based on pieces when enabled
        // if(autoWeight) {
        //      weight = (weight * pieces).toFixed(2);
        // }

        return false;
    }

    async handleSearch() {
        const val = encodeURIComponent(this.state.search_bol);
        if (_.isEmpty(val)) {
            this.props.setNotification("You need to enter something to search for!", { variant: "error" });
            return;
        }
        this.setState({ search: true });
        const url = "/index.php?p=api&r=json&c=billoflading&m=find&d=" + this.state.search_bol_by + "/" + val;
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
                const { body } = response.data;
                if (!_.isEmpty(body)) {
                    if (body.length == 1) {
                        this.setState({ search: false });
                        window.location.href = basePath + "/admin/bol/" + body[0].date + "/" + body[0].id;
                    } else {
                        this.setState({
                            search: false,
                            bols: body,
                            searchBolModal: true
                        });
                    }
                } else {
                    this.setState({ search: false });
                    this.props.setNotification("No results found for your search query and parameters.", { variant: "info" });
                }
            } else {
                this.setState({ search: false });
                this.props.setNotification("There was an error searching for a BoL!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ search: false });
            this.props.setNotification("There was an error searching for a BoL!", { variant: "error" });
        }
    }

    async handleSearchMerge() {
        const val = encodeURIComponent(this.state.search_bol_merge);
        if (_.isEmpty(val)) {
            this.props.setNotification("You need to enter something to search for!", { variant: "error" });
            return;
        }
        this.setState({ search: true });
        var url = "/index.php?p=api&r=json&c=postingPanel&m=index&filters[" + this.state.search_bol_merge_by + "]=";
        if(this.state.search_bol_merge_by === 'customReference') {
            url += this.state.search_bol_merge_reference_type + "/" + val;
        } else {
            url += val;
        }
        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
                const { body } = response.data;
                if (!_.isEmpty(body.result)) {
                    this.setState({
                        search: false,
                        bolsMerge: body.result,
                    });
                } else {
                    this.setState({ search: false });
                    this.props.setNotification("No results found for your search query and parameters.", { variant: "info" });
                }
            } else {
                this.setState({ search: false });
                this.props.setNotification("There was an error searching for a BoL!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ search: false });
            this.props.setNotification("There was an error searching for a BoL!", { variant: "error" });
        }
    }

    getOptions(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={key + 1}
                >
                    {prop.description}
                </MenuItem>
            );
        });
    }

    submitNonLcc() {
        let option = { id: "" };
        let descRequired = false;
        const { custom } = this.state;

        if (this.state.option === "") {
            if (_.isEmpty(custom)) {
                this.props.setNotification("Please input a reason for not selecting the LCC.", { variant: "error" });
                return;
            }
        } else {
            const { options } = this.state.company.non_lcc_tracking;
            option = options[this.state.option - 1];
            if (option.required == 1) {
                descRequired = true;
            }
        }

        if (_.isEmpty(custom) && descRequired) {
            this.props.setNotification("Custom description required for this Non LCC Selection Option.", { variant: "error" });
            return;
        }

        this.setState({ nonLccModal: false });
        this.executeBol(this.state.bolsToCancel, {
            option: option.id,
            custom: custom
        });
    }

    getBols(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <GridItem xs={12} key={key}>
                    <Paper className={classes.infoPaper} elevation={1}>
                        <Grid container>
                            <GridItem xs={4}>
                                <strong>Warehouse:</strong> {prop.warehouse_name}
                                <br />
                                <strong>Status:</strong> {prop.status}
                                <br />
                                <strong>Carrier:</strong> {prop.carrier_name}
                                <br />
                                <strong>User:</strong> {prop.user_name}
                                <br />
                                <a href="#" onClick={() => { window.location.href = basePath + "/admin/bol/" + prop.date + "/" + prop.id; }}>Load</a>
                            </GridItem>
                            <GridItem xs={4}>
                                <strong>BoL#:</strong> {prop.date + "" + prop.id}
                                <br />
                                <strong>PRO #:</strong> {prop.pro}
                                <br />
                                <strong>PO #:</strong> {prop.po}
                                <br />
                                <strong>SO #:</strong> {prop.so}
                                <br />
                                <strong>Ref #:</strong> {prop.ref}
                                <br />
                            </GridItem>
                            <GridItem xs={4}>
                                <strong>Origin:</strong>
                                <br />
                                {prop.origin_name}
                                <br />
                                <em>
                                    {prop.hasOwnProperty('origin_address1') ? prop.origin_address1 : ''}
                                    {prop.hasOwnProperty('origin_address1') ? <br /> : ''}
                                    {prop.origin_city}, {prop.origin_state} {prop.hasOwnProperty('origin_zip') ? prop.origin_zip : ''}
                                </em>
                                <br />
                                <strong>Consignee:</strong>
                                <br />
                                {prop.dest_name}
                                <br />
                                <em>
                                    {prop.hasOwnProperty('dest_address1') ? prop.dest_address1 : ''}
                                    {prop.hasOwnProperty('dest_address1') ? <br /> : ''}
                                    {prop.dest_city}, {prop.dest_state} {prop.hasOwnProperty('dest_zip') ? prop.dest_zip : ''}
                                </em>
                            </GridItem>
                        </Grid>
                    </Paper>
                </GridItem>
            );
        });
    }

    /**
     * Render the list of bol results for the bol merge modal
     * @param  {[type]} data -the bol serach results
     * @return               -rendered results
     */
    getBolsMerge(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <GridItem xs={12} key={key} style={{width: '500px'}}>
                    <Paper className={classes.infoPaper} elevation={1}>
                        <Grid container>
                            <GridItem xs={4}>
                                <strong>Warehouse:</strong> {prop.warehouse_name}
                                <br />
                                <strong>Status:</strong> {prop.status}
                                <br />
                                <strong>Carrier:</strong> {prop.carrier_name}
                                <br />
                                <strong>User:</strong> {prop.user_name}
                                <br />
                                <NavLink to={"#"} onClick={() => {
                                    if(this.state.status !== '') {
                                        this.mergeBolData(prop);
                                    } else {
                                        this.saveDraft().then(() => this.mergeBolData(prop));
                                    }
                                }}>Merge</NavLink>
                            </GridItem>
                            <GridItem xs={4}>
                                <strong>BoL#:</strong> {prop.date + "" + prop.id}
                                <br />
                                <strong>PRO #:</strong> {prop.pro}
                                <br />
                                <strong>PO #:</strong> {prop.po}
                                <br />
                                <strong>SO #:</strong> {prop.so}
                                <br />
                                <strong>Ref #:</strong> {prop.ref}
                                <br />
                            </GridItem>
                            <GridItem xs={4}>
                                <strong>Origin:</strong>
                                <br />
                                {prop.shipper.name}
                                <br />
                                <em>
                                    {prop.shipper.city}, {prop.shipper.state}
                                </em>
                                <br />
                                <strong>Consignee:</strong>
                                <br />
                                {prop.consignee.name}
                                <br />
                                <em>
                                    {prop.consignee.city}, {prop.consignee.state}
                                </em>
                            </GridItem>
                        </Grid>
                    </Paper>
                </GridItem>
            );
        });
    }

    /**
     * Ask server to merge the products/images from the provided BOL into this BOL
     * reload this BOL on success
     * @param  {object} bol - bol data and options
     * @return {bool}       - will return false on error
     */
    async mergeBolData(bol) {
        if(this.state.status !== 'hold') {
            this.props.setNotification("Cannot merge data, because this shipment has been previously executed. Create a new BoL to perform this function", { variant: "error" });
            return false;
        }
        if(_.isEmpty(bol.date) || _.isEmpty(bol.id)) {
            this.props.setNotification("The data provided for this action is invalid.", { variant: "error" });
            return false;
        }
        const data = {
            date: bol.date,
            id: bol.id,
            target_date: this.state.date,
            target_id: this.state.bolId,
        };
        try {
            const response = await axios.post('/index.php?p=api&r=json&c=billoflading&m=merge', qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
                setTimeout(() => {
                    window.location.href = basePath + "/admin/bol/" + this.state.date + "/" + this.state.bolId;
                }, 500);
            } else {
                this.props.setNotification("Looks like something went wrong. Please contact support!", { variant: "error" });
                return false;
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an error merging the shipments!", { variant: "error" });
        }
    }

    getShipmentTypes(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.value}
                >
                    {prop.display}
                </MenuItem>
            );
        });
    }

    getEquipmentTypes(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.value}
                >
                    {prop.display}
                </MenuItem>
            );
        });
    }

    getServiceOptions(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.id}
                >
                    {prop.service}
                </MenuItem>
            );
        });
    }

    getServices(data) {
        const { classes } = this.props;
        return data.map((prop, key) => {
            return (
                <GridContainer key={key} className={classes.unitWrapper}>
                    <GridItem xs={12} className={classes.package}>
                        <GridContainer>
                            <GridItem xs>
                                <small style={{ marginTop: "10px" }}>Stop</small>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select MenuProps={{ className: classes.selectMenu }}
                                    	classes={{ select: classes.select }} value={prop.stop || ""}
                                    	inputProps={{ name: "stop" }}
                                    	onChange={e => this.handleService(key, "stop", e)}
                                	>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                        value="1"
                                        >
                                            1
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="2"
                                        >
                                            2
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs>
                                <small style={{ marginTop: "10px" }}>Accessorial</small>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select MenuProps={{ className: classes.selectMenu }}
                                    	classes={{ select: classes.select }} value={prop.service || ""}
                                    	inputProps={{ name: "service" }}
                                    	onChange={e => this.handleService(key, "service", e)}
                                	>
                                        {!_.isEmpty(this.state.serviceOptions) ? (
                                            this.getServiceOptions(this.state.serviceOptions)
                                        ) : (
                                            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                                                No Accessorials
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs>
                                <small style={{ marginTop: "10px" }}>Description</small>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        type: "text",
                                        value: prop.description || "",
                                        onChange: e => this.handleService(key, "description", e)
                                    }}
                                    white
                                />
                            </GridItem>
                            <GridItem xs>
                                <small style={{ marginTop: "10px" }}>Quantity</small>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        type: "number",
                                        value: prop.quantity || "",
                                        inputProps: { min: 0 },
                                        onChange: e => this.handleService(key, "quantity", e)
                                    }}
                                    white
                                />
                            </GridItem>
                            <GridItem xs={2} className={classes.right}>
                                <Button color="danger" size="sm" justIcon round style={{ marginTop: "10px" }} onClick={() => this.removeService(key)}>
                                    <Close />
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            );
        });
    }

    addService() {
        const { services, serviceOptions } = this.state;
        const service = !_.isEmpty(serviceOptions) ? serviceOptions[0].id : "";
        services.push({
            stop: "1",
            service,
            description: "",
            quantity: 1
        });
        this.setState({ services });
    }

    handleService(i, name, event) {
        const { services } = this.state;
        const prop = services[i];
        prop[name] = event.target.value;
        services[i] = prop;
        this.setState({ services });
    }

    removeService(i) {
        const { services } = this.state;
        services.splice(i, 1);
        this.setState({ services });
    }

    async createShipment() {
        if (_.isEmpty(this.state.saved_bol_num)) {
            this.props.setNotification("The BOL must be saved to copy to TargetTL", { variant: "error" });
            return;
        }

        const { bolId, date } = this.state;
        const dateFormat = "MMM. DD, YYYY hh:mm A"; // PHP date("M. d, Y h:i A");

        const data = {
            customerId: this.state.customerId,
            carrierId: this.state.carrierId,
            shipmentType: this.state.shipmentType,
            equipment: this.state.equipment,
            pickupDockOpen: !_.isEmpty(this.state.pickupDockOpen) ? moment(this.state.pickupDockOpen).format(dateFormat) : "",
            pickupDockClose: !_.isEmpty(this.state.pickupDockClose) ? moment(this.state.pickupDockClose).format(dateFormat) : "",
            dropoffDockOpen: !_.isEmpty(this.state.dropoffDockOpen) ? moment(this.state.dropoffDockOpen).format(dateFormat) : "",
            dropoffDockClose: !_.isEmpty(this.state.dropoffDockClose) ? moment(this.state.dropoffDockClose).format(dateFormat) : "",
            serviceOptions: this.state.services
        };

        this.setState({ creatingShipment: true });

        const url = "/index.php?p=api&r=json&c=billoflading&m=ttl_createShipment&d=" + bolId + "/" + date;

        try {
            const response = await axios.post(url, qs.stringify(data));
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
                this.setState({
                    creatingShipment: false,
                    loadId: response.data.body.result,
                    status: "ttl_received",
                    showSendToTl: false,
                    tlAuthModal: false,
                    createLoadModal: false
                });
                this.loadBol();
                this.viewShipment(response.data.body.result);
            } else {
                this.setState({ creatingShipment: false });
                this.props.setNotification("There was an error creating the shipment!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.setState({ creatingShipment: false });
            this.props.setNotification("There was an error creating the shipment!", { variant: "error" });
        }
    }

    viewShipment(loadId) {
        window.open(this.props.ttlUrl + "/index.php?c=loads&m=loadBuilder&load=" + loadId);
    }

    handleKeyPress(name, event) {
        if (event.key === "Enter") {
            if (name === "bolSearch") {
                this.handleSearch();
            } else if (name === "bolSearch") {
                this.handleSearchMerge();
            } else if (name === "tlAuthenticate") {
                this.tlAuthenticate();
            } else if (name === "contactSearchShipper") {
                this.handleContactSearch("shipper");
            } else if (name === "contactSearchConsignee") {
                this.handleContactSearch("consignee");
            } else if (name === "contactSearchBilling") {
                this.handleContactSearch("billing");
            } else if (name === "contactSearchBlindShipper") {
                this.handleContactSearch("blind_shipper");
            } else if (name === "contactSearchBlindConsignee") {
                this.handleContactSearch("blind_consignee");
            } else if (name === "contactSearchBlindBilling") {
                this.handleContactSearch("blind_billing");
            } else if (name === "contactSearchDgdShipper") {
                this.handleContactSearch("dgd_shipper");
            } else if (name === "contactSearchDgdConsignee") {
                this.handleContactSearch("dgd_consignee");
            }
        }
    }

    handleLiability(scac) {
        const carrier = this.state.carriers.filter(prop => prop.scac == scac)[0];
        this.setState({
            liabilityModal: true,
            liability_modal_title: carrier.name + " Liability Coverage",
            liability: carrier.liability || null
        });
    }

    checkLiability() {
        const liability = !_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1;

        if (!liability) {
            return true;
        }

        const { units } = this.state;
        let requirementsMet = true;
        let hasUsedProduct = false;
        let disableRequestExcess = false;
        let allow_excess_liability = this.state.allow_excess_liability;
        let new_allow_excess_liability = allow_excess_liability;

        for (let i = 0; i < units.length; i++) {
            const unit = units[i];

            for (let j = 0; j < unit.products.length; j++) {
                const product = unit.products[j];
                product.errors = [];

                const condition = _.trim(product.condition);
                const packaging = _.trim(product.packaging);
                const declared_value = _.trim(product.declared_value);
                const net_weight = _.trim(product.net_weight);
                const nmfcPrime = _.trim(product.nmfc);

                if (condition === "Not New") {
                    hasUsedProduct = true;
                } else if (_.isEmpty(condition)) {
                    product.errors.push("condition");
                }

                if (_.isEmpty(packaging)) {
                    product.errors.push("packaging");
                }

                if (_.isEmpty(declared_value) || isNaN(declared_value) || declared_value <= 0) {
                    product.errors.push("declared_value");
                }

                if (_.isEmpty(net_weight) || isNaN(net_weight) || net_weight <= 0) {
                    product.errors.push("net_weight");
                }

                if (_.isEmpty(nmfcPrime)) {
                    product.errors.push("nmfc");
                }

                if (!_.isEmpty(product.errors)) {
                    requirementsMet = false;
                }

                unit.products[j] = product;
            }

            units[i] = unit;
        }

        if (hasUsedProduct || !requirementsMet) {
            disableRequestExcess = true;
            new_allow_excess_liability = false;
        } else if (requirementsMet) {
            disableRequestExcess = false;
        }

        this.setState({
            units,
            disableRequestExcess,
        });

        // was originally updating this every time, but it was conflicting with the state update in requestExcess
        if(new_allow_excess_liability !== allow_excess_liability) {
            this.setState({
                allow_excess_liability: new_allow_excess_liability,
            });
        }

        return requirementsMet;
    }

    requestExcess() {
        if (this.checkLiability()) {
            this.setState({
                classification: true,
                allow_excess_liability: true,
                liabilityModal: false,
            });
            this.handleLcc();
        }
    }

    getLiabilityErrors(data) {
        if (_.isEmpty(data)) {
            return "";
        }

        let errors = [];

        for (const prop of data) {
            let error = "";
            switch (prop) {
                case "nmfc":
                    error = "NMFC Prime not provided";
                    break;
                case "net_weight":
                    error = "Net Weight not provided";
                    break;
                case "condition":
                    error = "Condition not provided";
                    break;
                case "packaging":
                    error = "Packaging not provided";
                    break;
                case "declared_value":
                    error = "Value Per Piece not provided";
                    break;
            }
            if (error === "") {
                continue;
            }
            errors.push(error);
        }

        if (_.isEmpty(errors)) {
            return "";
        }

        return errors.join("! ") + "!";
    }

    handleReference(refList) {
        this.setState({references: refList.references});
    }

    hasOriginalExecutionDate = () => {
        return !_.isEmpty(this.state.original_execution_date);
    }

    originalExecutionDate = () => {
        if (this.hasOriginalExecutionDate()) {
            const momentObj = moment(this.state.original_execution_date);
            if (typeof momentObj === "object" && typeof momentObj.isValid === "function" && momentObj.isValid()) {
                return momentObj.format('MM/DD/YYYY');
            }
        }
        return "";
    }

    getBolNotes = () => {
        this.props.loadNotesModal(this.state.saved_bol_num);
    }

    openAlert = (message, callback) => {
        this.props.openAlertModal(message, callback);
    }

	async updateBolLevel(level) {
        const previousLevel = this.state.level;
        let newBolLevelId = +level.id;
		if(+newBolLevelId == +previousLevel) newBolLevelId--; // esentially deselects the level clicked
        this.setState({
            level: newBolLevelId,
        });
	}

    findAccessorialByCode = (code) => {
        if (Array.isArray(this.state.accessorials)) {
            for (let i = 0; i < this.state.accessorials.length; i++) {
                if (
                    isObject(this.state.accessorials[i]) &&
                    typeof this.state.accessorials[i].code === "string" &&
                    this.state.accessorials[i].code.toUpperCase() === code.toUpperCase()
                ) {
                    return {...this.state.accessorials[i]};
                }
            }
        }
        return false;
    }

    handleTrackingEmailInput = (key = null) => event => {
        let additional_tracking_emails = [...this.state.additional_tracking_emails];
        if (key == null) {
            additional_tracking_emails.push("");
        } else {
            if(additional_tracking_emails.length > key) {
                additional_tracking_emails[key] = event.target.value
            }
        }
        this.setState({additional_tracking_emails});
    }

    removeTrackingEmail = (key) => {
        let additional_tracking_emails = [...this.state.additional_tracking_emails];
        additional_tracking_emails.splice(key, 1);
        this.setState({additional_tracking_emails});
    }

    updateTrackingEmails = async () => {

        let data = {};
        let payload = {
            id: this.state.bolId,
            date: this.state.date,
            email: []
        };

        this.setState({
            updating_tracking: true
        });
        let addtlEmails = this.state.additional_tracking_emails;
        //remove any empty or duplicate emails.
        payload.email = addtlEmails.filter((email, index, self) => self.indexOf(email) === index && email != undefined && email != "");

        if(payload.email.length != this.state.additional_tracking_emails.length) {
            this.props.setNotification("Empty or duplicates tracking emails have been removed.", { variant: "error" });
        }
        data = qs.stringify(payload);
        try {
            const response = await axios.post("/index.php?p=api&r=json&c=billoflading&m=updateTrackingEmails", data);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && response.data.body) {
                if(response.data.body.hasOwnProperty('trackingEmails')) {
                    this.setState({
                        additional_tracking_emails: Object.keys(response.data.body.trackingEmails)
                    });
                }
            }
        } catch (error) {
            console.error(error);
            this.setState({
                loading: false,
                alert: null
            });
            this.props.setNotification("There was an exception updating tracking emails!", { variant: "error" });
        }

        this.setState({
            updating_tracking: false
        });
    }

    handleCancelDispatch(messages) {
		let showAlert = false
		let msg = [];

        for (const prop of messages) {
            const { type, message, traceroute} = prop;
			if(type == "error" && traceroute[1] == "p44CancelDispatch") {
				msg.push(message)
				showAlert = true;
			}
		}

		if(showAlert) {
			this.setState({
				alert: (
					<SweetAlert
						warning
						style={{
							display: "block",
							color: "black"
						}}
						title="Error Canceling Dispatch"
						onConfirm={() => this.setState({ alert: null,  canceled: true})}
						confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
						confirmBtnText="Okay"
						showCancel={false}
					>
						{msg.join("\n")}
					</SweetAlert>
				),
                transit_status:"booked"
			});
		} else {
            this.setState({
                canceled: true,
                alert: null
            });
        }
	}

    render() {
        let showExecute = false;
        let executed = false;
        if (this.state.redirect) {
            return this.state.redirect;
        }
        const { classes } = this.props;
        const selectClasses = cx({
            [classes.select]: true,
            [classes.requiredSelect]: true
        });
        if (this.state.canceled) {
            const redirectTo = basePath + "/admin/bol/archived";
            return <Redirect to={redirectTo} />;
        }
        if (this.state.status != 'hold' && !_.isEmpty(this.state.status)) {
            executed = true;
        }

        if (this.state.showExecute && !executed) {
            if(this.state.data_type == 'manual') {
                if(!_.isEmpty(this.state.non_program_carrier)) {
                    showExecute = true;
                }
            } else if(this.state.data_type == 'truckload') {
                showExecute = false;
            } else if(this.state.data_type == 'ltl' && this.state.showCarriers) {
                if(!_.isEmpty(this.state.scac)) {
                    showExecute = true;
                }
            } else if(this.state.data_type == 'volume' && this.state.showVolumeCarriers) {
                if(!_.isEmpty(this.state.scac)) {
                    showExecute = true;
                }
            }
        }
        let prodCatCols = ["Code", "Name", "NMFC", "Sub NMFC", "Class", "Weight", "Hazmat", "Actions"]
        if(this.state.enable_product_part_no == 1) {
            prodCatCols.splice(1, 0, "Part #");
        }
        const searchByOptions = [
    		{val: "bol", display: "BoL #"},
    		{val: "status", display: "Status"},
            {val: "custom", display: "Custom #"},
            {val: "carrier", display: "Carrier Name"},
            {val: "shipper", display: "Shipper Name"},
            {val: "oAddress1", display: "Origin Address 1"},
            {val: "oCity", display: "Origin City"},
            {val: "oState", display: "Origin State"},
            {val: "oZip", display: "Origin Zip"},
            {val: "consignee", display: "Consignee Name"},
            {val: "dAddress1", display: "Destination Address 1"},
            {val: "dCity", display: "Destination City"},
            {val: "dState", display: "Destination State"},
            {val: "dZip", display: "Destination Zip"},
            {val: "pro", display: "PRO #"},
            {val: "ref", display: "Reference #"},
            {val: "po", display: "PO #"},
            {val: "so", display: "Order/SO #"},
            {val: "date", display: "Date"},
            {val: "transitStatus", display: "Transit Status"},
            {val: "pickupDate", display: "Pickup Date"},
            {val: "expectedDeliveryDate", display: "Expected Delivery Date"},
            {val: "deliveryDate", display: "Delivery Date"}
    	];
        return (
            <>
                <div class={classes.fullHeight}>
                    <GridContainer>
                        {/* user/pass hack because Chrome thinks its sooooooooooo smart (Its filling creds in random inputs) */}
                        <div style={{display:"none"}}>
                            <label>Username</label>
                            <input type="text" id="username" name="username" />
                            <label>Password</label>
                            <input type="password" id="password" name="password" />
                        </div>
                        {this.state.alert}
                        <BillOfLadingNotesModal />
                        <PrintZplModal
                            open={this.state.printDocModal ? true: false}
                            modalName="printDocModal"
                            default_printer_save={this.state.default_printer_save}
                            printerType="documents"
                            zpl={this.state.docZpl}
                            handleChange={this.handleChange}
                            handleChecked={this.handleZplChecked}
                            handleModalClose={this.handleModalClose}
                        />
                        <TerminalInformationModal classes={classes}/>
                        {(this.state.deliveryDatePromptOpen && !this.state.pickupDatePromptOpen) && (
                            <DatePromptModal
                            title={<span>Please provide the shipment <b>delivery date</b></span>}
                            open={this.state.deliveryDatePromptOpen && !this.state.pickupDatePromptOpen}
                            onSubmit={this.handleDeliveryDatePromptSubmit}
                            onClose={this.closeDeliveryDatePrompt}
                        />
                        )}
                        {(this.state.pickupDatePromptOpen && !this.state.deliveryDatePromptOpen) && (
                        <DatePromptModal
                            title={<span>Please provide the shipment <b>pickup date</b></span>}
                            open={this.state.pickupDatePromptOpen && !this.state.deliveryDatePromptOpen}
                            onSubmit={this.handlePickupDatePromptSubmit}
                            onClose={this.closePickupDatePrompt}
                        />
                        )}
                        {(this.state.pickupDatePromptOpen && this.state.deliveryDatePromptOpen) && (
                        <DoubleDatePromptModal
                            title={<span>Please provide the shipment <b>pickup date</b></span>}
                            open={this.state.pickupDatePromptOpen && this.state.deliveryDatePromptOpen}
                            onSubmit={this.handleDoubleDatePromptSubmit}
                            onClose={this.closeDoubleDatePrompt}
                        />
                        )}
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <ShipmentSearchCard
                                        classes={classes}
                                        searchByOptions={searchByOptions}
                                        shipmentIdName={'BOL'}
                                        searchBy={this.state.search_bol_by || ""}
                                        searchById={"search_bol_by"}
                                        search={this.state.search_bol || ""}
                                        searchId={"search_bol"}
                                        savedShipmentId={this.state.saved_bol_num || "N/A"}
                                        status={this.state.status || "N/A"}
                                        warehouse={this.state.warehouse || ""}
                                        executed={executed}
                                        selectClasses={selectClasses}
                                        onChangeSelect={this.handleChange}
                                        onChangeSearch={this.handleInput("search_bol")}
                                        onKeyPressSearch={e => this.handleKeyPress("bolSearch", e)}
                                        onSearch={this.handleSearch}
                                        warehouses={!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.warehouses) ? (this.state.body.warehouses) : ("")}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <ShipmentHistoryCard
                                        username={this.state.username}
                                        dateCreated={this.state.date_created}
                                        timeCreated={this.state.time_created}
                                        confirmationNumber={this.state.confirmation_number}
                                        transactionId={this.state.transaction_id}
                                        pickupWindowStart={this.state.pickup_window_start}
                                        pickupWindowEnd={this.state.pickup_window_end}
                                        trackingSent = {!!this.state.trackingSent}
                                        trackingLink ={this.state.p44Url != "" && this.state.p44Url != undefined ? this.state.p44Url : ""}
                                        p44DispatchCanceled ={this.state.p44DispatchCanceled}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer justify="center">
                                <GridItem xs={12} className={classes.center}>
                                    {this.state.active === 0 ? (
                                        <Button color="warning" className={classes.marginRight}>
                                            General Information
                                        </Button>
                                    ) : (
                                        <Button size="sm" className={classes.marginRight} onClick={() => this.handleActive(0)}>
                                            General Information
                                        </Button>
                                    )}
                                    {this.state.active === 1 ? (
                                        <Button color="warning" className={classes.marginRight}>
                                            Shipment Details
                                        </Button>
                                    ) : (
                                        <Button size="sm" className={classes.marginRight} onClick={() => this.handleActive(1)}>
                                            Shipment Details
                                        </Button>
                                    )}
                                    {this.state.active === 2 ? (
                                        <Button color="warning" className={classes.marginRight}>
                                            Product Information
                                        </Button>
                                    ) : (
                                        <Button size="sm" className={classes.marginRight} onClick={() => this.handleActive(2)}>
                                            Product Information
                                        </Button>
                                    )}
                                    {this.state.active === 3 ? (
                                        <Button color="warning" className={classes.marginRight}>
                                            Special Instructions
                                        </Button>
                                    ) : (
                                        <Button size="sm" className={classes.marginRight} onClick={() => this.handleActive(3)}>
                                            Special Instructions
                                        </Button>
                                    )}
                                    {this.state.active === 4 ? (
                                        <Button color="warning" className={classes.marginRight}>
                                            Choose a Carrier
                                        </Button>
                                    ) : (
                                        <Button size="sm" className={classes.marginRight} onClick={() => this.handleActive(4)}>
                                            Choose a Carrier
                                        </Button>
                                    )}
                                    {!_.isEmpty(this.state.saved_bol_num) && <Button size="sm" className={classes.marginRight} onClick={this.getBolNotes}>
                                        Notes
                                    </Button>}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            {this.state.active === 0 && !this.state.loading && (
                                <GeneralInformation
                                    classes={classes}
                                    showIntlId={false}
                                    showMasterBill={false}
                                    showIntlTransitTimeInfo={false}
                                    showHouseBill={false}
                                    showPro={true}
                                    showBol={true}
                                    showBolTransitTimeInfo={true}
                                    pro={this.state.pro_num}
                                    require_pro={!_.isEmpty(this.state.body) && this.state.body.extra_required_fields.require_pro == 1}
                                    showGetPro={!_.isEmpty(this.state.body) && this.state.allow_auto_pro && this.state.showGetPro}
                                    bol={this.state.bol_num}
                                    po={this.state.po_num}
                                    require_po={!_.isEmpty(this.state.body) && this.state.body.extra_required_fields.require_po == 1}
                                    so_num={this.state.so_num}
                                    require_so={!_.isEmpty(this.state.body) && this.state.body.extra_required_fields.require_so == 1}
                                    ref_entry_type={this.state.ref_entry_type}
                                    ref_num={this.state.ref_num}
                                    refOpts={this.state.ref_entry_opts}
                                    require_ref={!_.isEmpty(this.state.body) && this.state.body.extra_required_fields.require_ref == 1}
                                    custom_date={this.state.custom_date}
                                    originalExecutionDate={this.hasOriginalExecutionDate() ? this.originalExecutionDate() : null}
                                    enable_blind_shipment={!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_blind_shipments == 1}
                                    is_blind_shipment={this.state.blind_shipment}
                                    company_require_pod_for_billing={this.state.company_require_pod_for_billing}
                                    require_pod_for_billing={this.state.require_pod_for_billing}
                                    transit_status={this.state.transit_status}
                                    transit_sub_status={this.state.transit_sub_status}
                                    late_pickup_reason={this.state.late_pickup_reason}
                                    late_delivery_reason={this.state.late_delivery_reason}
                                    pickup_date={this.state.pickup_date}
                                    original_pd={this.state.original_pd}
                                    expected_delivery_date={this.state.expected_delivery_date}
                                    original_edd={this.state.original_edd}
                                    delivery_date={this.state.delivery_date}
                                    delivery_time={this.state.delivery_time}
                                    allowTransitFieldsUpdate={this.state.showTransitUpdate}
                                    references={this.state.references}
                                    onChangePro={this.handleInput("pro_num")}
                                    onChangeBol={this.handleInput("bol_num")}
                                    onChangePo={this.handleInput("po_num")}
                                    onChangeSo={this.handleInput("so_num")}
                                    onChangeRef={this.handleInput("ref_num")}
                                    onChangeCustomDate={moment => {this.handleDatetime("custom_date", moment);}}
                                    onChangeBlindShipment={this.handleChecked("blind_shipment")}
                                    onRequirePodForBilling={this.handleChecked("require_pod_for_billing")}
                                    onUpdate={() => this.beginExecution(true)}
                                    onChangeTransitStatus={this.handleChange}
                                    onChangeLatePickupReason={this.handleChange}
                                    onChangeLateDeliveryReason={this.handleChange}
                                    onChangePickupDate={moment => {this.handleDatetime("pickup_date", moment);}}
                                    onChangeExpectedDeliveryDate={moment => {this.handleDatetime("expected_delivery_date", moment);}}
                                    onChangeDeliveryDate={moment => {this.handleDatetime("delivery_date", moment);}}
                                    onChangeDeliveryTime={moment => {this.handleDatetime("delivery_time", moment);}}
                                    onTransitUpdate={this.transitUpdate}
                                    clearGeneralInfo={this.clearGeneralInfo}
                                    getPro={this.getPro}
                                    handleReference={this.handleReference}
                                    showLevelSelect={this.props.hasBolLevelPermission}
                                    levelId={this.state.level}
                                    onSelectLevel={level => {this.updateBolLevel(level)}}
                                />
                            )}
                            {this.state.active === 1 && (
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <GridContainer>
                                                    <GridItem xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={8}>
                                                                <h5>Shipper Information</h5>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <WithTooltip
                                                                    title="Direction/Freight Term"
                                                                    content="Select the direction and freight term for the shipment."
                                                                >
                                                                    <span>
                                                                        <InputLabel className={classes.label}>Direction/Freight Term</InputLabel>
                                                                    </span>
                                                                </WithTooltip>
                                                                <br />
                                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                                    <Select
                                                                        MenuProps={{ className: classes.selectMenu }}
                                                                        classes={{ select: classes.select + " " + classes.requiredSelect }}
                                                                        value={(this.state.direction === 'Outbound/Prepaid' && this.state.charge_customer == 1) ? 'PPC' : this.state.direction}
                                                                        inputProps={{
                                                                            name: "direction",
                                                                            id: "direction"
                                                                        }}
                                                                        onChange={this.handleChange}
                                                                        disabled={executed && !this.state.enable_executed_edit}
                                                                    >
                                                                        <MenuItem
                                                                            classes={{
                                                                                root: classes.selectMenuItem,
                                                                                selected: classes.selectMenuItemSelected
                                                                            }}
                                                                            value="Outbound/Prepaid"
                                                                        >
                                                                            Outbound/Prepaid
                                                                        </MenuItem>
                                                                        {(this.state.user.user_company_settings.enable_prepaid_and_charge == 1 || (this.state.direction === 'Outbound/Prepaid' && this.state.charge_customer == 1)) && (
                                                                            <MenuItem
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value="PPC"
                                                                            >
                                                                                Outbound/Prepaid & Charge
                                                                            </MenuItem>
                                                                        )}
                                                                        {(this.state.user.user_company_settings.enable_outbound_collect == 1 || this.state.direction === 'Outbound/Collect') && (
                                                                            <MenuItem
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value="Outbound/Collect"
                                                                            >
                                                                                Outbound/Collect
                                                                            </MenuItem>
                                                                        )}
                                                                        <MenuItem
                                                                            classes={{
                                                                                root: classes.selectMenuItem,
                                                                                selected: classes.selectMenuItemSelected
                                                                            }}
                                                                            value="Inbound/Collect"
                                                                        >
                                                                            Inbound/Collect
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            classes={{
                                                                                root: classes.selectMenuItem,
                                                                                selected: classes.selectMenuItemSelected
                                                                            }}
                                                                            value="Third Party"
                                                                        >
                                                                            Third Party
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <GridContainer>
                                                            {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                                <ContactSearch
                                                                    classes={classes}
                                                                    searchByField="shipper_search_by"
                                                                    searchByValue={this.state.shipper_search_by}
                                                                    onChangeSearchBy={this.handleChange}
                                                                    searchForField="shipper_search_for"
                                                                    searchForValue={this.state.shipper_search_for || ""}
                                                                    onChangeSearchFor={this.handleInput("shipper_search_for")}
                                                                    onKeyPress={e => this.handleKeyPress("contactSearchShipper", e)}
                                                                    onSearch={e => this.handleContactSearch("shipper")}
                                                                    disabled={executed}
                                                                    enable_executed_edit={this.state.enable_executed_edit}
                                                                />
                                                            </GridItem>}
                                                        </GridContainer>
                                                    </GridItem>
                                                    <AddressFields
                                                        entity="shipper"
                                                        classes={classes}
                                                        disabled={executed}
                                                        enable_executed_edit={this.state.enable_executed_edit}
                                                        nameField="shipper_name"
                                                        address1Field="shipper_address1"
                                                        address2Field="shipper_address2"
                                                        zipField="shipper_zip"
                                                        cityField="shipper_city"
                                                        stateField="shipper_state"
                                                        countryField="shipper_country"
                                                        nameValue={this.state.shipper_name || ""}
                                                        address1Value={this.state.shipper_address1 || ""}
                                                        address2Value={this.state.shipper_address2 || ""}
                                                        zipValue={this.state.shipper_zip || ""}
                                                        cityValue={this.state.shipper_city || ""}
                                                        stateValue={this.state.shipper_state || ""}
                                                        countryValue={this.state.shipper_country || ""}
                                                        onChangeName={this.handleInput("shipper_name")}
                                                        onChangeAddress1={this.handleInput("shipper_address1")}
                                                        onChangeAddress2={this.handleInput("shipper_address2")}
                                                        onChangeZip={this.handleInput("shipper_zip")}
                                                        onChangeCity={this.handleInput("shipper_city")}
                                                        onChangeState={this.handleInput("shipper_state")}
                                                        onChangeCountry={this.handleInput("shipper_country")}
                                                        onBlurCountry={this.handleBlur("shipper_country")}
                                                    />
                                                    <GridItem xs={12}>
                                                        <GridContainer>
                                                            <GridItem xs={12}>
                                                                {this.props.hasAddressBookWrite && <WithTooltip
                                                                    title="Save to Address Book"
                                                                    content="Save the shipper information as a shipper contact to your Address Book."
                                                                >
                                                                    <span>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                                                    classes={{
                                                                                        checked: classes.checked,
                                                                                        root: classes.checkRoot
                                                                                    }}
                                                                                    checked={this.state.shipper_save}
                                                                                    onChange={this.handleChecked("shipper_save")}
                                                                                    disabled={executed}
                                                                                />
                                                                            }
                                                                            classes={{ label: classes.label }}
                                                                            label="Save to Address Book"
                                                                        />
                                                                    </span>
                                                                </WithTooltip>}
                                                                {!_.isEmpty(this.state.user) && this.props.hasSubmitPickupPermission && (
                                                                    <WithTooltip
                                                                        title="Shipper Pickup Confirmation"
                                                                        content="Send a confirmation email of the pickup request to the Shipper Contact (only if an email is provided.)"
                                                                    >
                                                                        <span>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                                                        classes={{
                                                                                            checked: classes.checked,
                                                                                            root: classes.checkRoot
                                                                                        }}
                                                                                        checked={this.state.shipper_pickup_confirm}
                                                                                        onChange={this.handleChecked("shipper_pickup_confirm")}
                                                                                        disabled={executed}
                                                                                    />
                                                                                }
                                                                                classes={{ label: classes.label }}
                                                                                label="Send Pickup Confirmation"
                                                                            />
                                                                        </span>
                                                                    </WithTooltip>
                                                                )}
                                                                <WithTooltip
                                                                    title="Clear Shipper Address"
                                                                    content="Remove all shipper address information."
                                                                >
                                                                    <span>
                                                                        <Button size="sm" color="warning" onClick={e => this.clearAddress("shipper")} className={classes.marginRight} disabled={this.state.enable_executed_edit ? false : executed}>
                                                                            Clear
                                                                        </Button>
                                                                    </span>
                                                                </WithTooltip>
                                                                <WithTooltip
                                                                    title="Search Shipper Address"
                                                                    content="Search for the street view of the shipper address."
                                                                >
                                                                    <span>
                                                                        <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("shipper")} className={classes.marginRight}>
                                                                            <LocationOn />
                                                                        </Button>
                                                                    </span>
                                                                </WithTooltip>
                                                                <WithTooltip
                                                                    title="Swap Information"
                                                                    content="Swap shipper and consignee information."
                                                                >
                                                                    <span>
                                                                        <Button justIcon round size="sm" color="info" onClick={this.handleSwap} className={classes.marginRight} disabled={this.state.enable_executed_edit ? false : executed}>
                                                                            <SwapVert />
                                                                        </Button>
                                                                    </span>
                                                                </WithTooltip>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>
                                                    {(this.state.shipper_save && this.state.saveShipperCode != undefined && this.state.saveShipperCode.length > 0) && (
                                                        <GridItem>
                                                            <>The above shipper address will be saved with location code {this.state.saveShipperCode}</>
                                                        </GridItem>
                                                    )}
                                                </GridContainer>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} className={classes.dividerLeft}>
                                                <h5>Shipper Contact</h5>
                                                <ContactFields
                                                    classes={classes}
                                                    entity="shipper"
                                                    nameField="shipper_contact_name"
                                                    phoneField="shipper_contact_phone"
                                                    emailField="shipper_contact_email"
                                                    faxField="shipper_contact_fax"
                                                    nameValue={this.state.shipper_contact_name || ""}
                                                    phoneValue={this.state.shipper_contact_phone || ""}
                                                    emailValue={this.state.shipper_contact_email || ""}
                                                    faxValue={this.state.shipper_contact_fax || ""}
                                                    onChangeName={this.handleInput("shipper_contact_name")}
                                                    onChangePhone={this.handleInput("shipper_contact_phone")}
                                                    onChangeEmail={this.handleInput("shipper_contact_email")}
                                                    onChangeFax={this.handleInput("shipper_contact_fax")}
                                                    disabled={executed}
                                                    enable_executed_edit={this.state.enable_executed_edit}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8} className={classes.dividerTop}>
                                                <h5>Consignee Information</h5>
                                                <GridContainer>
                                                    {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                        <ContactSearch
                                                            classes={classes}
                                                            searchByField="consignee_search_by"
                                                            searchByValue={this.state.consignee_search_by}
                                                            onChangeSearchBy={this.handleChange}
                                                            searchForField="consignee_search_for"
                                                            searchForValue={this.state.consignee_search_for || ""}
                                                            onChangeSearchFor={this.handleInput("consignee_search_for")}
                                                            onKeyPress={e => this.handleKeyPress("contactSearchConsignee", e)}
                                                            onSearch={e => this.handleContactSearch("consignee")}
                                                            disabled={executed}
                                                            enable_executed_edit={this.state.enable_executed_edit}
                                                        />
                                                    </GridItem>}
                                                </GridContainer>
                                                <GridContainer>
                                                    <AddressFields
                                                        entity="consignee"
                                                        classes={classes}
                                                        disabled={executed}
                                                        enable_executed_edit={this.state.enable_executed_edit}
                                                        nameField="consignee_name"
                                                        address1Field="consignee_address1"
                                                        address2Field="consignee_address2"
                                                        zipField="consignee_zip"
                                                        cityField="consignee_city"
                                                        stateField="consignee_state"
                                                        countryField="consignee_country"
                                                        nameValue={this.state.consignee_name || ""}
                                                        address1Value={this.state.consignee_address1 || ""}
                                                        address2Value={this.state.consignee_address2 || ""}
                                                        zipValue={this.state.consignee_zip || ""}
                                                        cityValue={this.state.consignee_city || ""}
                                                        stateValue={this.state.consignee_state || ""}
                                                        countryValue={this.state.consignee_country || ""}
                                                        onChangeName={this.handleInput("consignee_name")}
                                                        onChangeAddress1={this.handleInput("consignee_address1")}
                                                        onChangeAddress2={this.handleInput("consignee_address2")}
                                                        onChangeZip={this.handleInput("consignee_zip")}
                                                        onChangeCity={this.handleInput("consignee_city")}
                                                        onChangeState={this.handleInput("consignee_state")}
                                                        onChangeCountry={this.handleInput("consignee_country")}
                                                        onBlurCountry={this.handleBlur("consignee_country")}
                                                    />
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12}>
                                                        {this.props.hasAddressBookWrite && <WithTooltip
                                                            title="Save to Address Book"
                                                            content="Save the consignee information as a consignee contact to your Address Book."
                                                        >
                                                            <span>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            tabIndex={-1}
                                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                                            classes={{
                                                                                checked: classes.checked,
                                                                                root: classes.checkRoot
                                                                            }}
                                                                            checked={this.state.consignee_save}
                                                                            onChange={this.handleChecked("consignee_save")}
                                                                            disabled={executed}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classes.label
                                                                    }}
                                                                    label="Save to Address Book"
                                                                />
                                                            </span>
                                                        </WithTooltip>}
                                                        {this.state.user && this.props.hasSubmitPickupPermission && (
                                                            <WithTooltip
                                                                title="Consignee Pickup Confirmation"
                                                                content="Send a confirmation email of the pickup request to the Consignee Contact (only if an email is provided.)"
                                                            >
                                                                <span>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                tabIndex={-1}
                                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                classes={{
                                                                                    checked: classes.checked,
                                                                                    root: classes.checkRoot
                                                                                }}
                                                                                checked={this.state.consignee_pickup_confirm}
                                                                                onChange={this.handleChecked("consignee_pickup_confirm")}
                                                                                disabled={executed}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            label: classes.label
                                                                        }}
                                                                        label="Send Pickup Confirmation"
                                                                    />
                                                                </span>
                                                            </WithTooltip>
                                                        )}
                                                        <WithTooltip
                                                            title="Clear Consignee Address"
                                                            content="Remove all consignee address information."
                                                        >
                                                            <span>
                                                                <Button size="sm" color="warning" onClick={e => this.clearAddress("consignee")} className={classes.marginRight} disabled={this.state.enable_executed_edit ? false : executed}>
                                                                    Clear
                                                                </Button>
                                                            </span>
                                                        </WithTooltip>
                                                        <WithTooltip
                                                            title="Search Consignee Address"
                                                            content="Search for the street view of the consignee address."
                                                        >
                                                            <span>
                                                                <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("consignee")} className={classes.marginRight}>
                                                                    <LocationOn />
                                                                </Button>
                                                            </span>
                                                        </WithTooltip>
                                                    </GridItem>
                                                    {(this.state.consignee_save && this.state.saveConsigneeCode != undefined && this.state.saveConsigneeCode.length > 0) && (
                                                        <GridItem>
                                                            <>The above consignee address will be saved with location code {this.state.saveConsigneeCode}</>
                                                        </GridItem>
                                                    )}
                                                </GridContainer>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} className={classes.dividerTop + " " + classes.dividerLeft}>
                                                <h5>Consignee Contact</h5>
                                                <ContactFields
                                                    classes={classes}
                                                    entity="consignee"
                                                    nameField="consignee_contact_name"
                                                    phoneField="consignee_contact_phone"
                                                    emailField="consignee_contact_email"
                                                    faxField="consignee_contact_fax"
                                                    nameValue={this.state.consignee_contact_name || ""}
                                                    phoneValue={this.state.consignee_contact_phone || ""}
                                                    emailValue={this.state.consignee_contact_email || ""}
                                                    faxValue={this.state.consignee_contact_fax || ""}
                                                    onChangeName={this.handleInput("consignee_contact_name")}
                                                    onChangePhone={this.handleInput("consignee_contact_phone")}
                                                    onChangeEmail={this.handleInput("consignee_contact_email")}
                                                    onChangeFax={this.handleInput("consignee_contact_fax")}
                                                    disabled={executed}
                                                    enable_executed_edit={this.state.enable_executed_edit}
                                                />
                                                <br />
                                                {!executed && !this.state.blind_shipment && (
                                                    <WithTooltip
                                                        title="Automatically Send Public Tracking Link"
                                                        content="If this is checked, when the public tracking link becomes available, the consignee email address and any additional contacts will be sent an email with the tracking link when it becomes available. <B>Note: This can be cancelled using the 'Cancel Auto Trucking' button after the bill has been executed.</B>"
                                                    >
                                                        <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={-1}
                                                                        checkedIcon={
                                                                            <Check
                                                                                className={
                                                                                    classes.checkedIcon
                                                                                }
                                                                            />
                                                                        }
                                                                        icon={
                                                                            <Check
                                                                                className={
                                                                                    classes.uncheckedIcon
                                                                                }
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.checked,
                                                                            root: classes.checkRoot
                                                                        }}
                                                                        checked={this.state.auto_tracking}
                                                                        onChange={this.handleChecked(
                                                                            "auto_tracking"
                                                                        )}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label
                                                                }}
                                                                label="Automatically Send Public Tracking Link"
                                                            />
                                                    </WithTooltip>
                                                )}
                                                {!this.state.blind_shipment && (
                                                    <AdditionalContacts
                                                        handleTrackingEmailInput={this.handleTrackingEmailInput}
                                                        removeTrackingEmail={this.removeTrackingEmail}
                                                        additional_tracking_emails={this.state.additional_tracking_emails}
                                                        updating_tracking={this.state.updating_tracking}
                                                    />
                                                )}
                                                {this.state.status != '' && !this.state.blind_shipment && (
                                                    <Button color="success" onClick={this.updateTrackingEmails} style={{ marginBottom: "10px" }} className={classes.marginRight} disabled={this.state.updating_tracking}>
                                                        <Save /> Save Tracking Contacts
                                                    </Button>
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                        {this.state.blind_shipment && (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={8} className={classes.dividerTop}>
                                                    <h5>Blind Shipper Information</h5>
                                                    <GridContainer>
                                                        {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                            <ContactSearch
                                                                classes={classes}
                                                                searchByField="blind_shipper_search_by"
                                                                searchByValue={this.state.blind_shipper_search_by}
                                                                onChangeSearchBy={this.handleChange}
                                                                searchForField="blind_shipper_search_for"
                                                                searchForValue={this.state.blind_shipper_search_for || ""}
                                                                onChangeSearchFor={this.handleInput("blind_shipper_search_for")}
                                                                onKeyPress={e => this.handleKeyPress("contactSearchBlindShipper", e)}
                                                                onSearch={e => this.handleContactSearch("blind_shipper")}
                                                                disabled={executed}
                                                            />
                                                        </GridItem>}
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <AddressFields
                                                            entity="blind shipper"
                                                            classes={classes}
                                                            disabled={executed}
                                                            nameField="blind_shipper_name"
                                                            address1Field="blind_shipper_address1"
                                                            address2Field="blind_shipper_address2"
                                                            zipField="blind_shipper_zip"
                                                            cityField="blind_shipper_city"
                                                            stateField="blind_shipper_state"
                                                            countryField="blind_shipper_country"
                                                            nameValue={this.state.blind_shipper_name || ""}
                                                            address1Value={this.state.blind_shipper_address1 || ""}
                                                            address2Value={this.state.blind_shipper_address2 || ""}
                                                            zipValue={this.state.blind_shipper_zip || ""}
                                                            cityValue={this.state.blind_shipper_city || ""}
                                                            stateValue={this.state.blind_shipper_state || ""}
                                                            countryValue={this.state.blind_shipper_country || ""}
                                                            onChangeName={this.handleInput("blind_shipper_name")}
                                                            onChangeAddress1={this.handleInput("blind_shipper_address1")}
                                                            onChangeAddress2={this.handleInput("blind_shipper_address2")}
                                                            onChangeZip={this.handleInput("blind_shipper_zip")}
                                                            onChangeCity={this.handleInput("blind_shipper_city")}
                                                            onChangeState={this.handleInput("blind_shipper_state")}
                                                            onChangeCountry={this.handleInput("blind_shipper_country")}
                                                            onBlurCountry={this.handleBlur("blind_shipper_country")}
                                                        />
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12}>
                                                            <WithTooltip
                                                                title="Clear Blind Shipper Address"
                                                                content="Remove all blind shipper address information."
                                                            >
                                                                <span>
                                                                    <Button size="sm" color="warning" onClick={e => this.clearAddress("blind_shipper")} className={classes.marginRight} disabled={executed}>
                                                                        Clear
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                            <WithTooltip
                                                                title="Search Blind Shipper Address"
                                                                content="Search for the street view of the blind shipper address."
                                                            >
                                                                <span>
                                                                    <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("blind_shipper")} className={classes.marginRight}>
                                                                        <LocationOn />
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4} className={classes.dividerTop + " " + classes.dividerLeft}>
                                                    <h5>Blind Shipper Contact</h5>
                                                    <ContactFields
                                                        classes={classes}
                                                        entity="blind shipper"
                                                        nameField="blind_shipper_contact_name"
                                                        phoneField="blind_shipper_contact_phone"
                                                        emailField="blind_shipper_contact_email"
                                                        faxField="blind_shipper_contact_fax"
                                                        nameValue={this.state.blind_shipper_contact_name || ""}
                                                        phoneValue={this.state.blind_shipper_contact_phone || ""}
                                                        emailValue={this.state.blind_shipper_contact_email || ""}
                                                        faxValue={this.state.blind_shipper_contact_fax || ""}
                                                        onChangeName={this.handleInput("blind_shipper_contact_name")}
                                                        onChangePhone={this.handleInput("blind_shipper_contact_phone")}
                                                        onChangeEmail={this.handleInput("blind_shipper_contact_email")}
                                                        onChangeFax={this.handleInput("blind_shipper_contact_fax")}
                                                        // disabled={executed}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                        {this.state.blind_shipment && (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={8} className={classes.dividerTop}>
                                                    <h5>Blind Consignee Information</h5>
                                                    <GridContainer>
                                                        {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                            <ContactSearch
                                                                classes={classes}
                                                                searchByField="blind_consignee_search_by"
                                                                searchByValue={this.state.blind_consignee_search_by}
                                                                onChangeSearchBy={this.handleChange}
                                                                searchForField="blind_consignee_search_for"
                                                                searchForValue={this.state.blind_consignee_search_for || ""}
                                                                onChangeSearchFor={this.handleInput("blind_consignee_search_for")}
                                                                onKeyPress={e => this.handleKeyPress("contactSearchBlindConsignee", e)}
                                                                onSearch={e => this.handleContactSearch("blind_consignee")}
                                                                disabled={executed}
                                                            />
                                                        </GridItem>}
                                                        <AddressFields
                                                            entity="blind consignee"
                                                            classes={classes}
                                                            disabled={executed}
                                                            nameField="blind_consignee_name"
                                                            address1Field="blind_consignee_address1"
                                                            address2Field="blind_consignee_address2"
                                                            zipField="blind_consignee_zip"
                                                            cityField="blind_consignee_city"
                                                            stateField="blind_consignee_state"
                                                            countryField="blind_consignee_country"
                                                            nameValue={this.state.blind_consignee_name || ""}
                                                            address1Value={this.state.blind_consignee_address1 || ""}
                                                            address2Value={this.state.blind_consignee_address2 || ""}
                                                            zipValue={this.state.blind_consignee_zip || ""}
                                                            cityValue={this.state.blind_consignee_city || ""}
                                                            stateValue={this.state.blind_consignee_state || ""}
                                                            countryValue={this.state.blind_consignee_country || ""}
                                                            onChangeName={this.handleInput("blind_consignee_name")}
                                                            onChangeAddress1={this.handleInput("blind_consignee_address1")}
                                                            onChangeAddress2={this.handleInput("blind_consignee_address2")}
                                                            onChangeZip={this.handleInput("blind_consignee_zip")}
                                                            onChangeCity={this.handleInput("blind_consignee_city")}
                                                            onChangeState={this.handleInput("blind_consignee_state")}
                                                            onChangeCountry={this.handleInput("blind_consignee_country")}
                                                            onBlurCountry={this.handleBlur("blind_consignee_country")}
                                                        />
                                                        <GridItem xs={12}>
                                                            <WithTooltip
                                                                title="Clear Blind Consignee Address"
                                                                content="Remove all blind consignee address information."
                                                            >
                                                                <span>
                                                                    <Button size="sm" color="warning" onClick={e => this.clearAddress("blind_consignee")} className={classes.marginRight} disabled={executed}>
                                                                        Clear
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                            <WithTooltip
                                                                title="Search Blind Consignee Address"
                                                                content="Search for the street view of the blind consignee address."
                                                            >
                                                                <span>
                                                                    <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("blind_consignee")} className={classes.marginRight}>
                                                                        <LocationOn />
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4} className={classes.dividerTop + " " + classes.dividerLeft}>
                                                    <h5>Blind Consignee Contact</h5>
                                                    <ContactFields
                                                        classes={classes}
                                                        entity="blind consignee"
                                                        nameField="blind_consignee_contact_name"
                                                        phoneField="blind_consignee_contact_phone"
                                                        emailField="blind_consignee_contact_email"
                                                        faxField="blind_consignee_contact_fax"
                                                        nameValue={this.state.blind_consignee_contact_name || ""}
                                                        phoneValue={this.state.blind_consignee_contact_phone || ""}
                                                        emailValue={this.state.blind_consignee_contact_email || ""}
                                                        faxValue={this.state.blind_consignee_contact_fax || ""}
                                                        onChangeName={this.handleInput("blind_consignee_contact_name")}
                                                        onChangePhone={this.handleInput("blind_consignee_contact_phone")}
                                                        onChangeEmail={this.handleInput("blind_consignee_contact_email")}
                                                        onChangeFax={this.handleInput("blind_consignee_contact_fax")}
                                                        // disabled={executed}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={8} className={classes.dividerTop}>
                                                <h5>
                                                    3<sup>rd</sup> Party Billing Information
                                                </h5>
                                                <GridContainer>
                                                    {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                        <ContactSearch
                                                            classes={classes}
                                                            searchByField="billing_search_by"
                                                            searchByValue={this.state.billing_search_by}
                                                            onChangeSearchBy={this.handleChange}
                                                            searchForField="billing_search_for"
                                                            searchForValue={this.state.billing_search_for || ""}
                                                            onChangeSearchFor={this.handleInput("billing_search_for")}
                                                            onKeyPress={e => this.handleKeyPress("contactSearchBilling", e)}
                                                            onSearch={e => this.handleContactSearch("billing")}
                                                            disabled={executed}
                                                            enable_executed_edit={this.state.enable_executed_edit}
                                                        />
                                                    </GridItem>}
                                                    <AddressFields
                                                        entity="billing"
                                                        classes={classes}
                                                        disabled={executed}
                                                        enable_executed_edit={this.state.enable_executed_edit}
                                                        nameField="billing_name"
                                                        address1Field="billing_address1"
                                                        address2Field="billing_address2"
                                                        zipField="billing_zip"
                                                        cityField="billing_city"
                                                        stateField="billing_state"
                                                        countryField="billing_country"
                                                        nameValue={this.state.billing_name || ""}
                                                        address1Value={this.state.billing_address1 || ""}
                                                        address2Value={this.state.billing_address2 || ""}
                                                        zipValue={this.state.billing_zip || ""}
                                                        cityValue={this.state.billing_city || ""}
                                                        stateValue={this.state.billing_state || ""}
                                                        countryValue={this.state.billing_country || ""}
                                                        onChangeName={this.handleInput("billing_name")}
                                                        onChangeAddress1={this.handleInput("billing_address1")}
                                                        onChangeAddress2={this.handleInput("billing_address2")}
                                                        onChangeZip={this.handleInput("billing_zip")}
                                                        onChangeCity={this.handleInput("billing_city")}
                                                        onChangeState={this.handleInput("billing_state")}
                                                        onChangeCountry={this.handleInput("billing_country")}
                                                        onBlurCountry={this.handleBlur("billing_country")}
                                                    />
                                                    <GridItem xs={12}>
                                                        {this.props.hasAddressBookWrite && <WithTooltip
                                                            title="Save to Address Book"
                                                            content="Save the third party billing information as a third party billing contact to your Address Book."
                                                        >
                                                            <span>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            tabIndex={-1}
                                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                                            classes={{
                                                                                checked: classes.checked,
                                                                                root: classes.checkRoot
                                                                            }}
                                                                            checked={this.state.billing_save}
                                                                            onChange={this.handleChecked("billing_save")}
                                                                            disabled={executed}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classes.label
                                                                    }}
                                                                    label="Save to Address Book"
                                                                />
                                                            </span>
                                                        </WithTooltip>}
                                                        <WithTooltip
                                                            title={<span>Clear 3<sup>rd</sup> Party Billing Address</span>}
                                                            content="Remove all third party billing address information."
                                                        >
                                                            <span>
                                                                <Button size="sm" color="warning" onClick={e => this.clearAddress("billing")} className={classes.marginRight} disabled={executed}>
                                                                    Clear
                                                                </Button>
                                                            </span>
                                                        </WithTooltip>
                                                        <WithTooltip
                                                            title={<span>Search 3<sup>rd</sup> Party Billing Address</span>}
                                                            content="Search for the street view of the third party billing address."
                                                        >
                                                            <span>
                                                                <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("billing")} className={classes.marginRight}>
                                                                    <LocationOn />
                                                                </Button>
                                                            </span>
                                                        </WithTooltip>
                                                    </GridItem>
                                                    {(this.state.billing_save && this.state.saveBillingCode != undefined && this.state.saveBillingCode.length > 0) && (
                                                        <GridItem>
                                                            <>The above billing address will be saved with location code {this.state.saveBillingCode}</>
                                                        </GridItem>
                                                    )}
                                                </GridContainer>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} className={classes.dividerTop + " " + classes.dividerLeft}>
                                                <h5>
                                                    3<sup>rd</sup> Party Billing Contact
                                                </h5>
                                                <ContactFields
                                                    classes={classes}
                                                    entity="billing"
                                                    nameField="billing_contact_name"
                                                    phoneField="billing_contact_phone"
                                                    emailField="billing_contact_email"
                                                    faxField="billing_contact_fax"
                                                    nameValue={this.state.billing_contact_name || ""}
                                                    phoneValue={this.state.billing_contact_phone || ""}
                                                    emailValue={this.state.billing_contact_email || ""}
                                                    faxValue={this.state.billing_contact_fax || ""}
                                                    onChangeName={this.handleInput("billing_contact_name")}
                                                    onChangePhone={this.handleInput("billing_contact_phone")}
                                                    onChangeEmail={this.handleInput("billing_contact_email")}
                                                    onChangeFax={this.handleInput("billing_contact_fax")}
                                                    disabled={executed}
                                                    enable_executed_edit={this.state.enable_executed_edit}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        {this.state.blind_shipment && (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={8} className={classes.dividerTop}>
                                                    <h5>
                                                        Blind 3<sup>rd</sup> Party Billing Information
                                                    </h5>
                                                    <GridContainer>
                                                        {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                            <ContactSearch
                                                                classes={classes}
                                                                searchByField="blind_billing_search_by"
                                                                searchByValue={this.state.blind_billing_search_by}
                                                                onChangeSearchBy={this.handleChange}
                                                                searchForField="blind_billing_search_for"
                                                                searchForValue={this.state.blind_billing_search_for || ""}
                                                                onChangeSearchFor={this.handleInput("blind_billing_search_for")}
                                                                onKeyPress={e => this.handleKeyPress("contactSearchBlindBilling", e)}
                                                                onSearch={e => this.handleContactSearch("blind_billing")}
                                                                disabled={executed}
                                                            />
                                                        </GridItem>}
                                                        <AddressFields
                                                            entity="blind billing"
                                                            classes={classes}
                                                            disabled={executed}
                                                            nameField="blind_billing_name"
                                                            address1Field="blind_billing_address1"
                                                            address2Field="blind_billing_address2"
                                                            zipField="blind_billing_zip"
                                                            cityField="blind_billing_city"
                                                            stateField="blind_billing_state"
                                                            countryField="blind_billing_country"
                                                            nameValue={this.state.blind_billing_name || ""}
                                                            address1Value={this.state.blind_billing_address1 || ""}
                                                            address2Value={this.state.blind_billing_address2 || ""}
                                                            zipValue={this.state.blind_billing_zip || ""}
                                                            cityValue={this.state.blind_billing_city || ""}
                                                            stateValue={this.state.blind_billing_state || ""}
                                                            countryValue={this.state.blind_billing_country || ""}
                                                            onChangeName={this.handleInput("blind_billing_name")}
                                                            onChangeAddress1={this.handleInput("blind_billing_address1")}
                                                            onChangeAddress2={this.handleInput("blind_billing_address2")}
                                                            onChangeZip={this.handleInput("blind_billing_zip")}
                                                            onChangeCity={this.handleInput("blind_billing_city")}
                                                            onChangeState={this.handleInput("blind_billing_state")}
                                                            onChangeCountry={this.handleInput("blind_billing_country")}
                                                            onBlurCountry={this.handleBlur("blind_billing_country")}
                                                        />
                                                        <GridItem xs={12}>
                                                            <WithTooltip
                                                                title={<span>Clear Blind 3<sup>rd</sup> Party Billing Address</span>}
                                                                content="Remove all blind third party billing address information."
                                                            >
                                                                <span>
                                                                    <Button size="sm" color="warning" onClick={e => this.clearAddress("blind_billing")} className={classes.marginRight} disabled={executed}>
                                                                        Clear
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                            <WithTooltip
                                                                title={<span>Search Blind 3<sup>rd</sup> Party Billing Address</span>}
                                                                content="Search for the street view of the blind third party billing address."
                                                            >
                                                                <span>
                                                                    <Button justIcon round size="sm" color="info" onClick={e => this.handleAddress("blind_billing")} className={classes.marginRight}>
                                                                        <LocationOn />
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4} className={classes.dividerTop + " " + classes.dividerLeft}>
                                                    <h5>
                                                        Blind 3<sup>rd</sup> Party Billing Contact
                                                    </h5>
                                                    <ContactFields
                                                        classes={classes}
                                                        entity="blind billing"
                                                        nameField="blind_billing_contact_name"
                                                        phoneField="blind_billing_contact_phone"
                                                        emailField="blind_billing_contact_email"
                                                        faxField="blind_billing_contact_fax"
                                                        nameValue={this.state.blind_billing_contact_name || ""}
                                                        phoneValue={this.state.blind_billing_contact_phone || ""}
                                                        emailValue={this.state.blind_billing_contact_email || ""}
                                                        faxValue={this.state.blind_billing_contact_fax || ""}
                                                        onChangeName={this.handleInput("blind_billing_contact_name")}
                                                        onChangePhone={this.handleInput("blind_billing_contact_phone")}
                                                        onChangeEmail={this.handleInput("blind_billing_contact_email")}
                                                        onChangeFax={this.handleInput("blind_billing_contact_fax")}
                                                        // disabled={executed}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                            {this.state.active === 2 && (
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <GridContainer>
                                                    <GridItem xs>
                                                        <WithTooltip
                                                            title="Unit Conversion"
                                                            content="Use a tool to convert feet to inches."
                                                        >
                                                            <span>
                                                                <Button size="sm" color="linkedin" onClick={() => this.handleModalOpen("feetToInchesModal")}>
                                                                    <IoIosCalculator /> Feet to Inches
                                                                </Button>
                                                            </span>
                                                        </WithTooltip>
                                                        <WithTooltip
                                                            title="Surface Area Usage Calculator"
                                                            content="Use a tool to calculate surface area usage."
                                                        >
                                                            <span>
                                                                <Button size="sm" color="white" onClick={() => this.handleModalOpen("surfaceAreaModal")}>
                                                                    <IoIosCalculator /> Surface Area
                                                                </Button>
                                                            </span>
                                                        </WithTooltip>
                                                        {(this.state.status === 'hold' || this.state.status === '') && (
                                                            <WithTooltip
                                                                title="Merge BoL"
                                                                content="Search for an existing BoL, and add it's products, custom references, and uploaded images to this current BoL."
                                                            >
                                                                <span>
                                                                    <Button size="sm" color="white" onClick={() => this.handleModalOpen("mergeBolModal")}>
                                                                        <MergeType /> Merge BoL
                                                                    </Button>
                                                                </span>
                                                            </WithTooltip>
                                                        )}
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                            {(!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_total_declared_value == 1) && (
                                                <GridContainer>
                                                    <GridItem xs>
                                                        <GridItem xs={2}>
                                                            <WithTooltip
                                                                title="Total Declared Value"
                                                                content="Enter the total declared value of all products on this shipment."
                                                            >
                                                                <span>
                                                                    <small style={{ marginTop: "10px" }}>Total Declared Value</small>
                                                                </span>
                                                            </WithTooltip>
                                                            <CustomInput
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    type: "number",
                                                                    name: "total_declared_value",
                                                                    placeholder: "0.00",
                                                                    value: this.state.total_declared_value || '',
                                                                    onChange: this.handleInput("total_declared_value"),
                                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                    endAdornment:
                                                                    isNaN(this.state.total_declared_value) || this.state.total_declared_value <= 0 ? (
                                                                            <InputAdornment position="end" className={classes.error}>
                                                                                <Close style={{ color: "red" }} />
                                                                            </InputAdornment>
                                                                        ) : (
                                                                            undefined
                                                                        ),
                                                                    disabled: executed,
                                                                }}
                                                                required
                                                            />
                                                        </GridItem>
                                                    </GridItem>
                                                </GridContainer>
                                        )}
                                            <HandlingUnit
                                                uomSelect={false}
                                                showHsCode={false}
                                                showSubCode={true}
                                                showClass={true}
                                                showNetWeight={true}
                                                showCondition={true}
                                                showPackaging={true}
                                                showValuePer={true}
                                                showFullValCoverage={false}
                                                classes={classes}
                                                executed={executed}
                                                enable_executed_edit={this.state.enable_executed_edit}
                                                user={this.state.user}
                                                body={this.state.body}
                                                units={this.state.units}
                                                enable_auto_class={this.state.enable_auto_class}
                                                classification={this.state.classification}
                                                hasHandlingUnitRead={this.props.hasHandlingUnitRead}
                                                hasProductRead={this.props.hasProductRead}
                                                hasProductWrite={this.props.hasProductWrite}
                                                enable_product_part_no={this.state.enable_product_part_no}
                                                require_part_no={this.state.enable_product_part_no}
                                                display_custom_piece_count={this.state.display_custom_piece_count}
                                                require_packing_group={this.state.require_packing_group}
                                                nmfc_notice_message={this.state.nmfc_notice_message}
                                                addUnit={this.addUnit}
                                                removeUnit={this.removeUnit}
                                                handleUnit={this.handleUnit}
                                                handleUnitSearch={this.handleUnitSearch}
                                                addProduct={this.addProduct}
                                                removeProduct={this.removeProduct}
                                                saveProduct={this.saveProduct}
                                                handleProduct={this.handleProduct}
                                                clearProduct={this.clearProduct}
                                                handleProductSearch={this.handleProductSearch}
                                                toggleHazardous={this.toggleHazardous}
                                                handleHazardous={this.handleHazardous}
                                                validatePhoneNumber={this.validatePhoneNumber}
                                                getLiabilityErrors={(errors) => this.getLiabilityErrors(errors)}
                                                onChangeSelect={this.handleChange}
                                                classifyProducts={this.classifyProducts}
                                                updateTotals={this.updateTotals}
                                            />
                                            <GridItem xs={12} sm={12} md={12} lg={4}>
                                                <Grid container>
                                                    {((this.state.user && this.state.user.level === "admin") || this.state.enable_acc_recommendations == 1 && this.state.recommended_accessorials.length > 0) && (
                                                        <Grid item xs={12} sm={6} md={6} lg={12} style={{ padding: "5px 5px 5px 5px" }}>
                                                            <Paper className={classes.warningPaper} elevation={1}>
                                                                <h5>Recommended Accessorials</h5>
                                                                    {(this.getLocationMessages())}
                                                                    <GridContainer>
                                                                        <GridItem xs={8} style={{ marginTop: "15px", textAlign: "center", fontSize: '16px'}}>
                                                                            <b>Accessorial</b>
                                                                        </GridItem>
                                                                        <GridItem xs={4} style={{ marginTop: "15px", textAlign: "center", fontSize: '16px'}}>
                                                                            <b>Confidence Score</b>
                                                                        </GridItem>
                                                                        {this.displayAccData(this.state.recommended_accessorials)}
                                                                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: "25px", textAlign: "center", fontSize: '14px', fontStyle: 'italic' }}>
                                                                            Recommendations are not guarantees, please contact the shipper/receiver for specific requirements.
                                                                        </GridItem>
                                                                        <GridItem xs={8} sm={7} md={8} lg={8} xl={8} style={{ marginTop: "25px", textAlign: "center" }}></GridItem>
                                                                        {(!executed && this.state.recommended_accessorials.length > 0) && (
                                                                            <GridItem xs={4} sm={2} md={4} lg={4} xl={4} style={{ marginTop: "25px", textAlign: "center" }}>
                                                                                <Button color="success" size="sm" style={{ marginRight: "5px" }} onClick={() => this.approveRecommendedAccs()}>
                                                                                    Apply
                                                                                </Button>
                                                                            </GridItem>
                                                                        )}
                                                                    </GridContainer>
                                                            </Paper>
                                                        </Grid>
                                                    )}
                                                    {(this.state.display_custom_piece_count) && (
                                                        <Grid item xs={12} sm={6} md={6} lg={12} style={{ padding: "5px 5px 5px 5px" }}>
                                                            <Paper className={classes.paper} elevation={1}>
                                                                <h5>Custom Count</h5>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <WithTooltip
                                                                            title="Custom Unit Count"
                                                                            content="Enter a custom count for the total number of handling units."
                                                                        >
                                                                            <span>
                                                                                <InputLabel htmlFor="custom_pallet_count" className={classes.label}>
                                                                                    Units
                                                                                </InputLabel>
                                                                            </span>
                                                                        </WithTooltip>
                                                                        <br />
                                                                        <CustomInput
                                                                            formControlProps={{ fullWidth: true }}
                                                                            inputProps={{
                                                                                type: "number",
                                                                                id: "custom_pallet_count",
                                                                                name: "custom_pallet_count",
                                                                                value: this.state.custom_pallet_count || "",
                                                                                onChange: this.handleInput("custom_pallet_count"),
                                                                                disabled: this.state.enable_executed_edit ? false : executed,
                                                                            }}
                                                                            white
                                                                        />
                                                                    </Grid>
                                                                    <Grid container style={{ marginTop: "25px" }} >

                                                                        <Grid item
                                                                            xs={12}
                                                                            style={{ marginRight: "10px" }}
                                                                        >
                                                                            <WithTooltip
                                                                                title="Custom Product Type and Count"
                                                                                content="Enter a custom product type and count for the total number of products."
                                                                            >
                                                                                <span>
                                                                                    <InputLabel htmlFor="custom_piece_count" className={classes.label}>
                                                                                        Products
                                                                                    </InputLabel>
                                                                                </span>
                                                                            </WithTooltip>
                                                                        </Grid>
                                                                        <br />

                                                                        <Grid item
                                                                            xs={5}
                                                                            style = {{ marginRight: "25px", marginTop: "10px" }}
                                                                        >
                                                                            <CustomInput
                                                                                formControlProps={{ fullWidth: true }}
                                                                                inputProps={{
                                                                                    type: "number",
                                                                                    id: "custom_piece_count",
                                                                                    name: "custom_piece_count",
                                                                                    value: this.state.custom_piece_count || "",
                                                                                    onChange: this.handleInput("custom_piece_count"),
                                                                                    disabled: this.state.enable_executed_edit ? false : executed,
                                                                                }}
                                                                                white
                                                                            />
                                                                        </Grid>

                                                                        <Grid item
                                                                            xs={6}
                                                                            style = {{ marginTop: "10px" }}
                                                                        >
                                                                            <FormControl
                                                                                fullWidth className={classes.selectFormControl}>
                                                                                <Select
                                                                                    MenuProps={{ className: classes.selectMenu }}
                                                                                    classes={{ select: classes.select }}
                                                                                    inputProps={{
                                                                                        type: "text",
                                                                                        id: "custom_unit_master_name",
                                                                                        name: "custom_unit_master_name",
                                                                                        value: this.state.custom_unit_master_name,
                                                                                        onChange: this.handleInput("custom_unit_master_name"),
                                                                                        disabled: this.state.enable_executed_edit ? false : executed,
                                                                                    }}
                                                                                    disabled={this.state.enable_executed_edit ? false : executed}
                                                                                >
                                                                                    {!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.custom_unit_master) && this.getUnitTypes(this.state.body.custom_unit_master)}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>

                                                                    </Grid>

                                                                    <Grid item xs={12} style={{ marginTop: "25px" }} >
                                                                        <WithTooltip
                                                                            title="Custom Loose Count"
                                                                            content="Enter a custom count for the total number of loose items."
                                                                        >
                                                                            <span>
                                                                                <InputLabel htmlFor="custom_loose_count" className={classes.label}>
                                                                                    Loose Items
                                                                                </InputLabel>
                                                                            </span>
                                                                        </WithTooltip>
                                                                        <br />
                                                                        <CustomInput
                                                                            formControlProps={{ fullWidth: true }}
                                                                            inputProps={{
                                                                                type: "number",
                                                                                id: "custom_loose_count",
                                                                                name: "custom_loose_count",
                                                                                value: this.state.custom_loose_count || "",
                                                                                onChange: this.handleInput("custom_loose_count"),
                                                                                disabled: this.state.enable_executed_edit ? false : executed,
                                                                            }}
                                                                            white
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    ) || ('')}
                                                    <Grid item xs={12} sm={6} md={6} lg={12} style={{ padding: "5px 5px 5px 5px" }}>
                                                        <Paper className={classes.paper} elevation={1}>
                                                            <Grid container>
                                                                <Grid item xs={6} className={classes.left}>
                                                                    Total Units:
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.right}>
                                                                    <strong>{this.state.totals.units}</strong>
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.left}>
                                                                    Total Weight:
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.right}>
                                                                    <strong>{this.state.totals.weight} lbs.</strong>
                                                                </Grid>
                                                                {!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
                                                                    <Grid item xs={6} className={classes.left}>
                                                                        Total Value:
                                                                    </Grid>
                                                                )}
                                                                {!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
                                                                    <Grid item xs={6} className={classes.right}>
                                                                        <strong>${this.state.totals.value}</strong>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={6} className={classes.left}>
                                                                    Total Cubic Feet:
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.right}>
                                                                    <strong>
                                                                        {this.state.totals.volume} ft.<sup>{3}</sup>
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.left}>
                                                                    Total PCF:
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.right}>
                                                                    <strong>{this.state.totals.density}</strong>
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.left}>
                                                                    Linear Feet:
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.right}>
                                                                    {this.props.yatEnabled && !_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_new_ln_ft_method == 1 && (
                                                                        <LnftLoadPlanModal 
                                                                            units={this.state.units} 
                                                                            company={this.state.companyNameId}
                                                                            reqLoading={this.state.lnftLoading}
                                                                        />
                                                                    )}
                                                                    {this.state.lnftLoading ? '' : <strong>{this.state.totals.linear_feet} ft</strong>}
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12} lg={8}>
                                                <GridContainer>
                                                    <h5>Accessorials</h5>
                                                    <AccessorialsSelect
                                                        accessorials={this.state.accessorials}
                                                        recommended_accessorials={this.state.recommended_accessorials}
                                                        disabled={this.state.status != 'hold' && !_.isEmpty(this.state.status)}
                                                        handleChecked={(key, event) => this.handleAccsChecked(key, event)}
                                                    />
                                                    <br />
                                                    {((this.state.user && this.state.user.level === "admin") || this.state.enable_acc_recommendations == 1) && (
                                                        <>
                                                            {this.state.loadingRecommendedAccs ? (
                                                                <Button color="linkedin" size="sm" style={{ lineHeight: 0, lineWidth: 40 }}>
                                                                    <CircularProgress size={16} style={{ color: "white" }} />
                                                                </Button>
                                                            ) : (
                                                                <Button color="linkedin" size="sm"  className={classes.marginRight} onClick={() => this.getRecommendedAccessorials()}>
                                                                    Get Recommended
                                                                    <br />Accessorials
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </GridContainer>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            )}
                            {this.state.active === 3 && (
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h5>Special Information</h5>
                                                <SpecialInformationFields
                                                    cannedNotes={this.state.body.notes}
                                                    notes={this.state.notes}
                                                    cod_amount={this.state.cod_amount}
                                                    canned_note={this.state.canned_note}
                                                    cod_by={this.state.cod_by}
                                                    cod_type={this.state.cod_type}
                                                    onChangeNotes={this.handleInput("notes")}
                                                    onChangeCodAmount={this.handleInput("cod_amount")}
                                                    onAddNote={this.addNote}
                                                    handleChange={this.handleChange}
                                                    showCod={true}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        {!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 && (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12} className={classes.dividerTop}>
                                                    <h5>Dangerous Goods Information</h5>
                                                    <DGDFields
                                                        dgd_type={this.state.dgd_type}
                                                        dgd_custom_shipper={this.state.dgd_custom_shipper}
                                                        dgd_custom_consignee={this.state.dgd_custom_consignee}
                                                        dgd_air_date={this.state.dgd_air_date}
                                                        dgd_air_declarant={this.state.dgd_air_declarant}
                                                        dgd_air_place={this.state.dgd_air_place}
                                                        dgd_air_departure_airport={this.state.dgd_air_departure_airport}
                                                        dgd_air_destination_airport={this.state.dgd_air_destination_airport}
                                                        dgd_air_cargo_only={this.state.dgd_air_cargo_only}
                                                        dgd_air_radioactive={this.state.dgd_air_radioactive}
                                                        dgd_ocean_date ={this.state.dgd_ocean_date }
                                                        dgd_ocean_declarant={this.state.dgd_ocean_declarant}
                                                        dgd_ocean_place={this.state.dgd_ocean_place}
                                                        dgd_ocean_trans_doc_number={this.state.dgd_ocean_trans_doc_number}
                                                        dgd_ocean_freight_forwarders_reference={this.state.dgd_ocean_freight_forwarders_reference}
                                                        dgd_ocean_carrier={this.state.dgd_ocean_carrier}
                                                        dgd_ocean_vessel_flight_and_date={this.state.dgd_ocean_vessel_flight_and_date}
                                                        dgd_ocean_port_place_handling={this.state.dgd_ocean_port_place_handling}
                                                        dgd_ocean_ctu_number={this.state.dgd_ocean_ctu_number}
                                                        dgd_ocean_seal_number={this.state.dgd_ocean_seal_number}
                                                        dgd_ocean_ctu_size_and_type={this.state.dgd_ocean_ctu_size_and_type}
                                                        dgd_ocean_tare_mass={this.state.dgd_ocean_tare_mass}
                                                        dgd_ocean_receiving_remarks={this.state.dgd_ocean_receiving_remarks}
                                                        dgd_ocean_company_name={this.state.dgd_ocean_company_name}
                                                        dgd_ocean_haulers_name={this.state.dgd_ocean_haulers_name}
                                                        dgd_ocean_preparing_company_name={this.state.dgd_ocean_preparing_company_name}
                                                        dgd_ocean_vehicle_registration_number={this.state.dgd_ocean_vehicle_registration_number}
                                                        dgd_ocean_driver_name_date={this.state.dgd_ocean_driver_name_date}
                                                        executed={executed}
                                                        handleChange={this.handleChange}
                                                        handleInput={e => {this.handleInput(e.target.name)(e)}}
                                                        handleDatetime={(name, mom) => {this.handleDatetime(name, mom)}}
                                                        handleChecked={e => {this.handleChecked(e.target.name)(e)}}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                        {this.state.dgd_custom_shipper && (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={9} className={classes.dividerTop}>
                                                    <h5>Custom Shipper Information</h5>
                                                    <GridContainer>
                                                        {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                            <ContactSearch
                                                                classes={classes}
                                                                searchByField="dgd_shipper_search_by"
                                                                searchByValue={this.state.dgd_shipper_search_by}
                                                                onChangeSearchBy={this.handleChange}
                                                                searchForField="dgd_shipper_search_for"
                                                                searchForValue={this.state.dgd_shipper_search_for || ""}
                                                                onChangeSearchFor={this.handleInput("dgd_shipper_search_for")}
                                                                onKeyPress={e => this.handleKeyPress("contactSearchDgdShipper", e)}
                                                                onSearch={e => this.handleContactSearch("dgd_shipper")}
                                                                disabled={executed}
                                                            />
                                                        </GridItem>}
                                                        <AddressFields
                                                            entity="DGD shipper"
                                                            classes={classes}
                                                            disabled={executed}
                                                            nameField="dgd_shipper_name"
                                                            address1Field="dgd_shipper_address1"
                                                            address2Field="dgd_shipper_address2"
                                                            zipField="dgd_shipper_zip"
                                                            cityField="dgd_shipper_city"
                                                            stateField="dgd_shipper_state"
                                                            countryField="dgd_shipper_country"
                                                            nameValue={this.state.dgd_shipper_name || ""}
                                                            address1Value={this.state.dgd_shipper_address1 || ""}
                                                            address2Value={this.state.dgd_shipper_address2 || ""}
                                                            zipValue={this.state.dgd_shipper_zip || ""}
                                                            cityValue={this.state.dgd_shipper_city || ""}
                                                            stateValue={this.state.dgd_shipper_state || ""}
                                                            countryValue={this.state.dgd_shipper_country || ""}
                                                            onChangeName={this.handleInput("dgd_shipper_name")}
                                                            onChangeAddress1={this.handleInput("dgd_shipper_address1")}
                                                            onChangeAddress2={this.handleInput("dgd_shipper_address2")}
                                                            onChangeZip={this.handleInput("dgd_shipper_zip")}
                                                            onChangeCity={this.handleInput("dgd_shipper_city")}
                                                            onChangeState={this.handleInput("dgd_shipper_state")}
                                                            onChangeCountry={this.handleInput("dgd_shipper_country")}
                                                        />
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={3} className={classes.dividerLeft + " " + classes.dividerTop}>
                                                    <h5>Custom Shipper Contact</h5>
                                                    <ContactFields
                                                        classes={classes}
                                                        entity="DGD shipper"
                                                        nameField="dgd_shipper_contact_name"
                                                        phoneField="dgd_shipper_contact_phone"
                                                        emailField="dgd_shipper_contact_email"
                                                        faxField="dgd_shipper_contact_fax"
                                                        nameValue={this.state.dgd_shipper_contact_name || ""}
                                                        phoneValue={this.state.dgd_shipper_contact_phone || ""}
                                                        emailValue={this.state.dgd_shipper_contact_email || ""}
                                                        faxValue={this.state.dgd_shipper_contact_fax || ""}
                                                        onChangeName={this.handleInput("dgd_shipper_contact_name")}
                                                        onChangePhone={this.handleInput("dgd_shipper_contact_phone")}
                                                        onChangeEmail={this.handleInput("dgd_shipper_contact_email")}
                                                        onChangeFax={this.handleInput("dgd_shipper_contact_fax")}
                                                        disabled={executed}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                        {this.state.dgd_custom_consignee && (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={9} className={classes.dividerTop}>
                                                    <h5>Custom Consignee Information</h5>
                                                    <GridContainer>
                                                        {this.props.hasAddressBookRead && <GridItem xs={12}>
                                                            <ContactSearch
                                                                classes={classes}
                                                                searchByField="dgd_consignee_search_by"
                                                                searchByValue={this.state.dgd_consignee_search_by}
                                                                onChangeSearchBy={this.handleChange}
                                                                searchForField="dgd_consignee_search_for"
                                                                searchForValue={this.state.dgd_consignee_search_for || ""}
                                                                onChangeSearchFor={this.handleInput("dgd_consignee_search_for")}
                                                                onKeyPress={e => this.handleKeyPress("contactSearchDgdConsignee", e)}
                                                                onSearch={e => this.handleContactSearch("dgd_consignee")}
                                                                disabled={executed}
                                                            />
                                                        </GridItem>}
                                                        <AddressFields
                                                            entity="DGD consignee"
                                                            classes={classes}
                                                            disabled={executed}
                                                            nameField="dgd_consignee_name"
                                                            address1Field="dgd_consignee_address1"
                                                            address2Field="dgd_consignee_address2"
                                                            zipField="dgd_consignee_zip"
                                                            cityField="dgd_consignee_city"
                                                            stateField="dgd_consignee_state"
                                                            countryField="dgd_consignee_country"
                                                            nameValue={this.state.dgd_consignee_name || ""}
                                                            address1Value={this.state.dgd_consignee_address1 || ""}
                                                            address2Value={this.state.dgd_consignee_address2 || ""}
                                                            zipValue={this.state.dgd_consignee_zip || ""}
                                                            cityValue={this.state.dgd_consignee_city || ""}
                                                            stateValue={this.state.dgd_consignee_state || ""}
                                                            countryValue={this.state.dgd_consignee_country || ""}
                                                            onChangeName={this.handleInput("dgd_consignee_name")}
                                                            onChangeAddress1={this.handleInput("dgd_consignee_address1")}
                                                            onChangeAddress2={this.handleInput("dgd_consignee_address2")}
                                                            onChangeZip={this.handleInput("dgd_consignee_zip")}
                                                            onChangeCity={this.handleInput("dgd_consignee_city")}
                                                            onChangeState={this.handleInput("dgd_consignee_state")}
                                                            onChangeCountry={this.handleInput("dgd_consignee_country")}
                                                            onBlurCountry={this.handleBlur("dgd_consignee_country")}
                                                        />
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={3} className={classes.dividerLeft + " " + classes.dividerTop}>
                                                    <h5>Custom Consignee Contact</h5>
                                                    <ContactFields
                                                        classes={classes}
                                                        entity="DGD consignee"
                                                        nameField="dgd_consignee_contact_name"
                                                        phoneField="dgd_consignee_contact_phone"
                                                        emailField="dgd_consignee_contact_email"
                                                        faxField="dgd_consignee_contact_fax"
                                                        nameValue={this.state.dgd_consignee_contact_name || ""}
                                                        phoneValue={this.state.dgd_consignee_contact_phone || ""}
                                                        emailValue={this.state.dgd_consignee_contact_email || ""}
                                                        faxValue={this.state.dgd_consignee_contact_fax || ""}
                                                        onChangeName={this.handleInput("dgd_consignee_contact_name")}
                                                        onChangePhone={this.handleInput("dgd_consignee_contact_phone")}
                                                        onChangeEmail={this.handleInput("dgd_consignee_contact_email")}
                                                        onChangeFax={this.handleInput("dgd_consignee_contact_fax")}
                                                        disabled={executed}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                        <GridContainer className={classes.dividerTop}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <h5>Upload Files</h5>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Dropzone handleUpload={this.handleUpload} />
                                            </Grid>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <aside className={classes.thumbsContainer}>{!_.isEmpty(this.state.imageFiles) && this.getImages(this.state.imageFiles)}</aside>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            )}
                            {this.state.active === 4 && (
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ margin: "10px 0px" }}>
                                                        {this.state.data_type === "ltl" ? (
                                                            <Button color="warning" className={classes.marginRight}>
                                                                LTL
                                                            </Button>
                                                        ) : (
                                                            <Button color="transparent" className={classes.marginRight} onClick={() => this.handleDataType("ltl")}>
                                                                LTL
                                                            </Button>
                                                        )}
                                                        {this.state.data_type === "manual" ? (
                                                            <Button color="warning" className={classes.marginRight}>
                                                                Manual
                                                            </Button>
                                                        ) : (
                                                            <Button color="transparent" className={classes.marginRight} onClick={() => this.handleDataType("manual")}>
                                                                Manual
                                                            </Button>
                                                        )}
                                                        {this.showVolumeTab() && (
                                                            <span>
                                                                {this.state.data_type === "volume" ? (
                                                                    <Button color="warning" className={classes.marginRight}>
                                                                        Volume
                                                                    </Button>
                                                                ) : (
                                                                    <Button color="transparent" className={classes.marginRight} onClick={() => this.handleDataType("volume")}>
                                                                        Volume
                                                                    </Button>
                                                                )}
                                                            </span>
                                                        )}
                                                        {this.showTruckloadTab() && (
                                                            <span>
                                                                {this.state.data_type === "truckload" ? (
                                                                    <Button color="warning" className={classes.marginRight}>
                                                                        Truckload
                                                                    </Button>
                                                                ) : (
                                                                    <Button color="transparent" className={classes.marginRight} onClick={() => this.handleDataType("truckload")}>
                                                                        Truckload
                                                                    </Button>
                                                                )}
                                                            </span>
                                                        )}
                                                    </Grid>
                                                    {this.state.data_type === "manual" && !_.isEmpty(this.state.loadId) && (
                                                        <GridItem xs={4} sm={3} md={3}>
                                                            <img src={`${apiUrl}/view/img/ttl-logo-med.jpg`} alt="TFM Truckload" style={{ marginRight:'5px' }}/>
                                                        </GridItem>
                                                    )}
                                                    <GridItem xs={12}>
                                                        {this.state.data_type === "ltl" && (
                                                            <Grid container>
                                                                <GridItem xs={4}>
                                                                    {this.state.classification || (!this.state.enable_auto_class) ? (
                                                                        <WithTooltip
                                                                            title="Rate"
                                                                            content="Click to get real-time rates from carriers"
                                                                        >
                                                                            <span>
                                                                                <Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.handleLcc}>
                                                                                    Rate
                                                                                </Button>
                                                                            </span>
                                                                        </WithTooltip>
                                                                    ) : (
                                                                        <WithTooltip
                                                                            title="Classify Products"
                                                                            content="You must classify all products on the shipment before you can get a quote!"
                                                                        >
                                                                            <span>
                                                                                <Button color="reddit" size="lg" className={classes.marginRight} onClick={this.handleClassification}>
                                                                                    Rate
                                                                                </Button>
                                                                            </span>
                                                                        </WithTooltip>
                                                                    )}
                                                                    {!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
                                                                        <WithTooltip
                                                                            title="Full Value Coverage"
                                                                            content="Automatically request full value liability coverage when the carrier's standard coverage is not high enough. Additional charges may apply."
                                                                            placement="top"
                                                                        >
                                                                            <span style={{ marginLeft: "10px" }}>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            tabIndex={-1}
                                                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                                                            classes={{
                                                                                                checked: classes.checked,
                                                                                                root: classes.checkRoot
                                                                                            }}
                                                                                            checked={(this.state.allow_excess_liability && !this.state.disableRequestExcess) || false}
                                                                                            onChange={this.handleChecked("allow_excess_liability")}
                                                                                            disabled={this.state.disableRequestExcess}
                                                                                        />
                                                                                    }
                                                                                    classes={{
                                                                                        label: classes.label
                                                                                    }}
                                                                                    label="Full Value Coverage"
                                                                                />
                                                                            </span>
                                                                        </WithTooltip>
                                                                    )}
                                                                    <br />
                                                                    <br />
                                                                    {!_.isEmpty(this.state.carriers) && (
                                                                        <Paper className={classes.paper} elevation={1}>
                                                                            <b>Non-Program Carriers</b>
                                                                            <br />
                                                                            <Button round size="sm" className={classes.nonProgramCarriers}>
                                                                                {" "}
                                                                            </Button>
                                                                            <Button round size="sm" className={classes.nonProgramCarriersAlt}>
                                                                                {" "}
                                                                            </Button>
                                                                            <br />
                                                                            <b>Program Carriers</b>
                                                                            <br />
                                                                            <Button round size="sm" className={classes.programCarriers}>
                                                                                {" "}
                                                                            </Button>
                                                                            <Button round size="sm" className={classes.programCarriersAlt}>
                                                                                {" "}
                                                                            </Button>
                                                                        </Paper>
                                                                    )}
                                                                    <br />
                                                                </GridItem>
                                                                {!_.isEmpty(this.state.carriers) && (
                                                                    <GridItem xs={8}>
                                                                        <Paper className={classes.paper} elevation={1}>
                                                                            This quote does not apply on shipments that qualify as a Capacity Load or as a Cubic Capacity Density rated shipment. For shipments weighing more than 7,500 lbs. or that are greater than 750 cubic ft. or that take up more than 10 linear feet of a trailer please contact TFM at 1-888-653-1323 for routing Instruction. This quote will be based on the information you
                                                                            provide. Actual charges will be determined by shipment characteristics and any additional service options applicable at time of shipment.
                                                                            {this.state.user &&
                                                                                (this.state.user.level === "admin" || this.state.user.user_company_settings.enable_liability == 1) &&
                                                                                " Carrier liability rates are based on product data provided here and are subject to all applicable items found in carrier rules tariffs, such as packaging requirements and accurate NMFC numbers. If you require additional coverage, and you have non-new (used, refurbished, etc.) products on your shipment, please contact TFM for pricing."}
                                                                        </Paper>
                                                                <br />
                                                            </GridItem>
                                                        )}
                                                    </Grid>
                                                )}
                                                {this.state.data_type === "manual" && (
                                                            <Grid container>
                                                                <GridItem xs={4} sm={3} md={3}>
                                                                    <WithTooltip
                                                                        title="Choose a Carrier"
                                                                        content="Select a carrier from the list."
                                                                    >
                                                                        <span>
                                                                            <InputLabel className={classes.label}>Choose Carrier</InputLabel>
                                                                        </span>
                                                                    </WithTooltip>
                                                                    <br />
                                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                                        <Select
                                                                            MenuProps={{ className: classes.selectMenu }}
                                                                            classes={{ select: classes.select + " " + classes.requiredSelect }}

                                                                            value={this.state.non_program_carrier}
                                                                            inputProps={{
                                                                                name: "non_program_carrier",
                                                                                id: "non_program_carrier",
                                                                                disabled: executed
                                                                            }}
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            <MenuItem disabled classes={{ root: classes.selectMenuItem }} />
                                                                            {!_.isEmpty(this.state.body) && this.state.body.manual_carriers && this.getManualCarriers(this.state.body.manual_carriers)}
                                                                        </Select>
                                                                    </FormControl>
                                                                </GridItem>
                                                                <GridItem xs={4} sm={3} md={3}>
                                                                    <WithTooltip
                                                                        title="Rate"
                                                                        content="Enter a rate (optional, not required to execute)"
                                                                    >
                                                                        <span>
                                                                            <InputLabel className={classes.label}>Rate</InputLabel>
                                                                        </span>
                                                                    </WithTooltip>
                                                                    <br />
                                                                    <CustomInput
                                                                        inputProps={{
                                                                            type: "number",
                                                                            id: "non_program_rate",
                                                                            name: "non_program_rate",
                                                                            inputProps: { min: 0 },
                                                                            value: this.state.non_program_rate || "",
                                                                            onChange: this.handleInput("non_program_rate"),
                                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                            disabled: executed
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </Grid>
                                                )}
                                                {this.state.data_type === "volume" && (
                                                            <Grid container>
                                                                <GridItem xs={12}>
                                                                    <Button color="linkedin" size="lg" onClick={() => { this.findCarriers(true); }}>
                                                                        Rate
                                                                    </Button>
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <WithTooltip
                                                                        title="Total Shipment Length"
                                                                        content="The length of floorspace that the shipment will use on the truck in inches."
                                                                    >
                                                                        <span>
                                                                            <InputLabel className={classes.label}>Total Shipment Length</InputLabel>
                                                                        </span>
                                                                    </WithTooltip>
                                                                    <br />
                                                                    <CustomInput
                                                                        inputProps={{
                                                                            type: "text",
                                                                            id: "volume_total_length",
                                                                            name: "volume_total_length",
                                                                            value: this.state.volume_total_length || "",
                                                                            onChange: this.handleInput("volume_total_length"),
                                                                            endAdornment: <InputAdornment position="end">in.</InputAdornment>
                                                                        }}
                                                                        required
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <WithTooltip
                                                                        title="Total Shipment Width"
                                                                        content="The width of floorspace that the shipment will use on the truck in inches."
                                                                    >
                                                                        <span>
                                                                            <InputLabel className={classes.label}>Total Shipment Width</InputLabel>
                                                                        </span>
                                                                    </WithTooltip>
                                                                    <br />
                                                                    <CustomInput
                                                                        inputProps={{
                                                                            type: "text",
                                                                            id: "volume_total_width",
                                                                            name: "volume_total_width",
                                                                            value: this.state.volume_total_width || "",
                                                                            onChange: this.handleInput("volume_total_width"),
                                                                            endAdornment: <InputAdornment position="end">in.</InputAdornment>
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <WithTooltip
                                                                        title="Total Shipment Height"
                                                                        content="The shipment total height."
                                                                    >
                                                                        <span>
                                                                            <InputLabel className={classes.label}>Total Shipment Height</InputLabel>
                                                                        </span>
                                                                    </WithTooltip>
                                                                    <br />
                                                                    <CustomInput
                                                                        inputProps={{
                                                                            type: "text",
                                                                            id: "volume_total_height",
                                                                            name: "volume_total_height",
                                                                            value: this.state.volume_total_height || "",
                                                                            onChange: this.handleInput("volume_total_height"),
                                                                            endAdornment: <InputAdornment position="end">in.</InputAdornment>
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </Grid>
                                                )}
                                                {this.state.data_type === "truckload" && (
                                                            <Grid container>
                                                                <GridItem xs={4}>
                                                                    <WithTooltip
                                                                        title="Choose the Equipment"
                                                                        content="Select the equipement for the truckload shipment."
                                                                    >
                                                                        <span>
                                                                            <InputLabel className={classes.label}>Choose Equipment</InputLabel>
                                                                        </span>
                                                                    </WithTooltip>
                                                                    <br />
                                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                                        <Select
                                                                            MenuProps={{ className: classes.selectMenu }}
                                                                            classes={{ select: classes.select }}
                                                                            value={this.state.tl_equipment}
                                                                            inputProps={{
                                                                                name: "tl_equipment",
                                                                                id: "tl_equipment"
                                                                            }}
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            <MenuItem
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value="Van"
                                                                            >
                                                                                Van
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value="Reefer"
                                                                            >
                                                                                Refrigerated Trailer
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value="Flat"
                                                                            >
                                                                                Flatbed
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </GridItem>
                                                                {this.state.quote && (
                                                                    <GridItem xs={8} style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>
                                                                        <Paper className={classes.paper} elevation={1}>
                                                                            All pricing subject to availability and standard market fluctuations. Quoted rate may differ from actual rate negotiated with carrier, rate shown is based on historical averages for this lane for a full truck load. We do our best to find the best rate possible for every shipment. We will contact you to confirm shipment and provide final pricing prior to
                                                                            dispatching carrier and finalizing shipment. Actual pricing may be higher or lower than quoted amount. Accessorial charges including, but not limited to, hazardous, lift gates, inside delivery, etc vary by carrier and are not included in this quote. We will work directly with the carrier to negotiate an appropriate rate for these accessorials when they are
                                                                            requested and will confirm them with you prior to booking the load.
                                                                        </Paper>
                                                                        <br />
                                                                    </GridItem>
                                                        )}
                                                    </Grid>
                                                )}
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                            {this.state.data_type === "ltl" && (
                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    {this.state.showCarriers ? (
                                                        <Grid container>
                                                            {!_.isEmpty(this.state.carriers) ? (
                                                                <Grid item xs={12}>
                                                                    {this.renderRateResults(this.state.carriers)}
                                                                </Grid>
                                                            ) : (
                                                                <Grid item xs={12}>
                                                                    {this.state.max_weight ? (
                                                                        <Paper className={classes.warningPaper} elevation={1}>
                                                                            <h4>Contact Us</h4>
                                                                            This shipment exceeds the weight limits imposed for your organization. To ensure the greatest level of accuracy please{" "}
                                                                            <a href="https://targetfmi.com/contact-us/" target="_blank">
                                                                                Contact TFM
                                                                            </a>{" "}
                                                                            for a quote on this shipment. We apologize for any inconvenience.
                                                                        </Paper>
                                                                    ) : (
                                                                        <Grid container>
                                                                            <Spinner loading={this.state.loadingCarriers} message="No carriers found." />
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                            <br />

                                                        </Grid>
                                                    ) : (
                                                        <Grid container>
                                                            {this.state.max_weight && (
                                                                <GridItem xs={12}>
                                                                    <Paper className={classes.warningPaper} elevation={1}>
                                                                        <h4>Contact Us</h4>
                                                                        This shipment exceeds the weight limits imposed for your organization. To ensure the greatest level of accuracy please{" "}
                                                                        <a href="https://targetfmi.com/contact-us/" target="_blank">
                                                                            Contact TFM
                                                                        </a>{" "}
                                                                        for a quote on this shipment. We apologize for any inconvenience.
                                                                    </Paper>
                                                                </GridItem>
                                                    )}
                                                </Grid>
                                            )}
                                        </GridItem>
                                    )}
                                    {this.state.data_type === "truckload" && this.state.showQuote && (
                                                <GridItem xs={12} sm={12} md={12}>
                                                    {this.state.quote ? (
                                                        <Grid container>
                                                            <GridItem xs={12} sm={12} md={12} className={classes.center}>
                                                                <h3>
                                                                    <b>Estimated Market Average</b>
                                                                </h3>
                                                                <h2>${this.state.quote}</h2>
                                                                {this.state.status === "hold" && <p>Press 'Submit to TFM Truckload for Review' to send this quote to Target Truckload.</p>}
                                                            </GridItem>
                                                        </Grid>
                                                    ) : (
                                                        <Grid container>
                                                    <Spinner loading={this.state.loadingQuote} message="Failed to retrieve truckload quote from the server!" />
                                                </Grid>
                                            )}
                                        </GridItem>
                                    )}
                                    {this.state.data_type === "volume" && this.state.showVolumeCarriers && (
                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    {!_.isEmpty(this.state.volume_carriers) ? (
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                {this.renderRateResults(this.state.volume_carriers)}
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <Grid container>
                                                    <Spinner loading={this.state.loadingCarriers} message="Failed to retrieve volume quotes from the server!" />
                                                </Grid>
                                            )}
                                        </GridItem>
                                    )}
                                </GridContainer>
                                    </CardBody>
                                </Card>
                            )}
                        </GridItem>
                        <ContactSearchModal
                            open={this.state.contactSearchModal}
                            contacts={this.state.contacts}
                            onClose={() => this.handleModalClose("contactSearchModal")}
                            handleContactSelect={(key) => this.handleContactSelect(key, this.state.contactSearchType)}
                            contactSearchType={this.state.contactSearchType}
                            status={this.state.status}
                            enable_executed_edit={this.state.enable_executed_edit}
                        />
                        <LocationCodeModal
                            locationType={this.state.saveLocationType}
                            saveLocationCode={this.state.saveLocationCode}
                            handleInput={(name) => this.handleInput(name)}
                            open={this.state.locationCodeModal}
                            onClose={() => this.handleModalClose("locationCodeModal")}
                            onSkip={(type) => this.skipLocationCode(type)}
                            onSubmit={(type, code) => this.submitLocationCode(type, code)}
                        />
                        <FeetToInchesModal
                            open={this.state.feetToInchesModal}
                            feet={this.state.feet}
                            inches={this.state.inches}
                            onClose={() => this.handleModalClose("feetToInchesModal")}
                            feetChange={() => this.handleInput("feet")}
                            inchesChange={() => this.handleInput("inches")}
                            handleConversion={() => this.handleConversion()}
                        />
                        <SurfaceAreaModal
                            open={this.state.surfaceAreaModal}
                            onClose={() => this.handleModalClose("surfaceAreaModal")}
                            productLength={this.state.productLength}
                            productWidth={this.state.productWidth}
                            palletLength={this.state.palletLength}
                            palletWidth={this.state.palletWidth}
                            productLengthChange={e => this.handleSurfaceArea("productLength", e)}
                            productWidthChange={e => this.handleSurfaceArea("productWidth", e)}
                            palletLengthChange={e => this.handleSurfaceArea("palletLength", e)}
                            palletWidthChange={e => this.handleSurfaceArea("palletWidth", e)}
                            surfaceArea={this.state.surfaceArea}
                        />
                        <UnitSearchModal
                            open={this.state.unitSearchModal}
                            onClose={() => this.handleModalClose("unitSearchModal")}
                            handlingUnits={this.state.handling_units}
                            getHandlingUnits={() => this.getUnits(this.state.handling_units)}
                        />
                        <ProductSearchModal
                            open={this.state.productSearchModal}
                            onClose={() => this.handleModalClose("productSearchModal")}
                            products={this.state.products}
                            getProducts={() => this.getProducts(this.state.products)}
                            categoryColumns={prodCatCols}
                        />
                        <DuplicateBolsModal
                            open={this.state.duplicateBolsModal}
                            onClose={() => this.handleModalClose("duplicateBolsModal")}
                            duplicates={this.state.duplicates}
                            getDuplicates={() => this.getDuplicates(this.state.duplicates)}
                            continueExecution={() => this.handleContinue()}
                            cancelAllAndExecute={() => this.handleCancelAll()}
                        />
                        <PickupRequestModal
                            open={this.state.pickupRequestModal}
                            onClose={() => this.handleModalClose("pickupRequestModal")}
                            step={this.state.pickup_step}
                            loading={this.state.loadingPickup}
                            pickupDateTime={this.state.pickup_date_time}
                            dateTimeChange={(type, m) => this.handleDatetime(type, m)}
                            dockCloseTime={this.state.dock_close_time}
                            resetPickup={() => this.handlePickupRequest()}
                            nextStep={() => this.setState({ pickup_step: 2 })}
                            schedulePickup={() => this.schedulePickup()}
                            shipperDateTime={this.state.local_pickup_time}
                        />
                        <PrintDgdModal
                            open={this.state.printDgdModal}
                            onClose={() => this.handleModalClose("printDgdModal")}
                            handleInput={(name) => this.handleInput(name)}
                            step={this.state.dgd_step}
                            dgdPro={this.state.dgd_pro}
                            dgdBol={this.state.dgd_bol}
                            dgdPo={this.state.dgd_po}
                            dgdSo={this.state.dgd_so}
                            dgdRef={this.state.dgd_ref}
                            dgdUnits={this.state.dgd_units}
                            dgdGetUnits={() => this.getDgdUnits(this.state.dgd_units)}
                            next={() => this.setState({ dgd_step: 2 })}
                            back={() => this.setState({ dgd_step: 1 })}
                            loading={this.state.loadingDgd}
                            printDgd={() => this.printDgd()}
                        />
                        <TruckloadAuthModal
                            open={this.state.tlAuthModal}
                            onClose={() => this.handleModalClose("tlAuthModal")}
                            handleInput={(name) => this.handleInput(name)}
                            handleKeyPress={e => this.handleKeyPress("tlAuthenticate", e)}
                            username={this.state.username}
                            password={this.state.password}
                            tlAuthenticate={() => this.tlAuthenticate()}
                            loading={this.state.loadingAuth}
                        />
                        <CreateLoadModal
                            open={this.state.createLoadModal}
                            onClose={() => this.handleModalClose("createLoadModal")}
                            handleChange={() => this.handleChange}
                            shipmentType={this.state.shipmentType}
                            shipmentTypes={this.state.shipmentTypes}
                            getShipmentTypes={() => this.getShipmentTypes(this.state.shipmentTypes)}
                            equipment={this.state.equipment}
                            equipmentTypes={this.state.equipmentTypes}
                            getEquipmentTypes={() => this.getEquipmentTypes(this.state.equipmentTypes)}
                            customers={this.state.customers}
                            customerInput={this.state.customer_input}
                            customerInputValueChange={(value) => this.setState({ customer_input: value })}
                            customerInputOptionChange={(id, name) => this.setState({ customerId: id, customer_input: name })}
                            carrierTypes={this.state.carrierTypes}
                            carrierInput={this.state.carrier_input}
                            carrierInputValueChange={(value) => this.setState({ carrier_input: value })}
                            carrierInputOptionChange={(id, name) => this.setState({ carrierId: id, carrier_input: name })}
                            dateTimeChange={(type, m) => this.handleDatetime(type, m)}
                            pickupDockOpen={this.state.pickupDockOpen}
                            pickupDockClose={this.state.pickupDockClose}
                            dropoffDockOpen={this.state.dropoffDockOpen}
                            dropoffDockClose={this.state.dropoffDockClose}
                            addService={() => this.addService()}
                            services={this.state.services}
                            getServices={() => this.getServices(this.state.services)}
                            creatingShipment={this.state.creatingShipment}
                            createShipment={() => this.createShipment()}
                        />
                        <NonLccModal
                            selectClasses={selectClasses}
                            open={this.state.nonLccModal}
                            onClose={() => this.handleModalClose("nonLccModal")}
                            handleChange={this.handleChange}
                            getOptions={() => this.getOptions(this.state.company.non_lcc_tracking.options)}
                            handleInput={(name) => this.handleInput(name)}
                            company={this.state.company}
                            option={this.state.option}
                            custom={this.state.custom}
                            onCancel={ () =>
                                this.setState({ executingBol: false }, () => {
                                    this.handleModalClose("nonLccModal");
                                })
                            }
                            onSubmit={() => this.submitNonLcc()}
                        />
                        <SearchBolModal
                            open={this.state.searchBolModal}
                            onClose={() => this.handleModalClose("searchBolModal")}
                            bols={this.state.bols}
                            getBols={() => this.getBols(this.state.bols)}
                        />
                        <MergeBolModal
                            open={this.state.mergeBolModal}
                            onClose={() => this.handleModalClose("mergeBolModal")}
                            handleChange={this.handleChange}
                            handleInput={(name) => this.handleInput(name)}
                            handleKeyPress={(type, e) => this.handleKeyPress(type, e)}
                            searchBolMergeBy={this.state.search_bol_merge_by}
                            searchBolMerge={this.state.search_bol_merge}
                            searchBolMergeReferenceType={this.state.search_bol_merge_reference_type}
                            getRefTypes={() => this.getRefTypes(this.state.refTypes)}
                            bolsMerge={this.state.bolsMerge}
                            getBolsMerge={() => this.getBolsMerge(this.state.bolsMerge)}
                            handleSearchMerge={() => this.handleSearchMerge}
                        />
                        <LiabilityModal
                            classes={this.props.classes}
                            liability={this.state.liability}
                            open={this.state.liabilityModal}
                            onClose={() => {this.handleModalClose("liabilityModal")}}
                            title={this.state.liability_modal_title}
                            onRerate={this.requestExcess}
                        />
                        <PrintBolModal
                            open={this.state.printBolModal}
                            onClose={() => this.handleModalClose("printBolModal")}
                            showDraftPdf={this.state.showDraftPdf}
                            draftPdf={(type) => this.draftPdf(type)}
                            printBol={(isBlind) => this.printBol(isBlind)}
                        />
                        <SaveAsNewModal
                            open={this.state.saveAsNewModal}
                            onClose={() => this.handleModalClose("saveAsNewModal")}
                            saveAsNew={(copyPro) => this.saveAsNew(copyPro)}
                        />
                        <RequireEmailModal
                            open={this.state.requireEmailModal}
                            onClose={() => this.handleModalClose("requireEmailModal")}
                            closeModal={() => this.cancelUpdateConsigneeEmail()}
                            handleInput={(name) => this.handleInput(name)}
                            onSubmit={() => this.executeFromConsEmailModal()}
                        />
                        <CustomInputModal
                            open={this.state.customInputModal}
                            onClose={() => this.handleModalClose("customInputModal")}
                            closeModal={() => this.handleModalClose('customInputModal')}
                            handleInput={(name) => this.handleInput(name)}
                            onSubmit={() => this.handleModalClose('customInputModal')}
                            type={'number'}
                            title={'Full Declared Value Required'}
                            name={'total_declared_value'}
                            value={this.state.total_declared_value}
                            placeholder={'0.00'}
                            startAdornment={'$'}
                            fullWidth={false}
                            showCloseIcon={false}
                            showCloseButton={false}
                        />
                    </GridContainer>
                </div>
                <AppBar position="sticky" color="linkedin" className={classes.appBar}>
                        <Toolbar>
                            <GridItem xs={1}>
                                <Button
                                    size="sm"
                                    color="warning"
                                    className={classes.left}
                                    disabled={this.state.active <= 0}
                                    onClick={() => {
                                        this.setState(prevState => ({ active: prevState.active - 1 }));
                                        this.props.scrollTop(80);
                                    }}
                                >
                                    <FastRewind />
                                </Button>
                            </GridItem>
                            <GridItem xs={10}>
                                <GridContainer>
                                    <GridItem xs={12} className={classes.center}>
                                        {(this.state.showExecuteUpdate ||
                                            (this.state.showSave || 
                                            (!_.isEmpty(this.state.user) && (!_.isEmpty(this.state.user.parent_id) || this.state.user.level != "admin")))) && (
                                            <>
                                                <Button color="linkedin" size="md" style={{ marginTop: "4px" }}
                                                    id="basic-button"
                                                    aria-controls={this.state.showSaveMenu ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={this.state.showSaveMenu ? 'true' : undefined}
                                                    onClick={e => this.toggleMenu(e, "showSaveMenu")}
                                                    >
                                                    Save
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={this.state.showSaveMenu}
                                                    open={this.state.showSaveMenu}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    onClose={e => this.toggleMenu(false, "showSaveMenu")}
                                                >
                                                    {(this.state.showExecuteUpdate &&
                                                        <WithTooltip
                                                            title="Update BoL"
                                                            content="Update executed bol."
                                                        >
                                                            <span>
                                                                <MenuItem color="success" size="md" onClick={() => this.beginExecution()}>
                                                                    Update BOL
                                                                </MenuItem>
                                                            </span>
                                                        </WithTooltip>
                                                    )}
                                                {this.state.showSave && (
                                                    <WithTooltip
                                                        title="Save as Draft"
                                                        content="Save this BoL and set the status to 'On-Hold'"
                                                    >
                                                        <span>
                                                            <MenuItem
                                                                color="success"
                                                                size="md"
                                                                onClick={this.saveDraft}
                                                            >
                                                                <Save /> Save as Draft
                                                            </MenuItem>
                                                        </span>
                                                    </WithTooltip>
                                                )}
                                                {(!_.isEmpty(this.state.user) && (!_.isEmpty(this.state.user.parent_id) || this.state.user.level != "admin")) && (
                                                    <WithTooltip
                                                        title="Save as New"
                                                        content="Save a copy of this BoL with a new BoL # and set the status to 'On-Hold'"
                                                    >
                                                        <MenuItem
                                                            size="md"
                                                            color="linkedin"
                                                            onClick={() => this.handleSaveAsNew()}
                                                        >
                                                            <FileCopy /> Save as New
                                                        </MenuItem>
                                                    </WithTooltip>
                                                )}
                                                </Menu>
                                            </>
                                        )}

                                        {this.props.hasAutoTrackingPermission && !(this.state.autoSendTracking || this.state.trackingSent) && this.getIsExecuted() && !this.state.blind_shipment && (
                                            <WithTooltip
                                                title="Send Public Tracking to Consignee"
                                                content="Send the consignee tracking when available"
                                            >
                                                <Button
                                                    size="md"
                                                    color="linkedin"
                                                    onClick={() => this.toggleAutoTracking()}
                                                >
                                                    Send Public Tracking to Consignee
                                                </Button>
                                            </WithTooltip>
                                        )}
                                        {this.props.hasAutoTrackingPermission && this.state.trackingSent && this.getIsExecuted() && !this.state.blind_shipment && (
                                            <WithTooltip
                                                title="Resend Tracking Link "
                                                content="Re-send the consignee tracking"
                                            >
                                                <Button
                                                    size="md"
                                                    onClick={() => this.prepareResendTracking()}
                                                >
                                                    Resend Tracking Link
                                                </Button>
                                            </WithTooltip>
                                        )}
                                        {this.props.hasAutoTrackingPermission && this.state.autoSendTracking && !this.state.trackingSent &&  this.getIsExecuted() && (
                                            <WithTooltip
                                                title="Cancel Public Tracking Link"
                                                content="Cancel the sending of the public tracking link"
                                            >
                                                <Button
                                                    color="danger"
                                                    size="md"
                                                    onClick={() => this.toggleAutoTracking()}
                                                >
                                                    Cancel Auto Tracking
                                                </Button>
                                            </WithTooltip>
                                        )}
                                        {this.state.showSendAsn && (
                                            <WithTooltip
                                                title="Send an ASN"
                                                content="Send the consignee an Advanced Shipment Notification"
                                            >
                                                <Button
                                                    color="linkedin"
                                                    size="md"
                                                    onClick={() => this.sendAsn()}
                                                >
                                                    Send ASN
                                                </Button>
                                            </WithTooltip>
                                        )}
                                        {showExecute && (
                                            <>
                                                {this.state.executingBol ? (
                                                    <Button color="success" size="md" style={{ lineHeight: 0 }}>
                                                        <CircularProgress size={16} style={{ color: "white" }} />
                                                    </Button>
                                                ) : (
                                                    <WithTooltip
                                                        title="Execute Bill of Lading"
                                                        content="Save all the latest changes, set the status to 'Executed,' and enable printing"
                                                    >
                                                        <span>
                                                            <Button color="success" size="md" onClick={this.beginExecution}>
                                                                Execute
                                                            </Button>
                                                        </span>
                                                    </WithTooltip>
                                                )}
                                                </>
                                        )}
                                        {this.state.data_type === "truckload" && !executed && (
                                            <>
                                                {this.state.executingBol ? (
                                                    <Button color="success" size="md" style={{ lineHeight: 0 }}>
                                                        <CircularProgress size={16} style={{ color: "white" }} />
                                                    </Button>
                                                ) : (
                                                    <WithTooltip
                                                        title="Execute Bill of Lading"
                                                        content="Save all the latest changes, set the status to 'Executed,' and enable printing"
                                                    >
                                                        <span>
                                                            <Button color="success" size="md" onClick={() => this.submitToTruckload()}>
                                                                Submit to TFM Truckload for Review
                                                            </Button>
                                                        </span>
                                                    </WithTooltip>
                                                )}
                                            </>
                                        )}
                                        {!_.isEmpty(this.state.body) && this.state.allow_auto_pro && this.state.showGetPro && (
                                            <WithTooltip
                                                title="PRO Book"
                                                content="Retrieve an available PRO # for the carrier selected"
                                            >
                                                <Button justIcon color="linkedin" size="md" style={{ marginTop: "4px" }} onClick={this.getPro}>
                                                    <Book />
                                                </Button>
                                            </WithTooltip>
                                        )}
                                        {(this.state.showDraftPdf ||
                                            this.state.showPrint ||
                                            this.state.showPrintLabel ||
                                            (!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_costco_label == 1 && this.state.showPrintLabel) ||
                                            this.state.showPrintQuote ||
                                            this.state.showPrintPkList ) && (
                                            <>
                                            <Button color="linkedin" size="md" style={{ marginTop: "4px" }}
                                                id="basic-button"
                                                aria-controls={this.state.showPrintMenu ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={this.state.showPrintMenu ? 'true' : undefined}
                                                onClick={e => this.toggleMenu(e, "showPrintMenu")}
                                            >
                                                Print
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={this.state.showPrintMenu}
                                                open={this.state.showPrintMenu}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                                }}
                                                onClose={e => this.toggleMenu(false, "showPrintMenu")}
                                            >
                                                {this.state.showDraftPdf && (
                                                    <MenuItem color="linkedin" size="md" onClick={this.handleDraft}>
                                                        Draft PDF
                                                    </MenuItem>
                                                )}
                                                {this.state.showPrint && (
                                                    <MenuItem color="linkedin" size="md" onClick={this.handlePrint}>
                                                        Print BOL
                                                    </MenuItem>
                                                )}
                                                {this.state.showPrintLabel && (
                                                    <MenuItem color="linkedin" size="md" onClick={this.printLabel}>
                                                        Print Label
                                                    </MenuItem>
                                                )}
                                                {!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_costco_label == 1 && this.state.showPrintLabel && (
                                                    <MenuItem color="linkedin" size="md" onClick={this.printCostcoLabel}>
                                                        Print Costco Wholesale Shipping Label
                                                    </MenuItem>
                                                )}
                                                {this.state.showPrintQuote && (
                                                    <a target="_blank" style={{color: "inherit", textDecoration: "inherit"}} href={apiUrl + "/index.php?p=api&c=billoflading&m=printQuote&d=" + this.state.bolId + "/" + this.state.date}>
                                                        <MenuItem color="linkedin" size="md">
                                                            Print Quote
                                                        </MenuItem>
                                                    </a>
                                                )}
                                                {this.state.showPrintPkList && (
                                                    <a style={{color: "inherit", textDecoration: "inherit"}} href={apiUrl + "/index.php?p=api&c=billoflading&m=packingList&d=" + this.state.bolId + "/" + this.state.date}>
                                                        <MenuItem color="linkedin" size="md">
                                                            Packaging List
                                                        </MenuItem>
                                                    </a>
                                                )}
                                            </Menu>
                                            </>
                                        )}
                                        {!_.isEmpty(this.state.user) && this.props.hasSubmitPickupPermission && this.state.status !== "scheduled" && this.state.showPickupRequest && (
                                            <WithTooltip
                                                title="Pickup Request"
                                                content="Schedule this shipment for pickup by the chosen carrier when available."
                                            >
                                                <span>
                                                    <Button color="linkedin" size="md" onClick={this.handlePickupRequest}>
                                                        Pickup Request
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                        )}
                                        {this.state.showCancel && (
                                            <WithTooltip
                                                title="Cancel BoL"
                                                content="Cancel this BoL. This action cannot be undone, and it does not cancel any scheduled pickups with carrier."
                                            >
                                                <span>
                                                    <Button color="danger" size="md" onClick={this.handleCancel}>
                                                        Cancel
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                        )}
                                        {this.state.showUncancel && (
                                            <WithTooltip
                                                title="Uncancel BoL"
                                                content="Uncancel this BoL."
                                            >
                                                <span>
                                                    <Button color="warning" size="md" onClick={this.handleUncancel}>
                                                        Uncancel
                                                    </Button>
                                                </span>
                                            </WithTooltip>
                                        )}
                                    {!_.isEmpty(this.state.user) && (this.state.user.user_company_settings.enable_dgd == 1 || this.props.hasTlIntegrationPermission) && (
                                        <>
                                        {!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 && this.state.showPrintDgd && (
                                            <WithTooltip
                                                title="Print DGD"
                                                content="Print Dangerous Goods Declaration"
                                            >
                                                <Button color="linkedin" size="md" onClick={this.handleDgd}>
                                                    DGD
                                                </Button>
                                            </WithTooltip>
                                            )}
                                            {!_.isEmpty(this.state.user) && this.props.hasTlIntegrationPermission && this.state.showSendToTl && _.isEmpty(this.state.loadId) && (
                                                <span className={classes.marginRight}>
                                                    {this.state.loadingTl ? (
                                                        <Button color="linkedin" size="md" style={{ lineHeight: 0 }}>
                                                            <CircularProgress size={16} style={{ color: "white" }} />
                                                        </Button>
                                                    ) : (
                                                        <WithTooltip
                                                            title="Send to Truckload"
                                                            content="Send the shipment information to TFM Truckload."
                                                        >
                                                            <Button color="linkedin" size="md" onClick={this.handleTlAuth}>
                                                                Send to TL
                                                            </Button>
                                                        </WithTooltip>
                                                    )}
                                                </span>
                                            )}
                                            {!_.isEmpty(this.state.user) && this.props.hasTlIntegrationPermission && !_.isEmpty(this.state.loadId) && (
                                                <WithTooltip
                                                    title="View Load in Truckload"
                                                    content="View the shipment load in TFM Truckload."
                                                >
                                                    <Button color="linkedin" size="md" onClick={() => this.viewShipment(this.state.loadId)}>
                                                        View Load in TL
                                                    </Button>
                                                </WithTooltip>
                                            )}
                                        </>
                                    )}
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={1}>
                                <Button
                                    size="sm"
                                    color="warning"
                                    className={classes.right}
                                    disabled={this.state.active >= 4}
                                    onClick={() => {
                                        this.setState(prevState => ({ active: prevState.active + 1 }));
                                        this.props.scrollTop(80);
                                    }}
                                >
                                    <FastForward />
                                </Button>
                            </GridItem>
                        </Toolbar>
                </AppBar>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        hasHandlingUnitRead: hasPermission("HANDLING_UNIT_READ")(state),
        hasAddressBookRead: hasPermission("ADDRESS_BOOK_READ")(state),
        hasAddressBookWrite: hasPermission("ADDRESS_BOOK_WRITE")(state),
        hasProductRead: hasPermission("PRODUCT_CATALOG_READ")(state),
        hasProductWrite: hasPermission("PRODUCT_CATALOG_WRITE")(state),
        hasSubmitPickupPermission: hasPermission("SUBMIT_PICKUP_REQUEST")(state),
        hasAutoTrackingPermission: hasPermission("USE_PUBLIC_TRACKING")(state),
        hasVolumeQuotePermission: hasPermission("USE_VOLUME_QUOTE")(state),
        hasTlIntegrationPermission: hasPermission("USE_TL_INTEGRATION")(state),
        hasTlQuotePermission: hasPermission("USE_TL_QUOTE")(state),
        hasBolLevelPermission: hasPermission("USE_BOL_LEVEL")(state),
        userIsAdmin: userIsAdmin(state),
        ltlRateDisplay: getRateDisplayByMode("ltl")(state),
        volumeRateDisplay: getRateDisplayByMode("volume")(state),
        printers: state.PrintZplModal.printers,
        JSPM: state.PrintZplModal.JSPM,
        ttlUrl: state.Environment.ttl_index_url,
        yatEnabled: state.Environment.yat_enabled,
        yatUrl: state.Environment.yat_index_url,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        loadNotesModal,
        loadTerminalModal,
        openAlertModal,
        loadLevelsIfEmpty,
        loadAutoSelectAccessorialMaps,
        setJSPM,
        setAvailablePrinters,
        jspmStatus,
        setUpPrinters,
		autoPrint,
		printDocument,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withSnackbar(BillOfLading)));
