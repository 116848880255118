import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import qs from "qs";

import { NavLink, Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";


// material ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

const SlideTransition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});


// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class AutoCalcAccessorials extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			alert: null,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			autoCalcAccs: [],
			filters: {
				id: "",
				name: "",
				carrier: "",
				accessorial: "",
				startDate: "",
				endDate: ""
			},
			searching: false,
			addAccModal: false,
			acc_name: "",
			acc_carrier: "",
			acc: "",
			redirect: false,
			newAccId: "",
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleFilters = this.handleFilters.bind(this);
		this.addAcc = this.addAcc.bind(this);
		this.deleteAcc = this.deleteAcc.bind(this);
		this.handleDeleteAcc = this.handleDeleteAcc.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=autoCalcAccessorials"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					autoCalcAccs: data.body.autoCalcAccs,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"Unexpected response when loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput(name, e) {
		this.setState({ [name]: e.target.value });
	};
	handleFilters = name => event => {
		let { filters } = this.state;
		filters[name] = event.target.value;
		this.setState({ filters });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	async handleSearch() {
		this.setState({searching: true});

		const { filters } = this.state;
		let isEmpty = true;

		for(const filter in filters) {
			if (filters[filter] != "") isEmpty = false;
		}

		if (isEmpty) {
			this.props.setNotification("Search values are required", { variant: "error" });
			this.setState({searching: false});
			return false;
		}

		let searchFilters = 'filters[id]=' + encodeURI(filters.id) + '&filters[name]=' + encodeURI(filters.name) + '&filters[carrier]=' + encodeURI(filters.carrier) + '&filters[accessorial]=' + encodeURI(filters.accessorial) + '&filters[startDate]=' + encodeURI(filters.startDate) + '&filters[endDate]=' + encodeURI(filters.endDate);

		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=autoCalcAccessorials&" + searchFilters
			);
			const data = response.data;
			if (typeof data !== "string") {
				this.props.handleMessages(response);
				this.setState({
					autoCalcAccs: data.body.autoCalcAccs,
				});
			} else {

				this.props.setNotification(
					"Unexpected response when loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
		this.setState({searching: false});
	}
	getCarriers(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{"(" + prop.scac + ")  " + prop.carrier}
				</MenuItem>
			);
		});
	}
	getAccessorials(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.code + " - " + prop.name}
				</MenuItem>
			);
		});
	}
	getHeaders() {
		return [
			"ID",
			"Name",
			"Carrier",
			"Accessorial",
			"Start Date",
			"End Date",
			"Actions"
		];
	}
	getColumns(data) {
		return Object.values(data).map((prop, key) => {
			return [
				prop.id,
				prop.name,
				prop.carrier.scac + " - " + prop.carrier.name,
				prop.accessorial.code + " - " + prop.accessorial.name,
				prop.startDate,
				prop.endDate,
				<div className="actions-right">
					<NavLink to={basePath + "/admin/auto-calc-accessorials/view/" + prop.id}>
						<Button
							justIcon
							round
							size="sm"
							color="info"
							style={{
								marginRight: "4px"
							}}
						>
							<Edit />
						</Button>
					</NavLink>
					<Button
						justIcon
						round
						size="sm"
						color="danger"
						style={{
							marginRight: "4px"
						}}
						onClick={() => this.deleteAcc(prop.id, key)}
					>
						<Close />
					</Button>
				</div>
			];
		});
	}
	deleteAcc(id, index) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this auto-calc accessorial? This action may affect historical rates. You should only continue if this auto-calc accessorial was created in error."
					onConfirm={() => this.handleDeleteAcc(id, index)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async handleDeleteAcc(id, index) {
		this.setState({alert: null});

		const { autoCalcAccs } = this.state.body;

		try {
			let data = {id: id};
			const response = await axios.post('/index.php?p=api&r=json&c=admin&m=deleteAutoCalcAccessorial', qs.stringify(data));

			if (typeof response.data !== "string" && response.data.body.deleted) {
				this.props.handleMessages(response);
				autoCalcAccs.splice(index, 1);
				this.setState({autoCalcAccs});
			} else {
				this.props.setNotification("Unexpected response when attempting to delete the accessorial!", {
					variant: "error"
				});
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the accessorial!", {
				variant: "error"
			});
		}
	}
	async addAcc() {
		const { acc_name, acc_carrier, acc } = this.state;

		if(typeof acc_name == 'undefined' || acc_name == '') {
			this.props.setNotification("Please name this auto calc accessorial.", {
				variant: "error"
			});
			return;
		}

		if(typeof acc_carrier == 'undefined' || acc_carrier == '') {
			this.props.setNotification("No carrier ID provided.", {
				variant: "error"
			});
			return;
		}

		if(typeof acc == 'undefined' || acc == '') {
			this.props.setNotification("No accessorial ID provided.", {
				variant: "error"
			});
			return;
		}

		let data = {
			name: acc_name,
			carrierId: acc_carrier,
			accessorialId: acc,
		}

		try {
			const response = await axios.post('/index.php?p=api&r=json&c=admin&m=saveAutoCalcAccessorial', qs.stringify(data));

			if (typeof response.data !== "string" && response.data.body.autoCalcAcc) {
				this.props.handleMessages(response);
				await this.setState({redirect: true, newAccId: response.data.body.autoCalcAcc.id});
			} else {
				this.props.setNotification("Unexpected response when saving the accessorial!", {
					variant: "error"
				});
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error saving the accessorial!", {
				variant: "error"
			});
		}

	}
	render() {
		const { classes } = this.props;
		if (this.state.redirect) {
			return <Redirect to={basePath + "/admin/auto-calc-accessorials/view/" + this.state.newAccId} />;
		}
		return (
			<GridContainer>
			{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs />
										<GridItem xs={10}>
											<Paper
												className={classes.infoPaper}
												elevation={1}
											>
												<b>Auto Calc Accessorials</b>:<br />
												Use this page to create accessorials
												that will be added onto a rate based
												on shipment criteria. When using
												this feature, keep in mind:
												<br />
												<br />
												Accessorials will affect rates based
												on the effective dates provided
												<br />
												Accessorials of the same code
												returned in rates will be
												overwritten by TTMS auto-calc
												accessorials
												<br />
												Accessorials should not be deleted
												unless they were created in error.
											</Paper>
										</GridItem>
										<GridItem xs />
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} style={{paddingTop: '15px'}}>
									<GridContainer>
										<GridItem xs>
											<InputLabel
												htmlFor="id"
												className={
													classes.selectLabel
												}
											>
												ID
											</InputLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "id",
													value:
														this.state.filters.id ||
														"",
													onChange: this.handleFilters("id")
												}}
												white
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel
												htmlFor="name"
												className={
													classes.selectLabel
												}
											>
												Name
											</InputLabel>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													id: "name",
													name: "name",
													value:
														this.state.filters
															.name || "",
													onChange: this.handleFilters(
														"name"
													)
												}}
												white
											/>
										</GridItem>
										<GridItem xs>
											<InputLabel
												htmlFor="carrier"
												className={
													classes.selectLabel
												}
											>
												Carrier
											</InputLabel>
											<FormControl
												fullWidth
												className={
													classes.selectFormControl
												}
											>
												<Select
													MenuProps={{
														className:
															classes.selectMenu
													}}
													classes={{
														select:
															classes.select
													}}
													value={
														this.state.filters
															.carrier || ""
													}
													inputProps={{
														id: "carrier",
														name: "carrier"
													}}
													onChange={this.handleFilters(
														"carrier"
													)}
												>
													<MenuItem
														classes={{
															root:
																classes.selectMenuItem,
															selected:
																classes.selectMenuItemSelected
														}}
														value=""
													/>
													{this.state.body &&
														this.state.body
															.carriers &&
														this.getCarriers(
															this.state.body
																.carriers
														)}
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs>
											<InputLabel
												htmlFor="accessorial"
												className={
													classes.selectLabel
												}
											>
												Accessorial
											</InputLabel>
											<FormControl
												fullWidth
												className={
													classes.selectFormControl
												}
											>
												<Select
													MenuProps={{
														className:
															classes.selectMenu
													}}
													classes={{
														select:
															classes.select
													}}
													value={
														this.state.filters
															.accessorial || ""
													}
													inputProps={{
														id: "accessorial",
														name: "accessorial"
													}}
													onChange={this.handleFilters(
														"accessorial"
													)}
												>
													<MenuItem
														classes={{
															root:
																classes.selectMenuItem,
															selected:
																classes.selectMenuItemSelected
														}}
														value=""
													/>
													{this.state.body &&
														this.state.body
															.accessorials &&
														this.getAccessorials(
															this.state.body
																.accessorials
														)}
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs>
											<InputLabel
												className={classes.selectLabel}
											>
												Start Date
											</InputLabel>
											<FormControl fullWidth>
												<CustomInput
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "text",
														id: "startDate",
														name: "startDate",
														placeholder: 'YYYY-MM-DD',
														value: this.state.filters.startDate,
														onChange: this.handleFilters("startDate"),
													}}
													white
												/>
											</FormControl>
										</GridItem>
										<GridItem xs>
											<InputLabel
												className={classes.selectLabel}
											>
												End Date
											</InputLabel>
											<FormControl fullWidth>
												<CustomInput
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "text",
														id: "endDate",
														name: "endDate",
														placeholder: 'YYYY-MM-DD',
														value: this.state.filters.endDate,
														onChange: this.handleFilters("endDate"),
													}}
													white
												/>
											</FormControl>
										</GridItem>
										<GridItem xs>
											{!this.state.searching ? (
												<Button
													color="linkedin"
													onClick={() => {this.handleSearch()}}
													style={{
														marginRight: "4px"
													}}
												>
													Search
												</Button>
											) : (
												<Button
													color="linkedin"
													style={{
														marginRight: "4px"
													}}
												>
													<CircularProgress size={16} style={{ color: "white" }} />
												</Button>
											)}
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12}>
									<Button
										color="linkedin"
										onClick={() => {this.handleModalOpen('addAccModal')}}
										style={{
											marginRight: "4px"
										}}
									>
										<Add /> Add Accessorial
									</Button>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) &&
										!_.isEmpty(
											this.state.autoCalcAccs
										) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(
													this.state.autoCalcAccs
												)}
												customCellClasses={[
													classes.right
												]}
												customClassesForCells={[6]}
												customHeadCellClasses={[
													classes.right
												]}
												customHeadClassesForCells={[6]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{
														"No auto calc accessorials to display"
													}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve auto calc accessorials from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.addAccModal}
					fullWidth={true}
					maxWidth={'md'}
					TransitionComponent={SlideTransition}
					keepMounted
					onClose={() => this.handleModalClose("addAccModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("addAccModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Add New Accessorial</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						<GridContainer className={classes.left}>
							<GridItem xs={4}>
								<InputLabel className={classes.label}>Name</InputLabel>
                                <br />
								<CustomInput
									formControlProps={{ fullWidth: true }}
									inputProps={{
										type: "text",
										name: "acc_name",
										value: this.state.acc_name || "",
										onChange: (e) => {this.handleInput("acc_name", e)}
									}}
									required
								/>
							</GridItem>
							<GridItem xs={4}>
								<InputLabel className={classes.label}>Carrier</InputLabel>
                                <br />
								<FormControl
									fullWidth
									className={
										classes.selectFormControl
									}
									required
								>
									<Select
										MenuProps={{
											className:
												classes.selectMenu
										}}
										classes={{
											select:
												classes.select
										}}
										value={
											this.state.acc_carrier || ""
										}
										inputProps={{
											id: "carrier",
											name: "acc_carrier"
										}}
										onChange={(e) => {this.handleChange(e)}}
									>
										<MenuItem
											classes={{
												root:
													classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value=""
										/>
										{this.state.body &&
											this.state.body
												.carriers &&
											this.getCarriers(
												this.state.body
													.carriers
											)}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs={4}>
								<InputLabel className={classes.label}>Accessorial</InputLabel>
                                <br />
								<FormControl
									fullWidth
									className={
										classes.selectFormControl
									}
									required
								>
									<Select
										MenuProps={{
											className:
												classes.selectMenu
										}}
										classes={{
											select:
												classes.select
										}}
										value={
											this.state.acc || ""
										}
										inputProps={{
											id: "accessorial",
											name: "acc"
										}}
										onChange={(e) => {this.handleChange(e)}}
									>
										<MenuItem
											classes={{
												root:
													classes.selectMenuItem,
												selected:
													classes.selectMenuItemSelected
											}}
											value=""
										/>
										{this.state.body &&
											this.state.body
												.accessorials &&
											this.getAccessorials(
												this.state.body
													.accessorials
											)}
									</Select>
								</FormControl>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("addAccModal")} color="white" className={classes.marginRight}>
							Close
						</Button>
						{" "}
						{this.state.addingAcc ? (
							<Button size="sm" color="linkedin">
								<CircularProgress size={16} style={{ color: "white" }} />
							</Button>
						) : (
							<Button
								onClick={ () => {
									this.addAcc();
								}}
								color="linkedin"
							>
								Add
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(AutoCalcAccessorials));