import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "~/components/CustomButtons/Button.jsx";
import Fade from "@material-ui/core/Fade";
import { onConfirm, onClose } from "../actions/Alert.jsx";

class Alert extends Component {

    render() {
        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Fade}
                maxWidth="sm"
            >
                <DialogContent style={{textAlign: "center"}}>
                    {this.props.message}
                </DialogContent>
                <DialogActions style={{justifyContent: "center"}}>
                    <Button onClick={this.props.onConfirm} color="success">
                        Confirm
                    </Button>
                    <Button onClick={this.props.onClose} color="danger">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

const mapStateToProps = state => {
    return {
        open: state.Alert.open,
        message: state.Alert.message,
        onConfirm: state.Alert.onConfirm,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        onConfirm,
        onClose,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);