import React from "react";
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";
import { Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';

import FormLabel from '@material-ui/core/FormLabel';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },


  });



  // Inspired by blueprintjs
  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }


class AgingFilterPage extends React.Component {
    constructor(props) {
        super(props)
        this.state =
        {
            mounted: false,
            show: false,
            loading: true,
            selectedAgeValue: "30",
            selectedAgeOrder: "asc",
        }

        this.handleAgeValueChange = this.handleAgeValueChange.bind(this);
        this.handleAgeOrderChange = this.handleAgeOrderChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

    }


    async componentDidMount() {
        this.setState({ mounted: true });
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }


    handleAgeValueChange = (event) => {
        this.setState({ selectedAgeValue: event.target.value })
    }

    handleAgeOrderChange = (event) => {
        this.setState({ selectedAgeOrder: event.target.value })
    }

    async clearFilters() {
        this.setState({
            selectedAgeValue: "30",
            selectedAgeOrder: "asc",
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <GridContainer style={{ paddingLeft: "20px" }}>
                <GridContainer direction={"row"} style={{ paddingLeft: "20px" }} >

                    <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "30px" }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style = {{color : "black", fontSize : "13px", fontWeight: "normal"}} >Select Aging Days </FormLabel>
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                                <FormControlLabel
                                    checked={this.state.selectedAgeValue === "30"}
                                    onChange={this.handleAgeValueChange}
                                    value="30"
                                    name="radio-button-demo"
                                    aria-label="A"
                                    classes={{
                                        root: classes.radio, checked: classes.checked,
                                    }}
                                    label={<Typography style={{color : "black", fontSize : "14px", fontWeight: "normal"}}>30+ </Typography>}
                                    control={<StyledRadio />}
                                />
                                <FormControlLabel
                                    checked={this.state.selectedAgeValue === "60"}
                                    onChange={this.handleAgeValueChange}
                                    value="60"
                                    name="radio-button-demo"
                                    aria-label="A"
                                    classes={{
                                        root: classes.radio, checked: classes.checked,
                                    }}
                                    label={<Typography style={{color : "black", fontSize : "14px", fontWeight: "normal"}}>60+ </Typography>}
                                    control={<StyledRadio />}
                                />
                                <FormControlLabel
                                    checked={this.state.selectedAgeValue === "90"}
                                    onChange={this.handleAgeValueChange}
                                    value="90"
                                    name="radio-button-demo"
                                    aria-label="A"
                                    classes={{
                                        root: classes.radio, checked: classes.checked,
                                    }}
                                    label={<Typography style={{color : "black", fontSize : "14px", fontWeight: "normal"}}>90+ </Typography>}
                                    control={<StyledRadio />}
                                />

                                <FormControlLabel
                                    checked={this.state.selectedAgeValue === "120"}
                                    onChange={this.handleAgeValueChange}
                                    value="120"
                                    name="radio-button-demo"
                                    aria-label="A"
                                    classes={{
                                        root: classes.radio, checked: classes.checked,
                                    }}
                                    label={<Typography style={{color : "black", fontSize : "14px", fontWeight: "normal"}}>120+ </Typography>}
                                    control={<StyledRadio />}
                                />

                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <GridContainer direction={"row"} style={{ paddingLeft: "20px" }}>

                    <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "20px" }}>
                        <FormControl component="fieldset" >
                            <FormLabel component="legend" style = {{color : "black", fontSize : "13px", fontWeight: "normal"}}>Order Aging Days By  </FormLabel>
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                                <FormControlLabel
                                    checked={this.state.selectedAgeOrder === "asc"}
                                    onChange={this.handleAgeOrderChange}
                                    value="asc"
                                    name="radio-button-demo"
                                    aria-label="A"
                                    classes={{
                                        root: classes.radio, checked: classes.checked,
                                    }}
                                    label={<Typography style={{color : "black", fontSize : "14px", fontWeight: "normal"}}>Ascending </Typography>}
                                    control={<StyledRadio />}
                                />
                                <FormControlLabel
                                    checked={this.state.selectedAgeOrder === "desc"}
                                    onChange={this.handleAgeOrderChange}
                                    value="desc"
                                    name="radio-button-demo"
                                    aria-label="A"
                                    classes={{
                                        root: classes.radio, checked: classes.checked,
                                    }}
                                    label={<Typography style={{color : "black", fontSize : "14px", fontWeight: "normal"}}>Descending </Typography>}
                                    control={<StyledRadio />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                </GridContainer>


                <GridContainer style={{ paddingTop: "40px", paddingLeft: "20px" }}>
                    <GridItem>
                        <Button size="md" color="white" onClick={this.clearFilters}>  <ClearAll /> Clear </Button>
                    </GridItem>

                    <GridItem>
                        <Link to={{
                            pathname: basePath + "/admin/carrier-aging-report/search",

                            state: {
                                selectedAgeValue: this.state.selectedAgeValue,
                                selectedAgeOrder: this.state.selectedAgeOrder
                            }
                        }} >
                            <Button size="md" color="linkedin">  <Search /> Search </Button>
                        </Link>
                    </GridItem>
                </GridContainer>

            </GridContainer>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(AgingFilterPage)));