import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getUserSetting } from '../../../redux/selectors/Admin.jsx';
import Skeleton from 'react-loading-skeleton';
import ReactTable from 'react-table-6';
import GridItem from "~/components/Grid/GridItem.jsx";
import { fetchTFMInvoices, setTfmInvoicesPageNumber, downloadCarrierImages, prepareCarrierImages, checkReadyImages, fetchImageTypes } from '../actions/CustomerPortal.jsx'
import TopActions from "../components/TopActions.jsx";
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatCurrency } from "../../../helpers.jsx";
import { Link } from 'react-router-dom'
import { basePath } from '../../../variables/server.jsx';
import "../components/InvoiceList.css";
import { searchObject } from "../../../helpers.jsx";

const DEFAULT_PAGE_SIZE = 15;

class TFMInvoicesContainer extends Component {
	state = {
		initialized: false,
		runningCheckImages: false,
	}

	async componentDidMount() {
		this.props.setTfmInvoicesPageNumber(1);
		await this.props.fetchTFMInvoices();
		if(!_.isEmpty(this.props.invoices)) {
			await this.checkReadyImages(this.props.invoices);
		}
		if(this.props.restrictDocs) {
			await this.props.fetchImageTypes();
		}
		this.runCheckReadyImages();
		this.setState({initialized: true});
	}
	runCheckReadyImages = async () => {
		if (!this.state.runningCheckImages) {
			//runOnce is here to force it to run at least one time
			//in the event that runningCheckImages is set to false
			//between the time runningCheckImages is set true			
			//and before the start of the while loop
			let runOnce = true;
			this.setState({runningCheckImages: true});
			while(this.state.runningCheckImages || runOnce) {
				runOnce = false;
				await new Promise(r => setTimeout(r, 60000));
				await this.checkReadyImages(this.props.invoices);
				if (this.props.loadingList.length == 0) {
					this.setState({runningCheckImages: false});
				}
			}
		}
		//checkReadyImages may set the loadingList to [] before the file is available, so check again here.
		await new Promise(r => setTimeout(r, 1000));
		await this.checkReadyImages(this.props.invoices);
		if(this.props.loadingList.length > 0) {
			this.runCheckReadyImages();
		}
	}

	checkReadyImages = async (invoices) => {
		let invoiceList = invoices.map(invoice => {
			if(invoice.invoice_number != undefined && invoice.invoice_number != "") {
				return {
					broker_id: invoice.company.id,
					invoice_number:invoice.invoice_number,
					invoice_id: invoice.id
				};
			}
		});
		let readyImages = await this.props.checkReadyImages(invoiceList);
		if(readyImages != undefined) {
			return true;
		}
		return false;
	}

	numberPages = () => {
		return +this.props.pageSize > this.props.totalRecords
			? 1
			: Math.ceil(+this.props.totalRecords / +this.props.pageSize);
	}

	onPageChange = async index => {
		this.props.setTfmInvoicesPageNumber(index + 1);
		await this.props.fetchTFMInvoices();
		await this.checkReadyImages(this.props.invoices);
		this.runCheckReadyImages();
	}

	downloadCarrierImages = (brokerId, invoiceNumber, id) => {
		if (!this.props.downloadCarrierImagesLoading) {
			this.props.downloadCarrierImages(brokerId, invoiceNumber, id);	
		}
	}
	prepareCarrierImages = (brokerId, invoiceNumber, id) => {
		this.props.prepareCarrierImages(brokerId, invoiceNumber, id);	
		if(!this.state.runningCheckImages) {
			this.runCheckReadyImages();
		}
	}

	render() {
		if (!this.state.initialized) {
			return this.loading();
			
		} 
		let columns = [
			{Header: "Invoice #", Cell: rowInfo => {
				return (
					<Link to={`${basePath}/admin/customer-portal/view-invoices/${rowInfo.original.company_name_id}/${rowInfo.original.invoice_number}`}>
						{rowInfo.original.invoice_number}
					</Link>
				);
			}},
			{Header: "Customer", accessor: "company.name"},
			{Header: "Amount", Cell: rowInfo => {
				return formatCurrency(rowInfo.original.amount);
			}},
			{Header: "Paid", Cell: rowInfo => {
				return +rowInfo.original.paid ? "Y" : "N";
			}},
			{Header: "Due Date", Cell: rowInfo => {
				let date = rowInfo.original.due_date;
				if (typeof date === "string" && date.length >= 10) {
					date = moment(date.substring(0, 10), "YYYY-MM-DD").format("MM/DD/YYYY");
				}
				return date;
			}},
			{Header: "", Cell: rowInfo => {
				const row = rowInfo.original;
				// restricted_image_count - Filtered count that does not include image file types of 'INV', 'DOC', NULL, ''.
				let imgCount = this.props.restrictDocs ? "supportingData.restricted_image_count" : "supportingData.image_count";

				if (+searchObject(row, imgCount, 0) < 1) {
					return <span style={{color: "gray", opacity: ".6"}}>No Carrier Images</span>;
				}

				if(this.props.readyList.indexOf(row.invoice_number) > -1) {
					return (
						<a href="#" onClick={e => {
							e.preventDefault();
							this.downloadCarrierImages(row.company_name_id, row.invoice_number.trim(), row.id);
						}}>Download Carrier Images</a>
					)
				} else if(this.props.loadingList.indexOf(row.invoice_number) > -1) {
					return (
						<span>Preparing carrier images...</span>
					)
				} else {
					return (
						<a href="#" onClick={e => {
						e.preventDefault();
						this.prepareCarrierImages(row.company_name_id, row.invoice_number, row.id);
						}}>Request Carrier Images</a>
					)
				}
			}},
		];

		if(this.props.hideTfmInvoiceAmounts) {
			columns = columns.filter(el => el.Header !== "Amount")
		}

		return (
			<Fragment>
				<TopActions>
					<Fragment>
						<GridItem xs={8}>
							{this.props.downloadCarrierImagesLoading && (
								<Fragment>
									<CircularProgress size={30}/>
								</Fragment>
							)}
						</GridItem>
						{this.state.initialized && <GridItem xs={4} sm={4} md={4} style={{ textAlign:"right", color: "#000"}}>
							<h4 style={{marginRight: "5px"}}>{`Records: ${this.props.totalRecords}`}</h4>
						</GridItem>}
					</Fragment>
				</TopActions>
				<div className="react-table-condensed">
					<ReactTable
						data={this.props.invoices || []}
						pages={this.numberPages()}
						columns={columns}
						loading={this.props.loading}
						className={this.props.className || "-striped -highlight"}
						defaultPageSize={this.props.defaultPageSize || DEFAULT_PAGE_SIZE}
						showPaginationTop={true}
						showPaginationBottom={false}
						showPageSizeOptions={false}
						sortable={false}
						manual={true}
						onPageChange={this.onPageChange}
						minRows={this.props.minRows || this.state.pageSize}
						getTrProps={(state, rowInfo) => {
							if (rowInfo && rowInfo.original && !+rowInfo.original.paid) {
								if (rowInfo.original.due_date) {
									const dueDate = moment(rowInfo.original.due_date.substring(0, 10), "YYYY-MM-DD");
									if (dueDate.isValid() && dueDate.isBefore(moment())) {
										return {
											style: {
												backgroundColor: "rgb(242, 222, 222)"
											}
										};
									}
								}
								return {
									style: {
										backgroundColor: "rgb(208, 244, 198)"
									}
								};
							}
							return {};
						}}
					/>
				</div>
			</Fragment>
		);
	}

	loading() {
		return (
			<div style={{marginTop: "40px"}}>
				<Skeleton height={415} />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.CustomerPortal.tfmInvoicesLoading,
		invoices: state.CustomerPortal.tfmInvoices,
		pageNumber: state.CustomerPortal.tfmInvoicesPageNumber,
		pageSize: state.CustomerPortal.tfmInvoicesPageSize,
		totalRecords: state.CustomerPortal.tfmInvoicesTotalRecords,
		downloadCarrierImagesLoading: state.CustomerPortal.downloadCarrierImagesLoading,
		loadingList: state.CustomerPortal.imagesLoading,
		readyList: state.CustomerPortal.imagesReady,
		hideTfmInvoiceAmounts: +getUserSetting("hide_tfm_invoice_amounts", 0)(state),
		restrictDocs: +getUserSetting("restrict_carrier_document_access", 0)(state),
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchTFMInvoices,
		setTfmInvoicesPageNumber,
		downloadCarrierImages,
		prepareCarrierImages,
		checkReadyImages,
		fetchImageTypes,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TFMInvoicesContainer);