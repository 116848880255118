const initialState = {
	notifications: [],
	processed: [],
};

export default function(state = initialState, action) {
	switch (action.type) {
		case "SNACKBAR_NOTIFY": {
			return {
				...state,
				notifications: [...state.notifications, {
					message: action.message,
					options: action.options,
				}]
			}
		}
		case "SNACKBAR_CLEAR": {
			return {
				...state,
				notifications: [],
				processed: []
			}
		}
		case "SNACKBAR_MOVE_TO_PROCESSED": {
			const id = action.id;
			let toMove = [];
			let newProcessed = [...state.processed];
			let newNotifications = state.notifications.filter(notif => {
				if (notif.options.id === id) {
					toMove.push(notif);
					return false;
				}
				return true;
			});
			toMove.map(notif => {
				newProcessed.push(notif);
			});
			return {
				...state,
				notifications: [...newNotifications],
				processed: [...newProcessed],
			};
		}
		default:
			return state;
	}
}
