import React from "react";
import qs from "qs";

import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import Check from "@material-ui/icons/Check";
import FastRewind from "@material-ui/icons/FastRewind";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

const centerStyle = {
	textAlign: "center"
};

class ImportLocations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			is_warehouse_only: false,
			show: false,
			mounted: false,
			loading: true,
			importing: false,
			user: null,
			body: null,
			warehouses: [],
			warehouse: "",
			company: "",
			skip_de_dup: false,
			locations: "",
			processed: false,
			added: false,
			failed: false,
			errors: [],
			dups: [],
			warnings: []
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDeDupCheck = this.handleDeDupCheck.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.massImport = this.massImport.bind(this);
		this.getWarehouse = this.getWarehouse.bind(this);
	}

	async componentDidMount() {

		const parsed = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
		});

		const d = parsed.d ? parsed.d.split("/") : [];

		let warehouse = "";

        if (!_.isEmpty(d)) {

            warehouse = d[0];
		}

		this.setState({
			mounted: true,
		});

		try {

			let url = "/index.php?p=api&r=json&c=admin&m=importLocations";

			if(!_.isEmpty(warehouse)) {
				url += '&d=' + warehouse;
			}

			const response = await axios.get( url );

			const data = response.data;

			if (
				typeof data !== "string" &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {

				this.props.handleMessages(response);

				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body
				});

				if( (typeof data.body.warehouse !== 'undefined')) {

					this.setState({
						warehouse: data.body.warehouse.warehouse_name_id,
						company: data.body.warehouse.company_name_id,
						is_warehouse_only: true
					});

					this.getWarehouse(data.body.warehouse.company_name_id);

				} else{

					this.getWarehouse(data.body.companies[0].id);

				}


			} else {

				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!", {variant: "error"}
				);

			}

		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the data!", {variant: "error"}
			);
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleDeDupCheck(event) {

		if(this.state.skip_de_dup === false) {
			this.setState({
				skip_de_dup: true
			});
		} else{
			this.setState({
				skip_de_dup: false
			});
		}

	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
		if (event.target.name === "company") {
			this.getWarehouse(event.target.value);
		}
	}

	async getWarehouse(companyId) {
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=getWarehouse&d=" + companyId);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.props.handleMessages(response);
				this.setState({
					warehouses: data.body
				});
			} else {
				this.props.setNotification("There was an error loading the warehouses!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the warehouses!", {
				variant: "error"
			});
		}
	}

	getCompanies(data) {
		const { classes } = this.props;

		return data.map((prop, key) => {

			if(!this.state.is_warehouse_only || _.isEmpty(this.state.company)) {

				return (
					<MenuItem
						key={key}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected
						}}
						value={prop.id}
					>
						{prop.name}
					</MenuItem>
				);

			} else{

				if(this.state.company == prop.id) {

					return (
						<MenuItem
							key={key}
							classes={{
								root: classes.selectMenuItem,
								selected: classes.selectMenuItemSelected
							}}
							value={prop.id}
						>
							{prop.name}
						</MenuItem>
					);

				}

			}
		});

	}

	getWarehouses(data) {
		const { classes } = this.props;

		return data.map((prop, key) => {

			if(!this.state.is_warehouse_only || _.isEmpty(this.state.warehouse)) {

				return (
					<MenuItem
						key={key}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected
						}}
						value={prop.id}
					>
						{prop.name}
					</MenuItem>
				);

			} else{

				if(this.state.warehouse == prop.id) {

					return (
						<MenuItem
							key={key}
							classes={{
								root: classes.selectMenuItem,
								selected: classes.selectMenuItemSelected
							}}
							value={prop.id}
						>
							{prop.name}
						</MenuItem>
					);

				}

			}

		});
	}

	handleUpload(files) {
		const locations = files[0];
		this.setState({ locations });
	}

	async massImport() {

		this.setState({
			show: true,
			loading: true,
			importing: true
		});

		const data = new FormData();

		data.append("locations", this.state.locations);
		data.append("warehouse", this.state.warehouse);

		if(this.state.skip_de_dup) {
			data.append("skip_de_dup", 1);
		}

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout: 300000
		};

		let url = "/index.php?p=api&r=json&c=admin&m=importLocations";

		try {

			const response = await axios.post(url, data, config);

			if (response.data) {

				this.props.handleMessages(response);

				if (response.data.body) {

					this.setState({
						loading: false,
						importing: false,
						saved: true,
						processed: response.data.body.processed,
						added: response.data.body.added,
						failed: response.data.body.failed,
						errors: response.data.body.log != undefined && response.data.body.log.hasOwnProperty('errors') ? response.data.body.log.errors : [],
						dups: response.data.body.log != undefined && response.data.body.log.hasOwnProperty('dups') ? response.data.body.log.dups : [],
						warnings: response.data.body.log != undefined && response.data.body.log.hasOwnProperty('warnings') ? response.data.body.log.warnings : []
					});

				} else {

					this.setState({ loading: false, importing: false });

				}

			} else {

				this.setState({ loading: false, importing: false });
				this.props.setNotification("Unable to import the file!", {
					variant: "error"
				});

			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false, importing: false });
			this.props.setNotification("An error occurred while importing the file!", {
				variant: "error"
			});
		}
	}

	getHeaders() {
		return [
			'Code',
			'Address',
			'Contact',
			'Reason'
		];
	}

	getColumns(data) {

		return data.map((prop, key) => {

			const columns = [];

			columns.push(prop.location.code);

			columns.push(
				<div>
					<span>{prop.location.name}</span>
					<br />
					<span>{prop.location.address1}</span>
					{!_.isEmpty(prop.location.address2) ? (
							<div>
								<br />
								<span>{prop.location.address2}</span>
							</div>
						) : (
							<span></span>
						)
					}
					<br />
					<span>{prop.location.city}, {prop.location.state}</span>
					<br />
					<span>{prop.location.zip}</span>
				</div>
			);

			columns.push(
				<div>
					<span>{prop.location.contact_name}</span>
					<br />
					<span>P: {prop.location.phone}</span>
					<br />
					<span>F: {prop.location.fax}</span>
					<br />
					<span>E: {prop.location.email}</span>
				</div>
			);

			columns.push(
				<div>
					<span>Type: {prop.reason.type}</span>
					<br />
					<span>DUP Score: {prop.reason.dup_score}/5</span>
					<br />
					<span>Code: {prop.reason.code}</span>
					<br />
					<span>Message: {prop.reason.msg}</span>
				</div>
			);

			return columns;

		});
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{!_.isEmpty(this.state.id) && (
					<GridItem xs={12} sm={12} md={12} className={classes.left} style={{ marginTop: "10px" }}>
						<NavLink to={basePath + "/admin/warehouse/edit/" + this.state.id}>
							<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
								<FastRewind /> Back to Warehouse
							</Button>
						</NavLink>
					</GridItem>
				)}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.companies) && (
											<GridItem xs={12} sm={6} md={4}>
												<InputLabel htmlFor="company" className={classes.label}>
													Select Company
												</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.company || ""}
														inputProps={{
															id: "company",
															name: "company"
														}}
														onChange={this.handleChange}
													>
														{this.getCompanies(this.state.body.companies)}
													</Select>
												</FormControl>
											</GridItem>
										)}
										<GridItem xs={12} sm={6} md={4}>
											<InputLabel htmlFor="warehouse" className={classes.label}>
												Select Warehouse
											</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.warehouse || ""}
													inputProps={{
														id: "warehouse",
														name: "warehouse"
													}}
													onChange={this.handleChange}
												>
													{!_.isEmpty(this.state.warehouses) ? (
														this.getWarehouses(this.state.warehouses)
													) : (
														<MenuItem
															disabled
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
														>
															Select a Company First
														</MenuItem>
													)}
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={4}>
											<a href={apiUrl + "/index.php?p=api&c=location&m=exportTemplate"}>
												<Button color="linkedin" style={{ marginRight: "5px" }}>
													Download Template File
												</Button>
											</a>
										</GridItem>
									</GridContainer>
								</GridItem>
								{this.state.is_warehouse_only && (
									<GridItem xs={12} sm={12} md={12}>
										<GridContainer>
											<GridItem>
												<FormControlLabel
													control={
														<Checkbox
															value="1"
															onChange={e => this.handleDeDupCheck()}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot
															}}
														/>
													}
													classes={{
														label: classes.label
													}}
													label="Skip Address De-Duplication"
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								)}
								<GridItem xs={12} sm={12} md={12}>
									<h5>Location File</h5>
									<GridContainer>
										<GridItem xs={12} sm={12} md={6}>
											<Dropzone accept=".csv" handleUpload={this.handleUpload.bind(this)} />
										</GridItem>
										{!_.isEmpty(this.state.locations) && (
											<GridItem xs={12} sm={12} md={6}>
												<aside className={classes.thumbsContainer}>
													<Preview src="#" index={0} extension="csv" handleClick={() => {}} />
												</aside>
											</GridItem>
										)}
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									{this.state.importing ? (
										<Button size="lg" color="linkedin">
											<CircularProgress size={16} style={{ color: "white" }} />
										</Button>
									) : (
										<Button color="info" style={{ marginRight: "5px" }} onClick={this.massImport}>
											Import
										</Button>
									)}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
					{(this.state.processed !== false) && (
						<div>
	                        <Card>
	                        	<CardBody>
	                        		<GridContainer>
										<GridItem xs={12} sm={4} md={4} style={centerStyle}>
											Processed: {this.state.processed}
										</GridItem>
										<GridItem xs={12} sm={4} md={4} style={centerStyle}>
											Added: {this.state.added}
										</GridItem>
										<GridItem xs={12} sm={4} md={4} style={centerStyle}>
											Failed: {this.state.failed}
										</GridItem>
									</GridContainer>
	                        	</CardBody>
	                        </Card>
	                    	<Card>
	                        	<CardBody>
	                        		<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<legend>Errors Without Duplicate Ceiling</legend>
											{!_.isEmpty(this.state.errors) ? (
													<div>
														<span style={centerStyle}>These records were rejected by the Database but did not score high enough on the Duplication Index to be ommitted. Typically this is because of the location Code. You should review these records for accuracy</span>
														<Table
															tableHead={this.getHeaders()}
															tableData={this.getColumns(this.state.errors)}
														/>
													</div>
												) : (
													<span style={centerStyle}>No Errors With Non-Duplicates</span>
												)
											}
										</GridItem>
									</GridContainer>
	                        	</CardBody>
	                        </Card>
	                        <Card>
	                        	<CardBody>
	                        		<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
										<legend>Duplicate Ceiling Errors</legend>
											{!_.isEmpty(this.state.dups) ? (
													<div>
														<span style={centerStyle}>These records were rejected because they scored at least a 3 out of 5 on the Duplication Index. Typically these were accurately rejected, however there may be some exceptions.</span>
														<Table
															tableHead={this.getHeaders()}
															tableData={this.getColumns(this.state.dups)}
														/>
													</div>
												) : (
													<span style={centerStyle}>No Errors With Duplicates</span>
												)
											}
										</GridItem>
									</GridContainer>
	                        	</CardBody>
	                        </Card>
	                        <Card>
	                        	<CardBody>
	                        		<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
										<legend>Warnings</legend>
											{!_.isEmpty(this.state.warnings) ? (
													<div>
														<span style={centerStyle}>These records were added but some of the fields may not have been saved.</span>
														<Table
															tableHead={this.getHeaders()}
															tableData={this.getColumns(this.state.warnings)}
														/>
													</div>
												) : (
													<span style={centerStyle}>No Warnings</span>
												)
											}
										</GridItem>
									</GridContainer>
	                        	</CardBody>
	                        </Card>
                        </div>
                    )}
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(ImportLocations));