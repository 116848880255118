import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NonLccModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        const selectClasses = this.props.selectClasses || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton}
                    	key="close"
                    	aria-label="Close"
                    	color="transparent"
                    	onClick={() => this.props.onCancel()}
                	>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Non LCC Selection</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5>Please input the reason you did not select the least cost carrier</h5>
                        </GridItem>
                        {!_.isEmpty(this.props.company)
                    		&& !_.isEmpty(this.props.company.non_lcc_tracking)
                        	&& !_.isEmpty(this.props.company.non_lcc_tracking.options) && (
                            <GridItem xs={12}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <Select
                                        MenuProps={{ className: classes.selectMenu }}
                                        classes={{ select: selectClasses }}
                                        value={this.props.option || ""}
                                        inputProps={{
                                            name: "option",
                                            id: "option"
                                        }}
                                        onChange={this.props.handleChange}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value=""
                                        />
                                        {this.props.getOptions()}
                                    </Select>
                                </FormControl>
                            </GridItem>
                        )}
                        <GridItem xs={12}>
                            {!_.isEmpty(this.props.company)
                            	&& !_.isEmpty(this.props.company.non_lcc_tracking)
                            	&& !_.isEmpty(this.props.company.non_lcc_tracking.options)
                            	&& this.props.option !== ""
                            	&& !_.isEmpty(this.props.company.non_lcc_tracking.options[this.props.option - 1])
                            	&& this.props.company.non_lcc_tracking.options[this.props.option - 1].required == 1 ? (
                                <span>
                                    <InputLabel className={classes.label}>Custom Description</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            name: "custom",
                                            value: this.props.custom || "",
                                            onChange: this.props.handleInput("custom")
                                        }}
                                        required
                                    />
                                </span>
                            ) : (
                                <span>
                                    <InputLabel className={classes.label}>Custom Description</InputLabel>
                                    <br />
                                    <CustomInput
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            name: "custom",
                                            value: this.props.custom || "",
                                            onChange: this.props.handleInput("custom")
                                        }}
                                        white
                                    />
                                </span>
                            )}
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} />
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="warning" onClick={() => this.props.onCancel()} className={classes.marginRight}>
                        Cancel
                    </Button>
                    <Button color="linkedin" onClick={() => this.props.onSubmit()} className={classes.marginRight}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(NonLccModal);