import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from "lodash";
import moment from "~/lib/moment.js";
import { apiUrl } from "~/variables/server.jsx";
import { userIsAdmin, getRateDisplayByMode, isNotAdmin, getUserCompanySetting, addNpFee, npFeeAmount, npFeeType } from "../../../redux/selectors/Admin.jsx";

import Table from "~/components/Table/Table.jsx";
import Grid from "@material-ui/core/Grid";
import Badge from "~/components/Badge/Badge.jsx";
import Radio from "@material-ui/core/Radio";
import Button from "~/components/CustomButtons/Button.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import NewReleases from "@material-ui/icons/NewReleases";
import RateDetailsModal from "./RateDetailsModal.jsx";
import { openModal } from "../actions/RateDetailsModal.jsx";
import { searchObject, formatCurrency } from "../../../helpers.jsx";


/******************
 * Available props
 ******************
 * showDetails - bool - toggles button to open details modal
 * showLiability - bool - toggles liability controls - column/button
 * actionsHeader - text - if provided, the header text for the actions column
 * carriers - array - array of carrier rates
 * tfmrId - int - associated TFMR request ID, provide zero if not applicable
 * classes - classes - styles passed in from parent component
 * showHideMessage - bool - if true, display the hide message if there is one
 * hideTransitBadges - bool - whether or not to render badges with transit times
 * canSelect - bool - if the rates can be selected
 * selectIsRadio - bool - if true, render a radio, if false, render a button
 * selectedCarrierScac - string - scac of selected carrier rate
 * onSelect - function - select callback
 * canGetTerminalInfo - bool - toggles the terminal button
 * onClickTerminal - function - callback for terminal butto click
 * canShowLiability - bool - toggles the liability control
 * onOpenLiability - function - callback for clicking liability button
 * orderRatesBy - string - user setting to determine to order by (truecost, benchmark, etc.)
 * orderRatesFrom - string - user setting to determine how to order (if orderRatesBy is provided)
 * displayNCarriers - int - user setting to determine how many carriers to display
 * disableExclusions - bool - warehouse setting to determine if rate with exclusions applied can be selected
 */
class RateResults extends Component {

    showEstDeliveryColumn = () => {
        return this.props.showEstDelivery;
    }

    showRateDetails = () => {
        let display = this.props.rateDisplay;
        return this.props.userIsAdmin || ((+display.rated || +display.benchmark) && !this.props.isTargetSavings);
    }

    showRated = () => {
        return this.showRateDetails() && +this.props.rateDisplay.rated;
    }

    showBenchmark = () => {
        return this.showRateDetails() && +this.props.rateDisplay.benchmark;
    }

    showTruecost = () => {
        return !!+this.props.rateDisplay.true_cost;
    }

    showTruecostMinusMarkup = () => {
        return !!+this.props.rateDisplay.true_cost_minus_markup;
    }

    hasLiability = () => {
        return this.props.hasLiability;
    }

    showLiabilityColumn = () => {
        return this.props.showLiability && this.hasLiability() && !this.props.volume;
    }

    showLiabilityButton = () => {
        return this.props.showLiability && !this.props.volume && (this.props.userIsAdmin || this.hasLiability());
    }

    showDetailsButton = () => {
        if (!this.props.showDetails) return false;
        if (this.props.userIsAdmin) return true;
        return this.showRated() || this.showBenchmark();
    }

    showCalendarTransitDays = () => {
        return !!+this.props.rateDisplay.transit_calendar_days;
    }

    disableExclusion = (prop) => {
        if(this.props.disableExclusions && !_.isEmpty(prop.hide_type) && (prop.hide_type == 'linear' || prop.hide_type == 'cubic')) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Determine if we need a column at the end for action controls
     */
    hasActions = () => {
        return this.props.showDetails || this.props.canSelect || this.props.canGetTerminalInfo || this.showLiabilityButton();
    }

    /**
     * Check for an alias in rate display and return a default if not found
     */
    getHeaderText = (aliasSearchString, def) => {
        let alias = searchObject(this.props.rateDisplay.alias, aliasSearchString);
        return alias ? alias : def;
    }

    /**
     * Get table headers from props
     */
    getHeaders = () => {
        const headers = ["Carrier", "Transit"];
        if (this.showCalendarTransitDays()) {
            headers.push(this.getHeaderText("transit_calendar_days", "Transit Calendar Days"));
        }
        if (this.showEstDeliveryColumn()) {
            headers.push(this.getHeaderText("edd", "Est. Delivery"));
        }
        if (this.showRated()) {
            headers.push(this.getHeaderText("rated", "Rated"));
        }
        if (this.showBenchmark()) {
            headers.push(this.getHeaderText("benchmark", "Benchmark"));
        }
        if (this.showTruecost()) {
            headers.push(this.getHeaderText("true_cost", "True Cost"));
        }
        if (this.showTruecostMinusMarkup()) {
            headers.push(this.getHeaderText("true_cost_minus_markup", "True Cost w/o Markup"));
        }
        if (this.showLiabilityColumn()) {
            headers.push("Liability Coverage");
        }
        if (this.hasActions()) {
            headers.push(this.props.actionsHeader ? this.props.actionsHeader : "");
        }
        return headers;
    }

    getTableData = () => {

        if (!Array.isArray(this.props.carriers)) return [];

        //put carriers with hide message at the bottom of the list
        let carriersWithHideMessage = [];
        let carriers = this.props.carriers.filter(carrier => {
            if (typeof carrier === "object" && carrier.hide) {
                carriersWithHideMessage.push(carrier);
                return false;
            }
            return true;
        })

        // Apply user settings for ordering rates, if possible
        if(this.props.orderRatesBy != null && this.props.orderRatesBy != 'none' && this.props.orderRatesBy.length > 0) {

            const orderRatesBy = this.props.orderRatesBy;
            const orderRatesFrom = (this.props.orderRatesFrom != null && this.props.orderRatesFrom.length > 0) ? this.props.orderRatesFrom : null;

            if(this.props.rateDisplay[orderRatesBy] == '1') {

                if(orderRatesFrom == 'highest to lowest') {
                    carriers = carriers.sort((a,b) => b['rate'][orderRatesBy] - a['rate'][orderRatesBy]);
                } else if(orderRatesFrom == 'lowest to highest') {
                    carriers = carriers.sort((a,b) => a['rate'][orderRatesBy] - b['rate'][orderRatesBy]);
                }

            }

        }

        carriersWithHideMessage.map(carrier => {
            carriers.push(carrier);
        });

        // Apply user setting for limiting carriers, if possible
        if(this.props.displayNCarriers != null && this.props.displayNCarriers >= 0 && carriers.length > this.props.displayNCarriers) {
            carriers = carriers.slice(0, this.props.displayNCarriers);
        }

        return carriers
            .filter(carrier => typeof carrier === "object" && carrier.scac !== "BMRK")
            .map((prop) => {
                let data = [];

                const hasQuoteIdOrBillToAddress = (prop.hasOwnProperty('carrier_quote_id') && !_.isEmpty(prop['carrier_quote_id'])) ||
                (prop.hasOwnProperty('bill_to_address') && !_.isEmpty(prop['bill_to_address']));

                if (_.isEmpty(prop.logo_file)) {
                    data.push(prop.name);
                } else {
                    data.push(<img src={apiUrl + "/getLogo.php?logo=" + prop.logo_file} alt={prop.name} style={{ height: "60px", width: "120px", margin: "0" }} />);
                }

                if (this.props.showHideMessage && !_.isEmpty(prop.hide)) {
                    data.push(<span dangerouslySetInnerHTML={{ __html: prop.hide }} />);
                    for (let i = 0; i < this.getHeaders().length - 3; i++) {
                        data.push("");
                    }
                    if (!this.hasActions()) {
                        data.push("");
                    }
                } else {
                    if(_.isEmpty(prop.move_type) || prop.move_type === 'U' || this.props.hideTransitBadges) {
                        data.push(prop.trans_time + " - Days");
                    } else {
                        data.push(
                            <div>
                                <span>{prop.trans_time + " - Days"}</span>
                                <br />
                                {(prop.move_type === 'D')
                                    ? <Badge color="success">DIRECT</Badge>
                                    : <Badge color="warning">INDIRECT</Badge>
                                }
                            </div>
                        );
                    }

                    if (this.showCalendarTransitDays()) {
                        if(typeof prop.transit_calendar_days !== "undefined") {
                            data.push(prop.transit_calendar_days + " - Days");
                        } else {
                            data.push("");
                        }
                    }

                    if (this.showEstDeliveryColumn()) {
                        const pickupDate = moment(this.props.pickupDate, 'MM/DD/YYYY');
                        const calendarEdd =  moment(prop.transit_edd, 'YYYY/MM/DD');
                        const transTime = parseInt(prop.trans_time);

                        if(calendarEdd.isValid()) {
                            data.push(calendarEdd.format('MM/DD/YYYY'));
                        }else if (pickupDate.isValid() && transTime > 0) {                        
                            const edd = pickupDate.clone();
                            edd.addBusinessDays(transTime);
                            data.push(edd.format('MM/DD/YYYY'));
                        } else {
                            data.push('');
                        }
                    }
                    if (this.showRated())               data.push("$" + prop.rate.rated);
                    if (this.showBenchmark())           data.push("$" + prop.rate.benchmark);
                    if (this.showTruecost()) {
                        let trueCostValue = prop.rate.true_cost;
                        if (!prop.in_program && this.props.addNpFee && this.props.npFeeAmount > 0) {
                            let feeAmt = this.props.npFeeAmount;
                            if(this.props.npFeeType == 'percent') {
                                // convert fee to %.
                                let feePct = this.props.npFeeAmount / 100; 
                                // use % markup to update the fee amount
                                feeAmt = prop.rate.true_cost * feePct;
                            }
                            trueCostValue = parseFloat(+prop.rate.true_cost + feeAmt).toFixed(2);
                        }
                        data.push(formatCurrency(trueCostValue));
                    }
                    if (this.showTruecostMinusMarkup()) {
                        let trueCostMinusMarkupValue = prop.rate.true_cost_minus_markup;
                        if (!prop.in_program && this.props.addNpFee && this.props.npFeeAmount > 0) {
                            // add np_fee_type in calculation
                            let feeAmt = this.props.npFeeAmount;
                            if(this.props.npFeeType == 'percent') {
                                // convert fee to %.
                                let feePct = this.props.npFeeAmount / 100; 
                                // use % markup to update the fee amount
                                feeAmt = prop.rate.true_cost * feePct;
                            }
                            trueCostMinusMarkupValue = parseFloat(+prop.rate.true_cost_minus_markup + feeAmt).toFixed(2);
                        }
                        data.push(formatCurrency(trueCostMinusMarkupValue));
                    }
                    if (this.showLiabilityColumn()) {
                        let liabilityCoverage = (!_.isEmpty(prop.liability) && !isNaN(prop.liability.totalCoverage) && prop.liability.totalCoverage > 0) ? prop.liability.totalCoverage.toFixed(2) : "0.00";
                        data.push("$" + liabilityCoverage);
                    }
                }

                if (this.hasActions()) {
                    data.push(
                        <div>
                            {this.props.canSelect && this.props.selectIsRadio && (
                                <div>
                                    {this.disableExclusion(prop) ? (
                                        <WithTooltip
                                            title="Not Available"
                                            content="Your organization does not allow a carrier with this type of exclusion to be chosen"
                                        >
                                            {/* Disabled radio button requires span for tooltip to display */}
                                            <span><Radio disabled /></span>
                                        </WithTooltip>
                                    ) : (
                                        <Radio
                                            color="primary"
                                            checked={this.props.selectedCarrierScac === prop.scac}
                                            onChange={this.props.onSelect}
                                            value={prop.scac}
                                            name="scac"
                                        />
                                    )}
                                </div>
                            )}

                            {this.props.canSelect && !this.props.selectIsRadio && (
                                <div>
                                    {this.disableExclusion(prop) ? (
                                        <WithTooltip
                                            title="Not Available"
                                            content="Your organization does not allow a carrier with this type of exclusion to be chosen"
                                        >
                                            {/* Button requires span for tooltip to display */}
                                            <span><Button color="danger" size="sm" disabled > Select </Button></span>
                                        </WithTooltip>
                                    ) : (
                                        <Button
                                            value={prop.scac}
                                            color={this.props.selectedCarrierScac == prop.scac ? "success" : "info"}
                                            size="sm"
                                            onClick={() => {this.props.onSelect(prop);}}
                                        >
                                            {this.props.selectedCarrierScac === prop.scac ? "Selected" : "Select"}
                                        </Button>
                                    )}
                                </div>
                            )}

                            {((this.showDetailsButton() && _.isEmpty(prop.hide)) || (this.props.showDetails && hasQuoteIdOrBillToAddress)) && (
                                <div>
                                    <Button color="warning" size="sm" onClick={() => this.openDetailsModal(prop)}>
                                        {hasQuoteIdOrBillToAddress && (
                                            <NewReleases/>
                                        )}
                                        Details
                                    </Button>
                                </div>
                            )}
                            {this.props.canGetTerminalInfo && (
                                <div>
                                    <Button color="info" size="sm" onClick={() => {this.props.onClickTerminal(prop);}}>
                                        Terminals
                                    </Button>
                                </div>
                            )}
                            {this.showLiabilityButton() && !_.isEmpty(prop.liability) && (
                                <div>
                                    <Button
                                        color={searchObject(prop, "liability.details.excess.requested") != searchObject(prop, "liability.totalExcessCoverage") ? "error" : "info"}
                                        size="sm"
                                        onClick={() => {this.props.onOpenLiability(prop)}}
                                    >
                                        Liability
                                    </Button>
                                </div>
                            )}

                        </div>
                    );
                }

                return {
                    color: prop.in_program ? "info" : "warning",
                    data
                };
            }
        );
    }

    openDetailsModal = carrier => {
        this.props.openModal(carrier);
    }

    render() {
        const classes = this.props.classes || {};
        return (
            <Fragment>
                <RateDetailsModal volume={this.props.volume} classes={classes}/>
                {this.props.distance > 0 && (
                    <Grid item xs={12} className={classes.center}>
                        <h5>Shipment Distance: {this.props.distance} miles</h5>
                    </Grid>
                )}
                {(this.props.tfmrId > 0 && this.props.userIsAdmin && (
                    <Grid item xs={12} className={classes.center}>
                        <h5>
                            {this.props.tfmrIndexUrl ? (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${this.props.tfmrIndexUrl}?c=rater&m=rateShipment&record=${this.props.tfmrId.toString()}`}
                                >
                                    {`TFMRater Request ID: ${this.props.tfmrId.toString()} `}
                                </a>
                            ) : (
                                <span>{`TFMRater Request ID: ${this.props.tfmrId.toString()}`}</span>
                            )}
                        </h5>
                    </Grid>
                ))}
                <Table
                    striped
                    tableHead={this.getHeaders()}
                    tableData={this.getTableData()}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const mode = ownProps.volume ? "volume" : "ltl";

    return {
        userIsAdmin: userIsAdmin(state),
        rateDisplay: getRateDisplayByMode(mode)(state),
        isTargetSavings: !!+getUserCompanySetting("target_savings")(state) && isNotAdmin(state),
        hasLiability: !!+getUserCompanySetting("enable_liability")(state),
        addNpFee: addNpFee(state),
        npFeeAmount: npFeeAmount(state),
        npFeeType: npFeeType(state),
        tfmrIndexUrl: state.Environment.tfmr_index_url,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        openModal,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RateResults);
