import React, { Component } from "react";
import { withSnackbar } from "notistack";
import axios from "~/variables/axios.jsx";
import { Select, Checkbox } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';


class TransitSelect extends Component {
    state = {
		loading: true,
		options: []
	}

    async componentDidMount() {
        if(this.props.type == "pickup") {
            var url = '/index.php?p=api&r=json&c=latePickupOptions&m=collection&limit=100';
        } else if(this.props.type == "delivery") {
		    var url = '/index.php?p=api&r=json&c=lateDeliveryOptions&m=collection&limit=100';
        } else if(this.props.type == "transit") {
            var url = '/index.php?p=api&r=json&c=transitStatusOptions&m=collection&limit=100';
        }
		try {
			const response = await axios.get(url);
			if(response && response.data && response.data.body && response.data.body.response) {
				this.setState({ options: response.data.body.response });
			} else {
				this.props.setNotification( "Unable to load options", {variant: "error"} );
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("Unexpected error occurred while loading options", { variant: "error" });
		} finally {
			this.setState({ loading: false });
		}
	}

    render() {
        if(this.state.loading) {
    		return null;
    	}

        const { enqueueSnackbar, closeSnackbar, ...selectProps} = {...this.props};
        const classes = this.props.classes;
        var options = [...this.state.options];

        if(this.props.type == 'transit') {
            const noStatus = {id: "", status: "", description: "No Status"};
            options = [noStatus].concat(options);

            if(selectProps.multiple && typeof selectProps.renderValue === 'undefined') {
                selectProps.renderValue = (selected) => {
                    let displayValues = selected.map(status => {
                        return options.find(obj => obj.status === status).description;
                    });
                    return displayValues.join(', ');
                };
            }

            return (
                <Select {...selectProps}>
                    {options.map((opt, index) => {
                        return selectProps.multiple
                        ? (
                            <MenuItem
                                key={index}
                                value={opt.status}
                                classes={{
                                    root: opt.parent ? classes.selectMenuItem : classes.selectMenuItemParent,
                                    selected: classes.selectMenuItemSelected,
                                    parent: opt.parent ? "" : classes.selectMenuItemParent
                                }}
                            >
                                <Checkbox checked={selectProps.value.indexOf(opt.status) > -1} />
                                {opt.description}
                            </MenuItem>
                        ) : (
                            <MenuItem
                                key={index}
                                value={JSON.stringify({status: opt.status, parent: opt.parent})}
                                classes={{
                                    root: opt.parent ? classes.selectMenuItem : classes.selectMenuItemParent,
                                    selected: classes.selectMenuItemSelected,
                                }}
                            >
                                {opt.description}
                            </MenuItem>
                        );
                    })}
                </Select>
            );
        } else {
            const noReason = {id: "", reason: "", description: "No Reason"};
            options = [noReason].concat(options);

            return (
                <Select {...selectProps}>
                    {options.map((opt, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={opt.reason}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                }}
                            >
                                {opt.description}
                            </MenuItem>
                        );
                    })}
                </Select>
            );
        }
    }

}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(TransitSelect)));
