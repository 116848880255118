import React from "react";
import PropTypes from "prop-types";

// @progress/kendo-react-charts
import {
    Chart,
    ChartArea,
    ChartTitle,
    ChartTooltip,
    ChartLegend,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
} from "@progress/kendo-react-charts";

import Fade from "@material-ui/core/Fade";

import { grayColor } from "~/assets/jss/empire-tms.jsx";

class BolStatusOverview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const categories = [];
        const data = {
            executed: [],
            scheduled: [],
            received: [],
            billed: [],
            auditing: [],
            disputed: [],
            canceled: []
        };
        for (const prop of this.props.data) {
            categories.push(prop.date);
            data.executed.push(prop.executed);
            data.scheduled.push(prop.scheduled);
            data.received.push(prop.received);
            data.billed.push(prop.billed);
            data.auditing.push(prop.auditing);
            data.disputed.push(prop.disputed);
            data.canceled.push(prop.canceled);
        }
        return (
            <Fade in>
                <Chart>
                    <ChartArea background="#fff" margin={10} height={400} />
                    <ChartAxisDefaults
                        color="black"
                        visible
                        majorGridLines={{
                            color: grayColor[4],
                            visible: true,
                            width: 1
                        }}
                    />
                    <ChartTitle text="BoL Status Overview" color="black" visible />
                    <ChartLegend position="right" orientation="verticle" visible labels={{ color: "black" }} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={categories} max={31} labels={{ rotation: "auto" }} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem color="orange" data={data.executed} type="line" name="Executed" style="normal">
                            <ChartSeriesItemTooltip background="orange" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                        <ChartSeriesItem color="purple" data={data.scheduled} type="line" name="Scheduled" style="normal">
                            <ChartSeriesItemTooltip background="purple" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                        <ChartSeriesItem color="yellow" data={data.received} type="line" name="Received" style="normal">
                            <ChartSeriesItemTooltip background="yellow" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                        <ChartSeriesItem color="green" data={data.billed} type="line" name="Billed" style="normal">
                            <ChartSeriesItemTooltip background="green" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                        <ChartSeriesItem color="aqua" data={data.auditing} type="line" name="Auditing" style="normal">
                            <ChartSeriesItemTooltip background="aqua" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                        <ChartSeriesItem color="brown" data={data.disputed} type="line" name="Disputed" style="normal">
                            <ChartSeriesItemTooltip background="brown" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                        <ChartSeriesItem color="red" data={data.canceled} type="line" name="Canceled" style="normal">
                            <ChartSeriesItemTooltip background="red" color="white" format="Count: {0}" visible />
                        </ChartSeriesItem>
                    </ChartSeries>
                </Chart>
            </Fade>
        );
    }
}

BolStatusOverview.propTypes = {
    data: PropTypes.array.isRequired
};

export default BolStatusOverview;