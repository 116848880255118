import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import { Box } from "@material-ui/core";
// core components
import GridItem from "~/components/Grid/GridItem.jsx";

import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

import { isNotAdmin } from "~/redux/selectors/Admin.jsx";

class FilterStep extends React.Component {
  constructor(props) {
    super(props);

    let filters = [];
    for (var i = 0; i < 6; i++) {
      filters.push({
        _id: i,
        filterColumn: "",
        operator: "",
        inputValue: "",
        logicalOperator: "",
      });
    }

    this.state = {
      mounted: false,
      loading: true,
      isNotAdmin: isNotAdmin,
      filtersList: [],
      selected: [],
      companies: [],
      filters: filters,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this.getCompanies();

    this.getColumnFilter().then((_) => {

      const selectedFilters =
        this.props.filters != null
          ? this.props.filters
          : this.state.selectedFilters;

      const filters = this.state.filters;

      if (selectedFilters != null) {
        const objIndex = selectedFilters.findIndex(
          (obj) => obj.column == "company_id"
        );

        for (var j in this.state.filters) {
          var i = Number(j);
          if (!this.state.isNotAdmin && objIndex == -1) {
            if (i > 0 && selectedFilters.length >= i) {
              filters[i]._id = Number(selectedFilters[i-1].id);
              filters[i].filterColumn = selectedFilters[i-1].column;
              filters[i].inputValue = selectedFilters[i-1].value;
              filters[i].logicalOperator = selectedFilters[i-1].conjunction;
              filters[i].operator = selectedFilters[i-1].type;
            }
          } else {
            if (selectedFilters.length > i) {
              filters[j]._id = Number(selectedFilters[i].id);
              filters[j].filterColumn = selectedFilters[i].column;
              filters[j].inputValue = selectedFilters[i].value;
              filters[j].logicalOperator = selectedFilters[i].conjunction;
              filters[j].operator = selectedFilters[i].type;

              if (selectedFilters[i].column === "company_id") {
                this.setState({ selected: selectedFilters[i].value.split(",") });
              }
            }
          }
        }

        this.setState({ filters: filters }, this.handleFilterStepStates);
      }
    });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  getFilters = (data) => {
    const { classes } = this.props;

    return data.map((prop, key) => {
      if(prop.value !== 'company_id') { // prevent bug loading report if this filter is used in the filter rows on page
        return (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={prop.value}
            key={prop.value}
          >
            {prop.label}
          </MenuItem>
        );
      } else {
        return null;
      }
    });
  }

  addFilter = (e) => {
    e.preventDefault();

    let filters = this.state.filters.concat([
      {
        _id: this.state.filters.length,
        filterColumn: "",
        operator: "",
        inputValue: "",
        logicalOperator: "",
      },
    ]);

    this.setState({ filters });
  };

  clearFilter = (i) => (e) => {
    e.preventDefault();

    let filters = this.state.filters;

    filters[i] = {
      _id: i,
      filterColumn: "",
      operator: "",
      inputValue: "",
      logicalOperator: "",
    };

    this.setState({ filters });
  }

  removeFilter = (i) => (e) => {
    e.preventDefault();

    let filters = [
      ...this.state.filters.slice(0, i),
      ...this.state.filters.slice(i + 1),
    ];

    this.setState({ filters }, this.handleFilterStepStates);
  };

  setCompanyFilter = (event, index) => {
    const value = event.target.value;
    const { selected } = this.state;

    this.setState({ selected: value },
      () => {
        this.setState((prevState) => ({
          filters: prevState.filters.map((obj, ind) =>
            ind === index
              ? Object.assign(obj, {
                operator: "in",
                filterColumn: "company_id",
                inputValue: this.state.selected.join(","),
                logicalOperator: "and",
              })
              : obj
          ),
        }));

        this.handleFilterStepStates();
      });
  };

  setFilterColumn = (event, index) => {
    this.updateFilter(index, 'filterColumn', event.target.value);
    this.handleFilterStepStates();
  };

  setOperator = (event, index) => {
    this.updateFilter(index, 'operator', event.target.value);
    this.handleFilterStepStates();
  };

  setInputValue = (index, event) => {
    this.updateFilter(index, 'inputValue', event.target.value);
    this.handleFilterStepStates();
  }

  setLogicalOperator = (event, index) => {
    this.updateFilter(index, 'logicalOperator', event.target.value);
    this.handleFilterStepStates();
  };

  updateFilter = (index, property, value) => {
    this.setState((prevState) => ({
      filters: prevState.filters.map((obj) =>
        obj._id === index
          ? Object.assign(obj, { [property]: value })
          : obj
      ),
    }));
  }

  handleFilterStepStates = () => {
    this.props.handleStepState({ filters: this.state.filters }, "filter");
  };

  getCompanies = async () => {
    let url = "/index.php?p=api&r=json&c=reportDefinition&m=getCompanies";

    try {
      const response = await axios.get(url);

      if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
        this.props.handleMessages(response);
      }

      if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
        if (this.state.mounted) {
          this.setState({
            loading: false,
            companies: response.data.body,
            isNotAdmin: response.data.user.level === "user"
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }

  getColumnFilter = async () => {
    let url = "/index.php?p=api&r=json&c=searchtype&m=columnfilters&d=" + this.props.reportType;
    try {
      const response = await axios.get(url);

      if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
        this.props.handleMessages(response);
      }

      if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
        if (this.state.mounted) {
          var col = [];

          response.data.body.map((item) =>
            col.push({
              value: item.name,
              label: item.aliasname,
            })
          );

          const sorted_col = _.sortBy(col, "value");

          this.setState({
            loading: false,
            filtersList: sorted_col,
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }

  isValidated() {
    let count = 0;

    this.state.filters.map((filerValue, index) => {
      if (index > 0) {
        let ind = index + 1;
        if (
          filerValue.logicalOperator != "" &&
          (this.state.filters[ind].filterColumn &&
            this.state.filters[ind].operator &&
            this.state.filters[ind].inputValue) === ""
        ) {
          count++;
        }
      }
    });

    if (count === 1) {
      this.props.setNotification("Please fill the next filter field !", {
        variant: "warning",
      });
      return false;
    }

    return true;

    // const filterValidation = (filter) => filter.filterColumn != "";
    // const filterInputValidation = (filter) => filter.inputValue != "";
    // const filterOperatorValidation = (filter) => filter.operator != "";

    // if (
    //   this.state.filters.some(filterValidation) &&
    //   this.state.filters.some(filterInputValidation) &&
    //   this.state.filters.some(filterOperatorValidation)
    // ) {
    //   return true;
    // }
    // this.props.setNotification("Please fill filter values!", {
    //   variant: "warning",
    // });
    // return false;
  }

  

  render() {
    const { classes } = this.props;
    const { selected, companies } = this.state;

    return (
      <div>
        <Grid item xs={12}>
          {this.state.filters.map((filter, index) => {
            if (!this.state.isNotAdmin && index == 0) {
              return (
                <Grid container key={index}>
                  <GridItem xs>
                    <FormControl
                      className={classes.selectFormControl}
                      style={{ width: "210px" }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Select Company
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={selected}
                        onChange={(e) => this.setCompanyFilter(e, index)}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {companies.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <ListItemIcon>
                              <Checkbox
                                checked={selected.indexOf(option.id) > -1}
                                disabled={this.state.companies.length == 1}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </Grid>
              );
            } else {
              return (
                <Box key={index} mt={0} mb={-4} ml={0} mr={0}>
                  <Grid container key={index}>
                    <GridItem xs>
                      <FormControl
                        className={classes.selectFormControl}
                        style={{ width: "210px" }}
                      >
                        <InputLabel className={classes.label}>Field</InputLabel>
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={this.state.filters[index].filterColumn}
                          onChange={(e) => this.setFilterColumn(e, filter._id)}
                          inputProps={{
                            id: "filterColumn",
                            name: "filterColumn",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value=""
                          >
                            Field
                          </MenuItem>
                          {this.state.mounted
                            ? this.getFilters(this.state.filtersList)
                            : null}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs>
                      <FormControl
                        className={classes.selectFormControl}
                        style={{ width: "210px" }}
                      >
                        <InputLabel className={classes.label}>
                          Operator
                        </InputLabel>

                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={this.state.filters[index].operator}
                          onChange={(e) => this.setOperator(e, filter._id)}
                          inputProps={{
                            id: "operator",
                            name: "operator",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value=""
                          >
                            Operator
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="begins with"
                          >
                            Begins With
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="not begins with"
                          >
                            Not Begins With
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ends with"
                          >
                            Ends With
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="contains"
                          >
                            Contains
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="not contains"
                          >
                            Not Contains
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="="
                          >
                            Equals
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="exists"
                          >
                            Exists
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=">"
                          >
                            Greater than
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=">="
                          >
                            Greater than or Equal
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value="in"
                          >
                            IN
                          </MenuItem>

                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="<"
                          >
                            Less than
                          </MenuItem>

                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="<="
                          >
                            Less than or Equal
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="!="
                          >
                            Not Equal
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs>
                      <InputLabel className={classes.label}>Value</InputLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "text",
                          id: "inputValue",
                          name: "inputValue",
                          maxLength: "20",
                          minLength: "1",
                          value: filter.inputValue,
                          onChange: (e) => this.setInputValue(filter._id, e),
                        }}
                        white
                      />
                    </GridItem>
                    <GridItem xs>
                      {index < this.state.filters.length - 1 ? (
                        <FormControl
                          className={classes.selectFormControl}
                          style={{ width: "150px" }}
                        >
                          <InputLabel className={classes.label}>
                            Logical operator
                          </InputLabel>

                          <Select
                            MenuProps={{ className: classes.selectMenu }}
                            classes={{ select: classes.select }}
                            value={this.state.filters[index].logicalOperator}
                            onChange={(e) =>
                              this.setLogicalOperator(e, filter._id)
                            }
                            inputProps={{
                              id: "logicalOperator",
                              name: "logicalOperator",
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              value=""
                            >
                              Logical operator
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="&&"
                            >
                              And
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="||"
                            >
                              Or
                            </MenuItem>
                          </Select>
                        </FormControl>
                      ) : null}
                    </GridItem>
                    <GridItem xs>
                      <Button
                        style={{
                          background: "none",
                          border: "none",
                          padding: "30px",
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#474747",
                          borderRadius: "0px 0px 0px 0px",
                          boxShadow: "none",
                        }}
                        onClick={this.clearFilter(index)}
                      >
                        Remove
                      </Button>
                    </GridItem>
                  </Grid>
                </Box>
              );
            }
          })}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(FilterStep)));