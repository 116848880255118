import {
  uiColors,
  grayColor
} from "assets/jss/empire-tms.jsx";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px"
  },
  iconWrapper: {
    float: "left",
    marginTop: "5px",
    marginRight: "10px"
  },
  primary: {
    color: uiColors.primary.default
  },
  warning: {
    color: uiColors.warning.default
  },
  danger: {
    color: uiColors.danger.default
  },
  success: {
    color: uiColors.success.default
  },
  info: {
    color: uiColors.info.default
  },
  rose: {
    color: uiColors.rose.default
  },
  gray: {
    color: grayColor[0]
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  descriptionWrapper: {
    color: grayColor[0],
    overflow: "hidden"
  },
  title: {
    color: grayColor[2],
    margin: "10px 0 15px",
    textDecoration: "none",
    fontSize: "18px"
  },
  description: {
    color: grayColor[0],
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px"
  }
};

export default infoStyle;
