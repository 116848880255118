const initialState = {
	printers: [],
	JSPM: null,
	alerts: [],
	zplDocTypes: ['COSTCO'], // these are consts that are across pages. 
	dgDocTypes: ['OP_950', 'OP_900', 'DANGEROUS_GOODS_SHIPPERS_DECLARATION'], // these are consts that are across pages. 
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_AVAILABLE_PRINTERS": {
			return {...newState, printers: action.val};
		}
		case "SET_JSPM": {
			return {...newState, JSPM: action.val};
		}
		default:
			return state;
	}
}