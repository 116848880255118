import {
	notificationsWidth,
	drawerMiniWidth,
	transition,
	boxShadow,
	defaultFont,
	uiColors,
	makeBoxShadow,
	whiteColor,
	blackColor,
	grayColor,
	hexToRgb,
	tfmBlueColor,
	container,
} from "assets/jss/empire-tms.jsx";

/* --------------------- Shadow --------------------- */

const notificationBoxShadow = {
	border: "0",
	borderRadius: "3px",
	boxShadow:
		"0 10px 20px -12px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.42), 0 3px 20px 0px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.12), 0 8px 10px -5px rgba(" +
	    hexToRgb(blackColor) +
	    ", 0.2)",
	padding: "10px 10px 10px 10px",
	transition: "all 150ms ease 0s",
	margin: "7px"
};

const notificationSidebarStyle = theme => ({
	drawerPaperRTL: {
		[theme.breakpoints.up("md")]: {
			left: "auto !important",
			right: "0 !important"
		},
		[theme.breakpoints.down("sm")]: {
			left: "0  !important",
			right: "auto !important"
		}
	},
	drawerPaper: {
		border: "none",
		position: "fixed",
		top: "0",
		bottom: "0",
      	left: `calc(100% - ${notificationsWidth}px)`,
		right: "0",
		zIndex: "1032",
		transitionProperty: "top, bottom, width",
		transitionDuration: ".2s, .2s, .35s",
		transitionTimingFunction: "linear, linear, ease",
		...boxShadow,
		width: "notificationsWidth",
		[theme.breakpoints.up("md")]: {
			width: notificationsWidth,
			position: "fixed",
			height: "100%"
		},
		[theme.breakpoints.down("sm")]: {
			width: notificationsWidth,
			...boxShadow,
			position: "fixed",
			display: "block",
			top: "0",
			height: "100vh",
			right: "0",
			left: "auto",
			zIndex: "1032",
			visibility: "visible",
			overflowY: "visible",
			borderTop: "none",
			textAlign: "left",
			paddingRight: "0px",
			paddingLeft: "0",
			transform: `translate3d(${notificationsWidth}px, 0, 0)`,
			...transition
		},
		"&:before,&:after": {
			position: "absolute",
			zIndex: "3",
			width: "100%",
			height: "100%",
			content: '""',
			display: "block",
			top: "0",
			backgroundColor:   tfmBlueColor,
		}
	},
	blackBackground: {
		color: whiteColor,
	},
	blueBackground: {
		color: whiteColor,
		"&:after": {
			background: uiColors.info.default,
			opacity: ".93"
		}
	},
	whiteBackground: {
		color: grayColor[2],
		"&:after": {
			background: whiteColor,
			opacity: ".93"
		}
	},
	whiteAfter: {
		"&:after": {
			backgroundColor: "hsla(0,0%,71%,.3) !important"
		}
	},
	drawerPaperMini: {
		width: drawerMiniWidth + "px!important"
	},
	logo: {
		padding: "0px 0px",
		margin: "0",
		display: "block",
		position: "relative",
		zIndex: "4",
		"&:after": {
			content: '""',
			position: "absolute",
			bottom: "0",
			height: "1px",
			right: "15px",
			width: "calc(100% - 30px)",
		/*	backgroundColor: "hsla(0,0%,100%,.3)"*/
		}
	},
	logoMini: {
		transition: "all 300ms linear",
		opacity: 1,
		float: "left",
		textAlign: "center",
		width: "30px",
		display: "inline-block",
		maxHeight: "30px",
		marginLeft: "22px",
		marginRight: "18px",
		marginTop: "7px"
	},
	logoMiniRTL: {
		float: "right",
		marginRight: "30px",
		marginLeft: "26px"
	},
	logoNormal: {
		...defaultFont,
		transition: "all 300ms linear",
		display: "block",
		opacity: "1",
		transform: "translate3d(0px, 0, 0)",
		textTransform: "uppercase",
		padding: "0px 0px",
		fontSize: "18px",
		whiteSpace: "nowrap",
		fontWeight: "400",
		lineHeight: "30px",
		overflow: "hidden",
		"&,&:hover,&:focus": {
			color: "inherit"
		}
	},
	logoNormalRTL: {
		textAlign: "right"
	},
	logoNormalSidebarMini: {
		opacity: "0",
		transform: "translate3d(-25px, 0, 0)"
	},
	logoNormalSidebarMiniRTL: {
		transform: "translate3d(25px, 0, 0)"
	},
	img: {
		width: "35px",
		verticalAlign: "middle",
		border: "0"
	},
	background: {
		position: "absolute",
		zIndex: "1",
		height: "100%",
		width: "100%",
		display: "block",
		top: "0",
		left: "0",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		transition: "all 300ms linear"
	},
	list: {
		paddingLeft: "0",
		paddingTop: "0",
		paddingBottom: "0",
		marginBottom: "0",
		listStyle: "none",
		color: "inherit",
		"&:before,&:after": {
			display: "table",
			content: '" "'
		},
		"&:after": {
			clear: "both"
		}
	},
	item: {
		color: "inherit",
		position: "relative",
		display: "block",
		textDecoration: "none",
		margin: "0",
		padding: "0",
	},
	userItem: {
		"&:last-child": {
			paddingBottom: "0px"
		}
	},
	userBtn:{
		background: tfmBlueColor,
	},
	itemLink: {
		paddingLeft: "0px",
		paddingRight: "0px",
		transition: "all 300ms linear",
		margin: "0px 15px 0",
		borderRadius: "8px",
		position: "relative",
		display: "block",
		padding: "0px 0px",
		backgroundColor: "rgba(0, 88, 166, 0.8)",
		...defaultFont,
		width: "auto",

		"&:hover": {
			outline: "none",
			backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
			boxShadow: "none"
		},
		"&,&:hover,&:focus": {
			color: "inherit"
		}
	},
	userItemLink: {
		paddingLeft: "15px",
		paddingRight: "0px",
		transition: "all 300ms linear",
		margin: "0px 15px 0",
		borderRadius: "8px",
		position: "relative",
		display: "block",
		padding: "0px 0px",
		...defaultFont,
		width: "auto",

		"&:hover": {
			outline: "none",
			backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
			boxShadow: "none"
		},
		"&,&:hover,&:focus": {
			color: "inherit"
		}
	},
	collapseItemLink: {
		transition: "all 300ms linear",
		margin: "0px 15px 0",
		borderRadius: "8px",
		position: "relative",
		display: "block",
		padding: "0px",
		...defaultFont,
		width: "auto",
		"&:hover": {
			outline: "none",
			backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
			boxShadow: "none"
		},
		"&,&:hover,&:focus": {
			color: "inherit",
		}
	},
	itemLinkMini: {
		paddingLeft: "0px",
	},
	itemNavLink: {
		backgroundColor: "rgba(" + hexToRgb(uiColors.primary.mid) + ", 0.8)",
		"&:hover": {
			outline: "none",
			backgroundColor: "rgba(" + hexToRgb(uiColors.primary.mid) + ", 1)",
			boxShadow: "none"
		},
	},
	itemIcon: {
		color: "inherit",
		width: "30px",
		height: "24px",
		float: "left",
		position: "inherit",
		top: "3px",
		marginRight: "15px",
		textAlign: "center",
		verticalAlign: "middle",
		opacity: "0.8"
	},
	itemIconRTL: {
		float: "right",
		marginLeft: "15px",
		marginRight: "1px"
	},
	itemText: {
		color: "inherit",
		fontWeight: 'bold',
		margin: "0",
		lineHeight: "30px",
		fontSize: "14px",
		transform: "translate3d(0px, 0, 0)",
		opacity: "1",
		transition: "transform 100ms ease 0s, opacity 100ms ease 0s",
		position: "relative",
		display: "block",
		height: "auto",
		whiteSpace: "nowrap"
	},
	itemTextRTL: {
		marginRight: "45px",
		textAlign: "right"
	},
	itemTextMini: {
		transform: "translate3d(-25px, 0, 0)",
		opacity: "0"
	},
	itemTextMiniRTL: {
		transform: "translate3d(25px, 0, 0) !important"
	},
	collapseList: {
		marginTop: "0"
	},
	collapseItem: {
		position: "relative",
		display: "block",
		textDecoration: "none",
		margin: "0",
		padding: "0",
	},
	collapseActive: {
		outline: "none",
		backgroundColor: uiColors.primary.mid,
		boxShadow: "none"
	},
	collapseItemMini: {
		color: "inherit",
		fontWeight: "900",
		textTransform: "uppercase",
		width: "30px",
		marginRight: "15px",
		textAlign: "center",
		letterSpacing: "1px",
		position: "relative",
		float: "left",
		display: "inherit",
		transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
		fontSize: "14px"
	},
	collapseItemMiniRTL: {
		float: "right",
		marginLeft: "30px",
		marginRight: "1px"
	},
	collapseItemText: {
		color: "inherit",
		fontWeight: "900",
		margin: "0",
		position: "relative",
		transform: "translateX(0px)",
		opacity: "1",
		whiteSpace: "nowrap",
		display: "block",
		transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
		fontSize: "14px"
	},
	collapseItemTextRTL: {
		textAlign: "right"
	},
	collapseItemTextMiniRTL: {
		transform: "translate3d(25px, 0, 0) !important"
	},
	collapseItemTextMini: {
		transform: "translate3d(-25px, 0, 0)",
		opacity: "0"
	},
	caret: {
		marginTop: "10px",
		position: "absolute",
		right: "18px",
		transition: "all 150ms ease-in",
		display: "inline-block",
		width: "0",
		height: "0",
		marginLeft: "2px",
		verticalAlign: "middle",
		borderTop: "8px solid",
		borderRight: "8px solid transparent",
		borderLeft: "8px solid transparent"
	},
	userCaret: {
		marginTop: "10px"
	},
	caretRTL: {
		left: "11px",
		right: "auto"
	},
	caretActive: {
		transform: "rotate(180deg)"
	},
	purple: {
		"&,&:hover,&:focus": {
			color: whiteColor,
			backgroundColor: uiColors.primary.default,
			...makeBoxShadow(uiColors.primary.default)
		}
	},
	blue: {
		"&,&:hover,&:focus": {
			color: whiteColor,
			backgroundColor: uiColors.info.default,
			boxShadow:
				"0 12px 20px -10px rgba(" +
				hexToRgb(uiColors.info.default) +
				",.28), 0 4px 20px 0 rgba(" +
				hexToRgb(blackColor) +
				",.12), 0 7px 8px -5px rgba(" +
				hexToRgb(uiColors.info.default) +
				",.2)"
		}
	},
	green: {
		"&,&:hover,&:focus": {
			color: whiteColor,
			backgroundColor: uiColors.success.default,
			boxShadow:
				"0 12px 20px -10px rgba(" +
				hexToRgb(uiColors.success.default) +
				",.28), 0 4px 20px 0 rgba(" +
				hexToRgb(blackColor) +
				",.12), 0 7px 8px -5px rgba(" +
				hexToRgb(uiColors.success.default) +
				",.2)"
		}
	},
	orange: {
		"&,&:hover,&:focus": {
			color: whiteColor,
			backgroundColor: uiColors.warning.default,
			boxShadow:
				"0 12px 20px -10px rgba(" +
				hexToRgb(uiColors.warning.default) +
				",.28), 0 4px 20px 0 rgba(" +
				hexToRgb(blackColor) +
				",.12), 0 7px 8px -5px rgba(" +
				hexToRgb(uiColors.warning.default) +
				",.2)"
		}
	},
	red: {
		"&,&:hover,&:focus": {
			color: whiteColor,
			backgroundColor: uiColors.danger.default,
			boxShadow:
				"0 12px 20px -10px rgba(" +
				hexToRgb(uiColors.danger.default) +
				",.28), 0 4px 20px 0 rgba(" +
				hexToRgb(blackColor) +
				",.12), 0 7px 8px -5px rgba(" +
				hexToRgb(uiColors.danger.default) +
				",.2)"
		}
	},
	white: {
		"&,&:hover,&:focus": {
			color: grayColor[2],
			backgroundColor: whiteColor,
			boxShadow:
				"0 4px 20px 0 rgba(" +
				hexToRgb(blackColor) +
				",.14), 0 7px 10px -5px rgba(" +
				hexToRgb(grayColor[2]) +
				",.4)"
		}
	},
	rose: {
		"&,&:hover,&:focus": {
			color: whiteColor,
			backgroundColor: uiColors.rose.default,
			boxShadow:
				"0 4px 20px 0 rgba(" +
				hexToRgb(blackColor) +
				",.14), 0 7px 10px -5px rgba(" +
				hexToRgb(uiColors.rose.default) +
				",.4)"
		}
	},
	sidebarWrapper: {
		position: "relative",
		height: "calc(100vh - 75px)",
		overflow: "auto",
		width: "100%",
		zIndex: "4",
		overflowScrolling: "touch",
		transitionProperty: "top, bottom, width",
		transitionDuration: ".2s, .2s, .35s",
		transitionTimingFunction: "linear, linear, ease",
		color: "inherit",
		paddingBottom: "30px"
	},
	sidebarWrapperWithPerfectScrollbar: {
		overflow: "hidden !important"
	},
	user: {
		margin: "0 auto 10px",
		position: "relative",
		"&:after": {
			content: '""',
			position: "absolute",
			bottom: "0",
			right: "15px",
			height: "1px",
			width: "calc(100% - 30px)"
		}
	},
	photo: {
		transition: "all 300ms linear",
		width: "30px",
		height: "30px",
		overflow: "hidden",
		float: "left",
		zIndex: "5",
		marginRight: "15px",
		borderRadius: "50%",
		...boxShadow
	},
	photoRTL: {
		float: "right",
		marginLeft: "12px",
		marginRight: "24px"
	},
	avatarImg: {
		width: "100%",
		verticalAlign: "middle",
		border: "0"
	},
	userCollapseButton: {
		"&:hover": {
			background: "none"
		}
	},
	userCollapseLinks: {
		"&:hover": {
			outline: "none",
			backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
			boxShadow: "none"
		},
		"&:hover,&:focus": {
			color: whiteColor
		}
	},
	banner: {
		width: "100%",
		zIndex: "1"
	},
	bannerContainer: {
		backgroundColor: "transparent",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: "1",
		height: "70px",
		width: "100%"
	},
	logoBanner: {
		margin: "5px",
		display: "block",
		position: "relative",
		zIndex: "4",
		"&:after": {
			content: '""',
			position: "absolute",
			bottom: "0",
			height: "1px",
			right: "15px",
			width: "calc(100% - 30px)",
			backgroundColor: "hsla(0,0%,100%,.3)"
		},
		boxShadow:
			"0 4px 20px 0 rgba(" +
			hexToRgb(blackColor) +
			",.14), 0 7px 10px -5px rgba(" +
			hexToRgb(grayColor[2]) +
			",.4)"
	},
	footer: {
		borderTop: "1px solid " + grayColor[2],
		backgroundColor: tfmBlueColor,
		padding: "0",
		...defaultFont,
		fontSize: "10px",
		textAlign: "center",
		zIndex: 4,
	},
	poweredByImg: {
		width: '100px',
	},
	primary: {
		backgroundColor: tfmBlueColor,
		color: whiteColor,
		...notificationBoxShadow,
	},
	info: {
		backgroundColor: uiColors.info.dark,
		color: whiteColor,
		...notificationBoxShadow
	},
	success: {
		backgroundColor: uiColors.success.dark,
		color: whiteColor,
		...notificationBoxShadow
	},
	warning: {
		backgroundColor: uiColors.warning.dark,
		color: whiteColor,
		...notificationBoxShadow
	},
	misc: {
		backgroundColor: uiColors.misc.dark,
		color: whiteColor,
		...notificationBoxShadow
	},
	danger: {
		backgroundColor: uiColors.danger.dark,
		color: whiteColor,
		...notificationBoxShadow
	},
	error: {
		backgroundColor: uiColors.danger.dark,
		color: whiteColor,
		...notificationBoxShadow
	},
});

export default notificationSidebarStyle;
