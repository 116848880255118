import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import cx from "classnames";
import { NavLink, Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { baseUrl, assetPath, basePath, pathname } from "~/variables/server.jsx";
import { connect } from "react-redux";
import { clearNotifications } from "~/redux/actions/notifications.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import notificationSidebarStyle from "~/assets/jss/empire-tms/components/notificationSidebarStyle.jsx";

var ps;

class NotificationsSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			maxNoticesToShow: 100,
			miniActive: true,
		};
	}
	
	getItems() {
		const { classes, rtlActive } = this.props;
		let notices = this.props.notifications.processed;
		notices = notices.concat(this.props.notifications.notifications);
		notices = notices.reverse();
		if(notices.length > this.state.maxNoticesToShow) {
			notices = notices.slice(0, this.state.maxNoticesToShow);
		}
		let retVal = notices.map((prop, key) => {
			let className = classes[prop.options.variant];
			return (
				<GridItem xs={12} sm={12} md={12} lg={12} xl={12}
					onClick={this.handleClose}
				>
					<CardBody className={className}>
						{prop.message}
					</CardBody>
				</GridItem >
			);
		});
	  return (
		<GridContainer>
	  		{retVal}
		</GridContainer>
	  )
	}

	render() {
		const {
			classes,
			image,
			routes,
			bgColor,
			rtlActive
		} = this.props;
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperRTL]: rtlActive
		});

		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
		});
			
		const bannerClasses =
			classes.headerBanner +
			" " +
			cx({
				[classes.blackAfter]: true
		});
		var notifHeader = (
			<div className={bannerClasses}>
				<b>Notifications</b>
			</div>
		);

		return (
			<Drawer
				onMouseOver={() => this.setState({ miniActive: false })}
				onMouseOut={() => this.setState({ miniActive: true })}
				onClose={this.props.handleDrawerToggle}
				anchor={rtlActive ? "right" : "left"}
				variant="permanent"
				open
				classes={{paper: drawerPaper + " " + classes[bgColor + "Background"]}}
				ModalProps={{
					keepMounted: true // Better open performance on mobile.
				}}
			>
				{notifHeader}
				<SidebarWrapper
					className={sidebarWrapper}
					links={this.getItems()}
				/>
				{image !== undefined ? (
					<div
						className={classes.background}
						style={{backgroundImage: "url(" + image + ")"}}
					/>
				) : null}
			</Drawer>
		);
	}
}
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, headerLinks, links } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				<Divider variant="middle" />
				{headerLinks}
				{links}
			</div>
		);
	}
}
NotificationsSidebar.defaultProps = {
	bgColor: "black"
};

NotificationsSidebar.propTypes = {
	user: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"white",
		"red",
		"orange",
		"green",
		"blue",
		"purple",
		"rose"
	]),
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object)
};


const mapStateToProps = state => {
	return {
		notifications: state.notifications,
	}
}
export default connect(mapStateToProps, {clearNotifications})(withStyles(notificationSidebarStyle)(NotificationsSidebar));