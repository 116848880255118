import React, { Component } from "react";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import PasswordRulesList from "~/components/TMS/PasswordRulesList.jsx";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import withStyles from "@material-ui/core/styles/withStyles";

const PasswordTooltip = withStyles({
	tooltip: {
		color: "black",
		backgroundColor: "white",
		border: '1px solid black',
		maxWidth: 500 }
})(Tooltip);

class PasswordField extends Component {

    state = {
        uppercase: false,
        lowercase: false,
        digit: false,
        specialCharacter: false,
        showPassword: false,
        showPasswordRules: false
    };

    toggle = name => () => this.setState({ [name]: !this.state[name] });

    handleChange = event => {
		this.validatePassword(event.target.value);
		this.props.handleChange(event);
	};

    validatePassword = (password) => {
		let { uppercase, lowercase, digit, specialCharacter } = this.state;
		const uppercaseRegExp = /[A-Z]/;
		const lowercaseRegExp = /[a-z]/;
		const digitRegExp = /\d/;
		const specialCharacterRegExp = /[`~!@#%&_=:"'<>,\-\.\+\*\?\^\$\(\)\[\]\{\}\|\\\/]/;
		uppercase = password.match(uppercaseRegExp) ? true : false;
		lowercase = password.match(lowercaseRegExp) ? true : false;
		digit = password.match(digitRegExp) ? true : false;
		specialCharacter = password.match(specialCharacterRegExp) ? true : false;
		this.setState({ uppercase, lowercase, digit, specialCharacter });
	}

    render() {
        const { uppercase, lowercase, digit, specialCharacter, showPassword, showPasswordRules} = this.state;
        return (
            <PasswordTooltip
                PopperProps={{ disablePortal: true }}
                open={showPasswordRules}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <>
                        <PasswordRulesList
                            uppercase={uppercase}
                            lowercase={lowercase}
                            digit={digit}
                            specialCharacter={specialCharacter}
                        />
                    </>
                }
            >
                <CustomInput
                    labelText={this.props.label ? this.props.label : ''}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                        type: showPassword ? "text" : "password",
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={this.toggle('showPassword')}
                                    edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        name: "password",
                        value: this.props.password || "",
                        onChange: this.handleChange,
                        onBlur: () => this.setState({ showPasswordRules: false }, ),
                        onFocus: () => this.setState({ showPasswordRules: true })
                    }}
                    white
            />
            </PasswordTooltip>
        );
    }
}

export default PasswordField;