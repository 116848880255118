import React from "react";
import qs from "qs";

import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewNmfcClassMap extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: null,
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			nmfc: "",
			maps: [
				{
					pcf_from: "",
					pcf_to: "",
					sub: "",
					class: ""
				}
			],
			export_data: ""
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getMaps = this.getMaps.bind(this);
		this.addMap = this.addMap.bind(this);
		this.removeMap = this.removeMap.bind(this);
		this.saveMap = this.saveMap.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		if(!_.isEmpty(this.props.match.params.id)) {
			const nmfc = this.props.match.params.id;
			try {
				const response = await axios.get(
					"/index.php?p=api&r=json&c=admin&m=editNmfcClassMap&d=" + nmfc
				);
				const data = response.data;

				this.props.handleMessages(response);

				if (typeof data !== "string" && !_.isEmpty(data.user) && this.state.mounted) {
					this.props.pageTitle('Edit NMFC Class Map - ' + nmfc);
					this.setState({
						show: true,
						loading: false,
						user: data.user,
						body: data.body,
						nmfc: data.body.post.nmfc,
						maps: data.body.post.map
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"Unexpected response received from server while loading the data!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	getMaps(data) {
		const { classes } = this.props;
		return data.map((prod, key) => {
			return [
				<CustomInput
					formControlProps={{
						fullWidth: true
					}}
					inputProps={{
						type: "text",
						value: prod.pcf_from || "",
						onChange: e => this.handleMap(key, "pcf_from", e)
					}}
					required
				/>,
				<CustomInput
					formControlProps={{
						fullWidth: true
					}}
					inputProps={{
						type: "text",
						value: prod.pcf_to || "",
						onChange: e => this.handleMap(key, "pcf_to", e)
					}}
					required
				/>,
				<CustomInput
					formControlProps={{
						fullWidth: true
					}}
					inputProps={{
						type: "text",
						value: prod.sub || "",
						onChange: e => this.handleMap(key, "sub", e)
					}}
					required
				/>,
				<CustomInput
					formControlProps={{
						fullWidth: true
					}}
					inputProps={{
						type: "text",
						value: prod.class || "",
						onChange: e => this.handleMap(key, "class", e)
					}}
					required
				/>,
				<Button
					color="danger"
					size="sm"
					justIcon
					round
					onClick={e => this.removeMap(key)}
				>
					<Close />
				</Button>
			];
		});
	}
	addMap() {
		let { maps } = this.state;
		maps.push({
			pcf_from: "",
			pcf_to: "",
			sub: "",
			class: ""
		});
		this.setState({ maps });
	}
	handleMap(i, name, event) {
		let { maps } = this.state;
		if (!_.isEmpty(maps) && (i in maps)) {
			let map = maps[i];
			map[name] = event.target.value;
			maps[i] = map;
			this.setState({ maps });
		}
	}
	removeMap(i) {
		let { maps } = this.state;
		if (!_.isEmpty(maps)) {
			maps.splice(i, 1);
			this.setState({ maps });
		}
	}
	async saveMap() {
		this.setState({
			loading: true
		});
		if (_.isEmpty(this.state.nmfc)) {
			this.setState({ loading: false });
			this.props.setNotification("The NMFC cannot be blank!", {
				variant: "error"
			});
			return;
		}
		if (_.isEmpty(this.state.maps)) {
			this.setState({ loading: false });
			this.props.setNotification("There must be at least one class map!", {
				variant: "error"
			});
			return;
		}

		const { nmfc, maps } = this.state;
		const map = maps;
		const data = { nmfc, map };

		if (_.isEmpty(this.props.match.params)) {
			try {
				let response = await axios.get("/index.php?p=api&r=json&c=admin&m=nmfcClassMaps");
				for (let map of response.data.body.maps.result) {
					if (nmfc == map.nmfc) {
						this.setState({ loading: false });
						this.props.setNotification("This NMFC class map already exists!", {
							variant: "error"
						});
						return;
					}
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error retrieving NMFC class maps!", {
					variant: "error"
				});
			}
			try {
				let response = await axios.post(
					"/index.php?p=api&r=json&c=admin&m=newNmfcClassMap&d=" + this.state.nmfc,
					qs.stringify(data)
				);
				if (!_.isEmpty(response.data)) {
					this.props.handleMessages(response);
					this.setState({
						loading: false,
						redirect: <Redirect to={basePath + "/admin/settings/nmfc-class-map"} />
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"Received unexpected response while creating a new NMFC class map!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error creating new NMFC class map!", {
					variant: "error"
				});
			}
			return;
		} else if (!_.isEmpty(this.props.match.params.id)) {
			const nmfc = this.props.match.params.id;
			try {
				let response = await axios.get("/index.php?p=api&r=json&c=admin&m=nmfcClassMaps");
				for (let map of response.data.body.maps.result) {
					if (this.state.nmfc == map.nmfc && this.state.nmfc != nmfc) {
						this.setState({ loading: false });
						this.props.setNotification("This NMFC class map already exists!", {
							variant: "error"
						});
						return;
					}
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error retrieving NMFC class maps!", {
					variant: "error"
				});
			}
			try {
				const response = await axios.post(
					"/index.php?p=api&r=json&c=admin&m=editNmfcClassMap&d=" + this.props.match.params.id,
					qs.stringify(data)
				);
				if (!_.isEmpty(response.data)) {
					this.props.handleMessages(response);
					this.setState({
						loading: false,
						redirect: <Redirect to={basePath + "/admin/settings/nmfc-class-map"} />
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"Received unexpected response while creating a new NMFC class map!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving NMFC class map!", {
					variant: "error"
				});
			}
		} else {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("No NMFC has been specified!", {
				variant: "error"
			});
		}
	}
	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		const message =
			"<b>Instructions:</b> Paste exported FastClass data here and click Preview to see the data populated in the table to the left. This will work with most data provided they are density rated. Some NMFCs need broken down into Aliases, this tool cannot handle that and will require at best some manual intervention and at worst a fully manual mapping. <b>NOT ALL NMFCs ARE DENSITY RATED!! THIS TOOL DOES NOT WORK AT ALL FOR THOSE NMFCs!!</b>";
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<h5>Manually Create NMFC Class Map</h5>
									<GridContainer>
										<GridItem xs={6}>
											<CustomInput
												labelText="NMFC"
												inputProps={{
													type: "text",
													name: "nmfc",
													value:
														this.state.nmfc || "",
													onChange: this.handleInput(
														"nmfc"
													)
												}}
												required
											/>
											<Button
												color="success"
												size="sm"
												justIcon
												round
												onClick={this.addMap}
											>
												<Add />
											</Button>
										</GridItem>
										<GridItem xs={6}>
											<Button
												color="linkedin"
												className={classes.chip}
												onClick={this.saveMap}
											>
												Save Map
											</Button>
										</GridItem>
										<GridItem xs={12}>
											<Table
												tableHead={[
													"PCF From",
													"PCF To",
													"Sub",
													"Class",
													""
												]}
												tableData={this.getMaps(
													this.state.maps
												)}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(NewNmfcClassMap));