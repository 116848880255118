import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import navPillsStyle from "~/assets/jss/empire-tms/components/navPillsStyle.jsx";

class NavPills extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: props.active
		};
	}
	handleChange = (event, active) => {
		this.props.handleChange(active);
		this.setState({ active });
	};
	handleChangeIndex = index => {
		this.setState({ active: index });
	};
	render() {
		const {
			classes,
			tabs,
			direction,
			color,
			text,
			horizontal,
			alignCenter,
			tabContentOverflowHidden
		} = this.props;
		const flexContainerClasses = classNames({
			[classes.flexContainer]: true,
			[classes.horizontalDisplay]: horizontal !== undefined
		});
		const tabButtons = (
			<Tabs
				classes={{
					root: classes.root,
					fixed: classes.fixed,
					flexContainer: flexContainerClasses,
					indicator: classes.displayNone
				}}
				value={this.state.active}
				onChange={this.handleChange}
				centered={alignCenter}
			>
				{tabs.map((prop, key) => {
					var icon = {};
					let tabColor = (prop.tabColor !== undefined) ? tabColor : color;
					if (prop.tabIcon !== undefined) {
						icon["icon"] = (
							<prop.tabIcon className={classes.tabIcon} />
						);
					}
					const pillsClasses = classNames({
						[classes.pills]: text !== "white",
						[classes.pillsWhiteText]: text === "white",
						[classes.horizontalPills]: horizontal !== undefined,
						[classes.pillsWithIcons]: prop.tabIcon !== undefined,
					});
					return (
						<Tab
							label={prop.tabButton}
							key={key}
							{...icon}
							classes={{
								root: pillsClasses,
								selected: classes[tabColor]
							}}
						/>
					);
				})}
			</Tabs>
		);
		const tabContent = (
			<div className={classes.contentWrapper}>
				<SwipeableViews
					axis={direction === "rtl" ? "x-reverse" : "x"}
					index={this.state.active}
					onChangeIndex={this.handleChangeIndex}
					style={{ overflowY: "hidden" }}
				>
					{tabs.map((prop, key) => {
						return (
							<div className={classes.tabContent} key={key} style={tabContentOverflowHidden ? {overflow: "hidden"} : {}}>
								{prop.tabContent}
							</div>
						);
					})}
				</SwipeableViews>
			</div>
		);
		return horizontal !== undefined ? (
			<GridContainer>
				<GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
				<GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
			</GridContainer>
		) : (
			<div>
				{tabButtons}
				{tabContent}
			</div>
		);
	}
}

NavPills.defaultProps = {
	active: 0,
	color: "primary",
	text: "primary",
	handleChange: active => {}
};

NavPills.propTypes = {
	classes: PropTypes.object.isRequired,
	// index of the default active pill
	active: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			tabButton: PropTypes.string,
			tabIcon: PropTypes.func,
			tabContent: PropTypes.node,
			tabColor: PropTypes.oneOf([
				"primary",
				"warning",
				"danger",
				"success",
				"info",
				"rose",
				"linkedin"
			])
		})
	).isRequired,
	color: PropTypes.oneOf([
		"primary",
		"warning",
		"danger",
		"success",
		"info",
		"rose",
		"linkedin"
	]),
	text: PropTypes.oneOf([
		"primary",
		"white"
	]),
	handleChange: PropTypes.func,
	direction: PropTypes.string,
	horizontal: PropTypes.shape({
		tabsGrid: PropTypes.object,
		contentGrid: PropTypes.object
	}),
	alignCenter: PropTypes.bool
};

export default withStyles(navPillsStyle)(NavPills);