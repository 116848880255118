

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";




// TMS - Carrier 


import { basePath } from "~/variables/server.jsx";
import CarrierInvoiceLandingPage from "~/views/TMS/Admin/CarrierInvoiceLandingPage.jsx";
import invoiceHoldStatus from "~/views/TMS/Admin/invoiceHoldStatus.jsx";
import agingReport from "~/views/TMS/Admin/agingReport.jsx";
import invoiceRemittanceByDate from "~/views/TMS/Admin/invoiceRemittanceByDate.jsx";
import invoiceRemittanceByCheckNum from "~/views/TMS/Admin/invoiceRemittanceByCheckNum.jsx";
import invoiceRemittanceByProNum from "~/views/TMS/Admin/invoiceRemittanceByProNum.jsx";
import invoiceStandardCarrierByInvoiceNum  from "~/views/TMS/Admin/invoiceStandardCarrierByInvoiceNum.jsx";
import invoiceStandardCarrierByInvoiceDate  from "~/views/TMS/Admin/invoiceStandardCarrierByInvoiceDate.jsx";
import invoiceStandardCarrierByDeliveryDate   from "~/views/TMS/Admin/invoiceStandardCarrierByDeliveryDate.jsx";
import invoiceStandardCarrierByShipDate    from "~/views/TMS/Admin/invoiceStandardCarrierByShipDate.jsx";
import invoiceStandardCarrierByProNum   from "~/views/TMS/Admin/invoiceStandardCarrierByProNum.jsx";
import CarrierInvoiceDetail from "~/views/TMS/Admin/CarrierInvoiceDetail.jsx";
export default [
   
    
  {
        path: "/carrier-invoice",
        name: "Carrier Portal",
        rtlName: "Carrier Portal",
        icon: DashboardIcon,
        component: CarrierInvoiceLandingPage,
        layout: basePath + "/admin",

    },

    {
        path: "/carrier-report-invoice-status/search",
        name: "Invoice Hold Status Report",
        mini: "IS",
        rtlName: "Invoice Hold Status",
        rtlMini: "IS",
        component: invoiceHoldStatus,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-aging-report/search",
        name: "Invoice Aging Report",
        mini: "IS",
        rtlName: "Invoice Hold Status",
        rtlMini: "IS",
        component: agingReport,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-remittance-check/search/",
        name: "Invoice Remittance Report",
        mini: "IS",
        rtlName: "Remittance Report By Check Number",
        rtlMini: "IS",
        component: invoiceRemittanceByCheckNum,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-remittance-pro/search/",
        name: "Invoice Remittance Report",
        mini: "IS",
        rtlName: "Remittance Report By Pro Number",
        rtlMini: "IS",
        component: invoiceRemittanceByProNum,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-remittance-date/search/",
        name: "Invoice Remittance Report",
        mini: "IS",
        rtlName: "Remittance Report By Date",
        rtlMini: "IS",
        component: invoiceRemittanceByDate,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-Standard-report-pro-num/search",
        name: "Standard Carrier Invoice Report",
        mini: "IS",
        rtlName: "Standard Carrier Invoice Report",
        rtlMini: "IS",
        component: invoiceStandardCarrierByProNum,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-Standard-report-invoice-num/search",
        name: "Standard Carrier Invoice Report",
        mini: "IS",
        rtlName: "Standard Carrier Invoice Report",
        rtlMini: "IS",
        component: invoiceStandardCarrierByInvoiceNum,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-Standard-report-invoice-date/search",
        name: "Standard Carrier Invoice Report",
        mini: "IS",
        rtlName: "Standard Carrier Invoice Report",
        rtlMini: "IS",
        component: invoiceStandardCarrierByInvoiceDate,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-Standard-report-delivery-date/search",
        name: "Standard Carrier Invoice Report",
        mini: "IS",
        rtlName: "Standard Carrier Invoice Report",
        rtlMini: "IS",
        component: invoiceStandardCarrierByDeliveryDate ,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/carrier-Standard-report-ship-date/search",
        name: "Standard Carrier Invoice Report",
        mini: "IS",
        rtlName: "Standard Carrier Invoice Report",
        rtlMini: "IS",
        component: invoiceStandardCarrierByShipDate ,
        layout: basePath + "/admin",
        invisible: true
    },
    {
        path: "/Carrier_Invoice_Detail",
        name: "Invoice Detail",
        mini: "IS",
        rtlName: "Invoice Detail",
        rtlMini: "IS",
        component: CarrierInvoiceDetail ,
        layout: basePath + "/admin",
        invisible: true
    },
  
];


