const initialState = {
	intlShipId: "",
	modalOpen: false,
	notesLoading: false,
	notes: [],
	saveNoteLoading: false,
	deleteNoteLoading: false,
	newNoteMessage: "",
	editingNotes: {}
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_INTL_NOTE_SHIPMENT_ID": {
			return {...newState, intlShipId: action.id};
		}
		case "SET_NEW_INTL_NOTE_MESSAGE": {
			return {...newState, newNoteMessage: action.message};
		}
		case "SET_INTL_NOTES_MODAL_OPEN": {
			return {...newState, modalOpen: action.val};
		}
		case "SET_INTL_NOTES": {
			return {...newState, notes: [...action.notes]};
		}
		case "SET_INTL_NOTES_EDITING_NOTES": {
			return {...newState, editingNotes: {...action.val}};
		}
		case "SET_INTL_NOTES_LOADING": {
			return {...newState, notesLoading: action.val};
		}
		case "SET_INTL_NOTES_SAVE_LOADING": {
			return {...newState, saveNoteLoading: action.val};
		}
		case "SET_INTL_NOTES_DELETE_LOADING": {
			return {...newState, deleteNoteLoading: action.val};
		}
		default:
			return state
	}
}