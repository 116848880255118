import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import { withSnackbar } from "notistack";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
class ClassChargeStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classcharges: this.props.classcharges,
    };
  }

  componentDidMount() {
    this.props.handleStepState(this.state, "classcharge");
  }

  handleClassCharge = (value, event) => {
    let classcharges = this.state.classcharges;

    if (event.target.checked) {
      classcharges.push(value);
    } else {
      classcharges.splice(classcharges.indexOf(value), 1);
    }

    this.setState({ classcharges }, () => {
      this.props.handleStepState(this.state, "classcharge");
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="right">
        <GridItem xs={12} sm={12}>
          <h4>Select Class and/or Charge Option</h4>
        </GridItem>
        <GridItem xs={6} sm={4} md={4} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                value="Class"
                checked={this.state.classcharges.indexOf("Class") > -1}
                onChange={(e) => this.handleClassCharge("Class", e)}
              />
            }
            classes={{ label: classes.label }}
            label="Class"
          />

          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                value="Accessorial"
                checked={this.state.classcharges.indexOf("Accessorial") > -1}
                onChange={(e) => this.handleClassCharge("Accessorial", e)}
              />
            }
            classes={{ label: classes.label }}
            label="Charge"
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(ltlQuoteStyle)(withSnackbar(ClassChargeStep));
