import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Transition from "~/components/TMS/ModalTransition.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class FeetToInchesModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Feet to Inches Calculator</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <GridContainer>
                        <GridItem xs>
                            <InputLabel className={classes.label}>Feet</InputLabel>
                            <br />
                            <CustomInput
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "text",
                                    name: "feet",
                                    value: this.props.feet || "",
                                    onChange: this.props.feetChange(),
                                    endAdornment: <InputAdornment position="end">ft.</InputAdornment>
                                }}
                                white
                            />
                        </GridItem>
                        <GridItem xs>
                            <InputLabel className={classes.label}>Inches</InputLabel>
                            <br />
                            <CustomInput
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "text",
                                    name: "inches",
                                    value: this.props.inches || "",
                                    onChange: this.props.inchesChange(),
                                    endAdornment: <InputAdornment position="end">in.</InputAdornment>
                                }}
                                white
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => this.props.handleConversion()} color="linkedin" style={{ marginRight: "5px" }}>
                        Calculate
                    </Button>
                    <Button onClick={() => this.props.onClose()} color="danger" style={{ marginRight: "5px" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(FeetToInchesModal);