import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class SearchFilterType extends Component {
    state = {
        operators: this.props.operators || [
            { value: 'contains', text: 'CONTAINS' },
            { value: 'equals', text: 'EQUALS' },
            { value: 'starts with', text: 'BEGINS WITH' },
            { value: 'ends with', text: 'ENDS WITH' },
        ],
    };

    handleChange = (e) => {
        let value = e.target.value;
        this.props.updateFilterType(value);
    }

    render() {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    style={{ width: "inherit" }}
                    defaultValue={this.state.operators[0].value}
                    value={this.props.value}
                    onChange={this.handleChange}
                    placeholder="Select Comparison"
                >
                    {this.state.operators.map((operator, i) => (
                        <MenuItem key={i} value={operator.value}>
                            {operator.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
}

const mapStateToProps = (state) => {
	return {
        ...state.Search
	};
};

export default connect(mapStateToProps, mapDispatchToProps) (SearchFilterType);
