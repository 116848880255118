import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";
import { withSnackbar } from "notistack";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

// core components
import KendoDataWrapper from "~/components/Kendo/KendoDataWrapper.jsx";
import BolStatusOverview from "~/components/Kendo/BolStatusOverview.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import "hammerjs";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { hasPermission } from "../../redux/selectors/Admin.jsx";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.controller = new AbortController(); // abort axios xhr
		this.state = {
			mounted: false,
			alert: null,
			redirect: null,
			show: false,
			loading: true,
			user: null,
			customer: null,
			bol_compare: null
		};
		this.viewTfmReporting = this.viewTfmReporting.bind(this);
	}

	viewTfmReporting() {

		if(!this.props.hasDashboardAccess) {
			return false;
		}

		const url = basePath + "/index.php?p=api&r=json&c=reporting&m=viewTfmcDashboard";
		window.location = url;

	}

	async componentDidMount() {
		this.setState({ mounted: true });
		const url = "/index.php?p=api&r=json&c=reporting&m=viewDashboard";
		try {

			const response = await axios.get(url, { signal: this.controller.signal });

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && this.state.mounted) {
				this.setState({
					show: true,
					loading: false,
					alert: null,
					user: response.data.user,
					customer: !_.isEmpty(response.data.body) ? response.data.body.customer : null,
					bol_compare: !_.isEmpty(response.data.body)  ? response.data.body.bol_compare : null
				});

			} else {

				this.setState({ loading: false });
				if (typeof response.data === "string" || !_.isEmpty(response.data.message)) {
					this.props.setNotification("There was an error loading the data!", { variant: "error" });
				}

			}

		} catch (error) {
			if(typeof error.message === 'undefined' && error.message !== 'canceled') {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		}
	}

	componentWillUnmount() {
		this.controller.abort();
		this.setState({ mounted: false });
	}

	render() {
		const { classes } = this.props;
		return (
			<Grid container>
				{this.state.loading && (
					<Grid item xs={12} className={classes.center}>
						<CircularProgress />
					</Grid>
				)}
				{ (this.state.mounted && this.state.user && this.props.hasDashboardAccess) && (
					<Grid item xs={12} className={classes.spacing}>
						<Button size="sm" onClick={() => this.viewTfmReporting()} >Visit TFM Reporting to see more</Button>
					</Grid>
				)}

				{!_.isEmpty(this.state.bol_compare) && (
					<Grid item xs={12} className={classes.spacing}>
						<Paper className={classes.paper} elevation={1}>
							<BolStatusOverview data={this.state.bol_compare} />
						</Paper>
					</Grid>
				)}
				{!_.isEmpty(this.state.customer) && (
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={6} className={classes.spacing}>
								<KendoDataWrapper type="TotalShipments" token={this.state.customer.key} companies={[this.state.customer.companyId]} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6} className={classes.spacing}>
								<KendoDataWrapper type="TotalSpend" token={this.state.customer.key} companies={[this.state.customer.companyId]} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6} className={classes.spacing}>
								<KendoDataWrapper type="FacilityShipments" token={this.state.customer.key} companies={[this.state.customer.companyId]} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6} className={classes.spacing}>
								<KendoDataWrapper type="FacilitySpend" token={this.state.customer.key} companies={[this.state.customer.companyId]} />
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        hasDashboardAccess: hasPermission("ALLOW_DASHBOARD_ACCESS")(state),
    };
}

export default connect(mapStateToProps)(withStyles(style)(withSnackbar(Dashboard)));