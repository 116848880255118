import axios from '../../../variables/axios.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import qs from "qs";
import { getDateFromBol, getIdFromBol } from "../../../helpers.jsx";

const setBolNotesLoading = val => {
	return {type: "SET_BOL_NOTES_MODAL_NOTES_LOADING", val};
}
const setBolNotes = val => {
	return {type: "SET_BOL_NOTES_MODAL_NOTES", val};
}
const setSaveBolNoteLoading = val => {
	return {type: "SET_BOL_NOTES_MODAL_SAVE_BOL_NOTE_LOADING", val};
}
const setDeleteBolNoteLoading = val => {
	return {type: "SET_BOL_NOTES_MODAL_DELETE_BOL_NOTE_LOADING", val};
}
const setNotesModalOpen = val => {
	return {type: "SET_BOL_NOTES_MODAL_OPEN", val};
}
const setBol = val => {
	return {type: "SET_BOL_NOTES_MODAL_BOL", val};
}
const setEditingNotes = val => {
	return {type: "SET_BOL_NOTES_EDITING_NOTES", val};
}
const setAddedNote = val => {
	return {type: "SET_BOL_NOTES_ADDED_NOTE", val};
}
const setDeletedNote = val => {
	return {type: "SET_BOL_NOTES_DELETED_NOTE", val};
}
export const setNewNoteMessage = val => {
	return {type: "SET_BOL_NOTES_NEW_NOTE_MESSAGE", val};
}

export const clearState = () => {
	return {type: "CLEAR_BOL_NOTES_MODAL"};
}

export const closeModal = () => {
	return dispatch => {
		dispatch(clearState());
		dispatch(setNotesModalOpen(false));
	}
}

export const setEditNoteValue = (id, value) => {
	return (dispatch, getState) => {
		const state = getState();
		let editNotes = {...state.BillOfLadingNotes.editingNotes};
		if (Object.keys(editNotes).indexOf(id) !== -1) {
			editNotes[id].message = value;
			dispatch(setEditingNotes(editNotes));
		}
	}
}

export const editNote = id => {
	return (dispatch, getState) => {
		const state = getState();
		let editNotes = {...state.BillOfLadingNotes.editingNotes};
		let bolNotes = [...state.BillOfLadingNotes.bolNotes];
		if (Object.keys(editNotes).indexOf(id) === -1) {
			bolNotes.map(note => {
				if (+note.id === +id) {
					editNotes[id] = {
						message: note.message,
					};
				}
			});
			dispatch(setEditingNotes(editNotes));
		}
	}
}

export const stopEditingNote = id => {
	return (dispatch, getState) => {
		const state = getState();
		let editNotes = {...state.BillOfLadingNotes.editingNotes};
		if (Object.keys(editNotes).indexOf(id) !== -1) {
			delete(editNotes[id]);
			dispatch(setEditingNotes(editNotes));
		}
	}
}

/**
 * Fetch notes for the provided bol number
 * Open the modal upon success
 * @param  string bol 
 */
export const loadNotesModal = (bol) => {
	return async (dispatch) => {
		dispatch(clearState());
		dispatch(setBol(bol));
		dispatch(setBolNotesLoading(true));
		dispatch(setNotesModalOpen(true));
		try {
			if (typeof bol !== "string" || bol.length < 9) {
				dispatch(setNotification("Invalid BOL provided to load notes", {variant: "error"}))
			} else {
				const date = getDateFromBol(bol);
				const id = getIdFromBol(bol);
				const url = `index.php?p=api&r=json&c=billOfLadingNotes&m=collection&orderBy[]=id-&filters[bol_name_date]=${date}&filters[bol_name_id]=${id}&limit=999`;
				const response = await axios.get(url);
				dispatch(handleMessages(response));
				if (response && response.data && response.data.body) {
					const notes = Array.isArray(response.data.body.response) ? response.data.body.response : [];
					dispatch(setBolNotes(notes));
				}
			}
			
		} catch (err) {
			dispatch(setNotification("Error fetching bol notes status", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setBolNotesLoading(false));
		}
	}
}

/**
 * Create or update a bol note
 * @param  int | null id 
 * @param  object attributes
 */
export const saveBolNote = (id = null, message = "", internal = false) => {
	return async (dispatch, getState) => {
		const state = getState();
		dispatch(setSaveBolNoteLoading(true));
		try {
			const payload = id 
			? qs.stringify({message}) 
			: qs.stringify({
				bol_name_id: getIdFromBol(state.BillOfLadingNotes.bol),
				bol_name_date: getDateFromBol(state.BillOfLadingNotes.bol),
				message,
				internal: internal ? 1 : 0,
			});
			let url = `index.php?p=api&r=json&c=billOfLadingNotes&m=save`;
			if (id) {
				url += `&id=${id}`;
			}
			const response = await axios.post(url, payload);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				let notes = [...state.BillOfLadingNotes.bolNotes];
				if (id) {
					notes.map((note, index) => {
						if (+note.id === +id) {
							notes[index].message = message;
						}
					});
					dispatch(stopEditingNote(id));
				} else {
					notes.unshift(response.data.body.response);
					dispatch(setAddedNote(`${response.data.body.response.bol_name_date}${response.data.body.response.bol_name_id}`));
					dispatch(setNewNoteMessage(""));
				}
				dispatch(setBolNotes(notes));
			}
		} catch (err) {
			dispatch(setNotification("Error saving bill of lading note", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setSaveBolNoteLoading(false));
		}
	}
}

/**
 * Delete a note
 * @param  int id 
 */
export const deleteBolNote = (id) => {
	return async (dispatch, getState) => {
		dispatch(setDeleteBolNoteLoading(true));
		try {
			const url = `index.php?p=api&r=json&c=billOfLadingNotes&m=delete&id=${id}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.deleted) {
				const state = getState();
				let notes = [...state.BillOfLadingNotes.bolNotes];
				dispatch(setBolNotes(notes.filter(note => {
					return +note.id !== +id;
				})));
				dispatch(setDeletedNote(`${response.data.body.response.bol_name_date}${response.data.body.response.bol_name_id}`));
			}
		} catch (err) {
			dispatch(setNotification("Error deleting bill of lading note", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setDeleteBolNoteLoading(false));
		}
	}
}
