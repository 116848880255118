import React from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import axios from "~/variables/axios.jsx";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import _ from "lodash";
import moment from "moment";
import "formdata-polyfill";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";


// material ui icons
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Check from "@material-ui/icons/Check";
import Person from "@material-ui/icons/Person";
import Business from "@material-ui/icons/Business";
import Warning from "@material-ui/icons/Warning";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// react-icons
import { FaWarehouse, FaExchangeAlt } from "react-icons/fa";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import PasswordField from "~/components/TMS/PasswordField.jsx";
import { validatePassword } from "../../../helpers.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewCompany extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			alert: null,
			redirect: null,
			user: null,
			body: null,
			active: "company",
			// company
			c_name: "",
			c_billing_type: "Gain Share",
			c_calc_type: "% of Savings",
			c_calc_value: "",
			c_min_charge: "",
			c_min_profit_margin: "",
			passthru: "rated",
			c_max_weight: "",
			require_dims: true,
			profiles: [],
			pure_abf_bm: false,
			check_residential: false,
			enable_outbound_collect: false,
			enable_prepaid_and_charge: false,
			enable_miler: false,
			enable_claims: true,
			enable_liability: false,
			enable_total_declared_value: false,
			auto_calc_expected_delivery: true,
			tl_markup: "0.2",
			c_tl_quote: false,
			c_auto_class: false,
			np_fee_amount: 0,
			np_fee_type: "flat",
			prevent_dup_identifiers: false,
			unique_po: false,
			unique_so: false,
			unique_ref: false,
			auto_cancel_bols: false,
			bol_max_life: "",
			c_address1: "",
			c_address2: "",
			c_zip: "",
			c_city: "",
			c_state: "",
			linearFeetRules: [],
			// warehouse
			w_name: "",
			w_code: "",
			w_ctsi_code: "",
			broker_id: "",
			w_max_weight: "",
			w_auto_class: "inherit",
			default_direction_terms: "",
			w_address1: "",
			w_address2: "",
			w_zip: "",
			w_city: "",
			w_state: "",
			w_logo: "",
			// user
			username: "",
			password: "",
			confirmPassword: "",
			showConfirmPassword: false,
			rate_display: "",
			u_calc_type: "% of Savings",
			u_calc_value: "",
			u_calc_by: "truecost",
			u_min_charge: "",
			u_enable_load_api: true,
			level: "user",
			role: "",
			fName: "",
			lName: "",
			email: "",
			user_address1: "",
			user_address2: "",
			user_city: "",
			user_state: "",
			user_zip: "",
			volume_markup: "0",
			parcel_weight_limit: 0,
		};
		this.handleDatetime = this.handleDatetime.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.getImage = this.getImage.bind(this);
		this.getRates = this.getRates.bind(this);
		this.getRoles = this.getRoles.bind(this);
		this.getProfiles = this.getProfiles.bind(this);
		this.handleProfiles = this.handleProfiles.bind(this);
		this.getLinearFeetRules = this.getLinearFeetRules.bind(this);
		this.handleLinearFootRule = this.handleLinearFootRule.bind(this);
		this.createCompany = this.createCompany.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		const url = "/index.php?p=api&r=json&c=admin&m=newCompany";
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user) && !_.isEmpty(data.body)) {
				const linearFeetRules = data.body.linearFeetRules.map(prop => {
					prop.assigned = false;
					return prop;
				});
				const profiles = data.body.accessorial.map(prop => {
					prop.checked = false;
					return prop;
				});
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					profiles,
					rate_display: data.body.rates[0].id,
					linearFeetRules
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		if(name === "enable_total_declared_value" && this.state.enable_liability === true) {
			this.setState({ enable_liability: false, enable_total_declared_value: !!event.target.checked  })
		} else if(name === "enable_liability" && this.state.enable_total_declared_value === true ) {
			this.setState({ enable_total_declared_value: false, enable_liability: !!event.target.checked  })
		}else {
			this.setState({ [name]: !!event.target.checked });
		}
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	toggle = name => () => this.setState({ [name]: !this.state[name] });

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleSelect(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}

	handleUpload(files) {
		const w_logo = files[0];
		this.setState({ w_logo });
	}

	getImage(prop) {
		const src = !_.isEmpty(prop.preview) ? prop.preview : apiUrl + "/getLogo.php?logo=" + prop;
		const filename = !_.isEmpty(prop.name) ? prop.name : prop;
		const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
		return <Preview src={src} index={0} extension={extension} handleClick={() => {}} handleDelete={this.handleDeleteImage.bind(this)} />;
	}

	getRates(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getRoles(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getProfiles(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot
							}}
							checked = {prop.checked}
							onChange={e => this.handleProfiles(prop.id)}
						/>
					}
				/>,
				prop.name,
				moment.unix(prop.eff_from).format("MM/DD/YYYY"),
				moment.unix(prop.eff_to).format("MM/DD/YYYY")
			];
		});
	}

	handleProfiles(id) {
		const profiles = this.state.profiles;
		const index = profiles.findIndex(x => x.id === id);
		if (profiles[index].checked == false) {
			profiles[index].checked = true;
		} else {
			profiles[index].checked = false;
		}
		this.setState({ profiles });
	}

	getLinearFeetRules(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot
							}}
							checked={prop.assigned}
							onChange={() => this.handleLinearFootRule(prop.id)}
						/>
					}
				/>,
				prop.name,
				prop.scac,
				prop.carrier,
				<CustomInput
					formControlProps={{ fullWidth: true }}
					inputProps={{
						type: "text",
						name: "message",
						value: prop.message || "",
						onChange: e => this.handleLinearFootRule(prop.id, e),
						disabled: !prop.assigned
					}}
					white
				/>
			];
		});
	}

	handleLinearFootRule(id, e = null) {
		const linearFeetRules = this.state.linearFeetRules;

		const index = linearFeetRules.findIndex(x => x.id === id);

		if( e == null) {
			if (linearFeetRules[index].assigned == false) {
				linearFeetRules[index].assigned = true;
			} else {
				linearFeetRules[index].assigned = false;
				linearFeetRules[index].message = '';
			}

		} else {
			linearFeetRules[index].message = e.target.value;
		}
		this.setState({ linearFeetRules });
	}

	handleDeleteImage() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this image?"
					onConfirm={() => this.setState({ w_logo: null, alert: null })}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				/>
			)
		});
	}

	async createCompany() {

		const { username, password, confirmPassword } = this.state;

		if(!username) {
			this.props.setNotification("Username for new user required", { variant: "error" });
			return false;
		}
		if(!password) {
			this.props.setNotification("Password for new user required", { variant: "error" });
			return false;
		}
		if(password.length < 8) {
			this.props.setNotification("Password for new user must be at least 8 characters", { variant: "error" });
			return false;
		}
		if(username === password) {
			this.props.setNotification("Password for new user cannot match username", { variant: "error" });
			return false;
		}
		if(password.length > 0 && !validatePassword(password, username)) {
			this.props.setNotification("Password for new user does not meet character requirements", { variant: "error" });
			return false;
		}
		if(password !== confirmPassword) {
			this.props.setNotification("Please ensure both password fields match", { variant: "error" });
			return false;
		}

		const data = new FormData(); // FormData is needed to upload files

		const c_linearFeetRules = this.state.linearFeetRules;
		const assignedFeetRules = [];

		for (let i = 0; i < c_linearFeetRules.length; i++) {
			if (c_linearFeetRules[i].assigned == true) {
				const assignedRule = {
					'id': c_linearFeetRules[i].id,
					'message': c_linearFeetRules[i].message || null
				};
				assignedFeetRules.push(assignedRule);
			}
		}

		const accProfiles = this.state.profiles.filter(prop => prop.checked).map(prop => prop.id);

		// Company
		data.append("c_name", this.state.c_name);
		data.append("c_billing_type", this.state.c_billing_type);
		data.append("c_calc_type", this.state.c_calc_type);
		data.append("c_calc_value", this.state.c_calc_value);
		data.append("c_min_charge", this.state.c_min_charge);
		data.append("c_min_profit_margin", this.state.c_min_profit_margin);
		data.append("passthru", this.state.passthru);
		data.append("c_max_weight", this.state.c_max_weight);
		data.append("volume_markup", this.state.volume_markup);
		data.append("parcel_weight_limit", this.state.parcel_weight_limit);
		data.append("np_fee_amount", this.state.np_fee_amount);
		data.append("np_fee_type", this.state.np_fee_type);
		

		if(!_.isEmpty(accProfiles)) {
			data.append("profiles", !_.isEmpty(accProfiles) ? JSON.stringify(_.compact(accProfiles)) : JSON.stringify([]));
		} else {
			this.props.setNotification("At least one accessorial profile is required!", {
				variant: "error"
			});
			return false;
		}

		this.state.require_dims ? data.append("require_dims", "1") : data.append("require_dims", "0");

		if (this.state.pure_abf_bm) {
			data.append("pure_abf_bm", "1");
		}

		if (this.state.check_residential) {
			data.append("check_residential", "1");
		}

		if (this.state.enable_outbound_collect) {
			data.append("enable_outbound_collect", "1");
		}

		if (this.state.enable_prepaid_and_charge) {
			data.append("enable_prepaid_and_charge", "1");
		}

		if (this.state.enable_miler) {
			data.append("enable_miler", "1");
		}

		if (this.state.enable_claims) {
			data.append("enable_claims", "1");
		}

		if (this.state.enable_liability) {
			data.append("enable_liability", "1");
		}

		if (this.state.enable_total_declared_value) {
			data.append("enable_total_declared_value", "1");
		}

		if (this.state.auto_calc_expected_delivery) {
			data.append("auto_calc_expected_delivery", "1");
		}

		data.append("tl_markup", this.state.tl_markup);

		if (this.state.c_tl_quote) {
			data.append("c_tl_quote", "1");
		}

		if (this.state.c_auto_class) {
			data.append("c_auto_class", "1");
		}

		if (this.state.prevent_dup_identifiers) {
			data.append("prevent_dup_identifiers", "1");
		}

		if (this.state.unique_po) {
			data.append("unique_po", "1");
		}

		if (this.state.unique_so) {
			data.append("unique_so", "1");
		}

		if (this.state.unique_ref) {
			data.append("unique_ref", "1");
		}

		if (this.state.auto_cancel_bols) {
			data.append("auto_cancel_bols", "1");
		}

		data.append("bol_max_life", this.state.bol_max_life);
		data.append("c_address1", this.state.c_address1);
		data.append("c_address2", this.state.c_address2);
		data.append("c_zip", this.state.c_zip);
		data.append("c_city", this.state.c_city);
		data.append("c_state", this.state.c_state);
		data.append("c_linearFeetRules", !_.isEmpty(assignedFeetRules) ? JSON.stringify(_.compact(assignedFeetRules)) : JSON.stringify([]));

		// Warehouse
		data.append("w_name", this.state.w_name);
		data.append("w_code", this.state.w_code);
		data.append("w_ctsi_code", this.state.w_ctsi_code);
		data.append("broker_id", this.state.broker_id);
		data.append("w_max_weight", this.state.w_max_weight);
		data.append("w_auto_class", this.state.w_auto_class);
		data.append("default_direction_terms", this.state.default_direction_terms);
		data.append("w_address1", this.state.w_address1);
		data.append("w_address2", this.state.w_address2);
		data.append("w_zip", this.state.w_zip);
		data.append("w_city", this.state.w_city);
		data.append("w_state", this.state.w_state);
		data.append("w_logo", this.state.w_logo);

		// User
		data.append("username", username);
		data.append("password", password);
		data.append("rate_display", this.state.rate_display);
		data.append("u_calc_type", this.state.u_calc_type);
		data.append("u_calc_value", this.state.u_calc_value);
		data.append("u_calc_by", this.state.u_calc_by);
		data.append("u_min_charge", this.state.u_min_charge);

		if (this.state.u_enable_load_api) {
			data.append("u_enable_load_api", "1");
		}

		data.append("level", this.state.level);
		data.append("role", this.state.role);
		data.append("fName", this.state.fName);
		data.append("lName", this.state.lName);
		data.append("email", this.state.email);
		data.append("user_address1", this.state.user_address1);
		data.append("user_address2", this.state.user_address2);
		data.append("user_city", this.state.user_city);
		data.append("user_state", this.state.user_state);
		data.append("user_zip", this.state.user_zip);

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		const url = "/index.php?p=api&r=json&c=admin&m=newCompany";

		try {
			const response = await axios.post(url, data, config);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && _.isEmpty(response.data.body)) {
				this.setState({
					redirect: <Redirect to={basePath + "/admin/management/companies"} />
				});
			} else {
				this.props.setNotification("There was an error creating the company!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an exception creating the company!", {
				variant: "error"
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12} className={classes.center} style={{ margin: "10px 0px", textAlign: "center" }}>
					{this.state.active === "company" ? (
						<Button size="lg" color="warning" className={classes.marginRight}>
							<Business /> Company
						</Button>
					) : (
						<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "company" })}>
							<Business /> Company
						</Button>
					)}
					{this.state.active === "warehouse" ? (
						<Button size="lg" color="warning" className={classes.marginRight}>
							<FaWarehouse /> Warehouse
						</Button>
					) : (
						<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "warehouse" })}>
							<FaWarehouse /> Warehouse
						</Button>
					)}
					{this.state.active === "user" ? (
						<Button size="lg" color="warning" className={classes.marginRight}>
							<Person /> User
						</Button>
					) : (
						<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "user" })}>
							<Person /> User
						</Button>
					)}
					{this.state.active === "accessorials" ? (
						<Button size="lg" color="warning" className={classes.marginRight}>
							<Person /> Accessorials
						</Button>
					) : (
						<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "accessorials" })}>
							<Person /> Accessorials
						</Button>
					)}
					{this.state.active === "linearFootRules" ? (
						<Button size="lg" color="warning" className={classes.marginRight}>
							<FaExchangeAlt />
							Linear Feet Rules
						</Button>
					) : (
						<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "linearFootRules" })}>
							<FaExchangeAlt />
							Linear Feet Rules
						</Button>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{this.state.show ? (
						<Card>
							<CardBody>
								{this.state.active === "company" && (
									<GridContainer>
										<GridItem xs={12}>
											<InputLabel className={classes.label}>Name</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_name",
													value: this.state.c_name || "",
													onChange: this.handleInput("c_name")
												}}
												required
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Billing Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.c_billing_type}
													inputProps={{
														name: "c_billing_type",
														id: "c_billing_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Gain Share"
													>
														Gain Share
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Prepaid & Add"
													>
														Prepaid & Add
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Buy/Sell"
													>
														Buy/Sell
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="N/A"
													>
														N/A
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Calculation Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.c_calc_type}
													inputProps={{
														name: "c_calc_type",
														id: "c_calc_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% of Savings"
													>
														% of Savings
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% Mark Up"
													>
														% Mark Up
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="$ Mark Up"
													>
														$ Mark Up
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% Flat Charge"
													>
														% Flat Charge
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="$ Flat Charge"
													>
														$ Flat Charge
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="% Target Savings"
													>
														% Target Savings
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Calculation Value</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_calc_value",
													value: this.state.c_calc_value || "",
													onChange: this.handleInput("c_calc_value")
												}}
												helpText="This is the % in TFM's favour, or the amount added to the True Cost."
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Min Charge</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_min_charge",
													value: this.state.c_min_charge || "",
													onChange: this.handleInput("c_min_charge"),
													startAdornment: <InputAdornment position="end">$</InputAdornment>
												}}
												helpText={'Minimum mark up charge allowed (Only applies to "% Mark Up")'}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Min Profit Margin</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_min_profit_margin",
													value: this.state.c_min_profit_margin || "",
													onChange: this.handleInput("c_min_profit_margin"),
													endAdornment: <InputAdornment position="end">%</InputAdornment>
												}}
												helpText="The minimum profit margin that TFM needs to make on each shipment (Only applies to % Target Savings)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Passthru Value</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.passthru}
													inputProps={{
														name: "passthru",
														id: "passthru"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="rated"
													>
														Rated
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="benchmark"
													>
														Benchmark
													</MenuItem>
												</Select>
											</FormControl>
											<FormHelperText>Only applicable with % Target Savings, this is the field to display as the customer's LCC Rated amount when the actual rate is higher than the benchmark.</FormHelperText>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Volume Markup</InputLabel>
											<br />
											<CustomInput
												id="volume_markup"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "volume_markup",
													value: this.state.volume_markup || "",
													onChange: this.handleInput("volume_markup"),
													endAdornment: <InputAdornment position="end">%</InputAdornment>
												}}
												helpText="Markup percentage applied to volume quotes"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Max Shipment Weight</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_max_weight",
													value: this.state.c_max_weight || "",
													onChange: this.handleInput("c_max_weight"),
													placeholder: "10000",
													endAdornment: <InputAdornment position="end">lbs.</InputAdornment>
												}}
												helpText="Defaults to 10,000 lbs."
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Parcel Weight Limit</InputLabel>
											<br />
											<CustomInput
												id="parcel_weight_limit"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "parcel_weight_limit",
													value: this.state.parcel_weight_limit || "0",
													onChange: this.handleInput("parcel_weight_limit"),
													endAdornment: <InputAdornment position="end">lbs</InputAdornment>
												}}
												helpText="Set a weight limit in pounds for this company's parcel shipments. If 0, no weight restriction will apply."
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Non Program Fee</InputLabel>
											<br />
											<CustomInput
												id="np_fee_amount"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "np_fee_amount",
													value: this.state.np_fee_amount || "0",
													onChange: this.handleInput("np_fee_amount"),
													startAdornment: (this.state.np_fee_type === 'flat') ? (<InputAdornment position="end">$</InputAdornment>) : null,
													endAdornment: (this.state.np_fee_type === 'percent') ? (<InputAdornment position="end">%</InputAdornment>) : null,
												}}
												helpText="Non-Program Fee amount added to True Cost (For Display Only)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel className={classes.label}>Non Program Fee Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.np_fee_type || ""}
													inputProps={{
														id: "np_fee_type",
														name: "np_fee_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="flat"
													>
														Flat $ amount
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="percent"
													>
														% of Rated Amount
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={3} />
										<GridItem xs={12} sm={6} md={3} />
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.require_dims}
														onChange={this.handleChecked("require_dims")}
														value="require_dims"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Quick Rate Dimensions"
											/>
											<FormHelperText>Require dimensions (L x W x H) for the LCC Calculator (does not affect BoL entry).</FormHelperText>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.pure_abf_bm}
														onChange={this.handleChecked("pure_abf_bm")}
														value="pure_abf_bm"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Pure ABF Benchmark"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.check_residential}
														onChange={this.handleChecked("check_residential")}
														value="check_residential"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Check for Residential Address on BoL"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.enable_outbound_collect}
														onChange={this.handleChecked("enable_outbound_collect")}
														value="enable_outbound_collect"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Outbound/Collect"
											/>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.enable_prepaid_and_charge}
														onChange={this.handleChecked("enable_prepaid_and_charge")}
														value="enable_prepaid_and_charge"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Prepaid & Charge"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.enable_miler}
														onChange={this.handleChecked("enable_miler")}
														value="enable_miler"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Miler"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.enable_claims}
														onChange={this.handleChecked("enable_claims")}
														value="enable_claims"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Claims"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.enable_liability}
														onChange={this.handleChecked("enable_liability")}
														value="enable_liability"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}label={
													<>
														Enable Carrier Liability Support
														<WithTooltip content="Enabling this option will disable Total Declared Value if enabled">
															<InfoOutlined fontSize="small"/>
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.enable_total_declared_value}
														onChange={this.handleChecked("enable_total_declared_value")}
														value="enable_total_declared_value"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={
													<>
														Enable Total Declared Value
														<WithTooltip content="Enabling this option will disable Carrier Liability Support if enabled">
															<InfoOutlined fontSize="small"/>
														</WithTooltip>
													</>
												}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.auto_calc_expected_delivery}
														onChange={this.handleChecked("auto_calc_expected_delivery")}
														value="auto_calc_expected_delivery"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Auto-Calc Expected Delivery Date"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>TL Markup Value</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "tl_markup",
													value: this.state.tl_markup || "",
													onChange: this.handleInput("tl_markup")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.c_tl_quote}
														onChange={this.handleChecked("c_tl_quote")}
														value="c_tl_quote"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable TL Quotes (TFM Truckload API)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.c_auto_class}
														onChange={this.handleChecked("c_auto_class")}
														value="c_auto_class"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable PCF Calculator (Density Rated Freight)"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Switch
														checked={this.state.auto_cancel_bols}
														onChange={this.handleChecked("auto_cancel_bols")}
														value="auto_cancel_bols"
														classes={{
															switchBase: classes.switchBase,
															checked: classes.switchChecked
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Auto-Cancel BOLs"
											/>
											<div>
												<Fade in={this.state.auto_cancel_bols}>
													<GridContainer>
														<GridItem xs={12} sm={12} md={12}>
															<InputLabel className={classes.label}>BOL Max Life (# of days before auto-canceling)</InputLabel>
															<br />
															<CustomInput
																id="bol_max_life"
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "number",
																	name: "bol_max_life",
																	value: this.state.bol_max_life || "",
																	onChange: this.handleInput("bol_max_life"),
																	endAdornment: <InputAdornment position="end">Day(s)</InputAdornment>
																}}
															/>
														</GridItem>
													</GridContainer>
												</Fade>
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Address 1</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_address1",
													value: this.state.c_address1 || "",
													onChange: this.handleInput("c_address1")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Address 2</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_address2",
													value: this.state.c_address2 || "",
													onChange: this.handleInput("c_address2")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Zip</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_zip",
													value: this.state.c_zip || "",
													onChange: this.handleInput("c_zip")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>City</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_city",
													value: this.state.c_city || "",
													onChange: this.handleInput("c_city")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>State</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "c_state",
													value: this.state.c_state || "",
													onChange: this.handleInput("c_state")
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px"}}>
											<legend>Prevent Duplicate Identifiers</legend>
											<GridContainer>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={this.state.prevent_dup_identifiers || false}
																onChange={this.handleChecked("prevent_dup_identifiers")}
																value="prevent_dup_identifiers"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Ask to Cancel Duplicates"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={this.state.unique_po || false}
																onChange={this.handleChecked("unique_po")}
																value="unique_po"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Unique PO #"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={this.state.unique_so || false}
																onChange={this.handleChecked("unique_so")}
																value="unique_so"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Unique Order/SO #"
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<FormControlLabel
														control={
															<Switch
																checked={this.state.unique_ref || false}
																onChange={this.handleChecked("unique_ref")}
																value="unique_ref"
																classes={{
																	switchBase: classes.switchBase,
																	checked: classes.switchChecked
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Unique Reference #"
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								)}
								{this.state.active === "warehouse" && (
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<GridContainer>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Name</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_name",
															value: this.state.w_name || "",
															onChange: this.handleInput("w_name")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Warehouse Code</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_code",
															value: this.state.w_code || "",
															onChange: this.handleInput("w_code")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Rater Profile Code</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_ctsi_code",
															value: this.state.w_ctsi_code || "",
															onChange: this.handleInput("w_ctsi_code")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>TFMCentral Broker ID</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "broker_id",
															value: this.state.broker_id || "",
															onChange: this.handleInput("broker_id")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Override Max. Shipment Weight</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_max_weight",
															value: this.state.w_max_weight || "",
															onChange: this.handleInput("w_max_weight"),
															endAdornment: <InputAdornment position="end">lbs.</InputAdornment>
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Override Enable PCF Calculator</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.w_auto_class || ""}
															inputProps={{
																id: "w_auto_class",
																name: "w_auto_class"
															}}
															onChange={this.handleSelect}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enabled"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disabled"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
													<FormHelperText>
														<strong>Inherit</strong> use the value set for the Company; <strong>Enabled</strong> Explicity enabled, regardless of the value set for the Company; <strong>Disabled</strong> Explicity disabled, regardless of the value set for the Company;
													</FormHelperText>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel htmlFor="default_direction_terms" className={classes.label}>
														Default Direction/Freight Term
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.default_direction_terms || ""}
															inputProps={{
																id: "default_direction_terms",
																name: "default_direction_terms"
															}}
															onChange={this.handleSelect}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value=""
															/>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Outbound/Prepaid"
															>
																Outbound/Prepaid
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Outbound/Collect"
															>
																Outbound/Collect
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Inbound/Collect"
															>
																Inbound/Collect
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Third Party"
															>
																Third Party
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Address 1</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_address1",
															value: this.state.w_address1 || "",
															onChange: this.handleInput("w_address1")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Address 2</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_address2",
															value: this.state.w_address2 || "",
															onChange: this.handleInput("w_address2")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Zip</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_zip",
															value: this.state.w_zip || "",
															onChange: this.handleInput("w_zip")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>City</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_city",
															value: this.state.w_city || "",
															onChange: this.handleInput("w_city")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>State</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "w_state",
															value: this.state.w_state || "",
															onChange: this.handleInput("w_state")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<h5>Logo</h5>
											<GridContainer>
												<GridItem xs={12} sm={12} md={6}>
													<Dropzone handleUpload={this.handleUpload.bind(this)} />
												</GridItem>
												{!_.isEmpty(this.state.w_logo) && (
													<GridItem xs={12} sm={12} md={6}>
														<aside className={classes.thumbsContainer}>{this.getImage(this.state.w_logo)}</aside>
													</GridItem>
												)}
											</GridContainer>
										</GridItem>
									</GridContainer>
								)}
								{this.state.active === "user" && (
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<GridContainer>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Username</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "username",
															value: this.state.username || "",
															onChange: this.handleInput("username")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Password</InputLabel>
													<br />
													<PasswordField
														password={this.state.password}
														handleChange={(e) => this.handleChange(e)}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Confirm Password</InputLabel>
													<br />
													<CustomInput
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: this.state.showConfirmPassword ? "text" : "password",
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		onClick={this.toggle('showConfirmPassword')}
																		edge="end"
																	>
																	{this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
																	</IconButton>
																</InputAdornment>
															),
															name: "confirmPassword",
															value: this.state.confirmPassword || "",
															onChange: this.handleInput("confirmPassword"),
														}}
														white
													/>
												</GridItem>
												{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.rates) && (
													<GridItem xs={12} sm={6} md={4} lg={3}>
														<InputLabel className={classes.label}>Rate Display</InputLabel>
														<br />
														<FormControl fullWidth className={classes.selectFormControl}>
															<Select
																MenuProps={{
																	className: classes.selectMenu
																}}
																classes={{
																	select: classes.select + " " + classes.requiredSelect
																}}
																value={this.state.rate_display}
																inputProps={{
																	id: "rate_display",
																	name: "rate_display"
																}}
																onChange={this.handleSelect}
															>
																{this.getRates(this.state.body.rates)}
															</Select>
														</FormControl>
													</GridItem>
												)}
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Override Calculation Type</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.u_calc_type || ""}
															inputProps={{
																id: "u_calc_type",
																name: "u_calc_type"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="% of Savings"
															>
																{"% of Savings"}
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="% Mark Up"
															>
																{"% Mark Up"}
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="$ Mark Up"
															>
																{"$ Mark Up"}
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="% Flat Charge"
															>
																{"% Flat Charge"}
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="$ Flat Charge"
															>
																{"$ Flat Charge"}
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="% of Gross Savings Markup"
															>
																{"% of Gross Savings Markup"}
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Override Calculation Value</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "u_calc_value",
															value: this.state.u_calc_value || "",
															onChange: this.handleInput("u_calc_value")
														}}
														helpText="This is the value added to the true cost in addition to the company's value."
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Calculate By</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.u_calc_by || ""}
															inputProps={{
																id: "u_calc_by",
																name: "u_calc_by"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="truecost"
															>
																True Cost
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="benchmark"
															>
																Benchmark
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="rated"
															>
																Rated Amt.
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Override Min Charge</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "u_min_charge",
															value: this.state.u_min_charge || "",
															onChange: this.handleInput("u_min_charge"),
															startAdornment: <InputAdornment position="end">$</InputAdornment>
														}}
														helpText="Minimum mark up charge allowed (Only applies to '% Mark Up')."
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.u_enable_load_api}
																tabIndex={-1}
																onChange={this.handleChecked("u_enable_load_api")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Enable TTL Load Detail API"
													/>
												</GridItem>
												{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.roles) && (
													<GridItem xs={12} sm={6} md={4} lg={3}>
														<InputLabel className={classes.label}>Permission Group</InputLabel>
														<br />
														<FormControl fullWidth className={classes.selectFormControl}>
															<Select
																MenuProps={{
																	className: classes.selectMenu
																}}
																classes={{
																	select: classes.select + " " + classes.requiredSelect
																}}
																value={this.state.role || ""}
																inputProps={{
																	id: "role",
																	name: "role"
																}}
																onChange={this.handleSelect}
															>
																{this.getRoles(Object.values(this.state.body.roles))}
															</Select>
														</FormControl>
													</GridItem>
												)}
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>First Name</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "fName",
															value: this.state.fName || "",
															onChange: this.handleInput("fName")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Last Name</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "lName",
															value: this.state.lName || "",
															onChange: this.handleInput("lName")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Email</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "email",
															value: this.state.email || "",
															onChange: this.handleInput("email")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Address 1</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "user_address1",
															value: this.state.user_address1 || "",
															onChange: this.handleInput("user_address1")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Address 2</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "user_address2",
															value: this.state.user_address2 || "",
															onChange: this.handleInput("user_address2")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>Zip</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "user_zip",
															value: this.state.user_zip || "",
															onChange: this.handleInput("user_zip")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>City</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "user_city",
															value: this.state.user_city || "",
															onChange: this.handleInput("user_city")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={4} lg={3}>
													<InputLabel className={classes.label}>State</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "user_state",
															value: this.state.user_state || "",
															onChange: this.handleInput("user_state")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								)}
								{this.state.active === "accessorials" && (
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.body.accessorial) && <Table tableHead={["Assign", "Name", "Effective From", "Effective To"]} tableData={this.getProfiles(this.state.body.accessorial)} />}
										</GridItem>
									</GridContainer>
								)}
								{this.state.active === "linearFootRules" && (
									<GridContainer>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.linearFeetRules) &&
												<Table
													tableHead={["Assigned", "Name", "SCAC", "Carrier",
														<Tooltip
															title={
																<React.Fragment>
																	<Typography color="inherit">Note:</Typography>
																		Rule must be assigned to set a custom message. If a rule has a custom message set, then this message will be shown in place of the default message on the quote panel when the rule is enforced. This portion of the default message will always be shown, and does not need to be included in your custom message: <b>"Shipment hits Linear Feet exclusion rules"</b>
																</React.Fragment>
															}
															placement="bottom"
														>
															<span>
																Custom Message
																<Warning style={{ height: "16px" }}/>
															</span>
														</Tooltip>
													]}
													tableData={this.getLinearFeetRules(this.state.linearFeetRules)}
												/>
											}
										</GridItem>
									</GridContainer>
								)}
							</CardBody>
						</Card>
					) : (
						<Card>
							<CardBody>
								<GridContainer>
									<Spinner loading={this.state.loading} message="Failed to retrieve settings from the server" />
								</GridContainer>
							</CardBody>
						</Card>
					)}
				</GridItem>
				{this.state.show && (
					<GridItem xs={12} sm={12} md={12} style={{ margin: "10px 0px", textAlign: "center" }}>
						<Button size="lg" color="linkedin" onClick={this.createCompany}>
							Create Company
						</Button>
					</GridItem>
				)}
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(NewCompany));
