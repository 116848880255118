import React from "react";
import Datetime from "react-datetime";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import InputList from "~/components/TMS/InputList.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ReportScheduleRecipients extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			showRunNow: false,
			report_id: props.report_id != undefined ? props.report_id : '',
			offset_date: (!_.isEmpty(props.offset_date)) ? props.offset_date : '',
			schedule: (!_.isEmpty(props.schedule)) ? props.schedule : '',
			recipients: (!_.isEmpty(props.recipients)) ? props.recipients : [],
			ftp_site: (!_.isEmpty(props.ftp_site)) ? props.ftp_site : '',
			ftp_dir: (!_.isEmpty(props.ftp_dir)) ? props.ftp_dir : '',
			ftp_user: (!_.isEmpty(props.ftp_user)) ? props.ftp_user : '',
			ftp_pass: (!_.isEmpty(props.ftp_pass)) ? props.ftp_pass : ''
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
	}

	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps, this.props)) {
			this.setState({
				report_id: this.props.report_id,
				offset_date: this.props.offset_date,
				schedule: this.props.schedule,
				recipients: this.props.recipients,
				ftp_site: this.props.ftp_site,
				ftp_dir: this.props.ftp_dir,
				ftp_user: this.props.ftp_user,
				ftp_pass: this.props.ftp_pass
			});
		} else if(!_.isEqual(prevState, this.state)) {
			if(typeof this.props.onChange === 'function') {
				this.props.onChange({...this.state});
			}
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDatetime(name, moment) {
  		this.setState({ [name]: moment});
  	}

    handleEmailChange(key, event) {
    	let rec = [...this.state.recipients]
    	rec[key] = event.target.value;
        this.setState({ recipients: rec });
    }


    addRecipient() {
    	let rec = [...this.state.recipients]
    	rec.push('');
    	this.setState({recipients: rec});
    }

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader>
							<h5>Schedule Interval & Offset</h5>
						</CardHeader>
						<CardBody>
							<InputLabel
								htmlFor="schedule"
								className={classes.label}
							>
								Schedule Interval
							</InputLabel>
							<FormControl
								fullWidth
								className={
									classes.selectFormControl
								}
							>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select
									}}
									value={this.state.schedule}
									inputProps={{
										id: "schedule",
										name: "schedule"
									}}
									onChange={e => this.handleChange(e)}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Hourly"
									>
										Hourly
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Daily"
									>
										Daily
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Weekly"
									>
										Weekly
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Monthly"
									>
										Monthly
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Yearly"
									>
										Yearly
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Never"
									>
										Never
									</MenuItem>
								</Select>
							</FormControl>
							<InputLabel
								htmlFor="offset_date"
								className={
									classes.label
								}
							>
								Schedule Offset Date/Time
							</InputLabel>
							<br />
							<FormControl fullWidth>
								<Datetime
									value={this.state.offset_date || ""}
									dateFormat="MMMM DD, YYYY"
									timeFormat="hh:mm A"
									onChange={m => this.handleDatetime("offset_date", m)}
									className={classes.datetime}
									inputProps={{
										id: "offset_date",
										name: "offset_date"
									}}
								/>
							</FormControl>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<InputLabel className={classes.label}>
								<h5>Email</h5>
							</InputLabel>
							<InputList
								values={this.state.recipients}
								onChange={values => this.setState({ recipients: values })}
							/>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h5>FTP Settings</h5>
						</CardHeader>
						<CardBody>
							<InputLabel
								htmlFor="ftp_site"
								className={
									classes.label
								}
							>
								FTP Site
							</InputLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									id: "ftp_site",
									name: "ftp_site",
									value: this.state.ftp_site || "",
									onChange: (e) => {this.handleChange(e)}
								}}
								white
								p={5}
							/>
							<InputLabel
								htmlFor="ftp_dir"
								className={
									classes.label
								}
							>
								FTP Directory
							</InputLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									id: "ftp_dir",
									name: "ftp_dir",
									value: this.state.ftp_dir || "",
									onChange: (e) => {this.handleChange(e)}
								}}
								white
							/>
							<InputLabel
								htmlFor="ftp_user"
								className={
									classes.label
								}
							>
								FTP Username
							</InputLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									id: "ftp_user",
									name: "ftp_user",
									value: this.state.ftp_user || "",
									onChange: (e) => {this.handleChange(e)}
								}}
								white
							/>
							<InputLabel
								className={
									classes.label
								}
							>
								FTP Password
							</InputLabel>
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									id: "ftp_pass",
									name: "ftp_pass",
									value: this.state.ftp_pass || "",
									onChange: (e) => {this.handleChange(e)}
								}}
								white
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		handleMessages,
		setNotification,
	}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(ReportScheduleRecipients)));
