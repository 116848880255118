import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { NavLink } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// material ui icons
import Check from "@material-ui/icons/Check";
import FastRewind from "@material-ui/icons/FastRewind";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

import { setRateDisplayValue, loadRateDisplay, saveRateDisplay, clearState } from "../actions/RateDisplay.jsx";

class RateDisplay extends React.Component {

	async componentDidMount() {
		this.props.clearState();
		if(!_.isEmpty(this.props.match.params.id)) {
			this.props.loadRateDisplay(this.props.match.params.id);
		}
	}

	setValue = field => e => {
		this.props.setRateDisplayValue(field, e.target.value);
	}

	handleCheckbox = field => e => {
		this.props.setRateDisplayValue(field, e.target.checked);
	}

	saveRateDisplay = () => {
		this.props.saveRateDisplay();
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
					<NavLink to={basePath + "/admin/rate-displays"}>
						<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
							<FastRewind /> Back to Rate Displays
						</Button>
					</NavLink>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs={4}>
											<CustomInput
												labelText="Display Name"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "name",
													value: this.props.name || "",
													onChange: this.setValue("name"),
													disabled: !!this.props.loading,
												}}
												white
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.rated}
											onChangeCheckbox={this.handleCheckbox("rated")}
											label={"Rated Amount"}
											inputValue={this.props.rated_alias}
											onChangeInput={this.setValue("rated_alias")}
										/>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.true_cost}
											onChangeCheckbox={this.handleCheckbox("true_cost")}
											label={"True Cost"}
											inputValue={this.props.true_cost_alias}
											onChangeInput={this.setValue("true_cost_alias")}
										/>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.true_cost_minus_markup}
											onChangeCheckbox={this.handleCheckbox("true_cost_minus_markup")}
											label={"True Cost w/o User Markup"}
											inputValue={this.props.true_cost_minus_markup_alias}
											onChangeInput={this.setValue("true_cost_minus_markup_alias")}
										/>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.benchmark}
											onChangeCheckbox={this.handleCheckbox("benchmark")}
											label={"Benchmark"}
											inputValue={this.props.benchmark_alias}
											onChangeInput={this.setValue("benchmark_alias")}
										/>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.discount}
											onChangeCheckbox={this.handleCheckbox("discount")}
											label={"Discount"}
											inputValue={this.props.discount_alias}
											onChangeInput={this.setValue("discount_alias")}
										/>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.min}
											onChangeCheckbox={this.handleCheckbox("min")}
											label={"Min"}
											inputValue={this.props.min_alias}
											onChangeInput={this.setValue("min_alias")}
										/>
										<CheckboxWithInput
											loading={this.props.loading}
											classes={this.props.classes}
											checked={this.props.gross}
											onChangeCheckbox={this.handleCheckbox("gross")}
											label={"Gross"}
											inputValue={this.props.gross_alias}
											onChangeInput={this.setValue("gross_alias")}
										/>
										<GridItem xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.props.fuel}
														onChange={this.handleCheckbox("fuel")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														disabled={!!this.props.loading}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Fuel"
											/>
										</GridItem>
										<GridItem xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.props.accessorials}
														onChange={this.handleCheckbox("accessorials")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														disabled={!!this.props.loading}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Accessorials"
											/>
										</GridItem>
										<GridItem xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.props.only_show_chosen_carrier}
														onChange={this.handleCheckbox("only_show_chosen_carrier")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														disabled={!!this.props.loading}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Only Show Chosen Carrier"
											/>
										</GridItem>
										<GridItem xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.props.transit_calendar_days}
														onChange={this.handleCheckbox("transit_calendar_days")}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														disabled={!!this.props.loading}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Transit Calendar Days"
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<GridContainer>
										<GridItem xs={12}>
											<Button
												color="linkedin"
												onClick={this.saveRateDisplay}
												style={{
													marginRight: "5px"
												}}
											>
												Save Display
											</Button>
											{!!this.props.loading && <CircularProgress />}
										</GridItem>
									</GridContainer>

								</GridItem>

									<GridItem xs={12}>
										<p style={{float: "right", color:"gray"}}>Note: these rate display settings do not apply to volume quotes.</p>
									</GridItem>

							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

class CheckboxWithInput extends React.Component {
	render() {
		const classes = this.props.classes || {};
		return (
			<GridItem xs={6}>
				<GridContainer>
					<GridItem xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.props.checked}
									onChange={this.props.onChangeCheckbox}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									disabled={!!this.props.loading}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
								/>
							}
							classes={{
								label: classes.label
							}}
							label={this.props.label}
						/>
					</GridItem>
					<GridItem xs={6}>
		            	<CustomInput
							id="alias_rated"
							inputProps={{
								type: "text",
								placeholder: `${this.props.label} Alias`,
								value: this.props.inputValue || "",
								onChange: this.props.onChangeInput,
								disabled: !!this.props.loading,
							}}
							white
						/>
					</GridItem>
				</GridContainer>
			</GridItem>
		);
	}
}

const mapStateToProps = state => {
    return {
		loading: state.RateDisplay.loading,
		name: state.RateDisplay.name,
		rated: state.RateDisplay.rated,
		true_cost: state.RateDisplay.true_cost,
		true_cost_minus_markup: state.RateDisplay.true_cost_minus_markup,
		benchmark: state.RateDisplay.benchmark,
		discount: state.RateDisplay.discount,
		min: state.RateDisplay.min,
		gross: state.RateDisplay.gross,
		accessorials: state.RateDisplay.accessorials,
		fuel: state.RateDisplay.fuel,
		only_show_chosen_carrier: state.RateDisplay.only_show_chosen_carrier,
		transit_calendar_days: state.RateDisplay.transit_calendar_days,
		rated_alias: state.RateDisplay.rated_alias,
		true_cost_alias: state.RateDisplay.true_cost_alias,
		true_cost_minus_markup_alias: state.RateDisplay.true_cost_minus_markup_alias,
		benchmark_alias: state.RateDisplay.benchmark_alias,
		discount_alias: state.RateDisplay.discount_alias,
		min_alias: state.RateDisplay.min_alias,
		gross_alias: state.RateDisplay.gross_alias,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	setRateDisplayValue,
		loadRateDisplay,
		saveRateDisplay,
		clearState,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(RateDisplay)));
