import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import ArrowRight from "@material-ui/icons/ArrowRight";
import { Link } from 'react-router-dom';

const style = {
	card: {
		"&:hover": {
	    	boxShadow: "8px 4px 10px rgba(0,0,0,0.12)",
	    	filter: "brightness(0.99)",
	    },
	},
	cardTitle: {
		textAlign: "center",
		fontSize: "1.2rem",
	},
	icon: {
		float: "left",
		marginBottom: "-7px",
		marginRight: "5px",
	},
	arrowRight: {
		float: "right",
	},
};

class LinkCard extends Component {
	render() {
		const classes = this.props.classes;
		return (
			<Link to={this.props.path}>
				<Card variant="outlined" className={classes.card}>
					<CardContent style={{paddingBottom: "18px"}}>
						{React.createElement(this.props.icon, {className: classes.icon})}
						<ArrowRight className={classes.arrowRight} />
						<Typography variant="h5" noWrap className={classes.cardTitle}>
							 {this.props.title}
						</Typography>
					</CardContent>
				</Card>
			</Link>
		);
	}
}

export default withStyles(style)(LinkCard);
