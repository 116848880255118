const initialState = {
	tfmr_index_url: null,
	ttl_index_url: null,
	yat_enabled: false,
	yat_index_url: null,
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_TFMR_INDEX_URL": {
			return {...newState, tfmr_index_url: action.val};
		}
		case "SET_TTL_INDEX_URL": {
			return {...newState, ttl_index_url: action.val};
		}
		case "SET_YAT_ENABLED": {
			return {...newState, yat_enabled: action.val};
		}
		case "SET_YAT_INDEX_URL": {
			return {...newState, yat_index_url: action.val};
		}
		default:
			return state;
	}
}

