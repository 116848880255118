import React from "react";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";
import { Paper } from "@material-ui/core";

import RemittanceFilterPage from "~/views/CarrierPortal/components/RemittanceFilterPage.jsx";
import InvoiceHoldStatusFilterPage from "~/views/CarrierPortal/components/InvoiceHoldStatusFilterPage.jsx";
import AgingFilterPage from "~/views/CarrierPortal/components/AgingFilterPage.jsx";
import StandardCarrierFilterPage from "~/views/CarrierPortal/components/StandardCarrierFilterPage.jsx";

class CarrierInvoiceLandingPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mounted: false,
            show: false,
            loading: true,
            invoiceHoldStatus: "invoiceHoldStatus",

        };

        this.handleValue = this.handleValue.bind(this);

    }

    async componentDidMount() {
        this.setState({ mounted: true });
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    handleValue(name, value) {
        this.setState({
            [name]: value.target.value,
            startDate: new Date(),
            endDate: new Date(),
            checkNum: ""
        })
    }

    render() {
        const { classes } = this.props;
        return (

            <GridContainer>
                <GridItem >
                    <Paper elevation={0} style={{ paddingTop: "20px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridContainer>
                                    <GridItem>

                                        <p
                                            style={{ fontSize: "18px", fontWeight: "bold" }}
                                            className={classes.title}
                                        >
                                            Search Report Criteria
                                        </p>

                                    </GridItem>
                                </GridContainer>

                                <GridContainer style={{ paddingLeft: "10px" }}>

                                    <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "30px" }}>
                                        <InputLabel fontWeight="bold" className={classes.label} >
                                            Select Report
                                        </InputLabel>

                                        <FormControl style={{ paddingTop: "10px" }} fullWidth className={classes.selectFormControl}>
                                            <Select
                                                MenuProps={{ className: classes.selectMenu }}
                                                classes={{ select: classes.select }}
                                                value={this.state.invoiceHoldStatus}
                                                onChange={(m) => this.handleValue("invoiceHoldStatus", m)}
                                                inputProps={{
                                                    id: " invoiceHoldStatus ",
                                                    name: " InvoiceHoldStatus ",
                                                }}
                                            >
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="invoiceHoldStatus"
                                                >
                                                    Invoice Hold Status
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Aging report "
                                                >
                                                    Aging Report
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Remittance report "
                                                >
                                                    Remittance Report
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Standard carrier invoice report "
                                                >
                                                    Standard Carrier Invoice Report
                                                </MenuItem>
                                            </Select>
                                        </FormControl>

                                    </GridItem>
                                </GridContainer>

                                <GridContainer>

                                    {(this.state.invoiceHoldStatus === "invoiceHoldStatus") ?

                                        <InvoiceHoldStatusFilterPage />

                                        : (this.state.invoiceHoldStatus === "Aging report ") ?

                                            <AgingFilterPage />

                                            :
                                            (this.state.invoiceHoldStatus === "Remittance report ") ?
                                                <RemittanceFilterPage />
                                                :
                                                 <StandardCarrierFilterPage />}

                                </GridContainer>

                            </GridContainer>
                        </CardBody>
                    </Paper>

                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(CarrierInvoiceLandingPage));



