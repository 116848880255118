import React, { Component } from "react";
import GridItem from '~/components/Grid/GridItem.jsx';
import Typography from '@material-ui/core/Typography';

export default class GridText extends Component {
	render() {
		let gridItemProps = typeof this.props.gridItemProps === 'object' ? this.props.gridItemProps : {};
		if (this.props.width) {
			gridItemProps.xs = this.props.width;
		}
		let typographyProps = typeof this.props.typographyProps === 'object' ? this.props.typographyProps : {};
		typographyProps.color = "textPrimary"; //default to textPrimary
		if (this.props.color) {
			typographyProps.color = this.props.color;
		}

		//default to noWrap
		typographyProps.noWrap = this.props.noWrap === false ? false : true;

		return (
			<GridItem {...gridItemProps}>
				<Typography {...typographyProps}>{this.props.text}</Typography>
			</GridItem>
		);
	}
}