import React from "react";

import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import Person from "@material-ui/icons/Person";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class CarrierTerminalSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scac: (!_.isEmpty(this.props.scac)) ? this.props.scac : null,
			terminals: [],
			selected: null,
		};
		this.fetchTerminals = this.fetchTerminals.bind(this);
		this.getTerminals = this.getTerminals.bind(this);
		this.handleTerminal = this.handleTerminal.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		this.fetchTerminals((this.props.initTerminalCode || null));
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props.scac !== this.state.scac) {
			this.setState({
				scac: this.props.scac,
				terminals: [],
				selected: null,
			}, this.fetchTerminals);
		} else if(!_.isEqual(prevState.selected, this.state.selected)) {
			if(typeof this.props.onChange === 'function') {
				this.props.onChange(this.state.selected);
			}
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	async fetchTerminals(selectedCode) {
		this.setState({ loading: true });
		selectedCode = (typeof selectedCode !== 'string') ? null : selectedCode;
		if(!_.isEmpty(this.state.scac)) {
			const url = '/index.php?p=api&r=json&c=carrierConnect&m=getCarrierTerminals&d=' + this.state.scac;
			try {
				const response = await axios.get(url);
				const data = response.data;
				if (typeof data !== "string"){
					this.props.handleMessages(response);
					if(!_.isEmpty(data.body)) {
						this.setState({
							loading: false,
							terminals: data.body,
						}, () => {
							if(selectedCode !== null) {
								this.setState({ selected: this.getTerminalByCode(selectedCode) });
							}
						});
					}
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error loading carrier terminal data!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("An exception occurred while loading carrier terminal data!", {
					variant: "error"
				});
			}
		} else {
			this.setState({
				terminals: [],
				selected: null,
			});
		}
	}
	getTerminalByCode(code) {
		var tm = null;
		for(var i = 0; i < this.state.terminals.length; i++) {
			if(code == this.state.terminals[i].code) {
				tm = this.state.terminals[i];
				break;
			}
		}
		return tm;
	}
	handleTerminal(code) {
		this.setState({ selected: this.getTerminalByCode(code) });
	}
	getTerminals(data) {
		const { classes } = this.props;
		return data.map((terminal, key) => {
			return (
				<MenuItem
					key={"tm_sel_" + key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={terminal.code}
				>
					{terminal.stateProvince + ' - ' + terminal.city + ' | ' + terminal.code}
				</MenuItem>
			);
		});
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader>
							Select Terminal
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<GridContainer>
										<GridItem xs>
											<FormControl
												fullWidth
												className={
													classes.selectFormControl
												}
											>
												<InputLabel
													htmlFor="terminal"
													className={classes.selectLabel}
												>
													Select {this.state.scac} Terminal
												</InputLabel>
												<Select
													MenuProps={{
														className:
															classes.selectMenu
													}}
													classes={{
														select:
															classes.select +
															" " +
															classes.requiredSelect
													}}
													value={(!_.isEmpty(this.state.selected)) ? this.state.selected.code : ''}
													inputProps={{
														id: "terminal",
														name: "terminal"
													}}
													onChange={e => this.handleTerminal(e.target.value)}
												>
													{this.getTerminals(this.state.terminals)}
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
								{(!_.isEmpty(this.state.selected)) ?
										(
											<GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px'}}>
												<div>
													<p><b>Terminal Name</b></p>
													<p>{this.state.selected.terminalName}</p>
													<br />
													<p><b>Address</b></p>
													<p>{this.state.selected.address1}</p>
													{(!_.isEmpty(this.state.selected.address2)) ?
														<p>{this.state.selected.address2}</p>
													: ''}
													<br />
													<p><b>Contact</b></p>
													<p>
														<span style={{position: 'relative', top: '.25em'}}>
															<Person />
														</span>
														{": " + this.state.selected.contact.name + " - " + this.state.selected.contact.title}
													</p>
													<p><b>P:</b> {this.state.selected.contact.phone}</p>
													<p><b>F:</b> {this.state.selected.contact.fax}</p>
													<p><b>E:</b> {this.state.selected.contact.email}</p>
												</div>
											</GridItem>
										)
								: ''}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		handleMessages,
		setNotification,
	}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(CarrierTerminalSelector)));