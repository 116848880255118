import React from "react";
import { connect } from "react-redux";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import { basePath } from "~/variables/server.jsx";
import { hasPermission } from "../../redux/selectors/Admin.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class HandlingUnitQuotes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			alert: null,
			id: null,
			data: [],
			warehouses: [],
			warehouse: "",
			current: 1,
			last: 1,
		};
		this.getHandlingUnits = this.getHandlingUnits.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.deleteUnit = this.deleteUnit.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=handUnit&m=units"
			);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.units) &&
				this.state.mounted
			) {
				const body = response.data.body;
				let warehouse = { id: "" };
				if (!_.isEmpty(body.warehouses)) {
					warehouse = body.warehouses.filter(warehouse => {
						return warehouse.is_default == 1;
					})[0];
					if(_.isEmpty(warehouse)) {
						warehouse = body.warehouses[0];
					}
				}
				this.setState({
					show: true,
					loading: false,
					data: body.units.result || [],
					current: body.units.current ? body.units.current.toString() : "1",
					last: body.units.last ? body.units.last.toString() : "1",
					warehouses: body.warehouses,
					warehouse: warehouse.id
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the default handling unit catalog!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an exception loading the default handling unit catalog!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	async handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });

		if (event.target.name !== "warehouse") {
			return;
		}

		this.setState({
			show: false,
			loading: true
		});

		let warehouse = event.target.value,
			url = "/index.php?p=api&r=json&c=handUnit&m=units&d=";

		url += warehouse;

		try {
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.units)
			) {
				const body = response.data.body;
				this.setState({
					show: true,
					loading: false,
					warehouses: body.warehouses,
					data: body.units.result || [],
					current: body.units.current ? body.units.current.toString() : "1",
					last: body.units.last ? body.units.last.toString() : "1"
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the selected warehouse data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an exception loading the selected warehouse data!",
				{ variant: "error" }
			);
		}
	}
	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}
	getHandlingUnits(data) {
		return data.map((prop, key) => {
			const link = basePath + "/admin/handling-unit/edit?d=" + prop.id + "/" + this.state.warehouse;
			return [
				prop.id,
				prop.label,
				prop.type,
				prop.stack == 1 ? "Yes" : "No",
				prop.pieces,
				prop.length,
				prop.width,
				prop.height,
				prop.default == 1 ? "*" : "",
				<div className="actions-right">
					<NavLink to={link}>
						<Button
							size="sm"
							color="info"
						>
							{this.props.hasWrite ? "View/Edit" : "View"}
						</Button>
					</NavLink>
					{this.props.hasWrite && <Button
						size="sm"
						color="danger"
						onClick={() => this.handleDelete(prop.id, this.state.warehouse)}
					>
						Delete
					</Button>}
				</div>
			];
		});
	}
	getPagination() {
		const delta = 5;
		let currentPage = 1,
			pageCount = 1;

		if (!_.isEmpty(this.state.current) && this.state.current != 1) {
			currentPage = parseInt(this.state.current);
		}
		if (!_.isEmpty(this.state.last) && this.state.last != 1) {
			pageCount = parseInt(this.state.last);
		}

		let pagination = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" },
			pages = [];

		const separate = (a, b) => [
			a,
			...({
				0: [],
				1: [b],
				2: [a + 1, b]
			}[b - a] || ["...", b])
		];

		pages = Array(delta * 2 + 1)
			.fill()
			.map((_, index) => currentPage - delta + index)
			.filter(page => 0 < page && page <= pageCount)
			.flatMap((page, index, { length }) => {
				if (!index) {
					return separate(1, page);
				}
				if (index === length - 1) {
					return separate(page, pageCount);
				}
				return [page];
			});

		if (pageCount > 1 && !_.isEmpty(pages)) {
			pagination = pages.map((prop, key) => {
				let page = { text: prop };
				if (prop == currentPage) {
					page.active = true;
				} else if (prop === "...") {
					page.disabled = true;
				} else {
					page.onClick = e => this.handlePageClick(prop);
				}
				return page;
			});
		}

		if (currentPage < pageCount) {
			nextPage.onClick = e => this.handlePageClick(currentPage + 1);
		} else {
			nextPage.disabled = true;
		}

		if (currentPage - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(currentPage - 1);
		} else {
			prevPage.disabled = true;
		}

		pagination.push(nextPage);
		pagination.unshift(prevPage);

		return pagination;
	}
	async handlePageClick(page) {
		this.setState({ show: false, loading: true });
		let url = "/index.php?p=api&r=json&c=handUnit&m=units&d=" + this.state.warehouse + "/" + page + "/20";
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.body.units)
			) {
				const body = response.data.body;
				this.setState({
					show: true,
					loading: false,
					data: body.units.result || [],
					current: body.units.current ? body.units.current.toString() : "1",
					last: body.units.last ? body.units.last.toString() : "1"
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the selected page data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an exception loading the selected page data!",
				{ variant: "error" }
			);
		}
	}
	handleDelete(id, warehouse) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this unit?"
					onConfirm={() => this.deleteUnit(id, warehouse)}
					onCancel={() => this.deleteUnit()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async deleteUnit(id, warehouse) {
		this.setState({ alert: null });

		if (_.isEmpty(id) || _.isEmpty(warehouse)) {
			return;
		}

		try {
			const url = "/index.php?p=api&r=json&c=handUnit&m=delete&d=" + id + "/" + warehouse;
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if ( typeof response.data !== "string" && response.data.body) {
				let data = this.state.data.filter((prop, key) => {
					return prop.id != id;
				});
				this.setState({ data });
			} else {
				this.props.setNotification(
					"There was an error deleting the handling unit!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error deleting the handling unit!",
				{ variant: "error" }
			);
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6}>
									<GridContainer>
										{this.props.hasWrite && <GridItem xs={4}>
											<NavLink
												to={basePath + "/admin/handling-unit/new"}
											>
												<Button color="linkedin">
													New Handling Unit
												</Button>
											</NavLink>
										</GridItem>}
										<GridItem xs>
											<InputLabel htmlFor="warehouse" className={classes.label} >
												Warehouse/Facility
											</InputLabel>
											<br />
											<FormControl className={classes.selectFormControl} style={{ width: "175px" }}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{ select: classes.select }}
													value={this.state.warehouse}
													inputProps={{
														name: "warehouse",
														id: "warehouse"
													}}
													onChange={e => this.handleChange(e)}
												>
													{!_.isEmpty(this.state.warehouses) ? (
														this.getWarehouses(this.state.warehouses)
													) : (
														<MenuItem
															disabled
															classes={{ root: classes.selectMenuItem }}
														>
															No Warehouses
														</MenuItem>
													)}
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
							 	<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(this.state.data) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.data) ? (
											<Table
												tableHead={[
													"ID",
													"Label",
													"Type",
													"Stack",
													"Pieces",
													"Length",
													"Width",
													"Height",
													"Default",
													"Actions"
												]}
												tableData={this.getHandlingUnits(this.state.data)}
												customCellClasses={[classes.right]}
												customClassesForCells={[9]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={[9]}
											/>
										) : (
											<Badge color="info">No Handling Units</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve addresses from the server"
									/>
								)}
							</Grid>
							<GridContainer>
							 	<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasWrite: hasPermission("HANDLING_UNIT_WRITE")(state),
	};
}

export default connect(mapStateToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(HandlingUnitQuotes)));