import React from "react";
import Datetime from "react-datetime";
import qs from "qs";
import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardText from "~/components/Card/CardText.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewPermission extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			report: "",
			report_id: "",
			start_date: "",
			end_date: "",
			email: "",
			done: null
		}

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.runReport = this.runReport.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=reporting&m=runReport&d=" +
				this.props.match.params.id
			);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.user) &&
				this.state.mounted
			) {
				const data = response.data;
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					report_id: data.body.report_id,
					repoort: data.body.name
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, date) {
		this.setState({ [name]: date });
	}
	async runReport() {
		let data = {
			report: this.state.report,
			report_id: this.state.report_id,
			start_date: this.state.start_date.format(),
			end_date: this.state.end_date.format(),
			email: this.state.email,
		};
		try {
			let url = "/index.php?p=api&c=reporting&m=runReport&d=" + this.state.id;
			const response = await axios.post(url, qs.stringify(data));
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({ done: true });
			} else {
				this.props.setNotification(
					"There was an error running the report!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error running the report!",
				{
					variant: "error"
				}
			);
		}
	}
	render() {
		const { classes } = this.props;
		if(this.state.done) {
			let redirectTo = basePath + "/admin/manifest-reports";
			return <Redirect to={redirectTo} />;
		}
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.name) && (
							<CardHeader color="tfmOrange" text>
								<CardText color="tfmOrange">
									<h4 className={classes.cardTitle}>
										{this.state.body.name}
									</h4>
								</CardText>
							</CardHeader>
						)}
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel
										className={classes.label}
									>
										Start Date
									</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											value={
												this.state
													.start_date || ""
											}
											timeFormat={false}
											onChange={m =>
												this.handleDatetime(
													"start_date",
													m
												)
											}
											className={classes.datetime}
											inputProps={{
												id: "start_date",
												name: "start_date"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel
										className={classes.label}
									>
										End Date
									</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											value={
												this.state.end_date ||
												""
											}
											timeFormat={false}
											onChange={m =>
												this.handleDatetime(
													"end_date",
													m
												)
											}
											className={classes.datetime}
											inputProps={{
												id: "end_date",
												name: "end_date"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Email</InputLabel>
		                            <br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "email",
											value: this.state.email || "",
											onChange: this.handleInput(
												"email"
											)
										}}
										helpText="Separate multiple addresses with commas."
										white
									/>
								</GridItem>
								<GridItem xs>
									<Button
										color="linkedin"
										style={{
											marginRight: "5px"
										}}
										onClick={this.runReport}
									>
										Submit
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(NewPermission));
