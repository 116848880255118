import React from "react";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Search from "@material-ui/icons/Search";
import ClearAll from "@material-ui/icons/ClearAll";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

class InvoiceHoldStatusFilterPage extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            mounted: false,
            show: false,
            loading: true,
            startDate: new Date(),
            endDate: new Date(),

        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

    }


    async componentDidMount() {
        this.setState({ mounted: true });

    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }



    handleDateChange = (name, moment) => {
        this.setState(
            {
                [name]: moment
            }
        )


    };


    handleSearch = (event) => {

        const start_date = this.state.startDate ;
        const end_date = this.state.endDate;



        if (start_date == null || end_date == null) {
            this.props.setNotification("date must be provided", { variant: "warning" });
            event.preventDefault();
            return false;
        }

        else if (!(moment(start_date, 'MM-DD-YYYY').isValid()) || !(moment(end_date, 'MM-DD-YYYY').isValid())) {
            this.props.setNotification("Invalid date, The date format is MM/DD/YYYY!", { variant: "warning" });
            event.preventDefault();
            return false;

        }

        else if (start_date > end_date) {
            this.props.setNotification("The start date must be less than the end date!", { variant: "warning" });
            event.preventDefault();
            return false;

        }
    }

    async clearFilters() {
        this.setState({
            startDate: new Date(),
            endDate: new Date(),
        })
    }


    render() {
        const { classes } = this.props;
        return (

            <GridContainer style={{ paddingLeft: "20px" }}>
                <GridContainer style={{ paddingLeft: "20px" }}>
                    <GridItem xs={4} sm={12} md={4} style={{ paddingTop: "50px" }}>

                        <InputLabel fontWeight="bold" className={classes.label} >
                            Select Invoice Date Range
                        </InputLabel>


                    </GridItem>

                    <GridContainer style={{ paddingTop: "10px", paddingLeft: "15px"}}>

                        <GridItem >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="none"
                                    id="date-picker-inline"
                                    label="From"
                                    placeholder = "MM-DD-YYYY"
                                    value={this.state.startDate}
                                    onChange={m => this.handleDateChange("startDate", m)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: 14,

                                        }
                                    }}
                                     style={{width:"300px"}}
                                />

                            </MuiPickersUtilsProvider>

                        </GridItem>

                        <GridItem >
                            <MuiPickersUtilsProvider   style={{width:"100px", height:"44px" ,}} utils={DateFnsUtils}>

                                <KeyboardDatePicker
                                     required
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="none"
                                    id="date-picker-inline"
                                    label="To"
                                    placeholder = "MM-DD-YYYY"
                                    value={this.state.endDate}
                                    onChange={m => this.handleDateChange("endDate", m)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: 14,

                                        }
                                    }}
                                     style={{width:"300px"}}
                                />

                            </MuiPickersUtilsProvider>
                        </GridItem>

                    </GridContainer>
                </GridContainer>

                <GridContainer style={{ paddingTop: "50px", paddingLeft: "20px" }}>
                    <GridItem>
                        <Button size="md" color="white" onClick={this.clearFilters}>  <ClearAll /> Clear </Button>
                    </GridItem>

                    <GridItem>
                        <Link to={{
                            pathname: basePath + "/admin/carrier-report-invoice-status/search",

                            state: {
                                startDate: this.state.startDate,
                                endDate: this.state.endDate
                            }
                        }} >
                            <Button size="md" color="linkedin" onClick={this.handleSearch}>  <Search /> Search </Button>
                        </Link>
                    </GridItem>
                </GridContainer>

            </GridContainer>


        )
    }
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(InvoiceHoldStatusFilterPage)));