import React from "react";
import qs from "qs";
import { connect } from "react-redux";

import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import { userIsAdmin } from "../../../redux/selectors/Admin.jsx";
import { searchObject } from "../../../helpers.jsx";
import RateResults from "../../RateResults/containers/RateResults.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class LccQuotes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			quotes: null, // search results
			rateDetailModal: false,
			selectedQid: null, // the qid selected in quote search results
			selectedQuote: null, // the quote fetched via ajax using selectedQid
			selectedRateDetailKey: null, // selected key in selectedQuote carriers
			quote_id: null, // search params
			shipper: "", // search params
			consignee: "", // search params
			total_weight: "" // search params
		};
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getHeaders = this.getHeaders.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getRequestDetails = this.getRequestDetails.bind(this);
		this.getUnits = this.getUnits.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.handleNewSearch = this.handleNewSearch.bind(this);
		this.handleQuoteSelected = this.handleQuoteSelected.bind(this);
		this.handleDetailSelected = this.handleDetailSelected.bind(this);
		this.search = this.search.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=auditing&m=lccAudit");
			if (typeof response.data !== "string" && response.data.body && response.data.user && this.state.mounted) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					body: response.data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleQuoteSelected(selectedQid) {
		this.setState({
			selectedQid: selectedQid,
			selectedQuote: null },
		this.search);
	}
	handleDetailSelected(selectedRateDetailKey) {
		this.setState({ selectedRateDetailKey }, () => this.handleModalOpen('rateDetailModal'));
	}
	handleModalOpen(modal) {
		this.setState({
			[modal]: true
		});
	}
	handleModalClose(modal) {
		this.setState({
			selectedRateDetailKey: null,
			[modal]: false
		});
	}
	handleNewSearch() {
		this.setState({
			selectedQid: null,
			selectedQuote: null,
		}, this.search);
	}
	async search() {
		this.setState({show: false, loading: true, selectedRateDetailKey: null});
		const { quote_id, shipper, consignee, total_weight } = this.state;
		if(_.isEmpty(quote_id) && (_.isEmpty(shipper) || _.isEmpty(consignee))) {
			this.setState({ loading: false });
			this.props.setNotification("You must search by either a Quote ID or both a shipper and consignee zip code.", {
				variant: "error"
			});
			return;
		}
		const data = (!_.isEmpty(quote_id) || this.state.selectedQid !== null)
			? { quote: (this.state.selectedQid !== null) ? this.state.selectedQid : quote_id.trim() }
			: { shipper: shipper.trim(), consignee: consignee.trim(), weight: total_weight.trim() };
		const url = '/index.php?p=api&r=json&c=auditing&m=findQuote';
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string") {
				this.props.handleMessages(response);
				if(this.state.selectedQid === null) {
					this.setState({
						show: true,
						loading: false,
						quotes: (!_.isEmpty(response.data.body)) ? response.data.body : [],
					});
				} else { // if a specific qid was selected from existing results, need to add it's carriers to that quote in state
					if(!_.isEmpty(response.data.body)) {
						this.setState({ selectedQuote: response.data.body[0] });
					} else {
						this.setState({ loading: false });
						this.props.setNotification("An error has occurred! The details could not be found for the selected quote.", {
							variant: "error"
						});
					}
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error searching for quotes!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred searching for quotes!", {
				variant: "error"
			});
		}
	}

	isVolume = () => {
		return typeof this.state.selectedQuote === "object" &&
			   this.state.selectedQuote !== null &&
			   typeof this.state.selectedQuote.shipment === "object" &&
			   this.state.selectedQuote.shipment !== null &&
			   typeof this.state.selectedQuote.shipment.general === "object" &&
			   this.state.selectedQuote.shipment.general !== null &&
			   !!+this.state.selectedQuote.shipment.general.volume;
	}

	renderRateResults = (quote) => {
		let carriers = searchObject(quote, "carriers.carriers");
		let tfmr_internal_id = searchObject(quote, "carriers.tfmr_internal_id", false);
		let distance = searchObject(quote, "carriers.distance", 0);
		if (!carriers) {
			return null;
		}

		return (
			<RateResults
               carriers={carriers}
               tfmrId={+tfmr_internal_id}
			   distance={distance}
               volume={this.isVolume()}
               showDetails={true}
               selectedCarrierScac={this.state.selected}
               classes={this.props.classes}
           />
		);
	}

    getRequestDetails(quote) {
    	const { classes } = this.props;
    	return (
    		<GridContainer>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Paper
						className={classes.infoPaper + " " + classes.left}
						elevation={1}
					>
						<h5>{"QID-" + quote.qid}</h5>
						<p>
							{"Origin/Destination: "}
							<b>{quote.shipment.general.shipper + " - " + quote.shipment.general.consignee}</b>
						</p>
						<p>
							{"Direction/Terms: "}
							<b>{quote.shipment.general.shipment_type}</b>
						</p>
						{!_.isEmpty(quote.shipment.accessorials) && (
							<span>
								<p>
									{"Requested Accessorials: "}
								</p>
								<ul>
									{(() => {
										return quote.shipment.accessorials.map((acc, key) => {
											return (<li key={"req_acc_" + key}>{acc}</li>);
										});
									})()}
								</ul>
							</span>
						)}
					</Paper>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Paper
						className={classes.infoPaper + " " + classes.left}
						elevation={1}
					>
						<p>
							{"Total Weight: "}
							<b>{parseFloat(quote.shipment.general.total_weight).toFixed(2) + " lbs."}</b>
						</p>
						<p>
							{"Total Cubic Feet: "}
							<b>{parseFloat(quote.shipment.general.total_cube).toFixed(2) + " lbs."}</b>
						</p>
						<p>
							{"Total PCF: "}
							<b>{parseFloat(quote.shipment.general.total_pcf).toFixed(2) + " ft. "}<sup>{3}</sup></b>
						</p>
						<p>
							{"Linear Feet: "}
							<b>{parseFloat(quote.shipment.general.linear_feet).toFixed(2) + " ft."}</b>
						</p>
						{quote.shipment.general.volume && quote.shipment.general.shipmentTotalLength && (
							<p>
								{"Shipment Total Length: "}
								<b>{quote.shipment.general.shipmentTotalLength}</b>
							</p>
						)}
					</Paper>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<Table
						hover
						tableHead={["Pieces", "Type", "Stackable", "Length", "Width", "Height"]}
						tableData={this.getUnits(quote.shipment.units)}
					/>
				</GridItem>
			</GridContainer>
		);
    }
    getUnits(units) {
    	const { classes } = this.props;
    	let rows = [];
    	for(var i = 0; i < units.length; i++) {
    		rows.push([
    			units[i].num_of,
    			units[i].type,
    			units[i].stack,
				(parseFloat(units[i].length) * 12).toFixed(2),
				(parseFloat(units[i].width) * 12).toFixed(2),
				(parseFloat(units[i].height) * 12).toFixed(2),
    		]);
    		rows.push(
    			{
    				purchase: true,
    				colspan: 3,
    				col: {
    					colspan: 3,
    					text: (
    						<Table
								tableHead={["NMFC", "Class", "Weight"]}
								tableData={this.getProducts(units[i].products)}
							/>
						),
    				},
    			}
    		);
    	}
    	return rows;
    }
    getProducts(products) {
    	const { classes } = this.props;
    	return products.map((prod, key) => {
    		return [
    			prod.nmfc,
    			prod.class,
    			prod.weight,
    		];
    	});
    }
	getHeaders() {
		return ['QID', 'Shipper', 'Consignee', 'Weight', 'Warehouse', 'User', 'Timestamp', ''];
	}
	getColumns(quotes) {
		return quotes.map((data, key) => {

			let quoteData = [
				data.qid,
				data.shipper,
				data.consignee,
				!data.shipment ? '' : data.shipment.general.total_weight, // Handle edge cases (see below)
				data.warehouse,
				data.user,
				data.date,
			];

			// Handles edge cases where older quotes are missing shipment data so page doesn't crash.
			if(!data.shipment) {
				quoteData.push(
					"Details Unavailable"
				)
			} else {
				quoteData.push(
					<Button
						size={"sm"}
						color={"info"}
						onClick={() => this.handleQuoteSelected(data.qid)}
					>
						Details
					</Button>
				);
			}

			return quoteData;

		});
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<h5>Least Cost Carrier Auditing</h5>
									<GridContainer>
										<GridItem xs={12} sm={6} md={4}>
											<InputLabel className={classes.label}>QID</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "quote_id",
													value: this.state.quote_id || "",
													onChange: this.handleInput("quote_id")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4}>
											<InputLabel className={classes.label}>Shipper</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "shipper",
													value: this.state.shipper || "",
													onChange: this.handleInput("shipper")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4}>
											<InputLabel className={classes.label}>Consignee</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "consignee",
													value: this.state.consignee || "",
													onChange: this.handleInput("consignee")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4}>
											<InputLabel className={classes.label}>Total Weight</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "total_weight",
													value: this.state.total_weight || "",
													onChange: this.handleInput("total_weight"),
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12}>
											<Button
												color="linkedin"
												style={{
													marginRight: "5px"
												}}
												onClick={this.handleNewSearch}
											>
												Search
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
					{(() => {
						if(this.state.selectedQuote !== null) {
							return (
								<Card>
									<CardHeader>
										<Button
											size={"sm"}
											color={"linkedin"}
											onClick={() => this.handleQuoteSelected(null)}
										>
											Back to Quotes
										</Button>
									</CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												{this.getRequestDetails(this.state.selectedQuote)}
											</GridItem>
					                		<GridItem xs={12} sm={12} md={12} lg={12}>
							                	{this.renderRateResults(this.state.selectedQuote)}
							                </GridItem>
							            </GridContainer>
									</CardBody>
								</Card>
							);
					 	} else if(this.state.quotes !== null) {
					 		return (
								<Card>
									<CardBody>
										<Grid container>
											{this.state.show ? (
												<Grid item xs={12} sm={12} md={12} lg={12} className={!_.isEmpty(this.state.quotes) ? classes.left : classes.center}>
													{!_.isEmpty(this.state.quotes) ? <Table tableHead={this.getHeaders()} tableData={this.getColumns(this.state.quotes)} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} /> : <Badge color="info">No Results Found</Badge>}
												</Grid>
											) : (
												<Spinner loading={this.state.loading} message="Failed to retrieve users from the server" />
											)}
										</Grid>
									</CardBody>
								</Card>
							);
						} else {
							return '';
						}
					})()}
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
        userIsAdmin: userIsAdmin(state),
    };
}

export default connect(mapStateToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(LccQuotes)));