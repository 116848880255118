import axios from "~/variables/axios.jsx";
import { handleMessages, setNotification } from './notifications.jsx';

export const fetchUserList = (roles = [], companyId = null) => {
	return async dispatch => {
		let userList = [];
		let roleUri = roles.join('&roles[]=');
		if(!roleUri.length) {
			return [];
		}
		try {
			const url = `index.php?p=api&r=json&c=admin&m=getUserList&roles[]=${roleUri}&companyId=${companyId ? companyId : ""}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response.data && response.data.body) {
				userList = response.data.body;
			}
		} catch (error) {
			dispatch(setNotification("Unknown error loading user list", {variant: "error"}));
			console.error(error);
		}
		return userList;
	};
}
