import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import allActions from '../actions/FilterQueryModal.jsx';

const QueryFilterNameSelect = ({ clearQueryText, setFilterOptions }) => {
    const {
        filterHeaders,
        filterName
    } = useSelector(state => ({ ...state.FilterQueryModal }));
    const [headers, setHeaders] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(allActions.updateFilterNameValue(''));
        }
    },[]);
    
    useEffect(() => {
        setHeadersObj()
        dispatch(allActions.setHeaderObj(headers));
        dispatch(allActions.updateFilterNameValue(headers[0]));
        setName(headers[0]);
    },[headers]);

    const getVal = () => {
        return filterName.text
    }
    
    const setHeadersObj = () => {
        filterHeaders.forEach((item, i) => (
            item.data ?
                setHeaders(state => [...state, {
                    text: item.headerDisplayName,
                    accessor: item.accessor,
                    field: item.data
                }]) 
            : null
        ));
    }

    const handleChange = (e) => {
        clearQueryText();
        setName(e.target.value);
        dispatch(allActions.updateFilterNameValue(e.target.value));
        setFilterOptions(e.target.value.text)
    }

    return (
        <>
            <FormControl style={{ width: "100%" }}>
                <Select
                    style={{ width: "inherit" }}
                    open={open}
                    renderValue={getVal}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    value={name || ""}
                    onChange={handleChange}
                >
                    {headers.map((child, i) => (
                        <MenuItem
                            key={i}
                            value={child}
                        >
                            {child.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

export default QueryFilterNameSelect;