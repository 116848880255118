import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { basePath } from '../../../variables/server.jsx';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';
import { hasPermission } from '~/redux/selectors/Admin.jsx';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import ReactTable from '~/components/ReactTable/ReactTable.jsx';
import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import LevelSelect from "../../../components/ImportanceLevel/containers/ImportanceLevelSelect.jsx";
import InternationalLoadBoardFilters from './InternationalLoadBoardFilters.jsx';
import InternationalNotesModal from '../../InternationalNotesModal/containers/InternationalNotesModal.jsx';
import HistoryModal from "../../../components/HistoryModal/HistoryModal.jsx";
import { getIntlNotes } from "../../InternationalNotesModal/actions/InternationalNotes.jsx";
import { loadLevelsIfEmpty, updateIntlLevel } from "../../../components/ImportanceLevel/actions/ImportanceLevel.jsx";

import {
	saveLoadBoardListColumns,
	setLoadBoardDefaultColumns,
	applyLoadBoardCustomColumns,
	exportToCSV,
	getLoadBoardFilterOptions,
	getShipmentNoteCount,
	updateTransitStatus,
	setShipments,
	getShipmentHistory,
} from '../actions/InternationalLoadBoard.jsx';

class InternationalLoadBoardList extends Component {

	state = {
		update: false,
		historyModal: false
	}

	columns = () => {
		let columns = [
			{
				Header: "Warehouse",
				accessor: "warehouse",
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Import/Export",
				accessor: "direction",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "Ocean/Air",
				accessor: "shipping_mode",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "Container Load",
				accessor: "container_load",
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Master Shipment ID",
				Cell: this.shipmentIdCell,
				accessor: "id",
				width: 175,
				type: Number,
				colDisabled: false
			},
			{
				Header: "Shipper Name",
				accessor: "shipper_name",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipper Address",
				accessor: "shipper_addr1",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipper City",
				accessor: "shipper_city",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipper State",
				accessor: "shipper_state",
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipper Zip",
				accessor: "shipper_zip",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipper Country",
				accessor: "shipper_country",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Consignee Name",
				accessor: "consignee_name",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Consignee Address",
				accessor: "consignee_addr1",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Consignee City",
				accessor: "consignee_city",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Consignee State",
				accessor: "consignee_state",
				width: 180,
				type: String,
				colDisabled: false
			},
			{
				Header: "Consignee Zip",
				accessor: "consignee_zip",
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Consignee Country",
				accessor: "consignee_country",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "PO #",
				accessor: "po_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "SO #",
				accessor: "so_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "ISF/ITN #",
				accessor: "isf_num", 
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Ref #",
				accessor: "ref_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Shipment Date",
				accessor: "create_time",
				width: 175,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Status",
				accessor: "status",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "Transit Status",
				Cell: this.transitStatusCell,
				accessor: "transit_status",
				width: 190,
				type: String,
				colDisabled: false
			},
			{
				Header: "Transit Sub Status",
				Cell: this.transitStatusCell,
				accessor: "transit_sub_status",
				width: 190,
				type: String,
				colDisabled: false
			},
			{
				Header: "Carrier",
				accessor: "carrier",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Inco Term",
				accessor: "inco_term",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "Named Place",
				accessor: "named_place",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Master Bill #",
				accessor: "master_bill_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "House Bill #",
				accessor: "house_bill_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Airway Bill #",
				accessor: "airway_bill_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Customs Entry #",
				accessor: "customs_entry_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Origin Airport/Port",
				accessor: "origin_port",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Destination Airport/Port",
				accessor: "dest_port",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Booking Number",
				accessor: "booking_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Container #",
				accessor: "container_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Container Size",
				accessor: "container_size",
				width: 140,
				type: String,
				colDisabled: false
			},
			{
				Header: "Vessel/Airline Name",
				accessor: "vehicle_name",
				width: 300,
				type: String,
				colDisabled: false
			},
			{
				Header: "Voyage/Flight Number",
				accessor: "vehicle_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Seal",
				accessor: "seal_num",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Pickup Date/Cargo Ready Date",
				accessor: "cargo_ready_date",
				width: 245,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Actual Pickup Date",
				accessor: "actual_pu_date",
				width: 165,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Document Cutoff At Origin Date",
				accessor: "doc_cutoff_origin_date",
				width: 250,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Cargo Cutoff At Origin Date",
				accessor: "cargo_cutoff_origin_date",
				width: 245,
				type: Date,
				colDisabled: false
			},
			{
				Header: "ETD",
				accessor: "etd_depart_origin_date",
				width: 125,
				type: Date,
				colDisabled: false
			},
			{
				Header: "COB",
				accessor: "cob_depart_origin_date",
				width: 125,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Expected Arrival To Port Of Discharge",
				accessor: "actual_receipt_origin_date",
				width: 300,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Expected Delivery to final w/a/p at destination",
				accessor: "eta_arrival_dest_date",
				width: 340,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Actual arrival at final w/a/p at destination",
				accessor: "actual_arrival_dest_date",
				width: 312,
				type: Date,
				colDisabled: false
			},
			{
				Header: "ETA Door",
				accessor: "eta_door_date",
				width: 125,
				type: Date,
				colDisabled: false
			},
			{
				Header: "Actual Door Delivery",
				accessor: "actual_door_date",
				width: 175,
				type: Date,
				colDisabled: false
			},
			{
				Header: "HS Code",
				accessor: "hs_code",
				width: 200,
				type: String,
				colDisabled: false
			},
			{
				Header: "Total Pallet Count",
				accessor: "total_pieces",
				width: 160,
				type: String,
				colDisabled: false
			},
			{
				Header: "Total Overall Piece Qty",
				accessor: "total_overall_piece_qty",
				width: 190,
				type: String,
				colDisabled: false
			},
			{
				Header: "Total Weight",
				Cell: this.totalWeightCell,
				accessor: "total_weight",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "UOM",
				accessor: "uom_option",
				width: 125,
				type: String,
				colDisabled: false
			},
			{
				Header: "Cubic Meters",
				accessor: "cubic_meters",
				width: 140,
				type: String,
				colDisabled: false
			},
			{
				Header: "Actual Pallet Count",
				accessor: "post_pallet_count",
				width: 175,
				type: String,
				colDisabled: false
			},
			{
				Header: "Actual Total Weight",
				accessor: "post_total_weight",
				width: 170,
				type: String,
				colDisabled: false
			},
			{
				Header: "Importance Level",
				Cell: this.importanceLevelCell,
				accessor: "intl_ship_level_id",
				width: 170,
				type: String,
				colDisabled: !this.props.userHasBolLevelPermission,
			},
			{
				Header: "Notes",
				Cell: this.notesCell,
				accessor: "ship_notes",
				width: 125,
				type: String,
				colDisabled: false,
				sortable: false,
			},
			{
				Header: "History",
				Cell: this.historyCell,
				accessor: "history",
				width: 125,
				type: String,
				colDisabled: false,
				sortable: false,
			},
			{
				Header: "Rate",
				accessor: "intl_carrier_rate",
				width: 170,
				type: Number,
				colDisabled: false,
				sortable: false,
			},
			{
				Header: "Duties",
				accessor: "intl_carrier_duties",
				width: 170,
				type: Number,
				colDisabled: false,
				sortable: false,
			},
			{
				Header: "Air Chargeable Weight",
				accessor: "post_air_weight",
				Cell: (rowInfo) => {
					if(rowInfo.row.original.post_air_weight) {
						return rowInfo.row.original.post_air_weight + " " + rowInfo.row.original.post_air_weight_type;
					}
				},
				width: 125,
				type: String,
				colDisabled: false
			},
		];

		columns.forEach((e, i) => {
			if(e.colDisabled) {
				columns.splice(i, 1)
			}
		})

		if(_.isEmpty(this.props.loadBoardDefaultColumns)) {
			this.props.setLoadBoardDefaultColumns(columns);
			this.props.getLoadBoardFilterOptions(columns);
		}
		if(this.props.userHasBolLevelPermission){
			this.props.loadLevelsIfEmpty();
		}
		if(_.isEmpty(this.props.loadBoardCustomColumns)) {
			return columns;
		}else {
			let customCol = [];
			this.props.loadBoardCustomColumns.forEach((v) => {
				const disabled = v.colDisabled === "false" ? false : (!v.colDisabled ? false : true);
				columns.forEach((e) => {
					if(!customCol.includes(e) && (v.accessor == e.accessor) && !disabled) {
						customCol.push(e)
						return;
					}
				})
			})
			return customCol;
		}
	}

	updateCols = () => {
		this.setState({update: !!this.state.update})
	}

	shipmentIdCell = (rowInfo) => {
		return <Link to={`${basePath}/admin/intl/${rowInfo.row.original.id}`}>{rowInfo.row.original.id}</Link>
	}

	onNoteClick = async (id, hasUnread) => {
		await this.props.getIntlNotes(id);
		if(hasUnread) {
			this.props.getShipmentNoteCount();
		}
	}

	notesCell = (rowInfo) => {
		let noteCount = 0;
		let hasUnreadNotes = false;

		if(!this.props.notesLoading) {
			noteCount = this.props.shipmentNoteCounts[rowInfo.row.original.id].noteCount;
			hasUnreadNotes = this.props.shipmentNoteCounts[rowInfo.row.original.id].unreadNotes > 0 ? true : false;
		}

		const noteDisplay = noteCount > 0 ? `Notes (${noteCount})` : "Notes";

		return (
			<>
				{(this.props.notesLoading || this.props.loading) && (
					<CircularProgress size={8} />
				)}
				{(!this.props.notesLoading && !this.props.loading) && (
					<a href="#" style={{color: hasUnreadNotes ? "red" : ""}} onClick={(e) => {
						e.preventDefault();
						this.onNoteClick(rowInfo.row.original.id, hasUnreadNotes);
					}}>{noteDisplay}</a>
				)}
			</>
		)
	}

	totalWeightCell = (rowInfo) => {
		if(rowInfo.row.original.total_weight) {
			const uomType = rowInfo.row.original.uom_option == "imperial" ? "lbs" : "kg";
			return <div>{rowInfo.row.original.total_weight} {uomType}</div>
		}
		return "";
	}

	setTransitStatus = async (status, intlId) => {
		this.props.updateTransitStatus(status.value, status.name, intlId);
	}

	historyCell = (rowInfo) => {
		return (
			<a href="#" onClick={(e) => {
				e.preventDefault();
				this.onHistoryClick(rowInfo.row.original.id);
			}}>History</a>
		)
	}

	onHistoryClick = async (id) => {
		await this.props.getShipmentHistory(id);
		this.handleModal("historyModal", true)
	}

	handleModal(modal, state) {
        this.setState({[modal]: state });
    }

	transitStatusCell = (rowInfo) => {
		const type = rowInfo.column.id;
		const parentStatus = rowInfo.row.original.transit_status;
		
		return (
			<FormControl fullWidth>
				<Select
					value={rowInfo.row.original[type] || ""}
					onChange={(e) => this.setTransitStatus(e.target, rowInfo.row.original.id)}
					name={type}
				>
					<MenuItem
						key={0}
						value={""}
					>
						No Status
					</MenuItem>
					{this.props.transitStatusOptions.map((option, index) => {
						if(type == "transit_sub_status") {
							if(option.parent && option.parent == parentStatus) {
								return (
									<MenuItem
										key={index++}
										value={option.status}
									>
										{option.description}
									</MenuItem>
								);
							}
						} else {
							if(!option.parent) {
								return (
									<MenuItem
										key={index++}
										value={option.status}
									>
										{option.description}
									</MenuItem>
								);
							}
						}
					})}
				</Select>
			</FormControl>
		);
	}

	importanceLevelCell = (rowInfo) => {
		return (
			<LevelSelect
				levelId={rowInfo.row.original.intl_ship_level_id}
				onSelect={(level) => this.updateShipmentLevel(level, rowInfo.row.original)}
			/>
		);
	}

	async updateShipmentLevel(level, row) {
        let newLevelId = +level.id;
		if(+newLevelId == +row.intl_ship_level_id) newLevelId--;

		const result = await this.props.updateIntlLevel(row.id, newLevelId)
		if(result) {
			let shipments = [...this.props.shipments];
			const idx = shipments.findIndex(x => x.id === row.id);
			shipments[idx].intl_ship_level_id = newLevelId;
			this.props.setShipments(shipments)
		}
	}

	export = async () => {
		if(_.isEmpty(this.props.loadBoardCustomColumns)) {
			await this.props.saveLoadBoardListColumns(this.props.loadBoardDefaultColumns);
		}
		this.props.exportToCSV();
	}

	render() {
		return(
			<GridContainer>
				{this.props.mounted && (
					<>
						<GridItem xs={12} sm={12} md={12}>
							<InternationalLoadBoardFilters
								search={this.props.search}
								export={this.export}
							/>
						</GridItem>
					</>
				)}
				<GridItem xs={12} sm={12} md={12}>
					<ColumnListLayoutModal
						updateCols={this.updateCols}
						loading={this.props.loading}
						saveCustomColumns={(items) => this.props.saveLoadBoardListColumns(items)}
						applyCustomColumns={(items) => this.props.applyLoadBoardCustomColumns(items)}
						fetchColumns={() => {}}
						listColumns={this.props.loadBoardDefaultColumns}
						customListColumns={this.props.loadBoardCustomColumns}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Paper>
						<GridItem xs={12} sm={12} md={12}>
							<ReactTable
								loading={this.props.loading}
								disabled={this.props.loading}
								columns={this.columns()}
								data={this.props.shipments}
								totalRows={this.props.totalRecords}
								leftPaginationLabelFormat={({ from, to, count }) => `Total Records: ${this.props.totalRecords}`}
								rightPaginationLabelFormat={({ from, to, count }) => `Showing ${from}–${to}`}
								pageSize={this.props.pageSize}
								pageNumber={this.props.pageNum}
								setPageNumber={this.props.setPage}
								visibleRows={20}
								overscan={10}
								enableSorting
								enableResizing
								defaultColumn={{
									align: 'left'
								}}
							/>
						</GridItem>
					</Paper>
				</GridItem>
				<InternationalNotesModal />
				<HistoryModal
                    title={"Int'l Shipment History"}
                    open={this.state.historyModal}
                    onClose={() => this.handleModal("historyModal", false)}
                    history={this.props.history}
                    getHistory={() => this.props.history}
                    columns={["Date", "User", "Action", "Message"]}
                />
			</GridContainer>
		)
	}
}

const mapStateToProps = state => {
	return {
		shipments: state.InternationalLoadBoard.shipments,
		pageSize: state.InternationalLoadBoard.pageSize,
		pageNum: state.InternationalLoadBoard.pageNum,
		totalRecords: state.InternationalLoadBoard.totalRecords,
		loading: state.InternationalLoadBoard.loading,
		loadBoardDefaultColumns: state.InternationalLoadBoard.loadBoardDefaultColumns,
		loadBoardCustomColumns: state.InternationalLoadBoard.loadBoardCustomColumns,
		shipmentNoteCounts: state.InternationalLoadBoard.shipmentNoteCounts,
		notesLoading: state.InternationalLoadBoard.notesLoading,
		transitStatusOptions: state.InternationalLoadBoard.transitStatusOptions,
		history: state.InternationalLoadBoard.history,
		userHasBolLevelPermission: !!hasPermission("USE_BOL_LEVEL")(state),
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		saveLoadBoardListColumns,
		setLoadBoardDefaultColumns,
		applyLoadBoardCustomColumns,
		exportToCSV,
		getLoadBoardFilterOptions,
		getIntlNotes,
		getShipmentNoteCount,
        handleMessages,
		setNotification,
		updateTransitStatus,
		loadLevelsIfEmpty,
		updateIntlLevel,
		setShipments,
		getShipmentHistory,
	}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(InternationalLoadBoardList);
