import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Admin from './reducers/index.jsx'
import CustomerPortal from '../views/CustomerPortal/reducers/CustomerPortal.jsx';
import notifications from './reducers/notifications.jsx';
import ArchivedBillsOfLading from "../views/ArchivedBillsOfLading/reducers/ArchivedBillsOfLading.jsx";
import InternalCSTracking from "../views/InternalCSTracking/reducers/InternalCSTracking.jsx";
import BillOfLadingNotes from "../views/BillOfLadingNotesModal/reducers/BillOfLadingNotes.jsx";
import RateDetailsModal from "../views/RateResults/reducers/RateDetailsModal.jsx"
import TerminalInformationModal from "../views/RateResults/reducers/TerminalInformationModal.jsx";
import RateDisplay from "../views/RateDisplays/reducers/RateDisplay.jsx";
import Alert from "../views/Alert/reducers/Alert.jsx";
import ImportanceLevel from "../components/ImportanceLevel/reducers/ImportanceLevel.jsx";
import Environment from "./reducers/Environment.jsx";
import FilterQueryModal from "../components/FilterQueryModal/reducers/FilterQueryModal.jsx";
import ParcelLookup from "../components/ParcelLookup/reducers/ParcelLookup.jsx";
import InternationalLoadBoard from "../views/InternationalLoadBoard/reducers/InternationalLoadBoard.jsx";
import InternationalNotes from "../views/InternationalNotesModal/reducers/InternationalNotes.jsx";
import PrintZplModal from "../views/PrintZplModal/reducers/PrintZplModal.jsx";
import Search from "../components/Search/reducers/Search.jsx";

export default (history) => combineReducers({
	router: connectRouter(history),
	Environment,
	Admin,
	CustomerPortal,
	notifications,
	ArchivedBillsOfLading,
	InternalCSTracking,
	BillOfLadingNotes,
	RateDetailsModal,
	TerminalInformationModal,
	RateDisplay,
	Alert,
	ImportanceLevel,
	FilterQueryModal,
	ParcelLookup,
	InternationalLoadBoard,
	InternationalNotes,
	PrintZplModal,
	Search,
});
