import React, { useState, useEffect } from 'react';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { Button, IconButton, Typography, DialogTitle, Dialog, DialogContent, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';
import FilterQuerySelect from './FilterQuerySelect.jsx';
import FilterQueryNameSelect from './FilterQueryNameSelect.jsx';
import FilterQueryText from './FilterQueryText.jsx';
import FilterQueryButtonGroup from './FilterQueryButtonGroup.jsx';
import FilterQueryTextArea from './FilterQueryTextArea.jsx';
import { useSnackbar } from 'notistack';
import allActions from "../actions/FilterQueryModal.jsx";


const useStyles = makeStyles(() => ({
    modalHeader: {
        display: "flex",
        justifyContent: "flex-start",
    },

    selectBoxRow: {
        width: '100%',
        display: "flex",
        justifyContent: "spaced-evenly",
        flexGrow: 1,
        marginBottom: "2em",
    },

    DialogContent: {
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflowY: "unset",
    }
}));

/**
 * props:
 * headerData
 * filters
 * clearPresets
 * updateFilters
 * setFilterOptions - dropdown options
 * fieldsWithLimitedOperators ('equals','not_equals','like','not_like','begins_with','ends_with','in','not_in','is_null','is_not_null')
 * fieldsWithAutoComplete - set autocomplete
 * fieldsWithDate - sets date picker
 * optionsLoading - set loading
 */
export default function FilterQueryModal({ 
    headerData, 
    filters, 
    clearPreset, 
    updateFilters,
    setFilterOptions,
    fieldsWithLimitedOperators,
    fieldsWithAutoComplete,
	fieldsWithDate,
    optionsLoading}) {
    const {
        operator,
        filterName,
        filterHeaders,
        queryFilters
    } = useSelector(state => ({ ...state.FilterQueryModal }));

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState([]);
    const [queryText, setQueryText] = useState({ value: '' });
    const [queryText2, setQueryText2] = useState({ value: '' });
    const [presetLoad, setPresetLoad] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        setFilterOptions('');
    }, []);

    useEffect(() => {
        if (Array.isArray(filters) && !filters.length) {
            setFilter([]);
            dispatch(allActions.setFilterValue([]));
        }

        if (Array.isArray(filters) && filters.length) {
            setPresetLoad(true)
            dispatch(allActions.setFilterValue(filters));
        }
        
    }, [filters]);

    useEffect(() => {
        if (Array.isArray(filter) && filter.length) {
            filter.map((item, index) => item.id = `${index}`);
        }
    }, [filter]);

    useEffect(() => {
        dispatch(allActions.setHeaderObj(headerData));
    }, [open]);

    const handleOpen = () => {
        if (presetLoad) {
            setFilter([]);
            renderRules(filters);
        } else {
            setFilter([]);
            renderRules(queryFilters);
        }
        setOpen(true);
    }

    const handleClose = (apply=false) => {
        if (!apply) {
            setPresetLoad(false)
            dispatch(allActions.setFilterValue(filters));
        } else {
            dispatch(allActions.setFilterValue(filter));
        }
        setOpen(false);
        clearQueryText();
        setFilterOptions('');
    }

    const addRule = () => {
        const isNullType = operator.value == 'is_null' || operator.value == "is_not_null";
        const isBetweenType = operator.value === "between" || operator.value === "not_between";
        const isDate = fieldsWithDate.includes(filterName.text);

        if (isBetweenType) {
            if (queryText.value === "" || queryText2.value === "") {
                enqueueSnackbar('Both search values must be provided!', { variant: 'error' });
                return;
            }

            setFilter(state => [...state, {
                value: `[${filterName.text}] ${operator.text} "${queryText.value}" AND "${queryText2.value}"`,
                text: filterName.text,
                field: filterName.field,
				accessor: filterName.accessor,
                operator: operator.value,
                operatorText: operator.text,
                param1: queryText.value,
                param2: queryText2.value,
                color: "#777",
                id: `${filter.length}`,
            }]);

        } else if (isNullType) {
            setFilter(state => [...state, {
                value: `[${filterName.text}] ${operator.text}`,
                text: filterName.text,
                field: filterName.field,
                accessor: filterName.accessor,
                operator: operator.value,
                operatorText: operator.text,
                color: "#777",
                id: `${filter.length}`,
            }]);

        } else {
            setFilter(state => [...state, {
                value: `[${filterName.text}] ${operator.text} "${queryText.value}"`,
                text: filterName.text,
                field: filterName.field,
                accessor: filterName.accessor,
                operator: operator.value,
                operatorText: operator.text,
                param1: queryText.value,
                color: "#777",
                id: `${filter.length}`
            }]);
        }

       if(!isDate) clearQueryText();
    }

    // If param values are dates, convert to YYYY-MM-DD format when applied
    // Convert back to MM/DD/YYYY format when rules render again
    const checkIfDate = (field, val, isDisplay) => {

        if(val == undefined) {
            return val;
        }

        if(fieldsWithDate.includes(field)) {
            const date = moment(val.substring(0, 10), "MM/DD/YYYY");
            const date2 = moment(val.substring(0, 10), "YYYY-MM-DD");
            if(isDisplay) {
                val = date.isValid() ? moment(date, "MM/DD/YYYY").format("MM/DD/YYYY") : date2.isValid() ? moment(date2, "YYYY-MM-DD").format("MM/DD/YYYY") : val;
            } else {
                val = date.isValid() ? moment(date, "MM/DD/YYYY").format("YYYY-MM-DD") : date2.isValid() ?  moment(date2, "YYYY-MM-DD").format("YYYY-MM-DD") : val;
            }
        }
        return val;
    }

    const addQueryText = (value, hasParam2) => {
        !hasParam2 ? setQueryText({value: value}) : setQueryText2({value: value});
    };

    const clearQueryText = () => {
        setQueryText({ value: "" });
        setQueryText2({ value: "" });
    }

    const addOR = () => {
        setFilter(state => [...state, {
            value: "OR",
            operator: "or",
            color: "#f0ad4e",
            id: `${filter.length}`
        }]);
    }

    const openGroup = () => {
        setFilter(state => [...state, {
            value: "(",
            operator: "open",
            color: "#5bc0de",
            id: `${filter.length}`
        }]);
    }

    const closeGroup = () => {
        setFilter(state => [...state, {
            value: ")",
            operator: "close",
            color: "#5bc0de",
            id: `${filter.length}`
        }]);
    }

    const removeRule = (key) => {
        setFilter(filter.filter((item, index) => index !== key));
    }

    const getOp = (op) => {
        let val;
        const operators = [
            { value: 'equals', text: '=' },
            { value: 'not_equals', text: '!=' },
            { value: 'like', text: 'LIKE' },
            { value: 'not_like', text: 'NOT LIKE' },
            { value: 'begins_with', text: 'BEGINS WITH' },
            { value: 'ends_with', text: 'ENDS WITH' },
            { value: 'greater_than', text: '>' },
            { value: 'greater_than_equal_to', text: '>=' },
            { value: 'less_than', text: '<' },
            { value: 'less_than_equal_to', text: '<=' },
            { value: 'between', text: 'BETWEEN' },
            { value: 'not_between', text: 'NOT BETWEEN' },
            { value: 'in', text: 'IN' },
            { value: 'not_in', text: 'NOT IN' },
            { value: 'is_null', text: 'IS NULL' },
            { value: 'is_not_null', text: 'IS NOT NULL' },
        ];

        operators.map((item, i) => {
            if(op == item.value) {
                val = item.text
                return;
            }
        })
        return val;
    }

    const renderRules = (data) => {

        data.map(item => {
            if (item.operator == 'or') {
                addOR();
            } else if (item.operator == 'open') {
                openGroup();
            } else if (item.operator == 'close') {
                closeGroup();
            } else {

                filterHeaders.map(name => {
                    if (name.data === item.field) {
                        const opText = getOp(item.operator);
                        const isNullType =  item.operator === "is_null" || item.operator === "is_not_null";
                        const isBetweenType = item.operator === "between" || item.operator === "not_between";

                        item.param1 = checkIfDate(name.headerDisplayName, item.param1, true);
                        item.param2 = checkIfDate(name.headerDisplayName, item.param2, true);
                        
                        if (isBetweenType) {
                            setFilter(state => [...state, {
                                value: `[${name.headerDisplayName}] ${opText} "${item.param1}" AND "${item.param2}"`,
                                text: name.headerDisplayName,
                                field: item.field,
                                accessor: name.accessor,
                                operator: item.operator,
                                operatorText: opText,
                                param1: item.param1,
                                param2: item.param2,
                                color: "#777",
                                id: `${filter.length}`,
                            }]);
                        } else if (isNullType) {
                            setFilter(state => [...state, {
                                value: `[${name.headerDisplayName}] ${opText}`,
                                text: name.headerDisplayName,
                                field: item.field,
                                accessor: name.accessor,
                                operator: item.operator,
                                operatorText: opText,
                                color: "#777",
                                id: `${filter.length}`,
                            }]);
                        } else {
                            setFilter(state => [...state, {
                                value: `[${name.headerDisplayName}] ${opText} "${item.param1}"`,
                                text: name.headerDisplayName,
                                field:item.field,
                                accessor: name.accessor,
                                operator: item.operator,
                                operatorText: opText,
                                param1: item.param1,
                                color: "#777",
                                id: `${filter.length}`,
                            }]);
                        }
                    }
                });
            }
        });
        setPresetLoad(false);

    }

    const updateRule = (itemId, canceled, update) => {
        setIsUpdate(true);

        if (canceled) {
            setIsUpdate(false)
        }

        setFilter(filter.map(item => {
            const isOperator= item.value ==")" || item.value =="(" || item.value ==="OR";
            const isNullType = operator.value == 'is_null' || operator.value == "is_not_null";
            const isBetweenType = operator.value === "between" || operator.value === "not_between";
            const isItemBetweenType = item.operator === "between" || item.operator === "not_between";

            if (isOperator) return {...item};

            if (item.id !== itemId || canceled) return {...item,
                color: "#777", 
            };

            if (update) {
                if (item.id == itemId) {
                    if (isBetweenType) {
                        if (queryText.value === "" || queryText2.value === "") {
                            enqueueSnackbar('Both search values must be provided!', { variant: 'error' });
                            setIsUpdate(true)
                            return item;
                        }
                        setIsUpdate(false);
                        return {
                            id: item.id,
                            value: `[${filterName.text}] ${operator.text} "${queryText.value}" AND "${queryText2.value}"`,
                            text: filterName.text,
                            field: filterName.field,
                            accessor: filterName.accessor,
                            operator: operator.value,
                            operatorText: operator.text,
                            param1: queryText.value,
                            param2: queryText2.value,  
                            color: "#777", 
                        };

                    } else if (isNullType) {
                        setIsUpdate(false);
                        return {
                            id: item.id,
                            value: `[${filterName.text}] ${operator.text}`,
                            text: filterName.text,
                            field: filterName.field,
                            accessor: filterName.accessor,
                            operator: operator.value,
                            operatorText: operator.text,
                            color: "#777", 
                        };
                    } else {
                         setIsUpdate(false);
                        return {
                            id: item.id,
                            value: `[${filterName.text}] ${operator.text} "${queryText.value || ''}"`,
                            text: filterName.text,
                            field: filterName.field,
                            accessor: filterName.accessor,
                            operator: operator.value,
                            operatorText: operator.text,
                            param1: queryText.value || '',  
                            color: "#777", 
                        };
                    }
                }
            }
            
            dispatch(allActions.updateFilterNameValue({
                text: item.text,
                accessor: item.accessor,
                field: item.field
            }));

            dispatch(allActions.updateOperatorValue({value: item.operator, text: item.operatorText}));
            if (isItemBetweenType) {
                addQueryText(item.param2, true);
            }
            addQueryText(item.param1, false);

            return {...item, 
                color: "#00A300",
            };
        }));
    }

    const clearFilters = () => {
        setFilter([]);
        dispatch(allActions.setFilterValue([]));
        clearQueryText();
		clearPreset()
        handleClose(true);
    }

    const applyFilters = () => {
        if(validateRuleSet()) {
            setFilter([]);
            let arr = filter.map(({ field, operator, param1, param2, text }) => ({
                field: field,
                operator: operator,
                param1: checkIfDate(text, param1, false),
                param2: checkIfDate(text, param2, false),
            }))
            updateFilters(arr);
            setPresetLoad(true);
			clearQueryText();
            handleClose(true);
        }
    }

    const validateRuleSet = () => {
        let rules = filter;
       
        if (Array.isArray(rules) && !rules.length) {
            enqueueSnackbar('A query must be provided!', { variant: 'error'});
            return false;
        }

        if (Array.isArray(rules) && rules.length){
            let numRules = rules.length;

            if (rules[0].operator === 'or') {
                enqueueSnackbar('Invalid syntax - cannot start query with OR', { variant: 'error'});
                return false;
            }

            if (rules[numRules - 1].operator === 'or') {
                enqueueSnackbar('Invalid syntax - cannot end query with OR', { variant: 'error'});
                return false
            }

            let conditionFound = false;
            let errorFound = false;
            let openCount = 0;
            let closeCount = 0;
            rules.forEach((item, i) => {

                if (item.operator != 'open' && item.operator != 'close' && item.operator != 'or') {
                    conditionFound = true;
                }

                if (i < (numRules - 1)) {

                    if (item.operator === 'or') {

                        if (rules[i + 1].operator === 'or') {
                            enqueueSnackbar('Invalid syntax - back to back OR operators', { variant: 'error'});
                            errorFound = true;
                            return false;
                        }

                        if (rules[i + 1].operator === 'close') {
                            enqueueSnackbar('Invalid syntax - OR operator followed by CLOSE operator', { variant: 'error'});
                            errorFound = true;
                            return false;
                        }
                    }

                    if (item.operator === 'open') {

                        openCount++;

                        if (rules[i + 1].operator === 'or') {
                            enqueueSnackbar('Invalid syntax - OPEN operator followed by OR operator', { variant: 'error'});
                            errorFound = true;
                            return false;
                        }

                        if (rules[i + 1].operator === 'close') {
                            enqueueSnackbar('Invalid syntax - OPEN operator followed by CLOSE operator', { variant: 'error'});
                            errorFound = true;
                            return false;
                        }
                    }

                    if (rules[i + 1].operator === 'close') {
                        closeCount++;
                    }
                }
            });

            if (conditionFound === false) {
                enqueueSnackbar('No condition provided', { variant: 'error'});
                return false;
            }

            if (openCount != closeCount){
                enqueueSnackbar('Invalid number of opening and closing parentheses', { variant: 'error'});
                return false;
            }

            if (errorFound){
                return false;
            }
        }

        return true;

    }

    return (
        <React.Fragment>
           
            <IconButton component="span" type="button" onClick={handleOpen}>
                <Badge 
                    badgeContent={filters.length}
                    variant="dot"
                    color="secondary"
                >
                    <SearchIcon style={{ fontSize: 25 }} />
                </Badge>
            </IconButton>

            <Dialog
                open={open}
                style={{ bottom: "30%" }}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle >
                    <div className={classes.modalHeader}>
                        <Typography variant="h4" component="div" style={{ flexGrow: 1 }}> <SearchIcon style={{ fontSize: 36, marginBottom: '-5px' }} />Advanced Search</Typography>
                        <Button onClick={() => handleClose()} variant="outlined">X</Button>
                    </div>
                </DialogTitle>
                <DialogContent
                    dividers
                    className={classes.DialogContent}
                >
                {operator.value === "is_null" || operator.value === "is_not_null" ?
                    <Grid
                        className={classes.selectBoxRow}
                        container
                        spacing={3}
                    >
                        <Grid item xs={4} >
                            <FilterQueryNameSelect
                                clearQueryText={clearQueryText}
                                setFilterOptions={(text) => setFilterOptions(text)}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FilterQuerySelect
                                isUpdate={isUpdate}
                                fieldsWithLimitedOperators={fieldsWithLimitedOperators}
                            />
                        </Grid>
                    </Grid>
                : operator.value === "in" || operator.value === "not_in" ?
                    <Grid
                        className={classes.selectBoxRow}
                        container
                        spacing={3}
                    >
                        <Grid item xs={4} >
                            <FilterQueryNameSelect
                                clearQueryText={clearQueryText}
                                setFilterOptions={(text) => setFilterOptions(text)}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FilterQuerySelect
                                isUpdate={isUpdate}
                                fieldsWithLimitedOperators={fieldsWithLimitedOperators}
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            style={{ margin: 0 }}
                        >
                            <Grid item xs={12} >
                                <FilterQueryTextArea
                                    addQueryText={addQueryText}
                                    value={queryText.value}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                : operator.value === "between" || operator.value === "not_between" ?
                    <Grid
                        className={classes.selectBoxRow}
                        container
                        spacing={3}
                    >
                        <Grid item xs={4} >
                            <FilterQueryNameSelect
                                clearQueryText={clearQueryText}
                                setFilterOptions={(text) => setFilterOptions(text)}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FilterQuerySelect
                                isUpdate={isUpdate}
                                fieldsWithLimitedOperators={fieldsWithLimitedOperators}
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            style={{ margin: 0 }}
                        >
                            <Grid item xs={4} >
                                <FilterQueryText
                                    addQueryText={addQueryText}
                                    clearQueryText={clearQueryText}
                                    param="param1"
                                    value={queryText.value}
                                    placeholder={"Search Value 1"}
                                    isUpdate={isUpdate}
                                    fieldsWithAutoComplete={fieldsWithAutoComplete}
                                    fieldsWithDate={fieldsWithDate}
                                    optionsLoading={optionsLoading}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <FilterQueryText
                                    addQueryText={addQueryText}
                                    clearQueryText={clearQueryText}
                                    param="param2"
                                    value={queryText2.value}
                                    placeholder={"Search Value 2"}
                                    isUpdate={isUpdate}
                                    fieldsWithAutoComplete={fieldsWithAutoComplete}
                                    fieldsWithDate={fieldsWithDate}
                                    optionsLoading={optionsLoading}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                :
                    <Grid
                        className={classes.selectBoxRow}
                        container
                        spacing={3}
                    >
                        <Grid item xs={4} >
                            <FilterQueryNameSelect
                                clearQueryText={clearQueryText}
                                setFilterOptions={(text) => setFilterOptions(text)}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FilterQuerySelect
                                isUpdate={isUpdate}
                                fieldsWithLimitedOperators={fieldsWithLimitedOperators}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <FilterQueryText
                                addQueryText={addQueryText}
                                clearQueryText={clearQueryText}
                                value={queryText.value}
                                placeholder={"Search Value"}
                                isUpdate={isUpdate}
                                fieldsWithAutoComplete={fieldsWithAutoComplete}
                                fieldsWithDate={fieldsWithDate}
                                optionsLoading={optionsLoading}
                            />
                        </Grid>
                    </Grid>
                }
                    <FilterQueryButtonGroup
                        addOR={addOR}
                        openGroup={openGroup}
                        clearFilters={clearFilters}
                        clearQueryText={clearQueryText}
                        filter={filter}
                        closeGroup={closeGroup}
                        addRule={addRule}
                        removeRule={removeRule}
                        setFilter={setFilter}
                        applyFilters={applyFilters}
                        updateRule={updateRule}
                        setFilterOptions={(text) => setFilterOptions(text)}
                        fieldsWithDate={fieldsWithDate}
                        setIsUpdate={setIsUpdate}
                        isUpdate={isUpdate}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

