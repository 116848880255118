import React from "react";
import qs from "qs";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";

// custom variables
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";


// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewNote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			show: false,
			mounted: false,
			loading: true,
			alert: null,
			redirect: null,
			user: null,
			body: null,
			name: "",
			note: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveNote = this.saveNote.bind(this);
	}

	async componentDidMount() {
		let id = "";
		if (this.props.match.params.id) {
			id = this.props.match.params.id;
		}
		this.setState({
			mounted: true,
			id
		});
		let url = "/index.php?p=api&r=json&c=admin&m=newNote";
		if (!_.isEmpty(id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editNote&d=" + id;
		}
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user)) {
				if (!_.isEmpty(id)) {
					this.props.pageTitle('Edit Note - ' + data.body.name);
				}
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					name: !_.isEmpty(data.body.name) ? data.body.name : "",
					note: !_.isEmpty(data.body.note) ? data.body.note : "",
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async saveNote() {
		this.setState({ loading: true });
		const { name, note, id } = this.state;
		const data = { name, note };
		let url = "/index.php?p=api&r=json&c=admin&m=newNote";
		if (!_.isEmpty(id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editNote&d=" + id;
		}
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
				this.setState({ loading: false, redirect: basePath + "/admin/settings/canned-notes" });
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the note!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error saving the note!", {
				variant: "error"
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel className={classes.label}>Name/Title</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "name",
											name: "name",
											value: this.state.name || "",
											onChange: this.handleInput("name")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Note</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											multiline: true,
											id: "note",
											name: "note",
											value: this.state.note || "",
											onChange: this.handleInput("note")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<Button color="linkedin" onClick={this.saveNote}>
										Save
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(NewNote));