// Libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, DialogTitle, Dialog, DialogContent, DialogActions, Grid, IconButton, Fab, Badge } from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon, Add as AddIcon, ClearAll as ClearAllIcon } from '@material-ui/icons';

// Components
import SearchFilter from './SearchFilter.jsx';
import SearchFilterType from './SearchFilterType.jsx';
import SearchFilterValue from './SearchFilterValue.jsx';
import Transition from "~/components/TMS/ModalTransition.jsx";

// Styles
import modalStyle from "assets/jss/empire-tms/modalStyle.jsx";

// Actions
import { addFilter, setFilters } from "../actions/Search.jsx";

/**
 * props:
 * filters
 * clearPresets
 * updateFilters
 * loading - set loading
 */
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: this.props.loading || true,
            fields: this.props.fields || [],
            operators: this.props.operators || [
                { value: 'contains', text: 'CONTAINS' },
                { value: 'equals', text: 'EQUALS' },
                { value: 'starts with', text: 'BEGINS WITH' },
                { value: 'ends with', text: 'ENDS WITH' },
            ],
            route: this.props.location.pathname,
            open: this.props.open || false,
        };

		this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    componentWillUnmount() {
        const { filters } = this.props;
        // Remove any empty filters on unmount
        if(filters.length) {
            const updatedFilters = filters.filter(f => f.value.length);
            this.props.setFilters(updatedFilters);
        }
    }

    handleOpen = () => {
        this.setState({fields: this.props.fields }, () => {
            let filters = this.props.filters.filter(f => f.route == this.state.route);
            if(!filters.length) {
                this.addFilter();
            }
            this.setState({ open: true });
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    clearFilters = () => {
        this.props.setFilters(this.props.filters.filter(f => f.route !== this.state.route));
        this.props.onClear();
        this.handleClose();
    }

    applyFilters = () => {
        let filters = this.props.filters.filter(f => f.route == this.state.route);
        if(filters.length) {
            filters = filters.filter(f => f.value.length);
            this.props.setFilters(filters);
        }
        if(!filters.length) {
            this.clearFilters();
        } else {
            this.props.onSearch(filters);
            this.handleClose();
        }
    }

    addFilter = () => {
        const { fields, operators } = this.state;

        let filter = {
            field: fields && fields?.length > 0 ? fields[0].value : "",
            compare: operators && operators?.length > 0 ? operators[0].value : "",
            value: "",
            value2: "",
            route: this.state.route,
        };

        this.props.addFilter(filter);
    }

    removeFilter = (index) => {
        const { filters } = this.props;

       if (index >= 0 && index < filters.length) {
            const updatedFilters = filters.filter((_, i) => i !== index);
            this.props.setFilters(updatedFilters);
        }
    }

    updateFilter(index, filter) {
        const { filters } = this.props;
        filters[index] = filter;
        this.props.setFilters(filters);
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            this.applyFilters();
        }
    };

    renderFilters = () => {
        const { classes, filters } = this.props;
        return filters.map((filter, i) => {
            if (filter.route !== this.state.route) return null;
            const field = this.state.fields.find(field => field.value == filter.field);
            return (
                <Grid container key={i} spacing={3} className={classes.selectBoxRow}>
                    <Grid item xs={this.props.hideFilterType ? 5 : 4}>
                        <SearchFilter
                            value={filter.field}
                            fields={this.state.fields}
                            updateFilter={(field) => { this.updateFilter(i, { ...filter, field })}}/>
                    </Grid>
                    {this.props.hideFilterType ? '' : 
                        <Grid item xs={3}>
                            <SearchFilterType
                                value={filter.compare}
                                operators={this.state.operators}
                                updateFilterType={(compare) => { this.updateFilter(i, { ...filter, compare })}}/>
                        </Grid>
                    }
                    <Grid item xs={this.props.hideFilterType ? 6 : 4}>
                        <SearchFilterValue
                            value={filter.value}
                            value2={filter.value2}
                            placeholder={"Enter search value"}
                            field={field}
                            updateFilterValue={(value, value2) => { this.updateFilter(i, { ...filter, value, value2}) }}
                            setFocus={(i == (filters.length - 1))}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => this.removeFilter(i)} variant="outlined">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            );
        });
    }

    render() {
        const { classes } = this.props;
        const filterCount = this.props.filters.filter(f => f.route == this.state.route && f.value.length).length;
		if(this.props.loading) {
            return(
                <Button color="primary" style={{ margin: "10px 0" }} disabled>
                    <SearchIcon /> Search
                </Button>
            )
        }
        return (
            <Fragment>
                <Button color="primary" style={{ margin: "10px 0" }} onClick={this.handleOpen}>
                    <Badge color="primary" badgeContent={filterCount}>
                        <SearchIcon /> Search
                    </Badge>
                </Button>
                {filterCount > 0 &&
                   <Button color="primary" onClick={this.clearFilters}>
                        <ClearAllIcon /> Clear All
                    </Button>
                }

                <Dialog
                    classes={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal
                    }}
                    TransitionComponent={Transition}
                    open={this.state.open}
                    fullWidth={true}
                    maxWidth="md"
                    onKeyDown={this.handleKeyDown}
                >
                    <DialogTitle disableTypography className={classes.modalHeader}>
                        <IconButton aria-label="close" className={classes.modalCloseButton} onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <h4 className={classes.modalTitle}>
                            <SearchIcon/>Search
                        </h4>

                    </DialogTitle>
                    <DialogContent dividers className={classes.DialogContent}>
                        {this.renderFilters()}
                        <Fab size="small" color="primary" aria-label="add" className={classes.fab} onClick={this.addFilter}>
                            <AddIcon />
                        </Fab>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.clearFilters} variant="outlined">Clear All</Button>
                        <Button onClick={this.applyFilters} variant="outlined">Apply</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        addFilter,
        setFilters,
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        ...state.Search
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(modalStyle)(withRouter(Search)));
