import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardBodyStyle from "~/assets/jss/empire-tms/components/cardBodyStyle.jsx";

function CardBody({ ...props }) {
	const {
		classes,
		className,
		children,
		background,
		plain,
		formHorizontal,
		pricing,
		signup,
		color,
		profile,
		calendar,
		...rest
	} = props;
	const cardBodyClasses = classNames({
		[classes.cardBody]: true,
		[classes.cardBodyBackground]: background,
		[classes.cardBodyPlain]: plain,
		[classes.cardBodyFormHorizontal]: formHorizontal,
		[classes.cardPricing]: pricing,
		[classes.cardSignup]: signup,
		[classes.cardBodyColor]: color,
		[classes.cardBodyProfile]: profile,
		[classes.cardBodyCalendar]: calendar,
		[className]: className !== undefined
	});
	return (
		<div className={cardBodyClasses} {...rest}>
			{children}
		</div>
	);
}

CardBody.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	background: PropTypes.bool,
	plain: PropTypes.bool,
	formHorizontal: PropTypes.bool,
	pricing: PropTypes.bool,
	signup: PropTypes.bool,
	color: PropTypes.bool,
	profile: PropTypes.bool,
	calendar: PropTypes.bool
};

export default withStyles(cardBodyStyle)(CardBody);
