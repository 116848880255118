import React from "react";

import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

// material ui icons
import Check from "@material-ui/icons/Check";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class WarehouseSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			mode:(!_.isEmpty(props.mode)) ? props.mode : 'select',
			company: !_.isEmpty(props.company) ? props.company : null,
			selectedWarehouses: (!_.isEmpty(props.selectedWarehouses) && props.selectedWarehouses != undefined) ? props.selectedWarehouses : [],
			warehouses: (!_.isEmpty(props.warehouses)) ? props.warehouses : [],
		};
		this.getWarehouses = this.getWarehouses.bind(this);
		this.changeMode = this.changeMode.bind(this);
		this.handleWarehouse = this.handleWarehouse.bind(this);
		this.removeWarehouse = this.removeWarehouse.bind(this);
		this.getWarehouseById = this.getWarehouseById.bind(this);
		this.getListedWarehouses = this.getListedWarehouses.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		await this.loadWarehouses();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.company, this.props.company)) {
			this.setState({ selectedWarehouses: [] });
			this.loadWarehouses();
		}

		if(!_.isEqual(prevState.selectedWarehouses, this.state.selectedWarehouses)) {
			if(typeof this.props.onChange === 'function') {
				this.props.onChange(this.state.selectedWarehouses);
			}
		}

		if(!_.isEqual(prevProps.selectedWarehouses, this.props.selectedWarehouses)) {
			if(!_.isEqual(this.props.selectedWarehouses, this.state.selectedWarehouses)) {
				let selectedWarehouses = this.props.selectedWarehouses;
				if(_.isEmpty(selectedWarehouses)) {
					selectedWarehouses = [];
				}
				this.setState({selectedWarehouses: selectedWarehouses});
			}
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	async loadWarehouses() {
		if (_.isEmpty(this.props.warehouses)) {
			this.setState({ loading: true });
			let url = '/index.php?p=api&r=json&c=admin&m=getWarehouse';
			if (this.props.company != null && this.props.company.length > 0) {
				url += `&d=${this.props.company}`;
			}
			try {
				const response = await axios.get(url);
				const data = response.data;
				if (
					typeof data !== "string" && !_.isEmpty(data.body)) {
					this.props.handleMessages(response);
					this.setState({
						show: true,
						loading: false,
						warehouses: data.body,
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error loading warehouse data!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("An exception occurred while loading warehouse data!", {
					variant: "error"
				});
			}
		}
	}


	changeMode(mode) {
		this.setState({ mode });
	}

	handleWarehouse(id) {
		if(this.state.selectedWarehouses.indexOf(id) > -1) {
			return;
		}
		var selectedWarehouses = [...this.state.selectedWarehouses];
		selectedWarehouses.push(id);
		this.setState({ selectedWarehouses });
	}

	removeWarehouse(id) {
		var selectedWarehouses = [...this.state.selectedWarehouses];
		for(var i = 0; i < selectedWarehouses.length; i++) {
			if(id == selectedWarehouses[i]) {
				selectedWarehouses.splice(i, 1);
				break;
			}
		}
		this.setState({ selectedWarehouses });
	}

	getWarehouseById(id) {
		var wh = null;
		for(var i = 0; i < this.state.warehouses.length; i++) {
			if(id == this.state.warehouses[i].id) {
				wh = this.state.warehouses[i];
				break;
			}
		}
		return wh;
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={"wh_sel_" + key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.hasOwnProperty('company_name') ? prop.company_name + " - " + prop.name : prop.name}
				</MenuItem>
			);
		});
	}

	groupWarehousesByCompany(data) {
		var grouped = [];
		var index = {}; // keep track of group keys by company ID
		for(var i = 0; i < data.length; i++) {
			if(!(data[i].company_name_id in index)) {
				index[data[i].company_name_id] = (grouped.push({
					id: data[i].id,
					name: data[i].company_name,
					warehouses: [],
				}) - 1);
			}
			grouped[index[data[i].company_name_id]].warehouses.push({
				id: data[i].id,
				name: data[i].name,
			});
		}
		return grouped;
	}

	// render selected warehouses when mode is 'select'
	getSelectedWarehouses(data) {
		const { classes } = this.props;
		return data.map((id, key) => {
			const wh = this.getWarehouseById(id);
			return (wh !== null) ? (
				<GridContainer key={"selected_wh_" + key} justify="space-between">
					<GridItem>
						{wh.hasOwnProperty('company_name') ? wh.company_name + " - " + wh.name : wh.name}
					</GridItem>
					<GridItem>
						<Button size={"sm"} color={"danger"} onClick={e => this.removeWarehouse(id)}>
							<RemoveCircle />
						</Button>
					</GridItem>
				</GridContainer>
			) : null;
		});
	}

	getListedWarehouses(data, selected) {
		const { classes } = this.props;
		selected = (_.isEmpty(selected)) ? [] : selected;
		return this.groupWarehousesByCompany(data).map((company, key) => {
			return (
				<GridContainer key={"listed_company_" + key}>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<h4 style={{color: "blue"}}>{company.name}</h4>
					</GridItem>
					{(() => {
						return company.warehouses.map((wh, key) => {
							return (
								<GridItem xs={12} sm={12} md={12} lg={12} key={"listed_wh_" + key}>
				                    <FormControlLabel
				                        control={
				                            <Checkbox
				                                tabIndex={-1}
				                                checkedIcon={<Check className={classes.checkedIcon} />}
				                                icon={<Check className={classes.uncheckedIcon} />}
				                                classes={{
				                                    checked: classes.checked,
				                                    root: classes.checkRoot
				                                }}
				                                value={wh.id}
				                                checked={(selected.indexOf(wh.id) > -1) ? true : false}
				                                onChange={e => {
				                                	if(e.target.checked === true) {
					                                	this.handleWarehouse(e.target.value)
				                                	} else {
					                                	this.removeWarehouse(e.target.value)
				                                	}
				                               	}}
				                            />
				                        }
				                        classes={{
				                            label: classes.label
				                        }}
				                        label={wh.name}
				                    />
				                </GridItem>
		                   );
	                   });
	                })()}
				</GridContainer>
			);
		});
	}

	selectDeselectAll() {
		let { selectedWarehouses, warehouses } = this.state;

		if(warehouses.length != selectedWarehouses.length) {
			for(let i = 0; i < warehouses.length; i ++) {
				if(selectedWarehouses.indexOf(warehouses[i].id) == -1) {
					selectedWarehouses.push(warehouses[i].id);
				}
			}
		} else {
			selectedWarehouses = [];
		}
		this.setState({ selectedWarehouses });
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader>
							Select Warehouse(s)
							<Button
								size={"sm"}
								style={{
									marginLeft: '5px',
								}}
								color={(this.state.mode === 'select') ? 'linkedin' : 'white'}
								onClick={e => this.changeMode('select')}
							>
								Select
							</Button>
							<Button
								size={"sm"}
								color={(this.state.mode === 'list') ? 'linkedin' : 'white'}
								onClick={e => this.changeMode('list')}
							>
								List
							</Button>
						</CardHeader>
						<CardBody>
							<Button
								size={"sm"}
								color={"info"}
								onClick={(e) => this.selectDeselectAll()}
							>
								Select/Deselect All
							</Button>
							{this.state.mode === 'select' ?
								<GridContainer>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<GridContainer>
											<GridItem xs>
												<FormControl
													fullWidth
													className={
														classes.selectFormControl
													}
												>
													<InputLabel
														htmlFor="warehouses"
														className={classes.selectLabel}
													>
														Select Warehouse(s)
													</InputLabel>
													<Select
														MenuProps={{
															className:
																classes.selectMenu
														}}
														classes={{
															select:
																classes.select +
																" " +
																classes.requiredSelect
														}}
														value={""}
														inputProps={{
															id: "warehouses",
															name: "warehouses"
														}}
														onChange={e => this.handleWarehouse(e.target.value)}
													>
														{this.getWarehouses(this.state.warehouses)}
													</Select>
												</FormControl>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										{this.getSelectedWarehouses(this.state.selectedWarehouses)}
									</GridItem>
								</GridContainer>
							:   <GridContainer>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										{this.getListedWarehouses(this.state.warehouses, this.state.selectedWarehouses)}
									</GridItem>
								</GridContainer>}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(WarehouseSelector));
