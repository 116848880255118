import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Grid from "@material-ui/core/Grid";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class PrintBolModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
        	<Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton}
                    	key="close"
                    	aria-label="Close"
                    	color="transparent"
                    	onClick={() => this.props.onClose()}
                	>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Print BoLs</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Grid container justify="center">
                        <GridItem xs={12} className={classes.center}>
                            {this.props.showDraftPdf ? (
                                <Button fullWidth color="linkedin" onClick={() => this.props.draftPdf("original")}>
                                    Bill of Lading
                                </Button>
                            ) : (
                                <Button fullWidth color="linkedin" onClick={() => this.props.printBol(false)}>
                                    Bill of Lading
                                </Button>
                            )}
                        </GridItem>
                        <GridItem xs={12} className={classes.center}>
                            {this.props.showDraftPdf ? (
                                <Button fullWidth color="linkedin" onClick={() => this.props.draftPdf("blind")}>
                                    Blind BoL
                                </Button>
                            ) : (
                                <Button fullWidth color="linkedin" onClick={() => this.props.printBol(true)}>
                                    Blind BoL
                                </Button>
                            )}
                        </GridItem>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="warning" onClick={() => this.props.onClose()} className={classes.marginRight}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style)(PrintBolModal);