import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

import cx from "classnames";
import qs from "qs";
import _ from "lodash";
import { withSnackbar } from "notistack";
import { NavLink, Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { baseUrl, basePath, assetPath } from "~/variables/server.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

// core components
import TotalShipments from "~/components/Kendo/TotalShipments.jsx";
import TotalSpend from "~/components/Kendo/TotalSpend.jsx";
import FacilityShipments from "~/components/Kendo/FacilityShipments.jsx";
import FacilitySpend from "~/components/Kendo/FacilitySpend.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class KendoDataWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.controller = new AbortController(); // abort axios xhr
        this.state = {
            mounted: false,
            alert: null,
            redirect: null,
            show: false,
            loading: true,
            user: null,
            body: null,
            dataSet: "LTL_REVENUE",
            groups: [],
            aggregates: [],
            conditions: [],
            customFields: [],
            orders: [],
            limit: null,
            offset: null,
            params: [],
            endpoint: "getCustomerReportData",
            dataSource: null
        };
        this.setEndpoint = this.setEndpoint.bind(this);
        this.addCondition = this.addCondition.bind(this);
        this.clearCondition = this.clearCondition.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.replaceGroup = this.replaceGroup.bind(this);
        this.clearGroup = this.clearGroup.bind(this);
        this.addAggregate = this.addAggregate.bind(this);
        this.clearAggregate = this.clearAggregate.bind(this);
        this.addOrder = this.addOrder.bind(this);
        this.replaceOrder = this.replaceOrder.bind(this);
        this.clearOrder = this.clearOrder.bind(this);
        this.setView = this.setView.bind(this);
        this.setParam = this.setParam.bind(this);
        this.addCustomField = this.addCustomField.bind(this);
        this.buildQueryString = this.buildQueryString.bind(this);
        this.getDataSource = this.getDataSource.bind(this);
        this.generateData = this.generateData.bind(this);
    }

    componentDidMount() {
        this.setState({ mounted: true });

        this.setParam("key", this.props.token);

        this.setView("CUSTOMER_SHIPMENT");

        this.setEndpoint("getCustomerReportData");

        this.addAggregate("invoice_id", "count");
        this.addAggregate("total_weight", "sum");
        this.addAggregate("total_weight", "avg");
        this.addAggregate("pcf", "avg");
        this.addAggregate("distance", "avg");
        this.addAggregate("actual_transit", "avg");
        this.addAggregate("benchmark", "sum");
        this.addAggregate("benchmark", "avg");
        this.addAggregate("true_cost", "sum");
        this.addAggregate("true_cost", "avg");
        this.addAggregate("savings_to_client", "sum");
        this.addAggregate("savings_to_client", "avg");
        this.addAggregate("overcharge", "sum");
        this.addAggregate("bol_benchmark", "sum");
        this.addAggregate("bol_benchmark", "avg");
        this.addAggregate("bol_true_cost", "sum");
        this.addAggregate("bol_true_cost", "avg");
        this.addAggregate("lc_benchmark", "sum");
        this.addAggregate("lc_benchmark", "avg");
        this.addAggregate("lc_true_cost", "sum");
        this.addAggregate("lc_true_cost", "avg");
        this.addAggregate("lc_net_savings", "sum");
        this.addAggregate("lc_net_savings", "avg");
        this.addAggregate("lost_savings", "sum");
        this.addAggregate("lost_savings", "avg");
        this.addAggregate("possible_savings", "sum");
        this.addAggregate("possible_savings", "avg");

        this.addCondition("company_name_id", "in", this.props.companies);

        switch (this.props.type) {
            case "TotalShipments":
            case "TotalSpend":
                this.addGroup("invoiced_year");
                this.addOrder("invoiced_year", "ASC");
                break;
            case "FacilityShipments":
            case "FacilitySpend":
                this.addGroup("company_locations_id");
                this.addOrder("location_display", "ASC");
                break;
        }
    }

    componentWillUnmount() {
        this.controller.abort();
        this.setState({ mounted: false });
    }


    setEndpoint(endpoint) {
        this.setState({ endpoint });
    }

    addCondition(term, operator, val, valTo) {
        if (typeof valTo === undefined) {
            valTo = "";
        }
        const { conditions } = this.state;
        conditions.push({
            term,
            operator,
            val,
            valTo
        });
        this.setState({ conditions });
    }

    clearCondition() {
        this.setState({ conditions: [] });
    }

    addGroup(val) {
        if (val == null || val == "") {
            return;
        }
        const { groups } = this.state;
        groups.push(val);
        this.setState({ groups });
    }

    replaceGroup(val) {
        if (val == null || val == "") {
            return;
        }
        const groups = [];
        groups.push(val);
        this.setState({ groups });
    }

    clearGroup() {
        this.setState({ groups: [] });
    }

    addAggregate(field, func) {
        var agg = {
            field: field,
            function: func
        };
        const { aggregates } = this.state;
        aggregates.push(agg);
        this.setState({ aggregates });
    }

    clearAggregate() {
        this.setState({ aggregates: [] });
    }

    addOrder(field, order) {
        if (field == null || field == "") {
            return;
        }
        if (typeof order === undefined) {
            order = "";
        }
        var order = {
            field,
            order
        };
        const { orders } = this.state;
        orders.push(order);
        this.setState({ orders });
    }

    replaceOrder(field, order) {
        if (field == null || field == "") {
            return;
        }
        if (typeof order === undefined) {
            order = "";
        }
        const orders = [
            {
                field,
                order
            }
        ];
        this.setState({ orders });
    }

    clearOrder() {
        this.setState({ orders: [] });
    }

    setView(dataSet) {
        this.setState({ dataSet });
    }

    setParam(key, val) {
        const { params } = this.state;
        params.push({ key, val });
        this.setState({ params });
    }

    addCustomField(name, data) {
        var custom = {};
        custom.name = name;
        custom.expressions = [];
        for (const prop in data) {
            custom.expressions.push({
                type: prop.type,
                val: prop.val,
                function: prop.function
            });
        }
        const { customFields } = this.state;
        customFields.push(custom);
        this.setState({ customFields });
    }

    buildQueryString() {
        const query = {
            aggregates: this.state.aggregates,
            groupings: this.state.groups,
            orderings: this.state.orders,
            conditions: this.state.conditions,
            customFields: this.state.customFields,
            view: this.state.dataSet
        };
        const { params } = this.state;
        for (let i = 0; i < params.length; i++) {
            query[params[i].key] = params[i].val;
        }
        return qs.stringify(query);
    }

    async getDataSource() {
        const query = this.buildQueryString();
        const url = "/index.php?p=api&r=json&c=reporting&m=" + this.state.endpoint + "&" + query;
        try {
            const response = await axios.get(url, { signal: this.controller.signal });
            if (typeof response.data !== "string" && !_.isEmpty(response.data) && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.messages)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string" && !_.isEmpty(response.data) && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.responseData) && !_.isEmpty(response.data.body.responseData.result)) {
                const dataSource = response.data.body.responseData.result;
                this.setState({
                    dataSource,
                    loading: false
                });
            } else {
                this.props.setNotification("There was no data found to display for " + this.props.type, { variant: "warning" });
                this.setState({ loading: false });
            }
        } catch (error) {
            if(typeof error.message === 'undefined' || error.message !== 'canceled') {
                console.error(error);
                this.setState({ loading: false });
                this.props.setNotification("There was an error getting the data source!", { variant: "error" });
            }
        }
    }

    generateData() {
        if (_.isEmpty(this.state.dataSource)) {
            return null;
        }
        const { type } = this.props;
        const data = {
            TotalShipments: {
                categories: [],
                series: {
                    name: "Number of Shipments",
                    data: []
                }
            },
            TotalSpend: {
                categories: [],
                series: {
                    name: "Total Cost",
                    data: []
                }
            },
            FacilityShipments: {
                categories: [],
                series: {
                    name: "Number of Shipments",
                    data: []
                }
            },
            FacilitySpend: {
                categories: [],
                series: {
                    name: "Total Cost",
                    data: []
                }
            }
        };
        for (const prop of this.state.dataSource) {
            if (type === "TotalShipments") {
                data.TotalShipments.categories.push(prop.invoiced_year);
                data.TotalShipments.series.data.push(prop.count_invoice_id);
                data.cell = TotalShipments;
            } else if (type === "TotalSpend") {
                data.TotalSpend.categories.push(prop.invoiced_year);
                data.TotalSpend.series.data.push(prop.sum_true_cost);
                data.cell = TotalSpend;
            } else if (type === "FacilityShipments") {
                data.FacilityShipments.categories.push(prop.intra_display);
                data.FacilityShipments.series.data.push(prop.count_invoice_id);
                data.cell = FacilityShipments;
            } else if (type === "FacilitySpend") {
                data.FacilitySpend.categories.push(prop.intra_display);
                data.FacilitySpend.series.data.push(prop.sum_true_cost);
                data.cell = FacilitySpend;
            }
        }
        return data;
    }

    render() {
        if (this.state.redirect) {
            return this.state.redirect;
        }
        if (this.state.mounted && this.state.loading) {
            this.getDataSource();
        }
        const { classes, type } = this.props;
        let data = null;
        const table = null;
        if (!_.isEmpty(this.state.dataSource)) {
            data = this.generateData();
        }
        return (
            <Paper className={classes.paper} elevation={1}>
                {!_.isEmpty(data) && <data.cell categories={data[type].categories} data={data[type].series.data} />}
                {this.state.loading && (
                    <div className={classes.center}>
                        <CircularProgress />
                    </div>
                )}
            </Paper>
        );
    }
}

KendoDataWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    companies: PropTypes.arrayOf(PropTypes.number).isRequired,
    type: PropTypes.oneOf(["TotalShipments", "TotalSpend", "FacilityShipments", "FacilitySpend"]).isRequired
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(KendoDataWrapper)));