  
import { fade, makeStyles } from '@material-ui/core/styles'; 
  const optionStyle = theme => ({
	optionScrollbar :{ "::-webkit-scrollbar": {
		width: "12px",
		backgroundColor: "#F5F5F5"
	  }
	},
	searchWrapper : {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "5px 12px",
		position: "static",
		width: "287px",
		height: "30px",
		left: "12px",
		top: "8px",
		background: "#FFFFFF",
		border: "1px solid #D9D9D9",
		boxSizing: "border-box",
		borderRadius: "2px",
		flex: "none",
		order: 0,
		alignSelf: "stretch",
		flexGrow: 0,
		margin: "10px 0px"
	  },
	  scrollBar : {
		position: "absolute",
		height: "88px",
		left: "4px",
		right: "4px",
		top: "5px",
		background: "#002D55",
		borderRadius: "60px"
	  },
	transferList: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		padding: "0px",
		position: "static",
		width: "323px",
		height: "170px",
		left: "0px",
		top: "88px",
		flex: "none",
		order: 2,
		flexGrow: 1,
		margin: "0px 0px"
		
	  },
	  search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
		  backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		  marginLeft: theme.spacing(1),
		  width: 'auto',
		},
	  },
	  searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	  inputRoot: {
		color: 'inherit',
	  },
	  inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		  width: '12ch',
		  '&:focus': {
			width: '20ch',
		  },
		},
	  },
});
  
  export default optionStyle;
  