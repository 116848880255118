import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from '~/components/Grid/GridItem.jsx';
import GridText from '~/components/Grid/GridText.jsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ProductDetails from './ProductDetails.jsx';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import UpdateHeldBillContainer from '../containers/UpdateHeldBillContainer.jsx';
import { userIsCarrier, getUserSetting } from "../../../redux/selectors/Admin.jsx";
import InvoiceImageLink from "./InvoiceImageLink.jsx";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const cardBorderColor = "rgba(0,0,0,0.12)";
const financialDetailsBgColor = "rgba(0,0,0,0.11)";
const getVal = value => value ? value : "";

/**
 * Renders carrier bill data
 *
 * Available props
 *
 * loading - bool - if true, renders loading skeleton
 * canViewFinancialDetails - bool - toggles display of financial details
 * canUpdate - bool - toggles display of UpdateHeldBillContainer component
 * bill - bill data as returned by index.php?p=api&r=json&c=billing&m=loadBilledShipment
 */
class CarrierBill extends Component {

	render() {
		if (!!this.props.loading) {
			return this.renderLoadingState();
		}
		if(!this.props.bill) {
			return this.renderNoBill();
		}
		const canView = {payment: this.props.restrictPaymentInfo, products: this.props.restrictProducts};

		return (
			<Fragment>
				<div style={{marginTop: "5px"}}>
					<GridContainer>
						<GridItem xs={9}>
							<BillData bill={this.props.bill} />
						</GridItem>
						<GridItem xs={3}>
							<BillLocations bill={this.props.bill} />
						</GridItem>
					</GridContainer>
					<BillImagesContainer bill={this.props.bill}/>
					<BillProductsContainer hideProducts={canView.products} bill={this.props.bill} hideAmount={canView.payment} />
					<BillDates bill={this.props.bill} />
					{this.props.canViewFinancialDetails && <PaymentInformation hideAmount={canView.payment} userIsCarrier={this.props.userIsCarrier} bill={this.props.bill} />}
					<CustomerNotes bill={this.props.bill} />
					<HoldTable bill={this.props.bill} />
					{!!this.props.canUpdate && <UpdateHeldBillContainer />}
				</div>
			</Fragment>
		);
	}

	renderLoadingState() {
		return (
			<Fragment>
				<GridContainer>
					<GridItem xs={9}>
						<Skeleton height={300}/>
					</GridItem>
					<GridItem xs={3}>
						<Skeleton height={300}/>
					</GridItem>
				</GridContainer>
				<Skeleton height={100}/>
				<Skeleton height={100}/>
				<Skeleton height={100}/>
			</Fragment>
		);
	}

	renderNoBill() {
		return (
			<div style={{marginTop: "10px"}}>
				<GridText typographyProps={{align:'center'}} width={12} text="We were unable to load this bill. Either it does not exist, or you do not have permissions to view it." />
			</div>
		);
	}
}

class BillDataContainer extends Component {
	render() {
		const headerStyle = {
			paddingTop: "4px",
			paddingBottom: "4px",
			borderBottom: `1px solid ${cardBorderColor}`,
		}
		const providedStyle = this.props.style ? this.props.style : {};
		const cardStyle = {...providedStyle, marginBottom: "5px"};
		const cardContentStyle = this.props.cardContentStyle ? this.props.cardContentStyle : {};
		return (
			<Card style={cardStyle} variant="outlined">
				<CardHeader style={headerStyle} subheader={this.props.title}/>
				<CardContent style={cardContentStyle}>
					<GridContainer>
						{this.props.children}
					</GridContainer>
				</CardContent>
			</Card>
		);
	}
}

class BillProductsContainer extends Component {
	render() {
		return (
			<BillDataContainer title="Product Detail">
				{this.content()}
			</BillDataContainer>
		);
	}

	content = () => {
		if (!this.hasProductDetails()) {
			return <GridText width={12} color="textSecondary" text="No Product Details" />
		}
		return <ProductDetails hideProducts={this.props.hideProducts} bill={this.props.bill} hideAmount={this.props.hideAmount}/>
	}

	hasProductDetails = () => {
		return this.props.bill.accessorials.length || this.props.bill.looseItems.length || this.props.bill.units.length;
	}
}

class BillImagesContainer extends Component {
	render() {
		return (
			<BillDataContainer title="Documents">
				{this.content()}
			</BillDataContainer>
		);
	}

	content = () => {
		const bill = this.props.bill;
		if (!this.hasImages()) {
			return <GridText width={12} color="textSecondary" text="No Documents" />
		}
		return (
			<Fragment>
				{bill.images.map((img, index) => {
					return (
						<GridItem key={index} xs={3}>
							<InvoiceImageLink name={img.fileName} imageId={img.id} />
						</GridItem>
					);
				})}
			</Fragment>
		);
	}

	hasImages = () => {
		return this.props.bill.images.length;
	}
}

class BillData extends Component {
	render() {
		const bill = this.props.bill;
		return (
			<BillDataContainer title="General">
				<GridText width={3} text="Customer" />
				<GridText width={3} color="primary" text={getVal(bill.company.name)} />
				<GridText width={3} text="Pro" />
				<GridText width={3} color="primary" text={getVal(bill.pro)} />
				<GridText width={3} text="Customer #" />
				<GridText width={3} color="primary" text={getVal(bill.company.id)} />
				<GridText width={3} text="Original Pro" />
				<GridText width={3} color="primary" text={getVal(bill.originalPro)} />
				<GridText width={3} text="Carrier" />
				<GridText width={3} color="primary" text={getVal(bill.carrier.name)} />
				<GridText width={3} text="Invoice #" />
				<GridText width={3} color="primary" text={getVal(bill.carrierInvNumber)} />
				<GridText width={3} text="SCAC" />
				<GridText width={3} color="primary" text={getVal(bill.carrier.scac)} />
				<GridText width={3} text="BOL #" />
				<GridText width={3} color="primary" text={getVal(bill.bolNumber)} />
				<GridText width={3} text="Paid To" />
				<GridText width={3} color="primary" text={getVal(bill.paidTo)} />
				<GridText width={3} text="PO #" />
				<GridText width={3} color="primary" text={getVal(bill.poNumber)} />
				<GridText width={3} text="Return Authorization #" />
				<GridText width={3} color="primary" text={getVal(bill.raNumber)} />
				<GridText width={3} text="Account #" />
				<GridText width={3} color="primary" text={getVal(bill.accountNumber)} />
				<GridText width={3} text="Vendor #" />
				<GridText width={3} color="primary" text={getVal(bill.vendorNumber)} />
				<GridText width={3} text="Cust Reference 1" />
				<GridText width={3} color="primary" text={getVal(bill.refNumber1)} />
				<GridText width={3} text="Service" />
				<GridText width={3} color="primary" text={getVal(bill.service)} />
				<GridText width={3} text="Cust Reference 2" />
				<GridText width={3} color="primary" text={getVal(bill.refNumber2)} />
				<GridText width={3} text="Terms" />
				<GridText width={3} color="primary" text={getVal(bill.shippingDetail.terms)} />
				<GridText width={3} text="Distribution Code" />
				<GridText width={3} color="primary" text={getVal(bill.glCode)} />
				<GridText width={3} text="EDI / Manual" />
				<GridText width={3} color="primary" text={bill.legacyEDI ? "E" : "M"} />
				<GridText width={3} text="Cost/Profit Center" />
				<GridText width={3} color="primary" text={getVal(bill.distCode)} />
				<GridText width={3} text="Store #" />
				<GridText width={3} color="primary" text={getVal(bill.storeNumber)} />
				<GridText width={3} text="Pieces" />
				<GridText width={3} color="primary" text={getVal(bill.shippingDetail.totalPieces)} />
				<GridText width={3} text="Weight" />
				<GridText width={3} color="primary" text={getVal(bill.shippingDetail.totalWeight)} />
				<GridText width={3} text="Last Edit" />
				<GridText width={3} color="primary" text={this.getLastEdit()} />
				<GridText width={3} text="Reweigh Weight" />
				<GridText width={3} color="primary" text={getVal(bill.shippingDetail.reweighWeight)} />
				<GridText width={3} text="Reconcile Status" />
				<GridText width={3} color="primary" text={getVal(bill.reconcileStatus)} />
				<GridText width={3} text="Dimensional Weight" />
				<GridText width={3} color="primary" text={getVal(bill.shippingDetail.dimWeight)} />
			</BillDataContainer>
		);
	}

	getLastEdit = () => {
		const bill = this.props.bill
		return Array.isArray(bill.history) && bill.history.length && typeof bill.history[bill.history.length - 1] === "object"
			? bill.history[bill.history.length - 1].lastModified
			: "";
	}
}

class BillLocations extends Component {
	render() {
		const data = this.props.bill.shippingDetail;
		const g = {style: {minHeight: "24px"}};
		const gBorder = {style: {minHeight: "24px", borderBottom: `1px solid ${cardBorderColor}`}};
		return (
			<BillDataContainer title="Locations">
				<GridText gridItemProps={g} width={12} text="Shipper" />
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.originName)}`} />
				{(data.originContact.length) ?
					<GridText gridItemProps={g} width={12} color="primary" text={`ATTN: ${getVal(data.originContact)}`} />
				: null}
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.originAddress1)} ${getVal(data.originAddress2)}`} />
				<GridText gridItemProps={gBorder} width={12} color="primary" text={`${getVal(data.originCity)} ${getVal(data.originState)} ${getVal(data.originPostalCode)}`} />
				<GridText gridItemProps={g} width={12} text="Consignee" />
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.destinationName)}`} />
				{(data.destinationContact.length) ?
					<GridText gridItemProps={g} width={12} color="primary" text={`ATTN: ${getVal(data.destinationContact)}`} />
				: null}
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.destinationAddress1)} ${getVal(data.destinationAddress2)}`} />
				<GridText gridItemProps={gBorder} width={12} color="primary" text={`${getVal(data.destinationCity)} ${getVal(data.destinationState)} ${getVal(data.destinationPostalCode)}`} />
				<GridText gridItemProps={g} width={12} text="Bill To" />
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.billToName)}`} />
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.billToAddress1)} ${getVal(data.billToAddress2)}`} />
				<GridText gridItemProps={g} width={12} color="primary" text={`${getVal(data.billToCity)} ${getVal(data.billToState)} ${getVal(data.billToPostalCode)}`} />
				<GridText gridItemProps={{style: {minHeight: "22px"}}} width={12} />
			</BillDataContainer>
		);
	}
}

class BillDates extends Component {
	render() {
		const bill = this.props.bill;
		return (
			<BillDataContainer title="Dates">
				<GridText width={2} text="Created" />
				<GridText width={2} text="Ship Date" />
				<GridText width={2} text="Delivery Date" />
				<GridText width={2} text="Carrier Invoice Date" />
				<GridText width={4} text="Audit Date" />
				<GridText width={2} color="primary" text={this.getCreatedTime()} />
				<GridText width={2} color="primary" text={this.formatDate(getVal(bill.shippingDetail.shipDate))} />
				<GridText width={2} color="primary" text={this.formatDate(getVal(bill.shippingDetail.deliveryDate))} />
				<GridText width={2} color="primary" text={this.formatDate(getVal(bill.carrierInvDate))} />
				<GridText width={4} color="primary" text={this.formatDate(getVal(bill.datePosted))} />
			</BillDataContainer>
		);
	}

	formatMySQLDate = date => {
		if (typeof date === "string" && date.length === 10) {
			return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
		}
	}

	formatDate = date => {
		if (typeof date === "string" && date.length >= 10) {
			return date.substring(0, 10);
		}
		return date;
	}

	getCreatedTime = () => {
		const bill = this.props.bill
		return Array.isArray(bill.history) && bill.history.length && typeof bill.history[0] === "object"
			? this.formatDate(bill.history[0].lastModified)
			: "";
	}
}

class PaymentInformation extends Component {
	render() {
		const bill = this.props.bill;
		return (
			<BillDataContainer title="Financial Information" style={{backgroundColor: financialDetailsBgColor}}>
				<GridText width={1} text="Billed" />
				<GridText width={1} color="primary" text={this.format(this.props.userIsCarrier ? bill.financialInfo.carrierTotalBilled : bill.financialInfo.trueCost)} />
				<GridText width={1} text="GST" />
				<GridText width={1} color="primary" text={this.format(bill.gst)} />
				<GridText width={1} text="PST" />
				<GridText width={1} color="primary" text={this.format(bill.pst)} />
				<GridText width={1} text="HST" />
				<GridText width={1} color="primary" text={this.format(bill.hst)} />
				<GridText width={1} text="QST" />
				<GridText width={1} color="primary" text={this.format(bill.qst)} />
				<GridItem xs={12}><h5><strong>CARRIER PAYMENTS</strong></h5></GridItem>
				{bill.carrierChecks.length 
					? (
						<Fragment>
							{bill.carrierChecks.map((check, index) => {
								return (
									this.props.hideAmount && !this.props.userIsCarrier ? (
										<Fragment key={index}>
											<GridText width={2} text="Check#" />
											<GridText width={2} color="primary" text={check.check_number} />
											<GridText width={2} text="Date" />
											<GridText width={2} color="primary" text={this.formatDate(check.payment_date)} />
										</Fragment>
									) : (
										<Fragment key={index}>
											<GridText width={2} text="Check#" />
											<GridText width={2} color="primary" text={check.check_number} />
											<GridText width={2} text="Amount" />
											<GridText width={2} color="primary" text={this.format(check.amount)} />
											<GridText width={2} text="Date" />
											<GridText width={2} color="primary" text={this.formatDate(check.payment_date)} />
										</Fragment>
									)
								);
							})}
						</Fragment>
					)
					: <GridText width={2} color="textSecondary" text="No Carrier Payments" />
				}
				{!this.props.userIsCarrier &&
					<>
						<GridItem xs={12}><h5><strong>CUSTOMER PAYMENTS</strong></h5></GridItem>
						{(bill.payments.payments && bill.payments.payments.length)
							? (
								<Fragment>
									{bill.payments.payments.map((check, index) => {
										return (
											this.props.hideAmount ? (
												<Fragment key={index}>
													<GridText width={2} text="Check#" />
													<GridText width={2} color="primary" text={check.checkNumber} />
													<GridText width={2} text="Date" />
													<GridText width={2} color="primary" text={this.formatDate(check.payDate)} />
												</Fragment>
											) : (
												<Fragment key={index}>
													<GridText width={2} text="Check#" />
													<GridText width={2} color="primary" text={check.checkNumber} />
													<GridText width={2} text="Amount" />
													<GridText width={2} color="primary" text={this.format(check.paid)} />
													<GridText width={2} text="Date" />
													<GridText width={2} color="primary" text={this.formatDate(check.payDate)} />
												</Fragment>
											)
										);
									})}
								</Fragment>
							)
							: <GridText width={2} color="textSecondary" text="No Customer Payments" />}
						{bill.correctionCode != null && (
							<GridItem xs={12}>
								<Fragment>Correction Code: {bill.correctionCode}</Fragment>
							</GridItem>
						)}
						<GridItem xs={12}>
							<Fragment>Adjustment Remark: {bill.notes.adjNote}</Fragment>
						</GridItem>
					</>
				}
			</BillDataContainer>
		);
	}

	formatDate = date => {
		if (typeof date === "string" && date.length >= 10) {
			return moment(date.substring(0, 10), "YYYY-MM-DD").format("MM/DD/YYYY");
		}
		return date;
	}

	format = currency => {
		return currency ? `$${(+currency).toFixed(2)}` : "";
	}
}

class CustomerNotes extends Component {
	render() {
		const bill = this.props.bill;
		return (
			<BillDataContainer title="Notes">
				<GridText width={12} noWrap={false} color="primary" text={getVal(bill.notes.customerNotes)} />
			</BillDataContainer>
		);
	}
}

class HoldTable extends Component {
	render() {
		const bill = this.props.bill;
		const rows = Array.isArray(bill.holds) ? bill.holds : [];

		const formatDate = date => {
			if (!date) return "";
			const mDate = moment(date);
			if (mDate.isValid()) {
				return mDate.format("MM/DD/YYYY h:mm:ss A");
			}
			return "";
		}

		return (
			<BillDataContainer title="Applied Holds" cardContentStyle={{paddingTop: "0px", paddingBottom: "0px", marginBottom: "16px"}}>
				<TableContainer component={Paper}>
			      <Table size="small">
			        <TableHead>
			          <TableRow>
			            <TableCell>Code/Desc.</TableCell>
			            <TableCell>Hold Remark</TableCell>
			            <TableCell>Hold Timestamp</TableCell>
			            <TableCell>Release Timestamp</TableCell>
			            <TableCell>Release Remark</TableCell>
			          </TableRow>
			        </TableHead>
			        <TableBody>
			        	{!rows.length ? (
			        		<TableRow>
			        			<TableCell colSpan={5} align="center"><span style={{color: "gray"}}>No Holds</span></TableCell>
			        		</TableRow>
			        	) : (
			        		rows.map((item, key) => {
			        			const hold = typeof item === "object" && item !== null ? item : {};
				        		return (
				        			<TableRow key={key}>
										<TableCell>{`${hold.code} - ${hold.name}`}</TableCell>
										<TableCell>{hold.note}</TableCell>
										<TableCell>{formatDate(hold.timestamp)}</TableCell>
										<TableCell>{formatDate(hold.releaseTimestamp)}</TableCell>
										<TableCell>{hold.releaseNote}</TableCell>
									</TableRow>
				        		)
						}))}
			        </TableBody>
			      </Table>
			    </TableContainer>
			</BillDataContainer>
		);
	}
}
const mapStateToProps = state => {
    return {
        userIsCarrier: userIsCarrier(state),
        restrictPaymentInfo: +getUserSetting('restrict_payment_info_access', 0)(state),
        restrictProducts: +getUserSetting('restrict_shipment_products_access', 0)(state),
    };
}

export default connect(mapStateToProps)(CarrierBill);