import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink, Redirect, Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import moment from "moment";
import qs from "qs";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import { basePath } from "~/variables/server.jsx";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import { Grid } from "@material-ui/core";
import Select from "@material-ui/core/Select";

import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import ClearAll from "@material-ui/icons/ClearAll";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const ITEM_HEIGHT = 48;
class ScheduledReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      redirect: null,
      body: null,
      user: null,
      current: "1",
      last: "1",
      pages: [],
      alert: null,
      open: false,
      anchorEl: false,
      currentRow: 0,
      currentReportId: 0,
      anchorElEmail: false,
      recipient: "",
      currentRecipient: [],
      showInputField: false,
      shownUploadField: false,
      search: false,
      filters: [
        {
          field: "end_date",
          compare: "contains",
          value: "",
        },
      ],
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleDeletereports = this.handleDeletereports.bind(this);
    this.getPages = this.getPages.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRecipientClick = this.handleRecipientClick.bind(this);
    this.addRecipient = this.addRecipient.bind(this);
    this.showInputField = this.showInputField.bind(this);
    this.showUploadField = this.showUploadField.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getUpdatedRecipient = this.getUpdatedRecipient.bind(this);
  }
  async componentDidMount() {
    this.setState({ mounted: true });

    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitions"
      );
      const data = response.data;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (
        typeof data !== "string" &&
        !_.isEmpty(data.body) &&
        !_.isEmpty(data.user)
      ) {
        this.setState({
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }
  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handleRecipientClick(e, id, recipient) {
    var rec = recipient.filter((e) => e);
    this.setState({ currentRow: id });
    this.setState({ currentRecipient: rec });
    this.setState({ anchorElEmail: e.currentTarget });
    this.setState({ showInputField: false });
  }
  handleClick(e, prop) {
    this.setState({ currentRow: prop.id });
    this.setState({ currentReportId: prop.reportdefinitionid });
    this.setState({ anchorEl: e.currentTarget });
  }
  async handleClose() {
    this.setState({ anchorElEmail: false });
    this.setState({ anchorEl: false });
  }

  handleInput = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  showInputField() {
    this.setState({ showInputField: true });
  }

  stopPropagation = (e) => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  moveFocusToInput = (e) => {
    if (e.key === "Tab" || e.key === "ArrowRight") {
      e.stopPropagation();
      e.preventDefault();
      filterRef.current.focus();
    }
  };

  showUploadField() {
    this.setState({ shownUploadField: true });
  }
  

  getFilters(filters) {
    const { classes } = this.props;
    return filters.map((prop, key) => {
      return (
        <GridItem xs={12} key={key}>
          <GridContainer>
            <GridItem xs={2}>
              {key == 0 ? (
                <IconButton
                  aria-label="add filter"
                  size="small"
                  color="primary"
                  className={classes.marginRight}
                  style={{ float: "right" }}
                  onClick={() => this.addFilter()}
                >
                  <Add />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="remove filter"
                  size="small"
                  color="secondary"
                  className={classes.marginRight}
                  style={{ float: "right" }}
                  onClick={() => this.removeFilter(key)}
                >
                  <Close />
                </IconButton>
              )}
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={prop.field || ""}
                  inputProps={{ name: "field" }}
                  onChange={(e) => this.handleFilters(key, "field", e)}
                >
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="end_date"
                  >
                    End on
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="last_run"
                  >
                    Last Run
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="recipients"
                  >
                    Recipients
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="name"
                  >
                    Report Name
                  </MenuItem>

                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="schedule"
                  >
                    Schedule
                  </MenuItem>

                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="start_date"
                  >
                    Started on
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  value={prop.compare || ""}
                  inputProps={{ name: "compare" }}
                  onChange={(e) => this.handleFilters(key, "compare", e)}
                >
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="contains"
                  >
                    contains
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="ends with"
                  >
                    ends with
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="equals"
                  >
                    equals
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.selectMenuItem }}
                    value="starts with"
                  >
                    starts with
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "text",
                  value: prop.value || "",
                  onChange: (e) => this.handleFilters(key, "value", e),
                  onKeyPress: (e) => this.handleKeyPress(e),
                }}
                white
              />
            </GridItem>
            <GridItem xs={3}>
              {key == 0 && (
                <Button
                  size="sm"
                  color="linkedin"
                  className={classes.marginLeft}
                  onClick={() => this.handleSearch()}
                >
                  <Search /> Search
                </Button>
              )}
              {key == 0 &&
                (this.state.filters.length > 1 || prop.value !== "") && (
                  <Button
                    size="sm"
                    color="white"
                    onClick={() => this.clearFilters()}
                  >
                    <ClearAll /> Clear Filters
                  </Button>
                )}
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    });
  }

  addFilter() {
    const { filters } = this.state;
    filters.push({
      field: "name",
      compare: "starts with",
      value: "",
    });
    this.setState({ filters });
  }

  removeFilter(key) {
    const { filters } = this.state;
    if (!_.isEmpty(filters) && key != 0) {
      filters.splice(key, 1);
      this.setState({ filters });
    }
  }

  async clearFilters() {
    this.setState({
      show: false,
      loading: true,
      search: false,
      filters: [
        {
          field: "name",
          compare: "starts with",
          value: "",
        },
      ],
    });
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitions"
      );
      const { data } = response;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (typeof data !== "string" && !_.isEmpty(data.body)) {
        this.setState({
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error loading the  scheduled reports!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error loading the scheduled reports!",
        {
          variant: "error",
        }
      );
    }
  }

  handleFilters(key, name, event) {
    const { filters } = this.state;
    filters[key][name] = event.target.value;
    this.setState({ filters });
  }

  async handleSearch() {
    const { filters } = this.state;
    const data = { queries: filters };
    const url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=filterReportSchedule";

    this.setState({
      show: false,
      loading: true,
    });

    try {
      const response = await axios.post(url, qs.stringify(data));
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string") {
        this.setState({
          search: true,
          show: true,
          loading: false,
          user: response.data.user,
          current: response.data.body.current
            ? response.data.body.current.toString()
            : "1",
          last: response.data.body.last
            ? response.data.body.last.toString()
            : "1",
          pages: response.data.body.pages || [],
          result: response.data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error searching for scheduled reports!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error searching for scheduled reports!",
        { variant: "error" }
      );
    }
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSearch();
    }
  }

  async handlePageClick(page) {
    this.setState({ show: false, loading: true });
    const { filters, search } = this.state;
    const data = { queries: filters };
    let url =
      search == true
        ? "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=filterReportSchedule&d=" +
          page +
          "/5"
        : "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitions&d=" +
          page +
          "/5";
    try {
      const response = search
        ? await axios.post(url, qs.stringify(data))
        : await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
        this.setState({
          show: true,
          loading: false,
          user: response.data.user,
          current: response.data.body.current
            ? response.data.body.current.toString()
            : "1",
          last: response.data.body.last
            ? response.data.body.last.toString()
            : "1",
          pages: response.data.body.pages || [],
          result: response.data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading!", {
        variant: "warning",
      });
    }
  }

  async getUpdatedRecipient() {
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=scheduledReportDefinitions"
      );
      const data = response.data;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (
        typeof data !== "string" &&
        !_.isEmpty(data.body) &&
        !_.isEmpty(data.user)
      ) {
        this.setState({
          result: data.body || null,
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }

  async addRecipient() {
    this.setState({ loading: true });

    const { recipient, currentRecipient } = this.state;

    if (currentRecipient.indexOf(recipient) !== -1) {
      this.props.setNotification("Email already exist!", {
        variant: "warning",
      });
      return false;
    }

    if (recipient === "") {
      this.setState({ loading: false });
      this.props.setNotification("A email must be provided !", {
        variant: "warning",
      });
      return false;
    }

    currentRecipient.push(recipient);
    const result = currentRecipient.filter((e) => e);
    let data = { currentRecipient: result };

    let url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=editRecipients&d=" +
      this.state.currentRow;

    try {
      const response = await axios.post(url, qs.stringify(data));
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
        this.setState({ loading: true });
        this.setState({ recipient: "" });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error creating the reportType!",
          { variant: "warning" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error creating the reportType!",
        {
          variant: "warning",
        }
      );
    }

    this.getUpdatedRecipient();
  }

  getPages() {
    const { current, last } = this.state;

    let pages = [{ active: true, text: "..." }];
    const nextPage = { text: ">" };
    const prevPage = { text: "<" };

    if (last > 1 && !_.isEmpty(this.state.pages)) {
      pages = this.state.pages.map((index) => {
        const page = { text: index };
        if (index == parseInt(current)) {
          page.active = true;
        } else {
          page.onClick = (e) => this.handlePageClick(index);
        }
        return page;
      });
    }

    if (current < last) {
      nextPage.onClick = (e) => this.handlePageClick(parseInt(current) + 1);
    } else {
      nextPage.disabled = true;
    }

    if (parseInt(current) - 1 !== 0) {
      prevPage.onClick = (e) => this.handlePageClick(parseInt(current) - 1);
    } else {
      prevPage.disabled = true;
    }

    pages.push(nextPage);
    pages.unshift(prevPage);

    return pages;
  }

  async deletereports(id) {
    const url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=deleteScheduledReportDefinition&d=" +
      id;
    this.setState({ show: false, loading: true, alert: null });
    try {
      const response = await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.setState({ currentRow: 0, anchorEl: false });
        this.props.handleMessages(response);
        if(response.data.body) {
          this.handlePageClick(this.state.current);
        } else {
          this.setState({ show: true, loading: false });
        }
      }
    } catch (error) {
      this.setState({ currentRow: 0, anchorEl: false, show: true, loading: false });
      this.props.setNotification(
        "There was an error deleting the report schedule!",
        { variant: "warning" }
      );
    }
  }
  handleDeletereports(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{
            display: "block",
            color: "black",
          }}
          title="Are you sure you want to delete this scheduled report?"
          onConfirm={() => this.deletereports(id)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, delete it!"
          cancelBtnText="No!"
          showCancel
        >
          This action cannot be undone!
        </SweetAlert>
      ),
    });
  }
  getHeaders() {
    return [
      "#",
      "Report Name",
      "Type",
      "Schedule",
      "Last Run",
      "Started On",
      "Ended On",
      "Recipients",
      "Actions",
    ];
  }
  getColumns(data) {
    if (data.result != undefined) {
      return data.result.map((prop, key) => {
        return [
          prop.id,
          prop.name,
          prop.remark,
          prop.schedule,
          prop.last_run != null || prop.last_run != "" ? prop.last_run : "",
          prop.start_date != null
          ? moment(prop.start_date).format("MM/DD/YY")
          : "",

          prop.end_date != null && prop.schedule != "Never"
            ? moment(prop.end_date).format("MM/DD/YY")
            : "",
          <div key={key}>
            <PersonOutlineIcon />
            {prop.recipients != null
              ? prop.recipients.split(",").filter(function(x) {
                  return x != "";
                }).length
              : null}

            <IconButton
              aria-label="more"
              aria-controls="recipient-menu"
              aria-haspopup="true"
              onClick={(event) =>
                this.handleRecipientClick(
                  event,
                  prop.id,
                  prop.recipients.split(",")
                )
              }
            >
              <ExpandMoreIcon />
            </IconButton>
            {/* ) : null} */}
            <Menu
              id="recipient-menu"
              anchorEl={this.state.anchorElEmail}
              keepMounted
              open={this.state.anchorElEmail}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 6.5,
                  width: "40ch",
                },
              }}
            >
              <MenuItem button={false} onKeyDown={this.moveFocusToInput}>
                {this.state.showInputField ? (
                  <ValidatorForm
                    autoComplete="off"
                    onSubmit={this.addRecipient}
                  >
                    <Paper
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "30ch",
                      }}
                    >
                      <TextValidator
                        id="outlined-size-normal"
                        defaultValue="Normal"
                        placeholder="Enter email address"
                        validators={["isEmail"]}
                        errorMessages={["email is not valid"]}
                        style={{ paddingLeft: "15px" }}
                        type="text"
                        name="recipient"
                        value={this.state.recipient || ""}
                        maxLength="40"
                        size="small"
                        onChange={this.handleInput("recipient")}
                        onKeyDown={this.stopPropagation}
                      />
                      <Button size="sm" color="linkedin" type="submit">
                        Add
                      </Button>
                    </Paper>
                  </ValidatorForm>
                ) : (
                  <div>
                    <Button
                      style={{ width: "40ch" }}
                      color="linkedin"
                      onClick={this.showInputField}
                      disableElevation
                    >
                      Add
                    </Button>
                  </div>
                )}
              </MenuItem>

              <div>
                {this.state.currentRecipient.map((option, key) => (
                  <MenuItem key={key} onClick={this.handleClose}>
                    {option}
                  </MenuItem>
                ))}
              </div>
            </Menu>
          </div>,
          <div key={key} className="actions-right">
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => this.handleClick(event, prop)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={this.state.anchorEl}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                <MenuItem
                  component={Link}
                  to={
                    basePath +
                    "/admin/report-scheduler-edit/" +
                    this.state.currentRow
                  }
                >
                  Edit
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={
                    basePath + "/admin/run-report/" + this.state.currentReportId
                  }
                >
                  Run
                </MenuItem>

                <MenuItem
                  style={{ color: "#FF0000" }}
                  onClick={() =>
                    this.handleDeletereports(this.state.currentRow)
                  }
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          </div>,
        ];
      });
    }
    return data.map((prop, key) => {
      return [
        prop.id,
        prop.name,
        prop.remark,
        prop.schedule,
        prop.last_run != null || prop.last_run != "" ? prop.last_run : "",
        prop.start_date != null
          ? moment(new Date(prop.start_date)).format("MM/DD/YYYY")
          : "",
        prop.end_date != null
          ? moment(new Date(prop.end_date)).format("MM/DD/YYYY")
          : "",
        <div key={key}>
          <PersonOutlineIcon />
          {prop.recipients != null
            ? prop.recipients.split(",").filter(function(x) {
                return x != "";
              }).length
            : null}
          {prop.recipients != null && prop.recipients.length != 0 ? (
            <IconButton
              aria-label="more"
              aria-controls="recipient-menu"
              aria-haspopup="true"
              onClick={(event) =>
                this.handleRecipientClick(
                  event,
                  prop.id,
                  prop.recipients.split(",")
                )
              }
            >
              <ExpandMoreIcon />
            </IconButton>
          ) : null}
          <Menu
            id="recipient-menu"
            anchorEl={this.state.anchorElEmail}
            keepMounted
            open={this.state.anchorElEmail}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 6.5,
                width: "40ch",
              },
            }}
          >
            <MenuItem key={21}>
              {this.state.showInputField ? (
                <ValidatorForm autoComplete="off" onSubmit={this.addRecipient}>
                  <Paper
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "30ch",
                    }}
                  >
                    <TextValidator
                      defaultValue="Normal"
                      placeholder="Enter email address"
                      validators={["isEmail"]}
                      errorMessages={["email is not valid"]}
                      style={{ paddingLeft: "15px" }}
                      type="text"
                      id="recipient"
                      name="recipient"
                      value={this.state.recipient || ""}
                      maxLength="40"
                      size="small"
                      onChange={this.handleInput("recipient")}
                    />
                    <Button size="sm" color="linkedin" type="submit">
                      Add
                    </Button>
                  </Paper>
                </ValidatorForm>
              ) : (
                <div>
                  <Button
                    style={{ width: "40ch" }}
                    color="linkedin"
                    onClick={this.showInputField}
                    disableElevation
                  >
                    Add
                  </Button>
                </div>
              )}
            </MenuItem>

            {this.state.currentRecipient.map((option, key) => (
              <MenuItem key={key} onClick={this.handleClose}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>,
        <div className="actions-right">
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => this.handleClick(event, prop)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={this.state.anchorEl}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem
                component={Link}
                to={
                  basePath +
                  "/admin/report-scheduler-edit/" +
                  this.state.currentRow
                }
              >
                Edit
              </MenuItem>
              <MenuItem
                component={Link}
                to={
                  basePath + "/admin/run-report/" + this.state.currentReportId
                }
              >
                Run
              </MenuItem>

              <MenuItem
                style={{ color: "#FF0000" }}
                onClick={() => this.handleDeletereports(this.state.currentRow)}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>,
      ];
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.redirect) {
      return this.state.redirect;
    }

    return (
      <GridContainer>
        <GridItem style={{ paddingTop: "10px" }} xs={12} sm={12} md={12}>
          <Grid container>
            {!_.isEmpty(this.state.filters) &&
              this.getFilters(this.state.filters)}
          </Grid>
        </GridItem>

        <Grid container>
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Pagination pages={this.getPages()} />
          </GridItem>
        </Grid>

        {this.state.alert}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                {this.state.show ? (
                  <GridItem xs={12} sm={12} md={12}>
                    {!_.isEmpty(this.state.result) ? (
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              {this.getHeaders().map((cell) => (
                                <TableCell>{cell}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.getColumns(this.state.result).map(
                              (row, key) => (
                                <TableRow key={key}>
                                  {row.map((cell) => (
                                    <TableCell component="th" scope="row">
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <p className={classes.center}>
                        <b>{"No scheduled report to display"}</b>
                      </p>
                    )}
                  </GridItem>
                ) : (
                  <Spinner
                    loading={this.state.loading}
                    message="Failed to retrieve scheduled report from the server"
                  />
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(ScheduledReport)));
