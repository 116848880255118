import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {
	setError,
	getUserSession,
} from "~/redux/actions/Admin.jsx";
import Admin from "~/layouts/Admin.jsx";

const mapStateToProps = (state, ownProps) => {
	const { response } = state.Admin;
	let session = {
		environment: "",
		user: {
			id: "",
			parent_id: "",
			name: "",
			authenticated: false,
			level: "none",
			permissions: [],
			user_company_settings: [],
			user_settings: []
		},
		notice: [],
		loaded: false,
		isAuthenticated: false
	};
	if (response && response.data) {
		session = {
			environment: response.data.environment_status || "",
			user: response.data.user,
			loaded: true,
			isAuthenticated: typeof response.data.user === "object" ? response.data.user.authenticated : false,
			notice: response.data.notice
		};
	}
	return {
		response: state.Admin.response,
		session
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setError,
		getUserSession
	}, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Admin);
