import axios from '../../../variables/axios.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import { hist } from "../../../App.jsx";
import { basePath } from "~/variables/server.jsx";

export const setRateDisplayValue = (field, val) => {
	const map = {
		id: "SET_RATE_DISPLAY_ID",
		loading: "SET_RATE_DISPLAY_LOADING",
		name: "SET_RATE_DISPLAY_NAME",
		rated: "SET_RATE_DISPLAY_RATED",
		true_cost: "SET_RATE_DISPLAY_TRUE_COST",
		true_cost_minus_markup: "SET_RATE_DISPLAY_TRUE_COST_MINUS_MARKUP",
		benchmark: "SET_RATE_DISPLAY_BENCHMARK",
		discount: "SET_RATE_DISPLAY_DISCOUNT",
		min: "SET_RATE_DISPLAY_MIN",
		gross: "SET_RATE_DISPLAY_GROSS",
		accessorials: "SET_RATE_DISPLAY_ACCESSORIALS",
		fuel: "SET_RATE_DISPLAY_FUEL",
		only_show_chosen_carrier: "SET_RATE_DISPLAY_ONLY_SHOW_CHOSEN_CARRIER",
		transit_calendar_days: "SET_RATE_DISPLAY_TRANSIT_CALENDAR_DAYS",
		rated_alias: "SET_RATE_DISPLAY_RATED_ALIAS",
		true_cost_alias: "SET_RATE_DISPLAY_TRUE_COST_ALIAS",
		true_cost_minus_markup_alias: "SET_RATE_DISPLAY_TRUE_COST_MINUS_MARKUP_ALIAS",
		benchmark_alias: "SET_RATE_DISPLAY_BENCHMARK_ALIAS",
		discount_alias: "SET_RATE_DISPLAY_DISCOUNT_ALIAS",
		min_alias: "SET_RATE_DISPLAY_MIN_ALIAS",
		gross_alias: "SET_RATE_DISPLAY_GROSS_ALIAS",
	};

	return Object.keys(map).indexOf(field) !== -1
		? {type: map[field], val}
		: {type: "RATE_DISPLAY_INVALID_FIELD"};
}

export const clearState = () => {
	return {"type": "CLEAR_RATE_DISPLAY"};
}

export const setCollection = val => {
	return {"type": "SET_RATE_DISPLAY_COLLECTION", val};
}

export const loadRateDisplay = id => {
	return async (dispatch) => {
		dispatch(clearState());
		dispatch(setRateDisplayValue("loading", true));
		try {
			let url = `index.php?p=api&r=json&c=rateDisplays&m=collection&id=${id}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				const data = response.data.body.response;
				dispatch(setRateDisplayValue("name", data.name));
				dispatch(setRateDisplayValue("id", +data.id));
				dispatch(setRateDisplayValue("rated", !!+data.rated));
				dispatch(setRateDisplayValue("true_cost", !!+data.true_cost));
				dispatch(setRateDisplayValue("true_cost_minus_markup", !!+data.true_cost_minus_markup));
				dispatch(setRateDisplayValue("benchmark", !!+data.benchmark));
				dispatch(setRateDisplayValue("discount", !!+data.discount));
				dispatch(setRateDisplayValue("min", !!+data.min));
				dispatch(setRateDisplayValue("gross", !!+data.gross));
				dispatch(setRateDisplayValue("accessorials", !!+data.accessorials));
				dispatch(setRateDisplayValue("fuel", !!+data.fuel));
				dispatch(setRateDisplayValue("only_show_chosen_carrier", !!+data.only_show_chosen_carrier));
				dispatch(setRateDisplayValue("transit_calendar_days", !!+data.transit_calendar_days));
				if (data.alias) {
					dispatch(setRateDisplayValue("rated_alias", data.alias.rated ? data.alias.rated : null));
					dispatch(setRateDisplayValue("true_cost_alias", data.alias.true_cost ? data.alias.true_cost : null));
					dispatch(setRateDisplayValue("true_cost_minus_markup_alias", data.alias.true_cost_minus_markup ? data.alias.true_cost_minus_markup : null));
					dispatch(setRateDisplayValue("benchmark_alias", data.alias.benchmark ? data.alias.benchmark : null));
					dispatch(setRateDisplayValue("discount_alias", data.alias.discount ? data.alias.discount : null));
					dispatch(setRateDisplayValue("min_alias", data.alias.min ? data.alias.min : null));
					dispatch(setRateDisplayValue("gross_alias", data.alias.gross ? data.alias.gross : null));
				}
			}
		} catch (err) {
			dispatch(setNotification("Error loading rate display", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setRateDisplayValue("loading", false));
		}
	}
}

export const loadCollection = () => {
	return async (dispatch) => {
		dispatch(setRateDisplayValue("loading", true));
		try {
			let url = `index.php?p=api&r=json&c=rateDisplays&m=collection&limit=9999`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				dispatch(setCollection(response.data.body.response));
			}
		} catch (err) {
			dispatch(setNotification("Error loading rate displays", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setRateDisplayValue("loading", false));
		}
	}
}

export const saveRateDisplay = () => {
	return async (dispatch, getState) => {
		const state = getState().RateDisplay;
		dispatch(setRateDisplayValue("loading", true));
		try {
			const payload = {
				name: state.name,
				benchmark: +state.benchmark,
				true_cost: +state.true_cost,
				true_cost_minus_markup: +state.true_cost_minus_markup,
				rated: +state.rated,
				fuel: +state.fuel,
				accessorials: +state.accessorials,
				discount: +state.discount,
				min: +state.min,
				gross: +state.gross,
				only_show_chosen_carrier: +state.only_show_chosen_carrier,
				transit_calendar_days: +state.transit_calendar_days,
				alias: {
					benchmark: state.benchmark_alias ? state.benchmark_alias : null,
					true_cost: state.true_cost_alias ? state.true_cost_alias : null,
					true_cost_minus_markup: state.true_cost_minus_markup_alias ? state.true_cost_minus_markup_alias : null,
					rated: state.rated_alias ? state.rated_alias : null,
					discount: state.discount_alias ? state.discount_alias : null,
					min: state.min_alias ? state.min_alias : null,
					gross: state.gross_alias ? state.gross_alias : null,
				}
			};

			const config = {headers: {'Content-Type': 'application/json'}};

			let url = `index.php?p=api&r=json&c=rateDisplays&m=save`;
			if (state.id) {
				url += `&id=${state.id}`;
			}
			const response = await axios.post(url, payload, config);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				dispatch(setRateDisplayValue("id", response.data.body.response.id));
				hist.push(basePath + '/admin/rate-displays');
			}
		} catch (err) {
			dispatch(setNotification("Error saving rate display", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setRateDisplayValue("loading", false));
		}
	}
}

export const deleteRateDisplay = id => {
	return async (dispatch, getState) => {
		dispatch(setRateDisplayValue("loading", true));
		try {
			let url = `index.php?p=api&r=json&c=rateDisplays&m=delete&id=${id}`;
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.deleted) {
				dispatch(setCollection(getState().RateDisplay.collection.filter(rd => rd.id != id)));
			}
		} catch (err) {
			dispatch(setNotification("Error loading rate display", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setRateDisplayValue("loading", false));
		}
	}
}