const initialState = {
    filters: [], // Array of filters to be applied to the search ex. {field: "", compare: "", value: "", route: ""}
};

export default function(state = initialState, action) {
    const newState = state;
	switch(action.type){
        case "ADD_FILTER":
            return {...newState, filters: [...newState.filters, action.payload]};
        case "SET_FILTERS":
            return {...newState, filters: action.payload};
        default:
            return state
	}
}
