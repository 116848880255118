const initialState = {
	response: {},
	error: [],
	user: null,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case "SET_RESPONSE": {
			return Object.assign({}, state, {
				response: action.payload
			});
		}
		case "SET_ERROR": {
			return Object.assign({}, state, {
				error: state.error.concat(action.payload)
			});
		}
		case "SET_USER_SESSION_DATA": {
			return {
				...state,
				user: action.data,
			}
		}
		default:
			return state;
	}
}
