import {
	uiColors,
	defaultFont,
	whiteColor,
	blackColor,
	grayColor,
	hexToRgb
} from "assets/jss/empire-tms.jsx";

const customInputStyle = {
	disabled: {
		"&:before": {
			borderColor: "transparent !important"
		}
	},
	underline: {
		"&:hover:not($disabled):before,&:before": {
			borderColor: grayColor[4] + "!important",
			borderWidth: "2px !important"
		},
		"&:after": {
			borderColor: uiColors.primary.mid // added
		}
	},
	underlineError: {
		"&:after": {
			borderColor: uiColors.danger.default
		}
	},
	underlineSuccess: {
		"&:after": {
			borderColor: uiColors.success.default
		}
	},
	labelRoot: {
		...defaultFont,
		// color: grayColor[3] + " !important",
		zIndex: "1 !important", // added
		fontWeight: "400",
		fontSize: "14px",
		lineHeight: "1.42857",
		top: "10px",
		"& + $underline": {
			marginTop: "0px"
		}
	},
	labelRootError: {
		color: uiColors.danger.default + " !important"
	},
	labelRootSuccess: {
		color: uiColors.success.default + " !important"
	},
	formControl: {
		margin: "0 0 17px 0",
		paddingTop: "27px",
		position: "relative",
		verticalAlign: "unset",
		"& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
			// color: grayColor[14]
			color: grayColor[7], // added
		}
	},
	formControlLabel: {
		//margin: "0 0 17px 0",
		paddingTop: "0px",
		position: "relative",
		verticalAlign: "unset",
		"& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
			// color: grayColor[14]
			color: grayColor[7], // added
		}
	},
	whiteUnderline: {
		"&:hover:not($disabled):before,&:before": {
			backgroundColor: whiteColor
		},
		"&:after": {
			backgroundColor: whiteColor
		}
	},
	input: {
		// color: grayColor[14],
		color: grayColor[7], // added
		padding: "7px 0 7px 0",
		margin: "0 0 0 0",
		"&,&::placeholder": {
			fontSize: "14px",
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: "400",
			lineHeight: "1.42857",
			opacity: "1"
		},
		"&::placeholder": {
			color: grayColor[3]
		},
		"&:after": {
			borderColor: uiColors.primary.mid
		}
	},
	whiteInput: {
		backgroundColor: "transparent",
		color: grayColor[7],
		"&:after": {
			backgroundColor: whiteColor
		}
	},
	requiredInput: {
		backgroundColor: uiColors.yellow.lightest,
		borderBottom: "1px solid",
		borderBottomColor: uiColors.warning.darkest,
		color: grayColor[7],
		"&:hover:not($disabled):before,&:before": {
			borderColor: uiColors.warning.default
		},
		"&:after": {
			borderColor: uiColors.warning.darkest
		}
	},
	errorInput: {
		backgroundColor: uiColors.danger.dark,
		color: uiColors.danger.default,
		"&:hover:not($disabled):before,&:before": {
			borderColor: uiColors.danger.default
		},
		"&:after": {
			borderColor: uiColors.danger.darkest
		}
	}
};

export default customInputStyle;
