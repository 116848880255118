import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setNotification, processNotificationById } from "../../redux/actions/notifications.jsx";
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// material-ui icons
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import Notifications from "@material-ui/icons/Notifications";

// core components
import AdminNavbarLinks from "~/components/Navbars/AdminNavbarLinks.jsx";
import Button from "~/components/CustomButtons/Button.jsx";

import adminNavbarStyle from "~/assets/jss/empire-tms/components/adminNavbarStyle.jsx";

import routes from "~/routes.js";

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
		};
	}
	
	getMenuItemsCount() {
		let notices = this.props.notifications.notifications;
		notices = notices.concat(this.props.notifications.processed);
		let count = notices.length;
		return count
	  }

	async componentDidMount() {
		try {
			const session = await routes.getSession();
			if (
				!_.isEmpty(session) &&
				!_.isEmpty(session.data) &&
				!_.isEmpty(session.data.user)
			) {
				this.setState({ user: session.data.user });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error loading the user!",
				{ variant: "error" }
			);
		}
	}

	render() {
		const { classes, color, rtlActive, brandText, notices } = this.props;
		const appBarClasses = cx({
			[" " + classes[color]]: color
		});
		const sidebarMinimize =
			classes.sidebarMinimize +
			" " +
			cx({
				[classes.sidebarMinimizeRTL]: rtlActive
			});
		const wrapper = classNames({
			[classes.wrapperRTL]: rtlActive
		});
		const managerClasses = classNames({
			[classes.managerClasses]: true
		});

		return (
			<AppBar className={classes.appBar + appBarClasses}>
				<Toolbar className={classes.container}>
					<div className={sidebarMinimize}>
						<Button
							justIcon
							round
							color="secondary"
							onClick={this.props.sidebarMinimize}
						>
							<VerticalSplit className={classes.sidebarMiniIcon} />
						</Button>
					</div>
					<div className={classes.flex}>
						{/* Here we create navbar brand, based on route name */}
						<Button
							href="#"
							className={classes.title}
							color="transparent"
						>
							{brandText}
						</Button>
					</div>
					<div className={wrapper}>
						<div className={managerClasses}>
							<Button
								color="transparent"
								justIcon
								aria-label="Notifications"
								aria-owns={open ? "menu-list" : null}
								aria-haspopup="true"
								onClick={this.props.notificationsBarMinimize}
								className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
								muiClasses={{
								label: rtlActive ? classes.labelRTL : ""
								}}
								buttonRef={node => {
								this.anchorEl = node;
								}}
							>
								<Notifications
								className={
									classes.headerLinksSvg +
									" " +
									(rtlActive
									? classes.links + " " + classes.linksRTL
									: classes.links)
								}
								/>
								<span className={classes.notifications}>{this.getMenuItemsCount()}</span>
							</Button>
						</div>
					</div>
				</Toolbar>
			</AppBar>
		);
	}
}

AdminNavbar.defaultProps = {
	notices: []
};

AdminNavbar.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	rtlActive: PropTypes.bool,
	brandText: PropTypes.string,
	notices: PropTypes.array
};

const mapStateToProps = state => {
	return {
		notifications: state.notifications,
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	setNotification,
    	processNotificationById
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(adminNavbarStyle)(AdminNavbar));
