import React, { Component } from "react";
import Select from "@material-ui/core/Select"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "~/components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
//import "./DocumentModal.css";
import { withSnackbar } from "notistack";
import axios from "~/variables/axios.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

class DocumentModal extends Component {

    state = {
        date: null,
        selection: null,
        options: {},
        loading: true,
        mounted: false,
    }


    componentDidMount = () => {
        this.setState({mounted: true});
        this.getOptions();            
    }
    onChangeSelection = momentObj => {
        if (typeof this.props.onChange === "function") {
            this.props.onChange(momentObj);
        }
        this.setState({selection: momentObj})
    }

    onClose = () => {
        if (typeof this.props.onClose === "function") this.props.onClose();
    }

    onSubmit = () => {
        let retArr = {
            options: this.state.options,
            selection: this.state.selection,
        }
        if (typeof this.props.onSubmit === "function" && this.state.selection) this.props.onSubmit(retArr);
    }

    getOptions = async () => {
        try {
            const response = await axios.get("/index.php?p=api&r=json&c=parcel&m=getDocumentTypes&parcelId=" + this.props.parcelId);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.options) && this.state.mounted) {
				const  opts = response.data.body.options;

                let count = Object.keys(opts).length;
                if(count > 1) {
                    opts.ALL = "ALL";
                }
                if(opts != this.state.options) {
                    this.setState({
                        options: opts,
                        loading: false,
                    });
                }
            } else {
			 	this.setState({ loading: false });
			 	this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
    }

    displayOptionsSelections() {
        if(this.state.options == [] || this.state.options == undefined) {
            return(
                <div>
                    No options available at this time
                </div>
            );
        } else {
             return(   
                <div>
                    {
                        Object.entries(this.state.options).map(([key,value],i)=>{
                            return <label key={key}>
                                        <input 
                                            type="radio" 
                                            value={key} 
                                            checked={this.state.selection == key? true: false}
                                            onChange={this.onChangeSelection.bind(this,key)} 
                                            />
                                        {value}
                                    </label>
                        })
                    }
                </div>
            );
        }
    }


    render() {

        let optionDisplay = this.displayOptionsSelections();

        return (
            <Dialog
                open={!!this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogContent id="classic-modal-slide-description">
                    <InputLabel>
                        {this.props.title || "Choose a document"}
                    </InputLabel>
                    <br />
                        {this.displayOptionsSelections()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="white">
                        Cancel
                    </Button>
                    <Button onClick={this.onSubmit} disabled={!this.state.selection} color={this.state.selection ? "primary" : "white"}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(DocumentModal);