import React from "react";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";
import { Typography, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "~/assets/jss/empire-tms/views/loginPageStyle.jsx";

class PasswordRulesList extends React.Component {
	render() {
		const { uppercase, lowercase, digit, specialCharacter, classes } = this.props;
		return (
            <>
                <Typography variant="caption">
                    Password may not contain the username and must be at least 8 characters and include characters from three of the following four categories:
                </Typography>
                <List dense={true}>
                    <ListItem>
                        <ListItemIcon style={{minWidth: '30px'}} >
                            {uppercase ? (
                                <CheckBox fontSize="small" className={ classes.successColor } />
                            ) : (
                                <CheckBoxOutlineBlank fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary="Uppercase A - Z (Latin alphabet)"
                            primaryTypographyProps={{variant: "caption"}}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon style={{minWidth: '30px'}} >
                            {lowercase ? (
                                <CheckBox fontSize="small" className={ classes.successColor } />
                            ) : (
                                <CheckBoxOutlineBlank fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary="Lowercase a - z (Latin alphabet)"
                            primaryTypographyProps={{variant: "caption"}}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon style={{minWidth: '30px'}} >
                            {digit ? (
                                <CheckBox fontSize="small" className={ classes.successColor } />
                            ) : (
                                <CheckBoxOutlineBlank fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary="Digits 0-9"
                            primaryTypographyProps={{variant: "caption"}}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon style={{minWidth: '30px'}} >
                            {specialCharacter ? (
                                <CheckBox fontSize="small" className={ classes.successColor } />
                            ) : (
                                <CheckBoxOutlineBlank fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary="Special characters (e.g. ! @ # $ % etc.)"
                            primaryTypographyProps={{variant: "caption"}}
                        />
                    </ListItem>
                </List>
            </>
		);
	}
}

export default withStyles(loginPageStyle)(PasswordRulesList);