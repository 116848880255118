import React from "react";
import _ from "lodash";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import OptionListView from "../MultiSelect/OptionListView.jsx";
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class GroupingStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: this.props.grouping,
      loading: true,
      columnsList: [],
      mounted: false,
    };

    this.getOptions = this.getOptions.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
  }

  getOptionAlias = col => {
    if(typeof this.props.allOptions !== 'undefined') {
      for(var i = 0; i < this.props.allOptions.length; i++) {
        if(this.props.allOptions[i].value === col) {
          return this.props.allOptions[i].label;
        }
      }
    }

    return col.charAt(0).toUpperCase() + this.replaceStr(col, ["#", "_"], ["", " "]).slice(1);
  };

  async componentDidMount() {
    this.setState({
      mounted: true,
    });

    this.getOptions(this.props.selectedOption).then((_) => {
      this.props.handleStepState(this.state,'grouping');
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.mounted && this.props.selectedOption !== prevProps.selectedOption) {
      this.getOptions(this.props.selectedOption);
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handleOptions(value) {
    this.setState({ columnsList: value });
  }

  handleSelected(value) {
    this.setState({ columns: value },
      () => {
        this.props.handleStepState(this.state, 'grouping');
      }
    );
  }

  replaceStr(str, find, replace) {
    for (var i = 0; i < find.length; i++) {
      str = str.replace(new RegExp(find[i], "gi"), replace[i]);
    }
    return str;
  }

  async getOptions(data) {
    try {
      if (typeof data !== "string" && !_.isEmpty(data)) {
        var tempColumun = [];
        data.map((item) =>
          tempColumun.push({
            value: item,
            label: this.getOptionAlias(item), // something about calling this function is causing this component not to render
          })
        );

        this.setState({
          loading: false,
          columnsList: tempColumun,
          columns: _.intersection(this.state.columns, data),
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        columnsList: [],
      });
    }
  }

  render() {
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <OptionListView
            options={this.state.columnsList}
            selectedOptions={this.state.columns}
            handleSelected={this.handleSelected}
            handleOptions={this.handleOptions}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(GroupingStep)));