import React from "react";
import { withSnackbar } from "notistack";
import { Loader } from "@progress/kendo-react-indicators";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

import CloseIcon from '@material-ui/icons/Close';
import "~/views/CarrierPortal/components/inputChip.css";
//import * as styles "~/views/CarrierPortal/components/chipStyle.module.css";

class InputChip extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        mounted: false,
        show: false,
        loading: true,
        initialized:true,
        tags: this.props.tags,
        placehold: this.props.placehold
      };

    }

    async componentDidMount() {
      this.setState({ mounted: true });
    }

    componentWillUnmount() {
      this.setState({ mounted: false });
    }

    componentWillReceiveProps(nextprops){
      this.setState({
        tags: nextprops.tags,
        placehold: nextprops.placehold})
    }

    removeTags = indexToRemove => {
        this.setState({ tags: [...this.state.tags.filter((_,index) => index !== indexToRemove)]}, function(){
            this.props.changeTagsList(this.state.tags);
        });
    };

    removeLast = input_val => {
      if(input_val !==""){
        return;
      }
      const lastIndex = this.state.tags.length - 1;
      this.setState({ tags: [...this.state.tags.filter((_,index) => index !== lastIndex)]}, function(){
          this.props.changeTagsList(this.state.tags);
      });
    };

    addTags = event => {
      let input_val = event.target.value;

      if(event.key === "Backspace"){
        this.removeLast(event.target.value);
        return;
      }

      const nonWhiteSpace = /\S/g;
      if(!nonWhiteSpace.test(input_val)){
        this.props.setNotification(
          "You need to insert value!",
          { variant: "warning" }
        );
        event.target.value = "";
        return;
      }

      this.setState({
        initialized: false,
      });

      let input_array = input_val.trim().split(" ");

      const specialChar = /[^a-zA-Z0-9\/_-]/g; // allows "/", "_", and "-"

      if(input_array.length){
        //filter array before concat
        input_array = input_array.filter(element => {
          let offendingChar = element.search(specialChar);

          if (offendingChar > -1){
            this.props.setNotification(
              `Special character "${element[offendingChar]}" is not allowed!`,
              { variant: "warning" }
            )
            return false
          }

          return element !== "";
        });

        this.setState({ tags: [...this.state.tags, ...input_array]}, function(){
          this.props.changeTagsList(this.state.tags);
        });
        event.target.value = "";
      }

      this.setState({
        initialized: true,
      });
    };

    render(){

    return(
        <div className="tags-input">
          <ul id="tags">
            {this.state.tags.map((tag, index) => (
              <li key={index} className="tag">
                <span className='tag-title'>
                  {tag}
                </span>
                <span className='tag-close-icon' onClick={()=>this.removeTags(index)}>
                  <CloseIcon fontSize="small"/>
                </span>
              </li>
            ))}
          </ul>
          <input type="text" onKeyUp={event => event.key === "Enter" || event.key === "Backspace"|| event.key === " "? this.addTags(event):null}
            placeholder={this.state.placehold || ""}
            className="tag-input-field"/>
            {!this.state.initialized && (
              <Loader
                size={"large"}
                style={{
                  position: "absolute",
                  top: "62%",
                  left: "15%",
                  transform: "translate(-50%,-50%)",
                }}
                type={"infinite-spinner"}
              />
            )}
        </div>
      );
    }

}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(withSnackbar(InputChip)));