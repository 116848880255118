import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import TransitSelect from "~/components/TMS/TransitSelect.jsx";
import DateRangePicker from "~/components/DateRangePicker/DateRangePicker.jsx";
import WarehouseSelector from "~/components/TMS/WarehouseSelector.jsx";


// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
class FilterDefinition extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			mounted: false,
			show: false,
			loading: true,
			companies: [],
			statuses: [],
			filters: (!_.isEmpty(props.filters) && props.filters != undefined) ? props.filters : [],
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.getFilters = this.getFilters.bind(this);
		this.getCompanyItems = this.getCompanyItems.bind(this);

	}

	async componentDidMount() {

		this.setState({ mounted: true });

		try {

			const response = await axios.get( "/index.php?p=api&r=json&c=admin&m=companies&d=1/9999" );
			const data = response.data;

			if ( typeof data !== "string" && !_.isEmpty(data.body) ) {

				data.body.result.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					companies: data.body.result
				});

			} else {
				this.setState({ loading: false });
				this.props.setNotification( "There was an error loading company data!", { variant: "error" } );
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("Unexpected response when loading company data!", { variant: "error" });
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps.filters, this.props.filters)) {
			this.setState({
				filters: this.props.filters,
			});
		} else if(!_.isEqual(prevState, this.state)) {
			if(typeof this.props.onChange === 'function') {
				this.props.onChange({...this.state});
			}
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleChange(key, event) {
		let filters = [...this.state.filters];
		filters[key].value = event.target.value
        this.setState({ filters: filters });
    }

	handleWarehouseChange(key, warehouses) {
		let filters = [...this.state.filters];
		filters[key].value = warehouses.map(warehouse => warehouse.replace(/\'+/g, '')).join(',');
		this.setState({ filters: filters });
	}

    handleTransitStatusChange = (key, e) => {
    	let filters = [...this.state.filters]
    	filters[key].value = e.target.value.join(',');
    	this.setState({ filters: filters });
    }

    handleStatusChange(key, event) {

		let filters = [...this.state.filters];
		let split = filters[key].value.split(',');
		let val = event.target.value;
		let isChecked = event.target.checked;

		if(isChecked) {
			split.push(val.trim());
		} else{
			let index = split.indexOf(val);
			if(index >= 0) {
				split.splice(index, 1);
			}
		}

		for(var i = 0; i < split.length; i++) {
			if(_.isEmpty(split[i])) {
				split.splice(i, 1);
			}
		}

		let newStatuses = split.join(',');

		filters[key].value = newStatuses;

        this.setState({ filters: filters });

    }

	handleDateRangeChange(filter, dates) {
		const { filters } = this.state;
		const filterIdx = filters.findIndex(f => f.filter === filter);
		if (dates.empty) {
			filters[filterIdx].value = '';
		} else if (dates.rangeSelection) {
			filters[filterIdx].value = dates.rangeSelection;
		} else {
			const format = filter === 'date_executed' ? 'mysql-ts' : 'mysql';
			filters[filterIdx].value = [dates.startDate.format(format), dates.endDate.format(format)].join(',');
		}
		this.setState({ filters });
    }


	getCompanyItems() {

		const { classes } = this.props;

		const companies = [...this.state.companies];

  		return companies.map(function(comp, i) {
			return (
				<MenuItem
					key={comp.id}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={comp.name}
				>
					{comp.name}
				</MenuItem>
			)
		});

	}

    getFilters(data) {

		const { classes } = this.props;

		return data.map((prop, key) => {

			switch(prop.filter) {
			  	case 'company_name':

			  		return(

			  			<Card key={prop.filter}>
			  				<CardBody>
					    		<FormControl
									fullWidth
									className={
										classes.selectFormControl
									}
								>
									<InputLabel
										htmlFor={prop.filter}
										className={classes.selectLabel}
									>
										Company Name
									</InputLabel>
									<Select
										MenuProps={{
											className: classes.selectMenu
										}}
										classes={{
											select: classes.select
										}}
										value={prop.value}
										inputProps={{
											id: prop.filter,
											name: prop.filter,
											value: prop.value || ''
										}}
										onChange={e => this.handleChange(key, e)}
									>
										{this.getCompanyItems()}
									</Select>
								</FormControl>
							</CardBody>
						</Card>

					);

				case 'warehouse_code':
					let companyName = data.find(filter => filter.filter == "company_name").value;
					let company = this.state.companies.find(comp => comp.name == companyName);
					let warehouses = prop.value ? prop.value.split(',').map(warehouse => warehouse.replace(/\'+/g, '')) : [];
					return (

						<Card key={prop.filter}>
							<CardBody>
								<FormControl
									fullWidth
									className={
										classes.selectFormControl
									}
								>
									<InputLabel
										htmlFor={prop.filter}
										className={classes.selectLabel}
										shrink={company}
									>
										Warehouse Name
									</InputLabel>
									<GridContainer id={prop.filter} name={prop.filter} style={{ minHeight: "50px" }}>
										<GridItem xs={12} sm={12} md={12}>
											{company && (
												<WarehouseSelector
													mode={'list'}
													company={company.id}
													selectedWarehouses={warehouses}
													onChange={e => { this.handleWarehouseChange(key, e) }}
												/>
											)}
										</GridItem>
									</GridContainer>
								</FormControl>
							</CardBody>
						</Card>

					);
		    	case 'non_lcc_selection':

		    		return(

		    			<Card key={prop.filter}>
		    				<CardBody>
					    		<FormControl
									fullWidth
									className={
										classes.selectFormControl
									}
								>
									<InputLabel
										htmlFor={prop.filter}
										className={classes.selectLabel}
									>
										Non-LCC Selection
									</InputLabel>
									<Select
										MenuProps={{
											className: classes.selectMenu
										}}
										classes={{
											select: classes.select
										}}
										value={prop.value}
										inputProps={{
											id: prop.filter,
											name: prop.filter,
											value: prop.value || ''
										}}
										onChange={e => this.handleChange(key, e)}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="1"
										>
											Only BOLs with Non LCC Selection Data
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="0"
										>
											Disregard Non LCC Selection Data
										</MenuItem>
									</Select>
								</FormControl>
							</CardBody>
						</Card>
					);

		    	case 'exclude_statuses':

		    		const statuses = prop.value.split(",");

			    	return(

			    		<Card key={prop.filter}>
			    			<CardHeader>
			    				Exclude Statuses
			    			</CardHeader>
			    			<CardBody>
			    				<GridContainer>
				    				<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'executed'") || false}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'executed'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Executed"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'third_party_payor'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'third_party_payor'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Third Party Payor"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'scheduled'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'scheduled'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Scheduled"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'confirmed'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'confirmed'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Confirmed"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'ttl_received'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'ttl_received'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="TTL Received"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'ttl_confirmed'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'ttl_confirmed'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="TTL Confirmed"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'tfm_received'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'tfm_received'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="TFM Received"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'auditing'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'auditing'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Auditing"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'disputed'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'disputed'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Disputed"
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<FormControlLabel
											control={
												<Switch
													checked={statuses.includes("'billed'")}
													onChange={(e) => {this.handleStatusChange(key, e)}}
													value="'billed'"
													classes={{
														switchBase: classes.switchBase,
														checked: classes.switchChecked
													}}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Billed"
										/>
									</GridItem>
								</GridContainer>
			    			</CardBody>
			    		</Card>

					);

				case 'transit_status':

					return (
						<Card key={prop.filter}>
			  				<CardBody>
					    		<FormControl
									fullWidth
									className={
										classes.selectFormControl
									}
								>
									<InputLabel
										htmlFor={prop.filter}
										className={classes.selectLabel}
									>
										Transit Status
									</InputLabel>
									<TransitSelect
										MenuProps={{ className: classes.selectMenu }}
										classes={{ select: classes.select }}
										value={prop.value.length ? prop.value.split(',') : ['']}
										inputProps={{ name: "transit_status" }}
										onChange={e => this.handleTransitStatusChange(key, e)}
										multiple
										type='transit'
									/>
								</FormControl>
							</CardBody>
						</Card>
					);

				case 'date_executed':
				case 'shipped_date':
				case 'estimated_delivery':
					let [initialStartDate, initialEndDate] = prop.value.length ? prop.value.split(',') : [null, null];
					return (
						<Card key={prop.filter}>
							<CardBody>
								<InputLabel
									htmlFor={prop.filter}
									className={
										classes.label
									}
								>
									{{
										'date_executed': 'Date Executed',
										'shipped_date': 'Shipped Date',
										'estimated_delivery': 'Expected Delivery Date',
									}[prop.filter]}
								</InputLabel>
								<DateRangePicker
									dateFormat="MM/DD/YYYY"
									rangeOptions={['Today', 'Yesterday', 'This Week', 'Last Week', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'This Year', 'Last Year']}
									initialRangeSelection={initialStartDate}
									initialStartDate={initialStartDate}
									initialEndDate={initialEndDate}
									onChange={(dates) => this.handleDateRangeChange(prop.filter, dates)}
									width={275}
								/>
							</CardBody>
						</Card>
					);

			  	default:

			  		var filterDisplayName = prop.filter;

			  		switch(prop.filter) {
			  			case 'company_username':
			  				filterDisplayName = 'Username';
			  				break;
	  					default:
	  						break;
			  		}

			    	return(

			    		<Card key={prop.filter}>
			    			<CardBody>
								<InputLabel
									htmlFor={prop.filter}
									className={
										classes.label
									}
								>
									{filterDisplayName}
								</InputLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										id: prop.filter,
										name: prop.filter,
										value: prop.value || "",
										onChange: (e) => {this.handleChange(key, e)}
									}}
									white
								/>
							</CardBody>
						</Card>

		    		);

			}

		});

	}

	render() {
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
						{this.getFilters(this.state.filters)}
				</GridItem>
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		handleMessages,
		setNotification,
	}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(FilterDefinition)));