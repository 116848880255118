import React from "react";
import qs from "qs";

import { NavLink, Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";

// material ui icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newPermissionGroupStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewPermissionGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			show: false,
			mounted: false,
			alert: null,
			loading: true,
			user: null,
			body: {
				permissions: [],
				post: {}
			},
			name: "",
			permissions: [],
			redirect: null,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handlePermissions = this.handlePermissions.bind(this);
		this.saveRole = this.saveRole.bind(this);
	}
	async componentDidMount() {
		let id = this.props.match.params.id || "";

		if (_.isEmpty(id)) {
			this.props.setNotification("Role ID is empty!", { variant: "error" });
			this.setState({
				redirect: <Redirect to={basePath + "/admin/settings/permission-groups"} />
			});
			return;
		}

		this.setState({ mounted: true, id });

		const url = "/index.php?p=api&r=json&c=roleManagement&m=editRole&d=" + id;

		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.user) &&
				!_.isEmpty(data.body) &&
				!_.isEmpty(data.body.permissions) &&
				!_.isEmpty(data.body.post)
			) {
				this.props.pageTitle('Edit Permission Group - ' + data.body.post.name);
				let permissions = [];
				Object.keys(data.body.post.permissions).map(id => {
					permissions[id] = id;
				});
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					name: data.body.post.name,
					permissions
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	async saveRole() {
		const { name, permissions, id } = this.state;

		if (_.isEmpty(name)) {
			this.props.setNotification(
				"Name is required!",
				{ variant: "error" }
			);
			return;
		}

		if (_.isEmpty(permissions)) {
			this.props.setNotification(
				"Permission is required!",
				{ variant: "error" }
			);
			return;
		}

		this.setState({
			show: true,
			loading: true
		});

		const data = { name, permissions };
		const url = "/index.php?p=api&r=json&c=roleManagement&m=editRole&d=" + id;

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				this.setState({
					loading: false,
					redirect: <Redirect to={basePath + "/admin/settings/permission-groups"} />
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error saving the role!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error saving the role!",
				{ variant: "error" }
			);
		}
	}
	handlePermissions(id, event) {
		let { permissions } = this.state;
		if (event.target.checked) {
			permissions[id] = id;
		} else if (!_.isEmpty(permissions[id])) {
			delete permissions[id];
		}
		this.setState({ permissions });
	}
	getPermissions(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridItem xs={6} sm={4} md={4} lg={3} key={key}>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot
								}}
								value={prop.id}
								checked={!_.isEmpty(this.state.permissions[prop.id])}
								onChange={e => this.handlePermissions(prop.id, e)}
							/>
						}
						classes={{ label: classes.label }}
						label={prop.name}
					/>
				</GridItem>
			);
		});
	}
	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		const link = basePath + "/admin/settings/permission-groups";
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<NavLink to={link}>
										<Button color="linkedin">
											Permission Groups
										</Button>
									</NavLink>
								</GridItem>
								<GridItem xs={6}>
									<InputLabel className={classes.label}>Name</InputLabel><br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											id: "name",
											name: "name",
											value: this.state.name || "",
											onChange: this.handleInput("name")
										}}
										white
									/>
								</GridItem>
								<GridItem xs={12}>
									<GridContainer>
										{this.getPermissions(this.state.body.permissions)}
									</GridContainer>
								</GridItem>
								<GridItem xs={12}>
									<Button
										color="linkedin"
										onClick={this.saveRole}
									>
										Save Role
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newPermissionGroupStyle)(
	withSnackbar(NewPermissionGroup)
);
