import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class SelectReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  async componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  getMenuItems = (data, classes) => {
    return [
      <MenuItem
        key={1}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value="invoice"
        selected={true}
      >
        Invoice
      </MenuItem>,
    ];
  }

  render() {
    const classes = this.props.classes || {};
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        fullWidth={true}
        keepMounted
        onClose={() => this.props.onClose()}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.props.onClose()}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Select Report</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className={classes.left}>
            <GridItem xs={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="reportType"
                  className={classes.selectLabel}
                >
                  Choose Report Type
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.props.reportType}
                  onChange={this.props.handleSelect}
                  inputProps={{
                    name: "reportType",
                    id: "reportType",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Report Type
                  </MenuItem>
                  {this.state.mounted
                    ? this.getMenuItems(this.props.reportTypes, classes)
                    : null}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem style={{paddingTop : "5px"}} xs={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="reportBehavior"
                  className={classes.selectLabel}>
                  Report Behavior
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.props.reportBehavior}
                  onChange={this.props.handleSelect}
                  inputProps={{
                    name: "reportBehavior",
                    id: "reportBehavior",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Behavior
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="summary"
                  >
                    Summary
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="detail"
                  >
                    Detail
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
             {/*<GridItem>
               <FormControlLabel
                control={
                  <Switch
                    checked={this.props.wizard}
                    onChange={this.props.handleChange}
                    name="wizard"
                    color="primary"
                  />
                }
                label="Wizard"
              />
             </GridItem>*/}
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="warning"
            onClick={() => this.props.onClose()}
            className={classes.marginRight}
          >
            Close
          </Button>
          {this.props.loading ? (
            <Button
              color="linkedin"
              style={{ lineHeight: 0 }}
              className={classes.marginRight}
            >
              <CircularProgress size={16} style={{ color: "white" }} />
            </Button>
          ) : (
            <Button
              color="linkedin"
              onClick={() => this.props.selectReport()}
              className={classes.marginRight}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(style)(SelectReportModal);
