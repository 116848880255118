import React from "react";
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize'
import Datetime from "react-datetime";

import qs from "qs";

import moment from "moment";
import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewRuleSet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			id: null,
			start_date: "",
			end_date: "",
			display_to: "all",
			message: "",
			redirect: false,
			preview: false
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.togglePreview = this.togglePreview.bind(this);
		this.saveMessage = this.saveMessage.bind(this);
	}

	async componentDidMount() {
		this.setState({
			mounted: true
		});
		if(!_.isEmpty(this.props.match.params.id)) {
			const id = this.props.match.params.id;
			try {
				const response = await axios.get(
					"/index.php?p=api&r=json&c=system&m=editMessage&d=" + id
				);
				const data = response.data;
				if (typeof data !== "string" && !_.isEmpty(data.body)) {
					this.props.pageTitle('Edit System Message');
					this.props.handleMessages(response);
					this.setState({
						show: true,
						loading: false,
						id: id,
						timestamp: data.body.timestamp,
						display_to: data.body.display_to,
						start_date: moment.unix(data.body.start_date).format('YYYY-MM-DD'),
						end_date: moment.unix(data.body.end_date).format('YYYY-MM-DD'),
						message: data.body.message,
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"There was an error loading the data!",
						{
							variant: "error"
						}
					);
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	togglePreview() {
		this.setState({ preview: !this.state.preview });
	}
	async saveMessage() {
		this.setState({
			loading: true
		});

		const { id, start_date, end_date, display_to, message } = this.state;

		let data = {
			start_date: moment(start_date).format("YYYY-MM-DD"),
			end_date: moment(end_date).format("YYYY-MM-DD"),
			display_to: display_to,
			message: message,
		};
		let url = (id === null) ? "/index.php?p=api&r=json&c=system&m=newMessage" : "/index.php?p=api&r=json&c=system&m=editMessage&d=" + id;

		try {
			const response = await axios.post(
				url,
				qs.stringify(data)
			);
			if (!_.isEmpty(response.data)) {
				this.props.handleMessages(response);
				if (response.data.body !== false) {
					this.setState({
						loading: false,
						id: (id === null) ? response.data.body : id,
						redirect: true,
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification(
						"An error occurred while saving the system message!", { variant: "error" }
					);
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"An unexpected error occurred while saving the system message!", { variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"An exception occurred while saving the system message!", { variant: "error" }
			);
		}
	}
	render() {
		if (this.state.redirect) {
			let redirectTo = basePath + "/admin/system/global-notifications";
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<GridContainer>
										<GridItem xs>
											<InputLabel
												className={classes.label}
											>
												Start Date
											</InputLabel>
											<br />
											<FormControl fullWidth>
												<Datetime
													value={
														this.state
															.start_date || ""
													}
													timeFormat={false}
													onChange={m =>
														this.handleDatetime(
															"start_date",
															m
														)
													}
													className={classes.datetime}
													inputProps={{
														id: "start_date",
														name: "start_date"
													}}
												/>
											</FormControl>
										</GridItem>
										<GridItem xs>
											<InputLabel
												className={classes.label}
											>
												End Date
											</InputLabel>
											<br />
											<FormControl fullWidth>
												<Datetime
													value={
														this.state.end_date ||
														""
													}
													timeFormat={false}
													onChange={m =>
														this.handleDatetime(
															"end_date",
															m
														)
													}
													className={classes.datetime}
													inputProps={{
														id: "end_date",
														name: "end_date"
													}}
												/>
											</FormControl>
										</GridItem>
										<GridItem xs>
											<FormControl
												fullWidth
												className={
													classes.selectFormControl
												}
											>
												<InputLabel
													htmlFor="visibility"
													className={
														classes.selectLabel
													}
												>
													Visibility
												</InputLabel>
												<Select
													MenuProps={{
														className:
															classes.selectMenu
													}}
													classes={{
														select:
															classes.select
													}}
													value={
														this.state.display_to || ""
													}
													inputProps={{
														id: "visibility",
														name: "display_to"
													}}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root:
																classes.selectMenuItem,
															selected:
																classes.selectMenuItemSelected
														}}
														value="all"
													>
														Everyone
													</MenuItem>
													<MenuItem
														classes={{
															root:
																classes.selectMenuItem,
															selected:
																classes.selectMenuItemSelected
														}}
														value="user"
													>
														Customers Only
													</MenuItem>
													<MenuItem
														classes={{
															root:
																classes.selectMenuItem,
															selected:
																classes.selectMenuItemSelected
														}}
														value="admin"
													>
														Administrators Only
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									<CustomInput
										labelText="Message"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											multiline: true,
											id: "message",
											name: "message",
											placeholder: "Use Markdown to format this text.",
											value:
												this.state.message || "",
											onChange: this.handleInput(
												"message"
											)
										}}
										white
									/>
								</GridItem>
								<GridItem xs={12}>
									<Button
										color="linkedin"
										onClick={this.saveMessage}
										style={{
											marginRight: "4px"
										}}
									>
										Save Message
									</Button>
									{this.state.preview ? (
										<Button
											color="white"
											onClick={this.togglePreview}
											style={{
												marginRight: "4px"
											}}
										>
											Hide Preview
										</Button>
									) : (
										<Button
											color="white"
											onClick={this.togglePreview}
											style={{
												marginRight: "4px"
											}}
										>
											Show Preview
										</Button>
									)}
								</GridItem>
								{!_.isEmpty(this.state.message) && this.state.preview && (
									<GridItem xs={12} style={{ marginTop: "10px" }}>
										<Paper className={classes.paper} elevation={1}>
											<Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{this.state.message}</Markdown>
										</Paper>
									</GridItem>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(NewRuleSet));