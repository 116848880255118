import React, { Component } from "react";
import Datetime from "react-datetime";

// icons
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';


class DateSelect extends Component {

	state = {
		edit: false,
		date: this.props.date,
		editDate: this.props.date,
	}

	onDateChange = (m) => {
		this.setState({
			date: m,
			edit:true,
		})
	}

	onDateCancel = (e) => {
		this.setState({edit: false, date: this.state.editDate })
	}

	onDateSelect = async (name, idx) => {
		await this.props.setTransitTimes(name,  this.state.date, idx);
		this.setState({
			date: this.state.date,
			edit: false,
			editDate: this.state.date,
		})
	}

	render() {

		const {rowInfo, classes} = this.props
		const timeFormat = rowInfo.column.id == "delivery_time" ? true : false;
		return(
			<>
				<Datetime
					dateFormat={!timeFormat}
					timeFormat={timeFormat}
					className={classes.datetime}
					value={this.state.date || ""}
					closeOnSelect={true}
					onChange={m => this.onDateChange(m)}
					inputProps={{
						id: rowInfo.column.id,
						name: rowInfo.column.id,
						placeholder: `Select ${rowInfo.column.Header}`
					}}
					
				/>
				{this.state.edit && (
					<div>
						<ButtonGroup disableElevation variant="contained">
							<IconButton
								onClick={(e) => this.onDateCancel(e)}
								size="small"
							>
								<CancelIcon fontSize="inherit"/>
							</IconButton >
							<IconButton
								onClick={(e) => this.onDateSelect(rowInfo.column.id, rowInfo.row.index)}
								size="small"
							>
								<SaveIcon fontSize="inherit"/>
							</IconButton>
						</ButtonGroup>
					</div>
				)}
			</>
		)
	}
}

export default DateSelect;