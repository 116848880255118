import React from "react";
import Datetime from "react-datetime";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";

// custom variables
import axios from "~/variables/axios.jsx";
import { basePath} from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

// material ui icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";

// style
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewAccessorialProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			copy: null,
			show: false,
			mounted: false,
			loading: true,
			alert: null,
			redirect: null,
			user: null,
			body: null,
			name: "",
			eff_from: "",
			eff_to: "",
			entry: []
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getEntries = this.getEntries.bind(this);
		this.handleEntry = this.handleEntry.bind(this);
		this.saveProfile = this.saveProfile.bind(this);
	}

	async componentDidMount() {
		let id = "";
		let copy = "";
		if (!_.isEmpty(this.props.match.params.id)) {
			id = this.props.match.params.id;
		}
		if (!_.isEmpty(this.props.match.params.copy)) {
			copy = this.props.match.params.copy;
		}
		this.setState({
			mounted: true,
			id,
			copy
		});
		let url = "/index.php?p=api&r=json&c=admin&m=newAccessorialProfile";
		if (!_.isEmpty(id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editAccessorialProfile&d=" + id;
		} else if (!_.isEmpty(copy)) {
			url = "/index.php?p=api&r=json&c=admin&m=copyAccessorialProfile&d=" + copy;
		}
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
				let { name, eff_from, eff_to, entry } = this.state;
				let entries = [];
				const keys = [];
				let accessorials = {};
				if (!_.isEmpty(id) || !_.isEmpty(copy)) {
					entries = Object.values(response.data.body.accessorials);
					accessorials = response.data.body.profile.accessorials;
					name = response.data.body.profile.name;
					eff_from = moment.unix(response.data.body.profile.eff_from).format("MM/DD/YYYY");
					eff_to = moment.unix(response.data.body.profile.eff_to).format("MM/DD/YYYY");
				} else {
					entries = Object.values(response.data.body);
					eff_from = moment().format("MM/DD/YYYY");
					eff_to = moment.unix(2147483647).format("MM/DD/YYYY");
				}
				if (!_.isEmpty(id)) {
					this.props.pageTitle('Edit Accessorial Profile - ' + response.data.body.profile.name);
				} else if (!_.isEmpty(copy)) {
					this.props.pageTitle('Copy Accessorial Profile - ' + response.data.body.profile.name);
				}
				entry = entries.map((prop, key) => {
					const accId = prop.id;
					const checked = !!(!_.isEmpty(accessorials) && !_.isEmpty(accessorials[accId]));
					return {
						id: accId,
						name: prop.name,
						code: prop.code,
						checked: checked,
						common: !!(checked && accessorials[accId].common == 1),
						weight: checked ? accessorials[accId].weight : "",
						over_name: checked ? accessorials[accId].over_name : "",
						bm_cost: checked ? accessorials[accId].bm_cost : "",
						accept_dynamic_bm: !!(checked && accessorials[accId].accept_dynamic_bm == 1),
						bm_passthru_lower: !!(checked && accessorials[accId].bm_passthru_lower == 1),
						always_checked: !!(checked && accessorials[accId].always_checked == 1),
						comment: checked ? accessorials[accId].comment : ""
					};
				});

				this.setState({
					show: true,
					loading: false,
					body: response.data.body,
					user: response.data.user,
					name,
					eff_from,
					eff_to,
					entry
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleDatetime = (name, moment) => {
		this.setState({ [name]: moment });
	}


	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async saveProfile() {
		const entry = {};
		const entries = this.state.entry.filter(prop => prop.checked);
		for (let i = 0; i < entries.length; i++) {
			const code = entries[i].code;
			entry[code] = entries[i];

			//handle flags
			if (!entries[i].common) 			delete entry[code].common;
			if (!entries[i].accept_dynamic_bm) 	delete entry[code].accept_dynamic_bm;
			if (!entries[i].bm_passthru_lower) 	delete entry[code].bm_passthru_lower;
			if (!entries[i].always_checked) 	delete entry[code].always_checked;
		}
		const data = {
			name: this.state.name,
			eff_from: !_.isEmpty(this.state.eff_from) ? moment(this.state.eff_from).format("MM/DD/YYYY") : "",
			eff_to: !_.isEmpty(this.state.eff_to) ? moment(this.state.eff_to).format("MM/DD/YYYY") : "",
			entry
		};
		let url = "/index.php?p=api&r=json&c=admin&m=newAccessorialProfile";
		if (!_.isEmpty(this.state.id)) {
			url = "/index.php?p=api&r=json&c=admin&m=editAccessorialProfile&d=" + this.state.id;
		}
		if (!_.isEmpty(this.state.copy)) {
			url = "/index.php?p=api&r=json&c=admin&m=copyAccessorialProfile&d=" + this.state.copy;
		}
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				this.setState({ redirect: basePath + "/admin/settings/accessorial-profiles" });
			} else {
				this.props.setNotification("There was an error saving the profile!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the profile!", {
				variant: "error"
			});
		}
	}

	getEntries(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridContainer key={key}>
					<GridItem xs={12}>
						<Grid container>
							<Grid item>
								<h5>
									<strong>{prop.name + " [" + prop.code + "]"}</strong>
								</h5>
							</Grid>
							<Grid item style={{ marginLeft: "10px" }}>
								<FormControlLabel
									control={
										<Checkbox
											tabIndex={-1}
											checkedIcon={<Check className={classes.checkedIcon} />}
											icon={<Check className={classes.uncheckedIcon} />}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot
											}}
											checked={prop.checked || false}
											onChange={e => this.handleEntry(key, "checked", e)}
										/>
									}
									classes={{
										label: classes.label
									}}
								/>
							</Grid>
						</Grid>
					</GridItem>
					<GridItem xs={12} sm={6} md={4}>
						<InputLabel className={classes.label}>Profile Specific Name</InputLabel>
						<br />
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "over_name",
								value: prop.over_name || "",
								onChange: e => this.handleEntry(key, "over_name", e)
							}}
							white
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={4}>
						<InputLabel className={classes.label}>Weight</InputLabel>
						<br />
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "weight",
								value: prop.weight || "",
								onChange: e => this.handleEntry(key, "weight", e)
							}}
							white
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={4}>
						<InputLabel className={classes.label}>Benchmark Cost</InputLabel>
						<br />
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "bm_cost",
								value: prop.bm_cost || "",
								onChange: e => this.handleEntry(key, "bm_cost", e),
								startAdornment: <InputAdornment position="start">$</InputAdornment>
							}}
							white
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
									checked={prop.common || false}
									onChange={e => this.handleEntry(key, "common", e)}
								/>
							}
							classes={{
								label: classes.label
							}}
							label="Set Common"
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
									checked={prop.accept_dynamic_bm || false}
									onChange={e => this.handleEntry(key, "accept_dynamic_bm", e)}
								/>
							}
							classes={{
								label: classes.label
							}}
							label="Accept Dynamic BM"
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
									checked={prop.bm_passthru_lower || false}
									onChange={e => this.handleEntry(key, "bm_passthru_lower", e)}
								/>
							}
							classes={{
								label: classes.label
							}}
							label="Use Rated When Lower"
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
									checked={prop.always_checked || false}
									onChange={e => this.handleEntry(key, "always_checked", e)}
								/>
							}
							classes={{
								label: classes.label
							}}
							label="Always Checked"
						/>
					</GridItem>
					<GridItem xs={12}>
						<InputLabel className={classes.label}>Comments</InputLabel>
						<br />
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								name: "comment",
								value: prop.comment || "",
								multiline: true,
								onChange: e => this.handleEntry(key, "comment", e)
							}}
							helpText="Maximum 255 characters"
							white
						/>
					</GridItem>
				</GridContainer>
			);
		});
	}

	handleEntry(i, name, event) {
		const { entry } = this.state;
		if (entry.length) {
			const checkboxes = ["checked", "common", "accept_dynamic_bm", "bm_passthru_lower", "always_checked"];
			entry[i][name] = checkboxes.includes(name) ? event.target.checked : event.target.value;
			this.setState({ entry });
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />;
		}
		const { classes } = this.props;
		const newProfile = _.isEmpty(this.state.id) && _.isEmpty(this.state.copy);
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>Profile or Company Name</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "name",
											name: "name",
											value: this.state.name || "",
											onChange: this.handleInput("name")
										}}
										white
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>Effective From</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											value={this.state.eff_from || ""}
											timeFormat={false}
											onChange={m => this.handleDatetime("eff_from", m)}
											className={classes.datetime}
											inputProps={{
												id: "eff_from",
												name: "eff_from"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={4}>
									<InputLabel className={classes.label}>Effective To</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											value={this.state.eff_to || ""}
											timeFormat={false}
											onChange={m => this.handleDatetime("eff_to", m)}
											className={classes.datetime}
											inputProps={{
												id: "eff_to",
												name: "eff_to"
											}}
										/>
									</FormControl>
								</GridItem>
								{this.state.show ? <GridItem xs={12}>{!_.isEmpty(this.state.entry) ? this.getEntries(this.state.entry) : <Badge color="info">No Entries</Badge>}</GridItem> : <Spinner loading={this.state.loading} message="Failed to retrieve entries from the server" />}
								<GridItem xs={12}>
									<Button color="linkedin" onClick={this.saveProfile}>
										{newProfile ? "Create Profile" : "Save Profile"}
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(NewAccessorialProfile));