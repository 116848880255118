import React from "react";
import { connect } from "react-redux";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import { basePath } from "~/variables/server.jsx";
import { hasPermission } from "../../redux/selectors/Admin.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Badge from "~/components/Badge/Badge.jsx";

import productCatalogStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ProductCatalog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			alert: null,
			show: false,
			loading: true,
			body: null,
			user: null,
			data: [],
			current: 1,
			last: 1,
			warehouses: [],
			warehouse: "",
			sort_by: "",
			search: "",
			parcel_view: false
		};
		this.getWarehouses = this.getWarehouses.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.deleteProduct = this.deleteProduct.bind(this);
		this.getPagination = this.getPagination.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}
	async componentDidMount() {
		let url = "/index.php?p=api&r=json&c=product&m=catalog";

		if(window.location.pathname.includes('catalog-parcel')) {
			this.setState({parcel_view: true});
			url +="&parcel";
		}
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				url
			);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				!_.isEmpty(response.data.user) &&
				this.state.mounted
			) {
				const data = response.data;
				let warehouse = { id: "" };
				if (data.body.warehouses) {
					warehouse = data.body.warehouses.filter(
						warehouse => {
							return (
								warehouse.id ==
								data.body.selected_wh
							);
						}
					)[0];
				}
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					result: data.body.products.result || [],
					current: data.body.products.current ? data.body.products.current.toString() : "1",
					last: data.body.products.last ? data.body.products.last.toString() : "1",
					warehouses: data.body.warehouses || [],
					warehouse: warehouse.id || ""
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the product info!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the product info!",
				{
					variant: "error"
				}
			);
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	async handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });

		if (event.target.name !== "warehouse") {
			return;
		}

		this.setState({
			show: false,
			loading: true
		});

		let warehouse = "",
			sort_by = "",
			url = "/index.php?p=api&r=json&c=product&m=catalog&d=";

		if(event.target.name === "warehouse") {
			warehouse = event.target.value;
			sort_by = this.state.sort_by;
		} else if(event.target.name === "sort_by") {
			sort_by = event.target.value;
			warehouse = this.state.warehouse;
		}

		url += warehouse;

		if(!_.isEmpty(sort_by)) {
			const { current } = this.state;
			url += "/" + current + "/20/" + sort_by;
		}
		if(this.state.parcel_view) {
			url += "&parcel";
		}
		try {
			const response = await axios.get(url);

			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				this.state.mounted
			) {
				const { body } = response.data;
				this.setState({
					show: true,
					loading: false,
					current: body.products.current ? body.products.current.toString() : "1",
					last: body.products.last ? body.products.last.toString() : "1",
					result: body.products.result || []
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the products!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the products!",
				{ variant: "error" }
			);
		}
	}
	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}
	getProducts(data) {

		return data.map((prop, key) => {
			let cols = []
			let productPath = "/admin/product/edit/"
			if(this.state.parcel_view) {
				productPath = '/admin/product/parcel/edit/'
			}
			const link = basePath + productPath + prop.id + "/" + prop.warehouse_name_id;
			const button = (
				<div className="actions-right">
					<NavLink to={link}>
						<Button
							size="sm"
							color="info"
							style={{ marginRight: "4px" }}
						>
							{this.props.hasWrite ? "View/Edit" : "View"}
						</Button>
					</NavLink>
					{this.props.hasWrite && <Button
						size="sm"
						color="danger"
						style={{ marginRight: "4px" }}
						onClick={() => this.handleDelete(prop.id, prop.warehouse_name_id)}
					>
						Delete
					</Button>}
				</div>
			)
			if (this.state.parcel_view) {

				cols = [
					prop.id,
					prop.code,
					prop.name,
					prop.length,
					prop.width,
					prop.height,
					prop.weight,
					prop.declared_value,
					prop.haz_class || 'No',
					prop.parcel_default == 1 ? '*' : '',
					button
				]
			} else {

				cols = [
					prop.id,
					prop.code,
					prop.name,
					prop.weight,
					prop.pieces,
					prop.unit,
					prop.nmfc,
					prop.sub_nmfc,
					prop.declared_value,
					prop.class,
					prop.haz_class || 'No',
					prop.default == 1 ? '*' : '',
					button
				];
			}

			const wh = this.getSelectedWarehouse();
			if(wh && wh.enable_product_part_no == 1) {
				cols.splice(2, 0, prop.part_no);
			}
			if (this.state.parcel_view) {
				return prop.is_parcel == 1 ? cols : [];
			} else {
				return prop.is_parcel == 0 ? cols : [];
			}
		})
	}


	async handlePageClick(page) {
		const { warehouse, sort_by, current, parcel_view } = this.state;

		let url = "/index.php?p=api&r=json&c=product&m=catalog&d=" + warehouse + "/" + page + "/20";
		if (sort_by !== "") {
			url += "/" + sort_by;
		}
		if(parcel_view) {
			url += "&parcel";
		}
		this.setState({ show: false, loading: true });

		try {
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				this.state.mounted
			) {
				const { body } = response.data;
				this.setState({
					show: true,
					loading: false,
					current: body.products.current ? body.products.current.toString() : "1",
					last: body.products.last ? body.products.last.toString() : "1",
					result: body.products.result || []
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the products!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the products!",
				{ variant: "error" }
			);
		}
	}
	getPagination() {
		const delta = 5;
		let currentPage = 1,
			pageCount = 1;

		if (!_.isEmpty(this.state.current) && this.state.current != 1) {
			currentPage = parseInt(this.state.current);
		}
		if (!_.isEmpty(this.state.last) && this.state.last != 1) {
			pageCount = parseInt(this.state.last);
		}

		let pagination = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" },
			pages = [];

		const separate = (a, b) => [
			a,
			...({
				0: [],
				1: [b],
				2: [a + 1, b]
			}[b - a] || ["...", b])
		];

		pages = Array(delta * 2 + 1)
			.fill()
			.map((_, index) => currentPage - delta + index)
			.filter(page => 0 < page && page <= pageCount)
			.flatMap((page, index, { length }) => {
				if (!index) {
					return separate(1, page);
				}
				if (index === length - 1) {
					return separate(page, pageCount);
				}
				return [page];
			});

		if (pageCount > 1 && !_.isEmpty(pages)) {
			pagination = pages.map((prop, key) => {
				let page = { text: prop };
				if (prop == currentPage) {
					page.active = true;
				} else if (prop === "...") {
					page.disabled = true;
				} else {
					page.onClick = e => this.handlePageClick(prop);
				}
				return page;
			});
		}

		if (currentPage < pageCount) {
			nextPage.onClick = e => this.handlePageClick(currentPage + 1);
		} else {
			nextPage.disabled = true;
		}

		if (currentPage - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(currentPage - 1);
		} else {
			prevPage.disabled = true;
		}

		pagination.push(nextPage);
		pagination.unshift(prevPage);

		return pagination;
	}
	handleDelete(id, warehouse) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this product?"
					onConfirm={() => this.deleteProduct(id, warehouse)}
					onCancel={() => this.deleteProduct()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	async deleteProduct(id, warehouse) {
		this.setState({ alert: null });

		if (_.isEmpty(id) || _.isEmpty(warehouse)) {
			return;
		}

		try {
			const url = "/index.php?p=api&r=json&c=product&m=delete&d=" + id + "/" + warehouse;
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body)
			) {
				let result = this.state.result.filter((prop, key) => {
					return prop.id != id;
				});
				this.setState({ result });
			} else {
				this.props.setNotification(
					"There was an error deleting the product!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error deleting the product!",
				{ variant: "error" }
			);
		}
	}
	async handleSearch() {
		const { warehouse, search, parcel_view } = this.state;

		if(_.isEmpty(search)) {
			this.props.setNotification(
				"Please provide an ID, code or product name to search for!",
				{ variant: "error" }
			);
			return;
		}

		this.setState({
			show: false,
			loading: true
		});

		let url = "/index.php?p=api&r=json&c=product&m=search&d=" + warehouse + "/" + search;
		if (parcel_view) {
			url += '&parcel';
		}
		try {
			const response = await axios.get(url);
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body)
			) {
				const data = response.data;

				this.setState({
					show: true,
					loading: false,
					result: data.body.products.result || [],
					current: data.body.products.current ? data.body.products.current.toString() : "1",
					last: data.body.products.last ? data.body.products.last.toString() : "1",
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error searching the products!",
					{ variant: "error" }
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error searching the products!", {
				variant: "error"
			});
		}
	}
	handleKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleSearch();
		}
	}
	getSelectedWarehouse() {
		let wh = false;
		if(this.state.warehouse) {
			const match = this.state.warehouses.filter(obj => {
				return obj.id == this.state.warehouse;
			});
			if(match.length) {
				wh = match[0];
			}
		}
		return wh;
	}
	render() {
		const { classes } = this.props;
		let cols = []
		let selectedWarehouse = this.getSelectedWarehouse();
		let enablePartNo = false;
		let productPath = "/admin/product/new";

		if(this.state.parcel_view) {
			productPath = "/admin/product/parcel/new";
		}
		if(selectedWarehouse) {
			enablePartNo = (selectedWarehouse.enable_product_part_no == 1) ? true : false;
			selectedWarehouse = selectedWarehouse.id;
		}
		let newProductLink = basePath + productPath;
		if (selectedWarehouse) {
			newProductLink += "/" + selectedWarehouse;
		}
		if (this.state.parcel_view) {
			cols = [
				"ID",
				"Code",
				"Name",
				"Length",
				"Width",
				"Height",
				"Weight",
				"Declared Value",
				"Hazmat",
				"Default",
				"Actions"
			];
		} else {
			cols = [
				"ID",
				"Code",
				"Name",
				"Weight",
				"Pieces",
				"UoM",
				"NMFC",
				"Sub-NMFC",
				"Declared Value",
				"Class",
				"Hazmat",
				"Default",
				"Actions"
			];
		}
		if(enablePartNo) {
			cols.splice(2, 0, 'Part #');
		}
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
				                    <GridContainer>
										{this.props.hasWrite && <GridItem xs={4}>
											<NavLink to={newProductLink}>
												<Button
													color="linkedin"
												>
													New Product
												</Button>
											</NavLink>
										</GridItem>}
										<GridItem xs>
											<InputLabel htmlFor="warehouse" className={classes.label}>
												Warehouse/Facility
											</InputLabel>
											<br />
											<FormControl className={classes.selectFormControl} style={{ width: "175px" }}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.warehouse || ""}
													inputProps={{
														name: "warehouse",
														id: "warehouse"
													}}
													onChange={this.handleChange}
												>
													{!_.isEmpty(this.state.warehouses) ? this.getWarehouses(this.state.warehouses) : (
														<MenuItem
															disabled
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
														>
															No Warehouses
														</MenuItem>
													)}
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<GridContainer justify="flex-end">
										<GridItem xs={3} style={{ paddingTop: "20px" }}>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													id: "search",
													name: "search",
													value: this.state.search || "",
													onChange: this.handleInput("search"),
													onKeyPress: this.handleKeyPress
												}}
												white
											/>
										</GridItem>
										<GridItem xs={3} style={{ paddingTop: "12px" }}>
											<Button
												size="sm"
												color="linkedin"
												onClick={this.handleSearch}
											>
												<Search /> Search
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
							 	<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
							<Grid container>
								{this.state.show ? (
									<Grid item
										xs={12}
										sm={12}
										md={12}
										className={!_.isEmpty(this.state.result) ? classes.left : classes.center}
									>
										{!_.isEmpty(this.state.result) ? (
											<Table
												tableHead={cols}
												tableData={this.getProducts(this.state.result)}
												customCellClasses={[classes.right]}
												customClassesForCells={this.state.parcel_view ? [9]:[11]}
												customHeadCellClasses={[classes.right]}
												customHeadClassesForCells={this.state.parcel_view ? [9]:[11]}
											/>
										) : (
											<Badge color="info">No Products</Badge>
										)}
									</Grid>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve products from the server"
									/>
								)}
							</Grid>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} className={classes.center}>
									<Pagination pages={this.getPagination()} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasWrite: hasPermission("PRODUCT_CATALOG_WRITE")(state),
	};
}

export default connect(mapStateToProps)(withStyles(productCatalogStyle)(withSnackbar(ProductCatalog)));