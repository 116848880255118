import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { fetchCannedReports, runCannedReport, dlCannedReportFile } from '../actions/CustomerPortal.jsx';
import "react-table-6/react-table.css";

import ReactTable from 'react-table-6';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from '~/components/Grid/GridItem.jsx';
import Skeleton from 'react-loading-skeleton';
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "~/components/CustomButtons/Button.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import '../components/CannedReporting.css';
import { getUserSetting } from "../../../redux/selectors/Admin.jsx";
import { handleReactDatetimeChange } from '../../../helpers.jsx';
import Error403Container from "~/components/Error403Container/Error403Container.jsx";

class CannedReportingContainer extends Component {

	state = {
		initialized: false,
		report_type: '',
		start_date: '',
		end_date: '',
		newCannedReportId: null,
		refetchingReports: false,
	}

	async componentDidMount() {
		await this.props.fetchCannedReports();
		this.setState({initialized: true});
	}

	render() {
		if(this.props.hidePage) {
			// because this is hide instead of show setting, we have to check the setting and return the error container instead of it being handled in Admin.jsx
			return <Error403Container/>
		}
		if (this.props.loading || !this.state.initialized) return this.loading();

		const columns = [
			{
				Header: <span style={{textAlign: "center"}}>Click on Report to Download</span>,
				Cell: this.fileDisplayCell,
			},
		];

		return (
			<Fragment>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6}>
						<GridContainer>
							<GridItem style={{marginTop: "25px"}} xs={12} sm={4} md={12} lg={4}>
								<FormControl fullWidth>
									<InputLabel htmlFor="report_type">
										Choose Report Type
									</InputLabel>
									<Select
										inputProps={{
											id: "report_type",
											name: "report_type"
										}}
										value={this.state.report_type}
										onChange={e => this.handleChange(e)}
									>
										{this.props.hasJPWSpecialReport && 
											<MenuItem value="JPW_FREIGHT_TO_SALES">
												Freight To Sales
											</MenuItem>
										}
										<MenuItem value="CARRIER_SUMMARY">
											Carrier Summary
										</MenuItem>
										<MenuItem value="ACCOUNT_SUMMARY">
											Freight Account Summary
										</MenuItem>
										<MenuItem value="FREIGHT_INVOICE">
											Freight Invoice by Carrier
										</MenuItem>
										<MenuItem value="FREIGHT_EXPENSE">
											Freight Expense
										</MenuItem>
										<MenuItem value="CANADA_TAX">
											GST/PST
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem style={{marginTop: "25px"}} xs={12} sm={4} md={12} lg={4}>
								<InputLabel htmlFor="start_date">
									Processed Start Date
								</InputLabel>
								<FormControl fullWidth>
									<Datetime
										value={this.state.start_date || ""}
										timeFormat={false}
										dateFormat="MM/DD/YYYY"
										onChange={m => this.handleDate("start_date", m)}
									/>
								</FormControl>
							</GridItem>
							<GridItem style={{marginTop: "25px"}} xs={12} sm={4} md={12} lg={4}>
								<InputLabel htmlFor="end_date">
									Processed End Date
								</InputLabel>
								<FormControl fullWidth>
									<Datetime
										value={this.state.end_date || ""}
										timeFormat={false}
										dateFormat="MM/DD/YYYY"
										onChange={m => this.handleDate("end_date", m)}
									/>
								</FormControl>
							</GridItem>
							<GridItem xs={12}>
								<Button
									fullWidth
									color="linkedin"
									onClick={(e) => {this.handleRunCannedReportClick()}}
									disabled={this.props.running}
								>
									{this.props.running ? <CircularProgress color="inherit" size={20}/> : <span>Run Report Now</span>}
								</Button>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<GridItem style={{marginTop: "25px"}}>
							<div className="react-table-condensed">
								{this.state.refetchingReports 
									? (
										<Skeleton height={200}/>
									)
									:(
										<ReactTable
											data={this.props.cannedReports || []}
											columns={columns}
											className={this.props.className || "-striped -highlight"}
											defaultPageSize={500}
											showPaginationBottom={false}
											loading={this.props.loading}
											minRows={3}
										/>
									)
								}
								
							</div>
						</GridItem>
					</GridItem>
				</GridContainer>
			</Fragment>
		);
	}

	fileDisplayCell = props => {
		return (
			<a href="#" reportid={props.original.id} onClick={this.viewFile} style={{margin: "auto"}}>
				{props.original.time} - {props.original.type}
			</a>
		);
	}

	viewFile = e => {
        e.preventDefault();
        this.props.dlCannedReportFile(e.currentTarget.getAttribute("reportId"));
    }

	loading() {
		return (
			<Fragment>
				<GridContainer style={{marginTop: "25px"}}>
					<GridItem xs={6} sm={6} md={6}>
						<Skeleton height={200}/>
					</GridItem>
					<GridItem xs={6} sm={6} md={6}>
						<Skeleton height={200}/>
					</GridItem>
				</GridContainer>
			</Fragment>
		);
	}

	handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDate(name, moment) {
  		this.setState({ [name]: handleReactDatetimeChange(moment, "L") });
  	}

    async handleRunCannedReportClick() {
    	// Wait for report to be generated before trying to download
    	const result = await this.props.runCannedReport(this.state.report_type, this.state.start_date, this.state.end_date);
    	const reportId = this.props.newCannedReportId;
    	// Download the report - function handles empty value
    	this.props.dlCannedReportFile(reportId);
    	// Update the list of canned reports
    	this.setState({refetchingReports: true});
    	await this.props.fetchCannedReports();
    	this.setState({refetchingReports: false});
    }

}

const mapStateToProps = state => {
	return {
		loading: state.CustomerPortal.cannedReportsLoading,
		running: state.CustomerPortal.runCannedReportLoading,
		cannedReports: state.CustomerPortal.cannedReports,
		newCannedReportId: state.CustomerPortal.newCannedReportId,
		hasJPWSpecialReport: getUserSetting("jpw_special_report")(state) ? true : false,
		hidePage: getUserSetting("hide_run_std_rpts", 0)(state),
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchCannedReports,
		runCannedReport,
		dlCannedReportFile
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CannedReportingContainer);
