import React, { Component } from "react";

import axios from "~/variables/axios.jsx";
import _ from "lodash";
import qs from "qs";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Typography, DialogTitle, Dialog, DialogContent, DialogActions, FormControlLabel } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ColumnDialogTitle = withStyles({
  root: {
    margin: 0,
	padding: "10px",
  },
})(DialogTitle);

const ColumnDialogContent = withStyles({
  root: {
	padding: 0,
  },
})(DialogContent);

const getItemStyle = (isDragging, draggableStyle, colDisabled) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "5px",
	margin: "2px",
	textAlign: "center",
	fontSize: 20,
	fontWeight: "bold",
	borderStyle: isDragging ? "solid" : "none",
	background: isDragging ? "white" : colDisabled ? "lightgrey" : "white",
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#00acc1" : "#0077b5",
  display: "flex",
  flexDirection: "column",
  padding: 8,
});

class ColumnListLayoutModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  items: [],
		  modelOpen: false,
		  toggleAll: true,
		  defaultItems: [],
		  canDrag: true,
		};
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	async componentDidMount() {
		await this.fetchColumns();
		this.setState({ 
			defaultItems: this.props.listColumns
		}, function() {
			this.setItems();
		});
	}

	setItems = () => {
		let { items } = this.state;
		// To prevent having to reset and save custom lists when default columns are updated.
		// Add the new column and set to disabled if not found in saved data
		if (this.props.customListColumns && this.props.customListColumns.length) {
			items = this.props.customListColumns;
			this.props.listColumns.map((listItem) => {
				if (!items.some((e) => e.accessor === listItem.accessor)) {
					let newItem = listItem;
					newItem.colDisabled = true;
					items.push(newItem);
				}
			});
			// Remove the column if not found in defaults.
			items.map((item, i) => {
				if (!this.props.listColumns.some((e) => e.accessor === item.accessor)) {
					items.splice(i, 1);
				}
			});
			this.props.applyCustomColumns(items);
		} else {
			items = this.props.listColumns;
		}
		this.setState({ items });
	}

	onDragStart = () => {
		this.setState({
		  canDrag: false,
		});
	}

	onDragEnd(result) {
		// dropped outside of area
		if (!result.destination) {
			this.setState({
			  canDrag: true,
			});
		  return;
		}

		let items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		this.setState({
		  items,
		  canDrag: true,
		});
	}

	resetList = () => {
		const defaultCols = this.state.defaultItems.map(el => {
			return {...el, colDisabled: false};
		});
		this.setState({items: defaultCols, toggleAll: true});
	}

	saveList = () => {
		this.saveCustomColumns(this.state.items);
		this.props.applyCustomColumns(this.state.items);
		this.props.updateCols();
		this.setState({ modelOpen: false });
	}

	saveCustomColumns = async (cols) => {
		if(typeof this.props.type === 'undefined') { 
			this.props.saveCustomColumns(this.state.items); //int'l load board / invoice list /archived bol list
		 } else {
			const url = `/index.php?p=api&r=json&c=admin&m=saveCustomColumns&type=${this.props.type}`;
			const response = await axios.post(url, qs.stringify(cols));
			if (typeof response !== "string" && !_.isEmpty(response.data) && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if(typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.updated) {
				this.props.setNotification("Custom layout saved!", {variant: "success"});
				this.props.saveCustomColumns(cols);
			}
		}
	}

	fetchColumns = async () => {
		if(typeof this.props.type === 'undefined') {
			await this.props.fetchColumns(); //int'l load board / invoice list /archived bol list
		} else {
			const url = `/index.php?p=api&r=json&c=admin&m=getCustomColumns&type=${this.props.type}`;
			let customCols = await axios.get(url);
			if (typeof customCols !== "string" && !_.isEmpty(customCols.data) && !_.isEmpty(customCols.data.message)) {
				this.props.handleMessages(customCols);
			}
			if(typeof customCols.data !== "string" && !_.isEmpty(customCols.data.body) && customCols.data.body.columns) {
				this.props.fetchColumns(customCols.data.body.columns)
			}
		}
	}

	setModel = (action) => {
		this.setState({ modelOpen: action, toggleAll: true }, () => this.setItems());
	}

	setColumnDisabled = (index, e) => {
		let { items } = this.state;
		items[index].colDisabled = !e.target.checked;
		this.setState({items});
	}

	toggleAllColumns = (e) => {
		let { items } = this.state;
		const toggleAllItems = items.map(el => {
			return {...el, colDisabled: !e.target.checked};
		});
		this.setState({items: toggleAllItems, toggleAll: e.target.checked});
	}

	render() {
		if(this.props.loading) {
			return(
				<Button color="primary" style={{ margin: "10px 0" }} disabled>
					<ViewColumn/> Columns
				</Button>
			)
		}
		return (
			<React.Fragment>
				<Button color="primary" style={{ margin: "10px 0" }} onClick={() => this.setModel(true)}>
					<ViewColumn/> Columns
				</Button>
				<Dialog
					open={this.state.modelOpen}
					fullWidth={true}
					maxWidth="md"
				>
					<ColumnDialogTitle>
						<div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
							<ViewColumn style={{ fontSize: 26, margin: "5px" }} />
							<Typography variant="h6" component="div" style={{ flexGrow: 1 }}>Customize Column Layout</Typography>
							<IconButton type="button" onClick={() => this.setModel(false)}>
								<CloseIcon/>
							</IconButton>
						</div>
					</ColumnDialogTitle>
					<ColumnDialogContent style={{ display:"flex", flexDirection: "column", overflow: "hidden !important" }}>
						<FormControlLabel style={{ justifyContent: "right", margin: "0 15px"}}
        					control={<Checkbox
								checked={this.state.toggleAll}
								onClick={(e) => this.toggleAllColumns(e)}
							/>}
							label="Toggle All"
							labelPlacement="start"
	  					/>
						<DragDropContext 
							onDragEnd={this.onDragEnd} 
							onDragStart={this.onDragStart}
							style={{ width: "50%", height: "20%"}}
						>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									>
										{this.state.items.map((item, index) => {
											const disabled = item.colDisabled === "false" ? false : !item.colDisabled ? false : true;
											return (<Draggable key={item.accessor} draggableId={item.accessor} index={index} isDragDisabled={!this.state.canDrag}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style,
															disabled
														)}
													>
														<div>
															<DragIndicatorIcon/>
														</div>
														<div>
															{item.Header}
														</div>
														<div>
															<Checkbox
																size="small"
																checked={!disabled}
																onClick={(e) => this.setColumnDisabled(index, e)}
															/>
														</div>
													</div>
												)}
											</Draggable>)
											})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</ColumnDialogContent>
					<DialogActions>
						<Button
							onClick={() => this.resetList()}
							color="danger"
							size="sm"
							startIcon={<DeleteIcon />}
						>
							Reset
						</Button>
						<Button
							onClick={() => this.saveList()}
							color="linkedin"
							size="sm"
							startIcon={<SaveIcon />}
						 >
							Save
      					</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ColumnListLayoutModal);

