import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import _ from "lodash";
import Datetime from "react-datetime";

import { basePath } from "~/variables/server.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import CustomDropdown from "~/components/CustomDropdown/CustomDropdown.jsx";
import InputTag from "~/components/TMS/InputTag.jsx";
import InputList from "~/components/TMS/InputList.jsx";


// @material-ui/core components
import { Input } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import Info from "@material-ui/icons/InfoOutlined";
import Check from "@material-ui/icons/Check";
import Add from "@material-ui/icons/Add";
import Warning from "@material-ui/icons/Warning";
import Delete from "@material-ui/icons/Delete";

import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";


class WarehouseInfo extends Component {

	getBolPdfTemplates = (templates) => {
		const { classes } = this.props;
		return templates.map((template, key) => {
			return (
				<MenuItem
					key={"template_sel_" + key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={template.className}
				>
					{template.name}
				</MenuItem>
			);
		});
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<GridContainer>
						<GridItem xs>
							<InputLabel className={classes.label}>Name</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "name",
									value: this.props.name || "",
									onChange: this.props.handleInput("name")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Warehouse Code</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "code",
									value: this.props.code || "",
									onChange: this.props.handleInput("code")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Rater Profile Code</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "ctsi_code",
									value: this.props.ctsi_code || "",
									onChange: this.props.handleInput("ctsi_code")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>TFMCentral Broker ID</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "broker_id",
									value: this.props.broker_id || "",
									onChange: this.props.handleInput("broker_id")
								}}
								white
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel className={classes.label}>Override Max. Shipment Weight</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "override_max_weight",
									value: this.props.override_max_weight || "",
									onChange: this.props.handleInput("override_max_weight"),
									placeholder: "Defaults to the value set for the company."
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="override_enable_auto_class" className={classes.label}>
								Override Enable Auto Class
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.override_enable_auto_class || ""}
									inputProps={{
										id: "override_enable_auto_class",
										name: "override_enable_auto_class"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="inherit"
									>
										Inherit
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="enable"
									>
										Enabled
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="disable"
									>
										Disabled
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="override_allow_auto_pro" className={classes.label}>
								Override Enable Auto PRO
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.override_allow_auto_pro || ""}
									inputProps={{
										id: "override_allow_auto_pro",
										name: "override_allow_auto_pro"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="inherit"
									>
										Inherit
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="enable"
									>
										Enabled
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="disable"
									>
										Disabled
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel htmlFor="override_require_po" className={classes.label}>
								Override Require PO
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.override_require_po || ""}
									inputProps={{
										id: "override_require_po",
										name: "override_require_po"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="inherit"
									>
										Inherit
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="enable"
									>
										Enabled
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="disable"
									>
										Disabled
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="override_require_so" className={classes.label}>
								Override Require SO
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.override_require_so || ""}
									inputProps={{
										id: "override_require_so",
										name: "override_require_so"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="inherit"
									>
										Inherit
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="enable"
									>
										Enabled
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="disable"
									>
										Disabled
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="override_require_reference" className={classes.label}>
								Override Require Reference #
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.override_require_reference || ""}
									inputProps={{
										id: "override_require_reference",
										name: "override_require_reference"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="inherit"
									>
										Inherit
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="enable"
									>
										Enabled
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="disable"
									>
										Disabled
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel htmlFor="override_custom_piece_count" className={classes.label}>
								Override Custom Piece Count
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.override_custom_piece_count || ""}
									inputProps={{
										id: "override_custom_piece_count",
										name: "override_custom_piece_count"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="inherit"
									>
										Inherit
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="enable"
									>
										Enabled
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="disable"
									>
										Disabled
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel htmlFor="default_direction_terms" className={classes.label}>
								Default Direction/Freight Term
							</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.default_direction_terms || ""}
									inputProps={{
										id: "default_direction_terms",
										name: "default_direction_terms"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value=""
									/>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Outbound/Prepaid"
									>
										Outbound/Prepaid
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Outbound/Collect"
									>
										Outbound/Collect
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Inbound/Collect"
									>
										Inbound/Collect
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="Third Party"
									>
										Third Party
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							{(this.props.bol_pdf_template !== 'bolTemplateDefault') ? (
								<WithTooltip
									title="Important Notice!"
									content="This template is not compatible with any other BOL PDF configuration settings you may have applied."
									placement="bottom"
								>
									<InputLabel htmlFor="bol_pdf_template" className={classes.label}>
										BOL PDF Template
										<Warning style={{ height: "16px" }} />
									</InputLabel>
								</WithTooltip>
							) : (
								<InputLabel htmlFor="bol_pdf_template" className={classes.label}>
									BOL PDF Template
								</InputLabel>
							)}
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.bol_pdf_template || ""}
									inputProps={{
										id: "bol_pdf_template",
										name: "bol_pdf_template"
									}}
									onChange={this.props.handleChange}
								>
									{this.getBolPdfTemplates(this.props.bol_pdf_templates)}
								</Select>
							</FormControl>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel className={classes.label}>Address 1</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "address1",
									value: this.props.address1 || "",
									onChange: this.props.handleInput("address1")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Address 2</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "address2",
									value: this.props.address2 || "",
									onChange: this.props.handleInput("address2")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Zip</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "zip",
									value: this.props.zip || "",
									onChange: this.props.handleInput("zip")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>City</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "city",
									value: this.props.city || "",
									onChange: this.props.handleInput("city")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>State</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "state",
									value: this.props.state || "",
									onChange: this.props.handleInput("state")
								}}
								white
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel className={classes.label}>Rater</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{
										className: classes.selectMenu
									}}
									classes={{
										select: classes.select + " " + classes.requiredSelect
									}}
									value={this.props.rater || ""}
									inputProps={{
										id: "rater",
										name: "rater"
									}}
									onChange={this.props.handleChange}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="tfmr"
									>
										TFMRater
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="parallel"
									>
										Parallel Testing (TFMR/Sandbox)
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Rater Cutoff Date</InputLabel>
							<br />
							<FormControl fullWidth>
								<Datetime
									value={this.props.rater_cutoff_date || ""}
									timeFormat={false}
									onChange={m => this.props.handleDatetime("rater_cutoff_date", m)}
									className={classes.datetime}
									inputProps={{
										id: "rater_cutoff_date",
										name: "rater_cutoff_date",
										placeholder: "Select Rater Cutoff Date"
									}}
								/>
							</FormControl>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<InputLabel htmlFor="override_ref_entry_type" className={classes.label}>
						Reference # Entry Type
					</InputLabel>
					<br />
					<FormControl fullWidth className={classes.selectFormControl}>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={this.props.override_ref_entry_type || ""}
							inputProps={{
								name: "override_ref_entry_type",
								id: "override_ref_entry_type"
							}}
							onChange={this.props.handleSelect}
						>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="inherit"
							>
								Inherit
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="freeform"
							>
								Freeform Input
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="select"
							>
								Select Option
							</MenuItem>
						</Select>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={6} md={9}>
					{(this.props.override_ref_entry_type === 'select') ? (
						<div>
							<InputLabel className={classes.label}>
								Reference # Entry Options
							</InputLabel>
							<InputList 
								values={this.props.ref_entry_opts} 
								onChange={values => this.setState({ ref_entry_opts: values })}
							/>
						</div>
					): ''}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<h5>BOL</h5>
					<GridContainer>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.separate_prods_hus}
										tabIndex={-1}
										onChange={this.props.handleChecked("separate_prods_hus")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="List Products and Units Separately"
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.fuzzy_product_code}
										tabIndex={-1}
										onChange={this.props.handleChecked("fuzzy_product_code")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Allow Partial Matches on Product Code"
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.bol_pdf_hide_measurements}
										tabIndex={-1}
										onChange={this.props.handleChecked("bol_pdf_hide_measurements")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Hide Individual Measurements on BOL"
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.require_pod_for_billing}
										tabIndex={-1}
										onChange={this.props.handleChecked("require_pod_for_billing")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Default Require POD for Billing"
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.require_packing_group}
										tabIndex={-1}
										onChange={this.props.handleChecked("require_packing_group")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Require Packing Group"
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.enable_product_part_no}
										tabIndex={-1}
										onChange={this.props.handleChecked("enable_product_part_no")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Enable Product Part No."
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={4}>
							<WithTooltip
								title="Prevent Exclusion Execution"
								content="Prevent users from executing BOLs with a carrier that hit a linear foot exclusion or cubic capacity rule."
								placement="top"
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.props.prevent_exclusion_execution}
											tabIndex={-1}
											onChange={this.props.handleChecked("prevent_exclusion_execution")}
											checkedIcon={<Check className={classes.checkedIcon} />}
											icon={<Check className={classes.uncheckedIcon} />}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot
											}}
										/>
									}
									classes={{
										label: classes.label
									}}
									label="Prevent Exclusion Execution"
								/>
							</WithTooltip>
						</GridItem>
						<GridItem xs={4}>
							<WithTooltip
								title="DGD Additional Handling Instructions"
								content="Overrides the additional handling instructions space on the dangerous goods declaration with the content of the text field below."
								placement="top"
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.props.override_dgd_addl_handling_instructions}
											tabIndex={-1}
											onChange={this.props.handleChecked("override_dgd_addl_handling_instructions")}
											checkedIcon={<Check className={classes.checkedIcon} />}
											icon={<Check className={classes.uncheckedIcon} />}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot
											}}
										/>
									}
									classes={{
										label: classes.label
									}}
									label="DGD Additional Handling Instructions"
								/>
							</WithTooltip>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.require_acc_recommendations}
										tabIndex={-1}
										onChange={this.props.handleChecked("require_acc_recommendations")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Require Shiplify Accessorial Recommendations"
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={4}>
							<WithTooltip
								title="Standardize US LTL Pickup Addresses"
								content="Applies to Project44 dispatch requests originating from the US. If enabled, the shipper address provided by the user will be sent to a verification service to standardize the address. If exactly one valid result is returned from the service, the address provided by the service will be used as the pickup address in the request to Project44."
								placement="top"
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.props.standardize_us_ltl_pickup_adr}
											tabIndex={-1}
											onChange={this.props.handleChecked("standardize_us_ltl_pickup_adr")}
											checkedIcon={<Check className={classes.checkedIcon} />}
											icon={<Check className={classes.uncheckedIcon} />}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot
											}}
										/>
									}
									classes={{
										label: classes.label
									}}
									label="Standardize US LTL Pickup Addresses"
								/>
							</WithTooltip>
						</GridItem>
						<GridItem xs={4}>
							<Input
								disabled={!this.props.override_dgd_addl_handling_instructions}
								onChange={this.props.handleInput("dgd_addl_handling_instructions")}
								value={this.props.dgd_addl_handling_instructions}
								multiline
								fullWidth
								inputProps={{ maxLength: 500 }}
								placeholder="DGD Additional Handling Instructions (500 characters MAX)"
							/>
						</GridItem>
						{this.props.hide_tl_carriers && (
							<GridItem xs={6}>
								<InputTag
									inputLabel={
										<>
											Override FTL SCACs for Warehouse
											<WithTooltip
												content="If any SCACs are selected, the company defaults will not be applied and only the SCACs selected will be treated as FTL."
											>
												<Info style={{ height: 16, marginLeft: 4 }} />
											</WithTooltip>
										</>
									}
									classes={classes}
									value={this.props.warehouse_ftl_scacs}
									options={this.props.tfm_scacs}
									onChange={this.props.handleFtlScacAssignment}
									getOptionSelected={(option, value) => option == value}
									getOptionLabel={(option) => `${option}`}
									renderOption={(option, { selected }) => (
										<>
											<Checkbox
												checkedIcon={<Check className={this.props.classes.checkedIcon} />}
												icon={<Check className={this.props.classes.uncheckedIcon} />}
												style={{ marginRight: 8 }}
												checked={selected}
											/>
											{option}
										</>
									)}
								/>
							</GridItem>
						)}
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
								<h5>
									Default Standard BOL Reference Values
									<WithTooltip
										title="Default Standard BOL Reference Values"
										content="Configure values to set default standard BOL References to."
										placement="top"
									>
										<Info style={{ height: 16, marginLeft: 4 }} />
									</WithTooltip>
								</h5>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>PO Number</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "po_number",
									value: this.props.po_number || "",
									onChange: this.props.handleInput("po_number")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>SO Number</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "so_number",
									value: this.props.so_number || "",
									onChange: this.props.handleInput("so_number")
								}}
								white
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Ref Number</InputLabel>
							<br />
							<CustomInput
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "ref_number",
									value: this.props.ref_number || "",
									onChange: this.props.handleInput("ref_number")
								}}
								white
							/>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<h5>Parcel</h5>
					<GridContainer>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.enable_fedex_parcel_shipping}
										tabIndex={-1}
										onChange={this.props.handleChecked("enable_fedex_parcel_shipping")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Enable FedEx Shipping"
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.enable_ups_parcel_shipping}
										tabIndex={-1}
										onChange={this.props.handleChecked("enable_ups_parcel_shipping")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Enable UPS Shipping"
							/>
						</GridItem>
						<GridItem xs>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.props.enable_usps_parcel_shipping}
										tabIndex={-1}
										onChange={this.props.handleChecked("enable_usps_parcel_shipping")}
										checkedIcon={<Check className={classes.checkedIcon} />}
										icon={<Check className={classes.uncheckedIcon} />}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot
										}}
									/>
								}
								classes={{
									label: classes.label
								}}
								label="Enable USPS Shipping"
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs>
							<InputLabel className={classes.label}>HazMat Software</InputLabel>
							<br />
							<GridContainer>
								<GridItem xs>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.props.hazmat_api_test}
												tabIndex={-1}
												onChange={this.props.handleChecked("hazmat_api_test")}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
											/>
										}
										classes={{
											label: classes.label
										}}
										label="Test Account"
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Dev Id</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "hazmat_dev_id",
											value: this.props.hazmat_dev_id || "",
											onChange: this.props.handleInput("hazmat_dev_id")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>App Id</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "hazmat_app_id",
											value: this.props.hazmat_app_id || "",
											onChange: this.props.handleInput("hazmat_app_id")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>Token</InputLabel>
									<br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "hazmat_token",
											value: this.props.hazmat_token || "",
											onChange: this.props.handleInput("hazmat_token")
										}}
										white
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<h5>
										Custom Shipment References
										<WithTooltip
											title="Custom Shipment References"
											content="Configure what custom reference fields will appear on LTL and Parcel shipment entry pages, their default values and whether or not they are required. References can be set to be freeform text or drop downs with the options being those specified on this page."
											placement="top"
										>
											<Info style={{ height: 16, marginLeft: 4 }} />
										</WithTooltip>
										<Button color="success" size="sm" justIcon round className={classes.marginLeft} onClick={this.props.addReferenceSetting}>
											<Add/>
										</Button>
									</h5>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={12}>
									{this.props.referenceSettings.map((setting, index) => {
										return (
											<CustomReferenceSetting
												key={index}
												index={index}
												classes={classes}
												value={setting.value}
												default_value={setting.default_value}
												display_on_bol={setting.display_on_bol}
												isRequired={setting.required}
												cust_ref_entry_type={setting.cust_ref_entry_type}
												cust_ref_entry_opts={setting.cust_ref_entry_opts}
												types={this.props.referenceTypes}
												onRemove={this.props.onRemoveReferenceSetting}
												onChangeType={this.props.onChangeReferenceSettingType}
												onChangeCheckbox={this.props.onChangeReferenceSettingCheckBox}
											/>
										);
									})}
								</GridItem>
							</GridContainer>
					</GridContainer> 
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<h5>Logo</h5>
					<GridContainer>
						<GridItem xs={12} sm={12} md={6}>
							<Dropzone handleUpload={this.props.handleUpload.bind(this)} />
						</GridItem>
						{!_.isEmpty(this.props.logo) && (
							<GridItem xs={12} sm={12} md={6}>
								<aside className={classes.thumbsContainer}>{this.props.getImage(this.props.logo)}</aside>
							</GridItem>
						)}
					</GridContainer>
				</GridItem>
				{this.props.isEdit && (
					<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
						<Grid container>
							<GridItem xs={12} sm={8} md={9} lg={10} className={classes.right}>
								<Grid container justify="center">
									<GridItem xs={12} className={classes.center}>
										<NavLink to={basePath + "/admin/locations/import?d=" + this.props.id}>
											<Button className={classes.marginRight}>Import Locations</Button>
										</NavLink>
										<Button className={classes.marginRight} onClick={() => this.props.export("location")}>
											Export Locations
										</Button>
										<NavLink to={basePath + "/admin/products/import?d=" + this.props.id}>
											<Button className={classes.marginRight}>Import Products</Button>
										</NavLink>
										<Button className={classes.marginRight} onClick={() => this.props.export("product")}>
											Export TL/LTL Products
										</Button>
										<Button className={classes.marginRight} onClick={() => this.props.export("parcelProduct")}>
											Export Parcel Products
										</Button>
										<NavLink to={basePath + "/admin/units/import?d=" + this.props.id}>
											<Button className={classes.marginRight}>Import Units</Button>
										</NavLink>
										<Button className={classes.marginRight} onClick={() => this.props.export("unit")}>
											Export Units
										</Button>
									</GridItem>
								</Grid>
							</GridItem>
							<GridItem xs={12} sm={4} md={3} lg={2} className={classes.right}>
								<CustomDropdown
									buttonText="Dangerous Tools"
									hoverColor="danger"
									buttonProps={{
										round: false,
										block: true,
										color: "black"
									}}
									dropPlacement="top"
									dropdownList={[<div onClick={() => this.props.handleDelete("location")}>Delete All Locations</div>, <div onClick={() => this.props.handleDelete("product")}>Delete All Products</div>, <div onClick={() => this.props.handleDelete("unit")}>Delete All Units</div>]}
								/>
							</GridItem>
						</Grid>
					</GridItem>
				)}
			</GridContainer>
		)
	}
}

// @todo break this out?
class CustomReferenceSetting extends Component {
	render() {
		const classes = this.props.classes || {};
		return(
			<GridContainer>
				<GridItem xs={1} sm={1} md={1} lg={1}>
					<InputLabel className={classes.label}>Type</InputLabel>
					<FormControl fullWidth className={classes.selectFormControl}>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={this.props.cust_ref_entry_type || ""}
							inputProps={{
								name: "cust_ref_entry_type",
								id: "cust_ref_entry_type"
							}}
							onChange={this.onChangeEntryType}
						>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="freeform"
							>
								Freeform Input
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="select"
							>
								Select Option
							</MenuItem>
						</Select>
					</FormControl>
	            </GridItem>
	            <GridItem xs={4} sm={4} md={4} lg={3}>
	                <WithTooltip title="Reference Name" content="What is this reference called?">
	                    <InputLabel className={classes.label}>Name</InputLabel>
	                </WithTooltip>
	                <br />
	                <FormControl fullWidth>
	                    <CustomInput
	                        inputProps={{
	                            type: "text",
	                            value: this.props.value,
	                            onInputChange: this.onChangeType,
	                        }}
	                        autoComplete
	                        options={this.props.types}
	                        white
	                    />
	                </FormControl>
	            </GridItem>
				{(this.props.cust_ref_entry_type === 'select') ? (
					<>
						<GridItem xs={3} sm={3} md={3} lg={2}>
							<InputLabel className={classes.label}>
								Options
							</InputLabel>
							<InputList 
								values={this.props.cust_ref_entry_opts} 
								onChange={this.onChangeOpts}
							/>
						</GridItem>
						<GridItem xs={3} sm={3} md={3} lg={2}>
								<WithTooltip title="Default Value" content="If a value is provided in this field, the reference and value will be pre-loaded when users create new BOLs for this warehouse">
									<InputLabel className={classes.label}>Default Value</InputLabel>
								</WithTooltip>
								<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select 
									value={this.props.default_value || ''}
									inputProps={{ name: "cust_ref_entry_opts" }}
									onChange={this.onChangeDefaultValue}
									sx={{minWidth: 130}}
								>
									<MenuItem
										key={"default_value_" + this.props.index}
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value=""
									>
										<em>None</em>
									</MenuItem>
									{this.props.cust_ref_entry_opts.map((val, key) => {
										return (
											<MenuItem
												key={"reference_name_" + key}
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value={val}
											>
												{((_.isEmpty(val)) ? (<span style={{color: 'transparent'}}>none</span>) : val)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</GridItem>
					</>
				) : 
					<GridItem xs={6} sm={6} md={6} lg={4}>
						<WithTooltip title="Default Value" content="If a value is provided in this field, the reference and value will be pre-loaded when users create new BOLs for this warehouse">
							<InputLabel className={classes.label}>Default Value</InputLabel>
						</WithTooltip>
						<br />
						<FormControl fullWidth>
							<CustomInput
								inputProps={{
									type: "text",
									value: this.props.default_value,
									onChange: this.onChangeDefaultValue,
								}}
								white
							/>
						</FormControl>
					</GridItem>
				}
	            <GridItem xs={2} sm={2} md={2} lg={1}>
		            <br />
	                <WithTooltip title="Required" content="Require this custom reference for execution">
	                    <FormControlLabel
	                        label="Required"
	                        control={
	                            <Checkbox
	                                size="small"
	                                checked={this.props.isRequired ? true : false}
	                                onChange={this.onChangeRequired}
	                            />
	                        }
	                    />
	                </WithTooltip>
	            </GridItem>
	            <GridItem xs={2} sm={2} md={2} lg={1}>
	            	<br />
	                <WithTooltip title="Display on BOL" content="If enabled, BOLs with this reference may be displayed on the BOL. Up to 3 custom references will appear on a BOL.">
	                    <FormControlLabel
	                        label="Display on BOL"
	                        control={
	                            <Checkbox
	                                size="small"
	                                checked={this.props.display_on_bol ? true : false}
	                                onChange={this.onChangeDisplayOnBol}
	                            />
	                        }
	                    />
	                </WithTooltip>
	            </GridItem>
	            <GridItem xs={4} sm={4} md={4} lg={1}>
	            	<br />
	                <Button color="danger" size="sm" justIcon round onClick={this.onRemove}>
	                    <Delete/>
	                </Button>
	            </GridItem>
	        </GridContainer>
        );
	}

	onChangeType = (e, val) => {
		if (e) this.props.onChangeType(this.props.index, val, 'value');
	}

	onChangeEntryType = (e, val) => {
		if (e) {
			this.props.onChangeType(this.props.index, val.props.value, 'cust_ref_entry_type');
			// reset the options/default value on change
			this.props.onChangeType(this.props.index, [], 'cust_ref_entry_opts');
			this.props.onChangeType(this.props.index, '', 'default_value');
		}
	}
	onChangeOpts = (e) => {
		if (e) this.props.onChangeType(this.props.index, e, 'cust_ref_entry_opts');
	}
	// values => this.setState({ cust_ref_entry_opts: values }
	// cust_ref_entry_type
	// cust_ref_entry_opts

	onChangeDefaultValue = (e) => {
		if (e) this.props.onChangeType(this.props.index, e.target.value, 'default_value');
	}

	onChangeRequired = e => {
		if (e) this.props.onChangeCheckbox(this.props.index, 'required');
	}

	onChangeDisplayOnBol = e => {
		if (e) this.props.onChangeCheckbox(this.props.index, 'display_on_bol');
	}
	

	onRemove = e => {
		if (e) this.props.onRemove(this.props.index);
	}
}



export default withStyles(style)(withSnackbar(WarehouseInfo));