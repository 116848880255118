import React, { Component } from "react";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import GridItem from "~/components/Grid/GridItem.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "~/components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Table from "~/components/Table/Table.jsx";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

class LiabilityModal extends Component {

    getLiability = data => {
        return data.map((rate, key) => {
            const tableData = rate.coveredItems.map((prop) => {
                let nmfc = prop.nmfc.prime;
                if (!_.isEmpty(prop.nmfc.sub)) {
                    nmfc += "-" + prop.nmfc.sub;
                }
                return [prop.description, prop.class, nmfc, prop.totalNetWeight, prop.totalPieces, "$" + prop.coverage.toFixed(2)];
            });
            return (
                <GridItem xs={12} key={key}>
                    <h5>Products covered up to {rate.coverageRate}</h5>
                    <Table tableHead={["Product", "Class", "NMFC", "Weight", "Pieces", "Coverage"]} tableData={tableData} />
                </GridItem>
            );
        });
    }

    render() {
        const classes = this.props.classes || {};
        if (_.isEmpty(this.props.liability)) {
            return null;
        }
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.onClose}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={this.props.onClose}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{this.props.title}</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {!_.isEmpty(this.props.liability) ? (
                        <Grid container>
                            <GridItem xs={12}>
                                <Grid container>{this.getLiability(this.props.liability.details.standard)}</Grid>
                            </GridItem>
                            {this.props.liability.maxStandardCoverage > 0 && (
                                <GridItem xs={12}>
                                    <InfoOutlined /> <b>Maximum Carrier Coverage: ${this.props.liability.maxStandardCoverage.toFixed(2)}</b>
                                </GridItem>
                            )}
                            {this.props.liability.totalExcessCoverage > 0 && (
                                <GridItem xs={12}>
                                    <b>Excess Coverage Provided: ${this.props.liability.totalExcessCoverage.toFixed(2)}</b>
                                </GridItem>
                            )}
                            {this.props.liability.excessCoverageCharge > 0 && (
                                <GridItem xs={12}>
                                    <b>Excess Coverage Fee: ${this.props.liability.excessCoverageCharge.toFixed(2)}</b>
                                </GridItem>
                            )}
                        </Grid>
                    ) : (
                        <h5>No standard liability coverage found for this carrier.</h5>
                    )}
                    <Grid container justify="center">
                        <GridItem xs={12} className={classes.center}>
                            <Button color="linkedin" onClick={this.props.onRerate}>
                                Re-Rate with Full Value Coverage
                            </Button>
                        </GridItem>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button color="warning" onClick={this.props.onClose} className={classes.marginRight}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

export default LiabilityModal;