import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import DotSelect from "../../TMS/DotSelect.jsx";

class ImportanceLevelSelect extends React.Component {

    onSelect = (level, key) => {
        if (typeof this.props.onSelect === "function") {
            this.props.onSelect(level, key);
        }
    }

    getSelectedLevelKey = () => {
        let key = null
        if (this.props.levelId && this.props.levels) {
            this.props.levels.map((level, index) => {
                if (+level.id === +this.props.levelId) {
                    key = index;
                }
            })
        }
        return key;
    }

	render() {
		if (this.props.loading) return null;
		return(
            <DotSelect
                options={this.props.levels}
                onSelect={this.onSelect}
                selectedKey={this.getSelectedLevelKey()}
            />
        );
	}
}

const mapStateToProps = state => {
    return {
        loading: state.ImportanceLevel.loading,
        levels: state.ImportanceLevel.levels,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportanceLevelSelect);