import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import cx from "classnames";
import { NavLink, Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { baseUrl, assetPath, basePath, pathname } from "~/variables/server.jsx";
import { connect } from "react-redux";
import { clearNotifications } from "~/redux/actions/notifications.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

// core components
import AdminNavbarLinks from "~/components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "~/assets/jss/empire-tms/components/sidebarStyle.jsx";

const avatar = baseUrl + assetPath + "/img/default-avatar.png";
const poweredByLogo = baseUrl + assetPath + "/img/empire/logo-light.png";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				<Divider variant="middle" />
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			logo: (_.isEmpty(props.logo)) ? baseUrl + assetPath + "/empire/logo-light.png" : props.logo,
			openAvatar: false,
			miniActive: true,
			footerExpand: false,
			exit: false,
			logout: false,
			...this.getCollapseStates(props.routes)
		};
	}
	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = routes => {
		let initialState = {};
		if (routes === undefined || routes.length < 1) {
			return initialState;
		}
		routes.map((prop, key) => {
			if (prop === null) {
				return null;
			}
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (
				routes[i].collapse &&
				this.getCollapseInitialState(routes[i].views)
			) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute = routeName => {
		return this.props.location.pathname.indexOf(routeName) > -1
			? "active"
			: "";
	};
	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = routes => {
		if (routes === undefined || routes.length < 1) {
			return [];
		}
		const { classes, color, rtlActive } = this.props;
		return routes.map((prop, key) => {
			if (prop === null) {
				return null;
			}
			if (prop.invisible) {
				return null;
			}
			if (prop.redirect) {
				return null;
			}
			if (prop.collapse) {
				var st = {};
				st[prop["state"]] = !this.state[prop.state];
				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[classes.collapseActive]: this.getCollapseInitialState(
							prop.views
						),
						[classes.itemNavLink]: true,
						[classes.itemLinkMini]: this.props.miniActive && this.state.miniActive,
					});
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]:
							rtlActive &&
							this.props.miniActive &&
							this.state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});
				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextMiniRTL]:
							rtlActive &&
							this.props.miniActive &&
							this.state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive
					});
				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive
					});
				const caret =
					classes.caret +
					" " +
					cx({
						[classes.caretRTL]: rtlActive
					});
				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive
					});
				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.icon !== undefined },
							{ [classes.collapseItem]: prop.icon === undefined }
						)}
					>
						<NavLink
							to={"#"}
							className={navLinkClasses}
							onClick={e => {
								e.preventDefault();
								this.setState(st);
							}}
						>
							{prop.icon !== undefined ? (
								typeof prop.icon === "string" ? (
									<Icon className={itemIcon}>
										{prop.icon}
									</Icon>
								) : (
									<prop.icon className={itemIcon} />
								)
							) : (
								<span className={collapseItemMini}>
									{typeof prop.mini === "string" ? prop.mini : (
										<prop.mini />
									)}
								</span>
							)}
							<ListItemText
								primary={prop.name}
								secondary={
									<b
										className={
											caret +
											" " +
											(this.state[prop.state]
												? classes.caretActive
												: "")
										}
									/>
								}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined },
									{
										[collapseItemText]:
											prop.icon === undefined
									}
								)}
							/>
						</NavLink>
						<Collapse in={this.state[prop.state]} unmountOnExit>
							<List
								className={
									classes.list + " " + classes.collapseList
								}
							>
								{this.createLinks(prop.views)}
							</List>
						</Collapse>
					</ListItem>
				);
			}
			const innerNavLinkClasses =
				classes.collapseItemLink +
				" " +
				cx({
					[" " + classes[color]]: this.activeRoute(prop.path)
				});
			const collapseItemMini =
				classes.collapseItemMini +
				" " +
				cx({
					[classes.collapseItemMiniRTL]: rtlActive
				});
			const navLinkClasses =
				classes.itemLink +
				" " +
				cx({
					[classes[color]]: this.activeRoute(prop.path),
					[classes.itemLinkMini]: this.props.miniActive && this.state.miniActive,
				});
			const itemText =
				classes.itemText +
				" " +
				cx({
					[classes.itemTextMini]:
						this.props.miniActive && this.state.miniActive,
					[classes.itemTextMiniRTL]:
						rtlActive &&
						this.props.miniActive &&
						this.state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});
			const collapseItemText =
				classes.collapseItemText +
				" " +
				cx({
					[classes.collapseItemTextMini]:
						this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextMiniRTL]:
						rtlActive &&
						this.props.miniActive &&
						this.state.miniActive,
					[classes.collapseItemTextRTL]: rtlActive
				});
			const itemIcon =
				classes.itemIcon +
				" " +
				cx({
					[classes.itemIconRTL]: rtlActive
				});
			return (
				<ListItem
					key={key}
					className={cx(
						{ [classes.item]: prop.icon !== undefined },
						{ [classes.collapseItem]: prop.icon === undefined }
					)}
				>
					<NavLink
						to={prop.layout + prop.path}
						className={cx(
							{ [navLinkClasses]: prop.icon !== undefined },
							{ [innerNavLinkClasses]: prop.icon === undefined },
						)}
						onClick={(e) => {
							if(prop.path == '/bol/new') {
								if(window.location.pathname.includes('/bol/new')) {
									window.location.href = basePath + '/admin/bol/new';
								}
							}
						}}
					>
						{prop.icon !== undefined ? (
							typeof prop.icon === "string" ? (
								<Icon className={itemIcon}>{prop.icon}</Icon>
							) : (
								<prop.icon className={itemIcon} />
							)
						) : (
							<span className={collapseItemMini}>
								{typeof prop.mini === "string" ? prop.mini : (
									<prop.mini />
								)}
							</span>
						)}
						<ListItemText
							primary={rtlActive ? prop.rtlName : prop.name}
							disableTypography={true}
							className={cx(
								{ [itemText]: prop.icon !== undefined },
								{ [collapseItemText]: prop.icon === undefined }
							)}
						/>
					</NavLink>
				</ListItem>
			);
		});
	};
	async handleLogout() {
		let logoutUrl = basePath,
			exit = false,
			logout = false;
		if (this.props.user.parent_id) {
			exit = true;
			logoutUrl += "/index.php?p=api&r=json&c=user&m=takeBackAccount";
		} else {
			logout = true;
			logoutUrl += "/index.php?p=api&r=json&c=user&m=logout";
		}
		try {
			const response = await axios.get(logoutUrl);
			if(response.data) {
				this.setState({ exit, logout });
			}
			this.props.clearNotifications();
		} catch (error) {
			console.error(error);
		}
	}
	render() {
		if(this.state.exit) {
			window.location.href = basePath + "/admin/management/users"; //hacky page force to load corrent session, needs better handling
		}
		if(this.state.logout) {
			return <Redirect to={basePath + "/auth/login-page"} />;
		}
		const {
			classes,
			logo,
			image,
			logoText,
			routes,
			bgColor,
			rtlActive
		} = this.props;
		const navLinkClasses =
			classes.itemLink +
			" " +
			cx({
				[classes.itemNavLink]: true,
				[classes.itemLinkMini]: this.props.miniActive && this.state.miniActive,
			});
		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive
			});
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive
			});
		const userWrapperClass =
			classes.user +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white"
			});
		const caret =
			classes.caret +
			" " +
			cx({
				[classes.caretRTL]: rtlActive
			})
		const collapseItemMini =
			classes.collapseItemMini +
			" " +
			cx({
				[classes.collapseItemMiniRTL]: rtlActive
			});
		const user = (
			<div className={userWrapperClass}>

				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						<NavLink
							to={"#"}
							className={navLinkClasses}
							onClick={() => this.openCollapse("openAvatar")}
						>
							<div className={classes.photo}>
								<img src={avatar} className={classes.avatarImg} alt="..." />
							</div>
							<ListItemText
								primary={this.props.user.name}
								secondary={
									<b
										className={caret + " " + classes.userCaret + " " + (this.state.openAvatar ? classes.caretActive : "")}
									/>
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar} unmountOnExit>
							<List className={classes.list + " " + classes.collapseList}>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="#"
										className={classes.itemLink + " " + classes.userCollapseLinks}
										onClick={this.handleLogout.bind(this)}
									>
										<span className={collapseItemMini}>
											{this.props.user.parent_id ? "ES" : "L"}
										</span>
										<ListItemText
											primary={this.props.user.parent_id ? "Exit Session" : "Logout"}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
								{(this.props.user.user_settings.disable_password_change != 1) && (
									<ListItem className={classes.collapseItem}>
										<NavLink
											to={basePath + '/admin/change-password/' + this.props.user.id}
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<span className={collapseItemMini}>
												CP
											</span>
											<ListItemText
												primary={"Change Password"}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									</ListItem>
								)}
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		);

		var links = (
			<List className={classes.list}>{this.createLinks(routes)}</List>
		);
		const logoNormal =
			classes.logoNormal +
			" " +
			cx({
				[classes.logoNormalSidebarMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.logoNormalSidebarMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.logoNormalRTL]: rtlActive
			});
		const logoMini =
			classes.logoMini +
			" " +
			cx({
				[classes.logoMiniRTL]: rtlActive
			});
		const logoClasses =
			classes.logo +
			" " +
			cx({
				[classes.whiteAfter]: true
			});
		const bannerClasses =
			classes.logoBanner +
			" " +
			cx({
				[classes.blackAfter]: true
			});
		/*var brand = (
			<div className={logoClasses}>
				<a href="https://www.targetfmi.com" className={logoMini}>
					<img src={logo} alt="logo" className={classes.img} />
				</a>
				<a href="https://www.targetfmi.com" className={logoNormal}>
					{logoText}
				</a>
			</div>
		);*/
		// const tfmLogo = this.props.miniActive ? "tfm-logo-mini.png" : "tfm-logo-sidebar.jpg";
		// const tfmLogoSidebar = baseUrl + assetPath + "/img/tfm-logo-sidebar.jpg";
		var brand = (
			<div className={bannerClasses}>
				<a href="#" className={classes.bannerContainer}>
					<img className={classes.banner} src={this.state.logo} alt="TMS" />
				</a>
			</div>
		);
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});
		return (
			<div ref="mainPanel">
				<Drawer
					onMouseOver={() => this.setState({ miniActive: false })}
					onMouseOut={() => this.setState({ miniActive: true })}
					onClose={this.props.handleDrawerToggle}
					anchor={rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{paper: drawerPaper + " " + classes[bgColor + "Background"]}}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{brand}
					<SidebarWrapper
						className={sidebarWrapper}
						user={user}
						links={links}
					/>
					{image !== undefined ? (
						<div
							className={classes.background}
							style={{backgroundImage: "url(" + image + ")"}}
						/>
					) : null}
					<footer className={classes.footer}
						onMouseEnter={() => this.setState({ footerExpand: true })}
						onMouseLeave={() => this.setState({ footerExpand: false })}>
						<div>
							<img className={classes.poweredByImg} src={poweredByLogo} alt="TFM" />
						</div>
						{this.state.footerExpand && (<div>
							<div>
								&copy; {1900 + new Date().getYear()}{" "}
								<a href="https://www.targetfmi.com">
									{"Target FMI"}
								</a>
							</div>
							<div>
								{"US Patent No. 9,747,578"}
							</div>
						</div>)}
					</footer>
				</Drawer>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: "black"
};

Sidebar.propTypes = {
	user: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"white",
		"red",
		"orange",
		"green",
		"blue",
		"purple",
		"rose"
	]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object)
};

export default connect(null, {clearNotifications})(withStyles(sidebarStyle)(Sidebar));
