import React, { Component } from "react";
import { withSnackbar } from "notistack";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";

import Button from "~/components/CustomButtons/Button.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';

// material ui icons
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Add from "@material-ui/icons/Add";
import MoreVertIcon from '@material-ui/icons/MoreVert';

// style for this view
import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

class WarehouseUsers extends Component {
	state = {
		warehouseUsers: [],
		companyUsers: [],
		newUser: [],
		onEdit: false,
		alert: null,
		loading: false,
		show: false,
	}

	async componentDidMount() {
		const whId = this.props.warehouseId;
		const url = `/index.php?p=api&r=json&c=admin&m=editWarehouseUserAssignment&whId=${whId}`;
		this.setState({ show: false, loading: true });
		try {
			const response = await axios.get(url);
			if(typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			
			if(typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.users) {
				const users = response.data.body.users;
				let allUsers = this.props.companyUsers.filter(cUser => !users.some(user => cUser.id == user.id || cUser.active == "0")).map((cUser) => ({id: cUser.id, username: cUser.username}));
				this.setState({
					warehouseUsers: _.sortBy(users, user => parseInt(user.id, 10)),
					companyUsers: allUsers
				})
			} else {
				this.props.setNotification("Error loading warehouse users.", { variant: "error" });
				this.setState({
					companyUsers: this.props.companyUsers
				});
			}

		} catch(error) {
			console.error(error);
			this.props.setNotification("Error loading warehouse users.", { variant: "error" });
		}
		this.setState({ show: true, loading: false });
	}

	addNewUserRow = () => {
		this.setState({
			warehouseUsers:[
				{id: null, username: ""},
				...this.state.warehouseUsers,
			],
			onEdit: true
		})
	}

	saveNewUser = async (idx) => {
		let { warehouseUsers } = this.state;
		const uid = this.state.newUser[0].id || false;
		if(uid) {
			const companyUserIdx = this.props.companyUsers.findIndex(cUser => cUser.id == uid);
			await this.props.addUserToWarehouse(companyUserIdx)
			if(!_.isEmpty(this.props.currentWarehouseUsers)) {
				warehouseUsers = _.sortBy(this.props.currentWarehouseUsers, user => parseInt(user.id, 10));
			} else {
				warehouseUsers.splice(idx, 1);
			}
			let users = warehouseUsers;
			let allUsers = this.props.companyUsers.filter(cUser => !users.some(user => cUser.id == user.id || cUser.active == "0")).map((cUser) => ({id: cUser.id, username: cUser.username}));
			this.setState({
				warehouseUsers: users,
				onEdit: false,
				newUser: [],
				companyUsers: allUsers
			})
		}
	}

	removeNewUserRow = (idx) => {
		let { warehouseUsers } = this.state;
		warehouseUsers.splice(idx, 1);
		this.setState({
			newUser: [],
			warehouseUsers: warehouseUsers,
			onEdit: false,
		})
	}

	removeUser = async (idx) => {
		const { warehouseUsers } = this.state;
		const uid = warehouseUsers[idx].id || false;
		this.setState({alert: null});
		if(uid) {
			if(warehouseUsers.length == 1) {
				this.props.setNotification("A warehouse must have at least one user assigned to it.", { variant: "error" });
				return;
			}
			const companyUserIdx = this.props.companyUsers.findIndex(cUser => cUser.id == uid);
			await this.props.removeUserFromWarehouse(companyUserIdx);
			let users = !_.isEmpty(this.props.currentWarehouseUsers) ? _.sortBy(this.props.currentWarehouseUsers, user => parseInt(user.id, 10)) : warehouseUsers;
			let allUsers = this.props.companyUsers.filter(cUser => !users.some(user => cUser.id == user.id || cUser.active == "0")).map((cUser) => ({id: cUser.id, username: cUser.username}));
			this.setState({
				warehouseUsers: users,
				onEdit: false,
				newUser: [],
				companyUsers: allUsers,
			})
		}
	}

	enableUser = async (idx) => {
		const { warehouseUsers } = this.state;
		if (_.isEmpty(warehouseUsers[idx]) || warehouseUsers[idx].active == 1) {
			return;
		}
		const companyUserIdx = this.props.companyUsers.findIndex(cUser => cUser.id == warehouseUsers[idx].id);
		await this.props.enableUser(companyUserIdx)
		warehouseUsers[idx].active = this.props.companyUsers[companyUserIdx].active;
		this.setState({ warehouseUsers });
	}

	disableUser = async (idx) => {
		const { warehouseUsers } = this.state;
		if (_.isEmpty(warehouseUsers[idx]) || warehouseUsers[idx].active != 1) {
			return;
		}
		const companyUserIdx = this.props.companyUsers.findIndex(cUser => cUser.id == warehouseUsers[idx].id);
		await this.props.disableUser(companyUserIdx);
		warehouseUsers[idx].active = this.props.companyUsers[companyUserIdx].active;
		this.setState({ warehouseUsers });
	}

	grantApiKey = async (idx) => {
		const { warehouseUsers } = this.state;
		if (_.isEmpty(warehouseUsers[idx]) || !_.isEmpty(warehouseUsers[idx].key)) {
			return;
		}
		const companyUserIdx = this.props.companyUsers.findIndex(cUser => cUser.id == warehouseUsers[idx].id);
		await this.props.grantApiKey(companyUserIdx)
		warehouseUsers[idx].key = this.props.companyUsers[companyUserIdx].key;
		this.setState({ warehouseUsers });
	}

	disableApiKey = async (idx) => {
		const { warehouseUsers } = this.state;
		this.setState({ alert: null });
		if (_.isEmpty(warehouseUsers[idx]) || _.isEmpty(warehouseUsers[idx].key)) {
			return;
		}
		const companyUserIdx = this.props.companyUsers.findIndex(cUser => cUser.id == warehouseUsers[idx].id);
		await this.props.disableApiKey(companyUserIdx)
		warehouseUsers[idx].key = this.props.companyUsers[companyUserIdx].key;
		this.setState({ warehouseUsers });
	}

	switchUser = async (id) => {
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=user&m=takeAccount&d=${id}/true`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user)) {
				window.location.href = basePath + "/admin";
			} else {
				this.props.setNotification("There was an error assuming the user!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error assuming the user!", {
				variant: "error"
			});
		}
	}

	searchTypeNameChange = (e, val, idx) => {
		let { warehouseUsers, newUser } = this.state;
		let user = this.state.companyUsers.find((user) => user.username == val) || false;
		if(user) {
			newUser = [user];
			warehouseUsers[idx].username = val;
		} else {
			newUser = [];
		}

		this.setState({
			newUser: newUser,
			warehouseUsers: warehouseUsers
		});
	};

	handleAlert = (idx, action) => {
		let title = "";
		let body = "";
		if(action == "removeUser") {
			title = "Are you sure you want to remove this user from selected warehouse?";
		} else if(action == "disableApiKey") {
			title = "Are you sure you want to disable this user's API Key?";
			body = "Their existing API key will be destroyed and cannot be recovered!";
		} else {
			return;
		}

		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title={title}
					onConfirm={() => action == "removeUser" ? this.removeUser(idx) : this.disableApiKey(idx)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Confirm"
					cancelBtnText="Cancel"
					showCancel
				>
					{body}
				</SweetAlert>
			)
		});
	}

	render() {
		const { classes } = this.props;
		return(
			<TableContainer component={Paper}>
				{this.state.alert}
				{this.state.show ? ( 
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									{!this.state.onEdit && !_.isEmpty(this.state.companyUsers) && (
										<Button color="success" size="sm" justIcon round onClick={() => this.addNewUserRow()}>
											<Add/>
										</Button>
									)}
								</TableCell>
								<TableCell>ID</TableCell>
								<TableCell>Username</TableCell>
								<TableCell>Role</TableCell>
								<TableCell>Permission Group</TableCell>
								<TableCell>Warehouses</TableCell>
								<TableCell>Rate Display</TableCell>
								<TableCell>API Key</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.warehouseUsers.map((user, key) => {
								return(
									<UserRow 
										key={key}
										idx={key}
										user={user}
										newUser={this.state.newUser}
										companyUsers={this.state.companyUsers}
										onChangeType={(e, val) => this.searchTypeNameChange(e, val, key)}
										removeNewUserRow={() => this.removeNewUserRow(key)}
										saveNewUser={() => this.saveNewUser(key)}
										handleAlert={(action) => this.handleAlert(key, action)}
										disableUser={() => this.disableUser(key)}
										enableUser={() => this.enableUser(key)}
										grantApiKey={() => this.grantApiKey(key)}
										switchUser={(id) => this.switchUser(id)}
									/>
								)
							})}
						</TableBody>
					</Table>
				) : (
					<Spinner loading={this.state.loading} />
				)}
			</TableContainer>
		)
	}
}

class UserRow extends Component {
	state = {
		open: false,
	}

	setOpen(open) {
		this.setState({open: open})
	}

	render() {
		const { user } = this.props;
		const { open } = this.state;
		const code = !_.isEmpty(user.id) ? Object.values(user.codes)[0] : '';
		const codes = !_.isEmpty(user.id) ? Object.values(user.codes).map((code, index) => <li key={index}>{code}</li>) : '';
		const link = !_.isEmpty(user.id) ? `${basePath}/admin/company-${user.company_name_id}/user/edit/${user.id}` : '';
		return(
			<>
				{!_.isEmpty(user.id) ? (
					<TableRow style={{ verticalAlign:'baseline' }}>
						<TableCell >
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={() => this.setOpen(!open)}
							>
								{open ? <KeyboardArrowUpIcon /> : <MoreVertIcon />}
							</IconButton>
						</TableCell>
						<TableCell>{user.id}</TableCell>
						<TableCell>{user.username}</TableCell>
						<TableCell>{user.role}</TableCell>
						<TableCell>{user.permission_group}</TableCell>
						<TableCell >
							{!open && (
								<div>{code} {codes.length > 1 ? '...' : ''}</div> 
							)}
							<Collapse in={open} timeout="auto" unmountOnExit>
								<ul style={{ padding: '16px' }}>{codes}</ul>
							</Collapse>
						</TableCell>
						<TableCell>{user.rate_display}</TableCell>
						<TableCell>{user.key}</TableCell>
						<TableCell>
							{user.active == '1' && (
								<Button size="sm" color="success" onClick={() => this.props.disableUser()}>
									Active
								</Button>
							)}
							{user.active == '0' && (
								<Button size="sm" color="black" onClick={() => this.props.enableUser()}>
									Disabled
								</Button>
							)}
						</TableCell>
						<TableCell>
							{!open && (
								<Button size="sm" color="warning" onClick={() => this.props.switchUser(user.id)}>
									Switch User
								</Button>
							)}
							<Collapse in={open} timeout="auto" unmountOnExit>
								<ButtonGroup
									orientation="vertical"
								>
									<Button size="sm" color="warning" onClick={() => this.props.switchUser(user.id)}>
										Switch User
									</Button>
									<Button size="sm" color="info" component={NavLink} to={link}>
										View/Edit
									</Button>
									{_.isEmpty(user.key) ? (
										<Button size="sm" color="white" onClick={() => this.props.grantApiKey()}>
											Grant API
										</Button>
									):(
										<Button size="sm" color="black" onClick={() => this.props.handleAlert("disableApiKey")}>
											Disable API
										</Button>
									)}
									<Button size="sm" color="danger" 
										onClick={() => {
											this.props.handleAlert("removeUser")
											this.setState({open: false})
										}}
									>
										Remove
									</Button>
								</ButtonGroup>
							</Collapse>
						</TableCell>
					</TableRow>
				) : (
					<TableRow style={{ verticalAlign:'baseline' }}>
						<TableCell colSpan={2}></TableCell>
						<TableCell colSpan={2}>
							<Autocomplete
								id={"new_user_" + this.props.idx}
								style={{ width: "100%" }}
								options={this.props.companyUsers}
								filterSelectedOptions={true}
								getOptionLabel={(option) => option.username}
								isOptionEqualToValue={(option, selected) => option.username === selected.value}
								autoComplete
								disabled={_.isEmpty(this.props.companyUsers)}
								includeInputInList
								onInputChange={this.props.onChangeType}
								renderInput={(params) => (
									<TextField {...params} label="Company Users" variant="standard" />
								)}
							/>
						</TableCell>
						<TableCell colSpan={5}></TableCell>
						<TableCell>
							
								<ButtonGroup
									orientation="vertical"
								>
									{ !_.isEmpty(this.props.newUser) &&(
										<Button size="sm" color="success" onClick={() => this.props.saveNewUser()}>
											Save
										</Button>
									)}
									<Button size="sm" color="warning" onClick={() => this.props.removeNewUserRow()}>
										cancel
									</Button>
								</ButtonGroup>
							
						</TableCell>
					</TableRow>
				)}
			</>
		)
	}
}

export default withStyles(style)(withSnackbar(WarehouseUsers));