import React from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import moment from "moment";
import { apiUrl } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

class ScheduledReportHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
	}
	async componentDidMount() {
        this.setState({ mounted: true });
        if(!_.isEmpty(this.props.match.params.id)) {
			const id = this.props.match.params.id
            try {
                const response = await axios.get(
                    "/index.php?p=api&r=json&c=reporting&m=scheduledReportHistory&d=" + id
                );
                const data = response.data;
                if (typeof data !== "string" && data.body && data.user) {
                    this.props.handleMessages(response);
                    this.setState({
                        show: true,
                        loading: false,
                        body: data.body,
                        user: data.user
                    });
                } else {
                    this.setState({ loading: false });
                    this.props.setNotification(
                        "There was an error while retrieving the scheduled report history!",
                        {
                            variant: "error"
                        }
                    );
                }
            } catch (error) {
                console.error(error);
                this.setState({ loading: false });
                this.props.setNotification(
                    "There was an unexpected response from the server while retrieving the scheduled report history!",
                    {
                        variant: "error"
                    }
                );
            }
        }
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
    }
    downloadReport(reportName) {
		window.open(apiUrl + '/index.php?p=api&r=json&c=reporting&m=downloadReport&d=' + reportName);
    }
	getHeaders() {
		return [
			"ID",
			"Run Date",
			"File",
			"Type"
		];
	}
	getColumns(data) {
		return data.map((prop, key) => {
			return [
				prop.id,
                moment.unix(prop.run_timestamp).format("L LTS"),
                prop.result_file ? (
                    <Button
                        color="info"
                        size="sm"
                        onClick={() => this.downloadReport(prop.result_file)}
                    >
                    {prop.result_file}
                    </Button>
                ) : (
                    prop.result_file
                ),
				_.startCase(prop.type)
			];
		});
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(
													this.state.body
												)}
												customCellClasses={[
													classes.right
												]}
												customClassesForCells={[6]}
												customHeadCellClasses={[
													classes.right
												]}
												customHeadClassesForCells={[6]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{"No history to display"}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve scheduled report history from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(ScheduledReportHistory));
