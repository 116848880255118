import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { fetchBill } from '../actions/CustomerPortal.jsx';

import CarrierBill from "../components/CarrierBill.jsx";

/**
 * Connected container for rendering a carrier bill by ID
 * This calls the endpoint to load carrier bill data and 
 * uses it to render the CarrierBill component.
 *
 * Available props:
 * id - int|string - the invoice id used for loading bill data
 * canUpdate - bool - toggles the components used for updating carrier bills
 */
class CarrierBillContainer extends Component {

	state = {
		initialized: false,
	};

	async componentDidMount() {
		await this.props.fetchBill(this.props.id);
		this.setState({initialized: true});
	}

	render() {

		return (
			<CarrierBill
				loading={this.props.loading || !this.state.initialized}
				canViewFinancialDetails={true}
				canUpdate={!!this.props.canUpdate}
				bill={this.props.bill}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.CustomerPortal.fetchBillLoading,
		bill: state.CustomerPortal.viewingBill,
		canViewFinancialDetails: true, //@TODO need to check permission here
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchBill,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierBillContainer);
