import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import axios from "~/variables/axios.jsx";
import { Redirect } from "react-router-dom";
import qs from "qs";
import { basePath, baseUrl, assetPath } from "~/variables/server.jsx";
import { handleMessages, setNotification } from '../../redux/actions/notifications.jsx';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardFooter from "~/components/Card/CardFooter.jsx";
import { InputAdornment, IconButton, Typography, Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "~/assets/jss/empire-tms/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardAnimation: "cardHidden",
			username: '',
			password: '',
			showPassword: false,
			authenticated: false,
			passwordReset: false,
			resetBy: 'email',
			resetByValue: '',
		};
	}
	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function() {
				this.setState({ cardAnimation: "" });
			}.bind(this),
			700
		);
	}
	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}
	submit = async () => {
		const { username, password } = this.state;
		if(!username.length || !password.length) {
			this.props.setNotification("Please provide both username and password", { variant: "error" });
			return;
		}
		try {
			const response = await axios.post("/index.php?p=api&r=json&c=user&m=login", qs.stringify({ username, password }));
			this.props.handleMessages(response)
			if(response.data && response.data.body) {
				this.setState({ authenticated: true });
			} else {
				this.props.setNotification("Error logging in", { variant: "error" });
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("Exception caught while logging in", { variant: "error" });
		}
	}
	toggle = name => () => {
		if(name === 'resetBy') {
			this.state.resetBy === 'email' ? this.setState({ [name]: 'username' }) : this.setState({ [name]: 'email' });
		} else {
			this.setState({ [name]: !this.state[name] })
		}
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	}
	handleKeyPress = (event) => {
		if(event.key === 'Enter') this.submit();
	}
	sendEmail = async () => {
		const { resetBy, resetByValue } = this.state;
		if(!['email','username'].includes(resetBy)) {
			this.props.setNotification("A password reset email requires an email address or username", { variant: "error" });
			return;
		}
		if(!resetByValue.length) {
			this.props.setNotification(`Please provide ${resetBy}`, { variant: "error" });
			return;
		}
		try {
			const response = await axios.post(
				"/index.php?p=api&r=json&c=user&m=sendPasswordReset",
				qs.stringify({ [resetBy]: resetByValue })
			);
			this.props.handleMessages(response)
			if(!response.data || !response.data.body) {
				this.props.setNotification("Error sending password reset email", { variant: "error" });
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("Exception caught while sending password reset email", { variant: "error" });
		}
	}
	render() {
		const { authenticated, cardAnimation, showPassword, passwordReset, resetBy } = this.state;
		const { classes } = this.props;
		return (
			<>
				{authenticated ? (
					<Redirect to={basePath + "/admin/dashboard"} />
				) : (
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={6} md={4}>
								<Card login className={classes[cardAnimation]}>
									<CardHeader
										className={`${classes.cardHeader} ${classes.textCenter}`}
										color="tfmOrange"
									>
										<img className={classes.banner} src={baseUrl + assetPath + "/img/empire/logo-dark.png"} alt="TMS" />
									</CardHeader>
										<CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
											{!passwordReset && (
												<>
													<CustomInput
														labelText="Username"
														id="username"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															name: "username",
															onChange: this.handleInput('username')
														}}
													/>
													<CustomInput
														labelText="Password"
														id="password"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															name: "password",
															type: showPassword ? "text" : "password",
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		onClick={this.toggle('showPassword')}
																		edge="end"
																	>
																	{showPassword ? <VisibilityOff /> : <Visibility />}
																	</IconButton>
																</InputAdornment>
															),
															onChange: this.handleInput('password'),
															onKeyPress: this.handleKeyPress
														}}
													/>
												</>
											)}
											{passwordReset && (
												<>
													<Grid style={{ marginTop: '10px' }}>
														<Typography variant="caption">
															Please enter the {resetBy === 'email' ? 'email address' : 'username'} associated with your account. A password reset link will be sent to you. If you don't receive an email, please contact us at customerservice@targetfmi.com.
														</Typography>
													</Grid>
													<CustomInput
														labelText={resetBy === 'email' ? 'Email' : 'Username'}
														id="resetByValue"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															name: "resetByValue",
															onChange: this.handleInput('resetByValue')
														}}
													/>
												</>
											)}
										</CardBody>
									<CardFooter>
										<GridContainer>
											{passwordReset && (
												<>
													<GridItem xs={12}>
														<Button
															fullWidth
															color="tfmBlue"
															variant="outlined"
															onClick={this.sendEmail}
														>
															Send Email
														</Button>
													</GridItem>
													<GridItem xs={12}>
														<Button
															fullWidth
															color="tfmBlue"
															variant="outlined"
															onClick={this.toggle('resetBy')}
														>
															Reset By {resetBy === 'email' ? 'Username' : 'Email Address'}
														</Button>
													</GridItem>
												</>
											)}
											<GridItem xs={12}>
												<Button
													fullWidth
													color="tfmBlue"
													variant="outlined"
													onClick={passwordReset ? this.toggle('passwordReset') : this.submit}
												>
													{`${passwordReset ? 'Back to Log In' : 'Log In'}`}
												</Button>
											</GridItem>
											{!passwordReset && (
												<GridItem xs={12}>
													<Button
														fullWidth
														color="tfmBlue"
														variant="outlined"
														onClick={this.toggle('passwordReset')}
													>
														Forgot Password
													</Button>
												</GridItem>
											)}
										</GridContainer>
									</CardFooter>
								</Card>
							</GridItem>
						</GridContainer>
					</div>
				)}
			</>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));