import React from "react";
import { withSnackbar } from "notistack";
import "formdata-polyfill";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CarrierInfo from "~/components/TMS/CarrierInfo.jsx";


// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewCarrier extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			alert: null,
			redirect: null,
			show: false,
			loading: true,
			user: null,
			body: null,
			scac: "",
			carrier: "",
			phone: "",
			email: "",
			volume: "",
			density: "",
			server: "",
			ftp_user: "",
			ftp_pass: "",
			api_user: "",
			api_pass: "",
			logo_file: "",
			api_tracking: false,
			api_pickup: false,
			email_pickup: false,
			pickup_setting: "disabled",
			auto_pro: false,
			auto_pro_type: "",
			use_parcel: false,
			actual_scac: "",
			scac_map_pickups: false,
			scac_map_tracking: false,
			scac_map_p44: false,
			scac_map_auto_pro: false,
			scac_map_carrier_connect: false,
			p44_api_pickup: false,
			p44_api_tracking: false,
			p44_exclude_pickup: false,
			p44_parent_scac: "",
			account_require_username: false,
			account_require_password: false,
			account_require_account_number: false,
			account_require_edi_reference_number: false,
			account_require_license_key: false,
			notes: []
		};
	}

	render() {
		return (
			<CarrierInfo data={this.state} newScac={true}/>
		);
	}
}

export default withStyles(style)(withSnackbar(NewCarrier));