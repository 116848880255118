import React from "react";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { hasPermission, parcelWeightLimit, getUserCompanySetting, getUserSetting } from "../../redux/selectors/Admin.jsx";
import { bindActionCreators } from 'redux';
import { searchObject } from "../../helpers.jsx";
import ManagePrinters from "~/views/TMS/ManagePrinters.jsx";
import PrintZplModal from "~/views/PrintZplModal/containers/PrintZplModal.jsx";
import { setJSPM, setAvailablePrinters, jspmStatus, setUpPrinters, autoPrint, printDocument } from "~/views/PrintZplModal/actions/PrintZplModal.jsx";


import "formdata-polyfill";

import _ from "lodash";
import qs from "qs";
import moment from "moment";
import axios from "~/variables/axios.jsx";
import { basePath, apiUrl } from "~/variables/server.jsx";

import { formatHazUoM, validateItem } from "../../helpers.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Grow from '@material-ui/core/Grow';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

// material ui icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Phone from "@material-ui/icons/Phone";
import Search from "@material-ui/icons/Search";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import AddCircle from "@material-ui/icons/AddCircle";
import Home from "@material-ui/icons/Home";
import ReportProblem from "@material-ui/icons/ReportProblem";
import Warning from "@material-ui/icons/Warning";
import Info from "@material-ui/icons/Info";
import FastRewind from "@material-ui/icons/FastRewind";
import FastForward from "@material-ui/icons/FastForward";
import LocationOn from "@material-ui/icons/LocationOn";
import Person from "@material-ui/icons/Person";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardText from "~/components/Card/CardText.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import NavPills from "~/components/NavPills/NavPills.jsx";
import SnackbarContent from "~/components/Snackbar/SnackbarContent.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import ReferenceList from "~/components/TMS/ReferenceList.jsx";
import ProductSearchModal from "../BillOfLading/components/ProductSearchModal.jsx";
import { openAlertModal } from "../Alert/actions/Alert.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";

import ParcelPackageButton from "~/components/TMS/ParcelPackageButton.jsx";

import { convertToCountryCode } from "../../helpers.jsx";

// style for this view
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

import HazardousOptionsModal from "../../components/HazardousOptionsModal/HazardousOptionsModal.jsx";
import HazmatInfo from "../../components/HazmatInfo/HazmatInfo.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUps, faFedex, faUsps } from "@fortawesome/free-brands-svg-icons";
import { faHome, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ContactSearch from "../../components/TMS/ContactSearch.jsx";
import { CodeSharp } from "@material-ui/icons";

const FedExServices = [
	{name: 'FedEx Express Saver', value: 'FEDEX_EXPRESS_SAVER'},
	{name: 'FedEx 2 Day', value: 'FEDEX_2_DAY'},
	{name: 'FedEx 2 Day AM', value: 'FEDEX_2_DAY_AM'},
	{name: 'Standard Overnight' , value: 'STANDARD_OVERNIGHT'},
	{name: 'Priority Overnight', value: 'PRIORITY_OVERNIGHT'},
	{name: 'First Overnight', value: 'FIRST_OVERNIGHT'},
];

const UpsServices = [
	{name: 'UPS Ground', value: '03'},
	{name: 'UPS Next Day Air', value: '01'},
	{name: 'UPS 2nd Day Air', value: '02'},
	{name: 'UPS 3 Day Select', value: '12'},
	{name: 'UPS Next Day Air Saver', value: '13'},
	{name: 'UPS Next Day Air Early', value: '14'},
	{name: 'UPS 2nd Day Air AM', value: '59'},
];

const UspsServices = [
	{name: 'USPS Priority (1-3 Days)', value: 'usps_priority'},
	{name: 'USPS Priority Mail Express', value: 'usps_express'},
	{name: 'USPS First Class', value: 'usps_first_class'},
];

const SlideTransition = React.forwardRef((props, ref) => {
	return <Slide direction="down" ref={ref} {...props} />;
});

const FadeTransition = React.forwardRef((props, ref) => {
	return <Fade in {...props} />;
});

const ExtraRequiredFieldsMap = [
	{settingName: "require_so", inputName: "so_num"},
	{settingName: "require_po", inputName: "po_num"},
	{settingName: "require_ref", inputName: "ref_num"},
	{settingName: "require_cons_contact", inputName: "consignee_contact_name"}
];

class ParcelQuote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			showManagePrinters: false,
			loading: true,
			alert: null,
			redirect: null,
			create: false,
			active: 0,
			rated: false,
			data: [],
			company: "", // admin only
			warehouse: "",
			selected_warehouse: "",
			shipper_zip: "",
			shipper_state: "",
			shipper_country: "US",
			consignee_zip: "",
			consignee_state: "",
			consignee_country: "US",
			fedex_address_validation: false,
			residentialCheckModal: false,
			resi_street_line_1: "",
			resi_street_line_2: "",
			resi_city: "",
			resi_zip: "",
			loadingResidentialCheck: false,
			dropoff: "REGULAR_PICKUP",
			packaging_type: "BOX",
			standard_units: true,
			regulation_set: "iata",
			allowedUnitsOfMeasure: [],
			dg_enabled: false,
			show_notice: true,
			show_resi_notice: true,
			show_courtesy: true,
			notice_message: "To accurately assess a parcel rate, the dimensions are required. Target Freight Management is not responsible for the additional charges incurred due to missing or inaccurate dimensions.",
			resi_notice_message: "",
			courtesy_message: "Your shipment was scheduled but the carrier did not return a courtesy rate.",
			hazCarriers: [], // carriers that support hazmat
			carriers: [],
			services: [],
			hazInfo: [],
			selectedKey: null,
			productSearchModal: false,
			products: [],
			productKey: '',
			packages: [
				{
					id: "",
					length: "",
					width: "",
					height: "",
					weight: "",
					declaredValue: "",
					quantity: 1,
					hazardous: false,
					description: "",
					part_no: "",
					hazmat: {
						un: "",
						class: "",
						subHazardClass: "",
						properShippingName: "",
						packingGroup: "",
						packingInstructionCode: "",
						technicalName: "",
						packagingType: "",
						innerContainerType: "",
						regulatoryQuantity: "",
						uom: "",
						quantity: "",
						transportationMode: "",
						accessibility: "",
						requiredLabel: "",
						emergencyPhone: "",
						intlEmergencyPhone: "",
						emergencyResponseRegistrant: "",
						offeror: "",
						signatoryContactName: "",
						signatoryTitle: "",
						signatoryPlace: ""
					}
				}
			],
			hazmat: false,
			showHaz: false, // Shows haz options if dg_enabled and at least one carrier supported
			hazCarrier: "",
			hazService: "",
			cod: false,
			signature: false,
			signature_type: "INDIRECT",
			residential: false,
			saturdayDelivery: false,
			carbonNeutral: false,
			ship_date: "",
			// create shipment
			shipper_name: "",
			shipper_address1: "",
			shipper_address2: "",
			shipper_shipment_zip: "",
			selected_warehouse_zip: "",
			shipper_shipment_city: "",
			shipper_shipment_state: "",
			shipper_shipment_country: "US",
			shipper_contact_name: "",
			shipper_contact_phone: "",
			shipper_contact_email: "",
			shipper_save: false,
			shipper_search_by: "SEARCH_ANY",
			shipper_search_for: "",
			consignee_name: "",
			consignee_address1: "",
			consignee_address2: "",
			consignee_shipment_zip: "",
			consignee_shipment_city: "",
			consignee_shipment_state: "",
			consignee_shipment_country: "US",
			consignee_contact_name: "",
			consignee_contact_phone: "",
			consignee_contact_email: "",
			consignee_save: false,
			consignee_search_by: "SEARCH_ANY",
			consignee_search_for: "",
			so_num: "",
			po_num: "",
			ref_num: "",
			dept_num: "",
			billing_type: "account",
			billing_account: "",
			billing_zip: "",
			cod_amount: "",
			cod_collection_type: "ANY",
			label_type: "STOCK",
			label_size: "4X6",
			default_label_type: "STOCK",
			default_label_size_fedex: "",
			default_label_size_ups: "",
			default_label_save: false,
			is_inbound: false,
			asn_email: "",
			include_costco_label: false,
			// rates
			rates: ["warehouse", "dropoff", "packaging_type", "regulation_set", "shipper_zip", "shipper_state", "shipper_country", "consignee_zip", "consignee_state", "consignee_country", "cod", "signature", "signature_type", "residential", "saturday_delivery", "ship_date", "ground_hazmat"], // TTMS-1965 added ground_hazmat
			// required fields for scheduling a shipment
			required: [
				"shipper_name",
				"shipper_address1",
				"shipper_shipment_zip",
				"shipper_shipment_city",
				"shipper_shipment_state",
				"shipper_shipment_country",
				"shipper_contact_phone",
				"shipper_contact_name",
				"consignee_name",
				"consignee_address1",
				"consignee_shipment_zip",
				"consignee_shipment_city",
				"consignee_shipment_state",
				"consignee_shipment_country",
				"consignee_contact_phone",
				"billing_type",
				"label_type",
				"label_size"
			],
			showParcelRates: false,
			loadingParcelRates: false,
			parcelRates: [],
			rateBreakdownModal: false,
			parcel_rate_key: null,
			fedex_shipping_enabled: false,
			ups_shipping_enabled: false,
			usps_shipping_enabled: false,
			carrier: "",
			account: "",
			service: "",
			serviceInfo: "",
			service_code: "",
			quoted_amount: "",
			billingWeight: "",
			ratingMethod: "",
			rateZone: "",
			rateZoneInfo: "",
			surcharges: [],
			surchargesInfo: [],
			estOrGuar: "",
			estOrGuarInfo: "",
			deliveryTime: "",
			deliveryTimeInfo: "",
			contactSearchModal: false,
			contacts: [],
			contactSearchType: "",
			schedulingShipment: false,
			scheduled: false,
			general: null,
			shipmentInfo: {
				shipmentId: "",
				trackingId: "",
				totalCharge: "",
				billingType: "",
				acctNo: "",
				labelType: "",
				carrier: "",
				serviceType: "",
				shipDate: ""
			},
			zpl: "",
			docZpl: "",
			printLabelModal: false,
			printDocModal: false,
			printer: "",
			default_printer_save: false,
			pickupModal: false,
			pickup_conf_num: "",
			expeditePickupDateTime: "",
			expediteDockCloseTime: "",
			pickup_phone: "",
			pickup_contact_name: "",
			pickup_area: "",
			pickup_description: "",
			special_instructions: "",
			email_notification: "",
			schedulingPickup: false,
			imageFiles: [],
			customs: {
				customs_value: 0.00,
				commodity_units: 0.00,
				unit_value: 0.00,
				origin_country: "",
				part_number: "",
				description: "",
				import_uom: "",
				export_reason: "",
			},
			references: [],
			ground_hazmat: false,
			enable_product_part_no: false,
			dont_apply_addr_book_defaults: false,
		};
		this.getWarehouses = this.getWarehouses.bind(this);
		this.addPackage = this.addPackage.bind(this);
		this.getPackages = this.getPackages.bind(this);
		this.getTotalWeight = this.getTotalWeight.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleZplChecked = this.handleZplChecked.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.toggleHazardous = this.toggleHazardous.bind(this);
		this.handlePackage = this.handlePackage.bind(this);
		this.handleHazardous = this.handleHazardous.bind(this);
		this.shipmentIsHazardous = this.shipmentIsHazardous.bind(this);
		this.getParcelRates = this.getParcelRates.bind(this);
		this.handleParcelRate = this.handleParcelRate.bind(this);
		this.createShipment = this.createShipment.bind(this);
		this.scheduleShipment = this.scheduleShipment.bind(this);
		this.handleZip = this.handleZip.bind(this);
		this.handleContactSearch = this.handleContactSearch.bind(this);
		this.handleContactSelect = this.handleContactSelect.bind(this);
		this.getContacts = this.getContacts.bind(this);
		this.handleAddress = this.handleAddress.bind(this);
		this.clearAddress = this.clearAddress.bind(this);
		this.getShipmentData = this.getShipmentData.bind(this);
		this.getSpecialServices = this.getSpecialServices.bind(this);
		this.getLabelSizes = this.getLabelSizes.bind(this);
		this.getClasses = this.getClasses.bind(this);
		this.getSubClasses = this.getSubClasses.bind(this);
		this.printerCheck = this.printerCheck.bind(this);
		this.newQuote = this.newQuote.bind(this);
		this.handlePickup = this.handlePickup.bind(this);
		this.schedulePickup = this.schedulePickup.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.deleteImage = this.deleteImage.bind(this);
		this.getImages = this.getImages.bind(this);
		this.handleReference = this.handleReference.bind(this);
		this.loadServices = this.loadServices.bind(this);
		this.loadCarriers = this.loadCarriers.bind(this);
	}

	async componentDidMount() {
		const { required } = this.state;
		let shipmentId = "";
		let alert = null;

		if (!_.isEmpty(this.props.match.params.shipmentId)) {
			shipmentId = this.props.match.params.shipmentId;
		}

		let url = "/index.php?p=api&r=json&c=parcel&m=parcelQuotePage";

		if (!_.isEmpty(shipmentId)) {
			url = "/index.php?p=api&r=json&c=parcel&m=parcelQuotePage&shipmentId=" + shipmentId;

			alert = (
				<SweetAlert
					info
					style={{
						display: "block",
						color: "black"
					}}
					title="Shipment is loading."
					onConfirm={() => {}}
					showConfirm={false}
				>
					Please wait...
					<br />
					<div style={{ textAlign: "center", margin: 5 }}>
						<CircularProgress />
					</div>
				</SweetAlert>
			);

			this.setState({
				shipmentInfo: {
					shipmentId: shipmentId
				}
			});
		}

		let { JSPM } = this.props;
		if(JSPM == null) {
			JSPM = window.JSPM;
			this.props.setJSPM(JSPM);
		}
		this.setState({ mounted: true});
		try {
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user) && this.state.mounted) {
				this.props.handleMessages(response);

				let warehouse = { code: "" };
				let enable_product_part_no = 0;
				if (data.user.user_settings.dont_apply_addr_book_defaults == "1") {
					this.setState({ dont_apply_addr_book_defaults: true });
				}

				if (!_.isEmpty(data.body.warehouses)) {
					if (!_.isEmpty(data.body.warehouses.filter(warehouse => warehouse.is_default == "1"))) {
						warehouse = data.body.warehouses.filter(warehouse => warehouse.is_default == "1")[0];
						enable_product_part_no = warehouse.enable_product_part_no
					} else {
						warehouse = data.body.warehouses[0];
						enable_product_part_no = warehouse.enable_product_part_no
					}

					this.loadWarehouseDefaults(warehouse.code);
					this.loadLocationDefaults(false, warehouse.code);

					ExtraRequiredFieldsMap.map(field => {
						if(data.body.extra_required_fields.hasOwnProperty(field.settingName) && data.body.extra_required_fields[field.settingName] == 1) {
							required.push(field.inputName);
						}
					});

					if (enable_product_part_no == "1") {
						this.setState({enable_product_part_no: true})
					}

					// this.setState({required}); Seems like this should be necessary, but isn't - why?

					if (data.user.user_company_settings.enable_parcel_dg == "1") {
						this.setState({ dg_enabled: true });
					}

					this.loadCarriers(warehouse.code);
					await this.getDefaultProduct(warehouse.code);

					if (data.user.user_company_settings.enable_fedex_address_validation == "1") {
						this.setState({ fedex_address_validation: true });
						this.setState({ resi_notice_message: "Address information is provided by FedEx and is not guaranteed for accuracy." });
					} else {
						this.setState({ resi_notice_message: "Address information is provided by the USPS and is not guaranteed for accuracy." });
					}
				}

				if (data.body.hasOwnProperty("packageInfo")) {
					const packageInfo = data.body.packageInfo.packages;
					const packages = [];

					if (packageInfo.length > 0) {
						for (const package_info of packageInfo) {
							const id = package_info.id;
							const length = package_info.length;
							const width = package_info.width;
							const height = package_info.height;
							const weight = package_info.weight;

							const pack = {
								id: id,
								length: length,
								width: width,
								height: height,
								weight: weight,
								quantity: 1,
								declaredValue: "",
								part_no: "",
								hazardous: false,
								hazmat: {
									un: "",
									class: "",
									subHazardClass: "",
									properShippingName: "",
									packingGroup: "",
									packingInstructionCode: "",
									technicalName: "",
									packagingType: "",
									innerContainerType: "",
									regulatoryQuantity: "",
									uom: "",
									quantity: "",
									transportationMode: "",
									accessibility: "",
									requiredLabel: "",
									emergencyPhone: "",
									intlEmergencyPhone: "",
									emergencyResponseRegistrant: "",
									offeror: "",
									signatoryContactName: "",
									signatoryTitle: "",
									signatoryPlace: ""
								}
							};

							packages.push(pack);
						}
					} else {
						const pack = {
							id: "",
							length: "",
							width: "",
							height: "",
							weight: "",
							declaredValue: "",
							part_no: "",
							quantity: 1,
							hazardous: false,
							hazmat: {
								un: "",
								class: "",
								subHazardClass: "",
								properShippingName: "",
								packingGroup: "",
								packingInstructionCode: "",
								technicalName: "",
								packagingType: "",
								innerContainerType: "",
								regulatoryQuantity: "",
								uom: "",
								quantity: "",
								transportationMode: "",
								accessibility: "",
								requiredLabel: "",
								emergencyPhone: "",
								intlEmergencyPhone: "",
								emergencyResponseRegistrant: "",
								offeror: "",
								signatoryContactName: "",
								signatoryTitle: "",
								signatoryPlace: ""
							}
						};

						packages.push(pack);
					}

					if (!_.isEmpty(data.body.packageInfo.so)) {
						var soNum = data.body.packageInfo.so;
					}

					this.setState({ packages: packages, so_num: soNum });
				}

				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user,
					warehouse: warehouse.code || "",
					alert: null
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false, alert: null });
			this.props.setNotification("There was an exception loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	async loadCarriers(warehouse) {
		try {
			const url = "/index.php?p=api&r=json&c=parcel&m=getCarriers&d=" + encodeURIComponent(warehouse);
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			const { body } = response.data;
			let hazCarriers = [];
			let carriers = [];
			let accounts = [];
			if(!_.isEmpty(body)) {
				for(let i = 0; i < body.length; i ++) {
					carriers.push(body[i].name);
					accounts.push({name: body[i].name, account: body[i].account});
					if(body[i].hazSupported == 1 && this.state.dg_enabled) {
						hazCarriers.push(body[i].name);
						this.setState({showHaz: true});
					}
				}
				this.setState({hazCarriers: hazCarriers, carriers: carriers, carrier: carriers[0], accounts: accounts, account: accounts[0]});
			}
		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error loading the carriers for this warehouse!", { variant: "error" });
		}
	}

	getCarriers(carriers) {
		const { classes } = this.props;
		return carriers.map((carrier, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={carrier}
					style={{paddingTop: 0, paddingBottom: 0}}

				>
					{carrier}
				</MenuItem>
			);
		});
	}

	loadServices(carrier) {
		let services = [];

		if(carrier == 'FEDEX') {
			services = [...FedExServices];

			if(this.state.residential) {
				services.unshift({name: 'FedEx Home Delivery', value: 'GROUND_HOME_DELIVERY'});
			} else {
				services.unshift({name: 'FedEx Ground', value: 'FEDEX_GROUND'});
			}

		} else if(carrier == 'UPS') {
			services = UpsServices;
		} else {
			services = UspsServices;
		}

		this.setState({services: services, service: services[0].value, hazService: services[0].value});
	}

	getServices() {
		const { classes } = this.props;

		const services = this.state.services;

		const access = this.state.packages[0].hazmat.accessibility;

		return services.map((service, key) => {
			if(access == "ACCESSIBLE" && service.value != "FEDEX_GROUND" && service.value != "PRIORITY_OVERNIGHT" && service.value != "FIRST_OVERNIGHT") {
				return false;
			}
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={service.value}
				>
					{service.name}
				</MenuItem>
			);
		});
	}

    handleReference(refList) {
        this.setState({references: refList.references});
    }

	async loadWarehouseDefaults(warehouseCode) {
		const required = [...this.state.required];
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=warehouse&m=getDefaults&d=${warehouseCode}`);
			if(typeof response.data !== 'string' && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				let customReferences = [];
				response.data.body.custom_references.forEach(reference => {
					customReferences.push({
						'type': reference.name,
						'value': reference.value || '',
						'required': reference.required,
						'cust_ref_entry_type': reference.cust_ref_entry_type,
						'cust_ref_entry_opts': reference.cust_ref_entry_opts,
					});
				});
				// Sort to put any required warehouse references at the top
				customReferences.sort(function(a,b){
					if(a.required == 1 && b.required == 0) {
						return -1;
					}
					if(a.required == 0 && b.required == 1) {
						return 1;
					}
					return 0;
				});
				ExtraRequiredFieldsMap.map(field => {
					if(response.data.body.require_refs.hasOwnProperty(field.settingName)) {
						let requireRefIndex = required.indexOf(field.inputName)
						if(response.data.body.require_refs[field.settingName] == 1) {
							if(requireRefIndex < 0) {
								required.push(field.inputName);
							}
						} else {
							if(requireRefIndex >= 0) {
								required.splice(requireRefIndex, 1)
							}
						}
					}
				});

				this.setState({
					references: customReferences,
					enable_product_part_no: response.data.body.enable_product_part_no == '1' ? true : false,
					required,
				});
			}
		} catch(error) {
			console.error(error);
            this.props.setNotification("There was an error loading warehouse defaults!", { variant: "error" });
		}
    }


	handleClose() {
		this.setState({ show_notice: false });
	}

	handleChange(event) {

		if(event.target.name in this.state.customs) {
			let customs = {...this.state.customs};
			customs[event.target.name] = event.target.value;
			this.setState({customs});
		} else {
			this.setState({ [event.target.name]: event.target.value });
			for (const input of this.state.rates) {
				if (event.target.name === input) {
					this.setState({
						parcelRates: [],
						carrier: "",
						service: "",
						service_code: ""
					});
				}
			}

			if (event.target.name == "warehouse") {
				this.loadWarehouseDefaults(event.target.value);
				this.loadLocationDefaults(false, event.target.value);
			}
			if (event.target.name == 'hazCarrier') {
				this.loadServices(event.target.value);
			}
			if (event.target.name.indexOf("_country") !== -1) {
				const type = event.target.name.replace("_country", "");
				const postalCode = this.state[`${type}_zip`];
				this.setState({
					[`${type}_city`]: '',
					[`${type}_state`]: '',
				}, () => this.handleZip(type, postalCode, event.target.value));
			}
			if(event.target.name == 'label_type') {
				if(event.target.value == 'ZPL') {
					let JSPM = this.props.JSPM;
					if(JSPM == null) {
						JSPM = window.JSPM;
						this.props.setJSPM(JSPM);
					}
					this.props.setAvailablePrinters(JSPM);
				}
			}
		}
	}

	handleZplChecked = (name, event) => {
		this.setState({ [name]: event.target.checked });
	}

	handleChecked = name => event => {
		this.setState({ [name]: event.target.checked });
		if(name == 'is_inbound') {
			let inbound = this.state.is_inbound;
			let billingType = this.state.billing_type;

			//need to update billing type unless it is 3rd party/collect.
			if(billingType == 'recipient' || billingType == 'shipper') {
				//switch billing information to keep the same account billed that is currently selected.
				if(billingType == 'recipient') {
					billingType = 'shipper';
				} else if(billingType == 'shipper') {
					billingType = 'recipient';
				}
				this.setState({
					is_inbound: !inbound,
					billing_type: billingType,
				});
			} else {
				//only update the inbound flag.
				this.setState({
					is_inbound: !inbound,
				});
			}
		} else if(name == 'include_costco_label') {
			let JSPM = this.props.JSPM;
			if(JSPM == null) {
				JSPM = window.JSPM;
				this.props.setJSPM(JSPM);
			}
			this.props.setAvailablePrinters(JSPM);
		} else {
			for (const input of this.state.rates) {
				if (name === input) {
					this.setState({
					parcelRates: [],
					carrier: "",
					service: "",
					service_code: ""
					});
				}
			}
		}
	};

	handleInput = name => event => {
		if(name == 'shipper_shipment_country' || name == 'consignee_shipment_country') {
			const convertedCountryCode = convertToCountryCode(event.target.value, 'short');
			if(convertedCountryCode !== event.target.value) {
				event.target.value = convertedCountryCode;
				this.props.setNotification("Updated country code to " + convertedCountryCode + ".", { variant: "warning" });
			}
		}
		if(name in this.state.customs) {
			let customs = {...this.state.customs};
			customs[name] = event.target.value;
			this.setState({
				customs
			});
		} else {
			this.setState({ [name]: event.target.value });
			for (const input of this.state.rates) {
				if (name === input) {
					this.setState({
						parcelRates: [],
						carrier: "",
						service: "",
						service_code: ""
					});
				}
			}
			if (name.indexOf("zip") !== -1) {
				const type = name.replace("_zip", "");
				const zip = event.target.value;
				// Update country to Canada if zip has a letter
				const regex = /[a-z]/i;
				const country = regex.test(event.target.value) ? 'CA' : this.state[`${type}_country`];
				this.setState({
					[`${type}_state`]: '',
					[`${type}_country`]: country,
				}, () => this.handleZip(type, zip, country));
			}
		}
	};

	resiEnterPressed(e) {
		const code = e.keyCode || e.which;
		if (code == 13) {
			this.checkForResi();
		}
	}

	async handleZip(name, zip, country = null) {
		zip = zip.replace(" ", "").toUpperCase();
		let isNum = false;
		isNum = !(Number.isNaN(zip) || Number.isNaN(parseInt(zip)));
		if (!_.isEmpty(zip) && ((isNum) && zip.length >= 5) || zip.length >= 6) {
			try {
				let url = "/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + encodeURIComponent(zip);
				url += country != null ? `/${convertToCountryCode(country, 'long')}` : ``;
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					const { body } = response.data;
					if (body[zip]) {
						if (!["USA","CAN","MEX"].includes(body[zip][0].country)) {
							this.setState({ loading: false });
							this.props.setNotification("International Shipment - Contact us for information regarding International Shipments.", { variant: "error" });
						}
						const state = {};
						let party = '';
						if(name.indexOf("_shipment") !== -1) {
							party = `${name.split('_')[0]}_shipment`;
							state[`${party}_city`] = body[zip][0].city;
						} else {
							party = name.split('_')[0];
						}
						if(party == 'consignee') state.resi_city = body[zip][0].city;
						state[`${party}_country`] = convertToCountryCode(body[zip][0].country, 'short');
						state[`${party}_state`] = body[zip][0].state;
						this.setState(state);
					} else {
						this.props.setNotification("The postal code could not be found. Chances are it is not valid.", { variant: "error" });
					}
				} else {
					this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
			}
		}
	}

	async loadLocationDefaults(serviceTab, warehouse) {

		const { dont_apply_addr_book_defaults, resi_street_line_1, resi_street_line_2, resi_city, shipper_state, shipper_country, shipper_zip, consignee_state, consignee_country, consignee_zip } = this.state;
		if (dont_apply_addr_book_defaults) {
			return;
		}

		try {
			this.setState({selected_warehouse: warehouse});
			const url = "/index.php?p=api&r=json&c=location&m=defaults&d=" + encodeURIComponent(warehouse);
			const response = this.props.hasAddressBookRead ? await axios.get(url) : null;
			if (response && typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (response && typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				const { body } = response.data;
				if (!_.isEmpty(body.shipper)) {
					if(body.shipper.country == "USA") {
						body.shipper.country = "US";
					} else if(body.shipper.country == "CAN") {
						body.shipper.country = "CA";
					} else if(body.shipper.country == "MEX") {
						body.shipper.country = "MX";
					}
				}
				if (!_.isEmpty(body.consignee)) {
					if(body.consignee.country == "USA") {
						body.consignee.country = "US";
					} else if(body.consignee.country == "CAN") {
						body.consignee.country = "CA";
					} else if(body.consignee.country == "MEX") {
						body.consignee.country = "MX";
					}
				}

				let consigneeAddress1 = '';
				let consigneeAddress2 = '';
				let consigneeShipmentCity = '';
				let consigneeShipmentState = '';
				let consigneeShipmentCountry = '';
				let consigneeShipmentZip = '';

				// Use default consignee address if it exists
				if (!_.isEmpty(body.consignee)) {

					consigneeAddress1 = body.consignee.address1;
					consigneeAddress2 = body.consignee.address2;
					consigneeShipmentCity = body.consignee.city;
					consigneeShipmentState = body.consignee.state;
					consigneeShipmentCountry = body.consignee.country;
					consigneeShipmentZip = body.consignee.zip;

				}

				// Overwrite default consignee address if most values from quote page exist (address lines 1 and 2 not required to exist)
				if(	!_.isEmpty(resi_city) &&
					!_.isEmpty(consignee_state) &&
					!_.isEmpty(consignee_country) &&
					!_.isEmpty(consignee_zip)) {

						consigneeAddress1 = resi_street_line_1;
						consigneeAddress2 = resi_street_line_2;
						consigneeShipmentCity = resi_city;
						consigneeShipmentState = consignee_state;
						consigneeShipmentCountry = consignee_country;
						consigneeShipmentZip = consignee_zip;

				}

				if (!serviceTab && !_.isEmpty(body.shipper)) {

					this.setState({
						shipper_zip: body.shipper.zip || "",
						shipper_state: body.shipper.state || "",
						shipper_country: body.shipper.country || "",
						consignee_address1: consigneeAddress1,
						consignee_address2: consigneeAddress2,
						consignee_shipment_city: consigneeShipmentCity,
						consignee_shipment_state: consigneeShipmentState,
						consignee_shipment_country: consigneeShipmentCountry,
						consignee_shipment_zip: consigneeShipmentZip,
						selected_warehouse_zip: body.shipper.zip || "",
					});

				} else {

					const state = {
						shipper_name: "",
						shipper_address1: "",
						shipper_address2: "",
						shipper_shipment_city: "",
						shipper_shipment_zip: "",
						shipper_shipment_state: "",
						shipper_shipment_country: "",
						shipper_contact_name: "",
						shipper_contact_phone: "",
						shipper_contact_email: "",
						consignee_name: "",
						consignee_address1: "",
						consignee_address2: "",
						consignee_shipment_city: "",
						consignee_shipment_zip: "",
						consignee_shipment_state: "",
						consignee_shipment_country: "",
						consignee_contact_name: "",
						consignee_contact_phone: "",
						consignee_contact_email: ""

					};

					if (!_.isEmpty(body.shipper) && shipper_zip == body.shipper.zip) {

						state.shipper_name = body.shipper.name || "";
						state.shipper_address1 = body.shipper.address1 || "";
						state.shipper_address2 = body.shipper.address2 || "";
						state.shipper_shipment_zip = body.shipper.zip || "";
						state.shipper_shipment_city = body.shipper.city || "";
						state.shipper_shipment_state = body.shipper.state || "";
						state.shipper_shipment_country = body.shipper.country || "";
						state.shipper_contact_name = body.shipper.contact_name || "";
						state.shipper_contact_phone = body.shipper.contact_phone || "";
						state.shipper_contact_email = body.shipper.contact_email || "";

					}

					if (!_.isEmpty(body.consignee) && consignee_zip == body.consignee.zip) {

						state.consignee_name = body.consignee.name || "";
						state.consignee_address1 = body.consignee.address1 || "";
						state.consignee_address2 = body.consignee.address2 || "";
						state.consignee_shipment_zip = body.consignee.zip || "";
						state.consignee_shipment_city = body.consignee.city || "";
						state.consignee_shipment_state = body.consignee.state || "";
						state.consignee_shipment_country = body.consignee.country || "";
						state.consignee_contact_name = body.consignee.contact_name || "";
						state.consignee_contact_phone = body.consignee.contact_phone || "";
						state.consignee_contact_email = body.consignee.contact_email || "";

					}

					// Overwrite default consignee address if most values from quote page exist (address line 1 and 2 not required to exist)
					if(	!_.isEmpty(resi_city) &&
						(!_.isEmpty(resi_street_line_1) || !_.isEmpty(resi_street_line_2)) &&
						!_.isEmpty(consignee_country) &&
						!_.isEmpty(consignee_zip)) {
							state.consignee_name = "";
							state.consignee_address1 = resi_street_line_1;
							state.consignee_address2 = resi_street_line_2;
							state.consignee_shipment_city = resi_city;

					}

					if(state.shipper_shipment_zip == "") {
						state.shipper_shipment_zip = shipper_zip;
					}
					if(state.shipper_shipment_state == "") {
						state.shipper_shipment_state = shipper_state;
					}
					if(state.shipper_shipment_country == "") {
						state.shipper_shipment_country = shipper_country;
					}

					if(state.consignee_shipment_zip == "") {
						state.consignee_shipment_zip = consignee_zip;
					}
					if(state.consignee_shipment_state == "") {
						state.consignee_shipment_state = consignee_state;
					}
					if(state.consignee_shipment_country == "") {
						state.consignee_shipment_country = consignee_country;
					}

					await this.setState(state);
					if(state.consignee_shipment_city == "") {
						this.handleZip("consignee_shipment", state.consignee_shipment_zip);
					}
					if(state.shipper_shipment_city == "") {
						this.handleZip("shipper_shipment", state.shipper_shipment_zip);
					}

				}

			}

		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the zip code for this warehouse!", { variant: "error" });
		}

	}

	async checkForResi() {
		this.setState({ loadingResidentialCheck: true });

		const resiData = {
			street: this.state.resi_street_line_1,
			city: this.state.resi_city,
			state: this.state.consignee_state,
			zip: this.state.consignee_zip,
			country: this.state.consignee_country,
			code: this.state.warehouse
		};

		if (resiData.street == "" || resiData.city == "" || resiData.zip == "") {
			this.props.setNotification("Street, city and zip are required to check an address!", { variant: "error" });
		} else {
			try {
				if (this.state.fedex_address_validation == true) {
					const url = "/index.php?p=api&r=json&c=parcelShipService&m=fedexAddressValidation";
					const response = await axios.post(url, qs.stringify(resiData));

					if (typeof response.data !== "string") {
						this.props.handleMessages(response);
						const data = response.data;

						if (!_.isEmpty(data.body.classification)) {
							if (data.body.classification != "UNKNOWN") {
								if (data.body.classification == "RESIDENTIAL") {
									this.handleResi(resiData.street);
								} else {
									this.props.setNotification("This address is considered non-residential by FedEx.", { variant: "info" });

									this.setState({ residentialCheckModal: false });
								}
							} else {
								this.props.setNotification("Could not locate " + resiData.street, { variant: "error" });
							}
						} else {
							this.props.setNotification("An error occurred checking this address!", { variant: "error" });
						}
					} else {
						this.props.setNotification("An error occurred checking this address!", { variant: "error" });
					}
				} else {
					const url = "https://api.smartystreets.com/street-address?auth-id=13814496317761852&street=" + resiData.street + "&zipcode=" + resiData.zip + "&candidates=2";

					// Not sure about this, but on localhost this causes a network error with axios
					axios.defaults.withCredentials = false;
					const response = await axios.get(url);

					if (response.data.length === 1) {
						if (response.data[0].metadata.rdi === "Residential") {
							this.handleResi(response.data[0].delivery_line_1);
						} else {
							this.props.setNotification("This address is considered non-residential by the USPS.", { variant: "info" });

							this.setState({ residentialCheckModal: false });
						}
					} else {
						this.props.setNotification("Could not locate " + resiData.street, { variant: "error" });
					}
				}
			} catch (error) {
				console.error(error);

				this.props.setNotification("There was an error checking this address!", { variant: "error" });
			}
		}

		this.setState({ loadingResidentialCheck: false });
	}

	async getDefaultProduct(code) {
        const { packages } = this.state;
		let defaultPkg = {}
        if (_.isEmpty(code)) {
            return;
        }
        try {
            const url = "/index.php?p=api&r=json&c=product&m=defaults&d=" + code + "/1";
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {
				const res = response.data.body;
				if (res.id) {
					defaultPkg = {
						length: res.length,
						width: res.width,
						height: res.height,
						weight: res.weight,
						quantity: res.pieces || 1,
						declaredValue: res.declared_value || '',
						description: res.name || '',
						part_no: "",
						hazardous: res.haz_class ? true : false,
						hazmat: {
							un: res.un_num,
							class: res.haz_class,
							requiredLabel: res.required_label,
							subHazardClass: res.sub_haz_class,
							properShippingName: res.proper_shipping_name,
							packingGroup: res.group,
							packingInstructionCode: res.packing_inst,
							technicalName: res.technical_name,
							packagingType: res.pkg_type,
							regulatoryQuantity: res.regulatory_qty,
							uom: res.haz_uom,
							quantity: res.quantity,
							transportationMode: res.trans_mode,
							accessibility: res.accessibility,
							innerContainerType: res.inner_ctr_type
						}
					};
				} else {
					defaultPkg = packages[0]
				}
				packages[0] = defaultPkg
				this.setState({
					packages,
					allowedUnitsOfMeasure: formatHazUoM(res.haz_uom),
					hazmat:  res.haz_class ? true : false
				});
            } else {
                this.props.setNotification("There was an error loading the default product!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an exception loading the default product!", { variant: "error" });
        }
    }


	async handleProductSearch(i) {
        const { warehouse, packages } = this.state;
        const product = packages[i].description;

        const url = "/index.php?p=api&r=json&c=product&m=find&d=" + warehouse + "/" + product;

        try {
            const response = await axios.get(url);
            if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
                this.props.handleMessages(response);
            }
            if (typeof response.data !== "string") {

                if (response.data.body.length == 1 && response.data.body[0].is_parcel != 0) {

                    if(response.data.body[0].alert_message != null && response.data.body[0].alert_message.length > 0) {

                        this.setState({
                            products: response.data.body,
                            productKey: i,
                        }, this.openAlert(response.data.body[0].alert_message, () => {this.handleProductSelect(response.data.body[0].id)}));

                    } else {

                        this.setState({
                            products: response.data.body,
                            productKey: i,
                        });

                        this.handleProductSelect(response.data.body[0].id);

                    }

                } else if (response.data.body.length > 1) {

                    this.setState({
                        products: response.data.body,
                        productKey: i,
                        productSearchModal: true
                    });

                } else {
                    this.props.setNotification("No products found!", {
                        variant: "info"
                    });
                }
            } else {
                this.props.setNotification("There was an error searching products!", { variant: "error" });
            }
        } catch (error) {
            console.error(error);
            this.props.setNotification("There was an exception searching products!", { variant: "error" });
        }
    }

	async handleProductSelect(productId) {

        const { warehouse, productKey,  packages } = this.state;

        const url = "/index.php?p=api&r=json&c=product&m=load&d=" + warehouse + "/" + productId;

        try {

            const response = await axios.get(url);
            if (typeof response.data !== "string") {

				if(!_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
                }
				const res = response.data.body;
				let selectedPkg = {
					length: res.length,
					width: res.width,
					height: res.height,
					weight: res.weight,
					quantity: res.pieces || 1,
					declaredValue: res.declared_value || '',
					description: res.name,
					hazardous: res.haz_class ? true : false,
					part_no: res.part_no || '',
					hazmat: {
						un: res.un_num,
						class: validateItem(res.haz_class, 'class'),
						requiredLabel: res.required_label,
						subHazardClass: res.sub_haz_class,
						properShippingName: res.proper_shipping_name,
						packingGroup: validateItem(res.group, 'pkgGroup'),
						packingInstructionCode: res.packing_inst,
						technicalName: res.technical_name,
						packagingType: validateItem(res.pkg_type, 'pkgType'),
						regulatoryQuantity: validateItem(res.regulatory_qty, 'regQty'),
						uom: validateItem(res.haz_uom, 'uom'),
						quantity: res.quantity,
						transportationMode: validateItem(res.trans_mode, 'transMode'),
						accessibility: validateItem(res.accessibility, 'accessibility'),
						innerContainerType: validateItem(res.inner_ctr_type, "innerContainer")
					}
				};
				packages[productKey] = selectedPkg;
                this.setState({
					packages,
					productSearchModal: false,
					hazmat:  res.haz_class ? true : false,
					allowedUnitsOfMeasure: formatHazUoM(res.haz_uom)
				});
            } else {

                this.props.setNotification("There was an error loading the product!", {variant: "error"});

            }

        } catch (error) {

            console.error(error);

            this.props.setNotification("There was an exception loading the product!", { variant: "error" });

        }

    }

	handleResi(street) {
		this.setState({
			alert: (
				<SweetAlert
					info
					style={{
						display: "block",
						color: "black"
					}}
					title={street + " is a residential location."}
					onConfirm={() => this.setState({ residential: true, residentialCheckModal: false, alert: null })}
					onCancel={() => this.setState({ alert: null, residentialCheckModal: false })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Include Residential"
					cancelBtnText="Cancel"
					showCancel
				/>
			)
		});
	}

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
		if(modal == "printDocModal") {
			this.setState({docZpl:""})
		}
	}

	handleDatetime(name, momentObj) {
		this.setState({ [name]: momentObj });
		for (const input of this.state.rates) {
			if (name === input) {
				this.setState({
					parcelRates: [],
					carrier: "",
					service: "",
					service_code: ""
				});
			}
		}
	}

	handleDeletePackage(i) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this package?"
					onConfirm={() => this.deletePackage(i)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.code}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	removePackage(i) {
		const { packages } = this.state;

		if (packages[i].id != undefined) {
			this.handleDeletePackage(i);
		} else {
			this.deletePackage(i);
		}
	}

	async deletePackage(i) {
		this.setState({ alert: null });

		const { packages } = this.state;

		if (packages[i].id != undefined) {
			try {
				const response = await axios.post("/index.php?p=api&c=parcelShipService&m=deletePackage&d=" + packages[i].id);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && response.data.body) {
					packages.splice(i, 1);
					this.setState({
						packages,
						parcelRates: [],
						carrier: "",
						service: "",
						service_code: ""
					});

					if (packages.length == 0) {
						this.setState({
							packages: [
								{
									id: "",
									length: "",
									width: "",
									height: "",
									weight: "",
									declaredValue: "",
									part_no: "",
									quantity: 1,
									hazardous: false,
									hazmat: {
										un: "",
										class: "",
										subHazardClass: "",
										properShippingName: "",
										packingGroup: "",
										packingInstructionCode: "",
										technicalName: "",
										packagingType: "",
										innerContainerType: "",
										regulatoryQuantity: "",
										uom: "",
										quantity: "",
										transportationMode: ""
									}
								}
							]
						});
					}
				} else {
					this.props.setNotification("There was an error removing the package!", {
						variant: "error"
					});
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error removing the package!", {
					variant: "error"
				});
			}
		} else {
			if (i !== 0) {
				packages.splice(i, 1);
				this.setState({
					packages,
					parcelRates: [],
					carrier: "",
					service: "",
					service_code: ""
				});
			}
		}
	}

	addPackage() {
		const { packages } = this.state;
		packages.push({
			length: "",
			width: "",
			height: "",
			weight: "",
			declaredValue: "",
			quantity: 1,
			hazardous: false,
			description: "",
			part_no: "",
			hazmat: {
				un: "",
				class: "",
				subHazardClass: "",
				properShippingName: "",
				packingGroup: "",
				packingInstructionCode: "",
				technicalName: "",
				packagingType: "",
				innerContainerType: "",
				regulatoryQuantity: "",
				uom: "",
				quantity: "",
				transportationMode: ""
			}
		});
		this.setState({
			packages,
			parcelRates: [],
			carrier: "",
			service: "",
			service_code: ""
		});
	}

	handlePackage(i, name, event) {
		const { packages } = this.state;
		const prop = packages[i];
		const exceededWeightLimit = this.exceedsWeightLimit();
		prop[name] = event.target.value;
		packages[i] = prop;

		let customs = this.state.customs;
		let customs_value = this.state.customs.customs_value;

		if(name == 'declaredValue') {
			customs_value = 0;
			for (const prop of this.state.packages) {
				if(Number(prop.declaredValue) != 'NaN') {
					customs_value += Number(prop.declaredValue);
				}
			}
		}
		customs.customs_value = customs_value;

		this.setState({
			packages,
			parcelRates: [],
			carrier: "",
			service: "",
			service_code: "",
			customs,
		}, function() {
			if (!exceededWeightLimit && this.exceedsWeightLimit()) {
				this.props.setNotification("This shipment cannot be rated or scheduled because it exceeds your organization's weight limit for parcel shipments.", { variant: "error" });
			}
		});
	}

	getPackagingTypes = () => {
		const { classes } = this.props;
		const shipCountry = this.state.shipper_country;
		const consCountry = this.state.consignee_country;
		let iterateArr = [
			{
				'name': 'Express Box',
				'value' : 'EXPRESS_BOX'
			},
			{
				'name': 'Carrier Large Box',
				'value' : 'LARGE_BOX'
			},
			{
				'name': 'Carrier Medium Box',
				'value' : 'MEDIUM_BOX'
			},
			{
				'name': 'Carrier Small Box',
				'value' : 'SMALL_BOX'
			},
			{
				'name': 'FedEx Extra Large Box',
				'value' : 'FEDEX_EXTRA_LARGE_BOX'
			},
		];

		const upsIntlPackTypes = [
			{
				'name': 'Pallet',
				'value' : 'PALLET'
			},
		];

		const nonExclusiveIntlPackTypes = [
			{
				'name': '10KG Box',
				'value' : '10KG_BOX'
			},
			{
				'name': '25KG Box',
				'value' : '25KG_BOX'
			},
		];

		//none of these types are currently supported in the rate service.
		/* const upsSpecialPackTypes = [
			{
				'name': 'Flats 57 Parcel',
				'value' : 'UPS_57_BOX'
			},
			{
				'name': 'BPM  First Class',
				'value' : 'UPS_BPM'
			},
			{
				'name': 'Priority',
				'value' : 'UPS_PRIORITY'
			},
			{
				'name': 'Standard Flat',
				'value' : 'UPS_STD_FLAT'
			},
			{
				'name': 'Parcel Post',
				'value' : 'UPS_PARCEL_POST'
			},
			{
				'name': 'Machinables',
				'value' : 'UPS_MACHINABLES'
			},
			{
				'name': 'Irregulars',
				'value' : 'UPS_IRREGULAR'
			},
			{
				'name': 'BPM Parcel',
				'value' : 'UPS_BPM_PARCEL'
			},
			{
				'name': 'UPS Media Mail',
				'value' : 'UPS_MEDIA_MAIL'
			},
			{
				'name': 'UPS BPM Flat',
				'value' : 'UPS_BPM_FLAT'
			},
		]; */

		if(shipCountry != consCountry) {
			nonExclusiveIntlPackTypes.map((prop) => {
				iterateArr.push(prop);
			});
			if(shipCountry == "US") {
				upsIntlPackTypes.map((prop) => {
					iterateArr.push(prop);
				});
			}
		}

		return iterateArr.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	toggleHazardous() {
		const { packages } = this.state;
		packages[0].hazardous = !packages[0].hazardous;
		const hazmat = packages[0].hazardous;
		packages.length = 1;
		this.setState({
			packages,
			hazmat,
			parcelRates: [],
			carrier: "",
			service: "",
			service_code: ""
		});
		if(!hazmat) {
			this.setState({
				hazCarrier: "",
				hazService: "",
			});
			this.loadServices(this.state.carrier);
		} else {
			this.setState({
				hazCarrier: "FEDEX",
				hazService: "FEDEX_GROUND",
			});
			this.loadServices("FEDEX");
		}
	}

	handleHazardous(i, name, event) {
		const { packages } = this.state;
		if (!_.isEmpty(packages)) {

			const prop = packages[i];
			if(name == "transportationMode" || name =="un") {
				//reset shipment info, but keep the user-entered info.
				prop.hazmat = {
					accessibility: "",
					class: "",
					emergencyPhone: prop.hazmat.emergencyPhone,
					emergencyResponseRegistrant: prop.hazmat.emergencyResponseRegistrant,
					innerContainerType: prop.hazmat.innerContainerType,
					intlEmergencyPhone: prop.hazmat.intlEmergencyPhone,
					offeror: prop.hazmat.offeror,
					packagingType: prop.hazmat.packagingType,
					packingGroup: "",
					packingInstructionCode: "",
					properShippingName: "",
					quantity: prop.hazmat.quantity,
					regulatoryQuantity: "",
					requiredLabel: "",
					signatoryContactName: prop.hazmat.signatoryContactName,
					signatoryPlace: prop.hazmat.signatoryPlace,
					signatoryTitle: prop.hazmat.signatoryTitle,
					subHazardClass: "",
					technicalName: "",
					transportationMode: prop.hazmat.transportationMode,
					un: prop.hazmat.un,
					uom: "",
				};
			}

			prop.hazmat[name] = event.target.value;
			packages[i] = prop;
			this.setState({
				packages,
				parcelRates: [],
			});
			this.getTotalWeight();
		}
	}

	shipmentIsHazardous() {
		for(var key in this.state.packages) {
			if(this.state.packages[key].hazardous) {
				return true;
			}
		}
		return false;
	}

	getTotalWeight() {
		const { packages } = this.state;
		const total = packages.reduce((count, prop) => {
			const number = /^\d+(\.\d{1,2})?$/;
			const weight = number.test(prop.weight) ? parseFloat(prop.weight) : 0;
			const qty = prop.quantity;
			return (weight * qty) + count;
		}, 0);
		if (isNaN(total)) {
			return "0.0";
		}
		return total.toFixed(1);
	}
	exceedsWeightLimit = () => {
		return +this.getTotalWeight() > this.props.weightLimit;
	}
	getTotalPackageCount() {
		const { packages } = this.state;
		const total = packages.reduce((count, prop) => {
			const qty = prop.quantity;
			return parseFloat(qty) + parseFloat(count);
		}, 0)
		return total;
	}
	getPackages(packages) {
		const { classes } = this.props;
		const { packaging_type } = this.state;
		return packages.map((prod, key) => {
			const packageClassName = prod.hazardous ? classes.hazmat : classes.package;
			return (
				<GridContainer key={key} className={packageClassName}>
					<GridItem xs={12} >
						<GridContainer>
							<GridItem xs={1}>
								{prod.hazardous === true ? (
									<Chip
										avatar={
											<Avatar>
												<ReportProblem />
											</Avatar>
										}
										label={"#" + (key + 1)}
										color="secondary"
										variant="outlined"
										className={classes.chip}
									/>
								) : (
									<Chip label={"#" + (key + 1)} variant="outlined" className={classes.chip} />
								)}
							</GridItem>
							<GridItem xs={11}>
								<GridContainer>
									<GridItem xs>
										<small style={{ marginTop: "10px" }}>Length</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "length",
												value: (packaging_type === "BOX" || this.state.packaging_type == 'PALLET') ? prod.length : "",
												disabled: packaging_type !== "BOX" && this.state.packaging_type !== 'PALLET',
												endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'in.' : 'cm'}</InputAdornment>,
												onChange: e => this.handlePackage(key, "length", e)
											}}

											required={this.state.packaging_type == 'BOX' || this.state.packaging_type == 'PALLET'}
										/>
									</GridItem>
									<GridItem xs>
										<small style={{ marginTop: "10px" }}>Width</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "width",
												value: (packaging_type === "BOX" || this.state.packaging_type == 'PALLET') ? prod.width : "",
												disabled: packaging_type !== "BOX" && this.state.packaging_type !== 'PALLET',
												endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'in.' : 'cm'}</InputAdornment>,
												onChange: e => this.handlePackage(key, "width", e)
											}}
											required={this.state.packaging_type == 'BOX' || this.state.packaging_type == 'PALLET'}
										/>
									</GridItem>
									<GridItem xs>
										<small style={{ marginTop: "10px" }}>Height</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "height",
												value: (packaging_type === "BOX" || this.state.packaging_type == 'PALLET') ? prod.height : "",
												disabled: packaging_type !== "BOX" && this.state.packaging_type !== 'PALLET',
												endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'in.' : 'cm'}</InputAdornment>,
												onChange: e => this.handlePackage(key, "height", e)
											}}
											required={this.state.packaging_type == 'BOX' || this.state.packaging_type == 'PALLET'}
										/>
									</GridItem>
									<GridItem xs>
										<small style={{ marginTop: "10px" }}>Weight</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "weight",
												value: prod.weight,
												endAdornment: <InputAdornment position="end">{this.state.standard_units===true? 'lbs' : 'kg'}</InputAdornment>,
												onChange: e => this.handlePackage(key, "weight", e)
											}}
											required
										/>
									</GridItem>
									<GridItem xs>
										<small style={{ marginTop: "10px" }}>Declared Value</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "declaredValue",
												value: prod.declaredValue,
												onChange: e => this.handlePackage(key, "declaredValue", e)
											}}
											white
											required={this.state.consignee_country != this.state.shipper_country}
										/>
									</GridItem>
									<GridItem xs>
										<small style={{ marginTop: "10px" }}>Quantity</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "number",
												inputProps: { min: 1, max: 30 },
												name: "quantity",
												value: prod.quantity,
												onChange: e => this.handlePackage(key, "quantity", e)
											}}
											required
										/>
									</GridItem>
									<GridItem xs className={classes.center}>
										{key === 0 && (
											<WithTooltip
												title="Toggle Hazmat"
												content="Note: Multi-package Hazmat/Dangerous Goods Shipments are unsupported"
											>
												<span>
													<Button
														size="sm"
														justIcon
														round
														color="danger"
														title="Toggle Hazmat"
														className={classes.chip}
														style={{ paddingTop: "2px", display: this.state.showHaz == true ? "" : "none" }}
														onClick={() => this.toggleHazardous()}
														disabled={this.state.packages.length > 1}
													>
														<ReportProblem />
													</Button>
												</span>
											</WithTooltip>
										)}
										{(key !== 0 || !_.isEmpty(prod.id)) && (
											<Button
												size="sm"
												justIcon
												round
												color="danger"
												title="Remove Package"
												className={classes.chip}
												onClick={() => this.removePackage(key)}
											>
												<Close />
											</Button>
										)}
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={6}>
								<GridContainer>
									<GridItem xs={6}>
										<InputLabel className={classes.label}>Description</InputLabel>
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "description",
												value: prod.description || '',
												onChange: (e) => {
													this.handlePackage(key, "description", e);
												},
											}}
											required={this.state.consignee_country != this.state.shipper_country}
										/>
									</GridItem>
									<GridItem xs={2} >
										<WithTooltip
											title="Load A Product (Commodity)"
											content="Choose a pre-configured Product (Commodity) from the Product Catalog."
										>

											<span>
												<Button color="white" size="sm" justIcon round className={classes.chip}  onClick={e => this.handleProductSearch(key)}  >
													<Search />
												</Button>
											</span>

										</WithTooltip>
									</GridItem>
									<GridItem xs={4}>
										<small>Part #</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "part_no",
												value: prod.part_no,
												onChange: e => this.handlePackage(key, "part_no", e)
											}}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						{prod.hazardous === true && (
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<HazmatInfo
										packages={this.state.packages}
										getTotalWeight={this.getTotalWeight}
										prod={prod}
										pos={key}
										state={this.state}
										setHazUNInfo={this.setHazUNInfo}
										isQuotePage={true}

									/>
								</GridItem>
							</GridContainer>
						)}
					</GridItem>
				</GridContainer>
			);
		});
	}

	getShipmentData(serviceTab) {
		let shipper = {};
		let consignee = {};

		if (!serviceTab) {
			shipper = {
				zip: _.trim(this.state.shipper_zip),
				country: _.trim(this.state.shipper_country),
				state: _.trim(this.state.shipper_state)
			};
			consignee = {
				zip: _.trim(this.state.consignee_zip),
				country: _.trim(this.state.consignee_country),
				state: _.trim(this.state.consignee_state)
			};
		}

		const warehouseCode = this.state.warehouse;
		const dropoffType = this.state.dropoff;
		const shipDate = this.state.ship_date ? this.state.ship_date.format("YYYY-MM-DD") : "";
		const packagingType = this.state.packaging_type;
		const cod = this.state.cod;
		const signature = this.state.signature;
		const residential = this.state.residential;
		const saturdayDelivery = this.state.saturdayDelivery;
		const carbonNeutral = this.state.carbonNeutral;
		const shipmentId = this.state.shipmentInfo.shipmentId != "" ? this.state.shipmentInfo.shipmentId : null;
		let service = ""; // Only supporting if haz for now
		let carrier = ""; // Only supporting if haz for now

		const specialServices = [];
		const asnEmail = '';
		const packages = [];

		if (cod) {
			specialServices.push({
				type: "COD",
				additionalInfo: null,
				displayText: "Collect On Delivery"
			});
		}

		if (signature) {
			specialServices.push({
				type: "SIGNATURE_OPTION",
				additionalInfo: this.state.signature_type,
				displayText: this.state.signature_type + " Signature Required"
			});
		}

		if (saturdayDelivery) {
			specialServices.push({
				type: "SATURDAY_DELIVERY",
				additionalInfo: null,
				displayText: "Saturday Delivery"
			});
		}

		if (carbonNeutral) {
			specialServices.push({
				type: "CARBON_NEUTRAL",
				additionalInfo: null,
				displayText: "Carbon Neutral"
			});
		}

		for (const prop of this.state.packages) {
			let dgInfo = {};

			const id = prop.id;
			const length = prop.length.trim();
			const width = prop.width.trim();
			const height = prop.height.trim();
			const weight = prop.weight.trim();
			const part_no = prop.part_no;
			const description = prop.description;

			let declaredValue = prop.declaredValue;
			const qty = prop.quantity;
			const standardUnits = this.state.standard_units === true ? 1 : 0;

			const hazard = prop.hazardous;

			if (hazard) {

				if(!serviceTab) {
					service = this.state.hazService;
					carrier = this.state.hazCarrier;
				}

				dgInfo = {
					selectedKey: this.state.selectedKey,
					regulationSet: this.state.regulation_set,
					unNum: prop.hazmat.un,
					classNum: prop.hazmat.class,
					subriskClassNum: prop.hazmat.subHazardClass,
					properShippingName: prop.hazmat.properShippingName,
					technicalName: prop.hazmat.technicalName,
					transportMode: prop.hazmat.transportationMode,
					packagingType: prop.hazmat.packagingType,
					innerContainerType: prop.hazmat.innerContainerType,
					regulatoryQuantity: prop.hazmat.regulatoryQuantity,
					packingInstructionCode: prop.hazmat.packingInstructionCode,
					packagingGroup: prop.hazmat.packingGroup,
					accessibility: prop.hazmat.accessibility,
					requiredLabel: prop.hazmat.requiredLabel,
					emergencyPhone: prop.hazmat.emergencyPhone,
					intlEmergencyPhone: prop.hazmat.intlEmergencyPhone,
					emergencyResponseRegistrant: prop.hazmat.emergencyResponseRegistrant,
					offeror: prop.hazmat.offeror,
					signatoryContactName: prop.hazmat.signatoryContactName,
					signatoryTitle: prop.hazmat.signatoryTitle,
					signatoryPlace: prop.hazmat.signatoryPlace,
					packagingTypeQty: {
						uom: prop.hazmat.uom,
						qty: prop.hazmat.quantity
					}
				};
			}

			if (isNaN(declaredValue)) {
				declaredValue = "";
			}

			packages.push({
				id,
				length,
				width,
				height,
				weight,
				declaredValue,
				qty,
				standardUnits,
				part_no,
				description,
				dgInfo
			});
		}

		let retVal = {
			warehouseCode,
			shipper,
			consignee,
			packages,
			residential,
			dropoffType,
			shipDate,
			specialServices,
			asnEmail,
			packagingType,
			shipmentId
		};
		if(carrier != '') {
			retVal.carrier = carrier;
	}
		if(service != '') {
			retVal.service = service;
		}

		return retVal;
	}

	async getParcelRates() {
		const required = ["warehouse", "dropoff", "packaging_type", "shipper_zip", "shipper_state", "shipper_country", "consignee_zip", "consignee_state", "consignee_country"];

		let valid = true;

		for (const input of required) {
			if (_.isEmpty(this.state[input])) {
				valid = false;
			}
		}

		const data = this.getShipmentData(false);

		let hazmatReqFields = [
			'transportMode', 'unNum', 'classNum', 'properShippingName', 'packagingType', 'accessibility',
			'offeror', 'emergencyPhone', 'intlEmergencyPhone', 'emergencyResponseRegistrant',
			'signatoryContactName', 'signatoryTitle', 'signatoryPlace',
		];

		for (const prop of data.packages) {
			if (this.state.packaging_type === "BOX" || this.state.packaging_type === "PALLET" ) {
				if (_.isEmpty(prop.length)) {
					valid = false;
					break;
				}
				if (_.isEmpty(prop.width)) {
					valid = false;
					break;
				}
				if (_.isEmpty(prop.height)) {
					valid = false;
					break;
				}
			}
			if (_.isEmpty(prop.weight)) {
				valid = false;
				break;
			}
			if (isNaN(prop.qty)) {
				valid = false;
				break;
			}

			if(this.state.shipper_country != this.state.consignee_country) {
				if(_.isEmpty(prop.declaredValue)) {
					valid = false;
					break;
				}
				if(_.isEmpty(prop.description)) {
					valid = false;
					break;
				}
			}

			if(this.state.hazmat) {
				for (const input of hazmatReqFields) {
					if (_.isEmpty(prop.dgInfo[input])) {
						valid = false;
						break;
					}
				}
				if(prop.dgInfo.packagingTypeQty.qty == "" || prop.dgInfo.packagingTypeQty.uom == "" ) {
					valid = false;
				}
			}
		}

		if (!valid) {
			this.props.setNotification("All required inputs must be entered to get a parcel quote", { variant: "error" });
			return;
		}

		this.setState({
			rated: true,
			parcelRates: [],
			showParcelRates: false,
			loadingParcelRates: true,
			service_code: "",
		});

		// TTMS-1965
		if(this.state.ground_hazmat) {
			data.ground_hazmat = 1;
		}

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=parcelRateRequest&m=getParcelRates", qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.user)) {
				const default_label_type = response.data.user.default_label_sizes.type || "STOCK";
				this.setState({
					showParcelRates: true,
					loadingParcelRates: false,
					parcelRates: response.data.body.parcelRates,
					fedex_shipping_enabled: response.data.warehouse.fedex_shipping_enabled,
					ups_shipping_enabled: response.data.warehouse.ups_shipping_enabled,
					usps_shipping_enabled: response.data.warehouse.usps_shipping_enabled,
					quote_id: response.data.body.quote_id || "",
					default_label_type,
					label_type: default_label_type,
					default_label_size_fedex: response.data.user.default_label_sizes.FEDEX,
					default_label_size_ups: response.data.user.default_label_sizes.UPS
				});
			} else {
				this.setState({
					parcelRates: [],
					loadingParcelRates: false
				});
				this.props.setNotification("An error occurred loading the parcel rates!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({
				parcelRates: [],
				loadingParcelRates: false
			});
			this.props.setNotification("An exception occurred loading the parcel rates!", { variant: "error" });
		}
	}

	getHeaders(parcelRates) {
		const headers = [];
		headers.push("Service");
		if(!this.props.hideParcelRateDetails) {
			headers.push("Base Charge");
			headers.push("Total Surcharges");
			headers.push("Discount");
		}
		headers.push("Total Charge");
		if(!this.props.hideParcelRateDetails) {
			headers.push("Details");
		}
		if (!this.state.quote_id) {			
		   if (parcelRates.carrier == "FEDEX") {
				if (this.state.fedex_shipping_enabled) {
					headers.push("Select");
				}
			} else if (parcelRates.carrier == "UPS") {
				const shipDate = moment(parcelRates.shipDate).format("YYYY-MM-DD");
				const currentDate = moment().format("YYYY-MM-DD");
				if (shipDate === currentDate && this.state.ups_shipping_enabled) {
					headers.push("Select");
				}
			} else if (parcelRates.carrier == "USPS" && parcelRates.shipmentType != "INTERNATIONAL" && this.state.shipper_country == "US") {
				if(this.state.usps_shipping_enabled) {
					headers.push("Select");
				}
			}

		}
		return headers;
	}

	getColumns(parcelRates) {
		const { classes } = this.props;
		const carrier = parcelRates.carrier;
		const shipmentType = parcelRates.shipmentType;
		return parcelRates.rates.map((prop, key) => {
			let totalSurcharges = "N/A";
			let discount = "N/A";
			if (!isNaN(parseFloat(prop.totalSurcharges)) && parseFloat(prop.totalSurcharges) != 0) {
				totalSurcharges =
					"$" +
					parseFloat(prop.totalSurcharges)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,");
			}
			if (!isNaN(parseFloat(prop.discount)) && parseFloat(prop.discount) != 0) {
				discount =
					"$" +
					parseFloat(prop.discount)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,");
			}
			const data = [];
			data.push(<strong>{prop.service}</strong>);
			if(!this.props.hideParcelRateDetails) {
				data.push("$" +
					parseFloat(prop.baseCharge)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,")
				);
				data.push(totalSurcharges);
				data.push(discount);
			}
			data.push("$" +
				parseFloat(prop.totalCharge)
					.toFixed(2)
					.replace(/\d(?=(\d{3})+\.)/g, "$&,")
			);
			if(!this.props.hideParcelRateDetails) {
				data.push(
					<FontAwesomeIcon
						icon={faInfoCircle}
						size="2x"
						onClick={e => {
							this.handleModalOpen("rateBreakdownModal");
							this.handleParcelRate(key, carrier, e, true);
						}}
						style={{ cursor: "pointer" }}
					/>
				);
			}
			if (!this.state.quote_id) {
				const parcelRadioButton = (
					<FormControlLabel
						control={
							<Radio
								checked={this.state.parcel_rate_key === key && this.state.carrier === carrier}
								onChange={e => this.handleParcelRate(key, carrier, e)}
								value={key}
								icon={<FiberManualRecord className={classes.radioUnchecked} />}
								checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
								classes={{
									checked: classes.radio,
									root: classes.radioRoot
								}}
							/>
						}
					/>
				);
				if (carrier == "FEDEX") {
					if (this.state.fedex_shipping_enabled) {
						data.push(parcelRadioButton);
					}
				} else if (carrier == "UPS") {
					const shipDate = moment(parcelRates.shipDate).format("YYYY-MM-DD");
					const currentDate = moment().format("YYYY-MM-DD");
					if (shipDate === currentDate && this.state.ups_shipping_enabled) {
						data.push(parcelRadioButton);
					}
				} else if (parcelRates.carrier == "USPS" && parcelRates.shipmentType != "INTERNATIONAL" && this.state.shipper_country == 'US') {
					if(this.state.usps_shipping_enabled) {
						data.push(parcelRadioButton);
					}
			}
			}
			return {
				color: "info",
				data: data
			};
		});
	}

	getRatesTable(parcelRates) {
		const unit = (parcelRates.ratingMethod != 'CUBIC') ? 'lbs.' : 'cubic ft.';
		return (
			<GridContainer>
				<GridItem xs={12}>
					{parcelRates.ratingMethod && <h5>Rating Method: {parcelRates.ratingMethod}</h5>}
					{parcelRates.ratingMethod == "DIM" && parcelRates.dimDivisor != 139 && !_.isEmpty(parcelRates.dimDivisor) && <h5>Dim Divisor: {parcelRates.dimDivisor}</h5>}
					{parcelRates.billingWeight && <h5>Total Billing Weight: {parcelRates.billingWeight} {unit}</h5>}
					<Table hover tableHead={this.getHeaders(parcelRates)} tableData={this.getColumns(parcelRates)} />
				</GridItem>
			</GridContainer>
		);
	}

	getTabs(parcelRates) {
		const tabs = [];

		const fedexRates = parcelRates.filter(
			parcelRate => parcelRate.carrier === "FEDEX"
		);
		const upsRates = parcelRates.filter(
			parcelRate => parcelRate.carrier === "UPS"
		);
		const uspsRates = parcelRates.filter(
			parcelRate => parcelRate.carrier === "USPS"
		);

		if (fedexRates.length) {
			tabs.push({
				tabIcon: () => <FontAwesomeIcon icon={faFedex} size="6x" />,
				tabContent: (
					<span>
						<GridItem xs={12}>{this.getRatesTable(fedexRates[0])}</GridItem>
					</span>
				)
			});
		}

		if (upsRates.length) {
			tabs.push({
				tabIcon: () => <FontAwesomeIcon icon={faUps} size="6x" />,
				tabContent: (
					<span>
						<GridItem xs={12}>{this.getRatesTable(upsRates[0])}</GridItem>
					</span>
				)
			});
		}

		if(uspsRates.length) {
			tabs.push({
				tabIcon: () => <FontAwesomeIcon icon={faUsps} size="6x" />,
				tabContent: (
					<span>
						<GridItem xs={12}>
							{this.getRatesTable(uspsRates[0])}
						</GridItem>
					</span>
				)
			})
		}


		return tabs;
	}

	getSurcharges(surcharges) {
		return surcharges.map((surcharge, key) => {
			if (surcharge.type == "Cod") {
				surcharge.type = surcharge.type.toUpperCase();
			}

			surcharge.amount = parseFloat(surcharge.amount).toFixed(2);

			return (
				<div key={key}>
					<strong>{surcharge.type}:</strong> ${surcharge.amount}
				</div>
			);
		});
	}

	getDeliveryInfo(estOrGuar, deliveryTime) {
		return (
			<div>
				<strong>{estOrGuar}</strong> {deliveryTime}
			</div>
		);
	}

	handleParcelRate(parcel_rate_key, carrier, event, info = false) {
		const { parcelRates } = this.state;
		const carrierRates = parcelRates.filter(parcelRate => parcelRate.carrier === carrier)[0];
		if (!_.isEmpty(carrierRates.rates) && parcel_rate_key < carrierRates.rates.length) {
			const rate = carrierRates.rates[parcel_rate_key];
			let estOrGuar = "";
			let label_size = "4X6";

			if (!_.isEmpty(rate.delivery) && rate.delivery.guaranteed == true) {
				estOrGuar = "Guaranteed Delivery By: ";
			} else {
				estOrGuar = "Estimated Delivery By: ";
			}

			const service = rate.service;
			const service_code = rate.serviceCode;
			const account = carrierRates.accountNumber;
			const quoted_amount = rate.totalCharge;
			const deliveryTime = !_.isEmpty(rate.delivery) && !_.isEmpty(rate.delivery.dayOfWeek) && !_.isEmpty(rate.delivery.dateTime) ? rate.delivery.dayOfWeek + " @ " + rate.delivery.dateTime : "1 - 5 days";
			const rateZone = !_.isEmpty(rate.rateZone) ? rate.rateZone : "N/A";
			const surcharges = !_.isEmpty(rate.surcharges) ? rate.surcharges : null;
			const { default_label_type, default_label_size_fedex, default_label_size_ups } = this.state;

			const billingWeight = carrier == 'USPS' ? rate.billingWeight : carrierRates.billingWeight;
			const ratingMethod = carrier == 'USPS' ? rate.ratingMethod : carrierRates.ratingMethod;

			if (carrier === "UPS" && !_.isEmpty(default_label_size_ups)) {
				label_size = default_label_size_ups;
			}

			if (carrier === "FEDEX" && !_.isEmpty(default_label_size_fedex)) {
				label_size = default_label_size_fedex;
			}

			if (default_label_type === "PAPER" || default_label_type === "STOCK") {
				if (label_size === "4X6.75_TRAILING_DOC_TAB" || label_size === "4X6.75_LEADING_DOC_TAB" || label_size === "4X8.25_TRAILING_DOC_TAB") {
					label_size = "4X6";
				}
			}

			if (info) {
				this.setState({
					serviceInfo: service,
                    estOrGuarInfo: estOrGuar,
                    deliveryTimeInfo: deliveryTime,
                    rateZoneInfo: rateZone,
                    surchargesInfo: surcharges,
				});
			} else {
				this.setState({
					carrier,
					account,
					service,
					service_code,
					quoted_amount,
					estOrGuar,
					deliveryTime,
					rateZone,
					surcharges,
					parcel_rate_key,
					label_size,
					billingWeight,
					ratingMethod,
				});
			}
		}
	}

	async createShipment() {
		this.props.scrollTop(0);
		this.setState({ create: true, active: 0 });
		const {
			shipper_zip,
			shipper_country,
			consignee_zip,
			consignee_country,
			selected_warehouse_zip,
		} = this.state;
		await this.loadLocationDefaults(true, this.state.warehouse);
	}

	async scheduleShipment() {
		const { billing_type, cod, service_code, warehouse, asn_email, carrier, is_inbound} = this.state;
		const {customs_value, description, commodity_units, unit_value} = this.state.customs;

		const required = this.state.required;
		let valid = true;

		if (billing_type == "recipient" || billing_type == "shipper") {
			required.push("billing_account");
			required.push("billing_zip");
		}

		if (cod) {
			required.push("cod_amount");
			required.push("cod_collection_type");
		}

		for (const input of required) {
			if (_.isEmpty(this.state[input])) {
				valid = false;
			}
		}

		if (!valid) {
			this.props.setNotification("All required inputs must be entered to schedule a parcel shipment!", { variant: "error" });
			return;
		}
		const shipmentData = this.getShipmentData(true, warehouse);

		for (let i = 0; i < shipmentData.specialServices.length; i++) {
			if (shipmentData.specialServices[i].type === "COD") {
				shipmentData.codDetail = {
					amount: this.state.cod_amount,
					collectionType: this.state.cod_collection_type
				};
			}
		}

		if (!_.isEmpty(asn_email)) {

            // We no longer use FedEx to send the ASN; we send our own custom ASN.

			// shipmentData.specialServices.push({
			// 	type: "EVENT_NOTIFICATION",
			// 	additionalInfo: asn_email,
			// 	displayText: "Advanced Shipment Notification"
			// });

			shipmentData.asnEmail = asn_email;

		}

		shipmentData.billing = {};

		shipmentData.billing.type = billing_type;

		if (shipmentData.billing.type !== "account") {
			shipmentData.billing.account = this.state.billing_account;
			shipmentData.billing.zip = this.state.billing_zip;
		}

		shipmentData.carrier = carrier;
		shipmentData.serviceCode = service_code;
		shipmentData.billingWeight = this.state.billingWeight;
		shipmentData.ratingMethod = this.state.ratingMethod;
		shipmentData.rateZone = this.state.rateZone;

		shipmentData.shipper.company = _.trim(this.state.shipper_name);
		shipmentData.shipper.contact = !_.isEmpty(this.state.shipper_contact_name) ? _.trim(this.state.shipper_contact_name) : "";
		shipmentData.shipper.email = !_.isEmpty(this.state.shipper_contact_email) ? _.trim(this.state.shipper_contact_email) : "";
		shipmentData.shipper.phone = !_.isEmpty(this.state.shipper_contact_phone) ? _.trim(this.state.shipper_contact_phone) : "";
		shipmentData.shipper.streetLineOne = _.trim(this.state.shipper_address1);
		shipmentData.shipper.streetLineTwo = !_.isEmpty(this.state.shipper_address2) ? _.trim(this.state.shipper_address2) : "";
		shipmentData.shipper.city = _.trim(this.state.shipper_shipment_city);
		shipmentData.shipper.state = _.trim(this.state.shipper_shipment_state);
		shipmentData.shipper.zip = _.trim(this.state.shipper_shipment_zip);
		shipmentData.shipper.country = _.trim(this.state.shipper_shipment_country);
		shipmentData.shipper.save = this.state.shipper_save === true ? 1 : 0;

		shipmentData.consignee.company = _.trim(this.state.consignee_name);
		shipmentData.consignee.contact = !_.isEmpty(this.state.consignee_contact_name) ? _.trim(this.state.consignee_contact_name) : "";
		shipmentData.consignee.email = !_.isEmpty(this.state.consignee_contact_email) ? _.trim(this.state.consignee_contact_email) : "";
		shipmentData.consignee.phone = !_.isEmpty(this.state.consignee_contact_phone) ? _.trim(this.state.consignee_contact_phone) : "";
		shipmentData.consignee.streetLineOne = _.trim(this.state.consignee_address1);
		shipmentData.consignee.streetLineTwo = !_.isEmpty(this.state.consignee_address2) ? _.trim(this.state.consignee_address2) : "";
		shipmentData.consignee.city = _.trim(this.state.consignee_shipment_city);
		shipmentData.consignee.state = _.trim(this.state.consignee_shipment_state);
		shipmentData.consignee.zip = _.trim(this.state.consignee_shipment_zip);
		shipmentData.consignee.country = _.trim(this.state.consignee_shipment_country);
		shipmentData.consignee.save = this.state.consignee_save === true ? 1 : 0;

		shipmentData.so = !_.isEmpty(this.state.so_num) ? _.trim(this.state.so_num) : "";
		shipmentData.po = !_.isEmpty(this.state.po_num) ? _.trim(this.state.po_num) : "";
		shipmentData.dept = !_.isEmpty(this.state.dept_num) ? _.trim(this.state.dept_num) : "";
		shipmentData.ref = !_.isEmpty(this.state.ref_num) ? _.trim(this.state.ref_num) : "";

		const print_type = this.state.label_type === "ZPL" ? "print_language" : "image";

		shipmentData.labelSpec = {};
		shipmentData.labelSpec.dims = this.state.label_size;
		shipmentData.labelSpec.type = this.state.label_type;
		shipmentData.labelSpec.printType = print_type;

		shipmentData.labelSpec.includeDocTab = shipmentData.labelSpec.dims.indexOf("DOC_TAB") != -1 ? 1 : 0;
		shipmentData.saveLabel = this.state.default_label_save ? 1 : 0;
		shipmentData.costcoLabel = this.state.include_costco_label ? 1 : 0;

		shipmentData.customs = this.state.customs;
		shipmentData.is_inbound = this.state.is_inbound;

		if(shipmentData.shipper.country != shipmentData.consignee.country) {
			if(description == "") {
				this.props.setNotification("Description is required for international shipments.", { variant: "error" });
				return;
			}
			if(carrier == "FEDEX") {
				if(customs_value == "" || commodity_units == "" || unit_value == "") {
					this.props.setNotification("Customs information is required for FedEx international shipments.", { variant: "error" });
					return;
				}
			}
		}

		shipmentData.references = this.state.references;

		let config = {};
		let data = {};

		if (!_.isEmpty(this.state.imageFiles)) {
			let imgCount = 0;
			data = new FormData();
			for (let i = 0; i < this.state.imageFiles.length; i++) {
				const file = this.state.imageFiles[i];
				if (file.preview) {
					data.append("imgFile_" + i.toString(), file);
					imgCount++;
				}
			}
			if (imgCount > 0) {
				data.append("form_data_post", JSON.stringify(shipmentData));
				config = {
					headers: {
						"content-type": "multipart/form-data"
					}
				};
			} else {
				data = qs.stringify(shipmentData);
			}
		} else {
			data = qs.stringify(shipmentData);
		}

		this.setState({ schedulingShipment: true });

		try {
			const url = "/index.php?p=api&r=json&c=parcelShipService&m=createShipment";
			const response = await axios.post(url, data, config);
			this.setState({ schedulingShipment: false });
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.general) && !_.isEmpty(response.data.body.shipmentInfo)) {
				this.setState({
					general: response.data.body.general,
					shipmentInfo: response.data.body.shipmentInfo,
					schedulingShipment: false,
					create: false,
					scheduled: true
				});
				this.props.scrollTop(0);
				if(shipmentData.costcoLabel) {
					await this.viewNonDgDocs()
				}
				if (print_type === "print_language") {
					await this.printerCheck(carrier);
				}
			} else {
				this.props.setNotification("There was an error scheduling the shipment!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ schedulingShipment: false });
			this.props.setNotification("There was an exception scheduling the shipment!", { variant: "error" });
		}
	}

    closeHazOptions = () => {
        this.setState({
			showHazOptions: false,
			hazInfo: [],
		});
    }

    handleHazOptionsSubmit = selection => {
        this.setHazUNInfo(this.state.hazInfo[selection].ProductObject, this.state.selectedKey, selection);
		this.closeHazOptions();
    }


	setHazUNInfo = (responseObj, key, selection = null) => {

		let transMode = this.state.packages[0].hazmat.transportationMode;
		if(transMode == "") {
			this.props.setNotification("Transportation mode required to lookup UN info.")
			return false;
		}

		if(transMode == "Ground" && responseObj.Forbidden) {
			this.props.setNotification("Selected Service Option is not available for Ground transport mode.", { variant: "error" });
			return false;
		} else if(transMode == "Passenger" && responseObj.PassengerAndCargoAircraftPackingInstructions == "Forbidden")  {
			this.props.setNotification("Selected Service Option is not available for Cargo and Passenger Aircraft transport mode.", { variant: "error" });
			return false;
		} else if(transMode == "Cargo" && responseObj.CargoAircraftOnlyPackingInstructions == "Forbidden")  {
			this.props.setNotification("Selected Service Option is not available for Cargo Only transport mode.", { variant: "error" });
			return false;
		}

		if(typeof responseObj.LabelsRequired !== 'undefined') {
			responseObj.LabelsRequired = responseObj.LabelsRequired.trim();
		} else {
			this.props.setNotification("Selected Service Option is not available because Required Label Information is not available.", { variant: "error" });
			return false;
		}

		const { packages } = {...this.state};
		if (!_.isEmpty(packages)) {
			const prop = packages[key];

			prop.hazmat.class = responseObj.HazardClass.trim();
			prop.hazmat.subHazardClass = responseObj.HazardClass.trim();
			prop.hazmat.properShippingName = responseObj.ProperShippingName.trim();
			prop.hazmat.packingGroup = responseObj.PackingGroup.trim();
			prop.hazmat.requiredLabel = responseObj.LabelsRequired.trim();
			let allowedUnits = [];

			let hazNum = prop.hazmat.class;
			if(isNaN(prop.hazmat.class) && prop.hazmat.class.length > 2) {
				hazNum = hazNum.substring(0, hazNum.length - 1);
			}
			if(hazNum >= 3) {
				hazNum = Math.trunc(hazNum);
			}
			if(hazNum == "1.4" ||
				hazNum == "2.1" ||
				hazNum == "2.2" ||
				hazNum == "3" ||
				hazNum == "4" ||
				hazNum == "5" ||
				hazNum == "8" ) {
					prop.hazmat.accessibility = "ACCESSIBLE";
			} else {
				prop.hazmat.accessibility = "INACCESSIBLE";
			}

			switch(responseObj.LtdQtyUnitOfMeasureId) {
				case "None":
				default:
					prop.hazmat.regulatoryQuantity = "";
					break;
				case "L":
					prop.hazmat.regulatoryQuantity = "LQ";
					allowedUnits.push(responseObj.LtdQtyUnitOfMeasureId);
					break;
				//not actually sure of this value yet for fully regulated quantities
				case "F":
					prop.hazmat.regulatoryQuantity = "FR";
					allowedUnits.push(responseObj.LtdQtyUnitOfMeasureId);
					break;
			}

			let instructionCode = "";

			if(typeof responseObj.ExceptedMaxInnerLiquidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxInnerLiquidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxInnerSolidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxInnerSolidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxOuterLiquidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxOuterLiquidUnitOfMeasureId);
			}
			if(typeof responseObj.ExceptedMaxOuterSolidUnitOfMeasureId !== 'undefined') {
				allowedUnits.push(responseObj.ExceptedMaxOuterSolidUnitOfMeasureId);
			}

			if(transMode == "Cargo") {
				if(typeof responseObj.CargoAircraftOnlyUnitOfMeasureId !== 'undefined') {
					allowedUnits.push(responseObj.CargoAircraftOnlyUnitOfMeasureId);
				}

				if(typeof responseObj.CargoAircraftOnlyPackingInstructions !== 'undefined') {
					instructionCode = responseObj.CargoAircraftOnlyPackingInstructions.trim();
				}

			} else if(transMode == "Passenger") {
				if(typeof responseObj.PassengerAndCargoAircraftUnitOfMeasureId !== 'undefined') {
					allowedUnits.push(responseObj.PassengerAndCargoAircraftUnitOfMeasureId);
				}
				if(typeof responseObj.PassengerAndCargoAircraftPackingInstructions !== 'undefined') {
					instructionCode = responseObj.PassengerAndCargoAircraftPackingInstructions.trim();
				}
			} else {
				//ground shipments can take any of the units of measure.
				allowedUnits = [
					"cylinder",
					"g",
					"gallon",
					"kg",
					"kgG",
					"L",
					"mL",
					"oz",
					"pint",
					"lbs",
					"qt"
				];
			}

			if(instructionCode == "None") {
				instructionCode = "";
			}

			if(allowedUnits.length == 0) {
				this.props.setNotification("This package may not be shipped with this transportation mode.", { variant: "error" });
				return false;
			}

			prop.hazmat.packingInstructionCode = instructionCode;

			const access = this.state.packages[0].hazmat.accessibility;
			let serviceMode = this.state.service;
			if(access == "ACCESSIBLE") {
				if(this.state.carrier == "FEDEX" && serviceMode != "PRIORITY_OVERNIGHT" && serviceMode != "FEDEX_GROUND" && serviceMode != "FIRST_OVERNIGHT") {
					//prevents us from setting it multiple times as we iterate through the list.
					serviceMode = "PRIORITY_OVERNIGHT";
				}
			}
			packages[key] = prop;
			this.setState({
				selectedKey: selection,
				packages: packages,
				allowedUnitsOfMeasure: allowedUnits,
				service: serviceMode
			});
			this.getServices();
		}
	}

	openAlert = (message, callback) => {
        this.props.openAlertModal(message, callback);
    }


	handleProductAlert(key, productId) {
        const { products } = this.state;
		if(products[key].alert_message != null && products[key].alert_message.length > 0) {
			this.openAlert(products[key].alert_message, () => {this.handleProductSelect(productId)});
		} else {
			this.handleProductSelect(productId);
		}
    }


	getProducts(products) {
		const enableProductPartNo = this.state.enable_product_part_no
        return products.map((product, key) => {
            let row = [
                <div dangerouslySetInnerHTML={{ __html: product.code }} />,
                <div dangerouslySetInnerHTML={{ __html: product.name }} />,
                <div dangerouslySetInnerHTML={{ __html: product.length }} />,
                <div dangerouslySetInnerHTML={{ __html: product.width }} />,
                <div dangerouslySetInnerHTML={{ __html: product.height }} />,
                <div dangerouslySetInnerHTML={{ __html: product.weight }} />,
                <div dangerouslySetInnerHTML={{ __html: product.hazmat }} />,
                <div className="actions-right">
                    <Button size="sm" color="linkedin" onClick={() => this.handleProductAlert(key, product.id)}>
                        Select
                    </Button>
                </div>
            ];
			if(enableProductPartNo) {
				row.splice(1, 0, (
					<div dangerouslySetInnerHTML={{ __html: product.part_no }} />
				));
			}
            return  product.is_parcel == 1 ? row : [];
        });
    }

	async handleContactSearch(type) {
		let url = "";
		let searchType = "";

		const { warehouse } = this.state;
		const searchBy = this.state[type + "_search_by"];
		const searchFor = this.state[type + "_search_for"];

		switch (type) {
			case "shipper":
				searchType = "shipper";
				break;
			case "consignee":
				searchType = "consignee";
				break;
		}

		if (searchBy == "SEARCH_ANY") {
			url = "/index.php?p=api&r=json&c=location&m=findAnything&d=" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
		} else {
			url = "/index.php?p=api&r=json&c=location&m=find&d=" + searchBy + "/" + encodeURIComponent(searchFor) + "/" + searchType + "/" + warehouse;
		}

		try {
			const response = await axios.get(url);

			if (typeof response.data !== "string") {
				if (response.data.body.length == 1) {
					const contact = response.data.body[0];

					const convertedCountryCode = convertToCountryCode(contact.country, 'short');
					if(convertedCountryCode !== contact.country) {
						contact.country = convertedCountryCode;
						this.props.setNotification("Updated country code to " + convertedCountryCode + ".", { variant: "warning" });
					}

					this.setState({
						[type + "_name"]: contact.location_name || "",
						[type + "_address1"]: contact.address1 || "",
						[type + "_address2"]: contact.address2 || "",
						[type + "_shipment_zip"]: contact.zip || "",
						[type + "_shipment_city"]: contact.city || "",
						[type + "_shipment_state"]: contact.state || "",
						[type + "_shipment_country"]: contact.country || "US",
						[type + "_contact_name"]: contact.contact_name || "",
						[type + "_contact_phone"]: contact.phone || "",
						[type + "_contact_email"]: contact.email || "",
						contactSearchModal: false,
						contacts: [],
						contactSearchType: ""
					});
				} else if (response.data.body.length > 1) {
					this.setState({
						contacts: response.data.body,
						contactSearchType: type,
						contactSearchModal: true
					});
				} else {
					this.props.setNotification("No contacts found!", {
						variant: "info"
					});
				}
			} else {
				this.props.setNotification("There was an error searching contacts!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching contacts!", { variant: "error" });
		}
	}

	handleContactSelect(contactKey, type) {
		const { contacts } = this.state;
		const contact = {};
		Object.keys(contacts[contactKey]).map(key => {
			const prop = contacts[contactKey][key];
			if (prop && typeof prop === "string") {
				contact[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
			}
		});

		const convertedCountryCode = convertToCountryCode(contact.country, 'short');
		if(convertedCountryCode !== contact.country) {
			contact.country = convertedCountryCode;
			this.props.setNotification("Updated country code to " + convertedCountryCode + ".", { variant: "warning" });
		}

		this.setState({
			[type + "_name"]: contact.location_name,
			[type + "_address1"]: contact.address1,
			[type + "_address2"]: contact.address2,
			[type + "_shipment_zip"]: contact.zip,
			[type + "_shipment_city"]: contact.city,
			[type + "_shipment_state"]: contact.state,
			[type + "_shipment_country"]: contact.country,
			[type + "_contact_name"]: contact.contact_name,
			[type + "_contact_phone"]: contact.phone,
			[type + "_contact_email"]: contact.email,
			contactSearchModal: false,
			contacts: [],
			contactSearchType: ""
		});
		if (!_.isEmpty(contact.zip) && contact.zip.length >= 5) {
			this.handleZip(type, contact.zip);
		}
	}

	clearAddress(type) {
		this.setState({
			[type + "_name"]: "",
			[type + "_address1"]: "",
			[type + "_address2"]: "",
			[type + "_shipment_zip"]: "",
			[type + "_shipment_city"]: "",
			[type + "_shipment_state"]: "",
			[type + "_shipment_country"]: ""
		});
	}

	handleAddress(type) {
		const address = {
			address1: this.state[type + "_address1"],
			address2: this.state[type + "_address2"],
			zip: this.state[type + "_shipment_zip"],
			city: this.state[type + "_shipment_city"],
			state: this.state[type + "_shipment_state"]
		};

		let addressSearchString = "";

		for (const key in address) {
			const val = address[key];
			if (typeof val === "string" && _.trim(val) !== "") {
				if (addressSearchString === "") {
					addressSearchString = _.trim(val);
				} else {
					addressSearchString += " " + _.trim(val);
				}
			}
		}

		if (addressSearchString !== "") {
			const addressSearchURL = "http://www.google.com/maps/place/" + encodeURIComponent(addressSearchString);
			const addressSearchTitle = "Address Search";
			const addressSearchOpts = "resizable=1, scrollbars=1, fullscreen=0, status=0";

			window.open(addressSearchURL, addressSearchTitle, addressSearchOpts);
		}
	}

	getContacts(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<div dangerouslySetInnerHTML={{ __html: prop.code }} />,
				<address>
					<strong>
						<div
							dangerouslySetInnerHTML={{
								__html: prop.location_name
							}}
						/>
					</strong>
					<div dangerouslySetInnerHTML={{ __html: prop.address1 }} />
					{!_.isEmpty(prop.address2) && <div dangerouslySetInnerHTML={{ __html: prop.address2 }} />}
					<div
						dangerouslySetInnerHTML={{
							__html: prop.city + ", " + prop.state + " " + prop.zip + ", " + prop.country
						}}
					/>
				</address>,
				<GridContainer>
					{!_.isEmpty(prop.contact_name) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<Person />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.contact_name }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.phone) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<Phone />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.phone }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
					{!_.isEmpty(prop.email) && (
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={1}>
									<AlternateEmail />
								</GridItem>
								<GridItem xs={11}>
									<div dangerouslySetInnerHTML={{ __html: prop.email }} />
								</GridItem>
							</GridContainer>
						</GridItem>
					)}
				</GridContainer>,
				<div className="actions-right">
					<Button size="sm" color="linkedin" onClick={e => this.handleContactSelect(key, this.state.contactSearchType)}>
						Select
					</Button>
				</div>
			];
		});
	}

	getSpecialServices() {
		const { classes } = this.props;
		const shipmentData = this.getShipmentData(false);
		const specialServices = [];

		for (let i = 0; i < shipmentData.specialServices.length; i++) {
			if (!_.isEmpty(shipmentData.specialServices[i].displayText)) {
				specialServices.push(shipmentData.specialServices[i].displayText);
			}
		}

		if (this.state.residential) {
			specialServices.push("Residential Delivery");
		}

		if (!_.isEmpty(specialServices)) {
			const services = specialServices.map((prop, key) => {
				return (
					<div key={key}>
						<li>{prop}</li>
					</div>
				);
			});
			return <ul>{services}</ul>;
		}

		return "N/A";
	}

	getLabelSizes() {
		const { classes } = this.props;
		const { carrier, label_type, label_size } = this.state;
		let labels = [];
		if (carrier === "FEDEX") {
			let stockLabels = [
				{ value: "4X6", name: "4X6" },
				{ value: "4X6.75", name: "4X6.75" }, // already supported for normal shipments, but does not work with Hazmat
				{ value: "4X8", name: "4X8" },
				{ value: "4X9", name: "4X9" }, // already supported for normal shipments, but does not work with Hazmat
			];

			let paperLabels = [
				{ value: "4X6", name: "4X6" },
				{ value: "4X6.75", name: "4X6.75" }, // already supported for normal shipments, but does not work with Hazmat
				{ value: "4X8", name: "4X8" },
				{ value: "4X9", name: "4X9" },
				{ value: "7X4.75", name: "7X4.75"},
				{ value: "8.5X11_BOTTOM_HALF_LABEL", name: "8.5X11 with Trailing Label" },
				{ value: "8.5X11_TOP_HALF_LABEL", name: "8.5X11 with Leading Label" },
			]

			let zplLabels = [
				{ value: "4X6", name: "4X6" },
				{ value: "4X6.75_TRAILING_DOC_TAB", name: "4X6.75 With Trailing Doc-Tab" },
				{ value: "4X6.75_LEADING_DOC_TAB", name: "4X6.75 With Leading Doc-Tab" },
				{ value: "4X8", name: "4X8" },
				{ value: "4X9_TRAILING_DOC_TAB", name: "4X9 With Trailing Doc-Tab" },
				{ value: "4X9_LEADING_DOC_TAB", name: "4X9 With Leading Doc-Tab" },
			];

			// set label opts and remove unsupported types if a pkg is hazardous
			if (label_type === "PAPER") {

				labels = paperLabels;
				if(this.shipmentIsHazardous()) {
					labels = labels.filter((labelOpt) => !(['4X6.75'].includes(labelOpt.value)));
				}

			} else if (label_type === "STOCK") {

				labels = stockLabels;
				if(this.shipmentIsHazardous()) {
					labels = labels.filter((labelOpt) => !(['4X6.75', '4X9'].includes(labelOpt.value)));
				}

			} else if(label_type === "ZPL") {
				labels = zplLabels;
			}
		}
		if (carrier === "UPS") {
			labels = [{ value: "4X6", name: "4X6" }, { value: "4X8.25_TRAILING_DOC_TAB", name: "4X8.25 With Trailing Doc Tab" }];
			if (label_type === "PAPER" || label_type === "STOCK") {
				labels.splice(1, 1);
				if (label_size === "4X8.25_TRAILING_DOC_TAB") {
					this.setState({ label_size: "4X6" });
				}
			}
		}
		if (carrier === "USPS") {
			labels = [{value: "4X6", name: "4X6"}];

		}
		return labels.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.state;
		let data = ["1.4","1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N",
			"2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.2", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	getSubClasses() {
		const { classes } = this.props;
		const { regulation_set } = this.state;
		let data = [];
		if (regulation_set === "cfr") {
			data = ["1.4C", "1.4D", "1.4E", "1.4S", "1.4N", "2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		}
		if (regulation_set === "iata") {
			let data = ["1.4","1.4A", "1.4B", "1.4C", "1.4D", "1.4E", "1.4F", "1.4G", "1.4H", "1.4J", "1.4K", "1.4L", "1.4S", "1.4N",
				"2.1", "2.2", "3", "3,6.1", "3,8", "4.1", "4.2", "4.3", "5.1", "5.2", "6.1", "6.1,3", "6.1,8", "6.2", "7", "8", "8,3", "9"];
		}
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	printerCheck = async() => {
		const { carrier, label_type } = this.state;
		const printer = window.localStorage.getItem(carrier.toUpperCase());
		if (!_.isEmpty(printer) && label_type == "ZPL") {
			await this.autoPrint(printer, this.state.shipmentInfo.shipmentId, this.props.JSPM);
		} else {
			this.viewLabel();
		}
	}


	viewDgDocs = async () => {
		const { shipmentId } = this.state.shipmentInfo;

		let docTypes = await this.getDocumentTypes(shipmentId);

		for(var option in docTypes) {
			if(this.props.dgDocTypes.includes(option)) {
				let imgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewDgDocuments&id=" + shipmentId + "&type=" + option, "Dangerous Goods Documents " + option,"resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
				if (imgWindow) {
					try {
						imgWindow.focus();
					} catch (error) {
						console.error(error);
						this.props.handleMessages("There was an error loading the documents!", { variant: "error" });
					}
				}
				else {
					this.props.handleMessages("If multiple windows did not open, check your browsers popup blocker.", { variant: "info" })
				}
			}
		}

	}

	viewNonDgDocs = async () => {
		const { shipmentId } = this.state.shipmentInfo;

		const printer = window.localStorage.getItem('DOCUMENTS');

		let availableDocs = await this.getDocumentTypes(shipmentId);
		let zpl = "";
		for(var type in availableDocs) {
			// for now we only have the DG docs and costco label, but in the future we could support other doc types (ZPL the same as below, or PDF/image docs as we do in viewDgDocs)
			// get the ZPL of each file and combine it into a single string to print below.
			if(!this.props.dgDocTypes.includes(type)) {
				if(this.props.zplDocTypes.includes(type)) {
					const response = await axios.get("/index.php?p=api&c=parcelShipService&m=viewDocuments&id=" + shipmentId + "&type=" + type);
					if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
						this.props.handleMessages(response.data.message);
					}
					if(response && response.data && response.data.body && response.data.body.document) {
						zpl = zpl + response.data.body.document;
					}
				}
			}
		}

		if(zpl != "") {
			if (!_.isEmpty(printer)) {
				await this.props.printDocument(printer, zpl, this.props.JSPM);
			} else {
				this.setState({
					docZpl: zpl,
					printer: "",
					printDocModal: true
				});
			}
		}

	}

	autoPrint = async (printer, shipmentId, JSPM, labelType = 'label') => {
		this.props.autoPrint(printer, shipmentId, JSPM, labelType);
	}

	async viewLabel() {
		const { shipmentId, shipDate, labelType } = this.state.shipmentInfo;
		if (labelType == "image") {
			const labelImgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId, "Shipping label(s)", "resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
			if (labelImgWindow) {
				try {
					labelImgWindow.focus();
				} catch (error) {
					console.error(error);
					this.props.setNotification("There was an error loading the labels!", { variant: "error" });
				}
			}
		} else {
			try {
				const url = "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId;
				const response = await axios.get(url);

				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}

				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					this.setState({
						zpl: response.data.body.commands,
						printer: "",
						printLabelModal: true
					});
				} else {
					this.props.setNotification("There was an error viewing the label!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error viewing the label!", { variant: "error" });
			}
		}
	}

	getDocumentTypes = async (shipmentId) => {
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=parcel&m=getDocumentTypes&parcelId=" + shipmentId);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);

			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.options)) {
				const opts = response.data.body.options;
				return opts;

            } else {
			 	this.props.setNotification("There was an error loading document types!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an exception loading document types!", {
				variant: "error"
			});
		}
	}

	newQuote() {
		this.setState({
			shipper_zip: "",
			shipper_state: "",
			shipper_country: "US",
			consignee_zip: "",
			consignee_state: "",
			consignee_country: "US",
			dropoff: "REGULAR_PICKUP",
			packaging_type: "BOX",
			standard_units: true,
			regulation_set: "iata",
			show_courtesy: true,
			packages: [
				{
					id: "",
					length: "",
					width: "",
					height: "",
					weight: "",
					declaredValue: "",
					quantity: 1,
					hazardous: false,
					part_no: "",
					description: "",
					hazmat: {
						un: "",
						class: "",
						subHazardClass: "",
						properShippingName: "",
						packingGroup: "",
						packingInstructionCode: "",
						technicalName: "",
						packagingType: "",
						innerContainerType: "",
						regulatoryQuantity: "",
						uom: "",
						quantity: "",
						transportationMode: ""
					}
				}
			],
			parcelRates: [],
			carrier: "",
			service: "",
			service_code: "",
			create: false,
			scheduled: false,
			general: null,
			shipmentInfo: {
				shipmentId: "",
				trackingId: "",
				totalCharge: "",
				billingType: "",
				acctNo: "",
				labelType: "",
				carrier: "",
				serviceType: "",
				shipDate: ""
			},
			shipper_search_for: "",
			consignee_search_for: "",
			description: "",
			commodity_units: "",
			unit_value: "",
			pickup_conf_num: ""
		});
		this.loadLocationDefaults(false, this.state.warehouse);
	}

	handlePickup() {
		const { serviceType } = this.state.shipmentInfo;
		const { general } = this.state;

		// setup the pickup date
		const startDate = new Date();
		const endDate = new Date();

		startDate.setMinutes(startDate.getMinutes() + 10);

		// Thursday through Sunday need special handling to allow for a non-weekend window
		if (startDate.getDay() >= 4) {
			endDate.setDate(startDate.getDate() + 4);
		} else if (startDate.getDay() < 1) {
			endDate.setDate(startDate.getDate() + 3);
		} else {
			endDate.setDate(startDate.getDate() + 2);
		}

		if (serviceType == "FEDEX_GROUND") {
			this.props.setNotification("FedEx Ground pickup requests must be scheduled at least one day in advance.", { variant: "info" });
			startDate.setDate(startDate.getDate() + 1);
			startDate.setHours(0);
			startDate.setMinutes(0);
		}

		if(general.carrier == 'USPS') {
			window.open('https://tools.usps.com/schedule-pickup-steps.htm', '_blank');
		} else {
			this.setState({
				pickupModal: true,
				pickup_phone: !_.isEmpty(general.shipper.phone) ? general.shipper.phone : "",
				pickup_contact_name: !_.isEmpty(general.shipper.contact) ? general.shipper.contact : "",
				pickup_area: "",
				pickup_description: "",
				special_instruction: "",
				email_notification: !_.isEmpty(general.shipper.email) ? general.shipper.email : "",
				expeditePickupDateTime: moment(startDate).format("LLLL"),
				expediteDockCloseTime: moment(endDate).format("LLLL")
			});
		}

	}

	async schedulePickup() {
		let valid = true;

		const required = ["pickup_phone", "pickup_contact_name", "expeditePickupDateTime", "expediteDockCloseTime"];

		for (const input of required) {
			if (_.isEmpty(this.state[input])) {
				valid = false;
			}
		}

		if (!valid) {
			this.props.setNotification("All required inputs must be entered to schedule a parcel pickup!", { variant: "error" });
			return;
		}

		const data = this.getShipmentData(true);
		const overweight = false;

		const { shipmentId } = this.state.shipmentInfo;
		const readyTime = moment(this.state.expeditePickupDateTime).format("LLLL");
		const closeTime = moment(this.state.expediteDockCloseTime).format("LLLL");

		const resiPickup = this.state.resi_pickup == true ? 1 : 0;
		const phone = this.state.pickup_phone;
		var contactName = !_.isEmpty(this.state.pickup_contact_name) ? this.state.pickup_contact_name : "";
		var pickupArea = !_.isEmpty(this.state.pickup_area) ? this.state.pickup_area : "";
		var pickupAreaDescription = !_.isEmpty(this.state.pickup_description) ? this.state.pickup_description : "";
		var specialInstruction = !_.isEmpty(this.state.special_instructions) ? this.state.special_instructions : "";
		var email = !_.isEmpty(this.state.email_notification) ? this.state.email_notification : "";

		data.shipmentId = shipmentId;
		data.overweight = overweight;
		data.timeFrame = { readyTime, closeTime };
		data.resiPickup = resiPickup;
		data.phone = phone;
		data.contactName = contactName;
		data.pickupArea = pickupArea;
		data.pickupAreaDescription = pickupAreaDescription;
		data.specialInstruction = specialInstruction;
		data.email = email;

		this.setState({ schedulingPickup: true });

		try {
			const url = "/index.php?p=api&r=json&c=parcelShipService&m=schedulePickup";
			const response = await axios.post(url, qs.stringify(data));

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.confirmationNumber)) {
				this.setState({
					schedulingPickup: false,
					pickup_conf_num: response.data.body.confirmationNumber,
					pickupModal: false
				});
			} else {
				this.setState({ schedulingPickup: false });
				this.props.setNotification("There was an error scheduling the pickup!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ schedulingPickup: false });
			this.props.setNotification("There was an error scheduling the pickup!", { variant: "error" });
		}
	}

	getImages(data) {
		return data.map((prop, key) => {
			const src = URL.createObjectURL(prop);
			const filename = !_.isEmpty(prop.name) ? prop.name : prop.filename;
			const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
			return <Preview key={key} src={src} index={key} extension={extension} handleClick={this.handleDeleteImage.bind(this)} handleDelete={this.handleDeleteImage.bind(this)} />;
		});
	}

	handleUpload(files) {
		this.setState({ imageFiles: this.state.imageFiles.concat(files) });
	}

	handleDeleteImage(key) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this image?"
					onConfirm={() => this.deleteImage(key)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	deleteImage(i) {
		const { imageFiles } = this.state;
		imageFiles.splice(i, 1);
		this.setState({ alert: null, imageFiles });
	}

	addDefaultPackage = (pkg) => {

		const { packages, packaging_type} = this.state;

		// If there is only one package, replace it
		if(packages.length == 1) {

			// Not checking for packages[0] in case it was already removed
			let first = packages.shift();

			// If there were values filled out, add it back
			if(first.length != '' || first.width != '' || first.height != '' || first.weight != '') {
				packages.push(first);
			}

		}

		let thisPkg = {
			length: pkg.length,
			width: pkg.width,
			height: pkg.height,
			weight: pkg.weight,
			quantity: 1,
			declaredValue: "",
			hazardous: false,
			hazmat: {
				un: "",
				class: "",
				subHazardClass: "",
				properShippingName: "",
				packingGroup: "",
				packingInstructionCode: "",
				technicalName: "",
				packagingType: "",
				innerContainerType: "",
				regulatoryQuantity: "",
				uom: "",
				quantity: "",
				transportationMode: ""
			}
		};

		if(pkg.type != '') {
			this.setState({packaging_type: pkg.type});
		}

		packages.push(thisPkg);

		this.setState({
			packages,
			parcelRates: [],
			carrier: "",
			service: "",
			service_code: ""
		});

	}

	getImportUnitofMeasure = () => {
		const { classes } = this.props;
		let uom = [
			{
				name: 'Barrel',
				value: 'BA'
			},
			{
				name: 'Bundle',
				value: 'BE'
			},
			{
				name: 'Bag',
				value: 'BG'
			},
			{
				name: 'Bunch',
				value: 'BH'
			},
			{
				name: 'Box',
				value: 'BOX'
			},
			{
				name: 'Bolt',
				value: 'BT'
			},
			{
				name: 'Butt',
				value: 'BU'
			},
			{
				name: 'Canister',
				value: 'CI'
			},
			{
				name: 'Centimeter',
				value: 'CM'
			},
			{
				name: 'Container',
				value: 'CON'
			},
			{
				name: 'Crate',
				value: 'CR'
			},
			{
				name: 'Case',
				value: 'CS'
			},
			{
				name: 'Carton',
				value: 'CT'
			},
			{
				name: 'Cylinder',
				value: 'CY'
			},
			{
				name: 'Dozen',
				value: 'DOZ'
			},
			{
				name: 'Each',
				value: 'EA'
			},
			{
				name: 'Envelope',
				value: 'EN'
			},
			{
				name: 'Feet',
				value: 'Ft'
			},
			{
				name: 'Kilogram',
				value: 'KG'
			},
			{
				name: 'Kilograms',
				value: 'KGS'
			},
			{
				name: 'Pound',
				value: 'LB'
			},
			{
				name: 'Pounds',
				value: 'LBS'
			},
			{
				name: 'Liters',
				value: 'L'
			},
			{
				name: 'Meter',
				value: 'M'
			},
			{
				name: 'Number',
				value: 'NMB'
			},
			{
				name: 'Packet',
				value: 'PA'
			},
			{
				name: 'Pallet',
				value: 'PAL'
			},
			{
				name: 'Piece',
				value: 'PC'
			},
			{
				name: 'Pieces',
				value: 'PCS'
			},
			{
				name: 'Proof Liters',
				value: 'PF'
			},
			{
				name: 'Package',
				value: 'PKG'
			},
			{
				name: 'Pair',
				value: 'PR'
			},
			{
				name: 'Pairs',
				value: 'PRS'
			},
			{
				name: 'Roll',
				value: 'RL'
			},
			{
				name: 'Set',
				value: 'SET'
			},
			{
				name: 'Sq Meters',
				value: 'SME'
			},
			{
				name: 'Sq Yards',
				value: 'SYD'
			},
			{
				name: 'Tube',
				value: 'TU'
			},
			{
				name: 'Yard',
				value: 'YD'
			},
		];
		return uom.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getExportReasons = () => {
		const { classes } = this.props;
		let reasons = [
			{
				name: 'Sale',
				value: 'SALE'
			},
			{
				name: 'Gift',
				value: 'GIFT'
			},
			{
				name: 'Sample',
				value: 'SAMPLE'
			},
			{
				name: 'Return',
				value: 'RETURN'
			},
			{
				name: 'Repair',
				value: 'REPAIR'
			},
			{
				name: 'InterCompany Data',
				value: 'INTERCOMPANYDATA'
			},
		];
		return reasons.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.value}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}


	render() {
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		let prodCatCols = ["Code", "Name", "Length", "Width", "Height", "Weight", "Hazmat", "Actions"]
		if(this.state.enable_product_part_no) {
            prodCatCols.splice(1, 0, "Part #");
        }


		let icon = "";
		let carrier = "";

		if (this.state.carrier === "UPS") {
			carrier = "UPS";
			icon = <FontAwesomeIcon icon={faUps} size="6x" />;
		}

		if (this.state.carrier === "FEDEX") {
			carrier = "FedEx";
			icon = <FontAwesomeIcon icon={faFedex} size="6x" />;
		}

		if (this.state.carrier === 'USPS') {
			carrier = "USPS";
			icon = <FontAwesomeIcon icon={faUsps} size="6x" />;
		}

		if (this.state.scheduled && !_.isEmpty(this.state.general) && !_.isEmpty(this.state.shipmentInfo)) {
			return (
				<GridContainer style={{ marginTop: "15px" }}>
					<HazardousOptionsModal
						title={<span>Please select the proper hazardous option</span>}
						open={this.state.showHazOptions}
						onSubmit={this.handleHazOptionsSubmit}
						onClose={this.closeHazOptions}
						data={this.state.hazInfo}
						transMode={searchObject(this.state.packages, '0.hazmat.transportationMode', '')}
                	/>
					{this.state.alert}
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							<GridItem xs={6} sm={6} md={6} className={classes.left}>
								<Button color="white" size="lg" onClick={this.newQuote}>
									<FastRewind /> Get Another Quote
								</Button>
							</GridItem>
							<GridItem xs={6} sm={6} md={6} className={classes.right}>
								<NavLink to={basePath + "/admin/parcel/archived-shipments"}>
									<Button color="white" size="lg" className={classes.left}>
										Archived Parcel Shipments <FastForward />
									</Button>
								</NavLink>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="success" text>
								<CardText color="success">
									<h4 className={classes.cardTitle}>Your Shipment Has Been Successfully Scheduled!</h4>
								</CardText>
							</CardHeader>
							<CardBody>
								{_.isEmpty(this.state.shipmentInfo.totalCharge) && this.state.show_courtesy && (
									<GridContainer>
										<GridItem xs />
										<GridItem xs={10} sm={8} md={6}>
											<SnackbarContent
												message={this.state.courtesy_message}
												icon={Warning}
												color="warning"
												closeNotification={() => {
													this.setState({ show_courtesy: false });
												}}
												close
											/>
										</GridItem>
										<GridItem xs />
									</GridContainer>
								)}
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<GridContainer>
											<GridItem xs={12} sm={6} md={6}>
												<Paper className={classes.paper} elevation={1}>
													{icon}
													<Typography variant="subtitle2">{"Carrier: " + this.state.shipmentInfo.carrier}</Typography>
													<Typography variant="subtitle2">{"Ship Date: " + moment(this.state.shipmentInfo.shipDate).format("YYYY-MM-DD")}</Typography>
													<Typography variant="subtitle2">{"Billing Type: " + this.state.shipmentInfo.billingType}</Typography>
													<Typography variant="subtitle2">{"Account #: " + this.state.shipmentInfo.acctNo}</Typography>
													{!_.isEmpty(this.state.shipmentInfo.totalCharge) && this.state.shipmentInfo.totalCharge != "0.00" ? <Typography variant="subtitle2">{"Total Charge: $" + parseFloat(this.state.shipmentInfo.totalCharge).toFixed(2)}</Typography> : <Typography variant="subtitle2">Total Charge: N/A</Typography>}
													<Typography variant="subtitle2">{"Master Tracking ID: " + this.state.shipmentInfo.trackingId}</Typography>
													{!_.isEmpty(this.state.pickup_conf_num) && <Typography variant="subtitle2">{"Pickup Confirmation Number: " + this.state.pickup_conf_num}</Typography>}
												</Paper>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12} />
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							<GridItem xs={6} sm={6} md={6} className={classes.left}>
								<Button color="primary" size="lg" onClick={this.printerCheck}>
									View / Print Shipping Labels
								</Button>
							</GridItem>
							{_.isEmpty(this.state.pickup_conf_num) && (
								<GridItem xs={6} sm={6} md={6} className={classes.right}>
									<Button color="success" size="lg" onClick={this.handlePickup}>
										Schedule Pickup
									</Button>
								</GridItem>
							)}
						</GridContainer>
					</GridItem>
					<PrintZplModal
						open={this.state.printLabelModal ? true: false}
						modalName="printLabelModal"
						default_printer_save={this.state.default_printer_save}
						printerType={this.state.shipmentInfo.carrier}
						zpl={this.state.zpl}
						handleChange={this.handleChange}
						handleChecked={this.handleZplChecked}
						handleModalClose={this.handleModalClose}
					/>
					<PrintZplModal
						open={this.state.printDocModal ? true: false}
						modalName="printDocModal"
						default_printer_save={this.state.default_printer_save}
						printerType="documents"
						zpl={this.state.docZpl}
						handleChange={this.handleChange}
						handleChecked={this.handleZplChecked}
						handleModalClose={this.handleModalClose}
					/>
					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.pickupModal}
						TransitionComponent={FadeTransition}
						keepMounted
						onClose={() => this.handleModalClose("pickupModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader} style={{ paddingTop: 12 }}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("pickupModal")}>
								<Close className={classes.modalClose} />
							</Button>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody} style={{ paddingTop: 0, minHeight: 705, maxHeight: 900}}>
							<GridContainer className={classes.left}>
								<GridItem xs={12} sm={12} md={12}>
									{icon}
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs={12} sm={12} md={6} lg={6} className={classes.spacing}>
											<Paper className={classes.paper} elevation={1}>
												<Grid container>
													<Grid item xs={12} sm={12} md={6} lg={6}>
														<h6>
															<strong>Pickup Address</strong>
														</h6>
														<address>
															<div>{this.state.general.shipper.streetLineOne}</div>
															{!_.isEmpty(this.state.general.shipper.streetLineTwo) && <div>{this.state.general.shipper.streetLineTwo}</div>}
															<div>{this.state.general.shipper.city + ", " + this.state.general.shipper.state + " " + this.state.general.shipper.zip}</div>
														</address>
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6}>
														<h6>
															<strong>Payment Method</strong>
														</h6>
														<div>Account #: {this.state.shipmentInfo.acctNo}</div>
													</Grid>
												</Grid>
											</Paper>
										</GridItem>
										<GridItem xs={12} sm={12} md={6} lg={6}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.resi_pickup ? true : false}
														onChange={this.handleChecked("resi_pickup")}
													/>
												}
												classes={{ label: classes.label }}
												label="Residential Address"
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>Phone #</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "pickup_phone",
											value: this.state.pickup_phone || "",
											onChange: this.handleInput("pickup_phone")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>Contact Name</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "pickup_contact_name",
											value: this.state.pickup_contact_name || "",
											onChange: this.handleInput("pickup_contact_name")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>Pickup Area (Optional)</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{
												select: classes.select
											}}
											value={this.state.pickup_area || ""}
											inputProps={{ name: "pickup_area" }}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value=""
											/>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="DEPARTMENT"
											>
												Department
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="BUILDING"
											>
												Building
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="FLOOR"
											>
												Floor
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="ROOM"
											>
												Room
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="SUITE"
											>
												Suite
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>Pickup Area Description</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "pickup_description",
											value: this.state.pickup_description || "",
											onChange: this.handleInput("pickup_description")
										}}
										white
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>When will the shipment be ready for pickup?</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											timeFormat
											onChange={m => this.handleDatetime("expeditePickupDateTime", m)}
											className={classes.required}
											inputProps={{
												readOnly: true,
												id: "expeditePickupDateTime",
												name: "expeditePickupDateTime",
												placeholder: "Select Pickup Date Time"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>What time does the pickup location close?</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											timeFormat
											onChange={m => this.handleDatetime("expediteDockCloseTime", m)}
											className={classes.required}
											inputProps={{
												readOnly: true,
												id: "expediteDockCloseTime",
												name: "expediteDockCloseTime",
												placeholder: "Select Dock Close Time"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>Special Instructions</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "special_instructions",
											value: this.state.special_instructions || "",
											maxLength: "57",
											onChange: this.handleInput("special_instructions")
										}}
										white
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={4} lg={3}>
									<InputLabel className={classes.label}>Email Notification Address</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "email_notification",
											value: this.state.email_notification || "",
											onChange: this.handleInput("email_notification")
										}}
										white
									/>
								</GridItem>
							</GridContainer>
							<DialogActions className={classes.modalFooter}>
								<Button onClick={() => this.handleModalClose("pickupModal")} color="white" className={classes.marginRight}>
									Close
								</Button>
								{this.state.schedulingPickup ? (
									<Button color="primary" style={{ lineHeight: 0 }}>
										<CircularProgress size={16} style={{ color: "white" }} />
									</Button>
								) : (
									<Button color="primary" onClick={() => this.schedulePickup()}>
										Schedule Pickup
									</Button>
								)}
							</DialogActions>
						</DialogContent>
					</Dialog>
				</GridContainer>
			);
		}

		if (this.state.create && !_.isEmpty(this.state.carrier) && !_.isEmpty(this.state.service_code)) {
			return (
				<GridContainer>
					{this.state.alert}
					<GridItem xs={12} sm={12} md={12} className={classes.spacing}>
						<GridContainer>
							<GridItem xs={12} sm={6} md={6}>
								<Paper className={classes.paper} elevation={1}>
									{icon}
									<Typography variant="subtitle2">{"Service Type: " + this.state.service}</Typography>
									<Typography variant="subtitle2">{"Quoted Rate: $" + this.state.quoted_amount}</Typography>
									<Typography variant="subtitle2">{"Delivery: " + this.state.deliveryTime}</Typography>
									<Typography variant="subtitle2">{"# of Packages: " + this.getTotalPackageCount()}</Typography>
									<Typography variant="subtitle2">{"Total Actual Weight: " + this.getTotalWeight() + " lbs."}</Typography>
									<Typography variant="subtitle2">
										{"Special Services: "}
										{this.getSpecialServices()}
									</Typography>
								</Paper>
							</GridItem>
							<GridItem xs={12} sm={6} md={6} className={classes.right}>
								<Button color="white" size="lg" onClick={() => this.setState({ create: false, active: 0 })}>
									<FastRewind /> Return to Quotes
								</Button>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} style={{ marginRight: "15px" }}>
						<GridContainer justify="center">
							<GridItem xs={12} className={classes.center}>
								{this.state.active === 0 ? (
									<Button color="warning" className={classes.marginRight}>
										Shipper
									</Button>
								) : (
									<Button size="sm" className={classes.marginRight} onClick={() => this.setState({ active: 0 })}>
										Shipper
									</Button>
								)}
								{this.state.active === 1 ? (
									<Button color="warning" className={classes.marginRight}>
										Consignee
									</Button>
								) : (
									<Button size="sm" className={classes.marginRight} onClick={() => this.setState({ active: 1 })}>
										Consignee
									</Button>
								)}
								{this.state.active === 2 ? (
									<Button color="warning" className={classes.marginRight}>
										Shipment Details
									</Button>
								) : (
									<Button size="sm" className={classes.marginRight} onClick={() => this.setState({ active: 2 })}>
										Shipment Details
									</Button>
								)}
								<Button size="sm" className={classes.marginRight} onClick={() => this.setState({ showManagePrinters: true })}>
									Manage Printers
								</Button>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						{(this.state.showManagePrinters &&
							<Dialog
								open={this.state.showManagePrinters}
							>
								<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader} style={{ paddingTop: 12 }}>
									<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("showManagePrinters")}>
										<Close className={classes.modalClose} />
									</Button>
									<h3>Manage Printers</h3>
								</DialogTitle>
								<DialogContent id="classic-modal-slide-description">
									<ManagePrinters />
								</DialogContent>
							</Dialog>
						)}
						{this.state.active === 0 && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} sm={12} md={8}>
											<GridContainer>
												<GridItem xs={12}>
													<h5>Shipper</h5>
												</GridItem>
												<GridItem xs={12}>
													<GridContainer>
														<GridItem xs>
															<InputLabel className={classes.label}>Name</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_name"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_name",
																	value: this.state.shipper_name || "",
																	onChange: this.handleInput("shipper_name")
																}}
																required
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>Address</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_address1"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_address1",
																	value: this.state.shipper_address1 || "",
																	onChange: this.handleInput("shipper_address1")
																}}
																required
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>Extended Address</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_address2"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_address2",
																	value: this.state.shipper_address2 || "",
																	onChange: this.handleInput("shipper_address2")
																}}
																white
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem xs={12}>
													<GridContainer>
														<GridItem xs>
															<InputLabel className={classes.label}>Zip</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_shipment_zip"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_shipment_zip",
																	value: this.state.shipper_shipment_zip || "",
																	onChange: this.handleInput("shipper_shipment_zip")
																}}
																required
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>City</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_shipment_city"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_shipment_city",
																	value: this.state.shipper_shipment_city || "",
																	onChange: this.handleInput("shipper_shipment_city")
																}}
																required
															/>
														</GridItem>
														<GridItem xs={2}>
															<InputLabel className={classes.label}>State</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_shipment_state"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_shipment_state",
																	value: this.state.shipper_shipment_state || "",
																	onChange: this.handleInput("shipper_shipment_state")
																}}
																required
															/>
														</GridItem>
														<GridItem xs={2}>
															<InputLabel className={classes.label}>Country</InputLabel>
						                                    <br />
															<CustomInput
																id="shipper_shipment_country"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "shipper_shipment_country",
																	value: this.state.shipper_shipment_country || "",
																	onChange: this.handleInput("shipper_shipment_country")
																}}
																required
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem xs={12}>
													<GridContainer>
														{this.props.hasAddressBookRead && (
															<GridItem xs={12}>
																<ContactSearch
																	classes={classes}
																	searchByField="shipper_search_by"
																	searchByValue={this.state.shipper_search_by}
																	onChangeSearchBy={this.handleChange}
																	searchForField="shipper_search_for"
																	searchForValue={this.state.shipper_search_for || ""}
																	onChangeSearchFor={this.handleInput("shipper_search_for")}
																	onSearch={e => this.handleContactSearch("shipper")}
																/>
															</GridItem>
														)}
													</GridContainer>
													<GridContainer>
														<GridItem xs={12}>
															{this.props.hasAddressBookWrite && <FormControlLabel
																control={
																	<Checkbox
																		checkedIcon={<Check className={classes.checkedIcon} />}
																		icon={<Check className={classes.uncheckedIcon} />}
																		classes={{
																			checked: classes.checked,
																			root: classes.checkRoot
																		}}
																		checked={this.state.shipper_save ? true : false}
																		onChange={this.handleChecked("shipper_save")}
																	/>
																}
																classes={{ label: classes.label }}
																label="Save to Address Book"
															/>}
															<Button size="sm" color="warning" onClick={() => this.clearAddress("shipper")} className={classes.marginRight}>
																Clear
															</Button>
															<Tooltip
																title={
																	<React.Fragment>
																		<Typography color="inherit">Search Address</Typography>
																		{"Search for the street view of this address"}
																	</React.Fragment>
																}
																placement="bottom"
															>
																<span>
																	<Button justIcon round size="sm" color="info" onClick={() => this.handleAddress("shipper")} className={classes.marginRight}>
																		<LocationOn />
																	</Button>
																</span>
															</Tooltip>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={4}>
											<h5>Shipper Contact</h5>
											<GridContainer>
												<GridItem xs={6} sm={6} md={12}>
													<InputLabel className={classes.label}>Name</InputLabel>
				                                    <br />
													<CustomInput
														id="shipper_contact_name"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "shipper_contact_name",
															value: this.state.shipper_contact_name || "",
															onChange: this.handleInput("shipper_contact_name")
														}}
														white
														required
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={12}>
													<InputLabel className={classes.label}>Phone</InputLabel>
				                                    <br />
													<CustomInput
														id="shipper_contact_phone"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "shipper_contact_phone",
															value: this.state.shipper_contact_phone || "",
															onChange: this.handleInput("shipper_contact_phone")
														}}
														required
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={12}>
													<InputLabel className={classes.label}>Email</InputLabel>
				                                    <br />
													<CustomInput
														id="shipper_contact_email"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "shipper_contact_email",
															value: this.state.shipper_contact_email || "",
															onChange: this.handleInput("shipper_contact_email")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === 1 && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} sm={12} md={8}>
											<h5>Consignee</h5>
											<GridContainer>
												<GridItem xs={12}>
													<GridContainer>
														<GridItem xs>
															<InputLabel className={classes.label}>Name</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_name"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "consignee_name",
																	value: this.state.consignee_name || "",
																	onChange: this.handleInput("consignee_name")
																}}
																required
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>Address</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_address1"
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "text",
																	name: "consignee_address1",
																	value: this.state.consignee_address1 || "",
																	onChange: this.handleInput("consignee_address1")
																}}
																required
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>Extended Address</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_address2"
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "text",
																	name: "consignee_address2",
																	value: this.state.consignee_address2 || "",
																	onChange: this.handleInput("consignee_address2")
																}}
																white
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem xs={12}>
													<GridContainer>
														<GridItem xs>
															<InputLabel className={classes.label}>Zip</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_shipment_zip"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "consignee_shipment_zip",
																	value: this.state.consignee_shipment_zip || "",
																	onChange: this.handleInput("consignee_shipment_zip")
																}}
																required
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>City</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_shipment_city"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "consignee_shipment_city",
																	value: this.state.consignee_shipment_city || "",
																	onChange: this.handleInput("consignee_shipment_city")
																}}
																required
															/>
														</GridItem>
														<GridItem xs={2}>
															<InputLabel className={classes.label}>State</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_shipment_state"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "consignee_shipment_state",
																	value: this.state.consignee_shipment_state || "",
																	onChange: this.handleInput("consignee_shipment_state")
																}}
																required
															/>
														</GridItem>
														<GridItem xs={2}>
															<InputLabel className={classes.label}>Country</InputLabel>
						                                    <br />
															<CustomInput
																id="consignee_shipment_country"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "consignee_shipment_country",
																	value: this.state.consignee_shipment_country || "",
																	onChange: this.handleInput("consignee_shipment_country")
																}}
																required
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
											<GridContainer>
												{this.props.hasAddressBookRead && (
													<GridItem xs={12}>
														<ContactSearch
															classes={classes}
															searchByField="consignee_search_by"
															searchByValue={this.state.consignee_search_by}
															onChangeSearchBy={this.handleChange}
															searchForField="consignee_search_for"
															searchForValue={this.state.consignee_search_for || ""}
															onChangeSearchFor={this.handleInput("consignee_search_for")}
															onSearch={e => this.handleContactSearch("consignee")}
														/>
													</GridItem>
												)}
											</GridContainer>
											<GridContainer>
												<GridItem xs={12}>
													{this.props.hasAddressBookWrite && <FormControlLabel
														control={
															<Checkbox
																tabIndex={-1}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
																checked={this.state.consignee_save ? true : false}
																onChange={this.handleChecked("consignee_save")}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Save to Address Book"
													/>}
													<Button size="sm" color="warning" onClick={() => this.clearAddress("consignee")} className={classes.marginRight}>
														Clear
													</Button>
													<Tooltip
														title={
															<React.Fragment>
																<Typography color="inherit">Search Address</Typography>
																{"Search for the street view of this address"}
															</React.Fragment>
														}
														placement="bottom"
													>
														<span>
															<Button justIcon round size="sm" color="info" onClick={() => this.handleAddress("consignee")} className={classes.marginRight}>
																<LocationOn />
															</Button>
														</span>
													</Tooltip>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={4}>
											<h5>Consignee Contact</h5>
											<GridContainer>
												<GridItem xs={6} sm={6} md={12}>
													<InputLabel className={classes.label}>Name</InputLabel>
				                                    <br />
													<CustomInput
														id="consignee_contact_name"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "consignee_contact_name",
															value: this.state.consignee_contact_name || "",
															onChange: this.handleInput("consignee_contact_name")
														}}
														white
														required={(this.state.carrier == "UPS" && (this.state.service_code == 14 || this.state.consignee_country != this.state.shipper_country)) || this.state.required.includes('consignee_contact_name')}
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={12}>
													<InputLabel className={classes.label}>Phone</InputLabel>
				                                    <br />
													<CustomInput
														id="consignee_contact_phone"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "consignee_contact_phone",
															value: this.state.consignee_contact_phone || "",
															onChange: this.handleInput("consignee_contact_phone")
														}}
														required
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={12}>
													<InputLabel className={classes.label}>Email</InputLabel>
				                                    <br />
													<CustomInput
														success={this.state.consignee_contact_email_state === "success"}
														error={this.state.consignee_contact_email_state === "error"}
														id="consignee_contact_email"
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "consignee_contact_email",
															value: this.state.consignee_contact_email || "",
															onChange: this.handleInput("consignee_contact_email")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
						{this.state.active === 2 && (
							<Card>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<h5>References</h5>
											<GridContainer>
												<GridItem xs={6} sm={6} md={3} lg={3}>
													<InputLabel className={classes.label}>Order/SO #</InputLabel>
													<br />
													<CustomInput
														id="so_num"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "so_num",
															placeholder: "",
															value: this.state.so_num || "",
															onChange: this.handleInput("so_num")
														}}
														white
														required={this.state.required.includes("so_num")}
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={3} lg={3}>
													<InputLabel className={classes.label}>PO #</InputLabel>
													<br />
													<CustomInput
														id="po_num"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "po_num",
															placeholder: "",
															value: this.state.po_num || "",
															onChange: this.handleInput("po_num")
														}}
														white
														required={this.state.required.includes("po_num")}
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={3} lg={3}>
													<InputLabel className={classes.label}>Department</InputLabel>
													<WithTooltip
															content="For any UPS shipments or FedEx hazmat shipments, this field will be omitted from labels if more than 2 other references are provided."
													>
														<small>
															<span>
																<Warning style={{ height: "16px" }} />
															</span>
														</small>
													</WithTooltip>
													<CustomInput
														id="dept_num"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "dept_num",
															placeholder: "",
															value: this.state.dept_num || "",
															onChange: this.handleInput("dept_num")
														}}
														white
													/>
												</GridItem>
												<GridItem xs={6} sm={6} md={3} lg={3}>
													<InputLabel className={classes.label}>Reference #</InputLabel>
													<br />
													<CustomInput
														id="ref_num"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "ref_num",
															placeholder: "",
															value: this.state.ref_num || "",
															onChange: this.handleInput("ref_num")
														}}
														white
														required={this.state.required.includes("ref_num")}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
											<ReferenceList
												refTypeUrl={'/index.php?p=api&r=json&c=userSettings&m=getReferenceTypes'}
												data={this.state.references}
												onUpdate={this.handleReference}
											/>
										</GridItem>
										<GridItem xs={1} sm={1} md={1} lg={1}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.is_inbound ? true : false}
														onChange={this.handleChecked("is_inbound")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={"Is this an inbound shipment?"}
											/>
										</GridItem>
										<GridItem xs={6} sm={6} md={4} lg={4}>
											<InputLabel className={classes.label}>Billing Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.billing_type || ""}
													inputProps={{ name: "billing_type" }}
													onChange={this.handleChange}
													disabled={carrier == "USPS"}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="account"
													>

														Account - {this.state.account}
													</MenuItem>
													{(this.state.is_inbound ? (
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="shipper"
														>
															Shipper
														</MenuItem>
													) : (
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="recipient"
														>
															Recipient
														</MenuItem>
													))}
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="third_party"
													>
														Third Party
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										{(this.state.billing_type == 'third_party' || this.state.billing_type == 'recipient' || this.state.billing_type == 'shipper') && (
											<React.Fragment>
												<GridItem xs={3}>
													<InputLabel className={classes.label}>Account #</InputLabel>
													<br />
													<CustomInput
													id="billing_account"
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
															name: "billing_account",
															placeholder: "",
															value: this.state.billing_account || "",
															onChange: this.handleInput("billing_account")
														}}
														required
													/>
												</GridItem>
												<GridItem xs={3}>
													<InputLabel className={classes.label}>Zip Code</InputLabel>
													<br />
													<CustomInput
													id="billing_zip"
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
															name: "billing_zip",
															placeholder: "",
															value: this.state.billing_zip || "",
															onChange: this.handleInput("billing_zip")
														}}
														helpText="Must match billing account's zip code"
														required
													/>
												</GridItem>
											</React.Fragment>
										)}
										{this.state.cod && (
											<GridItem xs={6} sm={6} md={4} lg={4}>
												<InputLabel className={classes.label}>C.O.D. Amount</InputLabel>
			                                    <br />
												<CustomInput
													id="cod_amount"
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
														name: "cod_amount",
														placeholder: "",
														value: this.state.cod_amount || "",
														onChange: this.handleInput("cod_amount")
													}}
													required
												/>
											</GridItem>
										)}
										{this.state.cod && (
											<GridItem xs={6} sm={6} md={4} lg={4}>
												<InputLabel className={classes.label}>C.O.D. Collection Type</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{ className: classes.selectMenu }}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.cod_collection_type || ""}
														inputProps={{ name: "cod_collection_type" }}
														onChange={this.handleChange}
													>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="ANY"
														>
															Any (Unsecured Payment)
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="GUARANTEED_FUNDS"
														>
															Guaranteed Funds (Secured Payment)
														</MenuItem>
													</Select>
												</FormControl>
											</GridItem>
										)}
										<GridItem xs={6} sm={6} md={4} lg={4}>
											<InputLabel className={classes.label}>Label Type</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.label_type || ""}
													inputProps={{ name: "label_type" }}
													onChange={this.handleChange}
													>
													{carrier != 'USPS' && (
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="STOCK"
														>
															Label Stock (PDF)
														</MenuItem>
													)}
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="PAPER"
													>
														Plain Paper
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="ZPL"
													>
														ZPL (Zebra Thermal Printer)
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={6} sm={6} md={4} lg={4}>
											<InputLabel className={classes.label}>Label Size</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.label_size || "4X6"}
													inputProps={{ name: "label_size" }}
													onChange={this.handleChange}
												>
													{this.getLabelSizes()}
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={3} sm={3} md={2} lg={2}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.default_label_save ? true : false}
														onChange={this.handleChecked("default_label_save")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label={"Make This My Default Label Type For " + carrier}
											/>
										</GridItem>
										{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_costco_label == 1 && ( // TTMS-1965
											<GridItem xs={3} sm={3} md={2} lg={2}>
												<FormControlLabel
													control={
														<Checkbox
															tabIndex={-1}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot
															}}
															checked={this.state.include_costco_label ? true : false}
															onChange={this.handleChecked("include_costco_label")}
														/>
													}
													classes={{
														label: classes.label
													}}
													label={"Print Costco Wholesale Shipping Label"}
												/>
											</GridItem>
										)}
										<GridItem xs={6} sm={6} md={4} lg={4}>
											<InputLabel className={classes.label}>Advance Shipment Notifications</InputLabel>
											<br />
											<CustomInput
												id="asn_email"
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "asn_email",
													placeholder: "",
													value: this.state.asn_email || "",
													onChange: this.handleInput("asn_email")
												}}
												helpText="Enter up to 5 email addresses seperated by commas (optional)"
												white
											/>
										</GridItem>
										{this.state.consignee_shipment_country != this.state.shipper_shipment_country &&
										 !_.isEmpty(this.state.consignee_shipment_country) &&
										 !_.isEmpty(this.state.shipper_shipment_country) && (
											 <React.Fragment>
												<GridItem xs={6} sm={6} md={4} lg={4}>
													<InputLabel className={classes.label}>Description</InputLabel>
													<br />
													<CustomInput
														id="description"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "description",
															placeholder: "",
															value: this.state.customs.description || "",
															onChange: this.handleInput("description")
														}}
														helpText="Description of commodity being shipped"
														white
														required
													/>
												</GridItem>
												{this.state.carrier == 'FEDEX' && (
													<React.Fragment>
														<GridItem xs={6} sm={6} md={4} lg={4}>
															<InputLabel className={classes.label}>Customs Value</InputLabel>
															<br />
															<CustomInput
																id="customs_value"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "customs_value",
																	placeholder: "",
																	value: this.state.customs.customs_value || "",
																	onChange: this.handleInput("customs_value")
																}}
																helpText="Value for Customs"
																white
																required
															/>
														</GridItem>
														<GridItem xs={2}>
															<InputLabel className={classes.label}># of Units</InputLabel>
															<CustomInput
																id="commodity_units"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "commodity_units",
																	placeholder: "",
																	value: this.state.customs.commodity_units || "",
																	onChange: this.handleInput("commodity_units")
																}}
																required
															/>
														</GridItem>
														<GridItem xs={3}>
															<InputLabel className={classes.label}>Unit Value</InputLabel>
															<CustomInput
																id="unit_value"
																formControlProps={{ fullWidth: true }}
																inputProps={{
																	type: "text",
																	name: "unit_value",
																	placeholder: "",
																	value: this.state.customs.unit_value || "",
																	startAdornment: <InputAdornment position="start">$</InputAdornment>,
																	onChange: this.handleInput("unit_value")
																}}
																required
															/>
														</GridItem>
													</React.Fragment>
												)}
												{this.state.carrier == "UPS" && this.state.is_inbound && this.state.consignee_country != this.state.shipper_country && (
												<React.Fragment>
													<GridItem xs={3}>
														<InputLabel className={classes.label}>Part #</InputLabel>
														<CustomInput
															id="part_number"
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "part_number",
																placeholder: "",
																value: this.state.customs.part_number || "",
																	onChange: this.handleInput("part_number")
															}}
															required
														/>
													</GridItem>
													<GridItem xs={3}>
														<InputLabel className={classes.label}>Origin Country</InputLabel>
														<CustomInput
															id="origin_country"
															formControlProps={{ fullWidth: true }}
															inputProps={{
																type: "text",
																name: "origin_country",
																placeholder: "",
																value: this.state.customs.origin_country || "",
																onChange: this.handleInput("origin_country")
															}}
															required
														/>
													</GridItem>
													</React.Fragment>
												)}

											</React.Fragment>
										)}
										{this.state.carrier == "UPS" && this.state.is_inbound && this.state.consignee_country != this.state.shipper_country && (
											<React.Fragment>
												<GridItem xs={3}>
													<InputLabel className={classes.label}># of Units</InputLabel>
													<CustomInput
														id="commodity_units"
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "commodity_units",
															placeholder: "",
															value: this.state.customs.commodity_units || "",
															onChange: this.handleInput("commodity_units")
														}}
														required
													/>
												</GridItem>
												<GridItem>
													<InputLabel className={classes.label}>Units of Measure</InputLabel>
													<br />
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.customs.import_uom}
														inputProps={{
															name: "import_uom",
															id: "import_uom"
														}}
														onChange={this.handleChange}
													>
														{this.getImportUnitofMeasure()}
													</Select>
												</GridItem>
												<GridItem>
													<InputLabel className={classes.label}>Export Reason</InputLabel>
													<br />
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.customs.export_reason}
														inputProps={{
															name: "export_reason",
															id: "export_reason"
														}}
														onChange={this.handleChange}
													>
														{this.getExportReasons()}
													</Select>
												</GridItem>
											</React.Fragment>
										)}
										<GridItem xs={12} sm={12} md={12} lg={12}>
											<GridContainer>
												<GridItem xs={12} sm={12} md={6}>
													<Dropzone handleUpload={this.handleUpload} />
												</GridItem>
												<GridItem xs={12} sm={12} md={6}>
													<aside className={classes.thumbsContainer}>{!_.isEmpty(this.state.imageFiles) && this.getImages(this.state.imageFiles)}</aside>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} className={classes.right}>
											{this.state.schedulingShipment ? (
												<Button color="linkedin" size="lg" style={{ lineHeight: 0 }}>
													<CircularProgress size={16} style={{ color: "white" }} />
												</Button>
											) : (
												<Button color="linkedin" size="lg" onClick={this.scheduleShipment}>
													Schedule Shipment
												</Button>
											)}
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						)}
					</GridItem>
					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.contactSearchModal}
						TransitionComponent={SlideTransition}
						keepMounted
						onClose={() => this.handleModalClose("contactSearchModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
							<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("contactSearchModal")}>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.modalTitle}>Found Multiple Contacts</h4>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
							{!_.isEmpty(this.state.contacts) && <Table tableHead={["Code", "Address", "Contact", "Actions"]} tableData={this.getContacts(this.state.contacts)} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} />}
						</DialogContent>
						<DialogActions className={classes.modalFooter}>
							<Button onClick={() => this.handleModalClose("contactSearchModal")} color="white">
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</GridContainer>
			);
		}

		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={10} s={6}>
									<h5 style={{ fontWeight: "bold", marginBottom: 0 }}>{"Shipment Details"}</h5>
								</GridItem>
								<GridItem xs={2} s={6}>
									<Box display="flex" justifyContent="flex-end">
										<Button size="sm" color="linkedin" onClick={() => this.handleModalOpen("residentialCheckModal")}>
											<Home /> Check Address
										</Button>
									</Box>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={4}>
									<InputLabel htmlFor="warehouse" className={classes.label}>
										Warehouse/Facility
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.warehouse || ""}
											inputProps={{
												name: "warehouse",
												id: "warehouse"
											}}
											onChange={this.handleChange}
										>
											{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.warehouses) ? (
												this.getWarehouses(this.state.body.warehouses)
											) : (
												<MenuItem disabled classes={{ root: classes.selectMenuItem }}>
													No Warehouses
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={4}>
									<InputLabel htmlFor="pickup_dropoff_type" className={classes.label}>
										Pickup / Drop Off Type
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.dropoff}
											inputProps={{
												name: "dropoff",
												id: "dropoff"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="REGULAR_PICKUP"
											>
												Use an already scheduled pickup
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="REQUEST_COURIER"
											>
												Pickup needs scheduled
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="DROP_BOX"
											>
												Drop off at carrier location
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={4}>
									<InputLabel htmlFor="packaging_type" className={classes.label}>
										Packaging Type
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.packaging_type}
											inputProps={{
												name: "packaging_type",
												id: "packaging_type"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="BOX"
											>
												Customer Packaging
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="ENVELOPE"
											>
												Envelope
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="PAK"
											>
												Pak
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="TUBE"
											>
												Tube
											</MenuItem>
											{this.getPackagingTypes()}
										</Select>
									</FormControl>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={4}>
									<InputLabel htmlFor="shipper_zip" className={classes.label}>
										Shipper Zip
									</InputLabel>
									<br />
									<CustomInput
										id="shipper_zip"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "shipper_zip",
											placeholder: "",
											value: this.state.shipper_zip,
											onChange: this.handleInput("shipper_zip")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={4}>
									<InputLabel htmlFor="shipper_state" className={classes.label}>
										Shipper State
									</InputLabel>
									<br />
									<CustomInput
										id="shipper_state"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "shipper_state",
											placeholder: "",
											value: this.state.shipper_state,
											onChange: this.handleInput("shipper_state")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={4}>
									<InputLabel htmlFor="shipper_country" className={classes.label}>
										Shipper Country
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.shipper_country}
											inputProps={{
												name: "shipper_country",
												id: "shipper_country"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="US"
											>
												US
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="CA"
											>
												CA
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="MX"
											>
												MX
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={4}>
									<InputLabel htmlFor="consignee_zip" className={classes.label}>
										Consignee Zip
									</InputLabel>
									<br />
									<CustomInput
										id="consignee_zip"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "consignee_zip",
											placeholder: "",
											value: this.state.consignee_zip,
											onChange: this.handleInput("consignee_zip")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={4}>
									<InputLabel htmlFor="consignee_state" className={classes.label}>
										Consignee State
									</InputLabel>
									<br />
									<CustomInput
										id="consignee_state"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "consignee_state",
											placeholder: "",
											value: this.state.consignee_state,
											onChange: this.handleInput("consignee_state")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={4}>
									<InputLabel htmlFor="consignee_country" className={classes.label}>
										Consignee Country
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.consignee_country}
											inputProps={{
												name: "consignee_country",
												id: "consignee_country"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="US"
											>
												US
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="CA"
											>
												CA
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="MX"
											>
												MX
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.hazmat && (
										<React.Fragment>
											<GridItem xs={4}>
												<InputLabel htmlFor="regulation_set" className={classes.label}>
													HazMat Regulation Set
												</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.regulation_set}
														inputProps={{
															name: "regulation_set",
															id: "regulation_set"
														}}
														onChange={this.handleChange}
													>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="iata"
														>
															IATA
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="dot"
														>
															DOT
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="adr"
														>
															ADR
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="ormd"
														>
															ORMD
														</MenuItem>
														<MenuItem
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected
															}}
															value="cfr"
														>
															49CFR
														</MenuItem>
													</Select>
												</FormControl>
											</GridItem>
											<GridItem xs={4}>
												<InputLabel className={classes.label}>Carrier</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.hazCarrier}
														inputProps={{
															name: "hazCarrier",
															id: "hazCarrier"
														}}
														style={{paddingTop: 0, paddingBottom: 0}}
														onChange={this.handleChange}
													>
														{this.getCarriers(this.state.hazCarriers)}
													</Select>
												</FormControl>
											</GridItem>
											<GridItem xs={4}>
												<InputLabel className={classes.label}>Service Type</InputLabel>
												<br />
												<FormControl fullWidth className={classes.selectFormControl}>
													<Select
														MenuProps={{
															className: classes.selectMenu
														}}
														classes={{
															select: classes.select + " " + classes.requiredSelect
														}}
														value={this.state.hazService}
														inputProps={{
															name: "hazService",
															id: "hazService"
														}}
														onChange={this.handleChange}
													>
														{this.getServices()}
													</Select>
												</FormControl>
											</GridItem>
										</React.Fragment>
									)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									<h5 style={{fontWeight: "bold", marginBottom: 0}}>{"Package Details"}</h5>
								</GridItem>
								<GridItem xs={9} sm={9} md={9}>
									<GridContainer>
										<GridItem style={{paddingRight: 0}}>
											{this.state.hazmat ? (
												<WithTooltip
													content="Multi-package Hazmat/Dangerous Goods Shipments are unsupported"
												>
													<span>
														<Button size="sm" color="linkedin" disabled>
															<AddCircle /> Add Package
														</Button><br/>
													</span>
												</WithTooltip>
											) : (
												<Button size="sm" color="linkedin" onClick={this.addPackage}>
													<AddCircle /> Add Package
												</Button>
											)}
										</GridItem>
										{!_.isEmpty(this.state.selected_warehouse) && (
											<GridItem style={{paddingRight: 0}}>
												<ParcelPackageButton
													parentCallback={this.addDefaultPackage}
													warehouseCode={this.state.selected_warehouse}
													btnText="Choose Package"
													disabled={this.state.hazmat}
												/>
											</GridItem>
										)}
										<GridItem>
											<InputLabel className={classes.label}>
												Package Units
											</InputLabel><br/>
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.standard_units}
													inputProps={{
														name: "standard_units",
														id: "standard_units"
													}}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value={true}
													>
														Standard (in./lbs.)
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value={false}
													>
														Metric (cm./kgs.)
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
									</GridContainer>
								</GridItem>
								{this.props.weightLimit < Infinity && (
									<GridItem xs={12} style={{marginBottom: "0px"}}>
										<div style={{
											padding: "5px 15px",
											border: "1px #333 dotted"
										}}>
											<b>
												{`The maximum weight allowed for parcel shipping in your organization is ${this.props.weightLimit} lbs.`}
												{this.props.canCreateBol && (
													<span>
														{` For shipments greater than ${this.props.weightLimit} lbs, please `}
															<NavLink to={basePath + "/admin/bol/new"}>
																{"create a bill of lading"}
															</NavLink>
														{' for an LTL shipment.'}
													</span>
												)}
											</b>
										</div>
									</GridItem>
								)}
								{!!this.state.packages.length && (
									<GridItem xs={12} sm={12} md={12} className={classes.unitContainer} style={{marginTop:"0px"}}>
										{this.getPackages(this.state.packages)}
									</GridItem>
								)}
							</GridContainer>
							{this.state.show_notice && (
								<GridContainer>
									<GridItem xs />
									<GridItem xs={10} sm={8} md={6}>
										<SnackbarContent message={this.state.notice_message} icon={Warning} color="warning" closeNotification={this.handleClose} close />
									</GridItem>
									<GridItem xs />
								</GridContainer>
							)}
							<GridContainer>
								<GridItem xs={6} sm={4} md={3}>
									<Paper className={classes.paper} elevation={1}>
										<Typography variant="subtitle2">{"Total Packages: " + this.getTotalPackageCount()}</Typography>
										<Typography variant="subtitle2">{"Total Weight: " + this.getTotalWeight() + " lbs."}</Typography>
									</Paper>
								</GridItem>
								<GridItem xs={6} sm={5} md={5}>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.residential}
												onChange={this.handleChecked("residential")}
											/>
										}
										classes={{
											label: classes.label
										}}
										label="Residential Delivery"
									/>
									<WithTooltip
										title="Collect On Delivery"
										content="Collect On Delivery is no longer a supported service option for FedEx. Requesting this option will exclude rates from FedEx"
									>
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.cod}
													onChange={this.handleChecked("cod")}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="C.O.D."
										/>
									</WithTooltip>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.signature}
												onChange={this.handleChecked("signature")}
											/>
										}
										classes={{ label: classes.label }}
										label="Require Signature"
									/>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.saturdayDelivery}
												onChange={this.handleChecked("saturdayDelivery")}
											/>
										}
										classes={{ label: classes.label }}
										label="Saturday Delivery"
									/>

									<WithTooltip
										title="Carbon Neutral"
										content="Carbon Neutral is only a supported service option for UPS. Requesting this option will prevent rates from other carriers"
									>
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.carbonNeutral}
													onChange={this.handleChecked("carbonNeutral")}
												/>
											}
											classes={{ label: classes.label }}
											label="Carbon Neutral"
										/>
									</WithTooltip>
									{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.company_id == 1069 && ( // TTMS-1965
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.ground_hazmat ? true : false}
													onChange={this.handleChecked("ground_hazmat")}
												/>
											}
											classes={{ label: classes.label }}
											label="Ground Hazmat"
										/>
									)}
								</GridItem>
								<GridItem xs={6} sm={3} md={4}>
									<InputLabel className={classes.label}>Shipping Date (leave blank for current date)</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											timeFormat={false}
											onChange={m => this.handleDatetime("ship_date", m)}
											className={classes.datetime}
											inputProps={{
												readOnly: true,
												id: "ship_date",
												name: "ship_date",
												placeholder: "Select Shipping Date"
											}}
										/>
									</FormControl>
								</GridItem>
								{this.state.signature && (
									<>
										<GridItem xs={6} sm={3} md={4}>
											<WithTooltip
												title="Signature Type"
												content="Note: Indirect Signature is no longer supported by UPS."
											>
												<span>
													<InputLabel className={classes.label}>Signature Type</InputLabel>
												</span>
											</WithTooltip>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.signature_type || ""}
													inputProps={{ name: "signature_type" }}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="INDIRECT"
													>
														Indirect Signature Required
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="DIRECT"
													>
														Direct Signature Required
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="ADULT"
													>
														Adult Signature Required
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										{this.state.signature_type == "INDIRECT" && 
											<GridItem xs={6} sm={3} md={4}>
												<SnackbarContent message="UPS does not support an indirect signature and their rates will not be returned." icon={Info} color="warning"/>
											</GridItem>
										}
									</>
								)}
								<GridItem xs={12}>
									<Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.getParcelRates} disabled={(this.state.loadingParcelRates || this.exceedsWeightLimit())}>
										Get a Quote
									</Button>
								</GridItem>
							</GridContainer>
							{this.state.rated && (
								<GridContainer>
									{this.state.showParcelRates ? (
										<GridItem xs={12}>{this.state.parcelRates && !!this.state.parcelRates.length ? <NavPills color="warning" tabs={this.getTabs(this.state.parcelRates)} /> : null}</GridItem>
									) : (
										<GridItem xs={12}>
											<Spinner loading={this.state.loadingParcelRates} message="Failed to retrieve parcel rates from the server" />
										</GridItem>
									)}
								</GridContainer>
							)}
						</CardBody>
					</Card>
				</GridItem>
				{!_.isEmpty(this.state.carrier) && !_.isEmpty(this.state.service_code) && (
					<GridItem xs={12} className={classes.center}>
						{/* @todo: when support for shipping from CA/MEX, remove the above '&& this.state.shipper_country == 'US'*/}
						<Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.createShipment}>
							Create Shipment
						</Button>
						<div ref={el => (this.ref = el)} />
					</GridItem>
				)}
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.residentialCheckModal}
					TransitionComponent={SlideTransition}
					keepMounted
					onClose={() => this.handleModalClose("residentialCheckModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("residentialCheckModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Check For Residential Address</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody + " " + classes.left}>
						{this.state.show_resi_notice && (
							<GridContainer>
								<GridItem style={{ textAlign: "center" }}>
									<SnackbarContent message={this.state.resi_notice_message} icon={Warning} color="warning" />
								</GridItem>
								<GridItem xs />
							</GridContainer>
						)}
						<GridItem xs>
							<InputLabel className={classes.label}>Address</InputLabel>
                            <br />
							<CustomInput
								id="resi_street_line_1"
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "resi_street_line_1",
									placeholder: "",
									value: this.state.resi_street_line_1,
									onChange: this.handleInput("resi_street_line_1"),
									onKeyPress: this.resiEnterPressed.bind(this)
								}}
								required
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Extended Address</InputLabel>
                            <br />
							<CustomInput
								id="resi_street_line_2"
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "resi_street_line_2",
									placeholder: "",
									value: this.state.resi_street_line_2,
									onChange: this.handleInput("resi_street_line_2"),
									onKeyPress: this.resiEnterPressed.bind(this)
								}}
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>City</InputLabel>
                            <br />
							<CustomInput
								id="resi_city"
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "resi_city",
									placeholder: "",
									value: this.state.resi_city,
									onChange: this.handleInput("resi_city"),
									onKeyPress: this.resiEnterPressed.bind(this)
								}}
								required
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Zip</InputLabel>
                            <br />
							<CustomInput
								id="resi_zip"
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									type: "text",
									name: "resi_zip",
									placeholder: "",
									value: this.state.consignee_zip,
									onChange: this.handleInput("consignee_zip"),
									onKeyPress: this.resiEnterPressed.bind(this)
								}}
								required
							/>
						</GridItem>
						<GridItem xs>
							<InputLabel className={classes.label}>Country</InputLabel>
							<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									MenuProps={{ className: classes.selectMenu }}
									value={this.state.consignee_country || "US"}
									onChange={this.handleInput("consignee_country")}
								>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="US"
									>
										US
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="CA"
									>
										CA
									</MenuItem>
									<MenuItem
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value="MX"
									>
										MX
									</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("residentialCheckModal")} color="white" style={{ marginRight: "4px" }} disabled={this.state.loadingResidentialCheck}>
							Cancel
						</Button>
						<Button color="linkedin" style={{ marginRight: "4px" }} onClick={() => this.checkForResi()} disabled={this.state.loadingResidentialCheck}>
							Check Address
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.rateBreakdownModal}
					TransitionComponent={SlideTransition}
					keepMounted
					onClose={() => this.handleModalClose("rateBreakdownModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("rateBreakdownModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Rate Detail - {this.state.serviceInfo}</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody + " " + classes.left}>
						{!_.isEmpty(this.state.estOrGuarInfo) && !_.isEmpty(this.state.deliveryTimeInfo) ? this.getDeliveryInfo(this.state.estOrGuarInfo, this.state.deliveryTimeInfo) : null}
						<strong>Rate Zone:</strong> {this.state.rateZoneInfo} <br />
						{!_.isEmpty(this.state.surchargesInfo) ? this.getSurcharges(this.state.surchargesInfo) : null}
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("rateBreakdownModal")} color="white" style={{ marginRight: "4px" }}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<ProductSearchModal
                	open={this.state.productSearchModal}
                	onClose={() => this.handleModalClose("productSearchModal")}
                	products={this.state.products}
                	getProducts={() => this.getProducts(this.state.products)}
                	categoryColumns={prodCatCols}
                />
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        openAlertModal,
        setJSPM,
        setAvailablePrinters,
        jspmStatus,
        setUpPrinters,
		autoPrint,
		printDocument,
    }, dispatch);
}


const mapStateToProps = state => {
    return {
    	canCreateBol: hasPermission("USE_BILL_OF_LADING")(state),
        hasAddressBookRead: hasPermission("ADDRESS_BOOK_READ")(state),
        hasAddressBookWrite: hasPermission("ADDRESS_BOOK_WRITE")(state),
        hideParcelRateDetails: !!(+getUserSetting('hide_parcel_rate_details', 0)(state)),
        weightLimit: parcelWeightLimit(state),
        printers: state.PrintZplModal.printers,
        JSPM: state.PrintZplModal.JSPM,
        JSPM: state.PrintZplModal.JSPM,
        zplDocTypes: state.PrintZplModal.zplDocTypes,
        dgDocTypes: state.PrintZplModal.dgDocTypes,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(ParcelQuote)));
