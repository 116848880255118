import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { FaAsterisk } from "react-icons/fa";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";
import { deleteRateDisplay, loadCollection } from "../../RateDisplays/actions/RateDisplay.jsx";

class RateDisplays extends React.Component {
	componentDidMount() {
		this.props.loadCollection();
	}

	getHeaders = () => {
		return [
			"ID",
			"Name",
			"Rated Amount",
			"True Cost",
			"True Cost w/o User Markup",
			"Benchmark",
			"Fuel",
			"Accessorials",
			"Discount",
			"Min",
			"Gross",
			"Only Show Chosen Carrier",
			"Transit Calendar Days",
			"Actions"
		];
	}

	getButtons = id => {
		return (
			<div className="actions-right">
				<NavLink to={basePath + "/admin/rate-display/" + id}>
					<Button
						size="sm"
						color="info"
						style={{
							marginRight: "4px"
						}}
					>
						View/Edit
					</Button>
				</NavLink>
				<Button
					size="sm"
					color="danger"
					onClick={() => this.deleteRateDisplay(id)}
					style={{
						marginRight: "4px"
					}}
				>
					Delete
				</Button>
			</div>
		);
	}

	getColumns = () => {
		return this.props.collection.map(prop => {
			return [
				prop.id,
				prop.name,
				prop.rated == 1 ? <FaAsterisk /> : "",
				prop.true_cost == 1 ? <FaAsterisk /> : "",
				prop.true_cost_minus_markup == 1 ? <FaAsterisk /> : "",
				prop.benchmark == 1 ? <FaAsterisk /> : "",
				prop.fuel == 1 ? <FaAsterisk /> : "",
				prop.accessorials == 1 ? <FaAsterisk /> : "",
				prop.discount == 1 ? <FaAsterisk /> : "",
				prop.min == 1 ? <FaAsterisk /> : "",
				prop.gross == 1 ? <FaAsterisk /> : "",
				prop.only_show_chosen_carrier == 1 ? <FaAsterisk /> : "",
				prop.transit_calendar_days == 1 ? <FaAsterisk /> : "",
				this.getButtons(prop.id),
			];
		});
	}

	deleteRateDisplay = id => {
		this.props.deleteRateDisplay(id);
	}

	getContent = () => {
		const { classes } = this.props;

		if (this.props.loading) {
			return <Spinner loading={true}/>;
		}

		if (!this.props.collection.length) {
			return <center><span>No rate displays available.</span></center>
		}

		return (
			<Table
				tableHead={this.getHeaders()}
				tableData={this.getColumns()}
				customCellClasses={[
					classes.right
				]}
				customClassesForCells={[10]}
				customHeadCellClasses={[
					classes.right
				]}
				customHeadClassesForCells={[10]}
			/>
		);
	}

	render() {

		const link = basePath + "/admin/rate-display/new";
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											style={{
												marginRight: "4px"
											}}
										>
											New Rate Display
										</Button>
									</NavLink>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									{this.getContent()}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
    	loading: state.RateDisplay.loading,
    	collection: state.RateDisplay.collection || [],
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	deleteRateDisplay,
    	loadCollection,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(archivedBillsOfLadingStyle)(RateDisplays));