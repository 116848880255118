import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import qs from "qs";

import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import Check from "@material-ui/icons/Check";
import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import SnackbarContent from "~/components/Snackbar/SnackbarContent.jsx";
import WarehouseSelector from "~/components/TMS/WarehouseSelector.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class viewAutoCalcAccessorial extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			alert: null,
			loading: true,
			user: null,
			body: null,
			autoCalcAcc: {
				id: "",
				name: "",
				startDate: "",
				endDate: "",
				charge: "",
				chargeType: "",
				minCharge: "",
				maxCharge: "",
				customBmCharge: false,
				bmCharge: "",
				bmChargeType: "",
				bmMinCharge: "",
				bmMaxCharge: "",
				carrier: {
					id: "",
					scac: "",
					name: "",
				},
				accessorial: {
					id: "",
					code: "",
					name: "",
				},
				assignedWarehouses: [],
			},
			criteria: [],
			configure_bm: false,
			notice_message: "Any changes that you make to this auto-calculated accessorial after its initial configuration can potentially affect historical rates. Be sure that you understand the effects of editing this accessorial before saving. Unless there is an error in the configuration, it is best not to edit the charge, effective dates, or criteria.",
			saving: false,
			show_copy: false,
			copying: false,
			copyRedirect: false,
			saveRedirect: false,
			newAccId: "",
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleAutoCalcInput = this.handleAutoCalcInput.bind(this);
	}
	async componentDidMount() {

		let alert = (
			<SweetAlert
				info
				style={{
					display: "block",
					color: "black"
				}}
				title="Accessorial is loading."
				onConfirm={() => {}}
				showConfirm={false}
			>
				Please wait...
				<br />
				<div style={{ textAlign: "center", margin: 5 }}>
					<CircularProgress />
				</div>
			</SweetAlert>
		);

		this.setState({
			mounted: true,
			alert,
		});


		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=viewAutoCalcAccessorial&id=" + this.props.match.params.id
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {
				this.props.handleMessages(response);
				this.props.pageTitle('Edit Auto Calc Accessorial - ' + data.body.autoCalcAcc.name);
				await this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					autoCalcAcc: {
						id: data.body.autoCalcAcc.id,
						name: data.body.autoCalcAcc.name,
						startDate: data.body.autoCalcAcc.startDate,
						endDate: data.body.autoCalcAcc.endDate,
						charge: data.body.autoCalcAcc.charge != null ? data.body.autoCalcAcc.charge : "",
						chargeType: data.body.autoCalcAcc.chargeType,
						minCharge: data.body.autoCalcAcc.minCharge != null ? data.body.autoCalcAcc.minCharge : "",
						maxCharge: data.body.autoCalcAcc.maxCharge != null ? data.body.autoCalcAcc.maxCharge : "",
						bmCharge: data.body.autoCalcAcc.bmCharge != null ? data.body.autoCalcAcc.bmCharge : "",
						bmChargeType: data.body.autoCalcAcc.bmChargeType != null ? data.body.autoCalcAcc.bmChargeType : "Flat",
						bmMinCharge: data.body.autoCalcAcc.bmMin != null ? data.body.autoCalcAcc.bmMin : "",
						bmMaxCharge: data.body.autoCalcAcc.bmMax != null ? data.body.autoCalcAcc.bmMax : "",
						carrier: {
							id: data.body.autoCalcAcc.carrier.id,
							scac: data.body.autoCalcAcc.carrier.scac,
							name: data.body.autoCalcAcc.carrier.name,
						},
						accessorial: {
							id: data.body.autoCalcAcc.accessorial.id,
							code: data.body.autoCalcAcc.accessorial.code,
							name: data.body.autoCalcAcc.accessorial.name,
						},
						assignedWarehouses: data.body.autoCalcAcc.assignedWarehouseIds,
					},
					criteria: data.body.autoCalcAcc.criteria,
					configure_bm: data.body.autoCalcAcc.customBmCharge == 1 ? true : false,
					show_copy: !_.isEmpty(data.body.autoCalcAcc.charge) ? true : false,
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"Unexpected response when loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
		this.setState({alert: null});
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked(name, e) {
		this.setState({ [name]: !!e.target.checked });
	};
	handleInput(name, e) {
		this.setState({ [name]: e.target.value });
	};
	handleAutoCalcInput(name, e) {
		let { autoCalcAcc } = this.state;
		autoCalcAcc[name] = e.target.value;
		this.setState({ autoCalcAcc });
	};
	handleDatetime(name, moment) {
		let { autoCalcAcc } = this.state;
		autoCalcAcc[name] = moment.format('YYYY-MM-DD');
		this.setState({ autoCalcAcc });
	}
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleAutoCalcChange(name, e) {
		let { autoCalcAcc } = this.state;
		autoCalcAcc[name] = e.target.value;
		this.setState({ autoCalcAcc });
	}
	addCriteria() {
		const { criteria } = this.state;

		criteria.push(
			{
				selector: 'Linear Ft.',
				compare: '==',
				value: "",
			},
		);

		this.setState({ criteria });
	}
	handleCriteria(key, name, e) {
		let { criteria } = this.state;

		if (!_.isEmpty(criteria) && criteria[key]) {
			let criterion = criteria[key];
			criterion[name] = e.target.value;
			criteria[key] = criterion;
			this.setState({ criteria });
		}
	}
	deleteCriteria(key) {
		let { criteria } = this.state;
		criteria.splice(key, 1);
		this.setState({ criteria });
	}
	handleWarehouse(warehouses) {
		let { autoCalcAcc } = this.state;
		autoCalcAcc['assignedWarehouses'] = warehouses;
		this.setState({autoCalcAcc});
	}
	async saveAcc() {
		this.setState({saving: true});

		const { autoCalcAcc, criteria, configure_bm } = this.state;

		try {

			let data = {
				id: autoCalcAcc.id,
				carrierId: autoCalcAcc.carrier.id,
				accessorialId: autoCalcAcc.accessorial.id,
				charge: autoCalcAcc.charge,
				chargeType: autoCalcAcc.chargeType,
				minCharge: autoCalcAcc.minCharge,
				maxCharge: autoCalcAcc.maxCharge,
				customBmCharge: configure_bm == true ? 1 : 0,
				bmCharge: autoCalcAcc.bmCharge ? autoCalcAcc.bmCharge : null,
				bmChargeType: autoCalcAcc.bmChargeType ? autoCalcAcc.bmChargeType : null,
				bmMinCharge: autoCalcAcc.bmMinCharge ? autoCalcAcc.bmMinCharge : null,
				bmMaxCharge: autoCalcAcc.bmMaxCharge ? autoCalcAcc.bmMaxCharge : null,
				startDate: autoCalcAcc.startDate,
				endDate: autoCalcAcc.endDate,
				name: autoCalcAcc.name,
				criteria: criteria,
				warehouses: autoCalcAcc.assignedWarehouses,
			}

			const response = await axios.post('/index.php?p=api&r=json&c=admin&m=saveAutoCalcAccessorial', qs.stringify(data));

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if(response.data && response.data.body && response.data.body.result) {
				this.setState({ saveRedirect: true });
			}

		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
		this.setState({saving: false});
	}
	async copyAcc() {
		this.setState({copying: true});

		const { autoCalcAcc } = this.state;

		try {

			let data = {
				id: autoCalcAcc.id,
				startDate: autoCalcAcc.startDate,
				endDate: autoCalcAcc.endDate,
			}

			const response = await axios.post('/index.php?p=api&r=json&c=admin&m=copyAutoCalcAccessorial', qs.stringify(data));

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if(response && response.data.body && response.data.body.result){
				await this.setState({copyRedirect: true, newAccId: response.data.body.result});
			}

		} catch(error) {
			console.error(error);
			this.props.setNotification("There was an error copying the data!", {
				variant: "error"
			});
		}
		this.setState({copying: false});
	}
	getCriteria(data) {

		const { classes } = this.props;

		return data.map((prop, key) => {

			let unitType = '';
			let placeholder = '';

			switch(prop.selector) {

				case 'Total Pallets':

					break;

				case 'Origin State':
				case 'Destination State':

					placeholder = 'PA,CA,NY...';

					break;

				case 'Total Linear Ft.':
				case 'Linear Ft.':
				case 'Length':
				case 'Width':
				case 'Height':
				case 'Total Height':

					unitType = 'Ft.';

					break;

				case 'Total Square Ft.':
				case 'Square Ft.':

					unitType = 'Ft^2';

					break;

				case 'Total Wt.':
				case 'Weight':
				case 'Total PCF':

					unitType = 'Lb';

					break;

				case 'Distance':

					unitType = 'mi.';

					break;

				case 'Total Cube':
				case 'Cube':

					unitType = 'Ft^3';

					break;
			}

			return (
				<GridContainer key={key}>
					<GridItem xs={2}>
						<FormControl
							fullWidth
							className={
								classes.selectFormControl
							}
						>
							<Select
								MenuProps={{
									className:
										classes.selectMenu
								}}
								classes={{
									select:
										classes.select
								}}
								value={prop.selector || "Linear Ft."}
								inputProps={{
									name: "selector"
								}}
								onChange={(e) => {this.handleCriteria(key,"selector", e)}}
							>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Linear Ft."
								>
									Linear Ft.
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total Pallets"
								>
									Total Pallets
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Square Ft."
								>
									Square Ft.
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total Linear Ft."
								>
									Total Linear Ft.
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Length"
								>
									Length
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Width"
								>
									Width
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Height"
								>
									Height
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total Height"
								>
									Total Height
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total Square Ft."
								>
									Total Square Ft.
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total Wt."
								>
									Total Wt.
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Weight"
								>
									Weight
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total PCF"
								>
									Total PCF
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Total Cube"
								>
									Total Cube
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Cube"
								>
									Cube
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Distance"
								>
									Distance
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Origin State"
								>
									Origin State
								</MenuItem>
								<MenuItem
									classes={{
										root:
											classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="Destination State"
								>
									Destination State
								</MenuItem>
							</Select>
						</FormControl>
					</GridItem>
					<GridItem xs={2}>
						<FormControl
							fullWidth
							className={classes.selectFormControl}
						>
							<Select
								MenuProps={{
									className: classes.selectMenu
								}}
								classes={{
									select:
										classes.select
								}}
								value={prop.compare}
								onChange={e => this.handleCriteria(key, "compare", e)}
							>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="=="
								>
									==
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="==="
								>
									===
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="!="
								>
									!=
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="!=="
								>
									!==
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value=">"
								>
									{">"}
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value=">="
								>
									{">="}
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="<"
								>
									{"<"}
								</MenuItem>
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected:
											classes.selectMenuItemSelected
									}}
									value="<="
								>
									{"<="}
								</MenuItem>
							</Select>
						</FormControl>
					</GridItem>
					<GridItem xs={2}>
						<CustomInput
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "text",
								value: prop.value,
								placeholder: placeholder,
								onChange: e =>
									this.handleCriteria(key, "value", e),
								endAdornment: (
									<InputAdornment position="end">
										{unitType}
									</InputAdornment>
								),
							}}
						/>
					</GridItem>
					<GridItem xs={2}>
						<Button
							size="sm"
							color="danger"
							style={{marginRight: '4px'}}
							onClick={(e) => {this.deleteCriteria(key)}}
						>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			);
		});
	}
	render() {
		if (this.state.copyRedirect) {
			window.location = basePath + "/admin/auto-calc-accessorials/view/" + this.state.newAccId;
		}
		if (this.state.saveRedirect) {
			let redirectTo = basePath + "/admin/auto-calc-accessorials";
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} style={{paddingTop: '10px'}}>
					<SnackbarContent message={this.state.notice_message} color="info" />
				</GridItem>
				<GridItem xs={12}>
					<GridContainer>
						<GridItem xs={12}>
							{!this.state.saving ? (
								<Button
									size="sm"
									color="linkedin"
									style={{marginRight: '4px'}}
									onClick={(e) => {this.saveAcc()}}
								>
									Save All
								</Button>
							) : (
								<Button size="sm" color="linkedin">
									<CircularProgress size={16} style={{ color: "white" }} />
								</Button>
							)}
							{this.state.show_copy && !this.state.copying && (
								<Button
									size="sm"
									color="info"
									style={{marginRight: '4px'}}
									onClick={(e) => {this.copyAcc()}}
								>
									Copy
								</Button>
							)}
							{this.state.show_copy && this.state.copying && (
								<Button size="sm" color="info">
									<CircularProgress size={16} style={{ color: "white" }} />
								</Button>
							)}
						</GridItem>
					</GridContainer>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<h5 style={{fontWeight: 'bold'}}>Details</h5>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="set_name"
										className={
											classes.selectLabel
										}
									>
										Set Name
									</InputLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "name",
											name: "name",
											value: this.state.autoCalcAcc.name || "",
											onChange: (e) => {this.handleAutoCalcInput('name', e)},
										}}
										white
									/>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="carrier_scac"
										className={
											classes.selectLabel
										}
									>
										Carrier Scac
									</InputLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "carrier_scac",
											name: "carrier_scac",
											value: this.state.autoCalcAcc.carrier.scac + ' - ' + this.state.autoCalcAcc.carrier.name,
											disabled: true,
										}}
										white
									/>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="accessorial"
										className={
											classes.selectLabel
										}
									>
										Accessorial
									</InputLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "accessorial",
											name: "accessorial",
											value: this.state.autoCalcAcc.accessorial.code + ' - ' + this.state.autoCalcAcc.accessorial.name,
											disabled: true,
										}}
										white
									/>
								</GridItem>
							</GridContainer>
							<GridContainer style={{paddingTop: '10px'}}>
								<GridItem xs={3}>
									<InputLabel
										className={classes.selectLabel}
									>
										Start Date
									</InputLabel>
									<FormControl fullWidth>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												id: "startDate",
												name: "startDate",
												placeholder: 'YYYY-MM-DD',
												value: this.state.autoCalcAcc.startDate,
												onChange: (e) => {this.handleAutoCalcInput('startDate', e)},
											}}
											white
										/>
									</FormControl>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel
										className={classes.selectLabel}
									>
										End Date
									</InputLabel>
									<FormControl fullWidth>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												id: "endDate",
												name: "endDate",
												placeholder: 'YYYY-MM-DD',
												value: this.state.autoCalcAcc.endDate,
												onChange: (e) => {this.handleAutoCalcInput('endDate', e)},
											}}
											white
										/>
									</FormControl>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									<h5 style={{fontWeight: 'bold'}}>Charges</h5>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="charge"
										className={
											classes.selectLabel
										}
									>
										Charge
									</InputLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "charge",
											name: "charge",
											value: this.state.autoCalcAcc.charge,
											onChange: (e) => {this.handleAutoCalcInput('charge', e)},
											endAdornment: (
												<InputAdornment position="end">
													$
												</InputAdornment>
											),
										}}
										white
									/>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="charge_type"
										className={
											classes.selectLabel
										}
									>
										Charge Type
									</InputLabel>
									<FormControl
										fullWidth
										className={
											classes.selectFormControl
										}
									>
										<Select
											MenuProps={{
												className:
													classes.selectMenu
											}}
											classes={{
												select:
													classes.select
											}}
											value={this.state.autoCalcAcc.chargeType || "Flat"}
											inputProps={{
												id: "chargeType",
												name: "chargeType"
											}}
											onChange={(e) => {this.handleAutoCalcChange("chargeType", e)}}
										>
											<MenuItem
												classes={{
													root:
														classes.selectMenuItem,
													selected:
														classes.selectMenuItemSelected
												}}
												value="Flat"
											>
												Flat
											</MenuItem>
											<MenuItem
												classes={{
													root:
														classes.selectMenuItem,
													selected:
														classes.selectMenuItemSelected
												}}
												value="Per Hundred"
											>
												Per Hundred
											</MenuItem>
											<MenuItem
												classes={{
													root:
														classes.selectMenuItem,
													selected:
														classes.selectMenuItemSelected
												}}
												value="Percent of Net"
											>
												Percent of Net
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="min_charge"
										className={
											classes.selectLabel
										}
									>
										Min Charge
									</InputLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "min_charge",
											name: "min_charge",
											value: this.state.autoCalcAcc.minCharge,
											onChange: (e) => {this.handleAutoCalcInput('minCharge', e)},
											endAdornment: (
												<InputAdornment position="end">
													$
												</InputAdornment>
											),
										}}
										white
									/>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel
										htmlFor="max_charge"
										className={
											classes.selectLabel
										}
									>
										Max Charge
									</InputLabel>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "max_charge",
											name: "maxCharge",
											value: this.state.autoCalcAcc.maxCharge,
											onChange: (e) => {this.handleAutoCalcInput('maxCharge', e)},
											endAdornment: (
												<InputAdornment position="end">
													$
												</InputAdornment>
											),
										}}
										white
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.configure_bm}
												onChange={(e) => {this.handleChecked("configure_bm", e)}}
											/>
										}
										classes={{
											label: classes.label
										}}
										label={"Configure Benchmark Charge"}
									/>
								</GridItem>
							</GridContainer>
							{this.state.configure_bm && (
								<GridContainer>
									<GridItem xs={3}>
										<InputLabel
											htmlFor="bm_charge"
											className={
												classes.selectLabel
											}
										>
											Benchmark Charge
										</InputLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												id: "bmCharge",
												name: "bmCharge",
												value: this.state.autoCalcAcc.bmCharge || "",
												onChange: (e) => {this.handleAutoCalcInput('bmCharge', e)},
												endAdornment: (
													<InputAdornment position="end">
														$
													</InputAdornment>
												),
											}}
											white
										/>
									</GridItem>
									<GridItem xs={3}>
										<InputLabel
											htmlFor="carrier"
											className={
												classes.selectLabel
											}
										>
											Benchmark Charge Type
										</InputLabel>
										<FormControl
											fullWidth
											className={
												classes.selectFormControl
											}
										>
											<Select
												MenuProps={{
													className:
														classes.selectMenu
												}}
												classes={{
													select:
														classes.select
												}}
												value={this.state.autoCalcAcc.bmChargeType || "Flat"}
												inputProps={{
													id: "bmChargeType",
													name: "bmChargeType"
												}}
												onChange={(e) => {this.handleAutoCalcChange("bmChargeType", e)}}
											>
												<MenuItem
													classes={{
														root:
															classes.selectMenuItem,
														selected:
															classes.selectMenuItemSelected
													}}
													value="Flat"
												>
													Flat
												</MenuItem>
												<MenuItem
													classes={{
														root:
															classes.selectMenuItem,
														selected:
															classes.selectMenuItemSelected
													}}
													value="Per Hundred"
												>
													Per Hundred
												</MenuItem>
												<MenuItem
													classes={{
														root:
															classes.selectMenuItem,
														selected:
															classes.selectMenuItemSelected
													}}
													value="Percent of Net"
												>
													Percent of Net
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={3}>
										<InputLabel
											htmlFor="min_charge"
											className={
												classes.selectLabel
											}
										>
											Benchmark Min Charge
										</InputLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												id: "bmMinCharge",
												name: "bmMinCharge",
												value: this.state.autoCalcAcc.bmMinCharge,
												onChange: (e) => {this.handleAutoCalcInput('bmMinCharge', e)},
												endAdornment: (
													<InputAdornment position="end">
														$
													</InputAdornment>
												),
											}}
											white
										/>
									</GridItem>
									<GridItem xs={3}>
										<InputLabel
											htmlFor="max_charge"
											className={
												classes.selectLabel
											}
										>
											Benchmark Max Charge
										</InputLabel>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												id: "bmMaxCharge",
												name: "bmMaxCharge",
												value: this.state.autoCalcAcc.bmMaxCharge,
												onChange: (e) => {this.handleAutoCalcInput('bmMaxCharge', e)},
												endAdornment: (
													<InputAdornment position="end">
														$
													</InputAdornment>
												),
											}}
											white
										/>
									</GridItem>
								</GridContainer>
							)}
							<GridContainer>
								<GridItem xs={12}>
									<h5 style={{fontWeight: 'bold'}}>Criteria</h5>
									<Button size="sm" color="linkedin" onClick={(e) => {this.addCriteria()}}>
										<AddCircle />
									</Button>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12}>
									{this.getCriteria(this.state.criteria)}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={6}>
					<WarehouseSelector
						mode={'list'}
						selectedWarehouses={this.state.autoCalcAcc.assignedWarehouses}
						onChange={(warehouses) => {this.handleWarehouse(warehouses)}}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(viewAutoCalcAccessorial));
