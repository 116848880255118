import React from "react";
import cx from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import Paper from "@material-ui/core/Paper";
import { hist } from '../App.jsx';
import _ from "lodash";
import PropTypes from "prop-types";
import { Switch, Route, NavLink, Redirect, matchPath  } from "react-router-dom";
import Error403Container from "../components/Error403Container/Error403Container.jsx";

import { withSnackbar } from "notistack";
import { setNotification, handleMessages } from "../redux/actions/notifications.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import CircularProgress from "@material-ui/core/CircularProgress";
// import DashboardIcon from "@material-ui/icons/Dashboard";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "~/components/Navbars/AdminNavbar.jsx";
import Footer from "~/components/Footer/Footer.jsx";
import Sidebar from "~/components/Sidebar/Sidebar.jsx";
import NotificationsSidebar from "~/components/NotificationsSidebar/NotificationsSidebar.jsx";

import routes from "~/routes.js";

import appStyle from "~/assets/jss/empire-tms/layouts/adminStyle.jsx";

import { basePath, baseUrl, assetPath, apiUrl } from "~/variables/server.jsx";

const image = baseUrl + assetPath + "/img/sidebar-0.png";
const defaultLogo = baseUrl + assetPath + "/img/empire/logo-light.png";

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            pageTitle: "",
            isMounted: false,
            miniActive: false,
            showNotificationsBar: false,
            image: image,
            color: "blue",
            bgColor: "black",
            hasImage: false,
            fixedClasses: "dropdown",
            session: {
                environment: "",
                user: {
                    id: "",
                    parent_id: "",
                    name: "",
                    authenticated: false,
                    level: "none",
                    permissions: [],
                    user_company_settings: [],
                    user_settings: []
                },
                notice: [],
                loaded: false,
                isAuthenticated: false
            },
            alert: null,
            redirect: false
        };
        this.sidebarMinimize = this.sidebarMinimize.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        this.setRef = this.setRef.bind(this);
        this.focusRef = this.focusRef.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getSysMessages = this.getSysMessages.bind(this);
    }

    openingToLandingView = () => {
        return [
            basePath,
            basePath + "/",
            basePath + "/admin",
            basePath + "/admin/",
        ].indexOf(this.props.location.pathname) !== -1;
    }

    async componentDidMount() {
        const alert = (
            <SweetAlert
                info
                style={{
                    display: "block",
                    color: "black"
                }}
                title="Loading"
                onConfirm={() => {}}
                showConfirm={false}
            >
                Please wait...
                <br />
                <div style={{ textAlign: "center", margin: 5 }}>
                    <CircularProgress />
                </div>
            </SweetAlert>
        );
        
        this.setState({ isMounted: true, alert }, async () => {
            try {
                await this.props.getUserSession();
                const session = this.props.response;
                if (!_.isEmpty(session) && !_.isEmpty(session.data) && !_.isEmpty(session.data.user)) {
                    const user = session.data.user;
                    if(user.user_settings.default_landing_page != null && this.openingToLandingView()) {
                        hist.push(`${basePath}/admin/${user.user_settings.default_landing_page}`);
                    }
                    this.setState({
                        routes: routes.getRoutes(user),
                        pageTitle: !_.isEmpty(this.props.pageTitle) ? this.props.pageTitle : this.activeRoute(routes.getRoutes(user)),
                        session: {
                            environment: session.data.environment_status || "",
                            user: user,
                            loaded: true,
                            isAuthenticated: user.authenticated,
                            notice: session.data.notice
                        },
                        alert: null
                    });
                } else {
                    this.setState({
                        session: {
                            loaded: true,
                            isAuthenticated: false
                        },
                        alert: null
                    });
                }
            } catch (error) {
                console.error(error);
                this.setState({
                    session: {
                        loaded: true,
                        isAuthenticated: false
                    },
                    alert: null
                });
                this.props.setNotification("There was an error loading the data!", { variant: "error" });
            }
        });
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false,
            routes: []
        });
    }

    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname && this.refs.mainPanel !== undefined) {
            // was previously called many times on page change in routeMatches().
            if(this.state.session.loaded && this.state.isMounted) {
                this.setSessionData();
            }
            this.refs.mainPanel.scrollTop = 0;
            let pageTitle = !_.isEmpty(this.props.pageTitle) ? this.props.pageTitle : this.activeRoute(this.state.routes);
            this.setState({pageTitle: pageTitle});
        }
    }

    getRoute() {
        return this.props.location.pathname !== basePath + "/admin/full-screen-maps";
    }

    /**
     * Set index of the scroll bar in the main panel
     * @param  Integer i index position of scroll bar
     * @return
     */
    scrollTop(i) {
        this.refs.mainPanel.scrollTop = i;
    }

    setRef(ref, el) {
        this.refs[ref] = el;
    }

    focusRef(ref) {
        if (Object.keys(this.refs).indexOf(ref) !== -1) {
            this.refs[ref].focus();
        }
    }

    handleClick(ref) {
        this.focusRef(ref);
    }

    async setSessionData() {
        this.props.getUserSession(true);
        const session = this.props.response;
        if(!_.isEmpty(session) && !_.isEmpty(session.data) && !_.isEmpty(session.data.user)) {
            this.setState({session:{
                loaded: true, 
                isAuthenticated: session.data.user.authenticated,
                environment: session.data.environment_status || "",
                user: session.data.user,
                notice: session.data.notice
            }})
        }
    }

    /**
     * Check if a defined route matches the url path
     * while supporting path placeholders with the format
     * /path/to/:placeholder
     */
    routeMatches(route) {
        const pathParts = window.location.pathname.split('/');
        const layout = route.layout || "";
        const routePath = route.path || "";
        const routePathParts = (layout + routePath).split('/');

        if (pathParts.length !== routePathParts.length) {
            return false;
        }
        for(let i = 0; i < pathParts.length; i++) {
            if (routePathParts[i].indexOf(':') === -1 && routePathParts[i] !== pathParts[i]) {
                return false;
            }
        }
        return true
    }

    /**
     * Get the active route based on the url path
     */
    activeRoute(routes) {
        const activeRoute = "TMS";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                const collapseActiveRoute = this.activeRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (this.routeMatches(routes[i])) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }

    sidebarMinimize() {
        this.setState({ miniActive: !this.state.miniActive });
    }
    notificationsBarMinimize = () => {
        this.setState({ showNotificationsBar: !this.state.showNotificationsBar });
    }

    getSysMessages() {
        const { classes } = this.props;
        return this.state.session.notice.map((prop, key) => {
            var userLevel = this.state.session.user.level;
            var alertLevel = prop.visible;
            if(userLevel != alertLevel && alertLevel !== 'all') {
                return '';
            }
            return (
                <Paper key={key} className={classes.infoPaper} elevation={1}>
                    <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{prop.message}</Markdown>
                </Paper>
            );
        });
    }
    render() {
        const { classes, ...rest } = this.props;
        let mainPanel = classes.mainPanel + " ";
        // determine if we are rendering the page with mini/full size nav bar and with/without notifications
        if(this.state.miniActive) {
            if(this.state.showNotificationsBar) {
                mainPanel = mainPanel + 
                cx({
                    [classes.mainPanelWithNotificationsSidebarMini]: true,
                });
            } else {
                mainPanel = mainPanel + 
                cx({
                    [classes.mainPanelSidebarMini]: true,
                });
            }
        } else {
            if(this.state.showNotificationsBar) {
                mainPanel = mainPanel + 
                cx({
                    [classes.withNotifications]: true,
                });
            }
        }
        if (!this.state.session.loaded) {
            // Loading TMS
        }
        if (this.state.session.loaded && !this.state.session.isAuthenticated) {
            const redirectTo = basePath + "/auth/login-page";
            return <Redirect to={redirectTo} />;
        }

        const logo = (_.isEmpty(this.state.session.user.user_settings.custom_logo_file)) ? defaultLogo : baseUrl + "/getLogo.php?logo=" + this.state.session.user.user_settings.custom_logo_file;
        return (
            <div className={classes.wrapper}>
                {this.state.alert}
                {this.state.isMounted && this.state.session.isAuthenticated === true && 
                    <Sidebar user={this.state.session.user} routes={this.state.routes} logoText="TMS" logo={logo} image={this.state.image} color={this.state.color} bgColor={this.state.bgColor} miniActive={this.state.miniActive} {...rest} />}
                <div className={mainPanel} ref="mainPanel">
                    <AdminNavbar sidebarMinimize={this.sidebarMinimize} miniActive={this.state.miniActive} notificationsBarMinimize={this.notificationsBarMinimize} notificationsActive={this.state.showNotificationsBar} brandText={!_.isEmpty(this.state.pageTitle) ? this.state.pageTitle : this.activeRoute(routes)} notices={this.props.notice} color="primary" {...rest} />
                    {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and container classes are present because they have some paddings which would make the map smaller */}
                    {this.getRoute() ? (
                        <div className={classes.contentWrapper}>
                            {!_.isEmpty(this.state.session.notice) ?
                                <div>{this.getSysMessages()}</div>
                            : ''}
                        
                            <div className={classes.content}>
                                <div>
                                    <RouteHandler
                                        routes={this.state.routes}
                                        session={this.props.session || {}}
                                        location={this.props.location || {}}
                                        scrollTop={this.scrollTop}
                                        setNotification={this.props.setNotification}
                                        handleMessages={this.props.handleMessages}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.map}>
                            <RouteHandler
                                routes={this.state.routes}
                                session={this.props.session || {}}
                                location={this.props.location || {}}
                            />
                        </div>
                    )}
                </div>
                {this.state.showNotificationsBar && 
                    <NotificationsSidebar/>
                }
            </div>
        );
    }
}

class RouteHandler extends React.Component {
    render() {
        let userPermissions = {};
        let userSettings = {};
        let companySettings = {};
        if(!_.isEmpty(this.props) && !_.isEmpty(this.props.session) && !_.isEmpty(this.props.session.user)) {
            if(!_.isEmpty(this.props.session.user.permissions)) {
                userPermissions = this.props.session.user.permissions;
            }
            if(!_.isEmpty(this.props.session.user.user_settings)) {
                userSettings = this.props.session.user.user_settings;
            }
            if(!_.isEmpty(this.props.session.user.user_company_settings)) {
                companySettings = this.props.session.user.user_company_settings;
            }
        }

        return (
            <Switch>{this.routes(this.props.routes, userPermissions, userSettings, companySettings)}</Switch>
        );
    }

    routes(routes, userPermissions, userSettings, companySettings) {
        const pathname = this.props.location.pathname;

        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.routes(prop.views, userPermissions, userSettings, companySettings);
            }

            let isCurrentPath = matchPath(pathname, {path:prop.layout + prop.path, exact:true});
            let showRoute = true;

            if (prop.layout === basePath + "/admin") {
                if(!_.isEmpty(isCurrentPath)) {

                    if(!_.isEmpty(prop.permissions)) {
                        prop.permissions.forEach((permission) => {
                            if(!userPermissions[permission]) {
                                showRoute = false;
                            }
                        });
                    }
                    if(!_.isEmpty(prop.userSettings)) {
                        prop.userSettings.forEach((permission) => {
                            if(userSettings[permission] != 1) {
                                showRoute = false;
                            }
                        });
                    }
                    if(!_.isEmpty(prop.companyPermissions)) {
                        prop.companyPermissions.forEach((permission) => {
                            if(companySettings[permission] != 1) {
                                showRoute = false;
                            }
                        });
                    }
                }
                if(showRoute) {
                    return (
                        <Route path={prop.layout + prop.path} render={routeProps => <prop.component {...routeProps} scrollTop={this.props.scrollTop} pageTitle={(pageTitle) => this.setState({ pageTitle: pageTitle })} setRef={this.setRef} focusRef={this.focusRef} handleClick={this.handleClick} setNotification={this.props.setNotification} handleMessages={this.props.handleMessages} />} key={key} />
                    );
                } else {
                    return (
                        <Error403Container/>
                    );
                }
            } else {
                return null;
            }
        });
    }
}

Admin.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	setNotification,
        handleMessages
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(appStyle)(withSnackbar(Admin)));
