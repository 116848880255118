import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Dot from '@material-ui/icons/Brightness1';
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class DotSelect extends React.Component {

    state = {
        hoverKey: -1,
    }

    setHoverKey = hoverKey => {
        this.setState({hoverKey});
    }

    onSelect = key => {
        if (typeof this.props.onSelect === "function") {
            this.props.onSelect(this.props.options[key], key);
        }
    }

    render() {
        const options = Array.isArray(this.props.options) ? this.props.options : [];

        return(
            <Grid container styles={{ alignItems: "center", flex: 1 }}>
                <Grid item>
                    {options.map((prop, key) => {

                        const selectedKey = !isNaN(this.props.selectedKey) && this.props.selectedKey !== null ? +this.props.selectedKey : -1;
                        const hoverCeil = Math.max(selectedKey, this.state.hoverKey);

                        return(
                            <Dot
                                key={key}
                                style={{ color: key <= hoverCeil ? "red" : "gainsboro" }}
                                onMouseEnter={() => this.setHoverKey(key)}
                                onMouseLeave={() => this.setHoverKey(-1)}
                                onClick={() => this.onSelect(key)}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(DotSelect);