const initialState = {
    open: false,
    message: "",
    onConfirm: null,
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_ALERT_MODAL_OPEN": {
			return {...newState, open: action.val}
		}
		case "SET_ALERT_MODAL_MESSAGE": {
			return {...newState, message: action.val}
		}
		case "SET_ALERT_MODAL_ON_CONFIRM": {
			return {...newState, onConfirm: action.val}
		}
		default:
			return state;
	}
}