import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { basePath, apiUrl } from "~/variables/server.jsx";

// core
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

// icons
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Edit from "@material-ui/icons/Edit";
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';

class ArchivedActions extends Component {

	state = {
		anchorEl: null,
		open: false
	}

	handleClick = (e) => {
		this.setState({ 
			anchorEl: e.currentTarget,
			open: true
		});
	}

	handleActionSelect = (action) => {
		const {index, original} = this.props.row;
		const bol = `${original.date}${original.id}`;

		this.setState({ 
			anchorEl: null,
			open: false
		});

		switch (action) {
			case "print":
				this.props.handlePrint(index, original.date, original.id)
				break;
			case "carrier_doc":
				this.props.handleCarrierDocs(index)
				break;
			case "notes":
				this.props.getNotes(bol)
				break;
			case "history":
				this.props.handleHistory(index, original.date, original.id)
				break;
			case "rates":
				this.props.handleRates(index, original.rate_id, original.date, original.id)
				break;
			case "cancel":
				this.props.handleCancel(index, original.date, original.id)
				break;
			case "uncancel":
				this.props.handleUncancel(index, original.date, original.id)
				break;
			default:
				break;
		}

	}

	hasNewNotes = (bolNumber) => {
		return this.props.bolsWithNewNotes.indexOf(bolNumber) !== -1;
	}

	getNumNotes = (bolNumber) => {
		return this.props.bolsWithNotes[bolNumber] == 0 ? "" : this.props.bolsWithNotes[bolNumber];
	}

	getActions = (bol) => {
		let actions = [];
		const executed = (bol.status !== "hold" && bol.status != "canceled");
		const bolPerm = this.props.userHasBillOfLadingPermission;


		if(bol.status == "canceled" && bolPerm) {
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("uncancel")}
				>
					<ListItemIcon>
						<SettingsBackupRestoreOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Uncancel</ListItemText>
				</MenuItem>
			);
		}
	
		if(executed) {
			if(bolPerm) {
				actions.push(
					<MenuItem 
						onClick={() =>  this.handleActionSelect("print")}
					>
						<ListItemIcon>
							<PrintOutlinedIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Print BoL</ListItemText>
					</MenuItem>
				);
			}

			if(!isNaN(bol.carrier_docs) && bol.carrier_docs > 0) {
				actions.push(
					<MenuItem 
						onClick={() => this.handleActionSelect("carrier_doc")}
					>
						<ListItemIcon>
							<AssignmentOutlinedIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Carrier Docs</ListItemText>
					</MenuItem>
				);
			}

			actions.push(
				<MenuItem
					href={apiUrl + "/index.php?p=api&c=billoflading&m=packingList&d=" + bol.id + "/" + bol.date}
					component="a"
					onClick={() => this.handleActionSelect()}
				>
					<ListItemIcon>
						<ListAltOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Packaging List</ListItemText>
				</MenuItem>
			);
		};

		actions.push(
			<MenuItem 
				onClick={() => this.handleActionSelect("history")}
			>
				<ListItemIcon>
					<HistoryOutlinedIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>History</ListItemText>
			</MenuItem>
		);

		if(bolPerm) {
			actions.push(
				<MenuItem
					onClick={() => this.handleActionSelect("notes")}
				>
					<ListItemIcon>
						<NoteOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>{this.hasNewNotes(bol.date+bol.id) ? <NotificationsIcon fontSize="small" color="secondary"/> : null} Notes {this.getNumNotes(bol.date+bol.id) > 0 ? <span> ({this.getNumNotes(bol.date+bol.id)}) </span> : null}</ListItemText>
				</MenuItem>
			);
		}

		if(!!bol.rate_id && bol.rate_id != 0 && bolPerm) {
			actions.push(
				<MenuItem 
					onClick={() => this.handleActionSelect("rates")}
				>
					<ListItemIcon>
						<LocalShippingOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Rates</ListItemText>
				</MenuItem>
			);
		}

		if((bol.status === "tfm_received" ||
			bol.status === "auditing" || 
			bol.status === "billed" || 
			bol.status === "ttl_received" || 
			bol.status === "ttl_confirmed" || 
			bol.status === "third_party_payor") &&
			this.props.user && 
			this.props.hasClaimsPermission &&
			this.props.user.user_company_settings.enable_claims) {

			actions.push(
				<MenuItem 
					component={Link}
					to={`${basePath}/admin/claim/new/${bol.date}${bol.id}/`}
					onClick={() => this.handleActionSelect()}
				>
					<ListItemIcon>
						<CreateOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Submit Claim</ListItemText>
				</MenuItem>
			);
		}

		if(!isNaN(bol.tracking_history) && bol.tracking_history > 0) {
			actions.push(
				<MenuItem 
					component={Link}
					to={`${basePath}/admin/bol/HistoryPanel/${bol.date}${bol.id}/`}
					onClick={() => this.handleActionSelect()}
				>
					<ListItemIcon>
						<ExploreOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Track Shipment</ListItemText>
				</MenuItem>
			);
		} else if(bol.truckload_shipment_id && bol.external_tracking_url) {
			actions.push(
				<MenuItem 
					component="a"
					href={bol.external_tracking_url}
					target="_blank"
					onClick={() => this.handleActionSelect()}
				>
					<ListItemIcon>
						<ExploreOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Track Shipment</ListItemText>
				</MenuItem>
			);
		} else if(this.props.user && this.props.hasCarrierTrackingPermission && !_.isEmpty(bol.tracking_code) && bol.tracking_history == 0) {
			actions.push(
				<MenuItem 
					component={Link}
					to={`${basePath}/admin/public-track-pro/${bol.tracking_code}/`}
					onClick={() => this.handleActionSelect()}
				>
					<ListItemIcon>
						<ExploreOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Track Shipment</ListItemText>
				</MenuItem>
			);
		}
		if(executed) {
			if((bol.status === "executed" ||
				bol.status === "ttl_received" ||
				bol.status === "third_party_payor" ||
				bol.status === "scheduled") && 
				this.props.user && 
				this.props.user.level !== "admin") {
	
				actions.push(
					<MenuItem 
						onClick={() => this.handleActionSelect("cancel")}
					>
						<ListItemIcon>
							<BlockOutlinedIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Cancel</ListItemText>
					</MenuItem>
				);
			}
		}

		return actions;
	}

	render() {
		const {index,  original} = this.props.row;
		const { classes } = this.props;

		return(
			<ButtonGroup disableElevation variant="contained">
				<IconButton
					id="long-button"
					aria-label="more"
					aria-haspopup="true"
					onClick={(e) => this.handleClick(e)}
				>
					<MoreVertIcon fontSize="inherit" />
				</IconButton>
				<Menu
					id="menu-appbar"
					anchorEl={this.state.anchorEl}
					keepMounted
					open={this.state.open}
					onClose={this.handleActionSelect}
				>
					{this.getActions(original)}
				</Menu>
				{this.props.userHasBillOfLadingPermission && (
					<IconButton
						color="transparent"
						onClick={(e) => this.handleActionSelect("notes")}
						size="small"
					>
						<NoteOutlinedIcon fontSize="inherit" />
						<span className={this.hasNewNotes(original.date+original.id) ? classes.notifications : classes.notes}>{this.getNumNotes(original.date+original.id)}</span>
					</IconButton>
				)}
				<IconButton
					color="transparent"
					onClick={(idx) => this.props.setSelectedRow(index)}
					size="small"
				>
					<Edit fontSize="inherit" />
				</IconButton>
			</ButtonGroup>
		)
	}
}

export default ArchivedActions;