import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { apiUrl } from "~/variables/server.jsx";
import "formdata-polyfill";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Miler extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null,
			email: "",
			file: null
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.submitRequest = this.submitRequest.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=miler&m=miler");
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user)) {
				this.setState({
					show: true,
					loading: false,
					user: response.data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}


	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleUpload(files) {
		const file = files[0];
		this.setState({ file });
	}

	async submitRequest() {
		if (_.isEmpty(this.state.email)) {
			this.props.setNotification("You must provide an email to receive the result!", { variant: "error" });
			return;
		}

		if (_.isEmpty(this.state.file)) {
			this.props.setNotification("No CSV have been provided!", { variant: "error" });
			return;
		}

		const email = encodeURIComponent(this.state.email);

		const data = new FormData(); // FormData is needed to upload files

		data.append("imgFile_0", this.state.file);

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		try {
			const url = "/index.php?p=api&r=json&c=miler&m=request&email=" + email;
			const response = await axios.post(url, data, config);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.result) {
				this.setState({
					email: "",
					file: null
				});
				this.props.setNotification("You request was submitted and is being processed!", { variant: "success" });
			} else {
				this.props.setNotification("There was an error saving the user!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the user!", { variant: "error" });
		}
	}

	handleDelete() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure?"
					onConfirm={() => this.setState({ alert: null, file: null })}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this file!
				</SweetAlert>
			)
		});
	}

	render() {
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer style={{ marginBottom: "10px" }}>
								<GridItem xs />
								<GridItem xs={10}>
									<Paper className={classes.infoPaper} elevation={1}>
										<b>Instructions</b>:<br />
										Using the template provided, upload a series of origin/destination zip code pairs and the system will process your file and email when complete. Providing the origin/destination country code is optional. Valid country codes are USA, CAN, and MEX. If country is not provided, a default of USA or CAN will be assumed. The request file must be a CSV, and you must provide an email address to receive the result.
										<br />
										<br />
										The following template can be used to provide your data. Please leave the headers in place: <br />
										<a target="_blank" href={apiUrl + "/index.php?p=api&c=miler&m=requestTemplate"}>
											tfm_miler_request_template.csv
										</a>
										.
									</Paper>
								</GridItem>
								<GridItem xs />
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<Dropzone length={1} accept="text/plain,text/csv,application/vnd.ms-excel" handleUpload={this.handleUpload.bind(this)} />
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									{this.state.file && <Preview src="#" index={0} extension="csv" handleClick={this.handleDelete.bind(this)} handleDelete={this.handleDelete.bind(this)} />}
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<InputLabel className={classes.label}>Result Recipient Email</InputLabel>
									<br />
									<CustomInput
										id="email"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "email",
											value: this.state.email,
											onChange: this.handleInput("email")
										}}
										helpText="Comma delmit multiple addresses"
										className={classes.dividerTop}
										white
									/>
									<br />
									<Button color="linkedin" onClick={this.submitRequest}>
										Submit Request
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(Miler));