import React, { Component, Fragment } from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";
import qs from "qs";
import { process } from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-react-tooltip";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { Link } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";

const linkStyle = {
  color: '#0976B4',
  textDecoration: "underline",
  whiteSpace: "nowrap",

};

class invoiceRemittanceByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      body: null,
      start_date: this.props.location.state.startDate,
      end_date: this.props.location.state.endDate,
      skip: 0,
      take: 25,
      dataState: {
        skip: 0,
        take: 25,
      },
      result: [],
      reportName: null,
      initialized: true,
      open: false,
      anchorEl: false,
      columns: [
        {
          field: "pro_number",
          title: "Pro#",
          width: "175px",
          locked: true,
          cell: ({ dataItem, style, className }) => {
            return (
              <td
                title={dataItem.pro_number}
                style={style}
                className={className}
              >

                <Link to={{
                  pathname: basePath + "/admin/Carrier_Invoice_Detail",

                  state: {
                    pro_number: dataItem.invoice_id,
                  }
                }}

                  style={linkStyle}

                >

                  {dataItem.pro_number}
                </Link>




              </td>
            );
          },
        },
        {
          field: "invoice_number",
          title: "Invoice#",
          width: "110px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.invoice_number}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.invoice_number}
              </td>
            );
          },
        },
        {
          field: "check_number",
          title: "Payment Check#",
          width: "150px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.check_number}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.check_number}
              </td>
            );
          },
        },
        {
          field: "company_name",
          title: "Invoice Customer",
          width: "150px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.company_name}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.company_name}
              </td>
            );
          },
        },
        {
          field: "origin_name",
          title: "Origin Customer",
          width: "150px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.origin_name}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.origin_name}
              </td>
            );
          },
        },
        {
          field: "origin_city",
          title: "Origin City",
          width: "120px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.origin_city}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.origin_city}
              </td>
            );
          },
        },
        {
          field: "origin_state",
          title: "Origin State",
          width: "120px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.origin_state}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.origin_state}
              </td>
            );
          },
        },
        {
          field: "origin_postal_code",
          title: "Origin Zip",
          width: "120px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.origin_postal_code}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.origin_postal_code}
              </td>
            );
          },
        },
        {
          field: "origin_country_code",
          title: "Origin Country",
          width: "130px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.origin_country_code}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.origin_country_code}
              </td>
            );
          },
        },
        {
          field: "destination_name",
          title: "Destination Customer",
          width: "170px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.destination_name}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.destination_name}
              </td>
            );
          },
        },
        {
          field: "destination_city",
          title: "Destination City",
          width: "160px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.destination_city}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.destination_city}
              </td>
            );
          },
        },
        {
          field: "destination_state",
          title: "Destination State",
          width: "150px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.destination_state}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.destination_state}
              </td>
            );
          },
        },
        {
          field: "destination_postal_code",
          title: "Destination Zip",
          width: "140px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.destination_postal_code}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.destination_postal_code}
              </td>
            );
          },
        },
        {
          field: "destination_country_code",
          title: "Destination Country",
          width: "160px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.destination_country_code}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.destination_country_code}
              </td>
            );
          },
        },
        {
          field: "invoice_date",
          title: "Invoice Date ",
          width: "120px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.invoice_date}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.invoice_date}
              </td>
            );
          },
        },
        {
          field: "date_posted",
          title: "Process date",
          width: "120px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.date_posted}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.date_posted}
              </td>
            );
          },
        },
        {
          field: "payment_date",
          title: "Paid Date",
          width: "130px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.payment_date}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.payment_date}
              </td>
            );
          },
        },
        {
          field: "amount",
          title: "Check Amount",
          width: "130px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.amount}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.amount}
              </td>
            );
          },
        },
        {
          field: "cust_to_carrier_check_info",
          title: "Customer Payment Info",
          width: "180px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.cust_to_carrier_check_info}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.cust_to_carrier_check_info}
              </td>
            );
          },
        },
        {
          field: "amt_paid",
          title: "Amount Paid",
          width: "180px",
          cell: (props) => {
            return (
              <td
                title={props.dataItem.amt_paid}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {props.dataItem.amt_paid}
              </td>
            );
          },
        },
      ],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  async componentDidMount() {
    this.setState({ mounted: true });
    this.handleSearch();
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  

  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  handleClose = (event) => {
    this.setState({ anchorEl: false });
  };

  async handleSearch(pageNum = 1, rowCount = 25) {
    this.setState({
      initialized: false,
    });
    if (pageNum < 25) {
      pageNum = 1;
    }
    if (pageNum >= 25) {
      pageNum = pageNum / rowCount + 1;
    }
    let data = {
      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
    };
    const url =
      "/index.php?p=api&r=json&c=invoiceRemittanceReport&m=getByIssuedDate&d=" +
      pageNum +
      "/" +
      rowCount;

    this.setState({
      show: false,
      loading: true,
    });

    try {
      const response = await axios.post(url, qs.stringify(data));
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string") {
        this.setState({
          initialized: true,
          show: true,
          loading: false,
          result: response.data.body.result,
          totalCount: response.data.body.total,
          reportName: response.data.title,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error searching for Remittance Report!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error searching for Remittance Report!",
        { variant: "error" }
      );
    }
  }

  dataPageChange = (event) => {
    this.setState(
      {
        skip: event.page.skip,
        take: event.page.take,
        dataState: {
          skip: event.page.skip,
          take: event.page.take,
        },
      },
      () => {
        this.handleSearch(event.page.skip, event.page.take).then((res) => {
          this.setState({
            dataResult: process(this.state.result, this.state.dataState),
          });
        });
      }
    );
  };
  dataStateChange = (event) => {
    this.setState(
      {
        skip: event.page.skip,
        take: event.page.take,
      },
      () => {
        this.handleSearch(event.page.skip, event.page.take).then((res) => {
          this.setState({
            dataResult: process(this.state.result, this.state.dataState),
            dataState: event.dataState,
          });
        });
      }
    );
  };

  async exportReport() {
    this.setState({
      initialized: false,
    });
    let data = {
      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
    };
    const pageNum = 1;
    const rowCount = 1000000;
    const url =
      "/index.php?p=api&r=json&c=invoiceRemittanceReport&m=getByIssuedDate&d=" +
      pageNum +
      "/" +
      rowCount;

    try {
      const response = await axios.post(url, qs.stringify(data));

      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
      }
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.user) &&
        !_.isEmpty(response.data.body)
      ) {
        this.setState({
          initialized: true,
        });
        return response.data.body.result;
      } else {
        this.setState({
          initialized: true,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }

  exportExcel = () => {
    this.exportReport(1, 1000000).then((res) => {
      this._export.save(res);
    });
  };
  exportPDF = () => {
    this.exportReport(1, 1000000).then((res) => {
      this._pdfExport.save(res);
    });
  };


  async exportData(data_type){
    this.setState({
      initialized: false,
    });
    const url = "/index.php?p=api&r=json&c=invoiceRemittanceReport&m=exportRemittanceReportByDate";
    let data = {
      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
      data_type,
     };
    axios({
     url: url,
     method: 'POST',
     data: qs.stringify(data),
     responseType: 'blob',
   }).then((res) => {
        try{

          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;

          let contentDisposition = res.headers['content-disposition'];
          const fileName = contentDisposition.split("=");//extract the filename from here.

          link.setAttribute('download', fileName[1]);
          document.body.appendChild(link);
          link.click();
          this.setState({
          initialized: true,
        });

      }catch (error) {
        this.setState({ loading: false });
        this.props.setNotification("There was an error exporting the data!", {
          variant: "error",
        });
        this.setState({
          initialized: true,
        });
      }
   });
}

  render() {
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} style={{ paddingTop: "10px" }}>
            <Tooltip openDelay={100} position="center" anchorElement="element">
              <ExcelExport
                data={this.state.data}
                fileName={this.state.reportName}
                ref={(exporter) => {
                  this._export = exporter;
                }}
              >
                <Grid
                  style={{
                    height: "500px",
                    fontSize: "12px",
                    lineHeight: "0px",
                  }}
                  className={!this.state.initialized ? "k-state-disabled" : ""}
                  reorderable={true}
                  pageable={{
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [25, 50, 100],
                    previousNext: true,
                  }}
                  rowHeight={1}
                  sort={this.state.sort}
                  data={this.state.result}
                  total={this.state.totalCount}
                  skip={this.state.skip}
                  take={this.state.take}
                  pageSize={this.state.take}
                  onPageChange={this.dataPageChange}
                  onDataStateChange={this.dataStateChange}
                >
                  <GridToolbar>
                    <div style={{ marginRight: "50px", marginLeft: "20px" }}>
                      <b>- {this.state.reportName}</b>
                    </div>
                    <div style={{ paddingLeft: "10%" }}>
                      <div>
                        <button
                          title="Export to excel"
                          className="k-button k-primary"
                          onClick={(e)=>this.exportData("xls")} disabled={_.isEmpty(this.state.result)}
                        >
                          Export to excel
                        </button>
                        &nbsp;
                        <button
                          className="k-button k-primary"
                          onClick={(e)=>this.exportData("csv")} disabled={_.isEmpty(this.state.result)}
                        >
                          Export to CSV
                        </button>
                      </div>
                    </div>
                  </GridToolbar>
                  {this.state.columns.map((col, index) => {
                    return (
                      <Column
                        key={index}
                        field={col.field}
                        title={col.title}
                        width={col.width}
                        cell={col.cell}
                        locked={col.locked}
                      />
                    );
                  })}
                </Grid>
              </ExcelExport>
            </Tooltip>
            <GridPDFExport
              data={this.state.result}
              ref={(element) => {
                this._pdfExport = element;
              }}
              margin="1cm"
              fileName={this.state.reportName}
            >
              <Grid
                style={{
                  height: "500px",
                }}
                className={!this.state.initialized ? "k-state-disabled" : ""}
                sortable={true}
                groupable={{
                  footer: "visible",
                }}
                reorderable={true}
                pageable={{
                  buttonCount: 25,
                  pageSizes: true,
                }}
                data={this.state.result}
                total={this.state.totalCount}
                skip={this.state.skip}
                take={this.state.take}
                onPageChange={this.dataPageChange}
                onDataStateChange={this.dataStateChange}
              >
                <GridToolbar>
                  <label htmlFor="reportName">{this.state.reportName}</label>
                  <button
                    title="Export to excel"
                    className="k-button k-primary"
                    onClick={this.exportExcel}
                  >
                    Export to excel
                  </button>
                  &nbsp;
                </GridToolbar>
                {this.state.columns.map((col, index) => {
                  return (
                    <Column
                      key={index}
                      field={col.field}
                      title={col.title}
                      cell={col.cell}
                      width="200px"
                    />
                  );
                })}
              </Grid>
            </GridPDFExport>
            {!this.state.initialized && (
              <Loader
                size={"large"}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
                type={"infinite-spinner"}
              />
            )}
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(archivedBillsOfLadingStyle)(
  withSnackbar(invoiceRemittanceByDate)
);
