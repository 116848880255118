import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import { basePath } from "~/variables/server.jsx";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ScheduledReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      redirect: null,
      body: null,
      user: null,
      current: "1",
      last: "1",
      pages: [],
      alert: null,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleDeletereports = this.handleDeletereports.bind(this);
    this.getPages = this.getPages.bind(this);
  }
  async componentDidMount() {
    this.setState({ mounted: true });
    try {
      const response = await axios.get(
        "/index.php?p=api&r=json&c=reporting&m=scheduledReports"
      );
      const data = response.data;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (
        typeof data !== "string" &&
        !_.isEmpty(data.body) &&
        !_.isEmpty(data.user)
      ) {
        this.setState({
          show: true,
          loading: false,
          user: data.user,
          current: data.body.current ? data.body.current.toString() : "1",
          last: data.body.last ? data.body.last.toString() : "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
            variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
      });
    }
  }
  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  

  async handlePageClick(page) {
    const url =
      "/index.php?p=api&r=json&c=reporting&m=scheduledReports" + page + "/4";
    try {
      const response = await axios.get(url);
      const { data } = response;
      if (typeof data !== "string" && !_.isEmpty(data.message)) {
        this.props.handleMessages(response);
      }
      if (typeof data !== "string" && !_.isEmpty(data.body)) {
        this.setState({
          show: true,
          loading: false,
          current: data.body.current.toString() || "1",
          last: data.body.last.toString() || "1",
          pages: data.body.pages || [],
          result: data.body || null,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the reports!", {
            variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the reports!", {
          variant: "warning",
      });
    }
  }
  getPages() {
    const { current, last } = this.state;

    let pages = [{ active: true, text: "..." }];
    const nextPage = { text: ">" };
    const prevPage = { text: "<" };

    if (last > 1 && !_.isEmpty(this.state.pages)) {
      pages = this.state.pages.map((index) => {
        const page = { text: index };
        if (index == parseInt(current)) {
          page.active = true;
        } else {
          page.onClick = (e) => this.handlePageClick(index);
        }
        return page;
      });
    }

    if (current < last) {
      nextPage.onClick = (e) => this.handlePageClick(parseInt(current) + 1);
    } else {
      nextPage.disabled = true;
    }

    if (parseInt(current) - 1 !== 0) {
      prevPage.onClick = (e) => this.handlePageClick(parseInt(current) - 1);
    } else {
      prevPage.disabled = true;
    }

    pages.push(nextPage);
    pages.unshift(prevPage);

    return pages;
  }

  async deletereports(id) {
    const url =
      "/index.php?p=api&r=json&c=reportDefinitionScheduled&m=deleteScheduledReportDefinition&d=" +
      id;
    this.setState({ show: false, loading: true, alert: null });
    try {
      const response = await axios.get(url);
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
    } catch (error) {
      console.error(error);
      this.setState({ show: true, loading: false });
      this.props.setNotification(
        "There was an error deleting the report type!",
        { variant: "warning" }
      );
    }
  }
  handleDeletereports(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{
            display: "block",
            color: "black",
          }}
          title="Are you sure you want to delete this scheduled report?"
          onConfirm={() => this.deletereports(id)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, Delete It!"
          cancelBtnText="No!"
          showCancel
        >
          This action cannot be undone!
        </SweetAlert>
      ),
    });

  }
  getHeaders() {
    return [
      "ID",
      "Name",
      "Description",
      "Recipients",
      "Schedule",
      "Run timestamp",
      "Actions",
    ];
  }
  getColumns(data) {
    return data.map((prop, key) => {
      return [
        prop.id,
        prop.name,
        prop.description,
        prop.recipients,
        prop.schedule,
        moment.unix(prop.run_timestamp).format("LLLL"),
        <div className="actions-right">
          <NavLink to={basePath + "/admin/report-scheduler-edit/" + prop.id}>
            <Button
              size="sm"
              color="info"
              style={{
                marginRight: "4px",
              }}
            >
              Edit
            </Button>
          </NavLink>
          <Button
            size="sm"
            color="danger"
            style={{
              marginRight: "4px",
            }}
            onClick={() => this.handleDeletereports(prop.id)}
          >
            Delete
          </Button>
          <NavLink to={basePath + "/admin/report-schedule-history/" + prop.id}>
            <Button
              size="sm"
              color="info"
              style={{
                marginRight: "4px",
              }}
            >
              Report Schedule History
            </Button>
          </NavLink>
        </div>,
      ];
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.redirect) {
      return this.state.redirect;
    }

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem>

                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.center}>
                  <Pagination pages={this.getPages()} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {this.state.show ? (
                  <GridItem xs={12} sm={12} md={12}>
                    {!_.isEmpty(this.state.result) ? (
                      <Table
                        tableHead={this.getHeaders()}
                        tableData={this.getColumns(this.state.result)}
                        customCellClasses={[classes.right]}
                        customClassesForCells={[4]}
                        customHeadCellClasses={[classes.right]}
                        customHeadClassesForCells={[4]}
                      />
                    ) : (
                      <p className={classes.center}>
                        <b>{"No scheduled report to display"}</b>
                      </p>
                    )}
                  </GridItem>
                ) : (
                  <Spinner
                    loading={this.state.loading}
                    message="Failed to retrieve scheduled report from the server"
                  />
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(withSnackbar(ScheduledReportsContainer));
