import axios from '../../../variables/axios.jsx';
import { setNotification } from '../../../redux/actions/notifications.jsx';
import qs from "qs";

const setLoading = val => {
	return {type: "SET_TERMINAL_INFO_MODAL_LOADING", val};
}
const setOpen = val => {
	return {type: "SET_TERMINAL_INFO_MODAL_OPEN", val};
}
const setOrigin = val => {
	return {type: "SET_TERMINAL_INFO_ORIGIN", val};
}
const setDestination = val => {
	return {type: "SET_TERMINAL_INFO_DESTINATION", val};
}

export const closeModal = () => {
	return dispatch => {
		dispatch(setOpen(false));
	}
}

export const loadTerminalModal = (scac, data) => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			const response = await axios.post("/index.php?p=api&r=json&c=carrierConnect&m=terminalData&d=" + scac, qs.stringify(data));
			if (typeof response === "object" && response.data && response.data.body && response.data.body.origin && response.data.body.destination) {
				dispatch(setOrigin(response.data.body.origin));
				dispatch(setDestination(response.data.body.destination));
				dispatch(setOpen(true));
			} else {
				dispatch(setNotification("No terminal data found!", {variant: "info"}))
			}
		} catch (err) {
			dispatch(setNotification("Error loading terminal data", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setLoading(false));
		}
	}
}
