import React from "react";
import Datetime from "react-datetime";
import qs from "qs";


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserSetting } from "../../redux/selectors/Admin.jsx";

import _ from "lodash";
import moment from "moment";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { basePath, apiUrl } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Collections from "@material-ui/icons/Collections";
import ReportProblem from "@material-ui/icons/ReportProblem";

// react-icons
import { FaDownload, FaShippingFast, FaPallet } from "react-icons/fa";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Pagination from "~/components/Pagination/Pagination.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import NavPills from "~/components/NavPills/NavPills.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import DocumentModal from "~/components/DocumentModal/DocumentModal.jsx";
import InfoArea from "~/components/InfoArea/InfoArea.jsx";
import ParcelLookup from "../../components/ParcelLookup/components/ParcelLookup.jsx";
import { performLookup, clearParcelLookup } from "../../components/ParcelLookup/actions/ParcelLookup.jsx";
import PrintZplModal from "~/views/PrintZplModal/containers/PrintZplModal.jsx";
import { setJSPM, setAvailablePrinters, jspmStatus } from "~/views/PrintZplModal/actions/PrintZplModal.jsx";
import SearchModal from "~/components/Search/components/Search.jsx";
import ColumnListLayoutModal from "~/components/ColumnListLayoutModal/ColumnListLayoutModal.jsx";
import ArchivedParcelShipmentsList from "./ArchivedParcelShipmentsList.jsx";


import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { Icon } from "@material-ui/core";

class Transition extends React.Component {
	render() {
		return <Slide direction="down" {...this.props} />;
	}
}

class ArchivedParcelShipments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			user: null,
			result: [],
			selectedShipmentId: "",
			current: 1,
			last: 1,
			prev: "",
			next: "",
			alert: null,
			tracking_info: {},
			warehouse: "",
			warehouses: [],
			carrier: "FEDEX",
			ship_date: {},
			images: [],
			key: null,
			info: {},
			loadingShipment: false,
			trackingInfoModal: false,
			generateReportModal: false,
			shipmentInfoModal: false,
			JSPM: null,
			zpl: "",
			docZpl: "",
			printLabelModal: false,
			printDocModal: false,
			printers: [],
			printer: "",
			imageFiles: [],
			refTypes: [],
			searchFields: this.getSearchFields(),
			filters: [
				{
					field: "so",
					value: "",
					value2: ""
				}
			],
			defaultColumns: [],
			customColumns: [],
		};
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.viewLabels = this.viewLabels.bind(this);
		this.handleVoid = this.handleVoid.bind(this);
		this.cancelShipment = this.cancelShipment.bind(this);
		this.trackShipment = this.trackShipment.bind(this);
		this.getStatusUpdates = this.getStatusUpdates.bind(this);
		this.loadShipment = this.loadShipment.bind(this);
		this.getTabs = this.getTabs.bind(this);
		this.getPrinters = this.getPrinters.bind(this);
		this.printLabel = this.printLabel.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.deleteImage = this.deleteImage.bind(this);
		this.getImages = this.getImages.bind(this);
		this.handleImageClick = this.handleImageClick.bind(this);
	}

	async componentDidMount() {
		const { JSPM } = window;
		this.props.setJSPM(JSPM);
		this.setState({ mounted: true});
		let filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);

		try {
			filters = !_.isEmpty(filters) ? filters : this.state.filters;
			const url = "/index.php?p=api&r=json&c=parcel&m=parcelPostingPanel";
			const data = { filters: filters };
			const response = await axios.post(url, qs.stringify(data));

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && this.state.mounted) {
				const { data } = response;
				let warehouse = { code: "" };
				if (!_.isEmpty(data.body.warehouses)) {
					if (!_.isEmpty(data.body.warehouses.filter(warehouse => warehouse.is_default == "1"))) {
						warehouse = data.body.warehouses.filter(warehouse => warehouse.is_default == "1")[0];
					} else {
						warehouse = data.body.warehouses[0];
					}
				}
				const current = data.body.current.toString() || "1";
				const last = data.body.last.toString() || "1";
				const page = this.getPage(current, last);
				const customColumns = await this.getParcelLoadBoardBoardColumns();
				this.setState({
					show: true,
					user: data.user,
					current: current,
					last: last,
					prev: page.prev,
					next: page.next,
					result: Object.values(data.body.result) || [],
					warehouse: warehouse.code || "",
					warehouses: data.body.warehouses || [],
					filters: filters,
					customColumns
				}, () => {
					this.getReferences();
					this.setState({ loading: false });
					const filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);
					if(filters.length) {
						this.handleSearch(filters);
					}
				});

			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	jspmStatus() {
		return this.props.jspmStatus()
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.code}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	async handlePageClick(page) {
		this.setState({ show: true, loading: true });

		let filters = this.props.searchFilters.filter(f => f.route == this.props.location.pathname);
		try {
			const url = "/index.php?p=api&r=json&c=parcel&m=parcelPostingPanel&d=" + page + "/20";
			const data = { filters: filters };
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && this.state.mounted) {
				const { body } = response.data;
				const current = body.current.toString() || "1";
				const last = body.last.toString() || "1";
				const page = this.getPage(current, last);
				this.setState({
					show: true,
					loading: false,
					current: current,
					last: last,
					prev: page.prev,
					next: page.next,
					result: Object.values(body.result) || []
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the quotes!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the quotes!", { variant: "error" });
		}
	}

	getPage = (current, last) => {
		let currentPage = 1;
		let pageCount = 1;
		let page = {prev: "", next: "" };

		if (!_.isEmpty(current) && current != 1) {
			currentPage = parseInt(current);
		}

		if (!_.isEmpty(last) && last != 1) {
			pageCount = parseInt(last);
		}

		page.next = currentPage < pageCount ? currentPage + 1 : "";
		page.prev = currentPage - 1 !== 0 ? currentPage - 1 : "";

		return page;
	}

	async viewLabels(shipmentId, labelType) {
		if (labelType == "image") {
			const labelImgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId, "Shipping label(s)", "resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
			if (labelImgWindow) {
				try {
					labelImgWindow.focus();
				} catch (error) {
					console.error(error);
					this.props.setNotification("There was an error loading the labels!", { variant: "error" });
				}
			}
		} else {
			try {
				const url = "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId;
				const response = await axios.get(url);

				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}

				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					if(this.props.JSPM == null) {
						const { JSPM } = window;
						this.props.setJSPM(JSPM);
						this.props.setAvailablePrinters(JSPM);
					}
					const self = this;

					JSPM.JSPrintManager.start().then(() => {
						JSPM.JSPrintManager.WS.onStatusChanged = () => {
							if (self.jspmStatus()) {
								JSPM.JSPrintManager.getPrinters().then(myPrinters => {
									const printers = [];
									for (var i = 0; i < myPrinters.length; i++) {
										printers.push(myPrinters[i]);
									}
									if (!_.isEmpty(printers)) {
										self.setState({ printers });
									}
								});
							} else {
								self.setState({ printLabelModal: false });
							}
						};
					}, () => {
						self.jspmStatus();
					});

					this.setState({
						zpl: response.data.body.commands,
						printer: "",
						printLabelModal: true
					});
				} else {
					this.props.setNotification("There was an error viewing the labels!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error viewing the labels!", { variant: "error" });
			}
		}
	}

	async viewDgDocs(shipmentId, docInfo) {

		const options = docInfo.options;
		const type = docInfo.selection;

		if(type == "ALL") {
			let imgWindow = null;
			for(let option in options) {
				if(option != "ALL") {
					imgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewDgDocuments&id=" + shipmentId + "&type=" + option, "Dangerous Goods Documents " + option, "resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
					if (imgWindow) {
						try {
							imgWindow.focus();
						} catch (error) {
							console.error(error);
							this.props.setNotification("There was an error loading the documents!", { variant: "error" });
						}
					}
					else {
						this.props.setNotification("If multiple windows did not open, check your browsers popup blocker.", { variant: "info" })
					}

				}
			}
		} else {
			if(this.props.dgDocTypes.includes(type)) {
				const imgWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=viewDgDocuments&id=" + shipmentId + "&type=" + type, "Dangerous Goods Documents " + type, "resizable=1, scrollbars=1, fullscreen=0, height=900, width=850, toolbar=0, menubar=0, status=0");
				if (imgWindow) {
					try {
						imgWindow.focus();
					} catch (error) {
						console.error(error);
						this.props.setNotification("There was an error loading the documents!", { variant: "error" });
					}
				}
			}
		}

	}
	viewDocs = async (shipmentId, docInfo) => {
		this.viewDgDocs(shipmentId, docInfo);
		this.viewNonDgDocs(shipmentId, docInfo);
	}

	viewNonDgDocs = async (shipmentId, docInfo) => {
		if(this.props.JSPM == null) {
			const { JSPM } = window;
			this.props.setJSPM(JSPM);
			this.props.setAvailablePrinters(JSPM);
		}

		let zpl = "";
		const type = docInfo.selection;
		const options = docInfo.options;
		// for now we only have the DG docs and costco label, but in the future we could support other doc types (ZPL the same as below, or PDF/image docs as we do in viewDgDocs)
		// get the ZPL of each file and combine it into a single string to print below.
		if(!this.props.dgDocTypes.includes(type)) {
			if(this.props.zplDocTypes.includes(type)) {
				const response = await axios.get("/index.php?p=api&c=parcelShipService&m=viewDocuments&id=" + shipmentId + "&type=" + type);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.handleMessages(response.data.message);
				}
				if(response && response.data && response.data.body && response.data.body.document) {
					zpl = zpl + response.data.body.document;
				}
			}
		}
		if(zpl != "") {
			this.setState({
				docZpl: zpl,
				printer: "",
				selectedShipmentId: "",
				printDocModal: true,
				showDocumentModal: false,
			});
		}
	}

	handleVoid(shipmentId) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to cancel this shipment?"
					onConfirm={() => this.cancelShipment(shipmentId)}
					onCancel={() => this.cancelShipment()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, cancel it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async cancelShipment(shipmentId) {
		this.setState({ alert: null });

		if (_.isEmpty(shipmentId)) {
			return;
		}
		let result = this.state.result.map((prop, key) => {
			if (prop.shipment_id === shipmentId) {
				prop.voiding = true;
			}
			return prop;
		});
		this.setState({ result })
		const data = { shipmentId };

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=parcelShipService&m=cancelShipment", qs.stringify(data), {timeout: 50000});
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				const result = this.state.result.map((prop, key) => {
					if (prop.shipment_id === shipmentId) {
						prop.cancelled = 1;
						prop.voiding = false;
					}
					return prop;
				});
				this.setState({ result });
			} else {
				const result = this.state.result.map((prop, key) => {

					prop.voiding = false;

					return prop;
				});
				this.setState({ result });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error cancelling the shipment!", {
				variant: "error"
			});
			const result = this.state.result.map((prop, key) => {

				prop.voiding = false;

				return prop;
			});
			this.setState({ result });
		}
	}
	handleReopen(shipmentId) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Before re-opening this shipment - you must destroy all previous shipping labels!"
					onConfirm={() => this.reopenShipment(shipmentId)}
					onCancel={() => this.reopenShipment()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Re-open Shipment"
					cancelBtnText="Cancel"
					showCancel
				>
				</SweetAlert>
			)
		});
	}
	async reopenShipment(shipmentId) {
		let result = this.state.result.map((prop, key) => {
			if (prop.shipment_id === shipmentId) {
				prop.reopening = true;
			}
			return prop;
		});
		this.setState({ result, alert: null });

		if (_.isEmpty(shipmentId)) {
			return;
		}

		try {
			const url = "/index.php?p=api&r=json&c=parcelShipService&m=reopenShipment&d=" + shipmentId;
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body.reopened) {
				result = result.map((prop, key) => {
					if (prop.shipment_id === shipmentId) {
						prop.reopening = false;
						prop.cancelled = 0;
						prop.status = 'hold';
						prop.carrier = null;
						prop.shipper = null;
						prop.ship_date = null;
					}
					return prop;
				});
				this.setState({ result });
			} else {
				let result = this.state.result.map((prop, key) => {
					if (prop.shipment_id === shipmentId) {
						prop.reopening = false;
					}
					return prop;
				});
				this.setState({ result });
				this.props.setNotification("The shipment was not re-opened.", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			let result = this.state.result.map((prop, key) => {
				if (prop.shipment_id === shipmentId) {
					prop.reopening = false;
				}
				return prop;
			});
			this.setState({ result });
			this.props.setNotification("There was an error re-opening the shipment!", {
				variant: "error"
			});
		}
	}
	async trackShipment(shipmentId) {
		let result = this.state.result.map((prop, key) => {
			if (prop.shipment_id === shipmentId) {
				prop.tracking = true;
			}
			return prop;
		});
		this.setState({ result });
		try {
			const url = "/index.php?p=api&r=json&c=parcelShipService&m=trackShipment&d=" + shipmentId;
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				result = result.map((prop, key) => {
					if (prop.shipment_id === shipmentId) {
						prop.tracking = false;
					}
					return prop;
				});
				let trackingInfoModal = false;
				const tracking_info = response.data.body;
				if (!_.isEmpty(tracking_info)) {
					trackingInfoModal = true;
				}
				this.setState({
					result,
					tracking_info,
					trackingInfoModal
				});
			} else {
				this.props.setNotification("There was an error tracking the shipment!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error tracking the shipment!", {
				variant: "error"
			});
		}
	}

	getStatusUpdates(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridItem xs={12} key={key}>
					<Paper className={classes.infoPaper} elevation={1}>
						<GridContainer className={classes.left}>
							<GridItem xs={4}>
								<strong>Message: </strong> {prop.status}
							</GridItem>
							<GridItem xs={4}>
								<strong>Date: </strong> {prop.date}
							</GridItem>
							<GridItem xs={4}>
								<strong>Location: </strong> {!_.isEmpty(prop.location) && !_.isEmpty(prop.location.city) && prop.location.city + ", " + prop.location.state}
							</GridItem>
						</GridContainer>
					</Paper>
				</GridItem>
			);
		});
	}

	async generateReport() {
		let { warehouse, carrier, ship_date } = this.state;
		let shipDate = moment().format("YYYY-MM-DD");

		if (!_.isEmpty(ship_date)) {
			shipDate = moment(ship_date).format("YYYY-MM-DD");
		}

		warehouse = encodeURIComponent(warehouse);
		carrier = encodeURIComponent(carrier);
		shipDate = encodeURIComponent(shipDate);

		const reportWindow = window.open(apiUrl + "/index.php?p=api&c=parcelShipService&m=generateCloseReports&wh=" + warehouse + "&date=" + shipDate + "&carrier=" + carrier, "Parcel Report", "resizable=1, scrollbars=1, fullscreen=1, height=1600, width=1800, toolbar=0, menubar=0, status=0");

		if (reportWindow) {
			try {
				reportWindow.focus();
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error generating the report!", {
					variant: "error"
				});
			}
		}
	}

	clearFilters = async () => {
		this.handleSearch([{field: "so", value: "", value2: ""}], true);
	}

	handleSearch = async (filters, clear = false) => {
		this.setState({show: false, loading: true});

		const data = { filters: filters };
		let url = "/index.php?p=api&r=json&c=parcel&m=parcelPostingPanel&d=1/20";
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {

				const body = response.data.body;
				const page = this.getPage(body.current.toString() || "1", body.last.toString() || "1");
				this.setState({
					loading: false,
					show: true,
					current: body.current,
					last: body.last,
					result: Object.values(body.result),
					prev: page.prev,
					next: page.next
				});
			} else {
				this.props.setNotification("There was an error searching for shipments!", { variant: "error" });
				this.setState({loading: false});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching for shipments!", { variant: "error" });
			this.setState({loading: false});
		}
	}

	async loadShipment(key) {
		const { result } = this.state;
		result[key].loading = true;
		this.setState({ result });

		const shipmentId = result[key].shipment_id;
		const carrierName = result[key].carrier;
		const shipDate = result[key].ship_date ? moment(result[key].ship_date).format("YYYY-MM-DD") : "";

		const imgUrl = "/index.php?p=api&c=parcelShipService&m=imageCollection&d=" + shipmentId;
		const infoUrl = "/index.php?p=api&r=json&c=parcelShipService&m=getShipmentDetail&d=" + shipmentId;

		try {
			const imgResponse = await axios.get(imgUrl);
			const infoResponse = await axios.get(infoUrl);

			if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.message)) {
				this.props.handleMessages(imgResponse);
			}

			if (typeof infoResponse.data !== "string" && !_.isEmpty(infoResponse.data.message)) {
				this.props.handleMessages(infoResponse);
			}

			if (typeof imgResponse.data !== "string" && !_.isEmpty(imgResponse.data.body)) {
				this.setState({ imageFiles: imgResponse.data.body.images || [] });
			} else {
				this.props.setNotification("There was an error loading the images!", { variant: "error" });
			}

			if (typeof infoResponse.data !== "string" && !_.isEmpty(infoResponse.data.body)) {
				result[key].loading = false;
				this.setState({
					result,
					key,
					info: infoResponse.data.body,
					shipmentInfoModal: true
				});
			} else {
				result[key].loading = false;
				this.setState({ result });
				this.props.setNotification("There was an error loading the shipment information! Please contact support!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			result[key].loading = false;
			this.setState({ result });
			this.props.setNotification("There was an error loading the shipment data! Please contact support!", {
				variant: "error"
			});
		}
	}

	getPackages(data) {
		return data.map((prop, key) => {
			var trackingNum = (prop.tracking_id != null && prop.tracking_id != "") ? prop.tracking_id : 'N/A';
			var length = (prop.length != null && prop.length != "0") ? prop.length + " in." : 'N/A';
			var width = (prop.width != null && prop.width != "0") ? prop.width + " in." : 'N/A';
			var height = (prop.height != null && prop.height != "0") ? prop.height + " in." : 'N/A';
			var weight = prop.weight != null ? prop.weight + " lbs." : '';
			var declaredValue = (prop.declared_value != "" && prop.declared_value != null) ? "$ " + prop.declared_value : null;
			var hazmat = prop.is_dg ?
				<InfoArea
					title={"UN#:" + prop.un_num}
					description={prop.units + " " + prop.uom}
					icon={ReportProblem}
					iconColor="danger"
				/> : null;

			let retVal = [trackingNum, prop.type, length, width, height, weight];
			if(declaredValue != null) {
				retVal.push(declaredValue);
			}
			if(hazmat != null) {
				retVal.push(hazmat);
			}

			return retVal;
		});
	}

	getPackagesHeaders(data) {
		let retVal = ["Package Tracking #", "Type", "Length", "Width", "Height", "Weight"];

		let tempData = (data.filter(line => (line.declared_value != '' && line.declared_value != null)));
		if(tempData.length > 0) {
			retVal.push('Declared Value');
		}

		tempData = (data.filter(line => line.is_dg == true));
		if(tempData.length > 0) {
			retVal.push('HazMat')
		}

		return retVal;
	}

	getCustomRefs(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridContainer key={key}>
					<GridItem>
						<b>{prop.type} :</b> {prop.value}
					</GridItem>
				</GridContainer>
			)
		});
	}

	getTabs(info) {
		const { classes } = this.props;
		const { key, result } = this.state;
		const data = !_.isEmpty(result) ? result[key] : null;
		const tabs = [];
		let pickupInfo = null;
		let customRefs = null;
		let packageCount = 0;
		let totalWeight = 0.0;

		if (_.isEmpty(data)) {
			return tabs;
		}

		if (!_.isEmpty(info.pickup_confirmation_number)) {
			pickupInfo = (
				<GridContainer>
					<GridItem xs>
						<h5>Pickup Information</h5>
						<b>Confirmation #:</b> {info.pickup_confirmation_number}
					</GridItem>
				</GridContainer>
			);
		}

		if (!_.isEmpty(info.references)) {
			customRefs = this.getCustomRefs(info.references);
		}

		tabs.push({
			tabButton: "General Information",
			tabIcon: () => <span style={{ marginTop: '21px', marginBottom: '0px'}} ><Icon component={InfoOutlined} size="8x" /> </span>,
			tabContent: (
				<span>
					<GridItem xs={12} className={classes.left}>
						<Paper className={classes.infoPaper} elevation={1}>
							<GridContainer>
								<GridItem xs>
									<b>Shipment ID:</b> {info.shipment_id}
								</GridItem>
								<GridItem xs>
									<b>Master Tracking #:</b> {info.master_tracking_id}
								</GridItem>
								<GridItem xs>
									<b>Order/SO #:</b> {info.so}
								</GridItem>
								<GridItem xs>
									<b>PO#:</b> {info.po}
								</GridItem>
								<GridItem xs>
									<b>Department:</b> {info.dept}
								</GridItem>
								<GridItem xs>
									<b>Ref#:</b> {info.ref}
								</GridItem>
							</GridContainer>
							{!_.isEmpty(data.carrier) && (
								<GridContainer>
									<GridItem xs>
										<b>Carrier Name:</b> {data.carrier}
									</GridItem>
									<GridItem xs>
										<b>Ship Date:</b> {data.ship_date ? moment(data.ship_date).format("YYYY-MM-DD") : ""}
									</GridItem>
									<GridItem xs>
										<b>Service Type:</b> {info.service_type}
									</GridItem>
									<GridItem xs />
								</GridContainer>
							)}
							{!_.isEmpty(pickupInfo) && pickupInfo}
						</Paper>
						{!_.isEmpty(customRefs) && (
							<Paper className={classes.infoPaper} elevation={1} style={{marginTop: '10px'}}>
								<GridContainer>
									<GridItem>
										<h5>Custom References:</h5>
									</GridItem>
								</GridContainer>
								{customRefs}
							</Paper>
						)}
					</GridItem>
				</span>
			)
		});

		if(!_.isEmpty(info.marked_up.total_charge)) {
			info.rated_amount = info.marked_up.total_charge;
			info.total_cost = info.marked_up.total_charge;
		}

		const validatedFields = ['rated_amount', 'billing_account_number', 'gross_freight', 'net_freight', 'total_cost', 'total_discounts'];
		Object.keys(info).map((key, value) => {
			if(validatedFields.includes(key) && (info[key] == '0' || info[key] == '0.00' || info[key] == '' || info[key] == null)) {
				info[key] = 'N/A';
			}
		});

		if(info.status != 'canceled' && info.billing_type) {
			tabs.push({
				tabButton: "Billing Information",
				tabIcon: () => <span style={{ marginTop: '21px', marginBottom: '0px'}} ><Icon component={AttachMoney} size="8x" /> </span>,
				tabContent: (
					<span>
						<GridItem xs={12} className={classes.left}>
							<Paper className={classes.infoPaper} elevation={1}>
								<GridContainer>
									<GridItem xs>
										<b>Type:</b> {info.billing_type}
									</GridItem>
									<GridItem xs>
										<b>Account #:</b> {info.billing_account_number}
									</GridItem>
									{this.props.hideParcelRateDetails ? (
										<GridItem xs>
										<b>Total Cost: </b> {info.total_cost}
										</GridItem>
									) : (
										<>
											<GridItem xs>
												<b>Gross Freight: </b> {info.gross_freight}
											</GridItem>
											<GridItem xs>
											</GridItem>
										</>
									)}
								</GridContainer>
								<br/>
								{!this.props.hideParcelRateDetails && (
									<GridContainer>
										<GridItem xs>
											<b>Total Discounts: </b> {info.total_discounts}
										</GridItem>
										<GridItem xs>
											<b>Surcharge(s): </b> {info.surcharges.map((key, value) => {
												return (
													<GridItem xs>
														<b>{key.surcharge_type}:</b> {key.surcharge_amount}
													</GridItem>
												)
											})}
										</GridItem>
										<GridItem xs>
											<b>Net Freight: </b> {info.net_freight}
										</GridItem>
										<GridItem xs>
											<b>Total Cost: </b> {info.total_cost}
										</GridItem>
									</GridContainer>
								)}
							</Paper>
						</GridItem>
					</span>
				)
			});
		}

		tabs.push({
			tabButton: "Shipment Details",
			tabIcon: FaShippingFast,
			tabContent: (
				<span>
					<GridItem xs={12} className={classes.left}>
						<Paper className={classes.infoPaper} elevation={1}>
							<GridContainer>
								{!_.isEmpty(info.shipper.name) && (
									<GridItem xs>
										<h6>Shipper</h6>
										<div>
											<b>Name:</b> {info.shipper.name}
										</div>
										<div>
											<b>Address:</b>
											<address>
												<div>{info.shipper.address1}</div>
												<div>{info.shipper.address2}</div>
											</address>
										</div>
										<div>
											<b>City:</b> {info.shipper.city}
										</div>
										<div>
											<b>State:</b> {info.shipper.state}
										</div>
										<div>
											<b>Zip:</b> {info.shipper.zip}
										</div>
										<div>
											<b>Country:</b> {info.shipper.country}
										</div>
									</GridItem>
								)}
								<GridItem xs>
									<h6>Consignee</h6>
									<div>
										<b>Name:</b> {info.consignee.name}
									</div>
									<div>
										<b>Address:</b>
										<address>
											<div>{info.consignee.address1}</div>
											<div>{info.consignee.address2}</div>
										</address>
									</div>
									<div>
										<b>City:</b> {info.consignee.city}
									</div>
									<div>
										<b>State:</b> {info.consignee.state}
									</div>
									<div>
										<b>Zip:</b> {info.consignee.zip}
									</div>
									<div>
										<b>Country:</b> {info.consignee.country}
									</div>
								</GridItem>
							</GridContainer>
						</Paper>
					</GridItem>
				</span>
			)
		});

		for (const prop of info.package_detail) {
			packageCount += !_.isEmpty(prop.qty) ? parseInt(prop.qty) : 1;
			totalWeight += prop.weight * (!_.isEmpty(prop.qty) ? parseInt(prop.qty) : 1);
		}

		if(!_.isEmpty(info.package_detail) && info.package_detail[0].type != null) {
			tabs.push({
				tabButton: "Product Information",
				tabIcon: FaPallet,
				tabContent: (
					<span>
						<GridItem xs={12} className={classes.center}>
							<h5>Actual Weight: {totalWeight} lbs.</h5>
							<h5>Package Count: {packageCount}</h5>
							<Table hover tableHead={this.getPackagesHeaders(info.package_detail)} tableData={this.getPackages(info.package_detail)} />
						</GridItem>
					</span>
				)
			});
		}

		tabs.push({
			tabButton: "Uploaded Images",
			tabIcon: () => <span style={{ marginTop: '21px', marginBottom: '0px'}} ><Icon component={Collections} size="8x" /> </span>,
			tabContent: (
				<span>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={6}>
								<Dropzone accept="image/jpeg,image/png,image/gif,application/octet-stream" handleUpload={this.handleUpload.bind(this)} />
							</GridItem>
							<GridItem xs={12} sm={12} md={6}>
								<aside className={classes.thumbsContainer}>{!_.isEmpty(this.state.imageFiles) && this.getImages(this.state.imageFiles)}</aside>
							</GridItem>
						</GridContainer>
					</GridItem>
				</span>
			)
		});

		return tabs;
	}

	getPrinters(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	printLabel() {
		const { JSPM, printer, zpl } = this.state;

		const cpj = new JSPM.ClientPrintJob();
		cpj.clientPrinter = new JSPM.InstalledPrinter(printer);

		cpj.printerCommands = zpl; // Set printer commands...
		cpj.sendToClient(); // Send print job to printer!

		this.setState({ printLabelModal: false });
	}

	getParcelLoadBoardBoardColumns = async () => {
		let cols = [];
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=parcel&m=getParcelLoadBoardBoardColumns`);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (response && response.data && response.data.body && response.data.body.columns) {
				cols = response.data.body.columns
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("Error getting custom layout", { variant: "error" });
		}

		return cols;
	}

	saveLoadBoardColumns = async (cols) => {
		try {
			const url = `/index.php?p=api&r=json&c=parcel&m=saveParcelLoadBoardBoardColumns`;
			const response = await axios.post(url, qs.stringify(cols));
			if (typeof response !== "string" && !_.isEmpty(response.data) && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if(typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.updated) {
				this.props.setNotification("Custom layout saved!", {variant: "success"});
				this.setState({customColumns: cols});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("Error saving custom layout", { variant: "error" });
		}
	}

	setDefaultColumns = (cols) => {
		if(_.isEmpty(this.state.defaultColumns)) {
			this.setState({defaultColumns: cols});
		}
	}

	setCustomColumns = (cols) => {
		this.setState({customColumns: cols});
	}

	async loadSavedImages() {
		const url = "/index.php?p=api&c=parcelShipService&m=imageCollection&d=" + this.state.info.shipment_id;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.body.images)) {
				this.setState({ imageFiles: response.data.body.images });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the images!", { variant: "error" });
		}
	}

	getImages(data) {
		return data.map((prop, key) => {
			const src = prop.preview ? prop.preview : apiUrl + "/index.php?p=api&c=parcelShipService&m=downloadImg&d=" + prop.id + "&thumb=1";
			const filename = !_.isEmpty(prop.filename) ? prop.filename : prop.name;
			const extension = filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "");
			return <Preview key={key} src={src} index={key} extension={extension} handleClick={this.handleImageClick.bind(this)} handleDelete={this.handleDeleteImage.bind(this)} />;
		});
	}

	handleImageClick(i) {
		const { imageFiles } = this.state;
		const image = imageFiles[i];
		if (image.id) {
			const url = apiUrl + "/index.php?p=api&c=parcelShipService&m=downloadImg&d=" + image.id;
			const title = "Image Preview";
			const options = "resizable=1, scrollbars=1, fullscreen=0, status=0";
			window.open(url, title, options);
		}
	}

	async handleUpload(files) {
		this.setState({ imageFiles: this.state.imageFiles.concat(files) });

		if (!_.isEmpty(files)) {
			const data = new FormData();
			let imgCount = 0;
			const shipmentId = this.state.info.shipment_id;

			for (let i = 0; i < this.state.imageFiles.length; i++) {
				const file = this.state.imageFiles[i];

				if (file.preview) {
					data.append("imgFile_" + i.toString(), file);
					imgCount++;
				}
			}

			const config = {
				headers: {
					"content-type": "multipart/form-data"
				}
			};

			const url = "/index.php?p=api&c=parcelShipService&m=uploadImg&d=" + shipmentId;

			try {
				const response = await axios.post(url, data, config);

				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}

				if (response.data.body && response.data.body.uploadedImages) {
					this.loadSavedImages();

					this.props.setNotification("File uploaded successfully!", { variant: "success" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error uploading the image(s)!", { variant: "error" });
			}
		}
	}

	handleDeleteImage(key) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this image?"
					onConfirm={() => this.deleteImage(key)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async deleteImage(i) {
		const { imageFiles } = this.state;
		const image = imageFiles[i];
		this.setState({ alert: null });

		try {
			const response = await axios.get("/index.php?p=api&c=parcelShipService&m=deleteImg&d=" + image.id);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && response.data.body.deleted === 1) {
				imageFiles.splice(i, 1);
				this.setState({ imageFiles });
				this.props.setNotification("Image successfully deleted!", {
					variant: "success"
				});
			} else {
				this.props.setNotification("There was an error deleting the image!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the image!", { variant: "error" });
		}
	}

	handleDocumentModal = (event) => {
		this.viewDocs(this.state.selectedShipmentId, event);
	}


	showDocsModal(shipmentId) {
		this.setState({
			selectedShipmentId: shipmentId,
			showDocumentModal: true,
		});
	}

	closeDocumentModal = (event) => {

		this.setState({
			selectedShipmentId: "",
			showDocumentModal: false,
		});
	}

	async getReferences() {
		try {
			const response = await axios.get('/index.php?p=api&r=json&c=userSettings&m=getReferenceTypes');
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				const refTypes = response.data.body.map((ref) => {
					return {name: ref, value: ref}
				})

				this.setState({searchFields: this.getSearchFields(refTypes), refTypes: refTypes, loading: false});
			}
		} catch (error) {
			this.setState({loading: false});
			console.error(error);
			this.props.setNotification("There was an error loading the defaults!", { variant: "error" });
		}
	}

	getSearchFields = (refs) => {
		let searchFields = [
			{ name: "Order/SO#", value: "so" },
			{ name: "Custom Reference", value: "customReference", type: "select_with_text", options: refs},
			{ name: "Shipment ID", value: "sid" },
			{ name: "Date", value: "date", type:"date" },
			{ name: "Carrier", value: "carrier" },
			{ name: "PO#", value: "po" },
			{ name: "Department", value: "dept" },
			{ name: "Ref#", value: "ref" },
			{ name: "Shipper Name", value: "shipper_name" },
			{ name: "Orig. Zip", value: "shipper_zip" },
			{ name: "Orig. City", value: "shipper_city" },
			{ name: "Orig. State", value: "shipper_state" },
			{ name: "Consignee Name", value: "consignee_name" },
			{ name: "Dest. Zip", value: "consignee_zip" },
			{ name: "Dest. City", value: "consignee_city" },
			{ name: "Dest. State", value: "consignee_state" },
			{ name: "Status", value: "status", type: "select", options: [
				{ name:"Hold", value: "hold" },
				{ name:"Executed", value: "executed" },
				{ name:"Cancelled", value: "cancelled" },
			]},
		];

		return searchFields;
	}

	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer style={{padding: "0 15px"}}>
								<GridItem xs={12} sm={12} md={9}>
									<GridContainer>
										<GridItem xs={12}>
											{(this.state.showDocumentModal) && (
											<DocumentModal
												title={<span>Select which document you would like to view.</span>}
												open={this.state.showDocumentModal}
												onSubmit={this.handleDocumentModal}
												onClose={this.closeDocumentModal}
												parcelId={this.state.selectedShipmentId}
											/>)}
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={12}>
									<GridContainer>
										<GridItem xs={12} md={6} className={classes.left}>
											<ColumnListLayoutModal
												buttonType="primary"
												fetchColumns={() => {}}
												listColumns={this.state.defaultColumns}
												customListColumns={this.state.customColumns}
												updateCols={() => {}}
												saveCustomColumns={(items) => this.saveLoadBoardColumns(items)}
												applyCustomColumns={(items) => this.setCustomColumns(items)}
												loading={this.state.loading}
											/>
											<SearchModal
												fields={this.state.searchFields}
												onClear={this.clearFilters}
												hideFilterType
												onSearch={this.handleSearch}
												loading={this.state.loading}
											/>
										</GridItem>
										<GridItem xs={3} md={6} className={classes.right}>
											<Button color="success" onClick={() => this.handleModalOpen("generateReportModal")}>
												<FaDownload /> Reports
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<ArchivedParcelShipmentsList 
										loading={this.state.loading}
										results={this.state.result}
										pageSize={'20'}
										pageNumber={this.state.current}
										prev={this.state.prev}
										next={this.state.next}
										user={this.state.user}
										customColumns={this.state.customColumns}
										setPageNumber={this.handlePageClick}
										loadShipment={(key) => this.loadShipment(key)}
										viewLabels={(id, labelType) => this.viewLabels(id, labelType)}
										showDocsModal={(id) => this.showDocsModal(id)}
										handleVoid={(id) => this.handleVoid(id)}
										trackShipment={(id) => this.trackShipment(id)}
										handleReopen={(id) => this.handleReopen(id)}
										setDefaultColumns={this.setDefaultColumns}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.generateReportModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("generateReportModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("generateReportModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Generate End of Day Reports</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody + " " + classes.left}>
						<GridContainer>
							<GridItem xs={6}>
								<InputLabel htmlFor="warehouse" className={classes.label}>
									Warehouse/Facility
								</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{ select: classes.select }}
										value={this.state.warehouse}
										inputProps={{
											id: "warehouse",
											name: "warehouse"
										}}
										onChange={this.handleChange}
									>
										{!_.isEmpty(this.state.warehouses) ? (
											this.getWarehouses(this.state.warehouses)
										) : (
											<MenuItem
												disabled
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
											>
												No Warehouses
											</MenuItem>
										)}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs={6}>
								<InputLabel htmlFor="carrier" className={classes.label}>
									Carrier
								</InputLabel>
								<br />
								<FormControl fullWidth className={classes.selectFormControl}>
									<Select
										MenuProps={{ className: classes.selectMenu }}
										classes={{ select: classes.select }}
										value={this.state.carrier}
										inputProps={{
											id: "carrier",
											name: "carrier"
										}}
										onChange={this.handleChange}
									>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="FEDEX"
										>
											FedEx
										</MenuItem>
										<MenuItem
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected
											}}
											value="UPS"
										>
											UPS
										</MenuItem>
									</Select>
								</FormControl>
							</GridItem>
							<GridItem xs={12}>
								<InputLabel className={classes.label}>Shipping Date (leave blank for current date)</InputLabel>
								<br />
								<FormControl fullWidth>
									<Datetime
										timeFormat={false}
										onChange={m => this.handleDatetime("ship_date", m)}
										className={classes.datetime}
										inputProps={{
											id: "ship_date",
											name: "ship_date",
											placeholder: "Select Shipping Date"
										}}
									/>
								</FormControl>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("generateReportModal")} color="white" style={{ marginRight: "4px" }}>
							Cancel
						</Button>
						<Button onClick={() => this.generateReport()} color="linkedin" style={{ marginRight: "4px" }}>
							Generate
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.trackingInfoModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("trackingInfoModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("trackingInfoModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Tracking Information</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						{!_.isEmpty(this.state.tracking_info) && this.state.tracking_info.no_tracking_data && (
							<GridContainer>
								<GridItem xs={12}>
									<Paper className={classes.infoPaper} elevation={1}>
										<strong>Tracking Not Yet Available!</strong> This shipment has not been entered into the carrier system yet. Tracking will be available when this happens.
									</Paper>
								</GridItem>
							</GridContainer>
						)}
						{!_.isEmpty(this.state.tracking_info) && !this.state.tracking_info.no_tracking_data && (
							<GridContainer>
								<GridItem xs={12}>
									<Paper className={classes.infoPaper} elevation={1}>
										<GridContainer className={classes.left}>
											<GridItem xs>
												<strong>Tracking Number: </strong> {this.state.tracking_info.trackingId}
												<br />
												<strong>Pickup Date: </strong> {this.state.tracking_info.pickupDate}
											</GridItem>
											<GridItem xs>
												<strong>Carrier: </strong> {this.state.tracking_info.carrier}
												<br />
												<strong>Service Type: </strong> {this.state.tracking_info.service}
											</GridItem>
										</GridContainer>
									</Paper>
								</GridItem>
								<GridItem xs={12}>
									<h5>Status Updates</h5>
									<GridContainer className={classes.left}>{!_.isEmpty(this.state.tracking_info.status) && this.getStatusUpdates(this.state.tracking_info.status)}</GridContainer>
								</GridItem>
							</GridContainer>
						)}
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("trackingInfoModal")} color="white">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.shipmentInfoModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("shipmentInfoModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("shipmentInfoModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Shipment Information</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						<GridContainer>
							<GridItem xs={12}>
								<GridContainer justify="center">
									{!_.isEmpty(this.state.info) ? (
										<GridItem xs={12} className={classes.center}>
											<NavPills color="info" className={classes.center} tabs={this.getTabs(this.state.info)} />
										</GridItem>
									) : (
										<GridItem xs={12} className={classes.center}>
											<Badge color="info">No Shipment Information</Badge>
										</GridItem>
									)}
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("shipmentInfoModal")} color="white">
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<PrintZplModal
					open={this.state.printLabelModal ? true: false}
					modalName="printLabelModal"
					show_default_printer_save={false}
					show_copy_to_clipboard={true}
					printerType="Label"
					zpl={this.state.zpl}
					handleChange={this.handleChange}
					handleChecked={this.handleChecked}
					handleModalClose={this.handleModalClose}
				/>
				<PrintZplModal
					open={this.state.printDocModal ? true: false}
					modalName="printDocModal"
					show_default_printer_save={false}
					show_copy_to_clipboard={true}
					printerType="Documents"
					zpl={this.state.docZpl}
					handleChange={this.handleChange}
					handleChecked={this.handleChecked}
					handleModalClose={this.handleModalClose}
				/>
			</GridContainer>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        clearParcelLookup,
		performLookup,
        setJSPM,
        setAvailablePrinters,
        jspmStatus,
    }, dispatch);
}

const mapStateToProps = (state) => {
	return {
		refTypes: state.ParcelLookup.refTypes,
        zplDocTypes: state.PrintZplModal.zplDocTypes,
        dgDocTypes: state.PrintZplModal.dgDocTypes,
		searchFilters: state.Search.filters,
		hideParcelRateDetails: !!(+getUserSetting('hide_parcel_rate_details', 0)(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(withSnackbar(ArchivedParcelShipments)));
