import React, { Component, Fragment } from "react";
import { apiUrl } from '../../../variables/server.jsx'
import OpenInNewTab from '../../../components/TMS/OpenInNewTab.jsx';

class InvoiceImageLink extends Component {
    render() {
        return <OpenInNewTab 
                    text={this.props.name} 
                    url={`${apiUrl}/index.php?p=api&c=billing&m=viewDocumentImage&id=${this.props.imageId}`}
                />
    }
}

export default InvoiceImageLink;
