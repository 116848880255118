import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Close from "@material-ui/icons/Close";
import Person from "@material-ui/icons/Person";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Phone from "@material-ui/icons/Phone";

import Button from "~/components/CustomButtons/Button.jsx";
import Table from "~/components/Table/Table.jsx";
import Transition from "~/components/TMS/ModalTransition.jsx";

// react-icons
import { FaFax } from "react-icons/fa";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";



class ContactSearchModal extends React.Component {

    getContacts(contacts) {
        let executed = this.props.status != 'hold' && !_.isEmpty(this.props.status);
        if(this.props.enable_executed_edit) {
            executed = false;
        }
        return contacts.map((contact, key) => {
            return [
                <Typography variant="body2">
                    {contact.code}
                </Typography>,
                <Typography variant="body2">
                    <i>
                        {!!contact.location_name.length && (
                            <><strong>{contact.location_name}</strong><br /></>
                        )}
                        {!!contact.address1.length && (
                            <>{contact.address1}<br /></>
                        )}
                        {!!contact.address2.length && (
                            <>{contact.address2}<br /></>
                        )}
                        {!!contact.city.length && (
                            <>{contact.city}</>
                        )}
                        {!!contact.state.length && (
                            <>, {contact.state}</>
                        )}
                        {!!contact.zip.length && (
                            <> {contact.zip}</>
                        )}
                        {!!contact.country.length && (
                            <> {contact.country}</>
                        )}
                    </i>
                </Typography>,
                <GridContainer>
                    {!!contact.contact_name.length && (
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={1}>
                                    <Person />
                                </GridItem>
                                <GridItem xs={11}>
                                    <div dangerouslySetInnerHTML={{ __html: contact.contact_name }} />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    )}
                    {!!contact.phone.length && (
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={1}>
                                    <Phone />
                                </GridItem>
                                <GridItem xs={11}>
                                    <div dangerouslySetInnerHTML={{ __html: contact.phone }} />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    )}
                    {!!contact.fax.length && (
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={1}>
                                    <FaFax />
                                </GridItem>
                                <GridItem xs={11}>
                                    <div dangerouslySetInnerHTML={{ __html: contact.fax }} />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    )}
                    {!!contact.email.length && (
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={1}>
                                    <AlternateEmail />
                                </GridItem>
                                <GridItem xs={11}>
                                    <div dangerouslySetInnerHTML={{ __html: contact.email }} />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    )}
                </GridContainer>,
                <div className="actions-right">
                    <Button size="sm" color="linkedin" onClick={e => this.props.handleContactSelect(key)} disabled={executed}>
                        Select
                    </Button>
                </div>
            ];
        });
    }

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                    classes={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.props.onClose()}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                        <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
                            <Close className={classes.modalClose} />
                        </Button>
                        <h4 className={classes.modalTitle}>Found Multiple Contacts</h4>
                    </DialogTitle>
                    <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                        {!!this.props.contacts.length && <Table tableHead={["Code", "Address", "Contact", "Actions"]} tableData={this.getContacts(this.props.contacts)} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} />}
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        <Button onClick={() => this.props.onClose()} color="white">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

export default withStyles(style)(ContactSearchModal);