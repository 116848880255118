import React, { Component, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import "formdata-polyfill";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';
import axios from "~/variables/axios.jsx";
import { basePath, baseUrl, apiUrl } from "~/variables/server.jsx";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";


// core components
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import WarehouseInfo  from "../components/WarehouseInfo/WarehouseInfo.jsx";
import WarehouseUsers  from "../components/WarehouseUsers/WarehouseUsers.jsx";
import WarehouseCarriers  from "../components/WarehouseCarriers/WarehouseCarriers.jsx";
import WarehouseLocations  from "../components/WarehouseLocations/WarehouseLocations.jsx";
import WarehouseCatalogs  from "../components/WarehouseCatalogs/WarehouseCatalogs.jsx";

import Person from "@material-ui/icons/Person";
import Schedule from "@material-ui/icons/Schedule";

// style for this view
import style from "~/assets/jss/material-dashboard-pro-react/views/tms/ltlQuoteStyle.jsx";

class WarehouseManagementContainer extends Component {
	state = {
		warehouse: "",
		id: null,
		company: null,
		active: "warehouse",
		alert: null,
		redirect: null,
		show: false,
		mounted: false,
		loading: false,
		user: null,
		body: null,
		name: "",
		logo: "",
		code: "",
		ctsi_code: "",
		broker_id: "",
		hide_tl_carriers: false,
		warehouse_ftl_scacs: [],
		tfm_scacs: [],
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		override_max_weight: "",
		override_enable_auto_class: "",
		override_require_po: "inherit",
		override_require_so: "inherit",
		override_require_reference: "inherit",
		override_ref_entry_type: "inherit",
		override_custom_piece_count: "inherit",
		ref_entry_opts: [],
		cust_ref_entry_type: "freeform",
		cust_ref_entry_opts: [],
		default_direction_terms: "",
		bol_pdf_hide_measurements: false,
		bol_pdf_template: "bolTemplateDefault",
		separate_prods_hus: false,
		fuzzy_product_code: false,
		enable_fedex_parcel_shipping: false,
		enable_ups_parcel_shipping: false,
		enable_usps_parcel_shipping: false,
		hazmat_api_test: false,
		hazmat_dev_id: "",
		hazmat_app_id: "",
		hazmat_token: "",
		rater_cutoff_date: "",
		created: "",
		created_by: "",
		edited: "",
		edited_by: "",
		users: null,
		locations: null,
		bol_pdf_templates: null,
		require_pod_for_billing: false,
		require_packing_group: true,
		enable_product_part_no: false,
		prevent_exclusion_execution: false,
		isEdit: false,
		referenceTypes: [],
		customReferenceOptions: [],
		referenceSettings: [],
		po_number: "",
		so_number: "",
		ref_number: "",
		tabIndex: 0
	}

	componentDidMount() {
		if(!_.isEmpty(this.props.warehouse) && typeof this.props.warehouse == "object") {
			this.getWarehouseDetails(this.props.warehouse.id);
		}
		this.setState({ tabIndex: this.props.tabIndex });
	}

	handleChangeWarehouse = (e) => {
		this.props.setCurrentWarehouse(e.target.value);
		this.getWarehouseDetails(e.target.value.id);
	}

	async getWarehouseDetails(id) {
		let url = "/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + id;
		this.setState({ id: id, isEdit: true, loading: true, show: false });
		try {
			const response = await axios.get(url);
			const data = response.data;

			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user) && !_.isEmpty(data.body)) {
				const ref_entry_opts = data.body.warehouse.ref_entry_opts.map(opt => {
					return opt.value;
				});
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					company: data.body.warehouse.company_name_id,
					name: data.body.warehouse.name,
					logo: data.body.warehouse.logo_file,
					code: data.body.warehouse.code,
					ctsi_code: data.body.warehouse.ctsi_code,
					broker_id: data.body.warehouse.broker_id,
					warehouse_ftl_scacs: data.body.warehouse.ftl_scacs,
					tfm_scacs: data.body.tfm_scacs,
					address1: data.body.warehouse.address1,
					address2: data.body.warehouse.address2,
					city: data.body.warehouse.city,
					state: data.body.warehouse.state,
					zip: data.body.warehouse.zip,
					po_number: data.body.warehouse.po_number,
					so_number: data.body.warehouse.so_number,
					ref_number: data.body.warehouse.ref_number,
					hide_tl_carriers: data.body.warehouse.company_hide_tl_carriers == 1,
					override_max_weight: data.body.warehouse.override_max_weight,
					override_enable_auto_class: data.body.warehouse.override_enable_auto_class,
					override_ref_entry_type: data.body.warehouse.override_ref_entry_type,
					ref_entry_opts: ref_entry_opts,
					override_allow_auto_pro: data.body.warehouse.override_allow_auto_pro,
					override_require_reference: data.body.warehouse.override_require_reference,
					override_require_po: data.body.warehouse.override_require_po,
					override_require_so: data.body.warehouse.override_require_so,
					override_custom_piece_count: data.body.warehouse.override_custom_piece_count,
					default_direction_terms: data.body.warehouse.default_direction_terms,
					bol_pdf_hide_measurements: data.body.warehouse.bol_pdf_hide_measurements == 1,
					bol_pdf_template: data.body.warehouse.bol_pdf_template,
					enable_fedex_parcel_shipping: data.body.warehouse.enable_fedex_parcel_shipping == 1,
					enable_ups_parcel_shipping: data.body.warehouse.enable_ups_parcel_shipping == 1,
					enable_usps_parcel_shipping: data.body.warehouse.enable_usps_parcel_shipping == 1,
					hazmat_api_test: data.body.warehouse.hazmat_api_test == 1,
					hazmat_dev_id: data.body.warehouse.hazmat_dev_id,
					hazmat_app_id: data.body.warehouse.hazmat_app_id,
					hazmat_token: data.body.warehouse.hazmat_token,
					separate_prods_hus: data.body.warehouse.separate_prods_hus == 1,
					fuzzy_product_code: data.body.warehouse.fuzzy_product_code == 1,
					rater: data.body.warehouse.rater,
					rater_cutoff_date: data.body.warehouse.rater_cutoff_date,
					created: data.body.warehouse.created,
					created_by: data.body.warehouse.create_by,
					edited: data.body.warehouse.edited,
					edited_by: data.body.warehouse.edit_by,
					users: _.sortBy(data.body.users, user => parseInt(user.id, 10)),
					locations: data.body.locations,
					bol_pdf_templates: data.body.bol_pdf_templates,
					require_pod_for_billing: data.body.warehouse.require_pod_for_billing == 1 ? true : false,
					require_packing_group: data.body.warehouse.require_packing_group == 1 ? true : false,
					enable_product_part_no: data.body.warehouse.enable_product_part_no == 1 ? true : false,
					prevent_exclusion_execution: data.body.warehouse.prevent_exclusion_execution == 1 ? true : false,
					standardize_us_ltl_pickup_adr: data.body.warehouse.standardize_us_ltl_pickup_adr == 1 ? true : false,
					override_dgd_addl_handling_instructions: data.body.warehouse.override_dgd_addl_handling_instructions == 1 ? true : false,
					require_acc_recommendations: data.body.warehouse.require_acc_recommendations == 1 ? true : false,
					dgd_addl_handling_instructions: data.body.warehouse.dgd_addl_handling_instructions,
					referenceSettings: !Array.isArray(data.body.warehouse.default_custom_references) ? [] : data.body.warehouse.default_custom_references.map(setting =>{
						return {
							value: setting.name,
							default_value: setting.default_value,
							required: +setting.required,
							display_on_bol: +setting.display_on_bol,
							cust_ref_entry_type: setting.cust_ref_entry_type,
							cust_ref_entry_opts: setting.options,
						};
					}),
				});

				const refTypesResponse = await axios.get("/index.php?p=api&r=json&c=admin&m=getReferenceTypes&companyId=" + data.body.warehouse.company_name_id);
				if (refTypesResponse && refTypesResponse.data && refTypesResponse.data.body &&  Array.isArray(refTypesResponse.data.body.types)) {
					this.setState({referenceTypes: [...refTypesResponse.data.body.types]});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("Unable to load the warehouse data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("Unexpected error while loading the data", {
				variant: "error"
			});
		}
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	}

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	}

	handleSelect = event => {
		if(event.target.name === 'override_ref_entry_type') {
			const override_ref_entry_type = event.target.value;
			const ref_entry_opts = (override_ref_entry_type === 'select') ? [...this.state.ref_entry_opts] : [];
			this.setState({ override_ref_entry_type, ref_entry_opts });
		} else {
			this.setState({ [event.target.name]: event.target.value });
		}
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleDatetime = (name, moment) => {
		this.setState({ [name]: moment });
	}

	handleUpload = (files) => {
		const logo = files[0];
		this.setState({ logo });
	}

	handleFtlScacAssignment = (event, values) => {
		this.setState({ warehouse_ftl_scacs: values });
	}

	handleTabChange = (event, index) => {
		this.setState({ tabIndex: index});
		this.props.setCurrentWarehouseTab(index);
	}

	async changeWarehouseStatus(active) {
		const { warehouse } = this.props;
		const status = active ? "enable" : "disable";
		if (_.isEmpty(warehouse) || warehouse.active == active) {
			return;
		}
		const url = `/index.php?p=api&r=json&c=admin&m=changeWarehouseStatus&d=${warehouse.id}/${status}/company/${warehouse.company_name_id}`;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				warehouse.active = active;
				this.props.setCurrentWarehouse(warehouse);
			} else {
				this.props.setNotification("There was an error changing the warehouse status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred changing the warehouse status!", { variant: "error" });
		}
	}

	saveWarehouse = async () => {
		this.setState({
			show: true,
			loading: true
		});

		let url = "/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.state.id

		const data = new FormData();

		data.append("name", this.state.name);
		data.append("code", this.state.code);
		data.append("ctsi_code", this.state.ctsi_code);
		data.append("broker_id", this.state.broker_id);
		data.append("address1", this.state.address1);
		data.append("address2", this.state.address2);
		data.append("city", this.state.city);
		data.append("state", this.state.state);
		data.append("zip", this.state.zip);
		data.append("override_max_weight", this.state.override_max_weight);
		data.append("override_enable_auto_class", this.state.override_enable_auto_class);
		data.append("override_allow_auto_pro", this.state.override_allow_auto_pro);
		data.append("override_require_reference", this.state.override_require_reference);
		data.append("override_require_po", this.state.override_require_po);
		data.append("override_require_so", this.state.override_require_so);
		data.append("override_custom_piece_count", this.state.override_custom_piece_count);
		data.append("default_direction_terms", this.state.default_direction_terms);
		data.append("bol_pdf_template", this.state.bol_pdf_template);
		data.append("rater", this.state.rater);
		data.append("override_ref_entry_type", this.state.override_ref_entry_type);
		data.append("ref_entry_opts", this.state.ref_entry_opts);
		data.append("hazmat_dev_id", this.state.hazmat_dev_id);
		data.append("hazmat_app_id", this.state.hazmat_app_id);
		data.append("hazmat_token", this.state.hazmat_token);
		data.append("po_number", this.state.po_number);
		data.append("so_number", this.state.so_number);
		data.append("ref_number", this.state.ref_number);

		if(!_.isEmpty(this.state.rater_cutoff_date)) {
			data.append("rater_cutoff_date", moment(this.state.rater_cutoff_date).format('YYYY-MM-DD'));
		} else {
			data.append("rater_cutoff_date", "");
		}
		if (this.state.bol_pdf_hide_measurements) {
			data.append("bol_pdf_hide_measurements", "1");
		}
		if (this.state.enable_fedex_parcel_shipping) {
			data.append("enable_fedex_parcel_shipping", "1");
		}
		if (this.state.enable_ups_parcel_shipping) {
			data.append("enable_ups_parcel_shipping", "1");
		}
		if (this.state.enable_usps_parcel_shipping) {
			data.append("enable_usps_parcel_shipping", "1");
		}
		if (this.state.hazmat_api_test) {
			data.append("hazmat_api_test", "1");
		} else {
			data.append("hazmat_api_test", "0");
		}
		if (this.state.separate_prods_hus) {
			data.append("separate_prods_hus", "1");
		}
		if (this.state.fuzzy_product_code) {
			data.append("fuzzy_product_code", "1");
		}
		data.append("logo", this.state.logo);

		let requirePod = this.state.require_pod_for_billing ? 1 : 0;
		data.append('require_pod_for_billing', requirePod);

		let requirePackingGroup = this.state.require_packing_group ? 1 : 0;
		data.append('require_packing_group', requirePackingGroup);

		let enableProductPartNo = this.state.enable_product_part_no ? 1 : 0;
		data.append('enable_product_part_no', enableProductPartNo);

		let preventExclusionExecution = this.state.prevent_exclusion_execution ? 1 : 0;
		data.append('prevent_exclusion_execution', preventExclusionExecution);

		let standardizeUsLtlPickupAdr = this.state.standardize_us_ltl_pickup_adr ? 1 : 0;
		data.append('standardize_us_ltl_pickup_adr', standardizeUsLtlPickupAdr);

		let overrideDgdAddlHandlingInstructions = this.state.override_dgd_addl_handling_instructions ? 1 : 0;
		data.append('override_dgd_addl_handling_instructions', overrideDgdAddlHandlingInstructions);

		data.append('dgd_addl_handling_instructions', this.state.dgd_addl_handling_instructions);

		let requireAccRecommendations = this.state.require_acc_recommendations ? 1 : 0;
		data.append('require_acc_recommendations', requireAccRecommendations);
		
		data.append('warehouse_ftl_scacs', this.state.warehouse_ftl_scacs);

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		try {
			const response = await axios.post(url, data, config);
			let savedRefs = false;
			//save reference settings
			if (this.state.isEdit) {
				if (response && response.data && response.data.body && response.data.body.warehouse && response.data.body.warehouse.warehouse_id) {
					savedRefs = await this.saveWarehouseCustomReferenceSettings(response.data.body.warehouse.warehouse_id);
				}
			} else {
				if (response && response.data && response.data.createdWarehouseId) {
					savedRefs = await this.saveWarehouseCustomReferenceSettings(response.data.createdWarehouseId);
				}
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && savedRefs) {
				this.setState({ loading: false });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred while saving the warehouse!", {
				variant: "error"
			});
		}
	}

	saveWarehouseCustomReferenceSettings = async (warehouseId) => {
		const url =  "/index.php?p=api&r=json&c=admin&m=saveWarehouseCustomReferenceSettings";
		let validOpts;
		try {
			this.state.referenceSettings.forEach(setting => {
				if(setting.cust_ref_entry_type == 'select') {
					if(setting.cust_ref_entry_opts.length == 0) {
						throw new Error("Options must be provided when Custom Reference is type 'Select Option'")
					}
					setting.cust_ref_entry_opts.forEach(option => {
						if(option.trim() == "") {
							throw new Error("Custom reference select options cannot be empty")
						}
					})
				}
			});
		} catch (err) {
			this.props.setNotification("Cannot save reference options: " + err, {variant: "error"})
			return false;
		}
		const payload = qs.stringify({
			warehouse_id: warehouseId,
			settings: this.state.referenceSettings.map(setting => {
				return {
					type: setting.value,
					default_value: setting.default_value,
					cust_ref_entry_type: setting.cust_ref_entry_type,
					cust_ref_entry_opts: (setting.cust_ref_entry_type === 'select') ? setting.cust_ref_entry_opts : [],
					required: setting.required ? 1 : 0,
					display_on_bol: setting.display_on_bol ? 1 : 0,
				};
			}),
		})
		const response = await axios.post(url, payload);
		
		if (response && response.data && response.data.body && response.data.body.success) {
			return true;
		}

		if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
			this.props.setNotification("Error saving Custom Shipment References", {
				variant: "error",
			});
			this.props.handleMessages(response);
		} else {
			this.props.setNotification("Unknown Error saving Custom Shipment References", {
				variant: "error",
			});
		}
	}

	getImage = (prop) => {
		const src = !_.isEmpty(prop.preview) ? prop.preview : apiUrl + "/getLogo.php?logo=" + prop;
		const filename = !_.isEmpty(prop.name) ? prop.name : prop;
		const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
		return <Preview src={src} index={0} extension={extension} handleClick={() => {}} />;
	}

	export = async (type) => {
		let isParcel = false;
		if (["product", "location", "unit", "parcelProduct"].indexOf(type) !== -1) {
			if(type === 'parcelProduct') {
				isParcel = true;
				type = 'product';
			}
			const typeUrl = type === "unit" ? "handUnit" : type;
			let url = "/index.php?p=api&c=" + typeUrl + "&m=export&warehouse=" + this.state.id;
			if(isParcel) {
				url += '&d=1';
			}
			try {
				const response = await axios.get(url);
				if (typeof response.data === "string") {
					window.location = baseUrl + url;
				} else {
					this.props.handleMessages(response);
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error exporting the " + type + "s!", {
					variant: "error"
				});
			}
		}
	}

	handleDelete = (type) => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title={"Are you sure you want to delete all " + type + "s?"}
					onConfirm={() => this.delete(type)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	delete = async (type) => {
		if (["product", "location", "unit"].indexOf(type) === -1) {
			this.setState({ alert: null });
			return;
		}

		const url = "/index.php?p=api&r=json&c=admin&m=deleteWarehouse" + type.replace(/^\w/, c => c.toUpperCase()) + "s&d=" + this.state.id;

		this.setState({ show: false, loading: true, alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				if (type === "location") {
					const res = await axios.get("/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.state.id);
					if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
						this.props.handleMessages(res);
					}
					if (typeof res.data !== "string" && !_.isEmpty(res.data.body)) {
						this.setState({ show: true, loading: false, locations: _.sortBy(res.data.body.locations, location => parseInt(location.id, 10)) });
					} else {
						this.setState({ show: true, loading: false });
						this.props.setNotification("There was an error getting the warehouse locations!", { variant: "error" });
					}
				} else {
					this.setState({ show: true, loading: false });
				}
			} else {
				this.setState({ show: true, loading: false });
				this.props.setNotification("There was an error deleting the " + type + "s!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ show: true, loading: false });
			this.props.setNotification("There was an error deleting the " + type + "s!", { variant: "error" });
		}
	}

	deleteWarehouseLocation = async (id) => {
		const { warehouse } = this.props;
		const whId = warehouse.id;
		const url = "/index.php?p=api&r=json&c=admin&m=deleteLocation&d=" + id + "/warehouse/" + whId;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({ locations: _.sortBy(response.data.body.locations, location => parseInt(location.id, 10)) });
			} else {
				this.props.setNotification("There was an error deleting the address!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred while deleting the address!", { variant: "error" });
		}
	}

	isValidReferenceSettingIndex = (index) => {
		return !isNaN(index) && index >= 0 && index < this.state.referenceSettings.length;
	}

	addReferenceSetting = e => {
		this.setState({
			referenceSettings: [
				...this.state.referenceSettings,
				{value: "", required: false},
			],
		});
	}

	onRemoveReferenceSetting = index => {
		if (this.isValidReferenceSettingIndex(index)) {
			let refs = this.state.referenceSettings;
			refs.splice(index, 1);
			this.setState({referenceSettings: [...refs]});
		}
	}

	onChangeReferenceSettingType = (index, value, type) => {
		if (this.isValidReferenceSettingIndex(index)) {
			let refs = this.state.referenceSettings;
			refs[index][type] = value;
			this.setState({referenceSettings: [...refs]});
		}
	}

	onChangeReferenceSettingCheckBox = (index, type) => {
		if (this.isValidReferenceSettingIndex(index)) {
			let refs = this.state.referenceSettings;
			refs[index][type] = !refs[index][type];
			this.setState({referenceSettings: [...refs]});
		}
	}
	
	render() {
		const { classes } = this.props;
		return(
			<GridContainer>
				 {this.state.alert}
				<GridItem sm={2} md={2}>
					<WithTooltip
						title="Choose a Warehouse"
						content="Select a warehouse from the list."
					>
						<InputLabel className={classes.label}>Select A Warehouse</InputLabel>
					</WithTooltip>
					<FormControl className={classes.selectFormControl} fullWidth>
						<Select
							MenuProps={{ className: classes.selectMenu }}
							classes={{ select: classes.select + " " + classes.requiredSelect }}
							value={this.props.warehouse || ""}
							onChange={this.handleChangeWarehouse}
						>
							{this.props.warehouses.map((warehouse, key) => {
								return (
									<MenuItem
										key={key}
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value={warehouse || ""}
									>
										{`${warehouse.id}: ${warehouse.name} (${warehouse.code})`}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</GridItem>
				<GridItem sm={10} md={10} style={{display: "flex", justifyContent: "flex-end"}}>
					<NavLink to={basePath + "/admin/company-" + this.props.cid + "/warehouse/new"}>
						<Button
							color="linkedin"
							style={{
								marginRight: "4px"
							}}
						>
							New Warehouse
						</Button>
					</NavLink>
				</GridItem>
				<GridItem xs={4} sm={4} md={4}>
					{this.state.show && (
						<Paper className={classes.paper} elevation={1} style={{ padding: "5px" }}>
							<GridContainer>
								<GridItem xs={12} style={{ marginBottom: "5px" }}>
									<GridContainer style={{ marginBottom: "5px" }}>
										<GridItem xs={3}>
											<Chip variant="outlined" size="small" icon={<Schedule />} label="Created" />
										</GridItem>
										<GridItem xs={9}>{this.state.created}</GridItem>
									</GridContainer>
									<GridContainer style={{ marginBottom: "5px" }}>
										<GridItem xs={3}>
											<Chip variant="outlined" size="small" icon={<Person />} label="Created" />
										</GridItem>
										<GridItem xs={9}>{this.state.created_by}</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12}>
									<GridContainer style={{ marginBottom: "5px" }}>
										<GridItem xs={3}>
											<Chip variant="outlined" size="small" icon={<Schedule />} label="Updated" />
										</GridItem>
										<GridItem xs={9}>{this.state.edited}</GridItem>
									</GridContainer>
									<GridContainer style={{ marginBottom: "5px" }}>
										<GridItem xs={3}>
											<Chip variant="outlined" size="small" icon={<Person />} label="Updated" />
										</GridItem>
										<GridItem xs={9}>{this.state.edited_by}</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} style={{display: "flex", justifyContent: "center"}}>
									{this.state.show && this.props.warehouse.active == '1' && (
										<Button size="sm" color="success" onClick={() => this.changeWarehouseStatus(0).bind(this)}>
											Active
										</Button>
									)}
									{this.state.show && this.props.warehouse.active == '0' && (
										<Button size="sm" color="black" onClick={() => this.changeWarehouseStatus(1).bind(this)}>
											Disabled
										</Button>
									)}
								</GridItem>
							</GridContainer>
						</Paper>
					)}
				</GridItem>
				<GridItem xs={8} sm={8} md={8} style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
				{this.state.show && this.state.tabIndex == 0 && (
					<Fragment>
						<Button size="sm" color="info" style={{marginRight: "5px"}} onClick={this.saveWarehouse}>
							Save Warehouse
						</Button>
						<Button size="sm" color="danger" onClick={() => this.getWarehouseDetails(this.props.warehouse.id)} >
							Cancel
						</Button>
					</Fragment>
				)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{this.state.show && (
						<Tabs
							value={this.state.tabIndex}
							onChange={this.handleTabChange}
							textColor="primary"
							indicatorColor="primary"
							centered
						>
							<Tab label="Warehouse Info" />
							<Tab label="Assigned Users" />
							<Tab label="Assigned Carriers" />
							<Tab label="Locations" />
							<Tab label="Catalogs" />
						</Tabs>
					)}
				</GridItem>
				<br/>
				<GridItem xs={12} sm={12} md={12}>
					{this.state.show ? (
						<Card>
							<CardBody>
								{this.state.tabIndex == 0 && (
									<WarehouseInfo
										isEdit={this.state.isEdit}
										name={this.state.name}
										code={this.state.code}
										ctsi_code={this.state.ctsi_code}
										broker_id={this.state.broker_id}
										override_max_weight={this.state.override_max_weight}
										override_enable_auto_class={this.state.override_enable_auto_class}
										override_allow_auto_pro={this.state.override_allow_auto_pro}
										override_require_so={this.state.override_require_so}
										override_require_po ={this.state.override_require_po }
										override_require_reference={this.state.override_require_reference}
										override_custom_piece_count={this.state.override_custom_piece_count}
										default_direction_terms={this.state.default_direction_terms}
										bol_pdf_template={this.state.bol_pdf_template}
										address1={this.state.address1}
										address2={this.state.address2}
										zip={this.state.zip}
										city={this.state.city}
										state={this.state.state}
										rater={this.state.rater}
										bol_pdf_templates={this.state.bol_pdf_templates}
										rater_cutoff_date={this.state.rater_cutoff_date}
										override_ref_entry_type={this.state.override_ref_entry_type}
										separate_prods_hus={this.state.separate_prods_hus}
										fuzzy_product_code={this.state.fuzzy_product_code}
										bol_pdf_hide_measurements={this.state.bol_pdf_hide_measurements}
										require_pod_for_billing={this.state.require_pod_for_billing}
										require_packing_group={this.state.require_packing_group}
										enable_product_part_no={this.state.enable_product_part_no}
										prevent_exclusion_execution={this.state.prevent_exclusion_execution}
										override_dgd_addl_handling_instructions={this.state.override_dgd_addl_handling_instructions}
										require_acc_recommendations={this.state.require_acc_recommendations}
										standardize_us_ltl_pickup_adr={this.state.standardize_us_ltl_pickup_adr}
										dgd_addl_handling_instructions={this.state.dgd_addl_handling_instructions}
										hide_tl_carriers={this.state.hide_tl_carriers}
										warehouse_ftl_scacs={this.state.warehouse_ftl_scacs}
										tfm_scacs={this.state.tfm_scacs}
										po_number={this.state.po_number}
										so_number={this.state.so_number}
										ref_number={this.state.ref_number}
										cust_ref_entry_opts={this.state.cust_ref_entry_opts}
										enable_fedex_parcel_shipping={this.state.enable_fedex_parcel_shipping}
										enable_ups_parcel_shipping={this.state.enable_ups_parcel_shipping}
										enable_usps_parcel_shipping={this.state.enable_usps_parcel_shipping}
										hazmat_api_test={this.state.hazmat_api_test}
										hazmat_dev_id={this.state.hazmat_dev_id}
										hazmat_app_id={this.state.hazmat_app_id}
										hazmat_token={this.state.hazmat_token}
										logo={this.state.logo}
										id={this.state.id}
										referenceSettings={this.state.referenceSettings}
										referenceTypes={this.state.referenceTypes}
										handleInput={this.handleInput}
										handleDatetime={this.handleDatetime}
										handleChecked={this.handleChecked}
										onRemoveReferenceSetting={this.onRemoveReferenceSetting}
										onChangeReferenceSettingType={this.onChangeReferenceSettingType}
										onChangeReferenceSettingCheckBox={this.onChangeReferenceSettingCheckBox}
										handleUpload={this.handleUpload}
										getImage={this.getImage}
										handleDelete={this.handleDelete}
										handleChange={this.handleChange}
										handleSelect={this.handleSelect}
										handleFtlScacAssignment={this.handleFtlScacAssignment}
										addReferenceSetting={this.addReferenceSetting}
										export={this.export}
									/>
								)}
								{this.state.tabIndex == 1 && (
									<WarehouseUsers
										users={this.state.users}
										companyUsers={this.props.companyUsers}
										currentWarehouseUsers={this.props.currentWarehouseUsers}
										warehouseId={this.state.id}
										company={this.state.company}
										enableUser={this.props.enableUser}
										disableUser={this.props.disableUser}
										grantApiKey={this.props.grantApiKey}
										disableApiKey={this.props.disableApiKey}
										removeUserFromWarehouse={this.props.removeUserFromWarehouse}
										addUserToWarehouse={this.props.addUserToWarehouse}
										setNotification={this.props.setNotification}
										handleMessages={this.props.handleMessages}
									/>
								)}
								{this.state.tabIndex == 2 && (
									<WarehouseCarriers
										carriers={this.props.carriers}
										company={this.state.company}
										warehouseId={this.state.id}
										removeCarrierFromWarehouse={this.props.removeCarrierFromWarehouse}
										saveCarrierToWarehouse={this.props.saveCarrierToWarehouse}
										currentTab={this.state.tabIndex}
									/>
								)}
							</CardBody>
							{this.state.tabIndex == 3 && (
								<WarehouseLocations
									locations={this.state.locations}
									warehouseId={this.state.id}
									company={this.state.company}
									currentTab={this.state.tabIndex}
									deleteWarehouseLocation={this.deleteWarehouseLocation}
								/>
							)}
							{this.state.tabIndex == 4 && (
								<WarehouseCatalogs
									warehouseId={this.state.id}
									currentTab={this.state.tabIndex}
									company={this.state.company}
									setNotification={this.props.setNotification}
									handleMessages={this.props.handleMessages}
								/>
							)}
						</Card>
					):(
						<Spinner loading={this.state.loading} />
					)}
				</GridItem>
			</GridContainer>
		)
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(WarehouseManagementContainer)));