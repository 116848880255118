import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import { hasPermission, userIsAdmin } from "../../redux/selectors/Admin.jsx";

import cx from "classnames";
import qs from "qs";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import axios from "~/variables/axios.jsx";
import moment from "moment";
import { basePath, apiUrl } from "~/variables/server.jsx";

// lib
import YatClient from '~/lib/yat.js';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import Grow from '@material-ui/core/Grow';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

// material ui icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import Build from "@material-ui/icons/Build";
import AddCircle from "@material-ui/icons/AddCircle";
import Warning from "@material-ui/icons/Warning";

import { IoIosCalculator } from "react-icons/io";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import NavPills from "~/components/NavPills/NavPills.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import WithTooltip from "../../components/TMS/WithTooltip.jsx";
import { FREIGHT_CLASSES } from "../../constants.jsx";
import LiabilityModal from "../RateResults/containers/LiabilityModal.jsx";
import RateResults from "../RateResults/containers/RateResults.jsx";
import TerminalInformationModal from "../RateResults/containers/TerminalInformationModal.jsx";
import { loadTerminalModal } from '../RateResults/actions/TerminalInformationModal.jsx';
import AccessorialsSelect from "../../components/TMS/AccessorialsSelect.jsx";
import FreightClassSelect from "../../components/TMS/FreightClassSelect.jsx";
import LnftLoadPlanModal from "~/components/LnftLoadPlanModal/LnftLoadPlanModal.jsx";
import { openAlertModal } from "../Alert/actions/Alert.jsx";
import { convertToCountryCode } from "../../helpers.jsx";


// style for this view
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class Transition extends React.Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

class LtlQuote extends React.Component {
	constructor(props) {
		super(props);
		this.yat = new YatClient(this.props.yatUrl);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			lnftLoading: false,
			user: null,
			body: null,
			alert: null,
			company: null, // admin only
			data_type: "ltl",
			classification: false,
			show_notice: true,
			notice_message: "To accurately assess the Cubic Capacity, Density or Linear Foot Charges the dimensions are required. Target Freight Management is not responsible for the additional charges incurred due to missing dimensions. For an accurate quote on the shipments or to make the dimensions mandatory please call us at 1-888-653-1323.",
			totals: {
				units: 0,
				weight: 0,
				volume: 0,
				density: 0,
				linear_feet: 0,
				value: 0
			},
			warehouse: "",
			direction: "Outbound/Prepaid",
			blind_shipment: false,
			shipper_zip: "",
			shipper_city: "",
			shipper_state: "",
			shipper_country: "",
			consignee_zip: "",
			consignee_city: "",
			consignee_state: "",
			consignee_country: "",
			ship_date: "",
			accessorials: [],
			excess_liability: "",
			enable_auto_class: false,
			require_dims: false,
			units: [
				{
					type: "Pallet",
					pieces: "1",
					stackable: false,
					length: "",
					width: "",
					height: "",
					density: "",
					cube: "",
					products: [
						{
							nmfc: "",
							sub_nmfc: "",
							class: "",
							description: "",
							uom: "Pallet",
							pieces: "1",
							weight: "",
							netWeight: "",
							condition: "",
							packaging: "",
							declaredValue: "",
							errors: []
						}
					],
					errors: []
				}
			],
			classes: FREIGHT_CLASSES,
			carriers: [],
    		distance: 0,
			volume_carriers: [],
			scac: "",
			showCarriers: false,
			showVolumeTab: true,
			showVolumeCarriers: false,
			loadingCarriers: false,
			query_id: "",
			showTruckloadTab: true,
			tl_equipment: "Van",
			showQuote: false,
			loadingQuote: false,
			quote_id: "",
			tfmr_internal_id: false,
			quote: "",
			selected: "",
			max_weight: false,
			defaultUnit: {},
			defaultProduct: {},
			unitSearchModal: false,
			handling_units: [],
			unitKey: "",
			productSearchModal: false,
			products: [],
			productKey: "",
			productUnitKey: "",
			// validation
			rates: ["direction", "warehouse", "shipper_zip", "shipper_city", "shipper_state", "shipper_country", "consignee_zip", "consignee_city", "consignee_state", "shipper_country", "tl_equipment"],
			rateKey: "",
			feetToInchesModal: false,
			feet: "",
			inches: "",
			surfaceAreaModal: false,
			productLength: "",
			productWidth: "",
			palletLength: "",
			palletWidth: "",
			surfaceArea: 0,
			// carrier liability
			liabilityModal: false,
			allow_excess_liability: false,
			liability_modal_title: "",
			liability: null,
			disableRequestExcess: false,
			volume_total_length: '',
			volume_total_width: '',
			volume_total_height: '',
			prevent_exclusion_execution: false,
			enable_product_part_no: false,
			trailerDims: {},
		};
		this.addUnit = this.addUnit.bind(this);
		this.addProduct = this.addProduct.bind(this);
		this.classifyProducts = this.classifyProducts.bind(this);
		this.getHandlingUnits = this.getHandlingUnits.bind(this);
		this.getQuotes = this.getQuotes.bind(this);
		this.getWarehouses = this.getWarehouses.bind(this);

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleDataType = this.handleDataType.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleProduct = this.handleProduct.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.handlePrintAll = this.handlePrintAll.bind(this);
		this.handleUnit = this.handleUnit.bind(this);
		this.removeUnit = this.removeUnit.bind(this);
		this.removeProduct = this.removeProduct.bind(this);
		this.updateTotals = this.updateTotals.bind(this);
		this.setDefaultUnit = this.setDefaultUnit.bind(this);
		this.setDefaultProduct = this.setDefaultProduct.bind(this);
		this.getUnitTypes = this.getUnitTypes.bind(this);
		this.handleUnitSearch = this.handleUnitSearch.bind(this);
		this.handleUnitSelect = this.handleUnitSelect.bind(this);
		this.handleProductSearch = this.handleProductSearch.bind(this);
		this.handleProductSelect = this.handleProductSelect.bind(this);
		this.getUnits = this.getUnits.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.getTruckloadQuote = this.getTruckloadQuote.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleClassification = this.handleClassification.bind(this);
		this.handleConversion = this.handleConversion.bind(this);
		this.handleSurfaceArea = this.handleSurfaceArea.bind(this);
		this.loadDefaults = this.loadDefaults.bind(this);
		this.handleLiability = this.handleLiability.bind(this);
		this.getLiability = this.getLiability.bind(this);
		this.checkLiability = this.checkLiability.bind(this);
		this.requestExcess = this.requestExcess.bind(this);
		this.getLiabilityErrors = this.getLiabilityErrors.bind(this);
		this.clearCarriers = this.clearCarriers.bind(this);
	}


	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=billoflading&m=lccCalculator");
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body) && this.state.mounted) {
				const data = response.data;
				let warehouse = { code: "" };
				let enable_product_part_no = 0;
				let trailerDims = {};
				if (data.body.warehouses) {
					const warehouses = data.body.warehouses;
					if (warehouses.length === 1) {
						warehouse = warehouses[0];
						enable_product_part_no = warehouse.enable_product_part_no
					} else {
						const defaultWarehouse = warehouses.filter(prop => prop.is_default == "1");
						// Note, if user has more than one warehouse assigned but no default warehouse, defaulting to first assigned warehouse
						warehouse = !_.isEmpty(defaultWarehouse) ? defaultWarehouse[0] : warehouses[0];
						enable_product_part_no = warehouse.enable_product_part_no
					}
				}
				let accessorials = this.handleAccessorials(data.body.accessorials.accessorials).map(acc => {
					if (acc.always_checked) acc.checked = true;
					return acc;
				});
				if(!_.isEmpty(data.body.trailerDims)) {
					trailerDims = data.body.trailerDims;
				}
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body,
					warehouse: warehouse ? warehouse.code : "",
					accessorials,
					require_dims: data.body.require_dims == 1 ? true : false,
					enable_product_part_no: enable_product_part_no == 1 ? true : false,
					trailerDims
				}, () => {
					if (!_.isEmpty(warehouse) && !_.isEmpty(warehouse.code)) {
						this.loadDefaults(true);
					}
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
		if (name.indexOf("_zip") !== -1) {
			const type = name.replace("_zip", "");
			// Update country to Canada if zip has a letter
			const regex = /[a-z]/i;
			const country = regex.test(event.target.value) ? 'CA' : this.state[`${type}_country`];
			this.setState({
				[`${type}_city`]: '',
				[`${type}_state`]: '',
				[`${type}_country`]: country,
			});
			this.handleZip(type, event.target.value, country);
		}
		for (const input of this.state.rates) {
			if (name === input) {
				this.clearCarriers();
			}
		}
	};

	async handleChange(event) {
		const name = event.target.name;
		await this.setState({ [name]: event.target.value });
		if (name.indexOf("_country") !== -1) {
			const type = name.replace("_country", "");
			const postalCode = this.state[`${type}_zip`];
			this.setState({
				[`${type}_city`]: '',
				[`${type}_state`]: '',
			});
			this.handleZip(type, postalCode, event.target.value);
			this.clearCarriers();
			if(this.state.shipper_country == 'MX' || this.state.consignee_country == 'MX') {
				this.setState({
					showVolumeTab: false,
					showTruckloadTab: false,
				});
			} else {
				this.setState({
					showVolumeTab: true,
					showTruckloadTab: true,
				})
			}
		}
		for (const input of this.state.rates) {
			if (name === input) {
				this.clearCarriers();
			}
		}
		if (name === "warehouse") {
			this.loadDefaults(true);
		}
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
		this.clearCarriers();
	}

	handleDataType(data_type) {
		this.setState({ data_type });
	}

	handleClose() {
		this.setState({ show_notice: false });
	}

	clearCarriers() {
		this.setState({
			carriers: [],
			volume_carriers: [],
			max_weight: false,
			showCarriers: false,
			showVolumeCarriers: false,
			loadingCarriers: false,
			showQuote: false,
			loadingQuote: false,
			query_id: "",
			tfmr_internal_id: false,
			distance: 0,
			quote_id: "",
			quote: "",
			selected: ""
		});
	}

	addUnit() {
		const { units } = this.state;
		units.push({
			type: "Pallet",
			pieces: 1,
			stackable: false,
			length: "",
			width: "",
			height: "",
			density: "",
			cube: "",
			products: [
				{
					nmfc: "",
					sub_nmfc: "",
					class: "",
					description: "",
					uom: "Pallet",
					pieces: "1",
					weight: "",
					netWeight: "",
					condition: "",
					packaging: "",
					declaredValue: "",
					errors: []
				}
			],
			errors: []
		});
		this.setState({
			units,
			classification: false
		}, () => {
			if(units.length == 1) {
				this.setDefaultUnit();
			}
		});
		this.updateTotals(true);
		this.clearCarriers();
	}

	handleUnit(i, name, event) {
		const { units } = this.state;
		if (units.length) {
			const unit = units[i];
			unit[name] = event.target.value;
			units[i] = unit;
			this.setState({
				units,
				classification: false
			});
			this.updateTotals();
		}
		this.clearCarriers();
	}

	removeUnit(i) {
		const { units } = this.state;
		if (units.length) {
			units.splice(i, 1);
			this.setState({
				units,
				classification: false
			});
			this.updateTotals(true);
		}
		this.clearCarriers();
	}

	addProduct(i) {
		const { units } = this.state;
		if (units.length) {
			units[i].products.push({
				nmfc: "",
				sub_nmfc: "",
				class: "",
				weight: "",
				netWeight: "",
				uom: units[i].type,
				pieces: 1,
				description: "",
				condition: "",
				packaging: "",
				declaredValue: ""
			});
			this.setState({
				units,
				classification: false
			});
			this.updateTotals(true);
			this.checkLiability();
		}
		this.clearCarriers();
	}

	clearProduct(i, j) {
		const { units } = this.state;
		if (!_.isEmpty(units)) {
			const unit = units[i];
			if (!_.isEmpty(unit.products)) {
				const product = {
					nmfc: "",
					sub_nmfc: "",
					class: "",
					weight: "",
					netWeight: "",
					uom: units[i].type,
					pieces: 1,
					description: "",
					condition: "",
					packaging: "",
					declaredValue: ""
				};
				unit.products[j] = product;
				units[i] = unit;
				this.setState({
					units,
					classification: false
				});
				this.updateTotals();
				this.checkLiability();
			}
		}
		this.clearCarriers();
	}

	handleProduct(i, j, name, event) {
		const { units } = this.state;
		if (units.length) {
			const unit = units[i];
			if (unit.products.length) {
				const product = unit.products[j];
				product[name] = event.target.value;
				unit.products[j] = product;
				units[i] = unit;
				this.setState({
					units,
					classification: false
				});
				this.updateTotals();
				this.checkLiability();
			}
		}
		this.clearCarriers();
	}

	removeProduct(i, j) {
		const { units } = this.state;
		if (units.length) {
			units[i].products.splice(j, 1);
			this.setState({
				units,
				classification: false
			});
			this.updateTotals(true);
			this.checkLiability();
		}
		this.clearCarriers();
	}

	async updateTotals(requestLnft) {
		const useYatApi = requestLnft == true ? requestLnft : false;
		const { units, user, trailerDims } = this.state;

		let totalUnits = 0;
		let totalWeight = 0;
		let totalCube = 0;
		let totalPCF = 0;
		let totalLNFT = 0;
		let totalLength = 0;
		let totalValue = 0;

		let shipmentList = [];

		const liability = !_.isEmpty(user) && user.user_company_settings.enable_liability == 1;

		for (const i in units) {
			const unit = units[i];
			let weight = 0;
			let cube = 0;
			let pcf = 0;
			let { length, width, height, pieces, stackable } = unit;

			length = parseFloat(length);
			width = parseFloat(width);
			height = parseFloat(height);

			// SUM the longest sides
			totalLength += length > width ? length * pieces : width * pieces;

			unit.products.forEach(product => {
				weight += parseFloat(product.weight);
				if (liability && !_.isEmpty(product.declaredValue)) {
					const quantity = parseFloat(_.trim(product.pieces));
					const declaredValue = parseFloat(_.trim(product.declaredValue));
					totalValue += declaredValue <= 0 ? 0 : declaredValue * quantity * pieces;
				}
			});

			totalWeight += weight * pieces;

			shipmentList.push(YatClient.formatShipment(unit));

			// convert everything to feet for this unit
			length = length / 12;
			width = width / 12;
			height = height / 12;

			// do the math for this unit
			cube = length * width * height;
			pcf = weight / cube;

			totalCube += cube * pieces;
			totalUnits += isNaN(parseInt(pieces)) ? 1 : parseInt(pieces);

			unit.density = pcf.toFixed(2);
			unit.cube = cube.toFixed(2);
			units[i] = unit;
		}

		// do the rest of the math and update the page
		totalLNFT = totalLength / 12;
		if(this.props.yatEnabled && this.state.user.user_company_settings.enable_new_ln_ft_method == 1) {
			let yatReqCanceled = false
			try {
				if(useYatApi) {
					this.setState({lnftLoading: true});
                    let response = await this.yat.optimizeTrailerLoad(shipmentList, null, trailerDims);
                    totalLNFT = response || totalLNFT;
                }else{
                    totalLNFT = this.state.totals.linear_feet <= 0 ? totalLNFT : parseFloat(this.state.totals.linear_feet);
                }
			} catch (error) {
				if (axios.isCancel(error)) {
					yatReqCanceled = true;
                } else{
					console.error("Error optimizing trailer load:", error);
				}
			}
			if(!yatReqCanceled) {
				this.setState({lnftLoading: false});
			}
        }

		totalPCF = totalWeight / totalCube;

		const totals = {
			units: isNaN(totalUnits) || !isFinite(totalUnits) ? units.length : totalUnits,
			weight: isNaN(totalWeight) || !isFinite(totalWeight) ? 0.0 : totalWeight.toFixed(2),
			volume: isNaN(totalCube) || !isFinite(totalCube) ? 0.0 : totalCube.toFixed(2),
			density: isNaN(totalPCF) || !isFinite(totalPCF) ? 0.0 : totalPCF.toFixed(2),
			linear_feet: isNaN(totalLNFT) || !isFinite(totalLNFT) ? 0.0 : totalLNFT.toFixed(2),
			value: isNaN(totalValue) || !isFinite(totalValue) ? 0.0 : totalValue.toFixed(2)
		};

		this.setState({ totals, units });
	}

	async classifyProducts() {
		const { units } = this.state;
		let shipVolume = 0;
		const nmfcList = {};
		const shipWeight = this.state.totals.weight;
		let hasError = false;

		for (const unit of units) {
			shipVolume += ((unit.length * unit.width * unit.height) / Math.pow(12, 3)) * unit.pieces;
			if (!_.isEmpty(unit.products)) {
				for (const product of unit.products) {
					if (!_.isEmpty(product.nmfc)) {
						nmfcList[product.nmfc] = product.nmfc;
					}
				}
			}
		}

		for (const nmfc in nmfcList) {
			const calcClassUrl = "/index.php?p=api&r=json&c=billoflading&m=calcClass&d=" + nmfc + "/" + shipWeight + "/" + shipVolume;
			try {
				const response = await axios.get(calcClassUrl);
				this.props.handleMessages(response);
				const { body } = response.data;
				for (const i in units) {
					const unit = units[i];
					for (let index = 0; index < unit.products.length; index++) {
						const product = unit.products[index];
						if (product.nmfc === nmfc && body.class > 0) {
							product.nmfc = nmfc;
							product.sub_nmfc = body.sub;
							product.class = body.class;
							unit.products[index] = product;
						}
					}
					units[i] = unit;
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
				hasError = true;
			}
		}

		if (!hasError) {
			this.setState({
				units,
				classification: true
			});
		}
	}

    renderRateResults = (carriers) => {

        if (!Array.isArray(carriers)) return null;

        return (
            <RateResults
               carriers={carriers}
               distance={this.state.distance}
               tfmrId={+this.state.tfmr_internal_id}
               volume={this.state.data_type === "volume"}
               showDetails={true}
               showHideMessage={true}
               canSelect={true}
               onSelect={carrier => {
               		this.handleSelect(this.state.selected == carrier.scac ? "" : carrier.scac);
               }}
               selectedCarrierScac={this.state.selected}
               classes={this.props.classes}
               canGetTerminalInfo={true}
               showLiability={true}
               onOpenLiability={carrier => {
                    this.handleLiability(carrier.scac);
               }}
               onClickTerminal={carrier => {
                    this.props.loadTerminalModal(carrier.scac, {
						location: {
							shipper: {
								zip: this.state.shipper_zip,
								country: this.state.shipper_country
							},
							consignee: {
								zip: this.state.consignee_zip,
								country: this.state.consignee_country
							}
						}
					});
			   }}
			   orderRatesBy={this.state.user.user_settings.order_rates_by}
			   orderRatesFrom={this.state.user.user_settings.order_rates_from}
			   displayNCarriers={this.state.user.user_settings.display_n_carriers}
			   disableExclusions={this.state.prevent_exclusion_execution}
           />
        );
    }

	async getQuotes(volume) {

		this.clearCarriers();

		const liability = !_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1;
		if (liability && !this.checkLiability()) {
			this.props.setNotification("Your products are missing information that is required for carrier liability rates!", { variant: "error" });
		}

		if (_.isEmpty(this.state.shipper_zip) || this.state.shipper_zip.length < 5) {
			this.props.setNotification("Shipper zip code is invalid!", { variant: "error" });
			return false;
		}

		if (_.isEmpty(this.state.consignee_zip) || this.state.consignee_zip.length < 5) {
			this.props.setNotification("Consignee zip code is invalid!", { variant: "error" });
			return false;
		}

		const general = {
			code: this.state.warehouse,
			shipper: this.state.shipper_zip,
			shipper_country: convertToCountryCode(this.state.shipper_country, 'long'),
			consignee: this.state.consignee_zip,
			consignee_country: convertToCountryCode(this.state.consignee_country, 'long'),
			shipment_type: this.state.direction
		};

		if (liability) {
			general.allow_excess_liability = this.state.allow_excess_liability ? 1 : 0;
		}

		if (!_.isEmpty(this.state.ship_date)) {
			general.shipdate = moment(this.state.ship_date).format("L");
		}

		if (typeof volume !== 'undefined' && volume === true) {
            general.volume = 1;
            general.shipmentTotalLength = this.state.volume_total_length;
            general.shipmentTotalWidth = this.state.volume_total_width;
            general.shipmentTotalHeight = this.state.volume_total_height;
        }

		const accessorials = this.state.accessorials.filter(accs => accs.checked).map((prop, key) => prop.code);

        if(this.state.blind_shipment === true) {

            accessorials.push('BLD');

        }

		const dimsRequired = (this.state.enable_auto_class || this.state.require_dims == 1) ? true : false;
		let hasZeroDim = false;
		let cancel = false;

		const units = this.state.units.map((prop, key) => {
			const unit = {};
			let { length, width, height, pieces, stackable, type } = prop;

			length = parseFloat(_.trim(length)) / 12;
			width = parseFloat(_.trim(width)) / 12;
			height = parseFloat(_.trim(height)) / 12;

			length = (isNaN(length) || length < 0) ? 0 : length;
			width = (isNaN(width) || width < 0) ? 0 : width;
			height = (isNaN(height) || height < 0) ? 0 : height;

			if(length <= 0 || width <= 0 || height <= 0) {
				hasZeroDim = true;
				if(dimsRequired) {
					this.props.setNotification('Your organization is required to enter the dimensions for each handling unit on the shipment!', { variant: 'error' });
					cancel = true;
					return false;
				}
			}

			const products = prop.products.map((product, index) => {
				let nmfc = _.trim(product.nmfc);
				let sub_nmfc = "";
				if (!_.isEmpty(product.sub_nmfc) && !_.isEmpty(_.trim(product.sub_nmfc))) {
					sub_nmfc = _.trim(product.sub_nmfc);
					nmfc += "-" + sub_nmfc;
				}
				return {
					pieces: product.pieces,
					weight: _.trim(product.weight),
					class: product.class,
					condition: _.trim(product.condition),
					packaging: _.trim(product.packaging),
					netWeight: _.trim(product.netWeight),
					nmfc,
					sub_nmfc,
					description: _.trim(product.description),
					declared_value: _.trim(product.declaredValue)
				};
			});

			return {
				num_of: pieces,
				type: type,
				length: length,
				width: width,
				height: height,
				stack: stackable ? "Yes" : "No",
				products: products
			};
		});

		if(cancel) {
			return false;
		}

		if(hasZeroDim) {

			if(!dimsRequired && this.state.units.length > 1) {
				this.props.setNotification('When not using Dimensions, you must build on 1 pallet only. If you need to use pallet rates, you will have to provide Dimensions.', { variant: 'error' });
				return false;
			} else {
				this.props.setNotification('One or more dimensions were missing from a unit, the quoted rate(s) may not be valid.', { variant: 'warning' });
			}
		}

		const company = this.state.company ? "&d=" + this.state.company : "";

		const data = { general, accessorials, units };

		this.setState({ loadingCarriers: true, showCarriers: true, showVolumeCarriers: true });

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=ctsi&m=quote" + company, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				let query_id = "";
				let quote_id = "";
				let tfmr_internal_id = false;
				let carriers = [];
    			let distance = 0;
				let max_weight = false;
				let selected = "";
				if (response.data.body === null) {
					max_weight = true;
				} else if (!_.isEmpty(response.data.body)) {
					query_id = response.data.body.query_id;
					quote_id = response.data.body.quote_id;
					tfmr_internal_id = response.data.body.tfmr_internal_id || false;
					carriers = response.data.body.carriers;
        			distance = response.data.body.distance;
					selected = response.data.body.selected;
				}
				this.setState({
					query_id,
					quote_id,
					tfmr_internal_id,
					carriers,
        			distance,
					max_weight,
					selected,
					showCarriers: true,
					showVolumeCarriers: false,
					loadingCarriers: false,
				});
				if (typeof volume !== 'undefined' && volume === true) {
                    this.setState({volume_carriers: response.data.body.carriers, showVolumeCarriers: true, carriers: [], showCarriers: false});
                }
			} else {
				this.setState({ loadingCarriers: false });
				this.props.setNotification("There was an error loading the carriers!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loadingCarriers: false });
			this.props.setNotification("There was an error loading the carriers!", { variant: "error" });
		}
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.code}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	handleAccessorials(accessorials) {
		return Object.keys(accessorials).map(key => {
			const accs = accessorials[key];
			return {
				id: accs.id,
				code: accs.code,
				name: accs.name,
				common: accs.common,
				checked: false,
				always_checked: accs.always_checked,
			};
		});
	}

	handleAccsChecked(key, event) {
		const { accessorials } = this.state;
		accessorials[key].checked = event.target.checked;
		this.clearCarriers();
	}

	getHandlingUnits(units) {
		const { classes } = this.props;
		const selectClasses = cx({
			[classes.select]: true,
			[classes.requiredSelect]: true
		});
		return units.map((unit, key) => {
			let products = [];
			if (!_.isEmpty(unit.products)) {
				products = unit.products.map((product, index) => {
					const productClassName = product.hazardous ? classes.hazmat : classes.product;
					const productHeader = product.hazardous ? classes.hazmatHeader : classes.productHeader;
					product.errors = product.errors || [];
					return (
						<Grid container key={index} className={productClassName}>
							<GridItem xs={12} className={productHeader}>
								<GridContainer>
									<GridItem xs={4}>
										<h5>
											<strong>{"PRODUCT #" + (index + 1)}</strong>
											{!_.isEmpty(product.errors) && (
												<WithTooltip
													title="Missing Liability Requirements!"
													content={this.getLiabilityErrors(product.errors)}
												>
													<span>
														<Warning style={{ height: "16px" }} />
													</span>
												</WithTooltip>
											)}
										</h5>
									</GridItem>
									<GridItem xs={8} className={classes.right}>
										{index === unit.products.length - 1 && (
											<Button color="success" size="sm" className={classes.marginRight} onClick={e => this.addProduct(key)}>
												Add Product
											</Button>
										)}
										<Button color="warning" size="sm" className={classes.marginRight} onClick={e => this.clearProduct(key, index)}>
											Clear Product
										</Button>
										<WithTooltip
											title="Delete Product (Commodity)"
											content="Remove this Product (Commodity) from Handling Unit"
										>
											<span>
												<Button color="danger" size="sm" className={classes.marginRight} onClick={e => this.removeProduct(key, index)}>
													Delete Product
												</Button>
											</span>
										</WithTooltip>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={12}>
										<Grid container>
											<Grid item xs>
												<small style={{ marginTop: "10px" }}>Description</small>
												<CustomInput
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
														name: "description",
														value: product.description || "",
														onChange: e => this.handleProduct(key, index, "description", e)
													}}
													white
												/>
											</Grid>
											<Grid item xs={3} style={{ paddingLeft: "5px" }}>
												{this.props.hasProductRead && <WithTooltip
													title="Load A Product (Commodity)"
													content="Choose a pre-configured Product (Commodity) from Product Catalog"
												>
													<span>
														<Button color="white" size="sm" justIcon round style={{ marginTop: "20px" }} onClick={e => this.handleProductSearch(key, index)}>
															<Search />
														</Button>
													</span>
												</WithTooltip>}
											</Grid>
										</Grid>
									</GridItem>
									<GridItem xs={6} sm={!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 ? 2 : 3}>
										<small style={{ marginTop: "10px" }}>
											NMFC{" "}
											{this.state.require_dims == 0 && (
												<WithTooltip
													title="Important Notice!"
													content={this.state.notice_message}
												>
													<span>
														<Warning style={{ height: "16px" }} />
													</span>
												</WithTooltip>
											)}
										</small>
										{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 ? (
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "nmfc",
													value: product.nmfc || "",
													onChange: e => this.handleProduct(key, index, "nmfc", e)
												}}
												style={{ marginTop: "0" }}
												required
											/>
										) : (
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "nmfc",
													value: product.nmfc || "",
													onChange: e => this.handleProduct(key, index, "nmfc", e)
												}}
												style={{ marginTop: "0" }}
												white
											/>
										)}
									</GridItem>
									<GridItem xs={3} sm={2}>
										<small style={{ marginTop: "10px" }}>Sub</small>
										{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 ? (
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "sub_nmfc",
													value: product.sub_nmfc || "",
													onChange: e => this.handleProduct(key, index, "sub_nmfc", e)
												}}
												style={{ marginTop: "0" }}
												required
											/>
										) : (
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "sub_nmfc",
													value: product.sub_nmfc || "",
													onChange: e => this.handleProduct(key, index, "sub_nmfc", e)
												}}
												style={{ marginTop: "0" }}
												white
											/>
										)}
									</GridItem>
									<GridItem xs={3} sm={2}>
										<small style={{ marginTop: "10px" }}>Class</small>
										<FormControl fullWidth className={classes.selectFormControl}>
                                            <FreightClassSelect
                                                MenuProps={{className: classes.selectMenu}}
												classes={{select: classes.select + " " + classes.requiredSelect}}
                                                value={product.class || ""}
                                                inputProps={{ name: "class" }}
                                                onChange={e => this.handleProduct(key, index, "class", e)}
                                            />
                                        </FormControl>
									</GridItem>
									<GridItem xs={!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 ? 4 : 6} sm={2}>
										<small style={{ marginTop: "10px" }}>Quantity</small>
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "number",
												name: "pieces",
												value: product.pieces || "",
												onChange: e => this.handleProduct(key, index, "pieces", e)
											}}
											required
										/>
									</GridItem>
									<GridItem xs={!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 ? 4 : 6} sm={!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 ? 2 : 3}>
										<small style={{ marginTop: "10px" }}>Weight</small>
										<CustomInput
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												name: "weight",
												value: product.weight || "",
												onChange: e => this.handleProduct(key, index, "weight", e),
												onBlur: e => this.updateTotals(true),
												endAdornment: <InputAdornment position="end">lbs.</InputAdornment>
											}}
											required
										/>
									</GridItem>
									{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_dgd == 1 && this.state.user.user_company_settings.enable_liability != 1 && (
										<GridItem xs={4} sm={2}>
											<small style={{ marginTop: "10px" }}>Net Weight</small>
											<CustomInput
												formControlProps={{ fullWidth: true }}
												inputProps={{
													type: "text",
													name: "netWeight",
													value: product.netWeight || "",
													onChange: e => this.handleProduct(key, index, "netWeight", e)
												}}
												white
											/>
										</GridItem>
									)}
									{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3}>
													<small style={{ marginTop: "10px" }}>Net Weight</small>
													<CustomInput
														error={product.errors.indexOf("netWeight") > -1}
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "netWeight",
															value: product.netWeight || "",
															onChange: e => this.handleProduct(key, index, "netWeight", e),
															endAdornment:
																product.errors.indexOf("netWeight") > -1 ? (
																	<InputAdornment position="end" className={classes.error}>
																		<Close style={{ color: "red" }} />
																	</InputAdornment>
																) : (
																	<InputAdornment position="end">lbs.</InputAdornment>
																)
														}}
														required
													/>
												</GridItem>
												<GridItem xs={3}>
													<small style={{ marginTop: "10px" }}>Condition</small>
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={product.condition || ""}
															inputProps={{
																name: "condition"
															}}
															onChange={e => this.handleProduct(key, index, "condition", e)}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value=""
															/>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="New"
															>
																New
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Not New"
															>
																Not New
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={3}>
													<small style={{ marginTop: "10px" }}>Packaging</small>
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={product.packaging || ""}
															inputProps={{
																name: "packaging"
															}}
															onChange={e => this.handleProduct(key, index, "packaging", e)}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value=""
															/>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Crated"
															>
																Crated
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Uncrated"
															>
																Uncrated
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs={3}>
													<small style={{ marginTop: "10px" }}>Value Per Quantity</small>
													<CustomInput
														error={product.errors.indexOf("declaredValue") > -1}
														formControlProps={{ fullWidth: true }}
														inputProps={{
															type: "text",
															name: "declaredValue",
															value: product.declaredValue || "",
															onChange: e => this.handleProduct(key, index, "declaredValue", e),
															placeholder: "0.00",
															startAdornment: <InputAdornment position="start">$</InputAdornment>,
															endAdornment:
																product.errors.indexOf("declaredValue") > -1 ? (
																	<InputAdornment position="end" className={classes.error}>
																		<Close style={{ color: "red" }} />
																	</InputAdornment>
																) : (
																	undefined
																)
														}}
														required
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									)}
								</GridContainer>
							</GridItem>
						</Grid>
					);
				});
			}
			return (
				<GridContainer key={key} className={classes.unitWrapper}>
					<GridItem xs={12} className={classes.unit}>
						<GridContainer>
							<GridItem xs={12} className={classes.unitHeader}>
								<GridContainer>
									<GridItem xs={4}>
										<h5>
											<strong>{"UNIT #" + (key + 1)}</strong>
										</h5>
									</GridItem>
									<GridItem xs={8} className={classes.right}>
										<Button color="danger" size="sm" className={classes.marginRight} onClick={e => this.removeUnit(key)}>
											Delete Unit
										</Button>
										{this.props.hasHandlingUnitRead && <Button color="white" size="sm" justIcon round className={classes.marginRight} onClick={e => this.handleUnitSearch(key)}>
											<Search />
										</Button>}
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={4} sm={2}>
										<small style={{ marginTop: "10px" }}>Type</small>
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={unit.type || "Pallet"} inputProps={{ name: "type" }} onChange={e => this.handleUnit(key, "type", e)}>
												{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.uom) && this.getUnitTypes(this.state.body.uom)}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={4} sm={2}>
										<small style={{ marginTop: "10px" }}>Stackable</small>
										<FormControl fullWidth className={classes.selectFormControl}>
											<Select MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} value={unit.stackable || false} inputProps={{ name: "stackable" }} onChange={e => this.handleUnit(key, "stackable", e)}>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value
												>
													Yes
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value={false}
												>
													No
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={4} sm={2}>
										<small style={{ marginTop: "10px" }}>Quantity</small>
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "number",
												name: "pieces",
												value: unit.pieces || 1,
												onChange: e => this.handleUnit(key, "pieces", e),
												onBlur: e => this.updateTotals(true),
											}}
											required
										/>
									</GridItem>
									<GridItem xs={4} sm={2}>
										<WithTooltip
											title="Length"
											content="Enter item length in linear inches"
										>
											<span>
												<small style={{ marginTop: "10px" }}>Length</small>
												<CustomInput
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
														name: "length",
														value: unit.length || "",
														onChange: e => this.handleUnit(key, "length", e),
														onBlur: e => this.updateTotals(true),
														endAdornment: <InputAdornment position="end">in.</InputAdornment>
													}}
													required={this.state.enable_auto_class == true || this.state.require_dims == true ? true : false}

												/>
											</span>
										</WithTooltip>
									</GridItem>
									<GridItem xs={4} sm={2}>
										<WithTooltip
											title="Width"
											content="Enter item width in linear inches"With
										>
											<span>
												<small style={{ marginTop: "10px" }}>Width</small>
												<CustomInput
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
														name: "width",
														value: unit.width || "",
														onChange: e => this.handleUnit(key, "width", e),
														onBlur: e => this.updateTotals(true),
														endAdornment: <InputAdornment position="end">in.</InputAdornment>
													}}
													required={this.state.enable_auto_class == true || this.state.require_dims == true ? true : false}

												/>
											</span>
										</WithTooltip>
									</GridItem>
									<GridItem xs={4} sm={2}>
										<WithTooltip
											title="Height"
											content="Enter item height in linear inches"
										>
											<span>
												<small style={{ marginTop: "10px" }}>Height</small>
												<CustomInput
													formControlProps={{ fullWidth: true }}
													inputProps={{
														type: "text",
														name: "height",
														value: unit.height || "",
														onChange: e => this.handleUnit(key, "height", e),
														onBlur: e => this.updateTotals(true),
														endAdornment: <InputAdornment position="end">in.</InputAdornment>
													}}
													required={this.state.enable_auto_class == true || this.state.require_dims == true ? true : false}

												/>
											</span>
										</WithTooltip>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</GridItem>
					{!_.isEmpty(products) ? (
						<Grid item xs={12}>
							{products}
						</Grid>
					) : (
						<Grid item xs={12}>
							<Grid container className={classes.product}>
								<GridItem xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: "white", color: "red", textAlign: "center", paddingBottom: "10px" }}>
									<h5>
										<strong>NO PRODUCTS!</strong>
									</h5>
									<Button color="success" size="lg" className={classes.marginRight} onClick={e => this.addProduct(key)}>
										Add Product
									</Button>
								</GridItem>
							</Grid>
						</Grid>
					)}
				</GridContainer>
			);
		});
	}

	getProducts(products) {
		const { classes } = this.props;
		const enableProductPartNo = this.state.enable_product_part_no;
		return products.map((product, key) => {
			let row = [
				<div dangerouslySetInnerHTML={{ __html: product.code }} />,
				<div dangerouslySetInnerHTML={{ __html: product.name }} />,
				<div dangerouslySetInnerHTML={{ __html: product.nmfc }} />,
				<div dangerouslySetInnerHTML={{ __html: product.sub_nmfc }} />,
				<div dangerouslySetInnerHTML={{ __html: product.pClass }} />,
				<div dangerouslySetInnerHTML={{ __html: product.weight }} />,
				<div dangerouslySetInnerHTML={{ __html: product.hazmat }} />,
				<div className="actions-right">
					<Button size="sm" color="linkedin" onClick={() => this.handleProductAlert(key)}>
						Select
					</Button>
				</div>
			];
			if(enableProductPartNo) {
				row.splice(1, 0, (
					<div dangerouslySetInnerHTML={{ __html: product.part_no }} />
				));
			}
			return product.is_parcel == 0 ? row : []
		});
	}

	async handleProductSearch(i, j) {
		const { warehouse, units } = this.state;
		const product = units[i].products[j];

		const searchUrl = "/index.php?p=api&r=json&c=product&m=find&d=" + warehouse + "/" + product.description;

		try {
			const response = await axios.get(searchUrl);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				if (response.data.body.length == 1 ) {
					const { units } = this.state;
					const productSelect = {};

					Object.keys(response.data.body[0]).map(key => {
						const prop = response.data.body[0][key];
						if (prop) {
							productSelect[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
						}
					});

					if(productSelect.alert_message != null && productSelect.alert_message.length > 0) {

						this.setState({
							units,
							products: response.data.body,
							productUnitKey: i,
							productKey: j,
						}, this.openAlert(productSelect.alert_message, () => {this.handleProductSelect(0)}));

					} else {

						const product = units[i].products[j];

						product.nmfc = productSelect.nmfc;
						product.sub_nmfc = productSelect.sub_nmfc;
						product.class = productSelect.pClass;
						product.description = productSelect.name;
						product.weight = productSelect.weight;
						product.hazardous = productSelect.hazmat == "yes";

						units[i].products[j] = product;

						this.setState({
							units,
							products: [],
							productUnitKey: "",
							productKey: "",
							productSearchModal: false
						});
						this.updateTotals(true);
						this.checkLiability();

					}

				} else if (response.data.body.length > 1 ) {

					this.setState({
						products: response.data.body,
						productUnitKey: i,
						productKey: j,
						productSearchModal: true
					});

				} else {
					this.props.setNotification("No products found!", {
						variant: "info"
					});
				}
			} else {
				this.props.setNotification("There was an error searching products!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching products!", { variant: "error" });
		}
	}

	handleProductAlert(productSelectKey) {
		const { products } = this.state;
		if(products[productSelectKey].alert_message != null && products[productSelectKey].alert_message.length > 0) {
			this.openAlert(products[productSelectKey].alert_message, () => {this.handleProductSelect(productSelectKey)});
		} else {
			this.handleProductSelect(productSelectKey);
		}
	}

	handleProductSelect(productSelectKey) {
		const { products, units, productUnitKey, productKey } = this.state;
		const productSelect = {};

		Object.keys(products[productSelectKey]).map(key => {
			const prop = products[productSelectKey][key];
			if (prop) {
				productSelect[key] = prop.replace(/<span class=\"benchmark\">|<\/span>/gi, "");
			}
		});

		const product = units[productUnitKey].products[productKey];

		product.nmfc = productSelect.nmfc;
		product.sub_nmfc = productSelect.sub_nmfc;
		product.description = productSelect.name;
		product.class = productSelect.pClass;
		product.weight = productSelect.weight;
		product.hazardous = productSelect.hazmat == "yes";

		units[productUnitKey].products[productKey] = product;

		//auto select haz
        let accessorials = this.state.accessorials;
        if (product.hazardous === true) {
            accessorials.map((acc, index) => {
                if (acc.code == "420") {
                    accessorials[index].checked = true;
                }
            });
        }

		this.setState({
			units,
			products: [],
			productUnitKey: "",
			productKey: "",
			productSearchModal: false,
			classification: false,
			accessorials: [...accessorials],
		});
		this.updateTotals(true);
		this.checkLiability();
	}

	getUnits(data) {
		return data.map((prop, key) => {
			return [
				prop.label,
				prop.type,
				prop.stack == 1 ? "Yes" : "No",
				prop.pieces,
				prop.length,
				prop.width,
				prop.height,
				<div className="actions-right">
					<Button size="sm" color="linkedin" onClick={() => this.handleUnitSelect(key)}>
						Select
					</Button>
				</div>
			];
		});
	}

	async handleUnitSearch(i) {
		const { warehouse } = this.state;
		const url = "/index.php?p=api&r=json&c=handUnit&m=catalog&d=" + warehouse;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				const handling_units = Object.values(response.data.body); // convert body object to array
				handling_units.pop(); // remove warehouse from the end
				if (handling_units.length == 1) {
					const { units } = this.state;
					const unitSelect = handling_units[0];
					const unit = units[i];

					unit.type = unitSelect.type;
					unit.pieces = unitSelect.pieces;
					unit.stackable = unitSelect.stack == 1;
					unit.length = unitSelect.length;
					unit.width = unitSelect.width;
					unit.height = unitSelect.height;

					units[i] = unit;

					this.setState({
						units,
						unitKey: "",
						handling_units: [],
						unitSearchModal: false
					});
					this.updateTotals(true);
				} else if (handling_units.length > 1) {
					this.setState({
						handling_units,
						unitKey: i,
						unitSearchModal: true
					});
				} else {
					this.props.setNotification("No handling units found!", {
						variant: "info"
					});
				}
			} else {
				this.props.setNotification("There was an error searching handling units!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error searching handling units!", { variant: "error" });
		}
	}

	handleUnitSelect(unitSelectKey) {
		const { handling_units, units, unitKey } = this.state;
		const unit = units[unitKey];
		const unitSelect = handling_units[unitSelectKey];

		unit.type = unitSelect.type;
		unit.pieces = unitSelect.pieces;
		unit.stackable = unitSelect.stack == 1;
		unit.length = unitSelect.length;
		unit.width = unitSelect.width;
		unit.height = unitSelect.height;

		units[unitKey] = unit;

		this.setState({
			units,
			unitKey: "",
			handling_units: [],
			unitSearchModal: false,
			classification: false
		});
		this.updateTotals(true);
	}

	handlePrint() {}

	handlePrintAll() {
		const printUrl = apiUrl + "/index.php?p=api&c=rater&m=printQuote&d=" + this.state.quote_id;
		window.open(printUrl);
	}

	async setDefaultUnit() {
		const { warehouse, defaultUnit } = this.state;
		if (!_.isEmpty(warehouse) && !_.isEmpty(defaultUnit[warehouse]) && defaultUnit[warehouse].id) {
			const unit = defaultUnit[warehouse];
			const units = [
				{
					type: unit.type,
					pieces: unit.pieces,
					stackable: unit.stackable,
					length: unit.length,
					width: unit.width,
					height: unit.height,
					density: "",
					cube: "",
					products: [
						{
							nmfc: "",
							sub_nmfc: "",
							class: "",
							description: "",
							uom: "Piece",
							pieces: "1",
							weight: "",
							netWeight: "",
							condition: "",
							packaging: "",
							declaredValue: "",
							hazardous: false,
							hazmat: {
								class: "",
								un: "",
								packingGroup: "",
								emergencyNumber: "",
								packingInstructions: "",
								overpackUsed: false
							}
						}
					]
				}
			];
			await this.setState({ units }, this.setDefaultProduct);
			this.updateTotals(true);
		} else {
			this.setDefaultProduct();
		}
	}

	async setDefaultProduct() {
		let { warehouse, defaultProduct } = this.state;
		let units = [...this.state.units];
		if(_.isEmpty(units) && !_.isEmpty(this.state.defaultUnit)) {
			this.setDefaultUnit();
		} else {
			if (!_.isEmpty(warehouse) && !_.isEmpty(defaultProduct[warehouse]) && defaultProduct[warehouse].id) {
				const prop = defaultProduct[warehouse];
				const products = [
					{
						nmfc: prop.nmfc,
						sub_nmfc: prop.sub_nmfc,
						class: prop.class,
						description: prop.description,
						uom: prop.uom,
						pieces: prop.pieces,
						weight: prop.weight,
						netWeight: prop.net_weight,
						condition: "",
						packaging: "",
						declaredValue: prop.declared_value || "",
						hazardous: !_.isEmpty(prop.haz_class),
						hazmat: {
							class: !_.isEmpty(prop.haz_class) ? prop.haz_class : "",
							un: !_.isEmpty(prop.haz_class) ? prop.un_num : "",
							packingGroup: !_.isEmpty(prop.haz_class) ? prop.group : "",
							emergencyNumber: !_.isEmpty(prop.haz_class) ? prop.emergency : "",
							packingInstructions: !_.isEmpty(prop.haz_class) ? prop.packing_inst : "",
							overpackUsed: false
						}
					}
				];
				if (_.isEmpty(units)) {
					units = [
						{
							type: unit.unit_type,
							pieces: unit.pieces,
							stackable: unit.stackable,
							length: unit.length,
							width: unit.width,
							height: unit.height,
							density: "",
							cube: "",
							products
						}
					];
				} else {
					units[0].products = products;
				}
				await this.setState({ units });
				this.updateTotals(true);
				this.checkLiability();
			}
		}
	}

	getUnitTypes(uom) {
		const { classes } = this.props;
		return uom.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.name}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	async getTruckloadQuote() {
		const { shipper_zip, consignee_zip, tl_equipment } = this.state;
		let addressData = true;

		if (_.isEmpty(shipper_zip) || shipper_zip.length < 5) {
			this.props.setNotification("Shipper zip code is invalid!", { variant: "error" });
			addressData = false;
		}

		if (_.isEmpty(consignee_zip) || consignee_zip.length < 5) {
			this.props.setNotification("Consignee zip code is invalid!", { variant: "error" });
			addressData = false;
		}

		if (_.isEmpty(tl_equipment)) {
			this.props.setNotification("Please select an equipment category to get a truckload quote.", { variant: "error" });
			addressData = false;
		}

		const required = ["shipper_city", "shipper_state", "shipper_country", "consignee_city", "consignee_state", "consignee_country"];

		for (const value of required) {
			if (_.isEmpty(this.state[value])) {
				addressData = false;
				break;
			}
		}

		if (addressData === false) {
			this.props.setNotification("We were not able to determine the shipper/consignee cities based on the zip codes provided.", { variant: "error" });
			this.setState({ loadingCarriers: false });
			return false;
		}

		this.setState({
			carriers: [],
			max_weight: false,
			showCarriers: false,
			loadingCarriers: false,
			showQuote: true,
			loadingQuote: true,
			quote: "",
			quote_id: "",
			query_id: ""
		});

		const data = {
			warehouseCode: this.state.warehouse,
			shipZip: shipper_zip,
			shipCity: this.state.shipper_city,
			shipState: this.state.shipper_state,
			shipCountry: !_.isEmpty(this.state.shipper_country) ? this.state.shipper_country : "US",
			consZip: consignee_zip,
			consCity: this.state.consignee_city,
			consState: this.state.consignee_state,
			consCountry: !_.isEmpty(this.state.consignee_country) ? this.state.shipper_country : "US",
			equipment: tl_equipment
		};

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=rater&m=tlQuote&on_calculator=1", qs.stringify(data));
			if (typeof response.data !== "string") {
				this.props.handleMessages(response);
				if (response.data.body && response.data.body.quote) {
					this.setState({
						quote_id: response.data.body.quoteId,
						quote: response.data.body.quote
					});
				} else {
					this.setState({
						loadingQuote: false,
						quote: "",
						quote_id: ""
					});
					this.props.setNotification("No quote was found!", {
						variant: "info"
					});
				}
			} else {
				this.setState({
					loadingQuote: false,
					quote: "",
					quote_id: ""
				});
				this.props.setNotification("There was an error getting a truckload quote!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({
				loadingQuote: false,
				quote: "",
				quote_id: ""
			});
			this.props.setNotification("There was an error getting a truckload quote!", { variant: "error" });
		}
	}

	async handleZip(name, zip, country = null) {
		zip = zip.replace(" ", "").toUpperCase();
		if (!_.isEmpty(zip) && ((isNaN(zip) && zip.length >= 6) || (!isNaN(zip) && zip.length >= 5))) {
			try {
				let url = "/index.php?p=api&r=json&c=billoflading&m=searchZip&d=" + encodeURIComponent(zip);
				url += country != null ? `/${convertToCountryCode(country, 'long')}` : ``;
				const response = await axios.get(url);
				if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
					const { body } = response.data;
					if (body[zip]) {
						if (!["USA","CAN","MEX"].includes(body[zip][0].country)) {
							this.props.setNotification("International Shipment - Contact us for information regarding International Shipments.", { variant: "error" });
						}
						this.setState({
							[name + "_city"]: body[zip][0].city,
							[name + "_state"]: body[zip][0].state,
							[name + "_country"]: convertToCountryCode(body[zip][0].country, 'short'),
						});
					} else {
						this.props.setNotification("The postal code could not be found. Chances are it is not valid.", { variant: "error" });
					}
				} else {
					this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error searching for zip code info!", { variant: "error" });
			}
		}
	}

	async handleSelect(scac) {
		if (_.isEmpty(this.state.quote_id)) {
			this.props.setNotification("Quote ID is invalid!", { variant: "error" });
			return false;
		}
		scac = scac || "";
		const url = "/index.php?p=api&r=json&c=rater&m=saveQuoteCarrier";
		const data = { scac, qid: this.state.quote_id };
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body !== false) {
				const { body } = response.data;
				const text = !_.isEmpty(scac) ? "selected" : "unselected";
				const selected = !_.isEmpty(body) && !_.isEmpty(scac) && body.toUpperCase() === scac.toUpperCase() ? scac : "";
				this.setState({ selected });
				this.props.setNotification("Carrier " + text + "!", { variant: "success" });
			} else {
				this.props.setNotification("There was an error selecting the carrier!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error selecting the carrier!", { variant: "error" });
		}
	}

	handleClassification() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Warning!"
					onConfirm={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					confirmBtnText="OK"
				>
					You must classify all products on the shipment before you can get a quote!
				</SweetAlert>
			)
		});
	}

	handleConversion() {
		let { feet, inches } = this.state;
		if (inches.length && !feet) {
			feet = (parseFloat(inches) / 12).toFixed(1);
		} else if (feet.length) {
			inches = (parseFloat(feet) * 12).toFixed(1);
		}
		this.setState({ feet, inches });
	}

	handleSurfaceArea(name, event) {
		this.setState({ [name]: event.target.value });

		const { productLength, productWidth, palletLength, palletWidth } = this.state;

		if (!_.isEmpty(palletLength) && !_.isEmpty(palletWidth) && !_.isEmpty(productLength) && !_.isEmpty(productWidth)) {
			let surfaceArea = ((parseFloat(productLength) * parseFloat(productWidth)) / (parseFloat(palletLength) * parseFloat(palletWidth))) * 100;
			surfaceArea = isNaN(surfaceArea) ? 0 : surfaceArea.toFixed(1);
			this.setState({ surfaceArea });
		} else {
			this.setState({ surfaceArea: 0 });
		}
	}

	async loadDefaults(override) {
		const { warehouse } = this.state;
		if (_.isEmpty(warehouse)) {
			return;
		}

		override = !!override;
		const url = "/index.php?p=api&r=json&c=warehouse&m=getDefaults&d=" + warehouse;

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string") {
				if(!_.isEmpty(response.data.message)) {
					this.props.handleMessages(response);
				}
				if (!_.isEmpty(response.data.body)) {
					const { data } = response;
					let { shipper_zip, shipper_country, consignee_zip, consignee_country, enable_auto_class, direction, accessorials, } = this.state;
					let defaultUnit = {...this.state.defaultUnit};
					let defaultProduct = {...this.state.defaultProduct};

					shipper_country = 'US';
					if(!_.isEmpty(data.body.locations.shipper)) {
						let convertedShipperCountryCode = convertToCountryCode(data.body.locations.shipper.country, 'short');
						if(['US','CA','MX'].includes(convertedShipperCountryCode)) {
							shipper_country = convertedShipperCountryCode;
						}

						if(_.isEmpty(shipper_zip) || override) {
							shipper_zip = data.body.locations.shipper.zip;
							this.handleZip('shipper', shipper_zip, shipper_country);
						}

                        if(data.body.locations.shipper.accessorials) {
                            data.body.locations.shipper.accessorials.map((defaultId) => {
                                accessorials.map((acc, key) => {
                                    if(acc.id == defaultId) {
                                        accessorials[key].checked = true;
                                    }
                                })
                            });
                        }
					}

					consignee_country = 'US';
					if(!_.isEmpty(data.body.locations.consignee)) {
						let convertedConsigneeCountryCode = convertToCountryCode(data.body.locations.consignee.country, 'short');
						if(['US','CA','MX'].includes(convertedConsigneeCountryCode)) {
							consignee_country = convertedConsigneeCountryCode;
						}

						if(_.isEmpty(consignee_zip) || override) {
							consignee_zip = data.body.locations.consignee.zip;
							this.handleZip('consignee', consignee_zip, consignee_country);
						}

                        if(data.body.locations.consignee.accessorials) {
                            data.body.locations.consignee.accessorials.map((defaultId) => {
                                accessorials.map((acc, key) => {
                                    if(acc.id == defaultId) {
                                        accessorials[key].checked = true;
                                    }
                                })
                            });
                        }
					}

					enable_auto_class = data.body.enable_auto_class;
					direction = (_.isEmpty(data.body.terms)) ? direction : data.body.terms;

					await this.setState({
						shipper_zip,
						shipper_country,
						consignee_zip,
						consignee_country,
						enable_auto_class,
						direction,
						prevent_exclusion_execution: data.body.prevent_exclusion_execution == 1 ? true : false,
						enable_product_part_no: data.body.enable_product_part_no == 1 ? true : false,
						accessorials,
					});

					if(!_.isEmpty(data.body.product)) {
						const prod = data.body.product;
						defaultProduct[warehouse] = {
							id: prod.id,
							description: prod.name,
							pieces: prod.pieces,
							uom: prod.unit,
							nmfc: prod.nmfc,
							sub_nmfc: prod.subnmfc,
							class: prod.class,
							weight: prod.weight,
							haz_class: prod.haz_class,
							un_num: prod.un_num,
							group: prod.group,
							emergency: prod.emergency,
							packing_inst: prod.packing_inst,
							net_weight: prod.net_weight ? prod.net_weight : ""
						};
					} else {
						defaultProduct[warehouse] = {};
					}
					await this.setState({ defaultProduct });

					if (!_.isEmpty(data.body.handlingUnit)) {
						const unit = data.body.handlingUnit
						defaultUnit[warehouse] = {
							id: unit.id,
							type: unit.unit_type,
							pieces: unit.pieces,
							stackable: unit.stack == 1,
							length: unit.length,
							width: unit.width,
							height: unit.height
						};
					} else {
						defaultUnit[warehouse] = {};
					}
					await this.setState({ defaultUnit }, this.setDefaultUnit);
				}
			} else {
				this.props.setNotification("An unexpected error response was received from the server loading warehouse defaults!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("An exception occurred loading the warehouse defaults!", { variant: "error" });
		}
	}

	handleLiability(scac) {
		const carrier = this.state.carriers.filter(prop => prop.scac == scac)[0];
		this.setState({
			liabilityModal: true,
			liability_modal_title: carrier.name + " Liability Coverage",
			liability: carrier.liability || null
		});
	}

	getLiability(data) {
		const { classes } = this.props;
		return data.map((rate, key) => {
			const tableData = rate.coveredItems.map((prop, key) => {
				let nmfc = prop.nmfc.prime;
				if (!_.isEmpty(prop.nmfc.sub)) {
					nmfc += "-" + prop.nmfc.sub;
				}
				return [prop.description, prop.class, nmfc, prop.totalNetWeight, prop.totalPieces, "$" + prop.coverage.toFixed(2)];
			});
			return (
				<GridItem xs={12} key={key}>
					<h5>Products covered up to {rate.coverageRate}</h5>
					<Table tableHead={["Product", "Class", "NMFC", "Weight", "Pieces", "Coverage"]} tableData={tableData} />
				</GridItem>
			);
		});
	}

	checkLiability() {
		const liability = !_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1;

		if (!liability) {
			return true;
		}

		const { units } = this.state;
		let requirementsMet = true;
		let hasUsedProduct = false;
		let disableRequestExcess = false;

		for (let i = 0; i < units.length; i++) {
			const unit = units[i];

			for (let j = 0; j < unit.products.length; j++) {
				const product = unit.products[j];
				product.errors = [];
				const missingRequirements = [];

				const condition = _.trim(product.condition);
				const packaging = _.trim(product.packaging);
				const declaredValue = _.trim(product.declaredValue);
				const netWeight = _.trim(product.netWeight);
				const nmfcPrime = _.trim(product.nmfc);
				const nmfcSub = _.trim(product.sub_nmfc);

				if (condition === "Not New") {
					hasUsedProduct = true;
				} else if (_.isEmpty(condition)) {
					product.errors.push("condition");
				}

				if (_.isEmpty(packaging)) {
					product.errors.push("packaging");
				}

				if (_.isEmpty(declaredValue) || isNaN(declaredValue) || declaredValue <= 0) {
					product.errors.push("declaredValue");
				}

				if (_.isEmpty(netWeight) || isNaN(netWeight) || netWeight <= 0) {
					product.errors.push("netWeight");
				}

				if (_.isEmpty(nmfcPrime)) {
					product.errors.push("nmfc");
				}

				if (!_.isEmpty(missingRequirements)) {
					requirementsMet = false;
					disableRequestExcess = true;
				} else {
					disableRequestExcess = false;
				}

				unit.products[j] = product;
			}

			units[i] = unit;
		}

		if (hasUsedProduct) {
			disableRequestExcess = true;
		} else if (requirementsMet) {
			disableRequestExcess = false;
		}

		this.setState({
			units,
			disableRequestExcess
		});

		return requirementsMet;
	}

	requestExcess() {
		if (this.checkLiability()) {
			this.setState({
				allow_excess_liability: true,
				liabilityModal: false
			});
			this.getQuotes();
		}
	}

	getLiabilityErrors(data) {
		if (_.isEmpty(data)) {
			return "";
		}

		const errors = [];

		for (const prop of data) {
			let error = "";
			switch (prop) {
				case "nmfc":
					error = "NMFC Prime not provided";
					break;
				case "netWeight":
					error = "Net Weight not provided";
					break;
				case "condition":
					error = "Condition not provided";
					break;
				case "packaging":
					error = "Packaging not provided";
					break;
				case "declaredValue":
					error = "Value Per Weight not provided";
					break;
			}
			if (error === "") {
				continue;
			}
			errors.push(error);
		}

		if (_.isEmpty(errors)) {
			return "";
		}

		return errors.join("! ") + "!";
	}

	openAlert = (message, callback) => {
        this.props.openAlertModal(message, callback);
    }

	render() {
		const { classes } = this.props;
		let prodCatCols = ["Code", "Name", "NMFC", "Sub NMFC", "Class", "Weight", "Hazmat", "Actions"];
        if(this.state.enable_product_part_no == 1) {
            prodCatCols.splice(1, 0, "Part #");
        }
		return (
			<GridContainer>
				<TerminalInformationModal classes={classes} />
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer style={{marginBottom: "15px"}}>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel htmlFor="warehouse" className={classes.label}>
										Warehouse/Facility
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.warehouse || ""}
											inputProps={{
												name: "warehouse",
												id: "warehouse"
											}}
											onChange={this.handleChange}
										>
											{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.warehouses) ? (
												this.getWarehouses(this.state.body.warehouses)
											) : (
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem
													}}
												>
													No Warehouses
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel htmlFor="direction" className={classes.label}>
										Direction/Terms
									</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select + " " + classes.requiredSelect
											}}
											value={this.state.direction}
											inputProps={{
												name: "direction",
												id: "direction"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Outbound/Prepaid"
											>
												Outbound/Prepaid
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Outbound/Collect"
											>
												Outbound/Collect
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Inbound/Collect"
											>
												Inbound/Collect
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="Third Party"
											>
												Third Party
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Ship Date</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											timeFormat={false}
											className={classes.datetime}
											value={this.state.ship_date || ""}
											inputProps={{
												id: "ship_date",
												name: "ship_date",
												placeholder: "Select Ship Date"
											}}
											onChange={m => this.handleDatetime("ship_date", m)}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={6}>
									{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_blind_shipments == 1 && (
										<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
													checked={this.state.blind_shipment || false}
													onChange={this.handleChecked("blind_shipment")}
												/>
											}
											classes={{
												label: classes.label
											}}
											label="Blind Shipment"
										/>
									)}
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Shipper Zip</InputLabel>
									<br />
									<CustomInput
										id="shipper_zip"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "shipper_zip",
											value: this.state.shipper_zip || "",
											onChange: this.handleInput("shipper_zip")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Shipper Country</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.shipper_country}
											inputProps={{
												name: "shipper_country",
												id: "shipper_country"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="US"
											>
												US
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="CA"
											>
												CA
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="MX"
											>
												MX
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Shipper City/State</InputLabel>
									<br />
									<CustomInput
										id="shipper_city_state"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "shipper_city_state",
											value: !_.isEmpty(this.state.shipper_city) ? this.state.shipper_city + ", " + this.state.shipper_state : "",
											disabled: true
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Consignee Zip</InputLabel>
									<br />
									<CustomInput
										id="consignee_zip"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "consignee_zip",
											value: this.state.consignee_zip || "",
											onChange: this.handleInput("consignee_zip")
										}}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Consignee Country</InputLabel>
									<br />
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											value={this.state.consignee_country}
											inputProps={{
												name: "consignee_country",
												id: "consignee_country"
											}}
											onChange={this.handleChange}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="US"
											>
												US
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="CA"
											>
												CA
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value="MX"
											>
												MX
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={2}>
									<InputLabel className={classes.label}>Consignee City/State</InputLabel>
									<br />
									<CustomInput
										id="consignee_city_state"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											name: "consignee_city_state",
											value: !_.isEmpty(this.state.consignee_city) ? this.state.consignee_city + ", " + this.state.consignee_state : "",
											disabled: true
										}}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs>
											<Button size="sm" color="linkedin" className={classes.marginRight} onClick={() => this.handleModalOpen("feetToInchesModal")}>
												<IoIosCalculator /> Feet to Inches
											</Button>
											<Button size="sm" color="white" className={classes.marginRight} onClick={() => this.handleModalOpen("surfaceAreaModal")}>
												<IoIosCalculator /> Surface Area
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
								{!_.isEmpty(this.state.units) ? (
									<GridItem xs={12} sm={12} md={12} className={classes.unitContainer}>
										{this.getHandlingUnits(this.state.units)}
									</GridItem>
								) : (
									<GridItem xs={12} sm={12} md={12} lg={12} className={classes.unitContainer}>
										<GridContainer className={classes.unitWrapper}>
											<GridItem className={classes.unitHeader} xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: "white", color: "red", textAlign: "center", paddingBottom: "10px" }}>
												<h5>
													<strong>NO HANDLING UNITS!</strong>
												</h5>
												<Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.addUnit}>
													Add Unit
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								)}
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={4} lg={4}>
									<Grid container>
										<Grid item xs={6} sm={8} md={12} lg={12}>
											<WithTooltip
												title="New Handling Unit"
												content="Create a new handling unit onto which to add products"
											>
												<span>
													<Button color="linkedin" onClick={this.addUnit} style={{ marginBottom: "10px" }} className={classes.marginRight}>
														<AddCircle /> Add Unit
													</Button>
												</span>
											</WithTooltip>
											{this.state.enable_auto_class && (
												<WithTooltip
													title="Auto Class"
													content="Calculate the Sub-NMFC as well as the Class for all products at once using Shipment Weight and Total Handling Unit Dimensions for configured NMFC numbers. (Only used for density-rated freight.)"
												>
													<span>
														<Button color={this.state.classification ? "white" : "reddit"} style={{ marginBottom: "10px" }} onClick={this.classifyProducts}>
															<Build /> Classify Products
														</Button>
													</span>
												</WithTooltip>
											)}
										</Grid>
										<Grid item xs={6} sm={6} md={12} lg={12}>
											<Paper className={classes.paper} elevation={1}>
												<Grid container>
													<Grid item xs={6} className={classes.left}>
														Total Units:
													</Grid>
													<Grid item xs={6} className={classes.right}>
														<strong>{this.state.totals.units}</strong>
													</Grid>
													<Grid item xs={6} className={classes.left}>
														Total Weight:
													</Grid>
													<Grid item xs={6} className={classes.right}>
														<strong>{this.state.totals.weight + " lbs."}</strong>
													</Grid>
													{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
														<Grid item xs={6} className={classes.left}>
															Total Value:
														</Grid>
													)}
													{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
														<Grid item xs={6} className={classes.right}>
															<strong>${this.state.totals.value}</strong>
														</Grid>
													)}
													<Grid item xs={6} className={classes.left}>
														Total Cubic Feet:
													</Grid>
													<Grid item xs={6} className={classes.right}>
														<strong>
															{this.state.totals.volume + " ft. "}
															<sup>{3}</sup>
														</strong>
													</Grid>
													<Grid item xs={6} className={classes.left}>
														Total PCF:
													</Grid>
													<Grid item xs={6} className={classes.right}>
														<strong>{this.state.totals.density}</strong>
													</Grid>
													<Grid item xs={6} className={classes.left}>
														Linear Feet:
													</Grid>
													<Grid item xs={6} className={classes.right}>
														{this.props.yatEnabled && !_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_new_ln_ft_method == 1 && (
															<LnftLoadPlanModal
																units={this.state.units}
																company={this.state.user.user_company_settings.company_id}
																reqLoading={this.state.lnftLoading}
															/>
														)}
														{this.state.lnftLoading ? '' : <strong>{this.state.totals.linear_feet} ft</strong>}
													</Grid>
												</Grid>
											</Paper>
										</Grid>
									</Grid>
								</GridItem>
								<GridItem xs={12} sm={12} md={8} lg={8}>
									<GridContainer>
	                					<h5>Accessorials</h5>
										<AccessorialsSelect
											accessorials={this.state.accessorials}
											handleChecked={(key, event) => this.handleAccsChecked(key, event)}
										/>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.data_type === "ltl" ? (
									<Button size="lg" color="warning" className={classes.marginRight}>
										LTL
									</Button>
								) : (
									<Button size="lg" color="transparent"
										className={classes.marginRight}
										onClick={() => this.handleDataType("ltl")}>
										LTL
									</Button>
								)}
								{this.state.showTruckloadTab && !_.isEmpty(this.state.body) && this.state.body.enable_tl_quote == 1 && !_.isEmpty(this.state.user) && this.props.hasTlQuotePermission && (
									<span>
										{this.state.data_type === "truckload" ? (
											<Button size="lg" color="warning" className={classes.marginRight}>
												Truckload
											</Button>
										) : (
											<Button size="lg" color="transparent" className={classes.marginRight} onClick={() => this.handleDataType("truckload")}>
												Truckload
											</Button>
										)}
									</span>
								)}
								{this.state.showVolumeTab && !_.isEmpty(this.state.body) && !_.isEmpty(this.state.user) && this.props.hasVolumeQuotePermission && (
									<span>
										{this.state.data_type === "volume" ? (
											<Button size="lg" color="warning" className={classes.marginRight}>
												Volume
											</Button>
										) : (
											<Button size="lg" color="transparent" className={classes.marginRight} onClick={() => this.handleDataType("volume")}>
												Volume
											</Button>
										)}
									</span>
								)}
								{(this.state.data_type == 'ltl' && (
									<Grid container>
										<GridItem xs={4}>
											{this.state.classification || (!this.state.enable_auto_class) ? (
												<Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.getQuotes}>
													Rate
												</Button>
											) : (
												<Button color="reddit" size="lg" className={classes.marginRight} onClick={this.handleClassification}>
													Rate
												</Button>
											)}
											{!_.isEmpty(this.state.user) && this.state.user.user_company_settings.enable_liability == 1 && (
												<span style={{ marginLeft: "10px" }}>
													<FormControlLabel
														control={
															<Checkbox
																tabIndex={-1}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
																checked={this.state.allow_excess_liability || false}
																onChange={this.handleChecked("allow_excess_liability")}
																disable={this.state.disableRequestExcess.toString()}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Full Value Coverage"
													/>
												</span>
											)}
											<br />
											<br />
											{!_.isEmpty(this.state.carriers) && (
												<Paper className={classes.paper} elevation={1}>
													<Button round size="sm" className={classes.nonProgramCarriers}>
														{" "}
													</Button>{" "}
													<Button round size="sm" className={classes.nonProgramCarriersAlt}>
														{" "}
													</Button>{" "}
													<b>Non-Program Carriers</b>
													<br />
													<Button round size="sm" className={classes.programCarriers}>
														{" "}
													</Button>{" "}
													<Button round size="sm" className={classes.programCarriersAlt}>
														{" "}
													</Button>{" "}
													<b>Program Carriers</b>
												</Paper>
											)}
											<br />
										</GridItem>
										{!_.isEmpty(this.state.carriers) && (
											<GridItem xs={8}>
												<Paper className={classes.paper} elevation={1}>
													This quote does not apply on shipments that qualify as a Capacity Load or as a Cubic Capacity Density rated shipment. For shipments weighing more than 7,500 lbs. or that are greater than 750 cubic ft. or that take up more than 10 linear feet of a trailer please contact TFM at 1-888-653-1323 for routing Instruction. This quote will be based on the information you
													provide. Actual charges will be determined by shipment characteristics and any additional service options applicable at time of shipment.
													{this.state.user &&
														(this.state.user.level === "admin" || this.state.user.user_company_settings.enable_liability == 1) &&
														" Carrier liability rates are based on product data provided here and are subject to all applicable items found in carrier rules tariffs, such as packaging requirements and accurate NMFC numbers. If you require additional coverage, and you have non-new (used, refurbished, etc.) products on your shipment, please contact TFM for pricing."}
												</Paper>
												<br />
											</GridItem>
										)}
									</Grid>
								))}
								{((!_.isEmpty(this.state.body) && this.state.body.enable_tl_quote == 1 && !_.isEmpty(this.state.user) && this.props.hasTlQuotePermission && this.state.showTruckloadTab && this.state.data_type == 'truckload') && (
									<Grid container>
										<GridItem xs={4}>
											<InputLabel htmlFor="tl_equipment" className={classes.label}>
												Choose Equipment
											</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{ className: classes.selectMenu }}
													classes={{ select: classes.select }}
													value={this.state.tl_equipment}
													inputProps={{
														name: "tl_equipment",
														id: "tl_equipment"
													}}
													onChange={this.handleChange}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Van"
													>
														Trailer
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Reefer"
													>
														Refrigerated Trailer
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="Flat"
													>
														Flatbed
													</MenuItem>
												</Select>
											</FormControl>
											<br />
											{this.state.classification || !this.state.enable_auto_class ? (
												<Button color="linkedin" size="lg" className={classes.marginRight} onClick={this.getTruckloadQuote}>
													Rate
												</Button>
											) : (
												<Button color="reddit" size="lg" className={classes.marginRight} onClick={this.handleClassification}>
													Rate
												</Button>
											)}
										</GridItem>
										{this.state.quote && (
											<GridItem xs={8}>
												<Paper className={classes.paper} elevation={1}>
													All pricing subject to availability and standard market fluctuations. Quoted rate may differ from actual rate negotiated with carrier, rate shown is based on historical averages for this lane for a full truck load. We do our best to find the best rate possible for every shipment. We will contact you to confirm shipment and provide final pricing prior to
													dispatching carrier and finalizing shipment. Actual pricing may be higher or lower than quoted amount. Accessorial charges including, but not limited to, hazardous, lift gates, inside delivery, etc vary by carrier and are not included in this quote. We will work directly with the carrier to negotiate an appropriate rate for these accessorials when they are
													requested and will confirm them with you prior to booking the load.
												</Paper>
												<br />
											</GridItem>
										)}
									</Grid>
								))}
								{((!_.isEmpty(this.state.body) && !_.isEmpty(this.state.user) && this.props.hasVolumeQuotePermission && this.state.showVolumeTab && this.state.data_type == 'volume') && (
									<Grid container>
										<GridItem xs={12}>
											{this.state.classification || (!this.state.enable_auto_class) ? (
												<Button color="linkedin" size="lg" className={classes.marginRight} onClick={() => { this.getQuotes(true); }}>
													Rate
												</Button>
											) : (
												<Button color="reddit" size="lg" className={classes.marginRight} onClick={this.handleClassification}>
													Rate
												</Button>
											)}
										</GridItem>
										<GridItem xs={4}>
											<WithTooltip
												title="Total Shipment Length"
												content="The length of floorspace that the shipment will use on the truck in inches."
											>
												<span>
													<InputLabel className={classes.label}>Total Shipment Length</InputLabel>
												</span>
											</WithTooltip>
											<br />
											<CustomInput
												inputProps={{
													type: "text",
													id: "volume_total_length",
													name: "volume_total_length",
													value: this.state.volume_total_length || "",
													onChange: this.handleInput("volume_total_length"),
													endAdornment: <InputAdornment position="end">in.</InputAdornment>
												}}
												required
											/>
										</GridItem>
										<GridItem xs={4}>
											<WithTooltip
												title="Total Shipment Width"
												content="The width of floorspace that the shipment will use on the truck in inches."
											>
												<span>
													<InputLabel className={classes.label}>Total Shipment Width</InputLabel>
												</span>
											</WithTooltip>
											<br />
											<CustomInput
												inputProps={{
													type: "text",
													id: "volume_total_width",
													name: "volume_total_width",
													value: this.state.volume_total_width || "",
													onChange: this.handleInput("volume_total_width"),
													endAdornment: <InputAdornment position="end">in.</InputAdornment>
												}}
											/>
										</GridItem>
										<GridItem xs={4}>
											<WithTooltip
												title="Total Shipment Height"
												content="The shipment total height."
											>
												<span>
													<InputLabel className={classes.label}>Total Shipment Height</InputLabel>
												</span>
											</WithTooltip>
											<br />
											<CustomInput
												inputProps={{
													type: "text",
													id: "volume_total_height",
													name: "volume_total_height",
													value: this.state.volume_total_height || "",
													onChange: this.handleInput("volume_total_height"),
													endAdornment: <InputAdornment position="end">in.</InputAdornment>
												}}
											/>
										</GridItem>
									</Grid>
								))}
							</GridContainer>
							{this.state.data_type === "ltl" && this.state.showCarriers && (
								<Grid container>
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.carriers) ? (
											<Grid container>
												{this.state.quote_id && (
													<Grid item xs={12} className={classes.center}>
														<h5>{"Quote ID: " + this.state.quote_id.replace("QID-", "")}</h5>
													</Grid>
												)}
												<Grid item xs={12} sm={12} md={12}>
													{this.renderRateResults(this.state.carriers)}
												</Grid>
											</Grid>
										) : (
											<Grid container>
												{this.state.max_weight ? (
													<GridItem xs={12}>
														<Paper className={classes.warningPaper} elevation={1}>
															<h4>Contact Us</h4>
															This shipment exceeds the weight limits imposed for your organization. To ensure the greatest level of accuracy please{" "}
															<a href="https://targetfmi.com/contact-us/" target="_blank">
																Contact TFM
															</a>{" "}
															for a quote on this shipment. We apologize for any inconvenience.
														</Paper>
													</GridItem>
												) : (
													<Spinner loading={this.state.loadingCarriers} message="Failed to retrieve quotes from the server" />
												)}
											</Grid>
										)}
									</GridItem>
								</Grid>
							)}
							{this.state.data_type === "truckload" && this.state.showQuote && (
								<Grid container>
									<GridItem xs={12} sm={12} md={12}>
										{this.state.quote ? (
											<Grid container>
												<GridItem xs={12} sm={12} md={12} className={classes.center}>
													<h3>
														<b>Estimated Market Average</b>
													</h3>
													<h2>${this.state.quote}</h2>
												</GridItem>
											</Grid>
										) : (
											<Grid container>
												<Spinner loading={this.state.loadingQuote} message="Failed to retrieve truckload quotes from the server!" />
											</Grid>
										)}
									</GridItem>
								</Grid>
							)}
							{this.state.data_type === "volume" && this.state.showVolumeCarriers && (
								<GridItem xs={12} sm={12} md={12} lg={12}>
									{!_.isEmpty(this.state.volume_carriers) ? (
										<Grid container>
											{this.state.quote_id && (
												<Grid item xs={12} className={classes.center}>
													<h5>{"Quote ID: " + this.state.quote_id.replace("QID-", "")}</h5>
												</Grid>
											)}
											<Grid item xs={12} sm={12} md={12} lg={12}>
												{this.renderRateResults(this.state.volume_carriers)}
											</Grid>
										</Grid>
									) : (
										<Grid container>
											<Spinner loading={this.state.loadingCarriers} message="Failed to retrieve volume quotes from the server!" />
										</Grid>
									)}
								</GridItem>
							)}
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} style={{ marginBottom: "50px", textAlign: "center" }}>
					{!_.isEmpty(this.state.quote_id) && (
						<div>
							<NavLink to={basePath + "/admin/bol/new?quote=" + this.state.quote_id.replace("QID-", "")}>
								<Button color="linkedin" size="lg" className={classes.marginRight}>
									Create BoL
								</Button>
							</NavLink>
							<Button color="warning" size="lg" onClick={this.handlePrintAll}>
								Print All Quotes
							</Button>
						</div>
					)}
				</GridItem>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.unitSearchModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("unitSearchModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("unitSearchModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Handling Units</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						{!_.isEmpty(this.state.handling_units) && <Table tableHead={["Label", "Type", "Stackable", "Pieces", "Length", "Width", "Height", "Actions"]} tableData={this.getUnits(this.state.handling_units)} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} />}
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("unitSearchModal")} color="white">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.productSearchModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("productSearchModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("productSearchModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Found Multiple Products</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBodyX}>
						{!!this.state.products.length && <Table nonResponsive tableHead={prodCatCols} tableData={this.getProducts(this.state.products)} customCellClasses={[classes.right]} customClassesForCells={[7]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[7]} />}
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("productSearchModal")} color="white">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.feetToInchesModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("feetToInchesModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("feetToInchesModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Feet to Inches Calculator</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						<GridContainer>
							<GridItem xs>
								<InputLabel className={classes.label}>Feet</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "feet",
										value: this.state.feet || "",
										onChange: this.handleInput("feet"),
										endAdornment: <InputAdornment position="end">ft.</InputAdornment>
									}}
									white
								/>
							</GridItem>
							<GridItem xs>
								<InputLabel className={classes.label}>Inches</InputLabel>
								<br />
								<CustomInput
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										type: "text",
										name: "inches",
										value: this.state.inches || "",
										onChange: this.handleInput("inches"),
										endAdornment: <InputAdornment position="end">in.</InputAdornment>
									}}
									white
								/>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleConversion()} color="linkedin" style={{ marginRight: "5px" }}>
							Calculate
						</Button>
						<Button onClick={() => this.handleModalClose("feetToInchesModal")} color="danger" style={{ marginRight: "5px" }}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={this.state.surfaceAreaModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => this.handleModalClose("surfaceAreaModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
						<Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.handleModalClose("surfaceAreaModal")}>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Surface Area Usage Calculator</h4>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
						<GridContainer>
							<GridItem xs={2} className={classes.center}>
								<GridContainer>
									<GridItem xs={12} style={{ marginTop: "25px", textAlign: "center" }}>
										<Badge color="gray">Product</Badge>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} style={{ marginTop: "55px", textAlign: "center" }}>
										<Badge color="gray">Pallet</Badge>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={7}>
								<GridContainer className={classes.dividerBottom}>
									<GridItem xs={5}>
										<InputLabel className={classes.label}>Length</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "productLength",
												value: this.state.productLength || "",
												onChange: e => this.handleSurfaceArea("productLength", e)
											}}
											white
										/>
									</GridItem>
									<GridItem xs={2} style={{ marginTop: "30px" }}>
										<Close />
									</GridItem>
									<GridItem xs={5}>
										<InputLabel className={classes.label}>Width</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "productWidth",
												value: this.state.productWidth || "",
												onChange: e => this.handleSurfaceArea("productWidth", e)
											}}
											white
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={5} style={{ marginTop: "8px" }}>
										<InputLabel className={classes.label}>Length</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "palletLength",
												value: this.state.palletLength || "",
												onChange: e => this.handleSurfaceArea("palletLength", e)
											}}
											white
										/>
									</GridItem>
									<GridItem xs={2} style={{ marginTop: "33px" }}>
										<Close />
									</GridItem>
									<GridItem xs={5} style={{ marginTop: "8px" }}>
										<InputLabel className={classes.label}>Width</InputLabel>
										<br />
										<CustomInput
											formControlProps={{ fullWidth: true }}
											inputProps={{
												type: "text",
												name: "palletWidth",
												value: this.state.palletWidth || "",
												onChange: e => this.handleSurfaceArea("palletWidth", e)
											}}
											white
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={2} style={{ paddingTop: "45px" }}>
								<h5>{this.state.surfaceArea}%</h5>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => this.handleModalClose("surfaceAreaModal")} color="danger" style={{ marginRight: "5px" }}>
							Close
						</Button>
					</DialogActions>
					<LiabilityModal
	                    classes={this.props.classes}
	                    liability={this.state.liability}
	                    open={this.state.liabilityModal}
	                    onClose={() => {this.handleModalClose("liabilityModal")}}
	                    title={this.state.liability_modal_title}
	                    onRerate={this.requestExcess}
	                />
				</Dialog>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
    return {
        hasHandlingUnitRead: hasPermission("HANDLING_UNIT_READ")(state),
        hasProductRead: hasPermission("PRODUCT_CATALOG_READ")(state),
        hasVolumeQuotePermission: hasPermission("USE_VOLUME_QUOTE")(state),
        hasTlQuotePermission: hasPermission("USE_TL_QUOTE")(state),
        userIsAdmin: userIsAdmin(state),
		yatEnabled: state.Environment.yat_enabled,
		yatUrl: state.Environment.yat_index_url,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        loadTerminalModal,
		openAlertModal,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(LtlQuote)));
