import React, { Component, Fragment } from "react";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "~/components/CustomButtons/Button.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import NoteAdd from "@material-ui/icons/NoteAdd";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class SpecialInformationFields extends Component {

    getNotes = () => {
        const { classes } = this.props;
        if (!Array.isArray(this.props.cannedNotes)) return null;

        return this.props.cannedNotes.map((prop, key) => {
            return (
                <MenuItem
                    key={key}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={prop.note}
                >
                    {prop.name}
                </MenuItem>
            );
        });
    }

    InputContainer = props => {
        const classes = this.props.classes || {};
        return (
            <Fragment>
                <WithTooltip title={props.tooltipTitle} content={props.tooltipContent}>
                    <span>
                        <InputLabel className={classes.label}>{props.label}</InputLabel>
                    </span>
                </WithTooltip>
                <br />
                {props.children}
            </Fragment>
        );
    }

    RadioContainer = props => {
        const classes = this.props.classes || {};
        return (
            <Fragment>
                <WithTooltip title={props.tooltipTitle} content={props.tooltipContent}>
                    <span>
                        <InputLabel className={classes.label}>{props.label}</InputLabel>
                    </span>
                </WithTooltip>
                <div style={{display: "flex", flexDirection: "row", marginTop: "8px"}}>
                    {props.children}
                </div>
            </Fragment>
        );
    }

    Radio = props => {
        const classes = this.props.classes || {};
        return (
            <FormControlLabel
                control={
                    <Radio
                        checked={!!props.checked}
                        onChange={props.onChange}
                        value={props.value || ""}
                        name={props.name || ""}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{checked: classes.radio, root: classes.radioRoot}}
                    />
                }
                classes={{
                    label: classes.label
                }}
                label={props.label}
            />
        );
    }

    render() {
        const classes = this.props.classes || {};
        return (
            <GridContainer>
                {this.props.showCod && (
                    <GridItem xs={12} sm={2} md={2}>
                        {this.InputContainer({
                            tooltipTitle: "Collect on Delivery Amount",
                            tooltipContent: "Enter the Collect on Delivery (C.O.D.) amount in dollars and cents.",
                            label: "C.O.D. Amount",
                            children: (
                                <CustomInput
                                    id="cod_amount"
                                    inputProps={{
                                        type: "text",
                                        name: "cod_amount",
                                        value: this.props.cod_amount || "",
                                        placeholder: "0.00",
                                        onChange: this.props.onChangeCodAmount,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    white
                                />
                            ),
                        })}
                    </GridItem>
                )}
                <GridItem xs={12} sm={6} md={6}>
                    {this.InputContainer({
                        tooltipTitle: "Special Instructions",
                        tooltipContent: "Enter Special Instructions to put onto the shipment information.",
                        label: "Special Instructions",
                        children: (
                            <CustomInput
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    type: "text",
                                    id: "notes",
                                    name: "notes",
                                    value: this.props.notes || "",
                                    multiline: true,
                                    onChange: this.props.onChangeNotes
                                }}
                                white
                            />
                        ),
                    })}
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    {this.InputContainer({
                        tooltipTitle: "Canned Note",
                        tooltipContent: "Append to the Special Instructions a selected note from your Canned Notes.",
                        label: "Canned Note",
                        children: (
                            <Grid container>
                                <Grid item xs>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            value={this.props.canned_note || ""}
                                            inputProps={{
                                                name: "canned_note",
                                                id: "canned_note"
                                            }}
                                            onChange={this.props.handleChange}
                                        >
                                            {this.getNotes()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <WithTooltip
                                        title="Add"
                                        content="Add this Canned Note to Special Information."
                                    >
                                        <span>
                                            <Button color="linkedin" aria-label="edit" justIcon round onClick={this.props.onAddNote}>
                                                <NoteAdd />
                                            </Button>
                                        </span>
                                    </WithTooltip>
                                </Grid>
                            </Grid>
                        ),
                    })}
                </GridItem>
                {this.props.showCod && (
                    <GridItem xs={12} sm={12} md={3}>
                        {this.RadioContainer({
                            tooltipTitle: "Collect on Delivery Remittance",
                            tooltipContent: "Select the designated party to be remitted the cost of the goods.",
                            label: "Remit C.O.D. Amount To:",
                            children: (
                                <Fragment>
                                    {this.Radio({
                                        checked: this.props.cod_by === "shipper",
                                        onChange: this.props.handleChange,
                                        name:"cod_by",
                                        value:"shipper",
                                        label:"Shipper",
                                    })}
                                    {this.Radio({
                                        checked: this.props.cod_by === "consignee",
                                        onChange: this.props.handleChange,
                                        name: "cod_by",
                                        value: "consignee",
                                        label: "Consignee",
                                    })}
                                </Fragment>
                            ),
                        })}
                    </GridItem>
                )}
                {this.props.showCod && (
                    <GridItem xs={12} sm={5} md={4}>
                        {this.RadioContainer({
                            tooltipTitle: "Collect on Delivery Payment",
                            tooltipContent: "Select the payment method for the carrier's Collect on Delivery fee.",
                            label: "Carrier's C.O.D. Fee Paid With:",
                            children: (
                                <Fragment>
                                    {this.Radio({
                                        checked: this.props.cod_type === "money_order",
                                        onChange: this.props.handleChange,
                                        name: "cod_type",
                                        value: "money_order",
                                        label: "Money Order",
                                    })}
                                    {this.Radio({
                                        checked: this.props.cod_type === "company_check",
                                        onChange: this.props.handleChange,
                                        name: "cod_type",
                                        value: "company_check",
                                        label: "Company Check",
                                    })}
                                    {this.Radio({
                                        checked: this.props.cod_type === "cashiers_check",
                                        onChange: this.props.handleChange,
                                        name: "cod_type",
                                        value: "cashiers_check",
                                        label: "Cashier's Check",
                                    })}
                                    {this.Radio({
                                        checked: this.props.cod_type === "other",
                                        onChange: this.props.handleChange,
                                        name: "cod_type",
                                        value: "other",
                                        label: "Other",
                                    })}
                                </Fragment>
                            ),
                        })}
                    </GridItem>
                )}
            </GridContainer>
        );
    }
}

export default withStyles(style)(SpecialInformationFields);