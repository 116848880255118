import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from "lodash";
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { fetchUserList } from "../../redux/actions/UserList.jsx";

/**
 * A select for use with the list returned by actions/UserList fetchUserList function
 *
 * props:
 * roles - array - user roles filter (admin, carrier, user)
 * companyId - string - filter non-admin users by company
 * placeholder - string - optional label for select control
 * value - string - selected user's ID
 * onChange - callback, function of user object
 */
class UserListSelect extends Component {

	componentDidMount() {
		this.loadUserList();
	}

	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps.roles, this.props.roles)) {
			this.loadUserList();
		}
	}

	state = {
		users: [],
		roles: [],
		loading: false,
	}

	loadUserList = async () => {
		await this.setState({
			loading: true,
			roles: this.props.roles || []
		});
		const userList = await this.props.fetchUserList(this.roles(), this.companyId());
		this.setState({
			users: userList,
			loading: false
		});
	}

	getUserFromList = userId => {
		let result = {id: null, username: ""};
		this.state.users.map(user => {
			if (+userId === +user.id) {
				result = user;
				return false;
			}
		});
		return result;
	}

	onChange = e => {
		if (typeof this.props.onChange === "function") {
			this.props.onChange(this.getUserFromList(e.target.value));
		}
	}

	roles = () => {
		return this.state.roles ? this.state.roles : [];
	}

	companyId = () => {
		return this.props.companyId ? this.props.companyId : null;
	}

	render() {
		const label = this.props.placeholder ? this.props.placeholder : "Select a User";
		const users = this.state.users;
		const loading = !!this.state.loading;
		const value = this.props.value ? this.props.value : "";
		return (
			<FormControl fullWidth>
				<InputLabel>{label}</InputLabel>
				<Select onChange={this.onChange} disabled={loading} value={value} autoWidth={true}>
					{users.map((user, index) => {
	                    return (
	                        <MenuItem key={index} value={user.id}>
	                            {user.username}
	                        </MenuItem>
	                    );
	                })}
				</Select>
			</FormControl>
		);
	}
}

const mapStateToProps = () => {
	return {};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchUserList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListSelect);