import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Accordion from "~/components/Accordion/Accordion.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { closeModal } from "../actions/TerminalInformationModal.jsx"
import { searchObject } from "../../../helpers.jsx";

class TerminalInformationModal extends Component {

    onClose = () => {
        this.props.closeModal();
    }

    getTerminalInfo(terminal) {
        const classes = this.props.classes || {};
        if (_.isEmpty(terminal) || typeof terminal !== "object") {
            return <span>No terminal data available</span>;
        }
        return (
            <div className={classes.left}>
                <h6>Terminal</h6>
                <div>
                    [<strong>{terminal.terminalCode}</strong>] {terminal.terminalName}
                </div>
                <h6>Contact</h6>
                <div>
                    <strong>Name:</strong> {searchObject(terminal, "contact.name", "")}
                    <br />
                    <strong>Phone:</strong> {searchObject(terminal, "contact.phone", "")}
                    <br />
                    <strong>Email:</strong> {searchObject(terminal, "contact.email", "")}
                </div>
                <h6>Address</h6>
                <address>
                    {terminal.address1}
                    <br />
                    {terminal.address2 && terminal.address2}
                    {terminal.address2 && <br />}
                    {terminal.city + ", " + terminal.stateProvince + " " + terminal.postalCode}
                </address>
            </div>
        );
    }

    render() {
        const classes = this.props.classes || {};
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal + " " + classes.modalMedium
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.onClose}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={this.onClose}>
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Terminal Information</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Accordion
                        active={0}
                        collapses={[
                            {
                                title: "Origin",
                                content: this.getTerminalInfo(this.props.origin)
                            },
                            {
                                title: "Destination",
                                content: this.getTerminalInfo(this.props.destination)
                            }
                        ]}
                    />
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={this.onClose} color="white">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class Transition extends Component {
    render() {
        return <Slide direction="down" {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        open: state.TerminalInformationModal.open,
        origin: state.TerminalInformationModal.origin,
        destination: state.TerminalInformationModal.destination,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        closeModal
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TerminalInformationModal);