import {
	uiColors,
	grayColor,
	blackColor,
	whiteColor,
	defaultFont,
	hexToRgb
} from "assets/jss/empire-tms.jsx";

const tableStyle = theme => ({
	warning: {
		color: uiColors.warning.dark
	},
	primary: {
		color: uiColors.primary.default
	},
	danger: {
		color: uiColors.danger.default
	},
	success: {
		color: uiColors.success.default
	},
	info: {
		color: uiColors.info.light
	},
	rose: {
		color: uiColors.rose.default
	},
	gray: {
		color: grayColor[0]
	},
	white: {
		color: whiteColor
	},
	right: {
		textAlign: "right"
	},
	table: {
		marginBottom: "0",
		width: "100%",
		maxWidth: "100%",
		backgroundColor: "transparent",
		borderSpacing: "0",
		borderCollapse: "collapse",
		overflow: "auto"
	},
	tableShoppingHead: {
		fontSize: "0.9em !important",
		textTransform: "uppercase !important"
	},
	tableHeadFontSize: {
		fontSize: "1.25em !important"
	},
	tableHeadCell: {
		color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
		border: "none !important",
		backgroundColor: whiteColor,
	},
	tableCell: {
		...defaultFont,
		lineHeight: "1.42857143",
		padding: "12px 8px!important",
		verticalAlign: "middle",
		fontSize: "1em",
		borderBottom: "none",
		borderTop: "1px solid " + grayColor[5],
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			minHeight: "24px",
			minWidth: "32px"
		},
		color:"#002d55",
		fontWeight:"500",
	},
	tableCellTotal: {
		fontWeight: "500",
		fontSize: "1.25em",
		paddingTop: "14px",
		textAlign: "right"
	},
	tableCellAmount: {
		fontSize: "26px",
		fontWeight: "300",
		marginTop: "5px",
		textAlign: "right"
	},
	tableResponsive: {
		// width: "100%",
		minHeight: "0.1%",
		overflowX: "auto"
	},
	tableStripedRow: {
		backgroundColor: grayColor[12]
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: grayColor[13]
		}
	},
	warningRow: {
		backgroundColor: uiColors.warning.light,
		"&:hover": {
			backgroundColor: uiColors.warning.light
		}
	},
	warningStripedRow: {
		backgroundColor: uiColors.warning.lightest
	},
	dangerRow: {
		backgroundColor: uiColors.danger.lightest,
		"&:hover": {
			backgroundColor: uiColors.danger.light
		}
	},
	successRow: {
		backgroundColor: uiColors.success.darkest,
		"&:hover": {
			backgroundColor: uiColors.success.dark
		}
	},
	infoRow: {
		backgroundColor: uiColors.info.light,
		"&:hover": {
			backgroundColor: uiColors.info.light
		}
	},
	infoStripedRow: {
		backgroundColor: uiColors.info.lightest
	},
});

export default tableStyle;