const initialState = {
	shipments: [],
	pageSize: 50,
	pageNum: 1,
	totalRecords: 0,
	loading: false,
    notesLoading: false,
	loadBoardDefaultColumns: [],
	loadBoardCustomColumns: [],
	loadBoardFilterOptions: [],
	loadBoardFilters: [],
	loadBoardPresetFilters: [],
	transitStatusOptions: [],
	shipmentNoteCounts: [],
	warehouseOptions: [],
	history: [],
	fieldsWithAutoComplete: [
		"Warehouse",
		"Import/Export",
		"Ocean/Air",
		"Status",
		"UOM",
		"Transit Status",
		"Container Size",
	],

	fieldsWithDate: [
		"Shipment Date",
		"Pickup Date/Cargo Ready Date",
		"Actual Pickup Date",
		"Document Cutoff At Origin Date",
		"Cargo Cutoff At Origin Date",
		"ETD",
		"COB",
		"Expected Arrival To Port Of Discharge",
		"Expected Delivery to final w/a/p at destination",
		"Actual arrival at final w/a/p at destination",
		"ETA Door",
		"Actual Door Delivery",
	],

	fieldsWithLimitedOperators: [
		"Warehouse",
		"Import/Export",
		"Ocean/Air",
		"Latest Tracking Update",
		"Shipper Name",
		"Shipper Address",
		"Shipper Address 2",
		"Shipper City",
		"Shipper State",
		"Shipper Country",
		"Consignee Name",
		"Consignee Address",
		"Consignee Address 2",
		"Consignee State",
		"Consignee City",
		"Consignee Country",
		"Status",
		"Transit Status",
		"Carrier",
		"Inco Term",
		"Named Place",
		"Origin Airport/Port",
		"Destination Airport/Port",
		"Container Size",
		"Vessel/Airline Name",
		"UOM",
		"Notes",
	]
}

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_INTL_SHIPMENT": {
			return {...newState, shipments: [...action.shipments]};
		}
		case "SET_GET_INTL_SHIPMENTS_LOADING": {
			return {...newState, loading: action.val};
		}
		case "SET_INTL_SHIPMENTS_NOTES_LOADING": {
			return {...newState, notesLoading: action.val};
		}
		case "SET_INTL_SHIPMENT_TOTAL_RECORDS": {
			return {...newState, totalRecords: action.totalRecords};
		}
		case "SET_INTL_SHIPMENT_PAGE_NUMBER": {
			return {...newState, pageNum: action.pageNum};
		}
		case "SET_INTL_SHIPMENT_DEFAULT_COLUMNS": {
			return {...newState, loadBoardDefaultColumns: action.loadBoardDefaultColumns};
		}
		case "SET_INTL_SHIPMENT_CUSTOM_COLUMNS": {
			return {...newState, loadBoardCustomColumns: action.loadBoardCustomColumns};
		}
		case "SET_INTL_SHIPMENT_LOAD_BOARD_FILTER_OPTIONS": {
			return {...newState, loadBoardFilterOptions: action.loadBoardFilterOptions};
		}
		case "SET_INTL_SHIPMENT_LOAD_BOARD_FILTERS": {
			return {...newState, loadBoardFilters: [...action.loadBoardFilters]};
		}
		case "SET_INTL_SHIPMENT_LOAD_BOARD_PRESET_FILTERS": {
			return {...newState, loadBoardPresetFilters: action.loadBoardPresetFilters};
		}
		case "SET_INTL_SHIPMENT_TRANSIT_STATUS_OPTIONS": {
			return {...newState, transitStatusOptions: action.transitStatusOptions};
		}
		case "SET_INTL_SHIPMENT_WAREHOUSE_OPTIONS": {
			return {...newState, warehouseOptions: action.warehouseOptions};
		}
		case "SET_INTL_SHIPMENT_NOTE_COUNTS": {
			return {...newState, shipmentNoteCounts: action.shipmentNoteCounts};
		}
		case "SET_INTL_SHIPMENT_HISTORY": {
			return {...newState, history: action.history};
		}
        default:
			return state
	}
}