import React from "react";
import { withSnackbar } from "notistack";

import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ManagePrinters extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			printers: [],
			JSPM: null,
			loading: true,
			fedExDefault: "",
			upsDefault: "",
			documentDefault: "",
			show: false,
		}
		this.jspmStatus = this.jspmStatus.bind(this);
		this.loadPrinters = this.loadPrinters.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	async componentDidMount() {
		const { JSPM } = window;
		const fedExDefault = window.localStorage.getItem("FEDEX");
		const upsDefault = window.localStorage.getItem("UPS");
		const documentDefault = window.localStorage.getItem("DOCUMENTS");
		await this.setState({ JSPM });
		try {
			await this.loadPrinters();
		} catch (e) {
			console.error(e);
		}

		this.setState({
			fedExDefault,
			upsDefault,
			documentDefault,
			mounted: true
		});
	}

	loadPrinters = async () => {
		const { JSPM } = this.state;
		const self = this;
		if(JSPM && JSPM.JSPrintManager) {
			JSPM.JSPrintManager.start().then(() => {
				JSPM.JSPrintManager.WS.onStatusChanged = () => {
					if (this.jspmStatus()) {
						JSPM.JSPrintManager.getPrinters().then(myPrinters => {
							const printers = [];
							for (var i = 0; i < myPrinters.length; i++) {
								printers.push(myPrinters[i]);
							}
							if (!_.isEmpty(printers)) {
								this.setState({ printers: printers, show: true, loading: false });
							}
						});
					}
				};
			}, () => {
				this.jspmStatus();
				this.setState({ show: false, loading: false });
			});
		}
	}

	jspmStatus() {
		const { JSPM } = this.state;
		try {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open || JSPM.JSPrintManager.websocket_status == "undefined") {
				return true;
			} else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
				this.props.setNotification("JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm", { variant: "error" });
				return false;
			} else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.BlackListed) {
				this.props.setNotification("JSPM has blacklisted this website!", { variant: "error" });
				return false;
			} else {
				this.props.setNotification("An error occurred when loading available printers!", { variant: "error" });
				return false;
			}
		} catch (error) {
			this.props.setNotification("An exception occurred when loading available printers!", { variant: "error" });
		}
	}

	getPrinters(data, selectedValue) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					value={prop}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
				>
					{prop}
				</MenuItem>
			);
		});
	}

	handleChange = (e) => {
		let carrier = "";
		let { printers } = this.state;
		let key = printers.indexOf(e.target.value);
		if(e.target.name == 'fedExDefault') {
			carrier = 'FEDEX';
		} else if(e.target.name == 'upsDefault') {
			carrier = 'UPS';
		} else if(e.target.name == 'documentDefault') {
			carrier = 'DOCUMENTS';
		}
		if(!_.isEmpty(this.state.printers) && this.state.printers[key]) {
			window.localStorage.setItem(carrier, this.state.printers[key]);
			this.setState({ [e.target.name]: this.state.printers[key] });
		} else {
			window.localStorage.removeItem(carrier.toUpperCase());
			this.setState({ [e.target.name]: ""});
		}
	}

	render() {
		return(
			<>
				<br />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<GridContainer>
							{this.state.show ? (
								<>
									<GridItem xs={4} sm={4} md={4}>
										<InputLabel >FedEx Default Printer:</InputLabel>
										<br />
										<FormControl fullWidth>
											<Select
												value={this.state.fedExDefault || ""}
												inputProps={{ name: "fedExDefault" }}
												onChange={this.handleChange}
											>
												{!_.isEmpty(this.state.printers) && (
													this.getPrinters(this.state.printers)
												)}
												<MenuItem
													key={-1}
													value={""}
												>
													Remove Default Printer
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={4} sm={4} md={4}>
										<InputLabel >UPS Default Printer:</InputLabel>
										<br />
										<FormControl fullWidth>
											<Select
												value={this.state.upsDefault || ""}
												inputProps={{ name: "upsDefault" }}
												onChange={this.handleChange}
											>
												{!_.isEmpty(this.state.printers) && (
													this.getPrinters(this.state.printers, this.state.upsDefault)
												)}
												<MenuItem
													key={-1}
													value={""}
												>
													Remove Default Printer
												</MenuItem>
											</Select>
										</FormControl>
										<br />
									</GridItem>
									<GridItem xs={4} sm={4} md={4}>
										<InputLabel >Document Default Printer:</InputLabel>
										<br />
										<FormControl fullWidth>
											<Select
												value={this.state.documentDefault || ""}
												inputProps={{ name: "documentDefault" }}
												onChange={this.handleChange}
											>
												{!_.isEmpty(this.state.printers) && (
													this.getPrinters(this.state.printers, this.state.documentDefault)
												)}
												<MenuItem
													key={-1}
													value={""}
												>
													Remove Default Printer
												</MenuItem>
											</Select>
										</FormControl>
										<br />
									</GridItem>
										<br />
								</>
							) : (
								<Spinner loading={this.state.loading} message="This workstation is not currently enabled to print thermal labels." />
							)}
						</GridContainer>
					</GridItem>
				</GridContainer>
			</>
		);
	}


}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
		setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(style)(withSnackbar(ManagePrinters)));
