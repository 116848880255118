import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import Transition from "~/components/TMS/ModalTransition.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class TruckloadAuthModal extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
	        <Dialog
	            classes={{
	                root: classes.center + " " + classes.modalRoot,
	                paper: classes.modal
	            }}
	            open={this.props.open}
	            TransitionComponent={Transition}
	            keepMounted
	            onClose={() => this.props.onClose()}
	            aria-labelledby="classic-modal-slide-title"
	            aria-describedby="classic-modal-slide-description"
	        >
	            <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
	                <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.props.onClose()}>
	                    <Close className={classes.modalClose} />
	                </Button>
	                <h4 className={classes.modalTitle}>Authenticate to TargetTL</h4>
	            </DialogTitle>
	            <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
	                <GridContainer className={classes.left}>
	                    <GridItem xs={12}>
	                        <InputLabel className={classes.label}>Username</InputLabel>
	                        <br />
	                        <CustomInput
	                            formControlProps={{ fullWidth: true }}
	                            inputProps={{
	                                type: "text",
	                                name: "username",
	                                value: this.props.username || "",
	                                onChange: this.props.handleInput("username")
	                            }}
	                        />
	                    </GridItem>
	                    <GridItem xs={12}>
	                        <InputLabel className={classes.label}>Password</InputLabel>
	                        <br />
	                        <CustomInput
	                            formControlProps={{ fullWidth: true }}
	                            inputProps={{
	                                type: "password",
	                                name: "password",
	                                value: this.props.password || "",
	                                onChange: this.props.handleInput("password"),
	                                onKeyPress: this.props.handleKeyPress
	                            }}
	                        />
	                    </GridItem>
	                </GridContainer>
	            </DialogContent>
	            <DialogActions className={classes.modalFooter}>
	                <Button color="warning" onClick={() => this.props.onClose()} className={classes.marginRight}>
	                    Close
	                </Button>
	                {this.props.loading ? (
	                    <Button color="linkedin" style={{ lineHeight: 0 }} className={classes.marginRight}>
	                        <CircularProgress size={16} style={{ color: "white" }} />
	                    </Button>
	                ) : (
	                    <Button color="linkedin" onClick={() => this.props.tlAuthenticate()} className={classes.marginRight}>
	                        Submit
	                    </Button>
	                )}
	            </DialogActions>
	        </Dialog>
        );
    }
}

export default withStyles(style)(TruckloadAuthModal);