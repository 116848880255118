import React from "react";
import _ from "lodash";
import { withSnackbar } from "notistack";
import axios from "~/variables/axios.jsx";

import Datetime from "react-datetime";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// core components
import ltlQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";

class DateRangeStep extends React.Component {
  constructor(props) {
    super(props);

    let date = this.props.date;

    this.state = {
      loading: true,
      mounted: false,

      dateType: date.date_type ? date.date_type : "",
      isDynamic: date.date_type == "dynamic" ? true : false,
      dateColumn: date.date_column ? date.date_column : "",
      dateOperator: date.date_operator ? date.date_operator : "",
      dynamicValues: date.date_offset ? date.date_offset.split(",") : [],
      startDate: date.start_date ? date.start_date : "",
      endDate: date.end_date ? date.end_date : "",

      dateTypeList: this.props.dateTypeList,

      isStartDateValid: true,
      isEndDateValid: true,
    };
  }

  componentDidMount() {
    this.getColumnFilter().then(() => {
      this.setState({ mounted: true }, this.handleStatePush())
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.mounted) {
      if (this.props.allStates && this.props.allStates.options && this.props.allStates.options !== prevProps.allStates.options) {
        this.getOptions(this.props.allStates.options.dateTypeList);
      } else if (!_.isEqual(this.props.dateTypeList, prevProps.dateTypeList)) {
        this.getOptions(this.props.dateTypeList);
      }
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  replaceStr(str, find, replace) {
    for (var i = 0; i < find.length; i++) {
      str = str.replace(new RegExp(find[i], "gi"), replace[i]);
    }
    return str;
  }

  getColumnFilter = async () => {
    let url = "/index.php?p=api&r=json&c=reportDefinition&m=getDateTypeColumns&d=" + this.props.reportType;

    try {
      const response = await axios.get(url);

      if (typeof response.data !== "string" && !_.isEmpty(response.data.user) && !_.isEmpty(response.data.body)) {
        var dateCol = [];

        response.data.body.map((item) =>
          dateCol.push({
            value: item.name,
            label: item.aliasname,
          })
        );

        this.setState({
          loading: false,
          dateTypeList: _.orderBy(dateCol, ["value"], ["asc"]),
        }, this.handleStatePush);
      } else {
        this.setState({ loading: false });
        this.props.setNotification("There was an error loading the data!", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  };

  getOptions = (data) => {
    if (typeof data !== "string" && !_.isEmpty(data)) {
      if (this.state.mounted) {
        this.setState({
          loading: false,
          dateTypeList: data,
        }, this.handleStatePush);
      }
    }
  };

  handleCheckBoxValue = (name) => (event) => {
    this.setState({ [name]: !!event.target.checked }, this.handleStatePush);
  };

  handleValue = (name, event) => {
    const value = event.target.value;
    this.setState({ [name]: value }, this.handleStatePush);
  };

  handleDynamicValue = (index, event) => {
    const value = event.target.value;
    const dynamicValues = this.state.dynamicValues;

    dynamicValues[index] = value;

    this.setState({ dynamicValues: dynamicValues }, this.handleStatePush);
  };

  handleStatePush = () => {
    this.props.handleStepState(this.state, "date");
  };

  handleDate = (name, value) => {
    let date = moment(value, "YYYY-MM-DD", true);
    const momentAssign = date.format("YYYY-MM-DD");

    if (name === "startDate") {
      if (!date || !date.isValid()) {
        this.setState({ isStartDateValid: false }, this.handleStatePush);
      } else {
        this.setState({
          isStartDateValid: true,
          [name]: momentAssign,
        }, this.handleStatePush);
      }
    } else if (name === "endDate") {
      if (!date || !date.isValid()) {
        this.setState({ isEndDateValid: false }, this.handleStatePush);
      } else {
        this.setState({
          isEndDateValid: true,
          [name]: momentAssign,
        }, this.handleStatePush);

        const startdate = moment(this.state.startDate);
        let days = startdate.diff(date, 'days');

        if (days >= 364 ) {
          this.props.setNotification(
            "Report generation will take longer due to the volume of the data!",
            {
              variant: "info",
            }
          );
        }
      }
    }
  };

  getMenuItems = (data, classes) => {
    return data.map((item) => {
      return [
        <MenuItem
          key={item}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={item.value}
        >
          {item.label}
        </MenuItem>,
      ];
    });
  };

  getDynamicAdornment = (value) => {
    let unit = 'Day';

    switch (this.state.dateOperator) {
      case 'FromDay':
        unit = 'Day';
        break;
      case 'FromWeek':
        unit = 'Week';
        break;
      case 'FromMonth':
        unit = 'Month'
        break;
    }

    if (Math.abs(value) > 1) {
      unit += 's';
    } else if (value === '0') {
      unit = 'Now';
    }

    let tense;

    if (value > 0) {
      tense = 'from now';
    } else if (value < 0) {
      tense = 'ago';
    }

    return (
      <InputAdornment position="end">
        {unit} {tense}
      </InputAdornment>
    )
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form autoComplete="off">
          <Box mb={3}>
            <GridContainer>
              <GridItem xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.isDynamic}
                      onChange={this.handleCheckBoxValue("isDynamic")}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="Dynamic Date"
                />
                <br />
              </GridItem>
            </GridContainer>
          </Box>
          {this.state.isDynamic ? (
            <GridContainer>
              <GridItem xs={4}>
                <InputLabel className={classes.label}>Date Type </InputLabel>
                <br />
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.dateColumn}
                    onChange={(m) => this.handleValue("dateColumn", m)}
                    inputProps={{
                      name: "dynamicDateType",
                      id: "dynamicDateType",
                    }}
                  >
                    {this.state.mounted
                      ? this.getMenuItems(this.state.dateTypeList, classes)
                      : null}
                  </Select>
                </FormControl>{" "}
              </GridItem>
              <GridItem xs={4}>
                <InputLabel className={classes.label}>
                  Select Interval
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    value={this.state.dateOperator}
                    onChange={(m) => this.handleValue("dateOperator", m)}
                    inputProps={{
                      id: "dateOperator",
                      name: "dateOperator",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="FromDay"
                    >
                      Day
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="FromWeek"
                    >
                      Week
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="FromMonth"
                    >
                      Month
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={4}>
                <GridContainer>
                  <GridItem xs={6}>
                    <InputLabel className={classes.label}>
                      From
                    </InputLabel>
                    <CustomInput
                      inputProps={{
                        type: "number",
                        id: "dynamicValues",
                        name: "dynamicValues",
                        value: this.state.dynamicValues[0],
                        endAdornment: this.getDynamicAdornment(this.state.dynamicValues[0]),
                        onChange: (m) => this.handleDynamicValue(0, m),
                      }}
                      white
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <InputLabel className={classes.label}>
                      To
                    </InputLabel>
                    <CustomInput
                      inputProps={{
                        type: "number",
                        id: "dynamicValues",
                        name: "dynamicValues",
                        value: this.state.dynamicValues[1],
                        endAdornment: this.getDynamicAdornment(this.state.dynamicValues[1]),
                        onChange: (m) => this.handleDynamicValue(1, m),
                      }}
                      white
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={4}>
                <InputLabel className={classes.label}>Date Type </InputLabel>
                <br />
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.dateColumn}
                    onChange={(m) => this.handleValue("dateColumn", m)}
                    inputProps={{
                      name: "dateType",
                      id: "dateType",
                    }}
                  >
                    {this.state.mounted
                      ? this.getMenuItems(this.state.dateTypeList, classes)
                      : null}
                  </Select>
                </FormControl>{" "}
              </GridItem>
              <GridItem xs={4}>
                <InputLabel className={classes.label}>Start Date </InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat="YYYY-MM-DD"
                    value={
                      this.state.startDate
                    }

                    onChange={(m) => this.handleDate("startDate", m)}
                    className={classes.startDate}
                    inputProps={{
                      name: "startDate",
                      placeholder: "YYYY-MM-DD",
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={4}>
                <InputLabel className={classes.label}>End Date </InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat="YYYY-MM-DD"
                    value={
                      this.state.endDate
                    }
                    onChange={(m) => this.handleDate("endDate", m)}
                    className={classes.endDate}
                    inputProps={{
                      name: "endDate",
                      placeholder: "YYYY-MM-DD",
                    }}


                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          )}
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      handleMessages,
      setNotification,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(ltlQuoteStyle)(withSnackbar(DateRangeStep)));