import React from "react";
import Datetime from "react-datetime";

import qs from "qs";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// material ui icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class DataDump extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			start: "",
			end: "",
			email: ""
		}

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.dataDump = this.dataDump.bind(this);
	}
	async componentDidMount() {
		this.setState({
			mounted: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=reporting&m=dataDump"
			);
			const data = response.data;
			if (
				typeof data !== "string" &&
				!_.isEmpty(data.user) &&
				this.state.mounted
			) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleMessages(messages) {
		if (!messages) {
			return;
		}
		for (let prop of messages) {
			let { type, message } = prop,
				variant = "default";
			switch (type) {
				case "info":
					variant = "info";
					break;
				case "success":
					variant = "success";
					break;
				case "warning":
					variant = "warning";
					break;
				case "danger":
				case "error":
					variant = "error";
					break;
			}

			const action = (key) => (
			    <Button
			    	justIcon
			    	round
			    	color="transparent"
			    	onClick={() => { this.props.closeSnackbar(key) }}
			    >
			        <Close />
			    </Button>
			);

			this.props.setNotification(
				message,
				{
					variant,
					action,
					autoHideDuration: 5000
				}
			);
		}
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	async dataDump() {
		let data = {
			start: this.state.start ? this.state.start.format("YYYY-MM-DD") : "",
			end: this.state.end ? this.state.end.format("YYYY-MM-DD") : "",
			email: this.state.email,
		};
		try {
			let url = "/index.php?p=api&c=reporting&m=doDump";
			const response = await axios.post(url, qs.stringify(data));
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.message)
			) {
				this.props.handleMessages(response);
			}
			if (
				typeof response.data !== "string" &&
				!_.isEmpty(response.data.body) &&
				response.data.body.result
			) {
				this.props.setNotification("Request Submitted! You will receive an email containing the requested data. This may take several minutes, depending on the amount of data requested.",
					{ variant: "success" }
				);
				this.setState({
					start: "",
					end: "",
					email: ""
				});
			} else {
				this.props.setNotification(
					"There was an error dumping the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification(
				"There was an error dumping the data!",
				{
					variant: "error"
				}
			);
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel
										className={classes.label}
									>
										Start Date
									</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											value={this.state.start || ""}
											timeFormat={false}
											onChange={m => this.handleDatetime("start", m)}
											className={classes.datetime}
											inputProps={{
												id: "start",
												name: "start"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel className={classes.label}>
										End Date
									</InputLabel>
									<br />
									<FormControl fullWidth>
										<Datetime
											value={this.state.end || ""}
											timeFormat={false}
											onChange={m => this.handleDatetime("end", m)}
											className={classes.datetime}
											inputProps={{
												id: "end",
												name: "end"
											}}
										/>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="email" className={classes.label}>
                                        Email
                                    </InputLabel>
                                    <br />
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "email",
											name: "email",
											value: this.state.email || "",
											onChange: this.handleInput("email")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<Button
										color="linkedin"
										style={{
											marginRight: "5px"
										}}
										onClick={this.dataDump}
									>
										Email Data Dump
									</Button>
								</GridItem>
								<GridItem xs={12}>
									<small>* Report will generate and be sent to the email provided once complete.</small>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(DataDump));
