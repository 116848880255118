import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";

// custom variables
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

// style
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class CannedNotes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			alert: null,
			body: null,
			user: null
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDeleteNote = this.handleDeleteNote.bind(this);
		this.deleteNote = this.deleteNote.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=notes");
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}

	getHeaders() {
		return ["ID", "Title", "Note", "Actions"];
	}

	getColumns(data) {
		return data.map((prop, key) => {
			return [
				prop.id,
				prop.name,
				prop.plural_name,
				<div style={{ textAlign: "right" }}>
					<NavLink to={basePath + "/admin/note/edit/" + prop.id}>
						<Button size="sm" color="info">
							View/Edit
						</Button>
					</NavLink>
					<Button size="sm" color="danger" style={{ marginLeft: "5px" }} onClick={() => this.handleDeleteNote(prop.id)}>
						Delete
					</Button>
				</div>
			];
		});
	}

	handleDeleteNote(id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to remove this note?"
					onConfirm={() => this.deleteNote(id)}
					onCancel={() => this.deleteNote()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	async deleteNote(id) {
		this.setState({ alert: null });
		if (_.isEmpty(id)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&m=deleteNote&d=" + id;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				this.setState({
					show: false,
					loading: true
				});
				const res = await axios.get("/index.php?p=api&r=json&c=admin&m=notes");
				if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
					this.props.handleMessages(res.data.message);
				}
				if (typeof res.data !== "string" && !_.isEmpty(res.data.user) && !_.isEmpty(res.data.body)) {
					this.setState({
						show: true,
						loading: false,
						body: res.data.body,
						user: res.data.user
					});
				} else {
					this.setState({ loading: false });
					this.props.setNotification("There was an error loading the notes!", { variant: "error" });
				}
			} else {
				this.props.setNotification("There was an error deleting the note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error deleting the note!", { variant: "error" });
		}
	}

	render() {
		const { classes } = this.props;
		const link = basePath + "/admin/note/new";
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											style={{
												marginRight: "4px"
											}}
										>
											New Note
										</Button>
									</NavLink>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) ? (
											<Table tableHead={this.getHeaders()} tableData={this.getColumns(this.state.body)} customCellClasses={[classes.right]} customClassesForCells={[3]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[3]} />
										) : (
											<p className={classes.center}>
												<b>{"No notes to display"}</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner loading={this.state.loading} message="Failed to retrieve notes from the server" />
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(CannedNotes));