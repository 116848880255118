import {
  uiColors,
  grayColor,
  hexToRgb
} from "assets/jss/empire-tms.jsx";

const customLinearProgressStyle = {
  root: {
    height: "4px",
    marginBottom: "20px",
    overflow: "hidden"
  },
  bar: {
    height: "4px"
  },
  primary: {
    backgroundColor: uiColors.primary.default
  },
  warning: {
    backgroundColor: uiColors.warning.default
  },
  danger: {
    backgroundColor: uiColors.danger.default
  },
  success: {
    backgroundColor: uiColors.success.default
  },
  info: {
    backgroundColor: uiColors.info.default
  },
  rose: {
    backgroundColor: uiColors.rose.default
  },
  gray: {
    backgroundColor: grayColor[0]
  },
  primaryBackground: {
    background: "rgba(" + hexToRgb(uiColors.primary.default) + ", 0.2)"
  },
  warningBackground: {
    background: "rgba(" + hexToRgb(uiColors.warning.default) + ", 0.2)"
  },
  dangerBackground: {
    background: "rgba(" + hexToRgb(uiColors.danger.default) + ", 0.2)"
  },
  successBackground: {
    background: "rgba(" + hexToRgb(uiColors.success.default) + ", 0.2)"
  },
  infoBackground: {
    background: "rgba(" + hexToRgb(uiColors.info.default) + ", 0.2)"
  },
  roseBackground: {
    background: "rgba(" + hexToRgb(uiColors.rose.default) + ", 0.2)"
  },
  grayBackground: {
    background: "rgba(" + hexToRgb(grayColor[5]) + ", 0.2)"
  }
};

export default customLinearProgressStyle;
