import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setNotification, processNotificationById } from "../../redux/actions/notifications.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "~/components/CustomButtons/Button.jsx";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardHeader from "~/components/Card/CardHeader.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";

import adminNavbarLinksStyle from "~/assets/jss/empire-tms/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    maxNoticesToShow: 10,
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  getDrawerItems() {
    const { classes, rtlActive } = this.props;
    let notices = this.props.notifications.processed;
    notices = notices.concat(this.props.notifications.notifications);
    // ensure newest alerts appear at the top and only show the number of alerts defined in this.state.maxNoticesToShow
    notices = notices.reverse();
    if(notices.length > this.state.maxNoticesToShow) {
        notices = notices.slice(0, this.state.maxNoticesToShow);
    }

    let retVal = notices.map((prop, key) => {
        let className = classes[prop.options.variant];

        return (
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}
              onClick={this.handleClose}
            >
					    <Card className={className} key={key}>
			  				<CardBody>
                  <b>{prop.message}</b>
			  				</CardBody>
              </Card>
            </GridItem >
        );
    });
    return retVal
  }

  getMenuItemsCount() {
    let notices = this.props.notifications.notifications;
    notices = notices.concat(this.props.notifications.processed);
    let count = notices.length;
    if(count > this.state.maxNoticesToShow) {
        count = this.state.maxNoticesToShow;
    }
    return count
  }

  render() {
		const { classes, color, rtlActive } = this.props;
    const { open } = this.state;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <div className={wrapper}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>{this.getMenuItemsCount()}</span>
          </Button>
        <>
          <Drawer anchor="top" open={this.state.open} onClose={this.handleClick}>
            <GridContainer>
              {this.getDrawerItems()}
            </GridContainer>
          </Drawer>
        </>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		notifications: state.notifications,
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    	setNotification,
    	processNotificationById
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(adminNavbarLinksStyle)(HeaderLinks));