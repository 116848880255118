import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import axios from "~/variables/axios.jsx";
import _ from "lodash";

const setPrinters = val => {
	return {type: "SET_AVAILABLE_PRINTERS", val};
}

export const setJSPM = val => {
	return {type: "SET_JSPM", val};
}

export const setUpPrinters = () => {
	return (dispatch, getState) => {
		const { JSPM } = window;
		dispatch(setJSPM(JSPM));
		dispatch(setAvailablePrinters(JSPM));
	}
}
	// this may need to be moved to redux store to share across multiple printer modals.
export const setAvailablePrinters = (JSPM) => {
	return (dispatch, getState) => {
		const state = getState();
		const currentlyAvailablePrinters = {...state.PrintZplModal.printers};
		if(!_.isEmpty(currentlyAvailablePrinters)) {
			// we already loaded the printers so we don't need to check again.
			return;
		}
		JSPM.JSPrintManager.start().then(() => {
			JSPM.JSPrintManager.WS.onStatusChanged = () => {
				if (dispatch(jspmStatus())) {
					JSPM.JSPrintManager.getPrinters().then(myPrinters => {
						const printers = [];
						for (var i = 0; i < myPrinters.length; i++) {
							printers.push(myPrinters[i]);
						}
						if (!_.isEmpty(printers)) {
							dispatch(setPrinters( printers ));
						}
					});
				}
			};
		}, () => {
			dispatch(jspmStatus());
		});
	}
}

export const jspmStatus = () => {
	return (dispatch, getState) => {
		const state = getState();
		const { JSPM } = {...state.PrintZplModal};
		try {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open || JSPM.JSPrintManager.websocket_status == "undefined") {
				return true;
			} else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
				dispatch(handleMessages("JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm"));
				return false;
			} else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.BlackListed) {
				dispatch(handleMessages("JSPM has blacklisted this website!"));
				return false;
			} else {
				dispatch(handleMessages("Other error?"));
				return false;
			}
		} catch (error) {
			dispatch(handleMessages("Exception"));
		}
	}
}


export const autoPrint = (printer, shipmentId, JSPM, labelType = 'label', callback = () => {}) => {
	return async (dispatch, getState) => {

		let url = "";
		if(labelType == 'label') {
			url = "/index.php?p=api&c=parcelShipService&m=viewLabelImage&id=" + shipmentId;
		} else {
			url = "/index.php?p=api&c=parcelShipService&m=viewDocument&id=" + shipmentId + "&type=" + labelType;
		}
		await axios.get(url).then((response) => {

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				dispatch(handleMessages(response.data.message));
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.body)) {
				const { commands } = response.data.body;
				dispatch(printDocument(printer, commands, JSPM));
			}else {
				dispatch(handleMessages("There was an error auto-printing the label!", { variant: "error" }));
			}
		}).catch(error => {
			console.error(error);
			dispatch(handleMessages("There was an exception auto-printing the label!", { variant: "error" }));
		});

		// regardless of JSPM printing the label, reset the shipment. will probably have to change this
		if (typeof callback === 'function') {
			callback();
		}
	}
}

export const printDocument = (printer, commands, JSPM) => {
	return async (dispatch, getState) => {
		const cpj = new JSPM.ClientPrintJob();
		await JSPM.JSPrintManager.start().then(() => {
			JSPM.JSPrintManager.WS.onStatusChanged = function() {
				if (dispatch(jspmStatus())) {
					if (!_.isEmpty(commands)) {
						cpj.clientPrinter = new JSPM.InstalledPrinter(printer);

						// Set printer commands...
						cpj.printerCommands = commands;

						// Send print job to printer!
						cpj.sendToClient();

						dispatch(handleMessages("Print job sent to " + printer + ".", { variant: "success" }));
					} else {
						dispatch(handleMessages("There was an error printing the label!", { variant: "error" }));
					}
				}
			};
		}, () => {
			dispatch(jspmStatus());
		});
		return;
	}
}