import React from "react";
import "formdata-polyfill";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect, NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, apiUrl } from "~/variables/server.jsx";
import _ from "lodash";

import axios from "~/variables/axios.jsx";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Badge from '@material-ui/core/Badge';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import Check from "@material-ui/icons/Check";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Preview from "~/components/TMS/Preview.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "~/components/Table/Table.jsx";

// material ui icons
import FastRewind from "@material-ui/icons/FastRewind";
import Warning from "@material-ui/icons/Warning";
import Info from "@material-ui/icons/InfoOutlined";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import WarehouseSelector from "~/components/TMS/WarehouseSelector.jsx";
import UserListSelect from "../../../components/TMS/UserListSelect.jsx";
import InputTag from "~/components/TMS/InputTag.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import UserPortalSettingsContainer from "./UserPortalSettingsContainer.jsx"
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import PasswordField from "~/components/TMS/PasswordField.jsx";
import { validatePassword } from "../../../helpers.jsx";

import qs from "qs";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ManageUser extends React.Component {

	state = {
		activeTab: "account",
		id: null,
		alert: null,
		redirect: null,
		company: null,
		url: "",
		saved: false,
		loading: true,
		user: null,
		body: null,
		username: "",
		password: "",
		confirmPassword: "",
		showConfirmPassword: false,
		require_password_change: false,
		disable_password_change: false,
		rate_display: "",
		u_calc_type: "% of Savings",
		u_calc_value: "",
		u_calc_by: "truecost",
		u_min_charge: "",
		u_max_charge: "",
		u_enable_load_api: false,
		order_rates_by: "",
		order_rates_from: "",
		display_n_carriers: "",
		level: "user",
		role: "",
		roles: [],
		rates: [],
		fName: "",
		lName: "",
		phone: "",
		email: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		default_lcc_class: "",
		p44_account_group_code: "",
		default_landing_page: "dashboard",
		default_warehouse: "",
		disable_maps_on_bol: false,
		disable_auto_address_bol: false,
		show_tc_in_posting_panel: false,
		apply_oc_to_np_rates: false,
		hide_parcel_rate_details: false,
		display_custom_logo: false,
		display_custom_logo_on_bol: false,
		hide_from_manifest: false,
		dont_apply_addr_book_defaults: false,
		custom_logo_file: "",
		routings_contact: "",
		customer_service_contact: "",
		claims_contact: "",
		sales_rep_contact: "",
		tl_contact: "",
		auditing_contact: "",
		warehouses: [],
		page_warehouses: [],
		require_non_lcc_selection_explanation: 0,
		non_lcc_required: [],
		loading_brokers: true,
		tfmc_brokers: [],
		loading_broker_locations: true,
		tfmc_broker_locations: [],
		user_tfmc_brokers: [],
		tfmc_carriers: [],
		user_tfmc_carriers: [],
		user_tfmc_broker_locations: [],
		userHoldSettings: {},
        notificationId: null,
        notificationActive: false,
        notificationFrequency: null,
        notificationLastRun: null,
        notificationNextRun: null,
	};

	async componentDidMount() {
		await this.fetchTfmcBrokers();
		await this.fetchTfmcCarriers();

		if (!this.creatingNewUser()) {  //this.props.match.params.id == true
			this.setState({
				company: this.props.match.params.company || null,
				id: this.props.match.params.id,
				url: `/index.php?p=api&r=json&c=admin&m=editUser&d=${this.props.match.params.id}`,
			});

			this.fetchUserDataById(this.props.match.params.id);
		} else {
			//if creating user, set defaults
			this.setState({
				routings_contact: "routing@targetfmi.com",
				customer_service_contact: "customerservice@targetfmi.com",
				claims_contact: "claims@targetfmi.com",
				tl_contact: "TL@targetfmi.com",
				auditing_contact: "auditing@targetfmi.com",
			});

			let url = "/index.php?p=api&r=json&c=admin&m=newUser";

			if (!_.isEmpty(this.props.match.params.id)) { //this.props.match.params.id == true
				url = "/index.php?p=api&r=json&c=admin&m=editUser&d=" + this.props.match.params.id;
				if (!_.isEmpty(this.props.match.params.company)) {
					url += "/" + this.props.match.params.company;
					if (!_.isEmpty(this.props.match.params.warehouse)) {
						url += "/warehouse/" + this.props.match.params.warehouse;
					}
				}
			} else if (!_.isEmpty(this.props.match.params.company)) {
				url += "&d=" + this.props.match.params.company + "/company";
			}

			this.setState({
				company: this.props.match.params.company || null,
				id: this.props.match.params.id || null,
				url
			});

			try {
				const response = await axios.get(url);
				const data = response.data;

				if (typeof data !== "string" && !_.isEmpty(data.message)) {
					this.props.handleMessages(response);
				}

				if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
					let { non_lcc_required, require_non_lcc_selection_explanation } = this.state;

					if (!_.isEmpty(data.body.company_details) && data.body.company_details[0].require_non_lcc_selection_explanation == 1) {
						require_non_lcc_selection_explanation = 1;
					}

					if (!_.isEmpty(data.body.non_lcc_selection_options)) {
						non_lcc_required = data.body.non_lcc_selection_options.map(prop => {
							prop.required = false;
							return prop;
						});
					}

					this.props.pageTitle('company_details' in data.body ? 'New User - ' + data.body.company_details[0].name : "New User");

					this.setState({
						loading: false,
						user: data.user,
						body: data.body,
						level: this.isCompanyUser() ? "user" : "admin",
						roles: data.body.roles && typeof data.body.roles === "object" ? Object.values(data.body.roles) : [],
						rates: data.body.rates ? data.body.rates : [],
						page_warehouses: data.body.warehouses ? data.body.warehouses : [],
						default_warehouse: !_.isEmpty(this.props.match.params.warehouse) ? this.props.match.params.warehouse : null,
						warehouses: !_.isEmpty(this.props.match.params.warehouse) ? [this.props.match.params.warehouse] : [],
						require_non_lcc_selection_explanation,
						non_lcc_required,
						loading_brokers: false,
						loading_broker_locations: false,
						tfmc_broker_locations: data.tfmc_broker_locations ? data.tfmc_broker_locations : [],
						user_tfmc_brokers: data.body.user_tfmc_brokers ? data.body.user_tfmc_brokers : [],
						user_tfmc_carriers: data.body.user_tfmc_carriers ? data.body.user_tfmc_carriers : [],
						user_tfmc_broker_locations: data.user_tfmc_broker_locations ? data.user_tfmc_broker_locations : [],
					}, function() {
						const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
						if (queryParams.copy !== undefined) {
							this.fetchUserDataById(queryParams.copy, true);
						}
					});
					this.getUserPortalSettings(this.state.id);
				} else {
					this.setState({ loading: false });
					this.props.setNotification("There was an error loading the data!", {
						variant: "error"
					});
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		}
	}

	/**
	 * Fetch user data by user ID and populate state
	 * @param  string    id      user ID
	 * @param  boolean   copying if copying from existing user, not all fields will need populated.
	 */
	fetchUserDataById = async (id, copying = false) => {
		const url = `/index.php?p=api&r=json&c=admin&m=editUser&d=${id}`;
		try {
			this.setState({ loading: true });
			const response = await axios.get(url);
			const data = response.data;

			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}

			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.body[0]) && !_.isEmpty(data.user)) {
				let {
					username,
					password,
					non_lcc_required,
					warehouses,
					loading_brokers,
					loading_broker_locations,
					tfmc_broker_locations,
					user_tfmc_brokers,
					user_tfmc_broker_locations,
					user_tfmc_carriers,
				} = this.state;

				const body = data.body[0];

				if (!copying) {
					username = body.username || "";
				}

				if (!_.isEmpty(data.warehouses) && !_.isEmpty(data.user_warehouses)) {
					const user_warehouses = Object.values(data.user_warehouses);
					warehouses = data.warehouses
						.filter(prop => {
							return user_warehouses.indexOf(prop.code) !== -1;
						})
						.map(prop => prop.id);
				}

				if(!_.isEmpty(data.user_tfmc_brokers) && !_.isEmpty(this.state.tfmc_brokers)) {
					user_tfmc_brokers = [];
					data.user_tfmc_brokers.forEach(userBroker => {
						this.state.tfmc_brokers.forEach(broker => {
							if(userBroker.broker_id == broker.id) {
								user_tfmc_brokers.push({ id: broker.id, name: broker.name });
							}
						});
					});
				}

				if(!_.isEmpty(data.user_tfmc_broker_locations) && !_.isEmpty(data.tfmc_broker_locations) && !_.isEmpty(this.state.tfmc_brokers)) {
					user_tfmc_broker_locations = [];
					data.user_tfmc_broker_locations.forEach(userBrokerLocationId => {
						data.tfmc_broker_locations.forEach(location => {
							if(userBrokerLocationId == location.id) {
								const brokerForLocation = this.state.tfmc_brokers.find(broker => broker.id == location.broker);
								user_tfmc_broker_locations.push({
									group: `${brokerForLocation.name} - ${brokerForLocation.id}`,
									broker: location.broker,
									id: location.id,
									description: location.description });
							}
						});
					});
				}

				// For displaying broker name and ID in location menu sub-group header
				if(!_.isEmpty(user_tfmc_brokers) && !_.isEmpty(tfmc_broker_locations)) {
					user_tfmc_brokers.forEach(broker => {
						tfmc_broker_locations.forEach(location => {
							if(location.broker == broker.id) location.group = `${broker.name} - ${broker.id}`;
						});
					});
				}

				if (!_.isEmpty(data.user_tfmc_carriers) && !_.isEmpty(this.state.tfmc_carriers)) {
					user_tfmc_carriers = [];
					data.user_tfmc_carriers.forEach(userCarrier => {
						this.state.tfmc_carriers.forEach(carrier => {
							if (userCarrier.carrier_id == carrier.id) {
								user_tfmc_carriers.push({ id: carrier.id, scac: carrier.scac, name: carrier.name });
							}
						})
					})
				}

				if (!_.isEmpty(body.non_lcc_selection_options)) {
					non_lcc_required = data.body[0].non_lcc_selection_options;
				}

				//if this function is loading a user for the edit view,
				//update the page title
				if (!this.creatingNewUser() && !copying) {
					this.props.pageTitle('Edit User - ' + username);
				}

				this.setState({
					loading: false,
					user: data.user,
					page_warehouses: data.warehouses,
					body,
					username,
					password,
					require_password_change: body.change_pw == 1,
					disable_password_change: body.disable_password_change == 1,
					rate_display: body.rate_display_id || "",
					u_calc_type: body.override_calc_type || "",
					u_calc_value: body.override_calc_value || "",
					u_calc_by: body.calc_by || "",
					u_min_charge: body.override_min_charge || "",
					u_max_charge: body.override_max_charge || "",
					u_enable_load_api: body.enable_load_detail_api == 1,
					order_rates_by: body.order_rates_by || "",
					order_rates_from: body.order_rates_from || "",
					display_n_carriers: body.display_n_carriers || "",
					level: body.role || "",
					role: body.user_roles_id || "",
					roles: !_.isEmpty(data.body.roles) ? Object.values(data.body.roles) : [],
					rates: !_.isEmpty(data.rates) ? data.rates : [],
					fName: body.fName || "",
					lName: body.lName || "",
					phone: body.phone || "",
					email: body.email || "",
					address1: body.address1 || "",
					address2: body.address2 || "",
					city: body.city || "",
					state: body.state || "",
					zip: body.zip || "",
					default_lcc_class: body.default_lcc_class || "",
					p44_account_group_code: body.p44_account_group_code || "",
					default_landing_page: body.default_landing_page || "",
					default_warehouse: body.default_warehouse || "",
					disable_maps_on_bol: body.disable_maps_on_bol == 1,
					disable_auto_address_bol: body.disable_auto_address_bol == 1,
					show_tc_in_posting_panel: body.show_tc_in_posting_panel == 1,
					apply_oc_to_np_rates: body.apply_oc_to_np_rates == 1,
					hide_parcel_rate_details: body.hide_parcel_rate_details == 1,
					display_custom_logo: body.display_custom_logo == 1,
					display_custom_logo_on_bol: body.display_custom_logo_on_bol == 1,
					hide_from_manifest: body.hide_from_manifest == 1,
					dont_apply_addr_book_defaults: body.dont_apply_addr_book_defaults == 1,
					routings_contact: body.routings_contact || "",
					customer_service_contact: body.customer_service_contact || "",
					claims_contact: body.claims_contact || "",
					sales_rep_contact: body.sales_rep_contact || "",
					tl_contact: body.tl_contact || "",
					auditing_contact: body.auditing_contact || "",
					require_non_lcc_selection_explanation: body.require_non_lcc_selection_explanation == 1,
					non_lcc_required,
					warehouses,
					loading_brokers: false,
					loading_broker_locations: false,
					tfmc_broker_locations,
					user_tfmc_brokers,
					user_tfmc_broker_locations,
					user_tfmc_carriers,
				}, () => {
					if(!_.isEmpty(user_tfmc_brokers)) {
						this.updateBrokerLocationOptions(user_tfmc_brokers);
					}
				});

				if (typeof body.custom_logo_file === "string" && body.custom_logo_file.length) {
					const fileBlob = await this.getFileFromImage(body.custom_logo_file);
					if (fileBlob) {
						this.setState({custom_logo_file: fileBlob});
					}
				} else {
					this.setState({custom_logo_file: ""});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}

			await this.getUserPortalSettings(id);

		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	fetchTfmcBrokers = async () => {
		let url = `/index.php?p=api&r=json&c=admin&m=getTfmcBrokers`;
		const response = await axios.get(url);
		this.props.handleMessages(response);

		if (response.data) {
			if (response.data.tfmc_brokers) {
				// Alphabetize to help users more easily find a broker
				response.data.tfmc_brokers.sort((a,b) => {
					if(a.name > b.name) return 1;
					if(a.name < b.name) return -1;
					return 0;
				});

				this.setState({ tfmc_brokers: response.data.tfmc_brokers });
			}
		}

		return true;
	}

	fetchTfmcCarriers = async () => {
		let url = `/index.php?p=api&r=json&c=admin&m=getTfmcCarriers`;
		const response = await axios.get(url);
		this.props.handleMessages(response);

		if (response.data) {
			if (response.data.tfmc_carriers) this.setState({ tfmc_carriers: response.data.tfmc_carriers });
		}

		return true;
	}

	getUserPortalSettings = async (id) => {
		let url = `/index.php?p=api&r=json&c=admin&m=getUserPortalSettings&userId=`;
		if(id != null) url += id;
		const response = await axios.get(url);
        this.props.handleMessages(response);
		if(id != null) {
			if (response.data.body) {
				if(response.data.body.userHoldSettings.settings) {
					response.data.body.userHoldSettings.settings = JSON.parse(response.data.body.userHoldSettings.settings);
				}
				this.setState({
					userHoldSettings: response.data.body.userHoldSettings,
					notificationId: response.data.body.userHeldBillNotificationSetting.id,
					notificationActive: response.data.body.userHeldBillNotificationSetting.is_active == 1 ? true : false,
					notificationFrequency: response.data.body.userHeldBillNotificationSetting.frequency,
					notificationLastRun: response.data.body.userHeldBillNotificationSetting.last_run,
					notificationNextRun: response.data.body.userHeldBillNotificationSetting.next_run,
				});
			}
		} else {
			if (response.data.body) {
				if(response.data.body.settings) response.data.body.settings = JSON.parse(response.data.body.settings);
				this.setState({ userHoldSettings: response.data.body });
			}
		}
        return true;
    }

	handleEmailToggle = () => {
		const isSet = this.state.notificationActive ? false : true;
		this.setState({
			notificationActive: isSet,
		});
    }

	handleGeneralPortalSettings = name => event => {
		const { userHoldSettings } = this.state;
		userHoldSettings.settings[name] = event.target.checked ? 1 : 0;
		this.setState({ userHoldSettings: userHoldSettings });
	}

	handleSpecificHoldCodeSettings = name => event => {
		const { userHoldSettings } = this.state;
		userHoldSettings.settings.hold_codes[name][event.target.value] = event.target.checked ? 1 : 0;
		this.setState({ userHoldSettings: userHoldSettings });
	}

	handleDatetime(name, momentObj) {
        this.setState({ [name]: momentObj });
    }

	isCompanyUser = () => {
		return !_.isEmpty(this.props.match.params.company);
	}

	creatingNewUser = () => {
		return !this.props.match.params.id;
	}


	handleTabChange = (event, tab) => {
		this.setState({ activeTab: tab });
	}

	handleModalOpen = (modal) => {
		this.setState({ [modal]: true });
	}

	handleModalClose = (modal) => {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}

	toggle = name => () => this.setState({ [name]: !this.state[name] });

	getRates = data => {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getRoles = data => {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	getWarehouses = data => {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	handleWarehouse = warehouses => {
		this.setState({warehouses: warehouses});
	}

	handleUpload = files => {
		const custom_logo_file = files[0];
		this.setState({ custom_logo_file });
	}

	getNonLccRequired = data => {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return [
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							tabIndex={-1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot
							}}
							value={prop.id}
							checked={!!prop.required}
							onChange={e => this.handleNonLccRequired(key, e)}
						/>
					}
				/>,
				prop.code,
				prop.description
			];
		});
	}

	handleNonLccRequired = (key, event) => {
		const { non_lcc_required } = this.state;
		non_lcc_required[key].required = event.target.checked;
		this.setState({ non_lcc_required });
	}

	handleDeleteLogoFile = () => {
		this.setState({ custom_logo_file: "" });
	}

	getImage = prop => {
		const src = !_.isEmpty(prop.preview) ? prop.preview : apiUrl + "/getLogo.php?logo=" + prop;
		const filename = !_.isEmpty(prop.name) ? prop.name : prop;
		const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
		return <Preview src={src} index={0} extension={extension} handleClick={() => {}} handleDelete={this.handleDeleteLogoFile.bind(this)} />;
	}

	/**
	 * Get a JS File blob from name of an uploaded file in TMS
	 * @param  string filename the file name
	 * @return File | null
	 */
	getFileFromImage = async filename => {
		let file = null;
		const url = `${apiUrl}/getLogo.php?logo=${filename}`;
		try {
			const response = await axios.get(url, {responseType: "blob"});
			if (response.data instanceof Blob) {
				file = new File([response.data], filename);
				file.preview = URL.createObjectURL(file);
			}
		} catch (err) {
			console.error(err);
			this.props.setNotification(<span dangerouslySetInnerHTML={{ __html: "Error loading logo file" }} />, {
				variant: "error",
				autoHideDuration: 5000
			});
		}
		return file;
	}

	handleCopyUser = user => {
		this.fetchUserDataById(user.id, true);
	}

	handleBrokerAssignment = (event, values) => {
		const brokers = values
		this.setState({ user_tfmc_brokers: brokers }, () => this.updateBrokerLocationOptions(brokers));
	}

	handleBrokerLocationAssignment = (event, values) => this.setState({ user_tfmc_broker_locations: values });

	updateBrokerLocationOptions = async (brokers) => {
		// remove any locs associated with a no-longer-selected Broker ID
		let selectedBrokerIds = this.state.user_tfmc_brokers.map(broker => broker.id.toString());
		let selectedLocs = this.state.user_tfmc_broker_locations.filter(loc => selectedBrokerIds.indexOf(loc.broker.toString()) > -1);
		let options = [];
		if(!_.isEmpty(brokers)) {
			let brokerUri = selectedBrokerIds.join('&brokerId[]=');
			this.setState({loading_broker_locations: true});
			try {
				const response = await axios.get(`/index.php?p=api&r=json&c=admin&m=getTfmcBrokerLocations&brokerId[]=${brokerUri}`);
				if(response.data) {
					this.props.handleMessages(response);
					if(response.data.body) {
						response.data.body.forEach(location => {
							// get broker data associated with this location to create group string
							let broker = this.state.user_tfmc_brokers.filter(broker => broker.id == location.broker);
							broker = broker[0];
							location.group = `${broker.name} - ${broker.id}`; // needed for sub-group header
							options.push(location);
						});
					}
				} else {
					this.props.setNotification('Error retrieving TFMC Company Locations for selected Broker IDs', {
						variant: "error"
					});
				}
			} catch(error) {
				console.error(error);
				this.props.setNotification('Exception occurred retrieving TFMC Company Locations for selected Broker IDs', {
					variant: "error"
				});
			}
		}

		this.setState({
			loading_broker_locations: false,
			tfmc_broker_locations: options,
			user_tfmc_broker_locations: selectedLocs,
		});

	}

	handleCarrierAssignment = (event, values) => {
		this.setState({
			user_tfmc_carriers: values
		});
	}

	saveUser = async () => {

		const { password, confirmPassword, username } = this.state;
		if(!username) {
			this.props.setNotification("Username required", { variant: "error" });
			return false;
		}
		if(this.creatingNewUser() && !password) {
			this.props.setNotification("Password required", { variant: "error" });
			return false;
		}
		if(password.length > 0 && password.length < 8) {
			this.props.setNotification("Password must be at least 8 characters", { variant: "error" });
			return false;
		}
		if(password.length > 0 && !validatePassword(password, username)) {
			this.props.setNotification("Password does not meet character requirements", { variant: "error" });
			return false;
		}
		if(password !== confirmPassword) {
			this.props.setNotification("Please ensure both password fields match", { variant: "error" });
			return false;
		}
		if(this.state.notificationActive && !this.state.email) {
			this.props.setNotification("User email not provided. User won't receive held bill reminders.", { variant: "warning" });
		}
		if(this.state.notificationActive && !this.state.notificationNextRun) {
			this.props.setNotification("Next Run Date/Time required when activating held bill reminders", { variant: "error" });
			return false;
		}

		this.setState({loading: true});

		const data = new FormData(); // FormData is needed to upload files

		data.append("username", username);
		data.append("password", password);
		data.append("require_password_change", this.state.require_password_change ? "1" : "0");
		data.append("disable_password_change", this.state.disable_password_change ? "1" : "0");
		data.append("rate_display", this.state.level !== 'carrier' ? this.state.rate_display : this.state.rates[0].id);
		data.append("u_calc_type", this.state.u_calc_type);
		data.append("u_calc_value", this.state.u_calc_value);
		data.append("u_calc_by", this.state.u_calc_by);
		data.append("u_min_charge", this.state.u_min_charge);
		data.append("u_max_charge", this.state.u_max_charge);
		if (this.state.u_enable_load_api) {
			data.append("u_enable_load_api", "1");
		}
		data.append("order_rates_by", this.state.order_rates_by);
		data.append("order_rates_from", this.state.order_rates_from);
		data.append("display_n_carriers", this.state.display_n_carriers);
		data.append("level", this.state.level);
		if (this.state.role) {
			data.append("role", this.state.role);
		}
		data.append("fName", this.state.fName);
		data.append("lName", this.state.lName);
		data.append("phone", this.state.phone);
		data.append("email", this.state.email);
		data.append("address1", this.state.address1);
		data.append("address2", this.state.address2);
		data.append("city", this.state.city);
		data.append("state", this.state.state);
		data.append("zip", this.state.zip);
		data.append("default_lcc_class", this.state.default_lcc_class);
		data.append("p44_account_group_code", this.state.p44_account_group_code);
		data.append("default_landing_page", this.state.default_landing_page);
		data.append("default_warehouse", this.state.default_warehouse);
		data.append("disable_maps_on_bol", this.state.disable_maps_on_bol ? "1" : "0");
		data.append("disable_auto_address_bol", this.state.disable_auto_address_bol ? "1" : "0");
		data.append("show_tc_in_posting_panel", this.state.show_tc_in_posting_panel ? "1" : "0");
		data.append("apply_oc_to_np_rates", this.state.apply_oc_to_np_rates ? "1" : "0");
		data.append("hide_parcel_rate_details", this.state.hide_parcel_rate_details ? "1" : "0");
		data.append("display_custom_logo_on_bol", this.state.display_custom_logo_on_bol ? "1" : "0");
		data.append("dont_apply_addr_book_defaults", this.state.dont_apply_addr_book_defaults ? "1" : "0");
		if (this.state.display_custom_logo) {
			data.append("display_custom_logo", "1");
		}
		if (this.state.hide_from_manifest) {
			data.append("hide_from_manifest", "1");
		}
		if(!_.isEmpty(this.state.warehouses)){
			data.append("warehouses", JSON.stringify(_.compact(this.state.warehouses)));
		}

		let brokerIds = [];
		if(!_.isEmpty(this.state.user_tfmc_brokers)){
			this.state.user_tfmc_brokers.forEach(broker => {
				brokerIds.push(broker.id);
			});
		}
		data.append("user_tfmc_brokers", brokerIds);

		let brokerLocations = {};
		if(!_.isEmpty(this.state.user_tfmc_broker_locations)) {
			this.state.user_tfmc_broker_locations.forEach(location => {
				if(!(location.broker in brokerLocations)) brokerLocations[location.broker] = [];
				brokerLocations[location.broker].push(location.id);
			});
		}
		data.append("user_tfmc_broker_locations", JSON.stringify(brokerLocations));

		let carrierIds = [];
		if (!_.isEmpty(this.state.user_tfmc_carriers)) {
			this.state.user_tfmc_carriers.forEach(carrier => {
				carrierIds.push(carrier.id);
			});
		}
		data.append("user_tfmc_carriers", carrierIds);

		if (this.state.require_non_lcc_selection_explanation) {
			data.append("require_non_lcc_selection_explanation", "1");
			const non_lcc_required = this.state.non_lcc_required
				.filter(lcc => lcc.required)
				.map(prop => {
					return prop.id;
				});
			data.append("non_lcc_required", JSON.stringify(non_lcc_required));
		}

		data.append("routings_contact", this.state.routings_contact);
		data.append("customer_service_contact", this.state.customer_service_contact);
		data.append("claims_contact", this.state.claims_contact);
		data.append("sales_rep_contact", this.state.sales_rep_contact);
		data.append("tl_contact", this.state.tl_contact);
		data.append("auditing_contact", this.state.auditing_contact);
		data.append("custom_logo_file", this.state.custom_logo_file);
		data.append("userHoldSettings", JSON.stringify(this.state.userHoldSettings));
		data.append("notificationId", this.state.notificationId);
		data.append("notificationActive", this.state.notificationActive ? 1 : 0);
		data.append("notificationFrequency", this.state.notificationFrequency);
		data.append("notificationLastRun", this.state.notificationLastRun);
		data.append("notificationNextRun", this.state.notificationNextRun);

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		try {
			const response = await axios.post(this.state.url, data, config);
			if (response.data) {
				this.props.handleMessages(response);
				if (response.data.body) {
					this.setState({
						saved: true
					});
				}
			} else {
				this.props.setNotification("There was an error saving the user!", {
					variant: "error"
				});
			}
			this.setState({ loading: false });
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred saving the user!", {
				variant: "error"
			});
		}
	}

	render() {
		if (this.state.saved) {
			let redirectTo = basePath + "/admin/management/users";
			if (this.state.company) {
				redirectTo = basePath + "/admin/company/" + this.state.company;
			}
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={6} sm={6} md={6} style={{ margin: "10px 0px" }}>
					{this.isCompanyUser() && (
						<NavLink to={basePath + "/admin/company/" + this.props.match.params.company}>
							<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
								<FastRewind /> Back to Company
							</Button>
						</NavLink>
					)}
				</GridItem>
				<GridItem xs={6} sm={6} md={6} style={{ margin: "10px 0px" }}>
					<UserListSelect
						roles={this.isCompanyUser() ? ['user'] : [this.state.level]}
						companyId={this.isCompanyUser() ? this.props.match.params.company : null}
						value=""
						placeholder="Copy Settings From Existing User"
						onChange={this.handleCopyUser}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<TabContext value={this.state.activeTab}>
							<TabList onChange={this.handleTabChange} textColor="primary">
								<Tab label="Account" value="account"
									color="secondary" variant="dot" overlap="circular" component={Badge}
									invisible={this.state.username && (this.state.level === 'admin' || this.state.role)}
								/>
								<Tab label="General" value="general" />
								<Tab label="Rating" value="rating"
									style={{ display: this.state.level === "carrier" ? 'none' : 'inline-flex' }}
									color="secondary" variant="dot" overlap="circular" component={Badge}
									invisible={this.state.level !== 'carrier' && this.state.rate_display}
								/>
								<Tab label="Mappings" value="mappings"
									style={{ visibility: this.state.level === "admin" ? 'hidden' : 'visible' }}
									color="secondary" variant="dot" overlap="circular" component={Badge}
									invisible={this.state.level === "carrier" || (this.state.default_warehouse && this.state.warehouses.length)}
								/>
								<Tab label="Non-LCC Selection" value="nonLCC"
									style={{visibility: this.state.level !== "user" ? 'hidden' : 'visible' }}
								/>
								<Tab label="Customer Portal" value="customerPortal"
									style={{ visibility: this.state.level !== "user" ? 'hidden' : 'visible'}}
								/>
							</TabList>
							<CardBody>
								<GridContainer>
									{(this.state.loading) ? (
										<Spinner loading={true} message="Loading user data..." />
									) : (
										<GridItem xs={12} sm={12} md={12}>
											<TabPanel value="account">
												<GridContainer>
													<GridItem xs={12} sm={12} md={12}>
														<h5><strong>Credentials</strong></h5>
														<GridContainer>
															<GridItem xs={3}>
																<InputLabel className={classes.label}>Username</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "username",
																		value: this.state.username || "",
																		onChange: this.handleInput("username")
																	}}
																	required
																	white
																/>
															</GridItem>
															<GridItem xs={3}>
																<InputLabel className={classes.label}>Password</InputLabel>
																<br />
																<PasswordField
																	password={this.state.password}
																	handleChange={(e) => this.handleChange(e)}
																/>
															</GridItem>
															<GridItem xs={3}>
																<InputLabel className={classes.label}>Confirm Password</InputLabel>
																<br />
																	<CustomInput
																		formControlProps={{ fullWidth: true }}
																		inputProps={{
																			type: this.state.showConfirmPassword ? "text" : "password",
																			endAdornment: (
																				<InputAdornment position="end">
																					<IconButton
																						onClick={this.toggle('showConfirmPassword')}
																						edge="end"
																					>
																					{this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
																					</IconButton>
																				</InputAdornment>
																			),
																			name: "confirmPassword",
																			value: this.state.confirmPassword || "",
																			onChange: this.handleInput("confirmPassword"),
																		}}
																		white
																	/>
															</GridItem>
															<GridItem xs={3}>
																<FormControlLabel
																	control={
																		<Checkbox
																			checked={!!this.state.require_password_change}
																			tabIndex={-1}
																			onChange={this.handleChecked("require_password_change")}
																			checkedIcon={<Check className={classes.checkedIcon} />}
																			icon={<Check className={classes.uncheckedIcon} />}
																			classes={{
																				checked: classes.checked,
																				root: classes.checkRoot
																			}}
																		/>
																	}
																	classes={{
																		label: classes.label
																	}}
																	label="Require User to Change Password"
																/>
															</GridItem>
																<GridItem xs={3}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.disable_password_change}
																				tabIndex={-1}
																				onChange={this.handleChecked("disable_password_change")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Don't Allow User to Change Password"
																	/>
																</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
												<GridContainer style={{ marginTop: "20px" }}>
													<GridItem xs={12} sm={12} md={12}>
														<h5><strong>Role</strong></h5>
														<GridContainer>
															<GridItem xs={6}>
																<InputLabel className={classes.label}>Access Level</InputLabel>
																<br />
																<FormControl fullWidth className={classes.selectFormControl}>
																	<Select
																		MenuProps={{
																			className: classes.selectMenu
																		}}
																		classes={{
																			select: classes.select + " " + classes.requiredSelect
																		}}
																		defaultValue={this.state.level}
																		value={this.state.level}
																		inputProps={{
																			id: "level",
																			name: "level"
																		}}
																		required
																		onChange={this.handleChange}
																	>
																		{!this.isCompanyUser() ? (
																			[
																				<MenuItem
																					classes={{
																						root: classes.selectMenuItem,
																						selected: classes.selectMenuItemSelected
																					}}
																					value="admin"
																				>
																					Admin
																				</MenuItem>,
																				<MenuItem
																					classes={{
																						root: classes.selectMenuItem,
																						selected: classes.selectMenuItemSelected
																					}}
																					value="carrier"
																				>
																					Carrier
																				</MenuItem>
																			]
																		) : (
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="user"
																			>
																				User
																			</MenuItem>
																		)}
																	</Select>
																</FormControl>
															</GridItem>
															{this.state.level !== "admin" && (
																<GridItem xs={6}>
																	<InputLabel className={classes.label}>Permission Group</InputLabel>
																	<br />
																	<FormControl fullWidth className={classes.selectFormControl}>
																		<Select
																			MenuProps={{
																				className: classes.selectMenu
																			}}
																			classes={{
																				select: classes.select + " " + classes.requiredSelect
																			}}
																			value={this.state.role || ""}
																			inputProps={{
																				id: "role",
																				name: "role"
																			}}
																			required
																			onChange={this.handleChange}
																		>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value=""
																			><em>Select a Permission Group</em></MenuItem>
																			{this.getRoles(this.state.roles)}
																		</Select>
																	</FormControl>
																</GridItem>
															)}
														</GridContainer>
													</GridItem>
												</GridContainer>
												<GridContainer style={{ marginTop: "20px" }}>
													<GridItem xs={12} sm={12} md={12}>
														<h5><strong>Address</strong></h5>
														<GridContainer>
															<GridItem xs>
																<InputLabel className={classes.label}>First Name</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "fName",
																		value: this.state.fName || "",
																		onChange: this.handleInput("fName")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>Last Name</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "lName",
																		value: this.state.lName || "",
																		onChange: this.handleInput("lName")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>Phone #</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "phone",
																		value: this.state.phone || "",
																		onChange: this.handleInput("phone")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>Email</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "email",
																		value: this.state.email || "",
																		onChange: this.handleInput("email")
																	}}
																	white
																/>
															</GridItem>
														</GridContainer>
														<GridContainer style={{ marginTop: "10px" }}>
															<GridItem xs>
																<InputLabel className={classes.label}>Address 1</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "address1",
																		value: this.state.address1 || "",
																		onChange: this.handleInput("address1")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>Address 2</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "address2",
																		value: this.state.address2 || "",
																		onChange: this.handleInput("address2")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>Zip</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "zip",
																		value: this.state.zip || "",
																		onChange: this.handleInput("zip")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>City</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "city",
																		value: this.state.city || "",
																		onChange: this.handleInput("city")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs>
																<InputLabel className={classes.label}>State</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "state",
																		value: this.state.state || "",
																		onChange: this.handleInput("state")
																	}}
																	white
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</TabPanel>
											<TabPanel value="general">
												<GridContainer>
													<GridItem xs={12} sm={12} md={12}>
														<h5><strong>User Interface</strong></h5>
														<GridContainer>
															<GridItem xs={3}>
																<InputLabel className={classes.label}>Default Landing Page</InputLabel>
																<br />
																<FormControl fullWidth className={classes.selectFormControl}>
																	<Select
																		MenuProps={{
																			className: classes.selectMenu
																		}}
																		classes={{
																			select: classes.select
																		}}
																		value={this.state.default_landing_page || ""}
																		inputProps={{
																			id: "default_landing_page",
																			name: "default_landing_page"
																		}}
																		onChange={this.handleChange}
																	>
																		<MenuItem
																			classes={{
																				root: classes.selectMenuItem,
																				selected: classes.selectMenuItemSelected
																			}}
																			value="dashboard"
																		>
																			Dashboard
																		</MenuItem>
																		<MenuItem
																			classes={{
																				root: classes.selectMenuItem,
																				selected: classes.selectMenuItemSelected
																			}}
																			value="customer-portal"
																		>
																			Customer Portal
																		</MenuItem>
																		<MenuItem
																			classes={{
																				root: classes.selectMenuItem,
																				selected: classes.selectMenuItemSelected
																			}}
																			value="carrier-invoice"
																		>
																			Carrier Portal
																		</MenuItem>
																	</Select>
																</FormControl>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
												{this.state.level !== "carrier" && (
													<GridContainer style={{ marginTop: "20px" }}>
														<GridItem xs={12} sm={12} md={12}>
															<h5><strong>BOL</strong></h5>
															<GridContainer>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.disable_maps_on_bol}
																				tabIndex={-1}
																				onChange={this.handleChecked("disable_maps_on_bol")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Disable Google Maps Integration On BoL"
																	/>
																</GridItem>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.disable_auto_address_bol}
																				tabIndex={-1}
																				onChange={this.handleChecked("disable_auto_address_bol")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Disable Address Auto-Complete On BoL"
																	/>
																</GridItem>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.display_custom_logo}
																				tabIndex={-1}
																				onChange={this.handleChecked("display_custom_logo")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Display Custom Logo"
																	/>
																</GridItem>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.display_custom_logo_on_bol}
																				tabIndex={-1}
																				onChange={this.handleChecked("display_custom_logo_on_bol")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Display Custom Logo on BOL"
																	/>
																</GridItem>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.hide_from_manifest}
																				tabIndex={-1}
																				onChange={this.handleChecked("hide_from_manifest")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Hide User Data from Manifest"
																	/>
																</GridItem>
															</GridContainer>
															<GridContainer>
																<GridItem xs>
																	<Tooltip
																		title={
																			<Typography color="inherit">
																				If enabled, when entering a new BOL or parcel shipment, address book defaults will not be applied to the shipment automatically. Address information will have to be selected from the address book or entered manually.
																			</Typography>
																		}
																		placement="bottom"
																	>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={!!this.state.dont_apply_addr_book_defaults}
																					tabIndex={-1}
																					onChange={this.handleChecked("dont_apply_addr_book_defaults")}
																					checkedIcon={<Check className={classes.checkedIcon} />}
																					icon={<Check className={classes.uncheckedIcon} />}
																					classes={{
																						checked: classes.checked,
																						root: classes.checkRoot
																					}}
																				/>
																			}
																			classes={{
																				label: classes.label
																			}}
																			label="Do Not Apply Address Book Defaults"
																		/>
																	</Tooltip>
																</GridItem>
															</GridContainer>
														</GridItem>
													</GridContainer>
												)}
												<GridContainer style={{ marginTop: "20px" }}>
													<GridItem xs={12} sm={12} md={12}>
														<h5><strong>Logo Image</strong></h5>
														<GridContainer>
															<GridItem xs={12} sm={12} md={12}>
																<GridContainer>
																	<GridItem xs={12} sm={12} md={6}>
																		<Dropzone handleUpload={this.handleUpload} />
																	</GridItem>
																	{!_.isEmpty(this.state.custom_logo_file) && (
																		<GridItem xs={12} sm={12} md={6}>
																			{this.getImage(this.state.custom_logo_file)}
																		</GridItem>
																	)}
																</GridContainer>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
												<GridContainer style={{ marginTop: "20px" }}>
													<GridItem xs={12} sm={12} md={12}>
														<h5><strong>TFM Support Contacts</strong></h5>
														<GridContainer>
															<GridItem xs={12} sm={6} md={3}>
																<InputLabel className={classes.label}>Routings</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "routings_contact",
																		value: this.state.routings_contact || "",
																		onChange: this.handleInput("routings_contact")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={3}>
																<InputLabel className={classes.label}>Customer Service</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "customer_service_contact",
																		value: this.state.customer_service_contact || "",
																		onChange: this.handleInput("customer_service_contact")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={3}>
																<InputLabel className={classes.label}>Claims</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "claims_contact",
																		value: this.state.claims_contact || "",
																		onChange: this.handleInput("claims_contact")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={3}>
																<InputLabel className={classes.label}>Sales Rep</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "sales_rep_contact",
																		value: this.state.sales_rep_contact || "",
																		onChange: this.handleInput("sales_rep_contact")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={3}>
																<InputLabel className={classes.label}>Target Truckload</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "tl_contact",
																		value: this.state.tl_contact || "",
																		onChange: this.handleInput("tl_contact")
																	}}
																	white
																/>
															</GridItem>
															<GridItem xs={12} sm={6} md={3}>
																<InputLabel className={classes.label}>TFM Auditing</InputLabel>
																<br />
																<CustomInput
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		type: "text",
																		name: "auditing_contact",
																		value: this.state.auditing_contact || "",
																		onChange: this.handleInput("auditing_contact")
																	}}
																	white
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</TabPanel>
											{this.state.level !== "carrier" && (
												<TabPanel value="rating">
													<GridContainer>
														<GridItem xs={12} sm={12} md={12}>
															<h5><strong>Rating</strong></h5>
															<GridContainer>
																{!_.isEmpty(this.state.rates) && (
																	<GridItem xs={4}>
																		<InputLabel className={classes.label}>Rate Display</InputLabel>
																		<br />
																		<FormControl fullWidth className={classes.selectFormControl}>
																			<Select
																				MenuProps={{
																					className: classes.selectMenu
																				}}
																				classes={{
																					select: classes.select + " " + classes.requiredSelect
																				}}
																				value={this.state.rate_display || ""}
																				inputProps={{
																					id: "rate_display",
																					name: "rate_display"
																				}}
																				required
																				onChange={this.handleChange}
																			>
																				<MenuItem
																					classes={{
																						root: classes.selectMenuItem,
																						selected: classes.selectMenuItemSelected
																					}}
																					value=""
																				><em>Select a Rate Display</em></MenuItem>
																				{this.getRates(this.state.rates)}
																			</Select>
																		</FormControl>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={!!this.state.show_tc_in_posting_panel}
																					tabIndex={-1}
																					onChange={this.handleChecked("show_tc_in_posting_panel")}
																					checkedIcon={<Check className={classes.checkedIcon} />}
																					icon={<Check className={classes.uncheckedIcon} />}
																					classes={{
																						checked: classes.checked,
																						root: classes.checkRoot
																					}}
																				/>
																			}
																			classes={{
																				label: classes.label
																			}}
																			label={
																				<Tooltip
																					title={
																						<React.Fragment>
																							<Typography color="inherit">Important Notice!</Typography>
																							This will display the true cost WITH NO USER OVERRIDE MARKUP APPLIED for all shipments that this user sees in the Archived Bills of Lading page! The Rate Display assigned to this user will have no effect on this setting. Also, the true cost shown will be the true cost that was calculated at the exact moment in time that the associated rate was generated.
																						</React.Fragment>
																					}
																					placement="bottom"
																				>
																					<span>
																						Show True Cost in Posting Panel
																						<Warning style={{ height: "16px" }} />
																					</span>
																				</Tooltip>
																			}
																		/>
																	</GridItem>
																)}
																<GridItem xs={4}>
																	<InputLabel className={classes.label}>Override Calculation Type</InputLabel>
																	<br />
																	<FormControl fullWidth className={classes.selectFormControl}>
																		<Select
																			MenuProps={{
																				className: classes.selectMenu
																			}}
																			classes={{
																				select: classes.select + " " + classes.requiredSelect
																			}}
																			value={this.state.u_calc_type || ""}
																			inputProps={{
																				id: "u_calc_type",
																				name: "u_calc_type"
																			}}
																			required
																			onChange={this.handleChange}
																		>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="% of Savings"
																			>
																				{"% of Savings"}
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="% Mark Up"
																			>
																				{"% Mark Up"}
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="$ Mark Up"
																			>
																				{"$ Mark Up"}
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="% Flat Charge"
																			>
																				{"% Flat Charge"}
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="$ Flat Charge"
																			>
																				{"$ Flat Charge"}
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="% of Gross Savings Markup"
																			>
																				{"% of Gross Savings Markup"}
																			</MenuItem>
																		</Select>
																	</FormControl>
																	<FormControlLabel
																			control={
																				<Checkbox
																					checked={!!this.state.apply_oc_to_np_rates}
																					tabIndex={-1}
																					onChange={this.handleChecked("apply_oc_to_np_rates")}
																					checkedIcon={<Check className={classes.checkedIcon} />}
																					icon={<Check className={classes.uncheckedIcon} />}
																					classes={{
																						checked: classes.checked,
																						root: classes.checkRoot
																					}}
																				/>
																			}
																			classes={{
																				label: classes.label
																			}}
																			label={
																				<span>
																					Apply Override Calculations to Non-Program Rates
																				</span>
																			}
																		/>
																</GridItem>
																<GridItem xs={4}>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={!!this.state.hide_parcel_rate_details}
																					tabIndex={-1}
																					onChange={this.handleChecked("hide_parcel_rate_details")}
																					checkedIcon={<Check className={classes.checkedIcon} />}
																					icon={<Check className={classes.uncheckedIcon} />}
																					classes={{
																						checked: classes.checked,
																						root: classes.checkRoot
																					}}
																				/>
																			}
																			classes={{
																				label: classes.label
																			}}
																			label={
																				<Tooltip
																					title={
																						<>
																							When enabled the base charge, total surcharges, discount, and details columns will be hidden when viewing parcel rates in the Parcel Rate page.
																						</>
																					}
																					placement="bottom"
																				>
																					<span>
																						Hide Parcel Rate Details
																					</span>
																				</Tooltip>
																			}
																		/>
																</GridItem>
															</GridContainer>
															<GridContainer>
																<GridItem xs>
																	<InputLabel className={classes.label}>Override Calculation Value</InputLabel>
																	<br />
																	<CustomInput
																		formControlProps={{
																			fullWidth: true
																		}}
																		inputProps={{
																			type: "text",
																			name: "u_calc_value",
																			value: this.state.u_calc_value || "",
																			onChange: this.handleInput("u_calc_value")
																		}}
																		helpText="This is the value added to the true cost in addition to the company's value."
																		white
																	/>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Calculate By</InputLabel>
																	<br />
																	<FormControl fullWidth className={classes.selectFormControl}>
																		<Select
																			MenuProps={{
																				className: classes.selectMenu
																			}}
																			classes={{
																				select: classes.select + " " + classes.requiredSelect
																			}}
																			value={this.state.u_calc_by || ""}
																			inputProps={{
																				id: "u_calc_by",
																				name: "u_calc_by"
																			}}
																			onChange={this.handleChange}
																		>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="truecost"
																			>
																				True Cost
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="benchmark"
																			>
																				Benchmark
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="rated"
																			>
																				Rated Amt.
																			</MenuItem>
																		</Select>
																	</FormControl>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Override Min Charge</InputLabel>
																	<br />
																	<CustomInput
																		formControlProps={{
																			fullWidth: true
																		}}
																		inputProps={{
																			type: "text",
																			name: "u_min_charge",
																			value: this.state.u_min_charge || "",
																			onChange: this.handleInput("u_min_charge")
																		}}
																		helpText="Minimum mark up charge allowed (Only applies to '% Mark Up')."
																		white
																	/>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Override Max Charge</InputLabel>
																	<br />
																	<CustomInput
																		formControlProps={{
																			fullWidth: true
																		}}
																		inputProps={{
																			type: "text",
																			name: "u_max_charge",
																			value: this.state.u_max_charge || "",
																			onChange: this.handleInput("u_max_charge")
																		}}
																		helpText="Maximum allowable mark up charge to be applied (Only applies to '% Mark Up')."
																		white
																	/>
																</GridItem>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={!!this.state.u_enable_load_api}
																				tabIndex={-1}
																				onChange={this.handleChecked("u_enable_load_api")}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Enable TTL Load Detail API"
																	/>
																</GridItem>
															</GridContainer>
															<GridContainer>
																<GridItem xs>
																	<InputLabel className={classes.label}>Order Rates By</InputLabel>
																	<br />
																	<FormControl fullWidth className={classes.selectFormControl}>
																		<Select
																			MenuProps={{ className: classes.selectMenu }}
																			classes={{ select: classes.select }}
																			value={this.state.order_rates_by || "none"}
																			inputProps={{
																				id: "order_rates_by",
																				name: "order_rates_by"
																			}}
																			onChange={this.handleChange}
																		>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="none"
																			>
																				Default
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="true_cost"
																			>
																				True Cost
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="benchmark"
																			>
																				Benchmark
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="rated"
																			>
																				Rated Amt.
																			</MenuItem>
																		</Select>
																	</FormControl>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Order Rates From</InputLabel>
																	<br />
																	<FormControl fullWidth className={classes.selectFormControl}>
																		<Select
																			MenuProps={{ className: classes.selectMenu }}
																			classes={{ select: classes.select }}
																			value={this.state.order_rates_from || "lowest to highest"}
																			inputProps={{
																				id: "order_rates_from",
																				name: "order_rates_from"
																			}}
																			onChange={this.handleChange}
																		>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="lowest to highest"
																			>
																				Lowest to Highest
																			</MenuItem>
																			<MenuItem
																				classes={{
																					root: classes.selectMenuItem,
																					selected: classes.selectMenuItemSelected
																				}}
																				value="highest to lowest"
																			>
																				Highest to Lowest
																			</MenuItem>
																		</Select>
																	</FormControl>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Display Top N Carriers</InputLabel>
																	<br />
																	<CustomInput
																		formControlProps={{ fullWidth: true }}
																		inputProps={{
																			type: "number",
																			name: "display_n_carriers",
																			value: this.state.display_n_carriers || "",
																			onChange: this.handleInput("display_n_carriers")
																		}}
																		white
																	/>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Default LCC Calculator Class</InputLabel>
																	<br />
																	<CustomInput
																		formControlProps={{
																			fullWidth: true
																		}}
																		inputProps={{
																			type: "text",
																			name: "default_lcc_class",
																			value: this.state.default_lcc_class || "",
																			onChange: this.handleInput("default_lcc_class")
																		}}
																		white
																	/>
																</GridItem>
																<GridItem xs>
																	<InputLabel className={classes.label}>Project44 Account Group Code</InputLabel>
																	<br />
																	<CustomInput
																		formControlProps={{
																			fullWidth: true
																		}}
																		inputProps={{
																			type: "text",
																			name: "p44_account_group_code",
																			value: this.state.p44_account_group_code || "",
																			onChange: this.handleInput("p44_account_group_code")
																		}}
																		white
																	/>
																</GridItem>
															</GridContainer>
														</GridItem>
													</GridContainer>
												</TabPanel>
											)}
											{this.state.level !== "admin" && (
												<TabPanel value="mappings">
													{this.state.level !== "carrier" && (
														<GridContainer>
															<GridItem xs={12} sm={12} md={12}>
																<h5><strong>Company</strong></h5>
																<GridContainer>
																	<GridItem xs>
																		<InputTag
																			inputLabel={'Assign TFMCentral Companies to User'}
																			classes={classes}
																			value={this.state.user_tfmc_brokers}
																			options={this.state.tfmc_brokers}
																			onChange={this.handleBrokerAssignment}
																			getOptionSelected={(option, value) => option.id == value.id}
																			getOptionLabel={(option) => `${option.name} - ${option.id}`}
																			renderOption={(option, { selected }) => (
																				<>
																					<Checkbox
																						checkedIcon={<Check className={this.props.classes.checkedIcon} />}
																						icon={<Check className={this.props.classes.uncheckedIcon} />}
																						style={{ marginRight: 8 }}
																						checked={selected}
																					/>
																					{option.name} - {option.id}
																				</>
																			)}
																		/>
																	</GridItem>
																</GridContainer>
															</GridItem>
															<GridItem xs={12} sm={12} md={12}>
																<InputTag
																	inputLabel={
																		<>
																			Assign TFMCentral Company Locations to User
																			<WithTooltip
																				title="Note:"
																				content="Locations selected here will restrict what data the user has access to when working in the Customer Portal. For each assigned Broker ID, if no related locations are selected, then the user has access to all of the locations listed for that Broker ID."
																			>
																				<Info style={{ height: 16, marginLeft: 4 }} />
																			</WithTooltip>
																		</>
																	}
																	loading={this.state.loading_broker_locations}
																	classes={classes}
																	value={this.state.user_tfmc_broker_locations}
																	options={this.state.tfmc_broker_locations}
																	onChange={this.handleBrokerLocationAssignment}
																	groupBy={(option) => `${option.group}`}
																	getOptionLabel={(option) => `${option.description}`}
																	getOptionSelected={(option, value) => option.id == value.id}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				checkedIcon={<Check className={this.props.classes.checkedIcon} />}
																				icon={<Check className={this.props.classes.uncheckedIcon} />}
																				style={{ marginRight: 8 }}
																				checked={selected}
																			/>
																			{option.description}
																		</>
																	)}
																/>
															</GridItem>
														</GridContainer>
													)}
													{this.state.level === "carrier" && (
														<GridContainer>
															<GridItem xs={12} sm={12} md={12}>
																<h5><strong>Carrier</strong></h5>
																<GridContainer>
																	<GridItem xs>
																		<InputTag
																			inputLabel={'Assign TFMCentral Carriers to User'}
																			classes={classes}
																			value={this.state.user_tfmc_carriers}
																			options={this.state.tfmc_carriers}
																			onChange={this.handleCarrierAssignment}
																			getOptionSelected={(option, value) => option.id == value.id}
																			getOptionLabel={(option) => `${option.name} - ${option.scac} - ${option.id}`}
																			renderOption={(option, { selected }) => (
																				<>
																					<Checkbox
																						checkedIcon={<Check className={this.props.classes.checkedIcon} />}
																						icon={<Check className={this.props.classes.uncheckedIcon} />}
																						style={{ marginRight: 8 }}
																						checked={selected}
																					/>
																					{option.name} - {option.scac} - {option.id}
																				</>
																			)}
																		/>
																	</GridItem>
																</GridContainer>
															</GridItem>
														</GridContainer>
													)}
													{this.state.company != null && (
														<GridContainer style={{ marginTop: "10px" }}>
															<GridItem xs={12} sm={12} md={12}>
																<h5><strong>Warehouse</strong></h5>
																<GridContainer>
																	<GridItem xs={3}>
																		<InputLabel className={classes.label}>Default Warehouse</InputLabel>
																		<br />
																		<FormControl fullWidth className={classes.selectFormControl}>
																			<Select
																				MenuProps={{
																					className: classes.selectMenu
																				}}
																				classes={{
																					select: classes.select + " " + classes.requiredSelect
																				}}
																				value={this.state.default_warehouse || ""}
																				inputProps={{
																					id: "default_warehouse",
																					name: "default_warehouse"
																				}}
																				required
																				onChange={this.handleChange}
																			>
																				{this.getWarehouses(this.state.page_warehouses)}
																			</Select>
																		</FormControl>
																	</GridItem>
																	<GridItem xs={12}>
																		<WarehouseSelector
																			mode={'list'}
																			company={this.state.company}
																			selectedWarehouses={this.state.warehouses}
																			required
																			onChange={(warehouses) => { this.handleWarehouse(warehouses) }}
																		/>
																	</GridItem>
																</GridContainer>
															</GridItem>
														</GridContainer>
													)}
												</TabPanel>
											)}
											{this.state.level !== "admin" && (
												<TabPanel value="nonLCC">
													<GridContainer>
														{this.state.require_non_lcc_selection_explanation == 1 && !_.isEmpty(this.state.non_lcc_required) && (
															<GridItem xs={12} sm={12} md={12}>
																<h5><strong>Require Description For Non LCC Selection Options</strong></h5>
																<GridContainer>
																	<GridItem xs={12} sm={12} md={12}>
																		<Table tableHead={["Require User Description", "Code", "Description"]} tableData={this.getNonLccRequired(this.state.non_lcc_required)} />
																	</GridItem>
																</GridContainer>
															</GridItem>
														)}
													</GridContainer>
												</TabPanel>
											)}
											{this.state.level !== "admin" && (
												<TabPanel value="customerPortal">
													<GridContainer>
														{this.state.company != null && (
															<GridItem xs={12} sm={12} md={12}>
																<UserPortalSettingsContainer
																	userHoldSettings={this.state.userHoldSettings}
																	notificationActive={this.state.notificationActive}
																	notificationFrequency={this.state.notificationFrequency}
																	notificationLastRun={this.state.notificationLastRun}
																	notificationNextRun={this.state.notificationNextRun}
																	handleEmailToggle={this.handleEmailToggle}
																	handleGeneralPortalSettings={(setting) => this.handleGeneralPortalSettings(setting)}
																	handleSpecificHoldCodeSettings={(setting) => this.handleSpecificHoldCodeSettings(setting)}
																	handleDatetime={(name, mom) => { this.handleDatetime(name, mom) }}
																	handleChange={this.handleChange}
																/>
															</GridItem>
														)}
													</GridContainer>
												</TabPanel>
											)}
										</GridItem>
									)}
								</GridContainer>
								<GridContainer justifyContent="flex-end">
									<Button
										color="info"
										style={{
											margin: "20px 40px"
										}}
										onClick={this.saveUser}
									>
										Save User
									</Button>
								</GridContainer>
							</CardBody>
						</TabContext>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(withSnackbar(ManageUser))
