/*eslint-disable*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

import styles from "~/assets/jss/empire-tms/customCheckboxRadioSwitch.jsx";

import { baseUrl, assetPath } from "~/variables/server.jsx";

const image0 = baseUrl + assetPath + "/img/sidebar-0.png";
const image1 = baseUrl + assetPath + "/img/sidebar-1.jpg";
const image2 = baseUrl + assetPath + "/img/sidebar-2.jpg";
const image3 = baseUrl + assetPath + "/img/sidebar-3.jpg";
const image4 = baseUrl + assetPath + "/img/sidebar-4.jpg";

import Button from "~/components/CustomButtons/Button.jsx";

class FixedPlugin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classes: "dropdown show",
			bg_checked: true,
			bgImage: this.props.bgImage,
			showImage: true
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		this.props.handleFixedClick();
	}
	handleChange = name => event => {
		switch (name) {
			case "miniActive":
				this.props.sidebarMinimize();
				break;
			case "image":
				if (event.target.checked) {
					this.props.handleImageClick(this.state.bgImage);
				} else {
					this.props.handleImageClick();
				}
				this.setState({ showImage: event.target.checked });
				break;
			default:
				break;
		}
	};
	render() {
		const { classes } = this.props;
		return (
			<div
				className={
					"fixed-plugin" +
					(this.props.rtlActive ? " fixed-plugin-rtl" : "")
				}
			>
				<div
					id="fixedPluginClasses"
					className={this.props.fixedClasses}
				>
					<div onClick={this.handleClick}>
						<i className="fa fa-cog fa-2x" />
					</div>
					<ul className="dropdown-menu">
						<li className="header-title">SIDEBAR FILTERS</li>
						<li className="adjustments-line">
							<a className="switch-trigger active-color">
								<div className="badge-colors text-center">
									<span
										className={
											this.props.color === "purple"
												? "badge filter badge-purple active"
												: "badge filter badge-purple"
										}
										data-color="purple"
										onClick={() => {
											this.props.handleColorClick(
												"purple"
											);
										}}
									/>
									<span
										className={
											this.props.color === "blue"
												? "badge filter badge-blue active"
												: "badge filter badge-blue"
										}
										data-color="blue"
										onClick={() => {
											this.props.handleColorClick("blue");
										}}
									/>
									<span
										className={
											this.props.color === "green"
												? "badge filter badge-green active"
												: "badge filter badge-green"
										}
										data-color="green"
										onClick={() => {
											this.props.handleColorClick(
												"green"
											);
										}}
									/>
									<span
										className={
											this.props.color === "red"
												? "badge filter badge-red active"
												: "badge filter badge-red"
										}
										data-color="red"
										onClick={() => {
											this.props.handleColorClick("red");
										}}
									/>
									<span
										className={
											this.props.color === "orange"
												? "badge filter badge-orange active"
												: "badge filter badge-orange"
										}
										data-color="orange"
										onClick={() => {
											this.props.handleColorClick(
												"orange"
											);
										}}
									/>
									<span
										className={
											this.props.color === "white"
												? "badge filter badge-white active"
												: "badge filter badge-white"
										}
										data-color="orange"
										onClick={() => {
											this.props.handleColorClick(
												"white"
											);
										}}
									/>
									<span
										className={
											this.props.color === "rose"
												? "badge filter badge-rose active"
												: "badge filter badge-rose"
										}
										data-color="orange"
										onClick={() => {
											this.props.handleColorClick("rose");
										}}
									/>
								</div>
								<div className="clearfix" />
							</a>
						</li>
						<li className="header-title">SIDEBAR BACKGROUND</li>
						<li className="adjustments-line">
							<a className="switch-trigger active-color">
								<div className="badge-colors text-center">
									<span
										className={
											this.props.bgColor === "blue"
												? "badge filter badge-blue active"
												: "badge filter badge-blue"
										}
										data-color="orange"
										onClick={() => {
											this.props.handleBgColorClick(
												"blue"
											);
										}}
									/>
									<span
										className={
											this.props.bgColor === "white"
												? "badge filter badge-white active"
												: "badge filter badge-white"
										}
										data-color="orange"
										onClick={() => {
											this.props.handleBgColorClick(
												"white"
											);
										}}
									/>
									<span
										className={
											this.props.bgColor === "black"
												? "badge filter badge-black active"
												: "badge filter badge-black"
										}
										data-color="orange"
										onClick={() => {
											this.props.handleBgColorClick(
												"black"
											);
										}}
									/>
								</div>
								<div className="clearfix" />
							</a>
						</li>
						<li className="adjustments-line">
							<a className="switch-trigger">
								<p className="switch-label">Sidebar Mini</p>
								<Switch
									checked={this.props.miniActive}
									onChange={this.handleChange("miniActive")}
									value="sidebarMini"
									classes={{
										switchBase: classes.switchBase,
										checked: classes.switchChecked
									}}
								/>
								<div className="clearfix" />
							</a>
						</li>
						<li className="adjustments-line">
							<a className="switch-trigger">
								<p className="switch-label">Sidebar Image</p>
								<Switch
									checked={this.state.showImage}
									onChange={this.handleChange("image")}
									value="sidebarMini"
									classes={{
										switchBase: classes.switchBase,
										checked: classes.switchChecked
									}}
								/>
								<div className="clearfix" />
							</a>
						</li>
						<li className="header-title">Images</li>
						<li
							className={
								this.state["bgImage"] === image0 ? "active" : ""
							}
						>
							<a
								className="img-holder switch-trigger"
								onClick={() => {
									this.setState({
										showImage: true,
										bgImage: image0
									});
									this.props.handleImageClick(image0);
								}}
							>
								<img src={image0} alt="..." />
							</a>
						</li>
						<li
							className={
								this.state["bgImage"] === image1 ? "active" : ""
							}
						>
							<a
								className="img-holder switch-trigger"
								onClick={() => {
									this.setState({
										showImage: true,
										bgImage: image1
									});
									this.props.handleImageClick(image1);
								}}
							>
								<img src={image1} alt="..." />
							</a>
						</li>
						<li
							className={
								this.state["bgImage"] === image2 ? "active" : ""
							}
						>
							<a
								className="img-holder switch-trigger"
								onClick={() => {
									this.setState({
										showImage: true,
										bgImage: image2
									});
									this.props.handleImageClick(image2);
								}}
							>
								<img src={image2} alt="..." />
							</a>
						</li>
						<li
							className={
								this.state["bgImage"] === image3 ? "active" : ""
							}
						>
							<a
								className="img-holder switch-trigger"
								onClick={() => {
									this.setState({
										showImage: true,
										bgImage: image3
									});
									this.props.handleImageClick(image3);
								}}
							>
								<img src={image3} alt="..." />
							</a>
						</li>
						<li
							className={
								this.state["bgImage"] === image4 ? "active" : ""
							}
						>
							<a
								className="img-holder switch-trigger"
								onClick={() => {
									this.setState({
										showImage: true,
										bgImage: image4
									});
									this.props.handleImageClick(image4);
								}}
							>
								<img src={image4} alt="..." />
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(FixedPlugin);