import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import NotesModal from '../../../components/NotesModal/containers/NotesModal.jsx';

import { closeModal, setNewNoteMessage, saveIntlNote, deleteIntlNote, editIntlNote, setEditNoteValue, stopEditingNote } from "../actions/InternationalNotes.jsx";
import {getShipmentNoteCount} from "../../InternationalLoadBoard/actions/InternationalLoadBoard.jsx";
class InternationalNotesModal extends Component {

	onNoteSave = async (id, msg, internal) => {
		await this.props.saveIntlNote(id, msg, internal);
		if(id == null) {
			this.props.getShipmentNoteCount();
		}
	}

	onNoteDelete = async (id) => {
		await this.props.deleteIntlNote(id);
		this.props.getShipmentNoteCount();
	}

	render() {
		return (
		   <NotesModal
				open={this.props.open}
				title={`International Shipment: ${this.props.intlShipId}`}
				newNoteMessage={this.props.newNoteMessage}
				saveNoteLoading={this.props.saveNoteLoading}
				notesLoading={this.props.notesLoading}
				deleteNoteLoading={this.props.deleteNoteLoading}
				notes={this.props.intlShipNotes}
				editingNotes={this.props.editingNotes}
				closeModal={this.props.closeModal}
				setNewNoteMessage={(val) => this.props.setNewNoteMessage(val)}
				setEditNoteValue={(id, value) => this.props.setEditNoteValue(id, value)}
				saveNote={(id, msg, internal) => this.onNoteSave(id, msg, internal)}
				deleteNote={(id) => this.onNoteDelete(id)}
				editNote={(id) => this.props.editIntlNote(id)}
				cancelEdit={(id) => this.props.stopEditingNote(id)}
		   />
		);
	}
}

const mapStateToProps = state => {
	return {
		intlShipId: state.InternationalNotes.intlShipId,
		open: state.InternationalNotes.modalOpen,
		intlShipNotes: state.InternationalNotes.notes,
		newNoteMessage: state.InternationalNotes.newNoteMessage,
		editingNotes: state.InternationalNotes.editingNotes,
		notesLoading: state.InternationalNotes.notesLoading,
		saveNoteLoading: state.InternationalNotes.saveNoteLoading,
		deleteNoteLoading: state.InternationalNotes.deleteNoteLoading,
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		closeModal,
		saveIntlNote,
		deleteIntlNote,
		setNewNoteMessage,
		editIntlNote,
		stopEditingNote,
		setEditNoteValue,
		getShipmentNoteCount,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InternationalNotesModal);