import React, { Component, Fragment } from "react";
import Dialog from '../../../components/TMS/Dialog.jsx';
import GridItem from "~/components/Grid/GridItem.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "~/components/CustomButtons/Button.jsx";
import { exportCategoryCSV } from '../actions/CustomerPortal.jsx';

class ExportSummaryCSVModal extends Component {
    state = {
        selectedCategories: [],
    };
    render() {
        const images = Array.isArray(this.props.images) ? this.props.images : [];
        return (
            <Dialog 
                open={this.props.open} 
                onClose={this.onClose} 
                title="Export Held Bills By Hold Code"
                actions={[{
                    color: "success",
                    title: "Export",
                    onClick: this.handleExportClick,
                    disabled: !this.state.selectedCategories.length,
                }]}
            >
                {this.props.heldBillsByCode.map((item, index) => {
                    return (
                        <GridItem key={index} xs={12}>
                            <label>
                                <Checkbox
                                    value={item.code}
                                    size="small"
                                    checked={this.state.selectedCategories.indexOf(item.code) !== -1}
                                    onChange={this.handleCheckbox}
                                />
                                {`${item.code} - ${item.category}`}
                            </label>
                        </GridItem>
                    );
                })}
            </Dialog>
        );
    }

    handleExportClick = () => {
        exportCategoryCSV(this.state.selectedCategories.join(','));
    }

    handleCheckbox = e => {
        const code = e.currentTarget.value;
        let selectedCategories = this.state.selectedCategories;
        if (selectedCategories.indexOf(code) === -1) {
            selectedCategories.push(code);
        } else {
            selectedCategories.splice(selectedCategories.indexOf(code), 1);
        }
        this.setState({selectedCategories});
    }

    onClose = () => {
        this.setState({selectedCategories: []});
        this.props.onClose();
    }
}

export default ExportSummaryCSVModal;
