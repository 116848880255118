import React, { Component } from "react";
import Datetime from "react-datetime";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class DGDFields extends Component {

    handleChange = e => {
        this.props.handleChange(e);
    }

    handleInput = e => {
        this.props.handleInput(e);
    }

    handleDatetime = (name, m) => {
        this.props.handleDatetime(name, m);
    }

    handleChecked = e => {
        this.props.handleChecked(e);
    }

    inputContainer = props => {
        const classes = this.props.classes || {};
        return (
            <GridItem xs={props.xs || true}>
                <WithTooltip title={props.tooltipTitle} content={props.tooltipContent}>
                    <span>
                        <InputLabel className={classes.label}>{props.label}</InputLabel>
                    </span>
                </WithTooltip>
                <br />
                {props.input}
            </GridItem>
        );
    }

    TextInput = props => {
        return this.inputContainer({
            tooltipTitle: props.tooltipTitle,
            tooltipContent: props.tooltipContent,
            label: props.label,
            input: (
                <CustomInput
                    id={props.name}
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                        type: "text",
                        name: props.name,
                        value: props.value || "",
                        onChange: this.handleInput,
                        disabled: this.executed()
                    }}
                    white
                />
            )
        });
    }

    DateInput = props => {
        const classes = this.props.classes || {};
        return this.inputContainer({
            xs: 3,
            tooltipTitle: props.tooltipTitle,
            tooltipContent: props.tooltipContent,
            label: props.label,
            input: (
                <FormControl fullWidth disabled={this.executed()}>
                    <Datetime
                        value={props.value || ""}
                        timeFormat={false}
                        onChange={props.onChange}
                        className={classes.datetime}
                        inputProps={{
                            id: props.name,
                            name: props.name,
                            placeholder: props.placeholder || "",
                            disabled: this.executed()
                        }}
                    />
                </FormControl>
            )
        });
    }

    Checkbox = props => {
        const classes = this.props.classes || {};

        return (
            <WithTooltip title={props.tooltipTitle} content={props.tooltipContent}>
                <span>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                name={props.name}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                checked={!!props.checked}
                                onChange={this.handleChecked}
                            />
                        }
                        classes={{label: classes.label}}
                        label={props.label}
                        disabled={this.executed()}
                    />
                </span>
            </WithTooltip>
        );
    }

    executed = () => !!this.props.executed;

    render() {
        const classes = this.props.classes || {};
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={3}>
                            <WithTooltip
                                title="Shipping Type"
                                content="Enter the shipping type for the dangerous goods."
                            >
                                <span>
                                    <InputLabel htmlFor="dgd_type" className={classes.label}>
                                        Type
                                    </InputLabel>
                                </span>
                            </WithTooltip>
                            <FormControl fullWidth className={classes.selectFormControl} disabled={this.executed()}>
                                <Select
                                    MenuProps={{className: classes.selectMenu}}
                                    classes={{select: classes.select}}
                                    value={this.props.dgd_type || ""}
                                    inputProps={{name: "dgd_type",id: "dgd_type"}}
                                    name="dgd_type"
                                    onChange={this.handleChange}
                                >
                                    {[
                                        {val: "", display: "Select Type"},
                                        {val: "air", display: "Air"},
                                        {val: "ocean", display: "Ocean"},
                                    ].map((opt, key) => {
                                        return (
                                            <MenuItem
                                                key={key}
                                                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                                                value={opt.val}
                                            >
                                                {opt.display}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </GridItem>
                        {!_.isEmpty(this.props.dgd_type) && (
                            <GridItem xs={6} sm={3}>
                                {this.Checkbox({
                                    tooltipTitle: "Custom Shipper Address",
                                    tooltipContent: "Use a custom shipper address for dangerous goods.",
                                    checked: this.props.dgd_custom_shipper,
                                    name: "dgd_custom_shipper",
                                    label: "Use Custom Shipper Address",
                                })}
                            </GridItem>
                        )}
                        {!_.isEmpty(this.props.dgd_type) && (
                            <GridItem xs={6} sm={3}>
                                {this.Checkbox({
                                    tooltipTitle: "Custom Consignee Address",
                                    tooltipContent: "Use a custom consignee address for dangerous goods.",
                                    checked: this.props.dgd_custom_consignee,
                                    name: "dgd_custom_consignee",
                                    label: "Use Custom Consignee Address",
                                })}
                            </GridItem>
                        )}
                    </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                    {this.props.dgd_type === "air" && (
                        <GridContainer>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.DateInput({
                                        tooltipTitle: "Delivery Date",
                                        tooltipContent: "Select the delivery date for the air shipment.",
                                        label: "Date",
                                        value: this.props.dgd_air_date || "",
                                        onChange: m => this.handleDatetime("dgd_air_date", m),
                                        name: "dgd_air_date",
                                        placeholder: "Select Date",
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Name/Title of Signatory",
                                        tooltipContent: "Enter the name and title of the individual signing the shipper's declaration.",
                                        label: "Name/Title of Signatory",
                                        name: "dgd_air_declarant",
                                        value: this.props.dgd_air_declarant,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Place of Delivery",
                                        tooltipContent: "Enter the place of delivery for the air shipment",
                                        label: "Place",
                                        name: "dgd_air_place",
                                        value: this.props.dgd_air_place,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "Airport of Departure",
                                        tooltipContent: "Enter the starting point of the air shipment.",
                                        label: "Airport of Departure",
                                        name: "dgd_air_departure_airport",
                                        value: this.props.dgd_air_departure_airport,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Airport of Destination",
                                        tooltipContent: "Enter the final point of the air shipment.",
                                        label: "Airport of Destination",
                                        name: "dgd_air_destination_airport",
                                        value: this.props.dgd_air_destination_airport,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs>
                                        {this.Checkbox({
                                            tooltipTitle: "Cargo Only Aircraft (No Passengers)",
                                            tooltipContent: "Packages contains dangerous goods that are permitted only on cargo aircraft.",
                                            checked: this.props.dgd_air_cargo_only,
                                            name: "dgd_air_cargo_only",
                                            label: "Cargo Only (No Passengers)",
                                        })}
                                    </GridItem>
                                    <GridItem xs>
                                        {this.Checkbox({
                                            tooltipTitle: "Radioactive",
                                            tooltipContent: "Packages contains dangerous goods that are radioactive materials.",
                                            checked: this.props.dgd_air_radioactive,
                                            name: "dgd_air_radioactive",
                                            label: "Radioactive",
                                        })}
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    )}
                    {this.props.dgd_type === "ocean" && (
                        <GridContainer>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.DateInput({
                                        tooltipTitle: "Delivery Date",
                                        tooltipContent: "Select the delivery date for the ocean shipment.",
                                        label: "Date",
                                        value: this.props.dgd_ocean_date || "",
                                        onChange: m => this.handleDatetime("dgd_ocean_date", m),
                                        name: "dgd_ocean_date",
                                        placeholder: "Select Date",
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Name/Status of Declarant",
                                        tooltipContent: "Enter the name and status of the individual signing the shipper's declaration.",
                                        label: "Name/Status of Declarant",
                                        name: "dgd_ocean_declarant",
                                        value: this.props.dgd_ocean_declarant,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Place of Delivery",
                                        tooltipContent: "Enter the place of delivery for the ocean shipment.",
                                        label: "Place",
                                        name: "dgd_ocean_place",
                                        value: this.props.dgd_ocean_place,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "Transportation Document Number",
                                        tooltipContent: "Enter the transportation document number for the ocean shipment.",
                                        label: "Transportation Document Number",
                                        name: "dgd_ocean_trans_doc_number",
                                        value: this.props.dgd_ocean_trans_doc_number,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Forwarder's Cargo Receipt (FCR)",
                                        tooltipContent: "Enter the freight forwarder's certificate of the receipt of cargo.",
                                        label: "Freight Forwarder's Reference",
                                        name: "dgd_ocean_freight_forwarders_reference",
                                        value: this.props.dgd_ocean_freight_forwarders_reference,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Carrier (Declared by Carrier)",
                                        tooltipContent: "Enter the chosen carrier to be declared for the ocean shipment.",
                                        label: "Carrier (to be declared by Carrier)",
                                        name: "dgd_ocean_carrier",
                                        value: this.props.dgd_ocean_carrier,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "Vessel/Flight and Date",
                                        tooltipContent: "Enter the vessel name and/or flight number of aircraft, plus the date of departure.",
                                        label: "Vessel/Flight and Date",
                                        name: "dgd_ocean_vessel_flight_and_date",
                                        value: this.props.dgd_ocean_vessel_flight_and_date,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Port/Place Handling",
                                        tooltipContent: "Enter the port in which the shipment was loaded on the vessel.",
                                        label: "Port/Place Handling",
                                        name: "dgd_ocean_port_place_handling",
                                        value: this.props.dgd_ocean_port_place_handling,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "CTU Identification Number",
                                        tooltipContent: "Enter the identification number of the Code of Practice for Packing of Cargo Transport Units (CTU Code).",
                                        label: "CTU ID No.",
                                        name: "dgd_ocean_ctu_number",
                                        value: this.props.dgd_ocean_ctu_number,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Seal Number",
                                        tooltipContent: "Enter the identification number on the container seal.",
                                        label: "Seal No.",
                                        name: "dgd_ocean_seal_number",
                                        value: this.props.dgd_ocean_seal_number,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "CTU Size and Type",
                                        tooltipContent: "Enter the size and type of the Cargo Transport Unit.",
                                        label: "CTU Size and Type",
                                        name: "dgd_ocean_ctu_size_and_type",
                                        value: this.props.dgd_ocean_ctu_size_and_type,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Tare Mass (kg.)",
                                        tooltipContent: "Enter the mass or weight of the empty container in kilograms (kg.)",
                                        label: "Tare Mass",
                                        name: "dgd_ocean_tare_mass",
                                        value: this.props.dgd_ocean_tare_mass,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "Receiving Organization Receipt",
                                        tooltipContent: "Enter the receipt or remarks of the receiving organization.",
                                        label: "Receiving Organization Remarks",
                                        name: "dgd_ocean_receiving_remarks",
                                        value: this.props.dgd_ocean_receiving_remarks,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "Name of Company",
                                        tooltipContent: "Enter the name of the company.",
                                        label: "Name of Company",
                                        name: "dgd_ocean_company_name",
                                        value: this.props.dgd_ocean_company_name,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Hauler's Name",
                                        tooltipContent: "Enter the name of the hauler.",
                                        label: "Hauler's Name",
                                        name: "dgd_ocean_haulers_name",
                                        value: this.props.dgd_ocean_haulers_name,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Name of Company Preparing Note",
                                        tooltipContent: "Enter the name of the company preparing the note.",
                                        label: "Name of Company Preparing Note",
                                        name: "dgd_ocean_preparing_company_name",
                                        value: this.props.dgd_ocean_preparing_company_name,
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {this.TextInput({
                                        tooltipTitle: "Vehicle Registration Number",
                                        tooltipContent: "Enter the vehicle registration number.",
                                        label: "Vehicle Registration No.",
                                        name: "dgd_ocean_vehicle_registration_number",
                                        value: this.props.dgd_ocean_vehicle_registration_number,
                                    })}
                                    {this.TextInput({
                                        tooltipTitle: "Driver Name and Date",
                                        tooltipContent: "Enter the name of the driver and issue date.",
                                        label: "Driver Name and Date",
                                        name: "dgd_ocean_driver_name_date",
                                        value: this.props.dgd_ocean_driver_name_date,
                                    })}
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    )}
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(style)(DGDFields);