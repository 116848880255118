import React from "react";
import axios from "~/variables/axios.jsx";
import { NavLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import { basePath } from "~/variables/server.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

class UnitMaster extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			body: null,
			user: null
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleDatetime = this.handleDatetime.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.deleteUnit = this.deleteUnit.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true });
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=unitMaster"
			);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the data!",
				{
					variant: "error"
				}
			);
		}
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}
	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}
	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}
	getHeaders() {
		return [
			"ID",
			"Unit",
			"Plural",
			"Actions"
		];
	}
	getColumns(data) {
		const link = basePath + "/admin/unit/";
		return data.map((prop, key) => {
			return [
				prop.id,
				prop.name,
				prop.plural_name,
				<div className="actions-right">
					<NavLink to={link + prop.id.toString()}>
						<Button
							size="sm"
							color="info"
							style={{
								marginRight: "4px"
							}}
						>
							View/Edit
						</Button>
					</NavLink>
					<Button
						size="sm"
						color="danger"
						style={{
							marginRight: "4px"
						}}
						onClick={() => this.deleteUnit(prop.id)}
					>
						Delete
					</Button>
				</div>
			];
		});
	}
	async handlePageClick(page) {
		this.setState({
			loading: true
		});
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=unitMaster&d=" + page + "/20"
			);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.props.handleMessages(response);
				this.setState({
					show: true,
					loading: false,
					body: data.body,
					user: data.user
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error loading the data!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error loading the data!",
				{
					variant: "error"
				}
			);
		}
	}
	getPages() {
		let current = 1,
			last = 1;

		if(!_.isEmpty(this.state.body)) {
			current = this.state.body.current;
			last = this.state.body.last;
		}

		let pages = [{ active: true, text: "..." }],
			nextPage = { text: ">" },
			prevPage = { text: "<" };

		if (last > 1 && !_.isEmpty(this.state.body) && !_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.pages)) {
			pages = this.state.body.pages.map(index => {
				let page = { text: index };
				if (index == parseInt(current)) {
					page.active = true;
				} else {
					page.onClick = e => this.handlePageClick(index);
				}
				return page;
			});
		}

		if (current < last) {
			nextPage.onClick = e => this.handlePageClick(parseInt(current) + 1);
		} else {
			nextPage.disabled = true;
		}

		if (parseInt(current) - 1 !== 0) {
			prevPage.onClick = e => this.handlePageClick(parseInt(current) - 1);
		} else {
			prevPage.disabled = true;
		}

		pages.push(nextPage);
		pages.unshift(prevPage);

		return pages;
	}
	async deleteUnit(id) {
		try {
			const response = await axios.get(
				"/index.php?p=api&r=json&c=admin&m=deleteUnit&d=" + id,
			);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.props.handleMessages(response);
				this.setState({body: data.body, loading: false});
			} else {
				this.setState({ loading: false });
				this.props.setNotification(
					"There was an error deleting the unit!",
					{
						variant: "error"
					}
				);
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification(
				"There was an error deleting the unit!",
				{
					variant: "error"
				}
			);
		}
	}
	render() {
		const { classes } = this.props;
		const link = basePath + "/admin/unit/new";
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<NavLink to={link}>
										<Button
											color="linkedin"
											style={{
												marginRight: "4px"
											}}
										>
											New Unit
										</Button>
									</NavLink>
								</GridItem>
							</GridContainer>
							<GridContainer>
								{this.state.show ? (
									<GridItem xs={12} sm={12} md={12}>
										{!_.isEmpty(this.state.body) ? (
											<Table
												tableHead={this.getHeaders()}
												tableData={this.getColumns(
													this.state.body
												)}
												customCellClasses={[
													classes.right
												]}
												customClassesForCells={[3]}
												customHeadCellClasses={[
													classes.right
												]}
												customHeadClassesForCells={[3]}
											/>
										) : (
											<p className={classes.center}>
												<b>
													{"No units to display"}
												</b>
											</p>
										)}
									</GridItem>
								) : (
									<Spinner
										loading={this.state.loading}
										message="Failed to retrieve units from the server"
									/>
								)}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(archivedBillsOfLadingStyle)(withSnackbar(UnitMaster));
