import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import "chartist/dist/chartist.min.css";
// react plugin for creating vector maps

import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import {
	completedTasksChart
} from "~/variables/charts.jsx";
import dashboardStyle from "~/assets/jss/empire-tms/views/dashboardStyle.jsx";

import routes from "~/routes.js";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			mounted: false,
			show: false,
			loading: true,
			data: {
				user: {
					level: ""
				}
			},
			body: null,
			value: 0
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
	}
	async componentDidMount() {
		this.setState({ mounted: true }, async () => {
			try {
				const session = await routes.getSession();
				this.props.handleMessages(session);
				this.setState({
					show: true,
					loading: false,
					data: session,
					body: session.data.body || null
				});
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
			}
		});
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleChange(event, value) {
		this.setState({ value });
	}
	handleChangeIndex(index) {
		this.setState({ value: index });
	}
	getData(data) {
		let labels = [];
		let billed = [];
		let canceled = [];
		let executed = [];
		let hold = [];
		let received = [];
		let scheduled = [];
		for (let prop of data) {
			labels.push(prop.date);
			billed.push(prop.billed);
			canceled.push(prop.canceled);
			executed.push(prop.executed);
			hold.push(prop.hold);
			received.push(prop.received);
			scheduled.push(prop.scheduled);
		}
		return {
			labels,
			series: [billed, canceled, executed, hold, received, scheduled]
		};
	}
	render() {
		const { classes } = this.props;
		return (
			<div>
				{!_.isEmpty(this.state.body) && (
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Card chart className={classes.cardHover}>
								<CardBody>
									<h4 className={classes.cardTitle}>
										BoL Status Overview
									</h4>
									<Paper
										className={classes.paper}
										elevation={1}
									>
										<ChartistGraph
											data={this.getData(
												this.state.body.bol_compare
											)}
											type="Line"
											options={
												completedTasksChart.options
											}
											listener={
												completedTasksChart.animation
											}
										/>
									</Paper>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				)}
			</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
