import React from "react";
import "formdata-polyfill";
import _ from "lodash";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class ShipmentHistoryCard extends React.Component {

    render() {
        const classes = this.props.classes || {};
        return (
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6>History Information</h6>
                            <Grid container>
                                <Grid item xs={12} className={classes.left}>
                                    Created By: <strong>{this.props.username || "N/A"}</strong>
                                </Grid>
                                <Grid item xs={12} className={classes.left}>
                                    Date Created: {this.props.dateCreated || "N/A"}
                                </Grid>
                                <Grid item xs={12} className={classes.left}>
                                    Time Created: {this.props.timeCreated || "N/A"}
                                </Grid>
                            </Grid>
                            <br />
                        </GridItem>
                            {this.props.trackingSent && (
                                <GridItem xs={6} className={classes.left}>
                                    <strong>Tracking Link Sent:</strong><a href={(this.props.trackingLink)}>{(this.props.trackingLink)}</a>
                                </GridItem>
                            )}
                        {(!_.isEmpty(this.props.confirmationNumber) || !_.isEmpty(this.props.transactionId)) && (
                            <GridItem xs={12} sm={12} md={12}>
                                <Paper className={(this.props.p44DispatchCanceled == true) ? classes.dangerPaper : classes.successPaper} elevation={1}>
                                    {this.props.p44DispatchCanceled == true && (
                                        <GridItem xs={12} className={classes.center}>
                                            <strong>Dispatch Canceled</strong>
                                        </GridItem>
                                    )}
                                    <h6>Pickup Information</h6>
                                    <GridContainer>
                                        {!_.isEmpty(this.props.confirmationNumber) && (
                                            <GridItem xs={6} className={classes.left}>
                                                <strong>Confirmation #:</strong> {this.props.confirmationNumber}
                                            </GridItem>
                                        )}
                                        {!_.isEmpty(this.props.transactionId) && (
                                            <GridItem xs={6} className={classes.left}>
                                                <strong>Transaction #:</strong> {this.props.transactionId}
                                            </GridItem>
                                        )}
                                    </GridContainer>
                                    <GridContainer>
                                        {!_.isEmpty(this.props.pickupWindowStart) && (
                                            <GridItem xs={6} className={classes.left}>
                                                <strong>Pickup Ready Date and Time:</strong> {moment(this.props.pickupWindowStart).format('L')} {moment(this.props.pickupWindowStart).format('LT')}
                                            </GridItem>
                                        )}
                                        {!_.isEmpty(this.props.pickupWindowEnd) && (
                                            <GridItem xs={6} className={classes.left}>
                                                <strong>Dock Close Time:</strong> {moment(this.props.pickupWindowEnd).format('L')} {moment(this.props.pickupWindowEnd).format('LT')}
                                            </GridItem>
                                        )}
                                    </GridContainer>
                                </Paper>
                            </GridItem>
                        )}
                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(style)(ShipmentHistoryCard);