import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPenSquare } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        height: "30vh",
        width: "100%",
    },
    paper: {
        overflow: "auto",
        width: "80%",
        padding: "19px",
        backgroundColor: "#f5f5f5",
    }
}));

const FilterQueryButtonGroup = ({ 
    addOR, 
    openGroup, 
    clearFilters, 
    clearQueryText,
    filter, 
    closeGroup, 
    addRule, 
    removeRule, 
    updateRule, 
    setFilter, 
    applyFilters,
    setFilterOptions,
    fieldsWithDate,
    setIsUpdate,
    isUpdate }) => {
    const classes = useStyles();
    const [itemId, setItemId] = useState('');

    useEffect(() => {
        return () => {
            setIsUpdate(false);
            updateRule(itemId, true);
        }
    }, []);

    const handleOnDragEnd = (result) => {

        if (isUpdate) {
            return;
        }
        
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const list = reorder(
            filter,
            result.source.index,
            result.destination.index
        );
        setFilter(list);
    }

    const handleUpdate = (updating, item) => {
        if(item !== undefined) {
            setItemId(item.id);
            setFilterOptions(item.text)
        }
		if (updating) {
			updateRule(item.id, false, false);
		} else {
			updateRule(itemId, false, true);
		}
	};

    const handleCancel = () => {
        updateRule(itemId, true);
    }

    const handleDelete = (index, item) => {
        const isDate = fieldsWithDate.includes(item.text);
        removeRule(index);
        if(item.id === itemId) {
            setIsUpdate(false);
            if(!isDate) clearQueryText();
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);
        result.map((item, index) => item.id = `${index}`);

        return result;
    }

    const draggableContainerStyles = (item) => {
        return ({
            display: "flex",
            color: "white",
            fontWeight: "bold",
            margin: "2px",
            padding: "0 2px",
            borderRadius: "5px",
            backgroundColor: `${item.color}`,
            width: "max-content",
        });

    }

    return (

        <div className={classes.root}>
            <DragDropContext
                onDragEnd={handleOnDragEnd}>
                <Droppable
                    droppableId='rules'
                >
                    {(provided) => (
                        <Paper
                            className={classes.paper}
                            innerRef={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            
                            {filter.map((item, index) => (

                                <Draggable draggableId={item.id} index={index} key={item.id}  >

                                    {(provided) => (

                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{ ...provided.draggableProps.style, ...draggableContainerStyles(item) }}
                                        >
                                            <div
                                                style={{ padding: "2px 0" }}
                                            >
                                                {item.value}
                                            </div>
                                            <div
                                                style={{ margin: "2px 3px", cursor: "pointer", fontSize: '13px' }}
                                                onClick={() => handleDelete(index, item)}
                                            >
                                                <FontAwesomeIcon icon={faTimesCircle} />
                                            </div>
                                            {item.field ? 
                                            <div
                                                style={{ margin: "2px 3px", cursor: "pointer" }}
                                                onClick={() => handleUpdate(true, item)}
                                            >
                                                <FontAwesomeIcon icon={faPenSquare} />
                                            </div>
                                            :
                                            null}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Paper>
                    )}
                </Droppable>
            </DragDropContext>
            {!isUpdate ?                                     
                <div style={{ margin: "0 auto" }}>
                    <ButtonGroup
                        orientation="vertical"
                        component="div"
                    >
                        <Button
                            onClick={() => addRule()}
                            color="default"
                        >
                            Add Rule
                        </Button>
                        <Button
                            onClick={() => addOR()}
                        >
                            OR
                        </Button>
                        <Button
                            onClick={() => openGroup()}
                        >
                            Open Group
                        </Button>
                        <Button
                            onClick={() => closeGroup()}
                        >
                            Close Group</Button>
                        <Button
                            onClick={() => clearFilters()}
                            color="secondary"
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={() => applyFilters()}
                            color="primary"
                            variant="contained"
                        >
                            Apply
                        </Button>
                    </ButtonGroup>
                </div>
            :
                <div style={{ margin: "0 auto" }}>
                    <ButtonGroup
                        orientation="vertical"
                        component="div"
                    >
                        <Button
                            onClick={() => handleUpdate(false)}
                            color="primary"
                        >
                            Edit Rule
                        </Button>
                        <Button
                            onClick={() => handleCancel()}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </div>
            }
        </div>
    );
}

export default FilterQueryButtonGroup;

