import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import NotesModal from '../../../components/NotesModal/containers/NotesModal.jsx';

import { closeModal, saveBolNote, deleteBolNote, setNewNoteMessage, editNote, stopEditingNote, setEditNoteValue } from "../actions/BillOfLadingNotes.jsx";
class BillOfLadingNotesModal extends Component {

    render() {
        return (
           <NotesModal
                open={this.props.open}
                title={`Bill of Lading Notes - ${this.props.bol}`}
                newNoteMessage={this.props.newNoteMessage}
                saveNoteLoading={this.props.saveBolNoteLoading}
                notesLoading={this.props.bolNotesLoading}
                deleteNoteLoading={this.props.deleteBolNoteLoading}
                notes={this.props.bolNotes}
                editingNotes={this.props.editingNotes}
                closeModal={this.props.closeModal}
                setNewNoteMessage={(val) => this.props.setNewNoteMessage(val)}
                setEditNoteValue={(id, value) => this.props.setEditNoteValue(id, value)}
                saveNote={(id, msg, internal) => this.props.saveBolNote(id, msg, internal)}
                deleteNote={(id) => this.props.deleteBolNote(id)}
                editNote={(id) => this.props.editNote(id)}
                cancelEdit={(id) => this.props.stopEditingNote(id)}
           />
        );
    }
}

const mapStateToProps = state => {
    return {
        bol: state.BillOfLadingNotes.bol,
        open: state.BillOfLadingNotes.modalOpen ? true : false,
        bolNotesLoading: state.BillOfLadingNotes.bolNotesLoading,
        bolNotes: state.BillOfLadingNotes.bolNotes,
        saveBolNoteLoading: state.BillOfLadingNotes.saveBolNoteLoading,
        deleteBolNoteLoading: state.BillOfLadingNotes.deleteBolNoteLoading,
        newNoteMessage: state.BillOfLadingNotes.newNoteMessage,
        newNoteIsInternal: state.BillOfLadingNotes.newNoteIsInternal,
        editingNotes: state.BillOfLadingNotes.editingNotes,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        closeModal,
        saveBolNote,
        deleteBolNote,
        setNewNoteMessage,
        editNote,
        stopEditingNote,
        setEditNoteValue,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BillOfLadingNotesModal);