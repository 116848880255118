import React, { Component, Fragment } from "react";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Typography from '@material-ui/core/Typography';

class OpenInNewTab extends Component {
    render() {
        return (
            <a href="#" onClick={this.openLink}>
                <Typography noWrap>
                    <OpenInNew fontSize="inherit"/>
                    {this.props.text}
                </Typography>
            </a>
        );
    }

    openLink = e => {
        e.preventDefault();
        window.open(this.props.url, "_blank");
    }
}

export default OpenInNewTab;
