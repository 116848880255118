import axios from '../../../variables/axios.jsx';
import { handleMessages, setNotification } from '../../../redux/actions/notifications.jsx';
import qs from "qs";

export const setLoading = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_SET_LOADING", val};
}
const setBolNotesStatusesLoading = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_SET_BOL_NOTES_STATUS_LOADING", val};
}
export const setBolsWithNewNotes = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_BOLS_WITH_NEW_NOTES", val};
}
const setBolNotesCountLoading = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_SET_BOL_NOTES_COUNT_LOADING", val};
}
export const setBolsWithNotes = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_BOLS_WITH_NOTES", val};
}
export const setTransitStatusOpts = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_BOLS_TRANSIT_STATUS_OPTS", val};
}
export const setLateDeliveryOpts = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_BOLS_LATE_DELIVERY_OPTS", val};
}
export const setLatePickupOpts = val => {
	return {type: "SET_ARCHIVED_BILLS_OF_LADING_BOLS_LATE_PICKUP_OPTS", val};
}
export const setPostingPanelColumns = cols => {
	return {type: "SET_POSTING_PANEL_COLUMNS", cols};
}
export const setPostingDefaultColumns = cols => {
	return {type: "SET_POSTING_PANEL_DEFAULT_COLUMNS", cols};
}
export const setPostingPanelRefTypes = val => {
	return {type: "SET_POSTING_PANEL_REF_TYPES", val};
}

/**
 * Clear out any previously set state
 */
export const clearState = () => {
	return {type: "CLEAR_ARCHIVED_BILLS_OF_LADING"};
}

/**
 * Get statuses for bols provided
 * @param  array bols 
 */
export const getBolNotesStatus = (bols) => {
	return async (dispatch) => {
		dispatch(setBolNotesStatusesLoading(true));
		try {
			let url = 'index.php?p=api&r=json&c=billOfLadingNotes&m=getBolNoteStatus';
			if (Array.isArray(bols)) {
				bols.map(bol => {
					url += `&bols[]=${bol}`;
				});
			}
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				let bolsWithNewNotes = [];
				Object.keys(response.data.body.response).map(bol => {
					if (response.data.body.response[bol]) {
						bolsWithNewNotes.push(bol);
					}
				});
				dispatch(setBolsWithNewNotes(bolsWithNewNotes));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching bol notes status", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setBolNotesStatusesLoading(false));
		}
	}
}

/**
 * Get the number of notes for bols provided
 * @param  array bols 
 */
export const getBolsNoteCount = (bols) => {
	return async (dispatch) => {
		dispatch(setBolNotesCountLoading(true));
		try {
			let url = 'index.php?p=api&r=json&c=billOfLadingNotes&m=getNoteCount';
			if (Array.isArray(bols)) {
				bols.map(bol => {
					url += `&bols[]=${bol}`;
				});
			}
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				let bolsWithNotes = {};
				Object.keys(response.data.body.response).map(bol => {
					bolsWithNotes[bol] = response.data.body.response[bol];
				});
				dispatch(setBolsWithNotes(bolsWithNotes));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching bol notes count", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setBolNotesCountLoading(false));
		}
	}
}

export const  getTransitStatusOptions = () => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			let url = 'index.php?p=api&r=json&c=transitStatusOptions&m=collection&limit=100';
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				dispatch(setTransitStatusOpts(response.data.body.response));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching Transit Status Options", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setLoading(false));
		}
	}
}

export const  getLatePickupOptions = () => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			let url = 'index.php?p=api&r=json&c=latePickupOptions&m=collection&limit=100';
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				dispatch(setLatePickupOpts(response.data.body.response));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching Late Pickup Options", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setLoading(false));
		}
	}
}

export const  getLateDeliveryOptions = () => {
	return async (dispatch) => {
		dispatch(setLoading(true));
		try {
			let url = 'index.php?p=api&r=json&c=lateDeliveryOptions&m=collection&limit=100';
			const response = await axios.get(url);
			dispatch(handleMessages(response));
			if (response && response.data && response.data.body && response.data.body.response) {
				dispatch(setLateDeliveryOpts(response.data.body.response));
			}
		} catch (err) {
			dispatch(setNotification("Error fetching Late Delivery Options", {variant: "error"}))
			console.error(err);
		} finally {
			dispatch(setLoading(false));
		}
	}
}

export const savePostingPanelColumns = (cols) => {
	return async (dispatch, getState) => {
		let url = `index.php?p=api&r=json&c=postingPanel&m=savePostingPanelColumns`;
		const payload = cols;
		const customColumns = getState().ArchivedBillsOfLading.postingPanelColumns;
		try {
			const response = await axios.post(url, qs.stringify(payload));
			if (response && response.data && response.data.body && response.data.body.updated) {
				if (response.data.body.updated) {
					dispatch(setNotification("Custom layout saved!", {variant: "success"}));
					if(_.isEmpty(customColumns)) {
						dispatch(setPostingPanelColumns(cols));
					}
				}
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error saving custom layout.", {variant: "error"}));
			console.error(err);
		} 
	}
}

export const getPostingPanelColumns = () => {
	return async (dispatch) => {
		let url = `index.php?p=api&r=json&c=postingPanel&m=getPostingPanelColumns`;
		try {
			const response = await axios.get(url);
			if (response && response.data && response.data.body && response.data.body.columns) {
				dispatch(setPostingPanelColumns(response.data.body.columns));
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error getting custom layout", {variant: "error"}));
			console.error(err);
		} 
	}
}

export const getReferences = () => {
	return async (dispatch) => {
		let url = `index.php?p=api&r=json&c=userSettings&m=getReferenceTypes`;
		try {
			const response = await axios.get(url);
			if (response && response.data && response.data.body) {
				const types = response.data.body.map((ref) => {
					return {name: ref, value: ref}
				})
				dispatch(setPostingPanelRefTypes(types));
			}
			dispatch(handleMessages(response));
		} catch (err) {
			dispatch(setNotification("Error getting custom ref types.", {variant: "error"}));
			console.error(err);
		} 
	}
}