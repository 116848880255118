import { cardTitle } from "assets/jss/empire-tms.jsx";
import customSelectStyle from "assets/jss/empire-tms/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "~/assets/jss/empire-tms/customCheckboxRadioSwitch.jsx";
import modalStyle from "~/assets/jss/empire-tms/modalStyle.jsx";

import {
	containerFluid,
	defaultFont,
	uiColors,
	tfmBlueColor,
	defaultBoxShadow,
	whiteColor,
	blackColor,
} from "assets/jss/empire-tms.jsx";

const archivedBillsOfLadingStyle = theme => ({
	...customSelectStyle,
	...customCheckboxRadioSwitch,
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	search: {
		margin: "0",
		paddingTop: "7px",
		paddingBottom: "7px",
		[theme.breakpoints.down("sm")]: {
			margin: "10px 15px",
			float: "none !important",
			paddingTop: "1px",
			paddingBottom: "1px",
			padding: "10px 15px",
			width: "auto"
		},
		zIndex: "4"
	},
	greenIcon: {

	},
	yellowIcon: {
		color:"#ff9800",
		fontSize:"2.5rem",
	},
	redIcon: {
		color:"#ff9800",
		fontSize:"2.5rem",
	},
	searchButton: {
		[theme.breakpoints.down("sm")]: {
			top: "-50px !important",
			marginRight: "38px",
			float: "right"
		},
		zIndex: "4"
	},
	center: {
		textAlign: "center"
	},
	right: {
		textAlign: "right"
	},
	left: {
		textAlign: "left"
	},
	notifications: {
		zIndex: "4",
		[theme.breakpoints.up("md")]: {
		  position: "absolute",
		  bottom: "15px",
		  border: "1px solid " + whiteColor,
		  right: "5px",
		  fontSize: "9px",
		  background: uiColors.danger.default,
		  color: whiteColor,
		  minWidth: "16px",
		  height: "16px",
		  borderRadius: "10px",
		  textAlign: "center",
		  lineHeight: "14px",
		  verticalAlign: "middle",
		  display: "block"
		},
		[theme.breakpoints.down("sm")]: {
		  ...defaultFont,
		  fontSize: "14px",
		  marginRight: "8px"
		}
	},
	notes: {
		zIndex: "4",
		[theme.breakpoints.up("md")]: {
		  position: "absolute",
		  bottom: "15px",
		  right: "6px",
		  fontSize: "9px",
		  color: blackColor,
		  minWidth: "16px",
		  height: "16px",
		  textAlign: "center",
		  lineHeight: "15px",
		  verticalAlign: "middle",
		  display: "block"
		},
		[theme.breakpoints.down("sm")]: {
		  ...defaultFont,
		  fontSize: "14px",
		  marginRight: "8px"
		}
	},
	datetime: {
		position: 'static',
		"&.rdtOpen > .rdtPicker": {
			marginTop: "15px"
		}
	},
	...modalStyle(theme)
});

export default archivedBillsOfLadingStyle;