export const setTFMRIndexURL = val => {
	return {type: "SET_TFMR_INDEX_URL", val};
}

export const setTTLIndexURL = val => {
	return {type: "SET_TTL_INDEX_URL", val};
}

export const setYATEnabled = val => {
	return {type: "SET_YAT_ENABLED", val};
}

export const setYATIndexURL = val => {
	return {type: "SET_YAT_INDEX_URL", val};
}
