const initialState = {
	invoices: [],
	selectedInvoices: [],
	fetchInvoicesLoading: false,
	massReleasing: false,
	glcode: "",
	massReleaseComment: "",
	heldBillsByCode: [],
	totalHeldBills: 0,
	totalUniqueHeldBills: 0,
	heldBillsSummaryLoading: false,
	viewingBill: null,
	fetchBillLoading: null,
	holdCode: null,
	massReleaseLoading: false,
	cannedReports: [],
	cannedReportsLoading: false,
	runCannedReportLoading: false,
	newCannedReportId: null,
	refEntryType: "",
	refEntryOpts: [],
	updateDist: "",
	updateCostCenter: "",
	updateBol: "",
	updatePo: "",
	updateRef: "",
	updateRa: "",
	updateVendor: "",
	updateNote: "",
	declinePayment: false,
	correctionCode: "I",
	adjustmentNote: "",
	releaseForPayment: false,
	holdsToRelease: [],
	notApproved: false,
	notApprovedNote: "",
	updateBillLoading: false,
	fileUploadLoading: false,
	uploadedFiles: [],
	invoicesTotalRecords: 0,
	tfmInvoiceLocations:[],
	tfmInvoiceBilledLocation: 0,

	//tfmInvoices page
	tfmInvoices: [],
	tfmInvoicesLoading: false,
	tfmInvoicesPageNumber: 1,
	tfmInvoicesPageSize: 15,
	tfmInvoicesTotalRecords: 0,

	downloadCarrierImagesLoading: false,
	imagesLoading: [],
	imagesReady:[],
	imageTypes:[],

	//tfmInvoiceCarrierBills page
	tfmInvoiceCarrierBillsPageNumber: 1,
	tfmInvoiceCarrierBillsPageSize: 15,
	tfmInvoiceCarrierBillsTotalRecords: 0,

	// search page
	tfmInvoiceSearchResults: [],
	searchTfmInvoicesLoading: false,
	tfmInvoiceSearchPageNumber: 1,
	tfmInvoiceSearchPageSize: 50,
	tfmInvoiceSearchTotalRecords: 0,

	userBrokerLocations: [],
	shouldFetchBrokerLocationIds: true,
	invoiceListFilters: {},
	invoiceListColumns: {},
	invoiceListCustomColumns: {},
};

export default function(state = initialState, action) {
	const newState = state;
	switch (action.type) {
		case "SET_CUSTOMER_PORTAL_INVOICES": {
			return {...newState, invoices: [...action.invoices]};
		}
		case "SET_CUSTOMER_PORTAL_SELECTED_INVOICES": {
			return {...newState, selectedInvoices: [...action.ids]};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_LOADING": {
			return {...newState, fetchInvoicesLoading: action.val};
		}
		case "SET_CUSTOMER_PORTAL_HELD_BILLS_BY_CODE": {
			return {...newState, heldBillsByCode: [...action.bills]};
		}
		case "SET_CUSTOMER_PORTAL_GL_CODE": {
			return {...newState, glcode: action.val};
		}
		case "SET_CUSTOMER_PORTAL_MASS_RELEASE_COMMENT": {
			return {...newState, massReleaseComment: action.val};
		}
		case "SET_CUSTOMER_PORTAL_MASS_RELEASING": {
			return {...newState, massReleasing: action.val};
		}
		case "SET_CUSTOMER_PORTAL_TOTAL_HELD_BILLS": {
			return {...newState, totalHeldBills: action.val};
		}
		case "SET_CUSTOMER_PORTAL_TOTAL_UNIQUE_HELD_BILLS": {
			return {...newState, totalUniqueHeldBills: action.val};
		}
		case "SET_CUSTOMER_PORTAL_HELD_BILLS_SUMMARY_LOADING": {
			return {...newState, heldBillsSummaryLoading: action.val};
		}
		case "SET_CUSTOMER_PORTAL_FETCH_BILL_LOADING": {
			return {...newState, fetchBillLoading: action.val};
		}
		case "SET_CUSTOMER_PORTAL_VIEWING_BILL": {
			return {...newState, viewingBill: action.val};
		}
		case "SET_CUSTOMER_PORTAL_HOLD_CODE": {
			return {...newState, holdCode: action.val};
		}
		case "SET_CUSTOMER_PORTAL_MASS_RELEASE_LOADING": {
			return {...newState, massReleaseLoading: action.val};
		}
		case "SET_CANNED_REPORTS": {
			return {...newState, cannedReports: action.cannedReports};
		}
		case "SET_CANNED_REPORTS_LOADING": {
			return {...newState, cannedReportsLoading: action.val};
		}
		case "SET_RUN_CANNED_REPORT_LOADING": {
			return {...newState, runCannedReportLoading: action.val};
		}
		case "SET_NEW_CANNED_REPORT_ID": {
			return {...newState, newCannedReportId: action.val};
		}
		case "SET_REFERENCE_ENTRY_TYPE": {
			return {...newState, refEntryType: action.val};
		}
		case "SET_REFERENCE_ENTRY_OPTIONS": {
			return {...newState, refEntryOpts: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_DIST" : {
			return {...newState, updateDist: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_CC" : {
			return {...newState, updateCostCenter: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_BOL" : {
			return {...newState, updateBol: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_PO" : {
			return {...newState, updatePo: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_REF" : {
			return {...newState, updateRef: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_VENDOR" : {
			return {...newState, updateVendor: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_RA" : {
			return {...newState, updateRa: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_NOTE" : {
			return {...newState, updateNote: action.val};
		}
		case "SET_CUSTOMER_PORTAL_DECLINE_PAYMENT" : {
			return {...newState, declinePayment: action.val};
		}
		case "SET_CUSTOMER_PORTAL_CORRECTION_CODE" : {
			return {...newState, correctionCode: action.val};
		}
		case "SET_CUSTOMER_PORTAL_ADJUSTMENT_NOTE" : {
			return {...newState, adjustmentNote: action.val};
		}
		case "SET_CUSTOMER_PORTAL_RELEASE_FOR_PAYMENT" : {
			return {...newState, releaseForPayment: action.val};
		}
		case "SET_CUSTOMER_PORTAL_NOT_APPROVED" : {
			return {...newState, notApproved: action.val};
		}
		case "SET_CUSTOMER_PORTAL_NOT_APPROVED_NOTE" : {
			return {...newState, notApprovedNote: action.val};
		}
		case "SET_CUSTOMER_PORTAL_UPDATE_BILL_LOADING" : {
			return {...newState, updateBillLoading: action.val};
		}
		case "SET_CUSTOMER_PORTAL_HOLDS_TO_RELEASE" : {
			let newHolds = Array.isArray(action.val) ? action.val : [];
			return {...newState, holdsToRelease: [...newHolds]};
		}
		case "SET_CUSTOMER_PORTAL_FILE_UPLOAD_LOADING" : {
			return {...newState, fileUploadLoading: action.val};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICES" : {
			let tfmInvoices = Array.isArray(action.val) ? action.val : [];
			return {...newState, tfmInvoices: [...tfmInvoices]};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICES_PAGE_NUMBER" : {
			let pagenum = +action.val < 1 ? 1 : +action.val;
			return {...newState, tfmInvoicesPageNumber: pagenum};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICES_PAGE_SIZE" : {
			return {...newState, tfmInvoicesPageSize: action.val};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICES_LOADING" : {
			return {...newState, tfmInvoicesLoading: action.val};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICES_TOTAL_RECORDS" : {
			return {...newState, tfmInvoicesTotalRecords: +action.val};
		}
		case "SET_CUSTOMER_PORTAL_INVOICES_TOTAL_RECORDS" : {
			return {...newState, invoicesTotalRecords: action.val};
		}
		case "SET_CUSTOMER_PORTAL_DOWNLOAD_CARRIER_IMAGES_LOADING" : {
			return {...newState, downloadCarrierImagesLoading: !!action.val};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICE_CARRIER_BILLS_PAGE_NUMBER" : {
			return {...newState, tfmInvoiceCarrierBillsPageNumber: +action.val};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICE_CARRIER_BILLS_TOTAL_RECORDS" : {
			return {...newState, tfmInvoiceCarrierBillsTotalRecords: +action.val}
		}
		case "SET_CUSTOMER_PORTAL_TFM_LOADING_IMAGES" : {
			return {...newState, imagesLoading: [...action.val]};
		}
		case "SET_CUSTOMER_PORTAL_TFM_READY_IMAGES" : {
			return {...newState, imagesReady: [...action.val]};
		}
		case "SET_CUSTOMER_PORTAL_TFM_INVOICE_IMAGE_TYPES" : {
			return {...newState, imageTypes: [...action.val]};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_RESULTS" : {
			return {...newState, tfmInvoiceSearchResults: action.val}
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_LOADING" : {
			return {...newState, searchTfmInvoicesLoading: action.val}
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_PAGE_NUMBER" : {
			return {...newState, tfmInvoiceSearchPageNumber: +action.val};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_SEARCH_TOTAL_RECORDS" : {
			return {...newState, tfmInvoiceSearchTotalRecords: +action.val}
		}
		case "SET_CUSTOMER_PORTAL_COMPANY_LOCATIONS" : {
			return {...newState, tfmInvoiceLocations: [...action.val]};
		}
		case "SET_CUSTOMER_PORTAL_COMPANY_BILLED_LOCATION" : {
			return {...newState, tfmInvoiceBilledLocation: +action.val}
		}
		case "SET_CUSTOMER_PORTAL_USER_BROKER_LOCATIONS" : {
			return {...newState, userBrokerLocations: action.val};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_LIST_FILTERS" : {
			return (action.val === null) ? {...newState, invoiceListFilters: initialState.invoiceListFilters} : {...newState, invoiceListFilters: action.val};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_LIST_COLUMNS" : {
			return {...newState, invoiceListColumns: action.val};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_LIST_CUSTOM_COLUMNS" : {
			return {...newState, invoiceListCustomColumns: action.val};
		}
		case "SET_CUSTOMER_PORTAL_INVOICE_UPLOADED_FILES" : {
			return {...newState, uploadedFiles: action.val};
		}
		default:
			return state;
	}
}

