import React, { Fragment } from "react";
import axios from "~/variables/axios.jsx";
import { withSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";
import qs from "qs";
import { process } from "@progress/kendo-data-query";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Tooltip } from "@progress/kendo-react-tooltip";

// import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
// import Button from "~/components/CustomButtons/Button.jsx";

import archivedBillsOfLadingStyle from "~/assets/jss/empire-tms/views/tms/archivedBillsOfLadingStyle.jsx";

import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { Link } from "react-router-dom";
import { basePath } from "~/variables/server.jsx";


const NumberCell = (props, style, className) => {

  return (
    <td
      title={props.dataItem[props.field]}
      style={{
        whiteSpace: "nowrap",

      }}
    >
      {props.dataItem[props.field]}
    </td>
  );
}

const linkStyle = {
  color: '#0976B4',
  textDecoration: "underline",
  whiteSpace: "nowrap",
};
class invoiceStandardCarrierByInvoiceDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      show: false,
      loading: true,
      body: null,
      start_date: this.props.location.state.startDate,
      end_date: this.props.location.state.endDate,
      skip: 0,
      take: 25,
      data: [],
      initialized: true,
      title: null,
      sort: null,
      totalCount: 0,
      dataState: {
        skip: 0,
        take: 25,
      },
      result: [],
      backArrow: false,
      open: false,
      anchorEl: false,
      columns: this.props.location.state.unSelected,
      exportOptions: null,
    };


    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExportChange = this.handleExportChange.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  async componentDidMount() {
    this.setState({ mounted: true });
    this.handleSearch();
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  

  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  handleExportChange = (event) => {
    this.setState({ exportOptions: event.target.value });
  };

  handleClose = (event) => {
    this.setState({ anchorEl: false });
  };


  async handleSearch(pageNum = 1, rowCount = 25) {
    let search_fields = []
    this.state.columns.forEach(element => search_fields.push(element.field));
    search_fields.push("pro_number")
    search_fields.push("invoice_number")

    this.setState({
      initialized: false,
    });
    if (pageNum < 25) {
      pageNum = 1;
    }
    if (pageNum >= 25) {
      pageNum = pageNum / rowCount + 1;
    }

    let data = {
      search_fields,
      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
    };
    const url =
      "/index.php?p=api&r=json&c=standardCarrierInvoiceReports&m=standardCarrierReportByInvoice&d=" + pageNum +
      "/" +
      rowCount;

    this.setState({
      show: false,
      loading: true,
    });

    try {
      const response = await axios.post(url, qs.stringify(data));
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
        this.props.handleMessages(response);
      }
      if (typeof response.data !== "string") {
        this.setState({
          initialized: true,
          show: true,
          loading: false,
          title: response.data.title,
          totalCount: response.data.body.total,
          result: response.data.body.result,
        });
      } else {
        this.setState({ loading: false });
        this.props.setNotification(
          "There was an error searching for Standard Carrier Invoice report!",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      this.props.setNotification(
        "There was an error searching for Standard Carrier Invoice report!",
        { variant: "error" }
      );
    }
  }



  dataPageChange = (event) => {
    this.setState(
      {
        skip: event.page.skip,
        take: event.page.take,
        dataState: {
          skip: event.page.skip,
          take: event.page.take,
        },
      },
      () => {
        this.handleSearch(event.page.skip, event.page.take).then((res) => {
          this.setState({
            dataResult: process(this.state.data, this.state.dataState),
          });
        });
      }
    );
  };

  dataStateChange = (event) => {
    this.setState(
      {
        skip: event.page.skip,
        take: event.page.take,
      },
      () => {
        this.handleSearch(event.page.skip, event.page.take).then((res) => {
          this.setState({
            dataResult: process(this.state.data, this.state.dataState),
            dataState: event.dataState,
          });
        });
      }
    );
  };



  async exportReport() {

    let search_fields = []
    this.state.columns.forEach(element => search_fields.push(element.field));
    search_fields.push("pro_number")
    search_fields.push("invoice_number")

    this.setState({
      initialized: false,
    });
    let data = {
      search_fields,
      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
    };

    const pageNumber = this.state.dataState.skip;
    const rowCount = this.state.dataState.take;

    let url =
      "/index.php?p=api&r=json&c=standardCarrierInvoiceReports&m=standardCarrierReportByInvoice&d=" + pageNumber +
      "/" +
      rowCount;

    try {
      const response = await axios.post(url, qs.stringify(data));

      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.message)
      ) {
      }
      if (
        typeof response.data !== "string" &&
        !_.isEmpty(response.data.user) &&
        !_.isEmpty(response.data.body)
      ) {
        this.setState({
          initialized: true,
        });
        return response.data.body.result;
      } else {
        this.setState({
          initialized: true,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      this.props.setNotification("There was an error loading the data!", {
        variant: "warning",
      });
    }
  }

  exportExcel = () => {
    this.exportReport().then((res) => {
      this._export.save(res);
    });
  };
  exportPDF = () => {
    this.exportReport().then((res) => {
      this._pdfExport.save(res);
    });
  };


  async exportData(data_type) {
    this.setState({
      initialized: false,
    });
    let search_fields = []
    search_fields.push("pro_number")
    search_fields.push("invoice_number")

    this.state.columns.forEach(element => search_fields.push(element.field));
    let query_type = "invoice"

    const url = "/index.php?p=api&r=json&c=standardCarrierInvoiceReports&m=exportStandardReportByDate";

    let data = {
      search_fields,
      start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
      query_type,
      data_type
    };

    axios({
      url: url,
      method: 'POST',
      data: qs.stringify(data),
      responseType: 'blob',
    }).then((res) => {
      try{

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;

        let contentDisposition = res.headers['content-disposition'];
        const fileName = contentDisposition.split("=");//extract the filename from here.

        link.setAttribute('download', fileName[1]);
        document.body.appendChild(link);
        link.click();
        this.setState({
          initialized: true,
        });

      }catch (error) {
        this.setState({ loading: false });
        this.props.setNotification("There was an error exporting the data!", {
          variant: "error",
        });
        this.setState({
          initialized: true,
        });
      }
    });

  }


  render() {
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} style={{ paddingTop: "10px" }}>
            <Tooltip openDelay={100} position="center" anchorElement="element">
              <ExcelExport
                data={this.state.data}
                fileName={this.state.reportName}
                ref={(exporter) => {
                  this._export = exporter;
                }}
              >
                <Grid
                  style={{
                    height: "500px",
                    fontSize: "12px",
                    lineHeight: "0px",
                  }}
                  className={!this.state.initialized ? "k-state-disabled" : ""}
                  reorderable={true}
                  pageable={{
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [ 25, 50, 100],
                    previousNext: true,
                  }}
                  rowHeight={1}
                  sort={this.state.sort}
                  data={this.state.result}
                  total={this.state.totalCount}
                  skip={this.state.skip}
                  take={this.state.take}
                  pageSize={this.state.take}
                  onPageChange={this.dataPageChange}
                  onDataStateChange={this.dataStateChange}
                >
                  <GridToolbar>
                    <div style={{ paddingLeft: "2%" }}>
                      <b>{this.state.title}</b>
                    </div>

                    <div style={{ paddingLeft: "10%" }}>
                      <div>
                        <button
                          title="Export to excel"
                          className="k-button k-primary"
                          onClick={(e) => this.exportData("xls")} disabled={_.isEmpty(this.state.result)}
                        >
                          Export to excel
                        </button>
                        &nbsp;
                        <button
                          className="k-button k-primary"
                          onClick={(e) => this.exportData("csv")} disabled={_.isEmpty(this.state.result)}
                        >
                          Export to CSV
                        </button>
                      </div>
                    </div>
                  </GridToolbar>

                  <Column
                    field={"pro_number"}
                    title={"Pro#"}
                    width={"175px"}
                    locked={true}
                    cell={({ dataItem, style, className }) => {
                      return (
                        <td
                          title={dataItem.pro_number}
                          style={style}
                          className={className}
                        >

                          <Link to={{
                            pathname: basePath + "/admin/Carrier_Invoice_Detail",

                            state: {
                              pro_number: dataItem.invoice_id,
                            },
                          }}

                            style={linkStyle}

                          >

                            {dataItem.pro_number}
                          </Link>




                        </td>
                      );
                    }
                    }
                  />
                  <Column
                    field={"invoice_number"}
                    title={"Invoice#"}
                    width={"110px"}
                    cell={NumberCell}
                  />
                  {this.state.columns.map((col, index) => {
                    return (
                      <Column
                        key={index}
                        field={col.field}
                        title={col.title}
                        width={col.width}
                        cell={NumberCell}
                        locked={col.locked}
                      />

                    );
                  })}
                </Grid>
              </ExcelExport>
            </Tooltip>
            <GridPDFExport
              data={this.state.result}
              ref={(element) => {
                this._pdfExport = element;
              }}
              margin="1cm"
              fileName={this.state.reportName}
            >
              <Grid
                style={{
                  height: "500px",
                }}
                className={!this.state.initialized ? "k-state-disabled" : ""}
                sortable={true}
                groupable={{
                  footer: "visible",
                }}
                reorderable={true}
                pageable={{
                  buttonCount: 25,
                  pageSizes: true,
                }}
                data={this.state.result}
                total={this.state.totalCount}
                skip={this.state.skip}
                take={this.state.take}
                onPageChange={this.dataPageChange}
                onDataStateChange={this.dataStateChange}
              >
                <GridToolbar>
                  <label htmlFor="reportName">{this.state.reportName}</label>
                  <button
                    title="Export to excel"
                    className="k-button k-primary"
                    onClick={this.exportExcel}
                  >
                    Export to excel
                  </button>
                  &nbsp;

                </GridToolbar>

                <Column
                  field={"pro_number"}
                  title={"Pro#"}
                  width={"120px"}
                  locked={true}
                  cell={({ dataItem, style, className }) => {
                    return (
                      <td
                        title={dataItem.pro_number}
                        style={style}
                        className={className}
                      >
                        {dataItem.pro_number}
                      </td>
                    );
                  }
                  }
                />

                <Column
                  field={"invoice_number"}
                  title={"Invoice#"}
                  width={"110px"}
                  cell={NumberCell}
                />
                {this.state.columns.map((col, index) => {
                  return (
                    <Column
                      key={index}
                      field={col.field}
                      title={col.title}
                      width={col.width}
                      cell={NumberCell}
                      locked={col.locked}
                    />

                  );
                })}
              </Grid>
            </GridPDFExport>
            {!this.state.initialized && (
              <Loader
                size={"large"}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
                type={"infinite-spinner"}
              />
            )}
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(archivedBillsOfLadingStyle)(
  withSnackbar(invoiceStandardCarrierByInvoiceDate)
);
