import React from "react";
import { connect } from "react-redux";
import { hasPermission } from "../../redux/selectors/Admin.jsx";
import qs from "qs";
import { Redirect } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

// style for this view
import newProductStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewNote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			warehouse: "",
			id: "",
			name: "",
			note: "",
			default: false,
			done: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveNote = this.saveNote.bind(this);
	}

	async componentDidMount() {
		this.setState({ mounted: true });
		const id = this.props.match.params.id;
		const url = "/index.php?p=api&r=json&c=userSettings&m=editNote&d=" + id;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			this.props.pageTitle('Edit Canned Note');
			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && !_.isEmpty(response.data.user) && this.state.mounted) {
				this.setState({
					show: true,
					loading: false,
					user: response.data.user,
					body: response.data.body,
					id,
					name: response.data.body.name,
					note: response.data.body.note,
					warehouses: response.data.body.warehouses,
					warehouse: response.data.body.warehouse_name_id || "",
					default: response.data.body.default == 1
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", { variant: "error" });
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async saveNote() {
		this.setState({ loading: true });

		const { id, name, note, warehouse } = this.state;

		const data = {
			name,
			note,
			warehouse
		};

		if (this.state.default) {
			data.default = "1";
		}

		const url = "/index.php?p=api&r=json&c=userSettings&m=editNote&d=" + id;

		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				this.setState({
					loading: false,
					done: true
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the note!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error saving the note!", { variant: "error" });
		}
	}

	getWarehouses(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					{prop.name}
				</MenuItem>
			);
		});
	}

	render() {
		if (this.state.done) {
			const redirectTo = basePath + "/admin/canned-notes";
			return <Redirect to={redirectTo} />;
		}
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={3}>
									<InputLabel htmlFor="warehouse" className={classes.label}>
										Warehouse/Facility
									</InputLabel>
									<br />
									<FormControl className={classes.selectFormControl} fullWidth>
										<Select
											MenuProps={{ className: classes.selectMenu }}
											classes={{ select: classes.select }}
											value={this.state.warehouse || ""}
											inputProps={{
												name: "warehouse",
												id: "warehouse"
											}}
											onChange={this.handleChange}
											readOnly={!this.props.hasWrite}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value=""
											>
												All
											</MenuItem>
											{!_.isEmpty(this.state.warehouses) && this.getWarehouses(this.state.warehouses)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel htmlFor="name" className={classes.label}>
										Name/Title
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											name: "name",
											value: this.state.name || "",
											onChange: this.handleInput("name"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								<GridItem xs={3}>
									<InputLabel htmlFor="note" className={classes.label}>
										Note
									</InputLabel>
									<br />
									<CustomInput
										formControlProps={{ fullWidth: true }}
										inputProps={{
											type: "text",
											id: "note",
											name: "note",
											multiline: true,
											value: this.state.note || "",
											onChange: this.handleInput("note"),
											readOnly: !this.props.hasWrite,
										}}
										white
									/>
								</GridItem>
								<GridItem xs={3}>
									<FormControlLabel
										control={
											<Checkbox
												tabIndex={-1}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot
												}}
												checked={this.state.default}
												onChange={this.handleChecked("default")}
												disabled={!this.props.hasWrite}
											/>
										}
										classes={{
											label: classes.label
										}}
										label="Default"
									/>
								</GridItem>
								{this.props.hasWrite && <GridItem xs={12}>
									<Button color="linkedin" onClick={this.saveNote}>
										Save Note
									</Button>
								</GridItem>}
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasWrite: hasPermission("CANNED_NOTES_WRITE")(state),
	};
}

export default connect(mapStateToProps)(withStyles(newProductStyle)(withSnackbar(NewNote)));