import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { basePath } from "~/variables/server.jsx";
import Skeleton from 'react-loading-skeleton';
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import SearchByChip from "~/views/TMS/SearchByChip.jsx";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from "@material-ui/icons/Search";
import Button from "~/components/CustomButtons/Button.jsx";
import TopActions from '~/views/CustomerPortal/components/TopActions.jsx';
import ViewImagesModal from "../components/ViewImagesModal.jsx";
import Typography from '@material-ui/core/Typography';
import { 
    searchTfmInvoices,
    exportTfmInvoices,
    setCustomerPortalInvoiceSearchResults,
    setCustomerPortalInvoiceSearchTotalRecords,
    setCustomerPortalInvoiceSearchPageNumber
} from '~/views/CustomerPortal/actions/CustomerPortal.jsx'

class TFMInvoiceSearchContainer extends Component {

    state = {
        searchBy: 'pro',
        searchValues: [],
		viewingImages: [],
		imageModalOpen: false,
    };

    handleValue = (name, e) => this.setState({ [name]: e.target.value });

    clearAllFilters = () => this.setState({ searchValues: [] });

    changeSearchValues = value => this.setState({ searchValues: value });

    handleSearch = () => this.props.searchTfmInvoices(this.state.searchBy, this.state.searchValues);

    handleExport = async () => this.props.exportTfmInvoices(this.state.searchBy, this.state.searchValues, 'searchPros');

    numberPages = () => {
		return +this.props.pageSize > this.props.totalRecords
			? 1
			: Math.ceil(+this.props.totalRecords / +this.props.pageSize);
	}

	onPageChange = index => {
		this.props.setCustomerPortalInvoiceSearchPageNumber(index + 1);
		this.props.searchTfmInvoices(this.state.searchBy, this.state.searchValues);
	}

    handleDocsClick = e => {
		let invid = e.currentTarget.getAttribute('value');
		let images = [];
		this.props.invoices.map(invoice => {
			if (+invoice.id === +invid && Array.isArray(invoice.image)) {
				images = invoice.image;
			}
		})
		this.setState({images, imageModalOpen: true});
	}

    handlePlaceholder = (name) => {
        let placeholder = 'Pro';
        switch(name) {
            case 'carrierInvNumber':
                placeholder = 'Carrier Invoice';
                break;
            case 'bol':
                placeholder = 'Bol';
                break;
            case 'poNumber':
                placeholder = "Po";
                break;
        }
        return placeholder;
    }

    onCloseImageModal = () => this.setState({imageModalOpen: false});

    render() {
        let columns = [
            {Header: 'View Bill', Cell: rowInfo => {
                if (!Array.isArray(rowInfo.original.image) || !rowInfo.original.image.length) {
                    return '';
                } else {
                     return (
                        <div value={rowInfo.original.id} onClick={this.handleDocsClick}>
                            <IconButton size="small">
                                <SearchIcon fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ); 
                } 
            }},
            {Header: 'Pro #', Cell: rowInfo => {
                return (
                    <Link to={`${basePath}/admin/customer-portal/view-carrier-bill/${rowInfo.original.id}`}>
                        {rowInfo.original.pro}
                    </Link>
                );
            }},
            {Header: 'Customer', Cell: rowInfo => {
                return rowInfo.original.company.name;
            }},
            {Header: "Carrier", Cell: rowInfo => {
                return rowInfo.original.carrier.scac;
            }},
            {Header: 'TFM Invoice #', Cell: rowInfo => {
                return (
					<Link to={`${basePath}/admin/customer-portal/view-invoices/${rowInfo.original.company.id}/${rowInfo.original.invoiceNumber}`}>
						{rowInfo.original.invoiceNumber}
					</Link>
				);
            }},
            {Header: 'Paid', Cell: rowInfo => {
                return rowInfo.original.status === 'Paid' ? 'Y' : 'N';
            }},
            {Header: 'Amt Billed', Cell: rowInfo => {
                return rowInfo.original.financialInfo.trueCost;
            }},
            {Header: "PC/CC #", Cell: rowInfo => {
                return rowInfo.original.distCode;
            }},
            {Header: "Ship Date", Cell: rowInfo => {
                return rowInfo.original.shippingDetail.shipDate == null ? '' : rowInfo.original.shippingDetail.shipDate;
            }},
            {Header: "Hold Status", Cell: rowInfo => {
                return rowInfo.original.holds.map(h => h.code).join(', ');
            }},
            {Header: "Carrier Invoice #", Cell: rowInfo => {
                return rowInfo.original.carrierInvNumber;
            }},
            {Header: "Terms", Cell: rowInfo => {
                return rowInfo.original.shippingDetail.terms;
            }},
            {Header: "Shipper Company", Cell: rowInfo => {
                return rowInfo.original.shippingDetail.originName;
            }},
            {Header: "Consignee Company", Cell: rowInfo => {
                return rowInfo.original.shippingDetail.destinationName;
            }},
            {Header: 'BOL #', Cell: rowInfo => {
                return rowInfo.original.bolNumber;
            }},
            {Header: "PO #", Cell: rowInfo => {
                return rowInfo.original.poNumber;
            }},
            {Header: "Ret Authorization #", Cell: rowInfo => {
                return rowInfo.original.raNumber;
            }},
            {Header: "Ref #", Cell: rowInfo => {
                return rowInfo.original.refNumber;
            }},
            {Header: "Custom Ref 1", Cell: rowInfo => {
                return rowInfo.original.refNumber1;
            }},
            {Header: "Custom Ref 2", Cell: rowInfo => {
                return rowInfo.original.refNumber2;
            }}
        ];

        const menuOptions = [
            // potentially include other options in future
            {value: 'pro', display: 'Pro'},
            {value: 'bol', display: 'Bol'},
            {value: 'poNumber', display: 'Po'},
            {value: 'carrierInvNumber', display: 'Carrier Invoice #'},
        ];

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                {this.state.searchBy === 'pro' && (
                                    <GridItem style={{ marginBottom: "10px" }}>
                                        <Typography variant="body2">
                                            Please enter PRO numbers without leading zeros or dashes.
                                        </Typography>
                                    </GridItem>
                                )}
                                <GridItem xs={12} sm={11}>
                                    <SearchByChip
                                        menuLabel="Search By"
                                        menuOptions={menuOptions}
                                        searchBy={this.state.searchBy}
                                        onChangeSearchBy={this.handleValue}
                                        placeholder={`Enter ${this.handlePlaceholder(this.state.searchBy)} number(s) here and then press enter`}
                                        searchValues={this.state.searchValues}
                                        changeSearchValues={this.changeSearchValues}
                                        clearAllSearchValues={this.clearAllFilters}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={1}>
                                    <Button justIcon round color="linkedin" onClick={this.handleSearch}>
                                        <SearchIcon/>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                {this.props.loading && (
                                    <GridItem xs={12}>
                                        <Skeleton height={550}/>
                                    </GridItem>
                                )}
                                {(!this.props.loading) && (
                                    <GridItem xs={12}>
                                        <TopActions>
                                            <GridItem xs={8} sm={8} md={8}>
                                                <Button
                                                    round
                                                    color="success"
                                                    size="sm"
                                                    onClick={this.handleExport}
                                                    disabled={this.props.totalRecords == 0}
                                                >
                                                    Export to CSV
                                                </Button>
                                            </GridItem>
                                            <GridItem xs={4} style={{ textAlign:"right", color: "#000"}}>
                                                <h4 style={{marginRight: "5px"}}>{`Records: ${this.props.totalRecords}`}</h4>
                                            </GridItem>
                                        </TopActions>
                                        <ViewImagesModal open={this.state.imageModalOpen} images={this.state.images} onClose={this.onCloseImageModal}/>
                                        <div className="react-table-condensed">
                                            <ReactTable
                                                data={this.props.invoices || []}
                                                pages={this.numberPages()}
                                                columns={columns}
                                                loading={this.props.loading}
                                                className={this.props.className || "-striped -highlight"}
                                                defaultPageSize={this.props.pageSize}
                                                showPaginationTop={true}
                                                showPaginationBottom={false}
                                                showPageSizeOptions={false}
                                                sortable={false}
                                                page={this.props.pageNumber-1}
                                                manual={true}
                                                onPageChange={this.onPageChange}
                                                minRows={this.props.pageSize}
                                            />
                                        </div>
                                    </GridItem>
                                )}
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

const mapStateToProps = state => {
	return {
		loading: state.CustomerPortal.searchTfmInvoicesLoading,
		invoices: state.CustomerPortal.tfmInvoiceSearchResults,
		pageNumber: state.CustomerPortal.tfmInvoiceSearchPageNumber,
		pageSize: state.CustomerPortal.tfmInvoiceSearchPageSize,
		totalRecords: state.CustomerPortal.tfmInvoiceSearchTotalRecords,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
        searchTfmInvoices,
        exportTfmInvoices,
        setCustomerPortalInvoiceSearchResults,
        setCustomerPortalInvoiceSearchTotalRecords,
        setCustomerPortalInvoiceSearchPageNumber
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TFMInvoiceSearchContainer);