import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";

class OptionList extends Component {
  static defaultProps = {
    leftLabel: "Available",
    rightLabel: "Selected",
    sortable: true,
    searchable: true,
    moveLeftIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold" }}>{"<"}</span>
    ),
    moveAllLeftIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold" }}>{"<<"}</span>
    ),
    moveRightIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold" }}>{">"}</span>
    ),
    moveAllRightIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold" }}>{">>"}</span>
    ),
    moveUpIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
        &#xffea;
      </span>
    ),
    moveTopIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
        &#x21c8;
      </span>
    ),
    moveDownIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
        &#xffec;
      </span>
    ),
    moveBottomIcon: (
      <span style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>
        &#x21ca;
      </span>
    ),
    leftSearchPlaceholder: "Search available options",
    rightSearchPlaceholder: "Search selected options",
  };

  state = {
    searchStringLeft: "",
    searchStringRight: "",
    leftSelected: [],
    rightSelected: [],
  };

  onMoveLeft = () => {
    const { selected, onMove } = this.props;
    const selectedOptions = selected.filter(
      (op) => !this.state.rightSelected.includes(op)
    );
    onMove(selectedOptions);
    this.setState({ rightSelected: [] });
  };

  onMoveAllLeft = () => {
    const { onMove } = this.props;
    onMove([]);
    this.setState({ rightSelected: [] });
  };

  onMoveRight = () => {
    const { selected, onMove } = this.props;
    const selectedOptions = [...selected, ...this.state.leftSelected];
    onMove(selectedOptions);
    this.setState({ leftSelected: [] });
  };

  onMoveAllRight = () => {
    const { available, onMove } = this.props;
    const selectedOptions = available.map((op) => op.value);
    onMove(selectedOptions);
    this.setState({ leftSelected: [] });
  };

  onMoveUp = () => {
    const { selected, onMove } = this.props;
    const newSelected = selected;
    const currentIndex = selected.indexOf(this.state.rightSelected[0]);
    newSelected.splice(currentIndex, 1);
    newSelected.splice(currentIndex - 1, 0, this.state.rightSelected[0]);
    onMove(newSelected);
  };

  onMoveAllUp = () => {
    const { selected, onMove } = this.props;
    const newSelected = selected;
    const currentIndex = selected.indexOf(this.state.rightSelected[0]);
    newSelected.splice(currentIndex, 1);
    newSelected.splice(0, 0, this.state.rightSelected[0]);
    onMove(newSelected);
  };

  onMoveDown = () => {
    const { selected, onMove } = this.props;
    const newSelected = selected;
    const currentIndex = selected.indexOf(this.state.rightSelected[0]);
    newSelected.splice(currentIndex, 1);
    newSelected.splice(currentIndex + 1, 0, this.state.rightSelected[0]);
    onMove(newSelected);
  };

  onMoveAllDown = () => {
    const { selected, onMove } = this.props;
    const newSelected = selected;
    const currentIndex = selected.indexOf(this.state.rightSelected[0]);
    newSelected.splice(currentIndex, 1);
    newSelected.splice(newSelected.length, 0, this.state.rightSelected[0]);
    onMove(newSelected);
  };
  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a, b) {
    return a.filter((value) => {
      return b.indexOf(value) !== -1;
    });
  }

  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleToggleLeft(value) {
    const currentIndex = this.state.leftSelected.indexOf(value);
    const newChecked = [...this.state.leftSelected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      leftSelected: newChecked,
    });
  }

  handleToggleRight(value) {
    const currentIndex = this.state.rightSelected.indexOf(value);
    const newChecked = [...this.state.rightSelected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      rightSelected: newChecked,
    });
  }

  handleToggleAllLeft(items, e) {
    const values = [];
    for (let i = 0, l = items.length; i < l; i++) {
      if (e.target.checked) {
        values.push(items[i].value);
      } else {
        values.pop(items[i].value);
      }
    }
    this.setState({
      leftSelected: values,
    });
  }
  handleToggleAllRight(items, e) {
    const values = [];
    for (let i = 0, l = items.length; i < l; i++) {
      if (e.target.checked) {
        values.push(items[i].value);
      } else {
        values.pop(items[i]);
      }
    }
    this.setState({
      rightSelected: values,
    });
  }

  onSelectInLeft = (event) => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    if (this.props.onSelectInLeft) this.props.onSelectInLeft(values);
    this.setState({ leftSelected: values });
  };

  onSelectInRight = (event) => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    if (this.props.onSelectInRight) this.props.onSelectInRight(values);
    this.setState({ rightSelected: values });
  };

  visibleLeftOptions = () => {
    const { available, selected } = this.props;
    const searchString = this.state.searchStringLeft;
    return available.filter(op => {
      if (
        searchString &&
        !op.label
          .toLowerCase()
          .includes(searchString.toLowerCase())
      ) {
        return false;
      } else if (selected.includes(op.value)) {
        return false;
      }
      return true;
    });
  }

  visibleRightOptions = () => {
    const { available, selected } = this.props;
    let selectedOptions = [];
    selected.forEach((selection) => {
      if (
        !this.state.searchStringRight ||
        (this.state.searchStringRight &&
          selection
            .toLowerCase()
            .includes(this.state.searchStringRight.toLowerCase()))
      ) {
        selectedOptions.push(available.find((av) => av.value === selection));
      }
    });
    return selectedOptions;
  }

  renderLeftList = () => {
    const { available, selected } = this.props;
    const visibleOptions = this.visibleLeftOptions();
    return (
      <>
        <Card>
          <Grid container>
            <Grid item xs={2}>
              <Checkbox
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                onClick={(e) => this.handleToggleAllLeft(visibleOptions, e)}
                checked={
                  this.state.leftSelected.length === visibleOptions.length &&
                  available.length !== 0
                }
              />
            </Grid>
            <Grid item xs={5} style={{marginTop:"auto", transform:"translateY(-50%)"}}>
              {visibleOptions.length + " Items           "}
            </Grid>
            <Grid item xs={5}>

            </Grid>
          </Grid>

          <FormControl fullWidth variant="filled">
            <TextField
              label="Search"
              id="outlined-size-small"
              defaultValue=""
              variant="outlined"
              size="small"
              width="200%"
              onChange={this.onLeftSearch}
            />
          </FormControl>
          <Divider />
          <Paper style={{ maxHeight: 200 }}>
            <List>
              <div
                style={{
                  maxHeight: "200px",
                  minHeight: "200px",
                  minWidth: "200px",
                  overflow: "auto",
                }}
                role="list"
              >
                {visibleOptions.map((op) => {
                  return (
                    <div
                      key={op.value}
                      role="listitem"
                      onClick={() => this.handleToggleLeft(op.value)}
                    >
                      <Checkbox
                        checked={
                          this.state.leftSelected.indexOf(op.value) !== -1
                        }
                        tabIndex={-1}
                      />
                      {op.label}
                    </div>
                  );
                })}
              </div>
            </List>
          </Paper>
        </Card>
      </>
    );
  };

  renderRightList = () => {
    const { available, selected } = this.props;
    const selectedOptions = this.visibleRightOptions();
    return (
      <>

        <Card>
          <Grid container>
            <Grid item xs={2}>
              <Checkbox
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                onClick={(e) => this.handleToggleAllRight(selectedOptions, e)}
                checked={
                  this.state.rightSelected.length === selectedOptions.length &&
                  selected.length !== 0
                }
              />
            </Grid>

            <Grid item xs={5} style={{ marginTop: "auto", transform: "translateY(-50%)" }}>
              {selectedOptions.length + " Selected          "}
            </Grid>
            <Grid item xs={5}>

            </Grid>
          </Grid>

          <FormControl fullWidth variant="filled">
            <TextField
              label="Search"
              id="outlined-size-small"
              defaultValue=""
              variant="outlined"
              size="small"
              onChange={this.onRightSearch}
            />
          </FormControl>
          <Divider />
          <Paper style={{ maxHeight: 200 }}>
            <List>
              <div
                style={{
                  maxHeight: "200px",
                  minHeight: "200px",
                  minWidth: "200px",
                  overflow: "auto",
                }}
                role="list"
              >
                {selectedOptions.map((op) => {

                  if(op != undefined)
                    { return (

                    <div
                      key={op.value}
                      role="listitem"
                      onClick={() => this.handleToggleRight(op.value)}
                    >
                      <Checkbox
                        checked={
                          this.state.rightSelected.indexOf(op.value) !== -1
                        }
                        tabIndex={-1}
                      />
                      {op.label}
                    </div>
                    );}
                })}
              </div>
            </List>
          </Paper>
        </Card>
      </>
    );
  };

  onLeftSearch = (event) => {
    this.setState({ searchStringLeft: event.target.value });
  };

  onRightSearch = (event) => {
    this.setState({ searchStringRight: event.target.value });
  };

  render() {
    const {
      leftLabel,
      rightLabel,
      sortable,
      searchable,
      moveLeftIcon,
      moveAllLeftIcon,
      moveRightIcon,
      moveAllRightIcon,
      moveUpIcon,
      moveTopIcon,
      moveDownIcon,
      moveBottomIcon,
      available,
      selected,
      leftSearchPlaceholder,
      rightSearchPlaceholder,
      classes,
    } = this.props;

    if (available.length < 1) return <div></div>;
    return (
      <>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={5}>
              <Paper className={classes.paper}> {this.renderLeftList()}</Paper>
            </Grid>
            <Grid item xs={1} style={{ padding: "100px 10px" }}>
              <ButtonGroup orientation="vertical">
                <Button onClick={this.onMoveAllRight}>{moveAllRightIcon}</Button>
                <Button onClick={this.onMoveRight}>{moveRightIcon}</Button>
                <Button onClick={this.onMoveLeft}>{moveLeftIcon}</Button>
                <Button onClick={this.onMoveAllLeft}>{moveAllLeftIcon}</Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={5}>
              <Paper className={classes.paper}> {this.renderRightList()}</Paper>
            </Grid>

            <Grid item xs={1} style={{ padding: "100px 10px" }}>
              <ButtonGroup orientation="vertical">
                <Button
                  className="btnmove"
                  disabled={this.state.rightSelected.length !== 1}
                  onClick={this.onMoveAllUp}
                >
                  {moveTopIcon}
                </Button>
                <Button
                  className="btnmove"
                  disabled={this.state.rightSelected.length !== 1}
                  onClick={this.onMoveUp}
                >
                  {moveUpIcon}
                </Button>
                <Button
                  className="btnmove"
                  disabled={this.state.rightSelected.length !== 1}
                  onClick={this.onMoveDown}
                >
                  {moveDownIcon}
                </Button>
                <Button
                  className="btnmove"
                  disabled={this.state.rightSelected.length !== 1}
                  onClick={this.onMoveAllDown}
                >
                  {moveBottomIcon}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default OptionList;
