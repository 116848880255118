import React from "react";

import qs from "qs";

import { NavLink } from "react-router-dom";
import axios from "~/variables/axios.jsx";
import { basePath } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import FastRewind from "@material-ui/icons/FastRewind";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";

import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class EditLaneSplit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			mounted: false,
			loading: true,
			user: null,
			body: null,
			company: null,
			splitId: null,
			laneId: null,
			calcVal: null,
			minProfit: null,
			passthru: null,
			origin: null,
			destination: null,
			origins: [],
			destinations: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.saveLaneSplit = this.saveLaneSplit.bind(this);
	}
	async componentDidMount() {

		const splitId = this.props.match.params.splitId;
		const company = this.props.match.params.company;
		const laneId = (this.props.match.params.laneId > 0) ? this.props.match.params.laneId : "";

		this.setState({
			mounted: true,
			splitId: splitId,
			company: company,
		});

		if(laneId > 0) {

			try {

				const response = await axios.get(
					"/index.php?p=api&r=json&c=admin&m=companyLaneSplits&d=" + company.toString() + "/" + splitId.toString() + "/" + laneId.toString()
				);

				const data = response.data;

				if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.body.lanes)) {

					const lane = data.body.lanes[0];

					this.props.handleMessages(response);

					this.setState({
						show: true,
						loading: false,
						company: company,
						splitId: splitId,
						laneId: laneId,
						calcVal: lane.calcVal,
						minProfit: lane.minProfit,
						passthru: lane.passthru,
						origin: lane.origin,
						destination: lane.destination,
					});

				} else {

					this.setState({ loading: false });
					this.props.setNotification(
						"Unexpected response loading the split data!", {variant: "error"}
					);

				}

			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the split data!", {
					variant: "error"
				});
			}
		}
		this.props.pageTitle('Edit Lane Split');
	}
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async saveLaneSplit() {

		this.setState({
			loading: true
		});

		const { laneId, splitId, calcVal, minProfit, passthru, origin, destination } = this.state;

		var laneSplitData = { splitId, calcVal, minProfit, passthru, origin, destination };
		laneSplitData.id = laneId;

		let url = "/index.php?p=api&r=json&c=admin&m=saveCompanyLaneSplit";

		try {
			const response = await axios.post(
				url,
				qs.stringify(laneSplitData)
			);

			if (!_.isEmpty(response.data)) {

				this.props.handleMessages(response);

				if (!_.isEmpty(response.data.body)) {

					this.props.handleMessages(response);

					this.setState({
						loading: false,
						laneId: response.data.body.result.laneId
					});

					this.props.setNotification(
						"Successfully saved lane split!", { variant: "success" }
					);

				} else {

					this.setState({ loading: false });

					this.props.setNotification(
						"There was an unexpected response saving the lane split: no response body", { variant: "error" }
					);

				}

			} else {

				this.setState({ loading: false });

				this.props.setNotification(
					"There was an unexpected response saving the lane split: no response data", { variant: "error" }
				);

			}

		} catch (error) {

			console.error(error);

			this.setState({ loading: false });

			this.props.setNotification(
				"There was an error saving the lane split!", { variant: "error" }
			);

		}

	}

	render() {

		const { classes } = this.props;

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
					<NavLink to={basePath + "/admin/company-" + this.state.company + "/lane-splits/" + this.state.splitId}>
						<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
							<FastRewind /> Back to Lane Splits
						</Button>
					</NavLink>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs>
									<InputLabel htmlFor="calcVal">
										Calc Val
									</InputLabel>
									<br/>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "calcVal",
											name: "calcVal",
											value: this.state.calcVal || "",
											onChange: this.handleInput("calcVal")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="minProfit">
										Min Profit Margin
									</InputLabel>
									<br/>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "minProfit",
											name: "minProfit",
											value: this.state.minProfit || "",
											onChange: this.handleInput("minProfit")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="passthru">
										Passthru
									</InputLabel>
									<br/>
									<FormControl fullWidth className={classes.selectFormControl}>
										<Select
											MenuProps={{
												className: classes.selectMenu
											}}
											classes={{
												select: classes.select
											}}
											inputProps={{
												name: "passthru",
												id: "passthru",
												value: this.state.passthru || "rated",
												onChange: this.handleInput("passthru")
											}}
										>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
												}}
												value="rated"
											>
												Rated
											</MenuItem>
											<MenuItem
												classes={{
													root: classes.selectMenuItem,
												}}
												value="benchmark"
											>
												Benchmark
											</MenuItem>
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="origin">
										Origin
									</InputLabel>
									<br/>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "origin",
											name: "origin",
											value: this.state.origin || "",
											onChange: this.handleInput("origin")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<InputLabel htmlFor="destination">
										Destination
									</InputLabel>
									<br/>
									<CustomInput
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											type: "text",
											id: "destination",
											name: "destination",
											value: this.state.destination || "",
											onChange: this.handleInput("destination")
										}}
										white
									/>
								</GridItem>
								<GridItem xs>
									<Button
										color="linkedin"
										onClick={this.saveLaneSplit}
									>
										Save
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(style)(
	withSnackbar(EditLaneSplit)
);
