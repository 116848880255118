import React from "react";
import Datetime from "react-datetime";

import qs from "qs";

import axios from "~/variables/axios.jsx";
import { NavLink, Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath } from "~/variables/server.jsx";
import _ from "lodash";
import moment from "moment";
import { getCarrierScacs } from "../../ParcelServices/actions/ParcelServices.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// material ui icons
import Check from "@material-ui/icons/Check";
import FastRewind from "@material-ui/icons/FastRewind";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import WarehouseSelector from "~/components/TMS/WarehouseSelector.jsx";
import WithTooltip from "~/components/TMS/WithTooltip.jsx";

// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class NewCompanyCarrier extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			company: null,
			show: false,
			mounted: false,
			loading: true,
			alert: null,
			redirect: null,
			user: null,
			body: null,
			scac_master_id: "",
			scac: "",
			username: "",
			password: "",
			bill_to_account: "",
			edi_account_reference: "",
			license_key: "",
			meter_number: "",
			hub_id: "",
			p44_account_group_code: "",
			p44_shared_tracking_account_code: "",
			warehouse_ids: [],
			p44_api_pickup: false,
			p44_api_tracking: false,
			p44_api_images: false,
			test: false,
			new_markup: false,
			percent: "",
			start_date: "",
			end_date: "",
			require_user: false,
			require_pass: false,
			require_account: false,
			require_edi: false,
			require_license: false,
			isParcel: false,
		};

		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getCarriers = this.getCarriers.bind(this);
		this.getScac = this.getScac.bind(this);
		this.saveAccount = this.saveAccount.bind(this);
	}

	async componentDidMount() {
		this.setState({
			mounted: true,
			company: this.props.match.params.company
		});
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=companyCarriers&m=newAccount&d=" + this.props.match.params.company);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body) && !_.isEmpty(data.user)) {
				this.props.pageTitle('New Company Carrier - ' + data.body.company[0].name);
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					body: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error loading the data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}


	handleModalOpen(modal) {
		this.setState({ [modal]: true });
	}

	handleModalClose(modal) {
		this.setState({ [modal]: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
		if (event.target.name === "scac_master_id") {
			this.getScac(event.target.value);
		}
	}

	handleDatetime(name, moment) {
		this.setState({ [name]: moment });
	}

	getCarriers(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={prop.id}
				>
					({prop.scac}) - {prop.carrier}
				</MenuItem>
			);
		});
	}

	handleWarehouse(warehouses) {
		this.setState({warehouse_ids: warehouses});
	}

	async saveAccount() {
		if (!this.state.scac_master_id || _.isEmpty(this.state.scac_master_id)) {
			this.props.setNotification("You must choose a carrier!", { variant: "error" });
			return;
		}

		if (_.isEmpty(_.compact(this.state.warehouse_ids))) {
			this.props.setNotification("You must choose at least one warehouse!", { variant: "error" });
			return;
		}

		const data = {
			scac_master_id: this.state.scac_master_id,
			user: this.state.username,
			pass: this.state.password,
			bill_to_account: this.state.bill_to_account,
			edi_account_reference: this.state.edi_account_reference,
			license_key: this.state.license_key,
			meter_number: this.state.meter_number,
			hub_id: this.state.hub_id,
			p44_account_group_code: this.state.p44_account_group_code,
			p44_shared_tracking_account_code: this.state.p44_shared_tracking_account_code,
			p44_api_pickup: this.state.p44_api_pickup ? 1 : 0,
			p44_api_tracking: this.state.p44_api_tracking ? 1 : 0,
			p44_api_images: this.state.p44_api_images ? 1 : 0,
			warehouse_ids: this.state.warehouse_ids,
			parcel_api: this.state.parcel_api,
			parcel_credentials_option: this.state.parcel_api == 'REST' ? this.state.parcel_credentials_option : 'default',
		};

		if (this.state.test) {
			data.test = 1;
		}

		if (this.state.new_markup) {
			data.percent = this.state.percent;
			data.start_date = !_.isEmpty(this.state.start_date) ? moment(this.state.start_date).format("MM/DD/YYYY") : "";
			data.end_date = !_.isEmpty(this.state.end_date) ? moment(this.state.end_date).format("MM/DD/YYYY") : "";
		}

		try {
			const response = await axios.post("/index.php?p=api&r=json&c=companyCarriers&m=newAccount&d=" + this.state.company, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			} else if (typeof response.data === "string") {
				this.props.setNotification("There was an error saving the account!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error saving the account!", {
				variant: "error"
			});
		}
	}

	async getScac(scac) {
		try {
			const response = await axios.get("/index.php?p=api&r=json&c=admin&m=getScac&d=" + scac);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.messsage)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.body)) {
				this.setState({
					require_user: data.body.scac.account_require_username == 1,
					require_pass: data.body.scac.account_require_password == 1,
					require_account: data.body.scac.account_require_account_number == 1,
					require_edi: data.body.scac.account_require_edi_reference_number == 1,
					require_license: data.body.scac.account_require_license_key == 1,
					isParcel: !_.isEmpty(data.body.scac.parcel_class_name) && data.body.scac.use_parcel == 1,
					scac: data.body.scac.scac || "",
				});
			} else {
				this.props.setNotification("There was an error loading the SCAC!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error loading the SCAC!", {
				variant: "error"
			});
		}
	}

	render() {
		const { classes } = this.props;
		if (this.state.redirect) {
			return <Redirect to={basePath + "/admin/company/" + this.state.company} />;
		}
		return (
			<GridContainer>
				{this.state.alert}
				{!_.isEmpty(this.state.company) && (
					<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
						<NavLink to={basePath + "/admin/company/" + this.state.company}>
							<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
								<FastRewind /> Back to Company
							</Button>
						</NavLink>
					</GridItem>
				)}
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Carrier</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select + " " + classes.requiredSelect
													}}
													value={this.state.scac_master_id || ""}
													inputProps={{
														id: "scac_master_id",
														name: "scac_master_id"
													}}
													onChange={this.handleChange}
												>
													{!_.isEmpty(this.state.body) && !_.isEmpty(this.state.body.carriers) && this.getCarriers(this.state.body.carriers)}
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Username</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "username",
													value: this.state.username || "",
													onChange: this.handleInput("username")
												}}
												white
												required={this.state.require_user}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Password</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "password",
													value: this.state.password || "",
													onChange: this.handleInput("password")
												}}
												white
												required={this.state.require_pass}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Account #</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "bill_to_account",
													value: this.state.bill_to_account || "",
													onChange: this.handleInput("bill_to_account")
												}}
												white
												required={this.state.require_account}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>EDI Company Account Reference #</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "edi_account_reference",
													value: this.state.edi_account_reference || "",
													onChange: this.handleInput("edi_account_reference")
												}}
												white
												required={this.state.require_edi}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>License Key/Client ID</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "license_key",
													value: this.state.license_key || "",
													onChange: this.handleInput("license_key")
												}}
												white
												required={this.state.require_license}
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Meter Number/Client Secret</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "zip",
													value: this.state.meter_number || "",
													onChange: this.handleInput("meter_number")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Hub ID</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "hub_id",
													value: this.state.hub_id || "",
													onChange: this.handleInput("hub_id")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Project44 Account Group Code</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "p44_account_group_code",
													value: this.state.p44_account_group_code || "",
													onChange: this.handleInput("p44_account_group_code")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<InputLabel className={classes.label}>Project44 Shared Tracking Account Code</InputLabel>
											<br />
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "text",
													name: "p44_shared_tracking_account_code",
													value: this.state.p44_shared_tracking_account_code || "",
													onChange: this.handleInput("p44_shared_tracking_account_code")
												}}
												white
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.p44_api_pickup}
														onChange={this.handleChecked("p44_api_pickup")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Project44 Pickup"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.p44_api_tracking}
														onChange={this.handleChecked("p44_api_tracking")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Project44 Tracking"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.p44_api_images}
														onChange={this.handleChecked("p44_api_images")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Enable Project44 Images"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<FormControlLabel
												control={
													<Checkbox
														tabIndex={-1}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot
														}}
														checked={this.state.test}
														onChange={this.handleChecked("test")}
													/>
												}
												classes={{
													label: classes.label
												}}
												label="Test Account (Parcel Only)"
											/>
										</GridItem>
									</GridContainer>
									<GridContainer style={{paddingBottom: 20}}>
										{this.state.isParcel && (
										<GridItem xs={12} sm={6} md={4} lg={3}>
											<WithTooltip
												content="We are migrating from SOAP (old) to REST (new) APIs for Fedex and UPS parcel. Credentials defined here correspond to the API type. If the parcel carrier is using the New API methods, the Client ID and Secret should be added to the License Key and Meter Number fields respectively"
											>
												<FormLabel id="parcel-api-label">Parcel API</FormLabel>
											</WithTooltip>
											<RadioGroup row
												aria-labelledby="parcel-api-label"
												name="parcel_api"
												value={this.state.parcel_api}
												onChange={this.handleChange}
											>
												<FormControlLabel value="REST" control={<Radio />} label="New" />
												<FormControlLabel value="SOAP" control={<Radio />} label="Old" />
											</RadioGroup>
										</GridItem>
										)}
										{this.state.isParcel && this.state.scac == getCarrierScacs()['FEDEX'] && this.state.parcel_api == "REST" && (
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<WithTooltip
													content="FedEx Parcel requires a different set of credentials to accesses the REST (new) tracking API. Use this option to define which API the provided Client ID and Secret are for."
												>
													<FormLabel id="parcel-options-label">Credentials Options</FormLabel>
												</WithTooltip>
												<RadioGroup row
													aria-labelledby="parcel-options-label"
													name="parcel_credentials_option"
													value={this.state.parcel_credentials_option}
													onChange={this.handleChange}
												>
													<FormControlLabel value="default" control={<Radio />} label="Ship/Rate" />
													<FormControlLabel value="tracking" control={<Radio />} label="Tracking" />
												</RadioGroup>
											</GridItem>
										)}
									</GridContainer>
								</GridItem>
								{this.state.company != null && (
									<GridItem xs={12}>
										<WarehouseSelector
											mode={'list'}
											company={this.state.company}
											selectedWarehouses={this.state.warehouse_ids}
											onChange={(warehouses) => {this.handleWarehouse(warehouses)}}
										/>
									</GridItem>
								)}
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<h5>
										Parcel Mark Up
										{this.state.new_markup ? (
											<Button size="sm" color="warning" style={{ marginLeft: "20px" }} onClick={() => this.setState({ new_markup: false })}>
												Cancel
											</Button>
										) : (
											<Button size="sm" color="linkedin" style={{ marginLeft: "20px" }} onClick={() => this.setState({ new_markup: true })}>
												New Markup
											</Button>
										)}
									</h5>
									{this.state.new_markup && (
										<GridContainer>
											<GridItem xs={12} sm={6} md={4} lg={4}>
												<InputLabel className={classes.label}>Percent</InputLabel>
												<br />
												<CustomInput
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "text",
														name: "percent",
														value: this.state.percent || "",
														onChange: this.handleInput("percent"),
														endAdornment: <InputAdornment position="end">%</InputAdornment>
													}}
													white
												/>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={4}>
												<InputLabel className={classes.label}>Start Date</InputLabel>
												<br />
												<FormControl fullWidth>
													<Datetime
														timeFormat={false}
														value={this.state.start_date || ""}
														onChange={m => this.handleDatetime("start_date", m)}
														className={classes.datetime}
														inputProps={{
															id: "start_date",
															name: "start_date",
															placeholder: "Select Start Date"
														}}
													/>
												</FormControl>
											</GridItem>
											<GridItem xs={12} sm={6} md={4} lg={4}>
												<InputLabel className={classes.label}>End Date</InputLabel>
												<br />
												<FormControl fullWidth>
													<Datetime
														timeFormat={false}
														value={this.state.end_date || ""}
														onChange={m => this.handleDatetime("end_date", m)}
														className={classes.datetime}
														inputProps={{
															id: "end_date",
															name: "end_date",
															placeholder: "Select End Date"
														}}
													/>
												</FormControl>
											</GridItem>
										</GridContainer>
									)}
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									<Button color="info" style={{ marginRight: "5px" }} onClick={this.saveAccount}>
										Save Account
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(newVolumeQuoteStyle)(withSnackbar(NewCompanyCarrier));