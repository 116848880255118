import React from "react";
import qs from "qs";
import axios from "~/variables/axios.jsx";
import { apiUrl } from "~/variables/server.jsx";
import { withSnackbar } from "notistack";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// material ui icons
import Check from "@material-ui/icons/Check";
import Info from "@material-ui/icons/Info";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import SnackbarContent from "~/components/Snackbar/SnackbarContent.jsx";
// import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUps, faFedex } from "@fortawesome/free-brands-svg-icons";

import trackShipmentStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";

class TrackShipment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			show: false,
			loading: true,
			user: null,
			response: null,
			company: "",
			warehouse: "",
			pro: "",
			tracking_number: "",
			notify_email: "",
			all_updates: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getHistory = this.getHistory.bind(this);
		this.monitorShipment = this.monitorShipment.bind(this);
	}

	async componentDidMount() {
		let pro = "";
		if (!_.isEmpty(this.props.match.params.pro)) {
			pro = this.props.match.params.pro;
		} else {
			this.setState({ mounted: true });
			this.props.setNotification("No PRO provided!", { variant: "error" });
			return;
		}
		this.setState({
			mounted: true,
			tracking_number: pro,
			pro
		});
		const url = "/index.php?p=api&r=json&c=tracking&m=publicTrackPRO&d=" + pro;
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string" && response.data.body) {
				const { data } = response;
				this.setState({
					show: true,
					loading: false,
					user: data.user,
					response: data.body
				});
			} else {
				this.setState({ loading: false });
				this.props.setNotification("No tracking information, check the message stack.", {
					variant: "warning"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("There was an error loading the data!", {
				variant: "error"
			});
		}
	}


	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	};

	getImages(data) {
		return data.map((prop, key) => {
			return (
				<li>
					<a href={prop.url}>{prop.desc}</a>
				</li>
			);
		});
	}

	getHistory(data) {
		const { classes } = this.props;
		return data.map((prop, key) => {
			return (
				<GridItem xs={12} key={key}>
					<Paper className={classes.paper} elevation={1}>
						<GridContainer>
							<GridItem xs>
								<label>
									<strong>Status Code</strong>
								</label>
								<div>{prop.status_code}</div>
							</GridItem>
							<GridItem xs>
								<label>
									<strong>Status Message</strong>
								</label>
								<div>{prop.status_message}</div>
							</GridItem>
							<GridItem xs>
								<label>
									<strong>Location</strong>
								</label>
								<div>{prop.location}</div>
							</GridItem>
							<GridItem xs>
								<label>
									<strong>Date</strong>
								</label>
								<div>{prop.date + " " + prop.time}</div>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<label>
									<strong>Comments</strong>
								</label>
								<div>{!_.isEmpty(prop.comments) ? prop.comments : "None"}</div>
							</GridItem>
						</GridContainer>
					</Paper>
				</GridItem>
			);
		});
	}

	async monitorShipment() {
		if (this.state.user.level === "admin" && _.isEmpty(this.state.company)) {
			this.props.setNotification("You need to choose a company!", { variant: "error" });
			return false;
		}
		const data = {
			notifyEmail: this.state.notify_email,
			allUpdates: this.state.all_updates,
			companyId: this.state.company
		};
		const url = "/index.php?p=api&r=json&c=tracking&m=monitor&d=" + this.state.tracking_number;
		try {
			const response = await axios.post(url, qs.stringify(data));
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({ done: true });
			} else {
				this.props.setNotification("There was an error monitoring the shipment!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error monitoring the shipment!", { variant: "error" });
		}
	}

	render() {
		const { classes } = this.props;
		const searchButton = classes.top + " " + classes.searchButton;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									{!_.isEmpty(this.state.response) ? (
										<div>
											{!_.isEmpty(this.state.response.carrier) && this.state.response.carrier === "UPGF" && (
												<GridContainer>
													<GridItem xs={12} sm={12} md={12}>
														<FontAwesomeIcon icon={faUps} size="6x" />
													</GridItem>
												</GridContainer>
											)}
											{!_.isEmpty(this.state.response.carrier) && this.state.response.carrier === "FXNL" && (
												<GridContainer>
													<GridItem xs={12} sm={12} md={12}>
														<FontAwesomeIcon icon={faFedex} size="6x" />
													</GridItem>
												</GridContainer>
											)}
											{_.isEmpty(this.state.response) && (
												<GridContainer>
													<GridItem xs={12}>
														<Paper className={classes.warningPaper} elevation={1}>
															<strong>Warning!</strong> No tracking information, check the message stack.
														</Paper>
													</GridItem>
												</GridContainer>
											)}
											{!_.isEmpty(this.state.response) && this.state.response.no_tracking_data && (
												<GridContainer>
													<GridItem xs />
													<GridItem xs={10} sm={8} md={6}>
														<SnackbarContent message="This shipment has not been entered into the carrier system yet. Tracking will be available when this happens." icon={Info} color="info" />
													</GridItem>
													<GridItem xs />
												</GridContainer>
											)}
											{!_.isEmpty(this.state.response) && !this.state.response.no_tracking_data ? (
												<GridContainer>
													{!this.state.response["PUBLIC"] && (
														<GridItem xs={12}>
															<GridContainer>
																{this.state.user.level === "admin" && (
																	<GridItem xs>
																		<InputLabel className={classes.label}>Company</InputLabel>
											                            <br />
																		<CustomInput
																			formControlProps={{ fullWidth: true }}
																			inputProps={{
																				type: "text",
																				name: "company",
																				value: this.state.company || "",
																				onChange: this.handleInput("company")
																			}}
																			helpText="Required"
																			required
																		/>
																	</GridItem>
																)}
																<GridItem xs>
																	<InputLabel className={classes.label}>Monitor Email</InputLabel>
										                            <br />
																	<CustomInput
																		formControlProps={{
																			fullWidth: true
																		}}
																		inputProps={{
																			type: "text",
																			name: "notify_email",
																			value: this.state.notify_email,
																			onChange: this.handleInput("notify_email")
																		}}
																		helpText="Optional"
																		white
																	/>
																</GridItem>
																<GridItem xs>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={-1}
																				checkedIcon={<Check className={classes.checkedIcon} />}
																				icon={<Check className={classes.uncheckedIcon} />}
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot
																				}}
																				checked={this.state.all_updates}
																				onChange={this.handleChecked("all_updates")}
																			/>
																		}
																		classes={{
																			label: classes.label
																		}}
																		label="Send All Notifications"
																	/>
																</GridItem>
																<GridItem xs>
																	<Button color="linkedin" onClick={this.monitorShipment}>
																		Monitor Shipment
																	</Button>
																</GridItem>
															</GridContainer>
														</GridItem>
													)}
													{!_.isEmpty(this.state.response.pro_number) && (
														<GridItem xs={12} sm={12} md={12}>
															<GridContainer>
																<GridItem xs={12}>
																	<Paper className={classes.infoPaper} elevation={1}>
																		<strong>Public Tracking Link:</strong> <a href={apiUrl + "/admin/public-track-pro/" + this.state.response.tracking_number}>{apiUrl + "/admin/public-track-pro/" + this.state.response.tracking_number}</a>
																	</Paper>
																</GridItem>
															</GridContainer>
														</GridItem>
													)}
													<GridItem xs={12}>
														<Paper className={classes.paper} elevation={1}>
															<GridContainer>
																<GridItem xs>
																	<label>
																		<strong>Empire TMS Tracking Code</strong>
																	</label>
																	<div>{this.state.response.tracking_number}</div>
																	<label>
																		<strong>PRO No.</strong>
																	</label>
																	<div>{this.state.response.pro_number}</div>
																	<label>
																		<strong>BOL No.</strong>
																	</label>
																	<div>{this.state.response.bol_number}</div>
																	<label>
																		<strong>PO No.</strong>
																	</label>
																	<div>{this.state.response.po_number}</div>
																</GridItem>
																<GridItem xs>
																	<label>
																		<strong>Shipper</strong>
																	</label>
																	{!_.isEmpty(this.state.response.shipped_by) ? (
																		<address>
																			{this.state.response.shipped_by.name && (
																				<div>
																					<strong>{this.state.response.shipped_by.name}</strong>
																				</div>
																			)}
																			{this.state.response.shipped_by.address1 && <div>{this.state.response.shipped_by.address1}</div>}
																			{this.state.response.shipped_by.address2 && <div>{this.state.response.shipped_by.address2}</div>}
																			{this.state.response.shipped_by.address3 && <div>{this.state.response.shipped_by.address3}</div>}
																			{this.state.response.shipped_by.city && this.state.response.shipped_by.state && <div>{this.state.response.shipped_by.city + ", " + this.state.response.shipped_by.state}</div>}
																			{this.state.response.shipped_by.zip && <div>{this.state.response.shipped_by.zip}</div>}
																		</address>
																	) : (
																		<div>No Shipper Data</div>
																	)}
																</GridItem>
																<GridItem xs>
																	<label>
																		<strong>Consignee</strong>
																	</label>
																	{!_.isEmpty(this.state.response.received_by) ? (
																		<address>
																			{this.state.response.received_by.name && (
																				<div>
																					<strong>{this.state.response.received_by.name}</strong>
																				</div>
																			)}
																			{this.state.response.received_by.address1 && <div>{this.state.response.received_by.address1}</div>}
																			{this.state.response.received_by.address2 && <div>{this.state.response.received_by.address2}</div>}
																			{this.state.response.received_by.address3 && <div>{this.state.response.received_by.address3}</div>}
																			{this.state.response.received_by.city && this.state.response.received_by.state && <div>{this.state.response.received_by.city + ", " + this.state.response.received_by.state}</div>}
																			{this.state.response.received_by.zip && <div>{this.state.response.received_by.zip}</div>}
																		</address>
																	) : (
																		<div>No Consignee Data</div>
																	)}
																</GridItem>
																<GridItem xs>
																	<label>
																		<strong>Carrier SCAC</strong>
																	</label>
																	<br />
																	{this.state.response.carrier}
																	<br />
																	<label>
																		<strong>Origin Service Center</strong>
																	</label>
																	<br />
																	{!_.isEmpty(this.state.response.origin_service_center) ? (
																		<div>
																			{this.state.response.origin_service_center.link && <p>{this.state.response.origin_service_center.name ? <a href={this.state.response.origin_service_center.link}>{this.state.response.origin_service_center.name}</a> : <span>{this.state.response.origin_service_center.name}</span>}</p>}
																			{!this.state.response.origin_service_center.link && this.state.response.origin_service_center.name && <p>{this.state.response.origin_service_center.name}</p>}
																		</div>
																	) : (
																		<div>Unknown</div>
																	)}
																	<label>
																		<strong>Destination Service Center</strong>
																	</label>
																	<br />
																	{!_.isEmpty(this.state.response.destination_service_center) ? (
																		<div>
																			{this.state.response.destination_service_center.link && <p>{this.state.response.destination_service_center.name ? <a href={this.state.response.destination_service_center.link}>{this.state.response.destination_service_center.name}</a> : <span>{this.state.response.destination_service_center.name}</span>}</p>}
																			{!this.state.response.destination_service_center.link && this.state.response.destination_service_center.name && <p>{this.state.response.destination_service_center.name}</p>}
																		</div>
																	) : (
																		<div>Unknown</div>
																	)}
																</GridItem>
															</GridContainer>
															<GridContainer>
																<GridItem xs>
																	<label>
																		<strong>Pickup Date</strong>
																	</label>
																	<div>{this.state.response.pickup_date}</div>
																</GridItem>
																<GridItem xs>
																	<label>
																		<strong>Estimated Delivery Date</strong>
																	</label>
																	{this.state.response.estimated_delivery_date ? <div>{this.state.response.estimated_delivery_date}</div> : <div>Unknown</div>}
																	<label>
																		<strong>Delivery Date</strong>
																	</label>
																	{this.state.response.delivery_date && this.state.response.delivery_date.date && this.state.response.delivery_date.time ? <div>{this.state.response.delivery_date.date + " " + this.state.response.delivery_date.time}</div> : <div>Unknown</div>}
																</GridItem>
																<GridItem xs>
																	<label>
																		<strong>Delivery Status</strong>
																	</label>
																	{this.state.response.delivery_status ? (
																		<div>
																			{!_.isEmpty(this.state.response.delivery_status.code) && this.state.response.delivery_status.code + " "}
																			{this.state.response.delivery_status.message}
																		</div>
																	) : (
																		<div>Unknown</div>
																	)}
																	<label>
																		<strong>Delivery Signature</strong>
																	</label>
																	{this.state.response.delivery_signature ? <div>{this.state.response.delivery_signature}</div> : <div>Unknown</div>}
																</GridItem>
																<GridItem xs>
																	<label>
																		<strong>Shipment Weight</strong>
																	</label>
																	{this.state.response.shipment_weight ? <div>{this.state.response.shipment_weight}</div> : <div>Unknown</div>}
																	<label>
																		<strong>Shipment Pieces</strong>
																	</label>
																	{this.state.response.shipment_pieces ? <div>{this.state.response.shipment_pieces}</div> : <div>Unknown</div>}
																</GridItem>
															</GridContainer>
															{!_.isEmpty(this.state.response.image) && (
																<GridContainer>
																	<GridItem xs>
																		<h5>Available Images</h5>
																		<ul>{this.getImages(this.state.response.image)}</ul>
																	</GridItem>
																</GridContainer>
															)}
														</Paper>
													</GridItem>
													<GridItem xs={12}>
														<h5>Movement History</h5>
														{!_.isEmpty(this.state.response.history) ? (
															<GridContainer>{this.getHistory(this.state.response.history)}</GridContainer>
														) : (
															<GridContainer>
																<GridItem xs={12}>
																	<Paper className={classes.warningPaper} elevation={1}>
																		<strong>No Movement History:</strong> Either there is not yet any movement history or the carrier has not made this available.
																	</Paper>
																</GridItem>
															</GridContainer>
														)}
													</GridItem>
												</GridContainer>
											) : (
												<GridContainer>
													<GridItem xs={12}>
														<Paper className={classes.warningPaper} elevation={1}>
															<div>
																<strong>PRO Not Found!</strong>
																<br />
																Some carriers have a check digit at the end of their PRO number. This is often sepparated by a hyphen (-) or a blank space. In most cases, entering this number will have no effect, however some carriers do not provide tracking information when this digit is provided. Try typing the PRO again without the last digit, and try again. If
																that does not work please <a href="https://targetfmi.com/contact-us/">contact us</a>.
															</div>
														</Paper>
													</GridItem>
												</GridContainer>
											)}
											{this.state.response.carrier && this.state.response.carrier === "UPGF" && (
												<GridContainer>
													<GridItem xs={12} sm={12} md={12}>
														<Typography variant="caption" gutterBottom>
															UPS, the UPS brand mark, and the Color Brown are trademarks of United Parcel Service of America, Inc. All Rights Reserved.
														</Typography>
													</GridItem>
												</GridContainer>
											)}
										</div>
									) : (
										<Spinner loading={this.state.loading} message="Failed to retrieve tracking response from the server" />
									)}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(trackShipmentStyle)(withSnackbar(TrackShipment));
