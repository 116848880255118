import React from "react";

import Datetime from "react-datetime";
import moment, { tz } from "moment";
import "moment-timezone";
import { withSnackbar } from "notistack";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { handleMessages, setNotification } from '~/redux/actions/notifications.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    TimePicker,
} from "@material-ui/pickers";
// style for this view
import newVolumeQuoteStyle from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { Grid, RadioGroup, Radio, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },
});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

class ReportScheduler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            validation: false,
            showEndDate: props.reportSchedule && props.reportSchedule.schedule == "Never" ? false : true,
            showTime: props.reportSchedule && props.reportSchedule.schedule == "Hourly" ? false : true,

            reportSchedule: props.reportSchedule
                ? props.reportSchedule
                : {
                    name: "",
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                    recipients: [""],
                    schedule: "",
                    reportId: "",
                    format: "",
                    scheduleId: "",
                    time: moment(),
                    timeFormat: moment().format("HH:00"),
                },
        };
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.reportSchedule, this.props.reportSchedule)) {
            this.setState({
                reportSchedule: this.props.reportSchedule,
            });
        } else if (!_.isEqual(prevState, this.state)) {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this.state.reportSchedule);
            }
        }
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            reportSchedule: {
                ...prevState.reportSchedule,
                [name]: value
            }
        }));
    }

    handleReoccurenceChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            reportSchedule: {
                ...prevState.reportSchedule,
                [name]: value,
            },
            showEndDate: value == "Never" ? false : true,
            showTime: value == "Hourly" ? false : true,
        }));
    }

    handleDatetime = (momentt) => {
        if (!moment.isMoment(momentt)) {
            return false;
        }

        this.setState(prevState => ({
            reportSchedule: {
                ...prevState.reportSchedule,
                time: momentt,
                timeFormat: momentt.format('HH:00')
            }
        }));
    }

    handleDate = (name, date) => {
        if (!moment.isMoment(date)) {
            return false;
        }

        this.setState(prevState => ({
            reportSchedule: {
                ...prevState.reportSchedule,
                [name]: date.format('YYYY-MM-DD')
            }
        }));
    }

    handleEmailChange = (key, event) => {
        let duplicate = false;
        let recipients = [...this.state.reportSchedule.recipients];
        const { value } = event.target;

        recipients[key] = value;

        recipients = recipients.map((email, index) => {
            email = email.trim();

            if (index !== key && email != "" && email === recipients[key]) {
                duplicate = true;
            } else {
                return email;
            }
        });

        if (duplicate) {
            this.props.setNotification("Duplicate recipient email!", { variant: "warning" });
            this.setState({ validation: true });
        } else {
            this.setState(prevState => ({
                reportSchedule: {
                    ...prevState.reportSchedule,
                    recipients: recipients
                }
            }));
        }
    }

    addRecipient = () => {
        let rec = [...this.state.reportSchedule.recipients];
        rec.push("");

        this.setState(prevState => ({
            reportSchedule: {
                ...prevState.reportSchedule,
                recipients: rec
            }
        }));
    }

    removeRecipient = (key) => {
        let rec = [...this.state.reportSchedule.recipients];
        rec.splice(key, 1);

        this.setState(prevState => ({
            reportSchedule: {
                ...prevState.reportSchedule,
                recipients: rec
            }
        }));
    }

    isValidEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    renderInput(props) {
        return (
            <TextField {...props} />
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs>
                    <ValidatorForm onSubmit={() => {}}>
                        <GridContainer>
                            <GridItem xs style={{ paddingTop: "10px" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }} className={classes.title}>
                                    Schedule
                                </p>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={3}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                    <InputLabel id="reoccurring" className={classes.label}>
                                        Reoccurring
                                    </InputLabel>

                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                        }}
                                        classes={{
                                            select: classes.select,
                                        }}
                                        value={this.state.reportSchedule.schedule}
                                        labelId="reoccurring"
                                        inputProps={{
                                            id: "schedule",
                                            name: "schedule",
                                        }}
                                        onChange={(e) => this.handleReoccurenceChange(e)}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Never"
                                        >
                                            Never
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Hourly"
                                        >
                                            Hourly
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Daily"
                                        >
                                            Daily
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Weekly"
                                        >
                                            Weekly
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Monthly"
                                        >
                                            Monthly
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Yearly"
                                        >
                                            Yearly
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem xs={3}>
                                <Datetime
                                    timeFormat="YYYY-MM-DD"
                                    value={this.state.reportSchedule.startDate}
                                    onChange={(m) => this.handleDate("startDate", m)}
                                    className={classes.startDate}
                                    renderInput={this.renderInput}
                                    inputProps={{
                                        name: "startDate",
                                        placeholder: "YYYY-MM-DD",
                                        label: "Start Date",
                                        style: { width: "100%" },
                                    }}
                                />
                            </GridItem>

                            {this.state.showEndDate ? (
                                <GridItem xs={3}>
                                    <Datetime
                                        timeFormat="YYYY-MM-DD"
                                        value={this.state.reportSchedule.endDate}
                                        onChange={(m) => this.handleDate("endDate", m)}
                                        className={classes.endDate}
                                        renderInput={this.renderInput}
                                        inputProps={{
                                            name: "endDate",
                                            placeholder: "YYYY-MM-DD",
                                            label: "End Date",
                                            style: { width: "100%" },
                                        }}
                                    />
                                </GridItem>
                            ) : null}

                            {this.state.showTime ? (
                                <GridItem xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <TimePicker
                                            id="time-picker"
                                            label="Time"
                                            ampm={true}
                                            value={this.state.reportSchedule.time}
                                            format={"HH:00"}
                                            views={["hours"]}
                                            style={{ width: "100%" }}
                                            onChange={this.handleDatetime}
                                        />
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                            ) : null}
                        </GridContainer>
                        <br/>
                        <GridContainer>
                            <GridItem xs={6}>
                                <GridContainer>
                                    <GridItem xs style={{ paddingTop: "10px" }}>
                                        <p style={{ fontSize: "15px", fontWeight: "bold" }} className={classes.title}>
                                            Recipients
                                        </p>
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={6}>
                                        {this.state.reportSchedule.recipients.map((recipient, index) => (
                                            <GridContainer key={index}>
                                                <GridItem xs>
                                                    <TextValidator
                                                        placeholder="Enter email address"
                                                        onChange={(e) =>
                                                            this.handleEmailChange(index, e)
                                                        }
                                                        name="email"
                                                        type="email"
                                                        value={recipient || ""}
                                                        validators={["isEmail"]}
                                                        errorMessages={["email is not valid"]}
                                                        style={{
                                                            width: "100%",
                                                            paddingBottom: "5px",
                                                        }}
                                                    />
                                                </GridItem>
                                                <IconButton
                                                    onClick={() => this.removeRecipient(index)}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </GridContainer>
                                        ))}

                                        <GridContainer justifyContent="flex-end" style={{ paddingTop: "10px" }}>
                                            <GridItem>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    onClick={this.addRecipient}
                                                >
                                                    Add More
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={6}>
                                <GridContainer>
                                    <GridItem xs>
                                        <p
                                            style={{
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                paddingTop: "10px",
                                            }}
                                            className={classes.title}
                                        >
                                            Export Format
                                        </p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem>
                                        <RadioGroup row name="format" onChange={(e) => this.handleChange(e)}>
                                            <FormControlLabel
                                                checked={this.state.reportSchedule.format === "xls"}
                                                value="xls"
                                                control={<StyledRadio />}
                                                label="XLS"
                                            />
                                            <FormControlLabel
                                                checked={this.state.reportSchedule.format === "csv"}
                                                value="csv"
                                                control={<StyledRadio />}
                                                label="CSV"
                                                style={{ paddingLeft: "50px" }}
                                            />
                                        </RadioGroup>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </ValidatorForm>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleMessages,
        setNotification,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(newVolumeQuoteStyle)(withSnackbar(ReportScheduler)));