
const initialState = {
    search_by: "so",
    search_terms: "",
    reference_type: "",
    refTypes: [],
};

export default function(state = initialState, action) {
    const newState = state;
	switch(action.type){
        case "UPDATE_SEARCH_BY":
            return {...newState, search_by: action.val};
        case "UPDATE_SEARCH_TERMS":
            return {...newState, search_terms: action.val};
        case "UPDATE_REFERENCE_TYPE":
            return {...newState, reference_type: action.val};
        case "UPDATE_REFERENCE_TYPES":
            return {...newState, refTypes: action.val};
        default: 
            return state
	}
}