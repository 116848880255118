import React, { Component } from "react";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";

import axios from "~/variables/axios.jsx";
import { NavLink, Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import { basePath, baseUrl, apiUrl } from "~/variables/server.jsx";
import _ from "lodash";
import moment from "moment";
import "formdata-polyfill";
import { connect } from "react-redux";
import { clearNotifications } from "~/redux/actions/notifications.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Info from "@material-ui/icons/InfoOutlined";

// material ui icons
import Check from "@material-ui/icons/Check";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Schedule from "@material-ui/icons/Schedule";
import FastRewind from "@material-ui/icons/FastRewind";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Warning from "@material-ui/icons/Warning";

// react-icons
import { FaWarehouse, FaAddressBook, FaFax, FaAsterisk } from "react-icons/fa";

// core components
import GridContainer from "~/components/Grid/GridContainer.jsx";
import GridItem from "~/components/Grid/GridItem.jsx";
import CustomInput from "~/components/CustomInput/CustomInput.jsx";
import Button from "~/components/CustomButtons/Button.jsx";
import Card from "~/components/Card/Card.jsx";
import CardBody from "~/components/Card/CardBody.jsx";
import Table from "~/components/Table/Table.jsx";
import Spinner from "~/components/TMS/Spinner.jsx";
import Badge from "~/components/Badge/Badge.jsx";
import Dropzone from "~/components/TMS/Dropzone.jsx";
import Preview from "~/components/TMS/Preview.jsx";
import InputList from "~/components/TMS/InputList.jsx";
import CustomDropdown from "~/components/CustomDropdown/CustomDropdown.jsx";
import WithTooltip from '~/components/TMS/WithTooltip.jsx';
import InputTag from "~/components/TMS/InputTag.jsx";

// react component for creating dynamic tables
import ReactTable from "react-table-6";

// style for this view
import style from "~/assets/jss/empire-tms/views/tms/ltlQuoteStyle.jsx";
import { Input } from "@material-ui/core";

class EditWarehouse extends Component {
	state = {
		id: null,
		company: null,
		active: "warehouse",
		alert: null,
		redirect: null,
		show: false,
		mounted: false,
		loading: true,
		user: null,
		body: null,
		name: "",
		logo: "",
		code: "",
		ctsi_code: "",
		broker_id: "",
		hide_tl_carriers: false,
		warehouse_ftl_scacs: [],
		tfm_scacs: [],
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		override_max_weight: "",
		override_enable_auto_class: "",
		override_require_po: "inherit",
		override_require_so: "inherit",
		override_require_reference: "inherit",
		override_ref_entry_type: "inherit",
		override_custom_piece_count: "inherit",
		ref_entry_opts: [],
		cust_ref_entry_type: "freeform",
		cust_ref_entry_opts: [],
		default_direction_terms: "",
		bol_pdf_hide_measurements: false,
		bol_pdf_template: "bolTemplateDefault",
		separate_prods_hus: false,
		fuzzy_product_code: false,
		enable_fedex_parcel_shipping: false,
		enable_ups_parcel_shipping: false,
		enable_usps_parcel_shipping: false,
		hazmat_api_test: false,
		hazmat_dev_id: "",
		hazmat_app_id: "",
		hazmat_token: "",
		rater_cutoff_date: "",
		created: "",
		created_by: "",
		edited: "",
		edited_by: "",
		users: null,
		locations: null,
		bol_pdf_templates: null,
		require_pod_for_billing: false,
		require_packing_group: true,
		require_acc_recommendations: false,
		enable_product_part_no: false,
		prevent_exclusion_execution: false,
		isEdit: false,
		referenceTypes: [],
		customReferenceOptions: [],
		referenceSettings: [],
		po_number: "",
		so_number: "",
		ref_number: "",

	};

	async componentDidMount() {
		this.setState({ mounted: true });
		let url = "";
		let isEdit = true;
		let companyId = '';
		if(!_.isEmpty(this.props.match.params.id) && this.props.match.params.id > 0) {
			this.setState({ id: this.props.match.params.id, isEdit: true });
			url = "/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.props.match.params.id;
			companyId = this.props.match.params.id;
		} else {
			isEdit = false;
			this.setState({ company: this.props.match.params.company, isEdit: false });
			url = "/index.php?p=api&r=json&c=admin&m=newWarehouse&d=" + this.props.match.params.company + "/company";
			companyId = this.props.match.params.company;
		}
		try {
			let companyNameId = "";
			const response = await axios.get(url);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user) && !_.isEmpty(data.body)) {
				if(isEdit) {
					this.props.pageTitle('Edit Warehouse - ' + data.body.warehouse.name);
					const ref_entry_opts = data.body.warehouse.ref_entry_opts.map(opt => {
						return opt.value;
					});
					companyNameId = data.body.warehouse.company_name_id;
					this.setState({
						show: true,
						loading: false,
						user: data.user,
						body: data.body,
						company: data.body.warehouse.company_name_id,
						name: data.body.warehouse.name,
						logo: data.body.warehouse.logo_file,
						code: data.body.warehouse.code,
						ctsi_code: data.body.warehouse.ctsi_code,
						broker_id: data.body.warehouse.broker_id,
						warehouse_ftl_scacs: data.body.warehouse.ftl_scacs,
						tfm_scacs: data.body.tfm_scacs,
						address1: data.body.warehouse.address1,
						address2: data.body.warehouse.address2,
						city: data.body.warehouse.city,
						state: data.body.warehouse.state,
						zip: data.body.warehouse.zip,
						po_number: data.body.warehouse.po_number,
						so_number: data.body.warehouse.so_number,
						ref_number: data.body.warehouse.ref_number,
						hide_tl_carriers: data.body.warehouse.company_hide_tl_carriers == 1,
						override_max_weight: data.body.warehouse.override_max_weight,
						override_enable_auto_class: data.body.warehouse.override_enable_auto_class,
						override_ref_entry_type: data.body.warehouse.override_ref_entry_type,
						ref_entry_opts: ref_entry_opts,
						override_allow_auto_pro: data.body.warehouse.override_allow_auto_pro,
						override_require_reference: data.body.warehouse.override_require_reference,
						override_require_po: data.body.warehouse.override_require_po,
						override_require_so: data.body.warehouse.override_require_so,
						override_custom_piece_count: data.body.warehouse.override_custom_piece_count,
						default_direction_terms: data.body.warehouse.default_direction_terms,
						bol_pdf_hide_measurements: data.body.warehouse.bol_pdf_hide_measurements == 1,
						bol_pdf_template: data.body.warehouse.bol_pdf_template,
						enable_fedex_parcel_shipping: data.body.warehouse.enable_fedex_parcel_shipping == 1,
						enable_ups_parcel_shipping: data.body.warehouse.enable_ups_parcel_shipping == 1,
						enable_usps_parcel_shipping: data.body.warehouse.enable_usps_parcel_shipping == 1,
						hazmat_api_test: data.body.warehouse.hazmat_api_test == 1,
						hazmat_dev_id: data.body.warehouse.hazmat_dev_id,
						hazmat_app_id: data.body.warehouse.hazmat_app_id,
						hazmat_token: data.body.warehouse.hazmat_token,
						separate_prods_hus: data.body.warehouse.separate_prods_hus == 1,
						fuzzy_product_code: data.body.warehouse.fuzzy_product_code == 1,
						rater: data.body.warehouse.rater,
						rater_cutoff_date: data.body.warehouse.rater_cutoff_date,
						created: data.body.warehouse.created,
						created_by: data.body.warehouse.create_by,
						edited: data.body.warehouse.edited,
						edited_by: data.body.warehouse.edit_by,
						users: _.sortBy(data.body.users, user => parseInt(user.id, 10)),
						locations: data.body.locations,
						bol_pdf_templates: data.body.bol_pdf_templates,
						require_pod_for_billing: data.body.warehouse.require_pod_for_billing == 1 ? true : false,
						require_packing_group: data.body.warehouse.require_packing_group == 1 ? true : false,
						require_acc_recommendations: data.body.warehouse.require_acc_recommendations == 1 ? true : false,
						enable_product_part_no: data.body.warehouse.enable_product_part_no == 1 ? true : false,
						prevent_exclusion_execution: data.body.warehouse.prevent_exclusion_execution == 1 ? true : false,
						standardize_us_ltl_pickup_adr: data.body.warehouse.standardize_us_ltl_pickup_adr == 1 ? true : false,
						override_dgd_addl_handling_instructions: data.body.warehouse.override_dgd_addl_handling_instructions == 1 ? true : false,
						dgd_addl_handling_instructions: data.body.warehouse.dgd_addl_handling_instructions,
						referenceSettings: !Array.isArray(data.body.warehouse.default_custom_references) ? [] : data.body.warehouse.default_custom_references.map(setting =>{
							return {
								value: setting.name,
								default_value: setting.default_value,
								required: +setting.required,
								display_on_bol: +setting.display_on_bol,
								cust_ref_entry_type: setting.cust_ref_entry_type,
								cust_ref_entry_opts: setting.options,
							};
						}),
					});
				} else {
					companyNameId = data.body[0].company_name_id;
					this.props.pageTitle('New Warehouse - ' + data.body[0].name);
					this.setState({
						show: true,
						loading: false,
						user: data.user,
						body: data.body,
						bol_pdf_templates: data.body.bol_pdf_templates
					});
				}

				const refTypesResponse = await axios.get("/index.php?p=api&r=json&c=admin&m=getReferenceTypes&companyId=" + companyNameId);
				if (refTypesResponse && refTypesResponse.data && refTypesResponse.data.body &&  Array.isArray(refTypesResponse.data.body.types)) {
					this.setState({referenceTypes: [...refTypesResponse.data.body.types]});
				}
			} else {
				this.setState({ loading: false });
				this.props.setNotification("Unable to load the warehouse data!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("Unexpected error while loading the data", {
				variant: "error"
			});
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false });
	}

	handleChecked = name => event => {
		this.setState({ [name]: !!event.target.checked });
	}

	handleInput = name => event => {
		this.setState({ [name]: event.target.value });
	}

	handleSelect = event => {
		if(event.target.name === 'override_ref_entry_type') {
			const override_ref_entry_type = event.target.value;
			const ref_entry_opts = (override_ref_entry_type === 'select') ? [...this.state.ref_entry_opts] : [];
			this.setState({ override_ref_entry_type, ref_entry_opts });
		} else {
			this.setState({ [event.target.name]: event.target.value });
		}
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleDatetime = (name, moment) => {
		this.setState({ [name]: moment });
	}

	handleUpload = (files) => {
		const logo = files[0];
		this.setState({ logo });
	}

	handleFtlScacAssignment = (event, values) => {
		this.setState({ warehouse_ftl_scacs: values });
	}

	saveWarehouse = async () => {
		this.setState({
			show: true,
			loading: true
		});

		let url = this.state.isEdit
			? "/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.state.id
			: "/index.php?p=api&r=json&c=admin&m=newWarehouse&d=" + this.state.company + "/company";

		const data = new FormData();

		data.append("name", this.state.name);
		data.append("code", this.state.code);
		data.append("ctsi_code", this.state.ctsi_code);
		data.append("broker_id", this.state.broker_id);
		data.append("address1", this.state.address1);
		data.append("address2", this.state.address2);
		data.append("city", this.state.city);
		data.append("state", this.state.state);
		data.append("zip", this.state.zip);
		data.append("override_max_weight", this.state.override_max_weight);
		data.append("override_enable_auto_class", this.state.override_enable_auto_class);
		data.append("override_allow_auto_pro", this.state.override_allow_auto_pro);
		data.append("override_require_reference", this.state.override_require_reference);
		data.append("override_require_po", this.state.override_require_po);
		data.append("override_require_so", this.state.override_require_so);
		data.append("override_custom_piece_count", this.state.override_custom_piece_count);
		data.append("default_direction_terms", this.state.default_direction_terms);
		data.append("bol_pdf_template", this.state.bol_pdf_template);
		data.append("rater", this.state.rater);
		data.append("override_ref_entry_type", this.state.override_ref_entry_type);
		data.append("ref_entry_opts", this.state.ref_entry_opts);
		data.append("hazmat_dev_id", this.state.hazmat_dev_id);
		data.append("hazmat_app_id", this.state.hazmat_app_id);
		data.append("hazmat_token", this.state.hazmat_token);
		data.append("po_number", this.state.po_number);
		data.append("so_number", this.state.so_number);
		data.append("ref_number", this.state.ref_number);

		if(!_.isEmpty(this.state.rater_cutoff_date)) {
			data.append("rater_cutoff_date", moment(this.state.rater_cutoff_date).format('YYYY-MM-DD'));
		} else {
			data.append("rater_cutoff_date", "");
		}
		if (this.state.bol_pdf_hide_measurements) {
			data.append("bol_pdf_hide_measurements", "1");
		}
		if (this.state.enable_fedex_parcel_shipping) {
			data.append("enable_fedex_parcel_shipping", "1");
		}
		if (this.state.enable_ups_parcel_shipping) {
			data.append("enable_ups_parcel_shipping", "1");
		}
		if (this.state.enable_usps_parcel_shipping) {
			data.append("enable_usps_parcel_shipping", "1");
		}
		if (this.state.hazmat_api_test) {
			data.append("hazmat_api_test", "1");
		} else {
			data.append("hazmat_api_test", "0");
		}
		if (this.state.separate_prods_hus) {
			data.append("separate_prods_hus", "1");
		}
		if (this.state.fuzzy_product_code) {
			data.append("fuzzy_product_code", "1");
		}
		data.append("logo", this.state.logo);

		let requirePod = this.state.require_pod_for_billing ? 1 : 0;
		data.append('require_pod_for_billing', requirePod);

		let requirePackingGroup = this.state.require_packing_group ? 1 : 0;
		data.append('require_packing_group', requirePackingGroup);

		let enableProductPartNo = this.state.enable_product_part_no ? 1 : 0;
		data.append('enable_product_part_no', enableProductPartNo);

		let preventExclusionExecution = this.state.prevent_exclusion_execution ? 1 : 0;
		data.append('prevent_exclusion_execution', preventExclusionExecution);

		let standardizeUsLtlPickupAdr = this.state.standardize_us_ltl_pickup_adr ? 1 : 0;
		data.append('standardize_us_ltl_pickup_adr', standardizeUsLtlPickupAdr);

		let overrideDgdAddlHandlingInstructions = this.state.override_dgd_addl_handling_instructions ? 1 : 0;
		data.append('override_dgd_addl_handling_instructions', overrideDgdAddlHandlingInstructions);

		let requireShiplify = this.state.require_acc_recommendations ? 1 : 0;
		data.append('require_acc_recommendations', requireShiplify);

		data.append('dgd_addl_handling_instructions', this.state.dgd_addl_handling_instructions);

		data.append('warehouse_ftl_scacs', this.state.warehouse_ftl_scacs);

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		try {
			const response = await axios.post(url, data, config);
			let savedRefs = false;
			//save reference settings
			if (this.state.isEdit) {
				if (response && response.data && response.data.body && response.data.body.warehouse && response.data.body.warehouse.warehouse_id) {
					savedRefs = await this.saveWarehouseCustomReferenceSettings(response.data.body.warehouse.warehouse_id);
				}
			} else {
				if (response && response.data && response.data.createdWarehouseId) {
					savedRefs = await this.saveWarehouseCustomReferenceSettings(response.data.createdWarehouseId);
				}
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}

			if (typeof response.data !== "string" && !_.isEmpty(response.data.body) && savedRefs) {
				const redirect = <Redirect to={basePath + "/admin/company/" + this.state.company} />;
				this.setState({ redirect });
			} else {
				this.setState({ loading: false });
				this.props.setNotification("There was an error saving the warehouse!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
			this.props.setNotification("An exception occurred while saving the warehouse!", {
				variant: "error"
			});
		}
	}

	saveWarehouseCustomReferenceSettings = async (warehouseId) => {
		const url =  "/index.php?p=api&r=json&c=admin&m=saveWarehouseCustomReferenceSettings";
		let validOpts;
		try {
			this.state.referenceSettings.forEach(setting => {
				if(setting.cust_ref_entry_type == 'select') {
					if(setting.cust_ref_entry_opts.length == 0) {
						throw new Error("Options must be provided when Custom Reference is type 'Select Option'")
					}
					setting.cust_ref_entry_opts.forEach(option => {
						if(option.trim() == "") {
							throw new Error("Custom reference select options cannot be empty")
						}
					})
				}
			});
		} catch (err) {
			this.props.setNotification("Cannot save reference options: " + err, {variant: "error"})
			return false;
		}
		const payload = qs.stringify({
			warehouse_id: warehouseId,
			settings: this.state.referenceSettings.map(setting => {
				return {
					type: setting.value,
					default_value: setting.default_value,
					cust_ref_entry_type: setting.cust_ref_entry_type,
					cust_ref_entry_opts: (setting.cust_ref_entry_type === 'select') ? setting.cust_ref_entry_opts : [],
					required: setting.required ? 1 : 0,
					display_on_bol: setting.display_on_bol ? 1 : 0,
				};
			}),
		})
		const response = await axios.post(url, payload);

		if (response && response.data && response.data.body && response.data.body.success) {
			return true;
		}

		if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
			this.props.setNotification("Error saving Custom Shipment References", {
				variant: "error",
			});
			this.props.handleMessages(response);
		} else {
			this.props.setNotification("Unknown Error saving Custom Shipment References", {
				variant: "error",
			});
		}
	}

	getImage = (prop) => {
		const src = !_.isEmpty(prop.preview) ? prop.preview : apiUrl + "/getLogo.php?logo=" + prop;
		const filename = !_.isEmpty(prop.name) ? prop.name : prop;
		const extension = !_.isEmpty(filename) ? filename.match(/\.[0-9a-z]+$/i)[0].replace(".", "") : "";
		return <Preview src={src} index={0} extension={extension} handleClick={() => {}} handleDelete={this.handleDeleteLogoFile.bind(this)} />;
	}

	getUsers = (data) => {
		return data.map((prop, key) => {
			const link = basePath + "/admin/company-" + prop.company_name_id + "/user/edit/" + prop.id;
			const codes = Object.values(prop.codes).map((code, index) => <li key={index}>{code}</li>);
			return [
				prop.id,
				prop.username,
				<ul>{codes}</ul>,
				prop.rate_display,
				prop.key,
				<div className="actions-right">
					{prop.active == 1 ? (
						<Button size="sm" color="success" style={{ marginRight: "4px" }} onClick={() => this.disableUser(key)}>
							Active
						</Button>
					) : (
						<Button size="sm" color="black" style={{ marginRight: "4px" }} onClick={() => this.enableUser(key)}>
							Disabled
						</Button>
					)}
					<NavLink to={link}>
						<Button size="sm" color="info" style={{ marginRight: "4px" }}>
							View/Edit
						</Button>
					</NavLink>
					<Button size="sm" color="warning" style={{ marginRight: "4px" }} onClick={() => this.switchUser(prop.id)}>
						Switch User
					</Button>
					{_.isEmpty(prop.key) ? (
						<Button size="sm" color="white" style={{ marginRight: "4px" }} onClick={() => this.grantApiKey(key)}>
							Grant API
						</Button>
					) : (
						<Button size="sm" color="black" style={{ marginRight: "4px" }} onClick={() => this.handleDisable(key)}>
							Disable API
						</Button>
					)}
				</div>
			];
		});
	}

	enableUser = async (i) => {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || users[i].active == 1) {
			return;
		}

		const url = "/index.php?p=api&r=json&c=admin&m=changeUserStatus&d=" + users[i].id + "/enable/user";

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				users[i].active = 1;
				this.setState({ users });
			} else {
				this.props.setNotification("There was an error changing the user status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error changing the user status!", { variant: "error" });
		}
	}

	disableUser = async (i) => {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || users[i].active != 1) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&m=changeUserStatus&d=" + users[i].id + "/disable/user";

		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				users[i].active = 0;
				this.setState({ users });
			} else {
				this.props.setNotification("There was an error changing the user status!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error changing the user status!", { variant: "error" });
		}
	}

	grantApiKey = async (i) => {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || !_.isEmpty(users[i].key)) {
			return;
		}
		const url = "/index.php?p=api&r=json&c=admin&m=grantAPIKey&d=" + users[i].id + "/" + this.state.company;
		this.setState({ show: false, loading: true });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const res = await axios.get("/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.state.id);
				if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
					this.props.handleMessages(res.data.message);
				}
				if (typeof res.data !== "string" && !_.isEmpty(res.data.body)) {
					this.setState({ show: true, loading: false, users: _.sortBy(res.data.body.users, user => parseInt(user.id, 10)) });
				} else {
					this.setState({ show: true, loading: false });
					this.props.setNotification("There was an error getting the warehouse users!", { variant: "error" });
				}
			} else {
				this.setState({ show: true, loading: false });
				this.props.setNotification("There was an error granting the user an API key!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ show: true, loading: false });
			this.props.setNotification("There was an error granting the user an API key!", { variant: "error" });
		}
	}

	handleDisable = (i) => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to disable this user's API Key?"
					onConfirm={() => this.disableApiKey(i)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					Their existing API key will be destroyed and cannot be recovered!
				</SweetAlert>
			)
		});
	}

	disableApiKey = async (i) => {
		const { users } = this.state;
		if (_.isEmpty(users[i]) || _.isEmpty(users[i].key)) {
			return;
		}

		const url = "/index.php?p=api&r=json&c=admin&confirm=1&m=disableAPIKey&d=" + users[i].id + "/" + this.state.company;
		this.setState({ show: false, loading: true, alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				const res = await axios.get("/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.state.id);
				if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
					this.props.handleMessages(res.data.message);
				}
				if (typeof res.data !== "string" && !_.isEmpty(res.data.body)) {
					this.setState({ show: true, loading: false, users: _.sortBy(res.data.body.users, user => parseInt(user.id, 10)) });
				} else {
					this.setState({ show: true, loading: false });
					this.props.setNotification("There was an error getting the warehouse users!", { variant: "error" });
				}
			} else {
				this.setState({ show: true, loading: false });
				this.props.setNotification("There was an error disabling the user's API key!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ show: true, loading: false });
			this.props.setNotification("There was an error disabling the user's API key!", { variant: "error" });
		}
	}

	switchUser = async (id) => {
		try {
			const response = await axios.get(`/index.php?p=api&r=json&c=user&m=takeAccount&d=${id}/true`);
			const data = response.data;
			if (typeof data !== "string" && !_.isEmpty(data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof data !== "string" && !_.isEmpty(data.user)) {
				window.location.href = basePath + "/admin";
				this.props.clearNotifications();
			} else {
				this.props.setNotification("There was an error assuming the user!", {
					variant: "error"
				});
			}
		} catch (error) {
			console.error(error);
			this.props.setNotification("There was an error assuming the user!", {
				variant: "error"
			});
		}
	}

	getLocations(data) {
		return data.map((prop, key) => {
			const link = basePath + "/admin/location/edit/" + prop.loc_id + "/" + this.state.id + "/warehouse";
			return {
				code: prop.code,
				name: prop.name,
				address: (
					<address>
						<div dangerouslySetInnerHTML={{ __html: prop.address1 }} />
						{!_.isEmpty(prop.address2) && <div dangerouslySetInnerHTML={{ __html: prop.address2 }} />}
						<div dangerouslySetInnerHTML={{ __html: prop.city + ", " + prop.state + " " + prop.zip }} />
					</address>
				),
				contact: (
					<div>
						{!_.isEmpty(prop.contact_name) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<Person />} label={prop.contact_name} />
							</div>
						)}
						{!_.isEmpty(prop.phone) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<Phone />} label={prop.phone} />
							</div>
						)}
						{!_.isEmpty(prop.email) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<AlternateEmail />} label={prop.email} />
							</div>
						)}
						{!_.isEmpty(prop.fax) && (
							<div style={{ marginBottom: "5px" }}>
								<Chip variant="outlined" size="small" icon={<FaFax />} label={prop.fax} />
							</div>
						)}
					</div>
				),
				type: prop.type,
				default: prop.default == 1 ? <FaAsterisk /> : "",
				actions: (
					<div className="actions-right">
						<NavLink to={link}>
							<Button size="sm" color="info" className={this.props.classes.marginRight}>
								View/Edit
							</Button>
						</NavLink>
						<Button size="sm" color="danger" onClick={() => this.handleDeleteAddress(prop.loc_id)}>
							Delete
						</Button>
					</div>
				)
			};
		});
	}

	getBolPdfTemplates = (templates) => {
		const { classes } = this.props;
		return templates.map((template, key) => {
			return (
				<MenuItem
					key={"template_sel_" + key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={template.className}
				>
					{template.name}
				</MenuItem>
			);
		});
	}

	handleDeleteAddress = (id) => {

		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title="Are you sure you want to delete this address?"
					onConfirm={() => this.deleteAddress(id)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes, delete it!"
					cancelBtnText="No!"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}

	deleteAddress = async (id) => {
		const url = "/index.php?p=api&r=json&c=admin&m=deleteLocation&d=" + id + "/warehouse/" + this.state.id;
		this.setState({ show: false, loading: true, alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				this.setState({ show:true, loading: false, locations: _.sortBy(response.data.body.locations, location => parseInt(location.id, 10)) });
			} else {
				this.setState({ show: true, loading: false });
				this.props.setNotification("There was an error deleting the address!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ show: true, loading: false });
			this.props.setNotification("An exception occurred while deleting the address!", { variant: "error" });
		}
	}

	export = async (type) => {
		let isParcel = false;
		if (["product", "location", "unit", "parcelProduct"].indexOf(type) !== -1) {
			if(type === 'parcelProduct') {
				isParcel = true;
				type = 'product';
			}
			const typeUrl = type === "unit" ? "handUnit" : type;
			let url = "/index.php?p=api&c=" + typeUrl + "&m=export&warehouse=" + this.state.id;
			if(isParcel) {
				url += '&d=1';
			}
			try {
				const response = await axios.get(url);
				if (typeof response.data === "string") {
					window.location = baseUrl + url;
				} else {
					this.props.handleMessages(response);
				}
			} catch (error) {
				console.error(error);
				this.props.setNotification("There was an error exporting the " + type + "s!", {
					variant: "error"
				});
			}
		}
	}

	handleDelete = (type) => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{
						display: "block",
						color: "black"
					}}
					title={"Are you sure you want to delete all " + type + "s?"}
					onConfirm={() => this.delete(type)}
					onCancel={() => this.setState({ alert: null })}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Yes"
					cancelBtnText="Cancel"
					showCancel
				>
					This action cannot be undone!
				</SweetAlert>
			)
		});
	}
	
	handleDeleteLogoFile = () => {
		this.setState({ logo: "" });
	}

	delete = async (type) => {
		if (["product", "location", "unit"].indexOf(type) === -1) {
			this.setState({ alert: null });
			return;
		}

		const url = "/index.php?p=api&r=json&c=admin&m=deleteWarehouse" + type.replace(/^\w/, c => c.toUpperCase()) + "s&d=" + this.state.id;

		this.setState({ show: false, loading: true, alert: null });
		try {
			const response = await axios.get(url);
			if (typeof response.data !== "string" && !_.isEmpty(response.data.message)) {
				this.props.handleMessages(response);
			}
			if (typeof response.data !== "string") {
				if (type === "location") {
					const res = await axios.get("/index.php?p=api&r=json&c=admin&m=editWarehouse&d=" + this.state.id);
					if (typeof res.data !== "string" && !_.isEmpty(res.data.message)) {
						this.props.handleMessages(res.data.message);
					}
					if (typeof res.data !== "string" && !_.isEmpty(res.data.body)) {
						this.setState({ show: true, loading: false, locations: _.sortBy(res.data.body.locations, location => parseInt(location.id, 10)) });
					} else {
						this.setState({ show: true, loading: false });
						this.props.setNotification("There was an error getting the warehouse locations!", { variant: "error" });
					}
				} else {
					this.setState({ show: true, loading: false });
				}
			} else {
				this.setState({ show: true, loading: false });
				this.props.setNotification("There was an error deleting the " + type + "s!", { variant: "error" });
			}
		} catch (error) {
			console.error(error);
			this.setState({ show: true, loading: false });
			this.props.setNotification("There was an error deleting the " + type + "s!", { variant: "error" });
		}
	}

	isValidReferenceSettingIndex = (index) => {
		return !isNaN(index) && index >= 0 && index < this.state.referenceSettings.length;
	}

	addReferenceSetting = e => {
		this.setState({
			referenceSettings: [
				...this.state.referenceSettings,
				{value: "", required: false},
			],
		});
	}

	onRemoveReferenceSetting = index => {
		if (this.isValidReferenceSettingIndex(index)) {
			let refs = this.state.referenceSettings;
			refs.splice(index, 1);
			this.setState({referenceSettings: [...refs]});
		}
	}

	onChangeReferenceSettingType = (index, value, type) => {
		if (this.isValidReferenceSettingIndex(index)) {
			let refs = this.state.referenceSettings;
			refs[index][type] = value;
			this.setState({referenceSettings: [...refs]});
		}
	}

	onChangeReferenceSettingCheckBox = (index, type) => {
		if (this.isValidReferenceSettingIndex(index)) {
			let refs = this.state.referenceSettings;
			refs[index][type] = !refs[index][type];
			this.setState({referenceSettings: [...refs]});
		}
	}

	render() {
		if (this.state.redirect) {
			return this.state.redirect;
		}
		const { classes } = this.props;
		return (
			<GridContainer>
				{this.state.alert}
				{this.state.isEdit && (
					<GridItem xs={12} sm={12} md={12}>
						{!_.isEmpty(this.state.company) && (
							<GridContainer style={{ margin: "10px 0px" }}>
								<GridItem xs={12} sm={12} md={6} className={classes.left}>
									<NavLink to={basePath + "/admin/company/" + this.state.company}>
										<Button size="lg" color="linkedin" style={{ marginRight: "4px" }}>
											<FastRewind /> Back to Company
										</Button>
									</NavLink>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<Paper className={classes.paper} elevation={1}>
										<Grid container>
											<Grid item xs={12} style={{ marginBottom: "5px" }}>
												<GridContainer style={{ marginBottom: "5px" }}>
													<GridItem xs={3}>
														<Chip variant="outlined" size="small" icon={<Schedule />} label="Created" />
													</GridItem>
													<GridItem xs={9}>{this.state.created}</GridItem>
												</GridContainer>
												<GridContainer style={{ marginBottom: "5px" }}>
													<GridItem xs={3}>
														<Chip variant="outlined" size="small" icon={<Person />} label="Created" />
													</GridItem>
													<GridItem xs={9}>{this.state.created_by}</GridItem>
												</GridContainer>
											</Grid>
											<Grid item xs={12}>
												<GridContainer style={{ marginBottom: "5px" }}>
													<GridItem xs={3}>
														<Chip variant="outlined" size="small" icon={<Schedule />} label="Updated" />
													</GridItem>
													<GridItem xs={9}>{this.state.edited}</GridItem>
												</GridContainer>
												<GridContainer style={{ marginBottom: "5px" }}>
													<GridItem xs={3}>
														<Chip variant="outlined" size="small" icon={<Person />} label="Updated" />
													</GridItem>
													<GridItem xs={9}>{this.state.edited_by}</GridItem>
												</GridContainer>
											</Grid>
										</Grid>
									</Paper>
								</GridItem>
							</GridContainer>
						)}
						{!_.isEmpty(this.state.company) && (
							<Grid container justify="center">
								<Grid item className={classes.center}>
									{this.state.active === "warehouse" ? (
										<Button size="lg" color="warning" className={classes.marginRight}>
											<FaWarehouse />
											Warehouse Info
										</Button>
									) : (
										<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "warehouse" })}>
											<FaWarehouse />
											Warehouse Info
										</Button>
									)}
									{this.state.active === "users" ? (
										<Button size="lg" color="warning" className={classes.marginRight}>
											<People />
											Assigned Users
										</Button>
									) : (
										<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "users" })}>
											<People />
											Assigned Users
										</Button>
									)}
									{this.state.active === "locations" ? (
										<Button size="lg" color="warning" className={classes.marginRight}>
											<FaAddressBook />
											Address Book
										</Button>
									) : (
										<Button size="lg" className={classes.marginRight} onClick={() => this.setState({ active: "locations" })}>
											<FaAddressBook />
											Address Book
										</Button>
									)}
								</Grid>
							</Grid>
						)}
					</GridItem>
				)}
				<GridItem xs={12} sm={12} md={12}>
					{this.state.show ? (
						<Card>
							<CardBody>
								{this.state.active === "warehouse" && (
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<GridContainer>
												<GridItem xs>
													<InputLabel className={classes.label}>Name</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "name",
															value: this.state.name || "",
															onChange: this.handleInput("name")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>Warehouse Code</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "code",
															value: this.state.code || "",
															onChange: this.handleInput("code")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>Rater Profile Code</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "ctsi_code",
															value: this.state.ctsi_code || "",
															onChange: this.handleInput("ctsi_code")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>TFMCentral Broker ID</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "broker_id",
															value: this.state.broker_id || "",
															onChange: this.handleInput("broker_id")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<InputLabel className={classes.label}>Override Max. Shipment Weight</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "override_max_weight",
															value: this.state.override_max_weight || "",
															onChange: this.handleInput("override_max_weight"),
															placeholder: "Defaults to the value set for the company."
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="override_enable_auto_class" className={classes.label}>
														Override Enable Auto Class
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.override_enable_auto_class || ""}
															inputProps={{
																id: "override_enable_auto_class",
																name: "override_enable_auto_class"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enable"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disable"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="override_allow_auto_pro" className={classes.label}>
														Override Enable Auto PRO
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.override_allow_auto_pro || ""}
															inputProps={{
																id: "override_allow_auto_pro",
																name: "override_allow_auto_pro"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enable"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disable"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<InputLabel htmlFor="override_require_po" className={classes.label}>
														Override Require PO
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.override_require_po || ""}
															inputProps={{
																id: "override_require_po",
																name: "override_require_po"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enable"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disable"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="override_require_so" className={classes.label}>
														Override Require SO
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.override_require_so || ""}
															inputProps={{
																id: "override_require_so",
																name: "override_require_so"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enable"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disable"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="override_require_reference" className={classes.label}>
														Override Require Reference #
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.override_require_reference || ""}
															inputProps={{
																id: "override_require_reference",
																name: "override_require_reference"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enable"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disable"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<InputLabel htmlFor="override_custom_piece_count" className={classes.label}>
														Override Custom Piece Count
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.override_custom_piece_count || ""}
															inputProps={{
																id: "override_custom_piece_count",
																name: "override_custom_piece_count"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="inherit"
															>
																Inherit
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="enable"
															>
																Enabled
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="disable"
															>
																Disabled
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs>
													<InputLabel htmlFor="default_direction_terms" className={classes.label}>
														Default Direction/Freight Term
													</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.default_direction_terms || ""}
															inputProps={{
																id: "default_direction_terms",
																name: "default_direction_terms"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value=""
															/>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Outbound/Prepaid"
															>
																Outbound/Prepaid
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Outbound/Collect"
															>
																Outbound/Collect
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Inbound/Collect"
															>
																Inbound/Collect
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="Third Party"
															>
																Third Party
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs>
													{(this.state.bol_pdf_template !== 'bolTemplateDefault') ? (
														<WithTooltip
															title="Important Notice!"
															content="This template is not compatible with any other BOL PDF configuration settings you may have applied."
															placement="bottom"
														>
															<InputLabel htmlFor="bol_pdf_template" className={classes.label}>
																BOL PDF Template
																<Warning style={{ height: "16px" }} />
															</InputLabel>
														</WithTooltip>
													) : (
														<InputLabel htmlFor="bol_pdf_template" className={classes.label}>
															BOL PDF Template
														</InputLabel>
													)}
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.bol_pdf_template || ""}
															inputProps={{
																id: "bol_pdf_template",
																name: "bol_pdf_template"
															}}
															onChange={this.handleChange}
														>
															{this.getBolPdfTemplates(this.state.bol_pdf_templates)}
														</Select>
													</FormControl>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<InputLabel className={classes.label}>Address 1</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "address1",
															value: this.state.address1 || "",
															onChange: this.handleInput("address1")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>Address 2</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "address2",
															value: this.state.address2 || "",
															onChange: this.handleInput("address2")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>Zip</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "zip",
															value: this.state.zip || "",
															onChange: this.handleInput("zip")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>City</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "city",
															value: this.state.city || "",
															onChange: this.handleInput("city")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>State</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "state",
															value: this.state.state || "",
															onChange: this.handleInput("state")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<InputLabel className={classes.label}>Rater</InputLabel>
													<br />
													<FormControl fullWidth className={classes.selectFormControl}>
														<Select
															MenuProps={{
																className: classes.selectMenu
															}}
															classes={{
																select: classes.select + " " + classes.requiredSelect
															}}
															value={this.state.rater || ""}
															inputProps={{
																id: "rater",
																name: "rater"
															}}
															onChange={this.handleChange}
														>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="tfmr"
															>
																TFMRater
															</MenuItem>
															<MenuItem
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected
																}}
																value="parallel"
															>
																Parallel Testing (TFMR/Sandbox)
															</MenuItem>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>Rater Cutoff Date</InputLabel>
													<br />
													<FormControl fullWidth>
														<Datetime
															value={this.state.rater_cutoff_date || ""}
															timeFormat={false}
															onChange={m => this.handleDatetime("rater_cutoff_date", m)}
															className={classes.datetime}
															inputProps={{
																id: "rater_cutoff_date",
																name: "rater_cutoff_date",
																placeholder: "Select Rater Cutoff Date"
															}}
														/>
													</FormControl>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											<InputLabel htmlFor="override_ref_entry_type" className={classes.label}>
												Reference # Entry Type
											</InputLabel>
											<br />
											<FormControl fullWidth className={classes.selectFormControl}>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={this.state.override_ref_entry_type}
													inputProps={{
														name: "override_ref_entry_type",
														id: "override_ref_entry_type"
													}}
													onChange={this.handleSelect}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="inherit"
													>
														Inherit
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="freeform"
													>
														Freeform Input
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value="select"
													>
														Select Option
													</MenuItem>
												</Select>
											</FormControl>
										</GridItem>
										<GridItem xs={12} sm={6} md={9}>
											{(this.state.override_ref_entry_type === 'select') ? (
												<div>
													<InputLabel className={classes.label}>
														Reference # Entry Options
													</InputLabel>
													<InputList
														values={this.state.ref_entry_opts}
														onChange={values => this.setState({ ref_entry_opts: values })}
													/>
												</div>
											): ''}
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<h5>BOL</h5>
											<GridContainer>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.separate_prods_hus}
																tabIndex={-1}
																onChange={this.handleChecked("separate_prods_hus")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="List Products and Units Separately"
													/>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.fuzzy_product_code}
																tabIndex={-1}
																onChange={this.handleChecked("fuzzy_product_code")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Allow Partial Matches on Product Code"
													/>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.bol_pdf_hide_measurements}
																tabIndex={-1}
																onChange={this.handleChecked("bol_pdf_hide_measurements")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Hide Individual Measurements on BOL"
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.require_pod_for_billing}
																tabIndex={-1}
																onChange={this.handleChecked("require_pod_for_billing")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Default Require POD for Billing"
													/>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.require_packing_group}
																tabIndex={-1}
																onChange={this.handleChecked("require_packing_group")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Require Packing Group"
													/>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.enable_product_part_no}
																tabIndex={-1}
																onChange={this.handleChecked("enable_product_part_no")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Enable Product Part No."
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													<WithTooltip
														title="Prevent Exclusion Execution"
														content="Prevent users from executing BOLs with a carrier that hit a linear foot exclusion or cubic capacity rule."
														placement="top"
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.state.prevent_exclusion_execution}
																	tabIndex={-1}
																	onChange={this.handleChecked("prevent_exclusion_execution")}
																	checkedIcon={<Check className={classes.checkedIcon} />}
																	icon={<Check className={classes.uncheckedIcon} />}
																	classes={{
																		checked: classes.checked,
																		root: classes.checkRoot
																	}}
																/>
															}
															classes={{
																label: classes.label
															}}
															label="Prevent Exclusion Execution"
														/>
													</WithTooltip>
												</GridItem>
												<GridItem xs={4}>
													<WithTooltip
														title="DGD Additional Handling Instructions"
														content="Overrides the additional handling instructions space on the dangerous goods declaration with the content of the text field below."
														placement="top"
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.state.override_dgd_addl_handling_instructions}
																	tabIndex={-1}
																	onChange={this.handleChecked("override_dgd_addl_handling_instructions")}
																	checkedIcon={<Check className={classes.checkedIcon} />}
																	icon={<Check className={classes.uncheckedIcon} />}
																	classes={{
																		checked: classes.checked,
																		root: classes.checkRoot
																	}}
																/>
															}
															classes={{
																label: classes.label
															}}
															label="DGD Additional Handling Instructions"
														/>
													</WithTooltip>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.require_acc_recommendations}
																tabIndex={-1}
																onChange={this.handleChecked("require_acc_recommendations")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Require Shiplify Accessorial Recommendations"
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													<WithTooltip
														title="Standardize US LTL Pickup Addresses"
														content="Applies to Project44 dispatch requests originating from the US. If enabled, the shipper address provided by the user will be sent to a verification service to standardize the address. If exactly one valid result is returned from the service, the address provided by the service will be used as the pickup address in the request to Project44. Due to address line character limits enforced by the carrier, this setting will be ignored when submitting pickup requests with GLS."
														placement="top"
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.state.standardize_us_ltl_pickup_adr}
																	tabIndex={-1}
																	onChange={this.handleChecked("standardize_us_ltl_pickup_adr")}
																	checkedIcon={<Check className={classes.checkedIcon} />}
																	icon={<Check className={classes.uncheckedIcon} />}
																	classes={{
																		checked: classes.checked,
																		root: classes.checkRoot
																	}}
																/>
															}
															classes={{
																label: classes.label
															}}
															label="Standardize US LTL Pickup Addresses"
														/>
													</WithTooltip>
												</GridItem>
												<GridItem xs={4}>
													<Input
														disabled={!this.state.override_dgd_addl_handling_instructions}
														onChange={this.handleInput("dgd_addl_handling_instructions")}
														value={this.state.dgd_addl_handling_instructions}
														multiline
														fullWidth
														inputProps={{ maxLength: 500 }}
														placeholder="DGD Additional Handling Instructions (500 characters MAX)"
													/>
												</GridItem>
												{this.state.hide_tl_carriers && (
													<GridItem xs={6}>
														<InputTag
															inputLabel={
																<>
																	Override FTL SCACs for Warehouse
																	<WithTooltip
																		content="If any SCACs are selected, the company defaults will not be applied and only the SCACs selected will be treated as FTL."
																	>
																		<Info style={{ height: 16, marginLeft: 4 }} />
																	</WithTooltip>
																</>
															}
															classes={classes}
															value={this.state.warehouse_ftl_scacs}
															options={this.state.tfm_scacs}
															onChange={this.handleFtlScacAssignment}
															getOptionSelected={(option, value) => option == value}
															getOptionLabel={(option) => `${option}`}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		checkedIcon={<Check className={this.props.classes.checkedIcon} />}
																		icon={<Check className={this.props.classes.uncheckedIcon} />}
																		style={{ marginRight: 8 }}
																		checked={selected}
																	/>
																	{option}
																</>
															)}
														/>
													</GridItem>
												)}
											</GridContainer>
											<GridContainer>
												<GridItem xs={12} sm={12} md={12} lg={12}>
														<h5>
															Default Standard BOL Reference Values
															<WithTooltip
																title="Default Standard BOL Reference Values"
																content="Configure values to set default standard BOL References to."
																placement="top"
															>
																<Info style={{ height: 16, marginLeft: 4 }} />
															</WithTooltip>
														</h5>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>PO Number</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "po_number",
															value: this.state.po_number || "",
															onChange: this.handleInput("po_number")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>SO Number</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "so_number",
															value: this.state.so_number || "",
															onChange: this.handleInput("so_number")
														}}
														white
													/>
												</GridItem>
												<GridItem xs>
													<InputLabel className={classes.label}>Ref Number</InputLabel>
													<br />
													<CustomInput
														formControlProps={{
															fullWidth: true
														}}
														inputProps={{
															type: "text",
															name: "ref_number",
															value: this.state.ref_number || "",
															onChange: this.handleInput("ref_number")
														}}
														white
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<h5>Parcel</h5>
											<GridContainer>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.enable_fedex_parcel_shipping}
																tabIndex={-1}
																onChange={this.handleChecked("enable_fedex_parcel_shipping")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Enable FedEx Shipping"
													/>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.enable_ups_parcel_shipping}
																tabIndex={-1}
																onChange={this.handleChecked("enable_ups_parcel_shipping")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Enable UPS Shipping"
													/>
												</GridItem>
												<GridItem xs>
													<FormControlLabel
														control={
															<Checkbox
																checked={this.state.enable_usps_parcel_shipping}
																tabIndex={-1}
																onChange={this.handleChecked("enable_usps_parcel_shipping")}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot
																}}
															/>
														}
														classes={{
															label: classes.label
														}}
														label="Enable USPS Shipping"
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs>
													<InputLabel className={classes.label}>HazMat Software</InputLabel>
													<br />
													<GridContainer>
														<GridItem xs>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={this.state.hazmat_api_test}
																		tabIndex={-1}
																		onChange={this.handleChecked("hazmat_api_test")}
																		checkedIcon={<Check className={classes.checkedIcon} />}
																		icon={<Check className={classes.uncheckedIcon} />}
																		classes={{
																			checked: classes.checked,
																			root: classes.checkRoot
																		}}
																	/>
																}
																classes={{
																	label: classes.label
																}}
																label="Test Account"
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>Dev Id</InputLabel>
															<br />
															<CustomInput
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "text",
																	name: "hazmat_dev_id",
																	value: this.state.hazmat_dev_id || "",
																	onChange: this.handleInput("hazmat_dev_id")
																}}
																white
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>App Id</InputLabel>
															<br />
															<CustomInput
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "text",
																	name: "hazmat_app_id",
																	value: this.state.hazmat_app_id || "",
																	onChange: this.handleInput("hazmat_app_id")
																}}
																white
															/>
														</GridItem>
														<GridItem xs>
															<InputLabel className={classes.label}>Token</InputLabel>
															<br />
															<CustomInput
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	type: "text",
																	name: "hazmat_token",
																	value: this.state.hazmat_token || "",
																	onChange: this.handleInput("hazmat_token")
																}}
																white
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<GridContainer>
								                    <GridContainer>
														<GridItem xs={12} sm={12} md={12} lg={12}>
															<h5>
																Custom Shipment References
																<WithTooltip
																	title="Custom Shipment References"
																	content="Configure what custom reference fields will appear on LTL and Parcel shipment entry pages, their default values and whether or not they are required. References can be set to be freeform text or drop downs with the options being those specified on this page."
																	placement="top"
																>
																	<Info style={{ height: 16, marginLeft: 4 }} />
																</WithTooltip>
																<Button color="success" size="sm" justIcon round className={classes.marginLeft} onClick={this.addReferenceSetting}>
																	<Add/>
																</Button>
															</h5>
								                        </GridItem>
								                        <GridItem xs={12} sm={12} md={12} lg={12}>
								                         	{this.state.referenceSettings.map((setting, index) => {
								                         		return (
								                         			<CustomReferenceSetting
								                         				key={index}
								                         				index={index}
								                         				classes={classes}
								                         				value={setting.value}
								                         				default_value={setting.default_value}
								                         				display_on_bol={setting.display_on_bol}
								                         				isRequired={setting.required}
								                         				cust_ref_entry_type={setting.cust_ref_entry_type}
								                         				cust_ref_entry_opts={setting.cust_ref_entry_opts}
								                         				types={this.state.referenceTypes}
								                         				onRemove={this.onRemoveReferenceSetting}
								                         				onChangeType={this.onChangeReferenceSettingType}
								                         				onChangeCheckbox={this.onChangeReferenceSettingCheckBox}
								                         			/>
								                         		);
								                         	})}
								                        </GridItem>
								                    </GridContainer>
								            </GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<h5>Logo</h5>
											<GridContainer>
												<GridItem xs={12} sm={12} md={6}>
													<Dropzone handleUpload={this.handleUpload.bind(this)} />
												</GridItem>
												{!_.isEmpty(this.state.logo) && (
													<GridItem xs={12} sm={12} md={6}>
														<aside className={classes.thumbsContainer}>{this.getImage(this.state.logo)}</aside>
													</GridItem>
												)}
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<Button
												size="lg"
												color="info"
												style={{
													marginRight: "5px"
												}}
												onClick={this.saveWarehouse}
											>
												Save Warehouse
											</Button>
										</GridItem>
										{this.state.isEdit && (
											<GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
												<Grid container>
													<GridItem xs={12} sm={8} md={9} lg={10} className={classes.right}>
														<Grid container justify="center">
															<GridItem xs={12} className={classes.center}>
																<NavLink to={basePath + "/admin/locations/import?d=" + this.state.id}>
																	<Button className={classes.marginRight}>Import Locations</Button>
																</NavLink>
																<Button className={classes.marginRight} onClick={() => this.export("location")}>
																	Export Locations
																</Button>
																<NavLink to={basePath + "/admin/products/import?d=" + this.state.id}>
																	<Button className={classes.marginRight}>Import Products</Button>
																</NavLink>
																<Button className={classes.marginRight} onClick={() => this.export("product")}>
																	Export TL/LTL Products
																</Button>
																<Button className={classes.marginRight} onClick={() => this.export("parcelProduct")}>
																	Export Parcel Products
																</Button>
																<NavLink to={basePath + "/admin/units/import?d=" + this.state.id}>
																	<Button className={classes.marginRight}>Import Units</Button>
																</NavLink>
																<Button className={classes.marginRight} onClick={() => this.export("unit")}>
																	Export Units
																</Button>
															</GridItem>
														</Grid>
													</GridItem>
													<GridItem xs={12} sm={4} md={3} lg={2} className={classes.right}>
														<CustomDropdown
															buttonText="Dangerous Tools"
															hoverColor="danger"
															buttonProps={{
																round: false,
																block: true,
																color: "black"
															}}
															dropPlacement="top"
															dropdownList={[<div onClick={() => this.handleDelete("location")}>Delete All Locations</div>, <div onClick={() => this.handleDelete("product")}>Delete All Products</div>, <div onClick={() => this.handleDelete("unit")}>Delete All Units</div>]}
														/>
													</GridItem>
												</Grid>
											</GridItem>
										)}
									</GridContainer>
								)}
								{this.state.isEdit && this.state.active === "users" && (
									<GridContainer>
										<GridItem xs={12}>
											<NavLink to={basePath + "/admin/company-" + this.state.company + "/warehouse-" + this.state.id + "/user/new"}>
												<Button size="lg" color="linkedin">
													New User
												</Button>
											</NavLink>
										</GridItem>
										<GridItem xs={12} className={classes.center}>
											{!_.isEmpty(this.state.users) ? <Table tableHead={["ID", "Username", "Code", "Rate Display", "API Key", "Actions"]} tableData={this.getUsers(this.state.users)} customCellClasses={[classes.right]} customClassesForCells={[5]} customHeadCellClasses={[classes.right]} customHeadClassesForCells={[5]} /> : <Badge color="info">No Users</Badge>}
										</GridItem>
									</GridContainer>
								)}
								{this.state.isEdit && this.state.active === "locations" && (
									<GridContainer style={{ marginBottom: "10px" }}>
										<GridItem xs={12}>
											<NavLink to={basePath + "/admin/location/new/" + this.state.company + "/" + this.state.id + "/warehouse"}>
												<Button size="lg" color="linkedin">
													New Address
												</Button>
											</NavLink>
										</GridItem>
									</GridContainer>
								)}
								{this.state.isEdit && this.state.active === "locations" && (
									<ReactTable
										data={this.getLocations(this.state.locations)}
										filterable
										columns={[
											{
												Header: "Code",
												accessor: "code",
												filterable: false
											},
											{
												Header: "Name",
												accessor: "name",
												filterable: false
											},
											{
												Header: "Address",
												accessor: "address",
												filterable: false
											},
											{
												Header: "Contact",
												accessor: "contact",
												sortable: false,
												filterable: false
											},
											{
												Header: "Type",
												accessor: "type",
												filterable: false
											},
											{
												Header: "Default",
												accessor: "default",
												sortable: false,
												filterable: false
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												filterable: false
											}
										]}
										defaultPageSize={20}
										showPaginationTop
										showPaginationBottom
										className="-striped -highlight"
									/>
								)}
							</CardBody>
						</Card>
					) : (
						<Card>
							<CardBody>
								<GridContainer>
									<Spinner loading={this.state.loading} message="Failed to retrieve warehouse settings from the server" />
								</GridContainer>
							</CardBody>
						</Card>
					)}
				</GridItem>
			</GridContainer>
		);
	}
}

class CustomReferenceSetting extends Component {
	render() {
		const classes = this.props.classes || {};
		return(
			<GridContainer>
				<GridItem xs={1} sm={1} md={1} lg={1}>
					<InputLabel className={classes.label}>Type</InputLabel>
					<FormControl fullWidth className={classes.selectFormControl}>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={this.props.cust_ref_entry_type}
							inputProps={{
								name: "cust_ref_entry_type",
								id: "cust_ref_entry_type"
							}}
							onChange={this.onChangeEntryType}
						>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="freeform"
							>
								Freeform Input
							</MenuItem>
							<MenuItem
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected
								}}
								value="select"
							>
								Select Option
							</MenuItem>
						</Select>
					</FormControl>
	            </GridItem>
	            <GridItem xs={4} sm={4} md={4} lg={3}>
	                <WithTooltip title="Reference Name" content="What is this reference called?">
	                    <InputLabel className={classes.label}>Name</InputLabel>
	                </WithTooltip>
	                <br />
	                <FormControl fullWidth>
	                    <CustomInput
	                        inputProps={{
	                            type: "text",
	                            value: this.props.value,
	                            onInputChange: this.onChangeType,
	                        }}
	                        autoComplete
	                        options={this.props.types}
	                        white
	                    />
	                </FormControl>
	            </GridItem>
				{(this.props.cust_ref_entry_type === 'select') ? (
					<>
						<GridItem xs={3} sm={3} md={3} lg={2}>
							<InputLabel className={classes.label}>
								Options
							</InputLabel>
							<InputList
								values={this.props.cust_ref_entry_opts}
								onChange={this.onChangeOpts}
							/>
						</GridItem>
						<GridItem xs={3} sm={3} md={3} lg={2}>
								<WithTooltip title="Default Value" content="If a value is provided in this field, the reference and value will be pre-loaded when users create new BOLs for this warehouse">
									<InputLabel className={classes.label}>Default Value</InputLabel>
								</WithTooltip>
								<br />
							<FormControl fullWidth className={classes.selectFormControl}>
								<Select
									value={this.props.default_value || ''}
									inputProps={{ name: "cust_ref_entry_opts" }}
									onChange={this.onChangeDefaultValue}
									sx={{minWidth: 130}}
								>
									<MenuItem
										key={"default_value_" + this.props.index}
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value=""
									>
										<em>None</em>
									</MenuItem>
									{this.props.cust_ref_entry_opts.map((val, key) => {
										return (
											<MenuItem
												key={"reference_name_" + key}
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected
												}}
												value={val}
											>
												{((_.isEmpty(val)) ? (<span style={{color: 'transparent'}}>none</span>) : val)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</GridItem>
					</>
				) :
					<GridItem xs={6} sm={6} md={6} lg={4}>
						<WithTooltip title="Default Value" content="If a value is provided in this field, the reference and value will be pre-loaded when users create new BOLs for this warehouse">
							<InputLabel className={classes.label}>Default Value</InputLabel>
						</WithTooltip>
						<br />
						<FormControl fullWidth>
							<CustomInput
								inputProps={{
									type: "text",
									value: this.props.default_value,
									onChange: this.onChangeDefaultValue,
								}}
								white
							/>
						</FormControl>
					</GridItem>
				}
	            <GridItem xs={2} sm={2} md={2} lg={1}>
		            <br />
	                <WithTooltip title="Required" content="Require this custom reference for execution">
	                    <FormControlLabel
	                        label="Required"
	                        control={
	                            <Checkbox
	                                size="small"
	                                checked={this.props.isRequired ? true : false}
	                                onChange={this.onChangeRequired}
	                            />
	                        }
	                    />
	                </WithTooltip>
	            </GridItem>
	            <GridItem xs={2} sm={2} md={2} lg={1}>
	            	<br />
	                <WithTooltip title="Display on BOL" content="If enabled, BOLs with this reference may be displayed on the BOL. Up to 3 custom references will appear on a BOL.">
	                    <FormControlLabel
	                        label="Display on BOL"
	                        control={
	                            <Checkbox
	                                size="small"
	                                checked={this.props.display_on_bol ? true : false}
	                                onChange={this.onChangeDisplayOnBol}
	                            />
	                        }
	                    />
	                </WithTooltip>
	            </GridItem>
	            <GridItem xs={4} sm={4} md={4} lg={1}>
	            	<br />
	                <Button color="danger" size="sm" justIcon round onClick={this.onRemove}>
	                    <Delete/>
	                </Button>
	            </GridItem>
	        </GridContainer>
        );
	}

	onChangeType = (e, val) => {
		if (e) this.props.onChangeType(this.props.index, val, 'value');
	}

	onChangeEntryType = (e, val) => {
		if (e) {
			this.props.onChangeType(this.props.index, val.props.value, 'cust_ref_entry_type');
			// reset the options/default value on change
			this.props.onChangeType(this.props.index, [], 'cust_ref_entry_opts');
			this.props.onChangeType(this.props.index, '', 'default_value');
		}
	}
	onChangeOpts = (e) => {
		if (e) this.props.onChangeType(this.props.index, e, 'cust_ref_entry_opts');
	}
	// values => this.setState({ cust_ref_entry_opts: values }
	// cust_ref_entry_type
	// cust_ref_entry_opts

	onChangeDefaultValue = (e) => {
		if (e) this.props.onChangeType(this.props.index, e.target.value, 'default_value');
	}


	onChangeRequired = e => {
		if (e) this.props.onChangeCheckbox(this.props.index, 'required');
	}

	onChangeDisplayOnBol = e => {
		if (e) this.props.onChangeCheckbox(this.props.index, 'display_on_bol');
	}


	onRemove = e => {
		if (e) this.props.onRemove(this.props.index);
	}
}

export default connect(null, {clearNotifications})(withStyles(style)(withSnackbar(EditWarehouse)));
